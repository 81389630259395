import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { NavbarType } from '@/components/navbar/c-navbar.component';
import { Module } from 'vuex';
import { ICbVwUser, CbVwUser } from '@/shared/model/cb-vw-user.model';
import { ICbUserTimezone } from '@/shared/model/cb-user-timezone.model';
import { IKeycloakToken } from '@/shared/model/keycloak-token.model';
import { CbVwUserStatus, ICbVwUserStatus } from '@/shared/model/cb-vw-user-status.model';

export interface AccountStateStorable {
  logon: boolean;
  userIdentity: null | any;
  authenticated: boolean;
  ribbonOnProfiles: string;
  activeProfiles: string;
  pathHistory: string;
  loginType: NavbarType;
  isNewLogin: boolean;
  isSetUsername: boolean;
  userInfo: ICbUserInfo;
  currentUser: ICbVwUser;
  hasRoleCbUser: boolean;
  hasRoleCbPartner: boolean;
  recentSearch: string[];
  isIntroductionFreelancer: boolean;
  userBookmarks: null;
  watchIDGeolocation: 0;
  cbUserTimeZones: ICbUserTimezone[];
  countNotif: number;
  countMsg: number;
  keycloakToken: IKeycloakToken;
  userIdOrderIdIsShowMap: UserIdOrderIdPair[];
  cbVwUserStatus: ICbVwUserStatus;
}
export const defaultAccountState: AccountStateStorable = {
  logon: false,
  userIdentity: null,
  authenticated: false,
  ribbonOnProfiles: '',
  activeProfiles: '',
  pathHistory: '',
  loginType: NavbarType.GENERAL,
  isNewLogin: true,
  isSetUsername: false,
  userInfo: new CbUserInfo(),
  currentUser: new CbVwUser(),
  hasRoleCbUser: false,
  hasRoleCbPartner: false,
  recentSearch: [],
  isIntroductionFreelancer: false,
  userBookmarks: null,
  watchIDGeolocation: 0,
  cbUserTimeZones: [],
  countNotif: 0,
  countMsg: 0,
  keycloakToken: null,
  userIdOrderIdIsShowMap: [],
  cbVwUserStatus: new CbVwUserStatus(),
};

export const accountStore: Module<AccountStateStorable, any> = {
  state: { ...defaultAccountState },
  getters: {
    logon: state => state.logon,
    account: state => state.userIdentity,
    authenticated: state => state.authenticated,
    activeProfiles: state => state.activeProfiles,
    ribbonOnProfiles: state => state.ribbonOnProfiles,
    pathHistory: state => state.pathHistory,
    loginType: state => state.loginType,
    isNewLogin: state => state.isNewLogin,
    isSetUsername: state => state.isSetUsername,
    userInfo: state => state.userInfo,
    currentUser: state => state.currentUser,
    hasRoleCbUser: state => state.hasRoleCbUser,
    hasRoleCbPartner: state => state.hasRoleCbPartner,
    recentSearch: state => state.recentSearch,
    isIntroductionFreelancer: state => state.isIntroductionFreelancer,
    userBookmarks: state => state.userBookmarks,
    watchIDGeolocation: state => state.watchIDGeolocation,
    cbUserTimeZones: state => state.cbUserTimeZones,
    countNotif: state => state.countNotif,
    countMsg: state => state.countMsg,
    keycloakToken: state => state.keycloakToken,
    userIdOrderIdIsShowMap: state => state.userIdOrderIdIsShowMap,
    cbVwUserStatus: state => state.cbVwUserStatus,
  },
  mutations: {
    logon(state, logon) {
      state.logon = logon;
    },
    authenticate(state) {
      state.logon = true;
    },
    authenticated(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      // state.logon = false;
    },
    logout(state) {
      state.logon = false;
      state.userIdentity = null;
      state.authenticated = false;
      state.ribbonOnProfiles = '';
      state.activeProfiles = '';
      state.pathHistory = '';
      state.loginType = NavbarType.GENERAL;
      state.isNewLogin = true;
      state.userInfo = new CbUserInfo();
      state.currentUser = new CbVwUser();
      state.hasRoleCbUser = false;
      state.hasRoleCbPartner = false;
      state.watchIDGeolocation = 0;
      state.cbVwUserStatus = new CbVwUserStatus();
      Object.assign(state, defaultAccountState);
    },
    setActiveProfiles(state, profile) {
      state.activeProfiles = profile;
    },
    setRibbonOnProfiles(state, ribbon) {
      state.ribbonOnProfiles = ribbon;
    },
    pathHistory(state, pathHistory) {
      state.pathHistory = pathHistory;
    },
    loginType(state, loginType) {
      state.loginType = loginType;
    },
    isNewLogin(state, isNewLogin) {
      state.isNewLogin = isNewLogin;
    },
    isSetUsername(state, isSetUsername) {
      state.isSetUsername = isSetUsername;
    },
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    currentUser(state, cbVwUser) {
      state.currentUser = cbVwUser;
    },
    setRoleCbBuyer(state, value) {
      state.hasRoleCbUser = value;
    },
    setRoleCbPartner(state, value) {
      state.hasRoleCbPartner = value;
    },
    recentSearch(state, value) {
      state.recentSearch = value;
    },
    isIntroductionFreelancer(state, value) {
      state.isIntroductionFreelancer = value;
    },
    setUserBookmarks(state, userBookmarks) {
      state.userBookmarks = userBookmarks;
    },
    removeUserBookmarks(state) {
      state.userBookmarks = null;
    },
    watchIDGeolocation(state, value) {
      state.watchIDGeolocation = value;
    },
    setCbUserTimeZones(state, value) {
      state.cbUserTimeZones = value;
    },
    countNotif(state, value) {
      state.countNotif = value;
    },
    countMsg(state, value) {
      state.countMsg = value;
    },
    keycloakToken(state, keycloakToken) {
      state.keycloakToken = keycloakToken;
    },
    userIdOrderIdIsShowMap(state, newData) {
      state.userIdOrderIdIsShowMap = newData;
    },
    cbVwUserStatus(state, newData) {
      state.cbVwUserStatus = newData;
    },
  },
};

export interface UserIdOrderIdPair {
  orderId: number;
  userId: string;
}
