import { Component, Prop, Vue } from 'vue-property-decorator';
import CPopupBottom from '../c-popup-bottom/c-popup-bottom.vue';
import CFooter from '../c-footer/c-footer.vue';
import Icon from '@/shared/icons/icon.vue';

@Component({
  components: {CFooter, CPopupBottom, Icon},
})
export default class CFooterBottomSheet extends Vue {
	// ================= START SERVICES ===================
	// ================= END     SERVICES ===================
	// ================= START VARIABLES ==================
	@Prop({default:false}) public isShowTabFooter;
    public isShowFooter = false;
	// ================= END     VARIABLES ==================
	// ================= START DEFAULT FUNCTION ===========
	// ================= END     DEFAULT FUNCTION ===========
	// ================= START FUNCTION ==================

    public toPrivacyPolicy(){
    this.$router.push({ name: 'PLegalDocument', query: { tab: 'PRIVACY_POLICY' } });
    }
    public toTermService(){
    this.$router.push({ name: 'PLegalDocument', query: { tab: 'TERM_OF_SERVICE' } });
    }
	// ================= END     FUNCTION ==================
	// ================= START COMPUTE ==================
	// ================= END     COMPUTE ==================
	// ================= START LISTENERS ==================
	// ================= END     LISTENERS ==================
}

