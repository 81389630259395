var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-fp-bank-account wrap-border-grey-tertiary border-radius-card px-3 pt-3 pb-2",
    },
    [
      _vm.cbBankAccount
        ? _c(
            "p",
            {
              staticClass:
                "mb-2 font-roboto cj-color-black-primary font-size-18-auto font-weight-500",
              domProps: { textContent: _vm._s(_vm.$t("cbGlobal.bankAccount")) },
            },
            [_vm._v("\n    Bank Account\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        [
          !_vm.cbBankAccount
            ? [
                _c(
                  "p",
                  {
                    staticClass:
                      "flex-grow-1 mb-2 font-roboto cj-color-black-primary font-size-18-auto font-weight-500 mr-2",
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cFpBankAccount.bankAccount"
                          )
                        ),
                      },
                    }),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.cbBankAccount
            ? [
                _c("div", { staticClass: "mr-2 mb-2" }, [
                  _c("div", { staticClass: "wrapper-img" }, [
                    _c("img", {
                      attrs: {
                        src: `@/../content/images/bank/${_vm.cbBankAccount.bankCode.toLowerCase()}.svg`,
                        alt: "card",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-grow-1 mr-2 mb-2 overflow-hidden" },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto cj-color-black-primary font-size-18-auto font-weight-500 text-ellipsis-oneline",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("titleAsteriks")(
                                _vm.cbBankAccount.accountHolderName
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto cj-color-grey-secondary font-size-18-auto font-weight-400 text-ellipsis-oneline",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("accountNumberAsteriks")(
                                _vm.cbBankAccount.accountNumber
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto cj-color-grey-secondary font-size-18-auto font-weight-400 text-ellipsis-oneline",
                        },
                        [_vm._v("- " + _vm._s(_vm.getBankNameByKey))]
                      ),
                    ]),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass:
                "px-3 mb-2 font-roboto font-size-18-auto font-weight-500",
              attrs: {
                typeStyle: "secondary",
                dataCyCustom: "btn-add-bank-account",
                dataGTMCustom: "add-bank-account",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.toSetting.apply(null, arguments)
                },
              },
            },
            [
              !_vm.cbBankAccount
                ? [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.add")),
                      },
                    }),
                  ]
                : [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.change")),
                      },
                    }),
                  ],
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }