import CButton from '@/components/button/c-button.vue';
import CIcons from '@/module/chat-container/chat/components/c-icons/c-icons.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    CIcons,
    CButton,
  },
})
export default class messageDeleteNotif extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public callBack() {
    // this.$emit('callBack', null);
    this.$emit('messageActionHandler', 'CANCEL');
  }

  public save() {
    // this.callBack();
    this.$emit('messageActionHandler', 'DELETE');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
