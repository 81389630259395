import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import PCbLogService from '@/services/cb-log.service';
import { CbLogType } from '@/shared/model/enumerations/cb-log-type.model';
import { ICbLog } from '@/shared/model/cb-log.model';
import { CShareData } from '@/shared/model/cb-share-data.model';
@Component({
  components: {
    Icon,
  },
})
export default class CShare extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbLogService') private pCbLogService: () => PCbLogService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({
    default: {
      title: 'Show your PAW. Share your passion to the world.',
      description: 'Let your friends, colleges, and other people notice your PAW on social media.',
    },
  })
  public shareData: CShareData;

  public appId: '3493993048904';
  public canCopy: boolean = false;
  public isSavingLog: boolean = false;
  public CbLogType = CbLogType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    this.canCopy = !!navigator.clipboard;
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public async copy(s) {
    await navigator.clipboard.writeText(s);
    this.createLogService('CB_LINK');
  }

  public createLogService(name: string) {
    this.isSavingLog = true;
    let cbLog: ICbLog = {
      cbLogType: this.shareData.externalType == 'PAW' ? CbLogType.PAW_SHARE : CbLogType.AUCTION_SHARE,
      valueHit: name,
      valueHitId: this.shareData.externalId,
    };

    this.pCbLogService()
      .create(cbLog)
      .finally(() => {
        this.isSavingLog = false;
      });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getIsMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
