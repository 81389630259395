import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

import CButton from '@/components/button/c-button.vue';

import GlobalService from '@/services/global.service';

import { CbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';
import { HANDLE_BUYER_COMPLETED_ORDER_OPEN } from '@/module/chat-container/order-paw-controller.component';

@Component({
  components: {
    CButton,
  },
})
export default class CItemChatMessageClockout extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrderRequirement: CbVwPawOrderRequirement;
  @Prop({ default: null }) public cbVwPawOrder: CbVwPawOrder;

  public CbCommonStatus = CbCommonStatus;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public finishProject() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_BUYER_COMPLETED_ORDER_OPEN,
      typeModal: TypeComponent.CHAT_MODAL,
      cbVwPawOrder: this.cbVwPawOrder,
      cbVwPawOrderRequirement: this.cbVwPawOrderRequirement,
    });
  }

  public handleCMediaPreview(localUrl: string) {
    this.$root.$emit('app::cMediaPreview', localUrl);
    this.globalService().openGlobalDialog(this.$root, 'modal-c-media-preview');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
