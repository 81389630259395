import Vue from 'vue';

export function initFiltersChat() {
  Vue.filter('chatGetOtherUserPhotoId', (members, currentUserId, vwUsers) => {
    members = (<any>members).replace(/\s/g, '');
    const arr = members.split('||');

    const foundOtherId = arr.find(a => a != currentUserId);

    let ret = 0;

    if (vwUsers && vwUsers.length > 0) {
      const found = vwUsers.find(x => x.id === foundOtherId);
      if (found) {
        ret = found.photoId;
      }
    }
    return ret;
  });

  Vue.filter('chatIsUserOnline', (members, currentUserId, userIdsOnline) => {
    if (!members || !currentUserId || !userIdsOnline) return;

    const chatMembers = String(members).replace(/\s/g, '');
    const userMemberIds = chatMembers.split('||');
    const foundOtherId = userMemberIds.find(userId => userId != currentUserId);
    return !!userIdsOnline.find(id => id === foundOtherId);
  });

  Vue.filter('isUserOnline', (userId, userIdsOnline) => {
    if (!userId || !userIdsOnline) return;

    return userIdsOnline.find(id => id === userId) ? true : false;
  });

  Vue.filter('chatIsStar', (stars, currentUserId) => {
    if (stars && stars.includes(currentUserId)) {
      return true;
    } else {
      return false;
    }
  });

  Vue.filter('chatLanguageLabel', (LANGUAGE_CODE, code) => {
    return LANGUAGE_CODE.find(lov => lov.key === code)?.value || '';
  });

  Vue.filter('maxCount', count => {
    const maxCount = count > 99 ? 99 + '+' : count;
    return maxCount;
  });
}
