var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-app-modals" },
    [
      _c("b-modal", {
        attrs: {
          id: "modal-review-app",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          size: "lg",
          "hide-footer": "",
          "hide-header": "",
          centered: "",
          "no-close-on-backdrop": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("c-review", {
                  attrs: { cbVwPawOrder: _vm.cbVwPawOrder },
                  on: {
                    save: function ($event) {
                      return _vm.$root.$emit(
                        "appOrderReview::changeFunction",
                        "openDialogBalanceDeducatedAfterReview",
                        $event
                      )
                    },
                    cancel: function ($event) {
                      return _vm.closeAll()
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.visibleReview,
          callback: function ($$v) {
            _vm.visibleReview = $$v
          },
          expression: "visibleReview",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          size: "lg",
          id: "modal-survey-app",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "hide-footer": "",
          "hide-header": "",
          centered: "",
          "no-close-on-backdrop": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("c-survey", {
                  on: {
                    save: function ($event) {
                      return _vm.$root.$emit(
                        "appOrderReview::changeFunction",
                        "saveSurvey",
                        $event
                      )
                    },
                    cancel: function ($event) {
                      return _vm.closeAll()
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.visibleSurvey,
          callback: function ($$v) {
            _vm.visibleSurvey = $$v
          },
          expression: "visibleSurvey",
        },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.visibleConfirmFeedbackSuccess,
            callback: function ($$v) {
              _vm.visibleConfirmFeedbackSuccess = $$v
            },
            expression: "visibleConfirmFeedbackSuccess",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: _vm.$t("cbgwApp.cbDetailOrder.successfull"),
                subTitle: _vm.$t("cbgwApp.cbDetailOrder.thankyouFeedback"),
                submitTitle: _vm.$t("cbgwApp.cbDetailOrder.finish"),
                hideClose: true,
                iconCustom: "ic_check_circle",
              },
              on: { save: _vm.closeAll },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-confirmation-hibernate-confirm",
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.visibleHibernateConfirm,
            callback: function ($$v) {
              _vm.visibleHibernateConfirm = $$v
            },
            expression: "visibleHibernateConfirm",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: _vm.$t("cbgwApp.cbVwUser.offDutyTitle"),
                subTitle: _vm.$t("cbgwApp.cbVwUser.offDutySubTitle", {
                  startDate: _vm.$options.filters.dynamicFormatDate(
                    _vm.currentUser.offDutyStartDate,
                    "DD MMMM YYYY"
                  ),
                  endDate: _vm.$options.filters.dynamicFormatDate(
                    _vm.currentUser.offDutyEndDate,
                    "DD MMMM YYYY"
                  ),
                }),
                submitTitle: _vm.$t("cbgwApp.cbVwUser.toDashboard"),
                hideClose: true,
                iconCustom: "ic_check_circle",
              },
              on: {
                save: _vm.toEditAvailability,
                cancel: function ($event) {
                  return _vm.closeAll()
                },
                close: function ($event) {
                  return _vm.closeAll()
                },
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-confirmation-deactive-app",
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": "",
          },
          model: {
            value: _vm.visibleDeactiveConfirm,
            callback: function ($$v) {
              _vm.visibleDeactiveConfirm = $$v
            },
            expression: "visibleDeactiveConfirm",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: _vm.$t("cbgwApp.cbVwUser.nonActiveTitle"),
                subTitle: _vm.$t("cbgwApp.cbVwUser.nonActiveSubTitle"),
                submitTitle: _vm.$t("cbgwApp.cbVwUser.activateAccount"),
                cancelTitle: "LOG OUT",
                hideClose: true,
                iconCustom: "ic_warning_circle_outlined",
              },
              on: {
                save: function ($event) {
                  return _vm.reactiveAccount()
                },
                cancel: function ($event) {
                  return _vm.logout()
                },
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-confirmation-deactive-confirm-success",
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.visibleDeactiveConfirmSuccess,
            callback: function ($$v) {
              _vm.visibleDeactiveConfirmSuccess = $$v
            },
            expression: "visibleDeactiveConfirmSuccess",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: _vm.$t("cbgwApp.cbVwUser.reActiveAccountSuccessTitle"),
                subTitle: _vm.$t(
                  "cbgwApp.cbVwUser.reActiveAccountSuccessSubTitle"
                ),
                iconCustom: "ic_check_circle",
              },
              on: {
                cancel: function ($event) {
                  return _vm.closeAll()
                },
                close: function ($event) {
                  return _vm.closeAll()
                },
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-confirmation-user-id-exist-app",
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": "",
          },
          model: {
            value: _vm.visibleModalIdpExist,
            callback: function ($$v) {
              _vm.visibleModalIdpExist = $$v
            },
            expression: "visibleModalIdpExist",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: _vm.$t("cbgwApp.cbVwUser.userIdpExistTitle"),
                subTitle: _vm.$t("cbgwApp.cbVwUser.userIdpExistSubTitle"),
                iconCustom: "ic_warning_circle_outlined",
              },
              on: {
                cancel: function ($event) {
                  return _vm.closeAll()
                },
                close: function ($event) {
                  return _vm.closeAll()
                },
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-confirmation-user-suspend-app",
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": "",
          },
          model: {
            value: _vm.visibleModalSuspend,
            callback: function ($$v) {
              _vm.visibleModalSuspend = $$v
            },
            expression: "visibleModalSuspend",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: _vm.$t("cbgwApp.cbVwUser.userSuspendTitle", {
                  timeRemaining:
                    _vm.currentLanguage == "en"
                      ? _vm.cbVwUserStatus.timeLabelEn
                      : _vm.cbVwUserStatus.timeLabelId,
                }),
                subTitle: _vm.$t("cbgwApp.cbVwUser.userSuspendDetail"),
                submitTitle: _vm.$t("cbgwApp.cbVwUser.cusstomerSupport"),
                iconCustom: "ic_warning_circle_outlined",
              },
              on: {
                save: function ($event) {
                  return _vm.toAskCommitee()
                },
                cancel: function ($event) {
                  return _vm.closeAll()
                },
                close: function ($event) {
                  return _vm.closeAll()
                },
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.visibleVA
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "modal-is-show-va",
                size: "lg",
                "hide-footer": "",
                "hide-header": "",
                "content-class": "vac-custom-modal-content-b-modal",
                "dialog-class": "vac-custom-modal-size-b-modal",
                "modal-class": "vac-custom-modal-b-modal",
              },
              model: {
                value: _vm.visibleVA,
                callback: function ($$v) {
                  _vm.visibleVA = $$v
                },
                expression: "visibleVA",
              },
            },
            [
              [
                _c("c-complete-payment-va", {
                  attrs: { payDetailVA: _vm.payDetailVA },
                  on: {
                    cancel: function ($event) {
                      return _vm.closeAll()
                    },
                  },
                }),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleFormPayment2023
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "modal-is-show-form-payment-top-up",
                size: "lg",
                "hide-footer": "",
                "hide-header": "",
                "content-class": "vac-custom-modal-content-b-modal",
                "dialog-class": "vac-custom-modal-size-b-modal",
                "modal-class": "vac-custom-modal-b-modal",
              },
              model: {
                value: _vm.visibleFormPayment2023,
                callback: function ($$v) {
                  _vm.visibleFormPayment2023 = $$v
                },
                expression: "visibleFormPayment2023",
              },
            },
            [
              [
                _c("c-form-payment-2023", {
                  attrs: { payload: _vm.paymentPayload },
                  on: {
                    cancel: function ($event) {
                      return _vm.closeAll()
                    },
                    callbackVA: _vm.showModalVA,
                    onSuccess: function ($event) {
                      return _vm.closeAll()
                    },
                  },
                }),
              ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "cad-modal-we-gonna-miss-you",
          "hide-footer": "",
          "hide-header": "",
          "no-close-on-backdrop": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({}) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.gonnaMissYou"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.descGonnaMissYou"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: function ($event) {
                      return _vm.logout(true)
                    },
                    close: function ($event) {
                      return _vm.logout(true)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }