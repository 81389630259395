import { IChtMessage } from './cht-message.model';

export interface IChtMessageSeen {
  id?: number;
  content?: string | null;
  createdById?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  chtMessage?: IChtMessage | null;
}

export class ChtMessageSeen implements IChtMessageSeen {
  constructor(
    public id?: number,
    public content?: string | null,
    public createdById?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public chtMessage?: IChtMessage | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
