export enum CbSearchType {
  SVC = 'SVC',

  SVC_CAT = 'SVC_CAT',

  PAW = 'PAW',

  AUCTION_PROJECT = 'AUCTION_PROJECT',

  USER = 'USER',
  USER_BUYER = 'USER_BUYER',
  USER_PARTNER = 'USER_PARTNER',
}
