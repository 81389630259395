export enum ChtConversationType {
  GROUP = 'GROUP',

  COLLAB = 'COLLAB',

  CURHAT = 'CURHAT',

  PERSONAL = 'PERSONAL',

  SECRET = 'SECRET',

  CATEGORY = 'CATEGORY',
}
