import CButton from '@/components/button/c-button.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';
import Icon from '@/shared/icons/icon.vue';
import { CbPkgCoreType } from '@/shared/model/enumerations/cb-pkg-core-type.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: { Icon, CButton, CTextareaPreview },
  mixins: [StatusTextEnId],
})
export default class CPdPackageItem extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({ default: null })
  public title;

  @Prop({ default: null })
  public pkgType;

  @Prop({ default: null })
  public seletedPackage;

  @Prop({ default: false })
  public seletedPopUp;

  @Prop()
  public showMoreNonePkg;

  @Prop()
  public cbVwPaw;

  @Prop()
  public tabPkg;

  @Prop()
  public cbPawPkgAggs;

  @Prop()
  public isloader;

  public deviceSizeType = DeviceSizeType;
  public CbPkgCoreType = CbPkgCoreType;
  public CbSvcType = CbSvcType;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
