var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "live-chat-fixed-m" },
    [
      _vm.isShowliveChat
        ? _c("c-livechat", {
            attrs: {
              height: _vm.screenHeight,
              theme: _vm.theme,
              styles: _vm.styles,
              "current-user-id": _vm.currentUserId,
              "user-ids-online": _vm.userIdsOnline,
              "room-id": _vm.roomId,
              rooms: _vm.loadedRooms,
              "loading-rooms": _vm.loadingRooms,
              messages: _vm.messages,
              "messages-loaded": _vm.messagesLoaded,
              "rooms-loaded": _vm.roomsLoaded,
              "room-actions": _vm.roomActions,
              "menu-actions": _vm.menuActions,
              "message-selection-actions": _vm.messageSelectionActions,
              "room-message": _vm.roomMessage,
              "templates-text": _vm.templatesText,
              isDevice: _vm.isDevice,
              "is-closed": _vm.isClosed,
            },
            on: {
              "fetch-more-rooms": _vm.fetchMoreRooms,
              "fetch-messages": _vm.fetchMessages,
              "send-message": _vm.sendMessage,
              "edit-message": _vm.editMessage,
              "delete-message": _vm.deleteMessage,
              "open-file": _vm.openFile,
              "open-user-tag": _vm.openUserTag,
              "add-room": _vm.addRoom,
              "room-action-handler": _vm.menuActionHandler,
              "menu-action-handler": _vm.menuActionHandler,
              "message-selection-action-handler":
                _vm.messageSelectionActionHandler,
              "send-message-reaction": _vm.sendMessageReaction,
              "typing-message": _vm.typingMessage,
              "toggle-rooms-list": function ($event) {
                return _vm.$emit("show-demo-options", $event.opened)
              },
              deleteMessageReaction: _vm.deleteMessageReaction,
              "message-action-handler": _vm.messageActionHandler,
              callBack: function ($event) {
                return _vm.callBack($event)
              },
              "ask-me-question-again": _vm.askmeQuestionAgain,
            },
          })
        : _c("c-livechat-create", {
            attrs: {
              tkt: _vm.tkt,
              isMobile: _vm.isMobile,
              userLogin: _vm.userLogin,
            },
            on: {
              createConversation: _vm.createConversation,
              callBack: function ($event) {
                return _vm.callBack($event)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }