import StatusTextEnId from '@/shared/data/status-text-en-Id.service';
import { CbPawStatus } from '@/shared/model/enumerations/cb-paw-status.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  mixins: [StatusTextEnId],
})
export default class CPdStatus extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({default:[]})
  public cbVwPaw;
  public cbPawStatus = CbPawStatus;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
