import axios from 'axios';
import { TabSettingType } from '@/module/general/setting/settings.component';
import { Inject, Component, Prop, Emit, Mixins, Watch } from 'vue-property-decorator';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

import CButton from '@/components/button/c-button.vue';
import CAccountDeactive from '../c-account-deactive/c-account-deactive.vue';
import CAutocompleteNew from '../c-autocomplete-new/c-autocomplete-new.vue';
import CPopupBottom from '../c-popup-bottom/c-popup-bottom.vue';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import CVerificationPhoneNumber from './components/c-verification-phone-number.vue';
import CVerificationPhone from '../auth/c-verification-phone/c-verification-phone.vue';
import Icon from '@/shared/icons/icon.vue';

import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { ICbLov } from '@/shared/model/cb-lov.model';
import { Debounce } from '@/shared/decorator/debounce';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';

import PCbSetPhoneService from '@/services/cb-set-phone.service';
import PUserService from '@/services/user.service';
import JhiDataUtils from '@/shared/data/data-utils.service';
import GlobalService from '@/services/global.service';
import ContentService from '@/services/content.service';
import FormUtils from '@/shared/utils/form-utils.service';

const validations: any = {
  cbUserInfo: {
    isShowOnlineStatus: {
      required,
    },
    langKey: {
      required,
    },
    phone: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(16),
      $autoDirty: true,
    },
  },
  phone: {
    required,
    minLength: minLength(5),
    maxLength: maxLength(16),
    $autoDirty: true,
  },
  phoneCode: { required, $autoDirty: true },
};
@Component({
  validations,
  components: {
    CButton,
    Icon,
    CAccountDeactive,
    CConfirmation,
    CVerificationPhoneNumber,
    CAutocompleteNew,
    CPopupBottom,
    CVerificationPhone,
  },
  watch: {
    phone: {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 16;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.phone = oldVal;
        }
        this.phone = this.$options.filters.regexPhoneNumber(this.phone);
        this.handleCheckPhone();

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
    valuePassword: {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 255;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.valuePassword = oldVal;
        }

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
})
export default class CAccountUpdateProfile extends Mixins(JhiDataUtils, FormUtils) {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbSetPhoneService') private pCbSetPhoneService: () => PCbSetPhoneService;
  @Inject('pUserService') private pUserService: () => PUserService;
  @Inject('contentService') private contentService: () => ContentService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) userAccount: any;
  @Prop({ default: null }) cbUserInfo: CbUserInfo;
  public isFetching = false;
  public phone = '';
  public phoneTmp = '';
  public valuePassword = '';
  public errorSubmitNewPhoneNumber = false;
  public errorSubmitOtp = false;
  public passwordInputType = 'password';
  public enableBtnNewPhone = true;

  public phoneCode = '+62';
  public localPhoneCode = '+62';
  public isFetchingPhoneNumber = false;
  public isShowPhoneCode = false;
  public valueStatusPhone = '';
  public isExistPhone = false;

  public phoneOtp = '';
  public popUpLanguage = false;
  public valuePopUpLanguage = '';

  public isAlreadyOpenOTPVerificationPhoneNumber = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created(): void {
    this.$root.$off('AccountUpdate::changeValue');
    this.$root.$on('AccountUpdate::changeValue', (fieldName: string, value: string) => {
      this[fieldName] = value;
    });

    this.$root.$off('AccountUpdate::resendOtp');
    this.$root.$on('AccountUpdate::resendOtp', () => {
      this.submitNewPhoneNumber();
    });

    this.contentService().initRetrieveCbLovs(CbLovType.PHONE_CODE);
    this.contentService().initRetrieveCbLovs(CbLovType.LANGUAGE);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public openPopupPhoneCodeM(): void {
    if (this.isMobile) {
      this.localPhoneCode = this.phoneCode;
      this.isShowPhoneCode = true;
    }
  }

  public toAccountDeactive(): void {
    this.$router.push({ name: 'PSettingAccountDeactiveM' });
  }

  public modalDeactiveAccount(): void {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-deactive-account');
  }

  public toProfile(): void {
    this.$router.push({ name: 'PProfile' });
  }

  public changeValue(fieldName, value): void {
    this[fieldName] = value;
  }

  @Debounce(600)
  public handleCheckPhone(): void {
    if (this.phone && this.phone.length > 5 && this.phoneCode) {
      this.isFetchingPhoneNumber = true;
      this.pUserService()
        .findPhoneExist(this.phoneCode, this.phone)
        .then(res => {
          this.valueStatusPhone = res.data.detail;
          if (res.data.id > 0) {
            this.isExistPhone = true;
          } else {
            this.isExistPhone = false;
          }
        })
        .catch(err => {
          this.valueStatusPhone = '';
        })
        .finally(() => {
          this.isFetchingPhoneNumber = false;
        });
    } else {
      this.isExistPhone = false;
    }
  }
  public openModalSetPhone(): void {
    this.valuePassword = '';
    this.handleCheckPhone();
    this.globalService().openGlobalDialog(this.$root, 'modal-c-new-phone-number');
  }

  public handleSubmit(): void {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-set-change-phone');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-new-phone-number');
  }

  public inputPassword(e): void {
    if (e.target.value.length >= 8) {
      this.valuePassword = e.target.value;
      this.enableBtnNewPhone = false;
    } else if (e.target.value.length < 8) {
      this.enableBtnNewPhone = true;
    }
  }

  public submitNewPhoneNumber(): void {
    this.pCbSetPhoneService()
      .postChangePhone({ newPhone: this.phone, newPhoneCode: this.phoneCode, password: this.valuePassword })
      .then(res => {
        this.globalService().openGlobalDialog(this.$root, 'modal-c-phone-verification');
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-new-phone-number');
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-set-change-phone');

        this.isAlreadyOpenOTPVerificationPhoneNumber = true;
        this.phoneTmp = this.phone;
      })
      .catch(err => {
        this.errorSubmitNewPhoneNumber = true;
      });
    this.enableBtnNewPhone = true;
  }

  public togglePassword(): void {
    this.passwordInputType = this.passwordInputType === 'text' ? 'password' : 'text';
  }

  public inputOtp(e: string): void {
    this.phoneOtp = e;

    if (this.phoneOtp.length > 3) {
      this.pCbSetPhoneService()
        .postChangePhoneFinish({ newPhoneCode: this.phoneCode, newPhone: this.phone, otp: this.phoneOtp })
        .then(() => {
          const tmpCbUserInfo: ICbUserInfo = Object.assign({}, this.cbUserInfo);

          tmpCbUserInfo.phone = this.phone;
          tmpCbUserInfo.phoneCode = this.phoneCode;

          this.$root.$emit('setting::changeValue', 'cbUserInfo', tmpCbUserInfo);
          this.globalService().openGlobalDialog(this.$root, 'modal-c-success-change-phone-number');
          this.globalService().closeGlobalDialog(this.$root, 'modal-c-phone-verification');
        })
        .catch(() => {
          this.errorSubmitOtp = true;
        });
    }
  }

  public closePhoneNumberModal() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-new-phone-number');
    this.clearModelAccountUpdateProfile();

    if (this.isAlreadyOpenOTPVerificationPhoneNumber) {
      this.globalService().openGlobalDialog(this.$root, 'modal-c-phone-verification');
    }
  }

  public clearModelAccountUpdateProfile() {
    // clear
    this.$v.$reset();
    this.$v.phone.$model = '';
  }

  @Emit('saveOnlineLanguage')
  public saveOnlineLanguage(): void {
    return;
  }

  @Emit('accountProfile')
  public accountProfile(): void {
    return;
  }

  @Emit('accountLanguage')
  public accountLanguage(): void {
    return;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  public get cbLovPhoneCodes(): ICbLov[] {
    return this.$store.getters[CbLovType.PHONE_CODE];
  }

  public get cbLovLanguages(): ICbLov[] {
    return this.$store.getters[CbLovType.LANGUAGE] ? this.$store.getters[CbLovType.LANGUAGE] : [];
  }

  public get currentLanguage(): string {
    return this.$store.getters.currentLanguage;
  }

  public get currentUser(): any {
    return this.$store.getters.currentUser;
  }

  public getLanguage(): string | undefined {
    const language = this.cbLovLanguages.find((x: ICbLov) => x.key === this.currentLanguage);
    if (language) {
      return this.currentLanguage == 'en' ? language.value : language.value2;
    } else {
      return '';
    }
  }

  @Watch('isMobile')
  public isMobileListener() {
    if (!this.isMobile) {
      this.$router.replace({ name: 'PSettings', query: { tab: TabSettingType.ACCOUNT } });
    }
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
