export enum CbNotifImageLabel {
  CB = 'CB',

  PARTNER = 'PARTNER',

  LIVE_CHAT = 'LIVE_CHAT',

  BUYER = 'BUYER',

  OTHER = 'OTHER',
}
