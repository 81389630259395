import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Cubic, gsap } from 'gsap/all';

@Component({})
export default class CPopupBottom extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public value: boolean;
  @Prop({ default: null }) public dataCyCustom: string;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.watchValue();
  }

  public cancel() {
    this.$emit('input', false);
    this.$emit('cancel')
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('value')
  public watchValue() {
    if (this.value) {
      gsap.fromTo(
        <any>this.$refs.cPopupBottom,
        {
          display: 'block',
          visibility: 'hidden',
        },
        {
          delay: 0.25,
          visibility: 'visible',
          duration: 0.25,
          ease: Cubic.easeIn,
        }
      );
      gsap.fromTo(
        <any>this.$refs.cPopupBottomContent,
        {
          scaleY: 0,
          bottom: '-100%',
        },
        {
          bottom: 0,
          delay: 0.1,
          scaleY: 1,
          duration: 0.25,
          ease: Cubic.easeIn,
        }
      );
    } else {
      gsap.to(<any>this.$refs.cPopupBottom, {
        delay: 0.1,
        display: 'none',
        visibility: 'hidden',
        ease: Cubic.easeIn,
        duration: 0.25,
      });
      gsap.fromTo(
        <any>this.$refs.cPopupBottomContent,
        {
          ease: Cubic.easeIn,
          duration: 0.25,
          scaleY: 0,
          bottom: 0,
        },
        {
          bottom: '-100%',
        }
      );
    }
  }
  // ================= END LISTENERS ===================
}
