var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-linked-account",
      class: _vm.isMobile ? "navbar-distance m-3" : "",
    },
    [
      !_vm.isMobile
        ? _c("p", {
            staticClass:
              "font-size-24 cj-color-black-primary font-roboto font-weight-700 pb-3",
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.linkAccount")),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center wrap-border-grey-tertiary rounded p-3 mb-3",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex mr-3" },
            [_c("icon", { attrs: { name: "ic_google", width: "1.625rem" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "flex-grow-1 font-size-18 cj-color-black-primary font-roboto font-weight-500",
            },
            [_vm._v("Google")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-2 cursor-pointer" }, [
            _vm.linkedAccountGoogle && _vm.linkedAccountGoogle.userId
              ? _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-color-grey-secondary"
                  }`,
                  attrs: {
                    "data-gtm": "linked-google-button",
                    "data-cy": "unlink-google",
                    disabled:
                      _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.unlinkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showModal("google")
                    },
                  },
                })
              : _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-btn-text-primary"
                  }`,
                  attrs: {
                    "data-cy": "link-google",
                    "data-gtm": "linked-google-button",
                    disabled:
                      _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.linkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.loginGoogle()
                    },
                  },
                }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center wrap-border-grey-tertiary rounded p-3 mb-3",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex mr-3" },
            [_c("icon", { attrs: { name: "ic_facebook", width: "1.625rem" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "flex-grow-1 font-size-18 cj-color-black-primary font-roboto font-weight-500",
            },
            [_vm._v("Facebook")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-2 cursor-pointer" }, [
            _vm.linkedAccountFacebook && _vm.linkedAccountFacebook.userId
              ? _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-color-grey-secondary"
                  }`,
                  attrs: {
                    "data-gtm": "linked-facebook-button",
                    "data-cy": "unlink-facebook",
                    disabled:
                      _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.unlinkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showModal("facebook")
                    },
                  },
                })
              : _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-btn-text-primary"
                  }`,
                  attrs: {
                    "data-gtm": "linked-facebook-button",
                    "data-cy": "link-facebook",
                    disabled:
                      _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.linkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.loginFacebook()
                    },
                  },
                }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center wrap-border-grey-tertiary rounded p-3 mb-3",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex mr-3" },
            [_c("icon", { attrs: { name: "ic_linkedin", width: "1.625rem" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "flex-grow-1 font-size-18 cj-color-black-primary font-roboto font-weight-500",
            },
            [_vm._v("LinkedIn")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-2 cursor-pointer" }, [
            _vm.linkedAccountLinkedin && _vm.linkedAccountLinkedin.userId
              ? _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-color-grey-secondary"
                  }`,
                  attrs: {
                    "data-cy": "unlink-linkedin",
                    "data-gtm": "linked-linkedin-button",
                    disabled:
                      _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.unlinkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showModal("linkedin")
                    },
                  },
                })
              : _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-btn-text-primary"
                  }`,
                  attrs: {
                    "data-cy": "link-linkedin",
                    "data-gtm": "linked-linkedin-button",
                    disabled:
                      _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.linkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.loginLinkedin()
                    },
                  },
                }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center wrap-border-grey-tertiary rounded p-3 mb-3",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex mr-3" },
            [_c("icon", { attrs: { name: "ic_apple", width: "1.625rem" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "flex-grow-1 font-size-18 cj-color-black-primary font-roboto font-weight-500",
            },
            [_vm._v("Apple")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-2 cursor-pointer" }, [
            _vm.linkedAccountApple && _vm.linkedAccountApple.userId
              ? _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-color-grey-secondary"
                  }`,
                  attrs: {
                    "data-cy": "unlink-apple",
                    "data-gtm": "linked-apple-button",
                    disabled:
                      _vm.isDeleteLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.unlinkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showModal("apple")
                    },
                  },
                })
              : _c("p", {
                  staticClass: "font-size-18 font-roboto font-weight-500",
                  class: `${
                    _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount
                      ? "cj-color-grey-tertiary"
                      : "cj-btn-text-primary"
                  }`,
                  attrs: {
                    "data-cy": "link-apple",
                    "data-gtm": "linked-apple-button",
                    disabled:
                      _vm.isSavingLinkedAccount || _vm.isFetchingLinkedAccount,
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.linkAccount")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.loginApple()
                    },
                  },
                }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-c-confirmation-unlinked-account",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    dataGTMCustom: "unlink-account",
                    title: _vm.$t("cbgwApp.cbSetting.unlinkAccount"),
                    subTitle: _vm.$t("cbgwApp.cbSetting.descUnlinkAccount"),
                    cancelTitle: _vm.$t("cbgwApp.cbComponent.cancel"),
                    submitTitle: _vm.$t("cbgwApp.cbSetting.continue"),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: cancel,
                    close: cancel,
                    save: function ($event) {
                      return _vm.deleteLinkedAccount(_vm.unlinkProvider)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }