import { ICmTkt } from '@/shared/model/cm-tkt.model';

export interface ICmTktSender {
  id?: number;
  senderName?: string | null;
  senderId?: string | null;
  senderStatus?: string | null;
  senderPhone?: string | null;
  senderEmail?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  cmTkt?: ICmTkt | null;
}

export class CmTktSender implements ICmTktSender {
  constructor(
    public id?: number,
    public senderName?: string | null,
    public senderId?: string | null,
    public senderStatus?: string | null,
    public senderPhone?: string | null,
    public senderEmail?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public cmTkt?: ICmTkt | null
  ) {}
}
