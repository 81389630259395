export interface ICbLov {
  id?: number;
  name?: string;
  key?: string;
  value?: string;
  value2?: string | null;
  description?: string | null;
  seq?: number | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean;
  parentKey?: string;
  colorType?: string; //transient
}

export class CbLov implements ICbLov {
  constructor(
    public id?: number,
    public name?: string,
    public key?: string,
    public value?: string,
    public value2?: string | null,
    public description?: string | null,
    public seq?: number | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean,
    parentKey?: string,
    public colorType?: string | null, //transient
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
