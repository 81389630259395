import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IChtMessage } from '@/shared/model/cht-message.model';
import { ChtConversation, IChtConversation } from '@/shared/model/cht-conversation.model';
import { ChtAggConversationMember, IChtAggConversationMember } from '@/shared/model/cht-agg-conversation-member.model';
import { ChtVwConversation } from '@/shared/model/cht-vw-conversation.model';
import { ChtVwConversationCount } from '@/shared/model/cht-vw-conversation-count.model';
import { ChtConversationType } from '@/shared/model/enumerations/cht-conversation-type.model';

import { CHT_APP_SELECTED } from '@/constants';
import { IChtReaction } from '@/shared/model/cht-reaction.model';
import { IChtLiveAgentQuestion } from '@/shared/model/cht-live-agent-question.model';
import { App } from '@/shared/model/enumerations/app.model';

const baseApiChtUrl = 'services/btichat/api';
const baseApiReactionUrl = 'services/btichat/api/cht-reactions';
const userApiUrl = 'services/cbuserms/api/cb-vw-user-cores';
const userApiUrlV2 = 'services/cbuserms/api';

export default class PChtService {
  public createConversation(userId: string): Promise<IChtConversation> {
    const conversation: IChtConversation = new ChtConversation();
    const aggConversationMember: IChtAggConversationMember = new ChtAggConversationMember();
    conversation.app = CHT_APP_SELECTED;
    conversation.conversationType = ChtConversationType.PERSONAL;

    aggConversationMember.userId = userId;
    if (!conversation.chtAggConversationMembers) conversation.chtAggConversationMembers = [];
    conversation.chtAggConversationMembers.push(aggConversationMember);

    return new Promise<IChtConversation>((resolve, reject) => {
      axios.post(`${baseApiChtUrl}/cht-conversations/create-personal/by-id`, conversation).then(res => {
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject(false);
        }
      });
    });
  }
  // public frameCreateConversation(userId: string): Promise<IChtConversation> {
  //   let conversation: IChtConversation = new ChtConversation();
  //   let aggConversationMember: IChtAggConversationMember = new ChtAggConversationMember();
  //   // let aggConversationMember: IChtAggConversationMember = new ChtAggConversationMember();
  //   conversation.app = CHT_APP_SELECTED;
  //   conversation.conversationType = ChtConversationType.PERSONAL;

  //   aggConversationMember.userId = userId;
  //   if (!conversation.chtAggConversationMembers) conversation.chtAggConversationMembers = [];
  //   conversation.chtAggConversationMembers.push(aggConversationMember);

  //   return new Promise<IChtConversation>((resolve, reject) => {
  //     axios
  //       .post(`${baseApiChtUrl}/cht-conversations/create-personal/by-id`, conversation)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  public updateConversation(entity: IChtConversation): Promise<IChtConversation> {
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .put(`${baseApiChtUrl}/cht-conversations/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageByConversationId(conversationId: number, paginationQuery?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiChtUrl +
            `/cht-vw-messages/by-login/${conversationId}` +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`,
          { cancelToken }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageByLogin(params?: any, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/by-login` + `?${params}${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageById(messageId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/by-id/${messageId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createMessage(entity: IChtMessage): Promise<IChtMessage> {
    entity.app = CHT_APP_SELECTED;

    console.log('sd', entity);
    return new Promise<IChtMessage>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-messages`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveConversationCountByLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversation-counts/by-app/' + App.CB_APP)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public findConversationCount(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/count', { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findChtVwConversationCount(conversationId): Promise<ChtVwConversationCount> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversation-counts/one/' + conversationId + '/' + CHT_APP_SELECTED)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findChtVwConversation(conversationId): Promise<ChtVwConversation> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/one/' + conversationId + '/' + CHT_APP_SELECTED)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public frameFindChtVwConversation(conversationId): Promise<ChtVwConversation> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/one/' + conversationId + '/' + CHT_APP_SELECTED)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public setLatestViewConversation(conversation: IChtConversation): Promise<ChtAggConversationMember> {
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-conversations/set-latest-view`, conversation)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveContact(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-agg-contacts', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveStory(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-stories' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveConversationLiveAgentNoLogin(liveAgentKey?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + '/cht-vw-conversations/live-agent/no-login/' + CHT_APP_SELECTED + '/' + liveAgentKey)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageBymessageIdAndKey(messageId: number, key: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/live-agent/no-login/by-id/${messageId}/${key}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageByConversationIdAndKey(conversationId: number, key: string, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiChtUrl + `/cht-vw-messages/live-agent/no-login/${conversationId}/${key}` + `?${buildPaginationQueryOpts(paginationQuery)}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createMessageLiveAgentNoLogin(key, entity: IChtMessage): Promise<IChtMessage> {
    entity.app = CHT_APP_SELECTED;
    return new Promise<IChtMessage>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-messages/live-agent/no-login/${key}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveConversationByLogin(paginationQuery?: any, params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiChtUrl +
            '/cht-vw-conversations/by-login/' +
            CHT_APP_SELECTED +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`,
          { params, cancelToken }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveTotalCountUnread(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-conversation-counts/by-app/${CHT_APP_SELECTED}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteReaction(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiReactionUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createReaction(entity: IChtReaction): Promise<IChtReaction> {
    return new Promise<IChtReaction>((resolve, reject) => {
      axios
        .post(`${baseApiReactionUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public isTokenValid(token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/is-token-valid/${token}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveMessageStars(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiChtUrl + `/cht-vw-messages/star/by-login` + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveVwUser(params?: any, cancelToken?: any): Promise<any> {
    if (params.members) {
      const members: string[] = params.members.replace(/\s/g, '').split('||');
      params['id.in'] = members.filter(this.onlyUniqueIds);
      delete params.members;
    }
    if (!params.page) {
      params['page'] = 0;
    }
    if (!params.size) {
      params['size'] = 500;
    }
    return new Promise<any>((resolve, reject) => {
      axios
        .get(userApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public pinned(roomId, negate): Promise<ChtAggConversationMember> {
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-conversations/pinned/${roomId}/${negate}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createConversationLiveAgentNoLogin(chtLiveAgentQuestion: IChtLiveAgentQuestion): Promise<IChtConversation> {
    return new Promise<IChtConversation>((resolve, reject) => {
      axios
        .post(`${baseApiChtUrl}/cht-conversations/live-agent/create-personal/no-login`, chtLiveAgentQuestion)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveMessageByConversationIdMoreFilter(conversationId: number, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiChtUrl +
            `/cht-vw-messages/by-login/${conversationId}` +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private onlyUniqueIds(value, index, self) {
    return self.indexOf(value) === index;
  }

  public partialUpdateLiveChatByToken(token: string, id: number): Promise<any> {
    const chtConversation = new ChtConversation();
    chtConversation.id = id;
    chtConversation.token = token;
    return new Promise<any>((resolve, reject) => {
      axios
        .patch(`${baseApiChtUrl}/cht-conversations/live-chat/by-token-id/${id}`, chtConversation)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbVwChtAggConversationMembers(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(userApiUrlV2 + `/cb-vw-cht-agg-conversation-members?`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
