import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SvgIcon from '../../../../components/SvgIcon/SvgIcon.vue';
import CIconFile from '../../../c-icon-file/c-icon-file.vue';
@Component({
  components: { SvgIcon, CIconFile },
})
export default class CRmediaFiles extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop()
  public chtVwMedia;
  @Prop()
  public idx;

  @Prop({ default: [] })
  public selectedMessageIds;

  public selectedMessageIdsAvoidMutated = [];

  @Watch('selectedMessageIds')
  public onListen() {
    this.selectedMessageIdsAvoidMutated = this.selectedMessageIds;
  }
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return '0 Bytes';
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  public get getFileSize() {
    let size = this.chtVwMedia.mediaSize;
    return this.formatFileSize(size, 2);
  }

  public get getGroupDate() {
    const then = new Date(this.chtVwMedia.createdDate);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    if (hoursBetweenDates < 24) {
      return this.chtVwMedia.groupTime;
    } else {
      return this.chtVwMedia.groupDate;
    }
  }
  public get file() {
    let obj = { type: this.chtVwMedia.attContentType };
    return obj;
  }

  // ================= END COMPUTE ====================
}
