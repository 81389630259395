import { CbVwStItemPkg } from '@/shared/model/cb-vw-st-item-pkg.model';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import roomSettingUser from './RoomsList/room-setting-user/room-setting-user.vue';

@Component({
  components: { roomSettingUser },
})
export default class ChatWindowController extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null })
  public isDevice;
  public isShowOptionMenu = 'ROOMLIST';
  @Prop({ default: null })
  public cbStickers: CbVwStItemPkg[];
  public isMobile;
  public isShowHeaderMsg;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleShowOption(menu) {
    this.isShowOptionMenu = menu;
  }
  public closeShowOption() {
    console.log(this.isShowOptionMenu, 'this.isShowOptionMenu');
    this.isShowOptionMenu = 'ROOMLIST';
  }

  public downloadFile(value) {
    window.open(value.url, '_blank');
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  @Watch('isMobile')
  public callBackWindow() {
    if (!this.isMobile) {
      this.closeShowOption();
    }
  }
  // ================= END COMPUTE ====================
}
