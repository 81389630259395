var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-create-livechat p-3" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between pb-3 cs-container" },
        [
          _c("div", { staticClass: "cs-wrapper" }, [
            _c("div", { staticClass: "cs-logo-wrapper" }, [
              _c("img", {
                staticClass: "img-size",
                attrs: {
                  src: `${_vm.$env.CDN_CB}/content/cb-img/ic-logo-black.png`,
                  alt: "Image Customer Support",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "font-roboto cj-color-black-primary font-weight-500",
                class: _vm.isMobile ? "font-size-10" : "font-size-18",
              },
              [_vm._v("\n        Customer Support\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "cursor-pointer p-0 m-0",
                attrs: {
                  "data-cy": "close-floating-live-chat",
                  type: "button",
                },
                on: { click: _vm.callBack },
              },
              [
                _c("icon", {
                  staticClass:
                    "my-auto cj-fill-black-primary ic_arrow_dropdown",
                  attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "help-center-logo-container",
            class: {
              "vac-d-none-web": !_vm.isMobile && !_vm.userLogin,
            },
          },
          [
            _c("div", { staticClass: "help-center-logo-wrapper" }, [
              _c("img", {
                staticClass: "img-size",
                attrs: {
                  src: `${_vm.$env.CDN_CB}/content/cb-img/ic-livechat-cs-hi.png`,
                  alt: "img",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "my-3" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-red-primary px-3 font-size-24 font-weight-700 mb-2 text-center",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.CbLivechat.howCanWeHelp")
                    ),
                  },
                },
                [_vm._v("\n          How Can We Help?\n        ")]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        !_vm.userLogin
          ? _c("div", { staticClass: "vac-d-show-web" }, [
              _c("div", { staticClass: "mb-3 help-center-logo-wrapper-v2" }, [
                _c("img", {
                  staticClass: "img-size",
                  attrs: {
                    src: `${_vm.$env.CDN_CB}/content/cb-img/${
                      _vm.currentLanguage == "en"
                        ? "ic-livechat-cs-text-en.png"
                        : "ic-livechat-cs-text-id.png"
                    }`,
                    alt: "img",
                  },
                }),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.userLogin
        ? [
            _c("div", { staticClass: "custom-textarea" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.tkt.question.$model,
                    expression: "$v.tkt.question.$model",
                  },
                ],
                staticClass: "rounded cj-field-border-default",
                class: `${
                  _vm.$v.tkt.question.$anyDirty
                    ? _vm.$v.tkt.question.$invalid
                      ? "invalid-textarea"
                      : "valid-textarea"
                    : ""
                }`,
                staticStyle: { width: "100% !important" },
                attrs: {
                  "data-cy": "floating-live-chat-description",
                  placeholder: "",
                  rows: "7",
                },
                domProps: { value: _vm.$v.tkt.question.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.$v.tkt.question, "$model", $event.target.value)
                  },
                },
              }),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.userLogin
        ? _c("div", [
            _c("div", {}, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.tkt.email.$model,
                    expression: "$v.tkt.email.$model",
                  },
                ],
                staticClass: "mb-3 cj-field-input",
                class: `${
                  _vm.$v.tkt.email.$anyDirty
                    ? _vm.$v.tkt.email.$invalid
                      ? "invalid"
                      : "valid"
                    : ""
                }`,
                attrs: {
                  "data-cy": "floating-live-chat-email",
                  placeholder: "Email",
                  type: "text",
                },
                domProps: { value: _vm.$v.tkt.email.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.$v.tkt.email, "$model", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex mb-3 lvc-custom-phone-container cj-field-border-default",
                  class: `${
                    _vm.$v.tkt.phoneCode.$anyDirty || _vm.$v.tkt.phone.$anyDirty
                      ? _vm.$v.tkt.phoneCode.$invalid ||
                        _vm.$v.tkt.phone.$invalid
                        ? "invalid"
                        : "valid"
                      : ""
                  }`,
                  attrs: { "data-cy": "floating-live-custom-phone-container" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "lvc-custom-select position-relative" },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.tkt.phoneCode.$model,
                              expression: "$v.tkt.phoneCode.$model",
                            },
                          ],
                          attrs: { "data-cy": "floating-live-chat-phone-code" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.$v.tkt.phoneCode,
                                "$model",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "", selected: "" },
                              domProps: { value: null },
                            },
                            [
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("cbgwApp.cbRequestRefund.select")
                                    ),
                                  },
                                },
                                [_vm._v("Select")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "+62" } }, [
                            _vm._v("ID +62"),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "lvc-custom-input w-100" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.tkt.phone.$model,
                          expression: "$v.tkt.phone.$model",
                        },
                      ],
                      attrs: {
                        "data-cy": "floating-live-chat-phone-number",
                        placeholder: _vm.$t("cbgwApp.CbLivechat.phoneNumber"),
                        type: "number",
                      },
                      domProps: { value: _vm.$v.tkt.phone.$model },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$v.tkt.phone,
                            "$model",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "custom-textarea" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.tkt.question.$model,
                      expression: "$v.tkt.question.$model",
                    },
                  ],
                  staticClass: "rounded cj-field-border-default",
                  class: `${
                    _vm.$v.tkt.question.$anyDirty
                      ? _vm.$v.tkt.question.$invalid
                        ? "invalid-textarea"
                        : "valid-textarea"
                      : ""
                  }`,
                  staticStyle: { width: "100% !important" },
                  attrs: {
                    placeholder: "",
                    rows: "7",
                    "data-cy": "floating-live-chat-description",
                  },
                  domProps: { value: _vm.$v.tkt.question.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.tkt.question,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column pt-3 mx-5 lvc-btn-wrapper" },
        [
          _c(
            "c-button",
            {
              staticClass: "font-roboto font-size-20 font-weight-700",
              attrs: {
                "data-cy": "floating-live-chat-submit",
                disabled: _vm.userLogin
                  ? _vm.$v.tkt.question.$invalid
                  : _vm.$v.tkt.$invalid,
              },
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.CbLivechat.submit")),
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("createConversation")
                },
              },
            },
            [_vm._v("\n      SUBMIT")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }