import { ICbLinkedinRequest } from '@/shared/model/cb-linkedin-request.model';
import { ICbKeyAndPasswordVm, ICbLinkedAccount, ICbOid, ICbSetPassword } from '@/shared/model/cb-oids.model';
import { ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { CbVerificationMethodType } from '@/shared/model/enumerations/cb-verification-method-type.model';
import { User } from '@/shared/model/user.model';
import axios, { CancelToken } from 'axios';

const baseApiUrl = 'services/cbuserms/api/account';

export default class PCbAccountService {
  public setPasswordInit(): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/set-password/init`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public setPasswordFinish(entity: ICbSetPassword, cancelToken?: CancelToken): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/set-password/finish`, entity, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getLinkedAccounts(): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/linked-accounts/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public addLinkedAccount(entity: ICbLinkedAccount): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/add/linked-accounts`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public removeLinkedAccount(identityProvider: string): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/remove/linked-accounts/${identityProvider}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public addLinkedAccountLinkedin(entity: ICbLinkedinRequest): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/add-linkedin-provider/linked-accounts`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deactiveAccountByLogin(entity: ICbUserInfo): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/deactivate-account/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteAccountByLogin(entity: ICbUserInfo): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/delete-account/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public raActiveAccountByLogin(): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/reactive-account/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public setPasswordCbRegFinish(verificationOpt: CbVerificationMethodType, entity: ICbKeyAndPasswordVm): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/cb-reg-set-password/${verificationOpt.toLowerCase()}/finish`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public setEmailIfEmailDoesNotExist(user: User): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/set-email/init`, user)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
