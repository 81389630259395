import { Component, Prop, Mixins } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import { v4 as uuidv4 } from 'uuid';
import FormUtils from '@/shared/utils/form-utils.service';

@Component({
  components: {
    Icon,
  },
  watch: {
    tmpValue: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.maxlength) {
          const max = this.maxlength;
          const total = newVal?.length ? newVal.length : 0;
          if (total > max) {
            this.textareaRef().value = oldVal;
            this.onChangeInput();
          }

          this.validatePasteMaxLengthUtils(max, total);
        }
      },
    },
  },
})
export default class CTextareaNew extends Mixins(FormUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: '' })
  public value;
  @Prop({ default: '' })
  public placeholder;
  @Prop({ default: 6 })
  public rows;
  @Prop({ default: 6 })
  public maxRows;
  @Prop({ default: 140 })
  public maxlength;
  @Prop({ default: false }) public hideInfo: boolean;
  @Prop({ default: null }) public dataCyCustom: string;
  @Prop({ default: null }) public dataGTMCustom: string;
  public uniqueId: string = '';

  public tmpValue: string = '';
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.uniqueId = uuidv4();
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public onChangeInput() {
    if (this.textareaRef()?.value || this.textareaRef()?.value === '') {
      this.$emit('input', this.textareaRef()?.value);
      this.tmpValue = this.textareaRef()?.value;
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public textareaRef() {
    return <any>this.$refs.cTextareaNew;
  }
  public get valueLength() {
    let length = 0;
    if (this.value?.length > 0 && this.maxlength) {
      length = this.value.length;
    }
    return length;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
