import { Inject, Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import CInputOtp from '@/components/c-input-otp/c-input-otp.vue';
import PCbOidService from '@/services/cb-oid.service';
import { CbVerificationMethodType } from '@/shared/model/enumerations/cb-verification-method-type.model';
import PCbUserPinService from '@/services/cb-user-pin.service';
import { CbVerificationOtp, ICbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import { SetPinType } from '@/shared/model/enumerations/set-pin-type.model';
import AlertService from '@/shared/alert/alert.service';
import axios, { CancelTokenSource } from 'axios';

@Component({ components: { CTimeCountdown, CInputOtp } })
export default class CVerificationPin extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;
  @Inject('pCbUserPinService') public pCbUserPinService: () => PCbUserPinService;
  @Inject('alertService') private alertService: () => AlertService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false, type: Boolean }) public visibleHeader: boolean;
  @Prop({ default: null }) public value: CbVerificationOtp;
  public methodeVerification: CbVerificationMethodType;
  public visibleLoading: boolean = false;
  public showCountdown: boolean = false;
  public dateCountdown: Date = dayjs().toDate();
  public inputOtp: string = '';
  public invalidOtp: boolean = false;
  public setPinType = SetPinType.CHANGE_PIN;
  public isResend: boolean = false;
  public isFetching: boolean = false;
  public cancelToken: CancelTokenSource = null;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.showCountdown = true;
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public changeVerification() {
    (<any>this.$root).$emit('changePhoneVerification', this.value);
  }

  public resend() {
    if (this.showCountdown || this.isResend) {
      return;
    }
    this.isResend = true;
    this.pCbUserPinService()
      .postSetPin()
      .then(res => {
        (<any>this.$root).$bvToast.toast('Resend Verification Success', {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'success',
          solid: true,
          autoHideDelay: 3000,
        });
        if (res.id && res.id > 0 && res.detail) {
          let tmpCbOtp: ICbVerificationOtp = Object.assign({}, res.detail);
          tmpCbOtp.setPinType = this.value.setPinType;
          this.$emit('input', tmpCbOtp);
        }
        this.showCountdown = true;
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isResend = false;
      });
  }

  public handleInputOtp(isChanged?: boolean) {
    if (this.isFetching && !isChanged) return;
    if (this.inputOtp.length > 0) {
      this.inputOtp = this.inputOtp.toUpperCase();
    }
    if (this.inputOtp.length == 4) {
      this.visibleLoading = true;
      this.isFetching = true;
      this.invalidOtp = false;
      if (this.value.setPinType == SetPinType.SET_PIN) {
        this.valueOtp(this.inputOtp.toUpperCase());
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        this.pCbUserPinService()
          .postCheckOtp(
            {
              pinActivationCode: this.inputOtp,
            },
            this.cancelToken
          )
          .then(res => {
            this.setPinType = SetPinType.SET_PIN;
            this.$emit('submit', this.setPinType);
            this.$root.$emit('app::changeValue', 'visibleSetNewPin', true);
          })
          .catch(err => {
            if (!axios.isCancel(err)) {
              this.invalidOtp = true;
            }
          })
          .finally(() => {
            this.visibleLoading = true;
            this.isFetching = false;
          });
      } else if (this.value.setPinType == SetPinType.RESET_PIN) {
        this.valueOtp(this.inputOtp);
        // fungsi cek validasi otp benar atau salah
        if (this.cancelToken) {
          this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        this.pCbUserPinService()
          .postCheckResetOtp(
            {
              pinActivationCode: this.inputOtp,
            },
            this.cancelToken
          )
          .then(res => {
            this.setPinType = SetPinType.RESET_PIN;
            this.$emit('submit', this.setPinType);
            this.$root.$emit('app::changeValue', 'visibleSetNewPin', true);
          })
          .catch(err => {
            if (!axios.isCancel(err)) {
              this.invalidOtp = true;
            }
          })
          .finally(() => {
            this.visibleLoading = true;
            this.isFetching = false;
          });
      }
    }
  }
  @Emit('valueOtp')
  public valueOtp(valueOtp) {
    return valueOtp;
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('inputOtp.length')
  public inputOtpLength(newVal: number, oldVal: number) {
    if (newVal != oldVal && this.isFetching) {
      this.handleInputOtp(true);
    }
  }

  // ================= END LISTENERS ==================
}
