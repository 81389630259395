import CUploadFileIcon from '@/components/c-upload-file/c-upload-file-icon/c-upload-file-icon.vue';
import Icon from '@/shared/icons/icon.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {Icon, CUploadFileIcon},
})
export default class CPdReferenceItem extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({default:null})
  public item;
  @Prop({default:false})
  public isShowDownload;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
