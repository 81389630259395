import Icon from '@/shared/icons/icon.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isAudioFile, isDocFile, isImageFile, isPdfFile, isPptFile, isVideoFile, isXlsFile } from '../../utils/media-file';

@Component({
  components: { Icon },
})
export default class CIconFile extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop()
  public file;
  @Prop({ default: '2rem' })
  public width;
  @Prop({ default: '2.5rem' })
  public height;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isImage() {
    return isImageFile(this.file);
  }

  public get isVideo() {
    return isVideoFile(this.file);
  }
  public get isPdf() {
    return isPdfFile(this.file);
  }
  public get isAudio() {
    return isAudioFile(this.file);
  }
  public get isDoc() {
    return isDocFile(this.file);
  }
  public get isXls() {
    return isXlsFile(this.file);
  }
  public get isPpt() {
    return isPptFile(this.file);
  }
  // ================= END COMPUTE ====================
}
