import { IChtVwMedia } from '@/shared/model/cht-vw-media.model';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CRmediaGroupdate extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop()
  public chtVwMedias: IChtVwMedia[];
  @Prop()
  public chtVwMedia;
  @Prop()
  public index;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get showGroupDate() {
    if (this.index == 0) {
      return this.chtVwMedia.groupMonth || this.chtVwMedia.groupYear;
    } else {
      return (
        this.chtVwMedia.groupMonth !== this.chtVwMedias[this.index - 1].groupMonth ||
        this.chtVwMedia.groupYear !== this.chtVwMedias[this.index - 1].groupYear
      );
    }
  }

  public get getMonthBetweenYear() {
    const then = new Date(this.chtVwMedia.createdDate);
    const now = new Date();
    const months = (now.getFullYear() - then.getFullYear()) * 12;

    if (months < 12) {
      return this.chtVwMedia.groupMonth;
    } else {
      return this.chtVwMedia.groupYear;
    }
  }

  // ================= END COMPUTE ====================
}
