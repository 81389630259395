<template>
  <div
    :class="{
      'vac-box-search': showSearchBar,
      'vac-box-empty': !showSearchBar,
    }"
  >
    <template v-if="!isShowSearch">
      <slot name="room-bar-chat">
        <room-bar-chat
          @handle-show-search="$emit('handle-show-search')"
          @handle-show-setting-user="$emit('handle-show-setting-user')"
        ></room-bar-chat>
      </slot>
    </template>
    <template v-if="showSearch && isShowSearch">
      <div class="d-flex w-100" id="search-room-chat-wrap">
        <div
          @click="
            $emit('search-room', { target: { value: '' } });
            $emit('handle-show-search');
          "
          class="p-3 my-auto cursor-pointer"
        >
          <font-awesome-icon icon="fa-solid fa-arrow-left" class="cb-icon-size-arrow-left cj-color-black-primary" />
        </div>
        <div class="search-container my-auto w-100 mr-3">
          <icon name="ic_search" class="cj-stroke-red-primary mr-2 ic_search" width="1.125rem" height="100%" />
          <input
            data-cy="search-room-chat"
            ref="focusSearcList"
            type="text"
            class="rounded-pill cj-field-input"
            :placeholder="$t('chat.search')"
            autocomplete="off"
            @input="$emit('search-room', $event)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import roomBarChat from '../room-bar/room-bar.vue';
import Icon from '@/shared/icons/icon.vue';

export default {
  name: 'RoomsSearch',
  components: {
    SvgIcon,
    roomBarChat,
    Icon,
  },

  props: {
    textMessages: {
      type: Object,
      required: true,
    },
    showSearch: {
      type: Boolean,
      required: true,
    },
    showAddRoom: {
      type: Boolean,
      required: true,
    },
    rooms: {
      type: Array,
      required: true,
    },
    loadingRooms: {
      type: Boolean,
      required: true,
    },
    isShowSearch: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['search-room', 'add-room', 'handle-show-search'],

  computed: {
    showSearchBar() {
      return this.showSearch || this.showAddRoom;
    },
  },
};
</script>
