import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import CItemTimeRegion from '@/components/c-item-time-region/c-item-time-region.vue';
import Icon from '@/shared/icons/icon.vue';
import PCbUserTimezoneService from '@/services/cb-user-timezone.service';
import AlertService from '@/shared/alert/alert.service';
import { CbUserTimezone, ICbUserTimezone } from '@/shared/model/cb-user-timezone.model';
import { ICbLov } from '@/shared/model/cb-lov.model';
import dayjs from 'dayjs';
import { DATE_TIME_LONG_FORMAT } from '@/shared/date/filters';
import CAutocompleteNew from '@/components/c-autocomplete-new/c-autocomplete-new.vue';
import CItemTimeRegionContainer from '../c-item-time-region-container/c-item-time-region-container.vue';
import ContentService from '@/services/content.service';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';

@Component({
  components: {
    CItemTimeRegion,
    Icon,
    CAutocompleteNew,
    CItemTimeRegionContainer,
  },
})
export default class CTimeRegionMUpdate extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbUserTimezoneService') private pCbUserTimezoneService: () => PCbUserTimezoneService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('contentService') private contentService: () => ContentService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public timeUtcServer: Date;
  @Prop({ default: false }) public leftArrow: boolean;
  public countdownInterval: any = null;

  public isSaving = false;

  public isShowAutocomplete = false;
  
  public dummyTimes = [
    {
      title: 'utc',
    },
    {
      title: 'cuctom',
    },
  ];

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.contentService().initRetrieveCbLovs(CbLovType.TIME_ZONE);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public selectResultCbLovTimeZone(key: string) {
    if (key) {
      const find = this.TIME_ZONE.find(e => e.key == key);
      if (find) {
        const foundIndex = this.cbUserTimezones.findIndex(tz => tz.timeZoneCode == find.key && tz.timeZoneName == find.key);
        if (foundIndex < 0) {
          this.save(find);
        }
      }
    }
  }

  public save(cbUserTimezone) {
    this.isSaving = true;
    const cbTimezone: CbUserTimezone = {
      userId: this.currentUser.id,
      timeZoneName: cbUserTimezone.value,
      timeZoneCode: cbUserTimezone.key,
    };
    const foundIndex = this.cbUserTimezones.findIndex(zone => zone.timeZoneCode != 'UTC' && zone.isActive);
    if (foundIndex < 0) {
      cbTimezone.isActive = true;
    }

    this.pCbUserTimezoneService()
      .createByLogin(cbTimezone)
      .then(res => {
        res.userZoneDateTime = dayjs(res.userZoneDateTime, DATE_TIME_LONG_FORMAT).toDate();
        res.timeZoneName = res.timeZoneName.replace(res.timeZoneCode, '').replace('(', '').replace(')', '');
        res.dateFormat = res.userZoneDateTime.toLocaleString('en-US', { dateStyle: 'medium' });
        res.timeFormat = `${res.userZoneDateTime.toLocaleString('en-US', { hour: '2-digit', hour12: false, minute: '2-digit' })}`;

        this.handleAddTimeZone(res);
        (<any>this.$refs.autocompleteNewRef).handleClear();
        this.isSaving = false;
      })
      .catch(() => {
        this.isSaving = false;
      });
  }

  public handleSetDefaultTimeZone(checked, cbUserTimezone: ICbUserTimezone) {
    for (const cbUserTimezoneIter of this.cbUserTimezones) {
      if (cbUserTimezoneIter.timeZoneCode != 'UTC') cbUserTimezoneIter.isActive = false;
    }

    if (checked) {
      const foundIndex = this.cbUserTimezones.findIndex(time => time.id == cbUserTimezone.id);

      if (foundIndex >= 0) {
        const tmpUserTimezone = Object.assign({}, this.cbUserTimezones[foundIndex]);
        tmpUserTimezone.isActive = true;
        Vue.set(this.cbUserTimezones, foundIndex, tmpUserTimezone);

        this.pCbUserTimezoneService()
          .partialUpdate(this.cbUserTimezones[foundIndex])
          .then(res => {
            this.$store.commit('setCbUserTimeZones', this.cbUserTimezones);
          });
      }
    }
  }

  public deleteTimeRegion(timeZoneId: number) {
    this.pCbUserTimezoneService()
      .delete(timeZoneId)

      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      })
      .finally(() => {
        this.handleRemoveTimeZone(timeZoneId);
      });
  }

  public goBack() {
    this.$router.go(-1);
  }

  public handleAddTimeZone(cbUserTimeZone: ICbUserTimezone) {
    const cbUserTimeZonesStore: ICbUserTimezone[] = [...this.cbUserTimezones];

    const foundIndex = cbUserTimeZonesStore.findIndex(
      zone => zone.timeZoneCode == cbUserTimeZone.timeZoneCode && zone.timeZoneName == cbUserTimeZone.timeZoneName
    );

    if (foundIndex < 0) {
      cbUserTimeZonesStore.push(cbUserTimeZone);
    } else {
      Vue.set(cbUserTimeZonesStore, foundIndex, cbUserTimeZone);
    }
    this.$store.commit('setCbUserTimeZones', cbUserTimeZonesStore);
  }

  public handleRemoveTimeZone(timeZoneId: number) {
    const cbUserTimeZonesStore: ICbUserTimezone[] = [...this.cbUserTimezones];

    const foundIndex = cbUserTimeZonesStore.findIndex(e => e.id == timeZoneId);
    if (foundIndex >= 0) {
      Vue.delete(cbUserTimeZonesStore, foundIndex);
    }

    this.$store.commit('setCbUserTimeZones', cbUserTimeZonesStore);

    // ? cari timezone lain yang aktif, jika ga ada, update yang ada
    const foundActive = cbUserTimeZonesStore.findIndex(e => e.isActive && e.timeZoneCode != 'UTC');
    if (foundActive < 0) {
      const foundInActive = cbUserTimeZonesStore.findIndex(e => !e.isActive && e.timeZoneCode != 'UTC');
      if (foundInActive >= 0) {
        const tmpUserTimezone = Object.assign({}, cbUserTimeZonesStore[foundInActive]);
        this.handleSetDefaultTimeZone(true, tmpUserTimezone);
      }
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser() {
    return this.$store.getters.currentUser;
  }

  public get TIME_ZONE(): ICbLov[] {
    const localTimezone: ICbLov[] = [];
    for (const time of this.$store.getters.TIME_ZONE) {
      const found = this.cbUserTimezones.find(e => e.timeZoneCode.toUpperCase() == time.key.toUpperCase());
      if (!found) {
        localTimezone.push(time);
      }
    }
    return localTimezone;
  }
  public get cbUserTimezones(): ICbUserTimezone[] {
    return this.$store.getters.cbUserTimeZones;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================

  // ================= END LISTENERS ==================
}
