var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-time-region-m-update",
      class: { "h-auto": _vm.cbUserTimezones.length == 3 },
    },
    [
      _c("div", { staticClass: "d-flex bar-wrapper" }, [
        _vm.leftArrow
          ? _c(
              "div",
              {
                staticClass: "p-3 cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.$emit("closeModal")
                  },
                },
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "cb-icon-size-arrow-left cj-color-black-primary",
                  attrs: { icon: "fa-solid fa-arrow-left" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "pt-3 d-flex flex-column c-time-region-m-update-container",
          class: _vm.cbUserTimezones.length == 1 ? "index-web-time" : "",
        },
        [
          _vm._l(_vm.cbUserTimezones, function (cbUserTimezone, index) {
            return _c("c-item-time-region-container", {
              key: index,
              attrs: { parentCbUserTimeZone: cbUserTimezone, index: index },
              on: {
                deleteTimeRegion: _vm.deleteTimeRegion,
                handleSetDefaultTimeZone: _vm.handleSetDefaultTimeZone,
              },
            })
          }),
          _vm._v(" "),
          _vm.cbUserTimezones.length < 3
            ? _c("div", { staticClass: "pt-3 custom-search-time px-3" }, [
                _c(
                  "div",
                  [
                    _c("c-autocomplete-new", {
                      ref: "autocompleteNewRef",
                      staticClass: "rounded-pill cj-bg-white-primary",
                      attrs: {
                        items: _vm.TIME_ZONE,
                        "item-text": "value",
                        "item-value": "key",
                        placeholder: _vm.$t(
                          "cbgwApp.cbComponent.cTimezone.addTimezone"
                        ),
                      },
                      on: { input: _vm.selectResultCbLovTimeZone },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "custom-d-web" }, [
      _c(
        "p",
        {
          staticClass:
            "font-roboto font-weight-500 font-size-16 cj-color-black-primary",
        },
        [_vm._v("Settings UTC")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }