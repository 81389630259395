import { Component, Prop } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CButton from '../button/c-button.vue';
import CUploadFileIcon from './c-upload-file-icon/c-upload-file-icon.vue';
import { Flutter } from '@/app-flutter';
import { ICmAttachmentRestrict } from '@/shared/model/cm-attachment-restrict.model';
import { MAX_SIZE_10MB } from '@/shared/media-file/constants';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { mixins } from 'vue-class-component';
import { ICbAtt } from '@/shared/model/cb-att';

@Component({ components: { Icon, CButton, CUploadFileIcon } })
export default class CUploadFile extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop()
  public files: ICmAttachmentRestrict[];
  @Prop()
  public fileType: string;
  @Prop()
  public fileUrl: string;
  @Prop()
  public fileThumbnail: string;
  @Prop({ default: null })
  public accept: string;
  @Prop({ default: MAX_SIZE_10MB }) maxFile: number;

  @Prop({ default: null })
  public secondObj: string;
  @Prop()
  public textUploadCondition: string;
  @Prop({ default: 10 })
  public maxFileLength: number;
  @Prop({ default: true })
  @Prop({ default: null })
  public dataCyCustom: string;
  @Prop({ default: null })
  public isShowVideo: boolean;
  public isRunningOnFlutter: boolean = Flutter.isRunOn();

  public isDragging = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {}
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public fileChange(event: Event & { target: HTMLInputElement }): void {
    const files = Array.from(event.target.files);
    if (files.length == 0) return;
    this.setAttachment({
      files,
      maxSize: this.maxFile,
      allowedExtension: this.accept,
      onFileLoaded: this.onFileLoaded,
      skipThumbnail: false,
    });
  }

  private onFileLoaded(cbAtt: ICbAtt): void {
    this.$emit('onFileChange', cbAtt);
    (this.$refs.inputFile as HTMLInputElement).value = '';
  }

  public handleUploadFile(): void {
    this.openFileFlutter(
      {
        allowedExtensions: this.accept,
        fileMaxSizeInMb: this.maxFile,
        multiplePick: true,
        onFlutterNotRun: () => (this.$refs.inputFile as HTMLInputElement).click(),
      },
      this.onFileLoaded
    );
  }

  public openFileInput(): void {
    (this.$refs.inputFile as HTMLInputElement).click();
  }

  public handleDragEnter(event: Event): void {
    this.isDragging = true;
    event.preventDefault();
  }

  public handleDragLeave(): void {
    this.isDragging = false;
  }

  public handleDrop(event: Event & { dataTransfer: DataTransfer }): void {
    this.isDragging = false;
    event.preventDefault();
    const files: File[] = Array.from(event.dataTransfer.files);
    if (files.length == 0) return;
    this.setAttachment({
      files,
      maxSize: this.maxFile,
      allowedExtension: this.accept,
      onFileLoaded: this.onFileLoaded,
    });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
