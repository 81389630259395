var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.logoutLoader
    ? _c("div", { staticClass: "c-navbar-menu" }, [
        _c(
          "div",
          {
            staticClass:
              "height-navbar-m pr-3 d-flex border-grey-tertiary-bottom-1 justify-content-between",
          },
          [
            _c("div", { staticClass: "d-flex w-100" }, [
              _c(
                "div",
                {
                  staticClass: "p-3 cursor-pointer my-auto d-flex",
                  attrs: { "data-cy": "go-back" },
                  on: { click: _vm.goBack },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass:
                      "cb-icon-size-arrow-left cj-color-black-primary my-auto",
                    attrs: { icon: "fa-solid fa-arrow-left" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "flex-grow-1 cj-color-black-primary font-roboto font-size-18 font-weight-500 my-auto",
                },
                [_vm._v("Menu")]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.loginType !== _vm.navbarType.GENERAL
          ? _c("div", { staticClass: "pt-3" }, [
              _c("div", { staticClass: "rounded p-3 cj-bg-red-primary mx-3" }, [
                _c(
                  "div",
                  { staticClass: "d-flex w-100 nav-wrap" },
                  [
                    _c("c-user-photo-online", {
                      staticStyle: { "flex-shrink": "0" },
                      attrs: {
                        "data-cy": "photo-user",
                        width: 2.625,
                        url: _vm.$options.filters.LAZY(
                          `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                            _vm.currentUser ? _vm.currentUser.photoId : ""
                          }`,
                          42,
                          "ic-lazy-avatar.png"
                        ),
                        tabindex: "0",
                        isOnline: _vm.currentUser.isShowOnlineStatus == true,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "nav-title" },
                      [
                        _c("div", { staticClass: "my-auto ml-3" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto font-weight-500 font-size-10 cj-color-white-primary",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.loginType === _vm.navbarType.PARTNER
                                      ? "Freelancer"
                                      : "Buyer"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto font-weight-700 font-size-14 cj-color-white-primary text-name",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("sliceCharacter")(
                                      _vm.currentUser &&
                                        _vm.currentUser.displayName
                                        ? _vm.currentUser.displayName
                                        : "-",
                                      16
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("c-navbar-switch-logintype", {
                          staticClass: "mt-responsive",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("c-time-region-m", {
                      attrs: { timeUtcDisplay: _vm.timeUtcDisplay },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-roboto cj-color-black-primary mb-2 mt-4 font-weight-600 font-size-16 c-container",
                domProps: {
                  textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.navigation")),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 px-3",
                  attrs: {
                    "data-cy": "button-to-dashboar-m",
                    "data-gtm": "to-dashboar-m-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPages("dashboard")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { name: "ic_dashboard", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.dashboard")),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 px-3",
                  attrs: {
                    "data-cy": "button-to-profile-m",
                    "data-gtm": "to-profile-m-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPages("profile")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2 cj-fill-grey-tertiary",
                    attrs: { name: "ic_profile", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.profile")),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 px-3",
                  attrs: {
                    "data-cy": "button-to-manage-order-m",
                    "data-gtm": "to-manage-order-m-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPages("manageOrder")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { name: "ic_manage_order_m", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbNavbar.manageOrder")
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.loginType === _vm.navbarType.GENERAL
          ? _c(
              "div",
              { staticClass: "c-navbar-menu cj-bg-white-primary h-100" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between px-3 pt-3 pb-2",
                  },
                  [
                    _c("c-button", {
                      staticClass: "font-roboto font-size-16 mr-2",
                      attrs: {
                        fullWidth: "",
                        typeStyle: "secondary",
                        dataGTMCustom: "login",
                        "data-cy": "btn-login",
                      },
                      domProps: {
                        textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.login")),
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleOpenLoginMenu.apply(null, arguments)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("c-button", {
                      staticClass: "font-roboto font-size-16",
                      attrs: {
                        dataGTMCustom: "register",
                        "data-cy": "join",
                        fullWidth: "",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.register")
                        ),
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleOpenRegisterMenu.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer mt-1 py-3 px-3",
                    attrs: {
                      "data-cy": "join-freelancer",
                      "data-gtm": "join-freelancer-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPage()
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass:
                        "my-auto mr-2 cj-fill-grey-tertiary cj-stroke-grey-tertiary",
                      attrs: { name: "ic_project", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.joinFreelancer")
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex cursor-pointer justify-content-between py-3 px-3",
                    attrs: { "data-gtm": "change-language-button" },
                    on: { click: _vm.openModalLanguage },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("icon", {
                          staticClass: "my-auto mr-2",
                          attrs: { name: "ic_language", width: "27px" },
                        }),
                        _vm._v(" "),
                        _c("p", {
                          staticClass:
                            "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbNavbar.language")
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto font-size-16 cj-color-black-primary font-weight-700",
                      },
                      [_vm._v(_vm._s(_vm.currentLanguage.toUpperCase()))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: { "data-gtm": "to-help-center-button" },
                    on: {
                      click: function ($event) {
                        return _vm.toHelpCenter("general-account")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_help_center", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.helpCenter")
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm.loginType === _vm.navbarType.BUYER
          ? _c(
              "div",
              { staticClass: "c-navbar-menu cj-bg-white-primary h-100" },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: {
                      "data-cy": "button-to-manage-auction",
                      "data-gtm": "to-manage-request-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPages("manageRequest")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_manage_request_m", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.manageRequest")
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: {
                      "data-cy": "btn-navbar-menu-post-auction-project",
                      "data-gtm": "to-post-auction-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPages("postRequest")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_post_request_m", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.postRequest")
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: { "data-gtm": "to-paw-wishlist-button" },
                    on: {
                      click: function ($event) {
                        return _vm.toPages("pawWishlist")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_project_wishlist_m", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.pawWishlist")
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm.loginType === _vm.navbarType.PARTNER
          ? _c(
              "div",
              { staticClass: "c-navbar-menu cj-bg-white-primary h-100" },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: {
                      "data-cy": "btn-navbar-menu-manage-paw",
                      "data-gtm": "to-manage-paw-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPages("managePaw")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_manage_paw_m", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.managePaw")
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: {
                      "data-cy": "btn-navbar-menu-create-paw",
                      "data-gtm": "to-create-paw-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toPages("createPaw")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_add_paw", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.createPaw")
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: { "data-gtm": "find-project-button" },
                    on: {
                      click: function ($event) {
                        return _vm.toPages("findProject")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_find_project_m", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.findProject")
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cursor-pointer py-3 px-3",
                    attrs: { "data-gtm": "to-paw-wishlist-button" },
                    on: {
                      click: function ($event) {
                        return _vm.toPages("projectWishlist")
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "my-auto mr-2",
                      attrs: { name: "ic_project_wishlist_m", width: "27px" },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbNavbar.projectWishlist")
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loginType !== _vm.navbarType.GENERAL
          ? _c("div", [
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 px-3",
                  attrs: {
                    "data-cy": "button-to-balance-page",
                    "data-gtm": "to-balance-page-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPages("balance")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { name: "ic_balance", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbNavbar.accountBalance")
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 px-3",
                  attrs: {
                    "data-cy": "btn-to-billing-page",
                    "data-gtm": "to-billing-page-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPages("billing")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { name: "ic_billing", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbNavbar.billingHistory")
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-roboto c-container cj-color-black-primary mb-2 mt-4 font-weight-600 font-size-16",
                domProps: {
                  textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.setting")),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex cursor-pointer justify-content-between py-3 px-3",
                  attrs: {
                    "data-cy": "btn-set-language",
                    "data-gtm": "set-language-button",
                  },
                  on: { click: _vm.openModalLanguage },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("icon", {
                        staticClass: "my-auto mr-2",
                        attrs: { name: "ic_language", width: "27px" },
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass:
                          "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbNavbar.language")
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-size-16 cj-color-black-primary font-weight-700",
                    },
                    [_vm._v(_vm._s(_vm.currentLanguage.toUpperCase()))]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 px-3",
                  attrs: {
                    "data-cy": "button-to-help-center-page",
                    "data-gtm": "to-help-center-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toHelpCenter("general-account")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { name: "ic_help_center", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbNavbar.helpCenter")
                      ),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 px-3",
                  attrs: {
                    "data-cy": "button-to-setting-m",
                    "data-gtm": "to-setting-button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPages("setting")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { name: "ic_setting_m", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.setting")),
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex cursor-pointer py-3 mb-5 px-3",
                  attrs: {
                    "data-cy": "button-to-logout",
                    "data-gtm": "logout-button",
                  },
                  on: { click: _vm.logout },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto mr-2",
                    attrs: { name: "ic_logout", width: "27px" },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "my-auto font-roboto cj-color-black-primary font-weight-400 font-size-16",
                    domProps: {
                      textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.logout")),
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }