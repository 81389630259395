import JhiDataUtils from '@/shared/data/data-utils.service';
import { ChtLocation, IChtLocation } from '@/shared/model/cht-location.model';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import CButton from '@/components/button/c-button.vue';
import { MAX_SIZE_10MB } from '@/shared/media-file/constants';
import CConfirmation from '@/components/c-confirmation/c-confirmation.vue';
import { Flutter } from '@/app-flutter';
import MapUtils from '@/shared/utils/map-utils.service';

@Component({
  components: { CButton, CConfirmation },
})
export default class RoomFooterControllerChat extends mixins(JhiDataUtils, MapUtils) {
  [x: string]: any;
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public isShowUpload = false;
  public isShowEmoticon = false;
  public seletedFileUpload = '';
  public currentLocation: IChtLocation = new ChtLocation();

  @Prop({ default: null })
  public isShowEmotReactions;

  // public typeFile = IMAGEVIDEO_TYPES;
  public isRunningOnFlutter: boolean = Flutter.isRunOn();
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleShowUpload() {
    this.isShowUpload = !this.isShowUpload;
  }

  public closeIsShowUpload() {
    this.isShowUpload = false;
  }

  public handleShowEmoticon() {
    this.isShowEmoticon = !this.isShowEmoticon;
  }

  public closeIsShowEmoticon() {
    this.isShowEmoticon = false;
  }

  public async showRoomLocationChat() {
    if (this.isRunningOnFlutter) {
      this.permissionLocationFlutter(this.permissionLocation);
    } else {
      this.permissionLocation();
    }
  }

  public async permissionLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          if (position) {
            this.currentLocation.latGoogle = position.coords.latitude + '';
            this.currentLocation.longGoogle = position.coords.longitude + '';

            await new Promise(resolve => setTimeout(resolve, 0));

            if (this.$refs.roomLocationChatModal) {
              if (Flutter.isRunOn()) {
                Flutter.call('permissionLocationStatus', {
                  permissionLocationStatus: 'PERMISSION_GRANTED',
                });
              }
              (this.$refs.roomLocationChatModal as any).show();
            }
          }
        },
        error => {
          if (Flutter.isRunOn()) {
            if (error.code === error.PERMISSION_DENIED) {
              console.log('Geolocation permission denied (Flutter):', error.message);
              Flutter.call('permissionLocationStatus', {
                permissionLocationStatus: 'PERMISSION_DENIED',
              });
            }
          }
          if (error && !this.isRunningOnFlutter) {
            if (this.$refs.roomNotifLocationModal) {
              (this.$refs.roomNotifLocationModal as any).show();
            }
          }
        }
      );
    } else {
      console.error('Geolocation tidak didukung oleh browser ini.');
    }
  }

  public launchVideoImage() {
    this.isShowUpload = false;
    this.seletedFileUpload = 'VIDEOIMAGE';
    (<any>this.$refs.videoImage).value = '';
    this.openFileFlutter(
      {
        onFlutterNotRun: () => (this.$refs.videoImage as HTMLElement).click(),
        allowedExtensions: '.gif, .jpeg ,.jpg, .svg, .png, .3gp, .avi, .flv, .m4v, .mkv, .mov, .mp4, .mpg, .mpeg, .wmv',
        multiplePick: true,
        fileMaxSizeInMb: MAX_SIZE_10MB,
      },
      _ => {},
      (file: File) => this.$root.$emit('roomFooter::onFileChange', file)
    );
  }

  // move to roomfooter
  // public roomLocationChatCallBack(chtLocation:CbLocation) {
  //   (<any>this.$refs.roomLocationChatModal).hide();
  // }

  public roomCameraChatChatCallBack() {
    (<any>this.$refs.roomCameraChatChatModal).hide();
  }

  public async showRoomCameraChatChat() {
    const self = this;
    console.log('cameraChat AWAL ...');
    console.log('cameraPermission AWAL ...');

    if (this.isRunningOnFlutter) {
      const permissionGranted = await self.permissionCameraFlutter();

      console.log('cameraPermission hasil di room footer :', permissionGranted);

      // return if data from permissionCameraFlutter is false
      if (!permissionGranted) {
        return;
      }
    }

    console.log('cameraPermission akses diberikan');

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Prompt the user for camera access
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          // User has granted camera access, you can now use the camera stream
          // (e.g., display it in a video element, capture photos, or record videos)
          self.openFileFlutter(
            {
              onFlutterNotRun: () => (<any>self.$refs.roomCameraChatChatModal).show(),
              useCamera: true,
              cameraType: 'front',
              isChat: true,
            },
            _ => {},
            (file: File) => self.$root.$emit('roomFooter::onCamera', file)
          );
        })
        .catch(function (error) {
          console.log('cameraChat ERROR');
          if (error && !self.isRunningOnFlutter) {
            // Check if you're inside a Vue component
            if (self.$refs && <any>self.$refs.roomNotifCameraModal) {
              // Access the $refs property
              (<any>self.$refs.roomNotifCameraModal).show();
            } else {
              console.error("$refs.roomNotifCameraModal is undefined. Make sure you're inside a Vue component.");
            }
          }
          if (self.isRunningOnFlutter) {
            self.openFileFlutter(
              {
                useCamera: true,
                cameraType: 'front',
                isChat: true,
              },
              _ => {},
              (file: File) => self.$root.$emit('roomFooter::onCamera', file)
            );
          }
        });
    } else {
      console.log('cameraChat ELSE');
      // The WebRTC API is not supported in this browser
      self.openFileFlutter(
        {
          onFlutterNotRun: () => (<any>self.$refs.roomCameraChatChatModal).show(),
          useCamera: true,
          cameraType: 'front',
          isChat: true,
        },
        _ => {},
        (file: File) => self.$root.$emit('roomFooter::onCamera', file)
      );
    }
  }

  public closeShowReactions() {
    this.$emit('closeShowEmotReactions', null);
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
