import axios from 'axios';

import { ICbUserBookmarkAgg } from '@/shared/model/cb-user-bookmark-agg.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-bookmark-aggs';

export default class PCbUserBookmarkAggService {
  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public create(entity: ICbUserBookmarkAgg): Promise<ICbUserBookmarkAgg> {
    return new Promise<ICbUserBookmarkAgg>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbUserBookmarkAgg): Promise<ICbUserBookmarkAgg> {
    return new Promise<ICbUserBookmarkAgg>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveCount(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/count`,{ params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
