import { ICmAttachmentRestrict } from '@/shared/model/cm-attachment-restrict.model';
import { ICmTkt } from '@/shared/model/cm-tkt.model';

export interface ICmTktAtt {
  id?: number;
  isCover?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cmAttachmentRestrict?: ICmAttachmentRestrict | null;
  cmTkt?: ICmTkt | null;
  externalId?: number | null;
  externalEntity?: string | null;
  uploadloadLinks?: string | null;
}

export class CmTktAtt implements ICmTktAtt {
  constructor(
    public id?: number,
    public isCover?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cmAttachmentRestrict?: ICmAttachmentRestrict | null,
    public cmTkt?: ICmTkt | null,
    public externalId?: number | null,
    public externalEntity?: string | null,
    public uploadloadLinks?: string | null
  ) {
    // this.isCover = this.isCover ?? false;
    // this.isActive = this.isActive ?? false;
  }
}
