import axios from 'axios';

import { ICbVwHelpCenterFeedback } from '@/shared/model/cb-vw-help-center-feedback.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-help-center-feedbacks';

export default class PCbVwHelpCenterFeedbackService {
  public retrieveByLogin(): Promise<ICbVwHelpCenterFeedback> {
    return new Promise<ICbVwHelpCenterFeedback>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
