<template>
  <div class="vac-message-file-container">
    <div
      :ref="'imageRef' + index"
      data-cy="vac-message-image-container"
      class="vac-message-image-container"
      @mouseover="imageHover = true"
      @mouseleave="imageHover = false"
      @click="openFile($event, 'preview')"
      v-if="!message.isMap"
    >
      <progress-bar v-if="file.progress >= 0" :progress="file.progress" :style="{ top: `${imageResponsive.loaderTop}px` }" />
      <loader v-else :show="isImageLoading" :style="{ top: `${imageResponsive.loaderTop}px` }" class="vac-size-loader">
        <template v-for="(idx, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </loader>
      <div data-cy="vac-img-wrapper" class="position-relative vac-img-wrapper" :class="isImageLoading ? 'vac-img-loading' : ''">
        <img v-if="isImage" :src="file.url" class="var-image-video-size" alt="" />
        <template v-if="isVideo">
          <img :src="file.urlThumbnail" class="var-image-video-size" alt="" />
          <c-icons
            v-if="!isImageLoading"
            name="cic_play_circle_outlined"
            width="50px"
            height="50px"
            class="position-play cj-fill-white-primary"
          />
        </template>
        <transition name="vac-fade-image">
          <div
            v-if="!messageSelectionEnabled && imageHover && !isImageLoading"
            class="vac-image-buttons"
            style="top: 0; border-radius: 8px !important"
          >
            <div class="vac-svg-button vac-button-view" data-cy="vac-message-image-view"
                 @click="openFile($event, 'preview')">
              <slot name="eye-icon">
                <svg-icon name="eye" />
              </slot>
            </div>
            <div class="vac-svg-button vac-button-download" data-cy="vac-message-image-download"
                 @click="openFile($event, 'download')">
              <slot name="document-icon">
                <svg-icon name="document" />
              </slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <a
      :ref="'imageRef' + index"
      class="vac-message-image-container"
      v-if="message.isMap"
      @click="onFlutterClick"
      :href="getLink"
      target="_blank"
    >
      <progress-bar v-if="file.progress >= 0" :progress="file.progress" :style="{ top: `${imageResponsive.loaderTop}px` }" />
      <loader v-else :show="isImageLoading" class="vac-size-loader">
        <template v-for="(idx, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </loader>
      <div class="position-relative">
        <div v-if="isImageLoading" class="vac-map-wrapper" :class="isImageLoading ? 'vac-map-img-loading' : ''"></div>
        <div v-else class="map-container">
          <div>
            <div data-cy="img-map-wrapper" class="img-map-wrapper">
              <img :src="file.url" class="vac-map-image-size" alt="" />
            </div>
            <div class="description-map position-relative" :class="isYourSelf ? 'me' : ''">
              <p class="m-0 text-title vac-text-ellipsis">{{ message.mapPlaceName }}</p>
              <p data-cy="description-map-address" class="m-0 text-sub vac-text-ellipsis">{{ message.mapAddress }}</p>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import Loader from '../../../../../components/Loader/Loader';
import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';
import SvgIcon from '../../../../../components/SvgIcon/SvgIcon';
import CIcons from '../../../../../components/c-icons/c-icons.vue';
import {Flutter} from '@/app-flutter';

const { isImageFile, isVideoFile } = require('../../../../../utils/media-file');

export default {
  name: 'MessageFile',
  components: { SvgIcon, Loader, ProgressBar, CIcons },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    file: { type: Object, required: true },
    index: { type: Number, required: true },
    messageSelectionEnabled: { type: Boolean, required: true },
    isYourSelf: { default: null },
  },

  emits: ['open-file'],

  data() {
    return {
      imageResponsive: '',
      imageLoading: false,
      imageHover: false,
    };
  },

  computed: {
    isImageLoading() {
      return this.file.url.indexOf('blob:http') !== -1 || this.imageLoading;
    },
    isImage() {
      return isImageFile(this.file);
    },
    isVideo() {
      return isVideoFile(this.file);
    },
    isRunOn() {
      return Flutter.isRunOn();
    },
    getLink() {
      let link = `https://www.google.com/maps/place/${this.message.mapLatGoogle},${this.message.mapLongGoogle}`;
      if (!this.isRunOn) {
        return link;
      } else {
        return '';
      }
    },
  },

  watch: {
    file: {
      immediate: true,
      handler() {
        this.checkImgLoad();
        this.checkVideoLoad();
      },
    },
  },

  mounted() {
    const ref = this.$refs['imageRef' + this.index];

    if (ref) {
      this.imageResponsive = {
        maxHeight: ref.clientWidth - 18,
        loaderTop: ref.clientHeight / 2 - 9,
      };
    }
  },

  methods: {
    checkImgLoad() {
      if (!isImageFile(this.file)) return;
      this.imageLoading = true;
      const image = new Image();
      image.src = this.file.url;
      image.addEventListener('load', () => (this.imageLoading = false));
    },
    checkVideoLoad() {
      if (!isVideoFile(this.file)) return;
      this.imageLoading = true;
      const image = new Image();
      image.src = this.file.urlThumbnail;
      image.addEventListener('load', () => (this.imageLoading = false));
    },
    openFile(event, action) {
      if (!this.messageSelectionEnabled) {
        event.stopPropagation();
        this.$emit('open-file', { file: this.file, action });
      }
    },
    onFlutterClick() {
      Flutter.launchMap(this.message.mapLatGoogle, this.message.mapLongGoogle);
    },
  },
};
</script>
