var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "progress",
      staticClass: "vac-player-bar",
      on: {
        mousedown: _vm.onMouseDown,
        mouseover: function ($event) {
          return _vm.$emit("hover-audio-progress", true)
        },
        mouseout: function ($event) {
          return _vm.$emit("hover-audio-progress", false)
        },
      },
    },
    [
      _c("div", { staticClass: "vac-player-progress" }, [
        _c("div", { staticClass: "vac-line-container" }, [
          _c("div", {
            staticClass: "vac-line-progress",
            style: { width: `${_vm.percentage}%` },
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "vac-line-dot",
            class: { "vac-line-dot__active": _vm.isMouseDown },
            style: { left: `${_vm.percentage}%` },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }