import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
const baseApiUrl = 'services/cbuserms/api/cb-vw-user-connections';

export default class PCbVwUserConnectionService {
  public retrieveByLogin(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiUrl +
            '/by-login' +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
