var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-filter-budget",
      class: _vm.bgFilterBudget ? "cj-bg-white-secondary" : "",
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c(
          "div",
          {
            staticClass: "font-size-18 cj-color-black-primary font-weight-500",
          },
          [
            _c(
              "p",
              {
                staticClass:
                  "mb-2 font-size-14 cj-color-black-primary font-weight-500 font-roboto",
              },
              [_vm._v("Min")]
            ),
            _vm._v(" "),
            _c("c-input-currency", {
              ref: "inputMin",
              attrs: {
                placeholder: "IDR",
                currency: "IDR",
                dataCyCustom: "min",
                required: "",
                id: "min",
                max: _vm.MaxNumberType.NUMBER_9T,
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", _vm.advanceSearchFilter)
                },
              },
              model: {
                value: _vm.advanceSearchFilter.greaterStartPrice,
                callback: function ($$v) {
                  _vm.$set(_vm.advanceSearchFilter, "greaterStartPrice", $$v)
                },
                expression: "advanceSearchFilter.greaterStartPrice",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "font-size-18 px-2 pt-4 m-auto cj-color-black-primary font-weight-700",
          },
          [_vm._v("-")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "font-size-18 cj-color-black-primary font-weight-500",
          },
          [
            _c(
              "p",
              {
                staticClass:
                  "font-size-14 mb-2 cj-color-black-primary font-weight-500 font-roboto",
              },
              [_vm._v("Max")]
            ),
            _vm._v(" "),
            _c("c-input-currency", {
              attrs: {
                placeholder: "IDR",
                required: "",
                dataCyCustom: "max",
                id: "max",
                currency: "IDR",
                max: _vm.MaxNumberType.NUMBER_9T,
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", _vm.advanceSearchFilter)
                },
              },
              model: {
                value: _vm.advanceSearchFilter.lessStartPrice,
                callback: function ($$v) {
                  _vm.$set(_vm.advanceSearchFilter, "lessStartPrice", $$v)
                },
                expression: "advanceSearchFilter.lessStartPrice",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.hideAction
        ? _c("div", { staticClass: "d-flex justify-content-between mt-3" }, [
            _c("p", {
              staticClass:
                "font-roboto font-weight-500 cj-btn-text-primary font-size-14",
              attrs: { "data-cy": "freelancer-budget-clear" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.findProject.clearAll")
                ),
              },
              on: { click: _vm.clearAll },
            }),
            _vm._v(" "),
            _c("button", {
              staticClass:
                "font-size-14 px-4 py-1 font-weight-500 cj-color-white-primary cj-bg-red-primary rounded-pill",
              attrs: { "data-cy": "freelancer-budget-apply" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.findProject.apply")
                ),
              },
              on: { click: _vm.submit },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }