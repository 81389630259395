import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

// ================= START MODULE GENERAL =================
const PHome = () => import('@/module/general/home/home.vue');
const POtpVerification = () => import('@/module/general/otp-verification/otp-verification.vue');
const PIntroductionFreelancer = () => import('@/module/general/introduction-freelancer/introduction-freelancer.vue');
const PAppGeneral = () => import('@/module/general/app-general.vue');
const PSvc = () => import('@/module/general/svc/svc.vue');
const PSvcCategory = () => import('@/module/general/svc-category/svc-category.vue');
const PPromo = () => import('@/module/general/promo/promo.vue');
const PAccountBalance = () => import('@/module/general/account-balance/account-balance.vue');
const PRequestRefund = () => import('@/module/general/account-balance/components/c-request-refund.vue');
const PAdvanceSearch = () => import('@/module/general/advance-search/advance-search.vue');
const PSettings = () => import('@/module/general/setting/settings.vue');
const CBillingHistory = () => import('@/components/c-billing-history/c-billing-history.vue');
const PHelpCenter = () => import('@/module/general/help-center/help-center.vue');
const PDetailOrder = () => import('@/module/general/detail-order/detail-order.vue');
const PAboutUs = () => import('@/module/general/about-us/about-us.vue');
const PSetPassword = () => import('@/module/general/set-password/set-password.vue');
const PSearchMenuM = () => import('@/module/general/search-menu-m/search-menu-m.vue');
const PManageOrder = () => import('@/module/general/manage-order/manage-order.vue');
const PDashboard = () => import('@/module/general/dashboard/dashboard.vue');
const PProfile = () => import('@/module/general/profile/profile.vue');
const PLegalDocument = () => import('@/module/general/legal-document/legal-document.vue');
const PAuctionProjectDetail = () => import('@/module/general/p-auction-project/p-auction-project.vue');
const PPawDetail = () => import('@/module/general/paw-detail/paw-detail.vue');

const PDetailOrderLocation = () => import('@/module/general/detail-order-location/detail-order-location.vue');

const PSettingAccountMLinked = () => import('@/module/general/mobile/setting-account-m-linked/setting-account-m-linked.vue');

// ================= END MODULE GENERAL =================

// ================= START MODULE ASK COMMITEE =================
const PAppAskCommitee = () => import('@/module/ask-commitee/app-ask-commitee.vue');
const PAskCommitee = () => import('@/module/ask-commitee/ask-commitee/ask-commite.vue');
const PRequestedTicket = () => import('@/module/ask-commitee/ticket/requested-ticket.vue');

// ================= END MODULE FREELANCER =================

// ================= START MODULE FREELANCER =================
const PAppFreelancer = () => import('@/module/freelancer/app-freelancer.vue');
const PFreelancerHome = () => import('@/module/freelancer/home/home.vue');
const PRegisterFreelancerOne = () => import('@/module/freelancer/register-freelancer-one/register-freelancer-one.vue');
const PRegisterFreelancerTwo = () => import('@/module/freelancer/register-freelancer-two/register-freelancer-two.vue');
const PPawCreateOne = () => import('@/module/freelancer/paw/paw-create-one/paw-create-one.vue');
const PPawCreateTwo = () => import('@/module/freelancer/paw/paw-create-two/paw-create-two.vue');
const PPawCreateThree = () => import('@/module/freelancer/paw/paw-create-three/paw-create-three.vue');
const PPawCreateRevision = () => import('@/module/freelancer/paw/paw-create-revision/paw-create-revision.vue');
const PFindProject = () => import('@/module/freelancer/find-project/find-project.vue');
const PPawManage = () => import('@/module/freelancer/paw/paw-manage/paw-manage.vue');

// ================= END MODULE FREELANCER =================

// ================= START MODULE BUYER =================
const PAppBuyer = () => import('@/module/buyer/app-buyer.vue');
const PBuyerHome = () => import('@/module/buyer/home/home.vue');
const PBuyerPostRequest = () => import('@/module/buyer/auction-project/auction-project.vue');
const PBuyerPawBasedRequest = () => import('@/module/buyer/paw-based-request/paw-based-request.vue');
const PBuyerManageRequest = () => import('@/module/buyer/manage-request/manage-request.vue');
const PBuyerRequestOffer = () => import('@/module/buyer/request-offer/request-offer.vue');
const PPawWishlist = () => import('@/module/buyer/paw-wishlist/paw-wishlist.vue');

// ================= END MODULE BUYER =================

// ================= START MODULE CHAT =================

// ================= END MODULE CHAT =================

// ===== CHAT =====
// ===== CHAT =====

// ===== CHAT MOBILE =====
// ===== CHAT =====

// ================= END COMPONENT =================

// ================= START MODULE MOBILE =================
// ================= END MODULE MOBILE =================

// ================= START PAGES MOBILE =================
const PIntroductionM = () => import('@/module/general/mobile/introduction-m/introduction-m.vue');
const PSetPinM = () => import('@/components/c-set-pin/c-set-pin.vue');
const CNavbarMenu = () => import('@/components/c-navbar-menu/c-navbar-menu.vue');
const PLoginM = () => import('@/module/general/mobile/login-m/login-m.vue');
const PRegisterM = () => import('@/module/general/mobile/register-m/register-m.vue');
const PSettingAccountM = () => import('@/module/general/mobile/setting-account-m/setting-account-m.vue');
const PSettingAccountDeactiveM = () => import('@/module/general/mobile/setting-account-deactive-m/setting-account-deactive-m.vue');
// const PSecurityM = () => import('@/module/general/mobile/security-m/security-m.vue'); Step Sudah Tidak Ada
const PSettingNotificationM = () => import('@/module/general/mobile/setting-notification-m/setting-notification-m.vue');
const PNotificationM = () => import('@/module/general/mobile/notification-m/notification-m.vue');
const PBillingHistoryM = () => import('@/module/general/mobile/billing-history-m/billing-history-m.vue');
const PSettingAccountMBank = () => import('@/module/general/mobile/setting-account-m-bank/setting-account-m-bank.vue');
const PPinM = () => import('@/module/general/mobile/pin-m/pin-m.vue');
const PCancelledInvoiceM = () =>
  import('@/module/general/mobile/billing-history-m/components/c-cancelled-invoice-m/cancelled-invoice-m.vue');
const PSettingAccountMPassword = () => import('@/module/general/mobile/setting-account-m-password/setting-account-m-password.vue');
const PAppChatContainer = () => import('@/module/chat-container/App.vue');
const PWaitingPaymentM = () => import('@/module/general/dashboard/components/c-waiting-payment/mobile/c-waiting-payment-m.vue');

const CLocationTrack = () => import('@/components/location-track/c-location-track.vue');

const CItemProfileReview = () =>
  import('@/module/general/profile/components/c-profile-review-history/components/c-item-profile-review/c-item-profile-review.vue');

const PCBillingHistory = () => import('@/components/c-billing-history/c-billing-history.vue');

const LiveChat = () => import('@/module/live-chat/live-chat.vue');

const PAppLink = () => import('@/module/app-link/app-link.vue');

// const CClockProgressBar = () => import('@/components/c-clock-progress-bar/c-clock-progress-bar.vue');
// ================= END PAGES MOBILE =================

const PError = () => import('@/core/error/error.vue');

export default [
  // {
  //   path: '/c-clock-progress-bar',
  //   name: 'CClockProgressBar',
  //   component: CClockProgressBar,
  // },
  {
    path: '/chat',
    name: 'PAppChat',
    component: PAppChatContainer,
    meta: { authorities: [Authority.ROLE_CB_BUYER] },
  },

  {
    path: '/chat/:roomId',
    name: 'PAppChatByRoomId',
    component: PAppChatContainer,
    meta: { authorities: [Authority.ROLE_CB_BUYER] },
  },
  {
    path: '/chat/notif/:userId',
    name: 'PAppChatByUserId',
    component: PAppChatContainer,
    meta: { authorities: [Authority.ROLE_CB_BUYER] },
  },

  {
    path: '/location-track',
    name: 'CLocationTrack',
    component: CLocationTrack,
  },
  {
    path: '/c-item-profile-review',
    name: 'CItemProfileReview',
    component: CItemProfileReview,
  },

  {
    path: '/general',
    component: PAppGeneral,
    children: [
      {
        path: '',
        name: 'PHome',
        component: PHome,
      },
      {
        path: 'p-introduction-freelancer',
        name: 'PIntroductionFreelancer',
        component: PIntroductionFreelancer,
      },
      {
        path: 'p-legal-document',
        name: 'PLegalDocument',
        component: PLegalDocument,
      },
      {
        path: 'p-auction-project-detail/:id',
        name: 'PAuctionProjectDetail',
        component: PAuctionProjectDetail,
        meta: { authorities: [Authority.ROLE_CB_BUYER, Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-otp-verification/:otpType',
        name: 'POtpVerification',
        component: POtpVerification,
      },
      {
        path: 'p-otp-verification/PIN',
        name: 'POtpVerificationPin',
        component: POtpVerification,
      },
      {
        path: 'p-svc/:id',
        name: 'PSvc',
        component: PSvc,
      },
      {
        path: 'p-svc-cat/:svcCatId',
        name: 'PSvcCategory',
        component: PSvcCategory,
      },
      {
        path: 'p-promo',
        name: 'PPromo',
        component: PPromo,
      },
      {
        path: 'p-account-balance',
        name: 'PAccountBalance',
        component: PAccountBalance,
      },
      {
        path: 'p-account-balance/p-request-refund',
        name: 'PRequestRefund',
        component: PRequestRefund,
      },
      {
        path: 'p-advance-search/:key',
        name: 'PAdvanceSearch',
        component: PAdvanceSearch,
      },
      {
        path: 'p-billing-histories',
        name: 'PBillingHisories',
        component: PCBillingHistory,
        meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-billing-histories/:name',
        name: 'PBillingHisories',
        component: PCBillingHistory,
        meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-settings',
        name: 'PSettings',
        component: PSettings,
        meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
        children: [
          {
            path: 'account',
            name: 'PSettingAccountM',
            component: PSettingAccountM,
            meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          },
          {
            path: 'p-setting-account-deactive-m',
            name: 'PSettingAccountDeactiveM',
            component: PSettingAccountDeactiveM,
            meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          },
          // {
          //   path: 'security',
          //   name: 'PSecurityM',
          //   component: PSecurityM,
          //   meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          // }, Step Sudah Tidak Ada
          {
            path: 'pin',
            name: 'PPinM',
            component: PPinM,
            meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          },

          {
            path: 'p-set-new-password',
            name: 'PSettingAccountMPassword',
            component: PSettingAccountMPassword,
            meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          },
          {
            path: 'notification',
            name: 'PSettingNotificationM',
            component: PSettingNotificationM,
            meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          },
          {
            path: 'billing-history',
            name: 'PBillingHistoryM',
            component: PBillingHistoryM,
            meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          },
          {
            path: 'billing-history/:name',
            name: 'PBillingHistoryDetailM',
            component: CBillingHistory,
            meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
          },
        ],
      },

      {
        path: 'p-help-center/:info',
        name: 'PHelpCenter',
        component: PHelpCenter,
      },
      {
        path: 'p-detail-order/:cbVwPawOrderId/track',
        name: 'PDetailOrderLocation',
        component: PDetailOrderLocation,
      },
      {
        path: 'p-detail-order/:cbVwPawOrderId',
        name: 'PDetailOrder',
        component: PDetailOrder,
        meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-about-us',
        name: 'PAboutUs',
        component: PAboutUs,
      },
      {
        path: 'p-set-password/:id',
        name: 'PSetPassword',
        component: PSetPassword,
      },
      {
        path: 'setpasswordforcbreg/:verifcationMethod',
        name: 'PSetPasswordCbReg',
        component: PSetPassword,
      },
      {
        path: 'setpasswordforcbreg',
        name: 'PSetPasswordCbRegTest',
        component: PSetPassword,
      },
      {
        path: 'page-paw-detail/:id',
        name: 'PPawDetailView',
        component: PPawDetail,
      },
      {
        path: 'p-search-menu-m',
        name: 'PSearchMenuM',
        component: PSearchMenuM,
      },
      {
        path: 'p-manage-order',
        name: 'PManageOrder',
        component: PManageOrder,
        meta: { authorities: [Authority.ROLE_CB_BUYER, Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-dashboard',
        name: 'PDashboard',
        component: PDashboard,
        meta: { authorities: [Authority.ROLE_CB_BUYER, Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-profile',
        name: 'PProfile',
        component: PProfile,
        meta: { authorities: [Authority.ROLE_CB_BUYER, Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-profile/:id',
        name: 'PProfilePublic',
        component: PProfile,
        meta: { authorities: [Authority.ROLE_CB_BUYER, Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-navbar-menu',
        name: 'CNavbarMenu',
        component: CNavbarMenu,
      },
      {
        path: '/forbidden',
        name: 'Forbidden',
        component: PError,
        meta: { error403: true },
      },
      {
        path: '/not-found',
        name: 'NotFound',
        component: PError,
        meta: { error404: true },
      },
      // ================ Mobile Responsive =================

      // ================ Page Mobile ================
      {
        path: 'p-introduction-m',
        name: 'PIntroductionM',
        component: PIntroductionM,
      },
      {
        path: 'p-set-pin-m/:name',
        name: 'PSetPinM',
        component: PSetPinM,
      },
      {
        path: 'p-login-m',
        name: 'PLoginM',
        component: PLoginM,
      },
      {
        path: 'p-register-m',
        name: 'PRegisterM',
        component: PRegisterM,
      },
      {
        path: 'p-bank-account',
        name: 'PSettingAccountMBank',
        component: PSettingAccountMBank,
      },

      {
        path: 'p-cancelled-invoice-m/:name',
        name: 'PCancelledInvoiceM',
        component: PCancelledInvoiceM,
      },
      {
        path: 'p-notification-m',
        name: 'PNotificationM',
        component: PNotificationM,
      },

      {
        path: 'p-waiting-payment-m',
        name: 'PWaitingPaymentM',
        component: PWaitingPaymentM,
      },

      // ================ End Page Mobile ================

      ,
    ],
  },

  {
    path: '/buyer',
    component: PAppBuyer,
    meta: { authorities: [Authority.ROLE_CB_BUYER] },
    children: [
      {
        path: '',
        name: 'PBuyerHome',
        component: PBuyerHome,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-auction-project',
        name: 'PBuyerPostRequest',
        component: PBuyerPostRequest,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-auction-project/:id',
        name: 'PBuyerPostRequestEdit',
        component: PBuyerPostRequest,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },

      {
        path: 'p-auction-project/:id/guarantee',
        name: 'PBuyerPostRequestEditGuarantee',
        component: PBuyerPostRequest,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-paw-based-request/:id',
        name: 'PBuyerPawBasedRequest',
        component: PBuyerPawBasedRequest,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-manage-request',
        name: 'PBuyerManageRequest',
        component: PBuyerManageRequest,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-request-offer/:title/:id',
        name: 'PBuyerRequestOffer',
        component: PBuyerRequestOffer,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-paw-wishlist',
        name: 'PPawWishlist',
        component: PPawWishlist,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-linked-account',
        name: 'PSettingAccountMLinked',
        component: PSettingAccountMLinked,
        meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
    ],
  },
  {
    path: '/freelancer',
    component: PAppFreelancer,
    children: [
      {
        path: '',
        name: 'PFreelancerHome',
        component: PFreelancerHome,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-register-freelancer-one/:svcType',
        name: 'PRegisterFreelancerOne',
        component: PRegisterFreelancerOne,
        //  meta: { authorities: [Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-register-freelancer-two',
        name: 'PRegisterFreelancerTwo',
        component: PRegisterFreelancerTwo,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },

      {
        path: 'p-paw-create/detail',
        name: 'PPawCreateOne',
        component: PPawCreateOne,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-paw-create/detail/:id',
        name: 'PPawCreateOneEdit',
        component: PPawCreateOne,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-paw-create/package/:id',
        name: 'PPawCreateTwo',
        component: PPawCreateTwo,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-paw-create/portfolio/:id',
        name: 'PPawCreateThree',
        component: PPawCreateThree,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-paw-create-revision',
        name: 'PPawCreateRevision',
        component: PPawCreateRevision,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-find-project/:projectTab',
        name: 'PFindProject',
        component: PFindProject,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },

      {
        path: 'p-paw-manage',
        name: 'PPawManage',
        component: PPawManage,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
      {
        path: 'p-linked-account',
        name: 'PSettingAccountMLinked',
        component: PSettingAccountMLinked,
        meta: { authorities: [Authority.ROLE_CB_PARTNER] },
      },
    ],
  },

  {
    path: '/ask-commitee',
    component: PAppAskCommitee,
    children: [
      {
        path: '',
        name: 'PAskCommitee',
        component: PAskCommitee,
        meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
      },
      {
        path: 'p-requested-ticket',
        name: 'PRequestedTicket',
        component: PRequestedTicket,
        meta: { authorities: [Authority.ROLE_CB_PARTNER, Authority.ROLE_CB_BUYER] },
      },
    ],
  },

  // ====================== START CHAT ======================
  {
    path: '/live-chat',
    name: 'LiveChat',
    component: LiveChat,
  },
  {
    path: '/p-app-link',
    name: 'PAppLink',
    component: isMobileDevice() ? PAppLink : PError,
  },
];

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
