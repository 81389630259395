import { CbVwPawOrderRequirement, ICbVwPawOrderRequirement } from './cb-vw-paw-order-requirement.model';
import { App } from './enumerations/app.model';
import { PayType } from './enumerations/pay-type.model';
import { PayMethodType } from './enumerations/pay-method-type.model';
import { IWfHistory } from './wf-history.model';
import { CbPromoPayMethod } from './enumerations/cb-promo-pay-method.model';
import { ICbVwPawOrder } from './cb-vw-paw-order.model';
export interface IPayPayload2023 {
  orderId?: number | null;
  orderIdParent?: number | null;
  payTypes?: PayType[] | null;
  payMethodType?: PayMethodType | null;
  orderIdLabel?: string | null;
  bankCode?: string | null;
  cardDataAccountNumber?: string | null;
  cardDataCvn?: string | null;
  cardDataExpMonth?: string | null;
  cardDataExpYear?: string | null;
  accountHolderName?: string | null;
  externalId?: string | null;
  promoCode?: string | null;
  externalPin?: string | null;
  disputeBuyerPercentage?: number | null;
  disputePartnerPercentage?: number | null;
  amount?: number | null;
  app?: App | null;
  title?: string | null; // transient
  subTitle?: string | null; // transient
  // WF
  wfHistory?: IWfHistory | null;
  cbVwPawOrder?: ICbVwPawOrder | null;
  cbVwPawOrderRequirement?: CbVwPawOrderRequirement | null;

  promoPayMethod?: CbPromoPayMethod | null;
}

export class PayPayload2023 implements IPayPayload2023 {
  constructor(
    public orderId?: number | null,
    public orderIdParent?: number | null,
    public payTypes?: PayType[] | null,
    public payMethodType?: PayMethodType | null,
    public orderIdLabel?: string | null,
    public bankCode?: string | null,
    public cardDataAccountNumber?: string | null,
    public cardDataCvn?: string | null,
    public cardDataExpMonth?: string | null,
    public cardDataExpYear?: string | null,
    public accountHolderName?: string | null,
    public externalId?: string | null,
    public promoCode?: string | null,
    public externalPin?: string | null,
    public disputeBuyerPercentage?: number | null,
    public disputePartnerPercentage?: number | null,
    public amount?: number | null,
    public app?: App | null,
    public title?: string | null, // transient
    public wfHistory?: IWfHistory | null, // transient
    public cbVwPawOrder?: ICbVwPawOrder | null,
    public cbVwPawOrderRequirement?: CbVwPawOrderRequirement | null,
    public subTitle?: string | null, // transient,
    public promoPayMethod?: CbPromoPayMethod | null
  ) {}
}
