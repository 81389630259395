import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import Icon from '@/shared/icons/icon.vue';
import CLoader from '@/components/c-loader/c-loader.vue';

import { isAudioFile, isDocFile, isImageFile, isPdfFile, isPptFile, isVideoFile, isXlsFile } from '@/shared/media-file/media-file';
import ThumbnailWatermarkUtils from '@/shared/data/thumbnail-watermark-utils.service';
import { MAX_SIZE_10MB } from '@/shared/media-file/constants';
import CProgressBar from '@/components/c-progress-bar/c-progress-bar.vue';

@Component({
  components: {
    Icon,
    CLoader,
    CProgressBar,
  },
})
export default class CUploadFileIcon extends mixins(ThumbnailWatermarkUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop() public file;
  @Prop() public fileUrl;
  @Prop() public fileThumbnail;
  @Prop() public fileSize;
  @Prop() public fileProgress;
  @Prop() public isShowWatermarkSign: boolean;
  @Prop({ default: false }) public isWithLoading: boolean;
  @Prop({ default: false }) public isHiddenRatio: boolean;
  @Prop({ default: false }) public isVideoThumbnailImg: boolean;
  @Prop({ default: true }) public isShowVideo;
  @Prop() public dataCyCustom: string;
  @Prop() public classCustomImgPreview: string;
  @Prop({ default: 35 }) public radius: number;

  public isPlaying = false;
  public isPause = false;

  public MAX_SIZE_10MB = MAX_SIZE_10MB;

  public handlePlayVideo() {
    this.isPlaying = true;
    this.isPause = false;
    setTimeout(() => {
      (<any>this.$refs.myVideoFileMain).play();
    }, 50);
  }
  public handlePauseVideo() {
    this.isPlaying = false;
    this.isPause = true;
    setTimeout(() => {
      (<any>this.$refs.myVideoFileMain).pause();
    }, 50);
  }

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isImage() {
    return isImageFile(this.file);
  }
  public get isVideo() {
    return isVideoFile(this.file);
  }
  public get isPdf() {
    return isPdfFile(this.file);
  }
  public get isAudio() {
    return isAudioFile(this.file);
  }
  public get isDoc() {
    return isDocFile(this.file);
  }
  public get isXls() {
    return isXlsFile(this.file);
  }
  public get isPpt() {
    return isPptFile(this.file);
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
