
import Loader from '../../components/Loader/Loader.vue';
import SvgIcon from '../../components/SvgIcon/SvgIcon.vue';

import RoomHeader from './RoomHeader/RoomHeader.vue';
import RoomFooter from './RoomFooter/RoomFooter.vue';
import RoomMessage from './RoomMessage/RoomMessage.vue';
import { Component, Prop } from 'vue-property-decorator';
import RoomControllerChat from './room.controller';
import { mixins } from 'vue-class-component';
import roomMessageDate from './room-message-date/room-message-date.vue';

@Component({
  components: {
    Loader,
    SvgIcon,
    RoomHeader,
    RoomFooter,
    RoomMessage,
    roomMessageDate
  },
  // mixins: [RoomControllerChat],
  watch: {
    loadingMessages(val) {
      if (val) {
        this.infiniteState = null;
      } else {
        if (this.infiniteState) this.infiniteState.loaded();
        setTimeout(() => this.initIntersectionObserver());
      }
    },
    roomId() {
      this.onRoomChanged();
    },
    messages: {
      deep: true,
      handler(newVal, oldVal) {
        newVal.forEach((message, i) => {
          if (this.showNewMessagesDivider && !message.seen && message.senderId !== this.currentUserId) {
            this.newMessages.push({
              _id: message._id,
              index: i,
            });
          }
        });
        if (oldVal?.length === newVal?.length - 1) {
          this.newMessages = [];
        }
        if (this.infiniteState) {
          this.infiniteState.loaded();
        }
        setTimeout(() => (this.loadingMoreMessages = false));

        setTimeout(() => {
          this.initIntersectionObserverMsgSeen();
        }, 100);
      },
    },
    messagesLoaded(val) {
      if (val) this.loadingMessages = false;
      if (this.infiniteState) this.infiniteState.complete();
    },
  },
})
export default class Room extends mixins(RoomControllerChat) {
  // props: {
  @Prop({ type: [String, Number], required: true })
  currentUserId;
  @Prop({ type: Array })
  userIdsOnline;
  @Prop({ type: Object, required: true })
  textMessages;
  @Prop({ type: Boolean, required: true })
  singleRoom;
  @Prop({ type: Boolean, required: true })
  showRoomsList;
  @Prop({ type: Boolean, required: true })
  isMobile;
  @Prop({ type: Array, required: true })
  rooms;
  @Prop({ type: [String, Number], required: true })
  roomId;
  @Prop({ type: Boolean, required: true })
  loadFirstRoom;
  @Prop({ type: Array, required: true })
  messages;
  @Prop({ type: String, default: null })
  roomMessage;
  @Prop({ type: Boolean, required: true })
  messagesLoaded;
  @Prop({ type: Array, required: true })
  menuActions;
  @Prop({ type: Array, required: true })
  messageActions;
  @Prop({ type: Array, required: true })
  messageSelectionActions;
  @Prop({ type: Object, required: true })
  autoScroll;
  @Prop({ type: Boolean, required: true })
  showSendIcon;
  @Prop({ type: Boolean, required: true })
  showFiles;
  @Prop({ type: Boolean, required: true })
  showAudio;
  @Prop({ type: Number, required: true })
  audioBitRate;
  @Prop({ type: Number, required: true })
  audioSampleRate;
  @Prop({ type: Boolean, required: true })
  showEmojis;
  @Prop({ type: Boolean, required: true })
  showReactionEmojis;
  @Prop({ type: Boolean, required: true })
  showNewMessagesDivider;
  @Prop({ type: Boolean, required: true })
  showFooter;
  @Prop({ type: String, required: true })
  acceptedFiles;
  @Prop({ type: Object, required: true })
  textFormatting;
  @Prop({ type: Object, required: true })
  linkOptions;
  @Prop({ type: Boolean, required: true })
  loadingRooms;
  @Prop({ type: Boolean, required: true })
  roomInfoEnabled;
  @Prop({ type: Boolean, required: true })
  textareaActionEnabled;
  @Prop({ type: Boolean, required: true })
  textareaAutoFocus;
  @Prop({ type: Boolean, required: true })
  userTagsEnabled;
  @Prop({ type: Boolean, required: true })
  emojisSuggestionEnabled;
  @Prop({ type: Number, required: true })
  scrollDistance;
  @Prop({ type: Array, default: null })
  templatesText;
  @Prop({ type: Object, required: true })
  usernameOptions;
  // },
  @Prop({ type: Array, default: null })
  cbStickers;
  @Prop({type: Boolean, default: false})
  isClosed;
  @Prop({type: Boolean, default: false})
  isShowAgents;



  private editedMessageId = null;
  private initReplyMessage = null;
  private initEditMessage = null;
  private infiniteState = null;
  public loadingMessages = false;
  private observer = null;
  private showLoader = true;
  private loadingMoreMessages = false;
  private scrollIcon = false;
  private scrollMessagesCount = 0;
  private newMessages = [];
  private messageSelectionEnabled = false;
  private selectedMessages = [];
  private isFirstCall=true;
  private observerMsg = null;

  private ROOM_MESSAGE_CONSTANT="roomMessage"


  // computed: {
  public get room() {
    return this.rooms.find(room => room.roomId == this.roomId) || {};
  }
  public get showNoMessages() {
    return this.roomId && !this.messages.length && !this.loadingMessages && !this.loadingRooms;
  }
  public get showNoRoom() {
    const noRoomSelected = (!this.rooms.length && !this.loadingRooms) || (!this.roomId && !this.loadFirstRoom);
    if (noRoomSelected) {
      this.loadingMessages = false; /* eslint-disable-line vue/no-side-effects-in-computed-properties */
    }
    return noRoomSelected;
  }
  public get showMessagesStarted() {
    return this.messages.length && this.messagesLoaded;
  }

  public get CONTENT_IMAGE() {
    return this.$store.getters.CONTENT_IMAGE;
  }
  // }

  mounted() {
    this.newMessages = [];
    this.onRoomChanged(); //adehuh handle paing/load more stack when refresh
  }

  // methods: {
   public  initIntersectionObserverMsgSeen() {

    if (this.messages && this.messages.length>0) {
      const options = {
        root: document.getElementById('messages-list'),
        // rootMargin: `${this.scrollDistance}px`,
        threshold: 0,
      };

      this.observerMsg = new IntersectionObserver(entries => {
              entries.forEach((entry: any) => {
                if(entry.isIntersecting){
                  const msgId=(entry.target.id).replace(this.ROOM_MESSAGE_CONSTANT,"");
                      let obj:any={};
                      obj.action='MARK_SEEN';
                      obj.message={id:msgId};
                      this.messageActionHandler(obj);

                      const elementMsgId=document.getElementById(this.ROOM_MESSAGE_CONSTANT+msgId);
                      if(elementMsgId)
                      this.observerMsg.unobserve(elementMsgId);

                }
              });

      }, options);

      console.log("this.observerMsg");
      console.log(this.observerMsg);
        this.messages.forEach(message => {
         const elementMsgId= document.getElementById(this.ROOM_MESSAGE_CONSTANT+message.id);
            if(elementMsgId)
           this.observerMsg.observe(elementMsgId);
        });


    }
  }

  initIntersectionObserver() {
    if (this.observer) {
      this.showLoader = true;
      this.observer.disconnect();
    }

    const loader = document.getElementById('infinite-loader-messages');

    if (loader) {
      const options = {
        root: document.getElementById('messages-list'),
        rootMargin: `${this.scrollDistance}px`,
        threshold: 0,
      };

      this.observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          this.loadMoreMessages();
        }
      }, options);

      this.observer.observe(loader);
    }
  }
  preventTopScroll() {
    const container = <any>this.$refs.scrollContainer;
    const prevScrollHeight = container.scrollHeight;

    const observer = new ResizeObserver(_ => {
      if (container.scrollHeight !== prevScrollHeight) {
        if (this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTo({
            top: container.scrollHeight - prevScrollHeight,
          });
          observer.disconnect();
        }
      }
    });

    for (let i = 0; i < container.children.length; i++) {
      observer.observe(container.children[i]);
    }
  }
  touchStart(touchEvent) {
    if (this.singleRoom) return;

    if (touchEvent.changedTouches.length === 1) {
      const posXStart = touchEvent.changedTouches[0].clientX;
      const posYStart = touchEvent.changedTouches[0].clientY;

      addEventListener('touchend', touchEvent => this.touchEnd(touchEvent, posXStart, posYStart), { once: true });
    }
  }
  touchEnd(touchEvent, posXStart, posYStart) {
    if (touchEvent.changedTouches.length === 1) {
      const posXEnd = touchEvent.changedTouches[0].clientX;
      const posYEnd = touchEvent.changedTouches[0].clientY;

      const swippedRight = posXEnd - posXStart > 100;
      const swippedVertically = Math.abs(posYEnd - posYStart) > 50;

      if (swippedRight && !swippedVertically) {
        this.$emit('toggle-rooms-list');
      }
    }
  }
  onRoomChanged() {
    this.isFirstCall=true;
    this.loadingMessages = true;
    this.scrollIcon = false;
    this.scrollMessagesCount = 0;
    //this.scrollMessagesCount = this.room.unreadCount; set on message Add, to avoid call first
    this.resetMessageSelection();

    if (!this.messages.length && this.messagesLoaded) {
      this.loadingMessages = false;
    }

    const unwatch = this.$watch(
      () => this.messages,
      val => {
        if (!val || !val.length) return;

        const element = this.$refs.scrollContainer;
        if (!element) return;

        unwatch();

        setTimeout(() => {
           element.scrollTo({ top: element.scrollHeight });
          this.loadingMessages = false;
        });
      }
    );
  }
  resetMessageSelection() {
    this.messageSelectionEnabled = false;
    this.selectedMessages = [];
  }
  selectMessage(message) {
    this.selectedMessages.push(message);
  }
  unselectMessage(messageId) {
    this.selectedMessages = this.selectedMessages.filter(message => message._id !== messageId);
  }
  onMessageAdded({ message, index, ref }) {
    if (index !== this.messages.length - 1) return;

    const autoScrollOffset = ref.offsetHeight + 60;

    setTimeout(() => {


       if (this.isFirstCall && (this.isScrollToMessageId()){
          this.isFirstCall=false;
          return;
        }else{
          this.isFirstCall=false;
        }


      const scrolledUp = this.getBottomScroll(this.$refs.scrollContainer) > autoScrollOffset;
      if (message.senderId === this.currentUserId) {
        if (scrolledUp) {
          if (this.autoScroll.send.newAfterScrollUp) {
            this.scrollToBottom();
          }
        } else {
          if (this.autoScroll.send.new) {
            this.scrollToBottom();
          }
        }
      } else {
       //before default
        // if (scrolledUp) {
        //   if (this.autoScroll.receive.newAfterScrollUp) {
        //     this.scrollToBottom();
        //   } else {
        //     this.scrollIcon = true;
        //     this.scrollMessagesCount++;
        //   }
        // } else {
        //   if (this.autoScroll.receive.new) {
        //     this.scrollToBottom();
        //   } else {
        //     this.scrollIcon = true;
        //     this.scrollMessagesCount++;
        //   }
        // }

        //after
        this.scrollToBottom();
      }


    });
  }
  isScrollToMessageId(){
    //start scroll to position messageId
       const to = document.getElementById(this.ROOM_MESSAGE_CONSTANT + this.room.positionScrollMsgId);
       //if (to && this.getCountMsgs != 0 && this.chtVwConversation.lastMsgAuthor != this.currentUserId) {
        if (to  && this.room.unreadCount) {
          this.scrollMessagesCount = this.room.unreadCount;
          console.log("have this.room.unreadCoun")
          const element = <any>this.$refs.scrollContainer;
         element.scrollTo({ top: to.offsetTop - element.clientHeight });
        return true;
      }
    return false;
      //end scroll to position messageId
  }



  onContainerScroll(e) {

    if (!e.target) return;

    const bottomScroll = this.getBottomScroll(e.target);
    if (bottomScroll < 60){
      console.log("bottomScroll < 60");
      if( !this.isFirstCall && this.room.unreadCount !=0 &&  this.scrollMessagesCount !=0){
          let obj:any={};
          obj.action='SET_LATEST_VIEW';
          this.messageActionHandler(obj);
          console.log("SET_LATEST_VIEW");

      }
      this.scrollMessagesCount = 0;

    }

    this.scrollIcon = bottomScroll > 500 || this.scrollMessagesCount;
  }
  loadMoreMessages() {
    if (this.loadingMessages) return;

    setTimeout(
      () => {
        if (this.loadingMoreMessages) return;

        if (this.messagesLoaded || !this.roomId) {
          this.loadingMoreMessages = false;
          this.showLoader = false;
          return;
        }

        this.preventTopScroll();
        const options = { isLoadMore: true };

        this.$emit('fetch-messages', options);
        this.loadingMoreMessages = true;
      },
      // prevent scroll bouncing speed
      500
    );
  }
  messageActionHandler({ action, message, isValueNegate }) {
    switch (action.name) {
      case 'replyMessage':
        this.initReplyMessage = {...message};
        return;
      case 'editMessage':
        this.initEditMessage = message;
        return;
      case 'deleteMessage':
        return this.$emit('delete-message', message);
      case 'selectMessages':
        this.selectedMessages = [message];
        this.messageSelectionEnabled = true;
        return;
      default:
        return this.$emit('message-action-handler', { action, message, isValueNegate });
    }
  }
  messageSelectionActionHandler(action) {
    this.$emit('message-selection-action-handler', {
      action,
      messages: this.selectedMessages,
    });
    this.resetMessageSelection();
  }
  sendMessageReaction(messageReaction) {
    // alert(messageReaction);
    this.$emit('send-message-reaction', { ...messageReaction, messageId: this.selectedMessageId });
  }
  sendMessageSticker(messageSticker, replyMessage) {
    console.log(replyMessage,'>>> ROOM')
    this.$emit('send-message-sticker', { ...messageSticker, messageId: this.selectedMessageId, replyMessage:replyMessage});
  }

  getBottomScroll(element) {
    const { scrollHeight, clientHeight, scrollTop } = element;
    return scrollHeight - clientHeight - scrollTop;
  }
  scrollToBottom() {
    setTimeout(() => {
      const element = <any>this.$refs.scrollContainer;
      element.classList.add('vac-scroll-smooth');
      element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
      setTimeout(() => element.classList.remove('vac-scroll-smooth'));
    }, 50);
  }
  openFile({ message, file }) {
    this.$emit('open-file', { message, file });
  }
  openUserTag(user) {
    this.$emit('open-user-tag', user);
  }

  scrollToMessageId(msgId) { // call from search
  const to = document.getElementById(this.ROOM_MESSAGE_CONSTANT + msgId);
  if (to) {
    setTimeout(() => {
      const element =  <any>this.$refs.scrollContainer;
      element.classList.add('vac-scroll-smooth');
      element.scrollTo({ top: to.offsetTop - element.clientHeight, behavior: 'smooth' });

      setTimeout(() => {
        element.classList.remove('vac-scroll-smooth');
        to.classList.add('vac-scroll-actived');
        setTimeout(() => {
          to.classList.remove('vac-scroll-actived');
        }, 2000);

      }, 50);
    }, 50);

    return true;
  }

  return false;
}
  // },
  askmeQuestionAgain() {
    this.$emit('ask-me-question-again');
  }
}
