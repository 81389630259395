import Icon from '@/shared/icons/icon.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
  },
})
export default class CFpInformation extends Vue {
  // ================= START SERVICES =================
  @Prop({ default: '' })
  public infoTitle;
  @Prop({ default: '' })
  public infoPrice;
  @Prop({ default: false })
  public isShowInfoDetail;
  @Prop({ default: '' })
  public infoDetail;
  @Prop({ default: false })
  public isShowReadMore;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public toLink() {
    this.$emit('toLink');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
