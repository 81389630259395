var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-item-time-region-container px-3 d-flex align-items-center py-2 overflow-hidden border-grey-tertiary-bottom-1 custom-time-wrapper",
    },
    [
      _c(
        "div",
        { staticClass: "mr-2" },
        [
          _vm.index == 0
            ? [
                _c("c-item-time-region", {
                  staticClass: "px-3 pb-1",
                  attrs: {
                    time: _vm.cbUserTimeZone.userZoneDateTime,
                    timeZoneId: _vm.cbUserTimeZone.id,
                    isThirdClock: false,
                    width: "7rem",
                  },
                }),
              ]
            : [
                _c("c-item-time-region", {
                  staticClass: "custom-time px-3 pb-1",
                  attrs: {
                    time: _vm.cbUserTimeZone.userZoneDateTime,
                    timeZoneId: _vm.cbUserTimeZone.id,
                    isThirdClock: true,
                    width: "7rem",
                  },
                  on: {
                    deleteTimeRegion: function ($event) {
                      return _vm.$emit("deleteTimeRegion", $event)
                    },
                  },
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mx-auto" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            {
              staticClass: "pr-1 d-flex flex-column",
              staticStyle: {
                "border-right": "1px solid var(--cj-color-grey-tertiary)",
              },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "ml-auto mb-auto font-roboto cj-color-black-primary font-size-12 font-weight-500 text-ellipsis oneline",
                  staticStyle: { "margin-top": "3px !important" },
                },
                [
                  _vm.index == 0
                    ? [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cbUserTimeZone.timeFormat) +
                            "\n          "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cbUserTimeZone.timeFormat) +
                            "\n          "
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "ml-auto mt-auto font-roboto cj-color-black-primary font-size-9 font-weight-500 text-ellipsis oneline",
                },
                [
                  _vm.index == 0
                    ? [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cbUserTimeZone.dateFormat) +
                            "\n          "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.cbUserTimeZone.dateFormat) +
                            "\n          "
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "ml-1 d-flex flex-column" }, [
            _c(
              "p",
              {
                staticClass:
                  "mb-auto font-roboto font-weight-700 font-size-14 cj-color-black-primary text-ellipsis oneline",
              },
              [
                _vm.index == 0
                  ? [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cbUserTimeZone.timeZoneCode) +
                          "\n          "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cbUserTimeZone.timeZoneCode) +
                          "\n          "
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "mt-auto font-roboto font-weight-500 font-size-9 cj-color-black-primary text-ellipsis oneline",
              },
              [
                _vm.index == 0
                  ? [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cbUserTimeZone.timeZoneName) +
                          "\n          "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.cbUserTimeZone.timeZoneName) +
                          "\n          "
                      ),
                    ],
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.index >= 1
          ? _c(
              "div",
              {
                staticClass:
                  "custom-control custom-radio cb-custom-radio my-auto custom-d-web",
                class: _vm.index == 0 ? "d-none" : "d-flex ",
                attrs: { "data-cy": "advance-search-delivery-time-wrapper" },
              },
              [
                _c("input", {
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: `customRadio1` + _vm.index,
                    name: "customRadio",
                  },
                  domProps: { checked: _vm.cbUserTimeZone.isActive == true },
                  on: {
                    change: function ($event) {
                      return _vm.$emit(
                        "handleSetDefaultTimeZone",
                        $event.target.checked,
                        _vm.cbUserTimeZone
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: `customRadio1` + _vm.index },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto font-size-12 cj-color-black-primary m-0",
                      },
                      [_vm._v("Set Default")]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }