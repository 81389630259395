import { ICbPawOrderFile } from './cb-paw-order-file.model';
import { ICbTextRestrict } from './cb-text-restrict.model';
import { CbReminderValueEnum } from '@/shared/model/enumerations/cb-reminder-value-enum.model';
import { CbLocation } from './cb-location.model';
import { CbCommonStatus } from './enumerations/cb-common-status.model';

export interface ICbVwPawOrderRequirement {
  id?: number;
  seq?: number;
  requirementType?: string | null;
  title?: string | null;
  pawTitle?: string | null;
  labelOrderId?: string | null;
  labelPaymentId?: string | null;
  svcType?: string | null;
  svcName?: string | null;
  svcCategoryName?: string | null;
  orderType?: string | null;
  status?: CbCommonStatus | null;
  descriptionId?: number | null;
  partnerId?: string | null;
  cbPawOrderId?: number | null;
  refRequirementId?: number | null;
  refRequirementDescriptionId?: number | null;
  buyerId?: string | null;
  fileDetails?: string | null;
  createdDate?: Date | null;
  paymentStatus?: string | null;
  currency?: string | null;
  amount?: number | null;
  extraDetails?: string | null;
  offerExpiredDate?: Date | null;
  revisionExtraOfferingDate?: Date | null;
  progressProject?: number | null;
  offerExpired?: number | null;
  deliveryTime?: number | null;
  deliveryTimeType?: string | null;
  toWorkLocations?: string | null;
  bookStartDate?: Date | null;
  bookEndDate?: Date | null;
  revisionTotal?: number | null;
  revisionUsed?: number | null;
  reminderValue?: CbReminderValueEnum | null;
  reminderDate?: Date | null;
  isBuyerRead?: boolean | null;
  isPartnerRead?: boolean | null;
  cbPawOrderFiles?: ICbPawOrderFile[] | null;
  createdDateLabel?: string | null;
  timeZone?: string | null;

  // transient
  description?: ICbTextRestrict | null;
  toWorkLocation?: CbLocation | null;
  cbTextRestrict?: ICbTextRestrict | null;
  completedDateMilestone?: Date | string | null;
  acceptDirectlyMom?: boolean | null;
}

export class CbVwPawOrderRequirement implements ICbVwPawOrderRequirement {
  constructor(
    public id?: number,
    public seq?: number,
    public requirementType?: string | null,
    public title?: string | null,
    public pawTitle?: string | null,
    public labelOrderId?: string | null,
    public labelPaymentId?: string | null,
    public svcType?: string | null,
    public svcName?: string | null,
    public svcCategoryName?: string | null,
    public orderType?: string | null,
    public status?: CbCommonStatus | null,
    public descriptionId?: number | null,
    public partnerId?: string | null,
    public cbPawOrderId?: number | null,
    public refRequirementId?: number | null,
    public refRequirementDescriptionId?: number | null,
    public buyerId?: string | null,
    public fileDetails?: string | null,
    public createdDate?: Date | null,
    public paymentStatus?: string | null,
    public currency?: string | null,
    public amount?: number | null,
    public extraDetails?: string | null,
    public offerExpiredDate?: Date | null,
    public revisionExtraOfferingDate?: Date | null,
    public progressProject?: number | null,
    public offerExpired?: number | null,
    public deliveryTime?: number | null,
    public deliveryTimeType?: string | null,
    public toWorkLocations?: string | null,
    public bookStartDate?: Date | null,
    public bookEndDate?: Date | null,
    public revisionTotal?: number | null,
    public revisionUsed?: number | null,
    public reminderValue?: CbReminderValueEnum | null,
    public reminderDate?: Date | null,
    public isBuyerRead?: boolean | null,
    public isPartnerRead?: boolean | null,
    public cbPawOrderFiles?: ICbPawOrderFile[] | null,
    public createdDateLabel?: string | null,
    public timeZone?: string | null,

    // transient
    public description?: ICbTextRestrict | null,
    public toWorkLocation?: CbLocation | null,
    public cbTextRestrict?: ICbTextRestrict | null,
    public completedDateMilestone?: Date | string | null,
    public acceptDirectlyMom?: boolean | null
  ) {}
}
