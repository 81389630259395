var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-order-pd-item d-flex",
      attrs: {
        "data-cy": _vm.dataCyCustom
          ? `item-${_vm.dataCyCustom}`
          : "project-item",
      },
    },
    [
      _c(
        "div",
        { staticClass: "mr-2 mr-sm-3 img-size" },
        [
          _c("c-upload-file-icon", {
            staticClass: "img-size",
            attrs: {
              "data-cy-custom": _vm.dataCyCustom
                ? `file-${_vm.dataCyCustom}`
                : "file-icon",
              file: _vm.fileAtt.attContentType,
              fileUrl: _vm.fileAtt.localUrl,
              fileThumbnail: _vm.fileAtt.downloadLink
                ? _vm.fileAtt.downloadLink
                : _vm.fileAtt.thumbnailLocalUrl,
              fileSize: _vm.fileAtt.attSize,
              isShowVideo: false,
              isVideoThumbnailImg: true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow-1 mr-2 my-auto w-40" }, [
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-weight-500 font-size-16 text-ellipsis-oneline",
            class: {
              "font-size-16":
                _vm.isDeviceSize === _vm.deviceSizeType.XL ||
                _vm.isDeviceSize === _vm.deviceSizeType.LG,
              "font-size-14": _vm.isDeviceSize === _vm.deviceSizeType.MD,
              "font-size-12": _vm.isDeviceSize === _vm.deviceSizeType.SM,
            },
            attrs: {
              "data-cy": _vm.dataCyCustom
                ? `file-name-${_vm.dataCyCustom}`
                : "file-name-delivery",
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.fileName) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-weight-400 font-size-10 text-ellipsis-oneline",
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.formatFromBytes(_vm.fileAtt?.attSize)) +
                "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "button",
          {
            staticClass: "my-auto",
            attrs: {
              type: "button",
              "data-cy": _vm.dataCyCustom
                ? `btn-download-${_vm.dataCyCustom}`
                : "btn-download-file-delivery",
            },
            on: {
              click: function ($event) {
                return _vm.handleDownloadFileDelivery(_vm.fileAtt)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "cj-fill-red-primary icon-size",
              attrs: { name: "ic_download", width: "36px", height: "36px" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }