import { Component, Prop, Vue } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import { isVideoFile } from '@/shared/media-file/media-file';
@Component({
  components: {Icon},
})
export default class CPdPortfolioList extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({default:[]})
  public cbVwPawDetailAttPortfolios;
  @Prop({default:[]})
  public countPortfolio;

  public isShowVideo = false;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isVideo() {
    return attContentType => {
      this.isShowVideo = isVideoFile(attContentType);
      return this.isShowVideo;
    };
  }
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
