import axios from 'axios';

import { ICbPawOrderExtra } from '@/shared/model/cb-paw-order-extra.model';

const baseApiUrl = 'services/cbcorems/api/cb-paw-order-extras';

export default class PCbPawOrderExtraService {
  public create(entity: ICbPawOrderExtra): Promise<ICbPawOrderExtra> {
    return new Promise<ICbPawOrderExtra>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
