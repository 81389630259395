var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "feedback-chat-message" },
    [
      _c(
        "p",
        {
          staticClass:
            "font-roboto coloe-bali font-size-14 cj-color-black-primary font-weight-700",
          domProps: { textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.helpCb")) },
        },
        [_vm._v("\n    Help Klikjob Improve Your Experience\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c(
          "p",
          {
            staticClass:
              "font-catamaran font-weight-400 font-size-14 cj-color-black-primary mb-2",
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.descHowSatisfied")),
            },
          },
          [
            _vm._v(
              "\n      How satisfied are you with Klikjob Customer Support?\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex mt-3 mb-4" }, [
          _c(
            "div",
            { staticClass: "custom-radio-button m-auto" },
            _vm._l(5, function (item) {
              return _c("div", { key: item }, [
                _c("input", {
                  attrs: {
                    "data-cy": "feedback-satisfied-radio",
                    type: "radio",
                    id: `radio-${item}`,
                    name: "feedbackLvl",
                  },
                  domProps: {
                    value: _vm.$v.cbHelpCenterFeedback.feedBackLvl.$model,
                  },
                  on: {
                    click: function ($event) {
                      _vm.cbHelpCenterFeedback.feedBackLvl = item
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: `radio-${item}` } }),
              ])
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c(
            "p",
            {
              staticClass:
                "font-weight-400 cj-color-black-primary font-size-10 font-catamaran",
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.notSatisfied")),
              },
            },
            [_vm._v("\n        Not Satisfied At All\n      ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "font-weight-400 cj-color-black-primary font-size-10 font-catamaran",
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.verySatisfied")),
              },
            },
            [_vm._v("Very Satisfied")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", {}, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.cbHelpCenterFeedback.description.$model,
                expression: "$v.cbHelpCenterFeedback.description.$model",
              },
            ],
            ref: "roomTextarea",
            staticClass: "vac-textarea",
            style: {
              "min-height": `120px`,
              "padding-left": `12px`,
              "max-height": "160px",
              overflow: "scroll",
            },
            attrs: {
              id: "roomTextarea",
              placeholder: _vm.$t("cbgwApp.cbSurvey.descPlaceholder"),
            },
            domProps: { value: _vm.$v.cbHelpCenterFeedback.description.$model },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.$v.cbHelpCenterFeedback.description,
                  "$model",
                  $event.target.value
                )
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "c-button",
        {
          staticClass: "px-5",
          attrs: {
            disabled: _vm.isSaving || _vm.$v.cbHelpCenterFeedback.$invalid,
            fullWidth: "",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.sendFeedback")),
              },
            },
            [_vm._v("Send Feedback")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "p",
      {
        staticClass:
          "font-weight-400 cj-color-black-primary font-size-14 font-catamaran mb-2",
      },
      [
        _vm._v("\n      Tell us how we can improve "),
        _c("span", { staticClass: "cj-color-grey-tertiary" }, [
          _vm._v("(optional)"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }