import axios from 'axios';

import { ICbWithdraw } from '@/shared/model/cb-withdraw.model';

const baseApiUrl = 'services/cbuserms/api/cb-withdraws';

export default class PCbWithdrawService {
  public create(entity: ICbWithdraw): Promise<ICbWithdraw> {
    return new Promise<ICbWithdraw>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
