import {Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class CIcons extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public name: string;
  @Prop({ default: '24px' }) public width: string;
  @Prop({ default: '24px' }) public height: string;
  @Prop({ default: '#B3B3B3 ' }) public customColor: string;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
