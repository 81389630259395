import { Prop, Mixins } from 'vue-property-decorator';
import Component from 'vue-class-component';
import VideoTakeStream from '../../components/c-take-picture/components/VideoTakeStream.vue';
import CIcons from '../c-icons/c-icons.vue';
import CButton from '@/components/button/c-button.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
@Component({
  components: {
    VideoTakeStream,
    CIcons,
    CButton,
  },
})
export default class CTakePicture extends Mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false })
  public switchCameraRecord: boolean;

  @Prop({ default: 'Send to Chat' }) btnTextConfirm: string;
  public imageSrc: any = null;
  public video: any;
  public canvas: any;
  public isPause = false;
  public videoRefOne: any;
  public currentLanguage = localStorage.getItem('currentLanguage');

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  mounted() {
    this.videoRefOne = <any>this.$refs.video ? this.$refs.video : {};
    this.video = this.videoRefOne.$refs.video;
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public stopPicture() {
    this.isPause = false;

    this.videoRefOne.camera = 'stop';
  }

  public resetTakePicture() {
    this.videoRefOne.camera = 'on';
    this.isPause = false;
    this.imageSrc = null;
    this.showSwitchCameraRecord();
  }

  public submitPicture() {
    this.imageSrc = this.videoRefOne.$refs.pauseFrame.toDataURL('image/png');
    this.$emit(
      'submitPicture',
      this.imageSrc.split(';base64,')[1],
      'image/png',
      'png',
      this.base64ToBlobString(this.imageSrc.split(';base64,')[1], 'image/png'),
      this.dataURItoBlob(this.imageSrc),
      this.imageSrc.split(';base64,')[1].length
    );
  }

  public takePicture() {
    if (this.video.videoWidth == 0) {
      //not ready
      this.$root.$bvToast.toast('Not Ready', {
        toaster: 'b-toaster-top-center',
        title: 'Info',
        variant: 'warning',
        solid: true,
        autoHideDelay: 500,
      });

      return;
    }

    this.videoRefOne.camera = 'off';
    this.isPause = true;
    this.closeSwitchCameraRecord();
  }

  public closeSwitchCameraRecord() {
    this.$emit('closeShowSwitchCameraRecord', null);
  }

  public showSwitchCameraRecord() {
    this.$emit('isShowSwitchCameraRecord', null);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
