var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value:
            (_vm.isMobile && !_vm.showRoomsList) ||
            !_vm.isMobile ||
            _vm.singleRoom,
          expression: "(isMobile && !showRoomsList) || !isMobile || singleRoom",
        },
      ],
      staticClass: "vac-col-messages",
      on: { touchstart: _vm.touchStart },
    },
    [
      !_vm.roomId
        ? _c(
            "div",
            { staticClass: "h-100 w-100", attrs: { "data-cy": "room" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "no-msg-container empty-chat cj-bg-white-primary wrap-border-grey-tertiary w-100 h-100 d-flex justify-content-center align-items-center",
                },
                [
                  _c("img", {
                    staticClass: "h-100 w-100",
                    staticStyle: {
                      "object-fit": "contain",
                      "max-width": "37rem",
                      "max-height": "37rem",
                    },
                    attrs: {
                      src: `${_vm.$env.CDN_CB}/content/cb-img/ic-empty-chat.png`,
                      alt: "empty_chat",
                    },
                  }),
                ]
              ),
            ]
          )
        : [
            _vm.showNoRoom
              ? _vm._t("no-room-selected", function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "vac-container-center vac-room-empty" },
                      [_c("div", [_vm._v(_vm._s(_vm.textMessages.ROOM_EMPTY))])]
                    ),
                  ]
                })
              : _c("room-header", {
                  attrs: {
                    "current-user-id": _vm.currentUserId,
                    "user-ids-online": _vm.userIdsOnline,
                    "text-messages": _vm.textMessages,
                    "single-room": _vm.singleRoom,
                    "show-rooms-list": _vm.showRoomsList,
                    "is-mobile": _vm.isMobile,
                    "room-info-enabled": _vm.roomInfoEnabled,
                    "menu-actions": _vm.menuActions,
                    room: _vm.room,
                    "message-selection-enabled": _vm.messageSelectionEnabled,
                    "message-selection-actions": _vm.messageSelectionActions,
                    "selected-messages-total": _vm.selectedMessages.length,
                  },
                  on: {
                    "toggle-rooms-list": function ($event) {
                      return _vm.$emit("toggle-rooms-list")
                    },
                    "room-info": function ($event) {
                      return _vm.$emit("room-info")
                    },
                    "menu-action-handler": function ($event) {
                      return _vm.$emit("menu-action-handler", $event)
                    },
                    "message-selection-action-handler":
                      _vm.messageSelectionActionHandler,
                    "cancel-message-selection": function ($event) {
                      _vm.messageSelectionEnabled = false
                    },
                    handleShowOption: function ($event) {
                      return _vm.$emit("handleShowOption", $event)
                    },
                    showInfoPanel: function ($event) {
                      return _vm.$emit("showInfoPanel", $event)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (i, name) {
                        return {
                          key: name,
                          fn: function (data) {
                            return [_vm._t(name, null, null, data)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "scrollContainer",
                staticClass: "vac-container-scroll",
                attrs: {
                  id: "messages-list",
                  "data-cy": "vac-container-scroll",
                },
                on: { scroll: _vm.onContainerScroll },
              },
              [
                _c("loader", {
                  attrs: { show: _vm.loadingMessages },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (idx, name) {
                        return {
                          key: name,
                          fn: function (data) {
                            return [_vm._t(name, null, null, data)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("div", { staticClass: "vac-messages-container" }, [
                  _c(
                    "div",
                    { class: { "vac-messages-hidden": _vm.loadingMessages } },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "vac-fade-message" } },
                        [
                          _c("div", [
                            _vm.showNoMessages
                              ? _c(
                                  "div",
                                  { staticClass: "vac-text-started" },
                                  [
                                    _vm._t("messages-empty", function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-cy": "vac-no-message-info",
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t("chat.noMessages")
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.textMessages.MESSAGES_EMPTY
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showMessagesStarted
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vac-text-started",
                                    attrs: {
                                      "data-cy":
                                        "vac-message-conversation-info",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t("chat.conversationStarted")
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.textMessages
                                              .CONVERSATION_STARTED
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.messages[0].date)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.messages.length && !_vm.messagesLoaded
                        ? _c(
                            "div",
                            { attrs: { id: "infinite-loader-messages" } },
                            [
                              _c("loader", {
                                attrs: { show: true, infinite: true },
                                scopedSlots: _vm._u(
                                  [
                                    _vm._l(
                                      _vm.$scopedSlots,
                                      function (idx, name) {
                                        return {
                                          key: name,
                                          fn: function (data) {
                                            return [
                                              _vm._t(name, null, null, data),
                                            ]
                                          },
                                        }
                                      }
                                    ),
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "transition-group",
                        {
                          key: _vm.roomId,
                          attrs: { name: "vac-fade-message", tag: "span" },
                        },
                        [
                          _vm._l(_vm.messages, function (m, i) {
                            return [
                              m.chtConversationId == _vm.roomId
                                ? _c(
                                    "div",
                                    {
                                      key: i,
                                      staticStyle: {
                                        position: "sticky",
                                        top: "1rem",
                                        "z-index": "3",
                                      },
                                    },
                                    [
                                      _c("room-message-date", {
                                        attrs: {
                                          message: m,
                                          index: i,
                                          messages: _vm.messages,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              m.chtConversationId == _vm.roomId
                                ? _c(
                                    "div",
                                    {
                                      key: m.indexId || m._id,
                                      attrs: { "data-cy": "text-message" },
                                    },
                                    [
                                      !m.deleted
                                        ? [
                                            _c("room-message", {
                                              ref:
                                                _vm.ROOM_MESSAGE_CONSTANT +
                                                m.id,
                                              refInFor: true,
                                              attrs: {
                                                id:
                                                  _vm.ROOM_MESSAGE_CONSTANT +
                                                  m.id,
                                                "current-user-id":
                                                  _vm.currentUserId,
                                                message: m,
                                                index: i,
                                                messages: _vm.messages,
                                                "edited-message-id":
                                                  _vm.editedMessageId,
                                                "message-actions":
                                                  _vm.messageActions,
                                                "room-users": _vm.room.users,
                                                isShowAgents: _vm.isShowAgents,
                                                "text-messages":
                                                  _vm.textMessages,
                                                "new-messages": _vm.newMessages,
                                                "show-reaction-emojis":
                                                  _vm.showReactionEmojis,
                                                "show-new-messages-divider":
                                                  _vm.showNewMessagesDivider,
                                                "text-formatting":
                                                  _vm.textFormatting,
                                                "link-options": _vm.linkOptions,
                                                "username-options":
                                                  _vm.usernameOptions,
                                                "message-selection-enabled":
                                                  _vm.messageSelectionEnabled,
                                                "selected-messages":
                                                  _vm.selectedMessages,
                                              },
                                              on: {
                                                "message-added":
                                                  _vm.onMessageAdded,
                                                "message-action-handler":
                                                  _vm.messageActionHandler,
                                                "open-file": _vm.openFile,
                                                "open-user-tag":
                                                  _vm.openUserTag,
                                                "open-failed-message":
                                                  function ($event) {
                                                    return _vm.$emit(
                                                      "open-failed-message",
                                                      $event
                                                    )
                                                  },
                                                "send-message-reaction":
                                                  _vm.sendMessageReaction,
                                                "select-message":
                                                  _vm.selectMessage,
                                                "unselect-message":
                                                  _vm.unselectMessage,
                                                handleShowEmotReactions:
                                                  _vm.handleShowEmotReactions,
                                                deleteMessageReaction:
                                                  function ($event) {
                                                    return _vm.$emit(
                                                      "deleteMessageReaction",
                                                      $event
                                                    )
                                                  },
                                                searchMsgResultSelected:
                                                  function ($event) {
                                                    return _vm.$emit(
                                                      "searchMsgResultSelected",
                                                      $event
                                                    )
                                                  },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  _vm._l(
                                                    _vm.$scopedSlots,
                                                    function (idx, name) {
                                                      return {
                                                        key: name,
                                                        fn: function (data) {
                                                          return [
                                                            _vm._t(
                                                              name,
                                                              null,
                                                              null,
                                                              data
                                                            ),
                                                          ]
                                                        },
                                                      }
                                                    }
                                                  ),
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.loadingMessages
              ? _c(
                  "div",
                  [
                    _c("transition", { attrs: { name: "vac-bounce" } }, [
                      _vm.scrollIcon
                        ? _c(
                            "div",
                            {
                              staticClass: "vac-icon-scroll",
                              attrs: { "data-cy": "vac-icon-scroll" },
                              on: { click: _vm.scrollToBottom },
                            },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "vac-bounce" } },
                                [
                                  _vm.scrollMessagesCount
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vac-badge-counter vac-messages-count color-yellow actived-max-count",
                                          class: _vm.scrollMessagesCount
                                            ? "actived-max-count"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.scrollMessagesCount) +
                                              "\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._t("scroll-icon", function () {
                                return [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "dropdown",
                                      param: "scroll",
                                    },
                                  }),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("room-footer", {
              attrs: {
                room: _vm.room,
                "room-id": _vm.roomId,
                "room-message": _vm.roomMessage,
                "text-messages": _vm.textMessages,
                "show-send-icon": _vm.showSendIcon,
                "show-files": _vm.showFiles,
                "show-audio": _vm.showAudio,
                "show-emojis": _vm.showEmojis,
                "show-footer": _vm.showFooter,
                "accepted-files": _vm.acceptedFiles,
                "textarea-action-enabled": _vm.textareaActionEnabled,
                "textarea-auto-focus": _vm.textareaAutoFocus,
                "user-tags-enabled": _vm.userTagsEnabled,
                "emojis-suggestion-enabled": _vm.emojisSuggestionEnabled,
                "templates-text": _vm.templatesText,
                "text-formatting": _vm.textFormatting,
                "link-options": _vm.linkOptions,
                "audio-bit-rate": _vm.audioBitRate,
                "audio-sample-rate": _vm.audioSampleRate,
                "init-reply-message": _vm.initReplyMessage,
                "init-edit-message": _vm.initEditMessage,
                isShowEmotReactions: _vm.isShowEmotReactions,
                "cb-stickers": _vm.cbStickers,
                "is-closed": _vm.isClosed,
                isShowAgents: _vm.isShowAgents,
              },
              on: {
                "update-edited-message-id": function ($event) {
                  _vm.editedMessageId = $event
                },
                "edit-message": function ($event) {
                  return _vm.$emit("edit-message", $event)
                },
                "send-message": function ($event) {
                  return _vm.$emit("send-message", $event)
                },
                "send-message-sticker": _vm.sendMessageSticker,
                "typing-message": function ($event) {
                  return _vm.$emit("typing-message", $event)
                },
                "textarea-action-handler": function ($event) {
                  return _vm.$emit("textarea-action-handler", $event)
                },
                closeShowEmotReactions: _vm.closeShowEmotReactions,
                "send-message-reaction": _vm.sendMessageReaction,
                "ask-me-question-again": _vm.askmeQuestionAgain,
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function (idx, name) {
                    return {
                      key: name,
                      fn: function (data) {
                        return [_vm._t(name, null, null, data)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }