var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "items-chat-message" },
    [
      _vm.messageChtContentType == _vm.ChtContentType.ORDER_REQUIREMENT
        ? [
            !_vm.cbVwPawOrder &&
            !_vm.cbVwPawOrderRequirement &&
            !_vm.cbTextRestrict
              ? _c("div", { staticClass: "message-500-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-paw-order", {
                  ref: "c-item-chat-message-paw-order",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbTextRestrict: _vm.cbTextRestrict,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType == _vm.ChtContentType.ORDER_MOM
        ? [
            !_vm.cbVwPawOrder &&
            !_vm.cbVwPawOrderRequirement &&
            !_vm.cbTextRestrict
              ? _c("div", { staticClass: "message-400-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-mom", {
                  ref: "c-item-chat-message-mom",
                  attrs: {
                    "data-cy": "chat-message-mom",
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                    cbTextRestrict: _vm.cbTextRestrict,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType == _vm.ChtContentType.ORDER_MILESTONE
        ? [
            !_vm.cbVwPawOrder && !_vm.cbVwPawOrderRequirement
              ? _c("div", { staticClass: "message-200-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-milestone", {
                  ref: "c-item-chat-message-milestone",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType == _vm.ChtContentType.ORDER_PROJECT_DELIVERY
        ? [
            !_vm.cbVwPawOrder && !_vm.cbVwPawOrderRequirement
              ? _c("div", { staticClass: "message-200-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-project-delivery", {
                  ref: "c-item-chat-message-project-delivery",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.ORDER_REVISION
        ? [
            !_vm.cbVwPawOrderRequirement && !_vm.cbTextRestrict
              ? _c("div", { staticClass: "message-300-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-revision", {
                  ref: "c-item-chat-message-revision",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                    cbTextRestrict: _vm.cbTextRestrict,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.ORDER_REVISION_OFFER
        ? [
            !_vm.cbVwPawOrderRequirement && !_vm.cbTextRestrict
              ? _c("div", { staticClass: "message-350-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-revision-offer", {
                  ref: "c-item-chat-message-revision-offer",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                    cbTextRestrict: _vm.cbTextRestrict,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.ORDER_CLOCK_IN
        ? [
            !_vm.cbVwPawOrderRequirement
              ? _c("div", { staticClass: "message-300-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-clockin", {
                  ref: "c-item-chat-message-clockin",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.ORDER_CLOCK_OUT
        ? [
            !_vm.cbVwPawOrderRequirement
              ? _c("div", { staticClass: "message-250-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-clockout", {
                  ref: "c-item-chat-message-clockout",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.PROJECT_AUCTION
        ? [
            !_vm.cbVwAuctionProjectPreOffer
              ? _c("div", { staticClass: "message-350-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-auction-project", {
                  ref: "c-item-chat-message-auction-project",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwAuctionProjectPreOffer: _vm.cbVwAuctionProjectPreOffer,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.PAW
        ? [
            !_vm.cbVwPaw && !_vm.cbPawPreOrder
              ? _c("div", { staticClass: "message-450-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-interest", {
                  ref: "c-item-chat-message-interest",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbPawPreOrder: _vm.cbPawPreOrder,
                    cbVwPaw: _vm.cbVwPaw,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.ORDER_ON_MY_WAY
        ? [
            !_vm.cbVwPawOrderRequirement
              ? _c("div", { staticClass: "message-400-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-onmyway", {
                  ref: "c-item-chat-message-onmyway",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                  },
                }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.messageChtContentType === _vm.ChtContentType.ORDER_APPOINTMENT
        ? [
            !_vm.cbVwPawOrderRequirement && !_vm.cbTextRestrict
              ? _c("div", { staticClass: "message-450-55-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "position-loading" },
                    [
                      _c("c-loader", {
                        staticClass: "d-flex",
                        attrs: { show: true, infinite: true },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("c-item-chat-message-appointment", {
                  ref: "c-item-chat-message-appointment",
                  attrs: {
                    isYourSelf: _vm.isYourSelf,
                    cbVwPawOrder: _vm.cbVwPawOrder,
                    cbVwPawOrderRequirement: _vm.cbVwPawOrderRequirement,
                    cbTextRestrict: _vm.cbTextRestrict,
                  },
                }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }