export enum ModalStepFormOrderType {
  isShowSelectVwPaw = 'isShowSelectVwPaw',
  isShowSelectPackage = 'isShowSelectPackage',
  isShowPkgCustom = 'isShowPkgCustom',
  isShowAddExtras = 'isShowAddExtras',
  isShowOfferSummary = 'isShowOfferSummary',
  isShowMilestone = 'isShowMilestone',
  isShowMilestoneFile = 'isShowMilestoneFile',
  isShowUploadProjectDelivery = 'isShowUploadProjectDelivery',
  isShowProjectDelivery = 'isShowProjectDelivery',
  isShowMomEdit = 'isShowMomEdit',
  isShowMomPreview = 'isShowMomPreview',
  isShowPawBookingDate = 'isShowPawBookingDate',
  isShowWorkLocation = 'isShowWorkLocation',
  isShowAppointmentReminder = 'isShowAppointmentReminder',
  isShowAppointmentForm = 'isShowAppointmentForm',
  isShowAppointmentSummary = 'isShowAppointmentSummary',
  isShowExtendTime = 'isShowExtendTime',
  isShowEditWatermark = 'isShowEditWatermark',
  isShowUploadFieldClockOut = 'isShowUploadFieldClockOut',
  isShowAskRevision = 'isShowAskRevision',
  isShowAddExtraRevision = 'isShowAddExtraRevision',
  isShowOfferSummaryRevision = 'isShowOfferSummaryRevision',
  isShowAppointmentBookingDate = 'isShowAppointmentBookingDate',
  isShowAppointmentLocation = 'isShowAppointmentLocation',
  isShowConfirmationAcceptMom = 'isShowConfirmationAcceptMom',
  isShowConfirmationDeclinedMom = 'isShowConfirmationDeclinedMom',
  isShowConfirmationSendProjectDelivery = 'isShowConfirmationSendProjectDelivery',
}
