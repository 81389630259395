var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "vac-slide-up" } }, [
    _vm.filteredEmojis.length
      ? _c(
          "div",
          {
            staticClass: "vac-emojis-container",
            style: { bottom: `${_vm.footerHeight}px` },
          },
          _vm._l(_vm.filteredEmojis, function (emoji, index) {
            return _c(
              "div",
              {
                key: emoji,
                staticClass: "vac-emoji-element",
                class: { "vac-emoji-element-active": index === _vm.activeItem },
                on: {
                  mouseover: function ($event) {
                    _vm.activeItem = index
                  },
                  click: function ($event) {
                    return _vm.$emit("select-emoji", emoji)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(emoji) + "\n    ")]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }