import { ISocketMessage } from '@/shared/model/socket-message.model';
import { CbVwPawOrder, ICbVwPawOrder } from './shared/model/cb-vw-paw-order.model';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import AlertService from './shared/alert/alert.service';
import PCbUserReviewService from './services/cb-user-review.service';
import { ICbUserReview } from '@/shared/model/cb-user-review.model';
import { ICbHelpCenterFeedback } from './shared/model/cb-help-center-feedback.model';
import { CbExternalType } from './shared/model/enumerations/cb-external-type.model';
import { ICbVwUser } from './shared/model/cb-vw-user.model';
import PCbHelpCenterFeedbackService from './services/cb-help-center-feedback.service';

@Component({
  components: {},
})
export default class AppOrderReview extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbUserReviewService') private pCbUserReviewService: () => PCbUserReviewService;
  @Inject('pCbHelpCenterFeedbackService') private pCbHelpCenterFeedbackService: () => PCbHelpCenterFeedbackService;
  @Inject('alertService') public alertService: () => AlertService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public cbVwPawOrder: ICbVwPawOrder = new CbVwPawOrder();

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  public created() {
    (<any>this.$root).$off('appOrderReview::changeFunction');
    (<any>this.$root).$on('appOrderReview::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public openDialogBalanceDeducatedAfterReview(cbUserReview: ICbUserReview) {
    (<any>this.$root).$emit('appModals::changeFunction', 'closeAll');
    this.saveCbUserReview(cbUserReview);
  }

  public saveCbUserReview(cbUserReview: ICbUserReview): void {
    this.pCbUserReviewService()
      .create(cbUserReview)
      .then(() => {
        const msg: ISocketMessage = {
          externalEntity: 'CbVwPawOrder',
          externalId: this.cbVwPawOrder.id.toString(),
        };
        this.$root.$emit('do::receiveUpdateFromSocket', msg);
        this.$root.$emit('opc::receiveUpdateFromSocket', msg);
        this.$root.$emit('dashboardActiveOrder::receiveUpdateFromSocket', msg);

        this.$root.$emit('appModals::changeValue', 'visibleSurvey', true);
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public saveSurvey(cbHelpCenterFeedback: ICbHelpCenterFeedback) {
    cbHelpCenterFeedback.userId = this.currentUser.id;
    cbHelpCenterFeedback.externalId = this.cbVwPawOrder.id;
    cbHelpCenterFeedback.externalType = CbExternalType.ORDER;
    cbHelpCenterFeedback.isActive = true;
    this.pCbHelpCenterFeedbackService()
      .create(cbHelpCenterFeedback)
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.$root.$emit('appModals::changeFunction', 'closeAll');
        this.$root.$emit('appModals::changeValue', 'visibleConfirmFeedbackSuccess', true);
      });
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
