import { CbFeedBackType } from '@/shared/model/enumerations/cb-feed-back-type.model';
import { CbExternalType } from './enumerations/cb-external-type.model';
export interface ICbHelpCenterFeedback {
  id?: number;
  userId?: string | null;
  feedBack?: CbFeedBackType | null;
  externalId?: number | null;
  isActive?: boolean | null;
  createdDate?: Date | null;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  feedBackLvl?: number | null;
  externalType?: CbExternalType | null;
  description?: string | null;
  authorIdAgent?: string | null;
  createdByTktLiveChat?: string | null;
}

export class CbHelpCenterFeedback implements ICbHelpCenterFeedback {
  constructor(
    public id?: number,
    public userId?: string | null,
    public feedBack?: CbFeedBackType | null,
    public externalId?: number | null,
    public isActive?: boolean | null,
    public createdDate?: Date | null,
    public createdBy?: string | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public feedBackLvl?: number | null,
    public externalType?: CbExternalType | null,
    public description?: string | null,
    public authorIdAgent?: string | null,
    public createdByTktLiveChat?: string | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
