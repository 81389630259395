export interface ICbAtt {
  att?: string | null; // base64data
  attContentType?: string | null;
  localUrl?: string | null;
  name?: string | null;
  blob?: Blob | null;
  thumbnail?: any | null;
  thumbnailLocalUrl?: string | null;
  size?: number | null;
  extension?: string | null;
  isActive?: boolean | null;
  label?: string | null;

  // transient
  file?: File | null;
  localFileUrl?: string | null;
  thumbnailWatermark?: any | null;
  tmpPdfImages?: any | null;
  idTmp?: string | number | null;
}

export class CbAtt implements ICbAtt {
  constructor(
    public att?: string | null, // base64data
    public attContentType?: string | null,
    public localUrl?: string | null,
    public name?: string | null,
    public blob?: Blob | null,
    public size?: number | null,
    public extension?: string | null,
    public isActive?: boolean | null,
    public label?: string | null,

    // transient
    public file?: File | null,
    public localFileUrl?: string | null,
    public thumbnailWatermark?: any | null,
    public tmpPdfImages?: any | null,
    public idTmp?: string | number | null
  ) {}
}
