var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vac-room-location" }, [
    _c(
      "p",
      {
        staticClass:
          "mb-3 text-center font-roboto cj-color-black-primary font-weight-700 cj-color-black-primary font-size-24",
        domProps: { textContent: _vm._s(_vm.$t("chat.sharedLocation")) },
      },
      [_vm._v("\n    Share Location\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "room-location-container" },
      [
        _c("c-location-map", {
          ref: "cLocationMapRef",
          staticClass: "mb-3 location-wrapper-m",
          attrs: { placeModel: _vm.currentLocation, mapSize: "large" },
          on: { callback: _vm.callBack },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-items-center p-2 info-wrapper" },
          [
            _c("div", { staticClass: "mr-3" }, [
              _c(
                "div",
                { staticClass: "circle-icon-wrapper" },
                [
                  _c("icon", {
                    staticClass: "cj-stroke-red-primary my-auto",
                    attrs: {
                      name: "ic_location",
                      width: "18px",
                      height: "18px",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1 text-description" }, [
              _vm.resultLocation.placeNam ||
              _vm.resultLocation.latGoogle ||
              _vm.resultLocation.longGoogle
                ? _c(
                    "p",
                    {
                      staticClass:
                        "m-0 font-roboto cj-color-black-primary font-weight-500 cj-color-black-primary font-size-16",
                      attrs: { "data-cy": "vac-location-lat-long" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.resultLocation.placeName
                              ? _vm.resultLocation.placeName
                              : _vm.resultLocation.latGoogle +
                                  ", " +
                                  _vm.resultLocation.longGoogle
                          ) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "m-0 font-catamaran cj-color-black-primary font-weight-300 font-size-12",
                },
                [_vm._v(_vm._s(_vm.resultLocation.address))]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex pt-3 btn-room-location-wrapper" }, [
      _c(
        "div",
        { staticClass: "w-100 mr-1" },
        [
          _c(
            "c-button",
            {
              attrs: {
                dataCyCustom: "vac-location-cancel",
                fullWidth: "",
                typeStyle: "secondary",
              },
              domProps: { textContent: _vm._s(_vm.$t("chat.cancel")) },
              nativeOn: {
                click: function ($event) {
                  return _vm.cancel.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n        cancel")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-100 ml-1" },
        [
          _c(
            "c-button",
            {
              attrs: { dataCyCustom: "vac-location-send", fullWidth: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _vm.isLoading
                ? _c("font-awesome-icon", {
                    attrs: { icon: "sync", spin: _vm.isLoading },
                  })
                : _c(
                    "span",
                    { domProps: { textContent: _vm._s(_vm.$t("chat.send")) } },
                    [_vm._v("SEND")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }