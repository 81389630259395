var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-share", attrs: { "data-cy": "modal-share" } },
    [
      _c(
        "div",
        { staticClass: "d-flex mb-4" },
        [
          _c("icon", {
            staticClass: "m-auto cj-stroke-red-primary",
            attrs: {
              name: "ic_share_outlined",
              width: _vm.getIsMobile ? "2.9rem" : "3.375rem",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "text-center font-roboto cj-color-black-primary font-weight-700",
          class: _vm.getIsMobile ? "font-size-20" : "font-size-24",
        },
        [_vm._v("\n    " + _vm._s(_vm.shareData.title) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "text-center cj-color-black-primary font-weight-500 font-catamaran mt-2",
          class: _vm.getIsMobile ? "font-size-16" : "font-size-18",
        },
        [_vm._v("\n    " + _vm._s(_vm.shareData.description) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex",
          class: _vm.getIsMobile ? "mt-3 mb-2" : "mt-5",
        },
        [
          _c(
            "button",
            {
              staticClass:
                "wrap-border-grey-tertiary m-auto mr-2 rounded-pill p-2",
              attrs: { "data-cy": "social-media-share-twitter" },
              on: {
                click: function ($event) {
                  return _vm.createLogService("TWITTER")
                },
              },
            },
            [
              _c(
                "ShareNetwork",
                {
                  attrs: {
                    network: "Twitter",
                    url: _vm.shareData.url,
                    title: "Awesome Article",
                    description:
                      "This is an awesome article for awesome readers",
                    hashtags: "Frontend, Programming",
                  },
                },
                [
                  _c("icon", {
                    attrs: {
                      name: "ic_twitter",
                      width: _vm.getIsMobile ? "1.5rem" : "2.1rem",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "wrap-border-grey-tertiary m-auto mr-2 rounded-pill p-2",
              attrs: { "data-cy": "social-media-share-linkedin" },
              on: {
                click: function ($event) {
                  return _vm.createLogService("LINKEDIN")
                },
              },
            },
            [
              _c(
                "ShareNetwork",
                {
                  attrs: {
                    network: "LinkedIn",
                    url: _vm.shareData.url,
                    title: "Awesome Article",
                    description:
                      "This is an awesome article for awesome readers",
                    hashtags: "Frontend, Programming",
                  },
                },
                [
                  _c("icon", {
                    attrs: {
                      name: "ic_linkedin",
                      width: _vm.getIsMobile ? "1.5rem" : "2.1rem",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "wrap-border-grey-tertiary m-auto mr-2 rounded-pill p-2",
              attrs: { "data-cy": "social-media-share-facebook" },
              on: {
                click: function ($event) {
                  return _vm.createLogService("FACEBOOK")
                },
              },
            },
            [
              _c(
                "ShareNetwork",
                {
                  attrs: {
                    network: "facebook",
                    url: _vm.shareData.url,
                    title: "Awesome Article",
                    description:
                      "This is an awesome article for awesome readers",
                    hashtags: "Frontend, Programming",
                  },
                },
                [
                  _c("icon", {
                    attrs: {
                      name: "ic_facebook",
                      width: _vm.getIsMobile ? "1.5rem" : "2.1rem",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "wrap-border-grey-tertiary m-auto mr-2 rounded-pill p-2",
              attrs: { "data-cy": "social-media-share-whatsapp" },
              on: {
                click: function ($event) {
                  return _vm.createLogService("WHATSAPP")
                },
              },
            },
            [
              _c(
                "ShareNetwork",
                {
                  attrs: {
                    network: "WhatsApp",
                    url: _vm.shareData.url,
                    title: "Awesome Article",
                    description:
                      "This is an awesome article for awesome readers",
                    hashtags: "Frontend, Programming",
                  },
                },
                [
                  _c("icon", {
                    attrs: {
                      name: "ic_wa",
                      width: _vm.getIsMobile ? "1.5rem" : "2.1rem",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.canCopy
            ? _c(
                "button",
                {
                  staticClass:
                    "wrap-border-grey-tertiary m-auto mr-2 rounded-pill p-2 cursor-pointer cb-tooltip-copy-container",
                  attrs: { "data-cy": "social-media-share-copy" },
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.shareData.url)
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "cj-stroke-red-primary",
                    attrs: {
                      name: "ic_copy",
                      width: _vm.getIsMobile ? "1.3rem" : "1.8rem",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }