import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import GlobalService from '@/services/global.service';
import CNavbarSwitchLogintype from '../navbar/component/c-navbar-switch-logintype/c-navbar-switch-logintype.vue';
import { NavbarType } from '../navbar/c-navbar.component';
import CLogin from '../auth/login/c-login.vue';
import CRegister from '../auth/register/c-register.vue';
import CUserPhotoOnline from '../c-user-photo-online/c-user-photo-online.vue';
import CTimeRegionM from '../c-time-region-m/c-time-region-m.vue';
import { Flutter } from '@/app-flutter';
@Component({
  components: {
    CButton,
    Icon,
    CNavbarSwitchLogintype,
    CLogin,
    CRegister,
    CUserPhotoOnline,
    CTimeRegionM,
  },
})
export default class CNavbarMenu extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop() public timeUtcDisplay;

  @Prop() public timeUtcServer;
  public type: NavbarType = NavbarType.GENERAL;
  public navbarType = NavbarType;
  public t = new Date().getTime();

  public logoutLoader = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    this.logoutLoader = false;
    window.addEventListener('resize', ev => {
      if (window.innerWidth >= 992) {
        this.goBack();
      }
      (<any>this.$root).$emit('showFloatingLiveChat', false);
    });

    this.type = this.$store.getters.loginType;
    (<any>this.$root).$emit('showFloatingLiveChat', false);
    (<any>this.$root).$emit('hideNavbarHideFooter');
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public goBack() {
    if (this.$route.name == 'CNavbarMenu') {
      if (Flutter.isRunOn()) {
        Flutter.call('CNavbarMenuBack', { onFlutterNotRun: () => this.$router.go(-1) });
      } else {
        this.$router.go(-1);
      }
    }
  }

  public logout() {
    this.logoutLoader = true;
    Flutter.call('sendFcmToken');
    this.$emit('select');
    this.globalService().logout();
  }

  public toPage() {
    this.$router.push({ name: 'PIntroductionFreelancer' });
  }
  public openModalLanguage() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-change-language');
  }

  public handleOpenLoginMenu() {
    this.$router.push({ name: 'PLoginM' });
  }
  public handleOpenRegisterMenu() {
    this.$router.push({ name: 'PRegisterM' });
  }
  public toHelpCenter(params) {
    this.$router.push({ name: 'PHelpCenter', params: { info: params }, hash: '#menonaktifkan-akun' });
  }

  public toPages(conditionName: string) {
    let name = '';
    const params: any = {};
    const query: any = {};

    if (this.type == this.navbarType.BUYER) {
      if (conditionName == 'manageRequest') {
        name = 'PBuyerManageRequest';
      } else if (conditionName == 'postRequest') {
        name = 'PBuyerPostRequest';
      } else if (conditionName == 'pawWishlist') {
        name = 'PPawWishlist';
      }
    } else if (this.type == this.navbarType.PARTNER) {
      if (conditionName == 'managePaw') {
        name = 'PPawManage';
      } else if (conditionName == 'createPaw') {
        name = 'PPawCreateOne';
        query['type'] = 'NEW';
      } else if (conditionName == 'findProject') {
        name = 'PFindProject';
        params['projectTab'] = 'recommendation';
      } else if (conditionName == 'projectWishlist') {
        name = 'PFindProject';
        params['projectTab'] = 'wishlist';
      }
    }
    if (conditionName == 'balance') {
      name = 'PAccountBalance';
    } else if (conditionName == 'setting') {
      name = 'PSettings';
    } else if (conditionName == 'billing') {
      name = 'PBillingHisories';
    } else if (conditionName == 'helpCenter') {
      name = 'PHelpCenter';
      params['info'] = 'info';
    } else if (conditionName == 'manageOrder') {
      name = 'PManageOrder';
    } else if (conditionName == 'profile') {
      name = 'PProfile';
    } else if (conditionName == 'dashboard') {
      name = 'PDashboard';
    }

    if (Object.keys(query).length > 0) {
      this.$router.push({ name, query });
    } else {
      this.$router.push({ name, params });
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  public get loginType() {
    return this.$store.getters.loginType;
  }
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
