import axios from 'axios';

const baseApiUrl2 = 'services/cbuserms/api/users';
export default class PUserService {
  public findExist(email?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl2 + '/is-exist/' + email)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findPhoneExist(phoneCode: string, phone: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl2 + '/is-exist-phone/' + phoneCode + '/' + phone)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
