<template>
  <div class="vac-message-actions-wrapper">
    <div
      class="vac-message-action-item-container"
      :class="message.senderId === currentUserId ? 'right-me' : ''"
      :style="{
        display: hoverAudioProgress ? 'none' : 'initial',
        width: filteredMessageActions.length && showReactionEmojis ? '54px' : '45px',
      }"
    >
      <transition-group name="vac-slide-left" tag="span">
        <template v-if="isMessageActions">
          <message-action-item
            key="1"
            @message-action-handler="messageActionHandler"
            :currentUserId="currentUserId"
            :message="message"
            :messageActions="messageActions"
            @handleShowMsgDelete="$emit('handleShowMsgDelete', { ...$event, messageId: message.id })"
            @handleShowReactions="$emit('handleShowEmotReactions', { ...$event, messageId: message.id })"
          ></message-action-item>
        </template>
      </transition-group>
    </div>
    <template v-if="message.senderId === currentUserId"></template>
    <div
      v-else
      class="vac-options-container"
      :style="{
        display: hoverAudioProgress ? 'none' : 'initial',
        width: filteredMessageActions.length && showReactionEmojis ? '24px' : '45px',
      }"
    >
      <div>
        <div
          @click="$emit('message-action-handler', 'TRANSLATE')"
          data-cy="vac-option-translate-btn"
          class="menu-btn d-flex justify-content-center align-items-center cursor-pointer"
        >
          <c-icons name="cic_trasnlate" class="icon-wrapper" width="14px" height="14px" />
        </div>
      </div>
    </div>

    <transition v-if="filteredMessageActions.length" :name="message.senderId === currentUserId ? 'vac-slide-left' : 'vac-slide-right'">
      <div
        v-if="optionsOpened"
        ref="menuOptions"
        v-click-outside="closeOptions"
        class="vac-menu-options"
        :class="{
          'vac-menu-left': message.senderId !== currentUserId,
        }"
        :style="{ top: `${menuOptionsTop}px` }"
      >
        <div class="vac-menu-list">
          <div v-for="action in filteredMessageActions" :key="action.name">
            <div class="vac-menu-item" @click="messageActionHandler(action)">
              {{ action.title }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import EmojiPickerContainer from '../../../../components/EmojiPickerContainer/EmojiPickerContainer';
import MessageActionItem from './message-action-item/message-action-item.vue';
import CIcons from '../../../../components/c-icons/c-icons.vue';
export default {
  name: 'MessageActions',
  components: { SvgIcon, EmojiPickerContainer, MessageActionItem, CIcons },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    messageActions: { type: Array, required: true },
    showReactionEmojis: { type: Boolean, required: true },
    messageHover: { type: Boolean, required: true },
    hoverMessageId: { type: [String, Number], default: null },
    hoverAudioProgress: { type: Boolean, required: true },
  },

  emits: ['update-emoji-opened', 'update-options-opened', 'update-message-hover', 'message-action-handler', 'send-message-reaction'],

  data() {
    return {
      menuOptionsTop: 0,
      optionsOpened: false,
      optionsClosing: false,
      emojiOpened: false,
    };
  },

  computed: {
    isMessageActions() {
      return (
        this.filteredMessageActions.length &&
        this.messageHover &&
        !this.message.deleted &&
        !this.message.disableActions &&
        !this.hoverAudioProgress
      );
    },
    isMessageReactions() {
      return (
        this.showReactionEmojis && this.messageHover && !this.message.deleted && !this.message.disableReactions && !this.hoverAudioProgress
      );
    },
    filteredMessageActions() {
      return this.message.senderId === this.currentUserId ? this.messageActions : this.messageActions.filter(message => !message.onlyMe);
    },
  },

  watch: {
    emojiOpened(val) {
      this.$emit('update-emoji-opened', val);
      if (val) this.optionsOpened = false;
    },
    optionsOpened(val) {
      this.$emit('update-options-opened', val);
    },
  },

  methods: {
    openOptions() {
      if (this.optionsClosing) return;

      this.optionsOpened = !this.optionsOpened;
      if (!this.optionsOpened) return;

      setTimeout(() => {
        const roomFooterRef = document.getElementById('room-footer');

        if (!roomFooterRef || !this.$refs.menuOptions || !this.$refs.actionIcon) {
          return;
        }

        const menuOptionsTop = this.$refs.menuOptions.getBoundingClientRect().height;

        const actionIconTop = this.$refs.actionIcon.getBoundingClientRect().top;
        const roomFooterTop = roomFooterRef.getBoundingClientRect().top;

        const optionsTopPosition = roomFooterTop - actionIconTop > menuOptionsTop + 50;

        if (optionsTopPosition) this.menuOptionsTop = 30;
        else this.menuOptionsTop = -menuOptionsTop;
      });
    },
    closeOptions() {
      this.optionsOpened = false;
      this.optionsClosing = true;
      this.updateMessageHover();
      setTimeout(() => (this.optionsClosing = false), 100);
    },
    openEmoji() {
      this.emojiOpened = !this.emojiOpened;
    },
    closeEmoji() {
      this.emojiOpened = false;
      this.updateMessageHover();
    },
    updateMessageHover() {
      if (this.hoverMessageId !== this.message._id) {
        this.$emit('update-message-hover', false);
      }
    },
    messageActionHandler(action) {
      this.closeOptions();
      this.$emit('message-action-handler', action);
    },
    sendMessageReaction(emoji, reaction) {
      this.$emit('send-message-reaction', { emoji, reaction });
      this.closeEmoji();
    },
  },
};
</script>
