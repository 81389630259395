var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-order-pd-addl-price d-flex",
      class: {
        "align-items-center wrap-border-grey-tertiary rounded p-2":
          !_vm.isMobile,
        "flex-column": _vm.isMobile,
        "p-2 wrap-border-grey-tertiary rounded": !_vm.isShowInput,
      },
    },
    [
      _c("div", { staticClass: "flex-grow-1 mr-2" }, [
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-weight-500 mb-2 mb-lg-0",
            class: {
              "font-size-14":
                _vm.isDeviceSize === _vm.deviceSizeType.XL ||
                _vm.isDeviceSize === _vm.deviceSizeType.LG ||
                _vm.isDeviceSize === _vm.deviceSizeType.MD,
              "font-size-12": _vm.isDeviceSize === _vm.deviceSizeType.SM,
            },
          },
          [_vm._v("\n      Additional Price\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _vm.isShowInput
          ? _c(
              "div",
              {
                staticClass: "cj-field-border-default rounded d-flex",
                class: {
                  "w-100": _vm.isMobile,
                  invalid:
                    _vm.$v.additionalPrice.$anyDirty &&
                    _vm.$v.additionalPrice.$invalid,
                  valid:
                    _vm.$v.additionalPrice.$anyDirty &&
                    !_vm.$v.additionalPrice.$invalid,
                },
                attrs: { "data-cy": "wrap-fixed-price" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "cj-field-border-default-right font-roboto font-size-14 cj-color-black-primary p-2 font-weight-400 my-auto",
                  },
                  [_vm._v("IDR")]
                ),
                _vm._v(" "),
                _c("c-input-currency", {
                  staticClass:
                    "font-roboto font-weight-400 font-size-14 cj-color-black-primary cj-field-not-border p-2",
                  attrs: {
                    "data-cy": "fixed-price",
                    currency: "IDR",
                    max: _vm.MaxNumberType.NUMBER_10T,
                    placeholder: "0",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input", [_vm.$v.additionalPrice.$model])
                    },
                  },
                  model: {
                    value: _vm.$v.additionalPrice.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.additionalPrice, "$model", $$v)
                    },
                    expression: "$v.additionalPrice.$model",
                  },
                }),
              ],
              1
            )
          : _c(
              "p",
              {
                staticClass:
                  "font-roboto cj-color-black-primary font-weight-500 font-size-14",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.currency) +
                    " " +
                    _vm._s(
                      _vm.$options.filters.formatCurrency(
                        _vm.price,
                        _vm.currency
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }