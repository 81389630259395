
import Loader from '../../components/Loader/Loader.vue';

import RoomsSearch from './RoomsSearch/RoomsSearch.vue';
import RoomContent from './RoomContent/RoomContent.vue';

import filteredItems from '../../utils/filter-items';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Debounce } from '@/shared/decorator/debounce';

@Component({
  components: {
    Loader,
    RoomsSearch,
    RoomContent,
  },
  watch: {
    rooms: {
      deep: true,
      handler(newVal, oldVal) {
        // fix when duplicate roomId
        const uniqueRooms = newVal.filter((room, index, self) => index === self.findIndex(r => r.roomId === room.roomId));

        this.filteredRooms = uniqueRooms;
        console.log('cht===============================roomlist.loadingMoreRooms.stop');
        if (newVal.length !== oldVal.length || this.roomsLoaded) {
          this.loadingMoreRooms = false;
        }
      },
    },
    loadingRooms(val) {
      if (!val) {
        setTimeout(() => this.initIntersectionObserver());
      }
    },
    loadingMoreRooms(val) {
      console.log('cht===============================roomlist.loadingMoreRooms');
      this.$emit('loading-more-rooms', val);
    },
    roomsLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadingMoreRooms = false;
          if (!this.loadingRooms) {
            this.showLoader = false;
          }
        }
      },
    },
    room: {
      immediate: true,
      handler(val) {
        if (val && !this.isMobile) this.selectedRoomId = val.roomId;
      },
    },
  },
})
export default class RoomsList extends Vue {
  @Prop({ type: [String, Number], required: true })
  private currentUserId;
  @Prop({ type: Object, required: true })
  private textMessages;
  @Prop({ type: Boolean, required: true })
  private showRoomsList;
  @Prop({ type: Boolean, required: true })
  private showSearch;
  @Prop({ type: Boolean, required: true })
  private showAddRoom;
  @Prop({ type: Object, required: true })
  private textFormatting;
  @Prop({ type: Object, required: true })
  private linkOptions;
  @Prop({ type: Boolean, required: true })
  private isMobile;
  @Prop({ type: Array, required: true })
  private rooms;
  @Prop({ type: Boolean, required: true })
  private loadingRooms;
  @Prop({ type: Boolean, required: true })
  private roomsLoaded;
  @Prop({ type: Object, required: true })
  private room;
  @Prop({ type: Array, required: true })
  private roomActions;
  @Prop({ type: Number, required: true })
  private scrollDistance;
  @Prop({ type: Array })
  userIdsOnline;
  @Prop()
  private isShowSearch;
  @Prop()
  private isShowSettingUser;
  private filteredRooms = this.rooms || [];
  private observer = null;
  private showLoader = true;
  private loadingMoreRooms = false;
  private selectedRoomId = '';

  // private isShowSearch = false;
  // private isShowSettingUser = false;

  // methods: {
  initIntersectionObserver() {
    if (this.observer) {
      this.showLoader = true;
      this.observer.disconnect();
    }

    const loader = document.getElementById('infinite-loader-rooms');

    if (loader) {
      const options = {
        root: document.getElementById('rooms-list'),
        rootMargin: `${this.scrollDistance}px`,
        threshold: 0,
      };

      this.observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          console.log('cht===============================roomlist.loadingMoreRooms.start');

          this.loadMoreRooms();
        }
      }, options);

      this.observer.observe(loader);
    }
  }

  @Debounce(300)
  searchRoom(ev) {
    this.$emit('contactSearch', ev.target.value);
    // this.filteredRooms = filteredItems(this.room, 'roomName', ev.target.value);
  }
  openRoom(room) {
    if (this.isMobile) {
      this.$root.$emit('hideFooter');
    }
    if (room.roomId === (<any>this.room).roomId && !this.isMobile) return;
    if (!this.isMobile) this.selectedRoomId = room.roomId;
    this.$emit('fetch-room', { room });
  }
  loadMoreRooms() {
    if (this.loadingMoreRooms) return;

    if (this.roomsLoaded) {
      this.loadingMoreRooms = false;
      this.showLoader = false;
      return;
    }

    const options = { isLoadMore: true };
    this.$emit('fetch-more-rooms', { options });
    this.loadingMoreRooms = true;
  }

  handleShowSearch() {
    this.filteredRooms = filteredItems(this.rooms, 'roomName', null); //remove filter
    this.$emit('handleShowSearch');
    setTimeout(() => {
      const focusSearcListMain: any = this.$refs.focusSearcListMain;
      if (focusSearcListMain.$refs.focusSearcList) {
        focusSearcListMain.$refs.focusSearcList.focus();
      }
    }, 50);
  }
}
