import { Component, Inject, Mixins, Prop } from 'vue-property-decorator';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

import Icon from '@/shared/icons/icon.vue';
import CUploadFile from '@/components/c-upload-file/c-upload-file.vue';
import CConfirmation from '@/components/c-confirmation/c-confirmation.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import CTextareaNew from '@/components/c-textarea-new/c-textarea-new.vue';
import CAutocompleteNew from '@/components/c-autocomplete-new/c-autocomplete-new.vue';
import COrderPdAddlPrice from './components/c-order-pd-addl-price/c-order-pd-addl-price.vue';
import COrderPdBtn from './components/c-order-pd-btn/c-order-pd-btn.vue';
import COrderPdDate from './components/c-order-pd-date/c-order-pd-date.vue';
import COrderPdItem from './components/c-order-pd-item/c-order-pd-item.vue';
import CDetailOrderStatus from '../c-order-detail/components/c-detail-order-status/c-detail-order-status.vue';

import GlobalService from '@/services/global.service';
import JhiDataUtils from '@/shared/data/data-utils.service';

import { ICbAtt } from '@/shared/model/cb-att';
import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';
import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { ICbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbTextRestrict, ICbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import {
  HANDLE_BUYER_COMPLETED_ORDER_SEND,
  HANDLE_MDL_PROJECT_DELIVERY_SEND_EXTRA,
  HANDLE_MDL_PROJECT_DELIVERY_ASK_REVISION,
  HANDLE_MDL_PROJECT_DELIVERY_REJECT_EXTRA,
} from '@/module/chat-container/order-paw-controller.component';
import { CbPawOrderFile, ICbPawOrderFile } from '@/shared/model/cb-paw-order-file.model';
import { CbPawOrderFileType } from '@/shared/model/enumerations/cb-paw-order-file-type.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

const validations: any = {
  cbTextRestrict: {
    content: { required, minLength: minLength(1), maxLength: maxLength(1000) },
  },
  expiredTime: { required },
  additionalPrice: {},
};
@Component({
  validations,
  components: {
    Icon,
    COrderPdAddlPrice,
    COrderPdBtn,
    COrderPdDate,
    COrderPdItem,
    CConfirmation,
    CUploadFile,
    CTextareaPreview,
    CTextareaNew,
    CAutocompleteNew,
    CDetailOrderStatus,
  },
})
export default class COrderProjectDelivery extends Mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  // ================= END SERVICES ===================

  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;
  @Prop({ default: null }) public typeModal: TypeComponent;

  public cbTextRestrict: ICbTextRestrict = new CbTextRestrict();
  public cbPawOrderFiles: ICbPawOrderFile[] = [];
  public deviceSizeType = DeviceSizeType;
  public cbCommonStatus = CbCommonStatus;
  public typeComponent = TypeComponent;
  public cbRequirementType = CbRequirementType;

  public isExpandRevisionRequestForm = false;
  public isExpandFormAddlPrice = false;
  public isSavingCbPawOrderRequirementOne = false;
  public isSavingCbPawOrderRequirementTwo = false;
  public isShowDetailRevisionExtra = true;

  public expiredTime: number = null;
  public additionalPrice: number = null;

  private maxFileAmount = 10;
  public fileAccept = '.docx,.xls,.pdf,.jpeg,.jpg,.png';

  // ================= END VARIABLES ==================

  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========

  // ================= START FUNCTION =================
  public cancel() {
    // clear cbPawOrderFiles
    this.cbPawOrderFiles = [];
    this.$emit('cancel');
  }

  public async handleUploadFile(cbAtt: ICbAtt) {
    let totalFileAmount = this.cbPawOrderFiles.length;
    if (totalFileAmount + 1 > this.maxFileAmount) {
      this.showToastOnExceededFileAmount();
      return;
    }

    let cbPawOrderFile: ICbPawOrderFile = new CbPawOrderFile();
    cbPawOrderFile = {
      cbPawOrder: {
        id: this.getCbVwPawOrderProjectDelivery.cbPawOrderId,
      },
      cbAttachmentRestrict: {},
    };

    cbPawOrderFile.cbAttachmentRestrict.name = cbAtt.name;
    cbPawOrderFile.cbAttachmentRestrict.attContentType = cbAtt.attContentType;
    cbPawOrderFile.cbAttachmentRestrict.att = '';
    cbPawOrderFile.cbAttachmentRestrict.isActive = true;
    cbPawOrderFile.cbAttachmentRestrict.localUrl = cbAtt.localUrl;
    cbPawOrderFile.cbAttachmentRestrict.blob = cbAtt.blob;
    cbPawOrderFile.cbAttachmentRestrict.attSize = cbAtt.size;
    cbPawOrderFile.cbAttachmentRestrict.restrictToUserId = this.getCbVwPawOrderProjectDelivery.buyerId;
    cbPawOrderFile.fileName = cbAtt.name;
    cbPawOrderFile.name = cbAtt.name;
    cbPawOrderFile.pawOrderFileType = CbPawOrderFileType.PROJECT_DELIVERY;
    cbPawOrderFile.status = CbCommonStatus.COMPLETED;

    if (cbAtt.thumbnail) {
      cbPawOrderFile.cbAttachmentRestrict.thumbnail = cbAtt.thumbnail;
      cbPawOrderFile.cbAttachmentRestrict.thumbnailLocalUrl = cbAtt.thumbnailLocalUrl;
    }

    this.cbPawOrderFiles.push(cbPawOrderFile);
    totalFileAmount++;
  }

  public handleClear(index: number): void {
    this.cbPawOrderFiles.splice(index, 1);
  }

  public handleExpandAction(type: string) {
    if (this.cbVwPawOrder.orderStatus === CbCommonStatus.DISPUTED) return;

    switch (type) {
      case 'EXPAND_FORM_REVISION':
        this.isExpandRevisionRequestForm = true;
        break;
      case 'NONEXPAND_FORM_REVISION':
        this.isExpandRevisionRequestForm = false;
        break;
      case 'EXPAND_FORM_ADDL_PRICE':
        this.isExpandFormAddlPrice = true;
        break;
      case 'NONEXPAND_FORM_ADDL_PRICE':
        this.isExpandFormAddlPrice = false;
        break;
    }
  }

  public handleSendFormRevisionRequest(cbVwPawOrderRequirement: ICbVwPawOrderRequirement) {
    if (this.isExpandRevisionRequestForm && this.$v.cbTextRestrict.$invalid) {
      this.$v.cbTextRestrict.$touch();
      this.invalidValidationForm();
    } else {
      if (this.isSavingCbPawOrderRequirementTwo) return;

      (<any>this.$root).$emit(
        'opc::actionHandler',
        {
          action: HANDLE_MDL_PROJECT_DELIVERY_ASK_REVISION,
          cbVwPawOrder: this.cbVwPawOrder,
          cbTextRestrict: this.cbTextRestrict,
          cbVwPawOrderRequirement: cbVwPawOrderRequirement,
          cbPawOrderFiles: this.cbPawOrderFiles,
          typeModal: this.typeModal,
        },

        ({ isSavingLoader }) => {
          this.isSavingCbPawOrderRequirementTwo = isSavingLoader;
        }
      );
    }
  }

  public handleSendFormRevisionExtraRequest() {
    if (this.isExpandFormAddlPrice && this.$v.expiredTime.$invalid) {
      this.$v.expiredTime.$touch();
      this.invalidValidationForm();
    } else {
      if (this.isSavingCbPawOrderRequirementTwo) return;
      this.isSavingCbPawOrderRequirementTwo = true;

      (<any>this.$root).$emit(
        'opc::actionHandler',
        {
          action: HANDLE_MDL_PROJECT_DELIVERY_SEND_EXTRA,
          cbVwPawOrder: this.cbVwPawOrder,
          cbVwPawOrderRequirement: this.getCbVwPawOrderRevision,
          amount: this.additionalPrice ? this.additionalPrice[0] : 0,
          offerExpiredLabel: this.expiredTime,
          typeModal: this.typeModal,
        },
        ({ isSavingLoader }) => {
          this.isSavingCbPawOrderRequirementTwo = isSavingLoader;
        }
      );
    }
  }

  public handleCompleteOrderProjectDelivery() {
    if (this.isSavingCbPawOrderRequirementOne) return;
    this.isSavingCbPawOrderRequirementOne = true;

    (<any>this.$root).$emit(
      'opc::actionHandler',
      {
        action: HANDLE_BUYER_COMPLETED_ORDER_SEND,
        cbVwPawOrder: this.cbVwPawOrder,
        cbVwPawOrderRequirement: this.getCbVwPawOrderProjectDelivery,
        typeModal: this.typeModal,
      },
      ({ isSavingLoader }) => {
        this.isSavingCbPawOrderRequirementOne = isSavingLoader;
      }
    );

    if (!this.isSavingCbPawOrderRequirementOne) this.globalService().closeGlobalDialog(this.$root, 'modal-accept-project-delivery');
  }

  public handleRejectRequestRevisionExtra(type: string) {
    if (this.cbVwPawOrder.orderStatus === CbCommonStatus.DISPUTED || this.isSavingCbPawOrderRequirementOne) return;

    this.isSavingCbPawOrderRequirementOne = true;

    (<any>this.$root).$emit(
      'opc::actionHandler',
      {
        action: HANDLE_MDL_PROJECT_DELIVERY_REJECT_EXTRA,
        cbVwPawOrder: this.cbVwPawOrder,
        cbVwPawOrderRequirement: this.getCbVwPawOrderRevision,
        typeModal: this.typeModal,
      },
      ({ isSavingLoader }) => {
        this.isSavingCbPawOrderRequirementOne = isSavingLoader;

        if (type === 'REQUEST_REVISION_EXTRA' && !this.isSavingCbPawOrderRequirementOne) {
          this.handleActionConfirmationModal('REVISION_EXTRA_REJECT_CLOSE');
        } else if (type === 'REQUEST_ADDITIONAL_PRICE' && !this.isSavingCbPawOrderRequirementOne) {
          this.handleActionConfirmationModal('REVISION_EXTRA_REJECT_PRICE_CLOSE');
        }
      }
    );
  }

  public handleAcceptAndPayRevisionExtra(action: string) {
    if (this.cbVwPawOrder.orderStatus === CbCommonStatus.DISPUTED || this.isSavingCbPawOrderRequirementOne) return;

    this.isSavingCbPawOrderRequirementTwo = true;

    (<any>this.$root).$emit(
      'opc::actionHandler',
      {
        action,
        cbVwPawOrder: this.cbVwPawOrder,
        cbVwPawOrderRequirement: this.getCbVwPawOrderRevision,
        typeModal: this.typeModal,
      },
      ({ isSavingLoader }) => {
        this.isSavingCbPawOrderRequirementTwo = isSavingLoader;
      }
    );
  }

  public handleActionConfirmationModal(type?: string) {
    if (this.cbVwPawOrder.orderStatus === CbCommonStatus.DISPUTED) return;

    switch (type) {
      case 'ACCEPT_DELIVERY_OPEN':
        this.globalService().openGlobalDialog(this.$root, 'modal-accept-project-delivery');
        break;
      case 'REVISION_EXTRA_REJECT_OPEN':
        this.globalService().openGlobalDialog(this.$root, 'modal-reject-revision-request');
        break;
      case 'REVISION_EXTRA_REJECT_CLOSE':
        this.globalService().closeGlobalDialog(this.$root, 'modal-reject-revision-request');
        break;
      case 'REVISION_EXTRA_REJECT_PRICE_OPEN':
        this.globalService().openGlobalDialog(this.$root, 'modal-confirmation-reject-extra-price');
        break;
      case 'REVISION_EXTRA_REJECT_PRICE_CLOSE':
        this.globalService().closeGlobalDialog(this.$root, 'modal-confirmation-reject-extra-price');
        break;

      default:
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-order-project-delivery');
        (<any>this.$root.$emit)('opc::changeValue', 'modalStepFormOrders', null);
        break;
    }
  }

  public inputAdditionalPrice(event) {
    if (event !== this.additionalPrice) {
      this.additionalPrice = event;
    }
  }

  public invalidValidationForm() {
    this.$nextTick(() => {
      if (document.querySelector('.invalid')?.getBoundingClientRect()) {
        const domRect = document.querySelector('.invalid').getBoundingClientRect();
        const top = domRect.top + document.documentElement.scrollTop - 150;

        window.scrollTo({
          top: top,
          behavior: 'smooth',
        });
      }
    });

    const message = {
      toString: () => {
        return this.$t('cbgwApp.cbComponent.validation.wrongFormInput');
      },
    };
    (<any>this.$root).$bvToast.toast(message.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Info',
      variant: 'warning',
      solid: true,
      autoHideDelay: 3000,
    });
    return null;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get expiredTimes() {
    return [
      {
        key: '15',
        value: `15 ${this.$t('cbgwApp.cbComponent.deliveryTimeType.minute').toString()}`,
      },
      {
        key: '60',
        value: `1 ${this.$t('cbgwApp.cbComponent.deliveryTimeType.hour').toString()}`,
      },
      {
        key: '360',
        value: `6 ${this.$t('cbgwApp.cbComponent.deliveryTimeType.hour').toString()}`,
      },
      {
        key: '720',
        value: `12 ${this.$t('cbgwApp.cbComponent.deliveryTimeType.hour').toString()}`,
      },
      {
        key: '1440',
        value: `24 ${this.$t('cbgwApp.cbComponent.deliveryTimeType.hour').toString()}`,
      },
    ];
  }

  public get getCbVwPawOrderProjectDelivery(): ICbVwPawOrderRequirement {
    if (this.cbVwPawOrder.cbVwPawOrderProjectDeliverySelected?.cbPawOrderFiles) {
      return this.cbVwPawOrder.cbVwPawOrderProjectDeliverySelected;
    } else {
      return this.cbVwPawOrder?.cbVwPawOrderProjectDeliveryPreviews?.find(req => req.requirementType === CbRequirementType.DELIVERY);
    }
  }

  public get getCbVwPawOrderRevision() {
    const cbVwPawOrderProjectDeliveryPreviews: ICbVwPawOrderRequirement[] = this.cbVwPawOrder?.cbVwPawOrderProjectDeliveryPreviews;
    let retrieveFind = 0;

    if (
      this.cbVwPawOrder.id === cbVwPawOrderProjectDeliveryPreviews[0].cbPawOrderId &&
      cbVwPawOrderProjectDeliveryPreviews?.length > 0 &&
      retrieveFind === 0
    ) {
      let revision: ICbVwPawOrderRequirement;

      const cbVwPawOrderRevisionExtra = cbVwPawOrderProjectDeliveryPreviews.find(
        revision =>
          revision.refRequirementId === this.getCbVwPawOrderProjectDelivery?.id &&
          revision.requirementType === CbRequirementType.REVISION_EXTRA &&
          (revision.status === CbCommonStatus.REVISION_REQUESTED || revision.status === CbCommonStatus.WAITING_PAYMENT)
      );

      if (cbVwPawOrderRevisionExtra) {
        revision = cbVwPawOrderRevisionExtra;
      } else {
        const cbVwPawOrderRevision = cbVwPawOrderProjectDeliveryPreviews.find(
          revision =>
            revision.refRequirementId === this.getCbVwPawOrderProjectDelivery?.id && revision.requirementType === CbRequirementType.REVISION
        );

        revision = cbVwPawOrderRevision;
      }

      if (revision) {
        const cbTextRestrict = this.cbTextRestricts.find(e => e.id === revision.descriptionId);

        if (cbTextRestrict) {
          revision.cbTextRestrict = cbTextRestrict;
        }
      }

      retrieveFind++;
      return revision;
    }

    return null;
  }

  public get getCbVwPawOrderExtraRevisionHistorys(): ICbVwPawOrderRequirement[] {
    let cbVwPawOrderRequirementExtraRevisions: ICbVwPawOrderRequirement[] = [];

    cbVwPawOrderRequirementExtraRevisions = this.cbVwPawOrder?.cbVwPawOrderProjectDeliveryPreviews?.filter(
      extraRevision =>
        extraRevision.refRequirementId === this.getCbVwPawOrderProjectDelivery?.id &&
        (extraRevision.status === CbCommonStatus.DECLINED ||
          extraRevision.status === CbCommonStatus.ACCEPTED ||
          extraRevision.status === CbCommonStatus.PAID ||
          extraRevision.status === CbCommonStatus.REVISION_NEEDED) &&
        extraRevision.requirementType === CbRequirementType.REVISION_EXTRA
    );

    if (cbVwPawOrderRequirementExtraRevisions?.length > 0) {
      cbVwPawOrderRequirementExtraRevisions.forEach(extraRevision => {
        const cbTextRestrict = this.cbTextRestricts.find(e => e.id === extraRevision.descriptionId);
        if (cbTextRestrict) extraRevision.cbTextRestrict = cbTextRestrict;
      });

      return cbVwPawOrderRequirementExtraRevisions;
    }

    return null;
  }

  public get getRevisionNumber() {
    let value: string = null;
    if (this.getCbVwPawOrderProjectDelivery?.id) {
      let revisionUse = 0;
      let revisionTotal = 0;

      if (this.getCbVwPawOrderProjectDelivery.revisionUsed) revisionUse = +this.getCbVwPawOrderProjectDelivery.revisionUsed;
      if (this.getCbVwPawOrderProjectDelivery.revisionTotal) revisionTotal = +this.getCbVwPawOrderProjectDelivery.revisionTotal;
      if (revisionUse < revisionTotal) value = `(${revisionUse}/${revisionTotal})`;
    }
    return value;
  }

  public get cbTextRestricts(): ICbTextRestrict[] {
    return this.$store.getters.cbTextRestricts;
  }

  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  // ================= END COMPUTE ====================

  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
