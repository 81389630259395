var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "vac-slide-up" } }, [
    _vm.filteredTemplatesText.length
      ? _c(
          "div",
          {
            staticClass: "vac-template-container vac-app-box-shadow",
            style: { bottom: `${_vm.footerHeight}px` },
          },
          _vm._l(_vm.filteredTemplatesText, function (template, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "vac-template-box",
                class: { "vac-template-active": index === _vm.activeItem },
                on: {
                  mouseover: function ($event) {
                    _vm.activeItem = index
                  },
                  click: function ($event) {
                    return _vm.$emit("select-template-text", template)
                  },
                },
              },
              [
                _c("div", { staticClass: "vac-template-info" }, [
                  _c("div", { staticClass: "vac-template-tag" }, [
                    _vm._v("/" + _vm._s(template.tag)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "vac-template-text" }, [
                    _vm._v(
                      "\n          " + _vm._s(template.text) + "\n        "
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }