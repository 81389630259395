import Icon from '@/shared/icons/icon.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { isImageFile, isVideoFile } from '@/module/chat-container/chat/utils/media-file';
import Loader from '@/module/chat-container/chat/components/Loader/Loader.vue';
import SvgIcon from '@/module/chat-container/chat/components/SvgIcon/SvgIcon.vue';
import CIconFile from '@/module/chat-container/chat/components/c-icon-file/c-icon-file.vue';

@Component({
  components: { Icon, Loader, SvgIcon, CIconFile },
})
export default class RoomFileChat extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop()
  public file;

  @Prop()
  public index;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleRemove(index) {
    this.$emit('remove-file', index);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isImage() {
    return isImageFile(this.file);
  }

  public get isVideo() {
    return isVideoFile(this.file);
  }
  // ================= END COMPUTE ====================
}
