var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-card-promo-detail",
      class: _vm.isMobile ? "cb-position-h" : "",
    },
    [
      _c(
        "div",
        {
          class: _vm.isMobile
            ? "d-flex cb-position-sticky-top align-items-center p-0 py-1 pl-3"
            : "icon-close-promo",
        },
        [
          _vm.isMobile
            ? _c(
                "p",
                {
                  staticClass:
                    "flex-grow-1 font-roboto cj-color-black-primary font-size-18 font-weight-500",
                },
                [_vm._v("Klikjob Promo")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex cursor-pointer",
              class: _vm.isMobile ? "p-3" : "",
              attrs: { "data-cy": "go-back" },
              on: { click: _vm.close },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "c-header-promo-detail-wrapper cb-img-wrapper cb-ratio-2x1",
        },
        [
          _c("img", {
            staticClass: "cb-img-cover",
            attrs: {
              "data-cy": "promo-detail-image",
              src: _vm.$options.filters.LAZY(
                `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                  _vm.currentLanguage === "en"
                    ? _vm.cbVwPromo.attEnId
                      ? _vm.cbVwPromo.attEnId
                      : _vm.cbVwPromo.attId
                    : _vm.cbVwPromo.attId
                    ? _vm.cbVwPromo.attId
                    : _vm.cbVwPromo.attEnId
                }`,
                800,
                "ic-lazy-paw.png"
              ),
              onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-paw.png"),
              alt: "img",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-weight-500 py-3",
          class: _vm.isMobile ? "font-size-16 " : "font-size-20 ",
          attrs: { "data-cy": "promo-detail-title" },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.currentLanguage === "en"
                  ? _vm.cbVwPromo.titleEn
                    ? _vm.cbVwPromo.titleEn
                    : _vm.cbVwPromo.title
                  : _vm.cbVwPromo.title
                  ? _vm.cbVwPromo.title
                  : _vm.cbVwPromo.titleEn
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "promo-detail-code-wrapper p-3 mb-3" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            {
              staticClass:
                "col-6 px-2 d-flex flex-column align-items-center justify-content-center border-grey-tertiary-right-1",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-center cb-icon-position-wrapper cb-larger-icon icon-promo-period font-roboto cj-color-black-primary font-weight-300",
                  class: _vm.isMobile ? "font-size-10 " : "font-size-12 ",
                  staticStyle: { "line-height": "24px" },
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbGlobal.promo.promoPeriod")),
                  },
                },
                [_vm._v("\n          Promo Period\n        ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-6 px-2 d-flex flex-column align-items-center justify-content-center",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-center cb-icon-position-wrapper cb-larger-icon icon-minimum-transaction font-roboto cj-color-black-primary font-weight-300",
                  class: _vm.isMobile ? "font-size-10 " : "font-size-12 ",
                  staticStyle: { "line-height": "24px" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbGlobal.promo.minimumTransaction")
                    ),
                  },
                },
                [_vm._v("\n          Minimum Transaction\n        ")]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex mb-2" }, [
          _c(
            "div",
            {
              staticClass:
                "col-6 px-2 d-flex flex-column align-items-center justify-content-center border-grey-tertiary-right-1",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-center font-roboto cj-color-black-primary font-weight-500",
                  class: _vm.isMobile ? "font-size-12 " : "font-size-16",
                  attrs: { "data-cy": "promo-detail-period-end" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("cbGlobal.until")) +
                      " " +
                      _vm._s(
                        _vm._f("formatDateTimeDefault")(
                          _vm.cbVwPromo.priodeEndDate
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-6 px-2 d-flex flex-column align-items-center justify-content-center",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-center font-roboto cj-color-black-primary font-weight-500",
                  class: _vm.isMobile ? "font-size-12 " : "font-size-16",
                  attrs: { "data-cy": "promo-detail-min-amount" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.cbVwPromo.currency) +
                      " " +
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.cbVwPromo.minAmount,
                          _vm.cbVwPromo.currency
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center px-3 mt-1 wrap-border-grey-tertiary",
            staticStyle: { "border-radius": "20px" },
          },
          [
            _c(
              "p",
              {
                staticClass:
                  "py-1 flex-grow-1 font-roboto cj-color-red-primary font-weight-700 pr-2 border-grey-tertiary-right-1",
                class: _vm.isMobile ? "font-size-12 " : "font-size-16",
                staticStyle: { "letter-spacing": "1.2px" },
                attrs: { "data-cy": "promo-detail-code" },
              },
              [_vm._v("\n        " + _vm._s(_vm.cbVwPromo.code) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.canCopy
              ? _c(
                  "button",
                  {
                    staticClass:
                      "d-flex cb-toollip-copy cursor-pointer pl-2 py-1 pr-0 cb-tooltip-copy-container",
                    attrs: { "data-cy": "promo-detail-code-copy" },
                    on: {
                      click: function ($event) {
                        return _vm.copy(_vm.cbVwPromo.code)
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "cj-stroke-red-primary",
                      attrs: { name: "ic_copy", width: "1.4rem" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pb-3", attrs: { "data-cy": "description-promo" } },
        [
          _c(
            "p",
            {
              staticClass:
                "mb-2 font-roboto cj-color-black-primary font-weight-600 font-size-14",
              domProps: {
                textContent: _vm._s(_vm.$t("cbGlobal.promo.termAndCondition")),
              },
            },
            [_vm._v("\n      Term and Condition\n    ")]
          ),
          _vm._v(" "),
          _c("div", {
            attrs: { "data-cy": "promo-detail-content" },
            domProps: {
              innerHTML: _vm._s(
                _vm.$options.filters.SECURITY_VHTML(_vm.cbTextNoAuth.content)
              ),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.isMobile
            ? "cb-position-sticky-bottom border-grey-tertiary-top-1 mt-auto"
            : "",
        },
        [
          _vm.cbVwPromo.isUserHasClaimed == false ||
          _vm.cbVwPromo.isUserHasClaimed == null
            ? _c(
                "c-button",
                {
                  staticClass: "font-roboto font-weight-500",
                  class: _vm.isMobile ? "font-size-18" : "font-size-20",
                  attrs: {
                    "data-cy": "claim-promo",
                    dataCyCustom: "promo-detail-claim",
                    fullWidth: "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveCardPromoDetail.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("cbGlobal.promo.claimCoupon")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.cbVwPromo.isUserHasClaimed == true && !_vm.cbVwPromo.isUserHasUsed
            ? _c(
                "c-button",
                {
                  staticClass: "font-roboto font-weight-500",
                  class: _vm.isMobile ? "font-size-18" : "font-size-20",
                  attrs: {
                    "data-cy": "use-promo",
                    fullWidth: "",
                    dataCyCustom: "promo-detail-use",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.toAdvancedSearch(_vm.cbVwPromo)
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("cbGlobal.use")) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }