export enum CbNotifCodeUnique {
  TOP_UP = 'TOP_UP',

  WITHDRAW = 'WITHDRAW',

  CASHBACK_EARNED = 'CASHBACK_EARNED',

  REFUND = 'REFUND',

  BANK_ACCOUNT = 'BANK_ACCOUNT',

  RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL',

  RESET_PASSWORD_HANDPHONE = 'RESET_PASSWORD_HANDPHONE',

  SET_PIN = 'SET_PIN',

  CHANGE_PASSWORD = 'CHANGE_PASSWORD',

  CHANGE_PIN = 'CHANGE_PIN',

  SET_AVAILABILITY = 'SET_AVAILABILITY',

  TICKET_CREATED = 'TICKET_CREATED',

  TICKET_REPLY = 'TICKET_REPLY',

  TICKET_CLOSED = 'TICKET_CLOSED',

  STORAGE_ALMOST_FULL = 'STORAGE_ALMOST_FULL',

  STORAGE_FULL = 'STORAGE_FULL',

  SUBCRIPTION_ALMOST_FULL = 'SUBCRIPTION_ALMOST_FULL',

  SUBCRIPTION_FULL = 'SUBCRIPTION_FULL',

  SUBCRIPTION_EXTENDED = 'SUBCRIPTION_EXTENDED',

  REQUEST_POSTED = 'REQUEST_POSTED',

  REQUEST_OFFER = 'REQUEST_OFFER',

  REQUEST_OFFER_REJECTED = 'REQUEST_OFFER_REJECTED',

  REQUEST_OFFER_ACCEPTED = 'REQUEST_OFFER_ACCEPTED',

  REQUEST_EXPIRED_SOON = 'REQUEST_EXPIRED_SOON',

  REQUEST_EXPIRED_SOON_WITH_GUARANTEE = 'REQUEST_EXPIRED_SOON_WITH_GUARANTEE',

  REQUEST_SELECT_OFFER = 'REQUEST_SELECT_OFFER',

  REQUEST_EXPIRED = 'REQUEST_EXPIRED',

  ACTIVATE_ACCOUNT_EMAIL = 'ACTIVATE_ACCOUNT_EMAIL',

  ACTIVATE_ACCOUNT_WA = 'ACTIVATE_ACCOUNT_WA',

  FINISH_REGISTRATION_FREELANCER = 'FINISH_REGISTRATION_FREELANCER',

  FINISH_REGISTRATION_FREELANCER_PAW = 'FINISH_REGISTRATION_FREELANCER_PAW',

  CONTINUE_REGISTRATION_FREELANCER = 'CONTINUE_REGISTRATION_FREELANCER',

  ACCOUNT_CREATED = 'ACCOUNT_CREATED',

  PAW_UNDER_REVIEW = 'PAW_UNDER_REVIEW',

  CONTINUE_FINISH_PAW = 'CONTINUE_FINISH_PAW',

  PAW_POSTED = 'PAW_POSTED',

  EDIT_PAW = 'EDIT_PAW',

  IMPROVE_PAW = 'IMPROVE_PAW',

  REUPLOAD_FREELANCER_APPLICATION = 'REUPLOAD_FREELANCER_APPLICATION',

  CERTIFICATE_UPLOADED = 'CERTIFICATE_UPLOADED',

  REUPLOAD_CERTIFICATE = 'REUPLOAD_CERTIFICATE',

  CERTIFICATE_VERIFIED = 'CERTIFICATE_VERIFIED',

  RESUBMIT_DOCUMENT_CONFIRMATION = 'RESUBMIT_DOCUMENT_CONFIRMATION',

  CS_SESSION_ENDED = 'CS_SESSION_ENDED',

  FINISH_PAYMENT = 'FINISH_PAYMENT',

  DRAFT_ORDER_BOOKED = 'DRAFT_ORDER_BOOKED',

  PAYMENT_SUCCES_FIELD = 'PAYMENT_SUCCES_FIELD',

  SERVICE_START_SOON = 'SERVICE_START_SOON',

  LATE_LOCATION_FREELANCER = 'LATE_LOCATION_FREELANCER',

  LATE_LOCATION_BUYER = 'LATE_LOCATION_BUYER',

  FREELANCER_ON_MY_WAY = 'FREELANCER_ON_MY_WAY',

  BUYER_CANCELED_SERVICE = 'BUYER_CANCELED_SERVICE',

  SERVICE_STARTED_FREELANCER = 'SERVICE_STARTED_FREELANCER',

  SERVICE_STARTED_BUYER = 'SERVICE_STARTED_BUYER',

  RECEIVED_ADDITIONAL_SERVICE = 'RECEIVED_ADDITIONAL_SERVICE',

  ADDITIONAL_SERVICE_ACCEPTED = 'ADDITIONAL_SERVICE_ACCEPTED',

  ADDITIONAL_SERVICE_REJECTED = 'ADDITIONAL_SERVICE_REJECTED',

  BUYER_FINISH_SERVICE = 'BUYER_FINISH_SERVICE',

  SERVICE_FINISHED = 'SERVICE_FINISHED',

  SERVICE_FINISHED_AALT = 'SERVICE_FINISHED_AALT',

  INTERESTED_PAW_IN_OPERATIONAL_HOUR = 'INTERESTED_PAW_IN_OPERATIONAL_HOUR',

  INTERESTED_PAW_OUTSIDE_OPERATIONAL_HOUR = 'INTERESTED_PAW_OUTSIDE_OPERATIONAL_HOUR',

  BUYER_INTERESTED_PAW = 'BUYER_INTERESTED_PAW',

  ORDER_CANCELED = 'ORDER_CANCELED',

  NOT_PAYING_FREELANCER = 'NOT_PAYING_FREELANCER',

  NOT_PAYING_BUYER = 'NOT_PAYING_BUYER',

  ORDER_PAID = 'ORDER_PAID',

  PAYMENT_SUCCES_INSIDE = 'PAYMENT_SUCCES_INSIDE',

  MOM_UPDATED = 'MOM_UPDATED',

  MOM_ACCEPTED = 'MOM_ACCEPTED',

  MOM_DECLINED = 'MOM_DECLINED',

  APPOINTMENT_REQUEST = 'APPOINTMENT_REQUEST',

  APPOINTMENT_SCHEDULED_BUYER = 'APPOINTMENT_SCHEDULED_BUYER',
  APPOINTMENT_SCHEDULED_FREELANCER = 'APPOINTMENT_SCHEDULED_FREELANCER',

  APPOINTMENT_DECLINED = 'APPOINTMENT_DECLINED',

  PROJECT_DELIVERY_UPDATED = 'PROJECT_DELIVERY_UPDATED',

  ASK_REVISION = 'ASK_REVISION',

  EXTRA_REVISION = 'EXTRA_REVISION',

  EXTRA_REVISION_ACCEPTED = 'EXTRA_REVISION_ACCEPTED',

  EXTRA_REVISION_REJECTED = 'EXTRA_REVISION_REJECTED',

  REMINDER_PROJECT_OVERDUE = 'REMINDER_PROJECT_OVERDUE',

  PROJECT_OVERDUE_FREELANCER = 'PROJECT_OVERDUE_FREELANCER',

  PROJECT_OVERDUE_BUYER = 'PROJECT_OVERDUE_BUYER',

  EXTENDED_DELIVERY_TIME = 'EXTENDED_DELIVERY_TIME',

  CANCELED_ORDER_FREELANCER = 'CANCELED_ORDER_FREELANCER',

  CANCELED_ORDER_BUYER = 'CANCELED_ORDER_BUYER',

  ORDER_COMPLETED_FREELANCER = 'ORDER_COMPLETED_FREELANCER',

  ORDER_COMPLETED_BUYER = 'ORDER_COMPLETED_BUYER',

  DISPUTE_ORDER_FREELANCER = 'DISPUTE_ORDER_FREELANCER',

  DISPUTE_ORDER_BUYER = 'DISPUTE_ORDER_BUYER',

  FEEDBACK_RATE_REVIEW = 'FEEDBACK_RATE_REVIEW',

  RECEIVED_FEEDBACK = 'RECEIVED_FEEDBACK',

  HEADS_UP = 'HEADS_UP',

  CHAT_IN_OPERATIONAL_HOUR = 'CHAT_IN_OPERATIONAL_HOUR',

  CHAT_OUTSIDE_OPERATIONAL_HOUR = 'CHAT_OUTSIDE_OPERATIONAL_HOUR',

  BUYER_GIFT_TIPS = 'BUYER_GIFT_TIPS',

  USER_GIFT_TIPS = 'USER_GIFT_TIPS',

  USER_BUY_ITEM = 'USER_BUY_ITEM',

  INBOX_MESSAGES = 'INBOX_MESSAGES',

  ORDER_UPDATES = 'ORDER_UPDATES',

  SYSTEM_MESSAGES = 'SYSTEM_MESSAGES',

  FANS_FOLLOWING = 'FANS_FOLLOWING',

  NEW_REQUEST_OFFER = 'NEW_REQUEST_OFFER',
}
