var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-take-picture", attrs: { "data-cy": "c-take-picture" } },
    [
      _c(
        "div",
        { staticClass: "size-video-stream" },
        [
          _c("video-take-stream", {
            ref: "video",
            staticClass: "size-video",
            attrs: { "data-cy": "video-take-stream" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "px-3 pb-3 btn-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isPause,
                expression: "!isPause",
              },
            ],
            staticClass: "p-3 text-center",
          },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-camera",
                attrs: { "data-cy": "take-picture" },
                on: {
                  click: function ($event) {
                    return _vm.takePicture()
                  },
                },
              },
              [
                _vm.switchCameraRecord
                  ? _c("c-icons", { attrs: { name: "cic_record" } })
                  : _c("c-icons", { attrs: { name: "cic_camera" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPause,
                expression: "isPause",
              },
            ],
          },
          [
            _c("div", { staticClass: "btn-take-picture" }, [
              _c(
                "div",
                { staticClass: "ml-3 btn-m col-6 col-lg-auto px-0" },
                [
                  _c("c-button", {
                    staticClass: "px-0 px-lg-5",
                    attrs: {
                      "data-cy": "btn-take-again",
                      fullWidth: "",
                      type: "button",
                      typeStyle: "secondary",
                    },
                    domProps: { textContent: _vm._s(_vm.$t("chat.takeAgain")) },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.resetTakePicture()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-3 btn-m col-6 col-lg-auto px-0" },
                [
                  _c(
                    "c-button",
                    {
                      staticClass: "px-0 px-lg-5",
                      attrs: {
                        fullWidth: "",
                        type: "button",
                        "data-cy": "btn-submit-picture",
                        typeStyle: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.submitPicture()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.currentLanguage === "id" ? "KIRIM" : "SEND"
                          )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }