import { render, staticRenderFns } from "./app.vue?vue&type=template&id=9c16e704&scoped=true&"
import script from "./app.component.ts?vue&type=script&lang=ts&"
export * from "./app.component.ts?vue&type=script&lang=ts&"
import style0 from "./app.scss?vue&type=style&index=0&id=9c16e704&prod&lang=scss&scoped=true&"
import style1 from "./app.vue?vue&type=style&index=1&id=9c16e704&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c16e704",
  null
  
)

export default component.exports