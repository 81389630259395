import { ICbVwUserCore } from '@/shared/model/cb-vw-user-core.model';
import axios from 'axios';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-cores';

export default class PCbVwUserCoreService {
  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(): Promise<ICbVwUserCore> {
    return new Promise<ICbVwUserCore>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-login')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
