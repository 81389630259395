import axios from 'axios';

import { ICbHelpCenterFeedback } from '@/shared/model/cb-help-center-feedback.model';

const baseApiUrl = 'services/cbuserms/api/cb-help-center-feedbacks';

export default class PCbHelpCenterFeedbackService {
  public create(entity: ICbHelpCenterFeedback): Promise<ICbHelpCenterFeedback> {
    return new Promise<ICbHelpCenterFeedback>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbHelpCenterFeedback): Promise<ICbHelpCenterFeedback> {
    return new Promise<ICbHelpCenterFeedback>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<ICbHelpCenterFeedback> {
    return new Promise<ICbHelpCenterFeedback>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
