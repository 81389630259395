export interface ICbUserCookie {
  id?: number;
  userId?: string | null;
  mandatoryCookie?: boolean | null;
  analyticalCookie?: boolean | null;
  advertisingCookie?: boolean | null;
  isActive?: boolean | null;
  createdDate?: Date | null;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
}

export class CbUserCookie implements ICbUserCookie {
  constructor(
    public id?: number,
    public userId?: string | null,
    public mandatoryCookie?: boolean | null,
    public analyticalCookie?: boolean | null,
    public advertisingCookie?: boolean | null,
    public isActive?: boolean | null,
    public createdDate?: Date | null,
    public createdBy?: string | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null
  ) {
    this.mandatoryCookie = this.mandatoryCookie ?? false;
    this.analyticalCookie = this.analyticalCookie ?? false;
    this.advertisingCookie = this.advertisingCookie ?? false;
    // this.isActive = this.isActive ?? false;
  }
}
