import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CButton extends Vue {
  // ================= SERVICES =================
  // ================= SERVICES =================
  // ================= START VARIABLES =================
  @Prop({ default: 'button' }) type: string;
  @Prop({ default: 'primary' }) typeStyle: string;
  @Prop({ default: 'rounded-pill' }) borderRadius: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: true }) disabledAttr: boolean;
  @Prop({ default: null }) customStyle: string;
  @Prop({ default: null }) dataCyCustom: string;
  @Prop({ default: null }) dataGTMCustom: string;
  // ================= END VARIABLES =================

  // ================= DEFAULT FUNCTION =================
  // ================= DEFAULT FUNCTION =================
  // ================= FUNCTION =================
  // ================= FUNCTION =================

  // ================= START COMPUTE =================
  public get getTypeStyle() {
    switch (this.typeStyle) {
      case 'primary':
        return 'cj-btn-primary';
      case 'secondary':
        return 'cj-btn-secondary';
      case 'tertiary':
        return 'cj-btn-tertiary';
      case 'quartery':
        return 'cj-btn-quartery';
      case 'quaternary':
        return 'cj-btn-quaternary';
      case 'quinary':
        return 'cj-btn-quinary';
    }
  }
  // cj-btn-quaternary
  // ================= START COMPUTE =================
}
