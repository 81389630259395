var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-reset-password" }, [
    !_vm.hideClose
      ? _c(
          "button",
          {
            staticClass: "position-absolute",
            staticStyle: { right: "0", top: "0" },
            attrs: { type: "button" },
            on: { click: _vm.cancel },
          },
          [
            _c("b-icon-x-circle-fill", {
              staticClass: "font-size-18 cj-color-grey-secondary",
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.success
      ? _c("div", [
          _c("div", [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("icon", {
                  staticClass: "m-auto cj-fill-red-primary",
                  attrs: { name: "ic_message", width: "54px", height: "54px" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "font-catamaran cj-color-black-primary font-size-18 font-weight-300 text-center mt-2",
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cbResetPassword.linkReset")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "cj-color-red-primary" }, [
                  _vm._v(_vm._s(_vm.resetAccount.email)),
                ]),
                _vm._v(". "),
                _vm.isEmail
                  ? _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cbResetPassword.checkEmail"
                          )
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isEmail
                  ? _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cbResetPassword.checkInbox"
                          )
                        ),
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "d-flex",
                class: {
                  "cj-btn-text-primary": !_vm.showCountdown,
                  "cj-color-black-primary": _vm.showCountdown,
                },
              },
              [
                _c("p", {
                  staticClass:
                    "font-catamaran font-size-18 font-weight-400 my-auto",
                  attrs: {
                    "data-cy": _vm.showCountdown
                      ? "resend-button-off"
                      : "resend-button-on",
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbComponent.cbVerificationMethod.sendAgain"
                      )
                    ),
                  },
                  on: { click: _vm.resend },
                }),
                _vm._v(" "),
                _vm.showCountdown
                  ? _c("c-time-countdown", {
                      attrs: { timeMilis: 60 * 1000, stopCountdown: 0 },
                      on: {
                        action: function ($event) {
                          _vm.showCountdown = false
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ seconds }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-catamaran font-size-18 font-weight-400 my-auto ml-1",
                                  },
                                  [_vm._v(" (" + _vm._s(seconds) + ") ")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        284939014
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.success
      ? _c(
          "form",
          {
            attrs: { name: "form", role: "form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.requestReset()
              },
            },
          },
          [
            _c(
              "p",
              {
                staticClass:
                  "font-size-18 font-italic font-weight-700 cj-color-black-primary text-center mb-3",
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbComponent.cbResetPassword.enterRegisteredAccount"
                      )
                    ),
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.resetAccount.email.$model,
                      expression: "$v.resetAccount.email.$model",
                    },
                  ],
                  staticClass: "cj-field-border-default cj-field-input",
                  class: {
                    valid:
                      _vm.$v.resetAccount.email.$anyDirty &&
                      !_vm.$v.resetAccount.email.$invalid,
                    invalid:
                      _vm.$v.resetAccount.email.$anyDirty &&
                      _vm.$v.resetAccount.email.$invalid,
                  },
                  attrs: {
                    id: "email",
                    name: "email",
                    email: "",
                    required: "",
                    "data-cy": "emailResetPassword",
                    type: "text",
                    placeholder: _vm.$t(
                      "cbgwApp.cbComponent.cbResetPassword.placeholder.email"
                    ),
                  },
                  domProps: { value: _vm.$v.resetAccount.email.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.resetAccount.email,
                        "$model",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _vm.error == "ERROR"
                  ? _c("div", [
                      _c(
                        "small",
                        {
                          staticClass:
                            "form-text font-roboto font-size-12 font-weight-300",
                        },
                        [
                          _c("span", {
                            staticClass: "text-danger cj-color-status-error",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cbResetPassword.couldntFind"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("u", {
                            staticClass: "cj-color-red-primary hand",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cbResetPassword.register"
                                )
                              ),
                            },
                            on: { click: _vm.openModalRegister },
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "c-button",
                  {
                    staticClass: "font-roboto text-center mt-4",
                    attrs: {
                      type: "submit",
                      disabled: _vm.$v.resetAccount.$invalid || _vm.isInit,
                      "data-cy": "submit",
                      fullWidth: "",
                    },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbComponent.cbResetPassword.submit")
                        ),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }