import dayjs from 'dayjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Picker from 'vue-wheel-picker';

import Icon from '@/shared/icons/icon.vue';
import CPopupBottom from '../c-popup-bottom/c-popup-bottom.vue';

@Component({
  components: {
    Icon,
    CPopupBottom,
    Picker,
  },
})
export default class CTimepicker extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public value: any;
  @Prop({ default: false }) public isFormat24: boolean;
  @Prop({ default: '00 00' }) public placeholder: string;
  @Prop({ default: '' }) public timeHeight: string;
  @Prop({ default: null }) dataCyCustom: string;
  @Prop({ default: null }) dataGTMCustom: string;
  @Prop({ default: false }) public disabledTime: boolean;
  @Prop({ default: null }) public idCustomPopupTimepicker: string;

  public isShowPopup = false;
  public localValue: any = null;
  public localDate: Date = null;
  public currentMinute = {
    value: '00',
    text: '00',
  };

  public currentHour = {
    value: '00',
    text: '00',
  };
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.valueListen();
  }

  public created(): void {
    this.$root.$off('timepicker::changeValue');
    this.$root.$on('timepicker::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public showPopup() {
    this.isShowPopup = true;
  }

  public updateTime() {
    this.localValue = this.currentHour.value + ':' + this.currentMinute.value;
    this.$emit('time', this.localValue);
    this.isShowPopup = false;
  }

  public handleInput() {
    this.$emit('time', dayjs(this.localDate).format('HH:mm'));
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get getHours() {
    const hours = [];

    for (let i = 0; i < 24; i++) {
      const hour = i.toString().padStart(2, '0');
      const hourObject = {
        value: hour,
        text: dayjs().hour(i).format('HH'),
      };
      hours.push(hourObject);
    }

    return hours;
  }

  public get getMinutes() {
    const minutes = [];

    for (let i = 0; i < 60; i++) {
      const minute = i.toString().padStart(2, '0');
      const minuteObject = {
        value: minute,
        text: dayjs().minute(i).format('mm'),
      };
      minutes.push(minuteObject);
    }
    return minutes;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('value')
  public valueListen() {
    if (this.value) {
      const date = dayjs().format('DD/MM/YYYY');

      const combinedDateTime = `${date} ${this.value}`;
      const [datePart, timePart] = combinedDateTime.split(' ');

      const [day, month, year] = datePart.split('/');
      const [hour, minute] = timePart.split(':');

      const dateObject = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), 0);

      this.localDate = dateObject;

      const dateMobile = new Date(dateObject);
      const formattedDateMobile = dayjs(dateMobile).format('HH:mm');
      this.localValue = formattedDateMobile;
      const timeMobile = this.localValue.split(':');
      this.currentHour = {
        value: timeMobile[0],
        text: timeMobile[0],
      };
      this.currentMinute = {
        value: timeMobile[1],
        text: timeMobile[1],
      };
    }
  }
  // ================= END LISTENERS ===================
}
