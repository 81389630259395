var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass: "c-item-chat-message-mom p-2 cj-bg-white-primary",
          attrs: { "data-cy": "item-chat-message-mom" },
        },
        [
          _c("div", { staticClass: "text-center mb-1" }, [
            _c(
              "p",
              {
                ref: "cbVwPawOrderRequirement.title",
                staticClass: "c-icm-font-card-title text-ellipsis oneline",
                attrs: { "data-cy": "message-title-mom" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.cbVwPawOrderRequirement.title) +
                    "\n      "
                ),
                _c(
                  "b-tooltip",
                  {
                    staticClass: "custom-tooltip",
                    attrs: {
                      target: () => _vm.$refs["cbVwPawOrderRequirement.title"],
                      variant: "primary",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-catamaran font-size-12 font-weight-500",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.cbVwPawOrderRequirement.title) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                ref: "cbVwPawOrderRequirement.labelOrderId",
                staticClass:
                  "c-icm-font-card-subtitle text-ellipsis oneline mb-1",
                attrs: { "data-cy": "message-mom-order-id" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                    " | " +
                    _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                    "\n      "
                ),
                _c(
                  "b-tooltip",
                  {
                    staticClass: "custom-tooltip",
                    attrs: {
                      target: () =>
                        _vm.$refs["cbVwPawOrderRequirement.labelOrderId"],
                      variant: "primary",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-catamaran font-size-12 font-weight-500",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                            " | " +
                            _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.cbVwPawOrderRequirement.status ===
              _vm.CbCommonStatus.WAITING_PAYMENT ||
            _vm.cbVwPawOrderRequirement.status === _vm.CbCommonStatus.REQUESTED
              ? _c(
                  "div",
                  { staticClass: "d-flex mb-1" },
                  [
                    _c("c-time-countdown", {
                      staticClass: "m-auto",
                      attrs: {
                        "data-cy": "message-timecountdown",
                        timeDate: _vm.cbVwPawOrderRequirement.offerExpiredDate,
                      },
                      on: {
                        action: function ($event) {
                          return _vm.$root.$emit("opc::actionHandler", {
                            action: "HANDLE_REFRESH_ORDER",
                            cbVwPawOrder: _vm.cbVwPawOrder,
                            cbVwPawOrderRequirement:
                              _vm.cbVwPawOrderRequirement,
                          })
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ hours, minutes, seconds }) {
                              return [
                                hours && hours != "00"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "rounded cj-color-white-primary font-weight-700 font-roboto px-1 d-flex mr-1",
                                        class: [
                                          _vm.getTimeBackgroundByStatus({
                                            status:
                                              _vm.cbVwPawOrderRequirement
                                                .status,
                                          }).timeColor,
                                        ],
                                        attrs: {
                                          "data-cy":
                                            "message-timecountdown-hour",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-size-12" },
                                          [_vm._v(_vm._s(hours))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-size-10 mt-auto",
                                          },
                                          [_vm._v("H")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "rounded cj-color-white-primary font-weight-700 font-roboto px-1 d-flex mr-1",
                                    class: [
                                      _vm.getTimeBackgroundByStatus({
                                        status:
                                          _vm.cbVwPawOrderRequirement.status,
                                      }).timeColor,
                                    ],
                                    attrs: {
                                      "data-cy": "message-timecountdown-minute",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-size-12" },
                                      [_vm._v(_vm._s(minutes))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "font-size-10 mt-auto" },
                                      [_vm._v("M")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "rounded cj-color-white-primary font-weight-700 font-roboto px-1 d-flex",
                                    class: [
                                      _vm.getTimeBackgroundByStatus({
                                        status:
                                          _vm.cbVwPawOrderRequirement.status,
                                      }).timeColor,
                                    ],
                                    attrs: {
                                      "data-cy": "message-timecountdown-second",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-size-12" },
                                      [_vm._v(_vm._s(seconds))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "font-size-10 mt-auto" },
                                      [_vm._v("S")]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2511884255
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.cbVwPawOrderRequirement.status
              ? _c(
                  "p",
                  {
                    staticClass:
                      "c-icm-font-card-status mb-1 text-ellipsis oneline",
                    class: {
                      [_vm.getOrderStatusColorByStatus(
                        _vm.cbVwPawOrderRequirement.status,
                        "text"
                      )]: _vm.getOrderStatusColorByStatus(
                        _vm.cbVwPawOrderRequirement.status,
                        "text"
                      ),
                    },
                    attrs: { "data-cy": "message-status" },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.getOrderStatusTextByStatusOrder(
                            _vm.cbVwPawOrderRequirement.status
                          )
                        ) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded cj-border-color-red-primary border-width-1 p-2 mb-2",
            },
            [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c("p", { staticClass: "c-icm-font-title my-auto" }, [
                  _vm._v("Notes"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-block" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-1 py-0 rounded border-width-1 cj-border-color-grey-secondary",
                      attrs: {
                        "data-cy": "message-btn-drop-note",
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showNote = !_vm.showNote
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass:
                          "my-auto cj-fill-grey-primary ic_arrow_dropdown",
                        class: _vm.showNote ? "show-dropdown" : "",
                        attrs: {
                          name: "ic_down",
                          width: "1rem",
                          height: "1rem",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  model: {
                    value: _vm.showNote,
                    callback: function ($$v) {
                      _vm.showNote = $$v
                    },
                    expression: "showNote",
                  },
                },
                [
                  _vm.cbTextRestrict
                    ? _c("c-textarea-preview", {
                        staticClass: "c-icm-font-content",
                        attrs: {
                          "data-cy": "message-notes",
                          text: _vm.cbTextRestrict.content,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "cbVwPawOrderRequirement.currency",
              staticClass:
                "rounded cj-border-color-red-primary border-width-1 p-2 mb-2 d-flex justify-content-between w-100 flex-column",
            },
            [
              _c(
                "p",
                { staticClass: "c-icm-font-title text-ellipsis oneline" },
                [_vm._v("Additional Cost")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "c-icm-font-content text-ellipsis oneline",
                  attrs: { "data-cy": "additional-cost" },
                },
                [
                  _vm.cbVwPawOrderRequirement.amount
                    ? _c(
                        "span",
                        { attrs: { "data-cy": "message-additional-cost" } },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.cbVwPawOrderRequirement.currency) +
                              " " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.cbVwPawOrderRequirement.amount,
                                  _vm.cbVwPawOrderRequirement.currency
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          attrs: { "data-cy": "message-additional-cost-empty" },
                        },
                        [_vm._v("-")]
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["cbVwPawOrderRequirement.currency"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v("Additional Cost")]
                  ),
                  _vm._v(" "),
                  _vm.cbVwPawOrderRequirement.amount
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.cbVwPawOrderRequirement.currency) +
                              " " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.cbVwPawOrderRequirement.amount,
                                  _vm.cbVwPawOrderRequirement.currency
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [_vm._v("-")]
                      ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  ref: "cbVwPawOrderRequirement.deliveryTime",
                  staticClass:
                    "rounded cj-border-color-red-primary border-width-1 p-2 mb-2 d-flex justify-content-between w-100 flex-column",
                },
                [
                  _c(
                    "p",
                    { staticClass: "c-icm-font-title text-ellipsis oneline" },
                    [_vm._v("Additional Delivery Time")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "c-icm-font-content text-ellipsis-oneline",
                      attrs: { "data-cy": "message-delivery-time" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.getDeliveryTimeType(
                              _vm.cbVwPawOrderRequirement.deliveryTimeType,
                              _vm.cbVwPawOrderRequirement.deliveryTime
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () =>
                      _vm.$refs["cbVwPawOrderRequirement.deliveryTime"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v("Additional Delivery Time")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.getDeliveryTimeType(
                              _vm.cbVwPawOrderRequirement.deliveryTimeType,
                              _vm.cbVwPawOrderRequirement.deliveryTime
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass: "mb-2 c-icm-font-btn",
              attrs: {
                fullWidth: "",
                "data-cy": "message-btn-view-mom",
                "type-style": "secondary",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleOpenPreviewMom.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                staticClass: "text-uppercase",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cChatMomEdit.view")
                  ),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.cbVwPawOrderRequirement &&
          (_vm.cbVwPawOrderRequirement.status ===
            _vm.CbCommonStatus.WAITING_PAYMENT ||
            _vm.cbVwPawOrderRequirement.status ===
              _vm.CbCommonStatus.REQUESTED) &&
          _vm.cbVwPawOrderRequirement.buyerId === _vm.currentUser.id
            ? _c(
                "c-button",
                {
                  staticClass: "wf-accept-mom c-icm-font-btn",
                  attrs: { fullWidth: "", "data-cy": "message-btn-accept-mom" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleOpenConfirmationAccept.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c("span", {
                    staticClass: "text-uppercase",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cChatMomEdit.accept")
                      ),
                    },
                  }),
                  _vm._v(
                    "\n    " +
                      _vm._s(
                        _vm.cbVwPawOrderRequirement.amount &&
                          _vm.cbVwPawOrderRequirement.amount > 0
                          ? `& ${_vm
                              .$t("cbgwApp.cbComponent.cChatMomEdit.payment")
                              .toLocaleUpperCase()}`
                          : ""
                      ) +
                      "\n  "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }