import axios, { CancelTokenSource } from 'axios';

const baseApiUrlSetPinFinish = 'services/cbuserms/api/account/set-pin/finish';
const baseApiUrlResetPinFinish = 'services/cbuserms/api/account/reset-pin/finish';
const baseApiUrlResetPinInit = 'services/cbuserms/api/account/reset-pin/init';
const baseApiUrlChangePin = 'services/cbuserms/api/account/change-pin';
const baseApiUrlSetPin = 'services/cbuserms/api/account/set-pin/init';
const baseApiUrlCheckCurrenntPin = 'services/cbuserms/api/account/check-current-pin';
const baseApiUrlCheckOtp = 'services/cbuserms/api/account/set-pin/check-otp';
const baseApiUrlResetCheckOtp = 'services/cbuserms/api/account/reset-pin/check-otp';

export default class PCbUserPinService {
  public postChangePin(entity: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlChangePin}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public postCheckOtp(entity: any, cancelToken?: CancelTokenSource): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlCheckOtp}`, entity, { cancelToken: cancelToken?.token })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public postCheckResetOtp(entity: any, cancelToken?: CancelTokenSource): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlResetCheckOtp}`, entity, { cancelToken: cancelToken?.token })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public postSetPin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseApiUrlSetPin)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public postCurrentPin(entity: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlCheckCurrenntPin}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public postSetPinFinish(entity: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrlSetPinFinish}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public postResetPinInit(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseApiUrlResetPinInit)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public postResetPinFinish(entity: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseApiUrlResetPinFinish, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
