import { CbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { CbVwAuctionProject } from '@/shared/model/cb-vw-auction-project.model';
import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import locationUltis from '@/shared/data/location-ultis.service';
import CUploadFileIcon from '@/components/c-upload-file/c-upload-file-icon/c-upload-file-icon.vue';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

@Component({
  components: {
    Icon,
    CTextareaPreview,
    CUploadFileIcon,
  },
  mixins: [locationUltis, StatusTextEnId],
})
export default class CApPitchPreview extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: new CbTextNoAuth() }) public cbTextNoAuth: CbTextNoAuth;
  @Prop({ default: new CbVwAuctionProject() }) public vwAuctionProject: CbVwAuctionProject;
  @Prop({ default: true }) public closeModalId: boolean;
  public CbBookmarkType = CbBookmarkType;
  public CbSvcType = CbSvcType;
  public CbCommonStatus = CbCommonStatus;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }
  public downloadFile(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get cbUserBookmarkAggs() {
    return this.$store.getters.GET_USER_BOOKMARK_AUCTION_PROJECT;
  }
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
