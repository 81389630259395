import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { Subscription } from 'rxjs';

import SocketLiveAgentService from './admin/socket/socket-live-agent.service';
import SocketService from './admin/socket/socket.service';
import AccountService from '@/account/account.service';

import { CbVwPawOrder } from './shared/model/cb-vw-paw-order.model';
import { ICbVwUser } from './shared/model/cb-vw-user.model';
import { SocketTopic } from './shared/model/enumerations/socket-topic.model';
import { ISocketMessage } from './shared/model/socket-message.model';

@Component({
  components: {},
})
export default class AppSocket extends Vue {
  @Inject('socketService') private socketService: () => SocketService;
  @Inject('socketLiveAgentService') private socketLiveAgentService: () => SocketLiveAgentService;
  @Inject('accountService') private accountService: () => AccountService;

  private subscriptionNotif?: Subscription;
  private subscriptionConversationBasedOnMember?: Subscription;
  private subscriptionConversationId?: Subscription;
  private subscribeUserOnline?: Subscription;
  private subscriptionLiveAgentConversationId?: Subscription;
  private subscribePawOrderPaid?: Subscription;
  private subscribeCbOrderTrackLocation?: Subscription;
  private subscribeOrderTrackShowHideMap?: Subscription;
  private subscribeBlockedAndSuspend?: Subscription;
  private subscribeTktReplied?: Subscription;
  private ordersOnMyWay: CbVwPawOrder[] = [];
  private lastPosition: google.maps.GeolocationPosition;
  private subscribeMsgLastSeen?: Subscription;

  public created() {
    Vue.prototype.$socketUnsubscribeConversationId = () => {
      if (this.subscriptionConversationId) {
        this.subscriptionConversationId.unsubscribe();
        this.subscriptionConversationId = undefined;
      }
    };
    Vue.prototype.$socketSubscribeConversationId = (conversationId: number) => {
      this.subscriptionConversationId = this.socketService().subscribeConversationId((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveOneMessage', msg.conversationId, msg.messageId);
      }, conversationId);
    };

    Vue.prototype.$socketUnsubscribePawOrderPaid = () => {
      if (this.subscribePawOrderPaid) {
        this.subscribePawOrderPaid.unsubscribe();
        this.subscribePawOrderPaid = undefined;
      }
    };
    Vue.prototype.$socketSubscribePawOrderPaid = () => {
      this.subscribePawOrderPaid = this.socketService().subscribePawOrderPaid((msg: ISocketMessage) => {
        if (msg.externalEntity && msg.externalId) {
          this.$root.$emit('opc::receiveUpdateFromSocket', msg);
          this.$root.$emit('do::receiveUpdateFromSocket', msg);
          this.$root.$emit('dashboardActiveOrder::receiveUpdateFromSocket', msg);
        }
      });
    };

    Vue.prototype.$socketUnsubscribeLiveAgentConversationId = () => {
      this.unsubscribeLiveAgent();
    };
    Vue.prototype.$socketSubscribeLiveAgentConversationId = (conversationId: number) => {
      this.subscriptionLiveAgentConversationId = this.socketLiveAgentService().subscribeConversationId((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveOneMessageLa', msg.conversationId, msg.messageId);
      }, conversationId);
    };

    Vue.prototype.$socketUnSubscribeCbOrderTrackLocation = () => {
      if (this.subscribeCbOrderTrackLocation) {
        this.subscribeCbOrderTrackLocation.unsubscribe();
        this.subscribeCbOrderTrackLocation = undefined;
      }
    };
    Vue.prototype.$socketSubscribeCbOrderTrackLocation = (orderId: number, callback: (msg: ISocketMessage) => void) => {
      this.subscribeCbOrderTrackLocation = this.socketService().subscribeCbOrderTrackLocation(
        (msg: ISocketMessage) => callback(msg),
        orderId
      );
    };

    Vue.prototype.$socketUnsubscribeLiveAgentMsgLastSeen = () => {
      if (this.subscribeMsgLastSeen) {
        this.subscribeMsgLastSeen.unsubscribe();
        this.subscribeMsgLastSeen = undefined;
      }
    };

    Vue.prototype.$socketSubscribeLiveAgentMsgLastSeen = (conversationId: number) => {
      this.subscribeMsgLastSeen = this.socketLiveAgentService().subscribeLastSeen((msg: ISocketMessage) => {
        this.$root.$emit('chat::retrieveOneMessageLaLastSeen', msg.conversationId, msg);
      }, conversationId);
    };

    this.$root.$off('socket::initSocketLiveAgent');
    this.$root.$on('socket::initSocketLiveAgent', () => {
      this.initSocketLiveAgent();
    });

    this.$root.$off('socket::retrieveCbVwPawOrderOnMyWay4subscribeCbOrderTrackLocation');
    this.$root.$on('socket::retrieveCbVwPawOrderOnMyWay4subscribeCbOrderTrackLocation', () => this.retrieveCbVwPawOrderOnMyWay());

    this.initConnectSocketAndWatch();
  }

  public initConnectSocketAndWatch() {
    this.$store.watch(
      () => this.$store.getters.authenticated,
      () => {
        if (this.$store.getters.authenticated) {
          this.initSocket();
        } else {
          this.unsubscribe();
        }
      }
    );

    if (this.$store.getters?.account?.id) {
      this.initSocket();
    }
  }

  public initSocket(): void {
    this.socketService().isSessionLive();
    this.unsubscribe();
    this.socketService().connectCustome();
    this.socketService().stomp.connected$.subscribe(() => {
      this.$root.$emit('chat::reBroadcastMessageAfterOnPause');
    });

    this.subscriptionNotif = this.socketService().subscribeNotif((msg: ISocketMessage) => {
      const h = this.$createElement;
      const htmlToVnode = h('p', { domProps: { innerHTML: msg.body } });
      let variant = 'info';

      if (
        msg.title.toLowerCase().includes('late order') ||
        msg.title.toLowerCase().includes('order overdue') ||
        msg.title.toLowerCase().includes('order cancelled') ||
        msg.title.toLowerCase().includes('re-upload needed for paw application')
      ) {
        variant = 'warning';
      }

      (<any>this.$root).$bvToast.toast(msg.title ? msg.title : htmlToVnode, {
        toaster: 'b-toaster-top-center',
        title: msg.title,
        variant,
        solid: true,
        autoHideDelay: 5000,
      });
      (<any>this.$root).$emit('onNotifRefreshList', msg);
      this.$root.$emit('appnotif::retrieveNotifCount');
    });

    this.subscriptionConversationBasedOnMember = this.socketService().subscribeConversationBasedOnMember((msg: ISocketMessage) => {
      (<any>this.$root).$emit('onNotifConversationRefreshList', msg);
      // this.$root.$emit('chat::clearRetrieveAllChtVwConversations', msg);
      // this.$root.$emit('chat::retrieveTotalCountUnread');
      this.$root.$emit('chat::listenConversation', msg);
      this.$root.$emit('appnotif::retrieveChtConversationCount');
      // this.$root.$emit('chat::retrieveCategoryChtVwConversations');
    });

    this.subscribeUserOnline = this.socketService().subscribeUserOnline((msg: ISocketMessage) => {
      const userIdsOnline = this.$store.getters.userIdsOnline;
      const foundIdx = userIdsOnline.findIndex(x => x === msg.actionBy);

      if (foundIdx > -1 && msg.isOnline) {
        //found and online
      } else if (foundIdx > -1 && !msg.isOnline) {
        //found and online
        userIdsOnline.splice(foundIdx, 1);
      } else if (foundIdx <= -1 && msg.isOnline) {
        userIdsOnline.unshift(msg.actionBy);
      }

      this.$store.commit('userIdsOnline', userIdsOnline);
    });

    this.subscribeBlockedAndSuspend = this.socketService().subscribeBlockedAndSuspend((msg: ISocketMessage) => {
      if (msg.topic == SocketTopic.CB_SUSPEND_AND_BLOCKED) {
        setTimeout(() => {
          this.accountService().logout();
        }, 7000);
      }
    });

    this.subscribeTktReplied = this.socketService().subscribeTktReplied((msg: ISocketMessage) => {
      if (msg.topic == SocketTopic.CB_TKT_REPLIED) {
        (<any>this.$root).$emit('rt::changeFunction', 'findCmtKt', +msg.externalId);
      }
    });

    //pindah ketika login ~wildan
    // this.retrieveCbVwPawOrderOnMyWay().catch(err => console.log(err));
    this.$root.$emit('socket::retrieveCbVwPawOrderOnMyWay4subscribeCbOrderTrackLocation');
  }

  public unsubscribe(): void {
    if (this.subscriptionNotif) {
      this.subscriptionNotif.unsubscribe();
      this.subscriptionNotif = undefined;
    }
    if (this.subscriptionConversationBasedOnMember) {
      this.subscriptionConversationBasedOnMember.unsubscribe();
      this.subscriptionConversationBasedOnMember = undefined;
    }

    if (this.subscribeUserOnline) {
      this.subscribeUserOnline.unsubscribe();
      this.subscribeUserOnline = undefined;
    }

    if (this.subscribePawOrderPaid) {
      this.subscribePawOrderPaid.unsubscribe();
      this.subscribePawOrderPaid = undefined;
    }

    if (this.subscribeCbOrderTrackLocation) {
      this.subscribeCbOrderTrackLocation.unsubscribe();
      this.subscribeCbOrderTrackLocation = undefined;
    }

    if (this.subscribeOrderTrackShowHideMap) {
      this.subscribeOrderTrackShowHideMap.unsubscribe();
      this.subscribeOrderTrackShowHideMap = undefined;
    }

    if (this.subscribeBlockedAndSuspend) {
      this.subscribeBlockedAndSuspend.unsubscribe;
      this.subscribeBlockedAndSuspend = undefined;
    }

    if (this.subscribeTktReplied) {
      this.subscribeTktReplied.unsubscribe;
      this.subscribeTktReplied = undefined;
    }
  }

  public initSocketLiveAgent(): void {
    this.unsubscribeLiveAgent();
    this.socketLiveAgentService().connect();
  }

  public unsubscribeLiveAgent(): void {
    if (this.subscriptionLiveAgentConversationId) {
      this.subscriptionLiveAgentConversationId.unsubscribe();
      this.subscriptionLiveAgentConversationId = undefined;
    }
  }

  public retrieveCbVwPawOrderOnMyWay() {
    // move to mobile app only
  }

  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public onGetPartnerLocation(currentPosition: google.maps.GeolocationPosition): void {
    if (
      this.lastPosition?.coords?.latitude == currentPosition.coords.latitude &&
      this.lastPosition?.coords?.longitude == currentPosition.coords.longitude
    ) {
      return;
    }

    console.log('1share location>>>');
    this.lastPosition = currentPosition;

    for (const order of this.ordersOnMyWay) {
      if (order.partnerId === this.currentUser.id) {
        const msg: ISocketMessage = {
          topic: SocketTopic.CB_ORDER_TRACK_LOCATION,
          statusInt: 0,
          orderId: order.id,
          latGoogle: currentPosition.coords.latitude + '',
          longGoogle: currentPosition.coords.longitude + '',
        };

        this.socketService().broadcastCurrentLocationBasedOnOrderId(msg);
        // }
      }
    }
  }

  public get getUser() {
    return this.$store.getters.account;
  }
}
