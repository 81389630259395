import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

import PWfVwWorkflowService from '@/services/wf-vw-workflow.service';
import { IWfVwWorkflow } from './shared/model/wf-vw-workflow.model';
import { WfKeyType } from './shared/model/enumerations/wf-key-type';
import { WfHistory } from './shared/model/wf-history.model';
import { ICbPawOrderRequirement } from './shared/model/cb-paw-order-requirement.model';
import { CbRequirementType } from './shared/model/enumerations/cb-requirement-type.model';
import { IPayPayload2023, PayPayload2023 } from './shared/model/pay-payload-2023.model';
import { PayMethodType } from './shared/model/enumerations/pay-method-type.model';
import { TypeComponent } from './shared/model/enumerations/type-component.model';

// FIELD
export const WFNAME_OR_FIELD_WORKER = 'OR_FIELD_WORKER';
export const WFKEY_CREATE_CLOCK_OUT = 'CREATE_CLOCK_OUT';
export const WFKEY_SEND_OFFER = 'SEND_OFFER';
export const WFKEY_ADDITIONAL_CHARGE = 'ADDITIONAL_CHARGE';
export const WFKEY_ADDITIONAL_FREE = 'ADDITIONAL_FREE';
export const WFKEY_ACCEPT_ADDITIONAL_FREE = 'ACCEPT_ADDITIONAL';
export const WFKEY_DECLINE_ADDITIONAL_CHARGE = 'DECLINE_ADDITIONAL_2';
export const WFKEY_DECLINE_ADDITIONAL_FREE = 'DECLINE_ADDITIONAL';
export const WFKEY_PAYMENT_REQUEST_ADDITIONAL = 'PAYMENT_REQUEST_ADDITIONAL';
export const WFKEY_PAYMENT_REQUEST_ORDER = 'PAYMENT_REQUEST_ORDER';
export const WFKEY_CREATE_CLOCK_IN = 'CREATE_CLOCK_IN';
export const WFKEY_CREATE_ON_MY_WAY = 'CREATE_ON_MY_WAY';
export const WFKEY_CREATE_COMPLETED = 'CREATE_COMPLETED';
export const WFKEY_CHANGE_STATUS_FROM_LATE = 'CHANGE_STATUS_FROM_LATE';
export const WFKEY_CHANGE_STATUS_FROM_LATE_ON_TRACK = 'CHANGE_STATUS_FROM_LATE_ON_TRACK';

// INSIDE
export const WFNAME_OR_INSIDE_WORKER = 'OR_INSIDE_WORKER';
export const WFKEY_I_SEND_OFFER = 'SEND_OFFER';
export const WFKEY_I_PAYMENT_REQUEST_ORDER = 'PAYMENT_REQUEST_ORDER';
export const WFKEY_I_CREATE_NOTE_FREE = 'CREATE_NOTE_FREE';
export const WFKEY_I_ACCEPT_NOTE_FREE = 'ACCEPT_NOTE_FREE';
export const WFKEY_I_CREATE_NOTE_CHARGE = 'CREATE_NOTE_CHARGE';
export const WFKEY_I_DECLINE_NOTE_CHARGE = 'DECLINE_NOTE_CHARGE';
export const WFKEY_I_DECLINE_NOTE_FREE = 'DECLINE_NOTE_FREE';
export const WFKEY_I_UPLOAD_DELIVERY = 'UPLOAD_DELIVERY';
export const WFKEY_I_UPLOAD_FILE_DRAFT = 'UPLOAD_FILE_DRAFT';
export const WFKEY_I_UPLOAD_FILE_DRAFT_AFTER_DELIVERY = 'UPLOAD_FILE_DRAFT_AFTER_DELIVERY';
export const WFKEY_I_REQUEST_FOR_REVISION_FREE = 'REQUEST_FOR_REVISION_FREE';
export const WFKEY_I_REQUEST_FOR_EXTRA_REVISION = 'REQUEST_FOR_EXTRA_REVISION';
export const WFKEY_I_CANCEL_REVISION_EXTRA = 'CANCEL_REVISION_EXTRA';
export const WFKEY_I_DECLINED_REVISION_EXTRA = 'EXTRA_REVISION_DECLINED';
export const WFKEY_I_DECLINED_REVISION_EXTRA_FREE = 'EXTRA_REVISION_FREE_DECLINED';
export const WFKEY_I_SEND_OFFER_REVISION_EXTRA = 'SEND_OFFER_REVISION_EXTRA';
export const WFKEY_I_SEND_OFFER_REVISION_EXTRA_FREE = 'SEND_OFFER_REVISION_EXTRA_FREE';
export const WFKEY_I_REVISION_EXTRA_ACCEPT_FREE = 'ACCEPTED_REVISION_EXTRA_FREE';
export const WFKEY_I_ACCEPT_DELIVERY = 'ACCEPT_DELIVERY';
export const WFKEY_I_UPLOAD_MILESTONE = 'UPLOAD_MILESTONE';
export const WFKEY_I_CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const WFKEY_I_PAYMENT_REQUEST_NOTE = 'PAYMENT_REQUEST_NOTE';
export const WFKEY_I_ACCEPT_APPOINTMENT = 'ACCEPT_APPOINTMENT';
export const WFKEY_I_DECLINE_APPOINTMENT = 'DECLINE_APPOINTMENT';
export const WFKEY_I_REMINDER_APPOINTMENT = 'REMINDER_APPOINTMENT';
export const WFKEY_I_PAYMENT_EXTRA_REVISION_CHARGE = 'EXTRA_REVISION_CHARGE';
export const WFKEY_I_CREATE_EXTEND_TIME = 'CREATE_EXTEND_TIME';
export const WFKEY_I_CHANGE_STATUS_FROM_OVERDUE = 'CHANGE_STATUS_FROM_OVERDUE';

@Component({
  components: {},
})

// WF
export default class AppWorkflow extends Vue {
  @Inject('pWfVwWorkflowService') public pWfVwWorkflowService: () => PWfVwWorkflowService;

  private currentActionRoles: IWfVwWorkflow[] = [];
  public wfSelectedEventElementTmp = null;

  public wfActionRoleStart(workflowName: string) {
    this.pWfVwWorkflowService()
      .actionRoleStart(workflowName)
      .then(res => {
        this.setDataWfToAttribute(res.data);
      })
      .catch(err => {});
  }

  public wfActionRoleCurrent(workflowName: string, externalId: number) {
    this.pWfVwWorkflowService()
      .actionRoleCurrent(workflowName, externalId)
      .then(res => {
        this.setDataWfToAttribute(res.data);
      })
      .catch(err => {});
  }

  public async wfGetWfHistoryByPayment(payload: PayPayload2023) {
    let actionParam: WfHistory;
    const workflowName = payload.cbVwPawOrder.workflowName;

    // check important
    // payload.cbVwPawOrderRequirement.requirementType
    // and action role from workflow to define condition
    console.log('important: ' + workflowName + '  ' + payload.cbVwPawOrderRequirement.requirementType + '    ' + payload.payMethodType);

    if (
      workflowName == WFNAME_OR_FIELD_WORKER &&
      payload.cbVwPawOrderRequirement.requirementType == CbRequirementType.REQUIREMENT &&
      (payload.payMethodType == PayMethodType.ORDER_PAW || payload.payMethodType == PayMethodType.ORDER_PAW_DETAIL)
    ) {
      actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_PAYMENT_REQUEST_ORDER);
    } else if (
      workflowName == WFNAME_OR_FIELD_WORKER &&
      payload.cbVwPawOrderRequirement.requirementType == CbRequirementType.MOM &&
      (payload.payMethodType == PayMethodType.ORDER_PAW || payload.payMethodType == PayMethodType.ORDER_PAW_DETAIL)
    ) {
      actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_PAYMENT_REQUEST_ADDITIONAL);
    }
    // INSIDE
    else if (
      workflowName == WFNAME_OR_INSIDE_WORKER &&
      payload.cbVwPawOrderRequirement.requirementType == CbRequirementType.REQUIREMENT &&
      (payload.payMethodType == PayMethodType.ORDER_PAW || payload.payMethodType == PayMethodType.ORDER_PAW_DETAIL)
    ) {
      actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_PAYMENT_REQUEST_ORDER);
    } else if (
      workflowName == WFNAME_OR_INSIDE_WORKER &&
      payload.cbVwPawOrderRequirement.requirementType == CbRequirementType.MOM &&
      (payload.payMethodType == PayMethodType.ORDER_PAW || payload.payMethodType == PayMethodType.ORDER_PAW_DETAIL)
    ) {
      actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_PAYMENT_REQUEST_NOTE);
    } else if (
      workflowName == WFNAME_OR_INSIDE_WORKER &&
      payload.cbVwPawOrderRequirement.requirementType == CbRequirementType.REVISION_EXTRA &&
      (payload.payMethodType == PayMethodType.ORDER_PAW || payload.payMethodType == PayMethodType.ORDER_PAW_DETAIL)
    ) {
      actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_PAYMENT_EXTRA_REVISION_CHARGE);
    } else {
      console.log('wfGetWfHistoryByPayment not define');
      return new Promise(resolve => resolve(null));
    }

    // return await this.invokeActionRoleCurrent(workflowName, actionParam, payload.orderIdParent, payload.orderId);

    //dont call invokeActionRoleCurrent, because low level when requet payment to ignore NOT MATCHING WF when retrying payment
    return new Promise(resolve => resolve(actionParam));
  }

  public async wfGetWfHistoryByExternalId(workflowName: string, externalId: number, WFKEY: string, isSkipValidationWfHistory?: boolean) {
    console.log(workflowName);
    console.log(externalId);
    console.log(WFKEY);

    if (!workflowName || !externalId || !WFKEY) {
      console.log('wfGetWfHistoryByExternalId not define');
      return Promise.reject(false);
    }

    const actionParam: WfHistory = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);

    if (isSkipValidationWfHistory) return actionParam;
    return await this.invokeActionRoleCurrent(workflowName, actionParam, externalId);
  }

  public async wfGetWfHistoryByOR(workflowName: string, externalId: number, req: ICbPawOrderRequirement, WFKEY?: string) {
    console.log(workflowName);

    console.log(req.requirementType);
    console.log(WFKEY);
    console.log(!req.id);

    let actionParam: WfHistory;

    // IF REQ.ID exist get workflow based on sub external id
    if (req.id) {
      if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_DECLINE_NOTE_CHARGE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_DECLINE_NOTE_FREE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_ACCEPT_NOTE_FREE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_CANCEL_REVISION_EXTRA) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_DECLINED_REVISION_EXTRA) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_DECLINED_REVISION_EXTRA_FREE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_SEND_OFFER_REVISION_EXTRA) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_SEND_OFFER_REVISION_EXTRA_FREE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_REVISION_EXTRA_ACCEPT_FREE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_ACCEPT_APPOINTMENT) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_DECLINE_APPOINTMENT) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && WFKEY == WFKEY_I_REMINDER_APPOINTMENT) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && WFKEY == WFKEY_ACCEPT_ADDITIONAL_FREE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && WFKEY == WFKEY_DECLINE_ADDITIONAL_CHARGE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && WFKEY == WFKEY_DECLINE_ADDITIONAL_FREE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else {
        console.log('have id not configure WF');
        return Promise.reject(false);
      }
    } else if (!req.id) {
      if (workflowName == WFNAME_OR_FIELD_WORKER && req.requirementType == CbRequirementType.MOM && req.amount > 0) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_ADDITIONAL_CHARGE);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && req.requirementType == CbRequirementType.MOM) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_ADDITIONAL_FREE);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && req.requirementType == CbRequirementType.CLOCK_IN) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_CREATE_CLOCK_IN);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && req.requirementType == CbRequirementType.ON_MY_WAY) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_CREATE_ON_MY_WAY);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && req.requirementType == CbRequirementType.CLOCK_OUT) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_CREATE_CLOCK_OUT);
      } else if (workflowName == WFNAME_OR_FIELD_WORKER && req.requirementType == CbRequirementType.COMPLETED) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_CREATE_COMPLETED);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.MOM && req.amount > 0) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_CREATE_NOTE_CHARGE);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.MOM) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_CREATE_NOTE_FREE);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.DELIVERY) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_UPLOAD_DELIVERY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.REVISION) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_REQUEST_FOR_REVISION_FREE);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.REVISION_EXTRA) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_REQUEST_FOR_EXTRA_REVISION);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.COMPLETED) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_ACCEPT_DELIVERY);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.MILESTONE) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_UPLOAD_MILESTONE);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.APPOINTMENT) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_CREATE_APPOINTMENT);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.EXTENDED_DELIVERY_TIME) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_CREATE_EXTEND_TIME);
      } else if (workflowName == WFNAME_OR_INSIDE_WORKER && req.requirementType == CbRequirementType.CANCELLED) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY_I_CHANGE_STATUS_FROM_OVERDUE);
      } else if (
        workflowName == WFNAME_OR_FIELD_WORKER &&
        req.requirementType == CbRequirementType.CANCELLED &&
        (WFKEY == WFKEY_CHANGE_STATUS_FROM_LATE || WFKEY === WFKEY_CHANGE_STATUS_FROM_LATE_ON_TRACK)
      ) {
        actionParam = this.wfGetWfHistoryByDirectKey(workflowName, WFKEY);
      } else {
        console.log('WF wfGetWfHistoryByOR not define');
        return Promise.reject(false);
      }
    }
    return await this.invokeActionRoleCurrent(workflowName, actionParam, externalId, req.id);
  }

  private async invokeActionRoleCurrent(workflowName: string, actionParam: WfHistory, externalId: number, subExternalId?: number) {
    return await new Promise<any>((resolve, reject) => {
      this.pWfVwWorkflowService()
        .actionRoleCurrent(workflowName, externalId, subExternalId ? subExternalId : null)
        .then(async res => {
          const found = res.data
            .map(item => item.actorChilds.find(child => child.keyName == actionParam.keyName))
            .filter(item => item !== undefined)[0];
          console.log('>>>>>>>>', found);
          console.log('>>>>>>>> keyname ', actionParam.keyName);

          if (found) {
            resolve(actionParam);
          } else {
            console.log('WF not match');
            this.closeModalAfterWfoNotMatch();

            reject(false);
          }
        })
        .catch(err => {
          console.log('err WF not match');
          this.closeModalAfterWfoNotMatch();

          reject(err);
        });
    });
  }

  private setDataWfToAttribute(wfWorkflows: IWfVwWorkflow[]) {
    this.currentActionRoles = wfWorkflows;

    //class wf-send-offer
    //    console.log(e.target.attributes['data-wf'].value);

    for (const actor of wfWorkflows) {
      if (actor.actorChilds)
        actor.actorChilds.forEach(a => {
          if (actor.keyType == WfKeyType.ACTOR && a.keyType == WfKeyType.PROCESS) {
            if (!a.processClassName) alert(actor.keyName + ' wth WF.processClassName:' + a.processClassName + ' not found');

            this.wfSetAttributeDataByElementClassName(a.processClassName, a.keyName);
          }
        });
    }
  }
  private wfSetAttributeDataByElementClassName(className, valueAsKeyProcess) {
    const collection: HTMLCollectionOf<Element> = document.getElementsByClassName(className);
    (<any>collection).forEach(element => {
      (<any>element).setAttribute('data-wf', valueAsKeyProcess);
    });
  }

  public wfGetWfHistoryByElement(wfSelectedEventElement): WfHistory {
    console.log('>>>>>>>>', wfSelectedEventElement);

    if (!wfSelectedEventElement) return;

    const dataWf = wfSelectedEventElement.target.attributes['data-wf'];
    if (!dataWf) {
      console.log('dataWf not found');
      return;
    }
    const processKeyName = dataWf.value;
    // const wfHistory=this.currentActionRoles.find(a=>a.actorChilds && a.actorChilds.find(c=>c.keyName==processKeyName) );

    console.log('>>>>>>>>', processKeyName);

    console.log('>>>>>>>>', this.currentActionRoles);

    const wfHistory = this.currentActionRoles
      .map(item => item.actorChilds.find(child => child.keyName === processKeyName))
      .filter(item => item !== undefined)[0];

    console.log('>>>>>>>>', wfHistory);
    return wfHistory;
  }

  public wfGetWfHistoryByElementStatic(workflowName: string, wfSelectedEventElement): WfHistory {
    console.log('>>>>>>>>', wfSelectedEventElement);

    if (!wfSelectedEventElement) return;

    const dataWf = wfSelectedEventElement.target.attributes['data-wf'];
    if (!dataWf) {
      console.log('dataWf not found');
      return;
    }
    const processKeyName = dataWf.value;

    console.log('>>>>>>>>', processKeyName);

    const wfHistory: WfHistory = {};
    wfHistory.keyName = processKeyName;
    wfHistory.workflowName = workflowName;

    console.log('>>>>>>>>', wfHistory);
    return wfHistory;
  }

  public wfGetWfHistoryByDirectKey(workflowName: string, processKeyName: string): WfHistory {
    if (!workflowName || !processKeyName) {
      console.log('workflow_name and process not define');
      return;
    }
    const wfHistory: WfHistory = {};
    wfHistory.keyName = processKeyName;
    wfHistory.workflowName = workflowName;
    return wfHistory;
  }

  public closeModalAfterWfoNotMatch() {
    // close modal if Wf not match
    (<any>this.$root).$emit('opc::changeFunction', 'handleActionModal', { type: TypeComponent.CHAT_MODAL, isShowModal: 'closeAll' });
    (<any>this.$root).$emit('opc::changeFunction', 'handleActionModal', {
      type: TypeComponent.DETAIL_ORDER_MODAL,
      isShowModal: 'closeAll',
    });
    (<any>this.$root).$emit('opc::changeValue', 'isSavingPawOrderRequirement', false);
  }
}
