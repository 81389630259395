import { PayDetailVA } from '@/shared/model/pay-detail-va.model';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';

export enum CbListBank {
  BCA = 'BCA',
  MANDIRI = 'MANDIRI',
  BNI = 'BNI',
  BRI = 'BRI',
  BSI = 'BSI',
}

@Component({
  components: {
    Icon,
  },
})
export default class CContentBank extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: new PayDetailVA() }) payDetailVA: PayDetailVA;
  public cbListBank = CbListBank;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
