import { required } from 'vuelidate/lib/validators';
import { CbHelpCenterFeedback, ICbHelpCenterFeedback } from '@/shared/model/cb-help-center-feedback.model';
import { Component, Vue, Emit } from 'vue-property-decorator';
import CUserPhotoLevel from '@/components/c-user-photo-level/c-user-photo-level.vue';
import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import CTextareaNew from '../c-textarea-new/c-textarea-new.vue';

const validations: any = {
  cbHelpCenterFeedback: {
    userId: {},
    feedBack: {},
    externalId: {},
    isActive: {},
    createdDate: {},
    createdBy: {},
    lastModifiedBy: {},
    lastModifiedDate: {},
    feedBackLvl: { required },
    externalType: {},
    description: {},
  },
};
@Component({
  validations,
  components: {
    CUserPhotoLevel,
    CButton,
    Icon,
    CTextareaNew
    
  },
})
export default class CSurvey extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public maxCount = 5000;
  public cbHelpCenterFeedback: ICbHelpCenterFeedback = new CbHelpCenterFeedback();
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  @Emit('cancel')
  public cancel() {
    return;
  }
  @Emit('save')
  public save() {
    return this.cbHelpCenterFeedback;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
