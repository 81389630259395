var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "input",
    _vm._b(
      {
        ref: "inputCurrency",
        staticClass: "c-input-currency cj-field-input",
        class: _vm.customCLass
          ? "cj-bg-red-secondary wrap-border-red-primary"
          : "",
        attrs: {
          "data-cy": _vm.dataCyCustom
            ? `input-currency-${_vm.dataCyCustom}`
            : "input-currency",
          "data-gtm": _vm.dataGTMCustom
            ? `input-currency-${_vm.dataGTMCustom}`
            : "input-currency",
          type: "text",
          autocomplete: "off",
          inputmode: "numeric",
        },
        domProps: { value: _vm.stringValue },
        on: { input: _vm.inputCurrency, paste: _vm.handlePaste },
      },
      "input",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }