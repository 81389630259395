var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      staticClass: "c-navbar p-0 cj-bg-white-primary",
      attrs: { fixed: "top" },
    },
    [
      _vm.navbar.isDefaultMenu
        ? _c(
            "div",
            {
              staticClass:
                "c-navbar-mobile cj-bg-white-primary pl-3 height-navbar-m w-100 d-flex justify-content-between",
            },
            [
              _c(
                "a",
                {
                  staticClass: "my-auto",
                  attrs: { href: "/", "data-cy": "to-dashboard" },
                },
                [
                  _c("img", {
                    staticClass: "c-logo-mobile",
                    staticStyle: { width: "6rem" },
                    attrs: {
                      "data-gtm": "navbar-logo-button",
                      src: `${_vm.$env.CDN_CB}/content/cb-img/ic-logo-black.png`,
                      alt: "Image Klikjob",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "my-auto d-flex align-items-center" }, [
                _vm.authenticated
                  ? _c(
                      "div",
                      {
                        staticClass: "min-navbar cursor-pointer",
                        staticStyle: {
                          "margin-right": "10px",
                          "font-size": "10px",
                        },
                      },
                      [
                        _c("c-navbar-switch-logintype", {
                          staticStyle: { height: "36px" },
                          attrs: { isMarginRight: false, isHome: true },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.authenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "position-relative card-message my-auto cursor pointer",
                        class: _vm.countNotifTotal > 0 ? "mb-2" : "",
                        attrs: { "data-cy": "notification-m" },
                        on: { click: _vm.toPageNotification },
                      },
                      [
                        _vm.countNotifTotal > 0
                          ? _c("div", {
                              staticClass:
                                "cj-bg-status-success count-notif-total rounded-circle mb-2",
                              attrs: { "data-cy": "notification-marked-m" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("icon", {
                          staticClass: "cj-fill-black-primary",
                          attrs: {
                            name: "ic_mail_fill",
                            width: "24px",
                            height: "24px",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "my-auto p-3 cursor-pointer",
                    attrs: {
                      "data-cy": "navbar-hamburger",
                      "data-gtm": "navbar-hamburger-button",
                    },
                    on: { click: _vm.toNavbarMenu },
                  },
                  [
                    _c("icon", {
                      staticClass: "cj-fill-black-primary",
                      attrs: {
                        name: "ic_bars",
                        width: "1.5rem",
                        height: "1.5rem",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "w-100 height-navbar-m border-grey-tertiary-bottom-1 d-flex align-items-center",
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-3 cursor-pointer",
                  attrs: { "data-cy": "btn-back" },
                  on: { click: _vm.goBack },
                },
                [
                  _vm.navbar.isX
                    ? _c("b-icon-x-circle-fill", {
                        staticClass: "font-size-18 cj-color-grey-secondary",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.navbar.isArrow
                    ? _c("font-awesome-icon", {
                        staticClass:
                          "cb-icon-size-arrow-left cj-color-black-primary",
                        attrs: { icon: "fa-solid fa-arrow-left" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-500 font-size-18 cj-color-black-primary mr-auto text-ellipsis oneline pr-3",
                  staticStyle: { "text-transform": "capitalize !important" },
                },
                [_vm._v("\n      " + _vm._s(_vm.navbar.title) + "\n    ")]
              ),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }