import { CBPropIcon } from '@/shared/model/enumerations/cb-input-icon.model';
import FormUtils from '@/shared/utils/form-utils.service';
import { Component, Prop, Mixins } from 'vue-property-decorator';

@Component({
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.maxlength) {
          const max = this.maxlength;
          const total = newVal?.length ? newVal.length : 0;

          if (total > max) {
            this.textRef().value = oldVal;
            this.handleInput();
          }

          this.validatePasteMaxLengthUtils(max, total);
        }
      },
    },
  },
})
export default class CInput extends Mixins(FormUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: '' }) public value: string;
  @Prop({ default: null }) public maxlength: number;
  @Prop({ default: null }) public prepend: CBPropIcon;
  @Prop({ default: null }) public append: CBPropIcon;
  @Prop({ default: null }) public placeholder: string;
  @Prop({ default: false }) public hideCounter: boolean;
  @Prop({ default: '' }) public customClass: string;
  @Prop({ default: false }) public readonly: boolean;
  @Prop({ default: false }) public useValidChar: boolean;
  @Prop({ default: null }) public dataCyCustom: string;
  @Prop({ default: null }) public dataGTMCustom: string;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleInput() {
    if (this.textRef()?.value || this.textRef()?.value === '') {
      if (this.useValidChar && this.textRef()?.value) {
        this.textRef().value = this.$options.filters.regexLetterNumberAndValidChar(this.textRef().value);
      }
      this.$emit('input', this.textRef()?.value);
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get valueLength() {
    let length = 0;
    if (this.value?.length > 0 && this.maxlength) {
      length = this.value.length;
    }
    return length;
  }
  public textRef() {
    return <any>this.$refs.textRef;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
