var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawDetailAttPortfolios &&
    _vm.cbVwPawDetailAttPortfolios.length > 0
    ? _c(
        "div",
        { staticClass: "c-pd-portfolio-slideshow" },
        [
          _c(
            "swiper",
            {
              staticClass:
                "px-4 c-pd-portfolio-slideshow-wrapper cb-slideshow-container",
              attrs: {
                pagination: {
                  el: `.swiper-item-c-pd-portfolio-slideshow`,
                  clickable: true,
                },
                navigation: "",
                "slides-per-view": "auto",
                "space-between": 16,
                loop: false,
                threshold: 10,
              },
            },
            _vm._l(_vm.cbVwPawDetailAttPortfolios, function (item, index) {
              return _c(
                "swiper-slide",
                {
                  key: index,
                  class:
                    _vm.cbVwPawDetailAttPortfolios.length == 1 ? "actived" : "",
                  attrs: { "data-cy": "portofolio-paw" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit("handleShowPortfolio", item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "c-pd-portfolio-slideshow-card-wrapper" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-img-wrapper cb-img-wrapper cb-ratio-4x3",
                        },
                        [
                          _c("img", {
                            attrs: {
                              "data-cy": "detail-paw-portfolio-image",
                              src: _vm.$options.filters.LAZY(
                                `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                                  _vm.isVideo(
                                    item.cbAttachmentNoAuth.attContentType
                                  )
                                    ? item.cbAttachmentNoAuth &&
                                      item.cbAttachmentNoAuth.refThumbnailId
                                    : item.cbAttachmentNoAuth &&
                                      item.cbAttachmentNoAuth.id
                                }`,
                                1092,
                                "ic-lazy-paw.png"
                              ),
                              onerror:
                                _vm.$options.filters.LAZY_ERROR(
                                  "ic-lazy-paw.png"
                                ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex c-pd-portfolio-count px-2 py-1",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex my-auto mr-2" },
                                [
                                  _c("icon", {
                                    staticClass: "cj-fill-red-primary",
                                    attrs: {
                                      name: "ic_picture_double",
                                      height: "18px",
                                      width: "18px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto cj-color-red-primary font-weight-500 my-auto",
                                  class: _vm.isMobile
                                    ? "font-size-14 "
                                    : " font-size-18",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.countPortfolio(item.detailId) &&
                                          _vm.countPortfolio(item.detailId)
                                            .length
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "px-4 py-3 swiper-item-c-pd-portfolio-slideshow text-center",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }