import { CbBankAccount, ICbBankAccount } from '@/shared/model/cb-bank-account.model';
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import CInputNumber from '@/components/c-input-number/c-input-number.vue';
import dayjs from 'dayjs';
import FormUtils from '@/shared/utils/form-utils.service';
import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';
import Icon from '@/shared/icons/icon.vue';

@Component({
  components: {
    CInputNumber,
    Icon
  },
  watch: {
    'localCbBankAccount.accountHolderName': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 150;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.localCbBankAccount.accountHolderName = oldVal;
        }
        this.handleCheckHolderName(this.localCbBankAccount.accountHolderName);

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
})
export default class CItemFpBankAccount extends Mixins(FormUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbBankAccount: CbBankAccount;
  @Prop({ default: null }) public selectedBankAccountId: string;
  @Prop({ default: false, type: Boolean }) public isChecked: boolean;
  @Prop({ default: false, type: Boolean }) public disabled: boolean;
  public localCbBankAccount: ICbBankAccount = new CbBankAccount();

  public deviceSizeType = DeviceSizeType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.cbBankAccountListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public changeCbBankAccount() {
    if (this.localCbBankAccount && this.localCbBankAccount.expiryDateLabel != null && this.localCbBankAccount.expiryDateLabel.length >= 5) {
      this.processAssignBankExpiry();
    }
    let tmpCbBankAccounts: ICbBankAccount[] = [];
    this.$emit('getVariable', 'cbBankAccounts', cbBankAccounts => {
      tmpCbBankAccounts = [...cbBankAccounts];
    });
    if (tmpCbBankAccounts && tmpCbBankAccounts.length > 0) {
      let foundIndex = tmpCbBankAccounts.findIndex(e => e.id == this.cbBankAccount.id);
      if (foundIndex >= 0) {
        Vue.set(tmpCbBankAccounts, foundIndex, this.localCbBankAccount);
        this.$emit('changeValue', 'cbBankAccounts', tmpCbBankAccounts);
      }
    }
  }
  public processAssignBankExpiry() {
    this.localCbBankAccount.expiredDate = dayjs(this.localCbBankAccount.expiredDate).toDate();
    this.localCbBankAccount.expiredDate = dayjs(this.localCbBankAccount.expiredDate)
      .set('month', +Number(this.localCbBankAccount.expiryDateLabel.split('/')[0]))
      .toDate();
    this.localCbBankAccount.expiredDate = dayjs(this.localCbBankAccount.expiredDate)
      .set('year', Number(dayjs().toDate().getFullYear().toString().slice(0, 2) + this.localCbBankAccount.expiryDateLabel.split('/')[1]))
      .toDate();
  }
  public inputCardNumber(e: any) {
    let newString = e.target.value.replace(/\s/g, '');
    if (!Number(newString)) {
      return newString.replace(/[^,\d]/g, '');
    } else if (e.target.value) {
      const v = e.target.value
        .replace(/\s+/g, '')
        .replace(/[^,\d]/g, '')
        .replace(/[^0-9]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || '';
      let parts = [];
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      let result: string = '';
      if (parts.length) {
        result = parts.join(' ');
      } else {
        result = e.target.value;
      }
      return result;
    } else {
      return null;
    }
  }
  public formatExpiredDate(e) {
    let code = e.keyCode;
    let allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    return e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );
  }

  public handleCheckHolderName(username: string) {
    if (username) {
      this.localCbBankAccount.accountHolderName = username.replace(/[^A-Za-z ]/gi, '');
    }
    this.changeCbBankAccount();
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getStatusExpired() {
    return (cbCreditCard: ICbBankAccount) => {
      let now = dayjs().toDate();
      if (cbCreditCard && cbCreditCard.expiredDate && dayjs(cbCreditCard.expiredDate).toDate() < now) {
        return true;
      } else {
        return false;
      }
    };
  }
  public get validationCC() {
    return (field: string) => {
      if (this.isChecked) {
        if (field == 'accountNumber') {
          if (!this.localCbBankAccount.accountNumber || this.localCbBankAccount.accountNumber.split(' ').join('').length < 16) {
            return true;
          }
        }
        if (field == 'expiryDateLabel') {
          if (!this.localCbBankAccount.expiryDateLabel || this.localCbBankAccount.expiryDateLabel.length < 5) {
            return true;
          }
        }
        if (field == 'cvc') {
          if (!this.localCbBankAccount.cvc || this.localCbBankAccount.cvc.toString().length != 3) {
            return true;
          }
        }
        if (field == 'accountHolderName') {
          if (!this.localCbBankAccount.accountHolderName) {
            return true;
          }
        }
        return false;
      }
    };
  }
  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('cbBankAccount')
  public cbBankAccountListener() {
    if (this.cbBankAccount && this.cbBankAccount.id) {
      this.localCbBankAccount = Object.assign({}, this.localCbBankAccount, this.cbBankAccount);
    }
  }
  // ================= END LISTENERS ==================
}
