var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-img-banner-main h-100" },
    [
      _c(
        "vueper-slides",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPlaying,
              expression: "!isPlaying",
            },
          ],
          staticClass: "no-shadow",
          attrs: {
            autoplay: _vm.vueperConfig.autoplay,
            infinite: _vm.vueperConfig.infinite,
            transitionSpeed: _vm.vueperConfig.transitionSpeed,
            arrows: _vm.vueperConfig.arrows,
            bullets: _vm.vueperConfig.bullets,
            arrowsOutside: _vm.vueperConfig.arrowsOutside,
            "dragging-distance": 50,
          },
        },
        _vm._l(_vm.CONTENT_BANNER_BUYER, function (lov, idx) {
          return _c("vueper-slide", {
            key: idx,
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "h-100 w-100" }, [
                        _c("div", {
                          staticClass: "size-wrapper",
                          style: `background-image: url(${
                            _vm.$env.CDN_CB
                          }/CbAttachmentNoAuth/${
                            lov
                              ? _vm.$options.filters.findVideoLovId(
                                  lov,
                                  "VIDEO"
                                )
                              : 0
                          }); `,
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$options.filters.isContentLovType(
                                    lov,
                                    "VIDEO"
                                  ) && !_vm.isPlaying,
                                expression:
                                  "$options.filters.isContentLovType(lov, 'VIDEO') && !isPlaying",
                              },
                            ],
                            staticClass: "btn-play",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePlayVideo(lov.value)
                              },
                            },
                          },
                          [
                            _c("b-icon-play-circle", {
                              staticClass:
                                "font-size-64 cj-color-grey-secondary",
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isPlaying,
            expression: "isPlaying",
          },
        ],
        ref: "myVideo",
        staticClass: "size-video",
        attrs: {
          src:
            `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/` + _vm.selectedPlayVideoId,
        },
        on: {
          click: _vm.setPausePlay,
          mousedown: _vm.setPausePlay,
          touchstart: _vm.setPausePlay,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }