import CButton from '@/components/button/c-button.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import Icon from '@/shared/icons/icon.vue';
import { CbBankAccount } from '@/shared/model/cb-bank-account.model';
import FormUtils from '@/shared/utils/form-utils.service';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
const validations: any = {
  cbWithdraw: {
    withdrawAmount: {
      required: e => {
        return +e && +e >= 50000;
      },
    },
  },
  newCbBankAccount: {
    bankCode: { required },
    accountNumber: { required, maxLength: maxLength(16) },
    accountHolderName: { required, maxLength: maxLength(500) },
    expiryDateLabel: { minLength: minLength(5), maxLength: maxLength(5) },
  },
};
@Component({
  validations,
  components: {
    Icon,
    CButton,
  },
  watch: {
    'newCbBankAccount.accountHolderName': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 500;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.newCbBankAccount.accountHolderName = oldVal;
        }

        this.handleCheckUsername(this.newCbBankAccount.accountHolderName);

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
})
export default class CBaBankUpdate extends mixins(JhiDataUtils, FormUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: [] })
  public cbLovBanks;
  @Prop({ default: null })
  public newCbBankAccount: CbBankAccount;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public inputCardNumber(e) {
    let newString = e.target.value.replace(/\s/g, '');
    if (!Number(newString)) {
      newString = newString.replace(/[^\d]+/g, '');
    }
    return newString;
  }

  public selectBankCode(e) {
    this.$emit('selectBankCode', e);
    this.$v.newCbBankAccount.bankCode.$touch();
  }

  public handleCheckUsername(username: string) {
    if (username) {
      let tmpNewBankAccount = Object.assign({}, this.newCbBankAccount);
      tmpNewBankAccount.accountHolderName = username.replace(/[^A-Za-z ]/gi, '');
      this.$emit('changeValue', 'newCbBankAccount', tmpNewBankAccount);
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
