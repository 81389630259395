import { App } from '@/shared/model/enumerations/app.model';
export interface IChtLiveAgentQuestion {
  id?: number;
  app?: App | null;
  email?: string | null;
  phoneCode?: string | null;
  phone?: string | null;
  question?: string | null;
  createdDate?: Date | null;
  liveAgentKeyTrans?: string | null;
}

export class ChtLiveAgentQuestion implements IChtLiveAgentQuestion {
  constructor(
    public id?: number,
    public app?: App | null,
    public email?: string | null,
    public phoneCode?: string | null,
    public phone?: string | null,
    public question?: string | null,
    public createdDate?: Date | null,
    public liveAgentKeyTrans?: string | null
  ) {
    this.phoneCode = this.phoneCode ?? null;
  }
}
