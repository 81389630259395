var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "message-delete-notif" }, [
    _c(
      "div",
      { staticClass: "p-2" },
      [
        _c("b-icon-question-circle", {
          staticClass: "cj-color-red-primary font-size-64",
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "m-0 text-title text-center" }, [
      _vm._v(_vm._s(_vm.$t("chat.deleteChat"))),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "m-0 pb-3 text-sub text-center" }, [
      _vm._v(_vm._s(_vm.$t("chat.msgDeleteChat"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex w-100" }, [
      _c(
        "div",
        { staticClass: "mr-1 w-100" },
        [
          _c(
            "c-button",
            {
              attrs: { fullWidth: "", typeStyle: "secondary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.callBack()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("chat.cancel")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-1 w-100" },
        [
          _c(
            "c-button",
            {
              attrs: { fullWidth: "", typeStyle: "primary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("chat.delete")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }