var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-pd-review-detail-reply" }, [
    !_vm.isHideReply &&
    (_vm.seletedReviewId == _vm.cbVwUserReview.id ||
      (_vm.cbVwUserReviewReplies && _vm.cbVwUserReviewReplies.length > 0))
      ? _c(
          "div",
          { staticClass: "c-pd-review-detail-reply-wrapper p-3" },
          [
            _vm._l(
              _vm.cbVwUserReviewReplies,
              function (cbVwUserReviewReply, index) {
                return _c("div", { key: index, staticClass: "mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex mb-2" },
                    [
                      _c("c-user-photo-level", {
                        staticClass: "mr-2 my-auto cursor-pointer",
                        attrs: {
                          userPhoto: _vm.$options.filters.LAZY(
                            `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${cbVwUserReviewReply.reviewerPhotoId}`,
                            32,
                            "ic-lazy-avatar.png"
                          ),
                          width: "2rem",
                          userLevel: cbVwUserReviewReply.reviewerLevel,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "my-auto mx-0 flex-grow-1 font-roboto font-weight-500 font-size-12 cj-color-black-primary",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(cbVwUserReviewReply.reviewerName) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-auto mx-0 text-right font-roboto font-weight-400 font-size-8 cj-color-black-primary",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("formatDateTimeDefault")(
                                  cbVwUserReviewReply.createdDate
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "m-0 font-roboto font-weight-400 font-size-12 cj-color-black-primary",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(cbVwUserReviewReply.comment) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              }
            ),
            _vm._v(" "),
            _vm.seletedReviewId == _vm.cbVwUserReview.id &&
            _vm.cbVwUserReview.ownerId == _vm.currentUser.id &&
            _vm.cbVwUserReviewReplies &&
            _vm.cbVwUserReviewReplies.length == 0
              ? [
                  _c(
                    "div",
                    { staticClass: "d-flex mb-2" },
                    [
                      _c("c-user-photo-level", {
                        staticClass: "mr-2 my-auto cursor-pointer",
                        attrs: {
                          userPhoto: _vm.$options.filters.LAZY(
                            `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${_vm.currentUser.photoId}`,
                            32,
                            "ic-lazy-avatar.png"
                          ),
                          width: "2rem",
                          userLevel: _vm.cbVwUserReview.reviewerLevel,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "my-auto mx-0 flex-grow-1 font-roboto font-weight-500 font-size-12 cj-color-black-primary",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("sliceCharacter")(
                                  _vm.currentUser.displayName,
                                  16
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "custom-textarea-review-reply-wrapper" },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cbUserReview.comment,
                            expression: "cbUserReview.comment",
                          },
                        ],
                        ref: "textareaMessageReviewReply",
                        staticClass:
                          "cj-field-border-default font-roboto font-weight-400 font-size-14 cj-color-black-primary input-textarea",
                        attrs: { rows: "1" },
                        domProps: { value: _vm.cbUserReview.comment },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.cbUserReview,
                                "comment",
                                $event.target.value
                              )
                            },
                            _vm.handleInputReply,
                          ],
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.sendReply.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "ic-input-btn",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.sendReply()
                            },
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "cj-fill-red-primary",
                            attrs: {
                              name: "ic_send",
                              width: "25px",
                              height: "25px",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.seletedReviewId != _vm.cbVwUserReview.id
      ? _c("div", { staticClass: "d-flex align-items-center" }, [
          _vm.isHideReply &&
          _vm.cbVwUserReviewReplies &&
          _vm.cbVwUserReviewReplies.length > 0
            ? _c(
                "p",
                {
                  staticClass:
                    "my-2 font-catamaran text-justify font-weight-400 font-size-14 cj-btn-text-primary",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleSeeAllReview")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("cbGlobal.seeComment")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.cbVwUserReview.ownerId == _vm.currentUser.id &&
          _vm.cbVwUserReviewReplies &&
          _vm.cbVwUserReviewReplies.length == 0
            ? _c(
                "button",
                {
                  staticClass:
                    "ml-auto btn-reply rounded-circle justify-content-center",
                  on: {
                    click: function ($event) {
                      _vm.isHideReply
                        ? _vm.$emit("handleSeeAllReview")
                        : _vm.$emit("handleShowReview", _vm.cbVwUserReview)
                    },
                  },
                },
                [
                  _c("b-icon-reply-fill", {
                    staticClass: "cj-color-red-primary font-size-18 m-auto",
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }