import { Component, Vue, Prop } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CButton from '../button/c-button.vue';
import { ChtLiveAgentQuestion } from '@/shared/model/cht-live-agent-question.model';
import { required, minLength, email } from 'vuelidate/lib/validators';

const validations: any = {
  tkt: {
    email: {
      required,
      email,
    },
    phoneCode: { required },
    phone: {
      minLength: minLength(4),
      required,
    },
    question: {
      required,
      minLength: minLength(4),
    },
  },
};
@Component({
  validations,
  components: {
    Icon,
    CButton,
  },
})
export default class CCreateLivechat extends Vue {
  @Prop({ default: {} })
  public tkt: ChtLiveAgentQuestion;
  @Prop({ default: null })
  public userLogin;

  public callBack() {
    this.$emit('callBack');
  }

  public get CONTENT_IMAGE() {
    return this.$store.getters.CONTENT_IMAGE;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
}
