import axios from 'axios';

import { ICbTextRestrict } from '@/shared/model/cb-text-restrict.model';

const baseApiUrl = 'services/cbuserms/api/cb-text-restricts';

export default class PCbTextRestrictService {
  public find(id: number, cancelToken?: any): Promise<ICbTextRestrict> {
    return new Promise<ICbTextRestrict>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
