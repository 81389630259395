import Icon from '@/shared/icons/icon.vue';
import { Component, Emit, Inject, Mixins } from 'vue-property-decorator';
import GlobalService from '@/services/global.service';
import CButton from '@/components/button/c-button.vue';
import { email, maxLength, minLength, required } from 'vuelidate/lib/validators';
import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import PUserService from '@/services/user.service';
import PCbOidService from '@/services/cb-oid.service';
import PCbUserInfoService from '@/services/cb-user-info.service';
import CAutocompleteNew from '@/components/c-autocomplete-new/c-autocomplete-new.vue';
import { Debounce } from '@/shared/decorator/debounce';
import ContentService from '@/services/content.service';
import { CbHelpCenterType } from '@/shared/model/enumerations/cb-help-center-type.model';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';
import JhiDataUtils from '@/shared/data/data-utils.service';
import {
  regexMatchChar8,
  regexMatchNumber,
  regexMatchPunctuation,
  regexMatchTextLower,
  regexMatchTextUpper,
} from '@/shared/constant/constant-match-regex';
import FormUtils from '@/shared/utils/form-utils.service';
import CTmpAnnouncement from '@/components/c-tmp-announcement/c-tmp-announcement.vue';

const validations: any = {
  cbUserInfo: {
    email: {
      required,
      email,
      maxLength: maxLength(255),
    },
    phoneCode: { required },
    phone: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(16),
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  confirmPassword: {
    required,
    minLength: minLength(8),
  },
};
@Component({
  validations,
  components: {
    CButton,
    Icon,
    CAutocompleteNew,
    CTmpAnnouncement,
  },
  watch: {
    'cbUserInfo.email': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 255;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.cbUserInfo.email = oldVal;
        }

        this.handleCheckEmail();

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
    'cbUserInfo.phone': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 16;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.cbUserInfo.phone = oldVal;
        }

        this.cbUserInfo.phone = this.$options.filters.regexPhoneNumber(this.cbUserInfo.phone);

        // fix bug auto save form data, and show many alert
        const hasNonNumerics = /\D/.test(newVal);
        if (hasNonNumerics) return;

        this.handleCheckPhone();

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
    'cbUserInfo.password': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 255;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.cbUserInfo.password = oldVal;
        }
        this.removePasswordSpaces(this.cbUserInfo.password);
        this.onInputRequired(this.cbUserInfo.password);

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
    confirmPassword: {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 255;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.confirmPassword = oldVal;
        }

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
})
export default class CRegister extends Mixins(JhiDataUtils, FormUtils) {
  // ================= SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  @Inject('pUserService') private pUserService: () => PUserService;
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;
  @Inject('pCbUserInfoService') public pCbUserInfoService: () => PCbUserInfoService;
  @Inject('contentService') private contentService: () => ContentService;

  // ================= END SERVICES =================
  // ================= VARIABLES =================
  public cbUserInfo: ICbUserInfo = new CbUserInfo();
  public passwordInputType: string = 'password';
  public isLowerCase: boolean = false;
  public isUpperCase: boolean = false;
  public isCharacter: boolean = false;
  public isAllValidate: boolean = false;
  public confirmPassword: string = '';
  public isExistEmail: boolean = false;
  public isExistPhone: boolean = false;
  public isLoadingSubmit: boolean = false;
  public openModalTermOfService: boolean = false;
  public openModalPrivacyPolicy: boolean = false;
  public valueStatusEmail: string = '';
  public valueStatusPhone: string = '';

  public styleValidPassOne: string = 'var(--cj-color-grey-secondary)';
  public styleValidPassTwo: string = 'var(--cj-color-grey-secondary)';
  public styleValidPassThree: string = 'var(--cj-color-grey-secondary)';
  public styleValidPassFour: string = 'var(--cj-color-grey-secondary)';

  public isFetchingLovs: boolean = false;
  public isFetchingPhoneNumber: boolean = false;
  public isFetchingEmail: boolean = false;
  public isDirty: boolean = false;
  // ================= END VARIABLES =================
  // ================= DEFAULT FUNCTION =================
  public created() {
    if (!this.cbUserInfo.phoneCode) {
      this.cbUserInfo.phoneCode = '+62';
      this.cbUserInfo.emailVerificationOpt = false;
      this.cbUserInfo.phoneVerificationOpt = false;
    }

    this.contentService().initRetrieveCbVeHelpCenters(CbHelpCenterType.TERM_OF_SERVICE);
    this.contentService().initRetrieveCbVeHelpCenters(CbHelpCenterType.PRIVACY_POLICY);
    this.contentService().initRetrieveCbLovs(CbLovType.PHONE_CODE);
  }

  // ================= END DEFAULT FUNCTION =================
  // ================= FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }

  @Emit('openModalLogin')
  public openModalLogin() {
    return;
  }

  public handleSubmitRegister() {
    this.isLoadingSubmit = true;
    delete this.cbUserInfo.photo;
    delete this.cbUserInfo.banner;

    this.pCbOidService()
      .registerUser(
        this.cbUserInfo,
        progress => {
          console.log('progress...', progress);
        },
        this.succesRegis
      )
      .then(res => {
        this.isLoadingSubmit = false;
        this.$store.commit('isPhoneTrouble', false);
        (<any>this.$root).$bvToast.toast('Send OTP Success', {
          toaster: 'b-toaster-top-center',
          title: 'Info',
          variant: 'success',
          solid: true,
          autoHideDelay: 3000,
        });
        (<any>this.$root).$emit('submitRegister', res);
      })
      .catch(err => {
        this.isLoadingSubmit = false;
        console.log('ERROR ==> ', err);
      });
  }

  public async succesRegis(result: ICbUserInfo, userInfo: ICbUserInfo) {
    this.isLoadingSubmit = false;
    (<any>this.$root).$emit('submitRegister', result, userInfo);
  }

  public openDialogLogin() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-register');
    this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
  }
  public togglePassword() {
    this.passwordInputType = this.passwordInputType === 'text' ? 'password' : 'text';
  }

  public removePasswordSpaces(e: string) {
    if (e) this.cbUserInfo.password = e.replace(/\s/g, '');
  }

  public onInputRequired(e: string) {
    const value = !e ? '' : e.replace(/\s/g, '');
    if (value === '') {
      this.styleValidPassOne = 'var(--cj-color-grey-secondary)';
      this.styleValidPassTwo = 'var(--cj-color-grey-secondary)';
      this.styleValidPassThree = 'var(--cj-color-grey-secondary)';
      this.styleValidPassFour = 'var(--cj-color-grey-secondary)';
    }
    if (regexMatchTextUpper.test(value)) {
      this.isUpperCase = true;
      this.styleValidPassTwo = 'var(--cj-color-status-success)';
    } else {
      this.isUpperCase = false;
      this.styleValidPassTwo = 'var(--cj-color-status-error)';
    }
    if (regexMatchTextLower.test(value)) {
      this.isLowerCase = true;
      this.styleValidPassOne = 'var(--cj-color-status-success)';
    } else {
      this.isLowerCase = false;
      this.styleValidPassOne = 'var(--cj-color-status-error)';
    }

    if (regexMatchChar8.test(value)) {
      this.isCharacter = true;
      this.styleValidPassThree = 'var(--cj-color-status-success)';
    } else {
      this.isCharacter = false;
      this.styleValidPassThree = 'var(--cj-color-status-error)';
    }

    if (regexMatchNumber.test(value) || regexMatchPunctuation.test(value)) {
      this.isAllValidate = true;
      this.styleValidPassFour = 'var(--cj-color-status-success)';
    } else {
      this.isAllValidate = false;
      this.styleValidPassFour = 'var(--cj-color-status-error)';
    }
  }

  @Debounce(600)
  public handleCheckEmail() {
    if (this.cbUserInfo.email && this.cbUserInfo.email.includes('@')) {
      this.findExistEmail();
    } else {
      this.isExistEmail = false;
    }
  }

  public findExistEmail() {
    this.isFetchingEmail = true;
    this.pUserService()
      .findExist(this.cbUserInfo.email)
      .then(res => {
        if (res.data.id > 0) {
          this.isExistEmail = true;
          this.valueStatusEmail = res.data.detail;
        } else {
          this.isExistEmail = false;
          this.valueStatusEmail = res.data.detail;
        }
      })
      .catch(err => {
        this.valueStatusEmail = '';
      })
      .finally(() => {
        this.isFetchingEmail = false;
      });
  }

  @Debounce(600)
  public handleCheckPhone() {
    if (this.cbUserInfo.phone && this.cbUserInfo.phone.length > 5 && this.cbUserInfo.phoneCode) {
      this.isFetchingPhoneNumber = true;
      this.pUserService()
        .findPhoneExist(this.cbUserInfo.phoneCode, this.cbUserInfo.phone)
        .then(res => {
          this.valueStatusPhone = res.data.detail;
          if (res.data.id > 0) {
            this.isExistPhone = true;
          } else {
            this.isExistPhone = false;
          }
        })
        .catch(err => {
          this.valueStatusPhone = '';
        })
        .finally(() => {
          this.isFetchingPhoneNumber = false;
        });
    } else {
      this.isExistPhone = false;
    }
  }

  loginGoogle() {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginGoogle();
  }
  loginFacebook() {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginFacebook();
  }
  loginLinkedin() {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginLinkedin();
  }

  loginApple() {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginApple();
  }

  public validationDirty(e) {
    if (e.target.checked) {
      this.isDirty = true;
    }
  }

  // ================= FUNCTION =================
  // ================= COMPUTE =================
  public get isPasswordSame() {
    return this.cbUserInfo.password === this.confirmPassword;
  }
  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get TERM_OF_SERVICE() {
    if (this.currentLanguage == 'id') {
      return this.$store.getters.TERM_OF_SERVICE_ID[0]?.cbHelpCenterAggs[0]?.cbTextNoAuth?.content;
    } else {
      return this.$store.getters.TERM_OF_SERVICE_EN[0]?.cbHelpCenterAggs[0]?.cbTextNoAuth?.content;
    }
  }

  public get PRIVACY_POLICY() {
    if (this.currentLanguage == 'id') {
      return this.$store.getters.PRIVACY_POLICY_ID[0]?.cbHelpCenterAggs[0]?.cbTextNoAuth?.content;
    } else {
      return this.$store.getters.PRIVACY_POLICY_EN[0]?.cbHelpCenterAggs[0]?.cbTextNoAuth?.content;
    }
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  public get cbLovPhoneCodes() {
    return this.$store.getters[CbLovType.PHONE_CODE];
  }

  public get invalidPassword() {
    if (this.$v.cbUserInfo.password.$anyDirty) {
      if (this.$v.cbUserInfo.password.$invalid) {
        return true;
      }
      if (
        this.styleValidPassOne == 'var(--cj-color-status-error)' ||
        this.styleValidPassTwo == 'var(--cj-color-status-error)' ||
        this.styleValidPassThree == 'var(--cj-color-status-error)' ||
        this.styleValidPassFour == 'var(--cj-color-status-error)'
      ) {
        return true;
      }
    }
    return false;
  }

  // ================= COMPUTE =================
}
