import { Inject, Component, Vue, Watch } from 'vue-property-decorator';

import PCmTktService from '@/services/cm-tkt.service';
import PCmTktAttService from '@/services/cm-tkt-att.service';
import PCbHelpCenterFeedbackService from '@/services/cb-help-center-feedback.service';

import { CmTktAtt, ICmTktAtt } from '@/shared/model/cm-tkt-att.model';
import { ICmTkt } from '@/shared/model/cm-tkt.model';
import { ICbHelpCenterFeedback } from '@/shared/model/cb-help-center-feedback.model';
import { ICmAttachmentRestrict } from '@/shared/model/cm-attachment-restrict.model';
import { IProgressUpload, ProgressUpload } from '@/shared/model/progress-upload';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';

@Component({})
export default class FeedbackController extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbHelpCenterFeedbackService') private pCbHelpCenterFeedbackService: () => PCbHelpCenterFeedbackService;
  @Inject('pCmTktService') private pCmTktService: () => PCmTktService;
  @Inject('pCmTktAttService') private pCmTktAttService: () => PCmTktAttService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public messagesLiveChat: any[] = [];

  public isSaving = false;
  public isFetching = false;
  // ================= END DEFAULT FUNCTION ===========
  public created() {
    (<any>this.$root).$off('fc::changeFunction');
    (<any>this.$root).$on('fc::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
    (<any>this.$root).$off('fc::changeValue');
    (<any>this.$root).$on('fc::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });
  }
  // ================= START FUNCTION =================
  public findCbHelpCenterFeedback(id) {
    this.isFetching = true;
    this.pCbHelpCenterFeedbackService()
      .find(id)
      .then(res => {
        this.isFetching = false;
        (<any>this.$root).$emit('liveChat::feedback::getVariable', 'cbHelpCenterFeedback', res);
      })
      .catch(err => {
        this.isFetching = false;
        console.log('ERROR', err.response);
      });
  }

  public save(cbHelpCenterFeedback: ICbHelpCenterFeedback) {
    this.isSaving = true;
    if (cbHelpCenterFeedback.id) {
      this.pCbHelpCenterFeedbackService()
        .partialUpdate(cbHelpCenterFeedback)
        .then(res => {
          this.isSaving = false;
          (<any>this.$root).$emit('liveChat::feedback::getVariable', 'cbHelpCenterFeedback', res);
          (<any>this.$root).$emit('liveChat::feedback::getVariable', 'isSaving', false);
          (<any>this.$root).$emit('lc::changeFunction', 'addNotifMessage', cbHelpCenterFeedback.authorIdAgent);
        })
        .catch(err => {
          this.isSaving = false;
          console.log('ERROR', err.response);
        });
    } else {
      this.pCbHelpCenterFeedbackService()
        .create(cbHelpCenterFeedback)
        .then(res => {
          this.isSaving = false;
          (<any>this.$root).$emit('liveChat::feedback::getVariable', 'cbHelpCenterFeedback', res);
          (<any>this.$root).$emit('liveChat::feedback::getVariable', 'isSaving', false);
        })
        .catch(err => {
          this.isSaving = false;
          console.log('ERROR', err.response);
        });
    }
  }

  public saveCmTkt(cmTkt: ICmTkt, files: ICmAttachmentRestrict[]) {
    this.pCmTktService()
      .create(cmTkt)
      .then(async res => {
        await this.saveCmTktAtts(res, files);
        this.$root.$emit('chat::changeFunction', 'handleCloseModalReport');
        this.$store.commit('typeBackChat', 'IS_NOT_SHOW_CHAT_INFO_PANEL');
        this.$router.push({ name: 'PRequestedTicket' });
      })
      .catch(err => {
        this.$root.$emit('chat::changeFunction', 'handleCloseModalReport');
      });
  }

  public async saveCmTktAtts(cmTkt: ICmTkt, files: ICmAttachmentRestrict[]) {
    if (files.length > 0) {
      const progressUploads = [];

      for (const file of files) {
        const progressUpload = new ProgressUpload();
        progressUpload.title = file.name;
        progressUpload.localUrl = file.localUrl;
        progressUpload.progress = 0;
        progressUpload.status = null;
        progressUploads.push(progressUpload);
      }

      this.$root.$emit('app::progressUploadDownload', progressUploads);
      this.$root.$emit('app::showProgressUploadDownload', true);

      for (const file of files) {
        const cmTktAtt: ICmTktAtt = new CmTktAtt();
        cmTktAtt.cmAttachmentRestrict = file;
        cmTktAtt.cmTkt = cmTkt;

        await this.pCmTktAttService()
          .create(cmTktAtt, cmTktAtt.cmAttachmentRestrict.attContentType, cmTktAtt.cmAttachmentRestrict.blob, progress => {
            this.$root.$emit('app::getProgressUploadDownload', progressUploads => {
              const progressUpload = progressUploads.find((progressUpload: IProgressUpload) => progressUpload.localUrl == file.localUrl);
              if (!progressUpload) return;

              progressUpload.progress = progress;
              progressUpload.status = CbCommonStatus.COMPLETED;
              this.$root.$emit('app::progressUploadDownload', progressUploads);
            });
          })
          .then(res => {})
          .catch(error => {
            console.error('error', error.response);
          });
      }
    }
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('messagesLiveChat', { deep: true })
  public listenermessagesLiveChat() {
    if (this.messagesLiveChat && this.messagesLiveChat.length > 0) {
      const found = this.messagesLiveChat.find(message => message.chtContentType == ChtContentType.LIVE_AGENT_FEEDBACK);
      if (found) {
        this.$root.$emit('lc::changeValue', 'isClosed', true);
      }
    }
  }
  // ================= END LISTENERS ==================
  // ================= START RESET VARIABLES ================
  // ================= END RESET VARIABLES ==================
}
