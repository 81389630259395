import PCbTextNoAuthService from '@/services/cb-text-no-auth.service';
import PCbVwHelpCenterService from '@/services/cb-vw-help-center.service';
import { ICbAttachmentNoAuth } from '@/shared/model/cb-attachment-no-auth.model';
import { ICbHelpCenterAgg } from '@/shared/model/cb-help-center-agg.model';
import { ICbHelpCenter } from '@/shared/model/cb-help-center.model';
import { ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { CbHelpCenterType } from '@/shared/model/enumerations/cb-help-center-type.model';
import { CbLangContentType } from '@/shared/model/enumerations/cb-lang-content-type.model';
import { Store } from 'vuex';
import PCbLovService from './cb-lov.service';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';
import { CbLov } from '@/shared/model/cb-lov.model';
import PCbVwLocationService from './cb-vw-location.service';
import PCbEducationService from './cb-education.service';
import PCbVwSvcService from './cb-vw-svc.service';
import PCbVwSvcCatService from './cb-vw-svc-cat.service';
import PCbVwSvcCatPopularService from './cb-vw-svc-cat-popular.service';
import PCbVwSvcPopularService from './cb-vw-svc-popular.service';
import PCbUserBookmarkAggService from './cb-user-bookmark-agg.service';
import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';

export default class ContentService {
  public CONTENT_VERSION_CB_VW_SVC = 'CB_VW_SVC';
  public CONTENT_VERSION_CB_VW_SVC_CAT = 'CB_VW_SVC_CAT';
  public CONTENT_VERSION_CB_VW_SVC_CAT_POPULAR = 'CB_VW_SVC_CAT_POPULAR';
  public CONTENT_VERSION_CB_VW_SVC_POPULAR = 'CB_VW_SVC_POPULAR';
  public CONTENT_VERSION_CB_EDUCATION = 'CB_EDUCATION';
  public CONTENT_VERSION_CB_VW_LOCATION = 'CB_VW_LOCATION';
  private isRetrievingCbVeHelpCenter: { [key: string]: boolean } = {};
  private isRetrievingCbLov: { [key: string]: boolean } = {};
  private isRetrievingCbEducatin: boolean = false;
  private isRetrievingCbVwSvcCat: boolean = false;
  private isRetrievingCbVwSvcCatPopular: boolean = false;
  private isRetrievingCbLocation: boolean = false;
  private isRetrievingCbVwSvc: boolean = false;
  private isRetrievingCbVwSvcPopular: boolean = false;
  private isRetrievingCbUserBookmarkAgg: { [key: string]: boolean } = {};

  constructor(
    private store: Store<any>,
    private pCbTextNoAuthService: PCbTextNoAuthService,
    private pCbVwHelpCenterService: PCbVwHelpCenterService,
    private pCbLovService: PCbLovService,
    private pCbVwLocationService: PCbVwLocationService,
    private pCbEducationService: PCbEducationService,
    private pCbVwSvcService: PCbVwSvcService,
    private pCbVwSvcCatService: PCbVwSvcCatService,
    private pCbVwSvcCatPopularService: PCbVwSvcCatPopularService,
    private pCbVwSvcPopularService: PCbVwSvcPopularService,
    private pCbUserBookmarkAggService: PCbUserBookmarkAggService
  ) {
    this.retrieveCbLovs(CbLovType.CONTENT_VERSION);
  }

  public get cbTextNoAuths() {
    return this.store.getters.cbTextNoAuths; //dont use initRetrieveCbLovs
  }

  public initRetrieveCbVeHelpCenters(helpCenterType: CbHelpCenterType) {
    //addcoodnition versions
    if (
      !this.store.getters[helpCenterType + '_' + CbLangContentType.ID] ||
      this.store.getters[helpCenterType + '_' + CbLangContentType.ID]?.length == 0 ||
      (this.store.getters[helpCenterType + '_' + CbLangContentType.ID] &&
        this.contentVersion() != this.contentVersionBind(helpCenterType + '_' + CbLangContentType.ID))
    )
      this.retrieveCbVeHelpCenters(CbLangContentType.ID, helpCenterType);

    if (
      !this.store.getters[helpCenterType + '_' + CbLangContentType.EN] ||
      this.store.getters[helpCenterType + '_' + CbLangContentType.EN]?.length == 0 ||
      (this.store.getters[helpCenterType + '_' + CbLangContentType.EN] &&
        this.contentVersion() != this.contentVersionBind(helpCenterType + '_' + CbLangContentType.ID))
    )
      this.retrieveCbVeHelpCenters(CbLangContentType.EN, helpCenterType);
  }

  public retrieveCbVeHelpCenters(cbLangContentType: CbLangContentType, helpCenterType: CbHelpCenterType): void {
    if (this.isRetrievingCbVeHelpCenter[helpCenterType + '_' + cbLangContentType]) {
      return;
    }

    this.isRetrievingCbVeHelpCenter[helpCenterType + '_' + cbLangContentType] = true;

    const param = {
      'langType.equals': cbLangContentType,
      'helpCenterType.equals': helpCenterType,
      sort: 'seq,asc',
    };

    this.pCbVwHelpCenterService
      .retrieve(param)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          let formatedDatas: ICbHelpCenter[] = this.processDataCbVwHelpCenter(res.data);
          this.store.commit(helpCenterType + '_' + cbLangContentType, formatedDatas);
          if (formatedDatas && formatedDatas.length > 0) {
            for (const d of formatedDatas) {
              if (d && d.cbHelpCenterAggs != null) {
                for (const agg of d.cbHelpCenterAggs) {
                  this.pCbTextNoAuthService.find(agg.cbTextNoAuth.id).then(res => {
                    const hpIdx = formatedDatas.findIndex(hp => hp.id == d.id);
                    const hpAggIdx = formatedDatas[hpIdx].cbHelpCenterAggs.findIndex(hpAgg => hpAgg.cbTextNoAuth.id == agg.cbTextNoAuth.id);
                    formatedDatas[hpIdx].cbHelpCenterAggs[hpAggIdx].cbTextNoAuth = res;
                    this.store.commit(helpCenterType + '_' + cbLangContentType, formatedDatas);
                  });
                }
              }
            }
          }

          this.updateContentVersionBind(helpCenterType + '_' + cbLangContentType);
        }
      })
      .finally(() => {
        this.isRetrievingCbVeHelpCenter[helpCenterType + '_' + cbLangContentType] = false;
      });
  }

  public processDataCbVwHelpCenter(res: any): ICbHelpCenter[] {
    if (res) {
      const cbHelpCnters: ICbHelpCenter[] = [];

      for (const cbVwHelpCnter of res) {
        const cbHelpCnter: ICbHelpCenter = {
          id: cbVwHelpCnter.id,
          seq: cbVwHelpCnter.seq,
          title: cbVwHelpCnter.title,
          refId: cbVwHelpCnter.refId,
          helpCenterType: cbVwHelpCnter.helpCenterType,
          langType: cbVwHelpCnter.langType,
          lastModifiedDate: cbVwHelpCnter.lastModifiedDate,
          lastModifiedBy: cbVwHelpCnter.lastModifiedBy,
        };

        const cbHelpCenterAggs: ICbHelpCenterAgg[] = [];

        if (cbVwHelpCnter && cbVwHelpCnter.helpCenterAggs) {
          for (const helpCenterAgg of cbVwHelpCnter.helpCenterAggs.split('||')) {
            const helpCenterAggSpl = helpCenterAgg.split('::');

            const cbAttachmentNoAuth: ICbAttachmentNoAuth = {
              id: +helpCenterAggSpl[3],
            };

            const cbTextNoAuth: ICbTextNoAuth = {
              id: +helpCenterAggSpl[4],
            };

            const cbHelpCenterAgg: ICbHelpCenterAgg = {
              id: +helpCenterAggSpl[0],
              title: helpCenterAggSpl[1],
              seq: +helpCenterAggSpl[2],
              countViewer: +helpCenterAggSpl[5],
              countLike: +helpCenterAggSpl[6],
              countUnLike: +helpCenterAggSpl[7],
              cbAttachmentNoAuth: cbAttachmentNoAuth,
              cbTextNoAuth: cbTextNoAuth,
            };

            cbHelpCnter.cbHelpCenterAggs = cbHelpCenterAggs;

            cbHelpCenterAggs.push(cbHelpCenterAgg);
          }
        }

        cbHelpCnters.push(cbHelpCnter);
      }

      return cbHelpCnters;
    }
    return [];
  }
  public initRetrieveCbLovs(cbLovType: CbLovType) {
    //add version condition
    if (
      !this.store.getters[cbLovType] ||
      this.store.getters[cbLovType]?.length == 0 ||
      (this.store.getters[cbLovType] && this.contentVersion() != this.contentVersionBind(cbLovType))
    )
      this.retrieveCbLovs(cbLovType);
  }

  public retrieveCbLovs(cbLovType: CbLovType): void {
    if (this.isRetrievingCbLov[cbLovType]) {
      return;
    }

    this.isRetrievingCbLov[cbLovType] = true;

    const params = {
      'name.equals': cbLovType,
      sort: 'seq,asc',
      'isActive.equals': true,
    };
    this.pCbLovService
      .retrieve(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.store.commit(cbLovType, res.data);
          this.updateContentVersionBind(cbLovType);
        }
      })
      .finally(() => {
        this.isRetrievingCbLov[cbLovType] = false;
      });
  }

  public initRetrieveCbVwLocations() {
    if (
      !this.store.getters.cbVwLocations ||
      this.store.getters.cbVwLocations?.length == 0 ||
      (this.store.getters.cbVwLocations && this.contentVersion() != this.contentVersionBind(this.CONTENT_VERSION_CB_VW_LOCATION))
    ) {
      this.retrieveCbVwLocations();
    }
  }

  public retrieveCbVwLocations() {
    if (this.isRetrievingCbLocation) {
      return;
    }

    this.isRetrievingCbLocation = true;
    this.pCbVwLocationService
      .retrieve()
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.store.commit('cbVwLocations', res.data);
        }
        this.updateContentVersionBind(this.CONTENT_VERSION_CB_VW_LOCATION);
      })
      .finally(() => {
        this.isRetrievingCbLocation = false;
      });
  }

  public initRetrieveCbVwSvc() {
    if (
      !this.store.getters.cbVwSvcs ||
      this.store.getters.cbVwSvcs?.length == 0 ||
      (this.store.getters.cbVwSvcs && this.contentVersion() != this.contentVersionBind(this.CONTENT_VERSION_CB_VW_SVC))
    ) {
      this.retrieveCbVwSvc();
    }
  }

  public retrieveCbVwSvc() {
    if (this.isRetrievingCbVwSvc) {
      return;
    }

    this.isRetrievingCbVwSvc = true;
    let params: any = {
      size: 1000,
    };
    this.pCbVwSvcService
      .retrieve(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.store.commit('cbVwSvcs', res.data);
        }
        this.updateContentVersionBind(this.CONTENT_VERSION_CB_VW_SVC);
      })
      .finally(() => {
        this.isRetrievingCbVwSvc = false;
      });
  }

  public initRetrieveCbVwSvcPopular() {
    if (
      !this.store.getters.cbVwSvcPopulars ||
      this.store.getters.cbVwSvcPopulars?.length == 0 ||
      (this.store.getters.cbVwSvcPopulars && this.contentVersion() != this.contentVersionBind(this.CONTENT_VERSION_CB_VW_SVC_POPULAR))
    ) {
      this.retrieveCbVwSvcPopular();
    }
  }

  public retrieveCbVwSvcPopular() {
    if (this.isRetrievingCbVwSvcPopular) {
      return;
    }

    this.isRetrievingCbVwSvcPopular = true;

    let params: any = {
      size: 1000,
      sort: 'svcPopularWeight,desc',
    };
    this.pCbVwSvcPopularService
      .retrieve(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.store.commit('cbVwSvcPopulars', res.data);
        }
        this.updateContentVersionBind(this.CONTENT_VERSION_CB_VW_SVC_POPULAR);
      })
      .finally(() => {
        this.isRetrievingCbVwSvcPopular = false;
      });
  }

  public initRetrieveCbVwSvcCatPopular(size) {
    if (
      !this.store.getters.cbVwSvcCatPopulars ||
      this.store.getters.cbVwSvcCatPopulars?.length == 0 ||
      (this.store.getters.cbVwSvcCatPopulars &&
        this.contentVersion() != this.contentVersionBind(this.CONTENT_VERSION_CB_VW_SVC_CAT_POPULAR))
    ) {
      this.retrieveCbVwSvcCatPopular(size);
    }
  }

  public retrieveCbVwSvcCatPopular(size) {
    if (this.isRetrievingCbVwSvcCatPopular) {
      return;
    }

    this.isRetrievingCbVwSvcCatPopular = true;

    let params: any = {
      size: size,
      sort: 'svcPopularWeight,desc',
    };
    this.pCbVwSvcCatPopularService
      .retrieve(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.store.commit('cbVwSvcCatPopulars', res.data);
        }
        this.updateContentVersionBind(this.CONTENT_VERSION_CB_VW_SVC_CAT_POPULAR);
      })
      .finally(() => {
        this.isRetrievingCbVwSvcCatPopular = false;
      });
  }

  public initRetrieveCbVwSvcCat() {
    if (
      !this.store.getters.cbVwSvcCats ||
      this.store.getters.cbVwSvcCats?.length == 0 ||
      (this.store.getters.cbVwSvcCats && this.contentVersion() != this.contentVersionBind(this.CONTENT_VERSION_CB_VW_SVC_CAT))
    ) {
      this.retrieveCbVwSvcCat();
    }
  }

  public retrieveCbVwSvcCat() {
    if (this.isRetrievingCbVwSvcCat) {
      return;
    }

    this.isRetrievingCbVwSvcCat = true;

    let params: any = {
      size: 1000,
    };
    this.pCbVwSvcCatService
      .retrieve(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.store.commit('cbVwSvcCats', res.data);
        }
        this.updateContentVersionBind(this.CONTENT_VERSION_CB_VW_SVC_CAT);
      })
      .finally(() => {
        this.isRetrievingCbVwSvcCat = false;
      });
  }

  public initRetrieveCbEducations() {
    if (
      !this.store.getters.cbEducations ||
      this.store.getters.cbEducations?.length == 0 ||
      (this.store.getters.cbEducations && this.contentVersion() != this.contentVersionBind(this.CONTENT_VERSION_CB_EDUCATION))
    ) {
      this.retrieveCbEducations();
    }
  }

  public retrieveCbEducations() {
    if (this.isRetrievingCbEducatin) {
      return;
    }

    this.isRetrievingCbEducatin = true;

    let params: any = {
      size: 9999,
      'isActive.equals': true,
    };
    this.pCbEducationService
      .retrieve(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          if (res.data && res.data.length > 0) {
            this.store.commit('cbEducations', res.data);
          }
          this.updateContentVersionBind(this.CONTENT_VERSION_CB_EDUCATION);
        }
      })
      .finally(() => {
        this.isRetrievingCbEducatin = false;
      });
  }

  public initRetrieveCbUserBookmarkAggs(bookmarkType: CbBookmarkType) {
    if (this.store.getters.authenticated) {
      if (
        !this.store.getters['GET_USER_BOOKMARK_' + bookmarkType] ||
        this.store.getters['GET_USER_BOOKMARK_' + bookmarkType]?.length == 0
      ) {
        this.retrieveCbUserBookmarkAgg(bookmarkType);
      }
    }
  }

  public retrieveCbUserBookmarkAgg(bookmarkType: CbBookmarkType) {
    if (this.isRetrievingCbUserBookmarkAgg[bookmarkType]) {
      return;
    }

    this.isRetrievingCbUserBookmarkAgg[bookmarkType] = true;

    const params = {
      size: 9999,
      'bookmarkType.equals': bookmarkType,
    };
    this.pCbUserBookmarkAggService
      .retrieve(params)
      .then(res => {
        const bookmarkMapping = {
          [CbBookmarkType.AUCTION_PROJECT]: {
            set: 'SET_USER_BOOKMARK_AUCTION_PROJECT',
            remove: 'CLEAR_USER_BOOKMARK_AUCTION_PROJECT',
          },
          [CbBookmarkType.PAW]: {
            set: 'SET_USER_BOOKMARK_PAW',
            remove: 'CLEAR_USER_BOOKMARK_PAW',
          },
          [CbBookmarkType.LIKE_CB_ATTACHMENT_NO_AUTH]: {
            set: 'SET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH',
            remove: 'CLEAR_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH',
          },
          // Tambahkan tipe bookmark lainnya di sini jika diperlukan
        };

        const mapping = bookmarkMapping[bookmarkType];

        if (mapping) {
          if (res.data && res.data.length > 0) {
            this.store.commit(mapping.set, res.data);
          } else {
            this.store.commit(mapping.remove, res.data);
          }
        }
      })

      .finally(() => {
        this.isRetrievingCbUserBookmarkAgg[bookmarkType] = false;
      });
  }

  private contentVersion() {
    //updated from server
    const contentVersion: CbLov[] = this.store.getters[CbLovType.CONTENT_VERSION];
    return contentVersion && contentVersion[0] ? contentVersion[0].key : null;
  }

  private contentVersionBind(bindField) {
    //previous
    const contentVersionBind: any = this.store.getters[CbLovType.CONTENT_VERSION_BIND];
    return contentVersionBind ? contentVersionBind[bindField] : null;
  }

  private updateContentVersionBind(bindField) {
    const storeContentVersionBind: any = this.store.getters[CbLovType.CONTENT_VERSION_BIND];
    storeContentVersionBind[bindField] = this.contentVersion();
    this.store.commit(CbLovType.CONTENT_VERSION_BIND, storeContentVersionBind);
  }
}
