var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass:
            "c-item-chat-message-project-delivery p-2 cj-bg-white-primary",
          attrs: { "data-cy": "item-chat-message-project-delivery" },
        },
        [
          _c(
            "div",
            {
              ref: "cbVwPawOrderRequirement.labelOrderId",
              staticClass: "text-center mb-1",
            },
            [
              _c(
                "p",
                {
                  staticClass: "c-icm-font-card-title text-ellipsis oneline",
                  attrs: { "data-cy": "message-title-project-delivery" },
                },
                [_vm._v("Project Delivery")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "c-icm-font-card-subtitle text-ellipsis oneline",
                  attrs: { "data-cy": "message-sub-title-project-delivery" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                      " | " +
                      _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () =>
                      _vm.$refs["cbVwPawOrderRequirement.labelOrderId"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "Project Delivery " +
                          _vm._s(_vm.cbVwPawOrderRequirement.seq)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                          " | " +
                          _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded cj-border-color-red-primary border-width-1 p-2 mb-2",
              staticStyle: { "word-break": "break-all" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("div", { staticClass: "my-auto" }, [
                    _c("img", {
                      staticClass: "m-auto",
                      staticStyle: { width: "4rem" },
                      attrs: {
                        src: `${_vm.$env.CDN_CB}/content/cb-img/ic-file-plain-text.png`,
                        alt: "file",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "cbVwPawOrderRequirement.createdDate",
                      staticClass: "my-auto",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "c-icm-font-title text-ellipsis oneline",
                          attrs: {
                            "data-cy": "message-name-card-project-delivery",
                          },
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cProjectDelivery.projectDelivery"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.cbVwPawOrderRequirement.seq) +
                                "\n          "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "c-icm-font-content text-ellipsis oneline",
                          attrs: {
                            "data-cy": "message-date-card-project-delivery",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$options.filters.dynamicFormatDate(
                                  _vm.cbVwPawOrderRequirement.createdDate,
                                  "DD MMM YYYY HH:mm"
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("c-detail-order-status", {
                        staticClass: "justify-content-center align-items-start",
                        attrs: {
                          type: _vm.TypeComponent.CHAT_BUBBLE,
                          "order-status": _vm.cbVwPawOrderRequirement.status,
                          "expired-date":
                            _vm.cbVwPawOrderRequirement
                              .revisionExtraOfferingDate,
                          "aalt-date": _vm.cbVwPawOrder.aaltDate,
                          "freeze-date": _vm.cbVwPawOrder.freezeDate,
                          "interval-count-down-second":
                            _vm.cbVwPawOrder.xIntervalCountDownSecond,
                          "key-name": _vm.cbVwPawOrder.keyName,
                          "data-cy-custom": "message",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "custom-tooltip",
                      attrs: {
                        target: () =>
                          _vm.$refs["cbVwPawOrderRequirement.createdDate"],
                        variant: "primary",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [_vm._v("Project Delivery")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("formatCreateDatelabel")(
                                  _vm.cbVwPawOrderRequirement.createdDate
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto font-weight-700 font-size-10 text-ellipsis oneline",
                          class: {
                            [_vm.getOrderStatusColorByStatus(
                              _vm.cbVwPawOrderRequirement.status,
                              "text"
                            )]: _vm.getOrderStatusColorByStatus(
                              _vm.cbVwPawOrderRequirement.status,
                              "text"
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getOrderStatusTextByStatusOrder(
                                  _vm.cbVwPawOrderRequirement.status
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass: "c-icm-font-btn",
              attrs: {
                fullWidth: "",
                "data-cy": "message-project-delivery-btn-view",
                "type-style": "secondary",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleOpenPreviewProjectDelivery.apply(
                    null,
                    arguments
                  )
                },
              },
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cProjectDelivery.view")
                  ),
                },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }