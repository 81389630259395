var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPaw.status
    ? _c("div", { staticClass: "c-pd-status mx-auto" }, [
        _c(
          "p",
          {
            staticClass:
              "px-3 py-1 mb-0 font-roboto font-weight-700 font-size-16 paw-detail-text-status",
            class: _vm.getPawColorByStatus(_vm.cbVwPaw, true),
          },
          [
            _vm._v(
              "\n   " + _vm._s(_vm.getPawTextByStatus(_vm.cbVwPaw)) + "\n  "
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }