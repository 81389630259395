var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-item-svc-cat cursor-pointer",
      attrs: { "data-gtm": `service-${_vm.cbSvcCat.name}-option-button` },
    },
    [
      _c("div", { staticClass: "cb-img-wrapper cb-ratio-4x3" }, [
        _c("img", {
          attrs: {
            src: _vm.$options.filters.LAZY(
              `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                _vm.cbSvcCat && _vm.cbSvcCat.logoId
              }`,
              299,
              "ic-lazy-paw.png"
            ),
            onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-paw.png"),
            alt: "img",
            "data-gtm": `service-${_vm.cbSvcCat.name}-image-clickable`,
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "p-1 p-sm-2 font-weight-500 text-center font-roboto cj-color-black-primary text-ellipsis-oneline w-100",
          class: _vm.isMobile ? "font-size-10" : "font-size-14",
          attrs: {
            "data-cy": "item-personalize-interest",
            "data-gtm": `service-${_vm.cbSvcCat.name}-label`,
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.cbSvcCat.name) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }