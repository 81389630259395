import { Flutter } from '@/app-flutter';
import CButton from '@/components/button/c-button.vue';
import CLocationMap from '@/module/chat-container/chat/components/c-location-map/c-location-map.vue';
import Icon from '@/shared/icons/icon.vue';
import { ChtLocation } from '@/shared/model/cht-location.model';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
    CLocationMap,
    CButton,
  },
})
export default class roomLocationChat extends Vue {
  @Prop({ default: new ChtLocation() })
  public currentLocation: ChtLocation;
  public resultLocation: ChtLocation = {};

  public isLoading = false;

  // // ================= START SERVICES =================
  // // ================= END SERVICES ===================
  // // ================= START VARIABLES ================
  // // ================= END VARIABLES ==================
  // // ================= START DEFAULT FUNCTION =========
  // // ================= END DEFAULT FUNCTION ===========
  // // ================= START FUNCTION =================

  created() {
    if (Flutter.isRunOn()) {
      console.log('im calling permissionLocationDialog created');
      Flutter.call('permissionLocationDialog', {
        locationDialogStatus: 'open',
      });
    }
  }

  public async save() {
    this.isLoading = true;
    const snapshotMapBase64 = await (<any>this.$refs.cLocationMapRef).createSnapshot();
    this.resultLocation.snapshot = snapshotMapBase64;

    // alert(snapshotMapBase64);

    if (Flutter.isRunOn()) {
      console.log('im calling permissionLocationDialog save');

      Flutter.call('permissionLocationDialog', {
        locationDialogStatus: 'close',
      });
    }

    this.$emit('callBack', this.resultLocation);
  }

  public cancel() {
    // this.callBack();
    if (Flutter.isRunOn()) {
      console.log('im calling permissionLocationDialog cancel');

      Flutter.call('permissionLocationDialog', {
        locationDialogStatus: 'close',
      });
    }
    this.$emit('callBack', null);
  }

  public callBack(resultLocation) {
    this.resultLocation = resultLocation;
  }
  // // ================= END FUNCTION ===================
  // // ================= START COMPUTE ==================
  // // ================= END COMPUTE ====================
  // // ================= START LISTENERS =================
  // // ================= END LISTENERS ===================
}
