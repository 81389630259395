import { CbPayMethodType } from '@/shared/model/enumerations/cb-pay-method-type.model';
import { CbPaymentStatus } from '@/shared/model/enumerations/cb-payment-status.model';
export interface ICbTopup {
  id?: number;
  externalId?: string | null;
  cbPayMethodType?: CbPayMethodType | null;
  topupTotalAmount?: number | null;
  topupAmount?: number | null;
  note?: string | null;
  status?: CbPaymentStatus | null;
  statusPaidDate?: Date | null;
  ip?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
}

export class CbTopup implements ICbTopup {
  constructor(
    public id?: number,
    public externalId?: string | null,
    public cbPayMethodType?: CbPayMethodType | null,
    public topupTotalAmount?: number | null,
    public topupAmount?: number | null,
    public note?: string | null,
    public status?: CbPaymentStatus | null,
    public statusPaidDate?: Date | null,
    public ip?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null
  ) {}
}
