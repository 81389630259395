var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "vac-room-header",
      attrs: { id: "room-header-chat-message" },
    },
    [
      _vm._t(
        "room-header",
        function () {
          return [
            _c(
              "div",
              { staticClass: "vac-room-wrapper" },
              [
                _c("transition", { attrs: { name: "vac-slide-up" } }, [
                  _vm.messageSelectionEnabled
                    ? _c(
                        "div",
                        { staticClass: "vac-room-selection" },
                        [
                          _vm._l(
                            _vm.messageSelectionActions,
                            function (action) {
                              return _c(
                                "div",
                                {
                                  key: action.name,
                                  attrs: { id: action.name },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vac-selection-button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.messageSelectionActionHandler(
                                            action
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(action.title) +
                                          "\n              "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "vac-selection-button-count",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.selectedMessagesTotal
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "vac-selection-cancel vac-item-clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("cancel-message-selection")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.textMessages.CANCEL_SELECT_MESSAGE
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                !_vm.messageSelectionEnabled &&
                _vm.messageSelectionAnimationEnded
                  ? [
                      !_vm.singleRoom
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vac-toggle-button d-none-lc cursor-pointer",
                              class: {
                                "vac-rotate-icon-init": !_vm.isMobile,
                                "pl-0 pr-3": _vm.showRoomsList,
                                "vac-rotate-icon pl-3 pr-0":
                                  !_vm.showRoomsList && !_vm.isMobile,
                                "p-2 pr-3": _vm.isMobile,
                              },
                              attrs: { "data-cy": "maximize-room" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("toggle-rooms-list")
                                },
                              },
                            },
                            [
                              _vm._t("toggle-icon", function () {
                                return [
                                  _c("icon", {
                                    staticClass: "cj-fill-black-primary",
                                    staticStyle: { transform: "rotate(90deg)" },
                                    attrs: {
                                      name: "ic_down",
                                      width: "1rem",
                                      height: "1rem",
                                    },
                                  }),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "vac-info-wrapper cursor-pointer",
                          class: { "vac-item-clickable": _vm.roomInfoEnabled },
                          attrs: { "data-cy": "view-profile" },
                          on: {
                            click: function ($event) {
                              return _vm.viewProfile()
                            },
                          },
                        },
                        [
                          _vm._t(
                            "room-header-avatar",
                            function () {
                              return [
                                _vm.room.avatar
                                  ? _c("div", {
                                      staticClass: "vac-avatar",
                                      style: {
                                        "background-image": `url('${_vm.room.avatar}')`,
                                      },
                                      attrs: { "data-cy": "vac-avatar" },
                                    })
                                  : _vm._e(),
                              ]
                            },
                            null,
                            { room: _vm.room }
                          ),
                          _vm._v(" "),
                          _vm._t(
                            "room-header-info",
                            function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "vac-text-ellipsis" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vac-room-name vac-text-ellipsis",
                                        attrs: { "data-cy": "vac-room-name" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.room.roomName) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.typingUsers
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vac-room-info vac-text-ellipsis",
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.typingUsers) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vac-room-info vac-text-ellipsis",
                                          },
                                          [
                                            _vm.$options.filters.chatIsUserOnline(
                                              _vm.room.members,
                                              _vm.currentUserId,
                                              _vm.userIdsOnline
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vac-room-info vac-text-ellipsis status-online",
                                                    attrs: {
                                                      "data-cy":
                                                        "vac-room-status-online",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  online\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                  ]
                                ),
                              ]
                            },
                            null,
                            {
                              room: _vm.room,
                              typingUsers: _vm.typingUsers,
                              userStatus: _vm.userStatus,
                            }
                          ),
                          _vm._v(" "),
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isSocketServiceNotActive,
                                expression: "isSocketServiceNotActive",
                              },
                            ],
                            staticClass: "circle-info info-chat",
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vac-svg-button mx-2 vac-d-show-web d-none-lc",
                          attrs: {
                            "data-gtm": "search-chat-button",
                            "data-cy": "btn-search-cht",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleOption("ROOMSEARCHMSG")
                            },
                          },
                        },
                        [
                          _vm._t(
                            "search-history",
                            function () {
                              return [
                                _c("icon", {
                                  staticClass: "my-auto cj-stroke-red-primary",
                                  attrs: {
                                    name: "ic_search",
                                    width: "23px",
                                    height: "23px",
                                  },
                                }),
                              ]
                            },
                            { dataCy: "btn-search-cht-ic" }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vac-svg-button ml-3 vac-d-none-web d-none-lc",
                          attrs: { "data-cy": "show-info-panel" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("showInfoPanel")
                            },
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "cj-fill-red-primary",
                            attrs: {
                              name: "ic_clipboard",
                              width: "26px",
                              height: "29px",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.room.roomId
                        ? _vm._t("room-options", function () {
                            return [
                              _vm.menuActions.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vac-svg-button vac-room-options ml-3=2 d-none-lc",
                                      attrs: {
                                        "data-cy": "vac-room-options",
                                        "data-gtm":
                                          "vac-room-options-clickable",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.menuOpened = !_vm.menuOpened
                                        },
                                      },
                                    },
                                    [
                                      _vm._t("menu-icon", function () {
                                        return [
                                          _c("icon", {
                                            staticClass:
                                              "cj-fill-red-primary cj-stroke-red-primary",
                                            attrs: {
                                              name: "ic_kebab_menu",
                                              width: "2rem",
                                            },
                                          }),
                                        ]
                                      }),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.menuActions.length
                                ? _c(
                                    "transition",
                                    { attrs: { name: "vac-slide-left" } },
                                    [
                                      _vm.menuOpened
                                        ? _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "click-outside",
                                                  rawName: "v-click-outside",
                                                  value: _vm.closeMenu,
                                                  expression: "closeMenu",
                                                },
                                              ],
                                              staticClass: "vac-menu-options",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "vac-menu-list",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleOption(
                                                            "ROOMMEDIA"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vac-menu-item",
                                                          attrs: {
                                                            "data-cy":
                                                              "vac-menu-item-media",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "chat.media"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Media")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vac-d-none-web",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleOption(
                                                            "ROOMSEARCHMSG"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vac-menu-item",
                                                          attrs: {
                                                            "data-cy":
                                                              "vac-menu-item-search",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "chat.search"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [_vm._v("Search")]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleOption(
                                                            "ROOMSTARTMSG"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vac-menu-item",
                                                          attrs: {
                                                            "data-cy":
                                                              "vac-menu-item-starred-msg",
                                                          },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "chat.starredMessage"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Starred Message"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.menuActions,
                                                    function (action) {
                                                      return _c(
                                                        "div",
                                                        { key: action.name },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "vac-menu-item",
                                                              attrs: {
                                                                "data-cy":
                                                                  "vac-menu-item-actions",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.menuActionHandler(
                                                                      action
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm.room
                                                                .isPinned &&
                                                              action.name ==
                                                                "pinnedRoom"
                                                                ? [
                                                                    _c("span", {
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "chat.unPinChat"
                                                                              )
                                                                            ),
                                                                        },
                                                                    }),
                                                                  ]
                                                                : [
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            action.title
                                                                          )
                                                                        ) +
                                                                        "\n                    "
                                                                    ),
                                                                  ],
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]
        },
        null,
        {
          room: _vm.room,
          typingUsers: _vm.typingUsers,
          userStatus: _vm.userStatus,
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }