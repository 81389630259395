export enum App {
  CB_APP = 'CB_APP',

  CHAT_APP = 'CHAT_APP',

  CRM_APP = 'CRM_APP',

  BLUM_APP = 'BLUM_APP',

  OP_APP = 'OP_APP',
}
