import { render, staticRenderFns } from "./room-uploads.vue?vue&type=template&id=131eceaa&scoped=true&"
import script from "./room-uploads.component.ts?vue&type=script&lang=ts&"
export * from "./room-uploads.component.ts?vue&type=script&lang=ts&"
import style0 from "./room-uploads.vue?vue&type=style&index=0&id=131eceaa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131eceaa",
  null
  
)

export default component.exports