import { Component, Prop, Watch } from 'vue-property-decorator';
import vClickOutside from 'v-click-outside';
import RoomMessage from '../../lib/Room/RoomMessage/RoomMessage.vue';
import Icon from '@/shared/icons/icon.vue';
import { CbVwUser, ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { IChtVwMessage } from '@/shared/model/cht-vw-message.model';
import { parseTimestamp } from '@/module/chat-container/utils/dates';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';
import { ChtReaction, IChtReaction } from '@/shared/model/cht-reaction.model';
import FormatMessage from '../../components/FormatMessage/FormatMessage.vue';
import SvgIcon from '../../components/SvgIcon/SvgIcon.vue';
import cRoomSearchFormatMsg from './c-room-search-format-msg/c-room-search-format-msg.vue';
import { Debounce } from '@/shared/decorator/debounce';
import CLoader from '@/components/c-loader/c-loader.vue';
@Component({
  components: { RoomMessage, Icon, FormatMessage, SvgIcon, cRoomSearchFormatMsg, CLoader },
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class CRoomSearchMessage extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES =================

  // ================= START VARIABLES ================
  @Prop({ type: Array, required: true })
  rooms;
  @Prop({ type: [String, Number], required: true })
  currentUserId;
  @Prop({ type: Object, required: true })
  usernameOptions;
  @Prop({ type: Array, required: true })
  messageActions;
  @Prop({ type: Object, required: true })
  linkOptions;
  @Prop({ type: Object, required: true })
  textFormatting;
  @Prop({ type: Boolean, required: true })
  showNewMessagesDivider;
  @Prop({ type: Boolean, required: true })
  showReactionEmojis;
  @Prop({ type: Object, required: true })
  textMessages;
  @Prop({ type: Boolean, required: true })
  messagesLoaded;
  @Prop({ type: Number, required: true })
  scrollDistance;
  @Prop({ type: [String, Number], required: true })
  roomId;
  @Prop({ default: 'ROOMSTARTMSG' })
  isShowOptionMenu;
  @Prop({ type: Array, default: () => [] })
  messagesSearchResults;
  @Prop({ type: [Number], default: 0 })
  totalItemsMessagesSearchResults;

  public cbVwUsers: ICbVwUser[] = [];
  public cbVwUser: ICbVwUser = new CbVwUser();

  private messageSelectionEnabled = false;
  private observer = null;
  private selectedMessages = [];
  private loadingMessages = false;
  private loadingMoreMessages = false;
  private removeId: number = null;

  public menuOpened = false;
  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'createdDate';
  public reverse = true;
  public infiniteId = +new Date();
  public links = null;

  public messages = [];

  public isFetching = false;

  public isFindSearch = false;

  public seletedColor = false;

  public valueSearch = '';
  public searchInputed = '';

  public MAX_CHARAPTER = 50;
  public isClearCharapter = false;

  @Prop({ type: String, default: '' })
  chtToken;

  @Watch('messagesSearchResults')
  public totalItemsMessagesSearchResultsListener(): void {
    if (<any>this.$refs.infiniteLoading) {
      (<any>this.$refs.infiniteLoading).stateChanger.complete();
    }
  }

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =s================

  public callBack(): void {
    this.$emit('closeRoomSearchMsg', null);
  }

  public closeMenu(): void {
    this.menuOpened = false;
  }
  public handleActionStarDelete(action: string): void {
    for (const value of this.messages) {
      const starMsg = value.stars;
      if (starMsg) {
        this.messages = [];
        this.$emit('message-action-handler', { action, message: value });
      }
    }
  }
  public clear(): void {
    this.page = 1;
    this.links = null;
    this.infiniteId += 1;
    this.messages = [];
    // this.retrieveAllSearchMessages();
  }

  public reset(): void {
    this.page = 1;
    this.infiniteId += 1;
    this.messages = [];
  }

  public handleSyncList(): void {
    this.clear();
  }
  public loadMore($state): void {
    this.page++;
    const action = 'SEARCH_MESSAGE';
    this.$emit('message-action-handler', { action, value: this.valueSearch, currentPage: this.page });
  }
  public sort(): string[] {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'createdDate') {
      result.push('createdDate');
    }
    return result;
  }
  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }
  public transition(): void {
    // this.retrieveAllSearchMessages();
  }

  formatMessage(room, message: IChtVwMessage) {
    message = this.addFileList(message);
    message = this.addReactionList(message);

    const formattedMessage = {
      ...message,
      ...{
        senderId: message.authorId,
        id: message.id,
        _id: message.id,
        externalId: message.externalId,
        chtContentType: message.chtContentType,
        content: message?.content ?? '',
        seconds: new Date(message.createdDate).getTime(),
        timestamp: parseTimestamp(new Date(message.createdDate), 'HH:mm'),
        date: parseTimestamp(new Date(message.createdDate), 'DD MMMM YYYY'),
        username: this.cbVwUsers.find(user => message.authorId === user.id)?.displayName,
        distributed: true,
        deleted: message.deleteForAll ? true : false,
        stickerUrl:
          message.chtContentType === ChtContentType.STICKER
            ? process.env.CDN_CB + '/CbAttachmentNoAuth/' + message.externalId + '?format=gif'
            : null,
      },
    };
    if (message.messageRefId && message.messageRef) {
      message.replyMessage = this.formatMessage(room, message.messageRef);
      formattedMessage.replyMessage = {
        ...message.replyMessage,
        ...{
          senderId: message.authorId,
        },
      };
    }

    return formattedMessage;
  }
  private addFileList(chtVwMessage: IChtVwMessage) {
    chtVwMessage.files = chtVwMessage.chtFiles;
    return chtVwMessage;
  }

  private addReactionList(chtVwMessage: IChtVwMessage) {
    let reaction: IChtReaction;
    const reactionsTransient: IChtReaction[] = [];
    if (chtVwMessage.reactions) {
      const arr = chtVwMessage.reactions.split('||');
      arr.forEach(r => {
        const rArr = r.split('::');
        reaction = new ChtReaction();
        reaction.content = rArr[0];
        reaction.countTransient = Number(rArr[1]);
        reaction.firstDataTransient = rArr[2];
        reaction.id = Number(rArr[3]);

        reactionsTransient.push(reaction);
      });
      chtVwMessage.reactionsTransient = reactionsTransient;
    }

    return chtVwMessage;
  }
  public searchRoom(e) {
    if (e.target.value.length >= this.MAX_CHARAPTER) {
      if (this.isClearCharapter) {
        this.valueSearch = '';
        (<any>this.$root).$bvToast.toast(this.$t('cbgwApp.cbComponent.validation.maxLengthSize'), {
          toaster: 'b-toaster-top-center',
          title: 'Warning',
          variant: 'warning',
          solid: true,
          autoHideDelay: 5000,
        });
      } else {
        e.target.maxLength = this.MAX_CHARAPTER;
      }
    } else {
      this.isClearCharapter = false;
      this.msgSearch(e);
    }
  }

  public onPasteImage(pasteEvent): void {
    const clipboardDatapPaste = pasteEvent.clipboardData || window.clipboardData;
    const pastedData = clipboardDatapPaste.getData('Text');
    if (pastedData.length >= this.MAX_CHARAPTER) {
      this.isClearCharapter = true;
    }
  }

  @Debounce(600)
  public msgSearch(e): void {
    if (e.target.value.length == 0) {
      this.isFindSearch = false;
    } else {
      this.isFindSearch = true;
    }
    this.searchInputed = this.valueSearch;
    this.page = 0;
    const action = 'SEARCH_MESSAGE';
    this.$emit('message-action-handler', { action, value: this.valueSearch, currentPage: this.page });
  }

  public filterGroupDate(chtVwMessage: IChtVwMessage): Date {
    const then = new Date(chtVwMessage.createdDate);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    if (hoursBetweenDates < 24) {
      return chtVwMessage.timestamp;
    } else {
      return chtVwMessage.date;
    }
  }

  public searchMsgResultSelected(value): void {
    this.$emit('searchMsgResultSelected', value);
  }

  // ================ END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get room() {
    const room = this.rooms.find(room => room.roomId == this.roomId) || {};
    return room;
  }
  // ================= END COMPUTE ====================
}
