export enum CbPawOrderFileType {
  PROJECT_FILE = 'PROJECT_FILE',

  PROJECT_DELIVERY = 'PROJECT_DELIVERY',

  PROJECT_REVISION = 'PROJECT_REVISION',

  PROJECT_CLOCK_IN = 'PROJECT_CLOCK_IN',

  PROJECT_CLOCK_OUT = 'PROJECT_CLOCK_OUT',

  PROJECT_MILESTONE = 'PROJECT_MILESTONE',
}
