import { Inject, Component, Prop } from 'vue-property-decorator';
import vClickOutside from 'v-click-outside';
import RoomMessage from '../../lib/Room/RoomMessage/RoomMessage.vue';
import Loader from '../../components/Loader/Loader.vue';
import PChtService from '@/services/cht.service';
import { mixins } from 'vue-class-component';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { IChtVwMessage } from '@/shared/model/cht-vw-message.model';
import { parseTimestamp } from '@/module/chat-container/utils/dates';
import { CbVwUser, ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';
import { ChtReaction, IChtReaction } from '@/shared/model/cht-reaction.model';
import CLoader from '@/components/c-loader/c-loader.vue';
@Component({
  components: { RoomMessage, Loader, CLoader },
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class CRoomStarMessages extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('pChtService') private pChtService: () => PChtService;
  // ================= END SERVICES =================

  // ================= START VARIABLES ================
  @Prop({ type: Array, required: true })
  rooms;
  @Prop({ type: [String, Number], required: true })
  currentUserId;
  @Prop({ type: Object, required: true })
  usernameOptions;
  @Prop({ type: Array, required: true })
  messageActions;
  @Prop({ type: Object, required: true })
  linkOptions;
  @Prop({ type: Object, required: true })
  textFormatting;
  @Prop({ type: Boolean, required: true })
  showNewMessagesDivider;
  @Prop({ type: Boolean, required: true })
  showReactionEmojis;
  @Prop({ type: Object, required: true })
  textMessages;
  @Prop({ type: Boolean, required: true })
  messagesLoaded;
  @Prop({ type: Number, required: true })
  scrollDistance;
  @Prop({ type: [String, Number], required: true })
  roomId;
  @Prop({ default: 'ROOMSTARTMSG' })
  isShowOptionMenu;
  @Prop({ default: [] })
  starMessages;

  public cbVwUsers: ICbVwUser[] = [];
  public cbVwUser: ICbVwUser = new CbVwUser();

  public menuOpened = false;
  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'createdDate';
  public reverse = true;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = null;

  public messages = [];

  public isFetching = false;

  @Prop({ type: String, default: '' })
  chtToken;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted(): void {
    this.retrieveAllStarMessages();
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public callBack() {
    this.$emit('closeRoomStartMsg', null);
  }

  closeMenu() {
    this.menuOpened = false;
  }
  public handleActionStarDelete(action) {
    for (const value of this.messages) {
      const starMsg = value.stars;
      if (starMsg) {
        this.messages = [];
        this.$emit('message-action-handler', { action, message: value });
      }
    }
  }
  public clear(): void {
    this.page = 1;
    this.links = null;
    this.infiniteId += 1;
    this.messages = [];
    this.retrieveAllStarMessages();
  }

  public reset(): void {
    this.page = 1;
    this.infiniteId += 1;
    this.messages = [];
    this.retrieveAllStarMessages();
  }
  public retrieveAllStarMessages(): void {
    const roomId = this.room.roomId;
    this.isFetching = true;
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      sort: this.sort(),
      moreFilter: 'stars.equals=' + this.currentUserId + '::true' + '&',
    };

    this.pChtService()
      .retrieveMessageByConversationId(Number(roomId), paginationQuery)
      .then(
        res => {
          this.isFetching = false;
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const formattedMessage = this.formatMessage(null, res.data[i]);
              const findIndex = this.starMessages.findIndex(e => +e.id == +formattedMessage.id);
              if (findIndex >= 0) {
                // Mengganti data di this.messages jika ada kecocokan ID
                this.messages[i] = this.starMessages[findIndex];
              } else {
                // Jika tidak ada kecocokan ID, tambahkan formattedMessage ke this.messages
                this.messages.push(formattedMessage);
              }
            }

            // this.messages = starMessages
            this.$root.$emit('opc::retrieveAllDataFromMessageExternalId', this.messages, this.messages);

            if (res.headers && res.headers['link']) {
              this.links = this.parseLinks(res.headers['link']);
            }
          }
          this.totalItems = Number(res.headers['x-total-count']);
          this.queryCount = this.totalItems;
          this.isFetching = false;
          if (<any>this.$refs.infiniteLoading) {
            (<any>this.$refs.infiniteLoading).stateChanger.loaded();
            if (this.links !== null && this.page > this.links['last']) {
              (<any>this.$refs.infiniteLoading).stateChanger.complete();
            }
          }
        },
        err => {
          this.isFetching = false;
          // this.alertService().showHttpError(this, err.response);
        }
      );
  }
  public handleSyncList(): void {
    this.clear();
  }
  public loadMore($state): void {
    if (!this.isFetching) {
      this.page++;
      this.transition();
    }
  }
  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'createdDate') {
      result.push('createdDate');
    }
    return result;
  }
  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }
  public transition(): void {
    this.retrieveAllStarMessages();
  }

  formatMessage(room, message: IChtVwMessage) {
    message = this.addFileList(message);
    message = this.addReactionList(message);

    let seenByOther = false;

    if (message.seens) {
      const seenArr = message.seens.split('||');
      seenArr.forEach(r => {
        if (r.includes(this.currentUserId)) {
        } else if (r.trim().length > 1) {
          seenByOther = true;
        }
      });
    }

    const formattedMessage = {
      ...message,
      ...{
        senderId: message.authorId,
        id: message.id,
        _id: message.id,
        externalId: message.externalId,
        chtContentType: message.chtContentType,
        content: message?.content ?? '',
        seconds: new Date(message.createdDate).getTime(),
        timestamp: parseTimestamp(new Date(message.createdDate), 'HH:mm'),
        date: parseTimestamp(new Date(message.createdDate), 'DD MMMM YYYY'),
        username: this.cbVwUsers.find(user => message.authorId === user.id)?.displayName,
        // avatar: senderUser ? senderUser.avatar : null,
        distributed: true,
        seen: seenByOther, //seen by others
        deleted: message.deleteForAll ? true : false,
        stickerUrl:
          message.chtContentType === ChtContentType.STICKER
            ? process.env.CDN_CB + '/CbAttachmentNoAuth/' + message.externalId + '?format=gif'
            : null,
        //lastMessage: { ...message.lastMessage, senderId: message.sender_id },
      },
    };

    // if (message.replyMessage) {
    //   formattedMessage.replyMessage = {
    //     ...message.replyMessage,
    //     ...{
    //       senderId: message.replyMessage.sender_id,
    //     },
    //   };
    // }
    if (message.messageRefId && message.messageRef) {
      message.replyMessage = this.formatMessage(room, message.messageRef);
      formattedMessage.replyMessage = {
        ...message.replyMessage,
        ...{
          senderId: message.authorId,
        },
      };
    }

    return formattedMessage;
  }
  private addFileList(chtVwMessage: IChtVwMessage) {
    chtVwMessage.files = chtVwMessage.chtFiles;
    return chtVwMessage;
  }

  private addReactionList(chtVwMessage: IChtVwMessage) {
    let reaction: IChtReaction;
    const reactionsTransient: IChtReaction[] = [];
    if (chtVwMessage.reactions) {
      const arr = chtVwMessage.reactions.split('||');
      arr.forEach(r => {
        const rArr = r.split('::');
        reaction = new ChtReaction();
        reaction.content = rArr[0];
        reaction.countTransient = Number(rArr[1]);
        reaction.firstDataTransient = rArr[2];
        reaction.id = Number(rArr[3]);

        reactionsTransient.push(reaction);
      });
      chtVwMessage.reactionsTransient = reactionsTransient;
    }

    return chtVwMessage;
  }

  public starMsgResultSelected(value) {
    this.$emit('starMsgResultSelected', value);
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get room() {
    const room = this.rooms.find(room => room.roomId == this.roomId) || {};
    return room;
  }
  // ================= END COMPUTE ====================
}
