var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-user-photo-online rounded-circle",
      style: `width: ${_vm.width}rem; height: ${_vm.width}rem;`,
      attrs: { "data-cy": "user-photo-rounded" },
    },
    [
      _c("img", {
        staticClass: "w-100 h-100 rounded-circle",
        staticStyle: { "object-fit": "cover" },
        attrs: {
          "data-cy": "user-rounded-photo",
          "data-gtm": "user-rounded-photo-button",
          src: _vm.url,
          onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-avatar.png"),
          alt: "user",
        },
      }),
      _vm._v(" "),
      _vm.isOnline == true || _vm.isOnline == false
        ? _c("div", {
            class: `dot-online rounded-circle ${
              _vm.currentUser.isShowOnlineStatus == true ? "online" : ""
            }`,
            style: `width: ${(_vm.width * 30) / 100}rem; height: ${
              (_vm.width * 30) / 100
            }rem;`,
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }