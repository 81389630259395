var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex align-items-center c-pd-reference-item" },
    [
      _c(
        "div",
        { staticClass: "card-item-wrapper mb-2 mr-2" },
        [
          _c("c-upload-file-icon", {
            staticClass: "icon-wrapper",
            attrs: {
              dataCyCustom: "paw-refrence-thumb",
              file: _vm.item.cbAttachmentNoAuth.attContentType,
              fileUrl: _vm.item.cbAttachmentNoAuth.localUrl,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "flex-grow-1 font-roboto cj-color-black-primary font-weight-400 mb-2 font-size-16",
          attrs: { "data-cy": "detail-paw-reference" },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.item.cbAttachmentNoAuth && _vm.item.cbAttachmentNoAuth.name
                  ? _vm.item.cbAttachmentNoAuth.name
                  : "-"
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isShowDownload
        ? _c(
            "div",
            {
              staticClass: "d-flex cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.$emit(
                    "downloadDoc",
                    _vm.item && _vm.item.cbAttachmentNoAuth
                  )
                },
              },
            },
            [
              _c("icon", {
                staticClass: "cj-fill-red-primary",
                attrs: { name: "ic_download", width: "20px", height: "20px" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }