
import SvgIcon from '../../../components/SvgIcon/SvgIcon.vue';
import FormatMessage from '../../../components/FormatMessage/FormatMessage.vue';

import MessageReply from './MessageReply/MessageReply.vue';
import MessageFiles from './MessageFiles/MessageFiles.vue';
import MessageActions from './MessageActions/MessageActions.vue';
import MessageReactions from './MessageReactions/MessageReactions.vue';
import AudioPlayer from './AudioPlayer/AudioPlayer.vue';
import ItemsChatMessage from '../../../../items-chat-message/items-chat-message.vue';
import messageDeleteNotif from './message-delete-notif/message-delete-notif.vue';
import messageReactionsChat from './message-reactions/message-reactions.vue';
import FeedbackChatMessage from '../../../../feedback-chat-message/feedback-chat-message.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CIcons from '../../../components/c-icons/c-icons.vue';
import { CONST_MESSAGE_CARD, CONST_MESSAGE_CARD_FORMAT_MESSAGE } from '@/constants';

const { messagesValidation } = require('../../../utils/data-validation');
const { isAudioFile } = require('../../../utils/media-file');

@Component({
  components: {
    SvgIcon,
    FormatMessage,
    AudioPlayer,
    MessageReply,
    MessageFiles,
    MessageActions,
    MessageReactions,
    ItemsChatMessage,
    messageDeleteNotif,
    messageReactionsChat,
    CIcons,
    FeedbackChatMessage,
  },
  directives: {
    // clickOutside: vClickOutside.directive,
  },
  watch: {
    newMessages: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val.length || !this.showNewMessagesDivider) {
          this.newMessage = {};
          return;
        }

        this.newMessage = val.reduce((res, obj) => (obj.index < res.index ? obj : res));
      },
    },
    messageSelectionEnabled() {
      this.resetMessageHover();
    },
  },
})
export default class RoomMessage extends Vue {
  // props: {
  @Prop({ type: [String, Number], required: true })
  private currentUserId;
  @Prop({ type: Object, required: true })
  private textMessages;
  @Prop({ type: Number, required: true })
  private index;
  @Prop({ type: Object, required: true })
  private message;
  @Prop({ type: Array, required: true })
  private messages;
  @Prop({ type: [String, Number], default: null })
  private editedMessageId;
  @Prop({ type: Array, default: () => [] })
  private roomUsers;
  @Prop({ type: Array, required: true })
  private messageActions;
  @Prop({ type: Array, default: () => [] })
  private newMessages;
  @Prop({ type: Boolean, required: true })
  private showReactionEmojis;
  @Prop({ type: Boolean, required: true })
  private showNewMessagesDivider;
  @Prop({ type: Object, required: true })
  private textFormatting;
  @Prop({ type: Object, required: true })
  private linkOptions;
  @Prop({ type: Object, required: true })
  private usernameOptions;
  @Prop({ type: Boolean, required: true })
  private messageSelectionEnabled;
  @Prop({ type: Array, default: () => [] })
  private selectedMessages;
  @Prop({ type: Boolean, default: false })
  private isShowAgents;
  // },

  // data() {
  //   return {
  private hoverMessageId = null;
  private messageHover = false;
  private optionsOpened = false;
  private emojiOpened = false;
  private newMessage = {};
  private progressTime = '- : -';
  private hoverAudioProgress = false;

  private isShowMsgTranslate = false;

  public selectedMesssageId = null;

  //   };
  // },

  // computed: {
  public get showUsername() {
    if (!this.usernameOptions.currentUser && this.message.senderId === this.currentUserId) {
      return false;
    } else {
      return this.roomUsers.length >= this.usernameOptions.minUsers;
    }
  }
  public get showDate() {
    return this.index > 0 && this.message.date !== this.messages[this.index - 1].date;
  }
  public get messageOffset() {
    return this.index > 0 && this.message.senderId !== this.messages[this.index - 1].senderId;
  }
  public get isMessageHover() {
    return this.editedMessageId === this.message._id || this.hoverMessageId === this.message._id;
  }
  public get isAudio() {
    return this.message.files?.some(file => isAudioFile(file));
  }
  public get isCheckmarkVisible() {
    return (
      this.message.senderId === this.currentUserId &&
      !this.message.deleted &&
      (this.message.saved || this.message.distributed || this.message.seen)
    );
  }
  public get hasCurrentUserAvatar() {
    return this.messages.some(message => message.senderId === this.currentUserId && message.avatar);
  }
  public get hasSenderUserAvatar() {
    return this.messages.some(message => message.senderId !== this.currentUserId && message.avatar);
  }
  public get isMessageSelected() {
    return this.messageSelectionEnabled && !!this.selectedMessages.find(message => message._id === this.message._id);
  }

  public get CONST_MESSAGE_CARD() {
    return CONST_MESSAGE_CARD;
  }

  public get CONST_MESSAGE_CARD_FORMAT_MESSAGE() {
    return CONST_MESSAGE_CARD_FORMAT_MESSAGE;
  }

  // }

  mounted() {
    messagesValidation(this.message);

    this.$emit('message-added', {
      message: this.message,
      index: this.index,
      ref: this.$refs.message,
    });
  }

  // methods: {
  onHoverMessage() {
    if (!this.messageSelectionEnabled) {
      this.messageHover = true;
      if (this.canEditMessage()) this.hoverMessageId = this.message._id;
    }
  }
  canEditMessage() {
    return !this.message.deleted;
  }
  onLeaveMessage() {
    if (!this.messageSelectionEnabled) {
      if (!this.optionsOpened && !this.emojiOpened) this.messageHover = false;
      this.hoverMessageId = null;
    }
  }
  resetMessageHover() {
    this.messageHover = false;
    this.hoverMessageId = null;
  }
  openFile(file) {
    this.$emit('open-file', { message: this.message, file: file });
  }
  openUserTag(user) {
    this.$emit('open-user-tag', { user });
  }
  messageActionHandler(action) {
    (<any>this.$refs.MessageDeleteNotifModal).hide();
    this.resetMessageHover();

    if (action == 'TRANSLATE') {
      if (this.message?.id && this.$refs[CONST_MESSAGE_CARD + this.message.id]) {
        (<any>this.$refs[CONST_MESSAGE_CARD + this.message.id]).openAllCollapse();
      }
      this.isShowMsgTranslate = !this.isShowMsgTranslate;
    }
    setTimeout(() => {
      this.$emit('message-action-handler', { action, message: this.message, isValueNegate: this.isShowMsgTranslate });
    }, 300);
  }
  sendMessageReaction({ emoji, reaction }) {
    this.$emit('send-message-reaction', {
      messageId: this.message._id,
      reaction: emoji,
      remove: reaction && reaction.indexOf(this.currentUserId) !== -1,
    });
    this.messageHover = false;
  }
  selectMessage() {
    if (this.messageSelectionEnabled) {
      if (this.isMessageSelected) {
        this.$emit('unselect-message', this.message._id);
      } else {
        this.$emit('select-message', this.message);
      }
    }
  }

  messageDeleteNotifCallBack() {
    (<any>this.$refs.MessageDeleteNotifModal).hide();
  }

  showMessageDeleteNotif({ messageId }) {
    this.selectedMesssageId = messageId;
    (<any>this.$refs.MessageDeleteNotifModal).show();
  }

  // handleShowMsgTranslate() {
  //   this.isShowMsgTranslate = !this.isShowMsgTranslate;
  //   console.log(this.isShowMsgTranslate, 'masuk');
  // }

  // },
}
