import axios, { CancelTokenSource } from 'axios';
import { ICbOid, ICbOidActivation } from '@/shared/model/cb-oids.model';
import { ICbUserInfo } from '@/shared/model/cb-user-info.model';

const baseApiUrl = 'services/cbuserms/api/oids';

export default class PCbOidService {
  public registerUser(entity: ICbUserInfo, callBack: any, callbackReject: Function): Promise<ICbOid> {
    let blob = null;
    if (entity.photo && entity.photo.att && entity.photo.localUrl) {
      entity.photo.att = '';
      blob = entity.photo.blob;
      delete entity.photo.blob;
    }

    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/register-user`, entity)
        .then(res => {
          const result: ICbOid = res.data;
          if (entity && entity.photo && blob) {
            resolve(
              this.upload(
                result,
                entity,
                result.cbUserInfo.uploadLinkPhotoProfile,
                entity.photo.attContentType,
                blob,
                callBack,
                callbackReject
              )
            );
          } else {
            resolve(res.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendOtpTroubleByPhone(entity: ICbOidActivation): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/activate-phone/resend-by-phone`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public activate(entity: ICbOidActivation, method: string, cancelToken?: CancelTokenSource): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/activate-${method}/finish`, entity, { cancelToken: cancelToken?.token })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public resend(entity: ICbOidActivation, method: string): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/activate-${method}/re-send`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public updatePhoneByKey(entity: ICbOidActivation, method: string): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/update-phone-by-key`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public findOtp(entity: ICbOidActivation, method: string): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/activate-${method}/re-send`)
        // .get(`${baseApiUrl}/activate-${method}/re-send`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public upload(
    result: ICbOid,
    entity: ICbUserInfo,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(signUrlRes => {
          resolve(result);
        })
        .catch(signUrlErr => {
          reject(callbackReject(result, entity));
        });
    });
  }
}
