import {Component, Emit, Prop, Vue, } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class CTmpAnnouncement extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop() title: string;
  @Prop() content;
  @Prop({default:'tmp-announcement-close'}) dataCyClose: string;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('close')
  public close() {
    return;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}