import { Vue, Component } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
@Component({
  components: {
    Icon,
  },
})
export default class roomBarChat extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleShowSearch() {
    this.$emit('handle-show-search', null);
  }

  public handleShowSettingUser() {
    this.$emit('handle-show-setting-user', null);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
