import { Vue, Component, Prop } from 'vue-property-decorator';
import SvgIcon from '../../../SvgIcon/SvgIcon.vue';
import { isImageFile, isVideoFile } from '@/module/chat-container/chat/utils/media-file';
import { IChtFile } from '@/shared/model/cht-file.model';
import CIcons from '../../../c-icons/c-icons.vue';
import Icon from '@/shared/icons/icon.vue';
import { ChtIsVideoImageType } from '@/shared/model/enumerations/cht-is-video-image-type.model';

@Component({
  components: { SvgIcon, CIcons, Icon },
})
export default class CRmediaPreview extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: {} })
  public chtVwMedias;
  public selectedPlayVideoUrl = '';
  public isPlaying: boolean = false;
  public iWidht = 0;

  public seletedVideoImageType: ChtIsVideoImageType = ChtIsVideoImageType.IS_VIDEO;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.iWidht = window.innerWidth;
    window.addEventListener('resize', ev => {
      if (ev.isTrusted) this.iWidht = window.innerWidth;
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleDownload() {
    this.$emit('downloadImageVideo', this.chtVwMedias[(<any>this.$refs.vueperSlidesContentImageVideo).slides.current]);
  }
  public handleDelete() {
    this.$emit('deleteImageVideo', this.chtVwMedias[(<any>this.$refs.vueperSlidesContentImageVideo).slides.current]);
  }
  public closeModal() {
    this.$emit('close-media-preview', null);
  }

  public handlePlayVideo(url) {
    this.selectedPlayVideoUrl = url;
    setTimeout(() => {
      if (!this.isPlaying) (<any>this.$refs.myVideo).play();
      else (<any>this.$refs.myVideo).pause();
      this.isPlaying = !this.isPlaying;
    }, 10);
  }
  public setPausePlay() {
    if (!this.isPlaying) (<any>this.$refs.myVideo).play();
    else (<any>this.$refs.myVideo).pause();

    this.isPlaying = !this.isPlaying;
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isImage() {
    return (file: IChtFile) => {
      return isImageFile(file);
    };
  }

  public get isVideo() {
    return (file: IChtFile) => {
      return isVideoFile(file);
    };
  }
  public get getWidhtSlide() {
    const count = this.chtVwMedias.length;
    let value: boolean = false;
    if (this.iWidht > 992) {
      value = count <= 9;
    } else if (this.iWidht < 992 && this.iWidht >= 760) {
      value = count <= 7;
    } else if (this.iWidht < 760 && this.iWidht >= 572) {
      value = count <= 4;
    } else if (this.iWidht < 572) {
      value = count <= 3;
    }
    return value;
  }

  // ================= END COMPUTE ====================
}
