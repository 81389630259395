import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {},
})
export default class AppThemVars extends Vue {
  created() {
    this.initThemeVars();
  }
  public initThemeVars() {
    // :root selector selalu merujuk ke elemen html teratas
    var rootElement = document.getElementsByTagName('html')[0] as HTMLElement;

    // Array untuk menyimpan variabel tema dan nilainya
    var themeVars = [
      {
        name: '--cdn-ic-bg-im-face-hibernation',
        value: `url(${process.env.CDN_CB}/content/cb-img/ic-bg-Im-face-hibernation.png??width=100)`,
      },
      {
        name: '--cdn-ic-shining-rookie',
        value: `url(${process.env.CDN_CB}/content/ic_shining_rookie.png?width=200)`,
      },
      {
        name: '--cdn-ic-shining-verified',
        value: `url(${process.env.CDN_CB}/content/ic_shining_verified.png?width=200)`,
      },
      {
        name: '--cdn-ic-shining-professional',
        value: `url(${process.env.CDN_CB}/content/ic_shining_professional.png?width=200)`,
      },
      {
        name: '--cdn-ic-shining-expert',
        value: `url(${process.env.CDN_CB}/content/ic_shining_expert.png?width=200)`,
      },
      {
        name: '--cdn-ic-shining-extraordinary',
        value: `url(${process.env.CDN_CB}/content/ic_shining_extraordinary.png?width=200)`,
      },
      {
        name: '--cdn-ic_dropdown',
        value: `url(${process.env.CDN_CB}/content/ic_dropdown.svg?width=200)`,
      },

      // Tambahkan variabel tema lainnya di sini
    ];

    // Iterasi melalui array dan menetapkan nilai pada elemen root
    themeVars.forEach(varObj => {
      rootElement.style.setProperty(varObj.name, varObj.value);
    });
  }
}
