import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CInputOtp extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public value: string;
  @Prop({ default: false }) public isUpperCase: boolean;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    setTimeout(() => {
      let inputs = document.querySelectorAll<HTMLInputElement>('input.input-otp-wrapper');
      let values = Array(4);
      let clipData;
      inputs[0].focus();

      let that = this;

      function focusFirstInputIfAllEmpty() {
        const allEmpty = values.every(value => !value);
        if (allEmpty) {
          inputs[0].focus();
        }
      }

      inputs.forEach((tag: any, index) => {
        tag.addEventListener('click', () => {
          focusFirstInputIfAllEmpty();
        });
        tag.addEventListener('keyup', event => {
          if (event.key === 'Backspace' && hasNoValue(index)) {
            if (index > 0) inputs[index - 1].focus();
          }

          //else if any input move focus to next or out
          else if (tag.value !== '') {
            let length = (event.target as HTMLInputElement).value.length;
            inputs[index].setSelectionRange(length, length);
            if (index < inputs.length - 1) {
              inputs[index + 1].focus();
            } else {
              tag.blur();
              inputs[index].focus();
            }
          }

          values[index] = that.isUpperCase
            ? (event.target as HTMLInputElement).value?.toUpperCase()
            : (event.target as HTMLInputElement).value;
          that.$emit('input', values?.join(''));
        });
        tag.addEventListener('input', event => {
          if (tag.value > 10) {
            tag.value = tag.value % 10;
          }
          if (that.isUpperCase) {
            event.currentTarget.value = (event.target as HTMLInputElement).value?.toUpperCase();
          }
        });

        tag.addEventListener('paste', event => {
          event.preventDefault();
          if (that.isUpperCase) {
            clipData = event.clipboardData.getData('text/plain').toUpperCase().split('');
          } else {
            clipData = event.clipboardData.getData('text/plain').split('');
          }
          filldata(event, index);
        });
      });

      function filldata(event, index) {
        for (let i = index; i < inputs.length; i++) {
          const tmpValue = clipData.shift();
          if (tmpValue) {
            inputs[i].value = tmpValue;
            if (event.code === 'Backspace' && hasNoValue(i)) {
              if (i > 0) inputs[i - 1].focus();
            }

            //else if any input move focus to next or out
            else if (inputs[i].value !== '') {
              i < inputs.length - 1 ? inputs[i + 1].focus() : inputs[i].blur();
            }

            values[i] = inputs[i].value;
          }
        }
        that.$emit('input', values.join(''));
      }

      function hasNoValue(index) {
        if (values[index] || values[index] === 0) return false;

        return true;
      }
    }, 10);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('value')
  public valueListener() {
    if (this.value?.length == 4 && <any>this.$refs.inputOTP) {
      (<any>this.$refs.inputOTP).blur();
    }
  }
  // ================= END LISTENERS ==================
}
