export enum CbLovType {
  CONTENT_BANNER_BUYER = 'CONTENT_BANNER_BUYER',
  CONTENT_BANNER_PARTNER = 'CONTENT_BANNER_PARTNER',
  CONTENT_IMAGE = 'CONTENT_IMAGE',
  TIME_ZONE = 'TIME_ZONE',
  COUNTRY = 'COUNTRY',
  PROVINCE = 'PROVINCE',
  CITY = 'CITY',
  LANGUAGE_CODE = 'LANGUAGE_CODE',
  TIME_ZONE_ABBREVIATION = 'TIME_ZONE_ABBREVIATION',
  PHONE_CODE = 'PHONE_CODE',
  MAIN_BANNER_PROMO = 'MAIN_BANNER_PROMO',
  LANGUAGE = 'LANGUAGE',
  MAJOR = 'MAJOR',
  BANK_CODE = 'BANK_CODE',
  WATERMARK = 'WATERMARK',
  PAW_CRT_MANDATORY = 'PAW_CRT_MANDATORY',
  CONTENT_VERSION = 'CONTENT_VERSION',
  CONTENT_VERSION_BIND = 'CONTENT_VERSION_BIND',
  LANGUAGE_LEVEL = 'LANGUAGE_LEVEL',
  APP_LINK = 'APP_LINK',
}
