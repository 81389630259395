// PREFIX SESSION_STORAGE_

export const SESSION_STORAGE_CB_VW_MEDAL = 'cb-vw-medals';
export const SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL = 'cb-vw-user-partner-levels';
export const SESSION_STORAGE_IS_ALREADY_HIT = 'is-already-hit';
export const SESSION_STORAGE_REQUESTED_URL = 'requested-url';
export const SESSION_STORAGE_BEFORE_REQUESTED_URL = 'before-requested-url';
export const SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN = 'cb-jhi-authenticationToken';
export const SESSION_STORAGE_LINKEDIN_INSTATE = 'linkedIn.state';
export const SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT = 'cb-jhi-authenticationToken-rt';
export const SESSION_STORAGE_CB_JHI_ID_TOKEN = 'cb-jhi-id-token';
