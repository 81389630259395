var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-upload-file position-relative" }, [
    _vm.isRunningOnFlutter === false
      ? _c("input", {
          ref: "inputFile",
          staticStyle: { display: "none" },
          attrs: {
            "data-cy": _vm.dataCyCustom
              ? `upload-file-${_vm.dataCyCustom}`
              : "upload-file",
            type: "file",
            multiple: "",
            accept: _vm.accept,
          },
          on: {
            change: function ($event) {
              return _vm.fileChange($event)
            },
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-upload-file-wrapper cj-field-border-dashed-default",
        on: {
          dragenter: _vm.handleDragEnter,
          dragleave: _vm.handleDragLeave,
          dragover: function ($event) {
            $event.preventDefault()
          },
          drop: _vm.handleDrop,
        },
      },
      [
        (_vm.files && _vm.files.length == 0) || !_vm.files
          ? _c(
              "button",
              {
                staticClass: "c-upload-file-info position-relative",
                on: {
                  click: function ($event) {
                    return _vm.handleUploadFile()
                  },
                },
              },
              [
                _c("icon", {
                  staticClass: "cj-fill-grey-secondary mb-3",
                  attrs: { name: "ic_upload", width: "32px", height: "32px" },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto font-size-18 font-weight-400 cj-color-grey-secondary",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbProfile.profileReport.dragDrop")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto font-size-14 font-weight-300 cj-color-grey-secondary",
                  domProps: { textContent: _vm._s(_vm.textUploadCondition) },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto font-size-11 cj-color-grey-secondary mb-2",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbProfile.profileReport.or")
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "c-button",
                  {
                    staticClass: "font-size-11 px-3",
                    attrs: { typeStyle: "secondary" },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbProfile.profileReport.browse")
                        ),
                      },
                    }),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.files && _vm.files.length > 0
          ? _c(
              "div",
              {
                staticClass:
                  "c-upload-file-preview-wrapper position-relative mx-min-2",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap" },
                  [
                    _vm._l(_vm.files, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "col-4 col-sm-3 col-md-2 px-2 position-relative",
                          attrs: {
                            "data-cy": _vm.dataCyCustom
                              ? `list-upload-file-${_vm.dataCyCustom}`
                              : "list-upload-file",
                          },
                        },
                        [
                          _vm.secondObj && item[_vm.secondObj]
                            ? _c("c-upload-file-icon", {
                                staticClass: "icon-wrapper",
                                attrs: {
                                  "data-cy": "file-icon",
                                  file: item[_vm.secondObj][_vm.fileType],
                                  fileUrl: item[_vm.secondObj][_vm.fileUrl],
                                  fileThumbnail:
                                    item[_vm.secondObj][_vm.fileThumbnail],
                                  isShowVideo: _vm.isShowVideo,
                                },
                              })
                            : _c("c-upload-file-icon", {
                                staticClass: "icon-wrapper",
                                attrs: {
                                  "data-cy": "file-icon",
                                  file: item[_vm.fileType],
                                  fileUrl: item[_vm.fileUrl],
                                  fileThumbnail: item[_vm.fileThumbnail],
                                  isShowVideo: _vm.isShowVideo,
                                },
                              }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-center font-roboto font-size-14 cj-color-black-primary font-weight-400 text-center text-ellipsis-oneline",
                              attrs: {
                                "data-cy": _vm.dataCyCustom
                                  ? `file-name-${_vm.dataCyCustom}`
                                  : "file-name",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.name) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn-delete-wrapper p-1",
                              attrs: {
                                "data-cy": _vm.dataCyCustom
                                  ? `btn-delete-${_vm.dataCyCustom}`
                                  : "btn-delete-wrapper",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("removeFile", index)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex my-auto" },
                                [
                                  _c("icon", {
                                    staticClass: "cj-fill-red-primary my-auto",
                                    attrs: {
                                      name: "ic_outline_delete",
                                      width: "24px",
                                      height: "24px",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.files && _vm.files.length < _vm.maxFileLength
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-4 col-sm-3 col-md-2 px-2 position-relative",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "icon-wrapper add-wrapper",
                                attrs: {
                                  "data-cy": _vm.dataCyCustom
                                    ? `btn-add-${_vm.dataCyCustom}`
                                    : "btn-add-wrapper",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleUploadFile()
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex my-auto" },
                                  [
                                    _c("icon", {
                                      staticClass:
                                        "cj-fill-red-primary my-auto",
                                      attrs: {
                                        name: "ic_plus_circle_outlined",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }