import { CbSvcType } from './enumerations/cb-svc-type.model';

export interface IAdvanceSearchFilter {
  svcId?: number | null;
  svcCatId?: number | null;
  lessStartPrice?: number | null;
  greaterStartPrice?: number | null;
  deliveryTime?: number | null;
  rating?: number[] | null;
  levels?: string[] | null;
  name?: string | null;
  languages?: string[] | null;
  locations?: string[] | null;
  dueDate?: string[] | null;
  svcType?: CbSvcType | null;
  promoCode?: string | null;
  promoSvcCatId?: string[] | null;
  promoSvc?: string[] | null;
  tab?: AdvanceSearchTab | null;
}

export class AdvanceSearchFilter implements IAdvanceSearchFilter {
  constructor(
    public svcId?: number | null,
    public svcCatId?: number | null,
    public lessStartPrice?: number | null,
    public greaterStartPrice?: number | null,
    public deliveryTime?: number | null,
    public rating?: number[] | null,
    public levels?: string[] | null,
    public name?: string | null,
    public languages?: string[] | null,
    public locations?: string[] | null,
    public dueDate?: string[] | null,
    public svcType?: CbSvcType | null,
    public promoCode?: string | null,
    public promoSvcCatId?: string[] | null,
    public promoSvc?: string[] | null,
    public tab?: AdvanceSearchTab | null
  ) {
    this.rating = this.rating ?? [0, 0];
    this.levels = this.levels ?? [];
    this.languages = this.languages ?? [];
  }
}

export enum AdvanceSearchFilterKey {
  SVCID = 'svcId',
  SVCCATID = 'svcCatId',
  PROMOSVCID = 'promoSvc',
  PROMOSVCCATID = 'promoSvcCatId',
  LESSSTARTPRICE = 'lessStartPrice',
  GREATERSTARTPRICE = 'greaterStartPrice',
  DELIVERYTIME = 'deliveryTime',
  RATING = 'rating',
  LEVELS = 'levels',
  NAME = 'name',
  LANGUAGES = 'languages',
  LOCATIONS = 'locations',
  DUEDATE = 'dueDate',
  SVCTYPE = 'svcType',
   //transient
  PROMO = 'PROMO',
  TAB = 'tab',
}

export enum AdvanceSearchTab {
  PROJECT = 'PROJECT',
  PAW = 'PAW',
  USER = 'USER',
}

export enum AdvanceSearchAvailableFilter {
  SERVICE = 'SERVICE',
  LEVEL = 'LEVEL',
  BUDGET = 'BUDGET',
  LOCATIONS = 'LOCATIONS',
  RATING = 'RATING',
  DELIVERY_TIME = 'DELIVERY_TIME',
  DUE_DATE = 'DUE_DATE',
  LANGUAGE = 'LANGUAGE',
  PROMO = 'PROMO', //transient
}
