import { IChtMessage } from '@/shared/model/cht-message.model';

export interface IChtReaction {
  id?: number;
  content?: string | null;
  stickerId?: number | null;
  imgUrl?: string | null;
  createdById?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  chtMessage?: IChtMessage | null;
  countTransient?: number | null;
  firstDataTransient?: string | null;
}

export class ChtReaction implements IChtReaction {
  constructor(
    public id?: number,
    public content?: string | null,
    public stickerId?: number | null,
    public imgUrl?: string | null,
    public createdById?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public chtMessage?: IChtMessage | null,
    public countTransient?: number | null,
    public firstDataTransient?: string | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
