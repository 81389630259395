import CButton from '@/components/button/c-button.vue';
import { TabSettingType } from '@/module/general/setting/settings.component';
import ContentService from '@/services/content.service';
import Icon from '@/shared/icons/icon.vue';
import { CbBankAccount } from '@/shared/model/cb-bank-account.model';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
    CButton,
  },
})
export default class CFpBankAccount extends Vue {
  // ================= START SERVICES =================
  @Inject('contentService') private contentService: () => ContentService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbBankAccount: CbBankAccount;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created(){
    this.contentService().initRetrieveCbLovs(CbLovType.BANK_CODE);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public toSetting() {
    if (this.isMobile) {
      this.$router.push({
        name: 'PSettingAccountMBank',
      });
    } else {
      this.$router.push({
        name: 'PSettings',
        query: {
          tab: TabSettingType.BANK_ACCOUNT,
        },
      });
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  public get getBankNameByKey(): string {
    let value: string = '';
    if (this.cbBankAccount && this.cbBankAccount.id && this.cbBankAccount.bankCode && this.cbLovBanks.length > 0) {
      let found = this.cbLovBanks.find(e => e.key === this.cbBankAccount.bankCode);
      if (found) {
        value = found.value;
      }
    }
    return value;
  }
  public get cbLovBanks() {
    return this.$store.getters[CbLovType.BANK_CODE];
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
