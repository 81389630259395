var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-edit-phone-number" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center w-100 position-relative",
        },
        [
          _c("p", {
            staticClass: "font-roboto font-weight-600 cj-color-black-primary",
            class:
              _vm.isMobile || _vm.isPhoneTrouble
                ? "font-size-18"
                : "font-size-24",
            domProps: {
              textContent: _vm._s(
                _vm.isPhoneTrouble
                  ? _vm.$t("cbgwApp.cbComponent.cbEditPhoneNumber.subTitle")
                  : _vm.$t("cbgwApp.cbComponent.cbEditPhoneNumber.title")
              ),
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "position-absolute p-3 m-n3",
              staticStyle: { right: "0" },
              attrs: { type: "button" },
              on: { click: _vm.cancel },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.isPhoneTrouble
        ? _c("p", {
            staticClass:
              "font-roboto font-size-18 font-italic font-weight-500 cj-color-black-primary text-center mt-3",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cbEditPhoneNumber.inputPhone")
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form" }, [
        _c(
          "div",
          { class: !_vm.isMobile ? "px-3" : "" },
          [
            _c("div", { staticClass: "d-flex pt-4 position-relative" }, [
              !_vm.isMobile
                ? _c(
                    "div",
                    {
                      staticClass: "rounded d-flex pr-2",
                      class: {
                        invalid: _vm.$v.phoneCode.$invalid || _vm.isExistPhone,
                        valid: !_vm.$v.phoneCode.$invalid,
                      },
                      attrs: { "data-cy": "wrap-phone" },
                    },
                    [
                      _c("c-autocomplete-new", {
                        staticClass:
                          "font-roboto font-weight-400 font-size-16 cj-color-black-primary my-auto cj-field-not-border",
                        attrs: {
                          placeholder: "ID (+62)",
                          items: _vm.cbLovPhoneCodes,
                          isNoRelative: true,
                          "item-text": "value",
                          "item-value": "key",
                          "item-text-input": "key",
                        },
                        on: { input: _vm.handleCheckPhone },
                        model: {
                          value: _vm.$v.phoneCode.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.phoneCode, "$model", $$v)
                          },
                          expression: "$v.phoneCode.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "col-4 d-flex rounded",
                      class: {
                        invalid: _vm.$v.phoneCode.$invalid || _vm.isExistPhone,
                        valid: !_vm.$v.phoneCode.$invalid,
                      },
                      attrs: { "data-cy": "wrap-phone" },
                      on: { click: _vm.openPopupPhoneCodeM },
                    },
                    [
                      _c("input", {
                        staticClass:
                          "font-size-18 cj-field-not-border cj-field-input",
                        attrs: { type: "text", placeholder: "ID (+62)" },
                        domProps: { value: _vm.phoneCode },
                        on: { focus: _vm.openPopupPhoneCodeM },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "my-auto d-flex mr-2" },
                        [
                          _c("icon", {
                            staticClass:
                              "my-auto cj-fill-black-primary ic_arrow_dropdown",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pl-2", staticStyle: { "min-width": "65%" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.$v.phone.$model,
                        expression: "$v.phone.$model",
                      },
                    ],
                    staticClass: "font-size-18 cj-field-border-default",
                    class: {
                      invalid:
                        _vm.$v.phone.$anyDirty &&
                        (_vm.$v.phone.$invalid || _vm.isExistPhone),
                      valid:
                        _vm.$v.phone.$anyDirty &&
                        !_vm.$v.phone.$invalid &&
                        !_vm.isExistPhone,
                    },
                    attrs: {
                      type: "number",
                      "data-cy": "number-phone-change",
                      placeholder:
                        _vm.$t(
                          "cbgwApp.cbHome.cbHomeBeforeLogin.register.phoneNumber"
                        ) + "*",
                    },
                    domProps: { value: _vm.$v.phone.$model },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.$v.phone, "$model", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.isExistPhone
              ? _c("div", [
                  _c("small", { staticClass: "font-validation" }, [
                    _c("span", {
                      staticClass: "text-danger cj-color-status-error",
                      attrs: { "data-cy": "font-validation-phone" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            `cbgwApp.cbCreateAccount.phone.${_vm.valueStatusPhone}`
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _vm.valueStatusPhone != "DELETED"
                      ? _c(
                          "u",
                          {
                            staticClass: "cj-color-red-primary hand",
                            on: { click: _vm.openModalLogin },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.login"
                                  )
                                ),
                              },
                            }),
                            _vm._v("?\n          "),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _c("p", {
                  staticClass: "font-validation mt-2",
                  class: `${
                    _vm.$v.phone.$anyDirty
                      ? _vm.$v.phone.$invalid
                        ? "cj-color-status-error"
                        : "cj-color-status-success d-none"
                      : "d-none"
                  }`,
                  attrs: { "data-cy": "font-validation-phone" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(`cbgwApp.cbCreateAccount.phone.default`)
                    ),
                  },
                }),
            _vm._v(" "),
            _vm.isSendOtpError
              ? _c(
                  "div",
                  { staticClass: "d-flex", staticStyle: { gap: "2px" } },
                  [
                    _c("span", {
                      staticClass: "text-danger cj-color-status-error",
                      class: _vm.isMobile ? "font-size-10" : "font-size-12",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            `cbgwApp.cbComponent.cbEditPhoneNumber.sendOtpError`
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("u", {
                      staticClass:
                        "cj-color-black-primary font-weight-600 hand",
                      class: _vm.isMobile ? "font-size-10" : "font-size-12",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbComponent.cbResetPassword.register")
                        ),
                      },
                      on: { click: _vm.openRegisterModal },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "c-button",
              {
                staticClass: "font-roboto text-center mt-4 font-size-18",
                attrs: {
                  fullWidth: "",
                  disabled:
                    _vm.$v.phoneCode.$invalid ||
                    _vm.$v.phone.$invalid ||
                    _vm.isFetchingPhoneNumber ||
                    _vm.isExistPhone,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleSubmitEditVerification.apply(
                      null,
                      arguments
                    )
                  },
                },
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cbEditPhoneNumber.submit")
                    ),
                  },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "c-popup-bottom",
        {
          attrs: { id: "popup-c-edit-phone-number-code" },
          model: {
            value: _vm.isShowPhoneCode,
            callback: function ($$v) {
              _vm.isShowPhoneCode = $$v
            },
            expression: "isShowPhoneCode",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between mb-3 py-2 border-grey-tertiary-bottom-1",
            },
            [
              _c("p", {
                staticClass:
                  "font-roboto font-size-14 cj-color-black-primary font-weight-500",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.register.phoneCode"
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "font-roboto cj-btn-text-primary font-size-14 font-weight-500",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.phoneCode = _vm.localPhoneCode
                      _vm.isShowPhoneCode = false
                      _vm.handleCheckPhone()
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbCreateAccount.placeholder.update")
                      ),
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("c-autocomplete-new", {
            staticClass:
              "font-roboto font-size-14 cj-color-black-primary font-weight-400 cj-field-not-border",
            attrs: {
              items: _vm.cbLovPhoneCodes,
              "item-text": "value",
              "item-value": "key",
              placeholder: "ID (+62)",
              isShowDropdown: true,
              isShowListItem: true,
              inputStyle: "cj-field-border-default rounded px-3",
            },
            model: {
              value: _vm.localPhoneCode,
              callback: function ($$v) {
                _vm.localPhoneCode = $$v
              },
              expression: "localPhoneCode",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }