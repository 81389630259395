import { ICbSvcCat } from '@/shared/model/cb-svc-cat.model';
import { ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { ICbLocProvince } from '@/shared/model/cb-loc-province.model';
import { ICbAuctionProjectAtt } from '@/shared/model/cb-auction-project-att.model';

import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
export interface ICbAuctionProject {
  id?: number;
  svcType?: CbSvcType | null;
  title?: string;
  projectLocation?: string | null;
  revisionCount?: number | null;
  projectDueDate?: Date | null;
  auctionDueDate?: Date | null;
  priceType?: CbPriceType | null;
  hourlyValue?: number | null;
  currency?: CbCurrency | null;
  price?: number | null;
  isProjectGuarantee?: boolean | null;
  isPoliceAgreement?: boolean | null;
  status?: CbCommonStatus | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbSvcCat?: ICbSvcCat | null;
  description?: ICbTextNoAuth | null;
  cbLocProvince?: ICbLocProvince | null;
  cbAuctionProjectAtts?: ICbAuctionProjectAtt[] | null;

  // Transient
  auctionDueTime?: Date | null;
  tempPrice?: number | null;
}

export class CbAuctionProject implements ICbAuctionProject {
  constructor(
    public id?: number,
    public svcType?: CbSvcType | null,
    public title?: string,
    public projectLocation?: string | null,
    public revisionCount?: number | null,
    public projectDueDate?: Date | null,
    public auctionDueDate?: Date | null,
    public priceType?: CbPriceType | null,
    public hourlyValue?: number | null,
    public currency?: CbCurrency | null,
    public price?: number | null,
    public isProjectGuarantee?: boolean | null,
    public isPoliceAgreement?: boolean | null,
    public status?: CbCommonStatus | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbSvcCat?: ICbSvcCat | null,
    public description?: ICbTextNoAuth | null,
    public cbLocProvince?: ICbLocProvince | null,
    public cbAuctionProjectAtts?: ICbAuctionProjectAtt[] | null,
    // Transient
    public auctionDueTime?: Date | null,
    public tempPrice?: number | null
  ) {
    // this.isProjectGuarantee = this.isProjectGuarantee ?? false;
    // this.isPoliceAgreement = this.isPoliceAgreement ?? false;
    // this.isActive = this.isActive ?? false;
  }
}
