import { CbCommonStatus } from './enumerations/cb-common-status.model';

export interface ICbVwUserSchedule {
  id?: number;
  externalId?: number | null;
  scheduleType?: string | null;
  pawOrderId?: number | null;
  title?: string | null;
  minBookStartDate?: Date | null;
  maxBookStartDate?: Date | null;
  buyerId?: string | null;
  partnerId?: string | null;
  status?: string | null;
  orderStatus?: CbCommonStatus | null;
}

export class CbVwUserSchedule implements ICbVwUserSchedule {
  constructor(
    public id?: number,
    public externalId?: number | null,
    public scheduleType?: string | null,
    public pawOrderId?: number | null,
    public title?: string | null,
    public minBookStartDate?: Date | null,
    public maxBookStartDate?: Date | null,
    public buyerId?: string | null,
    public partnerId?: string | null,
    public status?: string | null,
    public orderStatus?: CbCommonStatus | null
  ) {}
}
