var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-form-payment-2023",
      class: _vm.isMobile ? "navbar-distance" : "",
      attrs: { "data-cy": "c-form-payment-2023" },
    },
    [
      _c("div", { staticClass: "d-flex bar-wrapper height-navbar-m" }, [
        _c(
          "div",
          {
            staticClass: "position-title cursor-pointer",
            attrs: { "data-cy": "cancel-form-payment" },
            on: { click: _vm.cancel },
          },
          [
            _c("b-icon-x-circle-fill", {
              staticClass:
                "font-size-icon cj-color-grey-secondary vac-d-show-web",
            }),
            _vm._v(" "),
            _c("font-awesome-icon", {
              staticClass:
                "cb-icon-size-arrow-left cj-color-black-primary vac-d-none-web p-3",
              attrs: { icon: "fa-solid fa-arrow-left" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "costum-margin" }, [
          _c(
            "p",
            {
              staticClass:
                "font-roboto text-center cj-color-black-primary font-size-title font-weight-bold",
              staticStyle: { "text-transform": "capitalize" },
              attrs: {
                "data-cy": _vm.dataCyCustomTitle
                  ? `payment-title-${_vm.dataCyCustomTitle}`
                  : "payment-title",
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("formatLanguage")(
                      _vm.localPayload.title,
                      _vm.currentLanguage
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.localPayload.subTitle
            ? _c(
                "p",
                {
                  staticClass:
                    "font-roboto text-center cj-color-black-primary font-size-sub-title font-weight-medium font-italic text-ellipsis-oneline",
                  attrs: {
                    "data-cy": _vm.dataCyCustom
                      ? `subtitle-payment-${_vm.dataCyCustom}`
                      : "subtitle-payment",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.localPayload.subTitle) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-payment-container pt-4",
          attrs: { "data-cy": "form-payment-container" },
        },
        [
          _vm.localPayload.payMethodType == _vm.PayMethodType.DISBURSEMENT ||
          _vm.localPayload.payMethodType ==
            _vm.PayMethodType.DISBURSEMENT_DETAIL ||
          _vm.localPayload.payMethodType ==
            _vm.PayMethodType.DISBURSEMENT_INCOME ||
          _vm.localPayload.payMethodType ==
            _vm.PayMethodType.DISBURSEMENT_INCOME_DETAIL ||
          _vm.localPayload.payMethodType == _vm.PayMethodType.REFUND_REQUEST ||
          _vm.localPayload.payMethodType ==
            _vm.PayMethodType.REFUND_REQUEST_DETAIL
            ? [
                _c("c-fp-input-balance-tab", {
                  staticClass: "mb-0 mb-lg-2",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.debounceCheckOrderDetail.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.localPayload.amount,
                    callback: function ($$v) {
                      _vm.$set(_vm.localPayload, "amount", $$v)
                    },
                    expression: "localPayload.amount",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "input-balance-container d-flex flex-wrap pb-2",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 box-wrapper px-2 pb-2" },
                      [
                        _c("c-input-currency", {
                          staticClass:
                            "font-roboto font-weight-500 cj-color-black-primary text-center font-size-36-auto cj-field-border-default border-radius-card",
                          attrs: {
                            placeholder: "IDR 0",
                            currency: "IDR",
                            min: 15000,
                            max: _vm.maxAmountWithdraw,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.debounceCheckOrderDetail()
                            },
                          },
                          model: {
                            value: _vm.localPayload.amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.localPayload, "amount", $$v)
                            },
                            expression: "localPayload.amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg-5 box-wrapper px-2 pb-3 pb-lg-2 text-lg-left text-right",
                      },
                      [
                        _c("c-fp-information", {
                          attrs: {
                            infoTitle: _vm.$t(
                              "cbgwApp.accountBalance.minimumWithdrawal"
                            ),
                            infoPrice: `${_vm.orderDetail.currency} 50.000`,
                            isShowInfoDetail: false,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg-7 box-wrapper px-2 pb-2 text-right",
                      },
                      [
                        _vm.localPayload.payMethodType ==
                          _vm.PayMethodType.DISBURSEMENT ||
                        _vm.localPayload.payMethodType ==
                          _vm.PayMethodType.DISBURSEMENT_DETAIL
                          ? _c("c-fp-information", {
                              attrs: {
                                infoTitle: _vm.$t(
                                  "cbgwApp.cbComponent.cWithdraw.availableWithdrawal"
                                ),
                                infoPrice:
                                  _vm.orderDetail.currency +
                                  " " +
                                  _vm.$options.filters.formatCurrency(
                                    _vm.orderDetail.balanceAmount
                                  ),
                                isShowInfoDetail: false,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.localPayload.payMethodType ==
                          _vm.PayMethodType.DISBURSEMENT_INCOME ||
                        _vm.localPayload.payMethodType ==
                          _vm.PayMethodType.DISBURSEMENT_INCOME_DETAIL
                          ? _c("c-fp-information", {
                              attrs: {
                                infoTitle: _vm.$t(
                                  "cbgwApp.cbComponent.cWithdraw.availableWithdrawal"
                                ),
                                infoPrice:
                                  _vm.orderDetail.currency +
                                  " " +
                                  _vm.$options.filters.formatCurrency(
                                    _vm.orderDetail.incomeAmount
                                  ),
                                isShowInfoDetail: false,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.localPayload.payMethodType ==
                          _vm.PayMethodType.REFUND_REQUEST ||
                        _vm.localPayload.payMethodType ==
                          _vm.PayMethodType.REFUND_REQUEST_DETAIL
                          ? _c("c-fp-information", {
                              attrs: {
                                infoTitle: _vm.$t(
                                  "cbgwApp.cbComponent.cWithdraw.availableWithdrawal"
                                ),
                                infoPrice:
                                  _vm.orderDetail.currency +
                                  " " +
                                  _vm.$options.filters.formatCurrency(
                                    _vm.orderDetail.refundAmount
                                  ),
                                isShowInfoDetail: false,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("c-fp-bank-account", {
                  staticClass: "mb-2",
                  attrs: { cbBankAccount: _vm.cbBankAccounts[0] },
                }),
                _vm._v(" "),
                _vm.orderDetail.feeAdmin && _vm.orderDetail.feeAdmin > 0
                  ? _c("c-fp-information", {
                      staticClass: "text-right mb-3",
                      attrs: {
                        infoTitle: _vm.$t(
                          "cbgwApp.accountBalance.transactionFee"
                        ),
                        infoPrice: `${
                          _vm.orderDetail.currency
                        } ${_vm.$options.filters.formatCurrency(
                          _vm.orderDetail.feeAdmin
                        )}`,
                        isShowInfoDetail: true,
                        infoDetail: _vm.$t(
                          "cbgwApp.accountBalance.withdrawDesc"
                        ),
                        isShowReadMore: true,
                      },
                      on: { toLink: _vm.toHelpCenter },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.localPayload.payMethodType == _vm.PayMethodType.TOP_UP ||
          _vm.localPayload.payMethodType == _vm.PayMethodType.TOP_UP_DETAIL ||
          _vm.isBackTopUp
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "mb-3 my-cb-balannce-wrapper d-flex flex-wrap align-items-center wrap-border-grey-tertiary border-radius-card px-3 pt-3 pb-2",
                    attrs: {
                      id: "payment-topup-account-balance",
                      "data-cy": "payment-topup-account-balance",
                    },
                  },
                  [
                    _c("p", {
                      staticClass:
                        "flex-grow-1 pb-2 font-roboto cj-color-black-primary font-size-18-auto font-weight-400",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.accountBalance.cbBalance")
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "pb-2 font-roboto cj-color-black-primary font-size-24-auto font-weight-700",
                        attrs: { "data-cy": "balance-amount" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.orderDetail.currency) +
                            "\n          " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.orderDetail.balanceAmount,
                                _vm.orderDetail.currency
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("c-fp-input-balance-tab", {
                  staticClass: "mb-0 mb-lg-2",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.debounceCheckOrderDetail.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.localPayload.amount,
                    callback: function ($$v) {
                      _vm.$set(_vm.localPayload, "amount", $$v)
                    },
                    expression: "localPayload.amount",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "input-balance-container d-flex flex-wrap pb-0",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 box-wrapper px-2 pb-2" },
                      [
                        _c("c-input-currency", {
                          staticClass:
                            "font-roboto font-weight-500 cj-color-black-primary text-center font-size-36-auto cj-field-border-default border-radius-card",
                          attrs: {
                            dataCyCustom: "topup",
                            placeholder: "IDR 0",
                            currency: "IDR",
                            max: 20000000,
                            min: 15000,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.debounceCheckOrderDetail()
                            },
                          },
                          model: {
                            value: _vm.localPayload.amount,
                            callback: function ($$v) {
                              _vm.$set(_vm.localPayload, "amount", $$v)
                            },
                            expression: "localPayload.amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg-5 box-wrapper px-2 pb-3 pb-lg-0 text-lg-left text-right",
                      },
                      [
                        _c("c-fp-information", {
                          attrs: {
                            infoTitle: _vm.$t(
                              "cbgwApp.accountBalance.minTopup"
                            ),
                            infoPrice: `${_vm.orderDetail.currency} 15.000`,
                            isShowInfoDetail: false,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 col-lg-7 box-wrapper px-2 pb-0 text-right",
                      },
                      [
                        _c("c-fp-information", {
                          attrs: {
                            infoTitle: _vm.$t(
                              "cbgwApp.accountBalance.topupLimit"
                            ),
                            infoPrice: `${
                              _vm.orderDetail.currency
                            }. ${_vm.$options.filters.formatCurrency(
                              _vm.orderDetail.limitAmount
                            )}/${_vm.$options.filters.formatCurrency(
                              _vm.topupLimit
                            )}`,
                            isShowInfoDetail: true,
                            infoDetail: _vm.$t(
                              "cbgwApp.accountBalance.limitSaldoDesc",
                              {
                                param: `${
                                  _vm.orderDetail.currency
                                }. ${_vm.$options.filters.formatCurrency(
                                  _vm.balanceLimit
                                )}`,
                                param2: `${
                                  _vm.orderDetail.currency
                                }. ${_vm.$options.filters.formatCurrency(
                                  _vm.topupLimit
                                )}`,
                              }
                            ),
                            isShowReadMore: true,
                          },
                          on: { toLink: _vm.toHelpCenter },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.localPayload.payMethodType ==
            _vm.PayMethodType.ORDER_PAW_DETAIL ||
            _vm.localPayload.payMethodType == _vm.PayMethodType.ORDER_PAW ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.ORDER_AUCTION_DETAIL ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.ORDER_AUCTION) &&
          !_vm.isBackTopUp
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "wrap-border-grey-tertiary border-radius-card order-ap-container",
                    style: !_vm.isMobile
                      ? "padding: 38px 38px 24px 38px;"
                      : "padding: 32px 16px 0px 16px",
                    attrs: { "data-cy": "order-summary" },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto cj-color-black-primary font-weight-700 font-size-18-title mb-3",
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbComponent.cFromPayment.orderSummary"
                              )
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-wrap" }, [
                      _c("div", { staticClass: "flex-grow-1 pb-2" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto cj-color-black-primary font-weight-300 font-size-18-auto",
                            attrs: { "data-cy": "payment-detail-info-title" },
                          },
                          [
                            _vm.localPayload.payMethodType ==
                              _vm.PayMethodType.ORDER_PAW_DETAIL ||
                            _vm.localPayload.payMethodType ==
                              _vm.PayMethodType.ORDER_PAW
                              ? _c("span", [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(_vm.pawTitlePrice)
                                      ),
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.localPayload.payMethodType ==
                              _vm.PayMethodType.ORDER_AUCTION_DETAIL ||
                            _vm.localPayload.payMethodType ==
                              _vm.PayMethodType.ORDER_AUCTION
                              ? _c("span", [
                                  _vm._v(
                                    "\n                Project Guarantee\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex col-5 col-sm-7 col-md-3 col-lg-4 ml-auto align-items-center px-0 pb-2",
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.orderDetail.currency) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                attrs: {
                                  "data-cy": _vm.dataCyCustomPriceOrderDetail
                                    ? `price-order-detail-${_vm.dataCyCustomPriceOrderDetail}`
                                    : "price-order-detail",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        _vm.orderDetail.orderAmount,
                                        _vm.orderDetail.currency
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.orderDetail.cbPawOrderExtras,
                      function (extra, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "d-flex flex-wrap" },
                          [
                            _c("div", { staticClass: "flex-grow-1 pb-2" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto cj-color-black-primary font-weight-300 font-size-18-auto",
                                  attrs: { "data-cy": "extra-price-payment" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("capitalFirstLetter")(
                                          extra.value
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex col-5 col-sm-7 col-md-3 col-lg-4 ml-auto align-items-center px-0 pb-2",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.orderDetail.currency) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-auto" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                      attrs: {
                                        "data-cy":
                                          _vm.dataCyCustomPriceOrderDetail
                                            ? `price-order-detail-${_vm.dataCyCustomPriceOrderDetail}`
                                            : "price-order-detail",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              extra.amount,
                                              _vm.orderDetail.currency
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-wrap border-grey-tertiary-top-1 pt-3 mt-2",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex col-5 col-sm-7 col-md-3 col-lg-4 ml-auto align-items-center px-0 pb-2",
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto cj-color-black-primary font-weight-500 font-size-20-auto-rp",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.orderDetail.currency) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-auto" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto cj-color-black-primary font-weight-500 font-size-20-auto-rp",
                                  attrs: {
                                    "data-cy": _vm.dataCyCustomPriceTotalOrder
                                      ? `price-total-order-${_vm.dataCyCustomPriceTotalOrder}`
                                      : "price-total-order",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.orderDetail.rawAmount,
                                          _vm.orderDetail.currency
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.localPayload.payMethodType !==
                      _vm.PayMethodType.ORDER_AUCTION_DETAIL &&
                    _vm.localPayload.payMethodType !==
                      _vm.PayMethodType.ORDER_AUCTION
                      ? _c(
                          "div",
                          {
                            staticClass: "border-grey-tertiary-top-1 pt-3 mt-2",
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "font-roboto cj-color-black-primary font-weight-700 font-size-18-auto mb-2",
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "cbgwApp.cbComponent.cFromPayment.promo"
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-wrap",
                                staticStyle: {
                                  "margin-left": "-1rem",
                                  "margin-right": "-1rem",
                                },
                                style: _vm.isMobile ? "position: relative" : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-7 col-sm-4 col-md-3 col-lg-8 4px-3",
                                    style: _vm.isMobile
                                      ? "position: static"
                                      : "",
                                    attrs: {
                                      "data-cy": "payment-section-promo",
                                    },
                                  },
                                  [
                                    _c("c-input-payment-promo", {
                                      attrs: {
                                        payload: _vm.localPayload,
                                        cbVwPromoUserAggs:
                                          _vm.cbVwPromoUserAggs,
                                        isFetchingCbVwPromoUserAggs:
                                          _vm.isFetchingCbVwPromoUserAggs,
                                        itemsPerPage: _vm.itemsPerPage,
                                        queryCount: _vm.queryCount,
                                        page: _vm.page,
                                        previousPage: _vm.previousPage,
                                        propOrder: _vm.propOrder,
                                        reverse: _vm.reverse,
                                        totalItems: _vm.totalItems,
                                        infiniteId: _vm.infiniteId,
                                        links: _vm.links,
                                      },
                                      on: {
                                        changeValue: _vm.changeValue,
                                        changeFunction: _vm.changeFunction,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "loaderWeb",
                                            fn: function () {
                                              return [
                                                _vm.totalItems >
                                                  _vm.itemsPerPage &&
                                                !_vm.isMobile
                                                  ? _c(
                                                      "infinite-loading",
                                                      {
                                                        ref: "infiniteLoading",
                                                        attrs: {
                                                          slot: "append",
                                                          identifier:
                                                            _vm.infiniteId,
                                                          distance: 5,
                                                        },
                                                        on: {
                                                          infinite:
                                                            _vm.loadMore,
                                                        },
                                                        slot: "append",
                                                      },
                                                      [
                                                        _c("div", {
                                                          attrs: {
                                                            slot: "no-more",
                                                          },
                                                          slot: "no-more",
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "spinner",
                                                            },
                                                            slot: "spinner",
                                                          },
                                                          [
                                                            _c("c-loader", {
                                                              staticClass:
                                                                "d-flex m-auto",
                                                              attrs: {
                                                                show: true,
                                                                infinite: true,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1935310356
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.orderDetail.promoAmount &&
                                _vm.orderDetail.promoAmount > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mt-2 d-flex col-5 col-sm-8 col-md-9 col-lg-4 ml-auto pl-0 pr-3 pb-2",
                                      },
                                      [
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "text-nowrap" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-roboto cj-color-red-primary font-weight-400 font-size-20-auto-rp",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.orderDetail.currency
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-roboto cj-color-red-primary font-weight-400 font-size-20-auto-rp mx-1",
                                                },
                                                [_vm._v("(-)")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "ml-auto" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "font-roboto cj-color-red-primary font-weight-400 font-size-20-auto-rp",
                                                  attrs: {
                                                    "data-cy": "coupon-amount",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatCurrency"
                                                        )(
                                                          _vm.orderDetail
                                                            .promoAmount,
                                                          _vm.orderDetail
                                                            .currency
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-wrap border-grey-tertiary-top-1 pt-3 mt-4",
                      },
                      [
                        _c("div", { staticClass: "flex-grow-1 pb-2" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-500 font-size-18-title",
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "cbgwApp.cbComponent.cFromPayment.totalPayment"
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex col-5 col-sm-7 col-md-3 col-lg-4 ml-auto align-items-center px-0 pb-2",
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto cj-color-black-primary font-weight-500 font-size-20-auto-rp",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.orderDetail.currency) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-auto" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto cj-color-black-primary font-weight-500 font-size-20-auto-rp",
                                  attrs: {
                                    "data-cy": _vm.dataCyCustomPriceTotalPayment
                                      ? `price-total-payment-${_vm.dataCyCustomPriceTotalPayment}`
                                      : "price-total-payment",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.orderDetail.promoAmount
                                            ? _vm.orderDetail.rawAmount -
                                                _vm.orderDetail.promoAmount
                                            : _vm.orderDetail.rawAmount,
                                          _vm.orderDetail.currency
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "border-grey-tertiary-top-1 pt-3 mt-2" },
                      [
                        _vm.orderDetail.pgAmountPaid &&
                        (_vm.localPayload.promoPayMethod ==
                          _vm.promoPayMethod.ALL ||
                          _vm.localPayload.promoPayMethod == null)
                          ? _c("div", { staticClass: "d-flex flex-wrap" }, [
                              _c("div", { staticClass: "flex-grow-1 pb-2" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "d-flex",
                                    attrs: { for: "balance-auction-guarantee" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-radio-button d-flex",
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            "data-cy": "pay-type-bank",
                                            type: "radio",
                                            id: `balance-auction-guarantee`,
                                            disabled: true,
                                          },
                                          domProps: {
                                            checked: true,
                                            value:
                                              _vm.PayType
                                                .BALANCE_AUCTION_GUARANTEE,
                                          },
                                          on: { click: _vm.handlePayTypeClick },
                                        }),
                                        _vm._v(" "),
                                        _c("label", {
                                          staticClass: "px-4 py-0",
                                          attrs: {
                                            for: `balance-auction-guarantee`,
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {}, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-roboto cj-color-black-primary font-weight-500 font-size-18-title",
                                        },
                                        [_vm._v("Project Guarantee")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-roboto cj-color-black-primary font-weight-400",
                                          class: !_vm.isMobile
                                            ? "font-size-16-auto"
                                            : "font-size-16-auto",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.orderDetail.currency) +
                                              "\n                    " +
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  _vm.orderDetail.pgAmountPaid,
                                                  _vm.orderDetail.currency
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex col-5 col-sm-4 col-md-4 col-lg-4 ml-auto align-items-center px-0 pb-2",
                                },
                                [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.orderDetail.currency) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp mx-1",
                                      },
                                      [_vm._v("(-)")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-auto" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.orderDetail.pgAmountPaid,
                                                _vm.orderDetail.currency
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex flex-wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex col-7 col-sm-8 col-md-8 col-lg-8 p-0 pb-2",
                            },
                            [
                              _vm.localPayload.promoPayMethod ==
                                _vm.promoPayMethod.ALL ||
                              _vm.localPayload.promoPayMethod == null
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { for: "refund-balance" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-radio-button d-flex",
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "radio",
                                              id: `refund-balance`,
                                              "data-cy":
                                                "pay-type-refund-balance",
                                              "data-gtm":
                                                "pay-type-cb-refund-clickable",
                                              disabled:
                                                !_vm.orderDetail.refundAmount ||
                                                _vm.orderDetail.refundAmount ==
                                                  0,
                                            },
                                            domProps: {
                                              checked: _vm.checkedBalance(
                                                _vm.PayType.BALANCE_REFUND
                                              ),
                                              value: _vm.PayType.BALANCE_REFUND,
                                            },
                                            on: {
                                              click: _vm.handlePayTypeClick,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("label", {
                                            staticClass: "px-4 py-0",
                                            attrs: { for: `refund-balance` },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", {}, [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-roboto cj-color-black-primary font-weight-500 font-size-18-title",
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "cbgwApp.cbComponent.cFromPayment.refundBalance"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-roboto cj-color-black-primary font-weight-400",
                                            class: !_vm.isMobile
                                              ? "font-size-16-auto"
                                              : "font-size-16-auto",
                                            attrs: {
                                              "data-cy": "saldo-refund-balance",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.orderDetail.currency
                                                ) +
                                                "\n                    " +
                                                _vm._s(
                                                  _vm._f("formatCurrency")(
                                                    _vm.orderDetail
                                                      .refundAmount,
                                                    _vm.orderDetail.currency
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.localPayload.promoPayMethod ==
                            _vm.promoPayMethod.ALL ||
                          _vm.localPayload.promoPayMethod == null
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex col-5 col-sm-4 col-md-4 col-lg-4 ml-auto align-items-center px-0 pb-2",
                                },
                                [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.orderDetail.currency) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp mx-1",
                                      },
                                      [_vm._v("(-)")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-auto" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                        attrs: {
                                          "data-cy": "total-refund-balance",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.orderDetail
                                                  .refundAmountPaid,
                                                _vm.orderDetail.currency
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-flex flex-wrap" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-6 col-sm-8 col-md-8 col-lg-8 p-0",
                            },
                            [
                              _vm.localPayload.promoPayMethod ==
                                _vm.promoPayMethod.ALL ||
                              _vm.localPayload.promoPayMethod == null ||
                              _vm.localPayload.promoPayMethod ==
                                _vm.promoPayMethod.BALANCE
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "mr-1 mb-2",
                                      class: _vm.isMobile
                                        ? "flex-col"
                                        : "d-flex",
                                      attrs: {
                                        "data-cy": "pay-type-cb-balance-label",
                                        for: "cb-balance",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-radio-button d-flex",
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "radio",
                                              id: `cb-balance`,
                                              "data-cy": "pay-type-cb-balance",
                                              "data-gtm":
                                                "pay-type-cb-balance-clickable",
                                              disabled:
                                                !_vm.orderDetail
                                                  .balanceAmount ||
                                                _vm.orderDetail.balanceAmount ==
                                                  0,
                                            },
                                            domProps: {
                                              checked: _vm.checkedBalance(
                                                _vm.PayType.BALANCE
                                              ),
                                              value: _vm.PayType.BALANCE,
                                            },
                                            on: {
                                              click: _vm.handlePayTypeClick,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("label", {
                                            staticClass: "px-4 py-0",
                                            attrs: {
                                              for: `cb-balance`,
                                              "data-cy": "label-cb-balance",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-col" },
                                            [
                                              _c("p", {
                                                staticClass:
                                                  "font-roboto cj-color-black-primary font-weight-500 font-size-18-title",
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "cbgwApp.cbComponent.cFromPayment.cbBalance"
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "font-roboto cj-color-black-primary font-weight-400 font-size-16-auto",
                                                  attrs: {
                                                    "data-cy":
                                                      "saldo-cb-balance",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.orderDetail.currency
                                                      ) +
                                                      "\n                      " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatCurrency"
                                                        )(
                                                          _vm.orderDetail
                                                            .balanceAmount,
                                                          _vm.orderDetail
                                                            .currency
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.isMobile
                                        ? _c(
                                            "div",
                                            {
                                              class: !_vm.isMobile
                                                ? "d-flex align-items-center"
                                                : "",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "d-flex",
                                                  class: !_vm.isMobile
                                                    ? "ml-2"
                                                    : "ml-5 mt-2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mr-2 mb-2",
                                                    },
                                                    [
                                                      _c(
                                                        "c-button",
                                                        {
                                                          staticClass:
                                                            "font-weight-500 font-size-16-auto",
                                                          attrs: {
                                                            "data-cy":
                                                              "button-topup",
                                                            dataGTMCustom:
                                                              "topup",
                                                            typeStyle:
                                                              "secondary",
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toTopUp.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        Top-up\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _c(
                                                        "c-button",
                                                        {
                                                          staticClass:
                                                            "font-weight-500 font-size-16-auto",
                                                          attrs: {
                                                            "data-cy":
                                                              "btn-refresh",
                                                            dataGTMCustom:
                                                              "refresh-balance",
                                                            typeStyle:
                                                              "secondary",
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.checkOrderDetail.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "cj-color-black-primary",
                                                              attrs: {
                                                                icon: "sync",
                                                                spin: _vm.isFetchingOrderDetail,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.localPayload.promoPayMethod ==
                            _vm.promoPayMethod.ALL ||
                          _vm.localPayload.promoPayMethod == null ||
                          _vm.localPayload.promoPayMethod ==
                            _vm.promoPayMethod.BALANCE
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex col-5 col-sm-4 col-md-4 col-lg-4 ml-auto align-items-center px-0 pb-2",
                                },
                                [
                                  _c("div", { staticClass: "text-nowrap" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.orderDetail.currency) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp mx-1",
                                      },
                                      [_vm._v("(-)")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml-auto" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-roboto cj-color-black-primary font-weight-400 font-size-20-auto-rp",
                                        attrs: {
                                          "data-cy": "total-cb-balance",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.orderDetail
                                                  .balanceAmountPaid,
                                                _vm.orderDetail.currency
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.isMobile
                          ? _c(
                              "div",
                              {
                                class: !_vm.isMobile
                                  ? "d-flex align-items-center"
                                  : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex",
                                    class: !_vm.isMobile ? "ml-2" : "ml-5 my-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "mr-2 mb-2" },
                                      [
                                        _c(
                                          "c-button",
                                          {
                                            staticClass:
                                              "font-weight-500 font-size-16-auto",
                                            attrs: {
                                              "data-cy": "button-topup",
                                              typeStyle: "secondary",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.toTopUp.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Top-up\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "mb-2" },
                                      [
                                        _c(
                                          "c-button",
                                          {
                                            staticClass:
                                              "font-weight-500 font-size-16-auto",
                                            attrs: {
                                              "data-cy": "btn-refresh",
                                              typeStyle: "secondary",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.checkOrderDetail.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass:
                                                "cj-color-black-primary",
                                              attrs: {
                                                icon: "sync",
                                                spin: _vm.isFetchingOrderDetail,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "wrap-border-grey-tertiary border-radius-26",
                      },
                      [
                        this.localPayload.payMethodType !==
                          _vm.PayMethodType.TOP_UP_DETAIL &&
                        this.localPayload.payMethodType !==
                          _vm.PayMethodType.ORDER_AUCTION_DETAIL &&
                        this.localPayload.payMethodType !==
                          _vm.PayMethodType.ORDER_AUCTION &&
                        this.localPayload.payMethodType !==
                          _vm.PayMethodType.TOP_UP &&
                        _vm.orderDetail.totalAmountMustPaid >=
                          _vm.vaRemainingMinimum &&
                        _vm.orderDetail.totalAmountMustPaid >=
                          _vm.ccRemainingMinimum &&
                        (_vm.localPayload.promoPayMethod ==
                          _vm.promoPayMethod.CC ||
                          _vm.localPayload.promoPayMethod ==
                            _vm.promoPayMethod.VA ||
                          _vm.localPayload.promoPayMethod ==
                            _vm.promoPayMethod.ALL ||
                          _vm.localPayload.promoPayMethod == null)
                          ? _c(
                              "div",
                              {
                                staticClass: "d-flex w-100 p-3",
                                attrs: { "data-cy": "header-payment-option" },
                                on: {
                                  click: function ($event) {
                                    _vm.visibleOptionPayment =
                                      !_vm.visibleOptionPayment
                                  },
                                },
                              },
                              [
                                _c("p", {
                                  staticClass:
                                    "font-roboto cj-color-black-primary font-weight-500 font-size-16-auto my-auto",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "cbgwApp.accountBalance.selectPaymentOption"
                                      )
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("icon", {
                                  staticClass:
                                    "cj-fill-black-primary my-auto ml-auto",
                                  attrs: {
                                    name: "ic_down",
                                    width: "1rem",
                                    height: "1rem",
                                    rotate: "270",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              accordion: "bank-transfer",
                              role: "tabpanel",
                            },
                            model: {
                              value: _vm.visibleOptionPayment,
                              callback: function ($$v) {
                                _vm.visibleOptionPayment = $$v
                              },
                              expression: "visibleOptionPayment",
                            },
                          },
                          [
                            _vm.orderDetail.totalAmountMustPaid >=
                            _vm.vaRemainingMinimum
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      "border-grey-tertiary-top-1":
                                        this.localPayload.payMethodType !==
                                          _vm.PayMethodType.TOP_UP_DETAIL &&
                                        this.localPayload.payMethodType !==
                                          _vm.PayMethodType.TOP_UP,
                                    },
                                  },
                                  [
                                    (_vm.localPayload.promoPayMethod ==
                                      _vm.promoPayMethod.VA ||
                                      _vm.localPayload.promoPayMethod ==
                                        _vm.promoPayMethod.ALL ||
                                      _vm.localPayload.promoPayMethod ==
                                        null) &&
                                    _vm.orderDetail.totalAmountMustPaid >=
                                      _vm.vaRemainingMinimum
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "cursor-pointer d-flex justify-content-between p-3",
                                            attrs: {
                                              "data-cy": "header-option-va",
                                              "data-gtm":
                                                "header-option-va-clickable",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleVisibleOptionVA()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "my-auto font-roboto cj-color-black-primary font-weight-300 font-size-16-auto",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  Bank Transfer - Virtual Account\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "my-auto vac-d-show-web",
                                              },
                                              _vm._l(
                                                _vm.getPayOptionVa,
                                                function (payOptionVA, index) {
                                                  return _c("img", {
                                                    key: index,
                                                    staticClass: "ic-bank mr-3",
                                                    attrs: {
                                                      "data-cy":
                                                        "pay-option-va",
                                                      src: `@/../content/images/bank/${payOptionVA.code.toLowerCase()}.svg`,
                                                      alt: "card",
                                                    },
                                                  })
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          accordion: "cb-bank-transfer",
                                          id: "pay-method-va",
                                          "data-cy": "pay-method-va",
                                        },
                                        model: {
                                          value: _vm.visibleOptionVA,
                                          callback: function ($$v) {
                                            _vm.visibleOptionVA = $$v
                                          },
                                          expression: "visibleOptionVA",
                                        },
                                      },
                                      _vm._l(
                                        _vm.getPayOptionVa,
                                        function (payOptionVA, index) {
                                          return _c(
                                            "label",
                                            {
                                              key: index,
                                              staticClass:
                                                "m-0 d-flex px-4 w-100 cursor-pointer border-grey-tertiary-top-1",
                                              attrs: {
                                                "data-cy":
                                                  "payment-option-label",
                                                "data-gtm": `payment-option-${payOptionVA.code}-clickable`,
                                                for: `va-${index}`,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "custom-radio-button my-3 d-flex",
                                                },
                                                [
                                                  _c("input", {
                                                    attrs: {
                                                      "data-cy":
                                                        "payment-option",
                                                      type: "radio",
                                                      id: `va-${index}`,
                                                      name: "choose_payment_option",
                                                    },
                                                    domProps: {
                                                      checked:
                                                        _vm.localPayload
                                                          .bankCode ==
                                                          payOptionVA.code &&
                                                        _vm.getPayTypeIncludes(
                                                          _vm.PayType.VA
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleClickVA(
                                                          payOptionVA.code
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", {
                                                    staticClass: "px-4 py-0",
                                                    attrs: {
                                                      for: `va-${index}`,
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("img", {
                                                staticClass: "ic-bank my-auto",
                                                attrs: {
                                                  "data-cy": "bank-option",
                                                  src: `@/../content/images/bank/${payOptionVA.code.toLowerCase()}.svg`,
                                                  alt: "card",
                                                },
                                              }),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.localPayload.payMethodType !=
                              _vm.PayMethodType.TOP_UP &&
                            _vm.localPayload.payMethodType !=
                              _vm.PayMethodType.TOP_UP_DETAIL &&
                            _vm.orderDetail.totalAmountMustPaid >=
                              _vm.ccRemainingMinimum &&
                            _vm.orderDetail.totalAmountMustPaid <=
                              _vm.ccRemainingMaximal &&
                            (_vm.localPayload.promoPayMethod ==
                              _vm.promoPayMethod.CC ||
                              _vm.localPayload.promoPayMethod ==
                                _vm.promoPayMethod.ALL ||
                              _vm.localPayload.promoPayMethod == null)
                              ? _c(
                                  "div",
                                  { staticClass: "border-grey-tertiary-top-1" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cursor-pointer d-flex justify-content-between p-3",
                                        attrs: {
                                          "data-cy": "header-option-credit",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleVisibleOptionCC()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "my-auto font-roboto cj-color-black-primary font-weight-300 font-size-16-auto",
                                          },
                                          [_vm._v("Credit Card")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          accordion: "cb-bank-transfer",
                                        },
                                        model: {
                                          value: _vm.visibleOptionCC,
                                          callback: function ($$v) {
                                            _vm.visibleOptionCC = $$v
                                          },
                                          expression: "visibleOptionCC",
                                        },
                                      },
                                      [
                                        _vm.cbBankAccounts &&
                                        _vm.cbBankAccounts.length > 0
                                          ? _c("hr", { staticClass: "p-0 m-0" })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.cbBankAccounts,
                                          function (cbBankAccount, index) {
                                            return _c(
                                              "div",
                                              { key: index },
                                              [
                                                _c("c-item-fp-bank-account", {
                                                  attrs: {
                                                    cbBankAccount:
                                                      cbBankAccount,
                                                    isChecked:
                                                      _vm.selectedBankAccountId ==
                                                        cbBankAccount.id &&
                                                      _vm.getPayTypeIncludes(
                                                        _vm.PayType.CC
                                                      ),
                                                    selectedBankAccountId:
                                                      _vm.selectedBankAccountId,
                                                  },
                                                  on: {
                                                    changeValue:
                                                      _vm.changeValue,
                                                    changeFunction:
                                                      _vm.changeFunction,
                                                    getVariable:
                                                      _vm.getVariable,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c("hr", { staticClass: "p-0 m-0" }),
                                        _vm._v(" "),
                                        (_vm.cbBankAccounts &&
                                          _vm.cbBankAccounts.length < 5) ||
                                        !_vm.cbBankAccounts
                                          ? _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "d-flex px-3 m-0 w-100 cursor-pointer",
                                                attrs: {
                                                  for: "cc-new",
                                                  "data-cy": "add-label-cc-new",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "custom-radio-button my-3",
                                                  },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "radio",
                                                        id: `cc-new`,
                                                        name: "choose_payment_option",
                                                      },
                                                      domProps: {
                                                        checked:
                                                          _vm.selectedBankAccountId ==
                                                            "NEWCC" &&
                                                          _vm.getPayTypeIncludes(
                                                            _vm.PayType.CC
                                                          ),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleClickCC(
                                                            "NEWCC"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("label", {
                                                      staticClass: "px-4 py-0",
                                                      attrs: { for: `cc-new` },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("p", {
                                                  staticClass:
                                                    "font-roboto cj-color-black-primary font-size-16-auto font-weight-500 my-auto",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "cbgwApp.accountBalance.addNewCreditCard"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "b-collapse",
                                          {
                                            attrs: {
                                              id: "collapse-diff-card",
                                              visible:
                                                _vm.selectedBankAccountId ==
                                                "NEWCC",
                                            },
                                          },
                                          [
                                            _c("hr", {
                                              staticClass: "p-0 m-0",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "p-3",
                                                attrs: {
                                                  "data-cy": "form-add-cc-new",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pb-2" },
                                                  [
                                                    _c("c-fp-information", {
                                                      staticClass: "mb-2",
                                                      attrs: {
                                                        infoTitle:
                                                          "Card Number",
                                                        isShowInfoDetail: true,
                                                        infoDetail:
                                                          "We don't save your CVV. The Collar Bear system is PCI DSS certified for user safety assurance",
                                                        isShowReadMore: false,
                                                      },
                                                      on: {
                                                        toLink:
                                                          _vm.toHelpCenter,
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.newCbBankAccount
                                                              .accountNumber,
                                                          expression:
                                                            "newCbBankAccount.accountNumber",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "cj-field-input",
                                                      class: `${
                                                        _vm.selectedBankAccountId ==
                                                          "NEWCC" &&
                                                        _vm.newCbBankAccount
                                                          .accountNumber
                                                          ? _vm.validationCC(
                                                              "accountNumber"
                                                            )
                                                            ? "invalid"
                                                            : "valid"
                                                          : ""
                                                      }`,
                                                      attrs: {
                                                        type: "text",
                                                        "data-cy":
                                                          "input-account-number",
                                                        id: "formControlInputAccountNumber",
                                                        placeholder:
                                                          "Account Number",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.newCbBankAccount
                                                            .accountNumber,
                                                      },
                                                      on: {
                                                        paste: _vm.handlePaste,
                                                        input: [
                                                          function ($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.newCbBankAccount,
                                                              "accountNumber",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                          function ($event) {
                                                            _vm.newCbBankAccount.accountNumber =
                                                              _vm.inputCardNumber(
                                                                $event
                                                              )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "row pb-2",
                                                    staticStyle: {
                                                      "margin-left": "-0.5rem",
                                                      "margin-right": "-0.5rem",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-sm-6 px-2 expiry-date-container",
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "font-size-16-auto font-roboto cj-color-black-primary font-weight-400 mb-2",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Expiry Date"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .newCbBankAccount
                                                                  .expiryDateLabel,
                                                              expression:
                                                                "newCbBankAccount.expiryDateLabel",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "cj-field-input",
                                                          class: `${
                                                            _vm.selectedBankAccountId ==
                                                              "NEWCC" &&
                                                            _vm.newCbBankAccount
                                                              .expiryDateLabel
                                                              ? _vm.validationCC(
                                                                  "expiryDateLabel"
                                                                ) ||
                                                                _vm.getStatusExpiredLabel(
                                                                  _vm.newCbBankAccount
                                                                )
                                                                ? "invalid"
                                                                : "valid"
                                                              : ""
                                                          }`,
                                                          attrs: {
                                                            "data-cy":
                                                              "input-expiry-date",
                                                            maxlength: "5",
                                                            placeholder:
                                                              "MM/YY",
                                                            type: "text",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm
                                                                .newCbBankAccount
                                                                .expiryDateLabel,
                                                          },
                                                          on: {
                                                            keyup: function (
                                                              $event
                                                            ) {
                                                              _vm.newCbBankAccount.expiryDateLabel =
                                                                _vm.formatExpiredDate(
                                                                  $event
                                                                )
                                                            },
                                                            paste:
                                                              _vm.handlePaste,
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                _vm.newCbBankAccount,
                                                                "expiryDateLabel",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _vm.getStatusExpiredLabel(
                                                          _vm.newCbBankAccount
                                                        )
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "font-roboto font-weight-500 font-size-14 cj-color-status-error",
                                                                attrs: {
                                                                  "data-cy":
                                                                    "exp-date-validation",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          Expired\n                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-12 col-sm-6 px-2",
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "font-size-16-auto font-roboto cj-color-black-primary font-weight-400 mb-2",
                                                          },
                                                          [_vm._v("CVV/CVC")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                          },
                                                          [
                                                            _c(
                                                              "c-input-number",
                                                              {
                                                                class: `${
                                                                  _vm.selectedBankAccountId ==
                                                                    "NEWCC" &&
                                                                  _vm
                                                                    .newCbBankAccount
                                                                    .cvc
                                                                    ? _vm.validationCC(
                                                                        "cvc"
                                                                      )
                                                                      ? "invalid"
                                                                      : "valid"
                                                                    : ""
                                                                }`,
                                                                attrs: {
                                                                  "data-cy":
                                                                    "input-CVC",
                                                                  placeholder:
                                                                    "Example : 123",
                                                                  maxlength:
                                                                    "3",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .newCbBankAccount
                                                                      .cvc,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.newCbBankAccount,
                                                                        "cvc",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "newCbBankAccount.cvc",
                                                                },
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c("img", {
                                                              staticClass:
                                                                "my-auto ml-2",
                                                              staticStyle: {
                                                                width:
                                                                  "2.625rem",
                                                              },
                                                              attrs: {
                                                                src: require("@/../content/images/cvv.svg"),
                                                                alt: "cvv",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "pb-2 w-100" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "font-size-16-auto font-roboto cj-color-black-primary font-weight-400 mb-2",
                                                      },
                                                      [_vm._v("Card Holder")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.newCbBankAccount
                                                              .accountHolderName,
                                                          expression:
                                                            "newCbBankAccount.accountHolderName",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "mb-1 cj-field-input",
                                                      class: `${
                                                        _vm.selectedBankAccountId ==
                                                          "NEWCC" &&
                                                        _vm.newCbBankAccount
                                                          .accountHolderName
                                                          ? _vm.validationCC(
                                                              "accountHolderName"
                                                            )
                                                            ? "invalid"
                                                            : "valid"
                                                          : ""
                                                      }`,
                                                      attrs: {
                                                        "data-cy":
                                                          "input-card-holder",
                                                        type: "text",
                                                        placeholder: "Name",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.newCbBankAccount
                                                            .accountHolderName,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.newCbBankAccount,
                                                            "accountHolderName",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "custom-control custom-checkbox cb-custom-checkbox my-auto cursor-pointer",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.newCbBankAccount
                                                              .isSave,
                                                          expression:
                                                            "newCbBankAccount.isSave",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-control-input",
                                                      attrs: {
                                                        type: "checkbox",
                                                        id: `c-form-payment-save-card`,
                                                        "data-cy":
                                                          "save-this-card",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          _vm.newCbBankAccount
                                                            .isSave
                                                        )
                                                          ? _vm._i(
                                                              _vm
                                                                .newCbBankAccount
                                                                .isSave,
                                                              null
                                                            ) > -1
                                                          : _vm.newCbBankAccount
                                                              .isSave,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm
                                                                .newCbBankAccount
                                                                .isSave,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  _vm.newCbBankAccount,
                                                                  "isSave",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  _vm.newCbBankAccount,
                                                                  "isSave",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              _vm.newCbBankAccount,
                                                              "isSave",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "custom-control-label",
                                                        attrs: {
                                                          for: `c-form-payment-save-card`,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "cj-color-black-primary font-weight-400 font-size-14 font-roboto",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Save this card"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex mt-2" }, [
                      (_vm.localPayload.payMethodType ==
                        _vm.PayMethodType.ORDER_PAW_DETAIL ||
                        _vm.localPayload.payMethodType ==
                          _vm.PayMethodType.ORDER_PAW) &&
                      _vm.orderDetail.totalAmountMustPaid > 0
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-700 font-size-36-auto ml-auto",
                              attrs: { "data-cy": "remaining-payment" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.orderDetail.currency) +
                                  "\n            " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.orderDetail.totalAmountMustPaid,
                                      _vm.orderDetail.currency
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isInsuficientBalance == true
                        ? _c("p", {
                            staticClass:
                              "font-roboto font-weight-500 font-size-14 cj-color-status-error ml-auto mt-3",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.accountBalance.insufficientBalance"
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.orderDetail.feeAdmin && _vm.orderDetail.feeAdmin > 0
                      ? _c("c-fp-information", {
                          staticClass: "pb-3 text-right",
                          attrs: {
                            infoTitle: _vm.$t(
                              "cbgwApp.accountBalance.transactionFee"
                            ),
                            infoPrice: `${
                              _vm.orderDetail.currency
                            } ${_vm.$options.filters.formatCurrency(
                              _vm.orderDetail.feeAdmin
                            )}`,
                            isShowInfoDetail: true,
                            infoDetail: _vm.$t(
                              "cbgwApp.accountBalance.descTransactionFee"
                            ),
                            isShowReadMore: true,
                          },
                          on: { toLink: _vm.toHelpCenter },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          this.localPayload.payMethodType != _vm.PayMethodType.DISBURSEMENT &&
          this.localPayload.payMethodType !=
            _vm.PayMethodType.DISBURSEMENT_DETAIL &&
          this.localPayload.payMethodType !=
            _vm.PayMethodType.DISBURSEMENT_INCOME &&
          this.localPayload.payMethodType !=
            _vm.PayMethodType.DISBURSEMENT_INCOME_DETAIL &&
          this.localPayload.payMethodType != _vm.PayMethodType.REFUND_REQUEST &&
          this.localPayload.payMethodType !=
            _vm.PayMethodType.REFUND_REQUEST_DETAIL &&
          this.localPayload.payMethodType !=
            _vm.PayMethodType.ORDER_AUCTION_DETAIL &&
          this.localPayload.payMethodType != _vm.PayMethodType.ORDER_AUCTION &&
          this.localPayload.payMethodType !=
            _vm.PayMethodType.ORDER_PAW_DETAIL &&
          this.localPayload.payMethodType != _vm.PayMethodType.ORDER_PAW
            ? _c("div", { staticClass: "mt-3 mb-2" }, [
                _c(
                  "div",
                  { staticClass: "wrap-border-grey-tertiary border-radius-26" },
                  [
                    this.localPayload.payMethodType !==
                      _vm.PayMethodType.TOP_UP_DETAIL &&
                    this.localPayload.payMethodType !==
                      _vm.PayMethodType.TOP_UP &&
                    (_vm.localPayload.promoPayMethod == _vm.promoPayMethod.CC ||
                      _vm.localPayload.promoPayMethod ==
                        _vm.promoPayMethod.VA ||
                      _vm.localPayload.promoPayMethod ==
                        _vm.promoPayMethod.ALL ||
                      _vm.localPayload.promoPayMethod == null)
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex w-100 p-3",
                            attrs: { "data-cy": "header-payment-option" },
                            on: {
                              click: function ($event) {
                                _vm.visibleOptionPayment =
                                  !_vm.visibleOptionPayment
                              },
                            },
                          },
                          [
                            _c("p", {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-500 font-size-16-auto my-auto",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "cbgwApp.accountBalance.selectPaymentOption"
                                  )
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c("icon", {
                              staticClass:
                                "cj-fill-black-primary my-auto ml-auto",
                              attrs: {
                                name: "ic_down",
                                width: "1rem",
                                height: "1rem",
                                rotate: "270",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-collapse",
                      {
                        attrs: { accordion: "bank-transfer", role: "tabpanel" },
                        model: {
                          value: _vm.visibleOptionPayment,
                          callback: function ($$v) {
                            _vm.visibleOptionPayment = $$v
                          },
                          expression: "visibleOptionPayment",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "border-grey-tertiary-top-1":
                                this.localPayload.payMethodType !==
                                  _vm.PayMethodType.TOP_UP_DETAIL &&
                                this.localPayload.payMethodType !==
                                  _vm.PayMethodType.TOP_UP,
                            },
                          },
                          [
                            _vm.localPayload.promoPayMethod ==
                              _vm.promoPayMethod.VA ||
                            _vm.localPayload.promoPayMethod ==
                              _vm.promoPayMethod.ALL ||
                            _vm.localPayload.promoPayMethod == null
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer d-flex justify-content-between p-3",
                                    attrs: {
                                      "data-cy": "header-option-va",
                                      "data-gtm": "header-option-va-clickable",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleVisibleOptionVA()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "my-auto font-roboto cj-color-black-primary font-weight-300 font-size-16-auto",
                                      },
                                      [
                                        _vm._v(
                                          "Bank Transfer - Virtual Account"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("icon", {
                                      staticClass:
                                        "cj-fill-black-primary my-auto ml-auto",
                                      class: {
                                        ic_arrow_dropdown_up:
                                          _vm.visibleOptionVA,
                                        "d-block": _vm.isMobile,
                                        "d-none": !_vm.isMobile,
                                      },
                                      attrs: {
                                        name: "ic_down",
                                        width: "1rem",
                                        height: "1rem",
                                        rotate: "270",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "my-auto vac-d-show-web" },
                                      _vm._l(
                                        _vm.getPayOptionVa,
                                        function (payOptionVA, index) {
                                          return _c("img", {
                                            key: index,
                                            staticClass: "ic-bank mr-3",
                                            attrs: {
                                              "data-cy": "pay-option-va",
                                              src: `@/../content/images/bank/${payOptionVA.code.toLowerCase()}.svg`,
                                              alt: "card",
                                            },
                                          })
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              {
                                attrs: {
                                  accordion: "cb-bank-transfer",
                                  id: "pay-method-va",
                                  "data-cy": "pay-method-va",
                                },
                                model: {
                                  value: _vm.visibleOptionVA,
                                  callback: function ($$v) {
                                    _vm.visibleOptionVA = $$v
                                  },
                                  expression: "visibleOptionVA",
                                },
                              },
                              _vm._l(
                                _vm.getPayOptionVa,
                                function (payOptionVA, index) {
                                  return _c(
                                    "label",
                                    {
                                      key: index,
                                      staticClass:
                                        "m-0 d-flex px-4 w-100 cursor-pointer border-grey-tertiary-top-1",
                                      attrs: {
                                        "data-cy": "payment-option-label",
                                        "data-gtm": `payment-option-${payOptionVA.code}-clickable`,
                                        for: `va-${index}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-radio-button my-3 d-flex",
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              "data-cy": "payment-option",
                                              type: "radio",
                                              id: `va-${index}`,
                                              name: "choose_payment_option",
                                            },
                                            domProps: {
                                              checked:
                                                _vm.localPayload.bankCode ==
                                                  payOptionVA.code &&
                                                _vm.getPayTypeIncludes(
                                                  _vm.PayType.VA
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickVA(
                                                  payOptionVA.code
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("label", {
                                            staticClass: "px-4 py-0",
                                            attrs: { for: `va-${index}` },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass: "ic-bank my-auto",
                                        attrs: {
                                          "data-cy": "bank-option",
                                          src: `@/../content/images/bank/${payOptionVA.code.toLowerCase()}.svg`,
                                          alt: "card",
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.localPayload.payMethodType !=
                          _vm.PayMethodType.TOP_UP &&
                        _vm.localPayload.payMethodType !=
                          _vm.PayMethodType.TOP_UP_DETAIL &&
                        (_vm.localPayload.promoPayMethod ==
                          _vm.promoPayMethod.CC ||
                          _vm.localPayload.promoPayMethod ==
                            _vm.promoPayMethod.ALL ||
                          _vm.localPayload.promoPayMethod == null)
                          ? _c(
                              "div",
                              { staticClass: "border-grey-tertiary-top-1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cursor-pointer d-flex justify-content-between p-3",
                                    attrs: {
                                      "data-cy": "header-option-credit",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleVisibleOptionCC()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "my-auto font-roboto cj-color-black-primary font-weight-300 font-size-16-auto",
                                      },
                                      [_vm._v("Credit Card")]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-collapse",
                                  {
                                    attrs: { accordion: "cb-bank-transfer" },
                                    model: {
                                      value: _vm.visibleOptionCC,
                                      callback: function ($$v) {
                                        _vm.visibleOptionCC = $$v
                                      },
                                      expression: "visibleOptionCC",
                                    },
                                  },
                                  [
                                    _vm.cbBankAccounts &&
                                    _vm.cbBankAccounts.length > 0
                                      ? _c("hr", { staticClass: "p-0 m-0" })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.cbBankAccounts,
                                      function (cbBankAccount, index) {
                                        return _c(
                                          "div",
                                          { key: index },
                                          [
                                            _c("c-item-fp-bank-account", {
                                              attrs: {
                                                cbBankAccount: cbBankAccount,
                                                isChecked:
                                                  _vm.selectedBankAccountId ==
                                                    cbBankAccount.id &&
                                                  _vm.getPayTypeIncludes(
                                                    _vm.PayType.CC
                                                  ),
                                                selectedBankAccountId:
                                                  _vm.selectedBankAccountId,
                                              },
                                              on: {
                                                changeValue: _vm.changeValue,
                                                changeFunction:
                                                  _vm.changeFunction,
                                                getVariable: _vm.getVariable,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c("hr", { staticClass: "p-0 m-0" }),
                                    _vm._v(" "),
                                    (_vm.cbBankAccounts &&
                                      _vm.cbBankAccounts.length < 5) ||
                                    !_vm.cbBankAccounts
                                      ? _c(
                                          "label",
                                          {
                                            staticClass:
                                              "d-flex px-3 m-0 w-100 cursor-pointer",
                                            attrs: {
                                              for: "cc-new",
                                              "data-cy": "add-label-cc-new",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-radio-button my-3",
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    type: "radio",
                                                    id: `cc-new`,
                                                    name: "choose_payment_option",
                                                  },
                                                  domProps: {
                                                    checked:
                                                      _vm.selectedBankAccountId ==
                                                        "NEWCC" &&
                                                      _vm.getPayTypeIncludes(
                                                        _vm.PayType.CC
                                                      ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleClickCC(
                                                        "NEWCC"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("label", {
                                                  staticClass: "px-4 py-0",
                                                  attrs: { for: `cc-new` },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("p", {
                                              staticClass:
                                                "font-roboto cj-color-black-primary font-size-16-auto font-weight-500 my-auto",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "cbgwApp.accountBalance.addNewCreditCard"
                                                  )
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "collapse-diff-card",
                                          visible:
                                            _vm.selectedBankAccountId ==
                                            "NEWCC",
                                        },
                                      },
                                      [
                                        _c("hr", { staticClass: "p-0 m-0" }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "p-3",
                                            attrs: {
                                              "data-cy": "form-add-cc-new",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ml-auto px-3 py-1 cj-bg-red-secondary",
                                                    staticStyle: {
                                                      "border-radius": "28px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "ml-auto font-roboto font-weight-400 font-size-12 cj-color-black-primary",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "cj-color-grey-secondary mr-1",
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.required"
                                                                  )
                                                                ),
                                                            },
                                                          },
                                                          [_vm._v("Required")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "pb-2" },
                                              [
                                                _c("c-fp-information", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    infoTitle: "Card Number",
                                                    isShowInfoDetail: true,
                                                    infoDetail:
                                                      "We don't save your CVV. The Collar Bear system is PCI DSS certified for user safety assurance",
                                                    isShowReadMore: false,
                                                  },
                                                  on: {
                                                    toLink: _vm.toHelpCenter,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.newCbBankAccount
                                                          .accountNumber,
                                                      expression:
                                                        "newCbBankAccount.accountNumber",
                                                    },
                                                  ],
                                                  staticClass: "cj-field-input",
                                                  class: `${
                                                    _vm.selectedBankAccountId ==
                                                      "NEWCC" &&
                                                    _vm.newCbBankAccount
                                                      .accountNumber
                                                      ? _vm.validationCC(
                                                          "accountNumber"
                                                        )
                                                        ? "invalid"
                                                        : "valid"
                                                      : ""
                                                  }`,
                                                  attrs: {
                                                    type: "text",
                                                    "data-cy":
                                                      "input-account-number",
                                                    id: "formControlInputAccountNumber",
                                                    placeholder:
                                                      "Account Number",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.newCbBankAccount
                                                        .accountNumber,
                                                  },
                                                  on: {
                                                    paste: _vm.handlePaste,
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.newCbBankAccount,
                                                          "accountNumber",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        _vm.newCbBankAccount.accountNumber =
                                                          _vm.inputCardNumber(
                                                            $event
                                                          )
                                                      },
                                                    ],
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "row pt-2",
                                                staticStyle: {
                                                  "margin-left": "-0.5rem",
                                                  "margin-right": "-0.5rem",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-12 col-sm-6 px-2 expiry-date-container",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "font-size-16-auto font-roboto cj-color-black-primary font-weight-400 mb-2",
                                                      },
                                                      [_vm._v("Expiry Date")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.newCbBankAccount
                                                              .expiryDateLabel,
                                                          expression:
                                                            "newCbBankAccount.expiryDateLabel",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "cj-field-input",
                                                      class: `${
                                                        _vm.selectedBankAccountId ==
                                                          "NEWCC" &&
                                                        _vm.newCbBankAccount
                                                          .expiryDateLabel
                                                          ? _vm.validationCC(
                                                              "expiryDateLabel"
                                                            ) ||
                                                            _vm.getStatusExpiredLabel(
                                                              _vm.newCbBankAccount
                                                            )
                                                            ? "invalid"
                                                            : "valid"
                                                          : ""
                                                      }`,
                                                      attrs: {
                                                        "data-cy":
                                                          "input-expiry-date",
                                                        maxlength: "5",
                                                        placeholder: "MM/YY",
                                                        type: "text",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.newCbBankAccount
                                                            .expiryDateLabel,
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          _vm.newCbBankAccount.expiryDateLabel =
                                                            _vm.formatExpiredDate(
                                                              $event
                                                            )
                                                        },
                                                        paste: _vm.handlePaste,
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            _vm.newCbBankAccount,
                                                            "expiryDateLabel",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.getStatusExpiredLabel(
                                                      _vm.newCbBankAccount
                                                    )
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "font-roboto font-weight-500 font-size-14 cj-color-status-error",
                                                            attrs: {
                                                              "data-cy":
                                                                "exp-date-validation",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        Expired\n                      "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-12 col-sm-6 px-2",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "font-size-16-auto font-roboto cj-color-black-primary font-weight-400 mb-2",
                                                      },
                                                      [_vm._v("CVV/CVC")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c("c-input-number", {
                                                          class: `${
                                                            _vm.selectedBankAccountId ==
                                                              "NEWCC" &&
                                                            _vm.newCbBankAccount
                                                              .cvc
                                                              ? _vm.validationCC(
                                                                  "cvc"
                                                                )
                                                                ? "invalid"
                                                                : "valid"
                                                              : ""
                                                          }`,
                                                          attrs: {
                                                            "data-cy":
                                                              "input-CVC",
                                                            placeholder:
                                                              "Example : 123",
                                                            maxlength: "3",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .newCbBankAccount
                                                                .cvc,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.newCbBankAccount,
                                                                "cvc",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "newCbBankAccount.cvc",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("img", {
                                                          staticClass:
                                                            "my-auto ml-2",
                                                          staticStyle: {
                                                            width: "2.625rem",
                                                          },
                                                          attrs: {
                                                            src: require("@/../content/images/cvv.svg"),
                                                            alt: "cvv",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "pb-2 w-100" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "font-size-16-auto font-roboto cj-color-black-primary font-weight-400 mb-2",
                                                  },
                                                  [_vm._v("Card Holder")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.newCbBankAccount
                                                          .accountHolderName,
                                                      expression:
                                                        "newCbBankAccount.accountHolderName",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "mb-1 cj-field-input",
                                                  class: `${
                                                    _vm.selectedBankAccountId ==
                                                      "NEWCC" &&
                                                    _vm.newCbBankAccount
                                                      .accountHolderName
                                                      ? _vm.validationCC(
                                                          "accountHolderName"
                                                        )
                                                        ? "invalid"
                                                        : "valid"
                                                      : ""
                                                  }`,
                                                  attrs: {
                                                    "data-cy":
                                                      "input-card-holder",
                                                    type: "text",
                                                    placeholder: "Name",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.newCbBankAccount
                                                        .accountHolderName,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.newCbBankAccount,
                                                        "accountHolderName",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-control custom-checkbox cb-custom-checkbox my-auto cursor-pointer",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.newCbBankAccount
                                                          .isSave,
                                                      expression:
                                                        "newCbBankAccount.isSave",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "custom-control-input",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: `c-form-payment-save-card`,
                                                    "data-cy": "save-this-card",
                                                    "data-gtm":
                                                      "save-this-card-clickable",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.newCbBankAccount
                                                        .isSave
                                                    )
                                                      ? _vm._i(
                                                          _vm.newCbBankAccount
                                                            .isSave,
                                                          null
                                                        ) > -1
                                                      : _vm.newCbBankAccount
                                                          .isSave,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.newCbBankAccount
                                                            .isSave,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.newCbBankAccount,
                                                              "isSave",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.newCbBankAccount,
                                                              "isSave",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.newCbBankAccount,
                                                          "isSave",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "custom-control-label",
                                                    attrs: {
                                                      for: `c-form-payment-save-card`,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "cj-color-black-primary font-weight-400 font-size-14 font-roboto",
                                                      },
                                                      [_vm._v("Save this card")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.localPayload.payMethodType == _vm.PayMethodType.TOP_UP ||
          _vm.localPayload.payMethodType == _vm.PayMethodType.TOP_UP_DETAIL
            ? [
                _vm.orderDetail.feeAdmin && _vm.orderDetail.feeAdmin > 0
                  ? _c("c-fp-information", {
                      staticClass: "mb-3 text-right",
                      attrs: {
                        infoTitle: _vm.$t(
                          "cbgwApp.accountBalance.transactionFee"
                        ),
                        infoPrice: `${
                          _vm.orderDetail.currency
                        } ${_vm.$options.filters.formatCurrency(
                          _vm.orderDetail.feeAdmin
                        )}`,
                        isShowInfoDetail: true,
                        infoDetail: _vm.$t(
                          "cbgwApp.accountBalance.feeTransactionDesc"
                        ),
                        isShowReadMore: true,
                      },
                      on: { toLink: _vm.toHelpCenter },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetail.totalAmountMustPaid
                  ? _c("c-fp-payment-Info", {
                      attrs: {
                        title: "You Need to Pay",
                        price:
                          _vm.orderDetail.currency +
                          " " +
                          _vm.$options.filters.formatCurrency(
                            _vm.orderDetail.totalAmountAfterFeeAdmin
                          ),
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.localPayload.payMethodType ==
            _vm.PayMethodType.ORDER_PAW_DETAIL ||
            _vm.localPayload.payMethodType == _vm.PayMethodType.ORDER_PAW ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.ORDER_AUCTION_DETAIL ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.ORDER_AUCTION) &&
          _vm.localPayload.payMethodType !=
            _vm.PayMethodType.ORDER_PAW_DETAIL &&
          _vm.localPayload.payMethodType != _vm.PayMethodType.ORDER_PAW &&
          !_vm.isBackTopUp
            ? [
                _c("div", { staticClass: "d-flex" }, [
                  (_vm.localPayload.payMethodType ==
                    _vm.PayMethodType.ORDER_PAW_DETAIL ||
                    _vm.localPayload.payMethodType ==
                      _vm.PayMethodType.ORDER_PAW) &&
                  _vm.orderDetail.totalAmountMustPaid > 0
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto cj-color-black-primary font-weight-700 font-size-36-auto ml-auto",
                          attrs: { "data-cy": "remaining-payment" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.orderDetail.currency) +
                              "\n          " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.orderDetail.totalAmountMustPaid,
                                  _vm.orderDetail.currency
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.orderDetail.feeAdmin && _vm.orderDetail.feeAdmin > 0
                  ? _c("c-fp-information", {
                      staticClass: "pb-3 text-right",
                      attrs: {
                        infoTitle: _vm.$t(
                          "cbgwApp.accountBalance.transactionFee"
                        ),
                        infoPrice: `${
                          _vm.orderDetail.currency
                        } ${_vm.$options.filters.formatCurrency(
                          _vm.orderDetail.feeAdmin
                        )}`,
                        isShowInfoDetail: true,
                        infoDetail: _vm.$t(
                          "cbgwApp.accountBalance.descTransactionFee"
                        ),
                        isShowReadMore: true,
                      },
                      on: { toLink: _vm.toHelpCenter },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.localPayload.payMethodType == _vm.PayMethodType.DISBURSEMENT ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.DISBURSEMENT_DETAIL ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.REFUND_REQUEST ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.REFUND_REQUEST_DETAIL ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.DISBURSEMENT_INCOME ||
            _vm.localPayload.payMethodType ==
              _vm.PayMethodType.DISBURSEMENT_INCOME_DETAIL) &&
          _vm.orderDetail.totalAmountAfterFeeAdmin
            ? [
                _c("c-fp-payment-Info", {
                  attrs: {
                    title: _vm.$t("cbgwApp.accountBalance.youWillReceive"),
                    price: `${
                      _vm.orderDetail.currency
                    } ${_vm.$options.filters.formatCurrency(
                      _vm.orderDetail.totalAmountAfterFeeAdmin
                    )}`,
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex btn-wrapper pt-3" },
            [
              _c(
                "c-button",
                {
                  staticClass:
                    "ml-auto my-auto font-roboto font-size-20-auto font-weight-500 wf-pay",
                  attrs: {
                    "data-cy": "btn-submit-payment",
                    dataGTMCustom: "submit-payment",
                    fullWidth: "",
                    disabled: _vm.submitDisabled,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.beforeSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.isSavingPayment
                    ? _c("font-awesome-icon", {
                        attrs: { icon: "sync", spin: _vm.isSavingPayment },
                      })
                    : _vm.payload.payMethodType == _vm.PayMethodType.TOP_UP ||
                      _vm.payload.payMethodType ==
                        _vm.PayMethodType.TOP_UP_DETAIL
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.accountBalance.btnContinue")
                          ),
                        },
                      })
                    : _vm.localPayload.payMethodType ==
                        _vm.PayMethodType.DISBURSEMENT ||
                      _vm.localPayload.payMethodType ==
                        _vm.PayMethodType.DISBURSEMENT_INCOME ||
                      _vm.localPayload.payMethodType ==
                        _vm.PayMethodType.DISBURSEMENT_INCOME_DETAIL ||
                      _vm.localPayload.payMethodType ==
                        _vm.PayMethodType.DISBURSEMENT_DETAIL ||
                      _vm.localPayload.payMethodType ==
                        _vm.PayMethodType.REFUND_REQUEST_DETAIL
                    ? _c("span", [_vm._v("\n          WITHDRAW\n        ")])
                    : _vm.localPayload.payMethodType ==
                      _vm.PayMethodType.REFUND_REQUEST
                    ? _c("span", [_vm._v(" REFUND REQUEST ")])
                    : _c("span", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbComponent.cFromPayment.continue"
                              )
                            ),
                          },
                        }),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.visiblePin
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "modal-payment-visible-pin",
                centered: "",
                "modal-class": "vac-custom-modal-b-modal",
                size: "md",
                "hide-footer": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center w-100 position-relative",
                          },
                          [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto text-center font-weight-700 cj-color-black-primary font-size-24",
                                },
                                [_vm._v("PIN")]
                              ),
                              _vm._v(" "),
                              _c("p", {
                                staticClass:
                                  "p-0 m-0 font-roboto cj-color-black-primary text-italic font-weight-500 mt-2 text-center font-size-18",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("cbgwApp.cbPayment.enterPin")
                                  ),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "position-absolute",
                                staticStyle: { right: "0" },
                                attrs: {
                                  type: "button",
                                  "data-cy": "close-input-pin",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelInputPin()
                                  },
                                },
                              },
                              [
                                _c("b-icon-x-circle-fill", {
                                  staticClass:
                                    "font-size-18 cj-color-grey-secondary",
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3397326060
              ),
              model: {
                value: _vm.visiblePin,
                callback: function ($$v) {
                  _vm.visiblePin = $$v
                },
                expression: "visiblePin",
              },
            },
            [
              _vm._v(" "),
              _c("c-input-pin", {
                attrs: { pinNotMatched: _vm.pinNotMatch },
                on: { save: _vm.checkPin },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-is-show-va",
          size: "lg",
          "hide-footer": "",
          "hide-header": "",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "modal-class": "vac-custom-modal-b-modal",
        },
        on: {
          hide: function ($event) {
            return _vm.cancel()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-complete-payment-va", {
                  attrs: { payDetailVA: _vm.payDetailVA },
                  on: {
                    changeValue: _vm.changeValue,
                    changeFunction: _vm.changeFunction,
                    getVariable: _vm.getVariable,
                    cancel: function ($event) {
                      return cancel()
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleVA,
          callback: function ($$v) {
            _vm.visibleVA = $$v
          },
          expression: "visibleVA",
        },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-c-card-promo-detail",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.isOpenPromoDetail,
            callback: function ($$v) {
              _vm.isOpenPromoDetail = $$v
            },
            expression: "isOpenPromoDetail",
          },
        },
        [_c("c-card-promo-detail", { attrs: { cbVwPromo: _vm.cbVwPromo } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "flex-grow-1 pb-2" }, [
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-weight-500 font-size-18-auto",
        },
        [_vm._v("Total Order")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }