export interface IProgressUpload {
  id?: number;
  title?: string | null;
  localUrl?: string | null;
  progress?: number | null;
  status?: string | null;
}

export class ProgressUpload implements IProgressUpload {
  constructor(
    public id?: number,
    public title?: string | null,
    public localUrl?: string | null,
    public progress?: number | null,
    public status?: string | null
  ) {}
}
