var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-register" },
    [
      _c("div", { staticClass: "d-flex w-100" }, [
        _c("div", { staticClass: "d-block ml-3 mb-3" }, [
          _c("p", {
            staticClass:
              "m-0 font-roboto font-weight-700 text-left cj-color-black-primary font-size-24",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.register.title")
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "position-absolute p-3 m-n3",
            staticStyle: { right: "0" },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.cancel()
              },
            },
          },
          [
            _c("b-icon-x-circle-fill", {
              staticClass: "font-size-18 cj-color-grey-secondary",
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
          [
            _c("div", { staticClass: "row no-gutters" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "ml-auto px-3 py-1 cj-bg-red-secondary",
                          staticStyle: { "border-radius": "28px" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "ml-auto font-roboto font-weight-400 font-size-12 cj-color-black-primary",
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "cj-color-grey-secondary mr-1" },
                                [_vm._v("*")]
                              ),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "cbgwApp.cbHome.cbHomeBeforeLogin.register.required"
                                    )
                                  ),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "mb-4 w-100 font-register" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.cbUserInfo.email.$model,
                            expression: "$v.cbUserInfo.email.$model",
                          },
                        ],
                        staticClass:
                          "font-size-12 p-0 py-2 cj-field-border-bottom-default cj-field-input",
                        class: `${
                          _vm.$v.cbUserInfo.email.$anyDirty
                            ? _vm.$v.cbUserInfo.email.$invalid ||
                              _vm.isExistEmail
                              ? "invalid"
                              : "valid"
                            : ""
                        }`,
                        attrs: {
                          "data-cy": "input-email-register",
                          name: "input-email-register",
                          type: "text",
                        },
                        domProps: { value: _vm.$v.cbUserInfo.email.$model },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.cbUserInfo.email,
                              "$model",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.isExistEmail
                        ? _c("div", [
                            _c("small", { staticClass: "font-validation" }, [
                              _c("span", {
                                staticClass:
                                  "text-danger cj-color-status-error",
                                attrs: { "data-cy": "text-exits" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      `cbgwApp.cbHome.cbHomeBeforeLogin.register.${_vm.valueStatusEmail}`
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _vm.valueStatusEmail != "DELETED"
                                ? _c(
                                    "u",
                                    {
                                      staticClass: "cj-color-red-primary hand",
                                      on: { click: _vm.openModalLogin },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.login"
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v("?\n                  "),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$v.cbUserInfo.email.$anyDirty &&
                      _vm.$v.cbUserInfo.email.$invalid
                        ? _c("div", [
                            !_vm.$v.cbUserInfo.email.email
                              ? _c("small", {
                                  staticClass:
                                    "font-validation cj-color-status-error",
                                  attrs: { "data-cy": "text-exits" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationMustEmail"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mb-4 w-100 font-register" }, [
                      _c("p", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbHome.cbHomeBeforeLogin.register.phoneNumber"
                              )
                            ),
                          },
                        }),
                        _c("span", { staticClass: "cj-color-grey-secondary" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "wrap-input-phone-number cj-field-border-bottom-default position-relative",
                          class: `${
                            _vm.$v.cbUserInfo.phone.$anyDirty ||
                            _vm.$v.cbUserInfo.phoneCode.$anyDirty
                              ? _vm.$v.cbUserInfo.phone.$invalid ||
                                _vm.$v.cbUserInfo.phoneCode.$invalid ||
                                _vm.isExistPhone
                                ? "invalid"
                                : "valid"
                              : ""
                          }`,
                          attrs: { "data-cy": "wrap-phone" },
                        },
                        [
                          _c("c-autocomplete-new", {
                            staticClass:
                              "font-roboto font-weight-400 font-size-12 cj-color-black-primary wrap-select cj-field-not-border",
                            attrs: {
                              items: _vm.cbLovPhoneCodes,
                              isNoRelative: true,
                              "item-text": "value",
                              "data-cy": "phone-code",
                              "data-cy-custom": "phone-code",
                              "item-value": "key",
                              "item-text-input": "key",
                              inputStyle: "pr-3 pl-0",
                            },
                            on: { input: _vm.handleCheckPhone },
                            model: {
                              value: _vm.$v.cbUserInfo.phoneCode.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.cbUserInfo.phoneCode,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.cbUserInfo.phoneCode.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.cbUserInfo.phone.$model,
                                expression: "$v.cbUserInfo.phone.$model",
                              },
                            ],
                            staticClass:
                              "font-size-12 cj-field-not-border cj-field-input",
                            attrs: {
                              type: "text",
                              "data-cy": "input-phone-register",
                              name: "input-phone-register",
                            },
                            domProps: { value: _vm.$v.cbUserInfo.phone.$model },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.cbUserInfo.phone,
                                  "$model",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isExistPhone
                        ? _c("div", [
                            _c(
                              "small",
                              {
                                staticClass: "font-validation",
                                attrs: { "data-cy": "font-validation" },
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "text-danger cj-color-status-error",
                                  attrs: { "data-cy": "font-validation-phone" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        `cbgwApp.cbCreateAccount.phone.${_vm.valueStatusPhone}`
                                      )
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.valueStatusPhone != "DELETED"
                                  ? _c(
                                      "u",
                                      {
                                        staticClass:
                                          "cj-color-red-primary hand",
                                        on: { click: _vm.openModalLogin },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "cbgwApp.cbHome.cbHomeBeforeLogin.register.login"
                                              )
                                            ),
                                          },
                                        }),
                                        _vm._v("?\n                  "),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ])
                        : _c("p", {
                            staticClass: "font-validation mt-2",
                            class: `${
                              _vm.$v.cbUserInfo.phone.$anyDirty
                                ? _vm.$v.cbUserInfo.phone.$invalid
                                  ? "cj-color-status-error"
                                  : "cj-color-status-success d-none"
                                : "d-none"
                            }`,
                            attrs: { "data-cy": "font-validation-phone" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(`cbgwApp.cbCreateAccount.phone.default`)
                              ),
                            },
                          }),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "mb-4 w-100 font-register" }, [
                      _c("p", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbHome.cbHomeBeforeLogin.register.password"
                              )
                            ),
                          },
                        }),
                        _c("span", { staticClass: "cj-color-grey-secondary" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex" }, [
                        _vm.passwordInputType === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.cbUserInfo.password.$model,
                                  expression: "$v.cbUserInfo.password.$model",
                                },
                              ],
                              staticClass:
                                "font-size-12 p-0 py-2 cj-field-border-bottom-default",
                              class: {
                                invalid: _vm.invalidPassword,
                                valid:
                                  _vm.$v.cbUserInfo.password.$anyDirty &&
                                  !_vm.invalidPassword,
                              },
                              attrs: {
                                "data-cy": "input-password-register",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.$v.cbUserInfo.password.$model
                                )
                                  ? _vm._i(
                                      _vm.$v.cbUserInfo.password.$model,
                                      null
                                    ) > -1
                                  : _vm.$v.cbUserInfo.password.$model,
                              },
                              on: {
                                keyup: function ($event) {
                                  return _vm.onInputRequired(
                                    $event.target.value
                                  )
                                },
                                change: function ($event) {
                                  var $$a = _vm.$v.cbUserInfo.password.$model,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.$v.cbUserInfo.password,
                                          "$model",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.$v.cbUserInfo.password,
                                          "$model",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.$v.cbUserInfo.password,
                                      "$model",
                                      $$c
                                    )
                                  }
                                },
                              },
                            })
                          : _vm.passwordInputType === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.cbUserInfo.password.$model,
                                  expression: "$v.cbUserInfo.password.$model",
                                },
                              ],
                              staticClass:
                                "font-size-12 p-0 py-2 cj-field-border-bottom-default",
                              class: {
                                invalid: _vm.invalidPassword,
                                valid:
                                  _vm.$v.cbUserInfo.password.$anyDirty &&
                                  !_vm.invalidPassword,
                              },
                              attrs: {
                                "data-cy": "input-password-register",
                                type: "radio",
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.$v.cbUserInfo.password.$model,
                                  null
                                ),
                              },
                              on: {
                                keyup: function ($event) {
                                  return _vm.onInputRequired(
                                    $event.target.value
                                  )
                                },
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.$v.cbUserInfo.password,
                                    "$model",
                                    null
                                  )
                                },
                              },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.cbUserInfo.password.$model,
                                  expression: "$v.cbUserInfo.password.$model",
                                },
                              ],
                              staticClass:
                                "font-size-12 p-0 py-2 cj-field-border-bottom-default",
                              class: {
                                invalid: _vm.invalidPassword,
                                valid:
                                  _vm.$v.cbUserInfo.password.$anyDirty &&
                                  !_vm.invalidPassword,
                              },
                              attrs: {
                                "data-cy": "input-password-register",
                                type: _vm.passwordInputType,
                              },
                              domProps: {
                                value: _vm.$v.cbUserInfo.password.$model,
                              },
                              on: {
                                keyup: function ($event) {
                                  return _vm.onInputRequired(
                                    $event.target.value
                                  )
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.cbUserInfo.password,
                                    "$model",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "btn-password cursor-pointer",
                            attrs: { "data-cy": "reveal-password" },
                            on: { click: _vm.togglePassword },
                          },
                          [
                            _vm.passwordInputType === "text"
                              ? _c("icon", {
                                  staticClass: "cj-stroke-grey-secondary",
                                  attrs: { name: "ic_eye_open", width: "1rem" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.passwordInputType === "password"
                              ? _c("icon", {
                                  staticClass: "cj-stroke-grey-secondary",
                                  attrs: {
                                    name: "ic_eye_close",
                                    width: "1rem",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "mb-4 w-100 font-register" }, [
                      _c("p", [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbHome.cbHomeBeforeLogin.register.confirmPassword"
                              )
                            ),
                          },
                        }),
                        _c("span", { staticClass: "cj-color-grey-secondary" }, [
                          _vm._v("*"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex" }, [
                        _vm.passwordInputType === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.confirmPassword.$model,
                                  expression: "$v.confirmPassword.$model",
                                },
                              ],
                              staticClass:
                                "font-size-12 p-0 py-2 cj-field-border-bottom-default",
                              class: `${
                                _vm.$v.confirmPassword.$anyDirty
                                  ? _vm.confirmPassword !=
                                      _vm.cbUserInfo.password ||
                                    _vm.$v.confirmPassword.$invalid
                                    ? "invalid"
                                    : "valid"
                                  : ""
                              }`,
                              attrs: {
                                "data-cy": "input-confirm-password-register",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.$v.confirmPassword.$model
                                )
                                  ? _vm._i(
                                      _vm.$v.confirmPassword.$model,
                                      null
                                    ) > -1
                                  : _vm.$v.confirmPassword.$model,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.$v.confirmPassword.$model,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.$v.confirmPassword,
                                          "$model",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.$v.confirmPassword,
                                          "$model",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.$v.confirmPassword,
                                      "$model",
                                      $$c
                                    )
                                  }
                                },
                              },
                            })
                          : _vm.passwordInputType === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.confirmPassword.$model,
                                  expression: "$v.confirmPassword.$model",
                                },
                              ],
                              staticClass:
                                "font-size-12 p-0 py-2 cj-field-border-bottom-default",
                              class: `${
                                _vm.$v.confirmPassword.$anyDirty
                                  ? _vm.confirmPassword !=
                                      _vm.cbUserInfo.password ||
                                    _vm.$v.confirmPassword.$invalid
                                    ? "invalid"
                                    : "valid"
                                  : ""
                              }`,
                              attrs: {
                                "data-cy": "input-confirm-password-register",
                                type: "radio",
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.$v.confirmPassword.$model,
                                  null
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.$v.confirmPassword,
                                    "$model",
                                    null
                                  )
                                },
                              },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.confirmPassword.$model,
                                  expression: "$v.confirmPassword.$model",
                                },
                              ],
                              staticClass:
                                "font-size-12 p-0 py-2 cj-field-border-bottom-default",
                              class: `${
                                _vm.$v.confirmPassword.$anyDirty
                                  ? _vm.confirmPassword !=
                                      _vm.cbUserInfo.password ||
                                    _vm.$v.confirmPassword.$invalid
                                    ? "invalid"
                                    : "valid"
                                  : ""
                              }`,
                              attrs: {
                                "data-cy": "input-confirm-password-register",
                                type: _vm.passwordInputType,
                              },
                              domProps: {
                                value: _vm.$v.confirmPassword.$model,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.confirmPassword,
                                    "$model",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "btn-password cursor-pointer",
                            attrs: { "data-cy": "reveal-confirm-password" },
                            on: { click: _vm.togglePassword },
                          },
                          [
                            _vm.passwordInputType === "text"
                              ? _c("icon", {
                                  staticClass: "cj-stroke-grey-secondary",
                                  attrs: { name: "ic_eye_open", width: "1rem" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.passwordInputType === "password"
                              ? _c("icon", {
                                  staticClass: "cj-stroke-grey-secondary",
                                  attrs: {
                                    name: "ic_eye_close",
                                    width: "1rem",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.confirmPassword.length > 0 &&
                      _vm.confirmPassword !== _vm.cbUserInfo.password
                        ? _c("div", [
                            _c("small", {
                              staticClass:
                                "font-validation cj-color-status-error",
                              attrs: { "data-cy": "confirm-password" },
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationConfirmPassword"
                                  )
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", {
                        staticClass: "font-validation",
                        style: "color:" + _vm.styleValidPassOne,
                        attrs: { "data-cy": "valid-one", id: "span-one" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationOne"
                            )
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", {
                        staticClass: "font-validation",
                        style: "color:" + _vm.styleValidPassTwo,
                        attrs: { "data-cy": "valid-two", id: "span-two" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationTwo"
                            )
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", {
                        staticClass: "font-validation",
                        style: "color:" + _vm.styleValidPassThree,
                        attrs: { "data-cy": "valid-three", id: "span-three" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationThree"
                            )
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("span", {
                        staticClass: "font-validation",
                        style: "color:" + _vm.styleValidPassFour,
                        attrs: { "data-cy": "valid-four", id: "span-four" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationFour"
                            )
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "my-3 d-flex" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "w-auto my-auto mr-4 font-weight-400 font-size-12 font-roboto",
                        },
                        [
                          _c("span", {
                            staticClass: "cj-color-black-primary",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbHome.cbHomeBeforeLogin.register.verificationOption"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "cj-color-grey-secondary" },
                            [_vm._v("*")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-control custom-checkbox cb-custom-checkbox my-auto mr-2",
                          class: {
                            "valid-checkbox":
                              _vm.cbUserInfo.phoneVerificationOpt &&
                              _vm.isDirty,
                            "invalid-checkbox":
                              !_vm.cbUserInfo.phoneVerificationOpt &&
                              !_vm.cbUserInfo.emailVerificationOpt &&
                              _vm.isDirty,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.cbUserInfo.phoneVerificationOpt,
                                expression: "cbUserInfo.phoneVerificationOpt",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: `check-phone-number-register-buyer`,
                              "data-cy": "whatsapp-verif",
                              "data-gtm": "whatsapp-verif",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.cbUserInfo.phoneVerificationOpt
                              )
                                ? _vm._i(
                                    _vm.cbUserInfo.phoneVerificationOpt,
                                    null
                                  ) > -1
                                : _vm.cbUserInfo.phoneVerificationOpt,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.cbUserInfo.phoneVerificationOpt,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.cbUserInfo,
                                          "phoneVerificationOpt",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.cbUserInfo,
                                          "phoneVerificationOpt",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.cbUserInfo,
                                      "phoneVerificationOpt",
                                      $$c
                                    )
                                  }
                                },
                                function ($event) {
                                  return _vm.validationDirty($event)
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label font-register",
                              attrs: {
                                for: `check-phone-number-register-buyer`,
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ml-2 my-auto cj-color-black-primary font-weight-400 font-size-12",
                                },
                                [_vm._v("WhatsApp")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "custom-control custom-checkbox cb-custom-checkbox my-auto",
                          class: {
                            "valid-checkbox":
                              _vm.cbUserInfo.emailVerificationOpt &&
                              _vm.isDirty,
                            "invalid-checkbox":
                              !_vm.cbUserInfo.phoneVerificationOpt &&
                              !_vm.cbUserInfo.emailVerificationOpt &&
                              _vm.isDirty,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.cbUserInfo.emailVerificationOpt,
                                expression: "cbUserInfo.emailVerificationOpt",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: `check-email-register-buyer`,
                              "data-cy": "email-verif",
                              "data-gtm": "email-verif",
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.cbUserInfo.emailVerificationOpt
                              )
                                ? _vm._i(
                                    _vm.cbUserInfo.emailVerificationOpt,
                                    null
                                  ) > -1
                                : _vm.cbUserInfo.emailVerificationOpt,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.cbUserInfo.emailVerificationOpt,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.cbUserInfo,
                                          "emailVerificationOpt",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.cbUserInfo,
                                          "emailVerificationOpt",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.cbUserInfo,
                                      "emailVerificationOpt",
                                      $$c
                                    )
                                  }
                                },
                                function ($event) {
                                  return _vm.validationDirty($event)
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label font-register",
                              attrs: { for: `check-email-register-buyer` },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ml-2 my-auto cj-color-black-primary font-weight-400 font-size-12",
                                },
                                [_vm._v("Email")]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "c-button",
                      {
                        staticClass: "font-roboto mr-2 text-center my-2",
                        attrs: {
                          disabled:
                            _vm.isExistEmail == true ||
                            _vm.isExistPhone == true ||
                            _vm.isPasswordSame == false ||
                            _vm.$v.cbUserInfo.$invalid == true ||
                            _vm.isLowerCase == false ||
                            _vm.isUpperCase == false ||
                            _vm.isCharacter == false ||
                            _vm.isAllValidate == false ||
                            (!_vm.cbUserInfo.emailVerificationOpt &&
                              !_vm.cbUserInfo.phoneVerificationOpt) ||
                            _vm.isLoadingSubmit ||
                            _vm.isFetchingPhoneNumber ||
                            _vm.isFetchingEmail,
                          fullWidth: "",
                          type: "button",
                          dataGTMCustom: "submit-register-button",
                          "data-cy": "button-submit-register",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSubmitRegister()
                          },
                        },
                      },
                      [
                        _vm.isLoadingSubmit
                          ? _c("font-awesome-icon", {
                              attrs: {
                                icon: "sync",
                                spin: _vm.isLoadingSubmit,
                              },
                            })
                          : _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.btnTitle"
                                  )
                                ),
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-catamaran font-weight-300 cj-color-grey-secondary font-size-12 my-2",
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.descRegister"
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("a", {
                      staticClass: "cj-color-red-primary font-weight-300",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.termOfService"
                          )
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.openModalTermOfService =
                            !_vm.openModalTermOfService
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.and"
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("a", {
                      staticClass: "cj-color-red-primary font-weight-300",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.privacyPolicy"
                          )
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.openModalPrivacyPolicy =
                            !_vm.openModalPrivacyPolicy
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "col-auto d-xs-none d-sm-none d-md-block d-lg-block d-xl-block vl px-3",
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 text-center",
          },
          [
            _c("p", {
              staticClass:
                "m-0 font-italic font-weight-bold font-size-12 cj-color-black-primary",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.descRegisterSocialAccount"
                  )
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-social p-3 my-3",
                attrs: {
                  "data-cy": "login-with-google",
                  "data-gtm": "register-with-google-button",
                },
                on: { click: _vm.loginGoogle },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-xs-center text-sm-center text-md-right text-lg-right text-xl-right",
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-1",
                        attrs: { name: "ic_google", width: "1.625rem" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 text-xs-center text-sm-center text-md-left text-lg-left text-xl-left d-flex",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "font-roboto font-weight-500 font-size-12 my-auto",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.google"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-social p-3 my-3",
                attrs: {
                  "data-cy": "login-with-facebook",
                  "data-gtm": "register-with-facebook-button",
                },
                on: { click: _vm.loginFacebook },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-xs-center text-sm-center text-md-right text-lg-right text-xl-right",
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-1",
                        attrs: { name: "ic_facebook", width: "1.625rem" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 text-xs-center text-sm-center text-md-left text-lg-left text-xl-left d-flex",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "font-roboto font-weight-500 font-size-12 my-auto",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.facebook"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-social p-3 my-3",
                attrs: {
                  "data-cy": "login-with-linkedin",
                  "data-gtm": "register-with-linkedin-button",
                },
                on: { click: _vm.loginLinkedin },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-xs-center text-sm-center text-md-right text-lg-right text-xl-right",
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-1",
                        attrs: { name: "ic_linkedin", width: "1.625rem" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 text-xs-center text-sm-center text-md-left text-lg-left text-xl-left d-flex",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "font-roboto font-weight-500 font-size-12 my-auto",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.linkedin"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-social p-3 my-3",
                attrs: {
                  "data-cy": "login-with-apple",
                  "data-gtm": "register-with-apple-button",
                },
                on: { click: _vm.loginApple },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-xs-center text-sm-center text-md-right text-lg-right text-xl-right",
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-1",
                        attrs: { name: "ic_apple", width: "1.625rem" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 text-xs-center text-sm-center text-md-left text-lg-left text-xl-left d-flex",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "font-roboto font-weight-500 font-size-12 my-auto",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.register.apple"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "font-catamaran font-weight-300 cj-color-grey-secondary font-size-12",
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.alreadyAccount"
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c("a", { on: { click: _vm.openDialogLogin } }, [
                  _c("b", {
                    staticClass: "cj-color-red-primary font-weight-300",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.title")
                      ),
                    },
                  }),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.openModalTermOfService,
            callback: function ($$v) {
              _vm.openModalTermOfService = $$v
            },
            expression: "openModalTermOfService",
          },
        },
        [
          _c("c-tmp-announcement", {
            attrs: {
              title: _vm.$t("cbgwApp.cbCreateAccount.termOfService"),
              content: _vm.TERM_OF_SERVICE,
            },
            on: {
              close: function ($event) {
                _vm.openModalTermOfService = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
          model: {
            value: _vm.openModalPrivacyPolicy,
            callback: function ($$v) {
              _vm.openModalPrivacyPolicy = $$v
            },
            expression: "openModalPrivacyPolicy",
          },
        },
        [
          _c("c-tmp-announcement", {
            attrs: {
              title: _vm.$t("cbgwApp.cbCreateAccount.termPrivacyPolicy"),
              content: _vm.PRIVACY_POLICY,
            },
            on: {
              close: function ($event) {
                _vm.openModalPrivacyPolicy = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", [
      _vm._v("Email"),
      _c("span", { staticClass: "cj-color-grey-secondary" }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }