import axios from 'axios';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-reviews';

export default class PCbVwUserReviewService {
  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-login', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public count(params?: any): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/count`, { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
