var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-ba-bank-update pt-3",
      attrs: { "data-cy": "add-bank-account-form" },
    },
    [
      _c(
        "p",
        {
          staticClass:
            "font-roboto font-weight-500 font-size-16-auto cj-color-black-primary mb-1",
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbSetting.bankAccount.bankName")
              ),
            },
          }),
          _c("span", { staticClass: "cj-color-grey-secondary" }, [_vm._v("*")]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-select mb-3" }, [
        _c(
          "select",
          {
            class: `${
              _vm.$v.newCbBankAccount.bankCode.$anyDirty
                ? _vm.$v.newCbBankAccount.bankCode.$invalid
                  ? "invalid"
                  : "valid"
                : ""
            } cj-color-black-primary`,
            attrs: { "data-cy": "bank-code" },
            on: { input: _vm.selectBankCode },
          },
          [
            _c(
              "option",
              {
                attrs: {
                  "data-cy": "select-bank-code-none",
                  disabled: "",
                  value: "",
                },
                domProps: {
                  selected:
                    !_vm.newCbBankAccount || !_vm.newCbBankAccount.bankCode,
                },
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.bankAccount.selectBank")
                    ),
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.cbLovBanks, function (cbLovBank, index) {
              return _c(
                "option",
                {
                  key: index,
                  attrs: { "data-cy": `select-bank-code-${cbLovBank.key}` },
                  domProps: {
                    selected: _vm.newCbBankAccount.bankCode == cbLovBank.key,
                    value: cbLovBank.key,
                  },
                },
                [_vm._v("\n        " + _vm._s(cbLovBank.value) + "\n      ")]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-roboto font-weight-500 font-size-16-auto cj-color-black-primary mb-1",
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbSetting.bankAccount.accountNumber")
              ),
            },
          }),
          _c("span", { staticClass: "cj-color-grey-secondary" }, [_vm._v("*")]),
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$v.newCbBankAccount.accountNumber.$model,
            expression: "$v.newCbBankAccount.accountNumber.$model",
          },
        ],
        staticClass: "mb-3 cj-field-input",
        class: `${
          _vm.$v.newCbBankAccount.accountNumber.$anyDirty
            ? _vm.$v.newCbBankAccount.accountNumber.$invalid
              ? "invalid"
              : "valid"
            : ""
        }`,
        attrs: {
          "data-cy": "account-number",
          type: "text",
          id: "formControlInputAccountNumber",
          placeholder: _vm.$t("cbgwApp.cbSetting.bankAccount.accountNumber"),
          maxlength: 16,
        },
        domProps: { value: _vm.$v.newCbBankAccount.accountNumber.$model },
        on: {
          paste: _vm.handlePaste,
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.$v.newCbBankAccount.accountNumber,
                "$model",
                $event.target.value
              )
            },
            function ($event) {
              _vm.newCbBankAccount.accountNumber = _vm.inputCardNumber($event)
            },
          ],
        },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-roboto font-weight-500 font-size-16-auto cj-color-black-primary mb-1",
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbSetting.bankAccount.accountHolder")
              ),
            },
          }),
          _c("span", { staticClass: "cj-color-grey-secondary" }, [_vm._v("*")]),
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.$v.newCbBankAccount.accountHolderName.$model,
            expression: "$v.newCbBankAccount.accountHolderName.$model",
          },
        ],
        staticClass: "mb-3 cj-field-input",
        class: `${
          _vm.$v.newCbBankAccount.accountHolderName.$anyDirty
            ? _vm.$v.newCbBankAccount.accountHolderName.$invalid
              ? "invalid"
              : "valid"
            : ""
        }`,
        attrs: {
          "data-cy": "account-holder-name",
          type: "text",
          id: "formControlInputName",
          placeholder: _vm.$t(
            "cbgwApp.cbSetting.bankAccount.accountHolderName"
          ),
          maxlength: 500,
        },
        domProps: { value: _vm.$v.newCbBankAccount.accountHolderName.$model },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(
              _vm.$v.newCbBankAccount.accountHolderName,
              "$model",
              $event.target.value
            )
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }