import axios from 'axios';

import { IRptKpiMetaTable } from '@/shared/model/rpt-kpi-meta-table.model';
import { App } from '@/shared/model/enumerations/app.model';

const baseApiUrl = 'services/btichartreport/api/rpt-kpi-meta-tables';

export default class PRptKpiMetaTableService {
  public find(app: App, kpiKey: String, params: String): Promise<IRptKpiMetaTable> {
    return new Promise<IRptKpiMetaTable>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${app}/${kpiKey}/${params ? params : '-'}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IRptKpiMetaTable): Promise<IRptKpiMetaTable> {
    return new Promise<IRptKpiMetaTable>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IRptKpiMetaTable): Promise<IRptKpiMetaTable> {
    return new Promise<IRptKpiMetaTable>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IRptKpiMetaTable): Promise<IRptKpiMetaTable> {
    return new Promise<IRptKpiMetaTable>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
