export enum CbRequirementType {
  REQUIREMENT = 'REQUIREMENT',

  MOM = 'MOM',

  REVISION = 'REVISION',

  APPOINTMENT = 'APPOINTMENT',

  DELIVERY = 'DELIVERY',

  ON_MY_WAY = 'ON_MY_WAY',

  CLOCK_IN = 'CLOCK_IN',

  CLOCK_OUT = 'CLOCK_OUT',

  COMPLETED = 'COMPLETED',

  REVISION_EXTRA = 'REVISION_EXTRA',

  MILESTONE = 'MILESTONE',

  CANCELLED = 'CANCELLED',

  CANCELLED_CHARGED = 'CANCELLED_CHARGED',

  OVERDUE = 'OVERDUE',

  EXTENDED_DELIVERY_TIME = 'EXTENDED_DELIVERY_TIME',

  SERVICE_FINISHED = 'SERVICE_FINISHED',
  DISPUTE = 'DISPUTE',
}
