import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import { DATE_TIME_LONG_FORMAT } from '../date/filters';
import { ICbVwUser } from '../model/cb-vw-user.model';

@Component({})
export default class UtilFormatter extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public convertSecondToDate(second: number): Date {
    return dayjs().set('second', Math.abs(second)).toDate();
  }

  public convertStringToDateUtc(date: string): Date {
    return dayjs(date, DATE_TIME_LONG_FORMAT).toDate();
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
