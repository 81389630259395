var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showDate
    ? _c(
        "div",
        {
          staticClass: "room-message-date font-roboto",
          attrs: { "data-cy": "vac-message-date" },
        },
        [_vm._v("\n  " + _vm._s(_vm.message.date) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }