import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormUtils extends Vue {
  public validatePasteMaxLengthUtils(max: number, total: number): boolean {
    if (total > max) {
      const message = {
        toString: () => {
          return this.$t('cbgwApp.cbComponent.validation.maxLengthSize');
        },
      };
      (<any>this.$root).$bvToast.toast(message.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Warning',
        variant: 'warning',
        solid: true,
        autoHideDelay: 5000,
      });
      return false;
    }
    return true;
  }

  public handlePaste(e: any) {
    const message = {
      toString: () => {
        return this.$t('cbgwApp.cbComponent.validation.cannotPaste');
      },
    };
    (<any>this.$root).$bvToast.toast(message.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Warning',
      variant: 'warning',
      solid: true,
      autoHideDelay: 5000,
    });
    e.preventDefault();
  }

  public isLoginValid(model: any, isLoginEntried: boolean): LoginValidation {
    const newVal: string = model.$model;
    const result = {
      isLoginEntried,
      isLoginInvalid: false,
      valid: 'invalid',
    } as LoginValidation;
    result.isLoginEntried = (newVal && newVal.length > 0) != undefined;
    if (!result.isLoginEntried) {
      result.valid = '';
      return result;
    }
    if (!newVal && newVal === '') {
      result.isLoginInvalid = true;
      result.valid = 'invalid';
      return result;
    }
    if (newVal.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)) {
      result.isLoginInvalid = false;
      result.valid = 'valid';
      return result;
    } else {
      if (!(newVal.startsWith('+62') || newVal.startsWith('08')) || newVal.length < 10) {
        result.isLoginInvalid = true;
        result.valid = 'invalid';
        return result;
      } else {
        result.isLoginInvalid = false;
        result.valid = 'valid';
        return result;
      }
    }
  }
}

interface LoginValidation {
  isLoginEntried: boolean;
  isLoginInvalid: boolean;
  valid: 'valid' | 'invalid' | '';
}
