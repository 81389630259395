import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class CPdLicenseItem extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({default:null})
  public item;
  @Prop({default:null})
  public currentLanguage;
  @Prop({default:null})
  public cbVwPaw;
  @Prop({default:null})
  public currentUser;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
