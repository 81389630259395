import { Component, Prop, Vue } from 'vue-property-decorator';
import CUserPhotoLevel from '@/components/c-user-photo-level/c-user-photo-level.vue';
import CPdReviewDetailReply from '../c-pd-review-detail-reply/c-pd-review-detail-reply.vue';
@Component({
  components: { CUserPhotoLevel, CPdReviewDetailReply },
})
export default class CPdReviewSlideshow extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop()
  public cbVwUserReviews;
  
  public seletedReviewId='';
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
