import GlobalService from '@/services/global.service';
import { Inject, Component, Vue, Emit } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
@Component({
  components: {
    CButton,
  },
})
export default class CCardCookied extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('openCookiesSetting')
  public openCookiesSetting() {
    return;
  }

  @Emit('accAllCookies')
  public accAllCookies() {
    return;
  }

  public openModalCookiePolicy() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-cookie-policy');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
