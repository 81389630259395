import { ICmTkt } from './../shared/model/cm-tkt.model';
import axios from 'axios';

import { ICbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { IWfHistory } from '@/shared/model/wf-history.model';

const baseApiUrl = 'services/cbcorems/api/cb-paw-order-requirements';

export default class PCbPawOrderRequirementService {
  public find(id: number, cancelToken?: any): Promise<ICbPawOrderRequirement> {
    return new Promise<ICbPawOrderRequirement>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  // WF
  public create(entity: ICbPawOrderRequirement, wfHistory: IWfHistory): Promise<ICbPawOrderRequirement> {
    // WF
    if (!wfHistory) {
      console.log('wf needed');
      return;
    }
    entity.wfHistory = wfHistory;

    return new Promise<ICbPawOrderRequirement>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createDispute(entity: ICmTkt): Promise<ICmTkt> {
    return new Promise<ICmTkt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/dispute`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public partialUpdate(entity: ICbPawOrderRequirement): Promise<ICbPawOrderRequirement> {
    return new Promise<ICbPawOrderRequirement>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
