var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-item-progress-milestone",
      class: { "mb-3": !_vm.type.includes("CHAT") },
    },
    [
      _c("div", {
        staticClass: "vl",
        class: {
          hide: _vm.lastItem,
          [_vm.vlCustomClass]: _vm.vlCustomClass,
          "vl-type-1": !_vm.type.includes("CHAT"),
          "vl-type-2":
            _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
            _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
            _vm.type === _vm.TypeComponent.CHAT_MODAL,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-center align-items-center poin-circle",
          class: {
            active: _vm.firstItem,
            "poin-circle-type-1": !_vm.type.includes("CHAT"),
            "poin-circle-type-2":
              _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
              _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
              _vm.type === _vm.TypeComponent.CHAT_MODAL,
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "font-roboto cj-color-black-primary",
              class: {
                "font-weight-500 font-size-14": !_vm.type.includes("CHAT"),
                "font-weight-700 font-size-8":
                  _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
                  _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
                  _vm.type === _vm.TypeComponent.CHAT_MODAL,
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.progress) + "%\n    ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "wrap-right",
          class: {
            "margin-left-custom":
              _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
              _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
              _vm.type === _vm.TypeComponent.CHAT_MODAL,
          },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }