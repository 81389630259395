import { ICbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';

export interface ICbTextRestrict {
  id?: number;
  content?: string | null;
  isActive?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  externalCode?: string | null;
  cbPawOrderRequirement?: ICbPawOrderRequirement | null;
}

export class CbTextRestrict implements ICbTextRestrict {
  constructor(
    public id?: number,
    public content?: string | null,
    public isActive?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public externalCode?: string | null,
    public cbPawOrderRequirement?: ICbPawOrderRequirement | null
  ) {
    // this.isActive = this.isActive ?? false;
    this.content = this.content ?? null;
  }
}
