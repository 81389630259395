import axios from 'axios';

import { ICbAuctionProjectPreOffer } from '@/shared/model/cb-auction-project-pre-offer.model';

const baseApiUrl = 'services/cbcorems/api/cb-auction-project-pre-offers';

export default class PCbAuctionProjectPreOfferService {
  public create(entity: ICbAuctionProjectPreOffer): Promise<ICbAuctionProjectPreOffer> {
    return new Promise<ICbAuctionProjectPreOffer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbAuctionProjectPreOffer): Promise<ICbAuctionProjectPreOffer> {
    return new Promise<ICbAuctionProjectPreOffer>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
