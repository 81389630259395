import { Component, Vue, Prop } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CButton from '@/components/button/c-button.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';

import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { ICbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { ICbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';
import {
  HANDLE_MDL_MOM_CONFIRMATION_ACCEPT_DIRECTLY,
  HANDLE_MDL_MOM_PREVIEW,
} from '@/module/chat-container/order-paw-controller.component';

@Component({
  components: {
    Icon,
    CButton,
    CTextareaPreview,
    CTimeCountdown,
  },
  mixins: [StatusTextEnId],
})
export default class CItemChatMessageMom extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;
  @Prop({ default: null }) public cbVwPawOrderRequirement: ICbPawOrderRequirement;
  @Prop({ default: null }) public cbTextRestrict: ICbTextRestrict;
  @Prop({ default: false }) public isYourSelf: boolean;

  public isSavingLoaderMom = false;

  public CbCommonStatus = CbCommonStatus;
  public CbLoginType = CbLoginType;
  public CbSvcType = CbSvcType;
  public showNote = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleOpenPreviewMom() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_MDL_MOM_PREVIEW,
      cbVwPawOrder: this.cbVwPawOrder,
      cbVwPawOrderRequirement: this.cbVwPawOrderRequirement,
      cbTextRestrict: this.cbTextRestrict,
      typeModal: TypeComponent.CHAT_MODAL,
    });
  }

  public handleOpenConfirmationAccept() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_MDL_MOM_CONFIRMATION_ACCEPT_DIRECTLY,
      cbVwPawOrder: this.cbVwPawOrder,
      cbVwPawOrderRequirement: this.cbVwPawOrderRequirement,
      typeModal: TypeComponent.CHAT_MODAL,
    });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getLoginType() {
    return this.$store.getters.loginType;
  }
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================

  // ================= END LISTENERS ===================
  public openAllCollapse() {
    this.showNote = true;
  }
}
