import { CbAttachmentNoAuth, ICbAttachmentNoAuth } from '@/shared/model/cb-attachment-no-auth.model';
import { ICbSvcCatPkgAgg } from '@/shared/model/cb-svc-cat-pkg-agg.model';
import { ICbSvcCatMetaDataAgg } from '@/shared/model/cb-svc-cat-meta-data-agg.model';
import { ICbPaw } from '@/shared/model/cb-paw.model';
import { ICbAuctionProject } from '@/shared/model/cb-auction-project.model';
import { ICbSvc } from '@/shared/model/cb-svc.model';

export interface ICbSvcCat {
  id?: number;
  name?: string;
  description?: string | null;
  descriptionIn?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  seq?: number | null;
  isActive?: boolean;
  logo?: ICbAttachmentNoAuth | null;
  banner?: ICbAttachmentNoAuth | null;
  cbSvcCatPkgAggs?: ICbSvcCatPkgAgg[] | null;
  cbSvcCatMetaDataAggs?: ICbSvcCatMetaDataAgg[] | null;
  cbPaw?: ICbPaw | null;
  cbAuctionProject?: ICbAuctionProject | null;
  cbSvc?: ICbSvc | null;
}

export class CbSvcCat implements ICbSvcCat {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string | null,
    public descriptionIn?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public seq?: number | null,
    public isActive?: boolean,
    public logo?: ICbAttachmentNoAuth | null,
    public banner?: ICbAttachmentNoAuth | null,
    public cbSvcCatPkgAggs?: ICbSvcCatPkgAgg[] | null,
    public cbSvcCatMetaDataAggs?: ICbSvcCatMetaDataAgg[] | null,
    public cbPaw?: ICbPaw | null,
    public cbAuctionProject?: ICbAuctionProject | null,
    public cbSvc?: ICbSvc | null
  ) {
    // this.isActive = this.isActive ?? false;
    this.logo = this.logo ?? new CbAttachmentNoAuth();
    this.banner = this.banner ?? new CbAttachmentNoAuth();
    this.cbSvcCatPkgAggs = this.cbSvcCatPkgAggs ?? [];
    this.cbSvcCatMetaDataAggs = this.cbSvcCatMetaDataAggs ?? [];
  }
}
