var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "vac-slide-up" } }, [
    _vm.messageReply
      ? _c(
          "div",
          {
            staticClass: "vac-reply-container",
            style: { bottom: `${_vm.footerHeight}px` },
          },
          [
            _c(
              "div",
              { staticClass: "vac-reply-box" },
              [
                _c("div", { staticClass: "vac-reply-info" }, [
                  _c("div", { staticClass: "vac-reply-username" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.messageReply.username) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vac-reply-content" },
                    [
                      _c("format-message", {
                        attrs: {
                          content: _vm.messageReply.content,
                          "sticker-url": _vm.messageReply.stickerUrl,
                          users: _vm.room.users,
                          "text-formatting": _vm.textFormatting,
                          "link-options": _vm.linkOptions,
                          reply: true,
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.$scopedSlots, function (i, name) {
                              return {
                                key: name,
                                fn: function (data) {
                                  return [_vm._t(name, null, null, data)]
                                },
                              }
                            }),
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.isImage
                  ? _c("img", {
                      staticClass: "vac-image-reply",
                      attrs: { src: _vm.firstFile.url, alt: "" },
                    })
                  : _vm.isVideo
                  ? _c(
                      "video",
                      {
                        staticClass: "vac-image-reply",
                        attrs: { controls: "" },
                      },
                      [_c("source", { attrs: { src: _vm.firstFile.url } })]
                    )
                  : _vm.isAudio
                  ? _c("audio-player", {
                      staticClass: "vac-audio-reply",
                      attrs: { src: _vm.firstFile.url },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.$scopedSlots, function (i, name) {
                            return {
                              key: name,
                              fn: function (data) {
                                return [_vm._t(name, null, null, data)]
                              },
                            }
                          }),
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm.isOtherFile
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vac-image-reply vac-file-container d-flex",
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("c-icon-file", {
                              attrs: { file: _vm.firstFile },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "vac-text-ellipsis" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.firstFile.name) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.firstFile.extension
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vac-text-ellipsis vac-text-extension",
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.firstFile.extension) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vac-icon-reply" }, [
              _c(
                "div",
                {
                  staticClass: "vac-svg-button",
                  attrs: { "data-cy": "vac-reply-close" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("reset-message")
                    },
                  },
                },
                [
                  _vm._t("reply-close-icon", function () {
                    return [
                      _c("b-icon-x-circle-fill", {
                        staticClass: "font-size-24 cj-color-grey-secondary",
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }