import axios from 'axios';

import { ICbVwBuyerBalance } from '@/shared/model/cb-vw-buyer-balance.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-buyer-balances';

export default class PCbVwBuyerBalanceService {
  public retrieveByLogin(): Promise<ICbVwBuyerBalance> {
    return new Promise<ICbVwBuyerBalance>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
