var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-fp-information", attrs: { "data-cy": "fp-information" } },
    [
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-size-16 font-weight-400",
        },
        [
          _vm._v("\n    " + _vm._s(_vm.infoTitle) + "\n    "),
          _c(
            "span",
            {
              staticClass: "font-weight-700",
              attrs: { "data-cy": "information-price" },
            },
            [_vm._v(_vm._s(_vm.infoPrice))]
          ),
          _vm._v(" "),
          _vm.isShowInfoDetail
            ? _c(
                "span",
                {
                  ref: "ref-c-fp-information",
                  staticStyle: { position: "relative", top: "-1.5px" },
                },
                [
                  _c("icon", {
                    staticClass: "cj-fill-red-primary",
                    attrs: {
                      name: "ic_exclamation",
                      width: "20px",
                      height: "100%",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          staticClass: "rounded",
          attrs: {
            "custom-class": "custom-tooltip-wrapper",
            target: () => _vm.$refs["ref-c-fp-information"],
            placement: "top",
            triggers: "hover",
          },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "font-catamaran font-size-14 font-weight-400 cj-color-grey-secondary",
            },
            [
              _vm._v("\n      " + _vm._s(_vm.infoDetail) + " "),
              _vm.isShowReadMore
                ? _c(
                    "span",
                    {
                      staticClass: "cj-btn-text-secondary",
                      on: {
                        click: function ($event) {
                          return _vm.toLink()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cbGlobal.readMore")))]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }