import Vue from 'vue';
import axios from 'axios';
import { Store } from 'vuex';
import VueRouter, { Route } from 'vue-router';
import AccountService from '@/account/account.service';
import { IChtConversation } from '@/shared/model/cht-conversation.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import { IKeycloakToken } from '@/shared/model/keycloak-token.model';
import SocketService from '@/admin/socket/socket.service';
import { Flutter } from '@/app-flutter';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { APPLE, FACEBOOK, GOOGLE, LINKEDIN, REFRESH_TOKEN_API } from '@/constants';
import { ICbLinkedinRequest, ICbLinkedinResponse } from '@/shared/model/cb-linkedin-request.model';
import {
  SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN,
  SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT,
  SESSION_STORAGE_CB_JHI_ID_TOKEN,
  SESSION_STORAGE_LINKEDIN_INSTATE,
} from '@/shared/constant/constants-session-storage';
import { ICbOid } from '@/shared/model/cb-oids.model';

export default class GlobalService {
  capitalEachSpace(name: string) {
    throw new Error('Method not implemented.');
  }
  formatCurrencyIDR(totalAmount: number) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private store: Store<any>,
    private accountService: AccountService,
    // private userInfoService: any,
    private router: VueRouter
  ) {}
  public socketService: SocketService;
  public client_id = process.env.LINKEDIN_CLIENT_ID;
  public redirect_uri = window.location.origin; // pass redirect_uri here
  public scope = process.env.LINKEDIN_SCOPE; // permissions required by end-user
  public win;
  public inState;
  public checkConnect;

  // SIGNAL
  public currentNavigationController = new AbortController();
  public pathsToCheckSignal = ['/general/p-search-menu-m', '/chat', '/general/p-manage-order', '/general/p-profile'];

  public isPathNeedSignal: Boolean = false;

  public setIsPathNeedSignal(from: Route, to: Route) {
    this.store.dispatch('updateFromPath', from.path);
    this.store.dispatch('updateToPath', to.path);

    const isToPathIncluded = this.pathsToCheckSignal.some(path => to.path.includes(path));
    const isFromPathIncluded = this.pathsToCheckSignal.some(path => from.path.includes(path));
    const isToRootAndFromIncluded = (to.path === '/' || to.path === '/freelancer' || to.path === '/buyer') && isFromPathIncluded;
    if (isToPathIncluded || isToRootAndFromIncluded) {
      this.isPathNeedSignal = true;
    } else {
      this.isPathNeedSignal = false;
    }
  }

  public openGlobalDialog(instance: Vue, id: string): void {
    instance.$emit('bv::show::modal', id);
  }

  public closeGlobalDialog(instance: Vue, id: string): void {
    instance.$emit('bv::hide::modal', id);
  }

  public login(data: any, that: any): Promise<any> {
    //remove cache
    this.store.commit('logoutChat');
    this.logoutStore();

    data.rememberMe = true; // new change  request
    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/authenticate/login', data)
        .then(async res => {
          await this.keepToken(res.data, data.rememberMe);
          resolve(res.data);
        })
        .catch(err => {
          // this.logout();fixbugrederect
          this.store.commit('logoutChat');
          this.logoutStore();
          reject(err);
        });
    });
  }

  public async keepToken(keycloakToken: IKeycloakToken, rememberMe: boolean): Promise<void> {
    keycloakToken.rememberMe = rememberMe;
    const jwtAccessToken: any = jwt_decode(keycloakToken.access_token);
    keycloakToken.expires_in = jwtAccessToken.exp * 1000; //not update from flutter
    const jwtRefreshToken: any = jwt_decode(keycloakToken.access_token);
    keycloakToken.refresh_expires_in = jwtRefreshToken.exp * 1000; //not update from flutter

    this.store.commit('keycloakToken', keycloakToken);
    Flutter.keepToken(keycloakToken);

    const jwt = keycloakToken.access_token;
    localStorage.setItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN, jwt);
    localStorage.setItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT, keycloakToken.refresh_token);
    localStorage.setItem(SESSION_STORAGE_CB_JHI_ID_TOKEN, keycloakToken.id_token);
    // sessionStorage.removeItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN);
  }

  public isHasRefreshToken() {
    return this.store.getters.keycloakToken;
  }

  public async checkRefreshToken() {
    const keycloakToken: IKeycloakToken = this.store.getters.keycloakToken;

    // console.log("AAA {}", (keycloakToken && dayjs(keycloakToken.expires_in).isBefore(dayjs(new Date()))))
    // if (keycloakToken && dayjs(keycloakToken.expires_in).isBefore(dayjs(new Date()))) {
    //expired
    return await new Promise<any>((resolve, reject) => {
      axios
        .post(REFRESH_TOKEN_API, { token: localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT) })
        .then(async res => {
          await this.keepToken(res.data, keycloakToken.rememberMe);
          const access_token = res.data.access_token;
          resolve(access_token);
        })
        .catch(err => {
          reject(err);
        });
    });
    // }

    // return Promise.resolve(false);
  }

  public async checkRefreshTokeFirstOpenPage() {
    if (!localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT)) {
      this.handleExpiredRefreshToken();
      return Promise.resolve(false);
    }
    return await new Promise<any>((resolve, reject) => {
      axios
        .post(REFRESH_TOKEN_API, { token: localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT) })
        .then(async res => {
          await this.keepToken(res.data, true);
          var access_token = res.data.access_token;
          resolve(access_token);
        })
        .catch(err => {
          this.handleExpiredRefreshToken();
          reject(err);
        });
    });
  }

  public async refreshTokenAfterSetEmail() {
    const keycloakToken: IKeycloakToken = this.store.getters.keycloakToken;

    if (keycloakToken) {
      //expired
      return await new Promise<any>((resolve, reject) => {
        axios
          .post(REFRESH_TOKEN_API, { token: keycloakToken.refresh_token })
          .then(async res => {
            await this.keepToken(res.data, keycloakToken.rememberMe);
            const access_token = res.data.access_token;
            this.socketService.connectCustome();
            resolve(access_token);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    return Promise.resolve(false);
  }

  public async logout() {
    axios.post('api/logout').then(
      response => {
        this.store.commit('logoutChat');
        this.logoutStore();
        if (response && response.data && response.data.logoutUrl) {
          // https://oid.bti.id/auth/realms/bti/protocol/openid-connect/logout?redirect_uri=null
          if (response.data.logoutUrl.includes('null')) {
            response.data.logoutUrl = response.data.logoutUrl.replace('null', window.location.origin);
            window.location.href = response.data.logoutUrl;
          } else {
            window.location.href = response.data.logoutUrl;
          }
        } else {
          this.router.replace({ path: '/' });
        }
      },
      err => {
        this.logoutStore();
        if (this.router.currentRoute.name != 'PLoginM') {
          this.router.replace({ path: '/' });
        }
      }
    );
  }

  public logoutStore() {
    Flutter.logoutEventListener('onGetFcmToken');
    this.accountService.logoutStore();
  }

  public handleExpiredRefreshToken() {
    if (window.navigator.onLine) {
      alert('Session is expired');
      Flutter.logoutEventListener('onGetFcmToken');
      this.accountService.logoutStore();
      this.router.replace({ path: '/' });
      Flutter.call('handleExpiredRefreshToken');
    }
  }

  public checkAccountAndLoginTypeData(that) {
    //comment by ade
    // this.accountService.retrieveAccount().then(loginValid => {
    //   if (loginValid) {
    //     this.router.replace({ name: 'PBuyerHome' });
    //     this.accountService.refreshAccount();
    //   }
    // });
  }

  public commitChtVwConversation(chtConversation: IChtConversation) {
    this.store.commit('chtConversation', chtConversation);
  }

  public loginGoogle() {
    Flutter.call('loginGoogle', {
      onFlutterNotRun: () => {
        this.loginFirebase('GOOGLE');
      },
    });
  }

  public loginFacebook() {
    Flutter.call('loginFacebook', {
      onFlutterNotRun: () => {
        this.loginFirebase('FACEBOOK');
      },
    });
  }
  public loginLinkedin() {
    Flutter.call('loginLinkedIn', {
      onFlutterNotRun: () => {
        this.invokeLoginLinkedin();
      },
    });
  }

  public loginApple() {
    Flutter.call('signInAppleId', {
      onFlutterNotRun: () => {
        this.loginFirebase('APPLE');
      },
    });
  }

  private loginFirebase(param) {
    //kondisi ulang by wildan
    let provider = null;
    if (param == 'GOOGLE') {
      provider = new GoogleAuthProvider();
      provider.addScope('email');
      provider.addScope('profile');
    } else if (param == 'FACEBOOK') {
      provider = new FacebookAuthProvider();
      provider.addScope('email');
      provider.addScope('public_profile');
    } else if (param == 'APPLE') {
      provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.addScope('openid');
    }
    try {
      provider.setCustomParameters({
        prompt: 'select_account',
      });
    } catch (error) {
      console.log(error);
    }
    const auth = getAuth();
    signInWithPopup(auth, provider, browserPopupRedirectResolver)
      .then(result => {
        //else facebook
        // const credential =
        //   param == 'GOOGLE' ? GoogleAuthProvider.credentialFromResult(result) : FacebookAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;

        let credential = null;
        if (param == 'GOOGLE') {
          credential = GoogleAuthProvider.credentialFromResult(result);
        } else if (param == 'FACEBOOK') {
          credential = FacebookAuthProvider.credentialFromResult(result);
        } else if (param == 'APPLE') {
          credential = OAuthProvider.credentialFromResult(result);
        }

        // The signed-in user info.

        let token = null;
        if (param == 'APPLE') {
          token = credential.idToken;
        } else {
          token = credential.accessToken;
        }

        // The signed-in user info.
        const email = (<any>result)._tokenResponse.email;

        console.log('token', token);

        console.log('credential', credential);
        if (param == 'GOOGLE') {
          this.generateTokenFromProvider(token, GOOGLE, email).then(res => {
            window.location.reload();
          });
        } else if (param == 'FACEBOOK') {
          this.generateTokenFromProvider(token, FACEBOOK, email).then(res => {
            window.location.reload();
          });
        } else if (param == 'APPLE') {
          this.generateTokenFromProvider(token, APPLE, email).then(res => {
            window.location.reload();
          });
        }

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  public onlyUniqueIds(value, index, self) {
    return self.indexOf(value) === index;
  }
  public deeperAssign(from, to) {
    Object.entries(to).forEach(([k, v]) => (from[k] = v && typeof v === 'object' ? this.deeperAssign((from[k] = from[k] || {}), v) : v));
    return from;
  }

  public get currentUser(): ICbVwUser {
    return this.store.getters.currentUser;
  }

  ///get  refresh_token provider

  private generateTokenFromProvider(token: string, provider: string, email?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/authenticate/generate-token/provider', {
          token: token,
          provider: provider == LINKEDIN ? process.env.LINKEDIN_SUBJECT_ISSUER : provider,
        })
        .then(async res => {
          await this.keepToken(res.data, true);
          try {
            await this.addLinkedAccountLocalDb(provider.toUpperCase()); //to handle  register social media and save linked account to db, must used wait before reload, and must try ti avoid enter to catch
          } catch (error) {
            console.error('addLinkedAccountLocalDb:', error);
          }
          resolve(true);
        })
        .catch(err => {
          this.logout();
          if (err.response && err.response.data) {
            this.store.commit('setVisibleModalIdpExist', true);
            this.store.commit('setEmailIdp', email);
            // this.
          }
          reject(err);
        });
    });
  }

  //Login linkedin

  public invokeLoginLinkedin() {
    if (!sessionStorage.getItem(SESSION_STORAGE_LINKEDIN_INSTATE)) {
      this.inState = Math.floor(Math.random() * 90000) + 10000;
      sessionStorage.setItem(SESSION_STORAGE_LINKEDIN_INSTATE, this.inState);
    } else {
      this.inState = sessionStorage.getItem(SESSION_STORAGE_LINKEDIN_INSTATE);
    }
    const url =
      process.env.LINKEDIN_URL + this.client_id + '&redirect_uri=' + this.redirect_uri + '&scope=' + this.scope + '&state=' + this.inState;

    this.win = window.open(encodeURI(url), 'LinkedIn Login', 'width=800, height=600, left=300, top=100');
    const that = this;
    const checkConnect = setInterval(function () {
      console.log(that.win);
      if (that.win && !that.win.window) {
        clearInterval(checkConnect);
        console.log('close popup');
        return;
      }

      if (!that.win) {
        console.log('1>>>>>>>>' + that.win);
        return;
      }

      //console.log("999")
      //console.log(that.win.opener.document.URL )
      if (that.win && that.win.document && that.win.document.URL == 'about:blank') {
        //        about:blank
        console.log('1url blank');
        return;
      }

      console.log('2>>>>>>>>' + that.win);

      that.getLinkedCode();
      clearInterval(checkConnect);
      console.log('3>>>>>>>>' + that.win);
    }, 100);
  }
  public getLinkedCode() {
    const cur_url = new URL(this.win.document.URL);
    const urlParams = new URLSearchParams(cur_url.search);

    if (urlParams.has('state') && sessionStorage.getItem(SESSION_STORAGE_LINKEDIN_INSTATE) == urlParams.get('state')) {
      console.log('state');

      if (urlParams.has('code')) {
        const code = urlParams.get('code');
        console.log('code: ' + code, cur_url.origin);

        const cbLinkedRequest: ICbLinkedinRequest = {
          redirectUri: cur_url.origin,
          code: code,
        };

        console.log('cbLinkedRequest', cbLinkedRequest);

        this.getAccessTokenLinkdin(cbLinkedRequest);
      } else if (urlParams.has('error') && urlParams.has('error_description')) {
        // close window
        //   parent.close();
      }
    }
    this.win.close();
  }

  private getAccessTokenLinkdin(cbLinkedRequest: ICbLinkedinRequest): Promise<ICbLinkedinResponse> {
    return new Promise<ICbLinkedinResponse>((resolve, reject) => {
      axios
        .post('services/cbuserms/api/account/linkedin/access-token', cbLinkedRequest)
        .then(res => {
          const responseLinkedin: ICbLinkedinResponse = res.data;
          console.log('responseLinkedin', responseLinkedin);
          this.generateTokenFromProvider(responseLinkedin.accessToken, LINKEDIN, responseLinkedin.email).then(res => {
            window.location.reload();
          });
        })
        .catch(err => {
          this.logout();
          reject(err);
        });
    });
  }

  public setNumberOfAjaxCAllPending(count) {
    this.store.commit('numberOfAjaxCAllPending', count);
  }

  //API to add linked account on local db when login or register social media
  private addLinkedAccountLocalDb(provider: string): Promise<ICbOid> {
    return new Promise<ICbOid>((resolve, reject) => {
      axios
        .post(`services/cbuserms/api/account/add-linked-account/${provider}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
