import Icon from '@/shared/icons/icon.vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import CUserBalanceTopup from './c-user-balance-topup/c-user-balance-topup.vue';
import PPayService from '@/services/pay.service';
import AlertService from '@/shared/alert/alert.service';
import { IPayVwBalanceCore, PayVwBalanceCore } from '@/shared/model/pay-vw-balance-core.model';
import { IPayVwTransactionSummaries, PayVwTransactionSummaries } from '@/shared/model/paw-vw-transaction-summaries.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { IPayPayload2023, PayPayload2023 } from '@/shared/model/pay-payload-2023.model';
import CFormPayment2023 from '../c-form-payment-2023/c-form-payment-2023.vue';
import { IPayDetailVA, PayDetailVA } from '@/shared/model/pay-detail-va.model';
import CCompletePaymentVa from '../c-complete-payment-va/c-complete-payment-va.vue';
@Component({
  components: { Icon, CUserBalanceTopup, CFormPayment2023, CCompletePaymentVa },
})
export default class cUserBalance extends Vue {
  // ================= START SERVICES ===================
  @Inject('pPayService') private pPayService: () => PPayService;
  @Inject('alertService') private alertService: () => AlertService;
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  public payVwBalanceCore: IPayVwBalanceCore = new PayVwBalanceCore();
  public payVwTransactionSummaries: IPayVwTransactionSummaries = new PayVwTransactionSummaries();
  public isFetchingPayVwTransactionSummaries = false;
  public isFetchingPayVwBalanceCore = false;
  public cbLoginType = CbLoginType;
  public isShowEyesBalance = false;

  public visibleFormPayment2023 = false;
  public paymentPayload: IPayPayload2023 = new PayPayload2023();
  public xenditUrl = '';
  public visibleXenditCC = false;
  public visibleVA = false;
  public payDetailVA: IPayDetailVA = new PayDetailVA();

  // ================= END hVARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  created() {
    this.findPayVwBalanceCore();
    this.findPayVwTransactionSummaries();

    this.$root.$off('cUserBalance::changeValue');
    this.$root.$on('cUserBalance::changeValue', (variable: string, value: any) => {
      this[variable] = value;
    });

    (<any>this.$root).$off('cUserBalance::getVwBalanceCore');
    (<any>this.$root).$on('cUserBalance::getVwBalanceCore', (callBack: (payVwBalanceCore: IPayVwBalanceCore) => void) => {
      callBack(this.payVwBalanceCore);
    });
  }
  mounted() {}
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================

  public handleShowEyes(type, value) {
    if (type == 'BALANCE') {
      this.$store.commit('setShowEyesBalance', value);
    } else if (type == 'REVENUE') {
      this.$store.commit('setShowEyesMonthRevenue', value);
    } else {
      this.$store.commit('setShowEyesMonthSpending', value);
    }
  }

  public handleTopup() {
    this.$root.$emit('appModals::changeFunction', 'handleTopup');
  }

  closeAll() {
    this.visibleFormPayment2023 = false;
    this.visibleVA = false;
    this.visibleXenditCC = false;
  }

  public findPayVwBalanceCore() {
    this.isFetchingPayVwBalanceCore = true;
    this.pPayService()
      .findVwBalanceByUserId()
      .then(res => {
        this.payVwBalanceCore = Object.assign({}, res);
        this.isFetchingPayVwBalanceCore = false;
      })
      .catch(error => {
        if (error.status && error.status != 404) {
          // this.alertService().showHttpError(this, error.response);
          this.isFetchingPayVwBalanceCore = false;
        }
      });
  }

  public findPayVwTransactionSummaries() {
    this.isFetchingPayVwTransactionSummaries = true;
    this.pPayService()
      .findPayVwTransactionSummaries()
      .then(res => {
        this.payVwTransactionSummaries = res;
        this.isFetchingPayVwTransactionSummaries = false;
      })
      .catch(error => {
        // this.alertService().showHttpError(this, error.response);
        this.isFetchingPayVwTransactionSummaries = false;
      });
  }

  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  public get loginType(): CbLoginType {
    return this.$store.getters.loginType;
  }
  public get getShowEyesBalance() {
    return this.$store.getters.getShowEyesBalance;
  }
  public get getShowEyesMonthRevenue() {
    return this.$store.getters.getShowEyesMonthRevenue;
  }
  public get getShowEyesMonthSpending() {
    return this.$store.getters.getShowEyesMonthSpending;
  }

  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
