import { ICbPawOrder } from '@/shared/model/cb-paw-order.model';

import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
export interface ICbPawOrderMilestone {
  id?: number;
  name?: string | null;
  progress?: number | null;
  isStart?: boolean | null;
  isEnd?: boolean | null;
  status?: CbCommonStatus | null;
  completedDate?: Date | string | null;
  xDealDate?: Date | null;
  xExpiredDate?: Date | null;
  deliveryTime?: number | null;
  deliveryTimeType?: string | null;
  isActive?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  cbPawOrder?: ICbPawOrder | null;

  // Transient
  time?: Date | string | null;
  completedDateMilestone?: Date | string | null;
}

export class CbPawOrderMilestone implements ICbPawOrderMilestone {
  constructor(
    public id?: number,
    public name?: string | null,
    public progress?: number | null,
    public isStart?: boolean | null,
    public isEnd?: boolean | null,
    public status?: CbCommonStatus | null,
    public completedDate?: Date | string | null,
    public xDealDate?: Date | null,
    public xExpiredDate?: Date | null,
    public isActive?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public cbPawOrder?: ICbPawOrder | null,

    // Transient
    public time?: Date | string | null,
    public completedDateMilestone?: Date | string | null
  ) {}
}
