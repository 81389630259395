import { Inject, Component, Vue, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import CButton from '../button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import CLogin from '../auth/login/c-login.vue';
import CRegister from '../auth/register/c-register.vue';
import CEditPhoneNumber from '../auth/c-edit-phone-number.vue';
import CNotificationEmail from '../auth/c-notification-email.vue';
import CNavbarSwitchLogintype from './component/c-navbar-switch-logintype/c-navbar-switch-logintype.vue';
import CUserPhotoOnline from '../c-user-photo-online/c-user-photo-online.vue';
import CNavbarDropdownMenu from './component/c-navbar-dropdown-menu/c-navbar-dropdown-menu.vue';
import CLoginIntroduction from '../c-login-introduction/c-login-introduction.vue';
import COfferDetail from '@/module/freelancer/components/c-offer-detail/c-offer-detail.vue';
import CNotification from '../c-notification/c-notification.vue';
import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import CQuickSearch from '../c-quick-search/c-quick-search.vue';
import CNavbarSearch from './component/c-navbar-search/c-navbar-search.vue';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import TranslationService from '@/locale/translation.service';
import vClickOutside from 'v-click-outside';
import CTimeRegionMUpdate from '../c-time-region-m/c-time-region-m-update/c-time-region-m-update.vue';
import { CbUserTimezone, ICbUserTimezone } from '@/shared/model/cb-user-timezone.model';
import { TIME_24_FORMAT } from '@/shared/date/filters';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import CPhWarning from '../c-alert copy/c-ph-warning.vue';
import { Debounce } from '@/shared/decorator/debounce';
import GlobalService from '@/services/global.service';
export enum NavbarType {
  GENERAL = 'GENERAL',
  BUYER = 'BUYER',
  PARTNER = 'PARTNER',
  CMS = 'CMS',
}

let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();
    }, 66);
  }
}
@Component({
  components: {
    CUserPhotoOnline,
    CButton,
    Icon,
    CLogin,
    CRegister,
    CEditPhoneNumber,
    CNotificationEmail,
    CNavbarSwitchLogintype,
    CNavbarDropdownMenu,
    CLoginIntroduction,
    COfferDetail,
    CNotification,
    CQuickSearch,
    CNavbarSearch,
    CConfirmation,
    CTimeRegionMUpdate,
    CPhWarning,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class CNavbar extends Vue {
  // ================= SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  // =================END SERVICES =================
  // ================= VARIABLES =================
  public navbarType = NavbarType;
  public cbUserInfo: ICbUserInfo = new CbUserInfo();

  public cbSvcType = CbSvcType;
  public search = '';
  public searchValue: any[] = [
    { name: 'Graphic & Design' },
    { name: 'Programming & Tech' },
    { name: 'Bismillah' },
    { name: 'Graphic & Design' },
    { name: 'Graphic & Design' },
    { name: 'Graphic & Design' },
  ];
  public newSearchValue: any[] = [];
  public toggle = false;

  public timeUtcDisplay = '00:00';
  public timeUtcServer: Date;
  public showNavbarMobile = false;
  public t = 0;

  public isShowDropdownMenu = false;
  public isShowUTC = false;
  public isShowExplore = false;
  public isShowMail = false;

  public cbUserTimezoneUTC: ICbUserTimezone = new CbUserTimezone();

  public isShowQuickSearch = false;

  public textColor = '';
  public isInputActived = false;
  public delayedInputState = true;
  // =================END VARIABLES =================
  // ================= DEFAULT FUNCTION =================
  public created() {
    (<any>this.$root).$off('navbar::changeFunction');
    (<any>this.$root).$on('navbar::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
    (<any>this.$root).$off('navbar::changeValue');
    (<any>this.$root).$on('navbar::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
      if (navigator.userAgent.includes('iPad') || (navigator.userAgent.includes('Macintosh') && 'ontouchend' in document)) {
        // Kode yang hanya akan dijalankan di iPad
        if (this.isInputActived) {
          this.applyDelayedStyle(true);
        } else if (!this.isInputActived) {
          this.applyDelayedStyle(false);
        }
      }
    });

    this.$store.watch(
      () => this.currentUser,
      () => {
        if (this.currentUser && this.currentUser.id) {
          this.initTimeZoneUTC();
        }
      }
    );
    this.initTimeZoneUTC();
  }
  public mounted() {
    document.addEventListener('scroll', this.scrollListener);
  }

  public isScrolling;

  handleScroll(): void {
    // const scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
  }

  public scrollListener() {
    resizeThrottler(this.handleScroll);
    window.clearTimeout(this.isScrolling);

    this.isScrolling = setTimeout(function () {}, 66);
  }
  // =================END DEFAULT FUNCTION =================
  // ================= FUNCTION =================

  public selectResult(searchValue) {
    this.search = searchValue.name;
  }

  public toPostRequest() {
    this.$router.push({ name: 'PBuyerPostRequest' });
  }

  public handleOpenLogin() {
    (<any>this.$root).$emit('handleOpenLogin');
  }
  public handleOpenRegister() {
    (<any>this.$root).$emit('handleOpenRegister');
  }

  public toSvcPage(svc) {
    this.$router.push({ name: 'PSvc', params: { id: svc } });
  }

  public toNavbarMenu() {
    this.$router.push({ name: 'CNavbarMenu' });
  }

  public closeIsShowUTC() {
    this.isShowUTC = false;
  }

  // =================END FUNCTION =================
  // ================= COMPUTE =================

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public searchAutocomplete(e) {
    const value = e.target.value;
    e.currentTarget.value = value;
    const filter = this.searchValue.filter(val => val.name.inludes(value));
    this.newSearchValue = filter;
  }

  public beforeDestroy() {
    console.log('beforeDestroy destop navbar');
    document.removeEventListener('scroll', this.scrollListener);
  }
  public get loginType() {
    return this.$store.getters.loginType;
  }

  public redirectHome() {
    if (this.loginType == CbLoginType.BUYER) {
      return this.$router.push({
        name: 'PBuyerHome',
      });
    } else if (this.loginType == CbLoginType.PARTNER) {
      return this.$router.push({
        name: 'PFreelancerHome',
      });
    } else {
      return this.$router.push('/');
    }
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get isSetUsername(): boolean {
    return this.$store.getters.isSetUsername;
  }

  public initTimeZoneUTC() {
    if (!this.currentUser) {
      return;
    }
    this.setTimeUtcStore();
    setInterval(this.setTimeUtcStore, 1000);
  }

  public handleCloseDropdownMenu() {
    this.isShowDropdownMenu = false;
  }

  public setTimeUtcStore() {
    const utcTime = dayjs().utc().format(TIME_24_FORMAT);
    this.cbUserTimezoneUTC.timeFormat = utcTime;
  }

  public showLanguage() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-change-language');
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public get countNotifTotal(): number {
    return this.$store.getters.countNotif + this.$store.getters.countMsg;
  }

  public get getAuthernticated(): any {
    return this.$store.getters.authenticated;
  }
  @Debounce(60)
  public applyDelayedStyle(value) {
    this.delayedInputState = value;
  }

  // =================END COMPUTE =================
  // ================= START LISTENERS =================

  @Watch('search')
  public searchs(val) {
    this.newSearchValue = [];
    this.searchValue.forEach(element => {
      if (element.name.includes(val) && val != '') {
        this.newSearchValue.push(element);
      }
    });
  }
  // ================= END LISTENERS ===================
}
