import { CmApp } from '@/shared/model/enumerations/cm-app.model';
import { CmTktCategory } from '@/shared/model/enumerations/cm-tkt-category.model';
import { CmTktChannel } from '@/shared/model/enumerations/cm-tkt-channel.model';
import { CmTktPriority } from '@/shared/model/enumerations/cm-tkt-priority.model';
import { ICmTktSender } from './cm-tkt-sender.model';
import { ICmTktEscalate } from './enumerations/cm-tkt-escalate.model';
export interface ICmTkt {
  id?: number;
  title?: string | null;
  description?: string | null;
  app?: CmApp | null;
  category?: CmTktCategory | null;
  channel?: CmTktChannel | null;
  priority?: CmTktPriority | null;
  isUpdated?: boolean | null;
  isArchived?: boolean | null;
  isAccepted?: boolean | null;
  acceptedBy?: string | null;
  acceptedDate?: Date | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  userType?: string | null;
  externalId?: string | null;
  externalClass?: string | null;
  cmTktSender?: ICmTktSender | null;
  contactReason?: string | null;
  subContactReason?: string | null;
  cmTktEscalates?: ICmTktEscalate[] | null;
}

export class CmTkt implements ICmTkt {
  constructor(
    public id?: number,
    public title?: string | null,
    public description?: string | null,
    public app?: CmApp | null,
    public category?: CmTktCategory | null,
    public channel?: CmTktChannel | null,
    public priority?: CmTktPriority | null,
    public isUpdated?: boolean | null,
    public isArchived?: boolean | null,
    public isAccepted?: boolean | null,
    public acceptedBy?: string | null,
    public acceptedDate?: Date | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public userType?: string | null,
    public externalId?: string | null,
    public externalClass?: string | null,
    public cmTktSender?: ICmTktSender | null,
    public contactReason?: string | null,
    public subContactReason?: string | null
  ) {
    // this.isUpdated = this.isUpdated ?? false;
    // this.isArchived = this.isArchived ?? false;
  }
}
