var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-item-chat-message-interest p-2 cj-bg-white-primary",
      attrs: { "data-cy": "c-item-chat-message-interest" },
    },
    [
      _c("icon", {
        staticClass: "ic_medal_interested",
        attrs: { name: "ic_medal_interested", width: "4rem", height: "4rem" },
      }),
      _vm._v(" "),
      !_vm.cbVwPaw ||
      (_vm.cbVwPaw && _vm.cbVwPaw?.status !== _vm.CbCommonStatus.ACTIVE)
        ? _c(
            "div",
            {
              ref: "underReview",
              staticClass:
                "d-flex align-items-center rounded border-width-1 p-1 cj-border-color-red-primary",
            },
            [
              _c("icon", {
                staticClass: "cj-fill-red-primary mr-2",
                attrs: {
                  name: "ic_exclamation",
                  width: "25px",
                  height: "25px",
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "c-icm-font-forbidden",
                attrs: { "data-cy": "message-paw-not-available" },
                domProps: {
                  textContent: _vm._s(_vm.$t("cbGlobal.cardChat.msgInterest")),
                },
              }),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["underReview"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center" },
                    [
                      _c("icon", {
                        staticClass: "cj-fill-red-primary mr-2",
                        attrs: {
                          name: "ic_exclamation",
                          width: "25px",
                          height: "25px",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass:
                          "font-catamaran font-size-10 cj-color-white-primary",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbDetailOrder.unfortunatelyPAW")
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "div",
                { ref: "cbPawOrder.title", staticClass: "text-center mb-2" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-card-title text-ellipsis-oneline mx-auto max-title-wrapper",
                      attrs: { "data-cy": "message-interest-paw-title" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbPawOrder.pawTitle) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-card-subtitle mb-1 text-ellipsis-oneline",
                      attrs: {
                        "data-cy": "message-interest-paw-svc-and-category",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbPawOrder.svcName) +
                          " | " +
                          _vm._s(_vm.cbPawOrder.svcCategoryName) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "custom-tooltip",
                      attrs: {
                        target: () => _vm.$refs["cbPawOrder.title"],
                        variant: "primary",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "c-icm-font-card-title text-break-all mx-auto max-title-wrapper",
                          attrs: { "data-cy": "message-interest-paw-title" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.cbPawOrder.pawTitle) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "c-icm-font-card-subtitle mb-1 text-break-all",
                          attrs: {
                            "data-cy": "message-interest-paw-svc-and-category",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.cbPawOrder.svcName) +
                              " | " +
                              _vm._s(_vm.cbPawOrder.svcCategoryName) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "cbPawOrder.pawPkgType",
                  staticClass:
                    "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-pkg-title mb-1 text-ellipsis oneline",
                      attrs: { "data-cy": "message-interest-paw-pkg-type" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("capitalEachSpace")(
                              _vm.cbPawOrder.pawPkgType
                            )
                          ) +
                          "\n        "
                      ),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbDetailOrder.package")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "c-icm-font-price-title",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbDetailOrder.totalPrice")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-price-content mb-1 text-ellipsis oneline",
                      attrs: { "data-cy": "message-interest-paw-price" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbPawOrder.currency || "IDR") +
                          " " +
                          _vm._s(
                            _vm.$options.filters.formatCurrency(
                              _vm.cbPawOrder.projectValueAmount,
                              _vm.cbPawOrder.currency
                            ) || 0
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("c-textarea-preview", {
                    staticClass:
                      "c-icm-font-description-content mb-1 text-break",
                    attrs: {
                      "data-cy-custom": "message-interest-paw-desc",
                      text: _vm.cbPawOrder.pawPkgDescription,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex justify-content-around" }, [
                    _c("div", { staticClass: "d-flex text-ellipsis-oneline" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex my-auto" },
                        [
                          _c("icon", {
                            staticClass: "cj-fill-red-primary mr-1",
                            attrs: {
                              name: "ic_time_outlined",
                              width: "16px",
                              height: "16px",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "c-icm-font-delivery-content my-auto text-ellipsis-oneline",
                          attrs: { "data-cy": "message-interest-paw-delivery" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getDeliveryTimeType(
                                  _vm.cbPawOrder.deliveryTimeType,
                                  _vm.cbPawOrder.deliveryTime
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.cbPawOrder.revision &&
                    _vm.cbPawOrder.svcType === _vm.CbSvcType.INSIDE
                      ? _c(
                          "div",
                          { staticClass: "d-flex text-ellipsis-oneline ml-1" },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex my-auto" },
                              [
                                _c("icon", {
                                  staticClass: "cj-fill-red-primary",
                                  staticStyle: {
                                    position: "relative",
                                    left: "-2px",
                                    "margin-right": "2px",
                                  },
                                  attrs: {
                                    name: "ic_revision",
                                    width: "16px",
                                    height: "16px",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "c-icm-font-delivery-content my-auto text-ellipsis-oneline",
                                attrs: {
                                  "data-cy": "message-interest-paw-revision",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.cbPawOrder.revision) +
                                    "\n            "
                                ),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        _vm.getRevisionTextEnId(
                                          _vm.cbPawOrder.revision
                                        )
                                      )
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "custom-tooltip",
                      attrs: {
                        target: () => _vm.$refs["cbPawOrder.pawPkgType"],
                        variant: "primary",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.cbPawOrder.pawPkgType) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", {
                        staticClass:
                          "font-catamaran font-size-12 font-weight-500",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbDetailOrder.totalPrice")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.cbPawOrder.currency || "IDR") +
                              "\n          " +
                              _vm._s(
                                _vm.$options.filters.formatCurrency(
                                  _vm.cbPawOrder.projectValueAmount,
                                  _vm.cbPawOrder.currency
                                ) || 0
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("c-textarea-preview", {
                        staticClass:
                          "font-catamaran font-size-12 font-weight-500 text-center",
                        staticStyle: {
                          color: "var(--cj-color-white-primary) !important",
                        },
                        attrs: { text: _vm.cbPawOrder.pawPkgDescription },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex text-ellipsis-oneline" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex my-auto" },
                                [
                                  _c("icon", {
                                    staticClass: "cj-fill-red-primary mr-1",
                                    attrs: {
                                      name: "ic_time_outlined",
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "c-icm-font-delivery-content my-auto text-ellipsis-oneline",
                                  attrs: {
                                    "data-cy": "message-interest-paw-delivery",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.getDeliveryTimeType(
                                          _vm.cbPawOrder.deliveryTimeType,
                                          _vm.cbPawOrder.deliveryTime
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.cbPawOrder.revision &&
                          _vm.cbPawOrder.svcType === _vm.CbSvcType.INSIDE
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex text-ellipsis-oneline ml-1",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex my-auto" },
                                    [
                                      _c("icon", {
                                        staticClass: "cj-fill-red-primary",
                                        staticStyle: {
                                          position: "relative",
                                          left: "-2px",
                                          "margin-right": "2px",
                                        },
                                        attrs: {
                                          name: "ic_revision",
                                          width: "16px",
                                          height: "16px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-icm-font-delivery-content my-auto text-ellipsis-oneline",
                                      attrs: {
                                        "data-cy":
                                          "message-interest-paw-revision",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.cbPawOrder.revision) +
                                          "\n              "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              _vm.getRevisionTextEnId(
                                                _vm.cbPawOrder.revision
                                              )
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "rounded border-width-1 p-2 text-left mb-2 cj-border-color-red-primary",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-between",
                      attrs: { "data-cy": "message-interest-paw-pkg-wrap" },
                    },
                    [
                      _c("p", {
                        staticClass: "c-icm-font-title my-auto",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbDetailOrder.packageInclude")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-block" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                            attrs: {
                              "data-cy": "message-interest-paw-btn-drop-pkg",
                              type: "button",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isExpandPackageInclude =
                                  !_vm.isExpandPackageInclude
                              },
                            },
                          },
                          [
                            _c("icon", {
                              staticClass:
                                "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                              class: _vm.isExpandPackageInclude
                                ? "actived"
                                : "",
                              attrs: {
                                name: "ic_down",
                                width: "1rem",
                                height: "1rem",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      model: {
                        value: _vm.isExpandPackageInclude,
                        callback: function ($$v) {
                          _vm.isExpandPackageInclude = $$v
                        },
                        expression: "isExpandPackageInclude",
                      },
                    },
                    [
                      _vm._l(
                        _vm.cbPawOrder.cbPawOrderIncludes,
                        function (includes, index) {
                          return [
                            includes.value
                              ? _c(
                                  "div",
                                  { key: index, staticClass: "d-flex mb-1" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex my-auto mr-1" },
                                      [
                                        _c("icon", {
                                          staticClass:
                                            "cj-fill-red-primary my-auto",
                                          attrs: {
                                            name: "ic_check",
                                            width: "1.5rem",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "c-icm-font-content my-auto text-break-all",
                                        attrs: {
                                          "data-cy":
                                            "message-interest-paw-pkg-include",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("capitalFirstLetter")(
                                                includes.value
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.cbVwPaw &&
              _vm.cbVwPaw.id &&
              _vm.cbPawPreOrder &&
              _vm.cbPawPreOrder.id &&
              _vm.cbVwPaw.svcType == _vm.CbSvcType.FIELD
                ? [
                    _c(
                      "div",
                      {
                        ref: "getBookDate",
                        staticClass:
                          "rounded border-width-1 p-2 text-left mb-2 cj-border-color-red-primary",
                      },
                      [
                        _c("p", {
                          staticClass: "c-icm-font-title mb-1",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbDetailOrder.bookDate")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "c-icm-font-content text-left",
                            attrs: {
                              "data-cy": "message-interest-booking-date",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$options.filters.convertDateToAccordanceTimeZone(
                                    _vm.cbPawPreOrder.bookStartDate,
                                    _vm.cbPawPreOrder.timeZone
                                  )
                                ) +
                                "\n\n          "
                            ),
                            _c(
                              "b-tooltip",
                              {
                                staticClass: "custom-tooltip",
                                attrs: {
                                  target: () => _vm.$refs["getBookDate"],
                                  variant: "primary",
                                },
                              },
                              [
                                _c("p", {
                                  staticClass:
                                    "font-catamaran font-size-12 font-weight-500",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("cbgwApp.cbDetailOrder.bookDate")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-catamaran font-size-12 font-weight-500",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$options.filters.convertDateToAccordanceTimeZone(
                                            _vm.cbPawPreOrder.bookStartDate,
                                            _vm.cbPawPreOrder.timeZone
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "rounded border-width-1 p-2 text-left mb-2 cj-border-color-red-primary",
                      },
                      [
                        _c("p", {
                          staticClass: "c-icm-font-title mb-1",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbDetailOrder.workLocation")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _vm.cbPawPreOrder.cbLocation &&
                        _vm.cbPawPreOrder.cbLocation.address
                          ? _c("div", [
                              _c(
                                "p",
                                {
                                  ref: "cbPawPreOrder.cbLocation",
                                  staticClass:
                                    "mb-1 c-icm-font-content text-ellipsis oneline",
                                  attrs: {
                                    "data-cy": "message-interest-work-location",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("capitalFirstLetter")(
                                          _vm.cbPawPreOrder.cbLocation.address
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", {
                                staticClass:
                                  "c-icm-font-text-btn cj-btn-text-primary",
                                attrs: { "data-cy": "message-btn-open-map" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("cbGlobal.viewInMap")
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenLocation(
                                      _vm.cbPawPreOrder.cbLocation
                                    )
                                  },
                                },
                              }),
                            ])
                          : _c("div", [
                              _c("p", { staticClass: "c-icm-font-content" }, [
                                _vm._v("No Data"),
                              ]),
                            ]),
                        _vm._v(" "),
                        _c(
                          "b-tooltip",
                          {
                            staticClass: "custom-tooltip",
                            attrs: {
                              target: () =>
                                _vm.$refs["cbPawPreOrder.cbLocation"],
                              variant: "primary",
                            },
                          },
                          [
                            _c("p", {
                              staticClass:
                                "font-catamaran font-size-12 font-weight-500",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("cbgwApp.cbDetailOrder.workLocation")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _vm.cbPawPreOrder.cbLocation &&
                            _vm.cbPawPreOrder.cbLocation.address
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-catamaran font-size-12 font-weight-500",
                                    attrs: {
                                      "data-cy":
                                        "message-interest-work-location",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm._f("capitalFirstLetter")(
                                            _vm.cbPawPreOrder.cbLocation.address
                                          )
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-catamaran font-size-12 font-weight-500",
                                  },
                                  [_vm._v("No Data")]
                                ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.cbPawPreOrder.partnerId === _vm.currentUser.id &&
              !_vm.cbPawPreOrder.status
                ? _c("div", { staticClass: "d-flex flex-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "w-100 mb-2 mx-1" },
                      [
                        _c(
                          "c-button",
                          {
                            staticClass: "c-icm-font-btn",
                            attrs: {
                              fullWidth: "",
                              typeStyle: "secondary",
                              "data-cy": "message-interest-paw-btn-edit",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openDraftPawOrder("EDIT")
                              },
                            },
                          },
                          [_vm._v("\n          EDIT")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w-100 mb-2 mx-1" },
                      [
                        _c(
                          "c-button",
                          {
                            staticClass: "c-icm-font-btn",
                            attrs: {
                              fullWidth: "",
                              "data-cy": "message-interest-paw-btn-accept",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.openDraftPawOrder("ACCEPT")
                              },
                            },
                          },
                          [_vm._v("\n          ACCEPT\n        ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }