import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CButton from '../button/c-button.vue';
import Vue2Filters from 'vue2-filters';
import { CbPromoValueType } from '@/shared/model/enumerations/cb-promo-value-type.model';
import { CbVwPromoUserAgg, ICbVwPromoUserAgg } from '@/shared/model/cb-vw-promo-user-agg.model';
import { CbPromoTagType } from '@/shared/model/enumerations/cb-promo-tag-type.model';
import { AdvanceSearchFilterKey } from '@/shared/model/advance-search-model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';

@Component({
  mixins: [Vue2Filters.mixin],
  components: {
    Icon,
    CButton,
  },
})
export default class CVoucherItem extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwPromo: CbVwPromoUserAgg;
  @Prop({ default: true }) isShowUseButton: boolean;
  @Prop({ default: false }) isShowMessage: boolean;
  @Prop({default: false}) isFromPayForm: boolean;

  public cbPromoValueType = CbPromoValueType;
 
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public toAdvancedSearch(promo: ICbVwPromoUserAgg) {
    let queryObj = {};
    if (promo.cbPromoSpecifics != null) {
      for (const spec of promo.cbPromoSpecifics.split('||')) {
        const specDtl = spec.split('::');

        if (specDtl[0] == CbPromoTagType.SERVICE_FIELD) {
          queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.FIELD;
        } else if (specDtl[0] == CbPromoTagType.SERVICE_INSIDE) {
          queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.INSIDE;
        } else if (specDtl[0] == CbPromoTagType.SERVICE_SPECIFIC) {
          if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] == null) {
            queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] = [];
            queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
          } else {
            queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
          }
        } else if (specDtl[0] == CbPromoTagType.CATEGORY_SPECIFIC) {
          if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] == null) {
            queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] = [];
            queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
          } else {
            queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
          }
        }
      }
    }

    queryObj['promoCode'] = promo.code;

    this.$router.push({
      name: 'PAdvanceSearch',
      params: { key: new Date().getTime().toString() },
      query: queryObj,
    });
  }

  public handlClickPromo(cbVwPromo: ICbVwPromoUserAgg) {
    if(!this.isFromPayForm ) {

      return;
    }
    if (
      cbVwPromo.isValidWithThisOrder &&
      !cbVwPromo.isExceedUsage &&
      cbVwPromo.isValidMinimumTransaction &&
      cbVwPromo.isValid &&
      cbVwPromo.isValidWithPayType &&
      cbVwPromo.isSpecificPromoValid
    ) {
      this.$emit('handlClickPromo', cbVwPromo);
    }

    
  }

 
  public get getPromoTag() {
    let promoTags: string[] = [];
    let promoValue: string[] = [];

    if (this.cbVwPromo.cbPromoSpecifics && this.cbVwPromo.cbPromoSpecifics.length > 0) {
      let promoTagSplit = this.cbVwPromo.cbPromoSpecifics.split('||');
      for (let item of promoTagSplit) {
        let fields = item.split('::');
        let findIndex = promoTags.findIndex(e => fields[0] && e.toUpperCase() == fields[0].toUpperCase());
        if (findIndex < 0) {
          promoTags.push(fields[0]);
        }
      }
    }
  
    if (promoTags.includes('CATEGORY_ALL') && promoTags.includes('SERVICE_ALL')) {
      promoValue = promoTags.filter(e => e.includes('SERVICE_ALL')).slice(0, 1);
    } else if (promoTags.includes('SERVICE_ALL')) {
      promoValue = promoTags.filter(e => e.includes('SERVICE_ALL')).slice(0, 1);
    } else if (promoTags.includes('CATEGORY_ALL')) {
      promoValue = promoTags.filter(e => e.includes('CATEGORY_ALL')).slice(0, 1);
    } else if (promoTags.includes('SPECIFIC')) {
      promoValue = promoTags.filter(e => e.includes('SPECIFIC')).slice(0, 1);
    }
    return promoValue;
  }

  public get messageInvalidPromo() {

    if(!this.cbVwPromo.isValidWithThisOrder) {
      return this.$t('cbgwApp.cbPromo.invalidMessage.notValidWithThisTransaction').toString();
    } 

    if(this.cbVwPromo.isExceedUsage) {
      return this.$t('cbgwApp.cbPromo.invalidMessage.exceedUsage').toString();
    }

    if(!this.cbVwPromo.isSpecificPromoValid) {
      return this.$t('cbgwApp.cbPromo.invalidMessage.invalidSpecificPromo').toString();
    }

    if(!this.cbVwPromo.isValidWithPayType) {
      return this.$t('cbgwApp.cbPromo.invalidMessage.invalidPaytype').toString();
    }

    if(!this.cbVwPromo.isValidMinimumTransaction) {
      return this.$t('cbgwApp.cbPromo.invalidMessage.invalidMinimumTransaction').toString();
    }

    return null;
  }

  public get isPromoDisabled() {
    return (cbVwPromoUserAgg: ICbVwPromoUserAgg) => {
      if(!this.isFromPayForm) {
        return false;
      }
      if (
        (cbVwPromoUserAgg.isValidWithThisOrder &&
        !cbVwPromoUserAgg.isExceedUsage &&
        cbVwPromoUserAgg.isValidMinimumTransaction &&
        cbVwPromoUserAgg.isValid &&
        cbVwPromoUserAgg.isValidWithPayType &&
        cbVwPromoUserAgg.isSpecificPromoValid ) 
      ) {
        return false;
      }
      return true;
    };
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
