import PCbUserCookieService from '@/services/cb-user-cookie.service';
import GlobalService from '@/services/global.service';
import AlertService from '@/shared/alert/alert.service';
import { CbUserCookie, ICbUserCookie } from '@/shared/model/cb-user-cookie.model';
import { Inject, Component, Vue, Emit } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
import ContentService from '@/services/content.service';
import { CbHelpCenterType } from '@/shared/model/enumerations/cb-help-center-type.model';
import CTmpAnnouncement from '../c-tmp-announcement/c-tmp-announcement.vue';

const validations: any = {
  cbUserCookie: {
    userId: {},
    mandatoryCookie: {},
    analyticalCookie: {},
    advertisingCookie: {},
    isActive: {},
    createdDate: {},
    createdBy: {},
    lastModifiedBy: {},
    lastModifiedDate: {},
  },
};
@Component({
  validations,
  components: {
    CButton,
    CTmpAnnouncement
  },
})
export default class CCookiesSetting extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbUserCookieService') private pCbUserCookieService: () => PCbUserCookieService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('contentService') private contentService: () => ContentService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public isShowModalCookiePolicy: boolean = false;
  public cbUserCookie: ICbUserCookie = new CbUserCookie();
  public isSaving = false;
  public isFetching = false;
  public cbUserCookies: ICbUserCookie[] = [];

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.contentService().initRetrieveCbVeHelpCenters(CbHelpCenterType.COOKIE_POLICY);
  }
  public mounted() {
    this.retrieveAllCbUserCookies();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  @Emit('cancel')
  public cancel() {
    return;
  }

  public openModalCookiesPolicy() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-cookie-setting');
  }

  public retrieveAllCbUserCookies(): void {
    this.isFetching = true;
    this.pCbUserCookieService()
      .retrieve()
      .then(
        res => {
          this.cbUserCookies = res.data;
          this.isFetching = false;
        },
        err => {
          this.isFetching = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public onSubmit() {
    this.isSaving = true;
    if (this.authenticated) {
      this.pCbUserCookieService()
        .createByLogin(this.cbUserCookie)
        .then(param => {
          this.isSaving = false;
          this.$store.commit('userCookie', param);
          this.globalService().closeGlobalDialog(this.$root, 'modal-c-cookie-setting');
          const message = 'Cookie Successfully Created';
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          });
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    } else {
      this.pCbUserCookieService()
        .create(this.cbUserCookie)
        .then(param => {
          this.isSaving = false;
          this.$store.commit('userCookie', param);
          const message = 'Cookie Successfully Created';
          this.globalService().closeGlobalDialog(this.$root, 'modal-c-cookie-setting');
          (this.$root as any).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
          });
        })
        .catch(error => {
          this.isSaving = false;
          this.alertService().showHttpError(this, error.response);
        });
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get COOKIE_POLICY() {
    if (this.currentLanguage == 'id') {
      return this.$store.getters.COOKIE_POLICY_ID[0]?.cbHelpCenterAggs[0]?.cbTextNoAuth?.content;
    }
    return this.$store.getters.COOKIE_POLICY_EN[0]?.cbHelpCenterAggs[0]?.cbTextNoAuth?.content;
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
