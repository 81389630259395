var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-popular-search-list" },
    [
      _vm.cbVwSvcPopulars.length === 0
        ? _c(
            "div",
            [
              _c("c-loader", {
                staticClass: "d-flex m-auto c-loader-red",
                attrs: { show: true, infinite: true },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.cbVwSvcPopulars, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "d-flex my-3 cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.toSvc(item)
              },
            },
          },
          [
            _c("div", [
              _c("img", {
                staticClass: "border-radius-card m-auto",
                staticStyle: { width: "3.211rem", height: "3.209rem" },
                attrs: {
                  src: _vm.$options.filters.LAZY(
                    `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${item.logoId}`,
                    51
                  ),
                  onerror: _vm.$options.filters.LAZY_ERROR(),
                  alt: "Image Picture",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "p-0 m-0 ml-3 my-auto font-size-14 font-weight-400 font-roboto cj-color-black-primary search-text-ellipses",
              },
              [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }