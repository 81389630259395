import axios from 'axios';

import { ICbUserReview } from '@/shared/model/cb-user-review.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-reviews';

export default class PCbUserReviewService {
  public create(entity: ICbUserReview): Promise<ICbUserReview> {
    return new Promise<ICbUserReview>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
