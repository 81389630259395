export enum CbInputCurrencyType {
  IDR = 'id-ID',
  USD = 'en-US',
  EUR = 'eu',
}

// SOURCE LOCALES
// ? https://gist.github.com/ncreated/9934896

// LOCALES
// en-IE      = €1,205.34
// ro-MD      = 1.205,34 MDL
// br         = ¤ 1 205,34
// en-GY      = $1,205
// es-GT      = Q1,205.34
// shi-Tfng-M = 1 205,34MAD
// mr         = ¤१,२०५.३४
// bs         = ¤ 1.205,34
// en-AS      = $1,205.34
// ksf        = 1 205,34 ¤
// bs-Cyrl    = 1.205,34 ¤
// en-PR      = $1,205.34
// ms         = ¤1,205.34
// sr-Latn-ME = 1.205,34 €
// mt         = ¤1,205.34
// ha         = ¤ 1,205.34
// nb-NO      = kr 1 205,34
// en-SE      = 1 205:34 SEK
// en-BZ      = $1,205.34
// pt-BR      = R$1.205,34
// or-IN      = ₹ 1,205.34
// is-IS      = kr1.205
// mn-MN      = ₮ 1 205
// ar-IQ      = ١٢٠٥ د.ع.‏
// he         = 1,205.34 ¤
// it-SM      = € 1.205,34
// en-AT      = € 1.205,34
// bas        = 1 205,34 ¤
// ckb        = ¤ ١٬٢٠٥٫٣٤
// my         = ¤ ၁,၂၀၅.၃၄
// zh-CN      = ￥1,205.34
// mer        = ¤1,205.34
// ks-Arab-IN = ₹ ۱٬۲۰۵٫۳۴
// en-JM      = $1,205.34
// dz-BT      = Nu.༡,༢༠༥.༣༤
// ms-Arab-BN = $ 1.205,34
// cy-GB      = £1,205.34
// sg         = ¤1.205,34
// it-CH      = CHF 1'205.34
// teo-KE     = Ksh1,205.34
// de-LU      = 1.205,34 €
// en-US      = $1,205.34
// hi         = ¤ 1,205.34
// hu-HU      = 1 205 Ft
// uz-Latn-UZ = сўм 1,205
// af-NA      = $ 1 205,34
// si         = ¤ 1,205.34
// fr-BI      = 1 205 FBu
// ga-IE      = €1,205.34
// mfe        = ¤ 1 205.34
// en-CA      = $1,205.34
// ne-IN      = ₹ १,२०५.३४
// rwk-TZ     = 1,205TSh
// en-AU      = $1,205.34
// sk         = 1 205,34 ¤
// teo        = ¤1,205.34
// en-PT      = 1 205,34 €
// en-NG      = ₦1,205.34
// sl         = ¤1.205,34
// tk-TM      = 1 205,34m.
// tzm        = 1 205,34 ¤
// ee-GH      = GH₵1,205.34
// kde        = ¤1,205.34
// sn         = ¤1,205.34
// dyo-SN     = 1 205 CFA
// en-SG      = $1,205.34
// mas-TZ     = TSh1,205
// so         = ¤1,205.34
// nyn-UG     = USh1,205
// br-FR      = € 1 205,34
// fr-BJ      = 1 205 CFA
// es-IC      = 1.205,34 €
// pt-MZ      = 1 205,34 MTn
// hr         = 1.205,34 ¤
// az         = ¤ 1.205,34
// sq         = ¤1 205,34
// sr         = 1.205,34 ¤
// sw-KE      = Ksh1,205.34
// ca         = ¤1.205,34
// hu         = 1 205,34 ¤
// et-EE      = 1 205,34 €
// lag-TZ     = TSh 1,205
// bn-IN      = ১,২০৫.৩৪₹
// nb         = ¤ 1 205,34
// sv         = 1 205:34 ¤
// th-TH      = ฿1,205.34
// ml-IN      = 1,205.34₹
// sr-RS      = 1.205 дин.
// sw         = ¤1,205.34
// nd         = ¤1,205.34
// ta-IN      = ₹ 1,205.34
// fr-MQ      = 1 205,34 €
// hy         = 1205,34 ¤
// en-TO      = T$1,205.34
// es-AR      = $1.205,34
// ne         = ¤ १,२०५.३४
// pt-AO      = 1 205,34 Kz
// ne-NP      = नेरू १,२०५.३४
// ar-BH      = ١٢٠٥٫٣٤٠ د.ب.‏
// en-SI      = €1.205,34
// bo-IN      = ₹ 1,205.34
// hi-IN      = ₹ 1,205.34
// seh        = 1.205,34¤
// de-DE      = 1.205,34 €
// ko-KP      = KPW1,205
// fr-BL      = 1 205,34 €
// fr-MR      = 1 205 UM
// ca-AD      = €1.205,34
// fa-IR      = ‎﷼۱٬۲۰۵
// nl         = ¤ 1.205,34
// es-PR      = $1,205.34
// en-PW      = $1,205.34
// rn-BI      = 1.205FBu
// nn         = 1 205,34 ¤
// kk         = 1 205,34 ¤
// en-DK      = 1.205,34 DKK
// sl-SI      = €1.205,34
// dua        = 1 205,34 ¤
// kea        = 1.205,34¤
// ms-Arab-MY = RM1,205.34
// ig-NG      = ₦1,205.34
// kln        = ¤1,205.34
// yo         = ¤1,205.34
// fr-DZ      = 1 205,34 DA
// sv-FI      = 1 205:34 €
// fr-SY      = 1 205 LS
// ru-MD      = 1 205,34 MDL
// en-ZW      = US$1,205.34
// brx-IN     = ₹ 1,205.34
// sw-UG      = USh1,205
// fil-PH     = ₱1,205.34
// cs         = 1 205,34 ¤
// pt-GW      = 1 205 CFA
// bn-BD      = ১,২০৫.৩৪৳
// de-AT      = € 1.205,34
// fr-PF      = 1 205 FCFP
// luo        = 1,205.34¤
// sk-SK      = 1 205,34 €
// ar-001     = ١٢٠٥٫٣٤ ¤
// es-US      = $1,205.34
// en-SK      = 1 205,34 €
// ta         = ¤ 1,205.34
// fr-HT      = 1 205,34 G
// mk-MK      = ден 1.205,34
// om-KE      = Ksh1,205.34
// da-DK      = 1.205,34 kr
// en-ME      = 1.205,34 €
// ko-KR      = ₩1,205
// ff-SN      = 1 205 CFA
// id         = ¤1.205,34
// en-KY      = $1,205.34
// kde-TZ     = TSh1,205
// shi        = 1 205,34¤
// cy         = ¤1,205.34
// en-ES      = 1.205,34 €
// mgh        = ¤ 1.205,34
// en-TR      = 1.205,34 TRY
// sr-ME      = 1.205,34 €
// te         = ¤1,205.34
// fr-GN      = 1 205 FG
// fo-FO      = kr1.205,34
// en-NL      = € 1.205,34
// ig         = ¤1,205.34
// it-IT      = € 1.205,34
// uk-UA      = 1 205,34 ₴
// tg         = ¤ 1,205.34
// en-DM      = EC$1,205.34
// bm-ML      = CFA1,205
// vai        = ¤1,205.34
// en-SL      = Le1,205
// ii         = ¤ 1,205.34
// en-150     = 1.205,34 €
// ses        = 1 205.34¤
// th         = ¤1,205.34
// ti         = ¤1,205.34
// en-IM      = £1,205.34
// ru-KZ      = 1 205,34 ₸
// fr-MU      = 1 205 Rs
// iu-Cans-CA = CA$ 1,205.34
// cs-CZ      = 1 205,34 Kč
// ar-AE      = ١٢٠٥٫٣٤ د.إ.‏
// te-IN      = ₹1,205.34
// tk         = 1 205,34¤
// brx        = ¤ 1,205.34
// haw        = ¤1,205.34
// tzm-MA     = 1 205,34 MAD
// so-DJ      = Fdj1,205
// uz-UZ      = сўм 1,205
// en-BA      = BAM 1.205,34
// to         = ¤ 1,205.34
// en-MG      = Ar1,205
// ewo-CM     = 1 205 FCFA
// ar-MR      = ١٢٠٥ أ.م.‏
// nl-AW      = Afl. 1.205,34
// en-IN      = ₹ 1,205.34
// mgo        = ¤ 1,205.34
// sn-ZW      = US$1,205.34
// en-CH      = CHF 1'205.34
// en-TT      = $1,205.34
// tr         = 1.205,34 ¤
// is         = ¤1.205,34
// fr-GP      = 1 205,34 €
// luy        = ¤1,205.34
// es-NI      = C$1,205.34
// pt-TL      = 1 205,34 US$
// it         = ¤ 1.205,34
// ms-Arab    = ¤1,205.34
// da         = 1.205,34 ¤
// kln-KE     = Ksh1,205.34
// iu         = ¤ 1,205.34
// tk         = 1 205,34¤
// en-BB      = $1,205.34
// ar-DZ      = د.ج.‏ 1.205,34
// ar-SY      = ١٢٠٥ ل.س.‏
// ha         = ¤ 1,205.34
// en-MH      = $1,205.34
// mr-IN      = ₹१,२०५.३४
// en-GB      = £1,205.34
// de         = 1.205,34 ¤
// fr-GQ      = 1 205 FCFA
// en-NO      = NOK 1 205,34
// ky-KG      = сом 1 205,34
// pt-PT      = 1 205,34 €
// fr-RW      = 1 205 RF
// nus-SD     = SDG1,205.34
// asa        = 1,205.34 ¤
// zh         = ¤1,205.34
// ha-GH      = GH₵ 1,205.34
// bo-CN      = ¥ 1,205.34
// kam-KE     = Ksh1,205.34
// dua-CM     = 1 205 FCFA
// khq-ML     = 1 205CFA
// ur-IN      = ₹ ۱,۲۰۵.۳۴
// en-LC      = EC$1,205.34
// fr-TD      = 1 205 FCFA
// ksb-TZ     = 1,205TSh
// gu-IN      = ₹1,205.34
// om         = ¤1,205.34
// jmc        = ¤1,205.34
// ro-RO      = 1.205,34 RON
// ja-JP      = ￥1,205
// ln-AO      = 1.205,34 Kz
// so-ET      = Br1,205.34
// en-GD      = EC$1,205.34
// nl-NL      = € 1.205,34
// es-ES      = 1.205,34 €
// en-VC      = EC$1,205.34
// or         = ¤ 1,205.34
// yo-NG      = ₦1,205.34
// es-PY      = ₲ 1.205
// mua-CM     = FCFA1.205
// fa-AF      = ‎؋۱٬۲۰۵
// en-HK      = $1,205.34
// ja         = ¤1,205.34
// luo-KE     = 1,205.34Ksh
// twq        = 1 205.34¤
// en-BE      = 1.205,34 €
// es-UY      = $ 1.205,34
// dje-NE     = 1 205CFA
// luy-KE     = Ksh1,205.34
// naq        = ¤1,205.34
// si-LK      = රු. 1,205.34
// zu         = ¤1,205.34
// zh-Hans-MO = MOP$1,205.34
// fr-KM      = 1 205 CF
// zh-HK      = $1,205.34
// dz         = ¤༡,༢༠༥.༣༤
// swc        = ¤1.205,34
// asa-TZ     = 1,205 TSh
// jgo-CM     = FCFA 1.205
// az-Cyrl    = ¤ 1.205,34
// ewo        = 1 205,34 ¤
// gv-GB      = £1,205.34
// ti-ER      = Nfk1,205.34
// be-BY      = р.1 205
// en-IS      = ISK1.205
// en-CM      = FCFA1,205
// uk         = 1 205,34 ¤
// cgg-UG     = USh1,205
// nyn        = ¤1,205.34
// tr-CY      = 1.205,34 €
// de-CH      = CHF 1'205.34
// fr-TG      = 1 205 CFA
// jmc-TZ     = TSh1,205
// ta-LK      = Rs. 1,205.34
// so-SO      = S1,205
// es-DO      = $1,205.34
// fr-LU      = 1.205,34 €
// shi-MA     = 1 205,34MAD
// en-SS      = £1,205.34
// swc-CD     = FC1.205,34
// kn-IN      = ₹1,205.34
// hy-AM      = 1205 դր.
// en-IT      = € 1.205,34
// en-GG      = £1,205.34
// fil        = ¤1,205.34
// bas-CM     = 1 205 FCFA
// en-TZ      = TSh1,205
// ar-TD      = ١٢٠٥ FCFA
// ur         = ¤1,205.34
// bez-TZ     = 1,205TSh
// haw-US     = $1,205.34
// fr-VU      = 1 205 VT
// tg         = ¤ 1,205.34
// pa         = ¤ 1,205.34
// bs-BA      = KM 1.205,34
// ee-TG      = CFA1,205
// ti-ET      = Br1,205.34
// sr-Latn-BA = 1.205,34 KM
// en-GH      = GH₵1,205.34
// ee         = ¤1,205.34
// en-VG      = $1,205.34
// sv-SE      = 1 205:34 kr
// ki-KE      = Ksh1,205.34
// zh-Hans    = ¤1,205.34
// bem        = ¤1,205.34
// uz         = ¤ 1,205.34
// ar-YE      = ١٢٠٥ ر.ي.‏
// fr-NC      = 1 205 FCFP
// seh-MZ     = 1.205,34MTn
// ru-UA      = 1 205,34 ₴
// fr-SC      = 1 205,34 SR
// ar-KM      = ١٢٠٥ ف.ج.ق.‏
// en-ZA      = R1 205,34
// en-GI      = £1,205.34
// mas-KE     = Ksh1,205.34
// nn-NO      = 1 205,34 kr
// ar-EG      = ١٢٠٥٫٣٤ ج.م.‏
// el         = 1.205,34 ¤
// en-RO      = 1.205,34 RON
// pl         = 1 205,34 ¤
// nl-BE      = 1.205,34 €
// en         = ¤1,205.34
// uz-Latn    = ¤ 1,205.34
// eo         = ¤ 1 205,34
// shi        = 1 205,34¤
// kok        = ¤ 1,205.34
// mas        = ¤1,205.34
// fr-FR      = 1 205,34 €
// rof        = ¤1,205.34
// en-MP      = $1,205.34
// de-BE      = 1.205,34 €
// hr-BA      = 1.205,34 KM
// ar-EH      = د.م.‏ 1,205.34
// es-CL      = $1.205
// en-AD      = €1.205,34
// es         = 1.205,34 ¤
// en-VI      = $1,205.34
// ps         = ۱٬۲۰۵٫۳۴ ¤
// et         = 1 205,34 ¤
// nl-SR      = $ 1.205,34
// vai-Latn   = ¤1,205.34
// pt         = ¤1.205,34
// eu         = 1.205,34 ¤
// ka         = 1 205,34 ¤
// fr-NE      = 1 205 CFA
// eu-ES      = 1.205,34 €
// mgh-MZ     = MTn 1.205,34
// zu-ZA      = R1,205.34
// ar-SA      = ١٢٠٥٫٣٤ ر.س.‏
// chr-US     = $1,205.34
// cgg        = ¤1,205.34
// sq-MK      = den1 205,34
// lag        = ¤ 1,205.34
// az-AZ      = man. 1.205,34
// es-VE      = Bs.1.205,34
// ks-Arab    = ¤ ۱٬۲۰۵٫۳۴
// en-HR      = 1.205,34 HRK
// el-GR      = 1.205,34 €
// el-CY      = €1.205,34
// mfe-MU     = Rs 1 205
// ki         = ¤1,205.34
// vi         = 1.205,34 ¤
// en-KE      = Ksh1,205.34
// rwk        = 1,205.34¤
// bez        = 1,205.34¤
// kk         = 1 205,34 ¤
// kl         = ¤1.205,34
// zh-Hant    = ¤1,205.34
// fr-CA      = 1 205,34 $
// km         = ¤1.205,34
// es-HN      = L1,205.34
// agq-CM     = 1 205FCFA
// kn         = ¤1,205.34
// ii-CN      = ¥ 1,205.34
// mn         = ¤ 1 205,34
// en-BM      = $1,205.34
// ko         = ¤1,205.34
// sv-AX      = 1 205:34 €
// ln-CD      = 1.205,34 FC
// en-GM      = D1,205.34
// iu-Cans    = ¤ 1,205.34
// fr-MA      = 1 205,34 MAD
// es-CO      = $1.205
// en-AG      = EC$1,205.34
// guz-KE     = Ksh1,205.34
// ks         = ¤ ۱٬۲۰۵٫۳۴
// es-PA      = B/.1,205.34
// twq-NE     = 1 205CFA
// en-NZ      = $1,205.34
// fr-TN      = 1 205,340 DT
// fa         = ‎¤۱٬۲۰۵٫۳۴
// en-US-POSI = $ 1205.34
// dav-KE     = Ksh1,205.34
// en-WS      = WS$1,205.34
// lt-LT      = 1 205,34 Lt
// en-SZ      = E1,205.34
// ar-SD      = ١٢٠٥٫٣٤ SDG
// rof-TZ     = TSh1,205
// uz-Arab-AF = ؋ ۱٬۲۰۵
// vi-VN      = 1.205 ₫
// en-MT      = €1,205.34
// kw         = ¤1,205.34
// yav-CM     = 1 205 FCFA
// ta-MY      = RM 1,205.34
// ru-KG      = 1 205,34 сом
// kab        = 1 205,34¤
// ky         = ¤ 1 205,34
// ff         = 1 205,34 ¤
// en-PG      = K1,205.34
// to-TO      = T$ 1,205.34
// ar-LY      = د.ل.‏ 1.205,340
// jgo        = ¤ 1.205,34
// en-HU      = 1 205 HUF
// af-ZA      = R1 205,34
// en-UG      = USh1,205
// de-LI      = CHF 1'205.34
// fi         = 1 205,34 ¤
// es-SV      = US$1,205.34
// khq        = 1 205.34¤
// gsw        = 1’205.34 ¤
// ksf-CM     = 1 205 FCFA
// fr-DJ      = 1 205 Fdj
// en-MU      = Rs1,205
// bs         = ¤ 1.205,34
// ln-CF      = 1.205 FCFA
// ms         = ¤1,205.34
// kea-CV     = 1,205$34 CVE
// pl-PL      = 1 205,34 zł
// pa-Arab    = ¤ ۱٬۲۰۵٫۳۴
// fr-MC      = 1 205,34 €
// sr-BA      = 1.205,34 КМ
// sr-Latn    = 1.205,34 ¤
// en-RU      = 1 205,34 RUB
// en-PH      = ₱1,205.34
// saq        = ¤1,205.34
// ar-PS      = ١٢٠٥٫٣٤ ₪
// fr-CD      = 1 205,34 FC
// bem-ZM     = KR1,205.34
// ru-RU      = 1 205,34 руб.
// uz         = ¤ 1,205.34
// pa         = ¤ 1,205.34
// vai        = ¤1,205.34
// en-FI      = 1 205,34 €
// fo         = ¤1.205,34
// so-KE      = Ksh1,205.34
// ln-CG      = 1.205 FCFA
// ar-OM      = ١٢٠٥٫٣٤٠ ر.ع.‏
// pt-ST      = 1 205 Db
// en-KI      = $1,205.34
// kl-GL      = kr1.205,34
// fr         = 1 205,34 ¤
// es-CR      = ₡1.205
// ses-ML     = 1 205CFA
// tzm        = 1 205,34 ¤
// mer-KE     = Ksh1,205.34
// xog        = 1,205.34 ¤
// xog-UG     = 1,205 USh
// nl-SX      = NAf. 1.205,34
// en-FJ      = $1,205.34
// ms-BN      = $ 1.205,34
// en-MW      = MK1,205.34
// ar-MA      = د.م.‏ 1.205,34
// pt-MO      = 1 205,34 MOP$
// kam        = ¤1,205.34
// en-TC      = $1,205.34
// af         = ¤1 205,34
// ar-TN      = د.ت.‏1205,340
// am-ET      = ብር1,205.34
// es-PE      = S/.1,205.34
// sbp-TZ     = 1,205TSh
// fr-CF      = 1 205 FCFA
// vun-TZ     = TSh1,205
// fr-RE      = 1 205,34 €
// ar-JO      = ١٢٠٥٫٣٤٠ د.أ.‏
// ebu        = ¤1,205.34
// lg         = 1,205.34¤
// ha-NG      = ₦ 1,205.34
// lv-LV      = Ls1 205,34
// ak         = ¤1,205.34
// chr        = ¤1,205.34
// az-Cyrl-AZ = ман. 1.205,34
// dav        = ¤1,205.34
// en-EE      = 1 205,34 €
// es-419     = ¤1,205.34
// ebu-KE     = Ksh1,205.34
// en-CY      = 1.205,34 €
// fr-MF      = 1 205,34 €
// en-AL      = ALL1 205
// am         = ¤1,205.34
// en-PK      = Rs1,205
// mgo-CM     = FCFA 1,205
// fr-CG      = 1 205 FCFA
// dje        = 1 205.34¤
// en-JE      = £1,205.34
// en-LR      = $1,205.34
// dyo        = 1 205,34 ¤
// ln         = 1.205,34 ¤
// ak-GH      = GH₵1,205.34
// pa-IN      = ₹ 1,205.34
// ar-DJ      = ١٢٠٥ Fdj
// en-BS      = $1,205.34
// lo         = ¤1.205,34
// zh-TW      = NT$1,205.34
// lg-UG      = 1,205USh
// ar-KW      = ١٢٠٥٫٣٤٠ د.ك.‏
// ar         = ١٢٠٥٫٣٤ ¤
// bs-Cyrl-BA = 1.205,34 КМ
// es-EA      = 1.205,34 €
// fr-MG      = 1 205 Ar
// ca-ES      = €1.205,34
// as         = ¤ ১,২০৫.৩৪
// he-IL      = 1,205.34 ₪
// es-CU      = $1,205.34
// en-CZ      = 1 205,34 CZK
// en-PL      = 1 205,34 PLN
// fr-GA      = 1 205 FCFA
// mg-MG      = Ar1,205
// fr-CH      = CHF 1'205.34
// en-LS      = R1,205.34
// lt         = 1 205,34 ¤
// my-MM      = K ၁,၂၀၅
// kk-KZ      = 1 205,34 ₸
// ga         = ¤1,205.34
// en-FM      = $1,205.34
// lu         = 1.205,34¤
// ps-AF      = ۱٬۲۰۵ ؋
// nmg        = 1 205,34 ¤
// es-BO      = Bs1.205,34
// sbp        = 1,205.34¤
// lv         = ¤1 205,34
// vun        = ¤1,205.34
// fr-YT      = 1 205,34 €
// km-KH      = ៛1.205,34
// teo-UG     = USh1,205
// fr-SN      = 1 205 CFA
// om-ET      = Br1,205.34
// ar-ER      = ١٢٠٥٫٣٤ Nfk
// gsw-CH     = 1’205.34 CHF
// az         = ¤ 1.205,34
// es-PH      = 1.205,34 ₱
// fi-FI      = 1 205,34 €
// tr-TR      = 1.205,34 ₺
// fr-CI      = 1 205 CFA
// en-LT      = 1 205,34 LTL
// en-UM      = $1,205.34
// sr         = 1.205,34 ¤
// ur-PK      = Rs1,205
// hr-HR      = 1.205,34 kn
// nl-CW      = NAf. 1.205,34
// en-KN      = EC$1,205.34
// ms-MY      = RM1,205.34
// ar-IL      = ١٢٠٥٫٣٤ ₪
// en-ZM      = KR1,205.34
// es-EC      = $1.205,34
// gl-ES      = €1.205,34
// en-GU      = $1,205.34
// gl         = ¤1.205,34
// nmg-CM     = 1 205 FCFA
// zh-MO      = MOP$1,205.34
// en-NA      = $1,205.34
// ha-NE      = CFA 1,205
// mt-MT      = €1,205.34
// rm         = 1’205.34 ¤
// kw-GB      = £1,205.34
// zh-SG      = $1,205.34
// rn         = 1.205,34¤
// ro         = 1.205,34 ¤
// rm-CH      = 1’205.34 CHF
// saq-KE     = Ksh1,205.34
// vai-LR     = $1,205.34
// ka-GE      = 1 205,34 GEL
// es-GQ      = FCFA1.205
// sr-Latn-RS = 1.205 din.
// en-VU      = VT1,205
// zh-Hans-HK = $1,205.34
// en-LV      = LVL1 205,34
// agq        = 1 205,34¤
// gu         = ¤1,205.34
// lo-LA      = ₭1.205
// ru         = 1 205,34 ¤
// en-SB      = $1,205.34
// gv         = ¤1,205.34
// en-BW      = P1,205.34
// yav        = 1 205,34 ¤
// ta-SG      = $ 1,205.34
// fr-BE      = 1.205,34 €
// bg-BG      = 1 205,34 лв.
// es-MX      = $1,205.34
// rw         = ¤ 1.205,34
// be         = ¤1 205,34
// nd-ZW      = US$1,205.34
// kab-DZ     = 1 205,34DA
// mua        = ¤1.205,34
// pt-CV      = 1 205$34 CVE
// bg         = 1 205,34 ¤
// tg-TJ      = сом 1,205.34
// ms-SG      = $1,205.34
// mg         = ¤1,205.34
// sg-CF      = FCFA1.205
// pa-Arab-PK = ر ۱٬۲۰۵
// sw-TZ      = TSh1,205
// en-SC      = SR1,205.34
// nus        = ¤1,205.34
// shi-Tfng   = 1 205,34¤
// ar-QA      = ١٢٠٥٫٣٤ ر.ق.‏
// naq-NA     = $1,205.34
// fr-BF      = 1 205 CFA
// rw-RW      = RF 1.205
// as-IN      = ₹ ১,২০৫.৩৪
// guz        = ¤1,205.34
// ksb        = 1,205.34¤
// fr-ML      = 1 205 CFA
// mk         = ¤ 1.205,34
// kok-IN     = ₹ 1,205.34
// sq-AL      = Lek1 205
// ml         = 1,205.34¤
// fr-GF      = 1 205,34 €
// bm         = ¤1,205.34
// lu-CD      = 1.205,34FC
// fr-CM      = 1 205 FCFA
// bn         = ১,২০৫.৩৪¤
// ar-LB      = ١٢٠٥ ل.ل.‏
// id-ID      = Rp1.205
// uz-Arab    = ¤ ۱٬۲۰۵٫۳۴
// mn         = ¤ 1 205,34
// bo         = ¤ 1,205.34
// en-FR      = 1 205,34 €
// en-DE      = 1.205,34 €
// vai-Latn-L = $1,205.34
// ar-SO      = ١٢٠٥ S
// ru-BY      = 1 205 р.
