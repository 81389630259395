import axios from 'axios';

import { ICbPawOrderFile } from '@/shared/model/cb-paw-order-file.model';
import { ICbAttachmentRestrict } from '@/shared/model/cb-attachment-restrict.model';

const baseApiUrl = 'services/cbcorems/api/cb-paw-order-files';
const baseApiUrlCbAttachmentRestrict = 'services/cbcorems/api/cb-attachment-restricts';

export default class PCbPawOrderFileService {
  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(
    entity: ICbPawOrderFile,
    isUploadToGoogleStorage: boolean,
    callBack: (progress: number) => void,
    callbackReject?: (data: ICbPawOrderFile) => void
  ): Promise<ICbPawOrderFile> {
    // kosongin att
    let tmpBlob = null;
    if (entity.cbAttachmentRestrict) {
      entity.cbAttachmentRestrict.att = '';
      tmpBlob = entity.cbAttachmentRestrict.blob;
      delete entity.cbAttachmentRestrict.blob;
    }
    return new Promise<ICbPawOrderFile>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          const result: ICbPawOrderFile = res.data;

          if (result.uploadLink && isUploadToGoogleStorage) {
            resolve(this.upload(result, result.uploadLink, entity.cbAttachmentRestrict.attContentType, tmpBlob, callBack, callbackReject));
          } else {
            resolve(result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createUploadAttachmnetRestrict(
    cbAttachmentRestrict: ICbPawOrderFile,
    callBack: (progress: number) => void,
    callbackReject?: (data: ICbPawOrderFile) => void
  ): Promise<ICbPawOrderFile> {
    // kosongin att
    let tmpBlob = null;
    if (cbAttachmentRestrict) {
      cbAttachmentRestrict.att = '';
      tmpBlob = cbAttachmentRestrict.blob;
      delete cbAttachmentRestrict.blob;
    }
    return new Promise<ICbPawOrderFile>((resolve, reject) => {
      axios
        .post(`${baseApiUrlCbAttachmentRestrict}`, cbAttachmentRestrict)
        .then(res => {
          const result: ICbAttachmentRestrict = res.data;
          if (result.uploadLink) {
            resolve(this.upload(result, result.uploadLink, cbAttachmentRestrict.attContentType, tmpBlob, callBack, callbackReject));
          } else {
            resolve(result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  private upload(
    result: any,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: (progress: number) => void,
    callbackReject: (data: ICbPawOrderFile) => void
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(res => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
          callbackReject(result);
        });
    });
  }
}
