var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-pd-title" }, [
    _c("div", { staticClass: "d-flex" }, [
      _c(
        "p",
        {
          staticClass:
            "p-0 mx-0 mx-lg-auto text-left text-lg-center mb-2 cj-color-black-primary font-roboto font-weight-500",
          class: _vm.isMobile ? "font-size-20" : "font-size-28",
          attrs: { "data-cy": "detail-paw-title" },
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.cbVwPaw.title ? _vm.cbVwPaw.title : "-") +
              "\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isMobile
        ? _c(
            "div",
            { staticClass: "ml-auto d-flex" },
            [
              _c("icon", {
                style: `fill:${_vm.getCbVwSvcsColor(_vm.cbVwPaw.svcServiceId)}`,
                attrs: { name: "ic_paw_fill", width: "3rem", height: "3rem" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass:
          "p-0 text-left text-lg-center mb-0 cj-color-black-primary font-roboto font-weight-400",
        class: _vm.isMobile ? "font-size-10" : "font-size-16",
        attrs: { "data-cy": "detail-paw-service" },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.cbVwPaw.svc ? _vm.cbVwPaw.svc : "-") +
            " | " +
            _vm._s(_vm.cbVwPaw.svcCategory ? _vm.cbVwPaw.svcCategory : "-") +
            "\n  "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }