var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isShowChatInfoPanel,
          expression: "!isShowChatInfoPanel",
        },
      ],
      staticClass: "vac-card-window",
      class: !_vm.roomId ? "isActivedNoRoomId" : "",
      style: [{ height: _vm.height }, _vm.cssVars],
    },
    [
      _c(
        "div",
        {
          staticClass: "vac-chat-container",
          attrs: { "data-cy": "vac-chat-container" },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showRoomsList,
                  expression: "showRoomsList",
                },
              ],
              staticClass: "vac-setting-container",
              class: {
                "vac-rooms-container-full show-footer-tab": _vm.isMobile,
                "d-none": !_vm.isShowSettingUser,
              },
              attrs: {
                id: "room-setting-user",
                "data-cy": "vac-setting-container",
              },
            },
            [
              _c("room-setting-user", {
                attrs: { "data-cy": "room-setting" },
                on: {
                  callBack: function ($event) {
                    return _vm.$emit("handleCloseSettingUser")
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.singleRoom
            ? _c("rooms-list", {
                class: _vm.isShowSettingUser ? "d-none" : "",
                attrs: {
                  "data-cy": "wrap-room-list",
                  "current-user-id": _vm.currentUserId,
                  rooms: _vm.orderedRooms,
                  "loading-rooms": _vm.loadingRooms,
                  "rooms-loaded": _vm.roomsLoaded,
                  room: _vm.room,
                  "room-actions": _vm.roomActions,
                  "text-messages": _vm.t,
                  "show-search": _vm.showSearch,
                  "show-add-room": _vm.showAddRoom,
                  "show-rooms-list": _vm.showRoomsList,
                  "text-formatting": _vm.textFormatting,
                  "link-options": _vm.linkOptions,
                  "is-mobile": _vm.isMobile,
                  "scroll-distance": _vm.scrollDistance,
                  "user-ids-online": _vm.userIdsOnline,
                  isShowSearch: _vm.isShowSearch,
                },
                on: {
                  "fetch-room": _vm.fetchRoom,
                  "fetch-more-rooms": _vm.fetchMoreRooms,
                  "loading-more-rooms": function ($event) {
                    _vm.loadingMoreRooms = $event
                  },
                  "add-room": _vm.addRoom,
                  "room-action-handler": _vm.roomActionHandler,
                  handleShowSearch: function ($event) {
                    return _vm.$emit("handleShowSearch")
                  },
                  handleShowSettingUser: function ($event) {
                    return _vm.$emit("handleShowSettingUser")
                  },
                  contactSearch: function ($event) {
                    return _vm.$emit("contactSearch", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowOptionMenu == "ROOMMEDIA"
            ? _c("c-room-media", {
                attrs: {
                  messages: _vm.messages,
                  rooms: _vm.rooms,
                  "room-id": _vm.room.roomId || "",
                  chtToken: _vm.chtToken,
                },
                on: {
                  closeRoomMedia: _vm.closeShowOption,
                  "message-action-handler": _vm.messageActionHandler,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowOptionMenu == "ROOMSTARTMSG"
            ? _c("c-room-star-messages", {
                attrs: {
                  rooms: _vm.rooms,
                  "current-user-id": _vm.currentUserId,
                  "username-options": _vm.usernameOptions,
                  "message-actions": _vm.messageActions,
                  "link-options": _vm.linkOptions,
                  "text-formatting": _vm.textFormatting,
                  "show-new-messages-divider": _vm.showNewMessagesDivider,
                  "show-reaction-emojis": _vm.showReactionEmojis,
                  "text-messages": _vm.t,
                  "messages-loaded": _vm.messagesLoaded,
                  "scroll-distance": _vm.scrollDistance,
                  "room-id": _vm.room.roomId || "",
                  "loading-rooms": _vm.loadingRooms,
                  "auto-scroll": _vm.autoScroll,
                  "is-mobile": _vm.isMobile,
                  "load-first-room": _vm.loadFirstRoom,
                  "single-room": _vm.singleRoom,
                  starMessages: _vm.messages,
                  isShowOptionMenu: _vm.isShowOptionMenu,
                  chtToken: _vm.chtToken,
                },
                on: {
                  closeRoomStartMsg: _vm.closeShowOption,
                  "message-action-handler": _vm.messageActionHandler,
                  starMsgResultSelected: function ($event) {
                    return _vm.$emit("starMsgResultSelected", $event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.$scopedSlots, function (i, name) {
                      return {
                        key: name,
                        fn: function (data) {
                          return [_vm._t(name, null, null, data)]
                        },
                      }
                    }),
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowOptionMenu == "ROOMSEARCHMSG"
            ? _c("c-room-search-messages", {
                attrs: {
                  "data-cy": "room-search-message",
                  rooms: _vm.rooms,
                  "current-user-id": _vm.currentUserId,
                  "username-options": _vm.usernameOptions,
                  "message-actions": _vm.messageActions,
                  "link-options": _vm.linkOptions,
                  "text-formatting": _vm.textFormatting,
                  "show-new-messages-divider": _vm.showNewMessagesDivider,
                  "show-reaction-emojis": _vm.showReactionEmojis,
                  "text-messages": _vm.t,
                  "messages-loaded": _vm.messagesLoaded,
                  "scroll-distance": _vm.scrollDistance,
                  "room-id": _vm.room.roomId || "",
                  "loading-rooms": _vm.loadingRooms,
                  "auto-scroll": _vm.autoScroll,
                  "is-mobile": _vm.isMobile,
                  "load-first-room": _vm.loadFirstRoom,
                  "single-room": _vm.singleRoom,
                  isShowOptionMenu: _vm.isShowOptionMenu,
                  chtToken: _vm.chtToken,
                  messagesSearchResults: _vm.messagesSearchResults,
                  totalItemsMessagesSearchResults:
                    _vm.totalItemsMessagesSearchResults,
                },
                on: {
                  closeRoomSearchMsg: _vm.closeShowOption,
                  "message-action-handler": _vm.messageActionHandler,
                  searchMsgResultSelected: function ($event) {
                    return _vm.$emit("searchMsgResultSelected", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("room", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowOptionMenu == "ROOMLIST",
                expression: "isShowOptionMenu == 'ROOMLIST'",
              },
            ],
            ref: "refRoom",
            attrs: {
              id: "room-list-message",
              "data-cy": "wrap-room",
              "current-user-id": _vm.currentUserId,
              "user-ids-online": _vm.userIdsOnline,
              rooms: _vm.rooms,
              "room-id": _vm.room.roomId || "",
              "load-first-room": _vm.loadFirstRoom,
              messages: _vm.messages,
              "room-message": _vm.roomMessage,
              "messages-loaded": _vm.messagesLoaded,
              "menu-actions": _vm.menuActions,
              "message-actions": _vm.messageActions,
              "message-selection-actions": _vm.messageSelectionActions,
              "auto-scroll": _vm.autoScroll,
              "show-send-icon": _vm.showSendIcon,
              "show-files": _vm.showFiles,
              "show-audio": _vm.showAudio,
              "audio-bit-rate": _vm.audioBitRate,
              "audio-sample-rate": _vm.audioSampleRate,
              "show-emojis": _vm.showEmojis,
              "show-reaction-emojis": _vm.showReactionEmojis,
              "show-new-messages-divider": _vm.showNewMessagesDivider,
              "show-footer": _vm.showFooter,
              "text-messages": _vm.t,
              "single-room": _vm.singleRoom,
              "show-rooms-list": _vm.showRoomsList,
              "text-formatting": _vm.textFormatting,
              "link-options": _vm.linkOptions,
              "is-mobile": _vm.isMobile,
              "loading-rooms": _vm.loadingRooms,
              "room-info-enabled": _vm.roomInfoEnabled,
              "textarea-action-enabled": _vm.textareaActionEnabled,
              "textarea-auto-focus": _vm.textareaAutoFocus,
              "user-tags-enabled": _vm.userTagsEnabled,
              "emojis-suggestion-enabled": _vm.emojisSuggestionEnabled,
              "scroll-distance": _vm.scrollDistance,
              "accepted-files": _vm.acceptedFiles,
              "templates-text": _vm.templatesText,
              "username-options": _vm.usernameOptions,
              "cb-stickers": _vm.cbStickers,
            },
            on: {
              "toggle-rooms-list": _vm.toggleRoomsList,
              "room-info": _vm.roomInfo,
              "fetch-messages": _vm.fetchMessages,
              "send-message": _vm.sendMessage,
              "edit-message": _vm.editMessage,
              "delete-message": _vm.deleteMessage,
              "open-file": _vm.openFile,
              "open-user-tag": _vm.openUserTag,
              "open-failed-message": _vm.openFailedMessage,
              "menu-action-handler": _vm.menuActionHandler,
              "message-action-handler": _vm.messageActionHandler,
              "message-selection-action-handler":
                _vm.messageSelectionActionHandler,
              "send-message-reaction": _vm.sendMessageReaction,
              "send-message-sticker": _vm.sendMessageSticker,
              "typing-message": _vm.typingMessage,
              "textarea-action-handler": _vm.textareaActionHandler,
              handleShowOption: _vm.handleShowOption,
              showInfoPanel: function ($event) {
                return _vm.$emit("showInfoPanel", $event)
              },
              deleteMessageReaction: function ($event) {
                return _vm.$emit("deleteMessageReaction", $event)
              },
              searchMsgResultSelected: function ($event) {
                return _vm.$emit("searchMsgResultSelected", $event)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (i, name) {
                  return {
                    key: name,
                    fn: function (data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-cht-media-preview",
            size: "lg",
            "hide-footer": "",
            "hide-header": "",
            "content-class": "vac-custom-modal-media-preview-content-b-modal",
            "dialog-class": "vac-custom-modal-media-preview-size-b-modal",
            "modal-class": "vac-custom-modal-media-preview-b-modal",
          },
        },
        [
          _c("media-preview-chat", {
            attrs: { message: _vm.selectedMessage, file: _vm.previewFile },
            on: {
              download: _vm.downloadFile,
              "close-media-preview": _vm.handleClosePreviewMedia,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }