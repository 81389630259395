/// <reference path="./c-location-track.component.d.ts" />
import { ChtLocation } from '@/shared/model/cht-location.model';

export class GoogleMapUtils {
  static getNearestPointIndex(point: google.maps.LatLng, path: google.maps.MVCArray<google.maps.LatLng>): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      try {
        let minDistance = Number.MAX_VALUE;
        let minIndex = -1;
        for (let i = 0; i < path.getArray().length; i++) {
          const distance = google.maps.geometry.spherical.computeDistanceBetween(point, path.getArray()[i]);
          if (distance < minDistance) {
            minDistance = distance;
            minIndex = i;
          }
        }
        resolve(minIndex);
      } catch (error) {
        reject(error);
      }
    });
  }

  static async getDetailByPlaceId(
    placeService: google.maps.places.PlacesService,
    placeId: string
  ): Promise<google.maps.places.PlaceResult> {
    return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
      placeService.getDetails({ placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) resolve(place);
        else reject(google.maps.places.PlacesServiceStatus);
      });
    });
  }

  static async getDetailByLatLng(geocoder: google.maps.Geocoder, location: google.maps.LatLng): Promise<google.maps.GeocoderResult> {
    return new Promise<google.maps.GeocoderResult>((resolve, reject) => {
      geocoder.geocode({ location }, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK || !results[0]) reject(status);
        else resolve(results[0]);
      });
    });
  }

  static changeMarkerRotation(marker: google.maps.Marker, heading: number, icon: google.maps.Icon): google.maps.Marker {
    icon.rotation = heading;
    marker.setIcon(icon);
    return marker;
  }

  static createMarker(
    position: google.maps.LatLng,
    name: string,
    html: string,
    map: google.maps.Map<HTMLElement>,
    icon?: string | google.maps.Icon
  ): google.maps.Marker {
    const marker = new google.maps.Marker({
      position,
      map: map,
      title: name,
      icon,
    });
    return marker;
  }

  static calculateRoute(
    start: google.maps.LatLng,
    end: ChtLocation,
    directionsService: google.maps.DirectionsService,
    polyline: google.maps.Polyline | undefined,
    routeColor?: string
  ): Promise<CalcRouteResult | undefined> {
    // TODO: must in same country I guess
    return new Promise<CalcRouteResult>((resolve, reject) =>
      directionsService.route(
        {
          origin: `${start.lat()}, ${start.lng()}`,
          destination: `${end.latGoogle}, ${end.longGoogle}`,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
          if (status == google.maps.DirectionsStatus.OK) {
            const temp: google.maps.Polyline = new google.maps.Polyline({ path: [] });
            const bounds = new google.maps.LatLngBounds();
            const legs = result.routes[0].legs;
            for (let i = 0; i < legs.length; i++) {
              const steps = legs[i].steps;
              for (let j = 0; j < steps.length; j++) {
                const nextSegment = steps[j].path;
                for (let k = 0; k < nextSegment.length; k++) {
                  temp.getPath().push(nextSegment[k]);
                  bounds.extend(nextSegment[k]);
                }
              }
            }
            if (polyline) {
              polyline.setPath(temp.getPath());
            } else {
              polyline = new google.maps.Polyline({
                path: temp.getPath(),
                strokeColor: routeColor,
                strokeWeight: 8,
              });
            }
            const totalDistance = this.computeTotalDistance(result);
            resolve({
              polyline,
              directionsService,
              totalDistance,
              bounds,
            });
          } else {
            reject((status as google.maps.DirectionsStatus).toString());
          }
        }
      )
    );
  }

  static computeTotalDistance(result: google.maps.DirectionsResult): number {
    let totalDistance = 0;
    const myroute = result.routes[0];
    for (let i = 0; i < myroute.legs.length; i++) {
      totalDistance += myroute.legs[i].distance.value;
    }
    return totalDistance;
  }

  static async getDistanceBetweenTwoPoints(point1: google.maps.LatLng, point2: google.maps.LatLng): Promise<number> {
    return await new Promise<number>((resolve, reject) => {
      try {
        const result = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }
  static isPathSame(newPath: google.maps.LatLng[], oldPath: google.maps.LatLng[]): boolean {
    if (newPath.length !== oldPath.length) return false;

    for (let i = 0; i < newPath.length; i++) {
      const newPoint = newPath[i];
      const oldPoint = oldPath[i];
      if (newPoint.lat() !== oldPoint.lat() || newPoint.lng() !== oldPoint.lng()) {
        return false;
      }
    }
    return true;
  }
}
