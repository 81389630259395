import { IChtMessage } from '@/shared/model/cht-message.model';

import { App } from '@/shared/model/enumerations/app.model';
export interface IChtFile {
  id?: number;
  app?: App | null;
  extension?: string | null;
  name?: string | null;
  description?: string | null;
  status?: string | null;
  attContentType?: string | null;
  att?: string | null;
  attSize?: number | null;
  type?: string | null;
  url?: string | null;
  senderId?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  chtMessage?: IChtMessage | null;
  refChtFileThumbnailId?: number | null;
  isThumbnail?: boolean | null;
  authorPhotoId?: number | null; //transient for notif
  authorDisplayName?: string | null; //transient for notif
  conversationIdTransient?: number | null; //transient for notif
  base64DataThumbnail?: string | null; //transient
  urlThumbnail?: string | null; //transient for notif
  progress?: number | null; //transient for notif
  localUrl?: string | null; //transient for notif
  uploadLink?: string | null; //transient for notif
}

export class ChtFile implements IChtFile {
  constructor(
    public id?: number,
    public app?: App | null,
    public extension?: string | null,
    public name?: string | null,
    public description?: string | null,
    public status?: string | null,
    public attContentType?: string | null,
    public att?: string | null,
    public attSize?: number | null,
    public type?: string | null,
    public url?: string | null,
    public senderId?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public chtMessage?: IChtMessage | null,
    public refChtFileThumbnailId?: number | null,
    public isThumbnail?: boolean | null,
    public authorPhotoId?: number | null, //transient for notif
    public authorDisplayName?: string | null, //transient for notif
    public conversationIdTransient?: number | null, //transient for notif
    public base64DataThumbnail?: string | null, //transient for notif
    public urlThumbnail?: string | null, //transient for notif
    public progress?: number | null, //transient for notif
    public localUrl?: string | null, //transient for notif
    public uploadLink?: string | null //transient for notif
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
