import axios from 'axios';

import { ChtFile, IChtFile } from '@/shared/model/cht-file.model';

const baseApiUrl = 'services/btichat/api/cht-files';

export default class ChtFileService {
  public create(idTokenOrUserIdLa: string, entity: IChtFile, type: string, fileBlob: Blob, callBack: (progress: number) => void): Promise<IChtFile> {
    return new Promise<IChtFile>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          const result: ChtFile = res.data;
          resolve(this.upload(result, result.uploadLink, idTokenOrUserIdLa, type, fileBlob, callBack));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create4La(idTokenOrUserIdLa: string, entity: IChtFile, type: string, fileBlob: Blob, callBack: (progress: number) => void): Promise<IChtFile> {
    return new Promise<IChtFile>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/la/${idTokenOrUserIdLa}`, entity)
        .then(res => {
          const result: ChtFile = res.data;
          resolve(this.upload(result, result.uploadLink, idTokenOrUserIdLa, type, fileBlob, callBack));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public upload(
    result: IChtFile,
    uploadLink: string,
    idTokenOrUserIdLa: string,
    type: string,
    fileBlob: Blob,
    callBack: (progress: number) => void
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        // console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(_ => {
          axios.post(`${baseApiUrl}/publish-message/${idTokenOrUserIdLa}`, result);

          resolve(result);
        })
        .catch(_ => {
          reject(false);
        });
    });
  }

  public createDownloadLink(chtFileId, token): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/download-link?id.equals=` + chtFileId + `&token.equals=` + token)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
