import dayjs from 'dayjs';
import Vue2Filters from 'vue2-filters';
import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';

import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import CProgressMilestone from '@/components/c-progress-milestone/c-progress-milestone.vue';
import CButton from '@/components/button/c-button.vue';
import CDetailOrderStatus from '@/components/c-order/c-order-detail/components/c-detail-order-status/c-detail-order-status.vue';
import Icon from '@/shared/icons/icon.vue';

import PWfVwWorkflowService from '@/services/wf-vw-workflow.service';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { ICbLocation, CbLocation } from '@/shared/model/cb-location.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { CbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbPawOrderExtraType } from '@/shared/model/enumerations/cb-paw-order-extra-type.model';
import { ICbPawOrderExtra } from '@/shared/model/cb-paw-order-extra.model';
import { ICbPawOrderInclude } from '@/shared/model/cb-paw-order-include.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { ICbPawOrderMilestone } from '@/shared/model/cb-paw-order-milestone.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

@Component({
  mixins: [Vue2Filters.mixin, StatusTextEnId],
  components: {
    CButton,
    Icon,
    CProgressMilestone,
    CTimeCountdown,
    CTextareaPreview,
    CDetailOrderStatus,
  },
})
export default class CItemChatMessagePawOrder extends Vue {
  // ================= START SERVICES =================
  @Inject('pWfVwWorkflowService') public pWfVwWorkflowService: () => PWfVwWorkflowService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrderRequirement: CbVwPawOrderRequirement;
  @Prop({ default: null }) public cbVwPawOrder: CbVwPawOrder;
  @Prop({ default: null }) public cbTextRestrict: CbTextRestrict;

  public cbPawOrderIncludes: ICbPawOrderInclude[] = [];
  public cbVwPawOrderExtras: ICbPawOrderExtra[] = [];
  public cbPawOrderMilestones: ICbPawOrderMilestone[] = [];
  public cbLocation: ICbLocation = new CbLocation();
  public wfHistoriesKeyName: string[] = [];

  public isExpandPackage = false;
  public isExpandRequirement = false;
  public isExpandExtra = false;
  public isExpandDate = false;
  public isExpandLocation = false;
  public isExpandMilestone = false;
  public isShowMore = false;
  public isSavingLoaderPaymentOrderOffer = false;
  public visibleCountdown = false;

  public TypeComponent = TypeComponent;
  public CbPawOrderExtraType = CbPawOrderExtraType;
  public CbCommonStatus = CbCommonStatus;
  public CbSvcType = CbSvcType;
  public CbLoginType = CbLoginType;

  // ================= END VARIABLES ==================

  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.cbVwPawOrderListener();
    this.cbVwPawOrderRequirementListener();
  }
  // ================= END DEFAULT FUNCTION ===========

  // ================= START FUNCTION =================
  public retrieveCbPawOrderTrack() {
    this.pWfVwWorkflowService()
      .retrieveHistoryByExternalId(this.cbVwPawOrder.workflowName, this.cbVwPawOrder.id)
      .then(res => {
        if (res?.length > 0) {
          if (!this.wfHistoriesKeyName) this.wfHistoriesKeyName = [];
          const cbVwPawOrderTracks = res;

          cbVwPawOrderTracks.forEach(track => {
            this.wfHistoriesKeyName.unshift(track.keyName);
          });
        }
      })
      .catch(err => {
        console.error('error', err.response);
      });
  }

  public handleShowMore() {
    this.isShowMore = !this.isShowMore;
  }

  public openAllCollapse() {
    this.isExpandPackage = true;
    this.isExpandRequirement = true;
    this.isExpandExtra = true;
    this.isExpandDate = true;
    this.isExpandLocation = true;
    this.isExpandMilestone = true;
  }

  public handleButtonOfferCard(action) {
    if (this.isSavingLoaderPaymentOrderOffer) return;

    (<any>this.$root).$emit(
      'opc::actionHandler',
      {
        action,
        typeModal: TypeComponent.CHAT_MODAL,
        cbVwPawOrder: this.cbVwPawOrder,
        cbVwPawOrderRequirement: this.cbVwPawOrderRequirement,
      },
      ({ isSavingLoader, data }) => {
        this.isSavingLoaderPaymentOrderOffer = isSavingLoader;
      }
    );
  }
  // ================= END FUNCTION ===================

  // ================= START COMPUTE ==================
  public get getBookDate() {
    let value = '';
    if (this.cbVwPawOrderRequirement.bookStartDate && this.cbVwPawOrderRequirement.bookEndDate) {
      const startTime: string = dayjs(this.cbVwPawOrderRequirement.bookStartDate).format('HH:ss');
      const endTime: string = dayjs(this.cbVwPawOrderRequirement.bookEndDate).format('HH:ss');
      value = `${dayjs(this.cbVwPawOrderRequirement.bookStartDate).format('DD MMMM YYYY')} | ${startTime}-${endTime}`;
    }
    return value;
  }

  public get loginType() {
    return this.$store.getters.loginType;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('cbVwPawOrderRequirement', { deep: true })
  public cbVwPawOrderRequirementListener() {
    if (this.cbVwPawOrderRequirement?.id) {
      if (
        dayjs(this.cbVwPawOrderRequirement.offerExpiredDate).toDate().getTime() > dayjs().toDate().getTime() &&
        this.cbVwPawOrderRequirement.status == CbCommonStatus.WAITING_PAYMENT
      ) {
        this.visibleCountdown = true;
      } else {
        this.visibleCountdown = false;
      }

      if (this.cbVwPawOrderRequirement?.toWorkLocations?.length > 0) {
        const splitLocation = this.cbVwPawOrderRequirement.toWorkLocations.split('::');
        if (splitLocation.length > 0) {
          // 57037::ChIJ44AmghyNaS4R5dQ_bSN2kZI::Jl. Raya Pekayon, RT.003/RW.001, Jaka Setia, Kec. Bekasi Sel., Kota Bks, Jawa Barat 17148, Indonesia
          // 0 id location
          // 1 place id
          // 2 address
          this.cbLocation = {
            ...new CbLocation(),
            ...{
              id: +splitLocation[0],
              placeId: splitLocation[1],
              address: splitLocation[2],
            },
          };
        }
      }
    }
  }

  @Watch('cbVwPawOrder', { deep: true })
  public cbVwPawOrderListener() {
    if (this.cbVwPawOrder?.id) {
      this.cbPawOrderIncludes = this.cbVwPawOrder.cbVwPawOrderIncludes;
      this.cbVwPawOrderExtras = this.cbVwPawOrder.cbVwPawOrderExtras;
      this.cbPawOrderMilestones = this.cbVwPawOrder.cbVwPawOrderMilestones;

      if (this.cbVwPawOrder.orderStatus === CbCommonStatus.LATE) {
        this.retrieveCbPawOrderTrack();
      }
    }
  }
  // ================= END LISTENERS ===================
}
