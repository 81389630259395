import { ICbAuctionProject } from '@/shared/model/cb-auction-project.model';

import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { ICbLocation } from '@/shared/model/cb-location.model';

export interface ICbAuctionProjectPreOffer {
  id?: number;
  pitch?: string | null;
  deliveryTime?: number | null;
  deliveryTimeType?: string | null;
  revisionCount?: number | null;
  priceType?: CbPriceType | null;
  currency?: CbCurrency | null;
  budget?: number | null;
  status?: CbCommonStatus | null;
  acceptedBy?: string | null;
  acceptedDate?: Date | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbAuctionProject?: ICbAuctionProject | null;
  bookStartDate?: Date | null;
  bookEndDate?: Date | null;
  timeZone?: string | null;
  fromWorkLocation?: ICbLocation;
  toWorkLocation?: ICbLocation;
}

export class CbAuctionProjectPreOffer implements ICbAuctionProjectPreOffer {
  constructor(
    public id?: number,
    public pitch?: string | null,
    public deliveryTime?: number | null,
    public deliveryTimeType?: string | null,
    public revisionCount?: number | null,
    public priceType?: CbPriceType | null,
    public currency?: CbCurrency | null,
    public budget?: number | null,
    public status?: CbCommonStatus | null,
    public acceptedBy?: string | null,
    public acceptedDate?: Date | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbAuctionProject?: ICbAuctionProject | null,
    public bookStartDate?: Date | null,
    public bookEndDate?: Date | null,
    public timeZone?: string | null,
    public fromWorkLocation?: ICbLocation,
    public toWorkLocation?: ICbLocation
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
