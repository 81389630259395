var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "room-emojis",
      attrs: { id: "emojis-chat-message", "data-cy": "room-emojis" },
    },
    [
      _vm.seletedShowEmojis == "EMOT"
        ? _c("v-emoji-picker", {
            staticClass: "custom-emoji-picker",
            attrs: {
              emojisByRow: 18,
              emojiWithBorder: false,
              showSearch: false,
              emojiSize: 24,
            },
            on: {
              select: function ($event) {
                return _vm.$emit("add-emoji", $event)
              },
            },
          })
        : _vm.seletedShowEmojis == "GIF"
        ? _c(
            "div",
            { staticClass: "emojis-wrapper" },
            _vm._l(2, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "m-auto cursor-pointer" },
                [
                  _c("img", {
                    staticClass: "image-size",
                    attrs: {
                      src: `@/../content/images/chat/empty-chat.png`,
                      alt: "Images",
                    },
                  }),
                ]
              )
            }),
            0
          )
        : _vm.seletedShowEmojis == "STICKER"
        ? _c(
            "div",
            { staticClass: "emojis-wrapper" },
            _vm._l(_vm.cbStickers, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "m-auto cursor-pointer",
                  attrs: { "data-cy": "emojis-sticker" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSticker(item)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "image-size",
                    attrs: {
                      src: _vm.$options.filters.LAZY(
                        `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${item.itemLogoId}`
                      ),
                      onerror: _vm.$options.filters.LAZY_ERROR(),
                      alt: "Images",
                    },
                  }),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "p-2 d-flex tab-emojis-wrapper",
          attrs: { "data-cy": "tab-emojis-wrapper" },
        },
        [
          _c(
            "div",
            {
              staticClass: "vac-svg-button",
              class:
                _vm.seletedShowEmojis == "EMOT" ? "cj-color-red-primary" : "",
              on: {
                click: function ($event) {
                  _vm.seletedShowEmojis = "EMOT"
                },
              },
            },
            [_c("b-icon-emoji-wink", { staticClass: "font-size-20 my-auto" })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "vac-svg-button sticker-fill",
              class: _vm.seletedShowEmojis == "STICKER" ? "actived" : "",
              on: {
                click: function ($event) {
                  _vm.seletedShowEmojis = "STICKER"
                },
              },
            },
            [
              _c("icon", {
                staticClass: "icon-chat-sticker my-auto",
                attrs: {
                  name: "ic_cht_sticker",
                  width: "1.5rem",
                  height: "1.5rem",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }