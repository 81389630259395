import axios from 'axios';

import { ICbPaw } from '@/shared/model/cb-paw.model';

const baseApiUrl = 'services/cbcorems/api/cb-paws';

export default class PCbPawService {
  public create(entity: ICbPaw): Promise<ICbPaw> {
    return new Promise<ICbPaw>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public partialUpdate(entity: ICbPaw): Promise<ICbPaw> {
    return new Promise<ICbPaw>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
