import { Component, Inject, Vue } from 'vue-property-decorator';
import { ICbLov } from '../model/cb-lov.model';
import ContentService from '@/services/content.service';
import { CbLovType } from '../model/enumerations/cb-lov-type.model';

@Component
export default class locationUltis extends Vue {
  @Inject('contentService') private contentService: () => ContentService;

  private created() {
    this.contentService().initRetrieveCbLovs(CbLovType.CITY);
    this.contentService().initRetrieveCbLovs(CbLovType.COUNTRY);
    this.contentService().initRetrieveCbLovs(CbLovType.PROVINCE);
  }

  private get COUNTRY() {
    return this.$store.getters.COUNTRY;
  }
  private get PROVINCE() {
    return this.$store.getters.PROVINCE;
  }
  private get CITY() {
    return this.$store.getters.CITY;
  }
  private get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  public get getLanguangeLocation() {
    return location_key => {
      if (this.COUNTRY && this.COUNTRY.length > 0 && this.PROVINCE && this.PROVINCE.length > 0 && this.CITY && this.CITY.length > 0) {
        let joinedArray: ICbLov[] = this.COUNTRY.concat(this.PROVINCE, this.CITY);
        if (joinedArray && joinedArray.length > 0) {
          let location = joinedArray.find(x => x.key == location_key);
          if (location) {
            return this.currentLanguage == 'en' ? location.value : location.value2;
          }
          return location_key?this.$options.filters.capitalEachSpace(location_key):'-' ; // You might want to handle the case where the conditions are not met
        }
        return location_key?this.$options.filters.capitalEachSpace(location_key):'-' ; // You might want to handle the case where the conditions are not met
      }
      return location_key?this.$options.filters.capitalEachSpace(location_key):'-'; // You might want to handle the case where the conditions are not met
    };
  }
}
