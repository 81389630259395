var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-order-project-delivery",
      class: _vm.isMobile ? "cb-position-h " : "",
      attrs: { "data-cy": "modal-order-project-delivery" },
    },
    [
      _c(
        "div",
        {
          class: _vm.isMobile
            ? "cb-position-sticky-top wrap-border-grey-tertiary-bottom p-0"
            : "",
        },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("div", { class: { "min-btn": !_vm.isMobile } }, [
              _vm.isMobile
                ? _c(
                    "button",
                    {
                      staticClass: "d-flex my-auto cursor-pointer",
                      attrs: {
                        type: "button",
                        "data-cy": "project-delivery-btn-back",
                      },
                      on: { click: _vm.cancel },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass:
                          "cb-icon-size-arrow-left cj-color-black-primary p-3",
                        attrs: { icon: "fa-solid fa-arrow-left" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "head-wrapper" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary text-ellipsis-oneline text-lg-center",
                  class: {
                    "font-weight-700 font-size-24":
                      _vm.isDeviceSize === _vm.deviceSizeType.XL,
                    "font-weight-700 font-size-20":
                      _vm.isDeviceSize === _vm.deviceSizeType.LG,
                    "font-weight-600 font-size-18":
                      _vm.isDeviceSize === _vm.deviceSizeType.MD,
                    "font-weight-600 font-size-16":
                      _vm.isDeviceSize === _vm.deviceSizeType.SM,
                  },
                  attrs: { "data-cy": "project-delivery-title" },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cProjectDelivery.projectDelivery"
                        )
                      ),
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.getCbVwPawOrderProjectDelivery.seq) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-weight-400 text-ellipsis-oneline text-lg-center",
                  class: {
                    "font-size-14":
                      _vm.isDeviceSize === _vm.deviceSizeType.XL ||
                      _vm.isDeviceSize === _vm.deviceSizeType.LG,
                    "font-size-12": _vm.isDeviceSize === _vm.deviceSizeType.MD,
                    "font-size-10 text-ellipsis-oneline":
                      _vm.isDeviceSize === _vm.deviceSizeType.SM,
                  },
                  attrs: { "data-cy": "project-delivery-sub-title" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.cbVwPawOrder.labelOrderId) +
                      " | " +
                      _vm._s(_vm.cbVwPawOrder.pawTitle) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            !_vm.isMobile
              ? _c("div", { staticClass: "min-btn my-auto my-lg-0" }, [
                  _c(
                    "button",
                    {
                      staticClass: "d-flex my-auto cursor-pointer",
                      attrs: {
                        type: "button",
                        "data-cy": "project-delivery-btn-close",
                      },
                      on: { click: _vm.cancel },
                    },
                    [
                      _c("b-icon-x-circle-fill", {
                        staticClass:
                          "font-size-24 cj-color-grey-secondary ml-auto",
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            {
              class: {
                "wrap-border-grey-tertiary rounded my-3 px-2 py-3":
                  _vm.isMobile,
                "mt-2": !_vm.isMobile,
              },
            },
            [
              _vm.getCbVwPawOrderProjectDelivery.status
                ? _c("c-detail-order-status", {
                    staticClass:
                      "justify-content-center align-items-center gap-1",
                    attrs: {
                      type: _vm.typeComponent.MODAL_DELIVERY,
                      "order-status": _vm.getCbVwPawOrderProjectDelivery.status,
                      "expired-date":
                        _vm.getCbVwPawOrderRevision?.offerExpiredDate,
                      "aalt-date": _vm.cbVwPawOrder.aaltDate,
                      "freeze-date": _vm.cbVwPawOrder.freezeDate,
                      "interval-count-down-second":
                        _vm.cbVwPawOrder.xIntervalCountDownSecond,
                      "key-name": _vm.cbVwPawOrder.keyName,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "opd-finale-container mb-3" }, [
            _c(
              "p",
              {
                staticClass:
                  "font-roboto cj-color-black-primary font-weight-700 mb-2",
                class: {
                  "font-size-16": _vm.isDeviceSize === _vm.deviceSizeType.XL,
                  "font-size-16": _vm.isDeviceSize === _vm.deviceSizeType.LG,
                  "font-size-14": _vm.isDeviceSize === _vm.deviceSizeType.MD,
                  "font-size-12": _vm.isDeviceSize === _vm.deviceSizeType.SM,
                },
              },
              [_vm._v("\n        Project Delivery File\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "pd-finale-wrapper wrap-border-grey-tertiary rounded mb-2 scroll-height",
              },
              _vm._l(
                _vm.getCbVwPawOrderProjectDelivery?.cbPawOrderFiles,
                function (delivery, index) {
                  return _c("c-order-pd-item", {
                    key: delivery.id,
                    staticClass: "p-3",
                    class: index == 0 ? "" : "border-grey-tertiary-top-1",
                    attrs: {
                      "data-cy-custom": "project-delivery",
                      "file-name": delivery.fileName,
                      "file-att": delivery.cbAttachmentRestrict,
                    },
                  })
                }
              ),
              1
            ),
          ]),
          _vm._v(" "),
          _vm.getCbVwPawOrderExtraRevisionHistorys?.length > 0
            ? _c(
                "div",
                { staticClass: "opd-revision-request-history-container mb-3" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto cj-color-black-primary font-weight-700 mb-2",
                      class: {
                        "font-size-16":
                          _vm.isDeviceSize === _vm.deviceSizeType.XL,
                        "font-size-16":
                          _vm.isDeviceSize === _vm.deviceSizeType.LG,
                        "font-size-14":
                          _vm.isDeviceSize === _vm.deviceSizeType.MD,
                        "font-size-12":
                          _vm.isDeviceSize === _vm.deviceSizeType.SM,
                      },
                    },
                    [_vm._v("\n        Revision Request History\n      ")]
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.getCbVwPawOrderExtraRevisionHistorys,
                    function (history, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "wrap-border-grey-tertiary cj-bg-white-primary mb-2 rounded",
                          attrs: { "data-cy": "revision-request-history" },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle",
                                  value: `rq-history-${index}`,
                                  expression: "`rq-history-${index}`",
                                },
                              ],
                              staticClass: "d-flex mx-3 py-3",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cursor-pointer flex-grow-1 mr-2",
                                },
                                [
                                  history.status
                                    ? _c("c-detail-order-status", {
                                        attrs: {
                                          "data-cy-custom": "revision-history",
                                          type: _vm.typeComponent
                                            .MODAL_DELIVERY,
                                          "order-status": history.status,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("c-order-pd-date", {
                                    attrs: {
                                      date: history.createdDate,
                                      dataCyCustom: "revision-request",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-flex my-auto" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "my-auto",
                                    attrs: {
                                      type: "button",
                                      "data-cy": "revision-request-btn-drop",
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      staticClass:
                                        "my-auto cj-fill-red-primary ic_arrow_dropdown_up_down",
                                      attrs: {
                                        name: "ic_down",
                                        width: "20px",
                                        height: "20px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            {
                              attrs: {
                                id: `rq-history-${index}`,
                                visible:
                                  _vm.getCbVwPawOrderExtraRevisionHistorys
                                    .length === 1,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mx-3 py-3 border-grey-tertiary-top-1",
                                },
                                [
                                  _c("c-textarea-preview", {
                                    staticClass:
                                      "font-roboto cj-color-black-primary font-size-16 font-weight-400 text-break-all text-justify mb-3",
                                    attrs: {
                                      "data-cy": "revision-request",
                                      text: history?.cbTextRestrict?.content,
                                    },
                                  }),
                                  _vm._v(" "),
                                  history?.cbPawOrderFiles?.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "wrap-border-grey-tertiary rounded mb-3 scroll-height sub-scroll",
                                        },
                                        _vm._l(
                                          history?.cbPawOrderFiles,
                                          function (file, index) {
                                            return _c("c-order-pd-item", {
                                              key: file.id,
                                              staticClass: "p-2",
                                              class:
                                                index === 0
                                                  ? ""
                                                  : "border-grey-tertiary-top-1",
                                              attrs: {
                                                "data-cy": "revision-request",
                                                "file-att":
                                                  file.cbAttachmentRestrict,
                                                "file-name": file.fileName,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("c-order-pd-addl-price", {
                                    staticClass: "mb-3",
                                    attrs: {
                                      price: history.amount,
                                      currency: history.currency,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getCbVwPawOrderRevision?.id
            ? _c(
                "div",
                {
                  staticClass: "opd-revision-request-container mb-3",
                  attrs: { "data-cy": "revision-request" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto cj-color-black-primary font-weight-700 mb-2",
                      class: {
                        "font-size-16":
                          _vm.isDeviceSize === _vm.deviceSizeType.XL,
                        "font-size-16":
                          _vm.isDeviceSize === _vm.deviceSizeType.LG,
                        "font-size-14":
                          _vm.isDeviceSize === _vm.deviceSizeType.MD,
                        "font-size-12":
                          _vm.isDeviceSize === _vm.deviceSizeType.SM,
                      },
                      attrs: { "data-cy": "revision-title" },
                    },
                    [_vm._v("\n        Revision Request\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "wrap-border-grey-tertiary cj-bg-white-primary mb-2 rounded",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "p-3 d-flex justify-content-between align-items-center",
                          on: {
                            click: function ($event) {
                              _vm.isShowDetailRevisionExtra =
                                !_vm.isShowDetailRevisionExtra
                            },
                          },
                        },
                        [
                          _c("c-order-pd-date", {
                            attrs: {
                              "data-cy-custom": "revision",
                              date: _vm.getCbVwPawOrderRevision.createdDate,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "my-auto",
                              attrs: {
                                type: "button",
                                "data-cy": "revision-extra-btn-drop",
                              },
                            },
                            [
                              _c("icon", {
                                staticClass: "my-auto cj-fill-red-primary",
                                class: {
                                  ic_arrow_dropdown_up:
                                    _vm.isShowDetailRevisionExtra,
                                  ic_arrow_dropdown_up_down:
                                    !_vm.isShowDetailRevisionExtra,
                                },
                                attrs: {
                                  name: "ic_down",
                                  width: "20px",
                                  height: "20px",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        { attrs: { visible: _vm.isShowDetailRevisionExtra } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mx-3 py-3 border-grey-tertiary-top-1",
                            },
                            [
                              _c("c-textarea-preview", {
                                staticClass:
                                  "font-roboto cj-color-black-primary font-weight-400 text-break-all text-justify mb-3",
                                class: {
                                  "font-size-16":
                                    _vm.isDeviceSize ===
                                      _vm.deviceSizeType.XL ||
                                    _vm.isDeviceSize === _vm.deviceSizeType.LG,
                                  "font-size-14":
                                    _vm.isDeviceSize === _vm.deviceSizeType.MD,
                                  "font-size-12":
                                    _vm.isDeviceSize === _vm.deviceSizeType.SM,
                                },
                                attrs: {
                                  "data-cy-custom": "revision",
                                  text: _vm.getCbVwPawOrderRevision
                                    ?.cbTextRestrict?.content,
                                },
                              }),
                              _vm._v(" "),
                              _vm.getCbVwPawOrderRevision?.cbPawOrderFiles
                                ?.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "wrap-border-grey-tertiary rounded mb-3 scroll-height sub-scroll",
                                    },
                                    _vm._l(
                                      _vm.getCbVwPawOrderRevision
                                        ?.cbPawOrderFiles,
                                      function (file, index) {
                                        return _c("c-order-pd-item", {
                                          key: file.id,
                                          staticClass: "p-2",
                                          class:
                                            index === 0
                                              ? ""
                                              : "border-grey-tertiary-top-1",
                                          attrs: {
                                            "data-cy-custom": "revision",
                                            "file-name": file.fileName,
                                            "file-att":
                                              file.cbAttachmentRestrict,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.cbVwPawOrder.keyName ===
                                "REVISION_REQUESTED_EXTRA_FREE" &&
                                _vm.getCbVwPawOrderRevision.requirementType ===
                                  _vm.cbRequirementType.REVISION_EXTRA &&
                                _vm.getCbVwPawOrderRevision.status ===
                                  _vm.cbCommonStatus.REVISION_REQUESTED) ||
                              _vm.getCbVwPawOrderRevision.amount > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "wrap-border-grey-tertiary rounded mb-3 d-flex justify-content-between align-items-center p-3",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-roboto cj-color-black-primary font-weight-500 font-size-14",
                                        },
                                        [_vm._v("Additional Price")]
                                      ),
                                      _vm._v(" "),
                                      _vm.getCbVwPawOrderRevision.amount > 0
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-roboto cj-color-black-primary font-weight-500 font-size-14",
                                              attrs: {
                                                "data-cy":
                                                  "revision-additional-price",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getCbVwPawOrderRevision
                                                      .currency
                                                  ) +
                                                  "\n                " +
                                                  _vm._s(
                                                    _vm.$options.filters.formatCurrency(
                                                      _vm
                                                        .getCbVwPawOrderRevision
                                                        .amount,
                                                      _vm
                                                        .getCbVwPawOrderRevision
                                                        .currency
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-roboto cj-color-black-primary font-weight-500 font-size-14",
                                              attrs: {
                                                "data-cy":
                                                  "revision-additional-price",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getCbVwPawOrderRevision
                                                      .currency
                                                  ) +
                                                  "\n                " +
                                                  _vm._s(0) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  attrs: { visible: _vm.isExpandFormAddlPrice },
                                },
                                [
                                  _c("c-order-pd-addl-price", {
                                    ref: "addlPrice",
                                    staticClass: "mb-3",
                                    attrs: {
                                      "is-show-input": true,
                                      value: _vm.additionalPrice,
                                    },
                                    on: { input: _vm.inputAdditionalPrice },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-roboto cj-color-black-primary font-weight-700 mb-2",
                                          class: {
                                            "font-size-14":
                                              _vm.isDeviceSize ===
                                                _vm.deviceSizeType.XL ||
                                              _vm.isDeviceSize ===
                                                _vm.deviceSizeType.LG ||
                                              _vm.isDeviceSize ===
                                                _vm.deviceSizeType.MD,
                                            "font-size-12":
                                              _vm.isDeviceSize ===
                                              _vm.deviceSizeType.SM,
                                          },
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  "cbgwApp.cbChat.offerExpired"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "cj-color-grey-secondary",
                                            },
                                            [_vm._v("*")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("c-autocomplete-new", {
                                        staticClass:
                                          "rounded cj-bg-white-primary font-roboto font-weight-400 cj-color-black-primary my-auto",
                                        class: {
                                          "font-size-16":
                                            _vm.isDeviceSize ===
                                              _vm.deviceSizeType.XL ||
                                            _vm.isDeviceSize ===
                                              _vm.deviceSizeType.LG ||
                                            _vm.isDeviceSize ===
                                              _vm.deviceSizeType.MD,
                                          "font-size-14":
                                            _vm.isDeviceSize ===
                                            _vm.deviceSizeType.SM,
                                          invalid:
                                            _vm.$v.expiredTime.$anyDirty &&
                                            _vm.$v.expiredTime.$invalid,
                                          valid:
                                            _vm.$v.expiredTime.$anyDirty &&
                                            !_vm.$v.expiredTime.$invalid,
                                        },
                                        attrs: {
                                          "data-cy-custom": "expired-time",
                                          items: _vm.expiredTimes,
                                          "item-text": "value",
                                          "item-value": "key",
                                          placeholder: _vm.$t(
                                            "cbgwApp.cbChat.selectTime"
                                          ),
                                        },
                                        model: {
                                          value: _vm.$v.expiredTime.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.expiredTime,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.expiredTime.$model",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("p", {
                                        staticClass:
                                          "font-catamaran font-weight-400 font-size-12 cj-color-black-primary mt-2",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "cbgwApp.cbComponent.cChatMomEdit.offerExpiredInfo"
                                            )
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.isExpandFormAddlPrice &&
                              _vm.cbVwPawOrder.orderStatus ===
                                _vm.cbCommonStatus.REVISION_REQUESTED &&
                              (_vm.cbVwPawOrder.keyName ===
                                "ASK_FOR_REVISION_1" ||
                                _vm.cbVwPawOrder.keyName ===
                                  "STATUS_PARTNER_WIN") &&
                              _vm.cbVwPawOrder.partnerId ===
                                _vm.currentUser.id &&
                              _vm.getCbVwPawOrderRevision.id &&
                              _vm.getCbVwPawOrderRevision.requirementType ===
                                _vm.cbRequirementType.REVISION_EXTRA &&
                              _vm.getCbVwPawOrderRevision.status ===
                                _vm.cbCommonStatus.REVISION_REQUESTED
                                ? _c("c-order-pd-btn", {
                                    attrs: {
                                      dataCyCustom1:
                                        "btn-reject-extra-revision",
                                      dataCyCustom2:
                                        "btn-accept-extra-revision",
                                      textBtn1:
                                        "cbgwApp.cbComponent.cProjectDelivery.reject",
                                      textBtn2:
                                        "cbgwApp.cbComponent.cProjectDelivery.acceptAndSetPrice",
                                      disabledBtn1:
                                        _vm.cbVwPawOrder.orderStatus ===
                                          _vm.cbCommonStatus.DISPUTED ||
                                        _vm.isSavingCbPawOrderRequirementOne,
                                      disabledBtn2:
                                        _vm.cbVwPawOrder.orderStatus ===
                                          _vm.cbCommonStatus.DISPUTED ||
                                        _vm.isSavingCbPawOrderRequirementOne,
                                      isLoadingBtn1:
                                        _vm.isSavingCbPawOrderRequirementOne,
                                    },
                                    on: {
                                      handleBtn1: function ($event) {
                                        return _vm.handleActionConfirmationModal(
                                          "REVISION_EXTRA_REJECT_OPEN"
                                        )
                                      },
                                      handleBtn2: function ($event) {
                                        return _vm.handleExpandAction(
                                          "EXPAND_FORM_ADDL_PRICE"
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isExpandFormAddlPrice
                                ? _c("c-order-pd-btn", {
                                    attrs: {
                                      textBtn1:
                                        "cbgwApp.cbComponent.cProjectDelivery.cancel",
                                      textBtn2:
                                        "cbgwApp.cbComponent.cProjectDelivery.send",
                                      dataCyCustom1:
                                        "btn-cancel-accept-extra-revision",
                                      dataCyCustom2:
                                        "btn-cancel-send-extra-revision",
                                      disabledBtn1:
                                        _vm.isSavingCbPawOrderRequirementTwo,
                                      disabledBtn2:
                                        _vm.isSavingCbPawOrderRequirementTwo,
                                      isLoadingBtn1:
                                        _vm.isSavingCbPawOrderRequirementOne,
                                      isLoadingBtn2:
                                        _vm.isSavingCbPawOrderRequirementTwo,
                                    },
                                    on: {
                                      handleBtn1: function ($event) {
                                        return _vm.handleExpandAction(
                                          "NONEXPAND_FORM_ADDL_PRICE"
                                        )
                                      },
                                      handleBtn2:
                                        _vm.handleSendFormRevisionExtraRequest,
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isExpandFormAddlPrice &&
                              _vm.cbVwPawOrder.orderStatus ===
                                _vm.cbCommonStatus.REVISION_REQUESTED &&
                              (_vm.cbVwPawOrder.keyName ===
                                "REVISION_REQUESTED_CHARGE" ||
                                _vm.cbVwPawOrder.keyName ===
                                  "WAITING_FOR_PAYMENT_4" ||
                                _vm.cbVwPawOrder.keyName ===
                                  "REVISION_REQUESTED_EXTRA_FREE" ||
                                _vm.cbVwPawOrder.keyName ===
                                  "STATUS_PARTNER_WIN") &&
                              _vm.cbVwPawOrder.buyerId === _vm.currentUser.id &&
                              _vm.getCbVwPawOrderRevision.id &&
                              _vm.getCbVwPawOrderRevision.requirementType ===
                                _vm.cbRequirementType.REVISION_EXTRA &&
                              (_vm.getCbVwPawOrderRevision.status ===
                                _vm.cbCommonStatus.WAITING_PAYMENT ||
                                _vm.getCbVwPawOrderRevision.status ===
                                  _vm.cbCommonStatus.REVISION_REQUESTED)
                                ? _c("c-order-pd-btn", {
                                    attrs: {
                                      textBtn1:
                                        "cbgwApp.cbComponent.cProjectDelivery.reject",
                                      textBtn2:
                                        _vm.getCbVwPawOrderRevision.status ===
                                        _vm.cbCommonStatus.WAITING_PAYMENT
                                          ? "cbgwApp.cbComponent.cProjectDelivery.acceptAndPay"
                                          : "cbgwApp.cbComponent.cProjectDelivery.accept",
                                      dataCyCustom1:
                                        "btn-reject-price-extra-revision",
                                      dataCyCustom2:
                                        "btn-accept-price-extra-revision",
                                      disabledBtn1:
                                        _vm.cbVwPawOrder.orderStatus ===
                                          _vm.cbCommonStatus.DISPUTED ||
                                        _vm.isSavingCbPawOrderRequirementTwo,
                                      disabledBtn2:
                                        _vm.cbVwPawOrder.orderStatus ===
                                          _vm.cbCommonStatus.DISPUTED ||
                                        _vm.isSavingCbPawOrderRequirementTwo,
                                      isLoadingBtn2:
                                        _vm.isSavingCbPawOrderRequirementTwo,
                                    },
                                    on: {
                                      handleBtn1: function ($event) {
                                        return _vm.handleActionConfirmationModal(
                                          "REVISION_EXTRA_REJECT_PRICE_OPEN"
                                        )
                                      },
                                      handleBtn2: function ($event) {
                                        return _vm.handleAcceptAndPayRevisionExtra(
                                          _vm.getCbVwPawOrderRevision.status ===
                                            _vm.cbCommonStatus.WAITING_PAYMENT
                                            ? "HANDLE_MDL_PAYMENT_ORDER_OFFER_REVISION"
                                            : "HANDLE_MDL_PROJECT_DELIVERY_ACCEPT_EXTRA_FREE"
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: _vm.isExpandRevisionRequestForm } },
            [
              _c(
                "div",
                {
                  staticClass: "opd-form-revision-request-container",
                  class: { "mb-3": _vm.isMobile },
                  attrs: { "data-cy": "revision-request-form" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "textarea-revision-request-wrapper mb-3" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto cj-color-black-primary font-weight-700 mb-2",
                          class: {
                            "font-size-16":
                              _vm.isDeviceSize === _vm.deviceSizeType.XL,
                            "font-size-16":
                              _vm.isDeviceSize === _vm.deviceSizeType.LG,
                            "font-size-14":
                              _vm.isDeviceSize === _vm.deviceSizeType.MD,
                            "font-size-12":
                              _vm.isDeviceSize === _vm.deviceSizeType.SM,
                          },
                        },
                        [_vm._v("\n            Revision Request\n          ")]
                      ),
                      _vm._v(" "),
                      _c("c-textarea-new", {
                        staticClass:
                          "rounded font-roboto font-weight-400 cj-color-black-primary",
                        class: {
                          "font-size-16":
                            _vm.isDeviceSize === _vm.deviceSizeType.XL ||
                            _vm.isDeviceSize === _vm.deviceSizeType.LG,
                          "font-size-14":
                            _vm.isDeviceSize === _vm.deviceSizeType.MD,
                          "font-size-12":
                            _vm.isDeviceSize === _vm.deviceSizeType.SM,
                          invalid:
                            _vm.$v.cbTextRestrict.content.$anyDirty &&
                            _vm.$v.cbTextRestrict.content.$invalid,
                          valid:
                            _vm.$v.cbTextRestrict.content.$anyDirty &&
                            !_vm.$v.cbTextRestrict.content.$invalid,
                        },
                        attrs: {
                          "data-cy-custom": "input-revision-reason",
                          "data-cy": "input-revision-reason-wrap",
                          placeholder: _vm.$t(
                            "cbgwApp.cbSetting.accountDeactivation.typeReason"
                          ),
                          maxlength: 1000,
                        },
                        model: {
                          value: _vm.$v.cbTextRestrict.content.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.cbTextRestrict.content,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.cbTextRestrict.content.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "font-validation mt-1",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cAskRevision.defaultInformation"
                            )
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "upload-revision-request-wrapper mb-3" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto cj-color-black-primary font-weight-700 mb-2",
                          class: {
                            "font-size-16":
                              _vm.isDeviceSize === _vm.deviceSizeType.XL,
                            "font-size-16":
                              _vm.isDeviceSize === _vm.deviceSizeType.LG,
                            "font-size-14":
                              _vm.isDeviceSize === _vm.deviceSizeType.MD,
                            "font-size-12":
                              _vm.isDeviceSize === _vm.deviceSizeType.SM,
                          },
                        },
                        [
                          _vm._v("\n            Attachment"),
                          _c(
                            "span",
                            { staticClass: "ml-1 cj-color-grey-secondary" },
                            [_vm._v("(Optional)")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("c-upload-file", {
                        attrs: {
                          "data-cy-custom": "revision-reason",
                          files: _vm.cbPawOrderFiles,
                          accept: _vm.fileAccept,
                          fileType: "attContentType",
                          fileUrl: "localUrl",
                          fileThumbnail: "thumbnail",
                          secondObj: "cbAttachmentRestrict",
                          textUploadCondition: _vm.$t(
                            "cbgwApp.cbAuctionProject.max10mb"
                          ),
                        },
                        on: {
                          onFileChange: _vm.handleUploadFile,
                          removeFile: _vm.handleClear,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("c-order-pd-btn", {
                    attrs: {
                      textBtn1: "cbgwApp.cbComponent.cProjectDelivery.cancel",
                      textBtn2: "cbgwApp.cbComponent.cProjectDelivery.send",
                      disabledBtn1: _vm.isSavingCbPawOrderRequirementTwo,
                      disabledBtn2: _vm.isSavingCbPawOrderRequirementTwo,
                      isLoadingBtn1: _vm.isSavingCbPawOrderRequirementOne,
                      isLoadingBtn2: _vm.isSavingCbPawOrderRequirementTwo,
                      dataCyCustom1: "btn-non-expand-revision",
                      dataCyCustom2: "btn-send-revision-request",
                    },
                    on: {
                      handleBtn1: function ($event) {
                        return _vm.handleExpandAction("NONEXPAND_FORM_REVISION")
                      },
                      handleBtn2: function ($event) {
                        return _vm.handleSendFormRevisionRequest(
                          _vm.getCbVwPawOrderProjectDelivery
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isExpandRevisionRequestForm &&
      !_vm.getCbVwPawOrderRevision?.id &&
      _vm.cbVwPawOrder.buyerId === _vm.currentUser.id &&
      _vm.cbVwPawOrder.orderStatus === _vm.cbCommonStatus.FEEDBACK_NEEDED &&
      _vm.getCbVwPawOrderProjectDelivery.status ===
        _vm.cbCommonStatus.FEEDBACK_NEEDED &&
      _vm.getCbVwPawOrderProjectDelivery.requirementType ===
        _vm.cbRequirementType.DELIVERY
        ? _c(
            "div",
            {
              class: _vm.isMobile
                ? "mt-auto cb-position-sticky-bottom border-grey-tertiary-top-1"
                : "",
            },
            [
              _c("c-order-pd-btn", {
                attrs: {
                  textConditionBtn1:
                    _vm.getCbVwPawOrderProjectDelivery.revisionUsed <
                    _vm.getCbVwPawOrderProjectDelivery.revisionTotal,
                  textBtnAdditional1: _vm.getRevisionNumber,
                  textBtn2:
                    "cbgwApp.cbComponent.cProjectDelivery.acceptAndCompleteOrder",
                  dataCyCustom1:
                    _vm.getCbVwPawOrderProjectDelivery.revisionUsed <
                    _vm.getCbVwPawOrderProjectDelivery.revisionTotal
                      ? "btn-expand-revision"
                      : "btn-expand-revision-extra",
                  dataCyCustom2: "btn-accept-delivery-open",
                  disabledBtn1:
                    _vm.cbVwPawOrder.orderStatus ===
                    _vm.cbCommonStatus.DISPUTED,
                  disabledBtn2:
                    _vm.cbVwPawOrder.orderStatus ===
                    _vm.cbCommonStatus.DISPUTED,
                },
                on: {
                  handleBtn1: function ($event) {
                    return _vm.handleExpandAction("EXPAND_FORM_REVISION")
                  },
                  handleBtn2: function ($event) {
                    return _vm.handleActionConfirmationModal(
                      "ACCEPT_DELIVERY_OPEN"
                    )
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-accept-project-delivery",
          "hide-footer": "",
          "hide-header": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    isLoading: _vm.isSavingCbPawOrderRequirementOne,
                    title: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.confirmationAccProjectDeliveryPriceTitle"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.confirmationAccProjectDeliverySubTitle"
                    ),
                    submitTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.accept&Complete"
                    ),
                    cancelTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.cancel"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: function ($event) {
                      if (_vm.isSavingCbPawOrderRequirementOne) return
                      cancel()
                    },
                    close: function ($event) {
                      if (_vm.isSavingCbPawOrderRequirementOne) return
                      cancel()
                    },
                    save: _vm.handleCompleteOrderProjectDelivery,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-reject-revision-request",
          "hide-footer": "",
          "hide-header": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    isLoading: _vm.isSavingCbPawOrderRequirementOne,
                    title: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.confirmationRejectRequestTitle"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.confirmationRejectRequestSubTitle"
                    ),
                    submitTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.rejectRequest"
                    ),
                    cancelTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.cancel"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: function ($event) {
                      if (_vm.isSavingCbPawOrderRequirementOne) return
                      cancel()
                    },
                    close: function ($event) {
                      if (_vm.isSavingCbPawOrderRequirementOne) return
                      cancel()
                    },
                    save: function ($event) {
                      return _vm.handleRejectRequestRevisionExtra(
                        "REQUEST_REVISION_EXTRA"
                      )
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-confirmation-reject-extra-price",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    isLoading: _vm.isSavingCbPawOrderRequirementOne,
                    title: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.confirmationRejectPriceTitle"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.confirmationRejectPriceSubTitle"
                    ),
                    submitTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.rejectPrice"
                    ),
                    cancelTitle: _vm.$t(
                      "cbgwApp.cbComponent.cProjectDelivery.cancel"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: function ($event) {
                      if (_vm.isSavingCbPawOrderRequirementOne) return
                      cancel()
                    },
                    close: function ($event) {
                      if (_vm.isSavingCbPawOrderRequirementOne) return
                      cancel()
                    },
                    save: function ($event) {
                      return _vm.handleRejectRequestRevisionExtra(
                        "REQUEST_ADDITIONAL_PRICE"
                      )
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }