import axios from 'axios';
import { Inject, Component, Emit, Mixins } from 'vue-property-decorator';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import CButton from '../button/c-button.vue';
import GlobalService from '@/services/global.service';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import Icon from '@/shared/icons/icon.vue';
import CTimeCountdown from '../c-time-countdown/c-time-countdown.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import FormUtils from '@/shared/utils/form-utils.service';
const validations = {
  resetAccount: {
    email: {
      required,
      email,
      minLength: minLength(5),
      maxLength: maxLength(255),
    },
  },
};
interface ResetAccount {
  email: string;
}

@Component({
  validations,
  watch: {
    email: {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 255;
        const total = newVal?.length ? newVal.length : 0;
        if (total > max) {
          this.email = oldVal;
        }

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
  components: {
    CButton,
    CConfirmation,
    Icon,
    CTimeCountdown,
  },
})
export default class CResetPassword extends Mixins(JhiDataUtils, FormUtils) {
  // ================= SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;

  // ================= SERVICES =================
  // ================= VARIABLES =================
  public success: boolean = null;
  public error: string = null;
  public resetAccount: ResetAccount = {
    email: null,
  };
  public showCountdown: boolean = true;
  public isInit: boolean = false;
  public isEmail = null;
  // ================= VARIABLES =================
  // ================= DEFAULT FUNCTION =================
  // ================= DEFAULT FUNCTION =================
  // ================= FUNCTION =================
  public requestReset(): void {
    this.error = null;
    this.isInit = true;

    if (RegExp(/^(.+)@(.+)$/).test(this.resetAccount.email)) {
      this.isEmail = true;
    } else {
      this.isEmail = false;
    }

    axios
      .post('services/cbuserms/api/account/reset-password/init', this.resetAccount.email, {
        headers: {
          'content-type': 'text/plain',
        },
      })
      .then(() => {
        this.success = true;
        this.showCountdown = true;
        this.isInit = false;
      })
      .catch(() => {
        this.success = null;
        this.isInit = false;
        this.error = 'ERROR';
      });
  }

  public resend() {
    if (this.success && !this.showCountdown) {
      this.requestReset();
    }
  }

  public openModalRegister() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-reset-password');
    this.globalService().openGlobalDialog(this.$root, 'modal-c-register');
  }

  @Emit('cancel')
  public cancel() {
    return;
  }

  // ================= FUNCTION =================
  // ================= COMPUTE =================
  // ================= COMPUTE =================
}
