import { Component, Prop } from 'vue-property-decorator';
import { DEFAULT_LAT, DEFAULT_LNG } from '@/constants';
import { CbLocation, ICbLocation } from '@/shared/model/cb-location.model';
import Icon from '@/shared/icons/icon.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { mixins } from 'vue-class-component';
import { GoogleMapUtils } from '@/components/location-track/googlemapUtils';
import { Flutter } from '@/app-flutter';

@Component({
  components: {
    Icon,
  },
})
export default class CLocationMap extends mixins(JhiDataUtils) {
  @Prop()
  public mapSize: string;
  @Prop({ default: new CbLocation() })
  public placeModel: CbLocation;

  public newPlaceModel: ICbLocation = new CbLocation();
  public map: google.maps.Map<HTMLElement>;
  public infowindow: google.maps.InfoWindow;
  public infoWindowContent: HTMLElement;
  public marker: google.maps.Marker;
  public markerCurrentPos: google.maps.Marker;
  public snapshotImageData: string;
  public pointedLocation: google.maps.LatLng;
  public currentLocation: google.maps.LatLng;
  public placeService: google.maps.places.PlacesService;
  public geocoder: google.maps.Geocoder;
  public currentGeolocationStatus = '';

  public mounted(): void {
    this.createMarker();
    this.createCurrentLocationMarker();
    this.initMap();
  }

  private async getPosition(): Promise<google.maps.LatLng> {
    if (Flutter.isRunOn()) {
      return new Promise((resolve, reject) => {
        this.permissionLocationFlutter(async () => {
          try {
            const position = await this.getPositionLocation();
            resolve(position);
          } catch (error) {
            reject(error);
          }
        });
      });
    } else {
      return this.getPositionLocation();
    }
  }

  private getPositionLocation(): Promise<google.maps.LatLng> {
    return new Promise(resolve => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => resolve(new google.maps.LatLng(position.coords.latitude, position.coords.longitude)),
          () => {
            if (this.placeModel && this.placeModel.latGoogle && this.placeModel.longGoogle) {
              resolve(new google.maps.LatLng(Number(this.placeModel.latGoogle), Number(this.placeModel.longGoogle)));
              if (Flutter.isRunOn()) {
                Flutter.call('permissionLocationStatus', {
                  permissionLocationStatus: 'PERMISSION_GRANTED',
                });
              }
            } else {
              resolve(new google.maps.LatLng(DEFAULT_LAT, DEFAULT_LNG));
            }
          }
        ),
          error => {
            if (Flutter.isRunOn()) {
              if (error.code === error.PERMISSION_DENIED) {
                console.log('Geolocation permission denied (Flutter):', error.message);
                Flutter.call('permissionLocationStatus', {
                  permissionLocationStatus: 'PERMISSION_DENIED',
                });
              }
            }
            console.log('ERR', error.message);
          };
        return;
      } else resolve(new google.maps.LatLng(DEFAULT_LAT, DEFAULT_LNG));
    });
  }

  public async initMap() {
    this.pointedLocation = await this.getPosition();
    this.currentLocation = this.pointedLocation;
    this.map = new google.maps.Map<HTMLElement>(document.getElementById('map'), {
      center: this.pointedLocation,
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });
    this.addRefocusToCurrentPositionButton();
    this.markerCurrentPos.setPosition(this.pointedLocation);
    this.markerCurrentPos.setMap(this.map);
    this.marker.setPosition(this.pointedLocation);
    this.marker.setMap(this.map);
    this.placeService = new google.maps.places.PlacesService(this.map);
    this.geocoder = new google.maps.Geocoder();
    let result: google.maps.PlaceResult | google.maps.GeocoderResult;
    if (this.placeModel && this.placeModel.placeId) {
      result = (await GoogleMapUtils.getDetailByPlaceId(this.placeService, this.placeModel.placeId)) as google.maps.PlaceResult;
    } else if (this.placeModel && !this.placeModel.placeId && this.placeModel.latGoogle) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(this.pointedLocation);
      this.map.fitBounds(bounds);
      this.map.setZoom(16);
      result = await GoogleMapUtils.getDetailByLatLng(this.geocoder, this.pointedLocation);
    }
    if (!result) return;
    this.handlePlaceResultOrGeocoderResult(result);
    this.initAutoComplete();
  }

  private addRefocusToCurrentPositionButton() {
    const centerControlDiv = document.getElementById('cur-loc') as HTMLElement;
    const controlButton = centerControlDiv.children.namedItem('loc-button') as HTMLElement;
    controlButton.addEventListener('click', async () => {
      this.refocusMarkerCurrentPos();
      this.handlePlaceResultOrGeocoderResult(await GoogleMapUtils.getDetailByLatLng(this.geocoder, this.currentLocation));
      this.pointedLocation = this.currentLocation;
    });
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
  }

  private initAutoComplete() {
    const inputWrapper = document.getElementById('pac-input-wraper') as HTMLElement;
    const input = inputWrapper.querySelector('#pac-input') as HTMLInputElement;
    const inputClear = inputWrapper.querySelector('#pac-input-clear') as HTMLButtonElement;
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(inputWrapper);
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', this.map);
    autocomplete.addListener('place_changed', () => {
      this.infowindow.close();
      const place = autocomplete.getPlace();
      this.handlePlaceResultOrGeocoderResult(place as google.maps.PlaceResult);
    });
    input.addEventListener('input', () => {
      if (input.value.trim() === '') {
        inputClear.style.display = 'none';
      } else {
        inputClear.style.display = 'flex';
      }
    });
    inputClear.addEventListener('click', () => {
      if (input) {
        input.value = '';
        input.focus();
      }
      input.dispatchEvent(new Event('input'));
    });

    if (input.value.trim() === '') {
      inputClear.style.display = 'none';
    } else {
      inputClear.style.display = 'flex';
    }
  }

  private createMarker() {
    this.infowindow = new google.maps.InfoWindow();
    this.infoWindowContent = document.getElementById('infowindow-content') as HTMLElement;
    this.infowindow.setContent(this.infoWindowContent);
    this.marker = new google.maps.Marker({
      map: this.map,
      draggable: true,
      zIndex: 101,
    });
    this.marker.addListener('click', () => this.infowindow.open(this.map, this.marker));
    this.marker.addListener('dragend', async e =>
      this.handlePlaceResultOrGeocoderResult(await GoogleMapUtils.getDetailByLatLng(this.geocoder, e.latLng))
    );
  }

  private createCurrentLocationMarker() {
    this.markerCurrentPos = new google.maps.Marker({
      map: this.map,
      draggable: false,
      zIndex: 100,
      icon: `${process.env.CDN_CB}/content/ic_map_position_circle.png`,
    });
  }

  private updateMarker(placeResult: google.maps.PlaceResult) {
    if (placeResult.geometry.viewport) {
      this.map.fitBounds(placeResult.geometry.viewport);
    } else {
      this.map.setCenter(placeResult.geometry.location);
      this.map.setZoom(17);
    }
    this.marker.setPosition(placeResult.geometry.location);
    this.marker.setVisible(true);
    (this.infoWindowContent.children.namedItem('place-name') as HTMLElement).textContent = placeResult.name;
    this.newPlaceModel.placeName = placeResult.name;
    (this.infoWindowContent.children.namedItem('place-address') as HTMLElement).textContent = placeResult.formatted_address;
    this.infowindow.open(this.map, this.marker);
    const input = document.getElementById('pac-input') as HTMLInputElement;
    input.value = placeResult.formatted_address;
  }

  private refocusMarkerCurrentPos() {
    this.map.setCenter(this.currentLocation);
    this.map.setZoom(17);
    this.markerCurrentPos.setPosition(this.currentLocation);
    this.markerCurrentPos.setVisible(true);
  }

  public handlePlaceResultOrGeocoderResult(placeResult: google.maps.PlaceResult | google.maps.GeocoderResult) {
    if (!placeResult.geometry || !placeResult.geometry.location) return;
    this.updateMarker(placeResult);
    const ac = placeResult.address_components;
    const componentForm = {
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
    };
    let cityCountry = '';
    for (let i = 0; i < placeResult.address_components.length; i++) {
      const addressType = placeResult.address_components[i].types[0];
      if (componentForm[addressType]) {
        const val = placeResult.address_components[i][componentForm[addressType]];
        cityCountry = cityCountry + val + (addressType == 'locality' || addressType == 'administrative_area_level_1' ? ',' : '') + ' ';
      }
    }
    if (!this.newPlaceModel) this.newPlaceModel = new CbLocation();

    this.pointedLocation = placeResult.geometry.location;

    //adehuh
    let country: string;
    let province: string;
    let city: string;
    let subCity: string;
    let zipCode: string;

    for (let c = 0; c < ac.length; c++) {
      if (ac[c].short_name.length == 2) {
        // country
        country = ac[c].long_name;
        if (ac[c - 1] != null) province = ac[c - 1].long_name;
        if (ac[c - 2] != null) city = ac[c - 2].long_name;
        if (ac[c - 3] != null) subCity = ac[c - 3].long_name;
        if (ac[c + 1] != null) zipCode = ac[c + 1].long_name;
      }
    }

    this.newPlaceModel = {
      ...this.newPlaceModel,
      country,
      province,
      city,
      subCity,
      zipCode,
      placeId: placeResult.place_id,
      address: placeResult.formatted_address,
      latGoogle: placeResult.geometry.location.lat() + '',
      longGoogle: placeResult.geometry.location.lng() + '',
      addressPinPoint: placeResult.formatted_address,
    };

    console.log(this.newPlaceModel);
    this.$emit('callback', this.newPlaceModel);
  }

  public async createSnapshot(): Promise<string> {
    this.map.panTo(this.pointedLocation);
    this.infowindow.close();
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].clear();
    console.log(this.map.controls[google.maps.ControlPosition.TOP_LEFT]);
    await this.sleep(500);
    return new Promise<string>(resolve => {
      this.createSnapshotOfElement('#map', 0, 0, base64MapData => {
        this.snapshotImageData = base64MapData;
        resolve(this.snapshotImageData);
      });
    });
  }

  public sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
