var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-input-revision",
      attrs: {
        "data-gtm": _vm.dataGTMCustom
          ? `${_vm.dataGTMCustom}-revision`
          : _vm.revision - _vm.button,
        "data-cy": _vm.dataCyCustom
          ? `revision-${_vm.dataCyCustom}`
          : "button-revision",
      },
    },
    [
      _c(
        "button",
        {
          staticClass:
            "rounded cj-field-border-default px-3 py-2 mr-2 custom-mr-project-pitch",
          class: {
            active: _vm.value == 1,
            valid: _vm.isValid && _vm.value == 1,
          },
          attrs: {
            "data-cy": "button-input-revision",
            "data-gtm": "input-revision-button-1",
            type: "button",
          },
          on: {
            click: function ($event) {
              return _vm.handleSelectRevision(1)
            },
          },
        },
        [_vm._v("\n    1\n  ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "rounded cj-field-border-default px-3 py-2 mr-2 custom-mr-project-pitch",
          class: {
            active: _vm.value == 2,
            valid: _vm.isValid && _vm.value == 2,
          },
          attrs: {
            "data-cy": "button-input-revision",
            "data-gtm": "input-revision-button-2",
            type: "button",
          },
          on: {
            click: function ($event) {
              return _vm.handleSelectRevision(2)
            },
          },
        },
        [_vm._v("\n    2\n  ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "rounded cj-field-border-default px-3 py-2",
          class: {
            active: _vm.value == 3,
            valid: _vm.isValid && _vm.value == 3,
          },
          attrs: {
            "data-cy": "button-input-revision",
            "data-gtm": "input-revision-button-3",
            type: "button",
          },
          on: {
            click: function ($event) {
              return _vm.handleSelectRevision(3)
            },
          },
        },
        [_vm._v("\n    3\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }