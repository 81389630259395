export interface ICbVwUserStatus {
  id?: string;
  isHibernate?: boolean | null;
  isDeactived?: boolean | null;
  isDeleted?: boolean | null;
  isSuspend?: boolean | null;
  isBlocked?: boolean | null;
  suspendTimeRemaining?: number | null;
  suspendTimeRemainingLabel?: string | null;
  timeLabelId?: string | null; //transient
  timeLabelEn?: string | null; //transient
}

export class CbVwUserStatus implements ICbVwUserStatus {
  constructor(
    public id?: string,
    public isHibernate?: boolean | null,
    public isDeactived?: boolean | null,
    public isDeleted?: boolean | null,
    public isSuspend?: boolean | null,
    public isBlocked?: boolean | null,
    public suspendTimeRemaining?: number | null,
    public suspendTimeRemainingLabel?: string | null,
    public timeLabelId?: string | null,
    public timeLabelEn?: string | null
  ) {}
}
