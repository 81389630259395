var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-audio" }, [
    _c("audio", {
      ref: "myAudioTyping",
      attrs: { src: require("../../../assets/audio/typing.mp3") },
    }),
    _vm._v(" "),
    _c("audio", {
      ref: "myAudioSendingMessage",
      attrs: { src: require("../../../assets/audio/sending-messagev2.mp3") },
    }),
    _vm._v(" "),
    _c("audio", {
      ref: "myAudioSpacingShiftingEmoticon",
      attrs: {
        src: require("../../../assets/audio/spacing-shifting-emoticon.mp3"),
      },
    }),
    _vm._v(" "),
    _c("audio", {
      ref: "myAudioReceivingMessage",
      attrs: { src: require("../../../assets/audio/receiving-message.mp3") },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }