import { ref } from 'vue';
import Vue2Filters from 'vue2-filters';
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import dayjs from 'dayjs';

import Icon from '@/shared/icons/icon.vue';
import CButton from '@/components/button/c-button.vue';
import CItemProgressMilestone from './c-item-progress-milestone/c-item-progress-milestone.vue';
import CInput from '../c-input/c-input.vue';
import CTimepicker from '../c-timepicker/c-timepicker.vue';

import JhiDataUtils from '@/shared/data/data-utils.service';
import { DATE_SLASH_FORMAT } from '@/shared/date/filters';

import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { ICbPawOrderMilestone, CbPawOrderMilestone } from '@/shared/model/cb-paw-order-milestone.model';
import { required } from 'vuelidate/lib/validators';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbDeliveryTimeType } from '@/shared/model/enumerations/cb-delivery-time-type.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';

const validations: any = {
  cbPawOrderMilestone: {
    id: {},
    name: {
      required,
    },
    date: {},
    progress: {},
    isStart: {},
    isEnd: {},
    status: {},
    completedDate: {
      required,
    },
    isActive: {},
    createdBy: {},
    createdDate: {},
    lastModifiedBy: {},
    lastModifiedDate: {},
    cbPawOrder: {},
    time: {},
  },
};
@Component({
  validations,
  components: {
    Icon,
    CItemProgressMilestone,
    CButton,
    CInput,
    CTimepicker,
  },
  mixins: [Vue2Filters.mixin],
})
export default class CProgressMilestone extends Mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) value: ICbPawOrderMilestone[];
  @Prop({ default: '' }) type: TypeComponent;
  @Prop({ default: false }) isEdit: boolean;
  @Prop({ default: false }) isHasMilestone: boolean;
  @Prop({ default: null }) showAction: CbLoginType;
  @Prop({ default: 'cj-bg-red-primary' }) milestoneBackground: string;
  @Prop({ default: 'cj-bg-red-primary' }) milestoneLine: string;
  @Prop({ default: null }) cbVwPawOrder: ICbVwPawOrder;
  @Prop({ default: null }) milestoneMaxDate: Date;
  @Prop({ default: null }) milestoneMinDate: Date;
  @Prop({ default: null }) milestoneValidation: boolean;
  @Prop({ default: false }) showProgress: boolean;

  public cbPawOrderMilestone: ICbPawOrderMilestone = new CbPawOrderMilestone();
  public CbCommonStatus = CbCommonStatus;
  public TypeComponent = TypeComponent;
  public CbLoginType = CbLoginType;

  public validateDueTime: boolean = null;
  public milestoneHour: Date | string = null;

  public masks = ref({
    input: DATE_SLASH_FORMAT,
  });

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.cbPawOrderMilestonesListener();
    this.insertDataFromParent();
  }

  public updated() {
    if (this.$v.cbPawOrderMilestone.name.$model === '') {
      this.resetValidation();

      if (this.cbPawOrderMilestone.time) this.$v.cbPawOrderMilestone.name.$touch();
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public resetValidation() {
    this.cbPawOrderMilestone.name = null;
    this.$v.$reset();
  }

  public touchValidation() {
    if (this.$v.cbPawOrderMilestone.completedDate.$invalid) this.$v.cbPawOrderMilestone.completedDate.$touch();
    if (this.$v.cbPawOrderMilestone.name.$invalid) this.$v.cbPawOrderMilestone.name.$touch();

    if (!this.milestoneValidation) {
      this.$v.cbPawOrderMilestone.time.$touch();

      if (this.cbPawOrderMilestone.name && !this.cbPawOrderMilestone.time) this.validateDueTime = false;
      if (this.cbPawOrderMilestone.time) this.$v.cbPawOrderMilestone.name.$touch();

      this.validateDueTime = this.validateInputTime();
    }
  }

  public handleTime(time: Date): void {
    const splitStartHour = time.toString().split(':');
    this.milestoneHour = time;

    if (+time.toString().split(':')[0] < dayjs(this.milestoneMinDate).hour()) {
      this.cbPawOrderMilestone.completedDate = dayjs()
        .add(1, 'day')
        .set('hour', +time.toString().split(':')[0])
        .set('minute', +time.toString().split(':')[1])
        .toDate();
    } else {
      this.cbPawOrderMilestone.completedDate = dayjs(this.milestoneMinDate)
        .set('hour', +splitStartHour[0])
        .set('minute', +splitStartHour[1])
        .toDate();
    }

    this.touchValidation();
  }

  public validateInputTime() {
    if (dayjs(this.milestoneMaxDate).toDate().getDate() > dayjs().toDate().getDate()) {
      if (
        Number(this.cbPawOrderMilestone.completedDate) > dayjs(this.milestoneMinDate).toDate().getTime() ||
        Number(this.cbPawOrderMilestone.completedDate) < dayjs(this.milestoneMaxDate).toDate().getTime()
      ) {
        return true;
      }
    } else {
      if (
        Number(this.cbPawOrderMilestone.completedDate) > dayjs(this.milestoneMinDate).toDate().getTime() &&
        Number(this.cbPawOrderMilestone.completedDate) < dayjs(this.milestoneMaxDate).toDate().getTime()
      ) {
        return true;
      }
    }

    return false;
  }

  public finishDate(entity) {
    let value: any = null;

    if (entity?.dealDate && entity?.deliveryTime && entity?.deliveryTimeType) {
      const total: number = dayjs(entity.dealDate).toDate().getDate() + entity.deliveryTime;
      if (entity.deliveryTimeType === CbDeliveryTimeType.DAY) {
        value = dayjs(entity.dealDate).set('day', total);
      }
      if (entity.deliveryTimeType === CbDeliveryTimeType.HOUR) {
        value = dayjs(entity.dealDate).set('hour', total);
      }
      if (entity.deliveryTimeType === CbDeliveryTimeType.MINUTE) {
        value = dayjs(entity.dealDate).set('minute', total);
      }
    } else if (entity?.expiredDate !== null) {
      value = entity?.expiredDate;
    }

    return value;
  }

  public insertDataFromParent() {
    if (this.cbPawOrderMilestone.name && this.cbPawOrderMilestone.completedDate) {
      if (!this.milestoneValidation) {
        this.milestoneHour = dayjs(this.cbPawOrderMilestone.completedDate).format('HH:mm');
      }

      this.touchValidation();
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getMilestoneProgress() {
    if (
      (this.showAction === CbLoginType.BUYER && this.$v.cbPawOrderMilestone.status.$model === CbCommonStatus.COMPLETED) ||
      (this.showAction === CbLoginType.PARTNER &&
        (this.$v.cbPawOrderMilestone.status.$model === CbCommonStatus.COMPLETED ||
          this.$v.cbPawOrderMilestone.status.$model === CbCommonStatus.ACTIVE))
    ) {
      return true;
    }
    return false;
  }

  public get getMilestoneButtonUpdateCondition() {
    if (
      (this.cbVwPawOrder.orderStatus === CbCommonStatus.OVERDUE &&
        this.getIndexAlreadySendProjectDelivery === -1 &&
        this.cbPawOrderMilestone.status === CbCommonStatus.ACTIVE) ||
      ((this.cbVwPawOrder.orderStatus === CbCommonStatus.OVERDUE ||
        this.cbVwPawOrder.orderStatus === CbCommonStatus.ON_PROGRESS ||
        this.cbVwPawOrder.orderStatus === CbCommonStatus.FEEDBACK_NEEDED ||
        this.cbVwPawOrder.orderStatus === CbCommonStatus.REVISION_REQUESTED ||
        this.cbVwPawOrder.orderStatus === CbCommonStatus.DISPUTED ||
        this.cbVwPawOrder.orderStatus === CbCommonStatus.COMPLETED ||
        this.cbVwPawOrder.orderStatus === CbCommonStatus.CANCELLED) &&
        this.cbPawOrderMilestone.status === CbCommonStatus.ACTIVE &&
        this.getIndexAlreadySendProjectDelivery !== -1)
    ) {
      return true;
    }

    return false;
  }

  public get getIndexAlreadySendProjectDelivery() {
    return this.cbVwPawOrder.cbVwPawOrderRequirements?.findIndex(req =>
      [CbRequirementType.DELIVERY].includes(<CbRequirementType>req.requirementType)
    );
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get currentLanguage(): string {
    return this.$store.getters.currentLanguage;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('value', { deep: true })
  public cbPawOrderMilestonesListener() {
    if (this.value?.length > 0) {
      if (this.cbVwPawOrder.isHasMilestone && this.isHasMilestone) {
        this.cbPawOrderMilestone = Object.assign({}, this.cbPawOrderMilestone, this.value[0]);
      } else {
        this.cbPawOrderMilestone = Object.assign(
          {},
          this.cbPawOrderMilestone,
          this.value.find(milestone => milestone.progress === 50)
        );
      }
    }
  }
  // ================= END LISTENERS ===================
}
