<template>
  <div class="vac-message-files-container">
    <div
      v-if="imageVideoFiles"
      class="var-message-video-image-container"
      :class="{
        'multi-imgvideo': imageVideoFiles.length >= 2,
        'solo-imgvideo': imageVideoFiles.length <= 1,
      }"
    >
      <div
        v-for="(file, idx) in imageVideoFiles"
        :key="idx + 'iv'"
        data-cy="video-image-wrapper"
        class="limit-image-video position-relative video-image-wrapper"
        :class="{
          'multi-image-video': imageVideoFiles.length >= 2,
          'multi-mr-2': idx == 0,
          'solo-image-video': imageVideoFiles.length <= 1,
        }"
      >
        <message-file
          :file="file"
          :current-user-id="currentUserId"
          :message="message"
          :index="idx"
          :message-selection-enabled="messageSelectionEnabled"
          :isYourSelf="isYourSelf"
          @open-file="$emit('open-file', $event)"
        >
          <template v-for="(i, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data" />
          </template>
        </message-file>
        <div v-if="idx == 1" :class="imageVideoFiles.length <= 2 ? 'd-none' : ''" class="position-count">
          <b-avatar size="25px" :class="message.senderId === currentUserId ? 'bg-isyourself' : 'bg-otheruser'">
            <p class="m-0" style="font-size: 14px; font-weight: 700">+{{ imageVideoFiles.length - 2 }}</p>
          </b-avatar>
        </div>
      </div>
    </div>
    <div v-for="(file, idx) in otherFiles" :key="idx + 'a'" data-cy="var-message-file-container" class="var-message-file-container pb-2">
      <progress-bar v-if="file.progress >= 0" :progress="file.progress" :style="{ top: '31px' }" />
      <div
        class="vac-file-container"
        :class="{ 'vac-file-container-progress': file.progress >= 0 }"
        @click="openFile($event, file, 'download')"
      >
        <div v-if="file.type == 'application/pdf' && file.urlThumbnail" class="h-100 w-100 pb-2">
          <img :src="file.urlThumbnail" class="preview-file-pdf" alt="" />
        </div>
        <div class="d-flex w-100">
          <div class="">
            <div class="mr-2">
              <c-icon-file :file="file" />
            </div>
          </div>
          <div class="w-100" style="overflow: hidden">
            <div data-cy="vac-file-name" class="vac-text-ellipsis" style="color: var(--cj-color-black-primary)">{{ file.name }}</div>
            <div class="d-flex align-items-center justify-content-center">
              <div v-if="file.extension" class="vac-text-ellipsis vac-text-extension">
                {{ file.extension }}
              </div>
              <div class="vac-svg-button ml-1" data-cy="vac-btn-download">
                <slot name="document-icon">
                  <svg-icon name="document" style="height: 14px; width: 14px" />
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <format-message
      :content="message.content"
      :sticker-url="message.stickerUrl"
      :users="roomUsers"
      :text-formatting="textFormatting"
      :link-options="linkOptions"
      @open-user-tag="$emit('open-user-tag', $event)"
    >
      <template v-for="(i, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </format-message>
  </div>
</template>

<script>
import SvgIcon from '../../../../components/SvgIcon/SvgIcon';
import FormatMessage from '../../../../components/FormatMessage/FormatMessage';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import CIconFile from '../../../../components/c-icon-file/c-icon-file.vue';
import MessageFile from './MessageFile/MessageFile';

const { isImageVideoFile } = require('../../../../utils/media-file');

export default {
  name: 'MessageFiles',
  components: { SvgIcon, FormatMessage, ProgressBar, MessageFile, CIconFile },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    roomUsers: { type: Array, required: true },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    messageSelectionEnabled: { type: Boolean, required: true },
    isYourSelf: { default: null },
  },

  emits: ['open-file', 'open-user-tag'],

  computed: {
    imageVideoFiles() {
      return this.message.files.filter(file => isImageVideoFile(file));
    },
    otherFiles() {
      return this.message.files.filter(file => !isImageVideoFile(file));
    },
  },

  methods: {
    openFile(event, file, action) {
      if (!this.messageSelectionEnabled) {
        event.stopPropagation();
        this.$emit('open-file', { file, action });
      }
    },
  },
};
</script>
