export enum CbCommonStatus {
  ACTIVE = 'ACTIVE',

  LATE = 'LATE',

  DELIVERED = 'DELIVERED',

  COMPLETED = 'COMPLETED',

  CANCELLED = 'CANCELLED',

  STARRED = 'STARRED',

  OFFER = 'OFFER',

  STARTING = 'STARTING',

  DECLINED = 'DECLINED',

  OVERDUE = 'OVERDUE',

  PAID = 'PAID',

  DELETED = 'DELETED',

  EXPIRED = 'EXPIRED',

  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  WAITING_YOUR_FEEDBACK = 'WAITING_YOUR_FEEDBACK',
  WAITING_PAYMENT = 'WAITING_PAYMENT',

  ON_PROGRESS = 'ON_PROGRESS',

  BOOKED = 'BOOKED',

  ON_MY_WAY = 'ON_MY_WAY',

  CLOCK_IN = 'CLOCK_IN',

  CLOCK_OUT = 'CLOCK_OUT',

  MOM = 'MOM',

  FEEDBACK_NEEDED = 'FEEDBACK_NEEDED',

  ADDITIONAL_ON_BOOKED = 'ADDITIONAL_ON_BOOKED',

  ADDITIONAL_ON_MY_WAY = 'ADDITIONAL_ON_MY_WAY',

  ADDITIONAL_ON_CLOCK_IN = 'ADDITIONAL_ON_CLOCK_IN',

  LATE_ON_TRACK = 'LATE_ON_TRACK',

  TRACK = 'TRACK',

  MILESTONE = 'MILESTONE',

  GIFT = 'GIFT',

  PENDING = 'PENDING',

  FAILED = 'FAILED',

  // new WF

  ASK_FOR_REVISION = 'ASK_FOR_REVISION',
  ASK_FOR_REVISION_EXTRA = 'ASK_FOR_REVISION_EXTRA',
  REVISION_EXTRA = 'REVISION_EXTRA',
  REVISION_REQUESTED = 'REVISION_REQUESTED',
  REQUESTED = 'REQUESTED',
  REVISION_NEEDED = 'REVISION_NEEDED',

  REJECT = 'REJECT',

  APPOINTMENT = 'APPOINTMENT',

  DISPUTED = 'DISPUTED',

  EXTENDED_DELIVERY_TIME = 'EXTENDED_DELIVERY_TIME',

  WAITING = 'WAITING',

  CHOOSEN = 'CHOOSEN',

  NOT_CHOOSEN = 'NOT_CHOOSEN',

  CLOSED = 'CLOSED',

  DRAFT = 'DRAFT',

  FINISHED = 'FINISHED',
  ACCEPTED = 'ACCEPTED',
  OPEN = 'OPEN',

  SERVICE_FINISHED = 'SERVICE_FINISHED',
  ON_THE_WAY = 'ON_THE_WAY',
  PRE_CHOOSEN = 'PRE_CHOOSEN',
  DISPUTE = 'DISPUTE',

  OFF_DUTY = 'OFF_DUTY',
}
