var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-textarea-new cj-field-border-default rounded" },
    [
      _c("textarea", {
        ref: "cTextareaNew",
        staticClass:
          "custom-textarea font-roboto cj-color-black-primary rounded",
        attrs: {
          "data-cy": _vm.dataCyCustom ? `${_vm.dataCyCustom}` : "textarea",
          "data-gtm": _vm.dataGTMCustom
            ? `${_vm.dataGTMCustom}-textarea`
            : "textarea",
          id: "cTextareaNew",
          placeholder: _vm.placeholder,
          rows: _vm.rows,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.onChangeInput },
      }),
      _vm._v(" "),
      _vm.maxlength
        ? _c(
            "p",
            {
              staticClass:
                "count-wrapper font-roboto cj-color-grey-secondary font-size-12 font-weight-500 position-relative",
              staticStyle: { top: "-7px" },
              attrs: {
                "data-cy": _vm.dataCyCustom
                  ? `${_vm.dataCyCustom}-validation`
                  : "textarea-validation",
              },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.valueLength) +
                  "/" +
                  _vm._s(_vm.maxlength) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideInfo
        ? _c("icon", {
            staticClass: "cj-fill-grey-tertiary info-wrapper",
            attrs: {
              name: "ic_information",
              width: "24px",
              height: "24px",
              id: "popoverTextareaCode" + _vm.uniqueId,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hideInfo
        ? _c(
            "b-popover",
            {
              attrs: {
                "custom-class": "content-popover-c-textarea-new",
                target: "popoverTextareaCode" + _vm.uniqueId,
                triggers: "hover",
                placement: "bottom",
              },
            },
            [
              _c("p", {
                staticClass:
                  "font-roboto cj-color-grey-secondary font-size-12 mb-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cTextArea.codeInfo1")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-roboto cj-color-grey-secondary font-size-12 mb-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cTextArea.codeInfo2")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-roboto cj-color-grey-secondary font-size-12 mb-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cTextArea.codeInfo3")
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-roboto cj-color-grey-secondary font-size-12 mb-1",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cTextArea.codeInfo4")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }