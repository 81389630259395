import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = 'services/btinotif/api/nt-vw-user-notifs';

export default class PNtVwUserNotifService {
  public retrieveByLogin(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/CB_APP/by-login?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveCountByLogin(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/count/CB_APP/by-login`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
