import { Component, Vue, Prop } from 'vue-property-decorator';

import CButton from '@/components/button/c-button.vue';

import { ICbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { HANDLE_MDL_MILESTONE_FILE } from '@/module/chat-container/order-paw-controller.component';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

@Component({
  components: {
    CButton,
  },
})
export default class CItemChatMessageMilestone extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrderRequirement: ICbVwPawOrderRequirement;
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleOpenPreviewMilestone() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_MDL_MILESTONE_FILE,
      typeModal: TypeComponent.CHAT_MODAL,
      cbVwPawOrder: this.cbVwPawOrder,
      cbVwPawOrderRequirement: this.cbVwPawOrderRequirement,
    });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
