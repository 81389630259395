var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-room-search-messages",
      attrs: { id: "room-search-chat-message" },
    },
    [
      _c("div", { staticClass: "header-container" }, [
        _c(
          "div",
          {
            staticClass: "p-3 cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.callBack()
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "cb-icon-size-arrow-left cj-color-black-primary",
              attrs: { icon: "fa-solid fa-arrow-left" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "search-container my-auto w-100" },
          [
            _c("icon", {
              staticClass: "cj-stroke-red-primary mr-2 ic_search",
              attrs: { name: "ic_search", width: "1.125rem", height: "100%" },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.valueSearch,
                  expression: "valueSearch",
                },
              ],
              ref: "focusSearchMedia",
              staticClass: "rounded-pill cj-field-input",
              attrs: {
                type: "text",
                placeholder: _vm.$t("chat.search"),
                "data-cy": "search-chat",
              },
              domProps: { value: _vm.valueSearch },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.valueSearch = $event.target.value
                  },
                  function ($event) {
                    return _vm.searchRoom($event)
                  },
                ],
                paste: _vm.onPasteImage,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search-msg-wrapper" }, [
        !_vm.isFindSearch && !_vm.isFetching
          ? _c("div", { staticClass: "d-flex justify-content-center p-3" }, [
              _c(
                "p",
                {
                  staticClass: "font-roboto cj-color-grey-primary font-size-16",
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("chat.findMessageHistoryWith")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.room.roomName))]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isFindSearch &&
        !_vm.isFetching &&
        _vm.messagesSearchResults.length === 0
          ? _c(
              "div",
              {
                staticClass: "d-flex justify-content-center p-3",
                attrs: { "data-cy": "no-found-chat" },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto cj-color-grey-primary font-size-12 font-weight-500",
                    domProps: {
                      textContent: _vm._s(_vm.$t("chat.noHistoryFound")),
                    },
                  },
                  [_vm._v("No Found")]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isFindSearch && _vm.messagesSearchResults.length > 0
          ? _c(
              "div",
              { staticClass: "pb-3" },
              [
                _vm._l(_vm.messagesSearchResults, function (m) {
                  return _c(
                    "div",
                    {
                      key: m.indexId || m._id,
                      attrs: {
                        "data-cy": "search-message",
                        id: "roomSearchMessage" + m.id,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.searchMsgResultSelected(m)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "msg-card-wrapper px-3 py-2" }, [
                        _c("p", { staticClass: "info-title" }, [
                          _vm._v(_vm._s(_vm.filterGroupDate(m))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            (m.senderId === _vm.currentUserId &&
                              !m.deleted &&
                              m.saved) ||
                            m.distributed ||
                            m.seen
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mr-2 d-flex align-items-center",
                                  },
                                  [
                                    _vm._t(
                                      "checkmark-icon",
                                      function () {
                                        return [
                                          _c("span", {
                                            staticClass: "var-circle-msg",
                                            class:
                                              m.distributed && m.seen
                                                ? "read"
                                                : "",
                                          }),
                                        ]
                                      },
                                      null,
                                      { m }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("c-room-search-format-msg", {
                              attrs: {
                                content: m.content,
                                users: _vm.room.users,
                                "text-formatting": _vm.textFormatting,
                                "link-options": _vm.linkOptions,
                                searchInputed: _vm.searchInputed,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.totalItemsMessagesSearchResults > _vm.itemsPerPage
                  ? _c(
                      "infinite-loading",
                      {
                        ref: "infiniteLoading",
                        attrs: {
                          slot: "append",
                          identifier: _vm.infiniteId,
                          distance: 20,
                        },
                        on: { infinite: _vm.loadMore },
                        slot: "append",
                      },
                      [
                        _c("div", {
                          attrs: { slot: "no-more" },
                          slot: "no-more",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "spinner" }, slot: "spinner" },
                          [
                            _c("c-loader", {
                              staticClass: "d-flex m-auto",
                              attrs: { show: true, infinite: true },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }