var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "vac-location-map", attrs: { id: "map-container" } },
      [
        _c("div", { staticStyle: { display: "none" } }, [
          _c(
            "div",
            {
              staticClass: "pac-input-wraper",
              attrs: { id: "pac-input-wraper" },
            },
            [
              _c("input", {
                staticClass: "controls rounded-pill cj-field-input",
                attrs: {
                  "data-cy": "input-search-location",
                  id: "pac-input",
                  type: "text",
                  placeholder: _vm.$t(
                    "cbgwApp.cbComponent.cLocationMap.placeholder"
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn-close-location",
                  attrs: { id: "pac-input-clear" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "p-1 d-flex cursor-pointer" },
                    [
                      _c("icon", {
                        staticClass: "cj-fill-grey-secondary my-auto",
                        attrs: {
                          name: "ic_close",
                          width: "1.5rem",
                          height: "1.5rem",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", {
          class: [
            { large: _vm.mapSize == "large" },
            { medium: _vm.mapSize == "medium" },
          ],
          attrs: { id: "map" },
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticStyle: { display: "none" } }, [
          _c(
            "div",
            { staticStyle: { "z-index": "2" }, attrs: { id: "cur-loc" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    cursor: "pointer",
                    "margin-right": "0.6rem",
                    "box-shadow": "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
                    "border-radius": "2px",
                    "background-color": "var(--cj-color-white-primary)",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    "justify-content": "center",
                  },
                  attrs: {
                    id: "loc-button",
                    name: "loc-button",
                    "data-cy": "click-location",
                  },
                },
                [
                  _c("icon", {
                    staticClass: "my-auto",
                    attrs: {
                      name: "ic_current_location",
                      width: "2rem",
                      height: "2rem",
                      stopColorFirst: "var(--cj-color-linier-gradient-primary)",
                      stopColorLast:
                        "var(--cj-color-linier-gradient-secondary)",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { attrs: { id: "infowindow-content" } }, [
      _c("span", { staticClass: "title", attrs: { id: "place-name" } }),
      _c("br"),
      _vm._v(" "),
      _c("span", { attrs: { id: "place-address" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }