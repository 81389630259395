var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-voucher-item", attrs: { "data-cy": "coupon-item" } },
    [
      _c("div", { staticClass: "position-relative" }, [
        _c(
          "div",
          {
            staticClass:
              "overflow-hidden d-flex c-voucher-item-container wrap-border-grey-tertiary",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "col-4 d-flex flex-column position-relative bg-disable",
                class:
                  _vm.isPromoDisabled(_vm.cbVwPromo) == true
                    ? "cj-bg-grey-tertiary"
                    : "cj-bg-red-primary",
                on: {
                  click: function ($event) {
                    return _vm.handlClickPromo(_vm.cbVwPromo)
                  },
                },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto p-0 m-0 mt-auto mx-auto cj-color-white-primary font-size-14-m font-weight-500",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("capitalEachSpace")(_vm.cbVwPromo.promoMethod)
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto p-0 m-0 mb-auto mx-auto cj-color-white-primary font-size-32-m font-weight-700",
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.cbVwPromo.promoValueLabel)
                    ),
                    _vm.cbVwPromo.promoValueType ==
                    _vm.cbPromoValueType.PERCENTAGE
                      ? _c("span", { staticClass: "font-size-26-m" }, [
                          _vm._v("%"),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "position-absolute border-dashed" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-right d-flex justify-content-between p-2 mr-2 position-relative",
              },
              [
                _c("div", { staticClass: "w-100 d-flex flex-column" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-auto font-roboto cj-color-black-primary font-size-14-m font-weight-500 text-left text-ellipsis",
                      attrs: { "data-cy": "coupon-title" },
                      on: {
                        click: function ($event) {
                          return _vm.handlClickPromo(_vm.cbVwPromo)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.currentLanguage === "en"
                              ? _vm.cbVwPromo.titleEn
                                ? _vm.cbVwPromo.titleEn
                                : _vm.cbVwPromo.title
                              : _vm.cbVwPromo.title
                              ? _vm.cbVwPromo.title
                              : _vm.cbVwPromo.titleEn
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto cj-color-grey-secondary font-size-12-m font-weight-500 mt-1 text-left",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbGlobal.promo.promoPeriod")
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handlClickPromo(_vm.cbVwPromo)
                        },
                      },
                    },
                    [_vm._v("\n            Promo Period\n          ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto cj-color-black-primary font-size-cbdate font-weight-400 text-left flex-grow-1",
                        on: {
                          click: function ($event) {
                            return _vm.handlClickPromo(_vm.cbVwPromo)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("cbGlobal.until")) +
                            " " +
                            _vm._s(
                              _vm._f("formatDateTimeDefault")(
                                _vm.cbVwPromo.priodeEndDate
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "font-roboto cj-color-black-primary font-size-12 font-weight-400 text-right ml-auto cursor-pointer",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbComponent.cInputPaymentPromo.tAndC")
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("promoDetail", _vm.cbVwPromo)
                        },
                      },
                    }),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "position-absolute half-circle half-circle-left",
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "position-absolute half-circle half-circle-right",
        }),
      ]),
      _vm._v(" "),
      _vm.isShowMessage && _vm.messageInvalidPromo
        ? _c(
            "div",
            {
              staticClass:
                "wrap-border-grey-tertiary text-left message-coupon-wrapper",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-size-10 font-weight-400 font-roboto cj-color-black-primary px-2 pb-1",
                  attrs: { "data-cy": "message-invalid-coupon" },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.messageInvalidPromo) + "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }