import { CbPromoMethod } from '@/shared/model/enumerations/cb-promo-method.model';
import { CbPromoValueType } from '@/shared/model/enumerations/cb-promo-value-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbPromoPayMethod } from './enumerations/cb-promo-pay-method.model';
export interface ICbVwPromo {
  id?: number;
  title?: string | null;
  code?: string | null;
  promoMethod?: CbPromoMethod | null;
  promoValueType?: CbPromoValueType | null;
  promoValue?: number | null;
  currency?: CbCurrency | null;
  minAmount?: number | null;
  maxDiscountAmount?: number | null;
  maxUsedCount?: number | null;
  priodeStartDate?: Date | null;
  priodeEndDate?: Date | null;
  cbPromoSpecifics?: string | null;
  attId?: number | null;
  descriptionId?: number | null;
  descriptionEnId?: number | null;
  seq?: number | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  promoId?: number | null;
  payPromoMethod?: CbPromoPayMethod | null;
  isUserHasUsed?: boolean | null;
  isUserHasClaimed?: boolean | null;
}

export class CbVwPromo implements ICbVwPromo {
  constructor(
    public id?: number,
    public title?: string | null,
    public code?: string | null,
    public promoMethod?: CbPromoMethod | null,
    public promoValueType?: CbPromoValueType | null,
    public promoValue?: number | null,
    public currency?: CbCurrency | null,
    public minAmount?: number | null,
    public maxDiscountAmount?: number | null,
    public maxUsedCount?: number | null,
    public priodeStartDate?: Date | null,
    public priodeEndDate?: Date | null,
    public cbPromoSpecifics?: string | null,
    public attId?: number | null,
    public descriptionId?: number | null,
    public descriptionEnId?: number | null,
    public seq?: number | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public promoId?: number | null,
    public payPromoMethod?: CbPromoPayMethod | null,
    public isUserHasUsed?: boolean | null, //Transient
    public isUserHasClaimed?: boolean | null //Transient
  ) {
    // this.isActive = this.isActive ?? false;
    // this.isUserHasUsed = this.isUserHasUsed ?? false;
    // this.isUserHasClaimed = this.isUserHasClaimed ?? false;
  }
}
