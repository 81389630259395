import { Inject, Component, Vue, Watch, Prop } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
import CInputOtp from '../c-input-otp/c-input-otp.vue';
import GlobalService from '@/services/global.service';
import PCbAccountService from '@/services/cb-account.service';
import PUserService from '@/services/user.service';
import { Debounce } from '@/shared/decorator/debounce';
import { email, required } from 'vuelidate/lib/validators';
import { ICbOidActivation } from '@/shared/model/cb-oids.model';
import PCbOidService from '@/services/cb-oid.service';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import { User } from '@/shared/model/user.model';
import AccountService from '@/account/account.service';

const validations: any = {
  email: { required, email, $autoDirty: true },
};
@Component({
  validations,
  components: {
    CButton,
    CInputOtp,
    CTimeCountdown,
  },
})
export default class CVerificationEmailNew extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbAccountService') private pCbAccountService: () => PCbAccountService;
  @Inject('pUserService') private pUserService: () => PUserService;
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;
  @Inject('accountService') private accountService: () => AccountService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false, type: Boolean }) public visibleFooter: boolean;
  @Prop({ default: false, type: Boolean }) public visibleHeader: boolean;
  @Prop({ default: false, type: Boolean }) public visibleCountdown: boolean;
  public isShowInputOtp = false;
  public email = '';
  public inputOtp: string = '';
  public isExistEmail = false;
  public valueStatusEmail = null;
  public isSubmit = false;
  public activationKey: string = null;
  public invalidOtp: boolean = false;
  public showCountdown: boolean = false;
  public visibleLoading: boolean = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.visibleCountdownListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public submit() {
    if (this.email && this.email.includes('@')) {
      this.isSubmit = true;
      const user = new User();
      user.email = this.email;
      this.pCbAccountService()
        .setEmailIfEmailDoesNotExist(user)
        .then(res => {
          if (res.id == 1) {
            this.isShowInputOtp = true;
            this.activationKey = res.detail.emailActivationKey;
          }
          this.isSubmit = false;
        })
        .catch(err => {
          this.isSubmit = false;
          this.isShowInputOtp = false;
        });
    }
  }

  public close() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-verification-email-new');
  }
  public changeEmail() {
    this.showCountdown = true;
    this.isShowInputOtp = false;
    this.email = '';
  }

  @Debounce(400)
  public handleCheckEmail() {
    if (this.email && this.email.includes('@')) {
      this.pUserService()
        .findExist(this.email)
        .then(res => {
          if (res.data.id > 0) {
            this.isExistEmail = true;
            this.valueStatusEmail = res.data.detail;
          } else {
            this.valueStatusEmail = res.data.detail;
            this.isExistEmail = false;
          }
        })
        .catch(err => {
          this.valueStatusEmail = null;
          this.isExistEmail = false;
        });
    } else {
      this.isExistEmail = false;
    }
  }

  public resend() {
    if (!this.showCountdown) {
      this.visibleLoading = true;
      let activation: ICbOidActivation = {
        key: this.activationKey,
        activationCode: null,
      };
      this.pCbOidService()
        .resend(activation, 'email')
        .then(res => {
          (<any>this.$root).$bvToast.toast('Resend Verification Success', {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          });
          if (res.id && res.id > 0 && res.detail) {
            this.activationKey = res.detail.emailActivationKey;
          }

          this.visibleCountdownListener();
        })
        .catch(() => {
          (<any>this.$root).$bvToast.toast('Resend Verification Failed', {
            toaster: 'b-toaster-top-center',
            title: 'Danger',
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
          });
        })
        .finally(() => {
          this.visibleLoading = false;
        });
    }
  }

  public handleInputOtp() {
    if (this.inputOtp.length > 0) {
      this.inputOtp = this.inputOtp.toUpperCase();
    }
    if (this.inputOtp.length == 4) {
      this.visibleLoading = true;
      let activation: ICbOidActivation = {
        key: this.activationKey,
        activationCode: this.inputOtp,
      };
      this.pCbOidService()
        .activate(activation, 'email')
        .then(res => {
          this.visibleLoading = false;
          if (res.id && res.id > 0) {
            this.invalidOtp = false;
            this.globalService().refreshTokenAfterSetEmail();
            this.accountService().retrieveCbVwUserCurrent();
            this.$emit('submit');
          } else {
            this.invalidOtp = true;
          }
        })
        .catch(err => {
          this.visibleLoading = false;
          this.invalidOtp = true;
          (<any>this.$root).$bvToast.toast('Email Otp Failed', {
            toaster: 'b-toaster-top-center',
            title: 'Failed',
            variant: 'danger',
            solid: true,
            autoHideDelay: 3000,
          });
        });
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('visibleCountdown')
  public visibleCountdownListener() {
    if (this.visibleCountdown) {
      this.showCountdown = true;
    }
  }
  // ================= END LISTENERS ==================
}
