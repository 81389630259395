var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-notification-email" },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("icon", {
            staticClass: "cj-fill-red-primary m-auto",
            attrs: { name: "ic_mail_fill", width: "50px", height: "50px" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", {
        staticClass:
          "font-weight-700 font-roboto font-size-24 cj-color-black-primary text-center",
        domProps: {
          textContent: _vm._s(
            _vm.$t("cbgwApp.cbComponent.cNotification.resetEmail")
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-weight-500 font-catamaran font-size-18 cj-color-black-primary text-center",
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cNotification.linkTo")
              ),
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "cj-color-red-primary" }, [
            _vm._v(_vm._s(_vm.value && _vm.value.email)),
          ]),
          _vm._v(".\n    "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cNotification.checkEmail")
              ),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "c-button",
        {
          staticClass: "font-roboto font-size-20 text-center mt-3",
          attrs: { fullWidth: "" },
          nativeOn: {
            click: function ($event) {
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbComponent.cPin.continue")),
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }