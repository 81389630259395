import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
export interface ICbVwSvc {
  id?: number;
  svcType?: CbSvcType | null;
  name?: string;
  description?: string | null;
  color?: string | null;
  seq?: number | null;
  iconId?: number | null;
  bannerId?: number | null;
  logoId?: number | null;
  svcCats?: string | null;
  seqCurrent?: number | null;
  seqAllSvc?: number | null;
  isActive?: boolean | null;
  downloadLinkBanner?: string | null;
  downloadLinkIcon?: string | null;
  downloadLinkLogo?: string | null;
  downloadLinkIconInActive?: string | null;
  downloadLinkSvcImage?: string | null;
  lastModifiedDate?: Date | null;
}

export class CbVwSvc implements ICbVwSvc {
  constructor(
    public id?: number,
    public svcType?: CbSvcType | null,
    public name?: string,
    public description?: string | null,
    public color?: string | null,
    public seq?: number | null,
    public iconId?: number | null,
    public bannerId?: number | null,
    public logoId?: number | null,
    public svcCats?: string | null,
    public isActive?: boolean | null,
    public downloadLinkBanner?: string | null,
    public downloadLinkIcon?: string | null,
    public downloadLinkLogo?: string | null,
    public lastModifiedDate?: Date | null,
    public downloadLinkIconInActive?: string | null,
    public downloadLinkSvcImage?: string | null,
    public seqCurrent?: number | null,
    public seqAllSvc?: number | null
  ) {
    this.isActive = this.isActive ?? false;
  }
}
