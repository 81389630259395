var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "cPopupBottom", staticClass: "c-popup-bottom" }, [
    _c("div", {
      staticClass: "c-popup-bottom-overlay",
      attrs: {
        "data-cy": _vm.dataCyCustom
          ? `c-popup-bottom-${_vm.dataCyCustom}`
          : "c-popup-bottom-overlay",
      },
      on: { click: _vm.cancel },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "cPopupBottomContent",
        staticClass: "c-popup-bottom-content",
        attrs: {
          "data-cy": _vm.dataCyCustom
            ? `popup-bottom-${_vm.dataCyCustom}`
            : "popup-bottom-content",
        },
      },
      [_vm.value ? _vm._t("default") : _vm._e()],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }