export enum WfKeyType {
  START = 'START',

  END = 'END',

  PROCESS = 'PROCESS',

  ACTOR = 'ACTOR',

  OUTPUT = 'OUTPUT',

  DECISION = 'DECISION',

  CRON = 'CRON',
  CONTINUE = 'CONTINUE',

}
