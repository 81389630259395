import axios from 'axios';

import { ICbUserOffDuty } from '@/shared/model/cb-user-off-duty.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-off-duties';

export default class PCbUserOffDutyService {
  public retrieveByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-login', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbUserOffDuty): Promise<ICbUserOffDuty> {
    return new Promise<ICbUserOffDuty>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByLogin(entity: ICbUserOffDuty): Promise<ICbUserOffDuty> {
    return new Promise<ICbUserOffDuty>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public partialUpdate(entity: ICbUserOffDuty): Promise<ICbUserOffDuty> {
    return new Promise<ICbUserOffDuty>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteByLogin(): Promise<ICbUserOffDuty> {
    return new Promise<ICbUserOffDuty>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
