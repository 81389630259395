
import vClickOutside from 'v-click-outside';

import SvgIcon from '../../../components/SvgIcon/SvgIcon.vue';
import Icon from '@/shared/icons/icon.vue';
import typingText from '../../../utils/typing-text';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
    SvgIcon,
    Icon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    messageSelectionEnabled(val) {
      if (val) {
        this.messageSelectionAnimationEnded = false;
      } else {
        setTimeout(() => {
          this.messageSelectionAnimationEnded = true;
        }, 300);
      }
    },
  },
})
export default class RoomHeader extends Vue {
  // props: {
  @Prop({ type: [String, Number], required: true })
  private currentUserId;
  @Prop({ type: Object, required: true })
  private textMessages;
  @Prop({ type: Boolean, required: true })
  private singleRoom;
  @Prop({ type: Boolean, required: true })
  private showRoomsList;
  @Prop({ type: Boolean, required: true })
  private isMobile;
  @Prop({ type: Boolean, required: true })
  private roomInfoEnabled;
  @Prop({ type: Array, required: true })
  private menuActions;
  @Prop({ type: Object, required: true })
  private room;
  @Prop({ type: Boolean, required: true })
  private messageSelectionEnabled;
  @Prop({ type: Array, required: true })
  private messageSelectionActions;
  @Prop({ type: Number, required: true })
  private selectedMessagesTotal;
  @Prop({ type: Array })
  private userIdsOnline;

  // },

  private menuOpened = false;
  private messageSelectionAnimationEnded = true;

  mounted() {}

  public get isSocketServiceNotActive() {
    return this.$store.getters.isSocketServiceNotActive;
  }

  public get typingUsers() {
    return typingText(this.room, this.currentUserId, this.textMessages);
  }

  public get userStatus() {
    let text = '';
    if (!this.room.users || this.room.users.length !== 2) return text;

    const user = this.room.users.find(u => u._id !== this.currentUserId);
    if (!user.status) return text;

    if (user.status.state === 'online') {
      text = this.textMessages.IS_ONLINE;
    } else if (user.status.lastChanged) {
      text = this.textMessages.LAST_SEEN + user.status.lastChanged;
    }

    return text;
  }

  menuActionHandler(action) {
    this.closeMenu();
    this.$emit('menu-action-handler', action);
  }
  closeMenu() {
    this.menuOpened = false;
  }
  messageSelectionActionHandler(action) {
    this.$emit('message-selection-action-handler', action);
  }

  handleOption(menu) {
    this.$emit('handleShowOption', menu);
  }

  viewProfile() {
    const userId = this.room.members
      .split('||')
      .find(x => x != ' ' + this.currentUserId + ' ')
      .replace(' ', '');
    this.$router.push({ name: 'PProfilePublic', params: { id: userId.trim() } });
  }
}
