import { mixins } from 'vue-class-component';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { minLength, required } from 'vuelidate/lib/validators';
import { Flutter } from '@/app-flutter';

import Icon from '@/shared/icons/icon.vue';
import CTextareaNew from '@/components/c-textarea-new/c-textarea-new.vue';
import CButton from '@/components/button/c-button.vue';
import CUploadFile from '@/components/c-upload-file/c-upload-file.vue';

import JhiDataUtils from '@/shared/data/data-utils.service';

import { ICmAttachmentRestrict } from '@/shared/model/cm-attachment-restrict.model';
import { CbReportReasonBuyerType } from '@/shared/model/enumerations/cb-report-reason-buyer-type.model';
import { CbReportReasonPartnerType } from '@/shared/model/enumerations/cb-report-reason-partner-type.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';

const validations: any = {
  description: {
    required,
    minLength: minLength(150),
  },
  reasons: {
    required,
    minLength: minLength(1),
    $each: {},
  },
};
@Component({
  validations,
  components: {
    CButton,
    Icon,
    CTextareaNew,
    CUploadFile,
  },
})
export default class CProfileReport extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: '' }) username: string;
  @Prop({ default: CbLoginType.PARTNER }) public loginTypeReport: CbLoginType;

  public files: ICmAttachmentRestrict[] = [];
  public reasons: string[] = [];

  public isRunningOnFlutter = Flutter.isRunOn();
  public isSaving = false;

  public description = '';
  public fileAccept = '.mp3,.wav,.jpeg,.jpg,.svg,.png,.mp4,.pdf,.mkv,.webm,.av,.docx,.xlxs,.doc,.xlx';
  private maxFileAmount = 10;

  public CbLoginType = CbLoginType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public onBack() {
    this.$router.go(-1);
  }

  public async handleUploadFile(attachment: ICmAttachmentRestrict) {
    let totalFileAmount = this.files.length;
    if (totalFileAmount + 1 > this.maxFileAmount) {
      this.showToastOnExceededFileAmount();
      return;
    }
    this.files.push(attachment);
    totalFileAmount++;
  }
  public handleClear(index: number): void {
    this.files.splice(index, 1);
  }

  public handleCheckBoxReason(e) {
    if (e.target.checked) {
      this.reasons.push(e.target.value);
    } else {
      const findIdx = this.reasons.findIndex(val => val == e.target.value);
      if (findIdx >= 0) {
        this.reasons.splice(findIdx, 1);
      }
    }
  }

  public handleSubmit() {
    this.isSaving = true;
    this.$emit('submitReportUser', this.reasons, this.description, this.files);
  }

  @Emit('handleCloseModalReport')
  public handleCloseModalReport() {
    return;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get loginType() {
    return this.$store.getters.loginType;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get reasonTypes() {
    if (this.loginTypeReport == CbLoginType.BUYER) {
      return Object.values(CbReportReasonBuyerType);
    } else {
      return Object.values(CbReportReasonPartnerType);
    }
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
