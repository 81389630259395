import formatString from '@/module/chat-container/chat/utils/format-string';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class CTextareaPreview extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: '' }) public text;

  @Prop({
    type: Object,
    default: () => ({
      disabled: false,
      italic: '_',
      bold: '*',
      strike: '~',
      underline: '!',
    }),
  })
  public textFormatting;

  @Prop({ type: Boolean, default: true }) public linkify;
  @Prop({ default: false }) public collapseText: boolean;
  @Prop({ default: null }) public dataCyCustom: string;

  public isCollapsed = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public checkType(value, type) {
    return value.types.indexOf(type) !== -1;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get linkifiedContents() {
    const value = formatString(this.text, this.linkify, this.textFormatting);

    value.forEach(v => {
      v.bold = this.checkType(v, 'bold');
      v.italic = this.checkType(v, 'italic');
      v.strike = this.checkType(v, 'strike');
      v.underline = this.checkType(v, 'underline');
    });

    return value;
  }

  public get countParagraphs() {
    return text => {
      if (text) {
        return text.split(/\n\s*\n/).filter(Boolean).length;
      }
    };
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
