var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-cookies-setting cj-bg-white-primary p-0 p-sm-3 p-md-3 p-lg-3 p-xl-3 border-radius-card",
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-size-24-m font-weight-700",
          },
          [_vm._v("Cookies Setting")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.cancel } },
          [
            _c("b-icon-x-circle-fill", {
              staticClass: "font-size-18-m cj-color-grey-secondary",
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-size-18-m font-weight-400 mt-2",
        },
        [
          _vm._v(
            "\n    We recommend allowing these funtions to get the most of your experience, but you can opt-out if you wish. Learn more about Klikjob\n    "
          ),
          _c(
            "span",
            {
              staticClass: "cj-btn-text-primary",
              on: { click: _vm.openModalCookiesPolicy },
            },
            [_vm._v("Cookie Policy")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "wrap-border-grey-tertiary border-radius-card p-4 mt-4",
        },
        [
          _c("div", [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-size-18-m font-weight-500 my-auto",
                },
                [_vm._v("Mandatory Cookies")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-checkbox", {
                    staticClass: "my-auto",
                    attrs: { true: "", name: "check-button", switch: "" },
                    model: {
                      value: _vm.$v.cbUserCookie.mandatoryCookie.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.cbUserCookie.mandatoryCookie,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.cbUserCookie.mandatoryCookie.$model",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "fonnt-roboto cj-color-black-primary font-size-16-m font-weight-400",
              },
              [
                _vm._v(
                  "\n        Klikjob use these cookies to remember login detail and account setting. Without these cookies, certain functionality may become\n        unavailable.\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "my-3" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-size-18-m font-weight-500",
                },
                [_vm._v("Analytical/ Performance Cookies")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-checkbox", {
                    staticClass: "my-auto",
                    attrs: { name: "check-button", switch: "" },
                    model: {
                      value: _vm.$v.cbUserCookie.analyticalCookie.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.cbUserCookie.analyticalCookie,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.cbUserCookie.analyticalCookie.$model",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "fonnt-roboto cj-color-black-primary font-size-16-m font-weight-400",
              },
              [
                _vm._v(
                  "\n        Allow Klikjob to identify how users navigate within Klikjob platform. This will provide us data for further platform\n        improvement.\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-size-18-m font-weight-500",
                },
                [_vm._v("Advertising Cookies")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("b-form-checkbox", {
                    staticClass: "my-auto",
                    attrs: { name: "check-button", switch: "" },
                    model: {
                      value: _vm.$v.cbUserCookie.advertisingCookie.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.cbUserCookie.advertisingCookie,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.cbUserCookie.advertisingCookie.$model",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "fonnt-roboto cj-color-black-primary font-size-16-m font-weight-400",
              },
              [
                _vm._v(
                  "\n        These cookies used to show relevant advertising and help us measure the effectiveness of our marketing campaigns.\n      "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex mt-3 w-100" },
        [
          _c(
            "c-button",
            {
              staticClass: "font-roboto font-size-16-m ml-auto btn-save",
              nativeOn: {
                click: function ($event) {
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-c-cookie-setting",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [
          _c("c-tmp-announcement", {
            attrs: {
              title:
                _vm.currentLanguage == "en"
                  ? "Klikjob Cookie Policy"
                  : "Klikjob Kebijakan Cookie",
              content: _vm.COOKIE_POLICY,
            },
            on: {
              close: function ($event) {
                return _vm.cancel()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }