import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import PNtLovService from './services/nt-lov.service';
import { NtVwUserNotif } from './shared/model/nt-vw-user-notif.model';
import ContentService from './services/content.service';
import { CbLovType } from './shared/model/enumerations/cb-lov-type.model';

enum EnumNotifName {
  CB_ICON_LABEL_URL = 'CB_ICON_LABEL_URL',
}

@Component({
  components: {},
})
export default class AppLov extends Vue {
  @Inject('pNtLovService') public pNtLovService: () => PNtLovService;
  @Inject('contentService') public contentService: () => ContentService;
  private ntLovs: NtVwUserNotif[] = [];

  created() {
    this.contentService().initRetrieveCbLovs(CbLovType.CONTENT_BANNER_BUYER);
    this.contentService().initRetrieveCbLovs(CbLovType.CONTENT_IMAGE);

    this.initAuthenticatedAppNotif();
  }

  public initAuthenticatedAppNotif() {
    this.$store.watch(
      () => this.$store.getters.authenticated,
      () => {
        if (this.$store.getters.authenticated === true) {
          this.retrieveAllNtLov();
        }
      }
    );

    if (this.$store.getters.authenticated === true) {
      this.retrieveAllNtLov();
    }
  }

  public retrieveAllNtLov() {
    this.pNtLovService()
      .retrieveNtLov(EnumNotifName.CB_ICON_LABEL_URL)
      .then(res => {
        this.ntLovs = res.data;
        this.$store.commit('CB_ICON_LABEL_URL', this.ntLovs);
      })
      .catch(err => {});
  }
}
