var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "m-3 m-lg-0 d-flex flex-wrap custom-border c-set-pin-setting align-items-center",
    },
    [
      _c("div", { staticClass: "col-12 col-sm-8 p-3" }, [
        _c(
          "p",
          {
            staticClass:
              "cj-color-black-primary font-roboto font-weight-500 mb-1",
            class: !_vm.isMobile ? "font-size-20" : "font-size-14",
          },
          [
            !_vm.pinActivated && !_vm.isPinExist
              ? _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.setCbBalancePin")
                    ),
                  },
                })
              : _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.changeCbBalancePin")
                    ),
                  },
                }),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "cj-color-black-primary font-roboto font-weight-300",
            class: !_vm.isMobile ? "font-size-16" : "font-size-10",
          },
          [
            !_vm.pinActivated && !_vm.isPinExist
              ? _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.youhaventsetyourPIN")
                    ),
                  },
                })
              : [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbSetting.descriptionForgetPin")
                        ),
                      },
                    },
                    [_vm._v(" If there are problems with your PIN, click ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "cj-btn-text-primary",
                      attrs: {
                        "data-gtm": "handle-forgot-pin-button",
                        "data-cy": "forgot-pin",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbSetting.forgotPin")
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleForgotPin()
                        },
                      },
                    },
                    [_vm._v("Forgot PIN")]
                  ),
                ],
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12 col-sm-4 px-3 pt-0 pt-sm-3 pb-3" },
        [
          !_vm.pinActivated && !_vm.isPinExist
            ? _c(
                "c-button",
                {
                  staticClass: "font-roboto font-weight-500 px-4 w-100",
                  class: !_vm.isMobile ? "font-size-18" : "font-size-12",
                  attrs: {
                    dataGTMCustom: "change-pin-submit",
                    disabled: _vm.isInit,
                    typeStyle: "secondary",
                    fullWidth: "",
                    "data-cy": "set-pin-button",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.modalSetPin.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbSetting.setCbBalancePin")
                      ),
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.pinActivated || _vm.isPinExist
            ? _c(
                "c-button",
                {
                  staticClass: "font-roboto font-weight-500 px-4 w-100",
                  class: !_vm.isMobile ? "font-size-18" : "font-size-12",
                  attrs: {
                    dataGTMCustom: "change-pin-submit",
                    typeStyle: "secondary",
                    fullWidth: "",
                    "data-cy": "change-pin-button",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.modalChangePin.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbSetting.changeCbBalancePin")
                      ),
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }