import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class DragScrollClickFix extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  readonly DRAG_DELAY = 100; // This is the minimal delay to consider a click to be a drag, mostly usefull for touch devices

  timer: NodeJS.Timeout | null = null;
  dragging: boolean = false;

  onDragScrollStart() {
    this.timer = setTimeout(() => this.onTimer(), this.DRAG_DELAY);
  }

  onTimer() {
    this.timer = null;
    this.dragging = true;
  }

  onDragScrollEnd() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    setTimeout(() => (this.dragging = false));
  }

  onClickCapture(e: MouseEvent) {
    if (this.dragging) {
      this.dragging = false;
      e.preventDefault();
      e.stopPropagation();
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
