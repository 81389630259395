import axios from 'axios';

import { ICbAuctionProjectAtt } from '@/shared/model/cb-auction-project-att.model';

const baseApiUrl = 'services/cbcorems/api/cb-auction-project-atts';

export default class PCbAuctionProjectAttService {
  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbAuctionProjectAtt, callBack, callbackReject?: Function): Promise<ICbAuctionProjectAtt> {
    // kosongin att
    let tmpBlob = null;
    if (entity.cbAttachmentNoAuth) {
      entity.cbAttachmentNoAuth.att = '';
      tmpBlob = entity.cbAttachmentNoAuth.blob;
      delete entity.cbAttachmentNoAuth.blob;
    }

    return new Promise<ICbAuctionProjectAtt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          const result: ICbAuctionProjectAtt = res.data;
          if (result.uploadLink) {
            resolve(this.upload(result, result.uploadLink, entity.cbAttachmentNoAuth.attContentType, tmpBlob, callBack, callbackReject));
          } else {
            resolve(result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbAuctionProjectAtt): Promise<ICbAuctionProjectAtt> {
    return new Promise<ICbAuctionProjectAtt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private upload(
    result: ICbAuctionProjectAtt,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(res => {
          resolve(result);
        })
        .catch(err => {
          reject(callbackReject(result));
        });
    });
  }
}
