import { Inject, Component, Prop, Mixins } from 'vue-property-decorator';
import { Flutter } from '@/app-flutter';

import Icon from '@/shared/icons/icon.vue';
import Clogin from '@/components/auth/login/c-login.component';

import PRegisterM from '../register-m/register-m.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import FormUtils from '@/shared/utils/form-utils.service';
import GlobalService from '@/services/global.service';
import NavigationUtils from '@/shared/data/navigation-utils.service';

import { IKeycloakToken } from '@/shared/model/keycloak-token.model';

@Component({
  components: {
    Icon,
    PRegisterM,
  },
  mixins: [Clogin],
})
export default class PLoginM extends Mixins(JhiDataUtils, FormUtils, NavigationUtils) {
  @Prop()
  public closePopUp: boolean;
  private isLoginEntried = false;
  public isLoginInvalid: boolean | null = null;

  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created(): void {
    (<any>this.$root).$emit('showNavMobile', false);
    (<any>this.$root).$emit('showNavDekstop', false);
    (<any>this.$root).$emit('showFloatingLiveChat', false);
    (<any>this.$root).$emit('showFloatingStore', false);
    (<any>this.$root).$emit('hideNavbarHideFooter');
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public mounted(): void {
    this.navigationPrevious();
    Flutter.onOAuth2Login({
      handlerLabel: 'onOAuth2',
      callback: (data: IKeycloakToken) => {
        console.log('onOAuth2', JSON.stringify(data));
        this.globalService().keepToken(data, true);
        this.globalService().checkAccountAndLoginTypeData(this);
        window.location.reload();
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
      },
    });
  }

  public onBack(): void {
    if (this.closePopUp) {
      this.globalService().closeGlobalDialog(this.$root, 'modal-p-login-m-pop-up');
    } else if (this.$store.getters.previousRouteName == 'CNavbarMenu' || !this.$store.getters.previousRouteName) {
      this.$router.go(-1);
    } else {
      this.$router.replace('/');
    }
  }

  public toRegister(): void {
    if (this.closePopUp) {
      this.closePopUp = true;
      this.globalService().openGlobalDialog(this.$root, 'modal-p-register-m-pop-up');
    } else {
      this.$router.push({ name: 'PRegisterM' });
    }
  }

  public openPhoneVerification(): void {
    this.$store.commit('isPhoneTrouble', true);
    this.globalService().openGlobalDialog(this.$root, 'modal-edit-phone-number');
    this.$root.$emit('app::changeValue', 'isNotOpenGlobalDialogCallback', true);
  }

  public isLoginValidCopy(model: any): string {
    const result = this.isLoginValid(model, this.isLoginEntried);
    this.isLoginInvalid = result.isLoginInvalid;
    this.isLoginEntried = result.isLoginEntried;

    if (this.isLoginInvalid) {
      this.authenticationError = false;
    }
    return result.valid;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
