import Icon from '@/shared/icons/icon.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
  },
})
export default class CFooterCopyright extends Vue {
  @Prop({default:false}) public isShowTabFooter;
}
