var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-room-media",
      attrs: { id: "room-media-chat-message", "data-cy": "c-room-media" },
    },
    [
      _c("div", { staticClass: "header-container" }, [
        _c("div", { staticClass: "d-flex align-items-center flex-grow-1" }, [
          _c(
            "div",
            {
              staticClass: "p-3 cursor-pointer",
              attrs: { "data-cy": "c-rmedia-header-back" },
              on: {
                click: function ($event) {
                  return _vm.callBack()
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "cb-icon-size-arrow-left cj-color-black-primary",
                attrs: { icon: "fa-solid fa-arrow-left" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm.isShowMediaOption
            ? _c(
                "div",
                {
                  staticClass: "d-flex align-items-center flex-grow-1",
                  attrs: { "data-cy": "c-rmedia-header-delete" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vac-svg-button ml-auto",
                      on: {
                        click: function ($event) {
                          return _vm.showDeleteChtMgs()
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "cj-fill-red-primary",
                        attrs: {
                          name: "ic_outline_delete",
                          width: "18px",
                          height: "18px",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "vac-svg-button ml-3",
                      attrs: { "data-cy": "c-rmedia-header-download" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadFile()
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "cj-fill-red-primary",
                        attrs: {
                          name: "ic_download",
                          width: "18px",
                          height: "18px",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tab-container" }, [
        _c(
          "div",
          {
            staticClass: "btn-tab col-4 px-0 py-1",
            class:
              _vm.seletedChtMediaType == _vm.tabChtMediaType.MD_VIDEOIMAGES
                ? "actived"
                : "",
            attrs: { "data-cy": "c-room-tab-image" },
            on: {
              click: function ($event) {
                return _vm.handleSeletedTab(_vm.tabChtMediaType.MD_VIDEOIMAGES)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "fill",
              attrs: { name: "ic_picture", width: "30px", height: "30px" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-tab col-4 px-0 py-1",
            class:
              _vm.seletedChtMediaType == _vm.tabChtMediaType.MD_FILES
                ? "actived"
                : "",
            attrs: { "data-cy": "c-room-tab-files" },
            on: {
              click: function ($event) {
                return _vm.handleSeletedTab(_vm.tabChtMediaType.MD_FILES)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "fill",
              attrs: { name: "ic_draft", width: "30px", height: "30px" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-tab col-4 px-0 py-1",
            class:
              _vm.seletedChtMediaType == _vm.tabChtMediaType.MD_LINKS
                ? "actived"
                : "",
            attrs: { "data-cy": "c-room-tab-link" },
            on: {
              click: function ($event) {
                return _vm.handleSeletedTab(_vm.tabChtMediaType.MD_LINKS)
              },
            },
          },
          [
            _c("icon", {
              staticClass: "fill",
              attrs: { name: "ic_link", width: "30px", height: "30px" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "media-wrapper" },
        [
          _vm.seletedChtMediaType == _vm.tabChtMediaType.MD_VIDEOIMAGES
            ? [
                _vm.isFetching && _vm.chtVwMedias.length === 0
                  ? _c("c-loader", {
                      staticClass: "d-flex m-auto",
                      attrs: {
                        show: _vm.isFetching && _vm.chtVwMedias.length === 0,
                        infinite: false,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isFetching && _vm.chtVwMedias.length === 0
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-center p-3" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto cj-color-grey-primary font-size-12 font-weight-500",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("chat.noImageVideoFound")
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n          No Image & Video Found\n        "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.chtVwMedias && _vm.chtVwMedias.length > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap px-0 px-sm-3 pb-3" },
                          [
                            _vm._l(
                              _vm.chtVwMedias,
                              function (chtVwMedia, idxOffer) {
                                return [
                                  _c("c-rmedia-groupdate", {
                                    key: "date" + idxOffer,
                                    staticClass: "pt-3 pb-2",
                                    attrs: {
                                      chtVwMedias: _vm.chtVwMedias,
                                      chtVwMedia: chtVwMedia,
                                      index: idxOffer,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("c-rmedia-pictures", {
                                    key: idxOffer,
                                    attrs: {
                                      "data-cy": "c-media-pictures-preview",
                                      chtVwMedia: chtVwMedia,
                                      index: idxOffer,
                                    },
                                    on: {
                                      handleMediaPreview: function ($event) {
                                        return _vm.handleMediaPreview(
                                          chtVwMedia,
                                          idxOffer
                                        )
                                      },
                                    },
                                  }),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.totalItems > _vm.itemsPerPage
                          ? _c(
                              "infinite-loading",
                              {
                                ref: "infiniteLoading",
                                attrs: {
                                  slot: "append",
                                  identifier: _vm.infiniteId,
                                  distance: 20,
                                },
                                on: { infinite: _vm.loadMore },
                                slot: "append",
                              },
                              [
                                _c("div", {
                                  attrs: { slot: "no-more" },
                                  slot: "no-more",
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "spinner" },
                                    slot: "spinner",
                                  },
                                  [
                                    _c("c-loader", {
                                      staticClass: "d-flex m-auto",
                                      attrs: { show: true, infinite: true },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm.seletedChtMediaType == _vm.tabChtMediaType.MD_FILES
            ? [
                _vm.isFetching && _vm.chtVwMedias.length === 0
                  ? _c("c-loader", {
                      staticClass: "d-flex m-auto",
                      attrs: {
                        show: _vm.isFetching && _vm.chtVwMedias.length === 0,
                        infinite: false,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isFetching && _vm.chtVwMedias.length === 0
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-center p-3" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto cj-color-grey-primary font-size-12 font-weight-500",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("chat.noDocumentFound")
                              ),
                            },
                          },
                          [_vm._v("\n          No Documents Found\n        ")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.chtVwMedias && _vm.chtVwMedias.length > 0
                  ? _c(
                      "div",
                      { staticClass: "py-2" },
                      [
                        _vm._l(_vm.chtVwMedias, function (chtVwMedia, index) {
                          return _c("c-rmedia-files", {
                            key: index,
                            attrs: {
                              "aria-describedby": "chtVwMedias",
                              chtVwMedia: chtVwMedia,
                              idxOffer: index,
                              selectedMessageIds: _vm.selectedMessageIds,
                            },
                            on: { onSelectedMedia: _vm.onSelectedMedia },
                          })
                        }),
                        _vm._v(" "),
                        _vm.totalItems > _vm.itemsPerPage
                          ? _c(
                              "infinite-loading",
                              {
                                ref: "infiniteLoading",
                                attrs: {
                                  slot: "append",
                                  identifier: _vm.infiniteId,
                                  distance: 20,
                                },
                                on: { infinite: _vm.loadMore },
                                slot: "append",
                              },
                              [
                                _c("div", {
                                  attrs: { slot: "no-more" },
                                  slot: "no-more",
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "spinner" },
                                    slot: "spinner",
                                  },
                                  [
                                    _c("c-loader", {
                                      staticClass: "d-flex m-auto",
                                      attrs: { show: true, infinite: true },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm.seletedChtMediaType == _vm.tabChtMediaType.MD_LINKS
            ? [
                _vm.isFetching && _vm.chtVwMedias.length === 0
                  ? _c("c-loader", {
                      staticClass: "d-flex m-auto",
                      attrs: {
                        show: _vm.isFetching && _vm.chtVwMedias.length === 0,
                        infinite: false,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isFetching && _vm.chtVwMedias.length === 0
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-center p-3" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto cj-color-grey-primary font-size-12 font-weight-500",
                            domProps: {
                              textContent: _vm._s(_vm.$t("chat.noLinkFound")),
                            },
                          },
                          [_vm._v("No Link Found")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.chtVwMedias && _vm.chtVwMedias.length > 0
                  ? _c(
                      "div",
                      [
                        _vm._l(_vm.chtVwMedias, function (chtVwMedia, index) {
                          return _c("c-rmedia-links", {
                            key: index,
                            attrs: { chtVwMedia: chtVwMedia },
                          })
                        }),
                        _vm._v(" "),
                        _vm.totalItems > _vm.itemsPerPage
                          ? _c(
                              "infinite-loading",
                              {
                                ref: "infiniteLoading",
                                attrs: {
                                  slot: "append",
                                  identifier: _vm.infiniteId,
                                  distance: 20,
                                },
                                on: { infinite: _vm.loadMore },
                                slot: "append",
                              },
                              [
                                _c("div", {
                                  attrs: { slot: "no-more" },
                                  slot: "no-more",
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "spinner" },
                                    slot: "spinner",
                                  },
                                  [
                                    _c("c-loader", {
                                      staticClass: "d-flex m-auto",
                                      attrs: { show: true, infinite: true },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "dltChtMgsModal",
          attrs: {
            id: "modal-media-delete-notif",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
        },
        [
          _c("div", { staticClass: "media-delete-notif" }, [
            _c(
              "div",
              { staticClass: "p-2" },
              [
                _c("b-icon-question-circle", {
                  staticClass: "cj-color-red-primary font-size-64",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "m-0 text-title" }, [_vm._v("Delete")]),
            _vm._v(" "),
            _c("p", { staticClass: "m-0 pb-3 text-sub" }, [
              _vm._v("Are you sure want to delete this??"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex w-100" }, [
              _c(
                "div",
                { staticClass: "mr-1 w-100" },
                [
                  _c(
                    "c-button",
                    {
                      attrs: { fullWidth: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.delChtMgsCallBack()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-1 w-100" },
                [
                  _c(
                    "c-button",
                    {
                      attrs: { fullWidth: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.confirmDeleteSelectedMediaIds("DELETE")
                        },
                      },
                    },
                    [_vm._v("Delete")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modal-crmedia-preview",
          attrs: {
            "data-cy": "modal-crmedia-preview",
            id: "modal-crmedia-preview",
            size: "lg",
            "hide-footer": "",
            "hide-header": "",
            "content-class": "vac-custom-modal-media-preview-content-b-modal",
            "dialog-class": "vac-custom-modal-media-preview-size-b-modal",
            "modal-class": "vac-custom-modal-media-preview-b-modal",
          },
        },
        [
          _c("c-rmedia-preview", {
            ref: "previewMediaMainRef",
            attrs: { chtVwMedias: _vm.chtVwMedias },
            on: {
              "close-media-preview": _vm.closeCRmediaPreview,
              downloadImageVideo: _vm.downloadImageVideo,
              deleteImageVideo: _vm.deleteImageVideo,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex align-items-center flex-grow-1" }, [
      _c(
        "p",
        {
          staticClass:
            "m-0 font-roboto font-size-24 font-weight-500 cj-color-black-primary",
        },
        [_vm._v("Media")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }