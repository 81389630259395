import {Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CRmediaLinks extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  @Prop()
  public chtVwMedia;
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getGroupDate() {
    const then = new Date(this.chtVwMedia.createdDate);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());

    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    if (hoursBetweenDates < 24) {
      return this.chtVwMedia.groupTime;
    } else {
      return this.chtVwMedia.groupDate;
    }
  }
  // ================= END COMPUTE ====================
}
