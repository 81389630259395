import * as SockJS from 'sockjs-client';
import { map } from 'rxjs';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { RxStomp } from '@stomp/rx-stomp';
import GlobalService from '@/services/global.service';

const API_PUBLISH = 'services/btisocketkafkagw/api/btisocketkafkagw-kafka/publish'; ///using dev.env to avoid cors

const SOCKET_APP = 'websocket/la';

const DESTINATION_USER_ONLINE = '/topic/la/user_online';
const DESTINATION_CONVERSATION_ID = '/topic/la/conversation_id';
const DESTINATION_CONVERSATION_NEW = '/topic/la/conversation_new';
const DESTINATION_LAST_SEEN = '/topic/la/conversation_last_seen';

export default class SocketLiveAgentService {
  private rxStomp: RxStomp;
  private authToken;

  constructor(private router: VueRouter, private store: Store<any>, private globalService: GlobalService) {
    this.stomp = new RxStomp();

    this.store.watch(
      (_state, getters) => getters.authenticated,
      (value, oldValue) => {
        if (value === oldValue) return;
        if (value) {
          return this.connect();
        } else {
          return this.disconnect();
        }
      }
    );
  }

  get stomp() {
    return this.rxStomp;
  }

  set stomp(rxStomp) {
    this.rxStomp = rxStomp;
    this.rxStomp.configure({
      debug: (msg: string): void => {
        console.log(new Date(), msg);
      },
    });
    this.rxStomp.connected$.subscribe(() => {});
  }

  public connect(): void {
    this.updateCredentials();
    return this.rxStomp.activate();
  }

  private disconnect(): Promise<void> {
    return this.rxStomp.deactivate();
  }

  private buildUrl(): string {
    let url = '//' + process.env.SOCKET_URL + SOCKET_APP;
    if (this.authToken) {
      url += '?bearer=' + this.authToken;
    }

    return url;
  }

  private updateCredentials(): void {
    this.rxStomp.configure({
      webSocketFactory: () => {
        return new SockJS(this.buildUrl()); //default
      },
    });
  }

  public subscribeUserOnline(observer) {
    return this.rxStomp
      .watch(DESTINATION_USER_ONLINE)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeConversationId(observer, conversationId) {
    return this.rxStomp
      .watch(DESTINATION_CONVERSATION_ID + '/' + conversationId)
      .pipe(map(imessage => JSON.parse(imessage.body)))
      .subscribe(observer);
  }

  public subscribeLastSeen(observer, conversationId) {
    return this.rxStomp
      .watch(DESTINATION_LAST_SEEN + '/' + conversationId)
      .pipe(
        map(imessage => {
          console.log(imessage, ' data socket only');
          return JSON.parse(imessage.body);
        })
      )
      .subscribe(observer);
  }
}
