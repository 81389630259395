import axios from 'axios';

import { ICbUserData } from '@/shared/model/cb-user-data.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-data';

export default class PCbUserDataService {
  public find(id: number): Promise<ICbUserData> {
    return new Promise<ICbUserData>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public create(entity: ICbUserData, type: string, fileBlob: Blob, callBack: Function, callbackReject: Function): Promise<ICbUserData> {
    if (entity.cbAttachmentRestrict) {
      entity.cbAttachmentRestrict.att = '';
      delete entity.cbAttachmentRestrict.blob;
    }
    return new Promise<ICbUserData>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          const result: ICbUserData = res.data;
          resolve(this.upload(result, result.uploadLink, type, fileBlob, callBack, callbackReject));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbUserData, type: string, fileBlob: Blob, callBack: Function, callbackReject): Promise<ICbUserData> {
    if (entity.cbAttachmentRestrict.blob) {
      delete entity.cbAttachmentRestrict.blob;
    }
    return new Promise<ICbUserData>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          const result: ICbUserData = res.data;
          resolve(this.upload(result, result.uploadLink, type, fileBlob, callBack, callbackReject));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public upload(
    result: ICbUserData,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(signUrlRes => {
          resolve(result);
        })
        .catch(signUrlErr => {
          reject(callbackReject(result));
        });
    });
  }

  public deleteByParam(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}?${paginationQuery && paginationQuery.deleteIds ? paginationQuery.deleteIds : ''}`, {})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
