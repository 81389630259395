export enum CbPawStatus {
  ACTIVE = 'ACTIVE',

  DRAFT = 'DRAFT',

  HIDE = 'HIDE',

  UNDER_REVIEW = 'UNDER_REVIEW',

  UNDER_CONFIRMATION = 'UNDER_CONFIRMATION',

  REVISION_NEEDED = 'REVISION_NEEDED', // cuma text dari under confirmation

  DELETED = 'DELETED',

  PENDING = 'PENDING',

  HIBERNATE = 'HIBERNATE',
}
