import Vue from 'vue';
import dayjs from 'dayjs';
import { TIMEZONE_JAKARTA } from '@/constants';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DATE_TIME_LONG_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_INDONESIAN_LONG_FORMAT = 'D MMMM YYYY';
export const DATE_INDONESIAN_SHORT_FORMAT = 'D MMM `YY';

export const DATE_REGION_LONG_FORMAT = 'MMM D, YYYY HH:mm';
export const DATE_REGION_SHORT_FORMAT = 'MMM D, YYYY';

export const TIME_24_FORMAT = 'HH:mm';

export const DATE_TIME_DEFAULT = 'DD MMM YYYY HH:mm';
export const DATE_DEFAULT = 'DD MMM YYYY';

export const DATE_SLASH_FORMAT = 'DD/MM/YYYY';

export const DATE_ISO_STRING_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_GMT = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)';

export function initFilters() {
  Vue.filter('formatDateMonth', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT);
    }
    return '';
  });

  Vue.filter('formatDateLong', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_LONG_FORMAT);
    }
    return '';
  });

  Vue.filter('formatIndonesianShortDate', value => {
    if (value) {
      return dayjs(value).format(DATE_INDONESIAN_SHORT_FORMAT);
    }
    return '';
  });

  Vue.filter('formatIndonesianDate', value => {
    if (value) {
      return dayjs(value).format(DATE_INDONESIAN_LONG_FORMAT);
    }
    return '';
  });

  Vue.filter('format24TimeToUtc', value => {
    if (value) {
      return dayjs(value).utc().format(TIME_24_FORMAT);
    }
    return '';
  });

  Vue.filter('formatMillis', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });

  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });

  Vue.filter('time24', value => {
    if (value) {
      const newDate = new Date(value);
      if (newDate) {
        return dayjs(value).format(TIME_24_FORMAT);
      }
    }
  });

  Vue.filter('formatCbTime', value => {
    if (value) {
      const newDate: Date = new Date(value);
      const nowDate: Date = new Date();
      if (newDate) {
        if (
          newDate.getFullYear() == nowDate.getFullYear() &&
          newDate.getMonth() == nowDate.getMonth() &&
          newDate.getDay() == nowDate.getDay()
        ) {
          return dayjs(newDate).format(TIME_24_FORMAT);
        } else {
          return dayjs(newDate).format('DD MMM YY');
        }
      }
    }
  });

  Vue.filter('formatCbDateMonth', value => {
    if (value) {
      const newDate: Date = new Date(value);
      const nowDate: Date = new Date();
      if (newDate) {
        if (
          newDate.getFullYear() == nowDate.getFullYear() &&
          newDate.getMonth() == nowDate.getMonth() &&
          newDate.getDay() == nowDate.getDay()
        ) {
          return dayjs(newDate).format(TIME_24_FORMAT);
        } else {
          return dayjs(newDate).format('DD MMM');
        }
      }
    }
  });

  Vue.filter('convertDateToAccordanceTimeZone', (startDate: Date, timeZoneUtc: string) => {
    const timeZone: string = timeZoneUtc?.split(' ')[1];

    if (startDate && timeZone) {
      const newDate = dayjs(startDate, { format: DATE_FORMAT_GMT }).format(DATE_ISO_STRING_FORMAT);
      const convertBasedOnJakarta = dayjs.tz(newDate, TIMEZONE_JAKARTA).format(DATE_TIME_DEFAULT);

      return `${convertBasedOnJakarta} ${timeZoneUtc}`;
    }

    return '';
  });

  Vue.filter('dynamicFormatDate', (value, formatStr) => {
    if (value) {
      return dayjs(value).format(formatStr);
    }
    return '';
  });

  Vue.filter('operationalHourDiff', (operationHourStart, operationHourEnd) => {
    if (operationHourStart && operationHourEnd) {
      const formatDateStart = dayjs(operationHourStart).format(TIME_24_FORMAT);
      const formatDateEnd = dayjs(operationHourEnd).format(TIME_24_FORMAT);
      const formatDateNow = dayjs().format(TIME_24_FORMAT);

      const dateStart = dayjs(formatDateStart, TIME_24_FORMAT);
      const dateEnd = dayjs(formatDateEnd, TIME_24_FORMAT);
      const dateNow = dayjs(formatDateNow, TIME_24_FORMAT);

      if (dateNow.isBefore(dateStart)) {
        const countHour = dateStart.diff(dateNow, 'hour');
        const countMinute = dateStart.diff(dateNow, 'minute') % 60;

        const arrayTime = [];
        if (countHour > 0) {
          arrayTime.push(countHour + (countHour === 1 ? ' Hour' : ' Hours'));
        }
        if (countMinute > 0) {
          arrayTime.push(countMinute + (countMinute === 1 ? ' Minute' : ' Minutes'));
        }
        return `${arrayTime.join(' and ')} to go`;
      } else if (dateNow.isAfter(dateEnd)) {
        const newStart = dateStart.add(1, 'day');
        let countTotMinute = dateNow.diff(newStart, 'minute');
        if (countTotMinute < 0) {
          countTotMinute *= -1;
        }

        const hour = Math.floor(countTotMinute / 60);
        const minute = countTotMinute % 60;

        const arrayTime = [];
        if (hour > 0) {
          arrayTime.push(hour + (hour === 1 ? ' Hour' : ' Hours'));
        }
        if (minute > 0) {
          arrayTime.push(minute + (minute === 1 ? ' Minute' : ' Minutes'));
        }
        return `${arrayTime.join(' and ')} to go`;
      } else {
        return;
      }
    }
  });

  Vue.filter('convertSecondToDate', (second: any) => {
    if (second) {
      return dayjs().set('second', second).toDate();
    }
  });

  Vue.filter('formatDateTimeDefault', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_DEFAULT);
    }
    return '';
  });

  Vue.filter('formatDateDefault', value => {
    if (value) {
      return dayjs(value).format(DATE_DEFAULT);
    }
    return '';
  });

  Vue.filter('formatTimeDefault', value => {
    if (value) {
      return dayjs(value).format(TIME_24_FORMAT);
    }
    return '';
  });

  Vue.filter('formatDateFromNow', value => {
    if (value) {
      return dayjs(value).fromNow();
    }
    return '-';
  });

  Vue.filter('formatCreateDatelabel', createdDate => {
    const now = dayjs();
    const diffYears = now.diff(createdDate, 'year');
    const diffMonths = now.diff(createdDate, 'month');
    const diffDays = now.diff(createdDate, 'day');
    const diffHours = now.diff(createdDate, 'hour');
    const diffMinutes = now.diff(createdDate, 'minute');
    const diffSeconds = now.diff(createdDate, 'second');

    if (diffYears > 0) {
      return dayjs(createdDate).format('DD MMM YYYY');
    } else if (diffMonths > 0) {
      return dayjs(createdDate).format('DD MMM YYYY');
    } else if (diffDays > 7) {
      return dayjs(createdDate).format('DD MMM YYYY');
    } else if (diffDays > 0 && diffDays <= 7) {
      return `${diffDays} days ago`;
    } else if (diffHours > 0) {
      return dayjs(createdDate).format('HH:mm');
    } else if (diffMinutes > 0) {
      return dayjs(createdDate).format('HH:mm');
    } else if (diffSeconds > 0) {
      return dayjs(createdDate).format('HH:mm');
    } else {
      return '-';
    }
  });
}
