import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class RoomControllerChat extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // public typeFile = IMAGEVIDEO_TYPES;
  public isShowEmotReactions = false;
  public selectedMessageId = 0;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public handleShowEmotReactions({ messageId }) {
    this.isShowEmotReactions = true;
    this.selectedMessageId = messageId;
  }
  public closeShowEmotReactions() {
    this.isShowEmotReactions = false;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
