var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-verification-phone" },
    [
      _vm.visibleHeader
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center w-100 position-relative",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "p-0 m-0 cj-color-black-primary font-weight-500 mt-2 text-center",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18",
                },
                [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cbVerificationMethod.otpDescPhone"
                          )
                        ),
                      },
                    },
                    [_vm._v(" OTP code has been send via WhatsApp to")]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "cj-color-red-primary font-weight-600 mt-2",
                    },
                    [_vm._v(" " + _vm._s(_vm.value && _vm.value.phone) + " ")]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.invalidOtp && !_vm.isFetching
        ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-roboto px-3 py-1 d-flex wrap-border-status-error rounded-pill m-auto cj-color-status-error font-size-14 font-weight-300",
              },
              [
                _c("b-icon-x-circle", {
                  staticClass: "font-size-16 cj-color-status-error my-auto",
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "ml-2 my-auto",
                    attrs: { "data-cy": "wrong-otp" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.wrongOtpCode"
                        )
                      ),
                    },
                  },
                  [_vm._v("Wrong OTP Code")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("c-input-otp", {
        attrs: { visibleLoading: false, isUpperCase: true },
        on: { input: _vm.handleInputOtp },
        model: {
          value: _vm.inputOtp,
          callback: function ($$v) {
            _vm.inputOtp = $$v
          },
          expression: "inputOtp",
        },
      }),
      _vm._v(" "),
      _vm.visibleFooter
        ? _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
            !_vm.isPhoneTrouble
              ? _c(
                  "p",
                  {
                    staticClass:
                      "font-catamaran font-weight-400 cj-btn-text-primary my-auto",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18",
                    attrs: { "data-cy": "change-phone-number" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.changePhoneNumber"
                        )
                      ),
                    },
                    on: { click: _vm.changePhoneNumber },
                  },
                  [_vm._v("\n      Change phone number\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex",
                class: {
                  "cj-btn-text-primary": !_vm.showCountdown,
                  "cj-color-black-primary": _vm.showCountdown,
                  "ml-auto": _vm.isPhoneTrouble,
                },
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "font-catamaran font-weight-400 my-auto",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18",
                    attrs: {
                      disabled: _vm.visibleLoading,
                      "data-cy": "resend-button",
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.sendAgain"
                        )
                      ),
                    },
                    on: {
                      click: () => {
                        if (!_vm.showCountdown && !_vm.visibleLoading) {
                          _vm.resend()
                        }
                      },
                    },
                  },
                  [_vm._v("\n        Send Again\n      ")]
                ),
                _vm._v(" "),
                _vm.showCountdown
                  ? _c("c-time-countdown", {
                      attrs: {
                        timeMilis:
                          60 *
                          (_vm.value.timeCountDown != null
                            ? _vm.value.timeCountDown
                            : _vm.timeCoundown),
                        stopCountdown: 0,
                      },
                      on: {
                        action: function ($event) {
                          _vm.showCountdown = false
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ minutes, seconds }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-catamaran font-weight-400 my-auto ml-1",
                                    class: _vm.isMobile
                                      ? "font-size-14"
                                      : "font-size-18",
                                  },
                                  [
                                    _vm._v(
                                      "\n          (" +
                                        _vm._s(minutes) +
                                        ":" +
                                        _vm._s(seconds) +
                                        ")\n        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        579652137
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }