import { Module } from 'vuex';
import { ICbLov } from '@/shared/model/cb-lov.model';
import { INtVwUserNotif } from '@/shared/model/nt-vw-user-notif.model';

export interface LovStateStorable {
  CONTENT_VERSION: ICbLov[];
  CONTENT_VERSION_BIND: any;

  CONTENT_BANNER_BUYER: ICbLov[];
  CONTENT_BANNER_PARTNER: ICbLov[];
  CONTENT_IMAGE: ICbLov[];
  TIME_ZONE: ICbLov[];
  COUNTRY: ICbLov[];
  PROVINCE: ICbLov[];
  CITY: ICbLov[];
  LANGUAGE_CODE: ICbLov[];
  CB_ICON_LABEL_URL: INtVwUserNotif[];
  TIME_ZONE_ABBREVIATION: ICbLov[];
  PHONE_CODE: ICbLov[];
  MAIN_BANNER_PROMO: ICbLov[];
  LANGUAGE: ICbLov[];
  MAJOR: ICbLov[];
  BANK_CODE: ICbLov[];
  WATERMARK: ICbLov[];
  PAW_CRT_MANDATORY: ICbLov[];
  LANGUAGE_LEVEL: ICbLov[];
  APP_LINK: ICbLov[];
}

export const defaultState: LovStateStorable = {
  CONTENT_VERSION: [],
  CONTENT_VERSION_BIND: {},
  CONTENT_BANNER_BUYER: [],
  CONTENT_BANNER_PARTNER: [],
  CONTENT_IMAGE: [],
  TIME_ZONE: [],
  COUNTRY: [],
  PROVINCE: [],
  CITY: [],
  LANGUAGE_CODE: [],
  CB_ICON_LABEL_URL: [],
  TIME_ZONE_ABBREVIATION: [],
  PHONE_CODE: [],
  MAIN_BANNER_PROMO: [],
  LANGUAGE: [],
  MAJOR: [],
  BANK_CODE: [],
  WATERMARK: [],
  PAW_CRT_MANDATORY: [],
  LANGUAGE_LEVEL: [],
  APP_LINK: [],
};

export const lovStore: Module<LovStateStorable, any> = {
  state: { ...defaultState },
  getters: {
    CONTENT_VERSION: state => state.CONTENT_VERSION,
    CONTENT_VERSION_BIND: state => state.CONTENT_VERSION_BIND,
    CONTENT_BANNER_BUYER: state => state.CONTENT_BANNER_BUYER,
    CONTENT_BANNER_PARTNER: state => state.CONTENT_BANNER_PARTNER,
    CONTENT_IMAGE: state => state.CONTENT_IMAGE,
    TIME_ZONE: state => state.TIME_ZONE,
    COUNTRY: state => state.COUNTRY,
    PROVINCE: state => state.PROVINCE,
    CITY: state => state.CITY,
    LANGUAGE_CODE: state => state.LANGUAGE_CODE,
    CB_ICON_LABEL_URL: state => state.CB_ICON_LABEL_URL,
    TIME_ZONE_ABBREVIATION: state => state.TIME_ZONE_ABBREVIATION,
    PHONE_CODE: state => state.PHONE_CODE,
    MAIN_BANNER_PROMO: state => state.MAIN_BANNER_PROMO,
    LANGUAGE: state => state.LANGUAGE,
    MAJOR: state => state.MAJOR,
    BANK_CODE: state => state.BANK_CODE,
    WATERMARK: state => state.WATERMARK,
    PAW_CRT_MANDATORY: state => state.PAW_CRT_MANDATORY,
    LANGUAGE_LEVEL: state => state.LANGUAGE_LEVEL,
    APP_LINK: state => state.APP_LINK,
  },
  mutations: {
    CONTENT_BANNER_BUYER(state, param) {
      state.CONTENT_BANNER_BUYER = param;
    },
    CONTENT_BANNER_PARTNER(state, param) {
      state.CONTENT_BANNER_PARTNER = param;
    },
    CONTENT_IMAGE(state, param) {
      state.CONTENT_IMAGE = param;
    },
    TIME_ZONE(state, param) {
      state.TIME_ZONE = param;
    },
    COUNTRY(state, param) {
      state.COUNTRY = param;
    },
    PROVINCE(state, param) {
      state.PROVINCE = param;
    },
    CITY(state, param) {
      state.CITY = param;
    },
    LANGUAGE_CODE(state, param) {
      state.LANGUAGE_CODE = param;
    },
    CB_ICON_LABEL_URL(state, param) {
      state.CB_ICON_LABEL_URL = param;
    },
    TIME_ZONE_ABBREVIATION(state, param) {
      state.TIME_ZONE_ABBREVIATION = param;
    },
    PHONE_CODE(state, param) {
      state.PHONE_CODE = param;
    },
    MAIN_BANNER_PROMO(state, param) {
      state.MAIN_BANNER_PROMO = param;
    },
    LANGUAGE(state, param) {
      state.LANGUAGE = param;
    },
    MAJOR(state, param) {
      state.MAJOR = param;
    },
    BANK_CODE(state, param) {
      state.BANK_CODE = param;
    },
    WATERMARK(state, param) {
      state.WATERMARK = param;
    },
    PAW_CRT_MANDATORY(state, param) {
      state.PAW_CRT_MANDATORY = param;
    },

    CONTENT_VERSION(state, param) {
      state.CONTENT_VERSION = param;
    },

    CONTENT_VERSION_BIND(state, param) {
      state.CONTENT_VERSION_BIND = param;
    },
    LANGUAGE_LEVEL(state, param) {
      state.LANGUAGE_LEVEL = param;
    },
    APP_LINK(state, param) {
      state.APP_LINK = param;
    },
  },
};
