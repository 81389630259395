import { Inject, Component, Vue } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import pCbVwSvcPopularService from '@/services/cb-vw-svc-cat-popular.service';
import { ICbVwSvcCatPopular } from '@/shared/model/cb-vw-svc-cat-popular.model';
import CLoader from '@/components/c-loader/c-loader.vue';

@Component({
  components: {
    Icon,
    CLoader,
  },
})
export default class CPopularSearchList extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbVwSvcPopularService') private pCbVwSvcPopularService: () => pCbVwSvcPopularService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public isFetchingCbVwSvcPopulars = false;
  public cbVwSvcPopulars: ICbVwSvcCatPopular[] = [];

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  mounted() {
    this.retrieveAllcbVwSvcPopulars();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public retrieveAllcbVwSvcPopulars(): void {
    this.isFetchingCbVwSvcPopulars = true;
    let params: any = {
      sort: 'svcPopularWeight,desc',
    };
    this.pCbVwSvcPopularService()
      .retrieve(params)
      .then(
        res => {
          this.cbVwSvcPopulars = res.data;
          this.isFetchingCbVwSvcPopulars = false;
        },
        err => {
          this.isFetchingCbVwSvcPopulars = false;
        }
      );
  }
  public toSvc(svc) {
    this.$emit('item-clicked');
    this.$router.push({ name: 'PSvc', params: { id: svc.svcId } });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
