export enum PayMethodType {
  CC = 'CC',
  EWALLET = 'EWALLET',
  QRCODE = 'QRCODE',
  VA = 'VA',
  CB_BALANCE = 'CB_BALANCE',
  BALANCE = 'BALANCE',
  BALANCE_REFUND = 'BALANCE_REFUND',
  BALANCE_AUCTION_GUARANTEE = 'BALANCE_AUCTION_GUARANTEE',

  TOP_UP = 'TOP_UP',
  TOP_UP_DETAIL = 'TOP_UP_DETAIL',
  ORDER_PAW = 'ORDER_PAW',
  ORDER_PAW_DETAIL = 'ORDER_PAW_DETAIL',
  DISBURSEMENT = 'DISBURSEMENT',
  REFUND_REQUEST = 'REFUND_REQUEST',
  ORDER_AUCTION = 'ORDER_AUCTION',
  ORDER_AUCTION_DETAIL = 'ORDER_AUCTION_DETAIL',
  DISBURSEMENT_DETAIL = 'DISBURSEMENT_DETAIL',
  REFUND_REQUEST_DETAIL = 'REFUND_REQUEST_DETAIL',

  DISBURSEMENT_INCOME_DETAIL = 'DISBURSEMENT_INCOME_DETAIL',
  DISBURSEMENT_INCOME = 'DISBURSEMENT_INCOME',
}
