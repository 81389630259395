import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbGender } from './enumerations/cb-gender.model';
import { ICbUserLanguage } from './cb-user-language.model';

export interface ICbVwPaw {
  id?: number;
  title?: string | null;
  subTitle?: string | null;
  star?: number | null;
  revision?: number | null;
  revisionLabel?: string | null;
  userName?: string | null;
  userLevel?: string | null;
  userStar?: number | null;
  userId?: string | null;
  isUserOnline?: boolean | null;
  metavalues?: string | null;
  currency?: string | null;
  startPrice?: number | null;
  deliveryTime?: number | null;
  deliveryTimeHour?: number | null;
  deliveryTimeLabel?: string | null;
  isColabs?: boolean | null;
  isPopular?: boolean | null;
  viewCount?: number | null;
  status?: string | null;
  svcType?: CbSvcType | null;
  svc?: string | null;
  svcCategory?: string | null;
  svcCategoryId?: number | null;
  svcServiceId?: number | null;
  authorName?: string | null;
  authorGender?: CbGender | null;
  authorId?: string | null;
  authorLogin?: string | null;
  locationState?: string | null;
  locationCity?: string | null;
  locationAddress?: string | null;
  latGoogle?: string | null;
  longGoogle?: string | null;
  placeId?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  photoAuthorId?: number | null;
  mainPhotoPawId?: number | null;
  mainVideoPawId?: number | null;
  orderCompletedCount?: number | null;
  descriptionId?: number | null;
  pkgs?: string | null;
  isPublish?: boolean | null;
  isHide?: boolean | null;
  impression?: number | null;
  impressionLabel?: string | null;
  pawSearch?: string | null;
  locationProvince?: string | null;
  locationCountry?: string | null;
  locationSearch?: string | null;
  statusSeq?: number | null;
  isAgreeTermOfUse?: boolean | null;
  postedDate?: Date | null;
  cbUserLanguages?: ICbUserLanguage[] | null;
}

export class CbVwPaw implements ICbVwPaw {
  constructor(
    public id?: number,
    public title?: string | null,
    public subTitle?: string | null,
    public star?: number | null,
    public revision?: number | null,
    public revisionLabel?: string | null,
    public userName?: string | null,
    public userLevel?: string | null,
    public userStar?: number | null,
    public userId?: string | null,
    public isUserOnline?: boolean | null,
    public metavalues?: string | null,
    public currency?: string | null,
    public startPrice?: number | null,
    public deliveryTime?: number | null,
    public deliveryTimeHour?: number | null,
    public deliveryTimeLabel?: string | null,
    public isColabs?: boolean | null,
    public isPopular?: boolean | null,
    public viewCount?: number | null,
    public status?: string | null,
    public svcType?: CbSvcType | null,
    public svc?: string | null,
    public svcCategory?: string | null,
    public svcCategoryId?: number | null,
    public svcServiceId?: number | null,
    public authorName?: string | null,
    public authorId?: string | null,
    public authorLogin?: string | null,
    public locationState?: string | null,
    public locationCity?: string | null,
    public locationAddress?: string | null,
    public latGoogle?: string | null,
    public longGoogle?: string | null,
    public placeId?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public photoAuthorId?: number | null,
    public mainPhotoPawId?: number | null,
    public mainVideoPawId?: number | null,
    public orderCompletedCount?: number | null,
    public descriptionId?: number | null,
    public pkgs?: string | null,
    public isPublish?: boolean | null,
    public isHide?: boolean | null,
    public impression?: number | null,
    public impressionLabel?: string | null,
    public pawSearch?: string | null,

    public locationProvince?: string | null,
    public locationCountry?: string | null,
    public locationSearch?: string | null,
    public statusSeq?: number | null,
    public isAgreeTermOfUse?: boolean | null,
    public postedDate?: Date | null,
    public cbUserLanguages?: ICbUserLanguage[] | null
  ) {}
}
