var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass:
            "c-item-chat-message-revision-offer p-2 cj-bg-white-primary",
        },
        [
          _c("div", { staticClass: "text-center mb-2" }, [
            _c(
              "p",
              {
                ref: "cbVwPawOrderRequirement.pawTitle",
                staticClass: "c-icm-font-card-title text-ellipsis oneline",
                attrs: { "data-cy": "revision-offer-paw-title" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                    "\n      "
                ),
                _c(
                  "b-tooltip",
                  {
                    staticClass: "custom-tooltip",
                    attrs: {
                      target: () =>
                        _vm.$refs["cbVwPawOrderRequirement.pawTitle"],
                      variant: "primary",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-catamaran font-size-12 font-weight-500",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                ref: "cbVwPawOrderRequirement.svcName",
                staticClass:
                  "c-icm-font-card-subtitle mb-1 text-ellipsis oneline",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.cbVwPawOrderRequirement.svcName) +
                    " |\n      " +
                    _vm._s(_vm.cbVwPawOrderRequirement.svcCategoryName) +
                    "\n      "
                ),
                _c(
                  "b-tooltip",
                  {
                    staticClass: "custom-tooltip",
                    attrs: {
                      target: () =>
                        _vm.$refs["cbVwPawOrderRequirement.svcName"],
                      variant: "primary",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-catamaran font-size-12 font-weight-500",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.cbVwPawOrderRequirement.svcName) +
                            " |\n          " +
                            _vm._s(
                              _vm.cbVwPawOrderRequirement.svcCategoryName
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.getStatusCountdown
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-center mb-1" },
                  [
                    _vm.cbVwPawOrderRequirement.status ==
                    _vm.CbCommonStatus.REVISION_REQUESTED
                      ? _c("c-time-countdown", {
                          attrs: {
                            timeDate:
                              _vm.cbVwPawOrderRequirement.offerExpiredDate,
                            stopCountdown: 0,
                          },
                          on: { action: _vm.toggleOfferExpired },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ hours, minutes, seconds }) {
                                  return [
                                    hours && hours != "00"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "rounded cj-bg-red-primary cj-color-white-primary font-weight-700 font-roboto px-1 d-flex mr-1",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "font-size-12" },
                                              [_vm._v(_vm._s(hours))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-size-10 mt-auto",
                                              },
                                              [_vm._v("H")]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "rounded cj-bg-red-primary cj-color-white-primary font-weight-700 font-roboto px-1 d-flex mr-1",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-size-12" },
                                          [_vm._v(_vm._s(minutes))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-size-10 mt-auto",
                                          },
                                          [_vm._v("M")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "rounded cj-bg-red-primary cj-color-white-primary font-weight-700 font-roboto px-1 d-flex",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-size-12" },
                                          [_vm._v(_vm._s(seconds))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "font-size-10 mt-auto",
                                          },
                                          [_vm._v("S")]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2987972867
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "c-icm-font-card-status" }, [
              _vm.cbVwPawOrderRequirement.status ==
              _vm.CbCommonStatus.REVISION_REQUESTED
                ? _c("span", [
                    _vm.getStatusCountdown
                      ? _c("span", { staticClass: "cj-color-black-primary" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("capitalEachSpace")(
                                  _vm.CbCommonStatus.WAITING_PAYMENT
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _c("span", { staticClass: "cj-color-status-error" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("capitalEachSpace")(
                                  _vm.CbCommonStatus.CANCELLED
                                )
                              ) +
                              "\n        "
                          ),
                        ]),
                  ])
                : _vm.cbVwPawOrderRequirement.status == _vm.CbCommonStatus.PAID
                ? _c("span", { staticClass: "cj-color-black-primary" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("capitalEachSpace")(
                            _vm.CbCommonStatus.ON_PROGRESS
                          )
                        ) +
                        "\n      "
                    ),
                  ])
                : _c("span", { staticClass: "cj-color-black-primary" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("capitalEachSpace")(
                            _vm.cbVwPawOrderRequirement.status
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "cbVwPawOrderRequirement.currency",
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-500 font-italic cj-color-black-primary font-size-14 mb-1",
                  attrs: { "data-cy": "extra-revision-heading" },
                },
                [_vm._v("\n      Extra Revisions\n    ")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-300 font-italic cj-color-black-primary font-size-8",
                },
                [_vm._v("Total Price")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-500 font-size-18 cj-color-black-primary mb-1 text-ellipsis oneline",
                  attrs: { "data-cy": "extra-revision-price" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cbVwPawOrderRequirement.currency) +
                      "\n      " +
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.cbVwPawOrderRequirement.amount,
                          _vm.cbVwPawOrderRequirement.currency
                        )
                      ) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["cbVwPawOrderRequirement.currency"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v("Extra Revisions")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v("Total Price")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.currency) +
                          "\n        " +
                          _vm._s(
                            _vm._f("formatCurrency")(
                              _vm.cbVwPawOrderRequirement.amount,
                              _vm.cbVwPawOrderRequirement.currency
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
              staticStyle: { "word-break": "break-all" },
            },
            [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto cj-color-black-primary font-weight-500 font-size-14 my-auto",
                  },
                  [_vm._v("Requirement")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-block" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "mr-2 px-2 rounded border-width-1 cj-border-color-grey-secondary",
                      attrs: {
                        "data-cy": "extra-revision-dropdown",
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isExpandRequirement = !_vm.isExpandRequirement
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass:
                          "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                        class: _vm.isExpandRequirement ? "actived" : "",
                        attrs: {
                          name: "ic_down",
                          width: "1rem",
                          height: "1rem",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  model: {
                    value: _vm.isExpandRequirement,
                    callback: function ($$v) {
                      _vm.isExpandRequirement = $$v
                    },
                    expression: "isExpandRequirement",
                  },
                },
                [
                  _vm.cbTextRestrict && _vm.cbTextRestrict.content
                    ? _c("c-textarea-preview", {
                        staticClass:
                          "font-catamaran font-weight-300 font-size-12 cj-color-black-primary text-left",
                        attrs: { text: _vm.cbTextRestrict.content },
                      })
                    : _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto cj-color-black-primary font-weight-400 font-size-12",
                          },
                          [_vm._v("No Data Requirement")]
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
              staticStyle: { "word-break": "break-all" },
            },
            [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto cj-color-black-primary font-weight-500 font-size-14 my-auto",
                  },
                  [_vm._v("Extra")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-block" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "mr-2 px-2 rounded border-width-1 cj-border-color-grey-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.isExpandExtra = !_vm.isExpandExtra
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass:
                          "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                        class: _vm.isExpandExtra ? "actived" : "",
                        attrs: {
                          name: "ic_down",
                          width: "1rem",
                          height: "1rem",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  model: {
                    value: _vm.isExpandExtra,
                    callback: function ($$v) {
                      _vm.isExpandExtra = $$v
                    },
                    expression: "isExpandExtra",
                  },
                },
                [
                  !_vm.cbPawOrderExtras || _vm.cbPawOrderExtras.length === 0
                    ? _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto cj-color-black-primary font-weight-400 font-size-12",
                          },
                          [_vm._v("No Data Extra")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.cbPawOrderExtras, function (extra, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "d-flex mb-2" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex my-auto mr-1" },
                          [
                            _c("icon", {
                              staticClass: "cj-fill-red-primary my-auto",
                              attrs: { name: "ic_check", width: "1.5rem" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        extra.pawOrderExtraType ===
                        _vm.CbPawOrderExtraType.REVISION_EXTRA
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "c-icm-font-content cj-color-white-primary cj-bg-status-error py-1 px-3 my-auto rounded text-left",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("capitalEachSpace")(extra.value)
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "p",
                              {
                                staticClass: "c-icm-font-content py-1 my-auto",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("capitalEachSpace")(extra.value)
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.cbVwPawOrderRequirement.buyerId == _vm.currentUser.id &&
          _vm.cbVwPawOrderRequirement.status ==
            _vm.CbCommonStatus.REVISION_REQUESTED &&
          _vm.getStatusCountdown
            ? _c(
                "c-button",
                {
                  staticClass: "wf-accept-revision-extra-offer c-icm-font-btn",
                  attrs: { fullWidth: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.toModalPayment.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.cbVwPawOrderRequirement.amount &&
                  _vm.cbVwPawOrderRequirement.amount > 0
                    ? _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbComponent.cAskRevision.accPay")
                            ),
                          },
                        },
                        [_vm._v("\n      Accept & Pay\n    ")]
                      )
                    : _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbComponent.cAskRevision.accept")
                            ),
                          },
                        },
                        [_vm._v(" Accept ")]
                      ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }