import { render, staticRenderFns } from "./c-room-search-messages.vue?vue&type=template&id=976e00f0&scoped=true&"
import script from "./c-room-search-messages.component.ts?vue&type=script&lang=ts&"
export * from "./c-room-search-messages.component.ts?vue&type=script&lang=ts&"
import style0 from "./c-room-search-messages.vue?vue&type=style&index=0&id=976e00f0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "976e00f0",
  null
  
)

export default component.exports