import { render, staticRenderFns } from "./c-upload-file.vue?vue&type=template&id=75ccb880&"
import script from "./c-upload-file.component.ts?vue&type=script&lang=js&"
export * from "./c-upload-file.component.ts?vue&type=script&lang=js&"
import style0 from "./c-upload-file.vue?vue&type=style&index=0&id=75ccb880&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports