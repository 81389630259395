var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: ["c-ph-warning", { mobile: _vm.isMobile, desktop: !_vm.isMobile }],
      on: {
        click: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
        },
      },
    },
    [
      _c("icon", {
        staticClass: "cj-fill-red-love-primary my-auto",
        attrs: { name: "ic_exclamation", width: "22px", height: "100%" },
      }),
      _vm._v(" "),
      _c(
        "p",
        { staticStyle: { "white-space": "nowrap", overflow: "hidden" } },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("cbGlobal.completeYourProfile")),
            },
          }),
          _vm._v(",\n    "),
          _c("span", {
            staticClass: "cj-color-red-primary",
            domProps: { textContent: _vm._s(_vm.$t("cbGlobal.here")) },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.goToEditProfile.apply(null, arguments)
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "p-2 m-n2 d-flex justify-content-center align-items-center",
          staticStyle: { right: "0", top: "0" },
          attrs: { type: "button", "data-cy": "close-button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.closePhWarning.apply(null, arguments)
            },
          },
        },
        [
          _c("b-icon-x-circle-fill", {
            staticClass: "font-size-18 cj-color-grey-secondary",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }