import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';

import CInputCurrency from '../c-input-currency/c-input-currency.vue';

import { IAdvanceSearchFilter, AdvanceSearchFilter } from '@/shared/model/advance-search-model';
import { MaxNumberType } from '@/shared/model/enumerations/maxNumberType.model';

@Component({ components: { CInputCurrency } })
export default class CFilterBudget extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public value: any;
  @Prop({ default: false }) public bgFilterBudget: boolean;
  @Prop({ default: false }) public hideAction: boolean;

  public advanceSearchFilter: IAdvanceSearchFilter = new AdvanceSearchFilter();

  public MaxNumberType = MaxNumberType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.valueListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('submit')
  public submit() {
    return;
  }

  @Emit('clearAll')
  public clearAll($event) {
    if ($event?.stopPropagation) {
      $event.stopPropagation();

      this.clear();
      console.log('Clear method executed. Event propagation stopped manually');
    }
  }

  public clear() {
    this.advanceSearchFilter.greaterStartPrice = null;
    this.advanceSearchFilter.lessStartPrice = null;
    this.$emit('input', null);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('value')
  public valueListener() {
    if (this.value) {
      this.advanceSearchFilter = { ...this.advanceSearchFilter, ...this.value };
    }
  }
  // ================= END LISTENERS ===================
}
