import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import PNtVwUserNotifService from './services/nt-vw-user-notif.service';
import PChtService from './services/cht.service';

@Component({
  components: {},
})
export default class AppNotif extends Vue {
  // ================= START SERVICES =================
  @Inject('pNtVwUserNotifService') private pNtVwUserNotifService: () => PNtVwUserNotifService;
  @Inject('pChtService') private pChtService: () => PChtService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.$root.$off('appnotif::retrieveChtConversationCount');
    this.$root.$on('appnotif::retrieveChtConversationCount', () => {
      this.retrieveChtConversationCount();
    });

    this.$root.$off('appnotif::retrieveNotifCount');
    this.$root.$on('appnotif::retrieveNotifCount', () => {
      this.retrieveCbVwUserNotifCount();
    });
    this.$store.watch(
      () => this.$store.getters.authenticated,
      () => {
        if (this.$store.getters.authenticated) {
          this.retrieveChtConversationCount();
          this.retrieveCbVwUserNotifCount();
        }
      }
    );
    if (this.$store.getters.authenticated) {
      this.retrieveChtConversationCount();
      this.retrieveCbVwUserNotifCount();
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public retrieveChtConversationCount() {
    this.pChtService()
      .retrieveConversationCountByLogin()
      .then(res => {
        this.$store.commit('countMsg', res.data.countMsgByApp);
      });
  }

  public retrieveCbVwUserNotifCount() {
    this.pNtVwUserNotifService()
      .retrieveCountByLogin()
      .then(res => {
        this.$store.commit('countNotif', res.data ? res.data : 0);
      });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
