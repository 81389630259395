import { ICbSvcCatMetaDataAgg } from '@/shared/model/cb-svc-cat-meta-data-agg.model';
import { ICbPawMetaValueAgg } from '@/shared/model/cb-paw-meta-value-agg.model';

import { CbInputType } from '@/shared/model/enumerations/cb-input-type.model';
export interface ICbMetaValue {
  id?: number;
  name?: string | null;
  inputType?: CbInputType;
  selectLovName?: string | null;
  inputPlaceHolder?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  seq?: number | null;
  isActive?: boolean;
  cbSvcCatMetaDataAggs?: ICbSvcCatMetaDataAgg[] | null;
  cbPawMetaValueAggs?: ICbPawMetaValueAgg[] | null;
}

export class CbMetaValue implements ICbMetaValue {
  constructor(
    public id?: number,
    public name?: string | null,
    public inputType?: CbInputType,
    public selectLovName?: string | null,
    public inputPlaceHolder?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public seq?: number | null,
    public isActive?: boolean,
    public cbSvcCatMetaDataAggs?: ICbSvcCatMetaDataAgg[] | null,
    public cbPawMetaValueAggs?: ICbPawMetaValueAgg[] | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
