import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import { ChtVwConversation } from '@/shared/model/cht-vw-conversation.model';
import { CbVwUser, ICbVwUser } from '@/shared/model/cb-vw-user.model';
import CUserPhotoOnline from '@/components/c-user-photo-online/c-user-photo-online.vue';
import { CbNotifIconLabel } from '@/shared/model/enumerations/cb-notif-icon-label.model';
import { CbNotifImageLabel } from '@/shared/model/enumerations/cb-notif-image-label.model';
import { NtVwUserNotif } from '@/shared/model/nt-vw-user-notif.model';
import { NtLov } from '@/shared/model/nt-lov.model';
import { NtUserNotif } from '@/shared/model/nt-user-notif.model';

enum EnumTab {
  MESSAGE = 'MESSAGE',
  NOTIFICATION = 'NOTIFICATION',
}
@Component({
  components: {
    Icon,
    CUserPhotoOnline,
  },
})
export default class CItemNotification extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  public CbNotifIconLabel = CbNotifIconLabel;
  public CbNotifImageLabel = CbNotifImageLabel;
  public enumTab = EnumTab;
  @Prop({ default: null }) public tab: any;
  @Prop({ default: null }) public chtVwConversation: ChtVwConversation;
  @Prop({ default: null }) public cbVwUsers: CbVwUser[];
  @Prop({ default: null }) public ntUserNotif: NtUserNotif;

  public cbVwUser: ICbVwUser = new CbVwUser();
  public icNotif: any = CbNotifIconLabel.GENERAL_NOTE;

  public notifReplaceBaseUrl = process.env.NOTIF_REPLACE_BASE_URL;

  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.watchGetCbVwUser();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getCbVwUser() {
    return [this.cbVwUsers, this.chtVwConversation];
  }

  public get getCurrentUser(): any {
    return this.$store.getters.currentUser;
  }

  public get getCountMsgs(): number {
    let value: number = 0;
    if (this.chtVwConversation.countMsgs && this.chtVwConversation.countMsgs.length > 0) {
      let splitCountMsgs = this.chtVwConversation.countMsgs.split('||');
      if (splitCountMsgs.length > 0) {
        for (const item of splitCountMsgs) {
          let splitChild = item.split('::');
          if (splitChild[0] == this.getCurrentUser.id && splitChild[1] && +splitChild[1] > 0) {
            value = Number(splitChild[1]);
          }
        }
      }
    }
    return value;
  }
  public get formatPhotoId() {
    return senderId => {
      let value: number = null;
      if (this.cbVwUsers && this.cbVwUsers.length > 0) {
        const found = this.cbVwUsers.find(x => x.id === senderId);
        if (found) {
          value = found.photoId;
        }
      }
      return value;
    };
  }

  public get notifTitle() {
    if (this.currentLanguage == 'id' && this.ntUserNotif.subjectOrTitleId) {
      return this.ntUserNotif.subjectOrTitleId;
    } else {
      return this.ntUserNotif.subjectOrTitle;
    }
  }

  public get notifContent() {
    if (this.currentLanguage == 'id' && this.ntUserNotif.contentId) {
      return this.ntUserNotif.contentId;
    } else {
      return this.ntUserNotif.content;
    }
  }

  @Watch('getCbVwUser')
  public watchGetCbVwUser() {
    if (this.chtVwConversation && this.chtVwConversation.id && this.cbVwUsers && this.cbVwUsers.length > 0) {
      let findIdUser = this.chtVwConversation.members
        .replace(/\s/g, '')
        .split('||')
        .find(e => e !== this.getCurrentUser.id);
      if (findIdUser) {
        let found = this.cbVwUsers.find(e => e.id === findIdUser);
        if (found) {
          this.cbVwUser = Object.assign({}, this.cbVwUser, found);
        }
      }
    }
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================

  public updateRead(ntUserNotif: NtVwUserNotif) {
    (<any>this.$root).$emit('updateRead', ntUserNotif);
  }

  public get CB_ICON_LABEL_URL(): NtLov[] {
    return this.$store.getters.CB_ICON_LABEL_URL;
  }

  public handleClick(event) {
    // Check if the clicked element is a button
    const url = event.target.getAttribute('href');
    if (event.target.tagName.toLowerCase() === 'span' && url) {
      //div from a dthat replace as div
      if (url && url.includes('btichartreport')) {
        this.$emit('actionHandler', { action: 'download', url });
      } else {
        this.$emit('actionHandler', { action: 'route', url });
      }
    }
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  // ================= END LISTENERS ===================
}
