var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "survey", attrs: { "data-cy": "modal-survey" } },
    [
      _c("div", { staticClass: "bar-wrapper" }, [
        _c("div", { staticClass: "custom-text-align" }, [
          _c(
            "p",
            {
              staticClass:
                "font-roboto coloe-bali font-size-24 cj-color-black-primary font-weight-700",
              class: _vm.isMobile ? "" : "text-center",
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.helpCb")),
              },
            },
            [_vm._v("\n        Help Klikjob Improve Your Experience\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cursor-pointer",
            class: _vm.isMobile ? "" : "web-button-right-top",
            attrs: { "data-cy": "btn-cancel-review" },
            on: { click: _vm.cancel },
          },
          [
            _c("b-icon-x-circle-fill", {
              staticClass: "font-size-24 cj-color-grey-secondary",
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-3 survery-container" }, [
        _c("div", { staticClass: "mb-3" }, [
          _c(
            "p",
            {
              staticClass:
                "font-roboto font-weight-500 font-size-16 cj-color-black-primary mb-2",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbSurvey.descHowSatisfied")
                ),
              },
            },
            [
              _vm._v(
                "\n        How satisfied are you using Klikjob platform?\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "custom-radio-button m-auto" },
              _vm._l(5, function (item) {
                return _c("div", { key: item }, [
                  _c("input", {
                    attrs: {
                      type: "radio",
                      id: `radio-${item}`,
                      name: "feedbackLvl",
                    },
                    domProps: {
                      value: _vm.$v.cbHelpCenterFeedback.feedBackLvl.$model,
                    },
                    on: {
                      click: function ($event) {
                        _vm.cbHelpCenterFeedback.feedBackLvl = item
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", {
                    attrs: {
                      "data-cy": "survey-feedbackLvl",
                      for: `radio-${item}`,
                    },
                  }),
                ])
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c(
              "p",
              {
                staticClass:
                  "font-weight-400 cj-color-black-primary font-size-12 font-roboto",
                domProps: {
                  textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.notSatisfied")),
                },
              },
              [_vm._v("\n          Not Satisfied At All\n        ")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "font-weight-400 cj-color-black-primary font-size-12 font-roboto",
                domProps: {
                  textContent: _vm._s(_vm.$t("cbgwApp.cbSurvey.verySatisfied")),
                },
              },
              [_vm._v("\n          Very Satisfied\n        ")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-3" },
          [
            _c(
              "p",
              {
                staticClass:
                  "font-weight-500 cj-color-black-primary font-size-16 font-roboto mb-2",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("cbgwApp.cbSurvey.descTellUs")) +
                    " "
                ),
                _c("span", { staticClass: "cj-color-grey-secondary ml-1" }, [
                  _vm._v("(optional)"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("c-textarea-new", {
              attrs: {
                "data-cy-custom": "input-textarea-survey",
                placeholder: _vm.$t("cbgwApp.cbSurvey.descPlaceholder"),
                maxlength: _vm.maxCount,
              },
              model: {
                value: _vm.cbHelpCenterFeedback.description,
                callback: function ($$v) {
                  _vm.$set(_vm.cbHelpCenterFeedback, "description", $$v)
                },
                expression: "cbHelpCenterFeedback.description",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-3 btn-survery-wrapper" },
        [
          _c(
            "c-button",
            {
              staticClass: "px-5",
              attrs: {
                disabled: _vm.$v.cbHelpCenterFeedback.$invalid,
                "data-cy": "btn-send-survey",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSurvey.sendFeedback")
                    ),
                  },
                },
                [_vm._v("Send Feedback")]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }