var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "room-reactions" },
    [
      _c("v-emoji-picker", {
        staticClass: "custom-emoji-picker",
        attrs: {
          emojisByRow: 18,
          emojiWithBorder: false,
          showSearch: false,
          emojiSize: 24,
        },
        on: {
          select: function ($event) {
            return _vm.$emit("send-message-reaction", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }