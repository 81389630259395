import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Icon from '@/shared/icons/icon.vue';
import CUserPhotoOnline from '../c-user-photo-online/c-user-photo-online.vue';
import CPhWarning from '../c-alert copy/c-ph-warning.vue';

@Component({
  components: { Icon, CUserPhotoOnline, CPhWarning },
})
export default class CTabFooter extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public isFillHome: boolean = false;
  public isFillSearch: boolean = false;
  public isFillChat: boolean = false;
  public isFillOrder: boolean = false;
  public isFillProfile: boolean = false;
  private isNavigating: boolean = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  created() {
    (<any>this.$root).$off('menuEnableColor');
    (<any>this.$root).$on('menuEnableColor', field => {
      this.menuClearColor();
      this[field] = true;
    });
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public openLogin() {
    this.$router.push({ name: 'PLoginM' });
  }

  public menuClearColor(home?: boolean, search?: boolean, chat?: boolean, order?: boolean, profile?: boolean): Promise<void> {
    return new Promise(resolve => {
      this.isFillHome = home;
      this.isFillSearch = search;
      this.isFillChat = chat;
      this.isFillOrder = order;
      this.isFillProfile = profile;
      this.$nextTick(() => resolve());
    });
  }

  public async navigateTo(routeName) {
    if (!this.authenticated && routeName !== 'PHome' && routeName !== 'PSearchMenuM') {
      this.openLogin();
      return;
    }

    if (this.isNavigating) return;
    this.isNavigating = true;

    this.getColorClearConfig(routeName);

    await this.delayedNavigation(routeName);

    this.isNavigating = false;
  }

  public delayedNavigation(routeName) {
    return new Promise<void>(resolve => {
      setTimeout(() => {
        this.$router.push({ name: routeName, query: routeName === 'PManageOrder' ? { tab: 'ON' } : {} });
        resolve();
      }, 0);
    });
  }

  // its used when back, and when refresh base on url
  private trigerColorBasedOnBackOrUrl() {
    this.getColorClearConfig(this.$route.name);
  }

  private getColorClearConfig(routeName) {
    switch (routeName) {
      case 'PAppChat':
      case 'PAppChatByRoomId':
        this.menuClearColor(false, false, true, false, false);
        break;
      case 'PFreelancerHome':
      case 'PBuyerHome':
      case 'PHome':
        this.menuClearColor(true, false, false, false, false);
        break;
      case 'PSearchMenuM':
        this.menuClearColor(false, true, false, false, false);
        break;
      case 'PManageOrder':
        this.menuClearColor(false, false, false, true, false);
        break;
      case 'PProfile':
        this.menuClearColor(false, false, false, false, true);
        break;
      default:
        break;
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get getUser() {
    return this.$store.getters.currentUser;
  }

  public get isSetUsername(): boolean {
    return this.$store.getters.isSetUsername;
  }

  public get authenticated(): boolean {
    this.trigerColorBasedOnBackOrUrl();
    return this.$store.getters.authenticated;
  }

  public get countChat(): number {
    return this.$store.getters.countMsg;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
