var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "room-setting-user" }, [
    _c("div", { staticClass: "room-setting-user-container" }, [
      _c(
        "div",
        {
          staticClass: "p-3 cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.callBack()
            },
          },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "cb-icon-size-arrow-left cj-color-black-primary",
            attrs: { icon: "fa-solid fa-arrow-left" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c("p", {
          staticClass: "m-0 text-title",
          domProps: { textContent: _vm._s(_vm.$t("chat.setting")) },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "room-setting-user-content" }, [
      _c("div", { staticClass: "p-3 d-flex flex-column" }, [
        _c("div", { staticClass: "mx-auto img-wrapper" }, [
          _c("img", {
            attrs: {
              src: _vm.$options.filters.LAZY(
                `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                  _vm.getCurrentUser && _vm.getCurrentUser.photoId
                }`,
                160,
                "ic-lazy-avatar.png"
              ),
              onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-avatar.png"),
              "data-cy": "room-setting-user-profile-img",
              alt: "img",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c("div", { staticClass: "w-100 mb-3" }, [
            _c("p", {
              staticClass: "m-0 mb-1 text-title-2",
              domProps: { textContent: _vm._s(_vm.$t("chat.onlineStatus")) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "vac-custom-select input-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.cbUserInfo.isShowOnlineStatus.$model,
                      expression: "$v.cbUserInfo.isShowOnlineStatus.$model",
                    },
                  ],
                  staticClass: "font-size-14 font-weight-400 m-0",
                  class: `${
                    _vm.$v.cbUserInfo.isShowOnlineStatus.$anyDirty
                      ? _vm.$v.cbUserInfo.isShowOnlineStatus.$invalid
                        ? "invalid"
                        : "valid"
                      : ""
                  }`,
                  attrs: { name: "", "data-cy": "select-online-status" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.$v.cbUserInfo.isShowOnlineStatus,
                        "$model",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: true } }, [_vm._v("Show")]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: false } }, [
                    _vm._v("Hide"),
                  ]),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-100 mb-3" },
            [
              _c("p", {
                staticClass: "m-0 mb-1 text-title-2",
                domProps: { textContent: _vm._s(_vm.$t("chat.language")) },
              }),
              _vm._v(" "),
              _c("c-autocomplete-new", {
                ref: "cAutoCompleteRef",
                staticClass: "rounded cj-bg-white-primary",
                class: {
                  valid:
                    _vm.$v.cbUserInfo.langKey.$anyDirty &&
                    !_vm.$v.cbUserInfo.langKey.$invalid,
                  invalid:
                    _vm.$v.cbUserInfo.langKey.$anyDirty &&
                    _vm.$v.cbUserInfo.langKey.$invalid,
                },
                attrs: {
                  "data-cy": "wrap-language",
                  dataCyCustom: "language-select",
                  items: _vm.cbLovLanguages,
                  "item-text": `${
                    _vm.currentLanguage == "en" ? "value" : "value2"
                  }`,
                  "item-value": "key",
                  placeholder: _vm.$t("chat.selectLangugae"),
                },
                model: {
                  value: _vm.$v.cbUserInfo.langKey.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.cbUserInfo.langKey, "$model", $$v)
                  },
                  expression: "$v.cbUserInfo.langKey.$model",
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-catamaran font-size-10 font-weight-400 mt-2",
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("chat.updateLanguage")),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-weight-700" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.currentLanguage == "en"
                            ? _vm.myLanguage?.value
                            : _vm.myLanguage?.value2
                        ) +
                        "\n            "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-room-setting" },
            [
              _c(
                "c-button",
                {
                  attrs: { fullWidth: "", disabled: _vm.isUpdate },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.partialUpdateCbUserInfoByLogin.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _vm.isUpdate
                    ? _c("font-awesome-icon", {
                        attrs: { icon: "sync", spin: _vm.isUpdate },
                      })
                    : _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("chat.update")),
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }