var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-textarea-preview font-roboto cj-color-black-primary" },
    [
      _c(
        "div",
        {
          class: {
            "text-preview":
              _vm.countParagraphs(_vm.text) >= 5 && _vm.collapseText,
            actived: _vm.isCollapsed,
          },
          attrs: { "data-cy": "wrap-desc-note" },
        },
        _vm._l(_vm.linkifiedContents, function (content, i) {
          return _c(
            "div",
            {
              key: i,
              staticClass: "vac-format-container text-break-all",
              attrs: {
                "data-cy": _vm.dataCyCustom
                  ? `preview-group-${_vm.dataCyCustom}`
                  : "abouts",
              },
            },
            [
              _c(
                "span",
                {
                  class: {
                    "vac-text-bold": content.bold,
                    "vac-text-italic": content.italic,
                    "vac-text-strike": content.strike,
                    "vac-text-underline": content.underline,
                  },
                },
                [
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          "data-cy": _vm.dataCyCustom
                            ? `preview-${_vm.dataCyCustom}`
                            : "desc-note",
                        },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.TRUNCATE(
                              _vm.$options.filters.SECURITY_VHTML(
                                content.value
                              ),
                              1500,
                              _vm.isCollapsed
                            )
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(content.value) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                ],
                2
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      ((_vm.text && _vm.text.length > 1500) ||
        _vm.countParagraphs(_vm.text) >= 5) &&
      _vm.collapseText
        ? _c(
            "px",
            {
              staticClass:
                "font-catamaran text-nowrap text-left cj-btn-text-primary mt-2",
              on: {
                click: function ($event) {
                  _vm.isCollapsed = !_vm.isCollapsed
                },
              },
            },
            [
              !_vm.isCollapsed
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cTextareaPreview.showMore")
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isCollapsed
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cTextareaPreview.showLess")
                      ),
                    },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }