var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass: "c-item-chat-message-onmyway p-2 cj-bg-white-primary",
          attrs: { "data-cy": "item-chat-message-onmyway" },
        },
        [
          _c(
            "div",
            {
              ref: "cbVwPawOrderRequirement.pawTitle",
              staticClass: "text-center mb-2",
            },
            [
              _c(
                "p",
                {
                  staticClass: "c-icm-font-card-title text-ellipsis-oneline",
                  attrs: { "data-cy": "message-title-onmyway" },
                },
                [
                  _vm.cbVwPawOrderRequirement.buyerId === _vm.currentUser.id
                    ? _c("span", [_vm._v("Freelancer On The Way")])
                    : _c(
                        "span",
                        { attrs: { "data-cy": "message-title-onmyway" } },
                        [_vm._v("On My Way")]
                      ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "c-icm-font-card-subtitle text-ellipsis-oneline",
                  attrs: { "data-cy": "message-title-and-cbor-onmyway" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                      " | " +
                      _vm._s(
                        _vm._f("capitalEachSpace")(
                          _vm.cbVwPawOrderRequirement.pawTitle
                        )
                      ) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["cbVwPawOrderRequirement.pawTitle"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm.cbVwPawOrderRequirement.buyerId === _vm.currentUser.id
                        ? _c("span", [_vm._v("Freelancer On The Way")])
                        : _c("span", [_vm._v("On The Way")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("capitalEachSpace")(
                              _vm.cbVwPawOrderRequirement.pawTitle
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "getBookDate",
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
            },
            [
              _c("p", { staticClass: "c-icm-font-title" }, [_vm._v("Date")]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-weight-400 font-size-10 text-left",
                  attrs: { "data-cy": "message-book-date-onmyway" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$options.filters.convertDateToAccordanceTimeZone(
                          _vm.cbVwPawOrderRequirement.bookStartDate,
                          _vm.cbVwPawOrderRequirement.timeZone
                        )
                      ) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["getBookDate"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v("Date")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$options.filters.convertDateToAccordanceTimeZone(
                              _vm.cbVwPawOrderRequirement.bookStartDate,
                              _vm.cbVwPawOrderRequirement.timeZone
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 location-height cj-border-color-red-primary",
            },
            [
              _c("p", { staticClass: "c-icm-font-title" }, [
                _vm._v("Location"),
              ]),
              _vm._v(" "),
              _vm.cbLocation && _vm.cbLocation.address
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "c-icm-font-content mb-1",
                        attrs: { "data-cy": "message-address-onmyway" },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.cbLocation.address) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "cj-btn-text-primary c-icm-font-text-btn",
                      attrs: { "data-cy": "message-btn-open-map" },
                      domProps: {
                        textContent: _vm._s(_vm.$t("cbGlobal.viewInMap")),
                      },
                      on: { click: _vm.handleOpenLocation },
                    }),
                  ])
                : _c("div", [
                    _c("p", { staticClass: "c-icm-font-content" }, [
                      _vm._v("No Data"),
                    ]),
                  ]),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["cbLocation.address"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v("Location")]
                  ),
                  _vm._v(" "),
                  _vm.cbLocation && _vm.cbLocation.address
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.cbLocation.address) +
                              "\n      "
                          ),
                        ]
                      )
                    : _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [_vm._v("No Data")]
                      ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass: "mt-1 c-icm-font-btn",
              attrs: { "data-cy": "message-btn-direction", fullWidth: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleOpenLocation.apply(null, arguments)
                },
              },
            },
            [
              _vm.cbVwPawOrderRequirement.buyerId == _vm.currentUser.id
                ? _c("span", [_vm._v("DIRECTION")])
                : _c("span", [_vm._v("TRACK")]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }