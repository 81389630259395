export enum CbUserDataType {
  ID_CARD = 'ID_CARD',
  ID_CARD_SELFIE = 'ID_CARD_SELFIE',

  LICENCE = 'LICENCE',

  DIPLOMA = 'DIPLOMA',

  SKCK = 'SKCK',
  NPWP = 'NPWP',
  PHOTO_PROFILE = 'PHOTO_PROFILE',
}
