import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbGender } from './enumerations/cb-gender.model';

export interface ICbVwAuctionProjectPreOffer {
  id?: number;
  senderDisplayName?: string | null;
  senderId?: string | null;
  senderRating?: number | null;
  senderLvl?: string | null;
  senderPhotoId?: number | null;
  senderCity?: string | null;
  senderGender?: CbGender | null;
  pitch?: string | null;
  deliveryTime?: number | null;
  deliveryTimeType?: string | null;
  revisionCount?: number | null;
  priceType?: CbPriceType | null;
  currency?: CbCurrency | null;
  budget?: number | null;
  status?: CbCommonStatus | null;
  auctionStatus?: CbCommonStatus | null;
  acceptedBy?: string | null;
  acceptedDate?: Date | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbAuctionProjectId?: number | null;
  svcType?: string | null;
  title?: string | null;
  description?: string | null;
  catName?: string | null;
  svcName?: string | null;
  authorUserId?: string | null;
  authorRating?: string | null;
  authorLvl?: string | null;
  authorPhotoId?: number | null;
  authorCity?: string | null;
  authorDisplayName?: string | null;
  authorSignature?: string | null;
  auctionCreatedDateLabel?: string | null;
  auctionDueDate?: Date | null;
  createdLabel?: string | null;
  bookStartDate?: Date | null;
  bookEndDate?: Date | null;
  timeZone?: string | null;
  fromWorkLocation?: string | null;
  toWorkLocation?: string | null;
  locationSenderSearch?: string | null;
  isProjectGuarantee?: boolean | null;
}

export class CbVwAuctionProjectPreOffer implements ICbVwAuctionProjectPreOffer {
  constructor(
    public id?: number,
    public senderDisplayName?: string | null,
    public senderId?: string | null,
    public senderRating?: number | null,
    public senderLvl?: string | null,
    public senderPhotoId?: number | null,
    public senderCity?: string | null,
    public pitch?: string | null,
    public deliveryTime?: number | null,
    public deliveryTimeType?: string | null,
    public revisionCount?: number | null,
    public priceType?: CbPriceType | null,
    public currency?: CbCurrency | null,
    public budget?: number | null,
    public status?: CbCommonStatus | null,
    public auctionStatus?: CbCommonStatus | null,
    public acceptedBy?: string | null,
    public acceptedDate?: Date | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbAuctionProjectId?: number | null,
    public svcType?: string | null,
    public title?: string | null,
    public description?: string | null,
    public catName?: string | null,
    public svcName?: string | null,
    public authorUserId?: string | null,
    public authorRating?: string | null,
    public authorLvl?: string | null,
    public authorPhotoId?: number | null,
    public authorCity?: string | null,
    public authorDisplayName?: string | null,
    public authorSignature?: string | null,
    public auctionCreatedDateLabel?: string | null,
    public auctionDueDate?: Date | null,
    public createdLabel?: string | null,

    public bookStartDate?: Date | null,
    public bookEndDate?: Date | null,
    public timeZone?: string | null,
    public fromWorkLocation?: string | null,
    public toWorkLocation?: string | null,
    public locationSenderSearch?: string | null,
    public isProjectGuarantee?: boolean | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
