import { Module } from 'vuex';

export const translationStore: Module<any, any> = {
  state: {
    currentLanguage: localStorage.getItem('currentLanguage') || 'id',
    languages: {
      en: { name: 'English' },
      id: { name: 'Bahasa Indonesia' },
      // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    },
    i18nData: null,
  },
  getters: {
    currentLanguage: state => state.currentLanguage,
    languages: state => state.languages,
    i18nData: state => state.i18nData,
  },
  mutations: {
    currentLanguage(state, newLanguage) {
      state.currentLanguage = newLanguage;
      localStorage.setItem('currentLanguage', newLanguage);
    },
    i18nData(state, i18nData) {
      state.i18nData = i18nData;
    },
  },
};
