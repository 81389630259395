var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-progress-milestone",
      attrs: { "data-cy": "c-progress-milestone" },
    },
    [
      _c("ul", [
        _c(
          "li",
          [
            _c(
              "c-item-progress-milestone",
              {
                attrs: {
                  type: _vm.type,
                  "vl-custom-class":
                    _vm.isEdit && _vm.milestoneValidation
                      ? "c-item-progress-0"
                      : "c-item-progress-0-time-vl",
                  lastItem: false,
                  firstItem: true,
                  progress: 0,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "my-auto d-flex justify-content-between w-100",
                    class: { "ml-lg-3": _vm.isEdit },
                  },
                  [
                    _c("div", { staticClass: "my-auto text-left" }, [
                      _c("p", {
                        staticClass:
                          "font-roboto cj-color-black-primary custom-overflow-scroll text-ellipsis oneline",
                        class: {
                          "font-size-10 font-weight-700":
                            _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
                            _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
                            _vm.type === _vm.TypeComponent.CHAT_MODAL,
                          "font-size-14 font-weight-500":
                            !_vm.type.includes("CHAT"),
                        },
                        attrs: { "data-cy": "milestone-project-started-head" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cProgressMilestone.start"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _vm.milestoneMinDate
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-left font-roboto cj-color-black-primary font-weight-400 font-size-12",
                              attrs: { "data-cy": "milestone-project-started" },
                            },
                            [
                              _vm._v(
                                "\n              Est. " +
                                  _vm._s(
                                    _vm._f("formatDateTimeDefault")(
                                      _vm.milestoneMinDate
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cbVwPawOrder?.xDealDate ||
                      _vm.cbPawOrderMilestone?.xDealDate
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-left font-roboto cj-color-black-primary font-weight-400",
                              class: {
                                "font-size-8":
                                  _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
                                  _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
                                  _vm.type === _vm.TypeComponent.CHAT_MODAL,
                                "font-size-12": !_vm.type.includes("CHAT"),
                              },
                              attrs: { "data-cy": "milestone-project-started" },
                            },
                            [
                              _vm._v(
                                "\n              Est. " +
                                  _vm._s(
                                    _vm._f("formatDateTimeDefault")(
                                      _vm.cbVwPawOrder?.xDealDate ||
                                        _vm.cbPawOrderMilestone?.xDealDate
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.showProgress &&
                    (_vm.cbVwPawOrder.isHasMilestone ||
                      !_vm.cbVwPawOrder.isHasMilestone)
                      ? _c("icon", {
                          staticClass: "cj-fill-red-primary",
                          attrs: {
                            "data-cy": "milestone-icon-success-start",
                            name: "ic_check_circle",
                            width: "1.55rem",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "c-item-progress-milestone",
              {
                class: {
                  "c-item-progress-50": _vm.isEdit && _vm.milestoneValidation,
                  "c-item-progress-50-time-en":
                    _vm.isEdit &&
                    !_vm.milestoneValidation &&
                    _vm.currentLanguage === "en",
                  "c-item-progress-50-time-id":
                    _vm.isEdit &&
                    !_vm.milestoneValidation &&
                    _vm.currentLanguage === "id",
                },
                attrs: {
                  type: _vm.type,
                  "vl-custom-class":
                    _vm.isEdit &&
                    !_vm.milestoneValidation &&
                    "c-item-progress-50-time-vl",
                  lastItem: false,
                  firstItem: false,
                  progress: 50,
                },
              },
              [
                _vm.isEdit
                  ? _c(
                      "div",
                      {
                        staticClass: "position-relative",
                        class: { "ml-lg-3": _vm.isEdit },
                      },
                      [
                        _c("c-input", {
                          staticClass:
                            "my-auto rounded cj-field-border-default cj-field-input",
                          class: {
                            invalid:
                              _vm.$v.cbPawOrderMilestone.name.$anyDirty &&
                              _vm.$v.cbPawOrderMilestone.name.$invalid,
                            valid:
                              _vm.$v.cbPawOrderMilestone.name.$anyDirty &&
                              !_vm.$v.cbPawOrderMilestone.name.$invalid,
                          },
                          attrs: {
                            type: "text",
                            "data-cy": "milestone-input-wrap",
                            dataCyCustom: "milestone",
                            placeholder: _vm.$t(
                              "cbgwApp.cbComponent.cProgressMilestone.placeholderMilestoneName"
                            ),
                            useValidChar: true,
                            maxlength: 50,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$emit("input", [
                                _vm.$v.cbPawOrderMilestone.$model,
                              ])
                            },
                          },
                          model: {
                            value: _vm.$v.cbPawOrderMilestone.name.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.cbPawOrderMilestone.name,
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.cbPawOrderMilestone.name.$model",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", {
                          staticClass:
                            "text-left font-catamaran cj-color-black-primary font-weight-300 font-size-10 my-2",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbComponent.cProgressMilestone.titleMilestone"
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _vm.milestoneValidation
                          ? _c(
                              "div",
                              {
                                staticClass: "rounded cj-field-border-default",
                                class: {
                                  invalid:
                                    _vm.$v.cbPawOrderMilestone.completedDate
                                      .$anyDirty &&
                                    _vm.$v.cbPawOrderMilestone.completedDate
                                      .$invalid,
                                  valid:
                                    _vm.$v.cbPawOrderMilestone.completedDate
                                      .$anyDirty &&
                                    !_vm.$v.cbPawOrderMilestone.completedDate
                                      .$invalid,
                                },
                                attrs: {
                                  "data-cy": "milestone-select-wrap-date",
                                },
                              },
                              [
                                _c("v-date-picker", {
                                  staticClass: "cj-date-picker",
                                  attrs: {
                                    mode: "date",
                                    is24hr: "",
                                    "min-date": _vm.milestoneMinDate,
                                    "max-date": _vm.milestoneMaxDate,
                                    masks: _vm.masks,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$emit("input", [
                                        _vm.$v.cbPawOrderMilestone.$model,
                                      ])
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({
                                          inputValue,
                                          inputEvents,
                                        }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c("icon", {
                                                  staticClass:
                                                    "cj-fill-grey-secondary dr-icon-wrapper ml-custom",
                                                  attrs: {
                                                    name: "ic_calendar",
                                                    width: "30px",
                                                    height: "30px",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "input",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "custom-date-picker font-roboto font-weight-400 font-size-input cj-color-black-primary rounded cj-field-not-border w-100 pl-2",
                                                      style: {
                                                        height: _vm.isMobile
                                                          ? "2.2rem"
                                                          : "2.6rem",
                                                      },
                                                      attrs: {
                                                        "data-cy":
                                                          "milestone-select-date",
                                                        placeholder: _vm.$t(
                                                          "cbgwApp.cbComponent.cAddExtra.selectDate"
                                                        ),
                                                      },
                                                      domProps: {
                                                        value: inputValue,
                                                      },
                                                    },
                                                    inputEvents
                                                  )
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2125783744
                                  ),
                                  model: {
                                    value:
                                      _vm.$v.cbPawOrderMilestone.completedDate
                                        .$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.cbPawOrderMilestone
                                          .completedDate,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.cbPawOrderMilestone.completedDate.$model",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "rounded",
                                attrs: {
                                  "data-cy": "wrap-select-data-milestone",
                                },
                              },
                              [
                                _c("c-timepicker", {
                                  staticClass:
                                    "rounded cj-field-input w-100 font-roboto font-weight-400 font-size-18 cj-color-black-primary",
                                  class: `${
                                    _vm.$v.cbPawOrderMilestone.time.$anyDirty
                                      ? !_vm.validateDueTime
                                        ? "invalid"
                                        : "valid"
                                      : "cj-field-border-default"
                                  }`,
                                  attrs: {
                                    dataCyCustom: "milestone-select-time",
                                    isFormat24: true,
                                    isValidHourOption: true,
                                    placeholder: "00:00",
                                    timeHeight: _vm.isMobile
                                      ? "2.2rem"
                                      : "2.6rem",
                                    type: "text",
                                  },
                                  on: { time: _vm.handleTime },
                                  model: {
                                    value:
                                      _vm.$v.cbPawOrderMilestone.time.$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.cbPawOrderMilestone.time,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "$v.cbPawOrderMilestone.time.$model",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-left font-catamaran cj-color-black-primary font-weight-300 font-size-10 my-2",
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "cbgwApp.cbComponent.cProgressMilestone.infoSetTime15Milestone"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateTimeDefault")(
                                            _vm.milestoneMinDate
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "cbgwApp.cbComponent.cProgressMilestone.infoSetExceedTime15Milestone"
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "my-auto d-flex w-100" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my-auto text-left",
                          class: {
                            "max-width-custom":
                              _vm.type === _vm.TypeComponent.CHAT_MODAL,
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary custom-overflow-scroll text-ellipsis oneline",
                              class: {
                                "font-size-10 font-weight-700":
                                  _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
                                  _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
                                  _vm.type === _vm.TypeComponent.CHAT_MODAL,
                                "font-size-14 font-weight-500":
                                  !_vm.type.includes("CHAT"),
                              },
                              attrs: { "data-cy": "milestone-name" },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$v.cbPawOrderMilestone.name.$model ||
                                      _vm.cbPawOrderMilestone?.title
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-400",
                              class: {
                                "font-size-8":
                                  _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
                                  _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
                                  _vm.type === _vm.TypeComponent.CHAT_MODAL,
                                "font-size-12": !_vm.type.includes("CHAT"),
                              },
                            },
                            [
                              _vm.cbPawOrderMilestone.completedDate
                                ? _c("span", [
                                    _vm._v(
                                      " Est. " +
                                        _vm._s(
                                          _vm._f("formatDateTimeDefault")(
                                            _vm.cbPawOrderMilestone
                                              .completedDate
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.cbPawOrderMilestone.completedDateMilestone
                                ? _c("span", [
                                    _vm._v(
                                      "\n                Est. " +
                                        _vm._s(
                                          _vm._f("formatDateTimeDefault")(
                                            _vm.cbPawOrderMilestone
                                              .completedDateMilestone
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$v.cbPawOrderMilestone.status.$model
                        ? _c(
                            "div",
                            { staticClass: "ml-auto my-auto" },
                            [
                              _vm.getMilestoneProgress
                                ? _c(
                                    "c-button",
                                    {
                                      staticClass:
                                        "font-size-12 font-weight-500",
                                      attrs: {
                                        "data-cy":
                                          _vm.showAction ===
                                            _vm.CbLoginType.PARTNER &&
                                          !_vm.cbVwPawOrder.isHasMilestone
                                            ? "btn-milestone-progress-updated"
                                            : "btn-milestone-progress-view",
                                        disabled:
                                          _vm.getMilestoneButtonUpdateCondition,
                                        "type-style": "secondary",
                                        typeStyle: "secondary",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          !_vm.getMilestoneButtonUpdateCondition &&
                                            _vm.$emit(
                                              "update",
                                              _vm.$v.cbPawOrderMilestone.$model
                                            )
                                        },
                                      },
                                    },
                                    [
                                      _vm.showAction ===
                                        _vm.CbLoginType.PARTNER &&
                                      !_vm.cbVwPawOrder.isHasMilestone
                                        ? _c("span", [_vm._v(" UPDATE ")])
                                        : _c("span", [_vm._v(" VIEW ")]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isHasMilestone
                                ? _c("icon", {
                                    staticClass: "cj-fill-red-primary",
                                    attrs: {
                                      name: "ic_check_circle",
                                      "data-cy":
                                        "milestone-icon-success-progress",
                                      width: "1.55rem",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "c-item-progress-milestone",
              {
                attrs: {
                  type: _vm.type,
                  lastItem: true,
                  firstItem: false,
                  progress: 100,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "my-auto d-flex w-100",
                    class: { "ml-lg-3": _vm.isEdit },
                  },
                  [
                    _c("div", { staticClass: "my-auto text-left" }, [
                      _c("p", {
                        staticClass:
                          "font-roboto cj-color-black-primary custom-overflow-scroll text-ellipsis oneline",
                        class: {
                          "font-size-10 font-weight-700":
                            _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
                            _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
                            _vm.type === _vm.TypeComponent.CHAT_MODAL,
                          "font-size-14 font-weight-500":
                            !_vm.type.includes("CHAT"),
                        },
                        attrs: { "data-cy": "milestone-project-finish-head" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cProgressMilestone.finish"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _vm.milestoneMaxDate
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-left font-roboto cj-color-black-primary font-weight-400 font-size-12",
                              attrs: {
                                "data-cy": "milestone-project-finished",
                              },
                            },
                            [
                              _vm._v(
                                "\n              Est. " +
                                  _vm._s(
                                    _vm._f("formatDateTimeDefault")(
                                      _vm.milestoneMaxDate
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cbVwPawOrder?.xExpiredDate ||
                      _vm.cbPawOrderMilestone?.xExpiredDate
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "text-left font-roboto cj-color-black-primary font-weight-400",
                              class: {
                                "font-size-8":
                                  _vm.type === _vm.TypeComponent.CHAT_WIDGET ||
                                  _vm.type === _vm.TypeComponent.CHAT_BUBBLE ||
                                  _vm.type === _vm.TypeComponent.CHAT_MODAL,
                                "font-size-12": !_vm.type.includes("CHAT"),
                              },
                              attrs: {
                                "data-cy": "milestone-project-finished",
                              },
                            },
                            [
                              _vm._v(
                                "\n              Est. " +
                                  _vm._s(
                                    _vm._f("formatDateTimeDefault")(
                                      _vm.cbVwPawOrder?.xExpiredDate ||
                                        _vm.cbPawOrderMilestone?.xExpiredDate
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }