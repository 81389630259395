import { CbSvcCat } from '@/shared/model/cb-svc-cat.model';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class CItemSvcCat extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbSvcCat: CbSvcCat;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public toSvcCategory() {
    this.$router.push({
      name: 'PSvcCategory',
      params: {
        svcCatId: (this.cbSvcCat && this.cbSvcCat.id.toString()) ?? '',
      },
    });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
