var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-login-introduction mt-2",
      attrs: { "data-cy": "modal-introduction" },
    },
    [
      _c("p", {
        staticClass:
          "text-center font-roboto font-weight-700 cj-color-black-primary font-size-24 mb-1",
        domProps: {
          textContent: _vm._s(
            _vm.$t(
              "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.title"
            )
          ),
        },
      }),
      _vm._v(" "),
      _c("p", {
        staticClass:
          "font-catamaran font-size-18 font-weight-300 text-center cj-color-black-primary mb-3",
        domProps: {
          textContent: _vm._s(
            _vm.$t(
              "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.subTitle"
            )
          ),
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg-6 col-xl-6 mb-3" }, [
          _c(
            "div",
            {
              staticClass:
                "border-radius-card wrap-border-grey-secondary py-4 px-3",
              class:
                _vm.selectLoginType === _vm.CbLoginType.BUYER ? "active" : "",
              attrs: {
                "data-cy": "login-type-buyer",
                "data-gtm": "login-type-buyer-button",
              },
              on: {
                click: function ($event) {
                  _vm.selectLoginType = _vm.CbLoginType.BUYER
                  _vm.isCollapse = true
                },
              },
            },
            [
              _c("p", {
                staticClass:
                  "font-size-18 type-title font-roboto font-weight-700 text-center pt-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.buyer"
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-size-12 type-subtitle font-weight-300 text-center font-catamaran pb-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.postJobs"
                    )
                  ),
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg-6 col-xl-6 mb-3" }, [
          _c(
            "div",
            {
              staticClass:
                "border-radius-card wrap-border-grey-secondary py-4 px-3",
              class:
                _vm.selectLoginType === _vm.CbLoginType.PARTNER ? "active" : "",
              attrs: {
                "data-cy": "login-type-freelancer",
                "data-gtm": "login-type-freelancer-button",
              },
              on: {
                click: function ($event) {
                  _vm.selectLoginType = _vm.CbLoginType.PARTNER
                  _vm.isCollapse = true
                },
              },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-size-18 type-title font-roboto font-weight-700 text-center pt-2",
                },
                [_vm._v("FREELANCER")]
              ),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-size-12 type-subtitle font-weight-300 text-center font-catamaran pb-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.startEarning"
                    )
                  ),
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          model: {
            value: _vm.isCollapse,
            callback: function ($$v) {
              _vm.isCollapse = $$v
            },
            expression: "isCollapse",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectLoginType === _vm.CbLoginType.BUYER,
                  expression: "selectLoginType === CbLoginType.BUYER",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass: "mx-auto",
                  staticStyle: { "max-width": "17rem" },
                },
                [
                  _c("div", { staticClass: "cb-img-wrapper cb-ratio-1x1" }, [
                    _c("img", {
                      staticClass: "img-project-aution",
                      attrs: {
                        src: `${_vm.$env.CDN_CB}/content/cb-img/ic-post-project-aution.png`,
                        alt: "post-project-aution",
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-size-16 cj-color-black-primary font-catamaran font-weight-300 text-center mt-3",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.descBuyer"
                    )
                  ),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectLoginType === _vm.CbLoginType.PARTNER,
                  expression: "selectLoginType === CbLoginType.PARTNER",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass: "mx-auto",
                  staticStyle: { "max-width": "17rem" },
                },
                [
                  _c("div", { staticClass: "cb-img-wrapper cb-ratio-1x1" }, [
                    _c("img", {
                      attrs: {
                        src: `${_vm.$env.CDN_CB}/content/cb-img/ic-inside-worker.png`,
                        alt: "img",
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "font-size-16 cj-color-black-primary font-catamaran font-weight-300 text-center mt-3",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.descFreelancer"
                    )
                  ),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("c-button", {
            staticClass: "font-roboto text-center mt-4",
            attrs: {
              "data-cy": "continue-introduction",
              dataGTMCustom: "continue-introduction",
              fullWidth: "",
            },
            domProps: {
              textContent: _vm._s(
                _vm.$t(
                  "cbgwApp.cbHome.cbHomeBeforeLogin.introductionAfterRegister.continue"
                )
              ),
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleLogin.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }