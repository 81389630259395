var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "app", attrs: { id: "app" } },
        [
          _vm.ribbonEnabled && _vm.numberOfAjaxCAllPending > 0
            ? _c("div", { staticClass: "cb-all-pending progress" }, [
                _c("div", {
                  staticClass:
                    "progress-bar progress-bar-striped cj-bg-red-primary progress-bar-animated",
                  staticStyle: { width: "100%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "100",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100",
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowNavbar,
                  expression: "isShowNavbar",
                },
              ],
              staticClass: "d-none d-sm-none d-md-none d-lg-block d-xl-block",
            },
            [_c("c-navbar")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { class: _vm.isShow100Height ? "body-100-wrapper" : "" },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _vm.isShowFooterBottomSheet && !_vm.isMobile
            ? _c("c-footer-bottom-sheet", {
                attrs: { isShowTabFooter: _vm.isShowTabFooter },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowFooter
            ? _c("c-footer", {
                attrs: { isShowTabFooter: _vm.isShowTabFooter },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowFooterCopyRight
            ? _c("c-footer-copyright", {
                attrs: { isShowTabFooter: _vm.isShowTabFooter },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowFloatingLiveChat,
                  expression: "isShowFloatingLiveChat",
                },
              ],
              staticClass: "floating-live-chat",
            },
            [
              _c(
                "b-collapse",
                {
                  staticClass: "p-3 border-radius-card",
                  model: {
                    value: _vm.visible,
                    callback: function ($$v) {
                      _vm.visible = $$v
                    },
                    expression: "visible",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "live-chat-collapse" },
                    [
                      _vm.visible
                        ? _c("live-chat", { on: { callBack: _vm.isVisible } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              !_vm.visible
                ? _c(
                    "button",
                    {
                      staticClass: "ml-auto btn-float",
                      attrs: {
                        type: "button",
                        "data-cy": "floating-live-chat-button",
                      },
                      on: { click: _vm.isVisible },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: `${_vm.$env.CDN_CB}/content/cb-img/ic-fab.png`,
                          alt: "Image Fab",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.cbCountCookies > 0
            ? _c(
                "div",
                { staticClass: "app-cookies" },
                [
                  _c("c-card-cookies", {
                    on: {
                      accAllCookies: _vm.showCardCookies,
                      openCookiesSetting: _vm.openCookiesSetting,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("c-app-modals", { attrs: { cbVwPawOrder: _vm.cbVwPawOrder } }),
          _vm._v(" "),
          _c("div", {
            ref: "appOverlay",
            staticClass: "app-overlay",
            on: { mouseenter: _vm.handleMouseLeave },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "navbar-mobile d-block d-lg-none" },
            [
              _c("c-navbar-mobile", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.navbarData.isShow,
                    expression: "navbarData.isShow",
                  },
                ],
                attrs: {
                  navbar: _vm.navbarData,
                  isDoubleCallBack: _vm.isDoubleCallBack,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isMobile && _vm.isShowTabFooter ? _c("c-tab-footer") : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-c-share",
            "hide-footer": "",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex w-100 position-relative",
                      staticStyle: { "z-index": "1" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "position-absolute p-3 m-n3",
                          staticStyle: { right: "0" },
                          attrs: { type: "button" },
                          on: { click: cancel },
                        },
                        [
                          _c("b-icon-x-circle-fill", {
                            staticClass: "font-size-16 cj-color-grey-tertiary",
                            attrs: { "data-cy": "close-modal" },
                            on: { cancel: _vm.closeAllDialog },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("c-share", {
            staticStyle: { "max-width": "40rem" },
            attrs: { shareData: _vm.shareData },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "dialog-class": "custom-modal-dialog",
            id: "modal-c-cookies-setting",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [[_c("c-cookies-setting", { on: { cancel: _vm.closeAllDialog } })]],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            id: "modal-c-input-pin-old",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center w-100 position-relative",
              },
              [
                _c("div", [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto text-center font-weight-700 cj-color-black-primary",
                      class: _vm.isMobile ? "font-size-20" : "font-size-24",
                    },
                    [_vm._v("\n            Edit Pin\n          ")]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "p-0 m-0 font-roboto cj-color-black-primary text-italic font-weight-500 mt-2 text-center",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cPin.oldPin")
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "position-absolute p-3 m-n3",
                    staticStyle: { right: "0" },
                    attrs: { type: "button" },
                    on: { click: _vm.closeAllDialogRegisterPin },
                  },
                  [
                    _c("b-icon-x-circle-fill", {
                      staticClass: "font-size-18 cj-color-grey-secondary",
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          _vm._v(" "),
          _c("c-input-pin", {
            attrs: {
              isShowForgotPin: _vm.isShowForgotPin,
              pinOldNotMatched: _vm.pinOldNotMatched,
            },
            on: {
              submitResetPin: _vm.submitSetPin,
              cancel: _vm.closeAllDialog,
              save: _vm.submitOldPin,
            },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "app-modal-set-new-pin",
            centered: "",
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          model: {
            value: _vm.visibleSetNewPin,
            callback: function ($$v) {
              _vm.visibleSetNewPin = $$v
            },
            expression: "visibleSetNewPin",
          },
        },
        [
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center w-100 position-relative",
              },
              [
                _c("div", [
                  _c("p", {
                    staticClass:
                      "font-roboto text-center font-weight-700 cj-color-black-primary",
                    class: _vm.isMobile ? "font-size-20" : "font-size-24",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cPin.setNewPin")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "p-0 m-0 font-roboto cj-color-black-primary text-italic font-weight-500 mt-2 text-center",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cPin.numberCombination")
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "position-absolute p-3 m-n3",
                    staticStyle: { right: "0" },
                    attrs: { type: "button" },
                    on: { click: _vm.closeAllDialogRegisterPin },
                  },
                  [
                    _c("b-icon-x-circle-fill", {
                      staticClass: "font-size-18 cj-color-grey-secondary",
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          _vm._v(" "),
          _c("c-input-pin", {
            on: { cancel: _vm.closeAllDialog, save: _vm.saveNewPin },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            id: "modal-c-enter-pin",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-center w-100 position-relative",
              },
              [
                _c("div", [
                  _c("p", {
                    staticClass:
                      "font-roboto text-center font-weight-700 cj-color-black-primary",
                    class: _vm.isMobile ? "font-size-20" : "font-size-24",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cPin.setNewPin")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "p-0 m-0 font-roboto cj-color-black-primary text-italic font-weight-500 mt-2 text-center",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cPin.reEnterPin")
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "position-absolute p-3 m-n3",
                    staticStyle: { right: "0" },
                    attrs: { type: "button" },
                    on: { click: _vm.closeAllDialogRegisterPin },
                  },
                  [
                    _c("b-icon-x-circle-fill", {
                      staticClass: "font-size-18 cj-color-grey-secondary",
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          _vm._v(" "),
          _c("c-input-pin", {
            attrs: { pinNotMatched: _vm.wrongOtpCode },
            on: { cancel: _vm.closeAllDialog, save: _vm.submitPin },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-info-set-pin",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    dataCyCustom: "set-pin-confirmation-modal",
                    title: _vm.$t("cbgwApp.cbUserPin.setPin"),
                    subTitle: _vm.$t("cbgwApp.cbUserPin.descSetPin"),
                    submitTitle: _vm.$t("cbgwApp.cbUserPin.continue"),
                    cancelTitle: _vm.$t("cbgwApp.cbUserPin.cancel"),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: cancel,
                    close: cancel,
                    save: function ($event) {
                      _vm.toSetPinPage()
                      cancel()
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "app-modal-pin-success-update",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t("cbgwApp.cbComponent.cPin.success"),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cPin.pinSuccessUpdate"
                    ),
                    iconCustom: "ic_check_circle",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleSuccessPin,
          callback: function ($$v) {
            _vm.visibleSuccessPin = $$v
          },
          expression: "visibleSuccessPin",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-c-success-review-helpdesk",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t(
                      "cbgwApp.cbComponent.successReviewHelpdesk.success"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.successReviewHelpdesk.content"
                    ),
                    iconCustom: "ic_check_circle",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            id: "modal-c-success-otp",
            "hide-footer": "",
            "hide-header": "",
          },
          on: { hide: _vm.submitSuccessOtp },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: "Success",
                hideClose: true,
                "data-cy": "modal-success-register",
                iconCustom: "ic_check_circle",
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-c-reset-pin",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    iconCustom: "ic_message",
                    iconWidth: "5.375rem",
                    subTitle:
                      "Link to reset your PIN has been sent to julianaindah@gmail.com Please check your email.",
                    submitTitle: "CONTINUE",
                  },
                  on: {
                    cancel: cancel,
                    close: cancel,
                    save: _vm.verificationPin,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "app-modal-confirmation-send-email-pin",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    iconCustom: "ic_message",
                    iconWidth: "5.375rem",
                    title: _vm.$t(
                      "cbgwApp.cbComponent.cConfirmationInfoSendEmailPin.title"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cConfirmationInfoSendEmailPin.subTitle",
                      {
                        email:
                          _vm.cbVerificationOtp && _vm.cbVerificationOtp.email
                            ? _vm.cbVerificationOtp.email
                            : _vm.cbVerificationOtp.detail &&
                              _vm.cbVerificationOtp.detail.email,
                      }
                    ),
                    submitTitle: _vm.$t(
                      "cbgwApp.cbComponent.cConfirmationInfoSendEmailPin.submitTitle"
                    ),
                  },
                  on: {
                    cancel: cancel,
                    close: cancel,
                    save: function ($event) {
                      _vm.visibleVerificationPin = true
                      cancel()
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleInfoPinEmailBeforeOtp,
          callback: function ($$v) {
            _vm.visibleInfoPinEmailBeforeOtp = $$v
          },
          expression: "visibleInfoPinEmailBeforeOtp",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-c-reset-password",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("p", {
                  staticClass:
                    "font-roboto cj-color-black-primary font-size-24 font-weight-700 text-center",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbComponent.cbResetPassword.resetPassword"
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("c-reset-password", {
                  staticClass: "w-100",
                  attrs: { "data-cy": "c-reset-password" },
                  on: { cancel: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      !_vm.isMobile
        ? _c(
            "b-modal",
            {
              attrs: {
                "content-class": "vac-custom-modal-content-b-modal",
                "dialog-class": "custom-modal-dialog",
                "modal-class": "vac-custom-modal-b-modal",
                size: "lg",
                id: "modal-c-login",
                "hide-footer": "",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function ({ cancel }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex w-100 justify-content-center position-relative",
                            attrs: { "data-cy": "c-modal-login" },
                          },
                          [
                            _c("p", {
                              staticClass:
                                "m-0 font-roboto font-weight-bold cj-color-black-primary font-size-24",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("cbgwApp.cbComponent.login.title")
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "position-absolute p-3 m-n3",
                                staticStyle: { right: "0" },
                                attrs: {
                                  "data-cy": "close-login",
                                  type: "button",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.$root.$emit(
                                      "registOne::isValidChangePage",
                                      false
                                    )
                                    cancel()
                                  },
                                },
                              },
                              [
                                _c("b-icon-x-circle-fill", {
                                  staticClass:
                                    "font-size-18 cj-color-grey-secondary",
                                  on: { cancel: _vm.closeAllDialog },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1873204023
              ),
            },
            [
              _vm._v(" "),
              _c("c-login", { attrs: { "data-cy": "c-modal-login" } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile
        ? _c("b-modal", {
            attrs: {
              "content-class": "vac-custom-modal-content-b-modal",
              "dialog-class": "vac-custom-modal-size-b-modal",
              "modal-class": "vac-custom-modal-b-modal",
              size: "lg",
              id: "modal-c-register",
              "hide-footer": "",
              "hide-header": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ cancel }) {
                    return [
                      _c("c-register", {
                        attrs: { "data-cy": "c-register" },
                        on: {
                          cancel: cancel,
                          openModalLogin: _vm.openModalLogin,
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              1547942809
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "data-cy": "modal-c-login-introduction",
            size: "lg",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-c-login-introduction",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
            "no-close-on-backdrop": true,
          },
        },
        [
          _c("c-login-introduction", {
            on: {
              cancel: _vm.closeDialogLoginIntroduction,
              changeFunction: _vm.changeFunction,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-edit-phone-number",
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          [
            _c("c-edit-phone-number", {
              attrs: {
                "data-cy": "modal-edit-phone-number",
                cbVerificationOtp: _vm.cbVerificationOtpPhone,
              },
              on: {
                save: _vm.handleSubmitEditVerification,
                cancel:
                  _vm.handleCloseEditPhoneNumberAndOpenOtpVerificationPhone,
                openModalLogin: _vm.openModalLogin,
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-c-success-reset-phone",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    iconCustom: "ic_message",
                    iconWidth: "5.375rem",
                    title: _vm.$t(
                      "cbgwApp.cbComponent.cbVerificationMethod.resetPhone"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cbVerificationMethod.descResetPhone"
                    ),
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-visible-verification-phone",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("div", { staticClass: "position-relative" }, [
                  _c("p", {
                    staticClass:
                      "font-roboto cj-color-black-primary font-weight-700 text-center",
                    class: _vm.isMobile ? "font-size-20" : "font-size-24",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.phoneVerification"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.visibleVerificationPhoneButtonCancel
                    ? _c(
                        "button",
                        {
                          staticClass: "position-absolute p-3 m-n3",
                          staticStyle: { right: "0", top: "0" },
                          attrs: { type: "button" },
                          on: { click: cancel },
                        },
                        [
                          _c("b-icon-x-circle-fill", {
                            staticClass: "font-size-18 cj-color-grey-secondary",
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr", {
                    staticStyle: {
                      "margin-left": "-1rem",
                      "margin-right": "-1rem",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("c-verification-phone", {
                  attrs: {
                    "data-cy": "c-verification-phone",
                    visibleCountdown: true,
                    visibleHeader: true,
                    visibleFooter: true,
                  },
                  on: {
                    submit: function ($event) {
                      return _vm.$root.$emit(
                        "handleSuccessRegister",
                        _vm.CbVerificationMethodType.PHONE
                      )
                    },
                  },
                  model: {
                    value: _vm.cbVerificationOtpPhone,
                    callback: function ($$v) {
                      _vm.cbVerificationOtpPhone = $$v
                    },
                    expression: "cbVerificationOtpPhone",
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleVerificationPhone,
          callback: function ($$v) {
            _vm.visibleVerificationPhone = $$v
          },
          expression: "visibleVerificationPhone",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-c-verification-email",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
          centered: "",
          "modal-class": "vac-custom-modal-b-modal",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("div", { staticClass: "position-relative" }, [
                  _c("p", {
                    staticClass:
                      "font-roboto cj-color-black-primary font-weight-700 text-center",
                    class: _vm.isMobile ? "font-size-20" : "font-size-24",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.emailVerification"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.visibleVerificationEmailButtonCancel
                    ? _c(
                        "button",
                        {
                          staticClass: "position-absolute p-3 m-n3",
                          staticStyle: { right: "0", top: "0" },
                          attrs: { type: "button" },
                          on: { click: cancel },
                        },
                        [
                          _c("b-icon-x-circle-fill", {
                            staticClass: "font-size-18 cj-color-grey-secondary",
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr", {
                    staticStyle: {
                      "margin-left": "-1rem",
                      "margin-right": "-1rem",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("c-verification-email", {
                  attrs: {
                    "data-cy": "c-verification-email",
                    visibleCountdown: true,
                    visibleHeader: true,
                    visibleFooter: true,
                  },
                  on: {
                    submit: function ($event) {
                      return _vm.$root.$emit(
                        "handleSuccessRegister",
                        _vm.CbVerificationMethodType.EMAIL
                      )
                    },
                  },
                  model: {
                    value: _vm.cbVerificationOtp,
                    callback: function ($$v) {
                      _vm.cbVerificationOtp = $$v
                    },
                    expression: "cbVerificationOtp",
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleVerificationEmail,
          callback: function ($$v) {
            _vm.visibleVerificationEmail = $$v
          },
          expression: "visibleVerificationEmail",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "data-cy": "modal-c-verification-pin",
          id: "modal-c-verification-pin",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
          centered: "",
          "modal-class": "vac-custom-modal-b-modal",
          "no-close-on-backdrop": "",
          "no-close-on-esc": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("div", { staticClass: "position-relative" }, [
                  _c("p", {
                    staticClass:
                      "font-roboto cj-color-black-primary font-weight-700 text-center",
                    class: _vm.isMobile ? "font-size-20" : "font-size-24",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.pinVerification"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "position-absolute p-3 m-n3",
                      staticStyle: { right: "0", top: "0" },
                      attrs: { type: "button" },
                      on: { click: cancel },
                    },
                    [
                      _c("b-icon-x-circle-fill", {
                        staticClass: "font-size-18 cj-color-grey-secondary",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr", {
                    staticStyle: {
                      "margin-left": "-1rem",
                      "margin-right": "-1rem",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("c-verification-pin", {
                  attrs: {
                    visibleCountdown: true,
                    visibleHeader: true,
                    visibleFooter: true,
                  },
                  on: {
                    submit: _vm.handleSetPinType,
                    valueOtp: function ($event) {
                      _vm.dataOtp = $event
                    },
                  },
                  model: {
                    value: _vm.cbVerificationOtp,
                    callback: function ($$v) {
                      _vm.cbVerificationOtp = $$v
                    },
                    expression: "cbVerificationOtp",
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleVerificationPin,
          callback: function ($$v) {
            _vm.visibleVerificationPin = $$v
          },
          expression: "visibleVerificationPin",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        staticClass: "modal-paw-detail",
        attrs: {
          id: "modal-paw-detail",
          "hide-footer": "",
          "hide-header": "",
          size: "xl",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "modal-class": "vac-custom-modal-b-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-paw-detail", {
                  attrs: { cbVwPawId: _vm.cbVwPaw.id },
                  on: { cancel: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "data-cy": "modal-personalize-interest",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-personalize-y-interests",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": true,
          },
        },
        [_c("c-personalize-y-interests")],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-billboard-detail",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [
          _c("c-billboard-detail", {
            attrs: { cbVwBillboard: _vm.cbVwBillboard },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          size: "lg",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "modal-class": "vac-custom-modal-b-modal",
          id: "modal-c-auction-project",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-form-auction-project-pitch", {
                  attrs: { cbVwAuctionProjectId: _vm.auctionProjectId },
                  on: { cancel: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-c-verification-email-new",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
        },
        [
          _c("c-verification-email-new", {
            attrs: {
              visibleCountdown: true,
              visibleHeader: true,
              visibleFooter: true,
            },
            on: {
              submit: function ($event) {
                return _vm.$root.$emit(
                  "handleSuccessRegister",
                  _vm.CbVerificationMethodType.EMAIL
                )
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            size: "lg",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.isShowAttachmentPreview,
            callback: function ($$v) {
              _vm.isShowAttachmentPreview = $$v
            },
            expression: "isShowAttachmentPreview",
          },
        },
        [
          _c("c-attachment-preview", {
            attrs: { attachments: _vm.attachmentPreviews },
            on: {
              cancel: function ($event) {
                _vm.isShowAttachmentPreview = false
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-first-choice",
            "content-class": "vac-custom-modal-content-full",
            "dialog-class": "vac-custom-modal-size-full",
            "modal-class": "vac-custom-modal-full",
            "no-close-on-backdrop": true,
            "hide-footer": "",
            "hide-header": "",
            static: "",
          },
        },
        [
          _c("div", { staticClass: "first-choice-wrapper" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.envSecret,
                  expression: "envSecret",
                },
              ],
              staticClass: "form-control cj-field-input",
              attrs: { type: "text", "data-cy": "env-secret" },
              domProps: { value: _vm.envSecret },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.envSecret = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex mt-3" },
              [
                _c(
                  "c-button",
                  {
                    staticClass: "mx-2",
                    attrs: { "data-cy": "env-secret-btn" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeModalFirstChoice.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("btn 1")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-c-order-project-delivery",
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [_c("c-order-project-delivery")],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "content-class": "vac-custom-modal-content-tranparant",
            id: "modal-c-loader",
            "hide-footer": "",
            "hide-header": "",
          },
          on: { hide: _vm.handleCancelTokenLoader },
        },
        [
          _c("c-loader", {
            staticClass: "d-flex m-auto c-loader-red",
            attrs: { show: true, infinite: true },
            nativeOn: {
              click: function ($event) {
                return _vm.handleCancelTokenLoader.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-c-media-preview",
            size: "lg",
            "hide-footer": "",
            "hide-header": "",
            "content-class": "vac-custom-modal-media-preview-content-b-modal",
            "dialog-class": "vac-custom-modal-media-preview-size-b-modal",
            "modal-class": "vac-custom-modal-media-preview-b-modal",
          },
        },
        [
          _c("c-media-preview", {
            attrs: { fileMediaPreview: _vm.fileMediaPreview },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            centered: "",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-c-change-language",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "position-relative d-flex justify-content-center w-100 position-relative",
            },
            [
              _c("p", {
                staticClass:
                  "font-roboto cj-color-black-primary font-weight-700",
                class: _vm.isMobile ? "font-size-18" : "font-size-24",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cbNavbar.language")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "position-absolute p-3 m-n3",
                  staticStyle: { right: "0", top: "0.438rem" },
                  attrs: { type: "button" },
                  on: { click: _vm.closeLanguages },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("c-languages"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-toast",
        {
          ref: "toastProgressUpload",
          attrs: {
            "body-class": "cj-bg-white-secondary border-radius-26",
            "header-class": "d-none",
            solid: "",
            "no-auto-hide": "",
          },
        },
        [
          _c("c-progress-upload", {
            attrs: { progressUploads: _vm.progressUploads },
            on: { closeProgressUploadMini: _vm.closeProgressUploadMini },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }