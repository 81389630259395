import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import dayjs from 'dayjs';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { TIME_24_FORMAT } from '@/shared/date/filters';

@Component({ components: { Icon } })
export default class CItemTimeRegion extends Vue {
  @Prop({ default: false }) public isShowOpHour;
  @Prop({ default: false }) isThirdClock: boolean;
  @Prop({ default: '20rem' }) width: string;
  @Prop({ default: null }) public time: Date;
  @Prop({ default: null }) public timeZoneId: number;
  @Prop({ default: null }) public opHourStart;
  @Prop({ default: null }) public opHourEnd;
  @Prop({ default: 30 }) public lineCircle;

  public now: Date = dayjs().toDate();

  // public testDate = new Date('May 19, 2000 03:39:52');
  // public testDate1 = new Date('May 19, 2000 09:51:52');

  public dateTime = '00:00';
  public dateFormat = '';
  public opHourStartNew = 0;
  public opHourEndNew = 0;
  public isDaylight = false;

  public interval: any = null;

  public clearPath = false;

  public mounted() {
    this.interval = setInterval(this.setDate, 1000);
    if (this.opHourStart) {
      this.onOpHourStartChanged(this.opHourStart);
    }
    if (this.opHourEnd) {
      this.onOpHourEndNewChanged(this.opHourEnd);
    }
  }

  @Emit('deleteTimeRegion')
  public deleteTimeRegion() {
    return this.timeZoneId;
  }

  public setDate() {
    const secondHand = <any>this.$refs.clockSecond;
    const minsHand = <any>this.$refs.clockMinute;
    const hourHand = <any>this.$refs.clockHour;
    this.now = dayjs(this.now).set('second', dayjs().get('second')).toDate();

    if (secondHand && minsHand && hourHand && this.now) {
      const seconds = this.now.getSeconds();

      const secondsDegrees = (seconds / 60) * 360 + 90;
      secondHand.style.transform = `rotate(${secondsDegrees}deg)`;

      const mins = this.now.getMinutes();
      const minsDegrees = (mins / 60) * 360 + (seconds / 60) * 6 + 90;
      minsHand.style.transform = `rotate(${minsDegrees}deg)`;

      const hour = this.now.getHours();
      if (hour > 6 && hour < 18) {
        this.isDaylight = true;
      } else this.isDaylight = false;
      const hourDegrees = (hour / 12) * 360 + (mins / 60) * 30 + 90;
      hourHand.style.transform = `rotate(${hourDegrees}deg)`;
    }
  }

  private drawLittleCircle(ids: number) {
    // Format the provided ID to match your desired format, if necessary
    let hourFormattingIds = this.hourFormatting(ids);

    // Find the SVG path element with the formatted ID
    const path = document.querySelector(`#clock_${hourFormattingIds}`) as SVGGraphicsElement;

    // Get the bounding box of the path element
    const pathBoundingBox = path.getBBox();

    // Calculate the center coordinates
    const centerX = pathBoundingBox.x + pathBoundingBox.width / 2;
    const centerY = pathBoundingBox.y + pathBoundingBox.height / 2;

    // Create a new circle element
    const backgroundCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    backgroundCircle.setAttribute('cx', centerX + '');
    backgroundCircle.setAttribute('cy', centerY + '');
    backgroundCircle.setAttribute('r', '120');
    backgroundCircle.setAttribute('fill', '#4CC800');
    path.setAttribute('fill', '#FEFEFE');
    path.setAttribute('stroke', '#FEFEFE');

    // Find the main SVG container (you may need to adjust the selector)
    const svg = document.querySelector('#main');

    // Insert the new circle element before the path element
    svg!.insertBefore(backgroundCircle, path);
  }

  private hourFormatting(val: number, type = '') {
    if (type === 'circleLine') return val > 15 ? val - 12 : val;
    else return val > 12 ? val - 12 : val;
  }

  private drawCircle(opHourStartNew, opHourEndNew) {
    // The content of your drawCircle method remains the same as in your previous code.
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    var cssToRealPixels = window.devicePixelRatio || 1;
    canvas.width = canvas.clientWidth * cssToRealPixels;
    canvas.height = canvas.clientHeight * cssToRealPixels;

    let centerX = canvas.width / 2;
    let centerY = canvas.height / 2;
    const radius = (canvas.width * 0.825) / 2;
    const lineWidth = this.lineCircle;
    const hours1 = this.hourFormatting(opHourStartNew, 'circleLine');
    const hours2 = this.hourFormatting(opHourEndNew, 'circleLine');

    ctx.imageSmoothingEnabled = false;
    ctx.beginPath();

    // Assuming the clock has 12 hours, calculate the angle for hours 1 and 6
    const angle1 = ((2 * Math.PI) / 12) * (hours1 - 3);
    const angle2 = ((2 * Math.PI) / 12) * (hours2 - 3);

    if (angle1 !== angle2) {
      ctx.arc(centerX, centerY, radius, angle1, angle2);
    } else {
      // When hours1 and hours2 are the same, draw a full circle
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    }
    ctx.fillStyle = 'black';
    ctx.font = '18px serif';
    ctx.globalAlpha = 0.8;
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = '#7FD196';
    ctx.stroke();
    ctx.closePath();
  }
  private ClearCircle() {
    // Find the main SVG container (you may need to adjust the selector)
    const svg = document.querySelector('#main');
    const cicleElements = svg.querySelectorAll('circle');
    // Remove each <cycle> element
    cicleElements.forEach(circle => {
      circle.remove();
    });
  }

  @Watch('time')
  public timeListener() {
    if (this.time) {
      this.now = this.time;
    }
  }
  @Watch('opHourStart')
  onOpHourStartChanged(newVal: any, oldVal?: any) {
    if (newVal) {
      console.log(newVal, 'newVal');
      let formatHour = dayjs(newVal).format(TIME_24_FORMAT);
      let dateStart = dayjs(formatHour, TIME_24_FORMAT);
      if (dateStart.hour() == 0) {
        this.opHourStartNew = 24;
      } else {
        this.opHourStartNew = dateStart.hour();
      }
    }
  }

  @Watch('opHourEnd')
  onOpHourEndNewChanged(newVal: any, oldVal?: any) {
    if (newVal && this.opHourStartNew) {
      this.opHourEndNew = null;
      let formatHour = dayjs(newVal).format(TIME_24_FORMAT);
      let dateEnd = dayjs(formatHour, TIME_24_FORMAT);
      if (dateEnd.hour() == 0) {
        this.opHourEndNew = 24;
      } else {
        this.opHourEndNew = dateEnd.hour();
      }
      console.log(this.opHourStartNew, 'this.opHourStartNew');
      console.log(this.opHourEndNew, 'this.opHourEndNew');
      this.drawCircle(this.opHourStartNew, this.opHourEndNew);
      this.ClearCircle();
      this.drawLittleCircle(this.opHourEndNew);
      this.drawLittleCircle(this.opHourStartNew);
    }
  }

  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
}
