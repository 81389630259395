import axios from 'axios';

import { ICbVwUser } from '@/shared/model/cb-vw-user.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-users';

export default class PCbVwUserService {
  public find(id: string, cancelToken?: any): Promise<ICbVwUser> {
    if(!id)return;

    return new Promise<ICbVwUser>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-login')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
