import { IChtConversation } from '@/shared/model/cht-conversation.model';

import { App } from '@/shared/model/enumerations/app.model';
export interface IChtAggConversationMember {
  id?: number;
  userId?: string | null;
  passwordHash?: string | null;
  isAdmin?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  latestViewConversationDate?: Date | null;
  isActive?: boolean | null;
  app?: App | null;
  chtConversation?: IChtConversation | null;
  displayName?: string | null;
}

export class ChtAggConversationMember implements IChtAggConversationMember {
  constructor(
    public id?: number,
    public userId?: string | null,
    public passwordHash?: string | null,
    public isAdmin?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public latestViewConversationDate?: Date | null,
    public isActive?: boolean | null,
    public app?: App | null,
    public chtConversation?: IChtConversation | null,
    public displayName?: string | null
  ) {
    this.isAdmin = this.isAdmin ?? false;
    // this.isActive = this.isActive ?? false;
  }
}
