import axios from 'axios';

import { ICmSopInputedAgg } from '@/shared/model/cm-sop-inputed-agg.model';

const baseApiUrl = 'services/crmcorems/api/cm-sop-inputed-aggs';

export default class PCmSopInputedAggService {
  public create(entity: ICmSopInputedAgg): Promise<ICmSopInputedAgg> {
    return new Promise<ICmSopInputedAgg>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
