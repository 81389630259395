import axios, { CancelToken } from 'axios';

const baseApiUrl = 'services/cbcorems/api/cb-attachment-restricts';

export default class PCbAttachmentRestricts {
  public createTmp(param: any, cancelToken?: CancelToken, callback?: (progress: number) => void): Promise<any> {
    let tmpBlob = null;
    if (param.blob) {
      tmpBlob = param.blob;
      delete param.blob;
    }
    console.log(cancelToken, 'cancelToken');

    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/tmp`, param, { cancelToken })
        .then(res => {
          const result = res.data;

          if (result.uploadLink) {
            resolve(this.upload(result, result.uploadLink, param.attContentType, tmpBlob, cancelToken, callback));
          } else {
            resolve(result);
          }
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.log('Request canceled in createTmp:', err.message);
          }
          reject(err);
        });
    });
  }

  private upload(
    result: any,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    cancelToken?: CancelToken,
    callback?: (progress: number) => void
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      cancelToken,
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        if (callback) callback(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(res => {
          resolve(result);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.log('Request canceled in upload:', err.message);
          }
          reject(err);
        });
    });
  }
}
