export enum CmTktCategory {
  GENERAL_INQUIRY = 'GENERAL_INQUIRY',

  INCIDENT = 'INCIDENT',

  PROBLEM = 'PROBLEM',

  SERVICE_REQUEST = 'SERVICE_REQUEST',

  REFUND = 'REFUND',

  DISPUTE_ORDER = 'DISPUTE_ORDER',
  NON_COMPLIANCE_USER = 'NON_COMPLIANCE_USER',
  NON_COMPLIANCE_ORDER = 'NON_COMPLIANCE_ORDER',
  NON_COMPLIANCE_PAW = 'NON_COMPLIANCE_PAW',
  VERIFICATION_REQUEST = 'VERIFICATION_REQUEST',
}
