import { ICbVwUser } from './../shared/model/cb-vw-user.model';
import axios from 'axios';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import TranslationService from '@/locale/translation.service';
import CbVwUSerService from '@/services/cb-vw-user.service';
import { Authority } from '@/shared/security/authority';
import PCbOidService from '@/services/cb-oid.service';
import { ICbOidActivation } from '@/shared/model/cb-oids.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { Flutter } from '@/app-flutter';
import { CbUserStatusType } from '@/shared/model/enumerations/cb-user-status-type.model';
import {
  SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN,
  SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT,
  SESSION_STORAGE_CB_JHI_ID_TOKEN,
  SESSION_STORAGE_REQUESTED_URL,
} from '@/shared/constant/constants-session-storage';
import { ChtConversation, IChtConversation } from '@/shared/model/cht-conversation.model';
import PCbVwUserPartnerLevelService from '@/services/cb-vw-user-partner-level.service';
import { ICbVwUserStatus } from '@/shared/model/cb-vw-user-status.model';
const baseApiUrl = 'services/cbuserms/api/account';

export default class AccountService {
  constructor(
    private store: Store<any>,
    private translationService: TranslationService,
    private cookie: any,
    private router: VueRouter,
    private cbVwUSerService: CbVwUSerService,
    private pCbOidService: PCbOidService,
    private pCbVwUserPartnerLevelService: PCbVwUserPartnerLevelService
  ) {
    this.init();
  }

  public init(): void {
    this.retrieveProfiles();
  }

  public retrieveProfiles(): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .get<any>('management/info')
        .then(res => {
          if (res.data && res.data.activeProfiles) {
            this.store.commit('setRibbonOnProfiles', res.data['display-ribbon-on-profiles']);
            this.store.commit('setActiveProfiles', res.data['activeProfiles']);
          }
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }

  public retrieveAccount(): Promise<boolean> {
    let token =
      localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN) ||
      sessionStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN);
    if (token) token = 'Bearer ' + token;

    if (!token) {
      this.logoutStore();
      return new Promise(resolve => resolve(false));
    }

    return new Promise(resolve => {
      axios
        .get<any>('api/account', { headers: { Authorization: token } })
        .then(async response => {
          const account = response.data;
          if (account) {
            this.store.commit('logon', true);
            this.store.commit('authenticated', account);
            // localStorage.setItem(SESSION_STORAGE_CB_JHI_ID_TOKEN, account.idToken);
            if (sessionStorage.getItem(SESSION_STORAGE_REQUESTED_URL)) {
              const path = sessionStorage.getItem(SESSION_STORAGE_REQUESTED_URL);
              if (path.includes('/freelancer') && !this.store.getters.hasRoleCbPartner) {
                this.router.replace('/');
              } else {
                this.router.replace(sessionStorage.getItem(SESSION_STORAGE_REQUESTED_URL));
              }
              sessionStorage.removeItem(SESSION_STORAGE_REQUESTED_URL);
            } else if (this.router.currentRoute.fullPath.includes('forbidden')) {
              this.router.replace('/');
            }
            this.store.commit('setRoleCbBuyer', false);
            this.store.commit('setRoleCbPartner', false);

            await this.checkAuthorities([Authority.ROLE_CB_BUYER]).then(value => {
              this.store.commit('setRoleCbBuyer', value);
            });
            await this.checkAuthorities([Authority.ROLE_CB_PARTNER]).then(value => {
              this.store.commit('setRoleCbPartner', value);
            });

            if (this.store.getters.loginType != CbLoginType.BUYER && this.store.getters.loginType != CbLoginType.PARTNER) {
              let loginType: any = CbLoginType.BUYER;
              if (this.store.getters.hasRoleCbPartner) {
                loginType = CbLoginType.PARTNER;
              }
              this.store.commit('loginType', loginType);
            }

            const idToken = localStorage.getItem(SESSION_STORAGE_CB_JHI_ID_TOKEN);
            const chtConversation = this.store.getters.chtLiveAgentConversation;
            if (idToken && chtConversation && chtConversation.id) {
              this.partialUpdateLiveChatByToken(idToken, chtConversation.id).then(res => {
                if (res && res.data) {
                  const chtLiveAgentConversation = this.store.getters.chtLiveAgentConversation;
                  chtLiveAgentConversation.realUserId = res.data.realUserId;
                  this.store.commit('chtLiveAgentConversation', chtLiveAgentConversation);
                }
              });
            }
          } else {
            this.store.commit('logout');
            if (this.router.currentRoute.path !== '/') {
              this.router.push('/');
            }
            sessionStorage.removeItem(SESSION_STORAGE_REQUESTED_URL);
          }
          this.translationService.refreshTranslation(this.store.getters.currentLanguage);
          resolve(true);
        })
        .catch(err => {
          //this.logoutStore(); // comment because when the first call web "/" it will call isLogin > retrieveAccount, and interceptor not ready so refresh token cannot handle  this.
          resolve(false);
        });
    });
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public get userAuthorities(): any {
    return this.store.getters.account?.authorities;
  }

  private checkAuthorities(authorities: any): Promise<boolean> {
    if (this.userAuthorities) {
      for (const authority of authorities) {
        if (this.userAuthorities.includes(authority)) {
          return Promise.resolve(true);
        }
      }
    }
    return Promise.resolve(false);
  }

  private isCallRetrieveAccount(isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
    const token =
      localStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN) ||
      sessionStorage.getItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN) ||
      this.cookie.get('XSRF-TOKEN') ||
      this.cookie.get('JSESSIONID');
    if ((!this.store.getters.account && !this.store.getters.logon && token) || isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
      return true;
    } else {
      return false;
    }
  }

  public hasAnyAuthorityAndCheckAuth(authorities: any, isFirstLoginForceRetrieveAccountToCheckSessionAccount): Promise<boolean> {
    if (typeof authorities === 'string') {
      authorities = [authorities];
    }

    if (!this.authenticated || !this.userAuthorities || isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
      if (this.isCallRetrieveAccount(isFirstLoginForceRetrieveAccountToCheckSessionAccount)) {
        return this.retrieveAccount().then(resp => {
          if (resp) {
            return this.checkAuthorities(authorities);
          }

          return Promise.resolve(false);
        });
      }
      return Promise.resolve(false);
    }

    return this.checkAuthorities(authorities);
  }

  public isLogin(isFirstLoginForceRetrieveAccountToCheckSessionAccount): Promise<boolean> {
    if (!this.authenticated || !this.userAuthorities || isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
      if (this.isCallRetrieveAccount(isFirstLoginForceRetrieveAccountToCheckSessionAccount)) {
        return this.retrieveAccount();
      }
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  public refreshAccount() {
    this.retrieveAccount().then(value => {
      this.retrieveCbVwUserCurrent();
    });
  }

  public retrieveCbVwUserCurrent() {
    this.cbVwUSerService
      .retrieveByLogin()
      .then((res: ICbVwUser) => {
        this.store.commit('currentUser', res);
        if (!res.emailActivated && !res.phoneActivated && (res.emailActivationKey || res.phoneActivationKey)) {
          const tmpEntity: ICbOidActivation = {
            key: res.emailActivationKey,
          };
          if (res.emailActivationKey) {
            this.pCbOidService.resend(tmpEntity, 'email');
          }
          if (res.phoneActivationKey) {
            this.pCbOidService.resend(tmpEntity, 'phone');
          }
          alert('Your account has not been activated, please check your email or WA to activate this account');
          this.logout();
        }

        if (res.userStatus == CbUserStatusType.DEACTIVED) {
          const account = this.store.getters.account;
          account.authorities = null;

          this.store.commit('authenticated', account);
          this.store.commit('setVisibleModalDeactive', true);
        }
        this.processAssignUserLvl(res);

        const storeStatus: ICbVwUserStatus = this.store.getters.cbVwUserStatus;

        if (!storeStatus.isSuspend) {
          this.findCbVwUserStatusByLogin();
        }
      })
      .catch(err => {
        console.log('ERROR ==> ', err);
      });
  }

  public processAssignUserLvl(cbVwUser: ICbVwUser) {
    this.pCbVwUserPartnerLevelService
      .retrieveByLogin()
      .then(res => {
        if (res && res.userLvl) {
          cbVwUser.userLvl = res.userLvl.toString();
          this.store.commit('currentUser', cbVwUser);
        }
      })
      .finally(() => {});
  }

  public findCbVwUserStatusByLogin() {
    this.retrieveCbVwUserStatusByLogin()
      .then(res => {
        let cbVwUserStatus: ICbVwUserStatus = res;
        console.log('cbVwUserStatus', cbVwUserStatus);

        if (cbVwUserStatus.isSuspend) {
          let storeStatus: ICbVwUserStatus = this.store.getters.cbVwUserStatus;

          if (cbVwUserStatus.suspendTimeRemainingLabel != null) {
            let splLabel = cbVwUserStatus.suspendTimeRemainingLabel.split('||');
            cbVwUserStatus.timeLabelEn =
              cbVwUserStatus.suspendTimeRemaining > 1
                ? cbVwUserStatus.suspendTimeRemaining + ' ' + splLabel[0] + 's'
                : storeStatus.suspendTimeRemaining + ' ' + splLabel[0];
            cbVwUserStatus.timeLabelId = cbVwUserStatus.suspendTimeRemaining + ' ' + splLabel[1];
          }

          if (storeStatus.isSuspend == null || storeStatus.isSuspend == false) {
            this.store.commit('cbVwUserStatus', cbVwUserStatus);
            this.store.commit('visibleModalSuspend', true);
          }
        }
      })
      .catch(err => {
        console.log('ERROR', err.response.data);
      });
  }

  public async logout() {
    axios.post('api/logout').then(
      response => {
        this.store.commit('logoutChat');
        this.logoutStore();

        if (response && response.data && response.data.logoutUrl) {
          if (response.data.logoutUrl.includes('null')) {
            response.data.logoutUrl = response.data.logoutUrl.replace('null', window.location.origin);
            window.location.href = response.data.logoutUrl;
          } else {
            window.location.href = response.data.logoutUrl;
          }
        } else {
          this.router.replace({ path: '/' });
        }
      },
      err => {
        this.logoutStore();

        this.router.replace({ path: '/' });
      }
    );
  }

  public logoutStore() {
    if (window.navigator.onLine) {
      //if internet online
      this.store.commit('logout');
      this.store.commit('CLEAR_USER_BOOKMARK_PAW');
      this.store.commit('CLEAR_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH');
      this.store.commit('CLEAR_USER_BOOKMARK_AUCTION_PROJECT');
      //fix bug refresh auto delete, must comment, sparated
      // this.store.commit('logoutChat');
      this.store.commit('clearTextRestrict');
      sessionStorage.removeItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN);
      localStorage.removeItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN);
      localStorage.removeItem(SESSION_STORAGE_CB_JHI_AUTHENTICATION_TOKEN_RT);
      localStorage.removeItem(SESSION_STORAGE_CB_JHI_ID_TOKEN);
      localStorage.removeItem('cb-jhi-access-token-4-flutter');

      Flutter.call('logout');
    }
  }

  public postRegisterRequestTicket(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(baseApiUrl + '/register-freelancer/send-ticket')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveUserAccessToken(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('services/cbuserms/api/users/token')
        .then(res => {
          localStorage.setItem('cb-jhi-access-token-4-flutter', res.data);
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdateLiveChatByToken(token: string, id: number): Promise<any> {
    const chtConversation: IChtConversation = new ChtConversation();
    chtConversation.id = id;
    chtConversation.token = token;
    return new Promise<any>((resolve, reject) => {
      axios
        .patch(`services/btichat/api/cht-conversations/live-chat/by-token-id/${id}`, chtConversation)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCbVwUserStatusByLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('services/cbuserms/api/cb-vw-user-statuses/by-login')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
