import { ReviewType } from '@/shared/model/enumerations/review-type.model';
import { ReviewStatus } from '@/shared/model/enumerations/review-status.model';
export interface ICbUserReview {
  id?: number;
  partnerId?: string | null;
  rating?: number;
  comment?: string;
  rewardAmount?: number | null;
  rewardActivationKey?: string | null;
  isRewardActivated?: boolean | null;
  rewardActivatedBy?: string | null;
  rewardActivatedDate?: Date | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  externalId?: string | null;
  externalOrderId?: string | null;
  replyRefId?: string | null;
  reviewType?: ReviewType | null;
  status?: ReviewStatus | null;
}

export class CbUserReview implements ICbUserReview {
  constructor(
    public id?: number,
    public partnerId?: string | null,
    public rating?: number,
    public comment?: string,
    public rewardAmount?: number | null,
    public rewardActivationKey?: string | null,
    public isRewardActivated?: boolean | null,
    public rewardActivatedBy?: string | null,
    public rewardActivatedDate?: Date | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public externalId?: string | null,
    public externalOrderId?: string | null,
    public replyRefId?: string | null,
    public reviewType?: ReviewType | null,
    public status?: ReviewStatus | null
  ) {
    // this.isRewardActivated = this.isRewardActivated ?? false;
  }
}
