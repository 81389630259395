var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vac-message-file-container" }, [
    !_vm.message.isMap
      ? _c(
          "div",
          {
            ref: "imageRef" + _vm.index,
            staticClass: "vac-message-image-container",
            attrs: { "data-cy": "vac-message-image-container" },
            on: {
              mouseover: function ($event) {
                _vm.imageHover = true
              },
              mouseleave: function ($event) {
                _vm.imageHover = false
              },
              click: function ($event) {
                return _vm.openFile($event, "preview")
              },
            },
          },
          [
            _vm.file.progress >= 0
              ? _c("progress-bar", {
                  style: { top: `${_vm.imageResponsive.loaderTop}px` },
                  attrs: { progress: _vm.file.progress },
                })
              : _c("loader", {
                  staticClass: "vac-size-loader",
                  style: { top: `${_vm.imageResponsive.loaderTop}px` },
                  attrs: { show: _vm.isImageLoading },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (idx, name) {
                        return {
                          key: name,
                          fn: function (data) {
                            return [_vm._t(name, null, null, data)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "position-relative vac-img-wrapper",
                class: _vm.isImageLoading ? "vac-img-loading" : "",
                attrs: { "data-cy": "vac-img-wrapper" },
              },
              [
                _vm.isImage
                  ? _c("img", {
                      staticClass: "var-image-video-size",
                      attrs: { src: _vm.file.url, alt: "" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isVideo
                  ? [
                      _c("img", {
                        staticClass: "var-image-video-size",
                        attrs: { src: _vm.file.urlThumbnail, alt: "" },
                      }),
                      _vm._v(" "),
                      !_vm.isImageLoading
                        ? _c("c-icons", {
                            staticClass: "position-play cj-fill-white-primary",
                            attrs: {
                              name: "cic_play_circle_outlined",
                              width: "50px",
                              height: "50px",
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("transition", { attrs: { name: "vac-fade-image" } }, [
                  !_vm.messageSelectionEnabled &&
                  _vm.imageHover &&
                  !_vm.isImageLoading
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-image-buttons",
                          staticStyle: {
                            top: "0",
                            "border-radius": "8px !important",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vac-svg-button vac-button-view",
                              attrs: { "data-cy": "vac-message-image-view" },
                              on: {
                                click: function ($event) {
                                  return _vm.openFile($event, "preview")
                                },
                              },
                            },
                            [
                              _vm._t("eye-icon", function () {
                                return [
                                  _c("svg-icon", { attrs: { name: "eye" } }),
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "vac-svg-button vac-button-download",
                              attrs: {
                                "data-cy": "vac-message-image-download",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openFile($event, "download")
                                },
                              },
                            },
                            [
                              _vm._t("document-icon", function () {
                                return [
                                  _c("svg-icon", {
                                    attrs: { name: "document" },
                                  }),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.message.isMap
      ? _c(
          "a",
          {
            ref: "imageRef" + _vm.index,
            staticClass: "vac-message-image-container",
            attrs: { href: _vm.getLink, target: "_blank" },
            on: { click: _vm.onFlutterClick },
          },
          [
            _vm.file.progress >= 0
              ? _c("progress-bar", {
                  style: { top: `${_vm.imageResponsive.loaderTop}px` },
                  attrs: { progress: _vm.file.progress },
                })
              : _c("loader", {
                  staticClass: "vac-size-loader",
                  attrs: { show: _vm.isImageLoading },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (idx, name) {
                        return {
                          key: name,
                          fn: function (data) {
                            return [_vm._t(name, null, null, data)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                }),
            _vm._v(" "),
            _c("div", { staticClass: "position-relative" }, [
              _vm.isImageLoading
                ? _c("div", {
                    staticClass: "vac-map-wrapper",
                    class: _vm.isImageLoading ? "vac-map-img-loading" : "",
                  })
                : _c("div", { staticClass: "map-container" }, [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "img-map-wrapper",
                          attrs: { "data-cy": "img-map-wrapper" },
                        },
                        [
                          _c("img", {
                            staticClass: "vac-map-image-size",
                            attrs: { src: _vm.file.url, alt: "" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "description-map position-relative",
                          class: _vm.isYourSelf ? "me" : "",
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "m-0 text-title vac-text-ellipsis" },
                            [_vm._v(_vm._s(_vm.message.mapPlaceName))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "m-0 text-sub vac-text-ellipsis",
                              attrs: { "data-cy": "description-map-address" },
                            },
                            [_vm._v(_vm._s(_vm.message.mapAddress))]
                          ),
                        ]
                      ),
                    ]),
                  ]),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }