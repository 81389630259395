export enum CbLogType {
  PAW_HIT = 'PAW_HIT',
  PAW_SEARCH = 'PAW_SEARCH',
  PAW_SHARE = 'PAW_SHARE',
  PAW_DETAIL = 'PAW_DETAIL',
  PAW_ORDER = 'PAW_ORDER',
  DAILY_LOGIN = 'DAILY_LOGIN',
  WEB_HIT = 'WEB_HIT',
  SVC_HIT = 'SVC_HIT',
  SVC_POPULAR_EXPIRED = 'SVC_POPULAR_EXPIRED',
  SVC_CAT_HIT = 'SVC_CAT_HIT',
  SVC_CAT_POPULAR_EXPIRED = 'SVC_CAT_POPULAR_EXPIRED',
  PROFILE_VISIT = 'PROFILE_VISIT',
  PROFILE_SEARCH = 'PROFILE_SEARCH',
  AUCTION_SEARCH = 'AUCTION_SEARCH',
  AUCTION_PAGE = 'AUCTION_PAGE',
  AUCTION_DETAIL = 'AUCTION_DETAIL',
  AUCTION_SHARE = 'AUCTION_SHARE',
  AUCTION_ORDER = 'AUCTION_ORDER',
  HELP_CENTER_RESPONSE = 'HELP_CENTER_RESPONSE',
  KEY_SEARCH = 'KEY_SEARCH',
  LOG = 'LOG',
}
