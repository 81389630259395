import axios from 'axios';
import { ICbLinkedinRequest } from './shared/model/cb-linkedin-request.model';
import { ICbLinkedAccount } from './shared/model/cb-oids.model';
import { IKeycloakToken } from './shared/model/keycloak-token.model';
import { Attachment, FileEvent, FlutterData, FlutterFileResponse, FlutterListenerData, FlutterOptions, OAuth2Event } from './app-flutter.d';
import { CbAttachmentRestrict } from './shared/model/cb-attachment-restrict.model';

export class Flutter {
  static fnArr: FlutterListenerData[] = [];

  static printError(error: any): void {
    console.log(error);
  }

  static listen(data: FlutterData): void {
    Flutter.addListener(data.handlerLabel, (event: Event) => Flutter.onListen(event, data));
  }

  static listenCbAttachmentRestrict(data: FlutterData): void {
    Flutter.addListener(data.handlerLabel, (event: Event) => Flutter.onListenCbAttachmentRestrict(event, data));
  }

  static listenPermissionLocation(data: FlutterData): void {
    Flutter.addListener(data.handlerLabel, (event: Event) => Flutter.onListenPermissionLocation(event, data));
  }

  static addListener(label: string, fn: (event: Event) => void): void {
    if (this.isRunOn() === false) return;
    const data = { key: label, fn: fn };
    const foundIndex = Flutter.fnArr.findIndex(obj => obj.key === label);
    if (foundIndex !== -1) {
      console.log(`RESTART ${label}`);
      window.removeEventListener(label, Flutter.fnArr[foundIndex].fn, false);
      Flutter.fnArr[foundIndex] = data;
    } else {
      console.log(`START ${label}`);
      Flutter.fnArr.push(data);
    }
    window.addEventListener(label, fn, false);
  }

  static async onListen(event: Event, data: FlutterData): Promise<void> {
    try {
      const flutterEvent = event as FileEvent;
      if (flutterEvent.detail.error) {
        data.onError(flutterEvent.detail.error);
        return;
      }
      console.log(`${data.handlerLabel} is called`);
      const dataList: Attachment[] = flutterEvent.detail;
      const result: FlutterFileResponse[] = [];
      console.log('attachment data json', JSON.stringify(dataList));
      for (const attachment of dataList) {
        console.log('attachment data', JSON.stringify(attachment));
        const blob = await this.dataURItoBlob(attachment.data);
        const localUrl = await new Promise<string>((resolve, reject) => {
          try {
            resolve(URL.createObjectURL(blob));
          } catch (error) {
            reject(error);
          }
        });
        result.push({
          file: new File([blob], attachment.name, { type: attachment.mime }),
          localUrl: localUrl,
          blob: blob,
          label: attachment.label,
        });
      }
      data.callback(result);
    } catch (e) {
      Flutter.printError(e);
    }
  }

  static async onListenCbAttachmentRestrict(event: Event, data: FlutterData): Promise<void> {
    try {
      const flutterEvent = event as FileEvent;
      if (flutterEvent.detail.error) {
        data.onError(flutterEvent.detail.error);
        return;
      }

      console.log(`${data.handlerLabel} is called`);
      const dataList: CbAttachmentRestrict[] = flutterEvent.detail['data'];
      const isLoadingFile: boolean = flutterEvent.detail['isLoadingFile'];

      data.callback(dataList, isLoadingFile);
    } catch (e) {
      Flutter.printError(e);
    }
  }

  static async onListenPermissionLocation(event: Event, data: FlutterData): Promise<void> {
    try {
      const flutterEvent = event as FileEvent;
      if (flutterEvent.detail.error) {
        data.onError(flutterEvent.detail.error);
        return;
      }

      console.log(`${data.handlerLabel} is called`);
      const dataList: string = flutterEvent.detail['data'];
      console.log(`${data.handlerLabel} is called with value ${flutterEvent.detail['data']}`);

      data.callback(dataList);
    } catch (e) {
      Flutter.printError(e);
    }
  }

  static listenDetailOrder(data: FlutterData): void {
    Flutter.addListener(data.handlerLabel, async (event: Event) => {
      console.log(`${data.handlerLabel} is called`);
      try {
        const flutterEvent = event as FileEvent;
        const dataList: Attachment[] = flutterEvent.detail;
        if (dataList.length === 0) return;
        data.callback(dataList[0]);
      } catch (e) {
        Flutter.printError(e);
      }
    });
  }

  static listenLinkAccount(handlerLabel: string, callback: (cbLinkedAccount: ICbLinkedAccount | ICbLinkedinRequest) => void): void {
    Flutter.addListener(handlerLabel, async (event: any) => {
      try {
        console.log(`${handlerLabel} is called   , event: ${JSON.stringify(event.detail)}`);
        callback(event.detail as ICbLinkedAccount | ICbLinkedinRequest);
      } catch (e) {
        Flutter.printError(e);
      }
    });
  }

  static listenNavigation(handlerLabel: string, callback: () => void): void {
    Flutter.addListener(handlerLabel, async (event: any) => {
      try {
        // console.log(handlerLabel, 'handlerLabelhandlerLabel')
        console.log(`${handlerLabel} >>>>>is called   , event: ${JSON.stringify(event.detail)}`);
        callback();
      } catch (e) {
        Flutter.printError(e);
      }
    });
  }
  static logoutEventListener(handlerLabel: string): void {
    Flutter.addListener(handlerLabel, async (event: Event) => {
      console.log(`${handlerLabel} is called`);
      const flutterEvent = event as FileEvent;
      console.log(`logout is called then : ${JSON.stringify(flutterEvent.detail['data'])}`);
      await axios.delete(`services/btinotif/api/nt-user-fcms/${flutterEvent.detail['data']}/CB_APP`);
    });
  }

  static onOAuth2Login(data: FlutterData): void {
    Flutter.addListener(data.handlerLabel, async (event: Event) => {
      console.log(`${data.handlerLabel} is called`);
      const flutterEvent = event as OAuth2Event;
      data.callback(flutterEvent.detail);
    });
  }

  static async dataURItoBlob(dataURI: string): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      try {
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        resolve(new Blob([ia], { type: mime }));
      } catch (error) {
        reject(error);
      }
    });
  }

  static isRunOn(): boolean {
    return window.flutter_inappwebview !== undefined;
  }

  static cancelUploadOrder(id: any): void {
    console.log('vue Request canceled is cancelUploadOrder with id: ', id);
    if (this.isRunOn()) {
      console.log('vue Request canceled isRunOn called with id: ', id);
      window.flutter_inappwebview.callHandler('cancelOrderUploadFile', id);
    }
  }

  static currentOrderUploadFileAmount(length: number): void {
    if (this.isRunOn()) {
      window.flutter_inappwebview.callHandler('currentOrderUploadFileAmount', length);
    }
  }

  static launchMap(lat: string, lng: string, onFlutterNotRun?: () => void): void {
    if (this.isRunOn() === true) {
      window.flutter_inappwebview.callHandler('launchMap', { lat: lat, lng: lng });
    } else {
      onFlutterNotRun?.();
    }
  }

  static call(handlerName: string, options?: FlutterOptions): void {
    if (this.isRunOn() === true) {
      window.flutter_inappwebview.callHandler(handlerName, options);
    } else if (options?.onFlutterNotRun) {
      options?.onFlutterNotRun();
    }
  }

  static keepToken(keyClockToken: IKeycloakToken): void {
    if (this.isRunOn() === false) return;
    window.flutter_inappwebview.callHandler('keepToken', keyClockToken);
  }
}
