var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-bank-account",
      class: _vm.isMobile ? "navbar-distance" : "",
    },
    [
      _c("p", {
        staticClass:
          "font-roboto cj-color-black-primary font-size-24 font-weight-700 d-none d-lg-block pb-3",
        domProps: {
          textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.bankAccount.title")),
        },
      }),
      _vm._v(" "),
      [
        _c(
          "div",
          {
            staticClass: "d-flex",
            class:
              !_vm.cbBankAccount || !_vm.cbBankAccount.id
                ? "flex-column-reverse flex-lg-row"
                : "flex-column",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "c-bank-card-container wrap-border-grey-tertiary border-radius-10 px-3 pt-3 pb-3",
                class: {
                  "w-100": !_vm.isMobile,
                },
              },
              [
                _c("p", {
                  staticClass:
                    "font-roboto cj-color-black-primary font-size-20-auto font-weight-500 pb-2",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.bankAccount.title")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto cj-color-black-primary font-size-16-auto font-weight-300 pb-2",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.bankAccount.descBankAccount")
                    ),
                  },
                }),
                _vm._v(" "),
                _vm.cbLovBanks &&
                _vm.cbLovBanks.length > 0 &&
                (_vm.cbBankAccountProcess == "CREATE" ||
                  _vm.cbBankAccountProcess == "UPDATE")
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-end cj-btn-text-primary",
                        attrs: { "data-cy": "cancel-bank-update" },
                        on: { click: _vm.handleHideBankUpdate },
                      },
                      [
                        _c("p", {
                          staticClass:
                            "font-roboto font-size-16-auto font-weight-300 hand",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbSetting.bankAccount.cancel")
                            ),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cbBankAccountProcess == "NO_PROCESS"
                  ? _c("c-ba-bank-card", {
                      attrs: {
                        cbBankAccount: _vm.cbBankAccount,
                        getBankNameByKey: _vm.getBankNameByKey,
                        newCbBankAccount: _vm.newCbBankAccount,
                        isFetchingCbBankAccount: _vm.isFetchingCbBankAccount,
                      },
                      on: {
                        openForm: _vm.openForm,
                        changeFunction: _vm.changeFunction,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.cbLovBanks &&
                _vm.cbLovBanks.length > 0 &&
                (_vm.cbBankAccountProcess == "CREATE" ||
                  _vm.cbBankAccountProcess == "UPDATE")
                  ? _c("c-ba-bank-update", {
                      attrs: {
                        cbLovBanks: _vm.cbLovBanks,
                        newCbBankAccount: _vm.newCbBankAccount,
                      },
                      on: {
                        inputCardNumber: _vm.inputCardNumber,
                        selectBankCode: _vm.selectBankCode,
                        changeValue: _vm.changeValue,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.cbBankAccountProcess == "CREATE" ||
                _vm.cbBankAccountProcess == "UPDATE"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "ml-auto col-12 col-lg-12 px-0 mb-2 d-flex",
                      },
                      [
                        _c(
                          "c-button",
                          {
                            staticClass:
                              "font-roboto font-size-18-auto font-weight-500 py-2",
                            attrs: {
                              dataCyCustom: "save-bank-account-button",
                              fullWidth: "",
                              disabled:
                                _vm.$v.newCbBankAccount.$invalid ||
                                _vm.isSavingBankAccount,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.handleSubmitBankAccount.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm.isSavingCbLovBanks
                              ? _c("font-awesome-icon", {
                                  attrs: {
                                    icon: "sync",
                                    spin: _vm.isSavingCbLovBanks,
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.newCbBankAccount.id
                                          ? _vm.$t(
                                              "cbgwApp.cbSetting.bankAccount.update"
                                            )
                                          : _vm.$t(
                                              "cbgwApp.cbSetting.bankAccount.save"
                                            )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "credit-container wrap-border-grey-tertiary border-radius-10 px-3 pt-3 pb-3 mt-3",
          },
          [
            _c("c-ba-credit-card", {
              staticClass: "pb-2",
              attrs: { cbCreditCards: _vm.cbCreditCards },
              on: {
                changeFunction: _vm.changeFunction,
                changeValue: _vm.changeValue,
              },
            }),
            _vm._v(" "),
            !_vm.isShowCreditUpdate && _vm.cbCreditCards.length < 5
              ? _c(
                  "div",
                  {
                    staticClass:
                      "cj-btn-border-primary rounded-pill p-2 cursor-pointer d-flex align-items-center justify-content-center mb-2 mt-1 mt-lg-0",
                    attrs: {
                      "data-cy": "add-credit-card-button",
                      "data-gtm": "add-credit-card-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleShowCreditUpdate()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex mr-1" },
                      [
                        _c("icon", {
                          staticClass: "my-auto cj-fill-black-primary",
                          attrs: {
                            name: "ic_plus_circle_outlined",
                            width: "22px",
                            height: "22px",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "font-roboto cj-color-black-primary font-size-20-auto font-weight-400",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbSetting.addCreditCard")
                        ),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-collapse",
              {
                staticClass: "credit-update-container",
                attrs: { visible: _vm.isShowCreditUpdate ? true : false },
              },
              [
                _c("c-ba-credit-update", {
                  attrs: { newCbCreditCardParent: _vm.newCbCreditCard },
                  on: {
                    submit: _vm.submitNewBankCC,
                    cancel: function ($event) {
                      _vm.isShowCreditUpdate = false
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-enter-pin",
            centered: "",
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center w-100 position-relative",
                    },
                    [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto text-center font-weight-700 cj-color-black-primary font-size-24",
                          },
                          [_vm._v("PIN")]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          staticClass:
                            "p-0 m-0 font-roboto cj-color-black-primary text-italic font-weight-500 mt-2 text-center font-size-18",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbPayment.enterPin")
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "position-absolute",
                          staticStyle: { right: "0" },
                          attrs: { type: "button" },
                          on: { click: _vm.handleCloseInputPin },
                        },
                        [
                          _c("b-icon-x-circle-fill", {
                            staticClass: "font-size-18 cj-color-grey-secondary",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.visiblePin,
            callback: function ($$v) {
              _vm.visiblePin = $$v
            },
            expression: "visiblePin",
          },
        },
        [
          _vm._v(" "),
          _c("c-input-pin", {
            attrs: {
              isSavingCbPayInBalance: _vm.isSavingBankAccount,
              pinNotMatched: _vm.pinNotMatched,
            },
            on: {
              cancel: _vm.handleCloseInputPin,
              save: _vm.saveBankAccountSettingPage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("b-modal", {
        ref: "modalnotifbankunderreview",
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-notif-bank-under-review",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: "Bank Account is Under Review",
                    subTitle:
                      "Please wait for our team to review your Bank Account Verification first before continuing freelancer registration.",
                    iconCustom: "ic_exclamation",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        ref: "modalnotiffreelancerverficationunderreview",
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-notif-freelancer-verfication-under-review",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t(
                      "cbgwApp.cbSetting.freelancerVerifUnderReview"
                    ),
                    subTitle: _vm.$t("cbgwApp.cbSetting.reviewYourFreelancer"),
                    iconCustom: "ic_exclamation",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        ref: "modalnotifbankdeletitem",
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "modal-notif-bank-delet-item",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t(
                      "cbgwApp.cbSetting.bankAccount.notifTitleBank"
                    ),
                    submitTitle: _vm.$t("cbgwApp.cbSetting.sure"),
                    cancelTitle: _vm.$t("cbgwApp.cbSetting.cancel"),
                    isLoading: _vm.isDeleteCbBankAccount,
                  },
                  on: {
                    cancel: cancel,
                    close: cancel,
                    save: _vm.deleteCbBankAccount,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("b-icon-question-circle", {
                              staticClass:
                                "cj-color-red-primary font-size-64 mb-2 mb-lg-3",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalnotifbankdeletesucces",
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            id: "modal-notif-bank-delete-succes",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: "Delete Success",
                hideClose: true,
                iconCustom: "ic_check_circle",
              },
            }),
          ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }