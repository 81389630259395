import { ICbLocation } from '@/shared/model/cb-location.model';

import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
export interface ICbPawPreOrder {
  id?: number;
  orderType?: CbOrderType | null;
  externalId?: number | null;
  pawPkgType?: string | null;
  bookStartDate?: Date | null;
  bookEndDate?: Date | null;
  partnerId?: string | null;
  buyerId?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbLocation?: ICbLocation | null;
  status?: CbCommonStatus | null;
  timeZone?: string | null;
}

export class CbPawPreOrder implements ICbPawPreOrder {
  constructor(
    public id?: number,
    public orderType?: CbOrderType | null,
    public externalId?: number | null,
    public pawPkgType?: string | null,
    public bookStartDate?: Date | null,
    public bookEndDate?: Date | null,
    public partnerId?: string | null,
    public buyerId?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbLocation?: ICbLocation | null,
    public status?: CbCommonStatus | null,
    public timeZone?: string | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
