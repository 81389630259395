import { Component } from 'vue-property-decorator';
import html2canvas from 'html2canvas';
import axios from 'axios';

import { CbAtt, ICbAtt } from '../model/cb-att';
import { mixins } from 'vue-class-component';
import ThumbnailWatermarkUtils from './thumbnail-watermark-utils.service';
import {
  regexFileType_3GP,
  regexFileType_AVI,
  regexFileType_DOC,
  regexFileType_DOCX,
  regexFileType_FLV,
  regexFileType_GIF,
  regexFileType_JPEG,
  regexFileType_JPG,
  regexFileType_KEY,
  regexFileType_MID,
  regexFileType_MIDI,
  regexFileType_MKV,
  regexFileType_MOV,
  regexFileType_MP3,
  regexFileType_MP4,
  regexFileType_MPEG,
  regexFileType_MPG,
  regexFileType_PAGES,
  regexFileType_PDF,
  regexFileType_PNG,
  regexFileType_PPT,
  regexFileType_PPTX,
  regexFileType_SVG,
  regexFileType_WAV,
  regexFileType_WMV,
  regexFileType_XLS,
  regexFileType_XLSX,
} from '../constant/constant-file-type';
import { regexMatchNumber } from '../constant/constant-match-regex';
import { isPdfFile, isVideoFile } from '../media-file/media-file';
import { Flutter } from '@/app-flutter';
import { FlutterFileResponse, FlutterOptions } from '@/app-flutter.d';
import { CbPawOrderFile } from '../model/cb-paw-order-file.model';
import { MAX_SIZE_10MB } from '../media-file/constants';
import { CbAttachmentRestrict, ICbAttachmentRestrict } from '../model/cb-attachment-restrict.model';

// //  import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// import pdfjsLib from '@/../lib/pdfjs-2.3.200-dist/build/pdf'; //error at safari 15, and cromium 77
// const pdfjsWorker='//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js'

/**
 * An utility service for data.
 */
@Component
export default class JhiDataUtils extends mixins(ThumbnailWatermarkUtils) {
  public abbreviate(text: string, append = '...'): string {
    if (text.length < 30) {
      return text;
    }
    return text ? text.substring(0, 15) + append + text.slice(-10) : '';
  }

  public byteSize(base64String: string): string {
    return this.formatAsBytes(this.size(base64String));
  }

  public openFile(contentType: string, data: string): void {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType,
    });
    const objectURL = URL.createObjectURL(blob);
    const win = window.open(objectURL);
    if (win) {
      win.onload = () => URL.revokeObjectURL(objectURL);
    }
  }

  public base64ToBlobString(att: string, contentType: string): string {
    const byteCharacters = atob(att);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType,
    });
    const objectURL = URL.createObjectURL(blob);

    if (objectURL) {
      return objectURL;
    }
  }

  public toBase64(file: File, cb: (base64Data: string) => void) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e: any) => {
      const base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
      cb(base64Data);
    };
  }

  public clearInputImage(entity: { field: string; value: string }, field: string, fieldContentType: string, idInput: string) {
    if (entity && field && fieldContentType) {
      if (Object.prototype.hasOwnProperty.call(entity, field)) {
        entity[field] = null;
      }
      if (Object.prototype.hasOwnProperty.call(entity, fieldContentType)) {
        entity[fieldContentType] = null;
      }
    }
  }

  private endsWith(suffix: string, str: string): boolean {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  public paddingSize(value: string): number {
    if (this.endsWith('==', value)) {
      return 2;
    }
    if (this.endsWith('=', value)) {
      return 1;
    }
    return 0;
  }

  size(value) {
    return (value.length / 4) * 3 - this.paddingSize(value);
  }

  formatAsBytes(size) {
    return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
  }

  setFileData(event, entity, field, isImage: boolean) {
    if (event?.target?.files[0]) {
      const file = event.target.files[0];
      if (isImage && !/^image\//.test(file.type)) {
        return;
      }
      this.toBase64(file, base64Data => {
        entity[field] = base64Data;
        entity[`${field}ContentType`] = file.type;
      });
    }
  }

  public downloadFile(contentType: string, data: string, fileName: string): void {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: contentType,
    });
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = fileName;
    tempLink.target = '_blank';
    tempLink.click();
  }

  public downloadFileGoogle(url: string): void {
    axios
      .get(url, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {},
      })
      .then(res => {
        const headerLine = res.headers['content-disposition'];
        let filename = null;
        if (headerLine) {
          const startFileNameIndex = headerLine.indexOf('"') + 1;
          const endFileNameIndex = headerLine.lastIndexOf('"');
          filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        }

        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (filename) link.download = filename;
        link.target = '_blank';
        link.click();
      })
      .catch(err => {});
  }

  /**
   * Method to parse header links
   */
  parseLinks(header) {
    const links = {};

    if (header === null || header.indexOf(',') === -1) {
      return links;
    }
    // Split parts by comma
    const parts = header.split(',');

    // Parse each part into a named link
    parts.forEach(p => {
      if (p.indexOf('>;') === -1) {
        return;
      }
      const section = p.split('>;');
      const url = section[0].replace(/<(.*)/, '$1').trim();
      const queryString = { page: null };
      url.replace(new RegExp(/([^?=&]+)(=([^&]*))?/g), ($0, $1, $2, $3) => {
        queryString[$1] = $3;
      });
      let page = queryString.page;
      if (typeof page === 'string') {
        page = parseInt(page, 10);
      }
      const name = section[1].replace(/rel="(.*)"/, '$1').trim();
      links[name] = page;
    });
    return links;
  }

  parseHtmlToString(html: string) {
    // Create a new div element
    const tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  public dataURItoBlob(dataURI: string | null): Blob | undefined {
    if (dataURI == null) return undefined;
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
  }

  public bytetoBlob(bytes, type) {
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: type });
  }

  public resizeImage({ file, maxSize }) {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
      let { width, height } = image;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);

      const dataUrl = canvas.toDataURL('image/jpeg');

      return this.dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }

      reader.onload = readerEvent => {
        image.onload = () => ok(resize());
        (<any>image).src = readerEvent.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  public blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob], fileName, { type: theBlob.type, lastModified: Date.now() });
  }

  public createSnapshotOfElement(element: string, posX: number, posY: number, convertedSnapshotCallback: (base64MapData: string) => void) {
    html2canvas(document.querySelector(element), {
      useCORS: true,
      allowTaint: false,
    }).then(canvas => {
      const context = canvas.getContext('2d');
      const imageData = context.getImageData(posX, posY, canvas.width, canvas.height).data;

      const outputCanvas = document.createElement('canvas');
      const outputContext = outputCanvas.getContext('2d');
      outputCanvas.width = canvas.width;
      outputCanvas.height = canvas.height;

      const outputIData = outputContext.createImageData(canvas.width, canvas.height);
      outputIData.data.set(imageData);
      outputContext.putImageData(outputIData, 0, 0);

      //resize
      let { width, height } = outputCanvas;
      const maxSize = 600;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }

      const canvasResult = document.createElement('canvas');
      canvasResult.width = width;
      canvasResult.height = height;
      canvasResult.getContext('2d').drawImage(outputCanvas, 0, 0, width, height);

      convertedSnapshotCallback(
        //use it to get the raw base64 data without format mention
        //outputCanvas.toDataURL().replace("data:image/png;base64,", "")
        // outputCanvas.toDataURL() //default
        canvasResult.toDataURL()
      );
    });
  }

  public formatFromBytes(a: number, b = 2): string {
    if (!+a) return '0 Bytes';
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`;
  }

  public gcd(a: number, b: number): number {
    if (b == 0) return a;
    return this.gcd(b, a % b);
  }

  public URL = window.URL || window.webkitURL;

  public setAttachmentWithRatio(files: File[], widthRatio: number, heightRatio: number, maxSize: number, callback: (att: ICbAtt) => void) {
    this.URL = window.URL || window.webkitURL;
    if (!files || files.length <= 0) {
      return;
    }
    if (!widthRatio && !heightRatio) {
      this.setAttachment({
        files,
        maxSize,
        onFileLoaded: callback,
      });
    } else {
      for (const element of files) {
        const file = element;
        const url = URL.createObjectURL(file);
        const type = file.type.split('/')[0];

        if (type == 'image' && /^image\//.test(file.type)) {
          const img = new Image();
          const reader = new FileReader();
          (<any>reader).customeData = file;
          reader.readAsDataURL(file);
          img.onload = _ => {
            const r = this.gcd(img.width, img.height);
            const rWidth = img.width / r;
            const rHeight = img.height / r;
            if (widthRatio == rWidth && heightRatio == rHeight) {
              this.toBase64(file, base64Data => {
                const rst = `data:${file.type};base64,${base64Data}`;
                const cbAtt: ICbAtt = new CbAtt();
                cbAtt.att = base64Data;
                cbAtt.attContentType = file.type;
                cbAtt.localUrl = this.base64ToBlobString(base64Data, file.type);
                cbAtt.name = file.name;
                cbAtt.blob = this.dataURItoBlob(rst);
                const size = this.size(base64Data);
                if (maxSize && size > maxSize) {
                  this.showToastOnMaxSizeExceeded(maxSize);
                  return;
                }
                callback(cbAtt);
              });
            } else {
              this.$root.$bvToast.toast(
                'Uploaded Video ' +
                  img.width +
                  ' x ' +
                  img.height +
                  ' ratio  = ' +
                  rWidth +
                  ':' +
                  rHeight +
                  ', should be ' +
                  widthRatio +
                  ' : ' +
                  heightRatio,
                {
                  toaster: 'b-toaster-top-center',
                  title: 'Warning',
                  variant: 'warning',
                  solid: true,
                  autoHideDelay: 5000,
                }
              );
            }
          };
          img.src = this.URL.createObjectURL(file); // ini penting buat masukin imagenya
        } else {
          const video = document.createElement('video');
          video.src = url;
          const that = this;
          video.addEventListener('loadedmetadata', function () {
            const reader = new FileReader();
            (<any>reader).customeData = file;
            reader.readAsDataURL(file);
            const width = this.videoWidth;
            const height = this.videoHeight;
            const r = that.gcd(width, height);
            const rWidth = width / r;
            const rHeight = height / r;
            reader.onload = function (e) {
              const target = e.target;

              if (widthRatio == rWidth && heightRatio == rHeight) {
                that.toBase64(file, async base64Data => {
                  const rst = `data:${file.type};base64,${base64Data}`;
                  const customeData: any = (<any>target).customeData;

                  const cbAtt: ICbAtt = new CbAtt();
                  cbAtt.att = base64Data;
                  cbAtt.attContentType = file.type;
                  cbAtt.localUrl = that.base64ToBlobString(base64Data, customeData.type);

                  cbAtt.name = file.name;
                  cbAtt.blob = that.dataURItoBlob(rst);
                  // semua yg jadi thumbnail max size lebih dari 500 akan auto keciin sizenya
                  cbAtt.thumbnail = await that.generateVideoThumbnail(file, 500);
                  cbAtt.thumbnailLocalUrl = that.base64ToBlobString(cbAtt.thumbnail.split(';base64,')[1], 'image/png');

                  const size = that.size(base64Data);
                  if (maxSize && size > maxSize) {
                    that.showToastOnMaxSizeExceeded(maxSize);
                    return;
                  }
                  callback(cbAtt);
                });
              } else {
                (<any>that.$root).$bvToast.toast(
                  'Uploaded Video ' +
                    width +
                    ' x ' +
                    height +
                    ' ratio  = ' +
                    rWidth +
                    ':' +
                    rHeight +
                    ', should be ' +
                    widthRatio +
                    ' : ' +
                    heightRatio,
                  {
                    toaster: 'b-toaster-top-center',
                    title: 'Warning',
                    variant: 'warning',
                    solid: true,
                    autoHideDelay: 5000,
                  }
                );
              }
            };
          });
          video.src = this.URL.createObjectURL(file);
        }
      }
    }
  }

  public setAttachment(params: SetAttachmentParams): void {
    this.URL = window.URL || window.webkitURL;
    if (params.files.length <= 0) return;
    const extList: string[] | undefined = params.allowedExtension?.replace(/[.\s]/g, '').split(',');
    const mimeTypesFromExtensions = this.getMimeTypesFromExtensions(extList);

    params.files.forEach(async f => {
      if (!this.fileCheck(f, params.maxSize, extList, mimeTypesFromExtensions)) {
        if (params.isInvalidFileCheck != null) {
          params.isInvalidFileCheck(true);
          return;
        } else {
          return;
        }
      }
      if (params.onLoadedAsFile) {
        params.onLoadedAsFile(f);
      } else if (params.skipThumbnail) {
        params.onFileLoaded(await this.convertFileIntoCbAtt(f, undefined, params.label));
      } else {
        // semua yg jadi thumbnail max size lebih dari 500 akan auto keciin sizenya
        params.onFileLoaded(await this.convertFileIntoCbAtt(f, await this.generateThumbnail(f, 500, params.onLoading), params.label));
      }
    });
  }

  public async setAttachmentToBlob(params: SetAttachmentParams): Promise<void> {
    this.URL = window.URL || window.webkitURL;
    if (params.files.length <= 0) return;

    const extList: string[] | undefined = params.allowedExtension?.replace(/[.\s]/g, '').split(',');
    const mimeTypesFromExtensions = this.getMimeTypesFromExtensions(extList);

    // Array untuk menyimpan semua promise async
    const promises: Promise<void>[] = [];

    params.files.forEach(async file => {
      if (!this.fileCheck(file, params.maxSize, extList, mimeTypesFromExtensions)) return;

      const promise = new Promise<void>(async (resolve, reject) => {
        try {
          const fileURL = URL.createObjectURL(file);
          // semua yg jadi thumbnail max size lebih dari 500 akan auto keciin sizenya
          const thumbnail = await this.generateThumbnail(file, 500, params.onLoading);
          const response = await fetch(fileURL);
          const blob = await response.blob();

          const ext = file.name.split('.').pop().toLowerCase();
          const cbAtt: ICbAtt = new CbAtt();

          cbAtt.attContentType = file.type === '' || file.type === null ? mimeMap[ext] : file.type;
          cbAtt.localUrl = fileURL;
          cbAtt.size = file.size;
          cbAtt.extension = ext;
          cbAtt.name = file.name;
          cbAtt.label = params.label;

          if (thumbnail) {
            cbAtt.thumbnail = thumbnail;
            cbAtt.thumbnailLocalUrl = this.base64ToBlobString(cbAtt.thumbnail.split(';base64,')[1], mimeMap[ext]);
          }
          cbAtt.blob = blob;

          params.onFileLoaded(cbAtt);

          resolve(); // Resolve promise setelah file diproses
        } catch (error) {
          this.showToast(this.$t('cbgwApp.cbComponent.validation.failedUploadFile').toString());
          console.error('Error fetching file:', error);
          reject(error); // Reject jika ada kesalahan
        }
      });

      promises.push(promise);
    });

    // Menunggu semua promise selesai sebelum melanjutkan
    await Promise.all(promises);
  }

  public async setAttachmentToUrl(params: SetAttachmentParams): Promise<void> {
    this.URL = window.URL || window.webkitURL;
    if (params.files.length <= 0) return;

    const extList: string[] | undefined = params.allowedExtension?.replace(/[.\s]/g, '').split(',');
    const mimeTypesFromExtensions = this.getMimeTypesFromExtensions(extList);

    // Array untuk menyimpan semua promise async
    const promises: Promise<void>[] = [];
    console.log(params.files.length, 'params.files');

    params.files.forEach(async file => {
      if (!this.fileCheck(file, params.maxSize, extList, mimeTypesFromExtensions)) return;

      const promise = new Promise<void>(async (resolve, reject) => {
        try {
          const fileURL = URL.createObjectURL(file);
          const ext = file.name.split('.').pop().toLowerCase();
          const cbAtt: ICbAtt = new CbAtt();

          cbAtt.attContentType = file.type === '' || file.type === null ? mimeMap[ext] : file.type;
          cbAtt.localUrl = fileURL;
          cbAtt.localFileUrl = fileURL;
          cbAtt.file = file;
          cbAtt.size = file.size;
          cbAtt.extension = ext;
          cbAtt.name = file.name;
          cbAtt.label = params.label;

          params.onFileLoaded(cbAtt);
          resolve(); // Resolve promise setelah file diproses
        } catch (error) {
          this.showToast(this.$t('cbgwApp.cbComponent.validation.failedUploadFile').toString());
          console.error('Error fetching file:', error);

          params.onFileLoaded(null);
          reject(error); // Reject jika ada kesalahan
        }
      });

      promises.push(promise);
    });

    // Menunggu semua promise selesai sebelum melanjutkan
    await Promise.all(promises);
  }

  public async setUrlToBlobAndThumbnail(params: SetAttachmentParams): Promise<void> {
    const promises: Promise<void>[] = [];

    params.cbPawOrderFiles.forEach(async orderFile => {
      const promise = new Promise<void>(async (resolve, reject) => {
        try {
          // semua yg jadi thumbnail max size lebih dari 500 akan auto keciin sizenya
          let thumbnail: any;
          if (orderFile.cbAtt.size <= MAX_SIZE_10MB) {
            thumbnail = await this.generateThumbnail(orderFile.cbAtt.file, 500, params.onLoading);
          }

          const response = await fetch(orderFile.cbAtt.localUrl);
          const blob = await response.blob();
          if (thumbnail) {
            orderFile.cbAtt.thumbnail = thumbnail;
            orderFile.cbAtt.thumbnailLocalUrl = this.base64ToBlobString(
              orderFile.cbAtt.thumbnail.split(';base64,')[1],
              mimeMap[orderFile.cbAtt.extension]
            );
          }
          orderFile.cbAtt.blob = blob;

          params.onFileLoaded(orderFile.cbAtt);
          resolve(); // Resolve promise setelah file diproses
        } catch (error) {
          this.showToast(this.$t('cbgwApp.cbComponent.validation.failedUploadFile').toString());
          console.error('Error fetching file:', error);

          params.onFileLoaded(orderFile.cbAtt);
          reject(error); // Reject jika ada kesalahan
        }
      });

      promises.push(promise);
    });

    // Menunggu semua promise selesai sebelum melanjutkan
    await Promise.all(promises);
  }

  public async setAttachmentMultipleBlob(params: SetAttachmentParams): Promise<void> {
    this.URL = window.URL || window.webkitURL;
    if (params.files.length <= 0) return;
    const extList: string[] | undefined = params.allowedExtension?.replace(/[.\s]/g, '').split(',');
    const mimeTypesFromExtensions = this.getMimeTypesFromExtensions(extList);
    const fileAttachments: ICbAtt[] = [];

    // Collect all promises
    const promises = params.files.map(async (file, index) => {
      if (!this.fileCheck(file, params.maxSize, extList, mimeTypesFromExtensions)) return;

      // semua yg jadi thumbnail max size lebih dari 500 akan auto keciin sizenya
      const thumbnail = await this.generateThumbnail(file, 500, params.onLoading);
      const ext = file.name.split('.').pop().toLowerCase();
      const fileURL = URL.createObjectURL(file);
      const cbAtt: ICbAtt = new CbAtt();

      cbAtt.attContentType = file.type === '' || file.type === null ? mimeMap[ext] : file.type;
      cbAtt.localUrl = fileURL;
      cbAtt.size = file.size;
      cbAtt.extension = ext;
      cbAtt.name = file.name;
      cbAtt.label = params.label;

      try {
        const response = await fetch(fileURL);
        const blob = await response.blob();

        cbAtt.blob = blob;

        if (thumbnail) {
          cbAtt.thumbnail = thumbnail;
          cbAtt.thumbnailLocalUrl = this.base64ToBlobString(cbAtt.thumbnail.split(';base64,')[1], mimeMap[ext]);
        }

        fileAttachments.push(cbAtt);
      } catch (error) {
        this.showToast(this.$t('cbgwApp.cbComponent.validation.failedUploadFile').toString());
        console.error('Error fetching file:', error);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    // Once all files are processed, call the callback
    params.onFileLoadedMultiple(fileAttachments);
  }

  private fileCheck(f: File, maxSize?: number, extList?: string[], mimeTypesFromExtensions?: string[]): boolean {
    const fileExt = f.name.split('.').pop().toLowerCase();

    const checkExt = extList?.includes(fileExt) ?? true;
    const checkSize = f.size > (maxSize ?? Infinity);
    let checkMime = mimeTypesFromExtensions?.length > 0 ? mimeTypesFromExtensions.includes(f.type) : true;

    if (
      ['mkv', 'wav', 'flv', 'numbers', 'pages', 'key', 'mid', 'midi', 'zip', 'm4v', 'ogg', '3gp'].includes(fileExt) ||
      (fileExt === 'ogg' && (f.type === 'audio/ogg' || f.type === 'video/ogg'))
    )
      checkMime = true;

    if (fileExt === 'rar') {
      checkMime = ['application/x-rar-compressed', 'application/rar', 'application/x-rar', ''].includes(f.type);
    }

    if (fileExt === 'avi') {
      checkMime = f.type === 'video/avi' || f.type === 'video/x-msvideo';
    }

    console.log(checkSize, 'checkSize');

    if (!checkMime || !checkExt) {
      this.showToastOnWrongExtension();
      return false;
    }
    if (checkSize) {
      console.log(checkSize, 'checkSize 2');
      this.showToastOnMaxSizeExceeded(maxSize);
      return false;
    }

    return true;
  }

  public openProcessFilesFlutter(options: FlutterOptions, callBackProcessFiles: (files: File[]) => void): void {
    Flutter.listen({
      handlerLabel: 'onGlobalUploadFile',
      callback: async (data: FlutterFileResponse[]) => {
        if (data.length === 0) return;
        callBackProcessFiles(data.map(e => e.file));
      },
      onError: (kind: string) => {
        if (kind === 'size') this.showToastOnMaxSizeExceeded(options.fileMaxSizeInMb);
        if (kind === 'extension') this.showToastOnWrongExtension();
        if (kind === 'duplicateNameMsg') this.showToastOnDuplicateName();
      },
    });
    Flutter.call('globalUploadFile', options);
  }

  public openFileFlutter(options: FlutterOptions, onFileLoaded?: (cbAtt: ICbAtt) => void, onLoadedAsFile?: (file: File) => void): void {
    console.log('cameraChat openFileFlutter');
    Flutter.listen({
      handlerLabel: 'onGlobalUploadFile',
      callback: (data: FlutterFileResponse[]) => {
        if (data.length === 0) return;

        console.log('cameraChat openFileFlutter SUKSES');

        this.setAttachment({
          files: data.map(e => e.file),
          allowedExtension: options.allowedExtensions,
          maxSize: options.fileMaxSizeInMb,
          onFileLoaded,
          onLoadedAsFile,
          label: options.label,
          skipThumbnail: options.skipThumbnail,
        });
      },
      onError: (kind: string) => {
        console.log('cameraChat openFileFlutter ERROR');
        if (kind === 'size') this.showToastOnMaxSizeExceeded(options.fileMaxSizeInMb);
        if (kind === 'extension') this.showToastOnWrongExtension();
        if (kind === 'duplicateNameMsg') this.showToastOnDuplicateName();
      },
    });
    Flutter.call('globalUploadFile', options);
  }

  public permissionCameraFlutter(): Promise<boolean> {
    console.log('cameraPermission is called from flutter');

    return new Promise(resolve => {
      Flutter.listen({
        handlerLabel: 'onCameraPermission',
        callback: (data: boolean) => {
          console.log('cameraPermission saya masuk ke sini bersama datanya :', data);
          resolve(data);
        },
      });

      Flutter.call('cameraPermission');
    });
  }

  public openProcessFilesFlutterOrder(
    options: FlutterOptions,
    callBackProcessFiles: (files: CbAttachmentRestrict[]) => void,
    callbackOnLoadingFiles: (isLoadingFile: boolean) => void
  ): void {
    Flutter.listenCbAttachmentRestrict({
      handlerLabel: 'onOrderUploadFile',
      callback: async (data: ICbAttachmentRestrict[], isLoadingFile: boolean) => {
        if (isLoadingFile) {
          callbackOnLoadingFiles(isLoadingFile);
        } else {
          callbackOnLoadingFiles(isLoadingFile);
          callBackProcessFiles(data);
        }
      },
      onError: (kind: string) => {
        if (kind === 'size') this.showToastOnMaxSizeExceeded(options.fileMaxSizeInMb);
        if (kind === 'extension') this.showToastOnWrongExtension();
        if (kind === 'duplicateNameMsg') this.showToastOnDuplicateName();
        if (kind === 'exceededFileAmount') this.showToastOnExceededFileAmount();
      },
    });
    Flutter.call('orderUploadFile', options);
  }

  public openFileFlutterBlob(options: FlutterOptions, onFileLoaded?: (cbAtt: ICbAtt) => void, onLoadedAsFile?: (file: File) => void): void {
    Flutter.listen({
      handlerLabel: 'onGlobalUploadFile',
      callback: (data: FlutterFileResponse[]) => {
        if (data.length === 0) return;

        this.setAttachmentToBlob({
          files: data.map(e => e.file),
          allowedExtension: options.allowedExtensions,
          maxSize: options.fileMaxSizeInMb,
          onFileLoaded,
          onLoadedAsFile,
          label: options.label,
          skipThumbnail: options.skipThumbnail,
        });
      },
      onError: (kind: string) => {
        if (kind === 'size') this.showToastOnMaxSizeExceeded(options.fileMaxSizeInMb);
        if (kind === 'extension') this.showToastOnWrongExtension();
        if (kind === 'duplicateNameMsg') this.showToastOnDuplicateName();
      },
    });
    Flutter.call('globalUploadFile', options);
  }

  public openFileFlutterMultipleBlob(
    options: FlutterOptions,
    onFileLoadedMultiple?: (cbAtts: ICbAtt[]) => void,
    onLoadedAsFile?: (file: File) => void
  ): void {
    Flutter.listen({
      handlerLabel: 'onGlobalUploadFile',
      callback: (data: FlutterFileResponse[]) => {
        if (data.length === 0) return;

        this.setAttachmentMultipleBlob({
          files: data.map(e => e.file),
          allowedExtension: options.allowedExtensions,
          maxSize: options.fileMaxSizeInMb,
          onFileLoadedMultiple,
          onLoadedAsFile,
          label: options.label,
          skipThumbnail: options.skipThumbnail,
        });
      },
      onError: (kind: string) => {
        if (kind === 'size') this.showToastOnMaxSizeExceeded(options.fileMaxSizeInMb);
        if (kind === 'extension') this.showToastOnWrongExtension();
        if (kind === 'duplicateNameMsg') this.showToastOnDuplicateName();
      },
    });
    Flutter.call('globalUploadFile', options);
  }

  private convertFileIntoCbAtt(file: File, thumbnail?: string, label?: string): Promise<ICbAtt> {
    return new Promise<ICbAtt>((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        try {
          const result = reader.result as string;
          const ext = file.name.split('.').pop().toLowerCase();
          const cbAtt: ICbAtt = new CbAtt();

          cbAtt.attContentType = file.type === '' || file.type === null ? mimeMap[ext] : file.type;
          cbAtt.localUrl = URL.createObjectURL(file);
          cbAtt.size = file.size;
          cbAtt.extension = ext;
          cbAtt.name = file.name;
          cbAtt.label = label;
          cbAtt.blob = this.dataURItoBlob(result);

          if (thumbnail) {
            cbAtt.thumbnail = thumbnail;
            cbAtt.thumbnailLocalUrl = this.base64ToBlobString(cbAtt.thumbnail.split(';base64,')[1], mimeMap[ext]);
          } else {
            cbAtt.att = result?.substring(result?.indexOf('base64,') + 'base64,'.length);
          }

          resolve(cbAtt);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = () => this.showToast(this.$t('cbgwApp.cbComponent.validation.failedUploadFile').toString());
    });
  }

  private showToast(msg: string): void {
    this.$root.$bvToast.toast(msg, {
      toaster: 'b-toaster-top-center',
      title: 'Warning',
      variant: 'warning',
      solid: true,
      autoHideDelay: 5000,
    });
  }

  private async generateThumbnail(
    file: File,
    maxSize: number,
    callbackLoading?: (isLoading: boolean) => void
  ): Promise<string | undefined> {
    if (callbackLoading != null) callbackLoading(true);

    try {
      let result: string | undefined;
      if (file.type === 'video/avi' || file.type === 'video/x-flv' || file.type === 'video/x-ms-wmv') return;

      if (isVideoFile(file.type)) {
        result = await this.generateVideoThumbnail(file, maxSize);
      } else if (isPdfFile(file.type)) {
        result = await this.generatePdfThumbnail(file, maxSize);
      }
      return result;
    } catch (error) {
      throw new Error(`Error : ${error}`);
    } finally {
      if (callbackLoading != null) callbackLoading(false);
    }
  }

  private getMimeTypesFromExtensions(extensions?: string[]): string[] | undefined {
    if (!extensions || extensions.length <= 0) return undefined;
    const mimeTypes: string[] = [];
    for (const extension of extensions) {
      const mimeType = mimeMap[extension];
      if (mimeType) mimeTypes.push(mimeType);
    }
    return mimeTypes;
  }

  public get textEllipsis() {
    return (value: string, limit: number, isEllipsis: boolean) => {
      if (isEllipsis && value.length > limit) {
        value = value.substring(0, limit) + '...';
      }
      return value;
    };
  }

  public downloadUrltoBinary(downloadLink: string, type: string): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${downloadLink}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {},
        })
        .then(signUrlRes => {
          let base64 = null;
          const blob = new Blob([signUrlRes.data], { type: signUrlRes.headers['content-type'] });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            base64 = reader.result;
            resolve(base64);
          };
        })
        .catch(signUrlErr => {
          reject(signUrlErr);
        });
    });
  }

  public b64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });

    return blob;
  }

  public localFilePathByContentType(contentType: string) {
    let path = '';
    if (
      contentType.match(regexFileType_MP3) ||
      contentType.match(regexFileType_MIDI) ||
      contentType.match(regexFileType_MID) ||
      contentType.match(regexFileType_WAV)
    ) {
      path = '@/../content/images/file-music.svg';
    }
    if (
      contentType.match(regexFileType_GIF) ||
      contentType.match(regexFileType_JPEG) ||
      contentType.match(regexFileType_JPG) ||
      contentType.match(regexFileType_SVG) ||
      contentType.match(regexFileType_PNG)
    ) {
      path = '@/../content/images/file-picture.svg';
    }
    if (
      contentType.match(regexFileType_3GP) ||
      contentType.match(regexFileType_AVI) ||
      contentType.match(regexFileType_FLV) ||
      contentType.match(regexFileType_MP4) ||
      contentType.match(regexFileType_MKV) ||
      contentType.match(regexFileType_MOV) ||
      contentType.match(regexFileType_MPG) ||
      contentType.match(regexFileType_MPEG) ||
      contentType.match(regexFileType_WMV)
    ) {
      path = '@/../content/images/file-video.svg';
    } else if (contentType.match(regexFileType_DOC) || contentType.match(regexFileType_DOCX) || contentType.match(regexFileType_PAGES)) {
      path = '@/../content/images/file-docs.svg';
    } else if (contentType.match(regexFileType_XLS) || contentType.match(regexFileType_XLSX) || contentType.match(regexMatchNumber)) {
      path = '@/../content/images/file-sheet.svg';
    } else if (contentType.match(regexFileType_PPT) || contentType.match(regexFileType_PPTX) || contentType.match(regexFileType_KEY)) {
      path = '@/../content/images/file-ppt.svg';
    } else if (contentType.match(regexFileType_PDF)) {
      path = '@/../content/images/file-pdf.svg';
    } else {
      path = '@/../content/images/file-other.svg';
    }
    return path;
  }

  public attemptPreviewDisplay({ localUrlImage }: { localUrlImage: string }): Promise<{ isPreviewVisible: boolean; errorMessage: string }> {
    return new Promise<{ isPreviewVisible: boolean; errorMessage: string }>((resolve, reject) => {
      const previewImage = new Image();
      previewImage.width = 100;
      previewImage.height = 100;
      previewImage.src = localUrlImage;

      console.log(previewImage, 'previewImage');

      const resultPreview = {
        isPreviewVisible: null,
        errorMessage: '',
      };

      previewImage.onload = () => {
        if (previewImage.width > 0 && previewImage.height > 0) {
          resultPreview.isPreviewVisible = true;
        } else {
          resultPreview.isPreviewVisible = false;
          resultPreview.errorMessage = 'Invalid image format.';
        }

        resolve(resultPreview);
      };

      previewImage.onerror = () => {
        resultPreview.isPreviewVisible = false;
        resultPreview.errorMessage = 'Error loading image.';
        reject(resultPreview);
      };
    });
  }

  public showToastOnMaxSizeExceeded(maxSize: number): void {
    this.showToast(
      this.$t('cbgwApp.cbComponent.validation.maxFileSize', {
        size: this.formatFromBytes(maxSize),
      }).toString()
    );
  }

  public async permissionLocationFlutter(callbackF: () => void) {
    console.log('locationPermssion is called from flutter');

    Flutter.listenPermissionLocation({
      handlerLabel: 'onLocationPermission',
      callback: async (data: string) => {
        if (data === 'granted') {
          callbackF();
        }
      },
      onError: (kind: string) => {
        console.error('location permission request 1::', kind);
      },
    });
    Flutter.call('locationPermission');
  }

  public showToastOnWrongExtension(): void {
    this.showToast(this.$t('cbgwApp.cbComponent.validation.invalidUploadFile').toString());
  }

  public showToastOnExceededFileAmount(): void {
    this.showToast(this.$t('cbgwApp.cbComponent.validation.maxUploadFileLength', { length: 10 }).toString());
  }

  public showToastOnDuplicateName(): void {
    this.showToast(this.$t('cbgwApp.cbComponent.validation.duplicateNameMsg').toString());
  }

  public showToastMaxFileUseWatermark10mbInApp(): void {
    this.showToast(this.$t('cbgwApp.cbComponent.validation.maximumFileUseWatermark10mbInApp').toString());
  }

  public showToastMaxFileUseWatermark100mbInWeb(): void {
    this.showToast(this.$t('cbgwApp.cbComponent.validation.maximumFileUseWatermark100mbInWeb').toString());
  }
}

interface SetAttachmentParams {
  files?: File[];
  maxSize?: number;
  allowedExtension?: string;
  onLoading?: (isLoading: boolean) => void;
  onFileLoaded?: (cbAtt: ICbAtt) => void;
  onFileLoadedMultiple?: (cbAtts: ICbAtt[]) => void;
  onLoadedAsFile?: (file: File) => void;
  label?: string;
  skipThumbnail?: boolean;
  isInvalidFileCheck?: (isInvalid: boolean) => void;
  cbPawOrderFiles?: CbPawOrderFile[];
}

const mimeMap: { [key: string]: string } = {
  pdf: 'application/pdf',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  bmp: 'image/bmp',
  tiff: 'image/tiff',
  tif: 'image/tiff',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  zip: 'application/zip',
  rar: 'application/x-rar',
  '7z': 'application/x-7z-compressed',
  txt: 'text/plain',
  csv: 'text/csv',
  rtf: 'application/rtf',
  psd: 'image/vnd.adobe.photoshop',
  ai: 'application/postscript',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  mov: 'video/quicktime',
  avi: 'video/avi',
  wmv: 'video/x-ms-wmv',
  flv: 'video/x-flv',
  ogg: 'audio/ogg',
  webm: 'video/webm',
  m4a: 'audio/m4a',
  m4v: 'video/m4v',
  '3gp': 'video/3gpp',
  '3g2': 'video/3gpp2',
  apk: 'application/vnd.android.package-archive',
  exe: 'application/octet-stream',
  svg: 'image/svg+xml',
  svgz: 'image/svg+xml',
  ico: 'image/x-icon',
  odt: 'application/vnd.oasis.opendocument.text',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odp: 'application/vnd.oasis.opendocument.presentation',
  odg: 'application/vnd.oasis.opendocument.graphics',
  odc: 'application/vnd.oasis.opendocument.chart',
  odf: 'application/vnd.oasis.opendocument.formula',
  odi: 'application/vnd.oasis.opendocument.image',
  odm: 'application/vnd.oasis.opendocument.text-master',
  ott: 'application/vnd.oasis.opendocument.text-template',
  ots: 'application/vnd.oasis.opendocument.spreadsheet-template',
  otp: 'application/vnd.oasis.opendocument.presentation-template',
  otg: 'application/vnd.oasis.opendocument.graphics-template',
  otc: 'application/vnd.oasis.opendocument.chart-template',
  otf: 'application/vnd.oasis.opendocument.formula-template',
  oti: 'application/vnd.oasis.opendocument.image-template',
  oth: 'application/vnd.oasis.opendocument.text-web',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  pages: 'application/x-iwork-pages-sffpages',
  numbers: 'application/x-iwork-numbers-sffnumbers',
  key: 'application/x-iwork-keynote-sffkey',
  midi: 'audio/midi',
  mkv: 'video/x-matroska',
  mid: 'audio/midi',
  wav: 'audio/x-wav',
  webp: 'image/webp',
  heic: 'image/heic',
  heif: 'image/heif',
  mts: 'video/mp2t',
  m2ts: 'video/mp2t',
  ts: 'video/mp2t',
  mxf: 'application/mxf',
  vob: 'video/x-ms-vob',
  java: 'text/x-java-source',
  json: 'application/json',
  js: 'application/javascript',
  css: 'text/css',
  html: 'text/html',
  htm: 'text/html',
  php: 'text/php',
  dart: 'application/dart',
  tsx: 'application/typescript',
  jsx: 'application/typescript',
  xml: 'application/xml',
  sh: 'application/x-sh',
  aac: 'audio/aac',
  bin: 'application/octet-stream',
  bz: 'application/x-bzip',
  bz2: 'application/x-bzip2',
  tar: 'application/x-tar',
  tgz: 'application/x-gzip',
  hevc: 'video/hevc',
  hdr: 'image/vnd.radiance',
  sql: 'application/sql',
  asf: 'video/x-ms-asf',
  mv4: 'video/x-m4v',
  dng: 'image/x-adobe-dng',
};
