import { IChtVwConversationCount } from './cht-vw-conversation-count.model';
import { ChtConversationType } from '@/shared/model/enumerations/cht-conversation-type.model';
import { App } from '@/shared/model/enumerations/app.model';
export interface IChtVwConversation {
  id?: number;
  name?: string | null;
  description?: string | null;
  avatarId?: number | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  conversationType?: ChtConversationType | null;
  secretConversationIdRef?: number | null;
  members?: string | null;
  membersDtl?: string | null;
  app?: App | null;
  lastMsgAuthor?: string | null;
  lastMsgDate?: Date | null;
  lastMsg?: string | null;
  lastMsgFileType?: string | null;
  countMsgs?: string | null;
  chtVwConversationCount?: IChtVwConversationCount | null;
  positionScrollMsgIds?: string | null;
  color?: string | null;
  refChtConversationId?: number | null;
  pinnedByMembers?: string | null;
  memberArrTransient?: string[] | null;
}

export class ChtVwConversation implements IChtVwConversation {
  constructor(
    public id?: number,
    public name?: string | null,
    public description?: string | null,
    public avatarId?: number | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public conversationType?: ChtConversationType | null,
    public secretConversationIdRef?: number | null,
    public members?: string | null,
    public membersDtl?: string | null,
    public app?: App | null,
    public lastMsgAuthor?: string | null,
    public lastMsgDate?: Date | null,
    public lastMsg?: string | null,
    public lastMsgFileType?: string | null,
    public countMsgs?: string | null,
    public chtVwConversationCount?: IChtVwConversationCount | null,
    public positionScrollMsgIds?: string | null,
    public color?: string | null,
    public refChtConversationId?: number | null,
    public pinnedByMembers?: string | null,
    public memberArrTransient?: string[] | null
  ) {}
}
