var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vac-message-actions-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "vac-message-action-item-container",
          class: _vm.message.senderId === _vm.currentUserId ? "right-me" : "",
          style: {
            display: _vm.hoverAudioProgress ? "none" : "initial",
            width:
              _vm.filteredMessageActions.length && _vm.showReactionEmojis
                ? "54px"
                : "45px",
          },
        },
        [
          _c(
            "transition-group",
            { attrs: { name: "vac-slide-left", tag: "span" } },
            [
              _vm.isMessageActions
                ? [
                    _c("message-action-item", {
                      key: "1",
                      attrs: {
                        currentUserId: _vm.currentUserId,
                        message: _vm.message,
                        messageActions: _vm.messageActions,
                      },
                      on: {
                        "message-action-handler": _vm.messageActionHandler,
                        handleShowMsgDelete: function ($event) {
                          return _vm.$emit("handleShowMsgDelete", {
                            ...$event,
                            messageId: _vm.message.id,
                          })
                        },
                        handleShowReactions: function ($event) {
                          return _vm.$emit("handleShowEmotReactions", {
                            ...$event,
                            messageId: _vm.message.id,
                          })
                        },
                      },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.message.senderId === _vm.currentUserId
        ? void 0
        : _c(
            "div",
            {
              staticClass: "vac-options-container",
              style: {
                display: _vm.hoverAudioProgress ? "none" : "initial",
                width:
                  _vm.filteredMessageActions.length && _vm.showReactionEmojis
                    ? "24px"
                    : "45px",
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "menu-btn d-flex justify-content-center align-items-center cursor-pointer",
                    attrs: { "data-cy": "vac-option-translate-btn" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("message-action-handler", "TRANSLATE")
                      },
                    },
                  },
                  [
                    _c("c-icons", {
                      staticClass: "icon-wrapper",
                      attrs: {
                        name: "cic_trasnlate",
                        width: "14px",
                        height: "14px",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
      _vm._v(" "),
      _vm.filteredMessageActions.length
        ? _c(
            "transition",
            {
              attrs: {
                name:
                  _vm.message.senderId === _vm.currentUserId
                    ? "vac-slide-left"
                    : "vac-slide-right",
              },
            },
            [
              _vm.optionsOpened
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeOptions,
                          expression: "closeOptions",
                        },
                      ],
                      ref: "menuOptions",
                      staticClass: "vac-menu-options",
                      class: {
                        "vac-menu-left":
                          _vm.message.senderId !== _vm.currentUserId,
                      },
                      style: { top: `${_vm.menuOptionsTop}px` },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vac-menu-list" },
                        _vm._l(_vm.filteredMessageActions, function (action) {
                          return _c("div", { key: action.name }, [
                            _c(
                              "div",
                              {
                                staticClass: "vac-menu-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.messageActionHandler(action)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(action.title) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }