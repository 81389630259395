import GlobalService from '@/services/global.service';
import { Component, Inject, Vue } from 'vue-property-decorator';

import { ModalStepFormOrderType } from '../model/enumerations/modal-step-form-order-type.model';
import { Flutter } from '@/app-flutter';

@Component
export default class NavigationUtils extends Vue {
  @Inject('globalService') public globalService: () => GlobalService;

  // main function
  public navigationPrevious() {
    Flutter.listenNavigation('onGlobalBackButton', () => {
      // function to close popup --haris
      const closePopup = (event: string, popupId: string) => {
        this.$root.$emit(event, popupId, false);
      };

      // modal v2
      const modalRegisterFreelancerTwoDeleteTitle = 'modal-register-freelancer-two-delete-title';
      const modalCRecheck = 'modal-c-recheck';
      const modalCChangeFile = 'modal-c-change-file';
      const modalInfoIdCard = 'modal-info-id-card';
      const modalInfoSelfie = 'modal-info-selfie';
      const modalInfoDiploma = 'modal-info-diploma';
      const modalCIntroductionSelectWorkerCategory = 'modal-c-introduction-select-worker-category';
      const modalCSetPassword = 'modal-c-set-password';
      const modalTermConditions = 'modal-term-conditions';
      const modalRegisterMTermOfService = 'modal-register-m-term-of-service';
      const modalRegisterMPrivacyPolicy = 'modal-register-m-privacy-policy';
      const modalCResetPassword = 'modal-c-reset-password';
      const modalCRequestedTicket = 'modal-c-requested-ticket';
      const modalCPhoneVerification = 'modal-c-phone-verification';
      const modalCPasswordVerification = 'modal-c-verification-password';
      const modalCAddCertificationPaw = 'modal-add-certification-paw';
      const modalCTermOfService = 'modal-term-of-service';
      const modalCCommunityGuidelines = 'modal-community-guidelines';
      const modalCProfileLocationEdit = 'modal-c-profile-location-edit';
      const modalCDeleteLanguage = 'modal-c-delete-language';
      const modalCDeleteEducation = 'modal-c-delete-education';
      const modalCDeleteCertification = 'modal-c-delete-certification';
      const modalCProfileFollow = 'modal-c-profile-follow';
      const modalCCheckStatus = 'modal-c-check-status';
      const modalCProfileCertificationEdit = 'modal-c-profile-certification-edit';
      const modalCProfileEducationEdit = 'modal-c-profile-education-edit';
      const modalCProfileLanguageEdit = 'modal-c-profile-language-edit';
      const modalCProfileSkillEdit = 'modal-c-profile-skill-edit';
      const modalCProfileChangePhoto = 'modal-c-profile-change-photo';
      const modalCProfileChangeCover = 'modal-c-change-cover';
      const modalCProfileDiscardChange = 'modal-c-discard-change';
      const modalCRoomCameraChat = 'modal-room-camera-chat';
      const modalCRoomLocationChat = 'modal-room-location-chat';
      const modalMediaDeleteNotif = 'modal-media-delete-notif';
      const modalCrmediaPreview = 'modal-crmedia-preview';
      const modalChtMediaPreview = 'modal-cht-media-preview';
      const modalChtMediaPreviewLiveChat = 'modal-cht-media-preview-live-chat';
      const modalWaitingReview = 'modal-dashboard-visibleReview';
      const modalIsShowYourRank = 'modal-dashboard-isShowYourRank';
      const modalCMediaPreview = 'modal-c-media-preview';
      const modalReviewApp = 'modal-review-app';
      const modalSurveyApp = 'modal-survey-app';
      const modalCLoader = 'modal-c-loader';
      const modalCSuccessful = 'modal-c-succesful';
      const modalCInfoBalanceDedicated = 'modal-c-info-balance-deducated';
      const modalCInfoInsufficientBalance = 'modal-c-insulficient-balance';
      const modalVisibleTimeRegionUpdate = 'modal-visible-time-region-update';
      const modalCChangeLanguage = 'modal-c-change-language';
      const modalPLoginMPopUp = 'modal-p-login-m-pop-up';
      const modalBillboardDetail = 'modal-billboard-detail';

      const modalConfirmationPawCreateOne = 'modal-confirmation-paw-create-one';
      const modalConfirmationPublishPawCreateThree = 'modal-confirmation-publish-paw-create-three';
      const modalConfirmationRejectRevisionRequest = 'modal-reject-revision-request';
      const modalConfirmationAcceptProjectDelivery = 'modal-accept-project-delivery';
      const modalConfirmationRejectExtraPrice = 'modal-confirmation-reject-extra-price';
      const modalConfirmationCCompleteProfile = 'modal-c-complete-profile';
      const modalConfirmationNotificationLocation = 'modal-confirmation-notification-location';
      const modalConfirmationNotificationCamera = 'modal-confirmation-notification-camera';
      const modalConfirmationHidePaw = 'modal-c-hide-paw';
      const modalConfirmationShowPaw = 'modal-c-show-paw';
      const modalConfirmationHibernateConfirm = 'modal-confirmation-hibernate-confirm';
      const modalConfirmationEndHibernate = 'modal-dashboard-visibleEndDhibernate';
      const modalConfirmationEndHibernateSuccess = 'modal-dashboard-visibleEndDhibernateSuccess';
      const modalConfirmationInsideCompleted = 'modal-is-show-confirmation-inside-completed';
      const modalConfirmationFieldCompleted = 'modal-is-show-confirm-field-completed';
      const modalConfirmationDeactiveApp = 'modal-confirmation-deactive-app';
      const modalConfirmationDeactiveConfirmSuccess = 'modal-confirmation-deactive-confirm-success';
      const modalConfirmationUserIdExistApp = 'modal-confirmation-user-id-exist-app';
      const modalConfirmationUserSuspendApp = 'modal-confirmation-user-suspend-app';

      // other v2
      const pageChatInfoPanel = 'chat-info-panel-chat-message';
      const pageRoomMedia = 'room-media-chat-message';
      const pageRoomSearchChat = 'room-search-chat-message';
      const pageRoomStarChat = 'room-star-chat-message';
      const elementSearchRoomChat = 'search-room-chat-wrap';
      const elementRoomSettingUser = 'room-setting-user';
      const elementManageOrderSearch = 'search-manage-order-wrap';
      const elementManagePawSearch = 'search-manage-paw-wrap';
      const elementManageRequestSearch = 'search-manage-request-wrap';
      const elementClockInCamera = 'form-clock-in-camera';
      const elementDetailOrderLocation = 'page-detail-order-location';
      const elementEmojiRoomChat = 'emojis-chat-message';

      // popup
      const popupRegisterMPhoneCode = this.checkDisplay(document.getElementById('popup-register-m-phone-code'));
      const popupCEditPhoneNumberCode = this.checkDisplay(document.getElementById('popup-c-edit-phone-number-code'));
      const popupRequestedTicketFilterSort = this.checkDisplay(document.getElementById('popup-requested-ticket-filter-sort'));
      const popupInfoGuarantee = this.checkDisplay(document.getElementById('popup-info-guarantee'));
      const popupInfoLeadTime = this.checkDisplay(document.getElementById('popup-info-lead-time'));
      const popupInfoTipsCreateTwoPaw = this.checkDisplay(document.getElementById('popup-info-tips-create-two-paw'));
      const popupSelectedInfoPackageCreatePawTwo = this.checkDisplay(document.getElementById('popup-selected-info-package-create-paw-two'));
      const popupOperationalHourTimepickerStart = this.checkDisplay(document.getElementById('popup-operational-hour-timepicker-start'));
      const popupOperationalHourTimepickerEnd = this.checkDisplay(document.getElementById('popup-operational-hour-timepicker-end'));
      const popupCProfileLanguageEdit = this.checkDisplay(document.getElementById('popup-c-profile-language-edit'));
      const popupCProfileSkillEdit = this.checkDisplay(document.getElementById('popup-c-profile-skill-edit'));
      const popupCProfileEducationEdit = this.checkDisplay(document.getElementById('popup-c-profile-education-edit'));
      const popupManageOrderSortList = this.checkDisplay(document.getElementById('popup-manage-order-sort-list'));
      const popupManagePawSortList = this.checkDisplay(document.getElementById('popup-manage-paw-sort-list'));
      const popupTimePicker = document.getElementById('popup-timepicker');
      const popupAuctionPitchPriceType = document.getElementById('popup-auction-pitch-price-type');
      const popupAuctionPitchDeliveryTimeType = document.getElementById('popup-auction-pitch-delivery-time-type');
      const popupOfferFilterBudget = document.getElementById('popup-offer-filter-budget');
      const popupOfferFilterDeliveryTime = document.getElementById('popup-offer-filter-delivery-time');
      const popupOfferFilterLocation = document.getElementById('popup-offer-filter-location');
      const popupOfferFilterSort = document.getElementById('popup-offer-filter-sort');
      const popupOfferFilterFreelancerLvl = document.getElementById('popup-offer-filter-freelancer-lvl');
      const popupLanguage = document.getElementById('popup-language');
      const popupWrapSvc = this.checkDisplay(document.getElementById('popup-svc'));
      const popupWrapSvcCat = this.checkDisplay(document.getElementById('popup-svc-cat'));
      const popupUpdateTool = this.checkDisplay(document.getElementById('popup-update-tool'));
      const popupUpdateTag = this.checkDisplay(document.getElementById('popup-update-tag'));
      const popupFLoatInfo = this.checkDisplay(document.getElementById('popup-float-info'));
      const popupAuctionSvc = document.getElementById('popup-auction-svc');
      const popupAuctionSvcCat = document.getElementById('popup-auction-svc-cat');
      const popupAuctionLocation = document.getElementById('popup-auction-location');
      const popupAuctionPriceType = document.getElementById('popup-auction-price-type');
      const popupGender = document.getElementById('popup-freelancer-gender');
      const popupNationality = document.getElementById('popup-freelancer-nationality');
      const popupIdType = document.getElementById('popup-freelancer-idtype');
      const popupPhoneType = document.getElementById('popup-freelancer-phonetype');
      const popupEmergencyRelation = document.getElementById('popup-freelancer-emergencyRelation');
      const popupEmergencyPhone = document.getElementById('popup-freelancer-emergencyPhone');
      const popupMap = document.getElementById('popup-freelancer-map');
      const popupCountry = document.getElementById('popup-freelancer-country');
      const popupProvince = document.getElementById('popup-freelancer-province');
      const popupCity = document.getElementById('popup-freelancer-city');
      const popupTimezone = document.getElementById('popup-freelancer-timezone');
      const popupFreelancerLanguage = document.getElementById('popup-freelancer-language');
      const popupLanguageLevel = document.getElementById('popup-freelancer-languageLevel');
      const popupUniversity = document.getElementById('popup-freelancer-univeristy');
      const popupMajor = document.getElementById('popup-freelancer-major');
      const popupSocialMedia = document.getElementById('popup-freelancer-socialmedia');
      const popupSortManageRequest = document.getElementById('popup-sort-manage-request');
      const popupEditDueDateRequest = document.getElementById('popup-edit-due-date');
      const popupBottomDetailPackageRegular = this.checkDisplay(document.getElementById('popup-bottom-detail-package-regular'));
      const popupBottomDetailPackagePremium = this.checkDisplay(document.getElementById('popup-bottom-detail-package-premium'));
      const componentSeeAllPortfolio = this.checkDisplay(document.getElementById('component-see-all-portfolio'));
      const componentShowPortfolio = this.checkDisplay(document.getElementById('component-show-portfolio'));
      const componentSeeAllLicense = this.checkDisplay(document.getElementById('component-see-all-license'));
      const componentSeeAllReference = this.checkDisplay(document.getElementById('component-see-all-reference'));
      const componentSeeAllReview = this.checkDisplay(document.getElementById('component-see-all-review'));

      // modal
      const modalRegisterFreelancerOneChangeWorkerSvc = document.getElementById('modal-register-freelancer-one-change-worker-svc');
      const modalCPawBookingDate = document.getElementById('modal-c-paw-booking-date');
      const modalCPawSetWorkLocation = document.getElementById('modal-c-paw-set-work-location');
      const modalCAuctionProject = document.getElementById('modal-c-auction-project');
      const modalCShare = document.getElementById('modal-c-share');
      const modalCReport = document.getElementById('modal-c-report');
      const modalPawDetail = document.getElementById('modal-paw-detail');
      const modalCItemCardOffer = document.getElementById('modal-c-item-card-offer');
      const modalCItemAuctionProjectPreOfferHover = document.getElementById('modal-c-item-auction-project-pre-offer-hover');
      const modalChatCSelectPaw = document.getElementById('modal-is-show-select-vw-paw');
      const modalChatCSelectPackage = document.getElementById('modal-is-show-select-package');
      const modalChatCCustomPackage = document.getElementById('modal-is-show-pkg-custom');
      const modalChatCAddExtras = document.getElementById('modal-is-show-add-extras');
      const modalChatCOfferSummary = document.getElementById('modal-is-show-offer-summary');
      const modalOfferCPawBookingDate = document.getElementById('modal-is-show-paw-booking-date');
      const modalOfferCPawSetWorkLocation = document.getElementById('modal-is-show-work-location');
      const modalFormPayment = document.getElementById('modal-is-show-form-payment-top-up');
      const modalCompletePaymentVA = document.getElementById('modal-is-show-va');
      const modalIsShowMomPreview = document.getElementById('modal-is-show-mom-preview');
      const modalCSetAvailability = document.getElementById('modal-c-set-availability');
      const modalIsShowFormPayment = document.getElementById('modal-is-show-form-payment');
      const modalCInviteFriend = document.getElementById('modal-c-invite-friend');
      const modalValidateActiveOrderHibernate = document.getElementById('csa-modal-validate-active-order-hibernate');
      const modalHibernateSuccess = document.getElementById('dashboard-modal-hibernate-success');
      const modalFinishOrder = document.getElementById('cad-modal-finish-order');
      const modalFinishOrderDelete = document.getElementById('cad-modal-finish-order-delete');
      const modalDeactiveDelete = document.getElementById('modal-deactive-delete');
      const modalInfoSetPin = document.getElementById('modal-info-set-pin');
      const modalPinSuccessUpdate = document.getElementById('app-modal-pin-success-update');
      const modalCResetPinInit = document.getElementById('modal-c-reset-pin-init');
      const modalWeGonnaMissYou = document.getElementById('cad-modal-we-gonna-miss-you');
      const modalWeGonnaMissYouDelete = document.getElementById('cad-modal-we-gonna-miss-delete');
      const modalCNewPhoneNumber = document.getElementById('modal-c-new-phone-number');
      const modalCSetChangePhone = document.getElementById('modal-c-set-change-phone');
      const modalCSuccessChangePhoneNumber = document.getElementById('modal-c-success-change-phone-number');
      const modalCChangePasswordInit = document.getElementById('modal-c-change-password-init');
      const modalSuccessUpdatePassword = document.getElementById('modal-success-update-password');
      const modalNotifBankdeletItem = document.getElementById('modal-notif-bank-delet-item');
      const modalIsShowFormPaymentTopUp = this.checkDisplay(document.getElementById('modal-is-show-form-payment-top-up'));
      const modalCCardPromoDetail = document.getElementById('modal-c-card-promo-detail');
      const modalChatXenditCC = document.getElementById('modal-is-show-xendit-cc');
      const modalIsShowMomEdit = document.getElementById('modal-is-show-mom-edit');
      const modalIsShowUploadProjectDelivery = document.getElementById('modal-is-show-upload-project-delivery');
      const modalIsShowOrderProjectDelivery = document.getElementById('modal-c-order-project-delivery');
      const modalIsShowMilestone = document.getElementById('modal-is-show-milestone');
      const modalIsShowMilestoneFile = document.getElementById('modal-is-show-milestone-file');
      const modalIsShowEditWatermark = document.getElementById('modal-is-show-edit-watermark');
      const modalIsShowAppointmentLocation = document.getElementById('modal-is-show-appointment-location');
      const modalIsShowAppointmentBookingDate = document.getElementById('modal-is-show-appointment-booking-date');
      const modalIsShowAppointmentForm = document.getElementById('modal-is-show-appointment-form');
      const modalIsShowAppointmentSummary = document.getElementById('modal-is-show-appointment-summary');
      const modalIsShowAppointmentReminder = document.getElementById('modal-is-show-appointment-reminder');
      const modalIsShowFieldClockInSelfie = document.getElementById('modal-is-show-clock-in-selfie');
      const modalIsShowFieldClockInLocation = document.getElementById('modal-is-show-clock-in-location');
      const modalIsShowUploadFieldClockOut = document.getElementById('modal-is-show-upload-field-clock-out');
      const modalIsShowExtendTime = document.getElementById('modal-is-show-extend-time');
      const modalEditPhoto = document.getElementById('modal-c-edit-profile-photo');
      const modalCDelete = document.getElementById('modal-c-delete');
      const modalCCancelRequest = document.getElementById('modal-c-cancel-request');
      const modalCEditDuaDateRequest = document.getElementById('modal-c-edit-duedate');

      const modalCConfirmationUnlinkedAccount = document.getElementById('modal-c-confirmation-unlinked-account');
      const modalConfirmationDeactiveAccountDelete = document.getElementById('cad-modal-confirmation-deactivate-account-delete');
      const modalConfirmationService = document.getElementById('modal-confirmation-paw-create-one');
      const modalConfirmationAcceptMom = document.getElementById('modal-confirmation-accept-mom');
      const modalConfirmationDeclineMom = document.getElementById('modal-confirmation-decline-mom');
      const modalConfirmationSendEmailPin = document.getElementById('app-modal-confirmation-send-email-pin');
      const modalConfirmationLimitPerks = document.getElementById('modal-confirmation-limit-perks');
      const modalConfirmationCancelNoRefund = document.getElementById('modal-is-show-confirm-cancel-no-refund');
      const modalConfirmationCancelWithRefund = document.getElementById('modal-is-show-confirm-cancel-with-refund');
      const modalConfirmationSendProjectDelivery = document.getElementById('modal-is-show-confirmation-send-project-delivery');

      // other
      const elementPaymentTopUpAccountBalance = document.getElementById('payment-topup-account-balance');
      const elementPaymentVATypeFormPayment = document.getElementById('payment-va-type-FORM_PAYMENT');

      //  popup condition
      if (popupLanguage) {
        this.$root.$emit('AccountUpdate::changeValue', 'popUpLanguage', false);
      } else if (popupRegisterMPhoneCode) {
        this.$root.$emit('registeM::changeValue', 'isShowPhoneCode', false);
      } else if (popupTimePicker || popupOperationalHourTimepickerStart || popupOperationalHourTimepickerEnd) {
        this.$root.$emit('timepicker::changeValue', 'isShowPopup', false);
      } else if (popupOfferFilterBudget) {
        this.$root.$emit('requestOffer::changeValue', 'isShowBudget', false);
      } else if (popupOfferFilterDeliveryTime) {
        this.$root.$emit('requestOffer::changeValue', 'isShowDeliveryTime', false);
      } else if (popupOfferFilterLocation) {
        this.$root.$emit('requestOffer::changeValue', 'isShowLocation', false);
      } else if (popupOfferFilterSort) {
        this.$root.$emit('requestOffer::changeValue', 'isShowSort', false);
      } else if (popupOfferFilterFreelancerLvl) {
        this.$root.$emit('requestOffer::changeValue', 'isShowFreelancerlvl', false);
      } else if (popupWrapSvcCat) {
        this.$root.$emit('pawCreateOnePopup::changeValue', 'visiblePopupSvcCat', false);
      } else if (popupWrapSvc) {
        this.$root.$emit('pawCreateOnePopup::changeValue', 'visiblePopupSvc', false);
      } else if (popupUpdateTool) {
        this.$root.$emit('pawCreateOnePopup::changeValue', 'visiblePopupTool', false);
      } else if (popupUpdateTag) {
        this.$root.$emit('pawCreateOnePopup::changeValue', 'visiblePopupTag', false);
      } else if (popupFLoatInfo) {
        this.$root.$emit('pawCreateOnePopup::changeValue', 'selectedFloatInfo', null);
      } else if (popupAuctionSvc) {
        closePopup('formAuctionPopup::changeValue', 'visiblePopupSvc');
      } else if (popupAuctionSvcCat) {
        closePopup('formAuctionPopup::changeValue', 'visiblePopupSvcCat');
      } else if (popupAuctionLocation) {
        closePopup('formAuctionPopup::changeValue', 'visiblePopupLocation');
      } else if (popupAuctionPriceType) {
        closePopup('formAuctionPopup::changeValue', 'visiblePopupPriceType');
      } else if (popupGender) {
        closePopup('registOnePopup:changeValue', 'popUpGender');
      } else if (popupNationality) {
        closePopup('registOnePopup:changeValue', 'popUpNationality');
      } else if (popupIdType) {
        closePopup('registOnePopup:changeValue', 'popUpIdType');
      } else if (popupPhoneType) {
        closePopup('registOnePopup:changeValue', 'popUpPhoneType');
      } else if (popupEmergencyRelation) {
        closePopup('registOnePopup:changeValue', 'popUpEmergencyReationship');
      } else if (popupEmergencyPhone) {
        closePopup('registOnePopup:changeValue', 'popUpEmergencyPhoneType');
      } else if (popupMap) {
        closePopup('registOnePopup:changeValue', 'popUpMap');
      } else if (popupCountry) {
        closePopup('registOnePopup:changeValue', 'popUpCountry');
      } else if (popupProvince) {
        closePopup('registOnePopup:changeValue', 'popUpProvince');
      } else if (popupCity) {
        closePopup('registOnePopup:changeValue', 'popUpCity');
      } else if (popupTimezone) {
        closePopup('registOnePopup:changeValue', 'popUpTimezone');
      } else if (popupFreelancerLanguage) {
        closePopup('registOnePopup:changeValue', 'popUpLanguage');
      } else if (popupLanguageLevel) {
        closePopup('registOnePopup:changeValue', 'popUpLanguageLevel');
      } else if (popupUniversity) {
        closePopup('registOnePopup:changeValue', 'popUpEducationUniversity');
      } else if (popupMajor) {
        closePopup('registOnePopup:changeValue', 'popUpEducationMajor');
      } else if (popupSocialMedia) {
        closePopup('registOnePopup:changeValue', 'popUpSocialMedia');
      } else if (popupSortManageRequest) {
        this.$root.$emit('manageRequestM::changeValue', 'isShowBottomSheet', false);
      } else if (popupEditDueDateRequest) {
        this.$root.$emit('manageRequestM::changeValue', 'togglePopupEditDueDate', false);
      } else if (popupCEditPhoneNumberCode) {
        this.$root.$emit('cEditPhoneNumber::changeValue', 'isShowPhoneCode', false);
      } else if (popupRequestedTicketFilterSort) {
        this.$root.$emit('requestedTicket::changeValue', 'isShowBottomSheet', false);
      } else if (popupInfoGuarantee) {
        this.$root.$emit('formAuctionPopup::changeValue', 'visiblePopupInfoGuarantee', false);
      } else if (popupInfoLeadTime) {
        this.$root.$emit('formAuctionPopup::changeValue', 'visiblePopupInfoLeadTime', false);
      } else if (popupInfoTipsCreateTwoPaw) {
        this.$root.$emit('pawCreateTwoM::changeValue', 'isShowTips', false);
      } else if (popupSelectedInfoPackageCreatePawTwo) {
        this.$root.$emit('pawCreateTwoMPackage::changeValue', 'selectedFloatInfo', null);
      } else if (popupCProfileLanguageEdit) {
        this.$root.$emit('profile::changeValue', 'isShowLanguageEdit', false);
      } else if (popupCProfileSkillEdit) {
        this.$root.$emit('profile::changeValue', 'isShowSkillEdit', false);
      } else if (popupCProfileEducationEdit) {
        this.$root.$emit('profile::changeValue', 'isShowEducationEdit', false);
      } else if (popupManagePawSortList) {
        this.$root.$emit('managePawM::changeValue', 'isShowBottomSheet', false);
      } else if (popupManageOrderSortList) {
        this.$root.$emit('manageOrderM::changeValue', 'isShowBottomSheet', false);
      } else if (popupBottomDetailPackageRegular) {
        this.$root.$emit('pawDetail::changeValue', 'isShowDetailPackage', false);
      } else if (popupBottomDetailPackagePremium) {
        this.$root.$emit('pawDetail::changeValue', 'isShowDetailPackage', false);
      } else if (componentSeeAllPortfolio) {
        this.$root.$emit('pawDetail::changeValue', 'isSeeAllPortfolio', false);
      } else if (componentShowPortfolio) {
        this.$root.$emit('pawDetail::changeValue', 'isShowPortfolio', false);
      } else if (componentSeeAllLicense) {
        this.$root.$emit('pawDetail::changeValue', 'isSeeAllLicense', false);
      } else if (componentSeeAllReference) {
        this.$root.$emit('pawDetail::changeValue', 'isSeeAllReference', false);
      } else if (componentSeeAllReview) {
        this.$root.$emit('pawDetail::changeValue', 'isSeeAllReview', false);
      }

      // modal condition
      else if (modalEditPhoto) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-edit-profile-photo');
      } else if (modalCCardPromoDetail) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-card-promo-detail');
      } else if (modalWeGonnaMissYouDelete) {
        this.$root.$emit('CAccountDeactive::logout');
      } else if (modalWeGonnaMissYou) {
        this.$root.$emit('CAccountDeactive::logout');
      } else if (modalChatCSelectPaw) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowSelectVwPaw);
      } else if (modalChatCSelectPackage) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowSelectPackage);
      } else if (modalChatCCustomPackage) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowPkgCustom);
      } else if (modalChatCAddExtras) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowAddExtras);
      } else if (modalChatCOfferSummary) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowOfferSummary);
      } else if (modalOfferCPawSetWorkLocation) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowWorkLocation);
      } else if (modalOfferCPawBookingDate) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowPawBookingDate);
      } else if (modalCPawSetWorkLocation) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-paw-set-work-location');
      } else if (modalCPawBookingDate) {
        // for global modal
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-paw-booking-date');
        this.globalService().openGlobalDialog(this.$root, 'modal-c-paw-set-work-location');

        // modal in request offer
        this.$root.$emit('requestOffer::changeValue', 'isShowPawBookingDate', false);
        (<any>this.$root).$emit('requestOffer::changeFunction', 'onBackModal');
      } else if (modalFormPayment || modalIsShowFormPaymentTopUp) {
        if (elementPaymentTopUpAccountBalance) {
          this.$root.$emit('formPayment::changeFunction', 'cancel');
        } else {
          this.$root.$emit('appModals::changeValue', 'visibleFormPayment2023', false);
          this.$root.$emit('mdl::changeValue', 'visibleFormPayment2023', false);
          this.$root.$emit('domdl::changeValue', 'visibleFormPayment2023', false);
          this.$root.$emit('auctionProject::changeValue', 'visibleFormPayment2023', false);
          this.$root.$emit('dashboard::changeValue', 'visibleFormPayment2023', false);
          this.globalService().closeGlobalDialog(this.$root, 'modal-is-show-form-payment-top-up');
        }
      } else if (modalChatXenditCC) {
        this.$root.$emit('mdl::changeValue', 'visibleXenditCC', false);
        this.$root.$emit('domdl::changeValue', 'visibleXenditCC', false);
        this.$root.$emit('mdl::changeValue', 'visibleFormPayment2023', true);
        this.$root.$emit('domdl::changeValue', 'visibleFormPayment2023', true);
        this.globalService().closeGlobalDialog(this.$root, 'modal-is-show-xendit-cc');
      } else if (modalCompletePaymentVA) {
        if (elementPaymentVATypeFormPayment) {
          this.$root.$emit('completeVA::changeFunction', 'cancel');
        } else {
          this.$root.$emit('appModals::changeValue', 'visibleVA', false);
          this.$root.$emit('mdl::changeValue', 'visibleVA', false);
          this.$root.$emit('domdl::changeValue', 'visibleVA', false);
          this.globalService().closeGlobalDialog(this.$root, 'modal-is-show-va');
        }
      } else if (modalIsShowMomPreview) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowMomPreview', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowMomPreview', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowMomPreview);
      } else if (modalIsShowMomEdit) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowMomEdit', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowMomEdit', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowMomEdit);
      } else if (modalIsShowUploadProjectDelivery) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowUploadProjectDelivery', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowUploadProjectDelivery', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowUploadProjectDelivery);
      } else if (modalConfirmationAcceptMom) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowConfirmationAcceptMom);
      } else if (modalConfirmationDeclineMom) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowConfirmationDeclinedMom);
      } else if (modalIsShowOrderProjectDelivery) {
        if (this.elementById(modalConfirmationAcceptProjectDelivery)) {
          this.closeModal(modalConfirmationAcceptProjectDelivery);
        } else if (this.elementById(modalConfirmationRejectRevisionRequest)) {
          this.closeModal(modalConfirmationRejectRevisionRequest);
        } else if (this.elementById(modalConfirmationRejectExtraPrice)) {
          this.closeModal(modalConfirmationRejectExtraPrice);
        } else {
          (<any>this.$root).$emit('mdl::changeValue', 'isShowProjectDelivery', false);
          (<any>this.$root).$emit('domdl::changeValue', 'isShowProjectDelivery', false);
          this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowProjectDelivery);
        }
      } else if (modalIsShowMilestone) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowMilestone', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowMilestone', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowMilestone);
      } else if (modalIsShowMilestoneFile) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowMilestoneFile', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowMilestoneFile', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowMilestoneFile);
      } else if (modalIsShowEditWatermark) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowEditWatermark', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowEditWatermark', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowEditWatermark);
      } else if (modalIsShowAppointmentLocation) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowAppointmentLocation', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowAppointmentLocation', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowAppointmentLocation);
      } else if (modalIsShowAppointmentBookingDate) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowAppointmentBookingDate', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowAppointmentBookingDate', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowAppointmentBookingDate);
      } else if (modalIsShowAppointmentForm) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowAppointmentForm', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowAppointmentForm', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowAppointmentForm);
      } else if (modalIsShowAppointmentSummary) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowAppointmentSummary', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowAppointmentSummary', false);
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowAppointmentSummary);
      } else if (modalIsShowAppointmentReminder) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowAppointmentReminder', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowAppointmentReminder', false);
      } else if (modalIsShowFieldClockInSelfie) {
        (<any>this.$root).$emit('doLocation::changeValue', 'isShowSelfie', false);
      } else if (modalIsShowFieldClockInLocation) {
        (<any>this.$root).$emit('doLocation::changeValue', 'isShowLocation', false);
      } else if (modalIsShowUploadFieldClockOut) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowUploadFieldClockOut', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowUploadFieldClockOut', false);
      } else if (modalIsShowExtendTime) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowExtendTime', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowExtendTime', false);
      } else if (modalConfirmationCancelNoRefund) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowConfirmCancelNoRefund', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowConfirmCancelNoRefund', false);
      } else if (modalConfirmationCancelWithRefund) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowConfirmCancelWithRefund', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowConfirmCancelWithRefund', false);
      } else if (modalConfirmationSendProjectDelivery) {
        this.callOnBackModalIncludesStepFormOrderType(ModalStepFormOrderType.isShowConfirmationSendProjectDelivery);
      } else if (modalCAuctionProject) {
        (<any>this.$root).$emit('cap::toggleOpenOfferShow', toggleOpenOffer => {
          if (toggleOpenOffer) {
            (<any>this.$root).$emit('cap::toggleOpenOffer', false);
          } else if (popupAuctionPitchPriceType) {
            this.$root.$emit('formApPitchPopup::changeValue', 'visiblePopupPriceType', false);
          } else if (popupAuctionPitchDeliveryTimeType) {
            this.$root.$emit('formApPitchPopup::changeValue', 'visiblePopupDeliveryTimeType', false);
          } else if (modalCShare) {
            this.globalService().closeGlobalDialog(this.$root, 'modal-c-share');
          } else if (modalCItemCardOffer) {
            this.globalService().closeGlobalDialog(this.$root, 'modal-c-item-card-offer');
          } else if (modalCAuctionProject) {
            this.globalService().closeGlobalDialog(this.$root, 'modal-c-auction-project');
          } else {
            this.$router.go(-1);
          }
        });
      } else if (modalConfirmationLimitPerks) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-confirmation-limit-perks');
      } else if (modalConfirmationService) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-confirmation-paw-create-one');
      } else if (modalNotifBankdeletItem) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-notif-bank-delet-item');
      } else if (modalSuccessUpdatePassword) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-success-update-password');
      } else if (modalCChangePasswordInit) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-change-password-init');
      } else if (modalConfirmationDeactiveAccountDelete) {
        this.globalService().closeGlobalDialog(this.$root, 'cad-modal-confirmation-deactivate-account-delete');
      } else if (modalCSuccessChangePhoneNumber) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-success-change-phone-number');
      } else if (modalCSetChangePhone) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-set-change-phone');
      } else if (modalCNewPhoneNumber) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-new-phone-number');
      } else if (modalCResetPinInit) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-reset-pin-init');
      } else if (modalPinSuccessUpdate) {
        this.globalService().closeGlobalDialog(this.$root, 'app-modal-pin-success-update');
      } else if (modalConfirmationSendEmailPin) {
        this.globalService().closeGlobalDialog(this.$root, 'app-modal-confirmation-send-email-pin');
      } else if (modalInfoSetPin) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-info-set-pin');
      } else if (modalDeactiveDelete) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-deactive-delete');
      } else if (modalFinishOrderDelete) {
        this.globalService().closeGlobalDialog(this.$root, 'cad-modal-finish-order-delete');
      } else if (modalFinishOrder) {
        this.globalService().closeGlobalDialog(this.$root, 'cad-modal-finish-order');
      } else if (modalCConfirmationUnlinkedAccount) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-confirmation-unlinked-account');
      } else if (this.elementById(modalConfirmationEndHibernate)) {
        this.globalService().closeGlobalDialog(this.$root, modalConfirmationEndHibernate);
        // (<any>this.$root).$emit('dashboard::changeValue', 'visibleEndDhibernate', false);
      } else if (this.elementById(modalWaitingReview)) {
        this.globalService().closeGlobalDialog(this.$root, modalWaitingReview);
      } else if (this.elementById(modalIsShowYourRank)) {
        this.globalService().closeGlobalDialog(this.$root, modalIsShowYourRank);
      } else if (this.elementById(modalConfirmationEndHibernateSuccess)) {
        this.globalService().closeGlobalDialog(this.$root, modalConfirmationEndHibernateSuccess);
      } else if (modalCShare) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-share');
      } else if (modalCReport) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-report');
      } else if (modalPawDetail) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-paw-detail');
      } else if (modalCItemAuctionProjectPreOfferHover) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-item-auction-project-pre-offer-hover');
      } else if (modalHibernateSuccess) {
        this.globalService().closeGlobalDialog(this.$root, 'dashboard-modal-hibernate-success');
      } else if (modalValidateActiveOrderHibernate) {
        this.globalService().closeGlobalDialog(this.$root, 'csa-modal-validate-active-order-hibernate');
      } else if (modalCSetAvailability) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-set-availability');
      } else if (modalIsShowFormPayment) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-is-show-form-payment');
      } else if (modalCInviteFriend) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-invite-friend');
      } else if (modalCDelete) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-delete');
      } else if (modalCCancelRequest) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-cancel-request');
      } else if (modalCEditDuaDateRequest) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-edit-duedate');
      } else if (modalRegisterFreelancerOneChangeWorkerSvc) {
        this.globalService().closeGlobalDialog(this.$root, 'modal-register-freelancer-one-change-worker-svc');
      } else if (this.elementById(modalCResetPassword)) {
        this.closeModal(modalCResetPassword);
      } else if (this.elementById(modalRegisterFreelancerTwoDeleteTitle)) {
        this.closeModal(modalRegisterFreelancerTwoDeleteTitle);
      } else if (this.elementById(modalCChangeFile)) {
        this.closeModal(modalCChangeFile);
      } else if (this.elementById(modalCRecheck)) {
        this.closeModal(modalCRecheck);
      } else if (this.elementById(modalInfoIdCard)) {
        this.closeModal(modalInfoIdCard);
      } else if (this.elementById(modalInfoSelfie)) {
        this.closeModal(modalInfoSelfie);
      } else if (this.elementById(modalInfoDiploma)) {
        this.closeModal(modalInfoDiploma);
      } else if (this.elementById(modalCIntroductionSelectWorkerCategory)) {
        this.closeModal(modalCIntroductionSelectWorkerCategory);
      } else if (this.elementById(modalCSetPassword)) {
        this.closeModal(modalCSetPassword);
      } else if (this.elementById(modalTermConditions)) {
        this.closeModal(modalTermConditions);
      } else if (this.elementById(modalRegisterMTermOfService)) {
        this.closeModal(modalRegisterMTermOfService);
      } else if (this.elementById(modalRegisterMPrivacyPolicy)) {
        this.closeModal(modalRegisterMPrivacyPolicy);
      } else if (this.elementById(modalConfirmationPawCreateOne)) {
        this.closeModal(modalConfirmationPawCreateOne);
        this.$root.$emit('pawCreateOne::changeValue', 'isShowConfirmContinue', false);
      } else if (this.elementById(modalConfirmationPublishPawCreateThree)) {
        this.closeModal(modalConfirmationPublishPawCreateThree);
      } else if (this.elementById(modalCRequestedTicket)) {
        this.closeModal(modalCRequestedTicket);
      } else if (this.elementById(modalCPhoneVerification)) {
        this.closeModal(modalCPhoneVerification);
      } else if (this.elementById(modalCPasswordVerification)) {
        this.closeModal(modalCPasswordVerification);
      } else if (this.elementById(modalCAddCertificationPaw)) {
        this.closeModal(modalCAddCertificationPaw);
      } else if (this.elementById(modalCTermOfService)) {
        this.closeModal(modalCTermOfService);
      } else if (this.elementById(modalCCommunityGuidelines)) {
        this.closeModal(modalCCommunityGuidelines);
      } else if (this.elementById(modalCProfileLocationEdit)) {
        this.closeModal(modalCProfileLocationEdit);
      } else if (this.elementById(modalCDeleteLanguage)) {
        this.closeModal(modalCDeleteLanguage);
      } else if (this.elementById(modalCDeleteEducation)) {
        this.closeModal(modalCDeleteEducation);
      } else if (this.elementById(modalCDeleteCertification)) {
        this.closeModal(modalCDeleteCertification);
      } else if (this.elementById(modalCProfileFollow)) {
        this.closeModal(modalCProfileFollow);
      } else if (this.elementById(modalCCheckStatus)) {
        this.closeModal(modalCCheckStatus);
      } else if (this.elementById(modalCProfileCertificationEdit)) {
        this.closeModal(modalCProfileCertificationEdit);
      } else if (this.elementById(modalCProfileEducationEdit)) {
        this.closeModal(modalCProfileEducationEdit);
      } else if (this.elementById(modalCProfileLanguageEdit)) {
        this.closeModal(modalCProfileLanguageEdit);
      } else if (this.elementById(modalCProfileSkillEdit)) {
        this.closeModal(modalCProfileSkillEdit);
      } else if (this.elementById(modalCProfileChangePhoto)) {
        this.closeModal(modalCProfileChangePhoto);
      } else if (this.elementById(modalCProfileChangeCover)) {
        this.closeModal(modalCProfileChangeCover);
      } else if (this.elementById(modalConfirmationCCompleteProfile)) {
        this.closeModal(modalConfirmationCCompleteProfile);
      } else if (this.elementById(modalCProfileDiscardChange)) {
        this.closeModal(modalCProfileDiscardChange);
      } else if (this.elementById(modalCRoomCameraChat)) {
        this.closeModal(modalCRoomCameraChat);
      } else if (this.elementById(modalCRoomLocationChat)) {
        this.closeModal(modalCRoomLocationChat);
      } else if (this.elementById(modalConfirmationNotificationLocation)) {
        this.closeModal(modalConfirmationNotificationLocation);
      } else if (this.elementById(modalConfirmationNotificationCamera)) {
        this.closeModal(modalConfirmationNotificationCamera);
      } else if (this.elementById(modalConfirmationHidePaw)) {
        this.closeModal(modalConfirmationHidePaw);
      } else if (this.elementById(modalConfirmationShowPaw)) {
        this.closeModal(modalConfirmationShowPaw);
      } else if (this.elementById(modalMediaDeleteNotif)) {
        this.closeModal(modalMediaDeleteNotif);
      } else if (this.elementById(modalCrmediaPreview)) {
        this.closeModal(modalCrmediaPreview);
      } else if (this.elementById(modalChtMediaPreview)) {
        this.closeModal(modalChtMediaPreview);
      } else if (this.elementById(modalChtMediaPreviewLiveChat)) {
        this.closeModal(modalChtMediaPreviewLiveChat);
      } else if (this.elementById(modalConfirmationHibernateConfirm)) {
        this.closeModal(modalConfirmationHibernateConfirm);
      } else if (this.elementById(modalReviewApp)) {
        this.closeModal(modalReviewApp);
      } else if (this.elementById(modalSurveyApp)) {
        this.closeModal(modalSurveyApp);
      } else if (this.elementById(modalConfirmationInsideCompleted)) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowConfirmationInsideCompleted', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowConfirmationInsideCompleted', false);
      } else if (this.elementById(modalConfirmationFieldCompleted)) {
        (<any>this.$root).$emit('mdl::changeValue', 'isShowConfirmFieldCompleted', false);
        (<any>this.$root).$emit('domdl::changeValue', 'isShowConfirmFieldCompleted', false);
      } else if (this.elementById(modalCMediaPreview)) {
        this.closeModal(modalCMediaPreview);
      } else if (this.elementById(modalConfirmationDeactiveApp)) {
        this.closeModal(modalConfirmationDeactiveApp);
      } else if (this.elementById(modalConfirmationDeactiveConfirmSuccess)) {
        this.closeModal(modalConfirmationDeactiveConfirmSuccess);
      } else if (this.elementById(modalConfirmationUserIdExistApp)) {
        this.closeModal(modalConfirmationUserIdExistApp);
      } else if (this.elementById(modalConfirmationUserSuspendApp)) {
        this.closeModal(modalConfirmationUserSuspendApp);
      } else if (this.elementById(modalCLoader)) {
        this.closeModal(modalCLoader);
      } else if (this.elementById(modalCSuccessful)) {
        this.closeModal(modalCSuccessful);
      } else if (this.elementById(modalCInfoBalanceDedicated)) {
        this.closeModal(modalCInfoBalanceDedicated);
      } else if (this.elementById(modalCInfoInsufficientBalance)) {
        this.closeModal(modalCInfoInsufficientBalance);
      } else if (this.elementById(modalVisibleTimeRegionUpdate)) {
        this.closeModal(modalVisibleTimeRegionUpdate);
      } else if (this.elementById(modalCChangeLanguage)) {
        this.closeModal(modalCChangeLanguage);
      } else if (this.elementById(modalPLoginMPopUp)) {
        this.closeModal(modalPLoginMPopUp);
      } else if (this.elementById(modalBillboardDetail)) {
        this.closeModal(modalBillboardDetail);
      }

      // element condition
      else if (this.checkDisplay(this.elementById(elementSearchRoomChat))) {
        this.$root.$emit('AppChat::changeFunction', 'handleShowSearch');
      } else if (this.checkDisplay(this.elementById(elementRoomSettingUser))) {
        this.$root.$emit('AppChat::changeFunction', 'handleCloseSettingUser');
      } else if (this.checkDisplay(this.elementById(elementClockInCamera))) {
        (<any>this.$root).$emit('doLocation::changeValue', 'toggleClockIn', false);
      } else if (this.elementById(elementManageOrderSearch)) {
        this.$root.$emit('manageOrderM::changeValue', 'isShowSearch', false);
      } else if (this.elementById(elementManagePawSearch)) {
        this.$root.$emit('managePawM::changeValue', 'isShowSearch', false);
      } else if (this.elementById(elementManageRequestSearch)) {
        this.$root.$emit('manageRequestM::changeValue', 'isShowSearch', false);
      }

      // page condition by nextRouteName
      else if (this.nextRouteName === 'PAppChatByRoomId') {
        if (this.elementById(elementDetailOrderLocation)) {
          (<any>this.$root).$emit('opc::changeFunction', 'closeShowDetailOrderLocation');
        } else if (this.checkDisplay(this.elementById(pageChatInfoPanel))) {
          (<any>this.$root).$emit('opc::changeFunction', 'closeShowInfoPanel');
        } else if (
          this.checkDisplay(this.elementById(pageRoomMedia)) ||
          this.checkDisplay(this.elementById(pageRoomSearchChat)) ||
          this.checkDisplay(this.elementById(pageRoomStarChat))
        ) {
          (<any>this.$root).$emit('cw::changeFunction', 'closeShowOption');
        } else if (this.checkDisplay(this.elementById(elementEmojiRoomChat))) {
          this.$root.$emit('roomFooter::changeValue', 'isShowEmoticon', false);
        } else {
          (<any>this.$root).$emit('cw::isRoomsListShow', isRoomsListShow => {
            if (!isRoomsListShow) {
              (<any>this.$root).$emit('cw::showRoomsList', true);
            }
          });
        }
      } else if (this.nextRouteName === 'PBuyerPostRequestEditGuarantee') {
        this.$router.push({
          name: 'PBuyerHome',
        });
      } else if (this.nextRouteName === 'PAskCommitee' || this.nextRouteName === 'PRequestedTicket') {
        this.actionBackAccordanceToPath();
      } else if (this.nextRouteName === 'PDetailOrder') {
        this.actionBackAccordanceToPath();
      } else if (this.nextRouteName === 'PProfile') {
        (<any>this.$root).$emit('profile::getVariable', 'editMode', editMode => {
          if (editMode) {
            (<any>this.$root).$emit('profile::changeValue', 'editMode', false);
          } else {
            this.$router.go(-1);
          }
        });
      } else if (this.nextRouteName === 'PPawManage') {
        if (this.typeBackGeneral === 'FROM_PAW_LIMIT') {
          this.$router.go(-2);
        } else if (this.nextRouteName === 'PPawCreateThree') {
          this.$router.push({
            name: 'PFreelancerHome',
          });
        } else {
          this.$router.go(-1);
        }
      } else if (this.nextRouteName === 'PPawCreateOneEdit' || this.nextRouteName === 'PPawCreateOne') {
        if (this.routeModulePaw.params.id && this.routeModulePaw.query.type == 'NEW') {
          this.$router.go(-2);
        } else {
          this.$router.go(-1);
        }
      } else if (this.nextRouteName === 'PHome' || this.nextRouteName === 'PFreelancerHome' || this.nextRouteName === 'PBuyerHome') {
        if (Flutter.isRunOn()) {
          Flutter.call('exitApp');
        } else {
          this.$router.go(-1);
        }
      }
      // other
      else {
        this.$router.go(-1);
      }
    });
  }

  // method
  public callOnBackModalIncludesStepFormOrderType(includeStep: ModalStepFormOrderType) {
    (<any>this.$root).$emit('opc::getVariable', 'modalStepFormOrders', (modalStepFormOrders: ModalStepFormOrderType[]) => {
      if (modalStepFormOrders.includes(includeStep)) {
        (<any>this.$root).$emit('domdl::changeFunction', 'onBackModal');
        (<any>this.$root).$emit('mdl::changeFunction', 'onBackModal');
      }
    });
  }

  public getValue(root: any, fieldName: string) {
    (<any>this.$root).$emit(root, fieldName, (value: any) => {
      this[fieldName] = value;
    });
  }

  public checkDisplay(html: any) {
    if (!html) {
      return false;
    }
    return window.getComputedStyle(html).display !== 'none';
  }

  private elementById(id: string | null): HTMLElement | null {
    if (!id) return null;
    return document.getElementById(id);
  }

  private closeModal(id: string | null): void {
    if (!id) return;
    this.globalService().closeGlobalDialog(this.$root, id);
  }

  public actionBackAccordanceToPath() {
    switch (true) {
      // navigationHistories for multiple history, previousRouteChat or previousRouteName for single history
      // the first sequence of case is a single history and then multiple history
      case this.previousRouteChat === 'PNotificationM':
        this.$router.replace({ name: 'PNotificationM' });
        break;
      case this.previousRouteChat === 'PAppChatByRoomId':
        if (this.typeBackChat === 'IS_NOT_SHOW_CHAT_INFO_PANEL') {
          // clear typeBackChat
          this.$store.commit('typeBackChat', '');
          this.$router.go(-1);
          return;
        }

        if (this.$store.getters.isMobile) {
          this.$router.replace({
            name: 'PAppChatByRoomId',
            params: { roomId: this.roomIdToStore },
            query: { type: 'isShowChatInfoPanelTrue' },
          });
          console.log('i am call true');
        } else {
          this.$router.replace({
            name: 'PAppChatByRoomId',
            params: { roomId: this.roomIdToStore },
          });
          console.log('i am call else');
        }
        break;
      default:
        this.$router.go(-1);
        break;
    }
  }

  public get previousRouteChat() {
    return this.$store.getters.previousRouteChat;
  }
  public get routeModulePaw() {
    // from general-store
    return this.$store.getters.routeModulePaw ? this.$store.getters.routeModulePaw : null;
  }

  public get nextRouteName() {
    return this.$store.getters.nextRouteName;
  }

  public get typeBackChat() {
    // from chat-store
    return this.$store.getters.typeBackChat;
  }
  public get typeBackGeneral() {
    // from general-store
    return this.$store.getters.typeBackGeneral;
  }

  public get roomIdToStore() {
    // from chat-store
    return this.$store.getters.roomIdToStore;
  }
}
