import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CUserPhotoLevel extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) userPhoto: string;
  @Prop({ default: null }) public userLevel: string;
  @Prop({ default: '2.625rem' }) public width: string;
  @Prop({ default: false }) public editMode: boolean;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
