var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-dashboard-chat-list" }, [
    _vm.isFetching && _vm.loadingRooms
      ? _c(
          "div",
          { staticClass: "text-center p-3 d-flex h-100" },
          [
            _c("c-loader", {
              staticClass: "d-flex m-auto",
              attrs: {
                show: _vm.isFetching && _vm.loadingRooms,
                infinite: false,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    (!_vm.rooms && !_vm.isFetching) ||
    (!_vm.isFetching && _vm.rooms.length === 0)
      ? _c("div", { staticClass: "text-center p-3 d-flex h-100" }, [
          _c("p", {
            staticClass:
              "font-size-12 cj-color-black-primary font-weight-300 font-roboto m-auto",
            attrs: { "data-cy": "no-conversation-text" },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbDashboard.noConversation")),
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "vac-room-list",
        attrs: { "data-cy": "vac-room-list", id: "rooms-list" },
      },
      _vm._l(_vm.rooms, function (fRoom, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "vac-room-item",
            attrs: {
              id: fRoom.roomId,
              "data-cy": "vac-room-item",
              "data-gtm": "vac-room-item-clickable",
            },
            on: {
              click: function ($event) {
                return _vm.openRoom(fRoom, "ROOMMEDIA")
              },
            },
          },
          [
            _c("room-content", {
              attrs: {
                "current-user-id": _vm.currentUser.id,
                room: fRoom,
                "text-formatting": {
                  disabled: false,
                  italic: "_",
                  bold: "*",
                  strike: "~",
                  underline: "!",
                  multilineCode: "```",
                  inlineCode: "`",
                },
                "link-options": {
                  disabled: false,
                  target: "_blank",
                  rel: null,
                },
                "text-messages": _vm.t,
                "room-actions": [],
                "user-ids-online": _vm.userIdsOnline,
              },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.$scopedSlots, function (i, name) {
                    return {
                      key: name,
                      fn: function (data) {
                        return [_vm._t(name, null, null, data)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowFetchLoading,
            expression: "isShowFetchLoading",
          },
        ],
      },
      [
        _vm.totalItems > _vm.roomsPerPage
          ? _c(
              "infinite-loading",
              {
                ref: "infiniteLoading",
                attrs: {
                  slot: "append",
                  identifier: _vm.infiniteId,
                  distance: 20,
                },
                on: { infinite: _vm.loadMore },
                slot: "append",
              },
              [
                _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "spinner" }, slot: "spinner" },
                  [
                    _c("c-loader", {
                      staticClass: "d-flex m-auto",
                      attrs: { show: true, infinite: true },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }