import { Module } from 'vuex';
const defaultState = () => {
  return {
    chtConversation: null,
    chtVwConversation: null, //chtConversation model
    tabChat: null,
    previewUploadChatMessage: null,
    // previewChatMessage: null,
    chtVwConversations: [],
    chtMsgForward: [],
    vwUsers: [],
    chtVwConversationIds: null,
    messagesCollection: null,
    currentReactions: [],
    userIdsOnline: [],
    cbTextRestricts: [],
    chtLiveAgentConversation: {},
    roomIdToStore: null,
    isSocketServiceNotActive: false,
    typeBackChat: '',
  };
};
export const chatStore: Module<any, any> = {
  state: defaultState(),
  getters: {
    chtConversation: state => state.chtConversation,
    chtVwConversation: state => state.chtVwConversation,
    chtVwConversations: state => state.chtVwConversations,
    getChtMsgForward: state => state.chtMsgForward,
    chtVwConversationIds: state => state.chtVwConversationIds,
    tabChat: state => state.tabChat,
    previewUploadChatMessage: state => state.previewUploadChatMessage,
    // previewChatMessage: state => state.previewChatMessage,
    vwUsers: state => state.vwUsers,
    messagesCollection: state => state.messagesCollection,
    currentReactions: state => state.currentReactions,
    userIdsOnline: state => state.userIdsOnline,
    cbTextRestricts: state => state.cbTextRestricts,
    chtLiveAgentConversation: state => state.chtLiveAgentConversation,
    roomIdToStore: state => state.roomIdToStore,
    isSocketServiceNotActive: state => state.isSocketServiceNotActive,
    typeBackChat: state => state.typeBackChat,
  },
  mutations: {
    logoutChat(state) {
      Object.assign(state, defaultState());
    },
    clearTextRestrict(state) {
      state.cbTextRestricts = [];
    },
    chtConversation(state, chtConversation) {
      state.chtConversation = chtConversation;
    },
    chtVwConversation(state, newData) {
      state.chtVwConversation = newData;
    },
    chtVwConversations(state, newData) {
      state.chtVwConversations = newData;
    },
    setChtMsgForward(state, newData) {
      state.chtMsgForward = newData;
    },
    chtVwConversationIds(state, newData) {
      state.chtVwConversationIds = newData;
    },
    tabChat(state, newData) {
      state.tabChat = newData;
    },
    previewUploadChatMessage(state, newData) {
      state.previewUploadChatMessage = newData;
    },
    // previewChatMessage(state, newData) {
    // state.previewChatMessage = newData;
    // },
    vwUsers(state, newData) {
      state.vwUsers = newData;
    },
    messagesCollection(state, newData) {
      state.messagesCollection = newData;
    },

    resetChatStore(state) {
      Object.assign(state, defaultState());
    },

    currentReactions(state, newData) {
      state.currentReactions = newData;
    },
    userIdsOnline(state, newData) {
      state.userIdsOnline = newData;
    },
    cbTextRestricts(state, newData) {
      state.cbTextRestricts = newData;
    },
    chtLiveAgentConversation(state, newData) {
      state.chtLiveAgentConversation = newData;
    },
    roomIdToStore(state, newData) {
      state.roomIdToStore = newData;
    },
    isSocketServiceNotActive(state, newData) {
      state.isSocketServiceNotActive = newData;
    },
    typeBackChat(state, newData) {
      state.typeBackChat = newData;
    },
  },
};
