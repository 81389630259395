var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-livechat",
      style: [{ height: _vm.height }, _vm.cssVars],
      attrs: { "data-cy": "c-livechat" },
    },
    [
      _c("div", { staticClass: "position-relative" }, [
        _c(
          "button",
          {
            staticClass: "cursor-pointer p-3 m-0",
            staticStyle: {
              position: "absolute",
              top: "0",
              right: "0",
              "z-index": "12",
            },
            attrs: { type: "button", "data-cy": "close-live-chat" },
            on: { click: _vm.callBack },
          },
          [
            _c("icon", {
              staticClass: "my-auto cj-fill-black-primary ic_arrow_dropdown",
              attrs: { name: "ic_down", width: "1rem", height: "1rem" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("room", {
        attrs: {
          "current-user-id": _vm.currentUserId,
          "user-ids-online": _vm.userIdsOnline,
          isShowAgents: _vm.isShowAgents,
          rooms: _vm.rooms,
          "room-id": _vm.room.roomId || "",
          "load-first-room": _vm.loadFirstRoom,
          messages: _vm.messages,
          "room-message": _vm.roomMessage,
          "messages-loaded": _vm.messagesLoaded,
          "menu-actions": _vm.menuActions,
          "message-actions": _vm.messageActions,
          "message-selection-actions": _vm.messageSelectionActions,
          "auto-scroll": _vm.autoScroll,
          "show-send-icon": _vm.showSendIcon,
          "show-files": _vm.showFiles,
          "show-audio": _vm.showAudio,
          "audio-bit-rate": _vm.audioBitRate,
          "audio-sample-rate": _vm.audioSampleRate,
          "show-emojis": _vm.showEmojis,
          "show-reaction-emojis": _vm.showReactionEmojis,
          "show-new-messages-divider": _vm.showNewMessagesDivider,
          "show-footer": _vm.showFooter,
          "text-messages": _vm.t,
          "single-room": _vm.singleRoom,
          "show-rooms-list": _vm.showRoomsList,
          "text-formatting": _vm.textFormatting,
          "link-options": _vm.linkOptions,
          "is-mobile": _vm.isMobile,
          "loading-rooms": _vm.loadingRooms,
          "room-info-enabled": _vm.roomInfoEnabled,
          "textarea-action-enabled": _vm.textareaActionEnabled,
          "textarea-auto-focus": _vm.textareaAutoFocus,
          "user-tags-enabled": _vm.userTagsEnabled,
          "emojis-suggestion-enabled": _vm.emojisSuggestionEnabled,
          "scroll-distance": _vm.scrollDistance,
          "accepted-files": _vm.acceptedFiles,
          "templates-text": _vm.templatesText,
          "username-options": _vm.usernameOptions,
          "is-closed": _vm.isClosed,
        },
        on: {
          "toggle-rooms-list": _vm.toggleRoomsList,
          "room-info": _vm.roomInfo,
          "fetch-messages": _vm.fetchMessages,
          "send-message": _vm.sendMessage,
          "edit-message": _vm.editMessage,
          "delete-message": _vm.deleteMessage,
          "open-file": _vm.openFile,
          "open-user-tag": _vm.openUserTag,
          "open-failed-message": _vm.openFailedMessage,
          "menu-action-handler": _vm.menuActionHandler,
          "message-action-handler": _vm.messageActionHandler,
          "message-selection-action-handler": _vm.messageSelectionActionHandler,
          "send-message-reaction": _vm.sendMessageReaction,
          "typing-message": _vm.typingMessage,
          "textarea-action-handler": _vm.textareaActionHandler,
          handleShowOption: _vm.handleShowOption,
          showInfoPanel: function ($event) {
            return _vm.$emit("showInfoPanel", $event)
          },
          deleteMessageReaction: function ($event) {
            return _vm.$emit("deleteMessageReaction", $event)
          },
          "ask-me-question-again": _vm.askmeQuestionAgain,
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (i, name) {
              return {
                key: name,
                fn: function (data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-cht-media-preview-live-chat",
            size: "lg",
            "hide-footer": "",
            "hide-header": "",
            "content-class": "vac-custom-modal-media-preview-content-b-modal",
            "dialog-class": "vac-custom-modal-media-preview-size-b-modal",
            "modal-class": "vac-custom-modal-media-preview-b-modal",
          },
        },
        [
          _c("media-preview-chat", {
            attrs: { message: _vm.selectedMessage, file: _vm.previewFile },
            on: {
              download: _vm.downloadFile,
              "close-media-preview": _vm.handleClosePreviewMedia,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }