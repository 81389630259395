var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwUserReviews && _vm.cbVwUserReviews.length > 0
    ? _c(
        "div",
        { staticClass: "c-pd-review-slideshow" },
        [
          _c(
            "swiper",
            {
              staticClass: "px-4 c-pd-review-slideshow-wrapper",
              attrs: {
                "slides-per-view": "auto",
                "space-between": 16,
                loop: false,
                threshold: 10,
              },
            },
            _vm._l(_vm.cbVwUserReviews, function (item, index) {
              return _c("swiper-slide", { key: index }, [
                _c(
                  "div",
                  { staticClass: "card-pd-review-slideshow p-3 h-100" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex mb-4" },
                      [
                        _c("c-user-photo-level", {
                          staticClass: "mr-2 my-auto cursor-pointer",
                          attrs: {
                            userPhoto: _vm.$options.filters.LAZY(
                              `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${item.reviewerPhotoId}`,
                              48,
                              "ic-lazy-avatar.png"
                            ),
                            width: "3rem",
                            userLevel: item.reviewerLevel,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.$emit(
                                "toProfile",
                                item.reviewerPhotoId
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-grow-1 mr-2 my-auto" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary text-ellipsis",
                              class: _vm.isMobile
                                ? "font-size-16 font-weight-600"
                                : "font-weight-700 font-size-18",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.reviewerName) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("b-form-rating", {
                                staticClass: "review-star-wrapper mr-1",
                                attrs: { readonly: "" },
                                model: {
                                  value: item.rating,
                                  callback: function ($$v) {
                                    _vm.$set(item, "rating", $$v)
                                  },
                                  expression: "item.rating",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto cj-color-black-primary",
                                  class: _vm.isMobile
                                    ? "font-size-12 font-weight-600"
                                    : "font-weight-700 font-size-12",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.rating) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto text-right cj-color-grey-secondary",
                            class: _vm.isMobile
                              ? "font-size-8 font-weight-600"
                              : "font-weight-700 font-size-10",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("formatDateTimeDefault")(
                                    item.createdDate
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto text-justify font-weight-400 cj-color-black-primary",
                        class: _vm.isMobile ? "font-size-12 " : " font-size-14",
                      },
                      [
                        _vm._v(
                          "\n          “" + _vm._s(item.comment) + "“\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("c-pd-review-detail-reply", {
                      attrs: {
                        cbVwUserReview: item,
                        seletedReviewId: _vm.seletedReviewId,
                        isHideReply: true,
                      },
                      on: {
                        handleSeeAllReview: function ($event) {
                          return _vm.$emit("handleSeeAllReview")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }