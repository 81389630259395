var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-fp-payment-info d-flex flex-wrap align-items-center wrap-border-grey-tertiary border-radius-card px-3 pt-3 pb-2",
      attrs: { "data-cy": "payment-info" },
    },
    [
      _c(
        "p",
        {
          staticClass:
            "flex-grow-1 pb-2 font-roboto cj-color-grey-secondary font-size-16-auto font-weight-400",
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "pb-2 font-roboto cj-color-black-primary font-size-24-auto font-weight-700",
          attrs: { "data-cy": "total-price" },
        },
        [_vm._v(_vm._s(_vm.price))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }