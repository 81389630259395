import { required } from 'vuelidate/lib/validators';
import { Inject, Vue, Component } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CAutocompleteNew from '@/components/c-autocomplete-new/c-autocomplete-new.vue';
import CButton from '@/components/button/c-button.vue';

import PCbVwUserPartnerLevelService from '@/services/cb-vw-user-partner-level.service';
import PCbUserInfoService from '@/services/cb-user-info.service';
import PCbVwUserService from '@/services/cb-vw-user.service';
import SocketService from '@/admin/socket/socket.service';
import ContentService from '@/services/content.service';

import { CbLov, ICbLov } from '@/shared/model/cb-lov.model';
import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';

const validations: any = {
  cbUserInfo: {
    langKey: { required },
    isShowOnlineStatus: { required },
  },
};
@Component({
  validations,
  components: {
    CAutocompleteNew,
    CButton,
    Icon,
  },
})
export default class roomSettingUser extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbUserInfoService') private pCbUserInfoService: () => PCbUserInfoService;
  @Inject('pCbVwUserService') private pCbVwUserService: () => PCbVwUserService;
  @Inject('pCbVwUserPartnerLevelService') private pCbVwUserPartnerLevelService: () => PCbVwUserPartnerLevelService;
  @Inject('contentService') private contentService: () => ContentService;
  @Inject('socketService') private socketService: () => SocketService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public cbUserInfo: ICbUserInfo = new CbUserInfo();
  public myLanguage: ICbLov = new CbLov();

  public isUpdate = false;
  public isFetching = false;

  public get LANGUAGE_CODE() {
    return this.$store.getters.LANGUAGE_CODE;
  }
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  mounted(): any {
    this.contentService().initRetrieveCbLovs(CbLovType.LANGUAGE);
    this.initPage();
    this.getMyLanguage();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public callBack(): void {
    this.$emit('callBack', null);
  }

  public initPage(): void {
    this.cbUserInfo.isShowOnlineStatus = this.getCurrentUser.isShowOnlineStatus ? this.getCurrentUser.isShowOnlineStatus : false;
    this.cbUserInfo.langKey = this.getCurrentUser.langKey;
  }

  public partialUpdateCbUserInfoByLogin(): void {
    const newEntity: ICbUserInfo = {
      isShowOnlineStatus: this.cbUserInfo.isShowOnlineStatus,
      langKey: this.cbUserInfo.langKey,
    };

    this.isUpdate = true;
    this.pCbUserInfoService()
      .partialUpdateByLogin(newEntity)
      .then(async res => {
        const cbVwUser = this.getCurrentUser;
        cbVwUser.isShowOnlineStatus = newEntity.isShowOnlineStatus;
        cbVwUser.langKey = newEntity.langKey;

        await this.retrieveCbVwUserByLogin(cbVwUser);
        this.callBack();
      })
      .catch(err => {
        console.error('error', err.response);
        this.isUpdate = false;
      });
  }

  public async retrieveCbVwUserByLogin(cbVwUser: ICbVwUser): Promise<void> {
    this.isFetching = true;

    await this.pCbVwUserService()
      .retrieveByLogin()
      .then(res => {
        const cbVwUserTmp = { ...res, ...cbVwUser };
        this.processAssignUserLvl(cbVwUserTmp);
      })
      .catch(err => {
        console.error('error', err.response);
        this.isUpdate = false;
      })
      .finally(() => {
        this.isFetching = false;
      });
  }

  public processAssignUserLvl(cbVwUser: ICbVwUser): void {
    this.pCbVwUserPartnerLevelService()
      .retrieveByLogin()
      .then(res => {
        if (res?.userLvl) {
          cbVwUser.userLvl = res.userLvl.toString();
        }
      })
      .catch(err => {
        console.error('error', err.response);
        this.isUpdate = false;
      })
      .finally(() => {
        this.$store.commit('currentUser', cbVwUser);
        this.socketService().sendUserOnline();
        this.isUpdate = false;
      });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getCurrentUser(): any {
    return this.$store.getters.currentUser;
  }

  public get cbLovLanguages(): ICbLov[] {
    return this.$store.getters[CbLovType.LANGUAGE] ? this.$store.getters[CbLovType.LANGUAGE] : [];
  }

  public get currentLanguage(): string {
    return this.$store.getters.currentLanguage;
  }

  private getMyLanguage(): void {
    this.myLanguage = this.cbLovLanguages.find(x => x.key == this.getCurrentUser.langKey);
  }
  // ================= END COMPUTE ====================
}
