import axios from 'axios';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-schedules';

export default class PCbVwUserScheduleService {
  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public countActiveOrderByLogin(params?: any): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/count/active-order/by-login', { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public countOfferWaitingByLogin(params?: any): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/count/offer-waiting/by-login', { params })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
