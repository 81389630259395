import axios from 'axios';

import { ICbUserFollow } from '@/shared/model/cb-user-follow.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-follows';

export default class PCbUserFollowService {
  public retrieveFollowerIdByLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/folower-id-login`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbUserFollow): Promise<ICbUserFollow> {
    return new Promise<ICbUserFollow>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbUserFollow): Promise<ICbUserFollow> {
    return new Promise<ICbUserFollow>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
