var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "review", attrs: { "data-cy": "modal-review" } },
    [
      _c("div", { staticClass: "bar-wrapper vac-d-none-web" }, [
        _c("div", { staticClass: "text-left" }, [
          _c("p", {
            staticClass:
              "font-roboto cj-color-black-primary text-center font-size-24 cj-color-black-primary font-weight-700",
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.ReviewStatus.ratingReview")),
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cursor-pointer my-auto d-flex ml-auto",
            attrs: { "data-cy": "btn-cancel-review" },
            on: { click: _vm.cancel },
          },
          [
            _c("b-icon-x-circle-fill", {
              staticClass: "font-size-24 cj-color-grey-secondary",
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "review-container" }, [
        _c("div", { staticClass: "text-center mb-3 position-relative" }, [
          _c("p", {
            staticClass:
              "cj-color-black-primary m-0 font-roboto font-weight-700 font-size-24",
            class: _vm.isMobile ? "text-start" : "",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.ReviewStatus.orderCompleted")
              ),
            },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass:
              "cj-color-black-primary m-0 font-roboto font-weight-300 font-size-18",
            class: _vm.isMobile ? "text-start" : "",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.ReviewStatus.shareExperince")
              ),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "cursor-pointer my-auto d-flex ml-auto btn-position-right",
              attrs: { "data-cy": "btn-cancel-review" },
              on: { click: _vm.cancel },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-24 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "image-rating" }, [
          _c(
            "div",
            { staticClass: "d-flex mb-3" },
            [
              _c("c-user-photo-level", {
                staticClass: "m-auto user-photo-level",
                attrs: {
                  userLevel: _vm.cbVwUserPartnerLevel.userLvl,
                  userPhoto: _vm.$options.filters.LAZY(
                    `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${_vm.cbVwPawOrder.partnerPhotoId}`,
                    110,
                    "ic-lazy-avatar.png"
                  ),
                  onerror:
                    _vm.$options.filters.LAZY_ERROR("ic-lazy-avatar.png"),
                  width: _vm.isMobile ? "5rem" : "6.5rem",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex mb-3 mt-2 custom-rating",
              attrs: { "data-cy": "review-ratings" },
            },
            [
              _c("b-form-rating", {
                staticClass: "m-auto",
                model: {
                  value: _vm.cbUserReview.rating,
                  callback: function ($$v) {
                    _vm.$set(_vm.cbUserReview, "rating", $$v)
                  },
                  expression: "cbUserReview.rating",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-3" },
          [
            _c("p", {
              staticClass:
                "font-size-18 cj-color-black-primary font-weight-500 font-roboto mb-3",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.ReviewStatus.workWithBuyer")
                ),
              },
            }),
            _vm._v(" "),
            _c("c-textarea-new", {
              attrs: {
                "data-cy-custom": "input-textarea-review",
                maxlength: 1000,
              },
              model: {
                value: _vm.cbUserReview.comment,
                callback: function ($$v) {
                  _vm.$set(_vm.cbUserReview, "comment", $$v)
                },
                expression: "cbUserReview.comment",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pt-3 btn-review-wrapper" },
        [
          _c(
            "c-button",
            {
              staticClass: "px-5",
              attrs: {
                "data-cy": "btn-submit-review",
                disabled:
                  _vm.cbUserReview.comment === null ||
                  _vm.cbUserReview.comment === "" ||
                  _vm.cbUserReview.rating === undefined,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.ReviewStatus.sendFeedback")
                  ),
                },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }