var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vac-room-file-container" },
    [
      _c("loader", {
        attrs: { show: _vm.file.loading },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (idx, name) {
              return {
                key: name,
                fn: function (data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vac-svg-button vac-icon-remove",
          on: {
            click: function ($event) {
              return _vm.$emit("remove-file", _vm.index)
            },
          },
        },
        [
          _vm._t("image-close-icon", function () {
            return [
              _c("svg-icon", { attrs: { name: "close", param: "image" } }),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.isImage
        ? _c("div", {
            staticClass: "vac-message-image",
            class: { "vac-blur-loading": _vm.file.loading },
            style: {
              "background-image": `url('${_vm.file.localUrl || _vm.file.url}')`,
            },
          })
        : _vm.isVideo
        ? _c(
            "video",
            {
              class: { "vac-blur-loading": _vm.file.loading },
              attrs: { controls: "" },
            },
            [
              _c("source", {
                attrs: { src: _vm.file.localUrl || _vm.file.url },
              }),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "vac-file-container",
              class: { "vac-blur-loading": _vm.file.loading },
              attrs: { "data-cy": "vac-file-container" },
            },
            [
              _c(
                "div",
                [
                  _vm._t("file-icon", function () {
                    return [_c("svg-icon", { attrs: { name: "file" } })]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "vac-text-ellipsis",
                  staticStyle: { color: "var(--color145)" },
                },
                [_vm._v("\n      " + _vm._s(_vm.file.name) + "\n    ")]
              ),
              _vm._v(" "),
              _vm.file.extension
                ? _c(
                    "div",
                    { staticClass: "vac-text-ellipsis vac-text-extension" },
                    [_vm._v("\n      " + _vm._s(_vm.file.extension) + "\n    ")]
                  )
                : _vm._e(),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }