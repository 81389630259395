export interface ICbVwSvcCat {
  id?: number;
  svcId?: number | null;
  name?: string;
  svcName?: string;
  description?: string | null;
  seq?: number | null;
  bannerId?: number | null;
  logoId?: number | null;
  svcCatPopularWeight?: number | null;
  isActive?: boolean | null;
}

export class CbVwSvcCat implements ICbVwSvcCat {
  constructor(
    public id?: number,
    public svcId?: number | null,
    public name?: string,
    public svcName?: string,
    public description?: string | null,
    public seq?: number | null,
    public bannerId?: number | null,
    public logoId?: number | null,
    public isActive?: boolean | null
  ) {}
}
