var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "vac-slide-up" } }, [
    _vm.files.length
      ? _c(
          "div",
          {
            staticClass: "vac-room-files-container",
            style: { bottom: `${_vm.footerHeight}px` },
          },
          [
            _c(
              "div",
              {
                staticClass: "vac-files-box",
                attrs: { "data-cy": "vac-files-box" },
              },
              _vm._l(_vm.files, function (file, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    _c("room-file", {
                      attrs: { file: file, index: i },
                      on: {
                        "remove-file": function ($event) {
                          return _vm.$emit("remove-file", $event)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vac-icon-close" }, [
              _c(
                "div",
                {
                  staticClass: "vac-svg-button",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("reset-message")
                    },
                  },
                },
                [
                  _vm._t("reply-close-icon", function () {
                    return [
                      _c("svg-icon", { attrs: { name: "close-outline" } }),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }