var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-input d-flex" }, [
    _c("input", {
      ref: "textRef",
      staticClass: "cj-field-not-border input-inside cj-field-input",
      class: _vm.customClass + (_vm.hideCounter ? " no-counter" : ""),
      attrs: {
        type: "text",
        "data-cy": _vm.dataCyCustom
          ? `input-${_vm.dataCyCustom}`
          : "input-form",
        "data-gtm": _vm.dataGTMCustom
          ? `${_vm.dataGTMCustom}-input`
          : "input-form",
        placeholder: _vm.placeholder,
        readonly: _vm.readonly,
      },
      domProps: { value: _vm.value },
      on: {
        input: _vm.handleInput,
        blur: function ($event) {
          return _vm.$emit("blur", $event.target.value)
        },
        focus: function ($event) {
          return _vm.$emit("focus")
        },
      },
    }),
    _vm._v(" "),
    _vm.maxlength && _vm.maxlength > 0 && !_vm.hideCounter
      ? _c(
          "p",
          {
            staticClass:
              "font-roboto font-weight-300 font-size-12 cj-color-grey-secondary max-value my-auto pr-2",
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm.valueLength) +
                "/" +
                _vm._s(_vm.maxlength) +
                "\n  "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }