var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      staticClass: "message-reactions",
      attrs: { "data-cy": "message-reactions" },
    },
    _vm._l(_vm.reactions, function (r, index) {
      return _c("span", { key: index, staticClass: "vac-icon-button" }, [
        _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.$emit("deleteMessageReaction", r.id)
              },
            },
          },
          [_vm._v(_vm._s(r.content))]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }