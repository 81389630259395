import Icon from '@/shared/icons/icon.vue';
import { ICbVwSvc } from '@/shared/model/cb-vw-svc.model';
import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import ContentService from '@/services/content.service';
import CFooterCopyright from '../c-footer-copyright/c-footer-copyright.vue';

@Component({
  components: {
    Icon,
    CFooterCopyright
  },
})
export default class CFooter extends Vue {
  // ================= START SERVICES =================
  // ================= SERVICES =================
  @Inject('contentService') public contentService: () => ContentService;

  // ================= END SERVICES =================
  // ================= START VARIABLES =================
  @Prop({default:false}) public isShowTabFooter;
  public isShowInside = false;
  public isShowField = false;
  public isShowSupport = false;
  public isShowFilterAbout = false;

  public cbSvcType = CbSvcType;
  public urlCareer = 'https://bti.id/';

  public dummyFooters = [
    { title: 'Support', support: 'helpCenter', name: 'cbgwApp.cbSvc.support.helpCenter'},
    { title: 'Support', support: 'askCommitee', name: 'cbgwApp.cbSvc.support.askCommitee'},
    { title: 'Support', support: 'career', name: 'cbgwApp.cbSvc.support.career'},
    { title: 'About', about: 'aboutUs', name: 'cbgwApp.cbSvc.about.aboutUs'},
    { title: 'About', about: 'privacyPolicy', name: 'cbgwApp.cbSvc.about.privacyPolicy'},
    { title: 'About', about: 'termService', name: 'cbgwApp.cbSvc.about.termService'},

    { title: 'About', about: 'termPayment', name: 'cbgwApp.cbSvc.about.termPayment'},
    { title: 'About', about: 'intellectualPropertyClaims', name: 'cbgwApp.cbSvc.about.intellectualPropertyClaims'},
    { title: 'About', about: 'cookiePolicy', name: 'cbgwApp.cbSvc.about.cookiePolicy'},
    { title: 'About', about: 'communityGuidelines', name: 'cbgwApp.cbSvc.about.communityGuidelines'},
  ];

  // ================= END VARIABLES =================
  // ================= START DEFAULT FUNCTION ================= s
  // public created(){
  // }
  public mounted() {
    this.initIntersectionObserver();
  }
  // ================= END DEFAULT FUNCTION =================
  // ================= START FUNCTION =================
  public handleToService(svcId) {
    this.$router.push({ name: 'PSvc', params: { id: svcId } });
  }

  public handleToPages(about) {
    if (about === 'aboutUs') {
      this.$router.push({ name: 'PAboutUs' });
    } else if (about === 'helpCenter') {
      this.$router.push({ name: 'PHelpCenter', params: { info: 'info' } });
    } else if (about === 'askCommitee') {
      this.$router.push({ name: 'PAskCommitee' });
    } else if (about === 'career') {
      window.open(this.urlCareer, '_blank');
    } else if (about == 'privacyPolicy') {
      this.$router.push({ name: 'PLegalDocument', query: { tab: 'PRIVACY_POLICY' } });
    } else if (about == 'termService') {
      this.$router.push({ name: 'PLegalDocument', query: { tab: 'TERM_OF_SERVICE' } });
    } else if (about == 'termPayment') {
      this.$router.push({ name: 'PLegalDocument', query: { tab: 'TERM_OF_PAYMENT' } });
    } else if (about == 'intellectualPropertyClaims') {
      this.$router.push({ name: 'PLegalDocument', query: { tab: 'INTELLECTUAL_PROPERTY_CLAIMS' } });
    } else if (about == 'cookiePolicy') {
      this.$router.push({ name: 'PLegalDocument', query: { tab: 'COOKIE_POLICY' } });
    } else if (about == 'communityGuidelines') {
      this.$router.push({ name: 'PLegalDocument', query: { tab: 'COMMUNITY_GUIDELINES' } });
    }
  }

  public toPages() {
    this.$router.push({ name: 'PAboutUs' });
  }

  public isCollapseInside() {
    this.isShowInside = !this.isShowInside;
  }
  public isCollapseField() {
    this.isShowField = !this.isShowField;
  }
  public isCollapseSupport() {
    this.isShowSupport = !this.isShowSupport;
  }
  public isCollapseFilterAbout() {
    this.isShowFilterAbout = !this.isShowFilterAbout;
  }

  public initIntersectionObserver() {
    const target = document.getElementById('footer-loader');
    if (target) {
      const options = {
        threshold: 0,
      };

      const observer = new IntersectionObserver(entries => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            this.contentService().initRetrieveCbVwSvc();

            observer.unobserve(target);
          }
        });
      }, options);

      observer.observe(target);
    }
  }
  // ================= END FUNCTION =================
  // ================= START COMPUTE ================='

  public get cbVwSvcs(): ICbVwSvc[] {
    return this.$store.getters.cbVwSvcs;
  }

  public get getSvcByType() {
    return (type: CbSvcType) => {
      if (this.cbVwSvcs && this.cbVwSvcs.length > 0) {
        const cbVwSvcsTmp = [...this.cbVwSvcs];
        if (type === CbSvcType.INSIDE) {
          return cbVwSvcsTmp.filter(e => e.svcType === CbSvcType.INSIDE);
        } else {
          return cbVwSvcsTmp.filter(e => e.svcType === CbSvcType.FIELD);
        }
      } else return [];
    };
  }
  // ================= END COMPUTE =================
}
