import { Vue, Component, Prop } from 'vue-property-decorator';

import ProgressBar from '../chat/components/ProgressBar/ProgressBar.vue';
import CLoader from '@/components/c-loader/c-loader.vue';
import CItemChatMessageClockin from './components/c-item-chat-mesage-clockin/c-item-chat-mesage-clockin.vue';
import CItemChatMessageClockout from './components/c-item-chat-mesage-clockout/c-item-chat-mesage-clockout.vue';
import CItemChatMessageAuctionProject from './components/c-item-chat-message-auction-project/c-item-chat-message-auction-project.vue';
import CItemChatMessageInterest from './components/c-item-chat-message-interest/c-item-chat-message-interest.vue';
import CItemChatMessageMilestone from './components/c-item-chat-message-milestone/c-item-chat-message-milestone.vue';
import CItemChatMessageMom from './components/c-item-chat-message-mom/c-item-chat-message-mom.vue';
import CItemChatMessageOnmyway from './components/c-item-chat-message-onmyway/c-item-chat-message-onmyway.vue';
import CItemChatMessagePawOrder from './components/c-item-chat-message-paw-order/c-item-chat-message-paw-order.vue';
import CItemChatMessageProjectDelivery from './components/c-item-chat-message-project-delivery/c-item-chat-message-project-delivery.vue';
import CItemChatMessageRevisionOffer from './components/c-item-chat-message-revision-offer/c-item-chat-message-revision-offer.vue';
import CItemChatMessageRevision from './components/c-item-chat-message-revision/c-item-chat-message-revision.vue';
import CItemChatMessageAppointment from './components/c-item-chat-mesage-appointment/c-item-chat-mesage-appointment.vue';

import { CbVwAuctionProjectPreOffer } from '@/shared/model/cb-vw-auction-project-pre-offer.model';
import { CbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbVwPaw } from '@/shared/model/cb-vw-paw.model';
import { CbPawPreOrder } from '@/shared/model/cb-paw-pre-order.model';
import { CbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';

@Component({
  components: {
    CItemChatMessageClockin,
    CItemChatMessageClockout,
    CItemChatMessageAuctionProject,
    CItemChatMessageInterest,
    CItemChatMessageMilestone,
    CItemChatMessageMom,
    CItemChatMessageOnmyway,
    CItemChatMessagePawOrder,
    CItemChatMessageProjectDelivery,
    CItemChatMessageRevision,
    CItemChatMessageRevisionOffer,
    CItemChatMessageAppointment,
    ProgressBar,
    CLoader,
  },
})
export default class ItemsChatMessage extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public messageChtContentType: ChtContentType;
  @Prop({ default: null }) public cbVwPawOrderRequirement: CbVwPawOrderRequirement;
  @Prop({ default: null }) public cbVwPawOrder: CbVwPawOrder;
  @Prop({ default: null }) public cbVwAuctionProjectPreOffer: CbVwAuctionProjectPreOffer;
  @Prop({ default: null }) public cbVwPaw: CbVwPaw;
  @Prop({ default: null }) public cbTextRestrict: CbTextRestrict;
  @Prop({ default: null }) public cbPawPreOrder: CbPawPreOrder;
  @Prop({ default: null }) public idx;

  public ChtContentType = ChtContentType;
  public isFetching = false;
  public cardItemResponsive = {};

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  mounted() {
    const ref = <any>this.$refs['cardItemRef' + this.idx];

    if (ref) {
      this.cardItemResponsive = {
        maxHeight: ref.clientWidth + 200,
        loaderTop: ref.clientHeight / 2 - 9,
      };
      console.log(this.cardItemResponsive);
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public openAllCollapse() {
    const refArr = [
      'c-item-chat-message-paw-order',
      'c-item-chat-message-mom',
      'c-item-chat-message-milestone',
      'c-item-chat-message-project-delivery',
      'c-item-chat-message-revision',
      'c-item-chat-message-revision-offer',
      'c-item-chat-message-clockin',
      'c-item-chat-message-clockout',
      'c-item-chat-message-auction-project',
      'c-item-chat-message-interest',
      'c-item-chat-message-onmyway',
      'c-item-chat-message-appointment',
      'c-item-chat-message-paw-order',
    ];

    refArr.forEach(r => {
      if (this.$refs[r] && (<any>this.$refs[r]).openAllCollapse) {
        (<any>this.$refs[r]).openAllCollapse();
      }
    });
  }
  // ================= END COMPUTE ====================
}
