import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import Icon from '@/shared/icons/icon.vue';
import CLoader from '@/components/c-loader/c-loader.vue';
import CPopularSearchList from '@/components/c-popular-search-list/c-popular-search-list.vue';

import CGSearch from '@/components/g-search/c-g-search.component';

@Component({
  components: {
    Icon,
    CLoader,
    CPopularSearchList,
  },
})
export default class CNavbarSearch extends mixins(CGSearch) {
  @Watch('$route')
  public routeListener(to, from) {
    if (to?.name == 'PAdvanceSearch' && to?.query?.name) {
      this.search = to.query.name;
    } else {
      this.search = '';
      this.toggle = false;
      this.searchInputed = '';
    }
  }

  public closeModal() {
    this.toggle = false;
    const inputElement = this.$refs.searchInput as HTMLInputElement;
    if (inputElement) {
      inputElement.blur();
    }
  }
}
