import Vue from 'vue';

export function initSecurityFilters() {
  Vue.filter('SECURITY_VHTML', value => {
    if (value) {
      const dataValue = value.replace('src=', '****').replace('<script>', '*********').replace('</script>', '**********');
      if (dataValue) {
        return dataValue;
      }
    }
  });

  Vue.filter('SECURITY_VHTML_AND_NOTIF_REPLACE_BASE_URL', (value, notifRepaceBaseUrl) => {
    if (value) {
      let dataValue = value.replace('src=', '****').replace('<script>', '*********').replace('</script>', '**********');
      dataValue = dataValue.replace(/<a /g, '<span ').replace(/<\/a>/g, '</span>');
      const urlArray: string[] = notifRepaceBaseUrl.split(',');

      // Iterate over the array
      for (const url of urlArray) {
        console.log(url);
        dataValue = dataValue.replace(`href="` + url, `href="`);
        // Your logic for each URL goes here
      }
      if (dataValue) {
        return dataValue;
      }
    }
  });
}
