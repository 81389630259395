var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "vac-reply-message" },
    [
      _c("div", { staticClass: "vac-reply-username" }, [
        _vm._v("\n    " + _vm._s(_vm.replyUsername) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.isImage
        ? _c("div", { staticClass: "vac-image-reply-container" }, [
            _c("div", {
              staticClass: "vac-message-image vac-message-image-reply",
              style: {
                "background-image": `url('${_vm.firstFile.url}')`,
              },
            }),
          ])
        : _vm.isVideo
        ? _c("div", { staticClass: "vac-video-reply-container" }, [
            _c(
              "video",
              { attrs: { width: "100%", height: "100%", controls: "" } },
              [_c("source", { attrs: { src: _vm.firstFile.url } })]
            ),
          ])
        : _vm.isAudio
        ? _c("audio-player", {
            attrs: { src: _vm.firstFile.url },
            on: {
              "update-progress-time": function ($event) {
                _vm.progressTime = $event
              },
              "hover-audio-progress": function ($event) {
                _vm.hoverAudioProgress = $event
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (i, name) {
                  return {
                    key: name,
                    fn: function (data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          })
        : _vm.isOtherFile
        ? _c("div", { staticClass: "vac-file-container" }, [
            _c("div", [
              _c(
                "div",
                [
                  _vm.firstFile.type == "application/pdf"
                    ? _c("img", {
                        staticClass: "size-file",
                        attrs: {
                          src: require("../../../../../assets/icon-pdf.svg"),
                          alt: "",
                        },
                      })
                    : _vm.firstFile.type ==
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ? _c("img", {
                        staticClass: "size-file",
                        attrs: {
                          src: require("../../../../../assets/icon-doc.svg"),
                          alt: "",
                        },
                      })
                    : _vm.firstFile.type ==
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ? _c("img", {
                        staticClass: "size-file",
                        attrs: {
                          src: require("../../../../../assets//icon-excel.svg"),
                          alt: "",
                        },
                      })
                    : _c("svg-icon", {
                        staticClass: "size-file",
                        attrs: { name: "file", alt: "" },
                      }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "vac-text-ellipsis",
                staticStyle: { color: "var(--cj-color-black-primary)" },
              },
              [_vm._v("\n      " + _vm._s(_vm.firstFile.name) + "\n    ")]
            ),
            _vm._v(" "),
            _vm.firstFile.extension
              ? _c(
                  "div",
                  { staticClass: "vac-text-ellipsis vac-text-extension" },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.firstFile.extension) + "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vac-reply-content" },
        [
          _c("format-message", {
            attrs: {
              content: _vm.message.replyMessage.content,
              "sticker-url": _vm.message.replyMessage.stickerUrl,
              users: _vm.roomUsers,
              "text-formatting": _vm.textFormatting,
              "link-options": _vm.linkOptions,
              reply: true,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (i, name) {
                  return {
                    key: name,
                    fn: function (data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }