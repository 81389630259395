import dayjs from 'dayjs';
import Vue2Filters from 'vue2-filters';
import { Inject, Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';

import Icon from '@/shared/icons/icon.vue';
import CButton from '@/components/button/c-button.vue';
import CTimepicker from '../c-timepicker/c-timepicker.vue';
import CAutocompleteNew from '../c-autocomplete-new/c-autocomplete-new.vue';

import PCbVwUserScheduleService from '@/services/cb-vw-user-schedule.service';
import ContentService from '@/services/content.service';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/shared/date/filters';
import { ICbVwUserSchedule } from '@/shared/model/cb-vw-user-schedule.model';
import { ModalStepFormOrderType } from '@/shared/model/enumerations/modal-step-form-order-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';
import { ICbLov } from '@/shared/model/cb-lov.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

const validations: any = {
  startDate: { required },
  startHour: { required },
  timeZone: { required },
};
@Component({
  validations,
  mixins: [Vue2Filters.mixin],
  components: {
    FullCalendar,
    Icon,
    CButton,
    CTimepicker,
    CAutocompleteNew,
  },
})
export default class CPawBookingDate extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbVwUserScheduleService') private pCbVwUserScheduleService: () => PCbVwUserScheduleService;
  @Inject('contentService') private contentService: () => ContentService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public parentBookStartDate: Date;
  @Prop({ default: null }) public parentTimeZone: string;
  @Prop({ default: null }) public parentExpiredDateOrderDelivery: Date;
  @Prop({ default: null }) public modalStepFormOrders: ModalStepFormOrderType[];
  @Prop({ default: null }) public withoutModalStepFormOrders: boolean;
  @Prop({ default: null }) public userId: string;
  @Prop({ default: null }) public bookingType: TypeComponent;
  @Prop({ default: false }) public isLoadingButtonPawBookingDate: string;

  public cbVwUserSchedules: ICbVwUserSchedule[] = [];
  public cbVwUserHibernationSchedules: ICbVwUserSchedule[] = [];

  public eventGuid = 0;
  public todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

  public INITIAL_EVENTS = [];

  public currentEvents: any[] = [];

  public selectedCalendarDate: Date = null;
  public paramsSchedule: any = {};
  public paramsHibernationSchedule: any = {};

  public startDate: Date = null;
  public startHour: Date | string | number = null;
  public validateDueTime = false;
  public disabledStartHour = false;
  public calendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    selectable: true,
    selectLongPressDelay: 0,
    select: this.handleDateSelect,
    droppable: false,
    disableDragging: true,
    editable: false,
    selectMirror: true,
    weekends: true,
    events: [],
    height: 350,
    datesSet: this.handleMonthChange,
    dayMaxEvents: 2,
    dayCellClassNames: this.fcDayClassName,
    selectAllow: this.fcDisabledSelect,
    dayCellDidMount: this.fcTooltip,
    moreLinkContent: function (args) {
      return '+' + args.num;
    },
  };
  public cbLovTimezones: ICbLov[] = [];
  public cbLovTimezone: ICbLov;
  public timeZone = '';
  public hideUserSchedule = false;
  public splitStartHour: string[] = null;
  public TypeComponent = TypeComponent;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.userIdListener();
    this.contentService().initRetrieveCbLovs(CbLovType.TIME_ZONE);
  }

  public mounted() {
    this.insertDataFromParent();

    if (this.bookingType !== TypeComponent.APPOINTMENT) {
      this.retrieveCbVwUserHibernationSchedules();
      this.retrieveCbVwUserSchedules();
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }
  @Emit('back')
  public back() {
    return;
  }

  public createEventId() {
    return String(this.eventGuid++);
  }

  public handleDateSelect(selectInfo) {
    if (selectInfo) this.selectedCalendarDate = selectInfo.start;

    if (this.isValidDateSelect(selectInfo)) {
      this.startDate = selectInfo.start;

      if (this.startHour) {
        this.splitStartHour = this.startHour.toString().split(':');
        this.startDate = dayjs(this.startDate).set('hour', +this.splitStartHour[0]).set('minute', +this.splitStartHour[1]).toDate();
        this.validateDueTime = this.startDate?.getTime() > dayjs().toDate().getTime();
      }

      if (this.bookingType === TypeComponent.APPOINTMENT) {
        this.validateStartHourAppointment(selectInfo);
      } else {
        this.validateStartHourBookingDate(selectInfo);
      }
    } else {
      const foundIndex = this.calendarOptions.events.findIndex(e => e.isNew);
      if (foundIndex >= 0) {
        Vue.delete(this.calendarOptions.events, foundIndex);
      }

      this.disabledStartHour = true;
      this.startDate = null;

      if (this.startDate) {
        this.startHour = 0;
        this.startHour = 0;
      }
    }
  }

  public validateStartHourBookingDate(selectInfo) {
    // search for dates that are already booked
    const foundAvailableDate = this.calendarOptions.events.find(
      e => e.start && e.end && dayjs(this.startDate).isSameOrAfter(dayjs(e.start)) && dayjs(this.startDate).isSameOrBefore(dayjs(e.end))
    );

    if (foundAvailableDate) {
      this.disabledStartHour = true;
      const foundIndex = this.calendarOptions.events.findIndex(e => e.isNew);
      if (foundIndex >= 0) {
        Vue.delete(this.calendarOptions.events, foundIndex);
      }

      if (!this.startHour) {
        this.startHour = 0;
        this.startHour = 0;
      }
      return;
    }

    const calendar: any = {
      start: selectInfo.startStr,
      rendering: 'background',
      isNew: true,
    };

    // delete the previously selected date to be replaced
    const foundIndex = this.calendarOptions.events.findIndex(e => e.isNew);
    this.disabledStartHour = false;

    if (foundIndex >= 0) {
      Vue.set(this.calendarOptions.events, foundIndex, calendar);
    } else {
      this.calendarOptions.events.push(calendar);
    }
  }

  public validateStartHourAppointment(selectInfo) {
    const isValidIsSameOrBefore = dayjs(dayjs(this.parentExpiredDateOrderDelivery).toDate()).isSameOrBefore(this.startDate);

    if (isValidIsSameOrBefore) {
      this.disabledStartHour = true;
      const foundIndex = this.calendarOptions.events.findIndex(e => e.isNew);
      if (foundIndex >= 0) {
        Vue.delete(this.calendarOptions.events, foundIndex);
      }

      if (!this.startHour) {
        this.startHour = 0;
        this.startHour = 0;
      }
      return;
    }

    const calendar: any = {
      start: selectInfo.startStr,
      rendering: 'background',
      isNew: true,
    };

    // delete the previously selected date to be replaced
    const foundIndex = this.calendarOptions.events.findIndex(e => e.isNew);
    this.disabledStartHour = false;

    if (foundIndex >= 0) {
      Vue.set(this.calendarOptions.events, foundIndex, calendar);
    } else {
      this.calendarOptions.events.push(calendar);
    }
  }

  public inputStartHourAppointment() {
    if (this.startHour && this.startDate) {
      this.splitStartHour = this.startHour?.toString()?.split(':');
      this.startDate = dayjs(this.startDate).set('hour', +this.splitStartHour[0]).set('minute', +this.splitStartHour[1]).toDate();

      // search for a date according to the time cannot be more than the delivery time
      const isValidIsSameOrBefore = dayjs(dayjs(this.parentExpiredDateOrderDelivery).toDate()).isSameOrBefore(this.startDate);
      if (isValidIsSameOrBefore) {
        this.disabledStartHour = true;
        return;
      }

      this.disabledStartHour = false;

      const calendar: any = {
        start: dayjs(this.startDate).format(DATE_TIME_FORMAT),
        rendering: 'background',
        isNew: true,
      };

      const foundIndex = this.calendarOptions.events.findIndex(e => e.isNew);
      if (foundIndex >= 0) {
        Vue.set(this.calendarOptions.events, foundIndex, calendar);
      } else {
        this.calendarOptions.events.push(calendar);
      }
    }
  }

  public inputStartHourBookingDate() {
    if (this.startHour && this.startDate) {
      this.splitStartHour = this.startHour?.toString()?.split(':');
      this.startDate = dayjs(this.startDate).set('hour', +this.splitStartHour[0]).set('minute', +this.splitStartHour[1]).toDate();

      // search for dates that are already booked
      const foundAvailableDate = this.calendarOptions.events.findIndex(
        e => e.start && e.end && dayjs(this.startDate).isSameOrAfter(dayjs(e.start)) && dayjs(this.startDate).isSameOrBefore(dayjs(e.end))
      );
      if (foundAvailableDate >= 0) {
        this.disabledStartHour = true;
        return;
      }

      this.disabledStartHour = false;

      const calendar: any = {
        start: dayjs(this.startDate).format(DATE_TIME_FORMAT),
        rendering: 'background',
        isNew: true,
      };

      const foundIndex = this.calendarOptions.events.findIndex(e => e.isNew);
      if (foundIndex >= 0) {
        Vue.set(this.calendarOptions.events, foundIndex, calendar);
      } else {
        this.calendarOptions.events.push(calendar);
      }
    }
  }

  public submit() {
    this.$emit('submit', this.startDate, this.timeZone);
  }

  public handleMonthChange(payload) {
    if (this.bookingType === TypeComponent.APPOINTMENT) return;

    if (payload.start && payload.end) {
      if (this.paramsSchedule['minBookStartDate.greaterThanOrEqual'] && this.paramsSchedule['maxBookStartDate.lessThanOrEqual']) {
        if (
          JSON.stringify(payload.start) != JSON.stringify(this.paramsSchedule['minBookStartDate.greaterThanOrEqual']) &&
          JSON.stringify(payload.end) != JSON.stringify(this.paramsSchedule['maxBookStartDate.lessThanOrEqual'])
        ) {
          this.paramsSchedule['minBookStartDate.greaterThanOrEqual'] = payload.start;
          this.paramsSchedule['maxBookStartDate.lessThanOrEqual'] = payload.end;
          this.retrieveCbVwUserSchedules();
        }
      } else {
        this.paramsSchedule['minBookStartDate.greaterThanOrEqual'] = payload.start;
        this.paramsSchedule['maxBookStartDate.lessThanOrEqual'] = payload.end;
        this.retrieveCbVwUserSchedules();
      }
    }
  }
  public retrieveCbVwUserHibernationSchedules(): void {
    if (this.hideUserSchedule) {
      return;
    }

    this.paramsHibernationSchedule['partnerId.equals'] = this.userId;
    this.paramsHibernationSchedule['scheduleType.equals'] = CbCommonStatus.OFF_DUTY;
    this.pCbVwUserScheduleService()
      .retrieve(this.paramsHibernationSchedule)
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.cbVwUserHibernationSchedules = res.data;
          this.processAssignSchedulesHibesOnCallendar();
        }
      });
  }

  public retrieveCbVwUserSchedules(): void {
    if (this.hideUserSchedule) {
      return;
    }

    this.paramsSchedule['partnerId.equals'] = this.userId;
    this.paramsSchedule['orderStatus.in'] = [
      CbCommonStatus.WAITING_PAYMENT,
      CbCommonStatus.BOOKED,
      CbCommonStatus.ON_THE_WAY,
      CbCommonStatus.ON_PROGRESS,
      CbCommonStatus.LATE,
    ];

    this.pCbVwUserScheduleService()
      .retrieve(this.paramsSchedule)
      .then(res => {
        this.cbVwUserSchedules = res.data;
        this.processAssignScheduleOnCallendar();
      });
  }

  public processAssignScheduleOnCallendar() {
    if (this.cbVwUserSchedules && this.cbVwUserSchedules.length > 0) {
      const events: any[] = [];

      if (this.parentBookStartDate && this.parentTimeZone) {
        events.push({
          id: '',
          start: dayjs(this.parentBookStartDate).format(DATE_TIME_FORMAT),
          end: '',
          title: '',
          status: '',
          isNew: true,
          color: 'var(--cj-color-red-primary)',
        });
      }

      for (const parentCbVwUserSchedule of this.cbVwUserSchedules) {
        const event: any = {
          id: parentCbVwUserSchedule.id,
          start: dayjs(parentCbVwUserSchedule.minBookStartDate).format(DATE_TIME_FORMAT),
          end: dayjs(parentCbVwUserSchedule.maxBookStartDate).format(DATE_TIME_FORMAT),
          title: parentCbVwUserSchedule.title,
          status: parentCbVwUserSchedule.orderStatus,
          isNew: false,
          color: 'var(--cj-color-red-primary)',
        };
        events.push(event);
      }

      setTimeout(() => {
        this.calendarOptions = {
          plugins: [dayGridPlugin, interactionPlugin],
          initialView: 'dayGridMonth',
          selectable: true,
          disableDragging: true,
          editable: false,
          selectMirror: true,
          weekends: true,
          selectLongPressDelay: 0,
          select: this.handleDateSelect,
          droppable: false,
          events,
          height: 300,
          datesSet: this.handleMonthChange,
          dayMaxEvents: 2,
          dayCellClassNames: this.fcDayClassName,
          selectAllow: this.fcDisabledSelect,
          dayCellDidMount: this.fcTooltip,
          moreLinkContent: function (args) {
            return '+' + args.num;
          },
        };
      });
    }
  }

  public processAssignSchedulesHibesOnCallendar() {
    if (this.cbVwUserHibernationSchedules && this.cbVwUserHibernationSchedules.length > 0) {
      const events: any[] = [];

      setTimeout(() => {
        this.calendarOptions = {
          plugins: [dayGridPlugin, interactionPlugin],
          initialView: 'dayGridMonth',
          selectable: true,
          disableDragging: true,
          editable: false,
          selectMirror: true,
          weekends: true,
          selectLongPressDelay: 0,
          select: this.handleDateSelect,
          droppable: false,
          events,
          height: 300,
          datesSet: this.handleMonthChange,
          dayMaxEvents: 2,
          dayCellClassNames: this.fcDayClassName,
          selectAllow: this.fcDisabledSelect,
          dayCellDidMount: this.fcTooltip,
          moreLinkContent: function (args) {
            return '+' + args.num;
          },
        };
      });
    }
  }

  public touchFilled() {
    if (this.$v.timeZone.$model) {
      this.$v.timeZone.$touch();
    }
    if (this.$v.startHour.$model) {
      this.$v.startHour.$touch();
    }
    if (this.$v.startDate.$model) {
      this.$v.startDate.$touch();
    }
  }

  public handleTime(time: Date): void {
    this.startHour = time;
    this.touchFilled();
  }

  public getValidationBookedList(start, end) {
    return dayjs(start).isSame(dayjs(end), 'day');
  }

  public insertDataFromParent() {
    if (this.parentBookStartDate) {
      this.INITIAL_EVENTS.push({
        id: this.createEventId(),
        title: 'All-Books',
        start: dayjs(this.parentBookStartDate).format('YYYY-MM-DD'),
      });
      this.startDate = dayjs(this.parentBookStartDate).toDate();
      this.startHour = dayjs(this.parentBookStartDate).format('HH:mm');

      if (this.parentTimeZone) {
        this.timeZone = this.parentTimeZone;
      }
    }
  }
  public fcDayClassName(arg) {
    const classes = [];
    const date = arg.date;
    for (const cbVwUserHibernationSchedule of this.cbVwUserHibernationSchedules) {
      // Add class for disabled dates
      const disabledStartDate = new Date(dayjs(cbVwUserHibernationSchedule.minBookStartDate).format(DATE_FORMAT));
      const disabledEndDate = new Date(dayjs(cbVwUserHibernationSchedule.maxBookStartDate).format(DATE_FORMAT));
      disabledStartDate.setDate(disabledStartDate.getDate() - 1);
      disabledEndDate.setDate(disabledEndDate.getDate() - 1);

      if (date >= disabledStartDate && date <= disabledEndDate) {
        classes.push('fc-disabled-date');
      }
    }

    return classes;
  }
  public fcDisabledSelect(selectInfo) {
    const start = new Date(selectInfo.startStr);
    const end = new Date(selectInfo.endStr);
    for (const cbVwUserHibernationSchedule of this.cbVwUserHibernationSchedules) {
      const disableStart = new Date(dayjs(cbVwUserHibernationSchedule.minBookStartDate).format(DATE_FORMAT));
      const disableEnd = new Date(dayjs(cbVwUserHibernationSchedule.maxBookStartDate).format(DATE_FORMAT));

      // Check if any part of the range overlaps with the disabled dates
      if (start < disableEnd && end > disableStart) {
        return false;
      }
      return true;
    }
    return true;
  }
  public fcTooltip(info) {
    const tooltipText = this.$t('cbGlobal.onHibernate'); // Example tooltip text
    info.el.setAttribute('fc-data-tooltip-disabled', tooltipText);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isValidDateSelect() {
    return (selectInfo: any) => {
      if (this.bookingType === TypeComponent.APPOINTMENT) {
        return (
          selectInfo.start &&
          dayjs(selectInfo.start).isSameOrBefore(dayjs(this.parentExpiredDateOrderDelivery).toDate()) &&
          dayjs(selectInfo.start).isSameOrAfter(dayjs().set('day', dayjs().get('day') - 1))
        );
      } else {
        return selectInfo.start && dayjs(selectInfo.start).isSameOrAfter(dayjs().set('day', dayjs().get('day') - 1));
      }
    };
  }

  public get getValidDate(): boolean {
    return (
      this.validateDueTime &&
      !this.disabledStartHour &&
      !this.$v.startDate.$invalid &&
      !this.$v.startHour.$invalid &&
      !this.$v.timeZone.$invalid
    );
  }

  public get bookedList(): any[] {
    if (this.selectedCalendarDate) {
      const tmpSelectedDate: Date = dayjs(this.selectedCalendarDate.setHours(0, 0, 0, 0)).toDate();

      const tmpEvent = this.calendarOptions.events.filter(e => {
        if (e.start && e.end && !e.isNew) {
          const tmpStart: Date = dayjs(dayjs(e.start).toDate().setHours(0, 0, 0, 0)).toDate();
          const tmpEnd: Date = dayjs(dayjs(e.end).toDate().setHours(0, 0, 0, 0)).toDate();
          if (dayjs(tmpSelectedDate).isSameOrAfter(dayjs(tmpStart)) && dayjs(tmpSelectedDate).isSameOrBefore(dayjs(tmpEnd))) {
            return e;
          }
        }
        return null;
      });
      if (tmpEvent) {
        return tmpEvent;
      }
    }
    return [];
  }

  public get textBasedModel() {
    return this.bookingType === TypeComponent.APPOINTMENT
      ? {
          headerTitle: this.$t('cbgwApp.cbComponent.cbPawBookingDate.titleAppointment').toString(),
          dateLabel: this.$t('cbgwApp.cbComponent.cbPawBookingDate.selectDateAppointment').toString(),
          hourLabel: this.$t('cbgwApp.cbComponent.cbPawBookingDate.startHourAppointment').toString(),
          desc: this.$t('cbgwApp.cbComponent.cbPawBookingDate.desc').toString(),
        }
      : {
          headerTitle: this.$t('cbgwApp.cbComponent.cbPawBookingDate.title').toString(),
          dateLabel: this.$t('cbgwApp.cbComponent.cbPawBookingDate.selectDate').toString(),
          hourLabel: this.$t('cbgwApp.cbComponent.cbPawBookingDate.startHour').toString(),
          desc: this.$t('cbgwApp.cbComponent.cbPawBookingDate.desc').toString(),
        };
  }

  public get TIME_ZONE() {
    return this.$store.getters.TIME_ZONE;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('startHour')
  public listenStartHour() {
    if (this.bookingType === TypeComponent.APPOINTMENT) {
      this.inputStartHourAppointment();
    } else {
      this.inputStartHourBookingDate();
    }

    this.validateDueTime = this.startDate?.getTime() > dayjs().toDate().getTime();
    this.touchFilled();
  }

  @Watch('userId')
  public userIdListener() {
    if (this.userId) {
      const events: any = [];

      if (this.parentBookStartDate && this.parentTimeZone) {
        events.push({
          id: '',
          start: dayjs(this.parentBookStartDate).format(DATE_TIME_FORMAT),
          end: '',
          title: '',
          status: '',
          isNew: true,
          color: 'var(--cj-color-red-primary)',
        });
      }

      setTimeout(() => {
        this.calendarOptions = {
          plugins: [dayGridPlugin, interactionPlugin],
          initialView: 'dayGridMonth',
          selectable: true,
          disableDragging: true,
          editable: false,
          selectMirror: true,
          weekends: true,
          selectLongPressDelay: 0,
          select: this.handleDateSelect,
          droppable: false,
          events,
          height: 300,
          datesSet: this.handleMonthChange,
          dayMaxEvents: 2,
          dayCellClassNames: this.fcDayClassName,
          selectAllow: this.fcDisabledSelect,
          dayCellDidMount: this.fcTooltip,
          moreLinkContent: function (args) {
            return '+' + args.num;
          },
        };
      });
    }
  }

  @Watch('timeZone')
  public listenTimeZone() {
    if (this.timeZone) {
      this.touchFilled();
    }
  }

  // ================= END LISTENERS ===================
}
