var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-accout-deactivate-update",
      attrs: { "data-cy": "modal-deactive-delete" },
    },
    [
      _c("c-confirmation", {
        staticClass: "w-100",
        attrs: {
          title:
            _vm.localCbUserInfo.userStatus == _vm.CbUserStatusType.DELETED
              ? _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.deleteAccount")
              : _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.title"),
          subTitle:
            _vm.localCbUserInfo.userStatus == _vm.CbUserStatusType.DELETED
              ? _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.descDeleteUpdate")
              : _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.tellUsDeactive"),
          iconCustom: "ic_exclamation",
        },
        on: { cancel: _vm.cancel, close: _vm.cancel },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _vm.localCbUserInfo.userStatus == _vm.CbUserStatusType.DEACTIVED
          ? _c(
              "p",
              {
                staticClass:
                  "font-size-18 cj-color-black-primary font-roboto mb-2 font-weight-500",
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.imDeactiveBecause")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "cj-color-grey-secondary" }, [
                  _vm._v("*"),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.localCbUserInfo.userStatus == _vm.CbUserStatusType.DELETED
          ? _c(
              "p",
              {
                staticClass:
                  "font-size-18 cj-color-black-primary font-roboto mb-2 font-weight-500",
              },
              [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbSetting.imLeavingBecause")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "cj-color-grey-secondary" }, [
                  _vm._v("*"),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "input-select" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$v.localCbUserInfo.inActiveReason.$model,
                  expression: "$v.localCbUserInfo.inActiveReason.$model",
                },
              ],
              staticClass: "cj-bg-white-primary",
              class: {
                valid:
                  _vm.$v.localCbUserInfo.inActiveReason.$anyDirty &&
                  !_vm.$v.localCbUserInfo.inActiveReason.$invalid,
                invalid:
                  _vm.$v.localCbUserInfo.inActiveReason.$anyDirty &&
                  _vm.$v.localCbUserInfo.inActiveReason.$invalid,
                "cj-field-border-default  ":
                  !_vm.$v.localCbUserInfo.inActiveReason.$model,
              },
              attrs: { "data-cy": "option-reason" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.$v.localCbUserInfo.inActiveReason,
                    "$model",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c(
                "option",
                {
                  staticClass: "cj-color-grey-tertiary",
                  domProps: { value: null },
                },
                [
                  _c("span", {
                    staticClass: "cj-color-grey-tertiary",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.accountDeactivation.selectReason"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "option",
                { domProps: { value: "I have another Klikjob Account" } },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.accountDeactivation.descLeavingOne"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "option",
                { domProps: { value: "I can’t find what I need in Klikjob" } },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.accountDeactivation.descLeavingTwo"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "option",
                {
                  domProps: { value: "Klikjob is complicated or hard to use" },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.accountDeactivation.descLeavingThree"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "option",
                {
                  domProps: {
                    value: "Negative experience with Buyer/Freelancer",
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.accountDeactivation.descLeavingFour"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "option",
                { domProps: { value: "I’m unhappy with Klikjob policies" } },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.accountDeactivation.descLeavingFive"
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("option", {
                domProps: {
                  value: "Other",
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSetting.accountDeactivation.other")
                  ),
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-3" }, [
        _c(
          "p",
          {
            staticClass:
              "font-roboto font-size-18 cj-color-black-primary mb-2 font-weight-500",
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.tellusmore")),
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "cj-color-grey-tertiary",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbSetting.accountDeactivation.optional")
                ),
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "label",
          { staticClass: "w-100 mt-2" },
          [
            _c("c-textarea-new", {
              staticClass:
                "rounded font-roboto font-weight-400 font-size-18 cj-color-black-primary",
              attrs: {
                "data-cy": "reason-text",
                placeholder: _vm.$t(
                  "cbgwApp.cbSetting.accountDeactivation.typeReason"
                ),
                maxlength: 1000,
              },
              model: {
                value: _vm.$v.localCbUserInfo.inActiveReasonMore.$model,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.$v.localCbUserInfo.inActiveReasonMore,
                    "$model",
                    $$v
                  )
                },
                expression: "$v.localCbUserInfo.inActiveReasonMore.$model",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "c-button",
        {
          staticClass: "py-1",
          attrs: {
            disabled: _vm.$v.localCbUserInfo.$invalid,
            dataGTMCustom: "deactivate-account-submit-button",
            fullWidth: "",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _vm.localCbUserInfo.userStatus == _vm.CbUserStatusType.DEACTIVED
            ? _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.deactiveMyAccount"
                    )
                  ),
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.localCbUserInfo.userStatus == _vm.CbUserStatusType.DELETED
            ? _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.deleteMyAccount")
                  ),
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }