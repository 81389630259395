import { Component, Vue, Prop, Inject } from 'vue-property-decorator';

import CButton from '@/components/button/c-button.vue';

import GlobalService from '@/services/global.service';

import { CbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';

@Component({
  components: {
    CButton,
  },
})
export default class CItemChatMessageClockin extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrderRequirement: CbVwPawOrderRequirement;
  @Prop({ default: null }) public cbVwPawOrder: CbVwPawOrder;

  public CbCommonStatus = CbCommonStatus;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========

  // ================= START FUNCTION =================
  public handleCMediaPreview(localUrl: string) {
    this.$root.$emit('app::cMediaPreview', localUrl);
    this.globalService().openGlobalDialog(this.$root, 'modal-c-media-preview');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
