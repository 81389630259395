import { Module } from 'vuex';

export interface TrackStateStorable {
  data: PosTrack[];
}

export interface PosTrack {
  lat: string;
  lng: string;
  idOrder: string;
}

export const defaultTrackStateStorable: TrackStateStorable = {
  data: [],
};

export const trackStore: Module<TrackStateStorable, any> = {
  state: { ...defaultTrackStateStorable },
  getters: {
    data: state => state.data,
  },
  mutations: {
    setTrackData(state, data: PosTrack[]) {
      state.data = data;
    },
  },
};
