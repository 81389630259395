var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-action-item",
      attrs: { "data-cy": "message-action-item" },
    },
    [
      _vm.isShowMenuReply
        ? _c(
            "div",
            {
              staticClass:
                "floating-btn d-flex justify-content-center align-items-center cursor-pointer",
              attrs: { "data-cy": "message-action-reply" },
              on: {
                click: function ($event) {
                  return _vm.showAction(_vm.messageAction)
                },
              },
            },
            [_c("b-icon-reply", { staticClass: "icon-wrapper" })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "floating-btn d-flex justify-content-center align-items-center cursor-pointer",
          attrs: { "data-cy": "message-action-emoji" },
          on: {
            click: function ($event) {
              return _vm.handleShowReactions()
            },
          },
        },
        [_c("b-icon-emoji-wink", { staticClass: "icon-wrapper" })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "floating-btn d-flex justify-content-center align-items-center cursor-pointer",
          attrs: { "data-cy": "message-action-star" },
        },
        [
          !_vm.$options.filters.chatIsStar(_vm.message.stars, _vm.currentUserId)
            ? _c("b-icon-star", {
                staticClass: "icon-wrapper",
                on: {
                  click: function ($event) {
                    return _vm.$emit("message-action-handler", "STAR")
                  },
                },
              })
            : _c("b-icon-star-fill", {
                staticClass: "icon-wrapper",
                on: {
                  click: function ($event) {
                    return _vm.$emit("message-action-handler", "STAR_DELETE")
                  },
                },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "floating-btn d-flex justify-content-center align-items-center cursor-pointer",
          attrs: { "data-cy": "message-action-delete" },
          on: {
            click: function ($event) {
              return _vm.handleMsgDelete()
            },
          },
        },
        [
          _c("icon", {
            staticClass: "cj-fill-red-primary",
            attrs: { name: "ic_outline_delete", width: "20px", height: "20px" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }