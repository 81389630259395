var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "room-camera", attrs: { "data-cy": "modal-room-camera" } },
    [
      _c("div", { staticClass: "d-flex align-items-center p-3 header-m" }, [
        _vm.switchCameraRecord
          ? _c("p", { staticClass: "m-0 text-title" }, [_vm._v("Video")])
          : _c("p", { staticClass: "m-0 text-title" }, [_vm._v("Camera")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "vac-svg-button custom-margin",
            attrs: { "data-cy": "vac-back-icon" },
            on: {
              click: function ($event) {
                return _vm.callBack()
              },
            },
          },
          [
            _c("b-icon-x-circle-fill", {
              staticClass: "font-size-24 cj-color-grey-secondary",
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("c-take-picture", {
        attrs: {
          switchCameraRecord: _vm.switchCameraRecord,
          btnTextConfirm: _vm.btnTextConfirm,
        },
        on: {
          closeShowSwitchCameraRecord: _vm.closeShowSwitchCameraRecord,
          isShowSwitchCameraRecord: _vm.isShowSwitchCameraRecord,
          submitPicture: _vm.submitPicture,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }