import CButton from '@/components/button/c-button.vue';
import CFpInformation from '@/components/c-form-payment-2023/components/c-fp-information/c-fp-information.vue';
import CInputNumber from '@/components/c-input-number/c-input-number.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import Icon from '@/shared/icons/icon.vue';
import { CbBankAccount, ICbBankAccount } from '@/shared/model/cb-bank-account.model';
import FormUtils from '@/shared/utils/form-utils.service';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

const validations: any = {
  newCbCreditCard: {
    accountNumber: {
      required,
      isFormatCC(accountNumber) {
        return accountNumber.replace(/\s/g, '').replace(/[^,\d]/g, '').length == 16;
      },
    },
    accountHolderName: { required, maxLength: maxLength(500) },
    expiryDateLabel: { required, minLength: minLength(5), maxLength: maxLength(5) },
  },
};
@Component({
  validations,
  components: {
    Icon,
    CButton,
    CFpInformation,
    CInputNumber,
  },
  watch: {
    'newCbCreditCard.accountHolderName': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 500;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.newCbCreditCard.accountHolderName = oldVal;
        }

        this.handleCheckUsername(this.newCbCreditCard.accountHolderName);

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
    'newCbCreditCard.accountNumber': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 16;
        const total = newVal?.length ? newVal.length : 0;
        
        if( this.newCbCreditCard.accountNumber != null) {

          this.newCbCreditCard.accountNumber = this.inputCardNumber(this.newCbCreditCard.accountNumber);
        }
      
        this.validatePasteMaxLengthUtils(max+ 4, total);
      }
    }
  },
})
export default class CBaCreditUpdate extends mixins(JhiDataUtils, FormUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public newCbCreditCardParent: CbBankAccount;
  public newCbCreditCard: ICbBankAccount = new CbBankAccount();
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.listenNewCbCreditCardParent();
  }

  public created() {
    (<any>this.$root).$off('ccUpdate::changeValue');
    (<any>this.$root).$on('ccUpdate::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });

    this.$root.$off('ccUpdate::changeFunction');
    this.$root.$on('ccUpdate::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public inputCardNumber(e: any) {
    let newString = e.replace(/\s/g, '');
    if (!Number(newString)) {
      newString = newString.replace(/[^\d]+/g, '');
    }
    const v = newString
      .replace(/\s+/g, '')
      .replace(/[^,\d]/g, '')
      .replace(/[^\d]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    let parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    let result: string = '';
    if (parts.length) {
      result = parts.join(' ');
    } else {
      result = newString;
    }

    return result;
  }

  public formatExpiredDate(e) {
    const code = e.keyCode;
    const allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    return e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([\d]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );
  }

  public handleCheckUsername(username: string) {
    if (username) {
      this.newCbCreditCard.accountHolderName = username.replace(/[^A-Za-z ]/gi, '');
    }
  }

  public handleCancel() {
    this.newCbCreditCard = new CbBankAccount();
    this.$emit('cancel');
    this.$root.$emit('bankAccountParent::changeValue', 'newCbCreditCard', null);
    this.$v.$reset();
  }

  public handleSubmit() {
    this.$root.$emit('bankAccountParent::changeFunction', 'submitNewBankCC', this.newCbCreditCard);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get disabled() {
    if (!this.newCbCreditCard) {
      return true;
    }
    if (!this.newCbCreditCard.accountHolderName) {
      return true;
    }
    if (!this.newCbCreditCard.accountNumber || (this.newCbCreditCard.accountNumber && this.newCbCreditCard.accountNumber.length != 19)) {
      return true;
    }
    if (
      !this.newCbCreditCard.expiryDateLabel ||
      (this.newCbCreditCard.expiryDateLabel && this.newCbCreditCard.expiryDateLabel.length != 5)
    ) {
      return true;
    }

    return false;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('newCbCreditCardParent')
  public listenNewCbCreditCardParent() {
    if (this.newCbCreditCardParent != null) {
      this.newCbCreditCard = Object.assign({}, this.newCbCreditCardParent);
    } else {
      this.newCbCreditCard = new CbBankAccount();
    }
  }
  // ================= END LISTENERS ==================
}
