export enum ChtContentType {
  ORDER_REQUIREMENT = 'ORDER_REQUIREMENT',

  ORDER_MOM = 'ORDER_MOM',

  ORDER_REVISION = 'ORDER_REVISION',

  ORDER_REVISION_OFFER = 'ORDER_REVISION_OFFER',

  ORDER_PROJECT_DELIVERY = 'ORDER_PROJECT_DELIVERY',

  IMAGE = 'IMAGE',

  VIDEO = 'VIDEO',

  FILE = 'FILE',

  STICKER = 'STICKER',

  CB_STICKER = 'CB_STICKER',

  PROJECT_AUCTION = 'PROJECT_AUCTION',

  PROJECT_AUCTION_OFFER = 'PROJECT_AUCTION_OFFER',

  DOC = 'DOC',

  PDF = 'PDF',

  ZIP = 'ZIP',

  EXCEL = 'EXCEL',

  TEXT = 'TEXT',

  AUDIO = 'AUDIO',

  PAW = 'PAW',

  DELIVERY = 'DELIVERY',

  ORDER_ON_MY_WAY = 'ORDER_ON_MY_WAY',

  ORDER_CLOCK_IN = 'ORDER_CLOCK_IN',

  ORDER_CLOCK_OUT = 'ORDER_CLOCK_OUT',

  ORDER_COMPLETED = 'ORDER_COMPLETED',

  ORDER_MILESTONE = 'ORDER_MILESTONE',

  ORDER_APPOINTMENT = 'ORDER_APPOINTMENT',
  LIVE_AGENT_FEEDBACK = 'LIVE_AGENT_FEEDBACK',
  NOTIF_LIVE_AGENT_CLOSED = 'NOTIF_LIVE_AGENT_CLOSED',
}
