var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-tmp-announcement",
      class: !_vm.isMobile ? "mx-min-custom" : "",
    },
    [
      _c(
        "div",
        { staticClass: "cb-position-sticky-top border-grey-tertiary-bottom-1" },
        [
          _c(
            "p",
            {
              staticClass: "text-center font-roboto cj-color-black-primary",
              class: _vm.isMobile
                ? "font-size-18 font-weight-500"
                : "font-size-24 font-weight-700",
            },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "position-btn",
              attrs: { "data-cy": _vm.dataCyClose, type: "button" },
              on: { click: _vm.close },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "py-3",
        class: !_vm.isMobile ? "px-3" : "",
        domProps: {
          innerHTML: _vm._s(_vm.$options.filters.SECURITY_VHTML(_vm.content)),
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }