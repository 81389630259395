import dayjs from 'dayjs';
import Vue2Filters from 'vue2-filters';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';

import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { User } from '@/shared/model/user.model';
import { CbPawOrder, ICbPawOrder } from '@/shared/model/cb-paw-order.model';
import { CbLocation, ICbLocation } from '@/shared/model/cb-location.model';
import { ChtLocation, IChtLocation } from '@/shared/model/cht-location.model';
import { ICbPawOrderRequirement, CbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { CbVwPawOrder, ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { ICbVwAuctionProjectPreOffer } from '@/shared/model/cb-vw-auction-project-pre-offer.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { CbDeliveryTimeType } from '@/shared/model/enumerations/cb-delivery-time-type.model';
import { ModalStepFormOrderType } from '@/shared/model/enumerations/modal-step-form-order-type.model';
import { HANDLE_VIEW_MAP_TRACK } from '@/module/chat-container/order-paw-controller.component';

@Component({
  mixins: [Vue2Filters.mixin, StatusTextEnId],
  components: {
    CButton,
    Icon,
    CTextareaPreview,
  },
})
export default class CItemChatMessageAuctionProject extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwAuctionProjectPreOffer?: ICbVwAuctionProjectPreOffer;
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;
  @Prop({ default: false }) public isYourSelf: boolean;

  public toWorkLocation: ICbLocation = new CbLocation();

  public visibleDescription = false;
  public visiblePitch = false;
  public visibleDate = false;
  public visibleLocation = false;

  public CbLoginType = CbLoginType;
  public CbCommonStatus = CbCommonStatus;
  public CbSvcType = CbSvcType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.cbVwAuctionProjectPreOfferListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public openMakeOffer() {
    (<any>this.$root).$emit('opc::changeFunction', 'getValidationUserHibernation', 'VALIDATION', ({ isResult }) => {
      if (isResult) {
        (<any>this.$root).$emit('opc::changeFunction', 'getValidationUserHibernation', 'CALL');
      } else {
        const cbPawOrder = this.processAssignPawOrder();
        const cbPawOrderRequirement: ICbPawOrderRequirement = this.processAssignPawOrderRequirement();
        cbPawOrder.cbPawOrderRequirements.push(cbPawOrderRequirement);
        cbPawOrder.pawPkgDescription = this.cbVwAuctionProjectPreOffer.pitch;

        this.$root.$emit('opc::changeValue', 'cbPawOrder', cbPawOrder);
        this.$root.$emit('opc::changeValue', 'cbPawOrderRequirement', cbPawOrderRequirement);
        this.$root.$emit('opc::changeValue', 'cbVwAuctionProjectPreOffer', this.cbVwAuctionProjectPreOffer);

        this.$root.$emit('opc::changeValue', 'modalStepFormOrders', [ModalStepFormOrderType.isShowPkgCustom]);
        (<any>this.$root).$emit('mdl::changeValue', 'isShowPkgCustom', true);
      }
    });
  }

  public processAssignPawOrder(): ICbPawOrder {
    const cbPawOrder = new CbPawOrder();

    cbPawOrder.buyer = new User();
    cbPawOrder.partner = new User();
    cbPawOrder.cbPawOrderExtras = [];
    cbPawOrder.cbPawOrderMilestones = [];
    cbPawOrder.cbPawOrderRequirements = [];
    cbPawOrder.cbPawOrderIncludes = [];

    cbPawOrder.pawTitle = this.cbVwAuctionProjectPreOffer.title;
    cbPawOrder.pawPkgDescription = this.cbVwAuctionProjectPreOffer.description;
    cbPawOrder.pawIdHistory = this.cbVwAuctionProjectPreOffer.cbAuctionProjectId;
    cbPawOrder.buyer.id = this.cbVwAuctionProjectPreOffer.authorUserId;
    cbPawOrder.partner.id = this.cbVwAuctionProjectPreOffer.senderId;
    cbPawOrder.svcName = this.cbVwAuctionProjectPreOffer.svcName;
    cbPawOrder.svcCategoryName = this.cbVwAuctionProjectPreOffer.catName;
    cbPawOrder.deliveryTime = this.cbVwAuctionProjectPreOffer.deliveryTime;
    cbPawOrder.deliveryTimeType = this.cbVwAuctionProjectPreOffer.deliveryTimeType;
    cbPawOrder.orderType = CbOrderType.AUCTION_PROJECT;
    cbPawOrder.pitchId = this.cbVwAuctionProjectPreOffer.id;
    cbPawOrder.pitch = this.cbVwAuctionProjectPreOffer.pitch;
    cbPawOrder.isActive = true;
    cbPawOrder.svcType = <CbSvcType>this.cbVwAuctionProjectPreOffer.svcType;
    cbPawOrder.currency = this.cbVwAuctionProjectPreOffer.currency;
    cbPawOrder.revision = this.cbVwAuctionProjectPreOffer.revisionCount;
    cbPawOrder.isProjectGuarantee = this.cbVwAuctionProjectPreOffer.isProjectGuarantee;
    cbPawOrder.priceType = this.cbVwAuctionProjectPreOffer.priceType;
    cbPawOrder.projectValueAmount = this.processAssignProjectValueAmount(cbPawOrder);

    return cbPawOrder;
  }

  public processAssignProjectValueAmount(cbPawOrder: ICbPawOrder): number {
    let projectValueAmount = 0;

    if (cbPawOrder?.priceType == CbPriceType.PER_HOUR && cbPawOrder?.deliveryTimeType && cbPawOrder?.deliveryTime) {
      if (cbPawOrder?.deliveryTimeType.toUpperCase() == CbDeliveryTimeType.DAY) {
        projectValueAmount = this.cbVwAuctionProjectPreOffer.budget / (24 * +cbPawOrder.deliveryTime);
      } else {
        projectValueAmount = this.cbVwAuctionProjectPreOffer.budget / +cbPawOrder.deliveryTime;
      }
    } else {
      projectValueAmount = this.cbVwAuctionProjectPreOffer.budget;
    }
    return projectValueAmount;
  }

  public processAssignPawOrderRequirement(): ICbPawOrderRequirement {
    const cbPawOrderRequirement: ICbPawOrderRequirement = new CbPawOrderRequirement();

    cbPawOrderRequirement.requirementType = CbRequirementType.REQUIREMENT;
    cbPawOrderRequirement.title = CbRequirementType.REQUIREMENT;
    cbPawOrderRequirement.status = CbCommonStatus.WAITING_PAYMENT;
    cbPawOrderRequirement.buyer = new User();
    cbPawOrderRequirement.partner = new User();
    cbPawOrderRequirement.buyer.id = this.cbVwAuctionProjectPreOffer.authorUserId;
    cbPawOrderRequirement.partner.id = this.cbVwAuctionProjectPreOffer.senderId;
    cbPawOrderRequirement.progressProject = 0;
    cbPawOrderRequirement.pawIdHistory = this.cbVwAuctionProjectPreOffer.id;
    cbPawOrderRequirement.amount = this.cbVwAuctionProjectPreOffer.budget;
    cbPawOrderRequirement.baseAmount = this.cbVwAuctionProjectPreOffer.budget;
    cbPawOrderRequirement.deliveryTime = this.cbVwAuctionProjectPreOffer.deliveryTime;
    cbPawOrderRequirement.deliveryTimeType = this.cbVwAuctionProjectPreOffer.deliveryTimeType;
    cbPawOrderRequirement.bookStartDate = this.cbVwAuctionProjectPreOffer.bookStartDate;
    cbPawOrderRequirement.bookEndDate = this.cbVwAuctionProjectPreOffer.bookEndDate;
    cbPawOrderRequirement.timeZone = this.cbVwAuctionProjectPreOffer.timeZone;
    cbPawOrderRequirement.toWorkLocation = this.toWorkLocation;

    return cbPawOrderRequirement;
  }

  public handleOpenLocation() {
    if (this.toWorkLocation) {
      const chtLocation: IChtLocation = new ChtLocation();
      chtLocation.address = this.toWorkLocation.address;
      chtLocation.placeId = this.toWorkLocation.placeId;
      chtLocation.latGoogle = this.toWorkLocation.latGoogle;
      chtLocation.longGoogle = this.toWorkLocation.longGoogle;

      let cbVwPawOrder = new CbVwPawOrder();
      if (this.cbVwPawOrder?.id) {
        cbVwPawOrder = { ...this.cbVwPawOrder };

        (<any>this.$root).$emit('opc::actionHandler', {
          action: HANDLE_VIEW_MAP_TRACK,
          cbVwPawOrder,
        });
      } else {
        cbVwPawOrder.pawTitle = this.cbVwAuctionProjectPreOffer.title;

        (<any>this.$root).$emit('opc::changeValue', 'cbVwPawOrder', cbVwPawOrder);
        (<any>this.$root).$emit('opc::changeValue', 'chtLocation', chtLocation);
        (<any>this.$root).$emit('opc::changeValue', 'chtLocation', chtLocation);

        (<any>this.$root).$emit('opc::changeValue', 'isShowDetailOrderLocation', true);
      }
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get getLoginType() {
    return this.$store.getters.loginType;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }

  public get getBookDate() {
    let value = '';
    if (this.cbVwAuctionProjectPreOffer?.bookStartDate && this.cbVwAuctionProjectPreOffer?.bookEndDate) {
      const startTime = dayjs(this.cbVwAuctionProjectPreOffer.bookStartDate).format('HH:ss');
      const endTime = dayjs(this.cbVwAuctionProjectPreOffer.bookEndDate).format('HH:ss');
      value = `${dayjs(this.cbVwAuctionProjectPreOffer.bookStartDate).format('DD MMMM YYYY')} | ${startTime}-${endTime}`;
    }
    return value;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('cbVwAuctionProjectPreOffer', { deep: true })
  public cbVwAuctionProjectPreOfferListener() {
    if (this.cbVwAuctionProjectPreOffer?.toWorkLocation) {
      // tl.id
      //  tl.country
      //  tl.province
      //  tl.city
      //  tl.address || tl.place_id
      // address pinpoint
      const splitField = this.cbVwAuctionProjectPreOffer.toWorkLocation.split('::');
      this.toWorkLocation = {
        id: null,
        country: splitField[1],
        province: splitField[2],
        city: splitField[3],
        address: splitField[4],
        placeId: splitField[5],
        latGoogle: splitField[6],
        longGoogle: splitField[7],
        addressPinPoint: splitField[8],
      };
    }
  }

  // ================= END LISTENERS ===================
  public openAllCollapse() {
    this.visibleDescription = true;
    this.visiblePitch = true;
    this.visibleDate = true;
    this.visibleLocation = true;
  }
}
