var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-rmedia-pictures col-4 col-sm-3 p-2",
      on: {
        click: function ($event) {
          return _vm.handleMediaPreview(_vm.chtVwMedia, _vm.index)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "img-wrapper" },
        [
          _vm.seletedVideoImageType == _vm.chtVwMedia.isVideoImageType
            ? [
                _c("img", {
                  attrs: { src: _vm.chtVwMedia.urlThumbnail, alt: "img" },
                }),
              ]
            : [_c("img", { attrs: { src: _vm.chtVwMedia.url, alt: "img" } })],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }