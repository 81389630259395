var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-pd-profile-user",
      attrs: { "data-cy": "detail-paw-profile-user" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-wrap align-items-center px-4 py-3" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("c-user-photo-level", {
                staticClass: "mr-2 my-auto cursor-pointer",
                attrs: {
                  userPhoto: _vm.$options.filters.LAZY(
                    `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${_vm.cbVwPaw.photoAuthorId}`,
                    56,
                    "ic-lazy-avatar.png"
                  ),
                  width: _vm.isMobile ? "3rem" : "3.5rem",
                  userLevel: _vm.cbVwPaw.userLevel,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("toProfile", _vm.cbVwPaw.authorId)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mr-2" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 font-roboto font-weight-700 cj-color-white-primary text-ellipsis oneline",
                    class: _vm.isMobile ? "font-size-14" : "font-size-24",
                    attrs: { "data-cy": "detail-paw-name" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.cbVwPaw.authorName ? _vm.cbVwPaw.authorName : "-"
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("b-icon-star-fill", {
                        staticClass:
                          "cj-color-status-warning mr-1 my-auto font-size-14",
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "m-0 my-auto font-roboto font-weight-700 font-size-16 cj-color-status-warning",
                          attrs: { "data-cy": "detail-paw-star" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.cbVwPaw.userStar ? _vm.cbVwPaw.userStar : 0
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.cbVwUser.defaultSvcType === _vm.CbSvcType.FIELD
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "border-grey-tertiary-left-1 ml-2 pl-2 font-roboto font-weight-400 cj-color-white-primary",
                          class: _vm.isMobile
                            ? "font-size-12 "
                            : "font-size-16",
                          attrs: { "data-cy": "detail-paw-user-gender" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getGenderTextEnId(_vm.cbVwPaw.authorGender)
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "border-grey-tertiary-left-1 ml-2 pl-2 font-roboto font-weight-400 cj-color-white-primary",
                      class: _vm.isMobile ? "font-size-12 " : "font-size-16",
                      attrs: { "data-cy": "detail-paw-order-complete" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.cbVwPaw.orderCompletedCount
                              ? _vm.cbVwPaw.orderCompletedCount
                              : 0
                          ) +
                          " "
                      ),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbPaw.detail.sold")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.$options.filters.isUserOnline(
                    _vm.cbVwPaw.authorId,
                    _vm.userIdsOnline
                  )
                    ? _c(
                        "div",
                        {
                          staticClass: "border-grey-tertiary-left-1 ml-2 pl-2",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "circle-online font-roboto font-weight-500 cj-color-white-primary",
                              class: _vm.isMobile
                                ? "font-size-12"
                                : "font-size-14",
                            },
                            [_vm._v("\n              Online\n            ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ml-auto cursor-pointer my-auto",
              on: {
                click: function ($event) {
                  _vm.isShowProfileDetail = !_vm.isShowProfileDetail
                },
              },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-right font-roboto font-weight-400 cj-color-white-primary font-underline",
                  class: _vm.isMobile ? "font-size-12" : "font-size-16",
                  attrs: { "data-cy": "detail-paw-show-more-or-less" },
                },
                [
                  !_vm.isShowProfileDetail
                    ? [_vm._v(_vm._s(_vm.$t("cbGlobal.showMore")) + " ")]
                    : [_vm._v(_vm._s(_vm.$t("cbGlobal.showLess")))],
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "p-3",
          attrs: { visible: _vm.isShowProfileDetail ? true : false },
        },
        [
          _c(
            "div",
            { staticClass: "paw-detail-profile-description d-flex flex-wrap" },
            [
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 col-md-6 px-2 box-order" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "paw-detail-profile-description-item mb-2 d-flex flex-column h-100",
                    },
                    [
                      _c("p", {
                        staticClass:
                          "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                        class: _vm.isMobile ? "font-size-12" : "font-size-16",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbPaw.detail.location")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-2 font-roboto font-weight-500 cj-color-white-primary",
                          class: _vm.isMobile ? "font-size-14" : "font-size-20",
                          attrs: { "data-cy": "detail-paw-location" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getLanguangeLocation(
                                  _vm.cbVwUser.locationCity
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 col-md-6 px-2 box-order" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "paw-detail-profile-description-item mb-2 d-flex flex-column h-100",
                    },
                    [
                      _c("p", {
                        staticClass:
                          "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                        class: _vm.isMobile ? "font-size-12" : "font-size-16",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbPaw.detail.memberSince")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-2 font-roboto font-weight-500 cj-color-white-primary",
                          class: _vm.isMobile ? "font-size-14" : "font-size-20",
                          attrs: { "data-cy": "detail-paw-member-since" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatDateTimeDefault")(
                                  _vm.cbVwUser.memberSinceDate
                                    ? _vm.cbVwUser.memberSinceDate
                                    : "-"
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 col-md-6 px-2 box-order" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "paw-detail-profile-description-item mb-2 d-flex flex-column h-100",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                          class: _vm.isMobile ? "font-size-12" : "font-size-16",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("cbGlobal.earnedMedals")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.cbVwMedals &&
                      _vm.cbVwMedals.every(
                        (medal) =>
                          medal.progress === 0 || medal.progress === null
                      )
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "mb-2 font-roboto font-weight-500 cj-color-white-primary",
                              class: _vm.isMobile
                                ? "font-size-14"
                                : "font-size-20",
                            },
                            [_vm._v("\n            -\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cbVwMedals &&
                      _vm.cbVwMedals.some((medal) => medal.progress > 0)
                        ? _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            [
                              _vm._l(
                                _vm.cbVwMedals,
                                function (cbVwMedal, index) {
                                  return [
                                    cbVwMedal.progress !== 0 &&
                                    cbVwMedal.progress !== null
                                      ? _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "d-flex mr-1 my-auto",
                                          },
                                          [
                                            _c("c-medal-progress", {
                                              staticStyle: {
                                                width: "2.063rem",
                                              },
                                              attrs: {
                                                logoInActiveUrl:
                                                  _vm.$options.filters.LAZY(
                                                    `${
                                                      _vm.$env.CDN_CB
                                                    }/content/medal/${
                                                      cbVwMedal &&
                                                      cbVwMedal.contentLogoInActive
                                                    }`
                                                  ),
                                                logoActiveUrl:
                                                  _vm.$options.filters.LAZY(
                                                    `${
                                                      _vm.$env.CDN_CB
                                                    }/content/medal/${
                                                      cbVwMedal &&
                                                      cbVwMedal.contentLogoActive
                                                    }`
                                                  ),
                                                percent: cbVwMedal.progress,
                                                requirement: `${
                                                  cbVwMedal.requirement
                                                } (${
                                                  cbVwMedal.progress
                                                    ? cbVwMedal.progress
                                                    : 0
                                                }%)`,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 col-md-6 px-2 box-order" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "paw-detail-profile-description-item mb-2 d-flex flex-column h-100",
                    },
                    [
                      _vm.cbVwUser.defaultSvcType === _vm.CbSvcType.INSIDE
                        ? _c("p", {
                            staticClass:
                              "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                            class: _vm.isMobile
                              ? "font-size-12"
                              : "font-size-16",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbPaw.detail.lastProjectDelivery"
                                )
                              ),
                            },
                          })
                        : _vm.cbVwUser.defaultSvcType === _vm.CbSvcType.FIELD
                        ? _c("p", {
                            staticClass:
                              "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                            class: _vm.isMobile
                              ? "font-size-12"
                              : "font-size-16",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("cbgwApp.cbPaw.detail.lastClockOut")
                              ),
                            },
                          })
                        : _c(
                            "p",
                            {
                              staticClass:
                                "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                              class: _vm.isMobile
                                ? "font-size-12"
                                : "font-size-16",
                            },
                            [_vm._v("-")]
                          ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-2 font-roboto font-weight-500 cj-color-white-primary",
                          class: _vm.isMobile ? "font-size-14" : "font-size-20",
                          attrs: { "data-cy": "detail-paw-project-delivery" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatDateFromNow")(
                                  _vm.cbVwUserSummary.lastProjectDeliveryDate
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 col-md-6 px-2 box-order" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "paw-detail-profile-description-item mb-2 d-flex flex-column h-100",
                    },
                    [
                      _c("p", {
                        staticClass:
                          "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                        class: _vm.isMobile ? "font-size-12" : "font-size-16",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbPaw.detail.avgReplyTime")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-2 font-roboto font-weight-500 cj-color-white-primary",
                          class: _vm.isMobile ? "font-size-14" : "font-size-20",
                          attrs: { "data-cy": "detail-paw-avg-resp-time" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.currentLanguage == "id"
                                  ? _vm.cbVwAvgResponseTime.avgResponseTimeLabel
                                    ? _vm.cbVwAvgResponseTime
                                        .avgResponseTimeLabel
                                    : "-"
                                  : _vm.cbVwAvgResponseTime
                                      .avgResponseTimeLabel2
                                  ? _vm.cbVwAvgResponseTime
                                      .avgResponseTimeLabel2
                                  : "-"
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-lg-4 col-md-6 px-2 box-order" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "paw-detail-profile-description-item mb-2 d-flex flex-column h-100",
                    },
                    [
                      _c("p", {
                        staticClass:
                          "pdpd-title-wrapper mb-1 font-roboto font-weight-400",
                        class: _vm.isMobile ? "font-size-12" : "font-size-16",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbPaw.detail.operationalHour")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-1 font-roboto font-weight-500 cj-color-white-primary",
                          class: _vm.isMobile ? "font-size-14" : "font-size-20",
                          attrs: { "data-cy": "detail-paw-utc" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("format24TimeToUtc")(
                                  _vm.cbVwUser.operationHourStart
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm._f("format24TimeToUtc")(
                                  _vm.cbVwUser.operationHourEnd
                                )
                              ) +
                              " UTC\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-2 font-roboto font-weight-400 cj-color-white-primary",
                          class: _vm.isMobile ? "font-size-10" : "font-size-14",
                          attrs: { "data-cy": "detail-paw-local" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatTimeDefault")(
                                  _vm.cbVwUser.operationHourStart
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm._f("formatTimeDefault")(
                                  _vm.cbVwUser.operationHourEnd
                                )
                              ) +
                              "\n            " +
                              _vm._s(_vm.$t("cbGlobal.localTime")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }