import Icon from '@/shared/icons/icon.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: { Icon },
})
export default class CPdTitle extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({ default: null })
  public cbVwPaw;
  @Prop({ default: null })
  public getCbVwSvcsColor;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
