import { DetailType } from '@/shared/model/enumerations/detail-type.model';
import { ICbAttachmentNoAuth } from './cb-attachment-no-auth.model';
export interface ICbVwPawDetailAtt {
  id?: number;
  detailType?: DetailType | null;
  detailId?: number | null;
  title?: string | null;
  cbPawId?: number | null;
  cbAttachmentNoAuthId?: number | null;
  dtl?: string | null;
  isPortfolioCover?: boolean | null;
  isAttCover?: boolean | null;
  cbAttachmentNoAuth?: ICbAttachmentNoAuth | null;
}

export class CbVwPawDetailAtt implements ICbVwPawDetailAtt {
  constructor(
    public id?: number,
    public detailType?: DetailType | null,
    public detailId?: number | null,
    public title?: string | null,
    public cbPawId?: number | null,
    public cbAttachmentNoAuthId?: number | null,
    public dtl?: string | null,
    public isPortfolioCover?: boolean | null,
    public isAttCover?: boolean | null,
    public cbAttachmentNoAuth?: ICbAttachmentNoAuth | null
  ) {}
}
