var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-ba-bank-card c-bank-account-container d-flex flex-nowrap align-items-center border-radius-card p-2 p-sm-3",
      class: {
        update:
          !_vm.cbBankAccount ||
          (!_vm.cbBankAccount.id && _vm.newCbBankAccount.id != null),
        "wrap-border-grey-tertiary": _vm.cbBankAccount && _vm.cbBankAccount.id,
      },
      attrs: { "data-cy": "bank-card-container" },
    },
    [
      (!_vm.cbBankAccount || !_vm.cbBankAccount.id) &&
      !_vm.newCbBankAccount.id &&
      !_vm.isFetchingCbBankAccount
        ? [
            _c("div", { staticClass: "col-12 px-0" }, [
              _c(
                "div",
                {
                  staticClass:
                    "cj-btn-border-primary rounded-pill p-2 cursor-pointer d-flex align-items-center justify-content-center mb-2",
                  attrs: {
                    "data-gtm": "add-bank-account-button",
                    "data-cy": "add-bank-account-button",
                  },
                  on: { click: _vm.openForm },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex mr-1" },
                    [
                      _c("icon", {
                        staticClass: "my-auto cj-fill-black-primary",
                        attrs: {
                          name: "ic_plus_circle_outlined",
                          width: "22px",
                          height: "22px",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "font-roboto cj-color-black-primary font-size-20-auto font-weight-400",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbSetting.bankAccount.addAccountBank")
                      ),
                    },
                  }),
                ]
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.cbBankAccount && _vm.cbBankAccount.id && !_vm.isFetchingCbBankAccount
        ? [
            _c("div", { staticClass: "my-auto mr-2" }, [
              _c(
                "div",
                {
                  staticClass: "wrapper-img",
                  attrs: { "data-cy": "bank-card-image" },
                },
                [
                  _c("img", {
                    staticClass: "img-size",
                    attrs: {
                      src: `@/../content/images/bank/${_vm.cbBankAccount.bankCode.toLowerCase()}.svg`,
                      alt: "card",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex-grow-0 mr-2 my-auto",
                staticStyle: { overflow: "hidden" },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto cj-color-black-primary font-size-18-auto font-weight-500 text-ellipsis-oneline",
                    attrs: { "data-cy": "bank-card-name" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.cbBankAccount.accountHolderName) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto cj-color-grey-secondary font-size-18-auto-sub font-weight-400 text-ellipsis-oneline",
                    attrs: { "data-cy": "bank-card-number" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.cbBankAccount.accountNumber) +
                        "\n        "
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "font-roboto cj-color-grey-secondary font-size-18-auto-sub font-weight-400",
                      },
                      [_vm._v(" - " + _vm._s(_vm.getBankNameByKey))]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            !_vm.newCbBankAccount.id
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex px-0 justify-content-end flex-grow-1",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex cursor-pointer my-auto",
                        attrs: {
                          "data-gtm": "add-bank-account-button",
                          "data-cy": "edit-bank-account-button",
                        },
                        on: { click: _vm.openForm },
                      },
                      [
                        _c("icon", {
                          staticClass: "cj-fill-red-primary pencil-size",
                          attrs: {
                            name: "ic_pencil",
                            width: "18px",
                            height: "18px",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }