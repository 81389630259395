import { render, staticRenderFns } from "./AudioPlayer.vue?vue&type=template&id=2b402d98&"
import script from "./AudioPlayer.vue?vue&type=script&lang=js&"
export * from "./AudioPlayer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports