var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-user-photo-level",
      style: { width: _vm.width, height: _vm.width },
    },
    [
      _c(
        "div",
        {
          staticClass: "rounded-circle",
          staticStyle: { overflow: "hidden" },
          style: `width: ${_vm.width}; height: ${_vm.width};`,
        },
        [
          _c("img", {
            staticClass: "h-100 w-100",
            attrs: {
              "data-cy": "profile-photo-image",
              src: _vm.userPhoto,
              onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-avatar.png"),
              alt: "user photo",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.userLevel && !_vm.editMode
        ? _c(
            "div",
            { staticClass: "user-level", style: `width: ${_vm.width}; ` },
            [
              _c("img", {
                attrs: {
                  width: "100%",
                  "data-cy": "image-level-m",
                  src: `${
                    _vm.$env.CDN_CB
                  }/content/cb-img/ic-rank-${_vm.userLevel.toLowerCase()}.png`,
                  alt: `user-level-${_vm.userLevel.toLowerCase()}`,
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }