import axios from 'axios';

import { INtUserNotif } from '@/shared/model/nt-user-notif.model';

const baseApiUrl = 'services/btinotif/api/nt-user-notifs';

export default class PNtUserNotifService {
  public updateRead(entity: INtUserNotif): Promise<INtUserNotif> {
    return new Promise<INtUserNotif>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/read/CB_APP/${entity.id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateReadAll(): Promise<INtUserNotif> {
    return new Promise<INtUserNotif>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/CB_APP/read-all`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<INtUserNotif> {
    return new Promise<INtUserNotif>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: INtUserNotif): Promise<INtUserNotif> {
    return new Promise<INtUserNotif>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: INtUserNotif): Promise<INtUserNotif> {
    return new Promise<INtUserNotif>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: INtUserNotif): Promise<INtUserNotif> {
    return new Promise<INtUserNotif>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
