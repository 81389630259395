import Icon from '@/shared/icons/icon.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { Icon } })
export default class CImgBannerMain extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // VIDEO
  public selectedPlayVideoId: number = null;
  public isPlaying: boolean = false;
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public vueperConfig = {
    autoplay: true,
    fixedHeight: false,
    gap: 1,
    infinite: true,
    slideMultiple: true,
    slideRatio: 1 / 1.2,
    touchable: true,
    transitionSpeed: 600,
    visibleSlides: 1,
    arrows: false,
    bullets: false,
    arrowsOutside: false,
    draggingDistance: 70,

    breakpoints: {
      992: {
        arrows: false,
        slideRatio: 1 / 1.5,
      },
      700: {
        slideRatio: 1 / 2,
        arrows: false,
      },
      600: {
        slideRatio: 1 / 1.5,
        arrows: false,
        bullets: false,
      },
      300: {
        slideRatio: 1 / 1.5,
        arrows: false,
        bullets: false,
      },
    },
  };

  public handlePlayVideo(id) {
    this.selectedPlayVideoId = id;
    setTimeout(() => {
      if (!this.isPlaying) (<any>this.$refs.myVideo).play();
      else (<any>this.$refs.myVideo).pause();
      this.isPlaying = !this.isPlaying;
    }, 10);
  }

  public setPausePlay() {
    if (!this.isPlaying) (<any>this.$refs.myVideo).play();
    else (<any>this.$refs.myVideo).pause();

    this.isPlaying = !this.isPlaying;
  }

  public get CONTENT_BANNER_BUYER() {
    return this.$store.getters.CONTENT_BANNER_BUYER;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
