import JhiDataUtils from '@/shared/data/data-utils.service';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import FormUtils from '@/shared/utils/form-utils.service';

@Component({
  watch: {
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.maxlength) {
          const max = this.maxlength;
          const total = newVal?.length ? newVal.length : 0;

          if (total > max) {
            this.textRef().value = oldVal;
            this.handleInput();
          }

          this.validatePasteMaxLengthUtils(max, total);
        }
      },
    },
  },
})
export default class CInputNumber extends Mixins(JhiDataUtils, FormUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public value: any;
  @Prop({ default: false }) public disabled: boolean;
  @Prop({ default: null }) public maxlength: number;
  @Prop({ default: false }) public hideCounter: boolean;
  @Prop({ default: null, type: Number }) public min: number;
  @Prop({ default: null, type: Number }) public max: number;
  @Prop({ default: null }) public dataCyCustom: string;
  @Prop({ default: null }) public dataGTMCustom: string;

  // ================= END VARIABLES ==========fi========
  // ================= START DEFAULT FUNCTION =========
  public created() {
    if (this.value) {
      const stringValue: string = this.value?.toString()?.replace(/[^\d]+/g, '');
      let numberValue = Number(stringValue);
      if (this.max && this.max > 0 && numberValue > this.max) {
        numberValue = this.max;
      }
      this.$emit('input', numberValue);
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleInput() {
    if (this.textRef()?.value || this.textRef()?.value === '') {
      this.textRef().value = this.textRef()
        ?.value?.toString()
        .replace(/\s/g, '')
        .replace(/[^\d]+/g, '');
      if (this.max > 0 && this.textRef()?.value > this.max) {
        this.textRef().value = this.max;
        const message = {
          toString: () => {
            return this.$t('cbgwApp.cbComponent.validation.maxSize');
          },
        };
        (<any>this.$root).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Warning',
          variant: 'warning',
          solid: true,
          autoHideDelay: 5000,
        });
      }
      this.$emit('input', this.textRef()?.value);
    }
  }

  public handlePaste(e: any) {
    const message = {
      toString: () => {
        return this.$t('cbgwApp.cbComponent.validation.cannotPaste');
      },
    };
    (<any>this.$root).$bvToast.toast(message.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Warning',
      variant: 'warning',
      solid: true,
      autoHideDelay: 5000,
    });
    e.preventDefault();
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get valueLength() {
    let length = 0;
    if (this.value?.length > 0 && this.maxlength) {
      length = this.value.length;
    }
    return length;
  }

  public textRef() {
    return <any>this.$refs.textRef;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
