export interface ICbUserTimezone {
  id?: number;
  userId?: string | null;
  timeZoneName?: string | null;
  timeZoneCode?: string | null;
  userZoneDateTime?: Date | null;
  userLocalDate?: Date | null;
  isActive?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  dateFormat?: string | null; //transient
  timeFormat?: string | null; //transient
}

export class CbUserTimezone implements ICbUserTimezone {
  constructor(
    public id?: number,
    public userId?: string | null,
    public timeZoneName?: string | null,
    public timeZoneCode?: string | null,
    public userZoneDateTime?: Date | null,
    public userLocalDate?: Date | null,
    public isActive?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public dateFormat?: string | null,
    public timeFormat?: string | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
