import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = 'services/cbuserms/api/cb-vw-promo-user-aggs';

export default class PCbVwPromoUserAggService {
  public retrieveByLogin(paginationQuery?: any, params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/by-login?${buildPaginationQueryOpts(paginationQuery)}`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLoginBasedOnOrderIdReqPayType(orderReqId, paymentType?: string, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiUrl +
            `/validate/order-id-req/${orderReqId}/${paymentType ? paymentType : 'ALL'}?${buildPaginationQueryOpts(paginationQuery)}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
