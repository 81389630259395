var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.name === "cic_camera"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 40 40",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M33.75 9.6875H28.4375L27.1719 6.14063C27.0844 5.89792 26.9242 5.68813 26.7131 5.53991C26.5019 5.39169 26.2502 5.31227 25.9922 5.3125H14.0078C13.4805 5.3125 13.0078 5.64453 12.832 6.14063L11.5625 9.6875H6.25C4.52344 9.6875 3.125 11.0859 3.125 12.8125V30.625C3.125 32.3516 4.52344 33.75 6.25 33.75H33.75C35.4766 33.75 36.875 32.3516 36.875 30.625V12.8125C36.875 11.0859 35.4766 9.6875 33.75 9.6875ZM34.0625 30.625C34.0625 30.7969 33.9219 30.9375 33.75 30.9375H6.25C6.07812 30.9375 5.9375 30.7969 5.9375 30.625V12.8125C5.9375 12.6406 6.07812 12.5 6.25 12.5H13.543L14.2109 10.6328L15.1055 8.125H24.8906L25.7852 10.6328L26.4531 12.5H33.75C33.9219 12.5 34.0625 12.6406 34.0625 12.8125V30.625ZM20 15C16.5469 15 13.75 17.7969 13.75 21.25C13.75 24.7031 16.5469 27.5 20 27.5C23.4531 27.5 26.25 24.7031 26.25 21.25C26.25 17.7969 23.4531 15 20 15ZM20 25C17.9297 25 16.25 23.3203 16.25 21.25C16.25 19.1797 17.9297 17.5 20 17.5C22.0703 17.5 23.75 19.1797 23.75 21.25C23.75 23.3203 22.0703 25 20 25Z",
              fill: "#FFFFFF",
            },
          }),
        ]
      )
    : _vm.name === "cic_record"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 40 40",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("g", { attrs: { "clip-path": "url(#clip0_16143_157633)" } }, [
            _c("path", {
              attrs: {
                d: "M37.7743 11.4863C37.7776 11.0492 37.6587 10.62 37.4309 10.2469C37.2032 9.87389 36.8757 9.57194 36.4854 9.37517C36.1155 9.17652 35.6968 9.08674 35.278 9.11624C34.8591 9.14574 34.4572 9.2933 34.1187 9.54184L28.8854 12.8863V8.88628C28.8854 8.29691 28.6513 7.73168 28.2345 7.31494C27.8178 6.89819 27.2526 6.66406 26.6632 6.66406H6.66319C5.48445 6.66406 4.35399 7.13231 3.5205 7.96581C2.687 8.7993 2.21875 9.92977 2.21875 11.1085V28.8863C2.21875 30.065 2.687 31.1955 3.5205 32.029C4.35399 32.8625 5.48445 33.3307 6.66319 33.3307H26.6632C27.2526 33.3307 27.8178 33.0966 28.2345 32.6799C28.6513 32.2631 28.8854 31.6979 28.8854 31.1085V27.1085L34.041 30.4418C34.3859 30.7059 34.7985 30.867 35.2311 30.9063C35.6637 30.9456 36.0986 30.8616 36.4854 30.6641C36.8826 30.464 37.2146 30.1551 37.4428 29.7734C37.6711 29.3918 37.786 28.953 37.7743 28.5085V11.4863ZM35.4743 28.6307C35.4076 28.6307 35.3521 28.6307 35.2632 28.5641L26.6632 23.0752V31.1085H6.66319C6.07382 31.1085 5.50859 30.8744 5.09185 30.4576C4.6751 30.0409 4.44097 29.4757 4.44097 28.8863V11.1085C4.44097 10.5191 4.6751 9.95391 5.09185 9.53716C5.50859 9.12041 6.07382 8.88628 6.66319 8.88628H26.6632V16.9196L35.3299 11.3641C35.3508 11.3487 35.3761 11.3404 35.4021 11.3404C35.4281 11.3404 35.4534 11.3487 35.4743 11.3641C35.4986 11.3735 35.5193 11.3904 35.5333 11.4124C35.5472 11.4344 35.5538 11.4603 35.5521 11.4863V28.5085C35.5538 28.5345 35.5472 28.5604 35.5333 28.5824C35.5193 28.6044 35.4986 28.6213 35.4743 28.6307Z",
                fill: "#DCDCDC",
              },
            }),
          ]),
          _vm._v(" "),
          _c("defs", [
            _c("clipPath", { attrs: { id: "clip0_16143_157633" } }, [
              _c("rect", {
                attrs: { width: "40", height: "40", fill: "white" },
              }),
            ]),
          ]),
        ]
      )
    : _vm.name === "cic_no_read"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 18 18",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M4.40234 6.14234C4.40234 5.51144 4.9217 5 5.56236 5H16.0025C16.6431 5 17.1625 5.51144 17.1625 6.14234V11.854C17.1625 12.4849 16.6431 12.9964 16.0025 12.9964H5.56236C4.9217 12.9964 4.40234 12.4849 4.40234 11.854V6.14234Z",
              fill: "#B4D8CA",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M16.0025 5.57117H5.56236C5.24203 5.57117 4.98235 5.82689 4.98235 6.14234V11.854C4.98235 12.1695 5.24203 12.4252 5.56236 12.4252H16.0025C16.3228 12.4252 16.5825 12.1695 16.5825 11.854V6.14234C16.5825 5.82689 16.3228 5.57117 16.0025 5.57117ZM5.56236 5C4.9217 5 4.40234 5.51144 4.40234 6.14234V11.854C4.40234 12.4849 4.9217 12.9964 5.56236 12.9964H16.0025C16.6431 12.9964 17.1625 12.4849 17.1625 11.854V6.14234C17.1625 5.51144 16.6431 5 16.0025 5H5.56236Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M15.8701 13H5.69738C5.39328 13 5.11723 12.8771 4.91406 12.6771L10.7837 7.21541L16.6534 12.6771C16.4502 12.8771 16.1742 13 15.8701 13Z",
              fill: "#FFF7EA",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M4.91406 12.6771C5.058 12.8188 5.23853 12.9218 5.43969 12.9698C5.52246 12.9896 5.60873 13 5.69738 13H15.8701C15.9587 13 16.045 12.9896 16.1278 12.9698C16.3289 12.9218 16.5094 12.8188 16.6534 12.6771L10.7837 7.21541L4.91406 12.6771ZM10.7837 7.99717L15.5385 12.4215H6.0289L10.7837 7.99717Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M15.8701 5H5.69738C5.39328 5 5.11723 5.11931 4.91406 5.31337L10.7837 10.6145L16.6534 5.31337C16.4502 5.11931 16.1742 5 15.8701 5Z",
              fill: "#68D2C8",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M4.91406 5.31337C5.058 5.17588 5.23853 5.07591 5.43969 5.02931C5.52246 5.01014 5.60873 5 5.69738 5H15.8701C15.9587 5 16.045 5.01014 16.1278 5.02931C16.3289 5.07591 16.5094 5.17588 16.6534 5.31337L10.7837 10.6145L4.91406 5.31337ZM10.7837 9.85569L15.5385 5.56145H6.0289L10.7837 9.85569Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M1 10.1059C1 9.91797 1.12829 9.76562 1.28654 9.76562H6.15778C6.31603 9.76562 6.44432 9.91797 6.44432 10.1059C6.44432 10.2938 6.31603 10.4462 6.15778 10.4462H1.28654C1.12829 10.4462 1 10.2938 1 10.1059Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M1.85156 7.80989C1.85156 7.66895 1.9801 7.55469 2.13867 7.55469H6.15811C6.31667 7.55469 6.44521 7.66895 6.44521 7.80989C6.44521 7.95083 6.31667 8.06509 6.15811 8.06509H2.13867C1.9801 8.06509 1.85156 7.95083 1.85156 7.80989Z",
              fill: "#0F3E38",
            },
          }),
        ]
      )
    : _vm.name === "cic_read"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 18 18",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M14.8244 7.79688H4.1682C3.84965 7.79688 3.56048 7.67368 3.34766 7.4733L9.4963 1.99958L15.645 7.4733C15.4321 7.67368 15.143 7.79688 14.8244 7.79688Z",
              fill: "#68D2C8",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M3.34766 7.4733C3.49844 7.61527 3.68754 7.71849 3.89827 7.76661C3.98497 7.78641 4.07534 7.79688 4.1682 7.79688H14.8244C14.9173 7.79688 15.0076 7.78641 15.0943 7.76661C15.3051 7.71849 15.4942 7.61527 15.645 7.4733L9.4963 1.99958L3.34766 7.4733ZM9.4963 2.78306L14.4771 7.21715H4.51548L9.4963 2.78306Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4.05469 4.94131C4.05469 4.28768 4.58778 3.75781 5.24538 3.75781H13.5802C14.2378 3.75781 14.7709 4.28768 14.7709 4.94131V13.8176C14.7709 14.4712 14.2378 15.0011 13.5802 15.0011H5.24538C4.58778 15.0011 4.05469 14.4712 4.05469 13.8176V4.94131Z",
              fill: "#FFF7EA",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M5.63281 6.65414C5.63281 6.5086 5.76592 6.39062 5.93011 6.39062H13.0652C13.2294 6.39062 13.3625 6.5086 13.3625 6.65414C13.3625 6.79967 13.2294 6.91765 13.0652 6.91765H5.93011C5.76592 6.91765 5.63281 6.79967 5.63281 6.65414Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M5.63281 8.14823C5.63281 7.95418 5.7639 7.79688 5.92561 7.79688H9.73191C9.89362 7.79688 10.0247 7.95418 10.0247 8.14823C10.0247 8.34227 9.89362 8.49958 9.73191 8.49958H5.92561C5.7639 8.49958 5.63281 8.34227 5.63281 8.14823Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M3.36274 7.6012C3.13914 7.81585 3 8.11753 3 8.45166V14.3504C3 15.002 3.52912 15.5302 4.18182 15.5302H14.8182C15.4709 15.5302 16 15.002 16 14.3504V8.45166C16 7.82046 15.5034 7.30504 14.879 7.27344C15.1728 7.28833 15.4383 7.41035 15.6371 7.60114L9.49988 13.1707L3.36274 7.6012Z",
              fill: "#B4D8CA",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M15.1979 7.99973L9.49988 13.1707L3.36274 7.6012C3.3244 7.63801 3.28854 7.67737 3.25544 7.71902C3.16334 7.83492 3.0926 7.96849 3.04917 8.11383C3.01718 8.22085 3 8.33425 3 8.45166V14.3504C3 15.002 3.52912 15.5302 4.18182 15.5302H14.8182C15.4709 15.5302 16 15.002 16 14.3504V8.45166C16 8.33419 15.9828 8.22073 15.9508 8.11366C15.9075 7.96884 15.8371 7.83569 15.7454 7.72009C15.712 7.678 15.6759 7.63824 15.6371 7.60109C15.6369 7.60081 15.6366 7.60053 15.6363 7.60026C15.4859 7.45624 15.2975 7.35153 15.0875 7.30268C15.2979 7.35164 15.4866 7.45663 15.6371 7.60109L15.1979 7.99973ZM15.4091 8.60525L9.49988 13.9679L3.59091 8.60546V14.3504C3.59091 14.6762 3.85547 14.9403 4.18182 14.9403H14.8182C15.1445 14.9403 15.4091 14.6762 15.4091 14.3504V8.60525Z",
              fill: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M3.34766 15.2077C3.49844 15.3496 3.68754 15.4529 3.89827 15.501C3.98497 15.5208 4.07534 15.5312 4.1682 15.5312H14.8244C14.9173 15.5312 15.0076 15.5208 15.0943 15.501C15.3051 15.4529 15.4942 15.3496 15.645 15.2077L9.4963 9.73395L3.34766 15.2077ZM9.4963 10.5174L14.4771 14.9515H4.51548L9.4963 10.5174Z",
              fill: "#0F3E38",
            },
          }),
        ]
      )
    : _vm.name === "cic_pin"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 18 18",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M10.4538 3C10.5113 2.9999 10.5683 3.01113 10.6214 3.03305C10.6746 3.05497 10.7229 3.08715 10.7636 3.12775L15.0948 7.459C15.1769 7.54104 15.2229 7.6523 15.2229 7.76831C15.2229 7.88432 15.1769 7.99558 15.0948 8.07763C14.6748 8.49763 14.1568 8.59213 13.7797 8.59213C13.6248 8.59213 13.4866 8.57638 13.3772 8.558L10.635 11.3003C10.7072 11.5911 10.754 11.8877 10.775 12.1866C10.8152 12.8009 10.747 13.6628 10.145 14.2648C10.0629 14.3468 9.95166 14.3928 9.83566 14.3928C9.71965 14.3928 9.60839 14.3468 9.52634 14.2648L7.05097 11.7903L4.26672 14.5745C4.09609 14.7451 3.20009 15.3638 3.02947 15.1931C2.85884 15.0225 3.47747 14.1256 3.64809 13.9559L6.43234 11.1716L3.95784 8.69625C3.87582 8.61421 3.82975 8.50295 3.82975 8.38694C3.82975 8.27093 3.87582 8.15967 3.95784 8.07763C4.55984 7.47563 5.42172 7.4065 6.03597 7.44763C6.33492 7.46857 6.63149 7.51542 6.92234 7.58763L9.66459 4.84625C9.6417 4.713 9.63 4.57807 9.62959 4.44288C9.62959 4.06663 9.72409 3.54863 10.145 3.12775C10.227 3.04597 10.338 3.00003 10.4538 3Z",
              fill: "#DCDCDC",
            },
          }),
        ]
      )
    : _vm.name === "cic_trasnlate"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 19 19",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("g", { attrs: { "clip-path": "url(#clip0_11498_134569)" } }, [
            _c("path", {
              attrs: {
                d: "M5.39719 7.97288L4.88063 9.5H3.5625L5.77362 3.5625H7.29837L9.5 9.5H8.11419L7.59762 7.97288H5.39719ZM7.33756 7.09888L6.53125 4.69775H6.47306L5.66675 7.09888H7.33875H7.33756Z",
                fill: "#008891",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d: "M0 2.375C0 1.74511 0.250223 1.14102 0.695621 0.695621C1.14102 0.250223 1.74511 0 2.375 0L10.6875 0C11.3174 0 11.9215 0.250223 12.3669 0.695621C12.8123 1.14102 13.0625 1.74511 13.0625 2.375V5.9375H16.625C17.2549 5.9375 17.859 6.18772 18.3044 6.63312C18.7498 7.07852 19 7.68261 19 8.3125V16.625C19 17.2549 18.7498 17.859 18.3044 18.3044C17.859 18.7498 17.2549 19 16.625 19H8.3125C7.68261 19 7.07852 18.7498 6.63312 18.3044C6.18772 17.859 5.9375 17.2549 5.9375 16.625V13.0625H2.375C1.74511 13.0625 1.14102 12.8123 0.695621 12.3669C0.250223 11.9215 0 11.3174 0 10.6875V2.375ZM2.375 1.1875C2.06006 1.1875 1.75801 1.31261 1.53531 1.53531C1.31261 1.75801 1.1875 2.06006 1.1875 2.375V10.6875C1.1875 11.0024 1.31261 11.3045 1.53531 11.5272C1.75801 11.7499 2.06006 11.875 2.375 11.875H10.6875C11.0024 11.875 11.3045 11.7499 11.5272 11.5272C11.7499 11.3045 11.875 11.0024 11.875 10.6875V2.375C11.875 2.06006 11.7499 1.75801 11.5272 1.53531C11.3045 1.31261 11.0024 1.1875 10.6875 1.1875H2.375ZM10.8514 13.0566C11.0806 13.414 11.3287 13.7489 11.5995 14.0612C10.7112 14.744 9.61281 15.2499 8.3125 15.5954C8.52388 15.8531 8.84806 16.3495 8.97156 16.625C10.3075 16.1987 11.4416 15.6227 12.3987 14.8509C13.3214 15.6406 14.4638 16.2343 15.8781 16.5989C16.036 16.2972 16.3697 15.7997 16.625 15.542C15.2891 15.2416 14.1823 14.7179 13.2763 14.0173C14.0849 13.1302 14.7274 12.0567 15.2012 10.7433H16.625V9.5H13.0625V10.7433H13.9709C13.5933 11.7456 13.0922 12.5792 12.4604 13.2727C12.2859 13.0868 12.1214 12.8918 11.9676 12.6884C11.6325 12.9033 11.2485 13.0299 10.8514 13.0566Z",
                fill: "#008891",
              },
            }),
          ]),
          _vm._v(" "),
          _c("defs", [
            _c("clipPath", { attrs: { id: "clip0_11498_134569" } }, [
              _c("rect", {
                attrs: { width: "19", height: "19", fill: "white" },
              }),
            ]),
          ]),
        ]
      )
    : _vm.name === "cic_play_circle_outlined"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 27 27",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M20.9577 13.5L8.02817 5.84328L8.02817 21.1567L20.9577 13.5ZM23.493 14.4008C24.169 14.0004 24.169 12.9996 23.493 12.5992L7.52113 3.1409C6.84507 2.74055 6 3.24099 6 4.0417V22.9583C6 23.759 6.84507 24.2594 7.52113 23.8591L23.493 14.4008Z",
            },
          }),
        ]
      )
    : _vm.name === "cic_read_2"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 30 30",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c(
            "mask",
            { attrs: { id: "path-1-inside-1_33151_168709", fill: "white" } },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M24.0003 13H6.00029C5.4622 13 4.97374 12.7875 4.61426 12.4418L15.0003 3L25.3863 12.4418C25.0268 12.7875 24.5384 13 24.0003 13Z",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M24.0003 13H6.00029C5.4622 13 4.97374 12.7875 4.61426 12.4418L15.0003 3L25.3863 12.4418C25.0268 12.7875 24.5384 13 24.0003 13Z",
              fill: "#68D2C8",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4.61426 12.4418L3.92115 13.1627L3.15018 12.4214L3.94159 11.7019L4.61426 12.4418ZM15.0003 3L14.3276 2.26006L15.0003 1.64854L15.673 2.26006L15.0003 3ZM25.3863 12.4418L26.059 11.7019L26.8504 12.4214L26.0794 13.1627L25.3863 12.4418ZM24.0003 14H6.00029V12H24.0003V14ZM5.30736 11.721C5.48806 11.8948 5.73084 12 6.00029 12V14C5.19356 14 4.45943 13.6803 3.92115 13.1627L5.30736 11.721ZM3.94159 11.7019L14.3276 2.26006L15.673 3.73994L5.28693 13.1818L3.94159 11.7019ZM15.673 2.26006L26.059 11.7019L24.7136 13.1818L14.3276 3.73994L15.673 2.26006ZM24.0003 12C24.2697 12 24.5125 11.8948 24.6932 11.721L26.0794 13.1627C25.5411 13.6803 24.807 14 24.0003 14V12Z",
              fill: "#0F3E38",
              mask: "url(#path-1-inside-1_33151_168709)",
            },
          }),
          _vm._v(" "),
          _c("rect", {
            attrs: {
              x: "4.5",
              y: "12.5",
              width: "21",
              height: "13",
              rx: "1.5",
              fill: "#B4D8CA",
              stroke: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("rect", {
            attrs: {
              x: "6.5",
              y: "6.5",
              width: "17",
              height: "18",
              rx: "1.5",
              fill: "#FFF7EA",
              stroke: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M9 11H21",
              stroke: "#0F3E38",
              "stroke-linecap": "round",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M9 13.5H15.5",
              stroke: "#0F3E38",
              "stroke-linecap": "round",
            },
          }),
          _vm._v(" "),
          _c(
            "mask",
            { attrs: { id: "path-7-inside-2_33151_168709", fill: "white" } },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M4.61397 12.5571C4.23551 12.921 4 13.4325 4 13.999V23.999C4 25.1035 4.89543 25.999 6 25.999H24C25.1046 25.999 26 25.1035 26 23.999V13.999C26 13.4325 25.7645 12.921 25.386 12.5571L15 21.999L4.61397 12.5571Z",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M4.61397 12.5571C4.23551 12.921 4 13.4325 4 13.999V23.999C4 25.1035 4.89543 25.999 6 25.999H24C25.1046 25.999 26 25.1035 26 23.999V13.999C26 13.4325 25.7645 12.921 25.386 12.5571L15 21.999L4.61397 12.5571Z",
              fill: "#B4D8CA",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4.61397 12.5571L5.28664 11.8172L4.59482 11.1883L3.92086 11.8363L4.61397 12.5571ZM25.386 12.5571L26.0791 11.8363L25.4052 11.1883L24.7134 11.8172L25.386 12.5571ZM15 21.999L14.3273 22.7389L15 23.3504L15.6727 22.7389L15 21.999ZM5 13.999C5 13.7156 5.11657 13.4611 5.30707 13.278L3.92086 11.8363C3.35446 12.3809 3 13.1494 3 13.999H5ZM5 23.999V13.999H3V23.999H5ZM6 24.999C5.44772 24.999 5 24.5513 5 23.999H3C3 25.6558 4.34314 26.999 6 26.999V24.999ZM24 24.999H6V26.999H24V24.999ZM25 23.999C25 24.5513 24.5523 24.999 24 24.999V26.999C25.6569 26.999 27 25.6558 27 23.999H25ZM25 13.999V23.999H27V13.999H25ZM24.6929 13.278C24.8834 13.4611 25 13.7156 25 13.999H27C27 13.1494 26.6455 12.3809 26.0791 11.8363L24.6929 13.278ZM15.6727 22.7389L26.0587 13.2971L24.7134 11.8172L14.3273 21.259L15.6727 22.7389ZM3.94129 13.2971L14.3273 22.7389L15.6727 21.259L5.28664 11.8172L3.94129 13.2971Z",
              fill: "#0F3E38",
              mask: "url(#path-7-inside-2_33151_168709)",
            },
          }),
          _vm._v(" "),
          _c(
            "mask",
            { attrs: { id: "path-9-inside-3_33151_168709", fill: "white" } },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M24.0003 26H6.00029C5.4622 26 4.97374 25.7875 4.61426 25.4418L15.0003 16L25.3863 25.4418C25.0268 25.7875 24.5384 26 24.0003 26Z",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M24.0003 26H6.00029C5.4622 26 4.97374 25.7875 4.61426 25.4418L15.0003 16L25.3863 25.4418C25.0268 25.7875 24.5384 26 24.0003 26Z",
              fill: "#FFF7EA",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4.61426 25.4418L3.92115 26.1627L3.15018 25.4214L3.94159 24.7019L4.61426 25.4418ZM15.0003 16L14.3276 15.2601L15.0003 14.6485L15.673 15.2601L15.0003 16ZM25.3863 25.4418L26.059 24.7019L26.8504 25.4214L26.0794 26.1627L25.3863 25.4418ZM24.0003 27H6.00029V25H24.0003V27ZM5.30736 24.721C5.48806 24.8948 5.73084 25 6.00029 25V27C5.19356 27 4.45943 26.6803 3.92115 26.1627L5.30736 24.721ZM3.94159 24.7019L14.3276 15.2601L15.673 16.7399L5.28693 26.1818L3.94159 24.7019ZM15.673 15.2601L26.059 24.7019L24.7136 26.1818L14.3276 16.7399L15.673 15.2601ZM24.0003 25C24.2697 25 24.5125 24.8948 24.6932 24.721L26.0794 26.1627C25.5411 26.6803 24.807 27 24.0003 27V25Z",
              fill: "#0F3E38",
              mask: "url(#path-9-inside-3_33151_168709)",
            },
          }),
        ]
      )
    : _vm.name === "cic_delivered_2"
    ? _c(
        "svg",
        {
          attrs: {
            width: _vm.width,
            height: _vm.height,
            viewBox: "0 0 30 30",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("rect", {
            attrs: {
              x: "4.5",
              y: "8.5",
              width: "21",
              height: "13",
              rx: "1.5",
              fill: "#B4D8CA",
              stroke: "#0F3E38",
            },
          }),
          _vm._v(" "),
          _c(
            "mask",
            { attrs: { id: "path-2-inside-1_33151_168708", fill: "white" } },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M24.0003 22H6.00029C5.4622 22 4.97374 21.7875 4.61426 21.4418L15.0003 12L25.3863 21.4418C25.0268 21.7875 24.5384 22 24.0003 22Z",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M24.0003 22H6.00029C5.4622 22 4.97374 21.7875 4.61426 21.4418L15.0003 12L25.3863 21.4418C25.0268 21.7875 24.5384 22 24.0003 22Z",
              fill: "#FFF7EA",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4.61426 21.4418L3.92115 22.1627L3.15018 21.4214L3.94159 20.7019L4.61426 21.4418ZM15.0003 12L14.3276 11.2601L15.0003 10.6485L15.673 11.2601L15.0003 12ZM25.3863 21.4418L26.059 20.7019L26.8504 21.4214L26.0794 22.1627L25.3863 21.4418ZM24.0003 23H6.00029V21H24.0003V23ZM5.30736 20.721C5.48806 20.8948 5.73084 21 6.00029 21V23C5.19356 23 4.45943 22.6803 3.92115 22.1627L5.30736 20.721ZM3.94159 20.7019L14.3276 11.2601L15.673 12.7399L5.28693 22.1818L3.94159 20.7019ZM15.673 11.2601L26.059 20.7019L24.7136 22.1818L14.3276 12.7399L15.673 11.2601ZM24.0003 21C24.2697 21 24.5125 20.8948 24.6932 20.721L26.0794 22.1627C25.5411 22.6803 24.807 23 24.0003 23V21Z",
              fill: "#0F3E38",
              mask: "url(#path-2-inside-1_33151_168708)",
            },
          }),
          _vm._v(" "),
          _c(
            "mask",
            { attrs: { id: "path-4-inside-2_33151_168708", fill: "white" } },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d: "M24.0003 8H6.00029C5.4622 8 4.97374 8.2125 4.61426 8.55815L15.0003 18L25.3863 8.55815C25.0268 8.2125 24.5384 8 24.0003 8Z",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M24.0003 8H6.00029C5.4622 8 4.97374 8.2125 4.61426 8.55815L15.0003 18L25.3863 8.55815C25.0268 8.2125 24.5384 8 24.0003 8Z",
              fill: "#68D2C8",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M4.61426 8.55815L3.92115 7.83731L3.15018 8.57863L3.94159 9.29809L4.61426 8.55815ZM15.0003 18L14.3276 18.7399L15.0003 19.3515L15.673 18.7399L15.0003 18ZM25.3863 8.55815L26.059 9.29809L26.8504 8.57863L26.0794 7.83731L25.3863 8.55815ZM24.0003 7H6.00029V9H24.0003V7ZM5.30736 9.27899C5.48806 9.10525 5.73084 9 6.00029 9V7C5.19356 7 4.45943 7.31975 3.92115 7.83731L5.30736 9.27899ZM3.94159 9.29809L14.3276 18.7399L15.673 17.2601L5.28693 7.81821L3.94159 9.29809ZM15.673 18.7399L26.059 9.29809L24.7136 7.81821L14.3276 17.2601L15.673 18.7399ZM24.0003 9C24.2697 9 24.5125 9.10525 24.6932 9.27899L26.0794 7.83731C25.5411 7.31975 24.807 7 24.0003 7V9Z",
              fill: "#0F3E38",
              mask: "url(#path-4-inside-2_33151_168708)",
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }