import CUserPhotoLevel from '@/components/c-user-photo-level/c-user-photo-level.vue';
import PCbUserReviewService from '@/services/cb-user-review.service';
import PCbVwUserPartnerLevelService from '@/services/cb-vw-user-partner-level.service';
import PCbVwUserReviewService from '@/services/cb-vw-user-review.service';
import GlobalService from '@/services/global.service';
import AlertService from '@/shared/alert/alert.service';
import { SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL } from '@/shared/constant/constants-session-storage';
import Icon from '@/shared/icons/icon.vue';
import { CbUserReview, ICbUserReview } from '@/shared/model/cb-user-review.model';
import { ICbVwUserPartnerLevel } from '@/shared/model/cb-vw-user-partner-level.model';
import { CbVwUserReview, ICbVwUserReview } from '@/shared/model/cb-vw-user-review.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { ReviewType } from '@/shared/model/enumerations/review-type.model';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: {CUserPhotoLevel, Icon},
})
export default class CPdReviewDetailReply extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('pCbUserReviewService') private pCbUserReviewService: () => PCbUserReviewService;
  @Inject('pCbVwUserReviewService') private pCbVwUserReviewService: () => PCbVwUserReviewService;
  @Inject('pCbVwUserPartnerLevelService') private pCbVwUserPartnerLevelService: () => PCbVwUserPartnerLevelService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwUserReview: CbVwUserReview;
  @Prop({ default: false }) public isHideReply;
  @Prop({ default: false }) public visibleReplyBtn: boolean;
  @Prop({ default: 'profileReview' }) public targetId: string;


  @Prop({ default: null })
  public seletedReviewId;
  
  public isShowInputReply = false;

  public cbUserReview: ICbUserReview = new CbUserReview();
  public isSavingCbUserReview = false;
  public countReply = 0;
  public isFetchingCbVwUserReview = false;

  public cbVwUserReviewReplies: ICbVwUserReview[] = [];

  public itemsPerPage = 10;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = true;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = null;
  public valueSearch = '';

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.retrieveCbVwUserReply();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleInputReply(e) {
    e.currentTarget.style.height = '1px';
    if (e.currentTarget.scrollHeight >= 100) e.currentTarget.style.height = 100 + 'px';
    else e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
  }
  public sendReply() {
    (<any>this.$refs.textareaMessageReviewReply).style.height = 'auto';
    this.isSavingCbUserReview = true;
    // this.isShowInputReply = false;
    this.cbUserReview.replyRefId = this.cbVwUserReview.id.toString();
    this.cbUserReview.externalId = this.cbVwUserReview.externalId;
    this.cbUserReview.rating = this.cbVwUserReview.rating;
    this.cbUserReview.reviewType = <ReviewType>this.cbVwUserReview.reviewType;
    this.pCbUserReviewService()
      .create(this.cbUserReview)
      .then(res => {
        this.cbUserReview = new CbUserReview();
        this.retrieveCbVwUserReply();
        // this.isShowFormReply = true;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      })
      .finally(() => {
        this.isSavingCbUserReview = false;
      });
}

  public toProfile(idUser) {
    this.$router.push({ name: 'PProfilePublic', params: { id: idUser } });
    this.globalService().closeGlobalDialog(this.$root, 'modal-paw-detail');
  }


  public retrieveCbVwUserReply() {
    let params: any = {
      'ownerId.equals': this.cbVwUserReview.ownerId,
      'externalId.equals': this.cbVwUserReview.externalId,
      'reviewType.equals': this.cbVwUserReview.reviewType,
      'replyRefId.equals': this.cbVwUserReview.id,
    };
    this.pCbVwUserReviewService()
      .retrieve(params)
      .then(res => {
        let userIds: string[] = [];
        for (const item of res.data) {
          let tmpCbVwUserReview: ICbVwUserReview = Object.assign({}, item);
          userIds.push(tmpCbVwUserReview.reviewerLevel);

          let findIndex = this.cbVwUserReviewReplies.findIndex(e => e.id == item.id);
          if (findIndex >= 0) {
            Vue.set(this.cbVwUserReviewReplies, findIndex, item);
          } else {
            this.cbVwUserReviewReplies.push(item);
          }
        }

        if (userIds.length > 0) {
          this.retrieveCbVwUserPartnerLevel(userIds);
        }
        this.cbVwUserReviewReplies.sort((a, b) => a.id - b.id);
        // this.isShowFormReply = true;
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => {
        this.isFetchingCbVwUserReview = false;
      });
  }

  public async retrieveCbVwUserPartnerLevel(partnerIds: string[]) {
    let tmpPartnerIds: string[] = partnerIds.filter(this.globalService().onlyUniqueIds);

    // CEK DLU UDH PERNAH GET ATAU BELUM
    let ids: string[] = [];
    for (const id of tmpPartnerIds) {
      let find = this.sessionCbVwUserPartnerLevel().find(e => e.userId == id);
      if (!find) {
        ids.push(id);
      }
    }
    if (ids.length > 0) {
      let params: any = {
        'userId.in': ids,
      };
      this.pCbVwUserPartnerLevelService()
        .retrieve(params)
        .then(res => {
          if (res.data) {
            let cbVwUserLevels: ICbVwUserPartnerLevel[] = res.data;

            // PUSH TO SESSION STORAGE
            let tmpUserLevels: ICbVwUserPartnerLevel[] = [...this.sessionCbVwUserPartnerLevel()];
            for (const item of cbVwUserLevels) {
              let foundIndex = tmpUserLevels.findIndex(e => e.userId == item.userId);
              if (foundIndex < 0) {
                tmpUserLevels.push(item);
              } else {
                Vue.set(tmpUserLevels, foundIndex, item);
              }
            }

            sessionStorage.setItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL, JSON.stringify(tmpUserLevels));
          }
        });
    }
    await this.processAssignPartnerLevel();
  }
  public sessionCbVwUserPartnerLevel(): ICbVwUserPartnerLevel[] {
    let stringCbVwUserPartnerLevels: string = sessionStorage.getItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL);
    let tmpCbVwUserPartnerLevels: ICbVwUserPartnerLevel[] = JSON.parse(stringCbVwUserPartnerLevels);
    if (tmpCbVwUserPartnerLevels?.length > 0) {
      return tmpCbVwUserPartnerLevels;
    }
    return [];
  }

  public async processAssignPartnerLevel() {
    if (this.cbVwUserReviewReplies?.length > 0 && this.sessionCbVwUserPartnerLevel()?.length > 0) {
      for (let index = 0; index < this.cbVwUserReviewReplies.length; index++) {
        let tmpCbVwUserReview: ICbVwUserReview = Object.assign({}, this.cbVwUserReviewReplies[index]);
        if (tmpCbVwUserReview.reviewerLevel) {
          let foundLevel = this.sessionCbVwUserPartnerLevel().find(e => e.userId == tmpCbVwUserReview.reviewerId);
          if (foundLevel) {
            tmpCbVwUserReview.reviewerLevel = foundLevel.userLvl.toString();
          }
        }

        Vue.set(this.cbVwUserReviewReplies, index, tmpCbVwUserReview);
      }
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================

  // ================= END LISTENERS ==================
  public handleShowReviewDetal(value){
    this.$emit('handleShowReviewDetal')
  }
}
