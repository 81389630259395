export interface IChtRoom {
  id?: number;
  _id?: number;
  content?: string | null;
  date?: string | null;
  files?: null;
  senderId?: string | null;
  sender_id?: string | null;
  username?: string | null;
  distributed?: boolean | null;
  roomName?: string | null;
  roomId?: number;
  typingUsers?: [] | null;
  users?: any[] | null;
  lastMessage?: { timestamp: string; distributed: boolean; content: string; iconMsg: string } | null;
  avatar?: string | null;
  index?: number;
  members?: string | null;
  unreadCount?: number;
  isPinned?: boolean | null;
  lastMsgDate?: Date | null;
  createdDate?: Date | null;
  isHasOrderActive?: boolean | null; //Transient
  positionScrollMsgId?: number;
}

export class ChtRoom implements IChtRoom {
  constructor(
    public id?: number,
    public _id?: number,
    public content?: string | null,
    public date?: string | null,
    public files?: null,
    public senderId?: string | null,
    public sender_id?: string | null,
    public username?: string | null,
    public distributed?: boolean | false,
    public roomName?: string | null,
    public roomId?: number,
    public typingUsers?: [] | null,
    public users?: any[] | null,
    public lastMessage?: { timestamp: string; distributed: boolean; content: string; iconMsg: string } | null,
    public avatar?: string | null,
    public index?: number,
    public members?: string | null,
    public unreadCount?: number,
    public isPinned?: boolean | null,
    public lastMsgDate?: Date | null,
    public createdDate?: Date | null,
    public isHasOrderActive?: boolean | null, //Transient
    public positionScrollMsgId?: number | null
  ) {}
}
