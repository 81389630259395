import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class GInitPage extends Vue {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.scrollToTop();
    });
  }

  public scrollToTop() {
    setTimeout(() => {
    }, 100);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.scrollTo({
      top:0,
      behavior: 'smooth'
    });
  }
}
