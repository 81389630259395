var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    _vm._b(
      {
        class: `c-button  ${
          _vm.$attrs.fullWidth !== undefined ? "w-100" : "w-auto"
        } ${_vm.borderRadius}
    ${
      _vm.disabled && _vm.typeStyle === "disabled-outline"
        ? "disabled-outline"
        : ""
    }
    ${_vm.disabled ? "disabled" : "ripple"}
    ${_vm.$attrs.boxShadow !== undefined ? "box-shadow" : ""}
    ${_vm.customStyle ? _vm.customStyle : _vm.getTypeStyle}
  `,
        attrs: {
          "data-gtm":
            _vm.dataGTMCustom !== null
              ? `${_vm.dataGTMCustom}-button`
              : "button",
          "data-cy": _vm.dataCyCustom || "button-component",
          disabled: _vm.disabled && _vm.disabledAttr,
          "aria-disabled": _vm.disabled,
          type: _vm.type,
        },
      },
      "button",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }