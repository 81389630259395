export interface ICShareData {
  title?: any | null;
  description?: any | null;
  url?: string | null;
  externalId?: number | null; //transient
  externalType?: string | null; //transient
}
export class CShareData implements ICShareData {
  constructor(
    public title?: any | null,
    public description?: any | null,
    public url?: string | null,
    public externalId?: number | null,
    public externalType?: string | null
  ) {}
}
