import { IChtReaction } from '@/shared/model/cht-reaction.model';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class messageReactionsChat extends Vue {
  @Prop({
    type: [Array],
    default: function () {
      return [];
    },
  })
  public reactions: IChtReaction[];
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
