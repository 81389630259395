import {Vue, Component } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class CAudio extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES =================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
