import { ICbAttachmentRestrict } from '@/shared/model/cb-attachment-restrict.model';
import { ICbPawOrder } from '@/shared/model/cb-paw-order.model';

import { CbPawOrderFileType } from '@/shared/model/enumerations/cb-paw-order-file-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { IWfHistory } from './wf-history.model';
import { ICbAtt } from './cb-att';

export interface ICbPawOrderFile {
  id?: number;
  pawOrderFileType?: CbPawOrderFileType | null;
  isDeleted?: boolean | null;
  isDeletedByPartner?: boolean | null;
  isDeletedByBuyer?: boolean | null;
  fileName?: string | null;
  status?: CbCommonStatus | null;
  restrictByPartner?: string | null;
  restrictByBuyer?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  cbAttachmentRestrict?: ICbAttachmentRestrict | null;
  cbPawOrder?: ICbPawOrder | null;
  deliveryGroupByDate?: Date | null;
  requirementId?: number | null;
  uploadLink?: string | null;

  // transient
  name?: string | null;
  wfHistory?: IWfHistory | null;
  isLoading?: boolean | null;
  idTmp?: string | null;
  cbAtt?: ICbAtt | null;
  isUploadFileWithWatermark?: boolean | null;
  isUploadFileInCbPawOrderFile?: boolean | null;
}

export class CbPawOrderFile implements ICbPawOrderFile {
  constructor(
    public id?: number,
    public pawOrderFileType?: CbPawOrderFileType | null,
    public isDeleted?: boolean | null,
    public isDeletedByPartner?: boolean | null,
    public isDeletedByBuyer?: boolean | null,
    public fileName?: string | null,
    public name?: string | null,
    public status?: CbCommonStatus | null,
    public restrictByPartner?: string | null,
    public restrictByBuyer?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public cbAttachmentRestrict?: ICbAttachmentRestrict | null,
    public cbPawOrder?: ICbPawOrder | null,
    public deliveryGroupByDate?: Date | null,
    public requirementId?: number | null,
    public uploadLink?: string | null,

    // transient
    public wfHistory?: IWfHistory | null,
    public isLoading?: boolean | null,
    public idTmp?: string | null,
    public cbAtt?: ICbAtt | null,
    public isUploadFileWithWatermark?: boolean | null,
    public isUploadFileInCbPawOrderFile?: boolean | null
  ) {
    this.isLoading = this.isLoading ?? false;
    // this.isDeleted = this.isDeleted ?? false;
    // this.isDeletedByPartner = this.isDeletedByPartner ?? false;
    // this.isDeletedByBuyer = this.isDeletedByBuyer ?? false;
  }
}
