import { NtNotifTypeWithSetting } from './enumerations/nt-notif-type.model';

export interface INtUserNotifSetting {
  id?: number;
  notifType?: NtNotifTypeWithSetting | null;
  isEmail?: boolean | null;
  isWa?: boolean | null;
  isOnPlatform?: boolean | null;
  userId?: string | null;
  isActive?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  unixId?: number | null;
}

export class NtUserNotifSetting implements INtUserNotifSetting {
  constructor(
    public id?: number,
    public notifType?: NtNotifTypeWithSetting | null,
    public isEmail?: boolean | null,
    public isWa?: boolean | null,
    public isOnPlatform?: boolean | null,
    public userId?: string | null,
    public isActive?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public unixId?: number | null
  ) {}
}
