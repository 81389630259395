import { ICbTool } from '@/shared/model/cb-tool.model';
import { ICbTag } from '@/shared/model/cb-tag.model';
import { ICbPawPortfolioDoc } from '@/shared/model/cb-paw-portfolio-doc.model';
import { ICbPawPortfolioAtt } from '@/shared/model/cb-paw-portfolio-att.model';
import { ICbAuctionProjectAtt } from '@/shared/model/cb-auction-project-att.model';

export interface ICbAttachmentNoAuth {
  id?: number;
  name?: string | null;
  attContentType?: string | null;
  att?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbTool?: ICbTool | null;
  cbTag?: ICbTag | null;
  cbPawPortfolioDoc?: ICbPawPortfolioDoc | null;
  cbPawPortfolioAtt?: ICbPawPortfolioAtt | null;
  cbAuctionProjectAtt?: ICbAuctionProjectAtt | null;
  attTrans?: string | null;
  extension?: string | null;
  description?: string | null;
  status?: string | null;
  attSize?: number | null;
  type?: string | null;
  url?: string | null;
  refThumbnailId?: number | null;
  isThumbnail?: boolean | null;
  thumbnail?: any | null;
  thumbnailLocalUrl?: string | null;
  localUrl?: string | null;
  blob?: Blob | null;
  uploadLink?: string | null;
  tmpPdfImages?: any | null; //transient
  downloadLink?: string | null;
}

export class CbAttachmentNoAuth implements ICbAttachmentNoAuth {
  constructor(
    public id?: number,
    public name?: string | null,
    public attContentType?: string | null,
    public att?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbTool?: ICbTool | null,
    public cbTag?: ICbTag | null,
    public cbPawPortfolioDoc?: ICbPawPortfolioDoc | null,
    public cbPawPortfolioAtt?: ICbPawPortfolioAtt | null,
    public cbAuctionProjectAtt?: ICbAuctionProjectAtt | null,
    public attTrans?: string | null,
    public extension?: string | null,
    public description?: string | null,
    public status?: string | null,
    public attSize?: number | null,
    public type?: string | null,
    public url?: string | null,
    public refThumbnailId?: number | null,
    public isThumbnail?: boolean | null,
    public thumbnail?: any | null,
    public localUrl?: string | null,
    public blob?: Blob | null,
    public uploadLink?: string | null,
    public tmpPdfImages?: any | null, //transient
    public downloadLink?: string | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
