import { ICbPawOrderFile } from '@/shared/model/cb-paw-order-file.model';
import { ICbLov } from './cb-lov.model';

export interface ICbAttachmentRestrict {
  app?: string | null;
  id?: number;
  name?: string | null;
  attContentType?: string | null;
  att?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbPawOrderFile?: ICbPawOrderFile | null;
  attTrans?: string | null;
  restrictToUserId?: string | null;
  extension?: string | null;
  watermarkId?: number | null;
  selectedWatermark?: ICbLov | null;
  localBlobUrl?: string | null;
  attSize?: number | null;
  localUrl?: string | null;
  blob?: Blob | null;
  refThumbnailId?: number | null;
  isThumbnail?: boolean | null;
  thumbnail?: any | null;
  thumbnailLocalUrl?: string | null;
  fileThumbnail?: any | null;
  uploadLink?: string | null;
  downloadLink?: string | null;
  uploadPercentage?: number | null;
  label?: string | null;

  // transient
  localFileUrl?: string | null;
  downloadLinkFile?: string | null;
  blobWatermark?: Blob | null;
  blobThumbnailLocalUrl?: Blob | null;
  tmpPdfImages?: any | null;
  isPreviewThumb?: boolean | null;
  attachmentRestrictThumbnail?: ICbAttachmentRestrict | null;
}

export class CbAttachmentRestrict implements ICbAttachmentRestrict {
  constructor(
    public app?: string | null,
    public id?: number,
    public name?: string | null,
    public attContentType?: string | null,
    public att?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbPawOrderFile?: ICbPawOrderFile | null,
    public attTrans?: string | null,
    public restrictToUserId?: string | null,
    public extension?: string | null,
    public watermarkId?: number | null,
    public selectedWatermark?: ICbLov | null,
    public localBlobUrl?: string | null,
    public attSize?: number | null,
    public localUrl?: string | null,
    public blob?: Blob | null,
    public refThumbnailId?: number | null,
    public isThumbnail?: boolean | null,
    public thumbnail?: any | null,
    public thumbnailLocalUrl?: string | null,
    public fileThumbnail?: string | null,
    public uploadLink?: string | null,
    public downloadLink?: string | null,
    public uploadPercentage?: number | null,
    public label?: string | null,

    // transient
    public localFileUrl?: string | null,
    public downloadLinkFile?: string | null,
    public blobWatermark?: Blob | null,
    public blobThumbnailLocalUrl?: Blob | null,
    public tmpPdfImages?: any | null,
    public isPreviewThumb?: any | null,
    public attachmentRestrictThumbnail?: ICbAttachmentRestrict | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
