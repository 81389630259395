var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "vac-audio-player" },
      [
        _c(
          "div",
          { staticClass: "vac-svg-button", on: { click: _vm.playback } },
          [
            _vm.isPlaying
              ? _vm._t("audio-pause-icon", function () {
                  return [_c("svg-icon", { attrs: { name: "audio-pause" } })]
                })
              : _vm._t("audio-play-icon", function () {
                  return [_c("svg-icon", { attrs: { name: "audio-play" } })]
                }),
          ],
          2
        ),
        _vm._v(" "),
        _c("audio-control", {
          attrs: {
            percentage: _vm.progress,
            "message-selection-enabled": _vm.messageSelectionEnabled,
          },
          on: {
            "change-linehead": _vm.onUpdateProgress,
            "hover-audio-progress": function ($event) {
              return _vm.$emit("hover-audio-progress", $event)
            },
          },
        }),
        _vm._v(" "),
        _c("audio", { attrs: { id: _vm.playerUniqId, src: _vm.audioSource } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }