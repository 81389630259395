import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import Icon from '@/shared/icons/icon.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: { CTextareaPreview, Icon },
})
export default class CPdDescription extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop()
  public cbVwPaw;
  @Prop()
  public cbTextNoauth;
  @Prop()
  public getFilterMetadatas;
  @Prop()
  public getFilterMetavalue;
  @Prop()
  public getLanguageTextEnId;

  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
