var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-form-auction-project-pitch cj-bg-white-primary",
      class: _vm.visibleFormOffer ? "btn-actived" : "",
    },
    [
      _vm.isFetchingVwAuctionProject
        ? _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center align-items-center p-3 cj-bg-white-primary w-100 h-100 size-loading",
            },
            [
              _c("c-loader", {
                staticClass: "d-flex m-auto",
                attrs: { show: _vm.isFetchingVwAuctionProject, infinite: true },
              }),
            ],
            1
          )
        : _c(
            "div",
            {
              class: _vm.isMobile
                ? ""
                : "c-form-auction-project-pitch-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header-wrapper-m c-navbar-m d-flex d-lg-none pr-3 border-grey-tertiary-bottom-1 cj-bg-white-primary",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "p-3 d-flex cursor-pointer my-auto",
                      attrs: { "data-cy": "go-back" },
                      on: { click: _vm.callBack },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass:
                          "cj-color-black-primary cb-icon-size-arrow-left my-auto",
                        attrs: { icon: "fa-solid fa-arrow-left" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-weight-500 font-size-18 font-size-title cj-color-black-primary my-auto mr-auto text-ellipsis oneline",
                    },
                    [
                      _vm.toggleOpenOffer
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAuctionProject.makeOffer"
                                )
                              ),
                            },
                          })
                        : _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAuctionProject.detailProject"
                                )
                              ),
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.toggleOpenOffer
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "btn-icon-shared wrap-border-grey-tertiary rounded-circle my-auto",
                          attrs: { "data-cy": "project-detail-share" },
                          on: {
                            click: function ($event) {
                              return _vm.openModalCShare(
                                _vm.vwAuctionProject.id
                              )
                            },
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "cj-stroke-red-primary",
                            attrs: { width: "1.5rem", name: "ic_shared" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    visible:
                      !_vm.isMobile || (_vm.isMobile && !_vm.toggleOpenOffer),
                  },
                },
                [
                  _c("c-ap-pitch-preview", {
                    attrs: {
                      cbTextNoAuth: _vm.cbTextNoAuth,
                      vwAuctionProject: _vm.vwAuctionProject,
                      closeModalId: _vm.closeModalId,
                    },
                    on: {
                      saveBookmark: _vm.saveBookmark,
                      openModalCShare: _vm.openModalCShare,
                      cancel: _vm.cancel,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  staticClass: "pt-3",
                  attrs: { visible: _vm.toggleOpenOffer },
                },
                [
                  !_vm.isMobile
                    ? _c("div", { staticClass: "mb-3" }, [
                        _c("p", {
                          staticClass:
                            "font-roboto font-weight-700 font-size-24 cj-color-black-primary text-center",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbComponent.cAuctionProject.yourOffer"
                              )
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ml-auto px-3 py-1 cj-bg-red-secondary",
                        staticStyle: { "border-radius": "28px" },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "ml-auto font-roboto font-weight-400 font-size-12 cj-color-black-primary",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "cj-color-grey-secondary mr-1" },
                              [_vm._v("*")]
                            ),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "cbgwApp.cbHome.cbHomeBeforeLogin.register.required"
                                  )
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto font-weight-500 font-size-18 cj-color-black-primary mb-2",
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAuctionProject.yourPitch"
                                )
                              ),
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "cj-color-grey-secondary" },
                            [_vm._v("*")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("c-textarea-new", {
                        staticClass:
                          "rounded font-roboto font-weight-400 font-size-18 cj-color-black-primary",
                        class: `${
                          _vm.$v.cbAuctionProjectPreOffer.pitch.$anyDirty
                            ? _vm.$v.cbAuctionProjectPreOffer.pitch.$invalid
                              ? "invalid"
                              : "valid"
                            : ""
                        }`,
                        attrs: {
                          "data-cy": "wrap-text-pitch",
                          maxlength: 1000,
                        },
                        model: {
                          value: _vm.$v.cbAuctionProjectPreOffer.pitch.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.cbAuctionProjectPreOffer.pitch,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.cbAuctionProjectPreOffer.pitch.$model",
                        },
                      }),
                      _vm._v(" "),
                      _vm.$v.cbAuctionProjectPreOffer.pitch.$anyDirty &&
                      _vm.$v.cbAuctionProjectPreOffer.pitch.$invalid
                        ? _c("div", { staticClass: "mt-2" }, [
                            !_vm.$v.cbAuctionProjectPreOffer.pitch.minLength
                              ? _c("small", {
                                  staticClass:
                                    "font-validation cj-color-status-error",
                                  attrs: {
                                    "data-cy": "text-validation-length",
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "cbgwApp.cbComponent.cAuctionProject.minLength"
                                      )
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _c("p", {
                            staticClass: "font-validation mt-2",
                            attrs: { "data-cy": "text-validation" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAuctionProject.descriptionInfo"
                                )
                              ),
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-wrap mx-min-2" }, [
                    _c(
                      "div",
                      { staticClass: "px-2 col-12 col-lg-6" },
                      [
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between mb-2",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto font-weight-500 font-size-18 cj-color-black-primary my-auto",
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "cbgwApp.cbComponent.cAuctionProject.deliveryTime"
                                        )
                                      ),
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "cj-color-grey-secondary" },
                                    [_vm._v("*")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  ref: "info-delivery-time",
                                  staticClass: "my-auto",
                                  on: {
                                    click: function ($event) {
                                      _vm.isMobile
                                        ? (_vm.isActivePopupBottom =
                                            !_vm.isActivePopupBottom)
                                        : ""
                                    },
                                  },
                                },
                                [
                                  _vm.cbAuctionProjectPreOffer.deliveryTimeType
                                    ? _c("icon", {
                                        staticClass: "cj-fill-grey-tertiary",
                                        attrs: {
                                          name: "ic_information",
                                          width: "24px",
                                          height: "24px",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap mx-min-2 mb-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-4 px-2" },
                                [
                                  _c("c-input-number", {
                                    staticClass:
                                      "font-roboto font-weight-400 font-size-18 cj-color-black-primary",
                                    class: `${
                                      _vm.$v.cbAuctionProjectPreOffer
                                        .deliveryTime.$anyDirty
                                        ? _vm.$v.cbAuctionProjectPreOffer
                                            .deliveryTime.$invalid
                                          ? "invalid"
                                          : "valid"
                                        : ""
                                    }`,
                                    attrs: {
                                      "data-cy": "delivery-time",
                                      max: _vm.maxDeliveryTime,
                                      placeholder: "0",
                                    },
                                    model: {
                                      value:
                                        _vm.$v.cbAuctionProjectPreOffer
                                          .deliveryTime.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.cbAuctionProjectPreOffer
                                            .deliveryTime,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.cbAuctionProjectPreOffer.deliveryTime.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-8 px-2" }, [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: () => {
                                        if (_vm.isMobile) {
                                          _vm.$root.$emit(
                                            "formApPitchPopup::changeValue",
                                            "visiblePopupDeliveryTimeType",
                                            true
                                          )
                                          _vm.$root.$emit(
                                            "formApPitchPopup::changeValue",
                                            "popupValueDeliveryTimeType",
                                            _vm.cbAuctionProjectPreOffer
                                              .deliveryTimeType
                                          )
                                        }
                                      },
                                    },
                                  },
                                  [
                                    _c("c-autocomplete-new", {
                                      staticClass:
                                        "rounded cj-bg-white-primary font-roboto font-weight-400 font-size-18 cj-color-black-primary",
                                      class: {
                                        invalid:
                                          _vm.$v.cbAuctionProjectPreOffer
                                            .deliveryTimeType.$anyDirty &&
                                          _vm.$v.cbAuctionProjectPreOffer
                                            .deliveryTimeType.$invalid,
                                        valid:
                                          _vm.$v.cbAuctionProjectPreOffer
                                            .deliveryTimeType.$anyDirty &&
                                          !_vm.$v.cbAuctionProjectPreOffer
                                            .deliveryTimeType.$invalid,
                                      },
                                      attrs: {
                                        "data-cy": "delivery-time-type",
                                        dataCyCustom: "delivery-time",
                                        items: _vm.deliveryTimeTypes,
                                        disableTyping: true,
                                        isHideItemList: _vm.isMobile,
                                        "item-text": "value",
                                        "item-value": "key",
                                        placeholder: _vm.$t(
                                          "cbgwApp.cbAuctionProject.deliveryTime"
                                        ),
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.$v.cbAuctionProjectPreOffer.deliveryTime.$model =
                                            null
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.$v.cbAuctionProjectPreOffer
                                            .deliveryTimeType.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.cbAuctionProjectPreOffer
                                              .deliveryTimeType,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$v.cbAuctionProjectPreOffer.deliveryTimeType.$model",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", {
                            staticClass: "font-validation",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAuctionProject.deliveryTimeInfo"
                                )
                              ),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.vwAuctionProject.svcType == _vm.CbSvcType.INSIDE
                          ? [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-roboto font-weight-500 font-size-18 cj-color-black-primary mb-2",
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("cbGlobal.revision")
                                      ),
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "cj-color-grey-secondary" },
                                    [_vm._v("*")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("c-input-revision", {
                                staticClass:
                                  "font-roboto font-weight-400 font-size-18 cj-color-black-primary mb-3",
                                attrs: {
                                  isValid:
                                    _vm.$v.cbAuctionProjectPreOffer
                                      .revisionCount.$anyDirty,
                                },
                                model: {
                                  value:
                                    _vm.$v.cbAuctionProjectPreOffer
                                      .revisionCount.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.cbAuctionProjectPreOffer
                                        .revisionCount,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.cbAuctionProjectPreOffer.revisionCount.$model",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "px-2 col-12 col-lg-6" }, [
                      _c("div", { staticClass: "mb-3 mb-lg-4" }, [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto font-weight-500 font-size-18 cj-color-black-primary mb-2",
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "cbgwApp.cbComponent.cAuctionProject.priceType"
                                  )
                                ),
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "cj-color-grey-secondary" },
                              [_vm._v("*")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "mb-2",
                            on: {
                              click: () => {
                                if (_vm.isMobile) {
                                  _vm.$root.$emit(
                                    "formApPitchPopup::changeValue",
                                    "visiblePopupPriceType",
                                    true
                                  )
                                  _vm.$root.$emit(
                                    "formApPitchPopup::changeValue",
                                    "popupValuePriceType",
                                    _vm.cbAuctionProjectPreOffer.priceType
                                  )
                                }
                              },
                            },
                          },
                          [
                            _c("c-autocomplete-new", {
                              staticClass:
                                "rounded cj-bg-white-primary font-roboto font-weight-400 font-size-18 cj-color-black-primary",
                              class: {
                                invalid:
                                  _vm.$v.cbAuctionProjectPreOffer.priceType
                                    .$anyDirty &&
                                  _vm.$v.cbAuctionProjectPreOffer.priceType
                                    .$invalid,
                                valid:
                                  _vm.$v.cbAuctionProjectPreOffer.priceType
                                    .$anyDirty &&
                                  !_vm.$v.cbAuctionProjectPreOffer.priceType
                                    .$invalid,
                              },
                              attrs: {
                                "data-cy": "price-type",
                                dataCyCustom: "price-type",
                                items: _vm.priceTypeList,
                                disableTyping: true,
                                isHideItemList: _vm.isMobile,
                                "item-text": "value",
                                "item-value": "key",
                                placeholder: _vm.$t(
                                  "cbgwApp.cbAuctionProject.priceType"
                                ),
                              },
                              model: {
                                value:
                                  _vm.$v.cbAuctionProjectPreOffer.priceType
                                    .$model,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.$v.cbAuctionProjectPreOffer.priceType,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression:
                                  "$v.cbAuctionProjectPreOffer.priceType.$model",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto font-weight-500 font-size-18 cj-color-black-primary mb-2 pt-0",
                          style: !_vm.isMobile
                            ? "padding-top: 21px !important"
                            : "",
                        },
                        [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAuctionProject.budget"
                                )
                              ),
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "cj-color-grey-secondary" },
                            [_vm._v("*")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex cj-field-border-default rounded px-2 cj-bg-white-primary mb-3",
                          class: `${
                            _vm.$v.budget.$anyDirty
                              ? _vm.$v.budget.$invalid ||
                                _vm.getTotalBudget < 150000
                                ? "invalid"
                                : "valid"
                              : ""
                          }`,
                          attrs: { "data-cy": "currency-budget" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto font-weight-500 font-size-18 cj-color-black-primary my-auto mr-2",
                            },
                            [_vm._v("IDR")]
                          ),
                          _vm._v(" "),
                          _c("c-input-currency", {
                            staticClass:
                              "font-roboto font-weight-400 font-size-18 cj-color-black-primary cj-field-not-border",
                            attrs: {
                              "data-cy": "wrap-currency",
                              placeholder: "0",
                              currency: _vm.CbCurrency.IDR,
                              max: _vm.MaxNumberType.NUMBER_10T,
                            },
                            model: {
                              value: _vm.$v.budget.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.budget, "$model", $$v)
                              },
                              expression: "$v.budget.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-3" }, [
                        _c("p", {
                          staticClass:
                            "font-roboto font-weight-500 font-size-18 cj-color-black-primary",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbComponent.cAuctionProject.totalBudget"
                              )
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto font-weight-500 font-size-24 my-auto",
                            class:
                              _vm.getTotalBudget > 0
                                ? "cj-color-black-primary"
                                : "cj-color-grey-secondary",
                            attrs: { "data-cy": "total" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mr-1 cj-color-black-primary" },
                              [_vm._v("IDR")]
                            ),
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.getTotalBudget,
                                  "IDR"
                                )
                              ) + "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.getTotalBudget != 0 && _vm.getTotalBudget < 150000
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "font-validation cj-color-status-error mt-2",
                                attrs: {
                                  "data-cy": "project-pitch-budget-warning",
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "cbgwApp.cbAuctionProject.warningMinBudget"
                                      )
                                    ),
                                  },
                                }),
                                _vm._v(
                                  "\n              IDR 150.000\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mt-auto row p-btn-wrapper" }, [
                _vm.isMobile && !_vm.toggleOpenOffer
                  ? _c("div", { staticClass: "mr-2 mb-2" }, [
                      !_vm.$options.filters.findBookmarkByIdIsActive(
                        _vm.cbUserBookmarkAggs,
                        _vm.vwAuctionProject.id,
                        _vm.CbBookmarkType.AUCTION_PROJECT
                      )
                        ? _c(
                            "div",
                            {
                              ref: "info-bookmark",
                              staticClass:
                                "my-auto btn-icon wrap-border-grey-secondary rounded-circle p-2",
                              attrs: {
                                "data-cy": "project-detail-bookmark-empty",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveBookmark(
                                    _vm.vwAuctionProject.id
                                  )
                                },
                              },
                            },
                            [
                              _c("icon", {
                                staticClass: "cj-stroke-grey-secondary m-auto",
                                attrs: {
                                  name: "ic_bookmark",
                                  width: "100%",
                                  height: "100%",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$options.filters.findBookmarkByIdIsActive(
                        _vm.cbUserBookmarkAggs,
                        _vm.vwAuctionProject.id,
                        _vm.CbBookmarkType.AUCTION_PROJECT
                      )
                        ? _c(
                            "div",
                            {
                              ref: "info-bookmark-fill",
                              staticClass:
                                "my-auto btn-icon wrap-border-grey-secondary rounded-circle p-2",
                              attrs: {
                                "data-cy": "project-detail-bookmark-fill",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveBookmark(
                                    _vm.vwAuctionProject.id
                                  )
                                },
                              },
                            },
                            [
                              _c("icon", {
                                staticClass:
                                  "cj-fill-red-primary cj-stroke-red-primary m-auto",
                                attrs: {
                                  name: "ic_bookmark",
                                  width: "100%",
                                  height: "100%",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMobile ? _c("div", { staticClass: "col" }) : _vm._e(),
                _vm._v(" "),
                _vm.vwAuctionProject.status === _vm.CbCommonStatus.ACTIVE &&
                _vm.visibleFormOffer
                  ? _c(
                      "div",
                      { staticClass: "col text-center col-m" },
                      [
                        !_vm.toggleOpenOffer &&
                        _vm.currentUser.userLvl != _vm.CbUserLevelType.ROOKIE
                          ? _c(
                              "c-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: _vm.toggleOpenOffer,
                                    expression: "toggleOpenOffer",
                                  },
                                ],
                                staticClass: "mb-2",
                                attrs: {
                                  fullWidth: "",
                                  boxShadow: "",
                                  "data-cy": "btn-make-offer",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleToggleOpenOffer.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "cbgwApp.cbComponent.cAuctionProject.makeOffer"
                                      )
                                    ),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.toggleOpenOffer
                          ? _c(
                              "c-button",
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  disabled: _vm.isSaving,
                                  fullWidth: "",
                                  boxShadow: "",
                                  "data-cy": "send-offer",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.submit()
                                  },
                                },
                              },
                              [
                                _vm.isSaving
                                  ? _c("font-awesome-icon", {
                                      attrs: {
                                        spin: _vm.isSaving,
                                        icon: "sync",
                                      },
                                    })
                                  : _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(
                                            "cbgwApp.cbComponent.cAuctionProject.sendOffer"
                                          )
                                        ),
                                      },
                                    }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isMobile
                          ? _c(
                              "p",
                              {
                                staticClass:
                                  "font-roboto font-weight-300 text-center font-size-14 cj-color-black-primary",
                                attrs: {
                                  "data-cy": "project-detail-proposal-count",
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.vwAuctionProject.proposalCount) +
                                    " " +
                                    _vm._s(
                                      _vm.$t(
                                        "cbGlobal.freelanceMainPage.proposalReceived"
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.vwAuctionProject.status == _vm.CbCommonStatus.EXPIRED &&
                _vm.currentUser.userLvl != _vm.CbUserLevelType.ROOKIE
                  ? _c(
                      "div",
                      { staticClass: "col text-center" },
                      [
                        _c(
                          "c-button",
                          {
                            staticClass: "mb-2",
                            attrs: {
                              typeStyle: "secondary",
                              fullWidth: "",
                              boxShadow: "",
                            },
                          },
                          [_vm._v("Chat Buyer")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        _vm.isMobile ? "c-popup-bottom" : "b-popover",
        {
          tag: "component",
          attrs: {
            target: () => _vm.$refs["info-delivery-time"],
            "custom-class": "custom-content-popover",
            triggers: "hover",
            placement: "bottom",
          },
          model: {
            value: _vm.isActivePopupBottom,
            callback: function ($$v) {
              _vm.isActivePopupBottom = $$v
            },
            expression: "isActivePopupBottom",
          },
        },
        [
          _vm.isMobile
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center col p-0 mb-2",
                },
                [
                  _c(
                    "p",
                    { staticClass: "font-roboto font-weight-500 font-size-18" },
                    [_vm._v("Info")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.isActivePopupBottom = false
                        },
                      },
                    },
                    [
                      _c("b-icon-x-circle-fill", {
                        staticClass: "font-size-20 cj-color-grey-secondary",
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "font-catamaran cj-color-black-primary font-size-12 font-weight-500 text-justify",
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.toolTip.deliveryTimeInfo.info1")
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.toolTip.deliveryTimeInfo.and")
                  ),
                },
              }),
              _vm._v(" "),
              _c("span", {
                staticClass: "cj-btn-text-primary",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.toolTip.deliveryTimeInfo.link2")
                  ),
                },
                on: { click: _vm.toHelpCenter },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("c-ap-pitch-popup", {
        attrs: { cbAuctionProjectPreOffer: _vm.cbAuctionProjectPreOffer },
        on: { changeValue: _vm.changeValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }