import { ICmAttachmentRestrict } from './cm-attachment-restrict.model';

export interface ICmVwTkt {
  id?: number;
  ticketId?: string | null;
  senderId?: number | null;
  senderStatus?: string | null;
  title?: string | null;
  category?: string | null;
  channel?: string | null;
  priority?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  statusResolution?: string | null;
  statusResolutionCreatedDate?: Date | null;
  picStatusReslution?: string | null;
  limitTime?: Date | null;
  resolutionStatus?: string | null;
  isUpdated?: boolean | null;
  isArchived?: boolean | null;
  picId?: number | null;
  picName?: string | null;
  senderEmail?: string | null;
  senderName?: string | null;
  escalateTicket?: string | null;
  escalateUserId?: string | null;
  escalateName?: string | null;
  firstResponseDate?: Date | null;
  app?: string | null;
  contentTicket?: string | null;
  shareToId?: string | null;
  shareNote?: string | null;
  shareToName?: string | null;
  contentResponedTicketId?: number | null;
  responedticketBy?: string | null;
  responedTicketDate?: Date | null;
  attTotal?: number | null;
  attIds?: string | null;
  shareOrigin?: string | null;
  escalateNote?: string | null;
  resolutionTicketId?: number | null;
  contactReason?: string | null;
  subContactReason?: string | null;
  lastCmTktText?: string | null;
  tktAtts?: string | null;
  lastStatusGeneral?: string | null;
  statusResolutionAgg?: string | null;
  qSearch?: string | null;
  cmAttachmentRestricts?: ICmAttachmentRestrict[] | null;
}

export class CmVwTkt implements ICmVwTkt {
  constructor(
    public id?: number,
    public ticketId?: string | null,
    public senderId?: number | null,
    public senderStatus?: string | null,
    public title?: string | null,
    public category?: string | null,
    public channel?: string | null,
    public priority?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public statusResolution?: string | null,
    public statusResolutionCreatedDate?: Date | null,
    public picStatusReslution?: string | null,
    public limitTime?: Date | null,
    public resolutionStatus?: string | null,
    public isUpdated?: boolean | null,
    public isArchived?: boolean | null,
    public picId?: number | null,
    public picName?: string | null,
    public senderEmail?: string | null,
    public senderName?: string | null,
    public escalateTicket?: string | null,
    public escalateUserId?: string | null,
    public escalateName?: string | null,
    public firstResponseDate?: Date | null,
    public app?: string | null,
    public contentTicket?: string | null,
    public shareToId?: string | null,
    public shareNote?: string | null,
    public shareToName?: string | null,
    public contentResponedTicketId?: number | null,
    public responedticketBy?: string | null,
    public responedTicketDate?: Date | null,
    public attTotal?: number | null,
    public attIds?: string | null,
    public shareOrigin?: string | null,
    public escalateNote?: string | null,
    public resolutionTicketId?: number | null,
    public contactReason?: string | null,
    public subContactReason?: string | null,
    public lastCmTktText?: string | null,
    public tktAtts?: string | null,
    public lastStatusGeneral?: string | null,
    public statusResolutionAgg?: string | null,
    public qSearch?: string | null,
    public cmAttachmentRestricts?: ICmAttachmentRestrict[] | null
  ) {
    // this.isUpdated = this.isUpdated ?? false;
    // this.isArchived = this.isArchived ?? false;
  }
}
