import { ICbUserInfo } from './cb-user-info.model';
export interface ICbOid {
  id?: number | null;
  reqType?: string | null;
  message?: any | null;
  detail?: any | null;
  cbUserInfo?: ICbUserInfo | null;
  countResend?: number | null;

  // transient
  typeCbOid?: 'DASHBOARD' | null;
}

export class CbOid implements ICbOid {
  constructor(
    public id?: number | null,
    public reqType?: string | null,
    public message?: any | null,
    public detail?: any | null,
    public cbUserInfo?: ICbUserInfo | null,
    public countResend?: number | null,
    public typeCbOid?: 'DASHBOARD' | null
  ) {}
}

export interface ICbOidActivation {
  key?: string | null;
  activationCode?: any | null;
  phone?: string | null;
  phoneCode?: string | null;
  newPassword?: string | null;
}

export class CbOidActivation implements ICbOidActivation {
  constructor(
    public key?: string | null,
    public activationCode?: any | null,
    public phone?: string | null,
    public phoneCode?: string | null,
    public newPassword?: string | null
  ) {}
}

export interface ICbSetPassword {
  activationCode?: string | null;
  newPassword?: string | null;
}

export class CbSetPassword implements ICbSetPassword {
  constructor(public activationCode?: string | null, public newPassword?: string | null) {}
}

// "identityProvider": "google",
// "userId": "105338291589494380283",
// "userName": "mohamadwildan1207@gmail.com"

export interface ICbLinkedAccount {
  identityProvider?: string | null;
  userId?: string | null;
  userName?: string | null;
}

export class CbLinkedAccount implements ICbLinkedAccount {
  constructor(public identityProvider?: string | null, public userId?: string | null, public userName?: string | null) {}
}

export interface ICbKeyAndPasswordVm {
  key?: string | null;
  newPassword?: string | null;
}

export class CbKeyAndPasswordVm implements ICbKeyAndPasswordVm {
  constructor(public key?: string | null, public newPassword?: string | null) {}
}
