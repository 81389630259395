import { Inject, Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
import { CbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import Icon from '@/shared/icons/icon.vue';
import Vue2Filters from 'vue2-filters';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import PCbOidService from '@/services/cb-oid.service';
import { CbVerificationMethodType } from '@/shared/model/enumerations/cb-verification-method-type.model';
import { ICbOidActivation } from '@/shared/model/cb-oids.model';
import AlertService from '@/shared/alert/alert.service';
import CAutocompleteNew from '../c-autocomplete-new/c-autocomplete-new.vue';
import ContentService from '@/services/content.service';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';
import CPopupBottom from '../c-popup-bottom/c-popup-bottom.vue';
import { Debounce } from '@/shared/decorator/debounce';
import PUserService from '@/services/user.service';
import GlobalService from '@/services/global.service';
import JhiDataUtils from '@/shared/data/data-utils.service';
import FormUtils from '@/shared/utils/form-utils.service';
import NavigationUtils from '@/shared/data/navigation-utils.service';
const validations: any = {
  phoneCode: { required, $autoDirty: true },
  phone: { required, minLength: minLength(5), maxLength: maxLength(16), $autoDirty: true },
};
@Component({
  validations,
  components: {
    CButton,
    Icon,
    CAutocompleteNew,
    CPopupBottom,
  },
  watch: {
    phone: {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 16;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max) {
          this.phone = oldVal;
        }
        this.phone = this.$options.filters.regexPhoneNumber(this.phone);
        this.handleCheckPhone();

        this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
  mixins: [Vue2Filters.mixin],
})
export default class CEditPhoneNumber extends Mixins(JhiDataUtils, FormUtils, NavigationUtils) {
  // ================= START SERVICES =================
  @Inject('pCbOidService') public pCbOidService: () => PCbOidService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('contentService') private contentService: () => ContentService;
  @Inject('pUserService') private pUserService: () => PUserService;
  @Inject('globalService') public globalService: () => GlobalService;

  // ================= END SERVICES =================
  // ================= START VARIABLES =================
  @Prop({ default: null }) public cbVerificationOtp: CbVerificationOtp;
  public phone: string = '';
  public phoneCode: string = '+62';

  public localPhoneCode: string = '+62';

  public isShowPhoneCode: boolean = false;
  public isSaving: boolean = false;
  public CbVerificationMethodType = CbVerificationMethodType;
  public isFetchingLovs: boolean = false;
  public valueStatusPhone: string = '';
  public isExistPhone: boolean = false;
  public isSendOtpError: boolean = false;
  public isFetchingPhoneNumber: boolean = false;
  // ================= END VARIABLES =================
  // ================= START DEFAULT FUNCTION =================
  public created() {
    this.$root.$off('cEditPhoneNumber::changeValue');
    this.$root.$on('cEditPhoneNumber::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });
    this.contentService().initRetrieveCbLovs(CbLovType.PHONE_CODE);
  }
  public get cbLovPhoneCodes() {
    return this.$store.getters[CbLovType.PHONE_CODE];
  }
  // ================= END DEFAULT FUNCTION =================
  // ================= START FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }

  public openPopupPhoneCodeM() {
    if (this.isMobile) {
      this.localPhoneCode = this.phoneCode;
      this.isShowPhoneCode = true;
    }
  }

  public openRegisterModal() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-edit-phone-number');
    if (this.isMobile) {
      this.$router.push({ name: 'PRegisterM' });
    } else {
      (<any>this.$root).$emit('handleOpenRegister');
    }
  }
  public handleSubmitEditVerification() {
    this.isSaving = true;
    if (this.isPhoneTrouble) {
      const activation: ICbOidActivation = {
        phone: this.phone,
        phoneCode: this.phoneCode,
      };

      this.pCbOidService()
        .sendOtpTroubleByPhone(activation)
        .then(res => {
          this.isSendOtpError = false;
          (<any>this.$root).$bvToast.toast('Send OTP Success', {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          });
          this.$store.commit('isPhoneTrouble', true);
          (<any>this.$root).$emit('submitRegister', res);
          this.globalService().closeGlobalDialog(this.$root, 'modal-edit-phone-number');
        })
        .catch(err => {
          this.isSendOtpError = true;
        })
        .finally(() => {
          this.isSaving = false;
        });
    } else {
      const activation: ICbOidActivation = {
        key: this.cbVerificationOtp.phoneActivationKey,
        activationCode: null,
        phone: this.phone,
        phoneCode: this.phoneCode,
      };
      this.pCbOidService()
        .updatePhoneByKey(activation, CbVerificationMethodType.PHONE)
        .then(res => {
          // this.$emit('save', res);
          this.$store.commit('isPhoneTrouble', true);
          (<any>this.$root).$bvToast.toast('Send OTP Success', {
            toaster: 'b-toaster-top-center',
            title: 'Info',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
          });
          (<any>this.$root).$emit('submitRegister', res);
          this.globalService().closeGlobalDialog(this.$root, 'modal-edit-phone-number');
        })
        .catch(err => {
          this.alertService().showHttpError(this, err.response);
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  }

  @Debounce(600)
  public handleCheckPhone() {
    if (this.isPhoneTrouble) {
      return;
    }
    if (this.phone && this.phone.length > 5 && this.phoneCode) {
      this.isFetchingPhoneNumber = true;
      this.pUserService()
        .findPhoneExist(this.phoneCode, this.phone)
        .then(res => {
          this.valueStatusPhone = res.data.detail;
          if (res.data.id > 0) {
            this.isExistPhone = true;
          } else {
            this.isExistPhone = false;
          }
        })
        .catch(err => {
          this.valueStatusPhone = '';
        })
        .finally(() => {
          this.isFetchingPhoneNumber = false;
        });
    } else {
      this.isExistPhone = false;
    }
  }

  @Emit('openModalLogin')
  public openModalLogin() {
    return;
  }

  // ================= END FUNCTION =================
  // ================= START COMPUTE =================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isPhoneTrouble(): boolean {
    return this.$store.getters.isPhoneTrouble;
  }

  // ================= END COMPUTE =================

  @Watch('isMobile')
  public isMobileListener() {
    if (!this.isMobile) {
      this.isShowPhoneCode = false;
    }
  }
}
