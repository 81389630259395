export interface ICbNavbar {
  isShow?: boolean | null;
  isDefaultMenu?: boolean | null;
  isX?: boolean | null;
  isArrow?: boolean | null;
  title?: string | null;
  typeGoBack?: 'IS_SHOW_CHAT_INFO_PANEL' | null;
  page?: string | null;
}

export class CbNavbar implements ICbNavbar {
  constructor(
    public isShow?: boolean | null,
    public isDefaultMenu?: boolean | null,
    public isX?: boolean | null,
    public isArrow?: boolean | null,
    public title?: string | null,
    public typeGoBack?: 'IS_SHOW_CHAT_INFO_PANEL' | null,
    public page?: string | null
  ) {}
}
