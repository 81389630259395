export enum CbPaymentStatus {
  INACTIVE = 'INACTIVE',

  ACTIVE = 'ACTIVE',

  COMPLETED = 'COMPLETED',

  PENDING = 'PENDING',

  FAILED = 'FAILED',

  PAID = 'PAID',

  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',

  FREEZE = 'FREEZE',
}
