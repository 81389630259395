import CUserPhotoLevel from '@/components/c-user-photo-level/c-user-photo-level.vue';
import Icon from '@/shared/icons/icon.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CPdReviewDetailReply from '../c-pd-review-detail-reply/c-pd-review-detail-reply.vue';
@Component({
  components: {Icon,CUserPhotoLevel, CPdReviewDetailReply},
})
export default class CPdReviewDetail extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop()
  public cbVwUserReviews;

  @Prop()
  public cbVwUserReviewRatings;

  @Prop()
  public averageRatingReview;

  @Prop()
  public queryCountUserReview;

  @Prop()
  public sortReview;

  public seletedReviewId='';
  
  
  
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  public handleShowReview(value){
    this.seletedReviewId = value.id;
  }
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
