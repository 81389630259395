import { Component, Emit, Inject, Vue } from 'vue-property-decorator';
import CItemSvcCat from '../c-item-svc-cat/c-item-svc-cat.vue';
import CButton from '../button/c-button.vue';
import { CbVwSvcCat, ICbVwSvcCat } from '@/shared/model/cb-vw-svc-cat.model';
import GlobalService from '@/services/global.service';
import PCbPersonalizeInterestsService from '@/services/cb-personalize-interests.service';
import AlertService from '@/shared/alert/alert.service';
import { CbPersonalizeInterests, ICbPersonalizeInterests } from '@/shared/model/cb-personalize-interests.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import ContentService from '@/services/content.service';
@Component({
  components: { CItemSvcCat, CButton },
})
export default class CPersonalizeYInterests extends Vue {
  // ================= START SERVICES ===================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbPersonalizeInterestsService') private pCbPersonalizeInterestsService: () => PCbPersonalizeInterestsService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('contentService') public contentService: () => ContentService;
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  public cbVwSvcCat: ICbVwSvcCat = new CbVwSvcCat();
  public seletedCbVwSvcCats: ICbVwSvcCat[] = [];
  public cbPersonalizeInterests: ICbPersonalizeInterests[] = [];
  public isSaving = false;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  mounted() {
    this.contentService().initRetrieveCbVwSvcCat();
    const checkboxes = document.querySelectorAll('.item-personalize-interest-wrapper');
    const labels = document.querySelectorAll('.item-personalize-interest');

    // Add event listeners to all checkboxes
    checkboxes.forEach((checkbox, index) => {
      checkbox.addEventListener('change', function () {
        if (checkbox.checked) {
          labels[index].classList.add('actived');
        } else {
          labels[index].classList.remove('actived');
        }
      });
    });
  }
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  public save(): void {
    this.isSaving = true;
    for (let dataValue of this.seletedCbVwSvcCats) {
      let personalizeInterest: ICbPersonalizeInterests = new CbPersonalizeInterests();
      personalizeInterest.userId = this.currentUser.id;
      personalizeInterest.svcCategoryId = dataValue.id;
      personalizeInterest.svcCategoryName = dataValue.name;
      this.cbPersonalizeInterests.push(personalizeInterest);
    }
    this.pCbPersonalizeInterestsService()
      .create(this.cbPersonalizeInterests)
      .then(param => {
        this.isSaving = false;

        this.globalService().closeGlobalDialog(this.$root, 'modal-personalize-y-interests');
        const message = 'Success';
        (this.$root as any).$bvToast.toast(message, {
          toaster: 'b-toaster-top-center',
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        });
      })
      .catch(error => {
        this.isSaving = false;
        this.alertService().showHttpError(this, error.response);
      });
  }

  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get cbVwSvcCats(): ICbVwSvcCat[] {
    return this.$store.getters.cbVwSvcCats;
  }

  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public get getCbVwSvcCatSorts() {
    if (this.cbVwSvcCats && this.cbVwSvcCats.length > 0) {
      return this.cbVwSvcCats.sort((a: ICbVwSvcCat, b: ICbVwSvcCat) => a.seq - b.seq);
    }
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
