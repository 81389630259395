import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import Vue from 'vue';
import { CbInputCurrencyType } from '@/shared/model/enumerations/cb-input-currency-type.model';
import { ICbUserBookmarkAgg } from '../model/cb-user-bookmark-agg.model';
import { CbBookmarkType } from '../model/enumerations/cb-bookmark-type.model';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const DATE_TIME_LONG_FORMAT = 'YYYY-MM-DDTHH:mm';
export const DATE_INDONESIAN_LONG_FORMAT = 'D MMMM YYYY';
export const DATE_INDONESIAN_SHORT_FORMAT = 'D MMM `YY';

export const TIME_24_FORMAT = 'HH:mm';

export function initFiltersGeneral() {
  Vue.filter('capitalEachSpace', value => {
    if (value) {
      value = value.toLowerCase();
      const arr = value.split('_');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      return arr.join(' ');
    }
    return '';
  });
  Vue.filter('capital', value => {
    if (value) {
      value = value.toLowerCase();
      const arr = value.split(' ');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      return arr.join(' ');
    }
    return '';
  });
  Vue.filter('capitalFirstLetter', (value: string) => {
    if (value) {
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1).replace(/_/g, ' ');
    }
    return '';
  });

  Vue.filter('replaceUndescore', (value: string) => {
    if (value) {
      return value.replace(/_/g, ' ');
    }
    return '';
  });
  Vue.filter('filterOrderStatus', (value: CbCommonStatus) => {
    let ret = '';
    if (value) {
      if (value == CbCommonStatus.SERVICE_FINISHED) {
        ret = 'Finished';
      } else if (value == CbCommonStatus.LATE_ON_TRACK) {
        ret = 'Late';
      } else if (value == CbCommonStatus.MOM) {
        ret = 'Additional';
      } else if (value == CbCommonStatus.REVISION_REQUESTED) {
        ret = 'Waiting for payment';
      } else if (value == CbCommonStatus.CLOCK_IN) {
        ret = 'On Progress';
      } else {
        ret = value;
      }
      ret = ret.toLowerCase();
      return ret.charAt(0).toUpperCase() + ret.slice(1).replace(/_/g, ' ');
    }
    return '';
  });
  Vue.filter('filterOrderRequirementMom', (value: CbCommonStatus) => {
    let ret = '';
    if (value) {
      if (value == CbCommonStatus.MOM) {
        ret = CbCommonStatus.WAITING;
      }
      if (value == CbCommonStatus.PAID) {
        ret = CbCommonStatus.ACCEPTED;
      }
      if (value == CbCommonStatus.CANCELLED) {
        ret = CbCommonStatus.DECLINED;
      }
      ret = ret.toLowerCase();
      return ret.charAt(0).toUpperCase() + ret.slice(1).replace(/_/g, ' ');
    }
    return '';
  });
  Vue.filter('filterOrderRequirementAppointment', (value: CbCommonStatus) => {
    let ret = '';
    if (value) {
      if (value == CbCommonStatus.APPOINTMENT) {
        ret = CbCommonStatus.WAITING;
      }
      if (value == CbCommonStatus.PAID) {
        ret = CbCommonStatus.ACCEPTED;
      }
      if (value == CbCommonStatus.CANCELLED) {
        ret = CbCommonStatus.DECLINED;
      }
      ret = ret.toLowerCase();
      return ret.charAt(0).toUpperCase() + ret.slice(1).replace(/_/g, ' ');
    }
    return '';
  });
  Vue.filter('filterOrderRequirementDelivery', (value: CbCommonStatus) => {
    let ret = '';
    if (value) {
      if (value == CbCommonStatus.FEEDBACK_NEEDED) {
        ret = CbCommonStatus.WAITING;
      }
      if (value == CbCommonStatus.COMPLETED) {
        ret = CbCommonStatus.ACCEPTED;
      }
      if (value == CbCommonStatus.ASK_FOR_REVISION) {
        ret = CbCommonStatus.ASK_FOR_REVISION;
      }
      ret = ret.toLowerCase();
      return ret.charAt(0).toUpperCase() + ret.slice(1).replace(/_/g, ' ');
    }
    return '';
  });

  Vue.filter('formatCurrency', (value: number, currency: any) => {
    let locale: any = 'id-ID';
    if (currency) {
      for (const localCurrency in CbInputCurrencyType) {
        if (currency.toUpperCase() == localCurrency) {
          locale = CbInputCurrencyType[localCurrency];
        }
      }
    }

    if (value) {
      return new Intl.NumberFormat(locale).format(value);
    }
    return '0';
  });

  Vue.filter('boldText', (fString, bString) => {
    if (bString && bString.length > 1) {
      const valueSearch = bString.toLowerCase();
      const content = fString.toLowerCase();
      const regex = new RegExp(valueSearch, 'g');
      const ret = content.replace(regex, `<span class="font-roboto font-size-14 font-weight-700">${valueSearch}</span>`);
      return ret;
    }
  });

  Vue.filter('isContentLovType', (lov: any, type: string) => {
    if (lov && lov.description && lov.description.toLowerCase().includes(type.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  });

  Vue.filter('colorOrderStatus', (value: CbCommonStatus) => {
    if (value) {
      if (value == CbCommonStatus.COMPLETED) {
        return 'cj-color-status-success';
      } else if (value == CbCommonStatus.WAITING_PAYMENT || value == CbCommonStatus.BOOKED || value == CbCommonStatus.DISPUTED) {
        return 'cj-color-grey-secondary';
      } else if (
        value == CbCommonStatus.ON_PROGRESS ||
        value == CbCommonStatus.CLOCK_IN ||
        value == CbCommonStatus.SERVICE_FINISHED ||
        value == CbCommonStatus.ASK_FOR_REVISION ||
        value == CbCommonStatus.REVISION_REQUESTED ||
        value == CbCommonStatus.FEEDBACK_NEEDED
      ) {
        return 'cj-color-status-warning';
      } else if (value == CbCommonStatus.OVERDUE || value == CbCommonStatus.LATE || value == CbCommonStatus.LATE_ON_TRACK) {
        return 'cj-color-status-warning';
      } else if (value == CbCommonStatus.CANCELLED) {
        return 'cj-color-status-error';
      } else {
        return 'cj-color-grey-secondary';
      }
    }
  });
  Vue.filter('colorAuctionPreOfferStatus', (value: CbCommonStatus) => {
    if (value) {
      if (value == CbCommonStatus.WAITING) {
        return 'color-status-muted';
      } else if (value == CbCommonStatus.CHOOSEN) {
        return 'color-status-success';
      } else if (value == CbCommonStatus.NOT_CHOOSEN) {
        return 'color-status-danger';
      }
    }
  });

  Vue.filter('formatBankAccountNumber', (value: string) => {
    let newString = value.replace(/\s/g, '');
    if (!Number(newString)) {
      newString = newString.replace(/[^,\d]/g, '');
    }
    if (newString) {
      const v = value
        .replace(/\s+/g, '')
        .replace(/[^,\d]/g, '')
        .replace(/[^0-9]/gi, '');
      const matches = v.match(/\d{4,16}/g);
      const match = (matches && matches[0]) || '';
      const parts = [];
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      let result = '';
      if (parts.length) {
        result = parts.join(' ');
      } else {
        result = value;
      }
      return result;
    } else {
      return '';
    }
  });

  Vue.filter('inputNoSpecialChar', (value: string) => {
    if (value) {
      return value.replace(/[^0-9A-Za-z_\s]/gi, '');
    }
    return '';
  });
  Vue.filter('regexLetterAndSpaceOnly', (value: string) => {
    if (value) {
      return value.replace(/[^A-Za-z ]/gi, '');
    }
    return '';
  });

  Vue.filter('regexLetterAndNumberOnly', (value: string) => {
    if (value) {
      return value.replace(/[^0-9A-Za-z]/gi, '');
    }
    return '';
  });
  Vue.filter('regexNumberOnly', (value: string) => {
    if (value && !Number.isNaN(Number(value))) {
      value = value.replace(/[^\d]+/g, '');
      return value;
    } else if (value && Number.isNaN(Number(value))) {
      return value.replace(/[^\d]+/g, '');
    }
    return null;
  });

  Vue.filter('regexPhoneNumber', (value: string) => {
    if (value && !Number.isNaN(Number(value))) {
      value = value.replace(/[^\d]+/g, '');
      if (value[0].toString() == '0') {
        value = value.slice(1);
      }
      return value;
    } else if (value && Number.isNaN(Number(value))) {
      return value.replace(/[^\d]+/g, '');
    }
    return null;
  });
  Vue.filter('regexLetterNumberAndValidChar', (value: string) => {
    if (value) {
      value = value.replace(/[^0-9A-Za-z \.\,\;\:\-\_\?\!()[\]"\/'`‘’'&“"”]/gi, '');
      value = value.replace(/::/gi, ':');
      value = value.replace(/:::/gi, ':');
      value = value.replace(/::/gi, ':');
      value = value.replace(/\|\|/gi, '');
      return value;
    }
    return '';
  });

  Vue.filter('capitalEachSpaceAndReplaceOr', (value: string) => {
    if (value) {
      value = value.toLowerCase().replace('OR', '/');
      const arr = value.split('_');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      return arr.join(' ');
    }
    return '';
  });

  Vue.filter('findBookmarkById', (bookmarkList: ICbUserBookmarkAgg[], dataId: number, bookmarkType: CbBookmarkType) => {
    if (bookmarkList && bookmarkList.length > 0 && dataId && bookmarkType) {
      const find = bookmarkList.find(e => e.dataId == dataId && e.bookmarkType == bookmarkType);
      return find;
    } else return null;
  });

  Vue.filter('findBookmarkByIdIsActive', (bookmarkList: ICbUserBookmarkAgg[], dataId: number, bookmarkType: CbBookmarkType) => {
    if (bookmarkList?.length > 0 && dataId && bookmarkType) {
      const find = bookmarkList.find(e => e.dataId == dataId && e.bookmarkType == bookmarkType && e.isActive == true);
      if (find) return find.isActive;
      else return false;
    } else return false;
  });

  Vue.filter('findVideoAttachmentId', (cbAttachment: any, type: string) => {
    if (cbAttachment && cbAttachment.attContentType && cbAttachment.attContentType.toLowerCase().includes(type.toLowerCase())) {
      return cbAttachment.refThumbnailId;
    } else {
      return cbAttachment.id;
    }
  });

  Vue.filter('findVideoLovId', (lov: any, type: string) => {
    if (lov && lov.description && lov.description.toLowerCase().includes(type.toLowerCase())) {
      return lov.value2;
    } else {
      return lov.value;
    }
  });

  Vue.filter('isContentType', (cbAttachment: any, type: string) => {
    if (cbAttachment && cbAttachment.attContentType && cbAttachment.attContentType.toLowerCase().includes(type.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  });
  Vue.filter('toLowerCase', (value: string) => {
    if (value) {
      return value.toLowerCase();
    }
  });
  Vue.filter('sliceCharacter', (value: string, startSlice: number) => {
    if (value && startSlice) {
      return value.slice(0, startSlice);
    }
  });
  Vue.filter('countFormatToK', (num: any) => {
    return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000) + 'k' : Math.sign(num) * Math.abs(num);
  });

  Vue.filter('countFormatToPlus', (num: any) => {
    return num > 99 ? '99+' : num;
  });

  Vue.filter('formatPriceWithPriceType', (value: any, priceType: CbPriceType, hour: any, currency: string) => {
    if (priceType == CbPriceType.PER_HOUR && hour) {
      value = value / hour;
    }

    let locale: any = 'id-ID';
    if (currency) {
      for (const localCurrency in CbInputCurrencyType) {
        if (currency.toUpperCase() == localCurrency) {
          locale = CbInputCurrencyType[localCurrency];
        }
      }
    }
    if (value) {
      return new Intl.NumberFormat(locale).format(value);
    }
    return '0';
  });

  Vue.filter('formatLanguage', (dataValue: string, language: any) => {
    let capitalConvert = '';
    if (dataValue) {
      dataValue = dataValue.toLowerCase();
      const arr = dataValue.split('_');
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      capitalConvert = arr.join(' ');
    }

    if (dataValue === 'paw_name' && language === 'en') {
      return 'Paw Name';
    } else if (dataValue === 'paw_name' && language === 'id') {
      return 'Nama Paw';
    } else if (dataValue === 'posted_date' && language === 'id') {
      return 'Tanggal Posting';
    } else if (dataValue === 'posted_date' && language === 'en') {
      return 'Posted Date';
    } else if (dataValue === 'impression' && language === 'id') {
      return 'Impresi';
    } else if (dataValue === 'impression' && language === 'en') {
      return 'Impression';
    } else if (dataValue === 'total_order' && language === 'id') {
      return 'Total Pesanan';
    } else if (dataValue === 'total_order' && language === 'en') {
      return 'Total Order';
    } else if (dataValue === 'account' && language === 'id') {
      return 'Akun';
    } else if (dataValue === 'account' && language === 'en') {
      return 'Account';
    } else if (dataValue === 'account modification' && language === 'id') {
      return 'Modifikasi Akun';
    } else if (dataValue === 'account modification' && language === 'en') {
      return 'Account Modification';
    } else if (dataValue === 'password' && language === 'id') {
      return 'Kata Sandi';
    } else if (dataValue === 'password' && language === 'en') {
      return 'Password';
    } else if (dataValue === 'linked_account' && language === 'id') {
      return 'Akun Tertaut';
    } else if (dataValue === 'linked_account' && language === 'en') {
      return 'Linked Account';
    } else if (dataValue === 'notification' && language === 'id') {
      return 'Notifikasi';
    } else if (dataValue === 'notification' && language === 'en') {
      return 'Notification';
    } else if (dataValue === 'billing_history' && language === 'id') {
      return 'Riwayat Transaksi';
    } else if (dataValue === 'billing_history' && language === 'en') {
      return 'Transaction History';
    } else if (dataValue === 'bank_account' && language === 'id') {
      return 'Akun Bank';
    } else if (dataValue === 'bank_account' && language === 'en') {
      return 'Bank Account';
    } else if (dataValue === 'pin' && language === 'id') {
      return 'PIN';
    } else if (dataValue === 'pin' && language === 'en') {
      return 'PIN';
    } else if (dataValue === 'inbox messages' && language === 'id') {
      return 'Pesan Masuk';
    } else if (dataValue === 'inbox messages' && language === 'en') {
      return 'Inbox Messages';
    } else if (dataValue === 'order_updates' && language === 'id') {
      return 'Pembaruan Pesanan';
    } else if (dataValue === 'order_updates' && language === 'en') {
      return 'Order Updates';
    } else if (dataValue === 'system_messages' && language === 'id') {
      return 'Pesan Sistem';
    } else if (dataValue === 'system_messages' && language === 'en') {
      return 'System Messages';
    } else if (dataValue === 'fans following' && language === 'id') {
      return 'Fans Following';
    } else if (dataValue === 'fans following' && language === 'en') {
      return 'Fans Following';
    } else if (dataValue === 'request_offer' && language === 'id') {
      return 'Permintaan Penawaran';
    } else if (dataValue === 'request_offer' && language === 'en') {
      return 'Request Offer';
    } else if (dataValue === 'new_request_offer' && language === 'id') {
      return 'Penawaran Permintaan Baru';
    } else if (dataValue === 'new_request_offer' && language === 'en') {
      return 'New Request Offer';
    } else if (dataValue === 'fixed_price' && language === 'id') {
      return 'Harga Tetap';
    } else if (dataValue === 'fixed_price' && language === 'en') {
      return 'Fixed Price';
    } else if (dataValue === 'per_hour' && language === 'id') {
      return 'Per Jam';
    } else if (dataValue === 'per_hour' && language === 'en') {
      return 'Hourly';
    } else if (dataValue === 'add' && language === 'id') {
      return 'Tambahkan';
    } else if (dataValue === 'add' && language === 'en') {
      return 'Add';
    } else if (dataValue === 'payment' && language === 'id') {
      return 'Pembayaran';
    } else if (dataValue === 'payment' && language === 'en') {
      return 'Payment';
    } else if (dataValue === 'credit card' && language === 'id') {
      return 'Kartu Kredit';
    } else if (dataValue === 'credit card' && language === 'en') {
      return 'Credit Card';
    } else if (dataValue === 'fixed price' && language === 'id') {
      return 'Harga Tetap';
    } else if (dataValue === 'fixed price' && language === 'en') {
      return 'Fixed Price';
    } else if (dataValue === 'per hour' && language === 'id') {
      return 'Per Jam';
    } else if (dataValue === 'per hour' && language === 'en') {
      return 'Per Hour';
    } else if (dataValue === 'status' && language === 'id') {
      return 'Status';
    } else if (dataValue === 'status' && language === 'en') {
      return 'Status';
    } else if (dataValue === 'request_name' && language === 'id') {
      return 'Nama Permintaan';
    } else if (dataValue === 'request_name' && language === 'en') {
      return 'Request Name';
    } else if (dataValue === 'request refund' && language === 'id') {
      return 'Permintaan Pengembalian Dana';
    } else if (dataValue === 'request refund' && language === 'en') {
      return 'Request Refund';
    } else if (dataValue === 'due_date' && language === 'id') {
      return 'Batas Waktu';
    } else if (dataValue === 'due_date' && language === 'en') {
      return 'Due Date';
    } else if (dataValue === 'service' && language === 'id') {
      return 'Layanan';
    } else if (dataValue === 'service' && language === 'en') {
      return 'Service';
    } else if (dataValue === 'budget' && language === 'id') {
      return 'Anggaran';
    } else if (dataValue === 'budget' && language === 'en') {
      return 'Budget';
    } else if (dataValue === 'locations' && language === 'id') {
      return 'Lokasi';
    } else if (dataValue === 'locations' && language === 'en') {
      return 'Location';
    } else if (dataValue === 'rating' && language === 'id') {
      return 'Rating';
    } else if (dataValue === 'rating' && language === 'en') {
      return 'Rating';
    } else if (dataValue === 'level' && language === 'id') {
      return 'Level';
    } else if (dataValue === 'level' && language === 'en') {
      return 'Level';
    } else if (dataValue === 'delivery_time' && language === 'id') {
      return 'Waktu Pengiriman';
    } else if (dataValue === 'delivery_time' && language === 'en') {
      return 'Delivery Time';
    } else if (dataValue === 'language' && language === 'id') {
      return 'Bahasa';
    } else if (dataValue === 'language' && language === 'en') {
      return 'Language';
    } else if (dataValue === 'my_account' && language === 'id') {
      return 'Akun Saya';
    } else if (dataValue === 'my_account' && language === 'en') {
      return 'My Account';
    } else if (dataValue === 'report_user' && language === 'id') {
      return 'Laporkan Pengguna';
    } else if (dataValue === 'report_user' && language === 'en') {
      return 'Report User';
    } else if (dataValue === 'report_paw' && language === 'id') {
      return 'Laporkan PAW';
    } else if (dataValue === 'report_paw' && language === 'en') {
      return 'Report PAW';
    } else if (dataValue === 'my_project' && language === 'id') {
      return 'Proyek Saya';
    } else if (dataValue === 'my_project' && language === 'en') {
      return 'My Project';
    } else if (dataValue === 'problem_with_freelancer' && language === 'id') {
      return 'Masalah dengan Freelancer';
    } else if (dataValue === 'problem_with_freelancer' && language === 'en') {
      return 'Problem With Freelancer';
    } else if (dataValue === 'report_activity' && language === 'id') {
      return 'Melaporkan Aktivitas';
    } else if (dataValue === 'report_activity' && language === 'en') {
      return 'Report Activity';
    } else if (dataValue === 'payment_or_billing' && language === 'id') {
      return 'Pembayaran atau Tagihan';
    } else if (dataValue === 'payment_or_billing' && language === 'en') {
      return 'Payment or Billing';
    } else if (dataValue === 'technical_issues' && language === 'id') {
      return 'Masalah Teknis';
    } else if (dataValue === 'technical_issues' && language === 'en') {
      return 'Technical Issues';
    } else if (dataValue === 'feedback' && language === 'id') {
      return 'Masukan';
    } else if (dataValue === 'feedback' && language === 'en') {
      return 'Feedback';
    } else if (dataValue === 'dafar' && language === 'id') {
      return 'Daftar';
    } else if (dataValue === 'daftar' && language === 'en') {
      return 'Register';
    } else if (dataValue === 'unggah dokumen' && language === 'id') {
      return 'Unggah Dokumen';
    } else if (dataValue === 'unggah dokumen' && language === 'en') {
      return 'Upload Document';
    } else if (dataValue === 'my_order' && language === 'en') {
      return 'My Order';
    } else if (dataValue === 'my_order' && language === 'id') {
      return 'Pesanan Saya';
    } else if (dataValue === 'change personal data' && language === 'en') {
      return 'Change Personal Data';
    } else if (dataValue === 'change personal data' && language === 'id') {
      return 'Mengganti Data Personal';
    } else if (dataValue === 'delete freelancer account' && language === 'en') {
      return 'Delete Freelancer Account';
    } else if (dataValue === 'delete freelancer account' && language === 'id') {
      return 'Hapus Akun Freelancer';
    } else if (dataValue === 'report order' && language === 'en') {
      return 'Report Order';
    } else if (dataValue === 'report order' && language === 'id') {
      return 'Lapor Pesanan';
    } else if (dataValue === 'unresponsive_freelancer' && language === 'id') {
      return 'Freelancer Tidak Responsif';
    } else if (dataValue === 'community_violation' && language === 'id') {
      return 'Pelanggaran Komunitas';
    } else if (dataValue === 'copyright_violation' && language === 'id') {
      return 'Pelanggaran Hak Cipta';
    } else if (dataValue === 'report a bug' && language === 'en') {
      return 'Report a Bug';
    } else if (dataValue === 'report a bug' && language === 'id') {
      return 'Laporkan Bug';
    } else if (dataValue === 'collarbear platform' && language === 'en') {
      return 'Collarbear Platform';
    } else if (dataValue === 'collarbear platform' && language === 'id') {
      return 'Platform Collarbear';
    } else if (dataValue === 'privacy_violation' && language === 'id') {
      return 'Pelanggaran Privasi';
    } else if (dataValue === 'change worker type' && language === 'en') {
      return 'Change Worker Type';
    } else if (dataValue === 'change worker type' && language === 'id') {
      return 'Mengganti Tipe Pekerja';
    } else if (dataValue === 'other' && language === 'en') {
      return 'Other';
    } else if (dataValue === 'other' && language === 'id') {
      return 'Lainnya';
    } else if (dataValue === 'register' && language === 'en') {
      return 'Register';
    } else if (dataValue === 'register' && language === 'id') {
      return 'Daftar';
    } else if (dataValue === 'upload document' && language === 'en') {
      return 'Upload Document';
    } else if (dataValue === 'upload document' && language === 'id') {
      return 'Unggah Dokumen';
    } else if (dataValue === 'verified' && language === 'en') {
      return 'Verified';
    } else if (dataValue === 'verified' && language === 'id') {
      return 'Terverifikasi';
    } else if (dataValue === 'order' && language === 'en') {
      return 'Order';
    } else if (dataValue === 'order' && language === 'id') {
      return 'Pesanan';
    } else if (dataValue === 'canceled_invoice' && language === 'en') {
      return 'Cancelled Invoice';
    } else if (dataValue === 'canceled_invoice' && language === 'id') {
      return 'Invoice Pembatalan';
    } else if (dataValue === 'top_up' && language === 'en') {
      return 'Top Up';
    } else if (dataValue === 'top_up' && language === 'id') {
      return 'Isi Saldo';
    } else if (dataValue === 'withdrawal' && language === 'en') {
      return 'Withdrawal';
    } else if (dataValue === 'withdrawal' && language === 'id') {
      return 'Penarikan';
    } else if (dataValue === 'waiting' && language === 'en') {
      return 'Waiting';
    } else if (dataValue === 'waiting' && language === 'id') {
      return 'Waiting';
    } else if (dataValue === 'not_choosen' && language === 'en') {
      return 'Not Chosen';
    } else if (dataValue === 'not_choosen' && language === 'id') {
      return 'Not Chosen';
    } else if (dataValue === 'choosen' && language === 'en') {
      return 'Chosen';
    } else if (dataValue === 'choosen' && language === 'id') {
      return 'Chosen';
    } else if (dataValue === 'buyer' && language === 'en') {
      return 'Buyer';
    } else if (dataValue === 'buyer' && language === 'id') {
      return 'Pembeli';
    } else if (dataValue === 'partner' && language === 'en') {
      return 'Freelancer';
    } else if (dataValue === 'partner' && language === 'id') {
      return 'Freelancer';
    } else if (dataValue === 'delete_freelancer_account' && language === 'id') {
      return 'Hapus Akun Freelancer';
    } else if (dataValue === 'change_personal_data' && language === 'id') {
      return 'Ubah Data Pribadi';
    } else if (dataValue === 'change_worker_type' && language === 'id') {
      return 'Ubah Tipe Pekerja';
    } else if (dataValue === 'report_order' && language === 'id') {
      return 'Laporkan Order';
    } else if (dataValue === 'report_bug' && language === 'id') {
      return 'Laporkan Bug';
    } else if (dataValue === 'fraud_or_scam' && language === 'id') {
      return 'Penipuan';
    } else if (dataValue === 'delete_account' && language === 'id') {
      return 'Hapus Akun';
    } else {
      return capitalConvert;
    }
  });

  Vue.filter('accountNumberDisplay', value => {
    if (value) {
      return value.slice(value.length - 4);
    }
    return '';
  });

  Vue.filter('accountNumberAsteriks', (accountNumber: string) => {
    if (accountNumber && accountNumber.length > 0) {
      const lastString = accountNumber.substring(accountNumber.length - 4);
      return '**** **** **** ' + lastString;
    } else {
      return '';
    }
  });
  Vue.filter('titleAsteriks', name => {
    const names = name.split(' ');
    const obscuredNames = names.map(subname => {
      const nameArray = subname.split('');
      for (let i = 2; i < nameArray.length - 1; i++) {
        nameArray[i] = '*';
      }
      const obscuredsubName = nameArray.join('');

      return obscuredsubName;
    });
    const obscuredName = obscuredNames.join(' ');

    return obscuredName;
  });

  Vue.filter('regexUsername', (value: string) => {
    if (value) {
      value = value.replace(/[^0-9A-Za-z-._&'\`\‘\’\']/gi, '');
    } else {
      value = null;
    }
    return value;
  });

  Vue.filter('TRUNCATE', function (value, size, show) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size || show) {
      return value;
    }
    return value.substr(0, size) + '...';
  });

  Vue.filter('HtmlImageSize', (content: string) => {
    // find img in content and then add class img-content into element
    const img = content?.match(/<img[^>]*>/gi);
    if (img) {
      img.forEach(element => {
        content = content.replace(element, element.replace('<img', '<img class="img-content"'));
      });
    }
    return content;
  });

  Vue.filter('formatLanguageDate', (dataValue: string, language: any) => {
    if (dataValue) {
      const arrays = dataValue.split(' ');
      if (dataValue && dataValue.toUpperCase().includes('SECOND') && language === 'id') {
        return arrays[0] + ' ' + 'Detik lalu';
      } else if (dataValue && dataValue.toUpperCase().includes('MINUTE') && language === 'id') {
        return arrays[0] + ' ' + 'Menit lalu';
      } else if (dataValue && dataValue.toUpperCase().includes('HOUR') && language === 'id') {
        return arrays[0] + ' ' + 'Jam lalu';
      } else if (dataValue && dataValue.toUpperCase().includes('DAY') && language === 'id') {
        return arrays[0] + ' ' + 'Hari lalu';
      } else if (dataValue && dataValue.includes('WEEK') && language === 'id') {
        return arrays[0] + ' ' + 'Minggu lalu';
      } else if (dataValue && dataValue.includes('MONTH') && language === 'id') {
        return arrays[0] + ' ' + 'Bulan lalu';
      } else if (dataValue && dataValue.includes('YEAR') && language === 'id') {
        return arrays[0] + ' ' + 'Tahun lalu';
      } else {
        return dataValue;
      }
    } else {
      return '-';
    }
  });
}
