import { CmTktChannel } from '@/shared/model/enumerations/cm-tkt-channel.model';
import { CmAttachmentAuth } from './cm-attachment-auth.model';
export interface ICmVwSopInputedAgg {
  id?: number;
  tktExternalId?: string | null; // userid
  channel?: CmTktChannel | null;
  groupPawVerification?: string | null;
  pawCertReview?: string | null;
  pawReqConfirmation?: string | null;
  pawPortfolioReview?: string | null;
  pawVerification?: string | null;
  descPawReview?: string | null;
  groupFreelancerReview?: string | null;
  freelancerVerification?: string | null;
  freelancerSelfieIdCard?: string | null;
  freelancerReqConfirm?: string | null;
  freelancerDiplomaReview?: string | null;
  freelancerIdcardReview?: string | null;
  lastFreelancerReqConfirmDtl?: string | null;
  lastPawReqConfirmDtl?: string | null;
  lastFreelancerReqConfirmParent?: string | null;
  cmAttachmentAuths?: CmAttachmentAuth[] | null;
  docPawReview?: string | null;
  bankAccountIdCardReview?: string | null;
  bankAccountSelfieIdCardReview?: string | null;
  bankAccountReqConfirm?: string | null;
  lastBankAccountReqConfirmDtl?: string | null;
  lastBankAccountReqConfirmParent?: string | null;
  groupBankAccountReview?: string | null;
  freelancerPhotoProfileReview?: string | null;
}

export class CmVwSopInputedAgg implements ICmVwSopInputedAgg {
  constructor(
    public id?: number,
    public tktExternalId?: string | null,
    public channel?: CmTktChannel | null,
    public groupPawVerification?: string | null,
    public pawCertReview?: string | null,
    public pawReqConfirmation?: string | null,
    public pawPortfolioReview?: string | null,
    public pawVerification?: string | null,
    public descPawReview?: string | null,
    public groupFreelancerReview?: string | null,
    public freelancerVerification?: string | null,
    public freelancerSelfieIdCard?: string | null,
    public freelancerReqConfirm?: string | null,
    public freelancerDiplomaReview?: string | null,
    public freelancerIdcardReview?: string | null,
    public lastFreelancerReqConfirmDtl?: string | null,
    public lastPawReqConfirmDtl?: string | null,
    public lastFreelancerReqConfirmParent?: string | null,
    public cmAttachmentAuths?: CmAttachmentAuth[] | null,
    public docPawReview?: string | null,
    public bankAccountIdCardReview?: string | null,
    public bankAccountSelfieIdCardReview?: string | null,
    public bankAccountReqConfirm?: string | null,
    public lastBankAccountReqConfirmDtl?: string | null,
    public lastBankAccountReqConfirmParent?: string | null,
    public groupBankAccountReview?: string | null,
    public freelancerPhotoProfileReview?: string | null
  ) {}
}
