export enum CbNotifIconLabel {
  ACCOUNT_BALANCE = 'ACCOUNT_BALANCE',

  HIBERNATE = 'HIBERNATE',

  AGENT = 'AGENT',

  STORAGE = 'STORAGE',

  REQUEST = 'REQUEST',

  REQUEST_PROJECT = 'REQUEST_PROJECT',

  GENERAL_NOTE = 'GENERAL_NOTE',

  PAW = 'PAW',

  RE_UPLOAD = 'RE_UPLOAD',

  ACTIVE_ORDER = 'ACTIVE_ORDER',

  HEADS_UP = 'HEADS_UP',

  GIFT = 'GIFT',

  STORE = 'STORE',
}
