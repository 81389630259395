import { CbUserLvlType } from './enumerations/cb-user-lvl-type.model';
export interface ICbVwUserPartnerLevel {
  id?: number;
  userId?: string | null;
  userLvl?: CbUserLvlType | null;

  rookieTasks?: string | null;
  verifiedTasks?: string | null;
  proTasks?: string | null;
  extraordinaryTasks?: string | null;
  expertTasks?: string | null;
  lvlNeedUpdate?: boolean | null;
}

export class CbVwUserPartnerLevel implements ICbVwUserPartnerLevel {
  constructor(
    public id?: number,
    public userId?: string | null,
    public userLvl?: CbUserLvlType | null,
    public rookieTasks?: string | null,
    public verifiedTasks?: string | null,
    public proTasks?: string | null,
    public extraordinaryTasks?: string | null,
    public expertTasks?: string | null,
    public lvlNeedUpdate?: boolean | null
  ) {}
}
