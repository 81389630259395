export interface ICbPersonalizeInterests {
  id?: number;
  userId?: string | null;
  svcCategoryId?: number;
  svcCategoryName?: string | null;

  isActive?: boolean | null;
  createdDate?: Date | null;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
}

export class CbPersonalizeInterests implements ICbPersonalizeInterests {
  constructor(
    public id?: number,
    public userId?: string | null,
    public svcCategoryId?: number,
    public svcCategoryName?: string | null,

    public isActive?: boolean | null,
    public createdDate?: Date | null,
    public createdBy?: string | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null
  ) {}
}
