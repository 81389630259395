var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-order-pd-btn d-flex mx-min-2" }, [
    _vm.textBtnAdditional1 || !_vm.textConditionBtn1
      ? _c(
          "div",
          { staticClass: "px-2", class: _vm.textBtn2 ? "col-6" : "col-12" },
          [
            _c(
              "c-button",
              {
                staticClass:
                  "font-roboto font-weight-500 text-ellipsis-oneline",
                class: {
                  "font-size-16":
                    _vm.isDeviceSize === _vm.deviceSizeType.XL ||
                    _vm.isDeviceSize === _vm.deviceSizeType.LG ||
                    _vm.isDeviceSize === _vm.deviceSizeType.MD,
                  "font-size-14": _vm.isDeviceSize === _vm.deviceSizeType.SM,
                },
                attrs: {
                  fullWidth: "",
                  type: "button",
                  typeStyle: "secondary",
                  disabled: _vm.disabledBtn1,
                  "data-cy": _vm.dataCyCustom1,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("handleBtn1")
                  },
                },
              },
              [
                _vm.isLoadingBtn1
                  ? _c("font-awesome-icon", {
                      attrs: { icon: "sync", spin: _vm.isLoadingBtn1 },
                    })
                  : _c("span", [
                      _vm.textConditionBtn1
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAskRevision.askRevision"
                                )
                              ),
                            },
                          })
                        : _vm.textConditionBtn1 === false
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAskRevision.addExtraRevision"
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.textBtnAdditional1
                        ? _c("span", [_vm._v(_vm._s(_vm.textBtnAdditional1))])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.textBtn1
                        ? _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.$t(_vm.textBtn1)),
                            },
                          })
                        : _vm._e(),
                    ]),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.textBtn2
      ? _c(
          "div",
          { staticClass: "col-6 px-2" },
          [
            _c(
              "c-button",
              {
                staticClass:
                  "font-roboto font-weight-500 text-ellipsis-oneline",
                class: {
                  "font-size-16":
                    _vm.isDeviceSize === _vm.deviceSizeType.XL ||
                    _vm.isDeviceSize === _vm.deviceSizeType.LG ||
                    _vm.isDeviceSize === _vm.deviceSizeType.MD,
                  "font-size-14": _vm.isDeviceSize === _vm.deviceSizeType.SM,
                },
                attrs: {
                  fullWidth: "",
                  type: "button",
                  typeStyle: "primary",
                  disabled: _vm.disabledBtn2,
                  "data-cy": _vm.dataCyCustom2,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("handleBtn2")
                  },
                },
              },
              [
                _vm.isLoadingBtn2
                  ? _c("font-awesome-icon", {
                      attrs: { icon: "sync", spin: _vm.isLoadingBtn2 },
                    })
                  : _c("span", {
                      domProps: { textContent: _vm._s(_vm.$t(_vm.textBtn2)) },
                    }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }