import Vue from 'vue';
import { isAudioFile, isDocFile, isImageFile, isPdfFile, isPptFile, isVideoFile, isXlsFile } from '@/shared/media-file/media-file';

export function initFiltersLov() {
  Vue.filter('CONTENT_IMAGE', (cdnUrl, CONTENT_IMAGE, key) => {
    const lov = CONTENT_IMAGE ? CONTENT_IMAGE.find(l => l.key === key) : {};
    if (lov && lov.value && lov.value.includes('/')) {
      return cdnUrl + '/' + lov.value;
    } else if (lov && lov.value) {
      return cdnUrl + '/CbAttachmentNoAuth/' + lov.value;
    }
  });

  Vue.filter('ICON_LABEL_URL_BY_KEY', (ICON_LABEL_URL, key) => {
    const lov = ICON_LABEL_URL ? ICON_LABEL_URL.find(l => l.key === key) : {};
    if (lov && lov.value) {
      return lov.value;
    }
  });

  Vue.filter('DEVICE_WIDTH', (deviceWidthImg, cdnUrl, value) => {
    if (deviceWidthImg && cdnUrl && value) {
      return cdnUrl + '/CbAttachmentNoAuth/' + value + '?width=' + deviceWidthImg;
    }
  });
  Vue.filter('LAZY', (value: any, deviceWidth?: number, lazyDefault?: string) => {
    let lazyError = lazyDefault
      ? `${process.env.CDN_CB}/content/cb-img/${lazyDefault}`
      : `${process.env.CDN_CB}/content/cb-img/ic-lazy-default.png`;
    let item: string = lazyError;
    if (
      (typeof value === 'string' && value.includes(`${process.env.CDN_CB}/CbAttachmentNoAuth/null`)) ||
      (typeof value === 'string' && value.includes(`${process.env.CDN_CB}/CbAttachmentNoAuth/''`)) ||
      (typeof value === 'string' && value.includes(`${process.env.CDN_CB}/CbAttachmentNoAuth/undefined`)) ||
      !value
    ) {
      return item;
    }
    value ? (item = value) : item;
    return deviceWidth && typeof item === 'string' && item.includes(process.env.CDN_CB) ? `${item}?width=${deviceWidth}` : item;
  });

  Vue.filter('LAZY_ERROR', (lazyDefault?: string, isVideo?: boolean) => {
    let lazyError = lazyDefault
      ? `${process.env.CDN_CB}/content/cb-img/${lazyDefault}`
      : `${process.env.CDN_CB}/content/cb-img/ic-lazy-default.png`;
    return isVideo ? lazyError : `this.onerror=null; this.src='${lazyError}';`;
  });

  Vue.filter('PROCESS_IMAGE_PREVIEW', (localUrl: string, fileAtt?: number) => {
    let previewError: string;

    switch (true) {
      case isImageFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_picture.png`;
        break;
      case isVideoFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_video.png`;
        break;
      case isPdfFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_pdf.png`;
        break;
      case isAudioFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_audio.png`;
        break;
      case isDocFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_docx.png`;
        break;
      case isXlsFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_excel.png`;
        break;
      case isPptFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_ppt.png`;
        break;
      default:
        previewError = `${process.env.CDN_CB}/content/ic_file_other.png`;
        break;
    }

    let item: string = previewError;

    if (
      (typeof localUrl === 'string' && localUrl.includes(`${process.env.CDN_CB}/CbAttachmentNoAuth/null`)) ||
      (typeof localUrl === 'string' && localUrl.includes(`${process.env.CDN_CB}/CbAttachmentNoAuth/''`)) ||
      (typeof localUrl === 'string' && localUrl.includes(`${process.env.CDN_CB}/CbAttachmentNoAuth/undefined`)) ||
      !localUrl
    ) {
      return item;
    }

    if (localUrl) item = localUrl;
    else item;

    return item;
  });

  Vue.filter('PROCESS_IMAGE_PREVIEW_ERROR', (fileAtt: string, isVideo?: boolean) => {
    let previewError: string;

    switch (true) {
      case isImageFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_picture.png`;
        break;
      case isVideoFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_video.png`;
        break;
      case isPdfFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_pdf.png`;
        break;
      case isAudioFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_audio.png`;
        break;
      case isDocFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_docx.png`;
        break;
      case isXlsFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_excel.png`;
        break;
      case isPptFile(fileAtt):
        previewError = `${process.env.CDN_CB}/content/ic_file_ppt.png`;
        break;
      default:
        previewError = `${process.env.CDN_CB}/content/ic_file_other.png`;
        break;
    }

    return isVideo ? previewError : `this.onerror=null; this.src='${previewError}';`;
  });
}
