var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "vac-box-search": _vm.showSearchBar,
        "vac-box-empty": !_vm.showSearchBar,
      },
    },
    [
      !_vm.isShowSearch
        ? [
            _vm._t("room-bar-chat", function () {
              return [
                _c("room-bar-chat", {
                  on: {
                    "handle-show-search": function ($event) {
                      return _vm.$emit("handle-show-search")
                    },
                    "handle-show-setting-user": function ($event) {
                      return _vm.$emit("handle-show-setting-user")
                    },
                  },
                }),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showSearch && _vm.isShowSearch
        ? [
            _c(
              "div",
              {
                staticClass: "d-flex w-100",
                attrs: { id: "search-room-chat-wrap" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "p-3 my-auto cursor-pointer",
                    on: {
                      click: function ($event) {
                        _vm.$emit("search-room", { target: { value: "" } })
                        _vm.$emit("handle-show-search")
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass:
                        "cb-icon-size-arrow-left cj-color-black-primary",
                      attrs: { icon: "fa-solid fa-arrow-left" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "search-container my-auto w-100 mr-3" },
                  [
                    _c("icon", {
                      staticClass: "cj-stroke-red-primary mr-2 ic_search",
                      attrs: {
                        name: "ic_search",
                        width: "1.125rem",
                        height: "100%",
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      ref: "focusSearcList",
                      staticClass: "rounded-pill cj-field-input",
                      attrs: {
                        "data-cy": "search-room-chat",
                        type: "text",
                        placeholder: _vm.$t("chat.search"),
                        autocomplete: "off",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("search-room", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }