import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import { CbVwPawOrder } from './../../shared/model/cb-vw-paw-order.model';

import PPayService from '@/services/pay.service';

import CConfirmation from '../c-confirmation/c-confirmation.vue';
import CReview from '../review/review.vue';
import CSurvey from '../survey/survey.vue';
import Icon from '@/shared/icons/icon.vue';
import AccountService from '@/account/account.service';
import PCbAccountService from '@/services/cb-account.service';
import CCompletePaymentVa from '../c-complete-payment-va/c-complete-payment-va.vue';
import CFormPayment2023 from '../c-form-payment-2023/c-form-payment-2023.vue';

import { App } from '@/shared/model/enumerations/app.model';
import { IPayPayload2023, PayPayload2023 } from '@/shared/model/pay-payload-2023.model';
import { IPayDetailVA, PayDetailVA } from '@/shared/model/pay-detail-va.model';
import { PayMethodType } from '@/shared/model/enumerations/pay-method-type.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';

@Component({
  components: {
    CConfirmation,
    CFormPayment2023,
    CCompletePaymentVa,
    CReview,
    CSurvey,
    Icon,
  },
})
export default class CAppModals extends Vue {
  // ================= START SERVICES =================
  @Inject('accountService') private accountService: () => AccountService;
  @Inject('pCbAccountService') private pCbAccountService: () => PCbAccountService;
  @Inject('pPayService') private pPayService: () => PPayService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwPawOrder: CbVwPawOrder;

  public visibleReview = false;
  public visibleSurvey = false;
  public visibleConfirmFeedbackSuccess = false;
  public visibleHibernateConfirm = false;
  public visibleDeactiveConfirmSuccess = false;

  public visibleFormPayment2023 = false;
  public paymentPayload: IPayPayload2023 = new PayPayload2023();
  public visibleVA = false;
  public payDetailVA: IPayDetailVA = new PayDetailVA();


  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    (<any>this.$root).$off('appModals::changeFunction');
    (<any>this.$root).$on('appModals::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
    (<any>this.$root).$off('appModals::changeValue');
    (<any>this.$root).$on('appModals::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public closeAll() {
    this.visibleReview = false;
    this.visibleSurvey = false;
    this.visibleConfirmFeedbackSuccess = false;
    this.visibleHibernateConfirm = false;
    this.$store.commit('setVisibleModalDeactive', false);
    this.$store.commit('setVisibleModalIdpExist', false);
    this.$store.commit('visibleModalSuspend', false);
    this.visibleDeactiveConfirmSuccess = false;
    this.visibleFormPayment2023 = false;
    this.visibleVA = false;
  }

  toEditAvailability() {
    this.closeAll();
    this.$router.push({ name: 'PDashboard' });
  }
  toAskCommitee() {
    this.closeAll();
    this.$router.push({ name: 'PAskCommitee' });
  }


  logout(isLastCloseAll?:boolean) {
    if(!isLastCloseAll) {
      this.closeAll();
    }
    this.accountService().logout();
    if(isLastCloseAll) {
      this.closeAll();
    }
  }

  public reactiveAccount() {
    this.pCbAccountService()
      .raActiveAccountByLogin()
      .then(res => {
        this.closeAll();
        this.accountService().refreshAccount();
        this.visibleDeactiveConfirmSuccess = true;
      });
  }

  public checkPaymentVa(payPayload2023: PayPayload2023) {
    this.paymentPayload = Object.assign({}, payPayload2023);
    this.pPayService()
      .pay2023(this.paymentPayload)
      .then(res => {
        if (res?.reqType == 'VA_ACTIVE' && res?.detail) {
          this.showModalVA(res.detail);
        } else {
          this.visibleFormPayment2023 = true;
        }
      });
  }

  public showModalVA(payDetailVA: IPayDetailVA) {
    this.closeAll();
    this.payDetailVA = payDetailVA;
    this.visibleVA = true;
  }

  public handleTopup() {
    const paymentPayload: IPayPayload2023 = {
      title: this.$t('cbgwApp.accountBalance.topupBalance').toString(),
      payMethodType: PayMethodType.TOP_UP_DETAIL,
      app: App.CB_APP,
    };

    this.paymentPayload = Object.assign({}, paymentPayload);
    this.checkPaymentVa(paymentPayload);
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public get visibleDeactiveConfirm() {
    return this.$store.getters.visibleModalDeactive;
  }

  public get visibleModalIdpExist() {
    return this.$store.getters.visibleModalIdpExist;
  }

  public get emailIdp() {
    return this.$store.getters.emailIdp;
  }

  public get visibleModalSuspend() {
    return this.$store.getters.visibleModalSuspend;
  }

  public get cbVwUserStatus() {
    return this.$store.getters.cbVwUserStatus;
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
