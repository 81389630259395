import { SetPinType } from './enumerations/set-pin-type.model';

export interface ICbVerificationOtp {
  id?: number | null;
  dataKey?: string | null;
  email?: string | null;
  emailActivationKey?: string | null;
  pinActivationKey?: string | null;
  phone?: string | null;
  phoneActivationKey?: string | null;
  pinResetKey?: string | null;
  detail?: any;
  setPinType?: SetPinType | null;
  timeCountDown?: number | null;
}
export class CbVerificationOtp implements ICbVerificationOtp {
  constructor(
    public id?: number | null,
    public dataKey?: string | null,
    public email?: string | null,
    public emailActivationKey?: string | null,
    public pinActivationKey?: string | null,
    public phone?: string | null,
    public phoneActivationKey?: string | null,
    public pinResetKey?: string | null,
    public detail?: any,
    public setPinType?: SetPinType | null,
    public timeCountDown?: number | null
  ) {}
}
