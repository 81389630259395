import { ChtRoom, IChtRoom } from '@/shared/model/cht-room.model';
import { ChtConversationType } from '@/shared/model/enumerations/cht-conversation-type.model';
import { Inject, Component, Watch, Prop } from 'vue-property-decorator';
import PChtService from '@/services/cht.service';
import { IChtVwConversation } from '@/shared/model/cht-vw-conversation.model';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import locales from '@/module/chat-container/chat/locales';
import RoomContent from '@/module/chat-container/chat/lib/RoomsList/RoomContent/RoomContent.vue';
import Loader from '@/module/chat-container/chat/components/Loader/Loader.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { mixins } from 'vue-class-component';
import { formatTimestamp } from '@/module/chat-container/utils/dates';
import CLoader from '@/components/c-loader/c-loader.vue';
import PCbVwPawOrderRequirementActiveService from '@/services/cb-vw-paw-order-requirement-active.service';
import { ICbVwPawOrderRequirementActive } from '@/shared/model/cb-vw-paw-order-requirement-active.model';

@Component({
  components: {
    RoomContent,
    Loader,
    CLoader,
  },
})
export default class CDashboardChatList extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('pChtService') private pChtService: () => PChtService;
  @Inject('pCbVwPawOrderRequirementActiveService')
  private pCbVwPawOrderRequirementActiveService: () => PCbVwPawOrderRequirementActiveService;

  @Prop({ default: true }) public isShowFetchLoading: boolean;
  public cbVwPawOrderRquirementActives: ICbVwPawOrderRequirementActive[] = [];
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public pinnedIndex = 10000000000000;
  roomsPerPage = 10;
  public chtVwConversations: IChtVwConversation[] = [];
  public cbVwUsers: ICbVwUser[] = [];
  rooms: IChtRoom[] = [];

  public isFetching = false;
  public queryCount: number = null;
  public pageRoom = 1;
  public previousPage = 1;
  public propOrder = 'id';
  public reverse = false;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = null;

  roomsLoaded = false;
  roomsLoadedCount = 0;
  loadingRooms = true;

  private showLoader = true;
  private loadingMoreRooms = false;

  public currentLanguage = '';
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created(): void {
    (<any>this.$root).$off('onNotifConversationRefreshList');
    (<any>this.$root).$on('onNotifConversationRefreshList', () => {
      this.retrieveAllChtVwConversations();
    });
    this.retrieveAllChtVwConversations();
    this.retrieveCbVwPawOrderRquirementActiveByLogin();

    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
        this.rooms.forEach(r => {
          if (r.lastMessage.timestamp == 'Kemarin' || r.lastMessage.timestamp == 'Yesterday') {
            if (this.currentLanguage == 'id') {
              r.lastMessage.timestamp = formatTimestamp(new Date(r.lastMsgDate), new Date(r.lastMsgDate), 'Kemarin');
            } else {
              r.lastMessage.timestamp = formatTimestamp(new Date(r.lastMsgDate), new Date(r.lastMsgDate), 'Yesterday');
            }
          }
        });
      }
    );
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  retrieveAllChtVwConversations(): void {
    this.isFetching = true;
    const paginationQuery = {
      page: this.pageRoom - 1,
      size: this.roomsPerPage,
      sort: ['lastModifiedDate,desc'],
      moreFilter: 'isLiveChat.equals=false&conversationType.notEquals=' + ChtConversationType.CATEGORY + '&',
    };

    this.pChtService()
      .retrieveConversationByLogin(paginationQuery)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.chtVwConversations.push(res.data[i]);
          }
          if (res.headers && res.headers['link']) {
            this.links = this.parseLinks(res.headers['link']);
          }
        }
        await this.retrieveCbVwUser(this.chtVwConversations);

        this.totalItems = Number(res.headers['x-total-count']);
        this.queryCount = this.totalItems;

        const formattedRooms = [];

        [...this.chtVwConversations].forEach((chtVwConversation, idx) => {
          formattedRooms.push({ ...this.formatRoom(chtVwConversation) });
        });

        this.rooms = this.rooms.concat(formattedRooms.filter(e => e.id));
        //this.rooms=[{ "users": [ { "username": "a", "_id": "33mEBwitxQAXsfUbdFDC", "id": "33mEBwitxQAXsfUbdFDC" } ], "typingUsers": [], "lastUpdated": { "seconds": 1668252069, "nanoseconds": 247000000 }, "id": "YbNDBISFI1bCBQaSbTce", "roomName": "a", "roomId": "YbNDBISFI1bCBQaSbTce", "avatar": "http://localhost:9000/app/../9b596f63b94c587cfe48.png", "index": 1668252069, "lastMessage": { "content": "ss", "sender_id": "6R0MijpK6M4AIrwaaCY2", "timestamp": "12/11/2022", "id": "Dv3OZoVoXqC2d57RqQWt", "senderId": "6R0MijpK6M4AIrwaaCY2", "username": "", "distributed": true, "new": false, "lastMessage": { "senderId": "6R0MijpK6M4AIrwaaCY2" } } }];

        this.roomsLoaded = res.data.length === 0 || res.data.length < this.roomsPerPage;
        this.loadingRooms = false;
        this.roomsLoadedCount = this.rooms.length; //must declare
      })
      .catch(err => {
        this.loadingRooms = false;
        this.roomsLoadedCount = 0;
      })
      .finally(() => {
        this.isFetching = false;

        if (<any>this.$refs.infiniteLoading) {
          (<any>this.$refs.infiniteLoading).stateChanger.loaded();
          if (this.links && this.pageRoom > this.links['last']) {
            (<any>this.$refs.infiniteLoading).stateChanger.complete();
          }
        }
      });
  }

  async retrieveCbVwUser(chtVwConversations: IChtVwConversation[]): Promise<any> {
    let members = '';
    for (const item of chtVwConversations) {
      members = members + ' || ' + item.members;
    }
    const params: any = {
      members,
    };

    return new Promise((resolve, reject) => {
      this.pChtService()
        .retrieveVwUser(params)
        .then(res => {
          res.data.forEach(user => {
            if (this.cbVwUsers.find(ex => ex.id === user.id)) {
            } else {
              this.cbVwUsers.push(user);
            }
          });
          this.$store.commit('vwUsers', this.cbVwUsers);
          resolve(res);
        })
        .catch(err => {
          // this.isFetchingCbVwUser = false;
          console.log('ERROR RETRIEVE User', err);
          reject(err);
        });
    });
  }

  public formatRoom(chtVwConversation: IChtVwConversation): IChtRoom {
    const foundIndex = this.rooms.findIndex(e => e.id == chtVwConversation.id);
    if (foundIndex < 0) {
      const room: IChtRoom = new ChtRoom();
      room.id = chtVwConversation.id;
      const members = chtVwConversation.members.replace(/\s/g, '');
      const arrMembers = members.split('||');
      const foundOtherId = arrMembers.find(a => a != this.currentUser.id);
      const foundOtherUser = this.cbVwUsers.find(usr => usr.id === foundOtherId);
      room.roomName = foundOtherUser && foundOtherUser.username ? foundOtherUser.username : 'Group';
      room.users = [];
      let displayNameTmp;
      arrMembers.forEach(id => {
        displayNameTmp = this.cbVwUsers.find(user => id === user.id)?.displayName;
        room.users.push({ username: displayNameTmp, _id: id, id: id });
      });

      // room.users = [{ username: 'a', _id: '33mEBwitxQAXsfUbdFDC', id: '33mEBwitxQAXsfUbdFDC' }];
      //room.lastUpdated= { "seconds": 1668252069, "nanoseconds": 247000000 };
      room.typingUsers = [];

      const isExistAvatar = this.$options.filters.chatGetOtherUserPhotoId(chtVwConversation.members, this.currentUser.id, this.cbVwUsers);
      if (isExistAvatar && isExistAvatar != 0) {
        room.avatar = process.env.CDN_CB + '/CbAttachmentNoAuth/' + isExistAvatar;
      } else {
        room.avatar = `${process.env.CDN_CB}/content/cb-img/ic-lazy-avatar.png`;
      }

      room.distributed = true;
      room.senderId = chtVwConversation.lastModifiedBy;
      room.sender_id = chtVwConversation.lastModifiedBy;
      //const roomAvatar = roomContacts.length === 1 && roomContacts[0].avatar ? roomContacts[0].avatar : require('./assets/logo.png');
      room.members = chtVwConversation.members;

      // positionScrollMsgIds
      if (chtVwConversation.positionScrollMsgIds) {
        const found = chtVwConversation.positionScrollMsgIds.split('||').find(x => x.includes(this.currentUser.id));
        const positionScrollMsgId = Number(found?.split('::')[1] ?? 0);
        room.positionScrollMsgId = positionScrollMsgId;
      }

      room.roomId = chtVwConversation.id;
      if (chtVwConversation.countMsgs) {
        const found = chtVwConversation.countMsgs.split('||').find(x => x.includes(this.currentUser.id));
        const count = Number(found?.split('::')[1] ?? 0);
        room.unreadCount = count;
      }

      if (chtVwConversation.lastMsgDate != null)
        room.lastMessage = {
          distributed: true,
          content: chtVwConversation.lastMsg ? chtVwConversation.lastMsg : '',
          timestamp: formatTimestamp(
            new Date(chtVwConversation.lastMsgDate),
            new Date(chtVwConversation.lastMsgDate),
            this.$t('chat.yesterday')
          ),
          iconMsg: chtVwConversation.lastMsgFileType,
        };
      room.lastMsgDate = chtVwConversation.lastMsgDate;
      room.createdDate = chtVwConversation.createdDate;

      const pinnedDate = this.pinnedMapping(chtVwConversation);
      if (pinnedDate) {
        room.index = this.pinnedIndex + new Date(pinnedDate).getTime();
        room.isPinned = true;
      } else {
        room.index = chtVwConversation.lastMsgDate
          ? new Date(chtVwConversation.lastMsgDate).getTime()
          : new Date(chtVwConversation.createdDate).getTime(); //to move to top
        room.isPinned = false;
      }
      return room;
    }
  }

  private pinnedMapping(chtVwConversation: IChtVwConversation) {
    if (chtVwConversation.pinnedByMembers) {
      const found = chtVwConversation.pinnedByMembers.split('||').find(x => x.includes(this.currentUser.id));
      const pinned = found?.split('::')[1];
      if (pinned == 'true') {
        const pinnedDate = found?.split('::')[2];
        return pinnedDate;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public loadMore($state): void {
    if (!this.isFetching) {
      this.pageRoom++;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveAllChtVwConversations();
  }
  public openRoom(room: IChtRoom) {
    this.$store.commit('roomIdToStore', room.roomId.toString());
    this.$root.$emit('opc::changeValue', 'isShowDetailOrderLocation', false);
    this.$router.push({ name: 'PAppChatByRoomId', params: { roomId: room.roomId.toString() } });
    this.$root.$emit('navbar::changeValue', 'isShowMail', false);
  }

  public retrieveCbVwPawOrderRquirementActiveByLogin() {
    this.pCbVwPawOrderRequirementActiveService()
      .retrieveByLogin()
      .then(res => {
        if (res.data && res.data.length > 0) {
          for (let dataValue of res.data) {
            this.cbVwPawOrderRquirementActives.push(dataValue);
          }
          this.onListenRoomsAndCbVwPawOrderRquirementActives();
        }
      })
      .catch(err => {});
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  // computed= {
  public get t() {
    return {
      ...locales,
    };
  }
  public get userIdsOnline() {
    return this.$store.getters.userIdsOnline;
  }
  public get currentUserId(): string {
    return this.$store.getters.account?.id ?? '';
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('rooms', { deep: true })
  public roomsListener(newVal, oldVal) {
    this.rooms = newVal;
    console.log('cht===============================roomlist.loadingMoreRooms.stop');
    if (newVal.length !== oldVal.length || this.roomsLoaded) {
      this.loadingMoreRooms = false;
    }
  }
  @Watch('rooms')
  public onListenRoomsAndCbVwPawOrderRquirementActives() {
    if (this.chtVwConversations.length > 0 && this.cbVwPawOrderRquirementActives.length > 0) {
      this.rooms.forEach(room => {
        room.isHasOrderActive = false;
        room.users.forEach(user => {
          if (user.id != this.currentUserId) {
            const cbVwPawOrderRquirementActive = this.cbVwPawOrderRquirementActives.find(
              active => active.partnerId == user.id || active.buyerId == user.id
            );

            if (cbVwPawOrderRquirementActive) {
              room.isHasOrderActive = true;
            }
          }
        });
      });
    }
  }
  // ================= END LISTENERS ==================
}
