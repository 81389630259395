var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-pd-review-detail px-2" },
    [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c(
          "p",
          {
            staticClass:
              "mb-2 pr-2 flex-grow-1 font-roboto cj-color-black-primary font-weight-700",
            class: _vm.isMobile ? "font-size-14" : " font-size-16",
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("cbGlobal.overallRating")) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex mb-2" },
          [
            _c("b-icon-star-fill", {
              staticClass: "cj-color-status-warning mr-1 my-auto font-size-18",
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "my-auto font-roboto font-weight-500 font-size-18 cj-color-black-primary",
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.averageRatingReview ? _vm.averageRatingReview : 0
                    ) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.cbVwUserReviewRatings, function (cbVwUserReviewRating, index) {
        return _c(
          "div",
          { key: index, staticClass: "d-flex align-items-center mb-2" },
          [
            _c("div", { staticClass: "d-flex align-items-center mr-2" }, [
              _c(
                "div",
                { staticClass: "mr-1" },
                [
                  _c("b-icon-star-fill", {
                    staticClass: "cj-color-status-warning font-size-18 my-auto",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-black-primary font-weight-700 font-size-14",
                },
                [_vm._v(_vm._s(cbVwUserReviewRating.rating))]
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-progress",
              {
                staticClass: "w-100 rounded-pill my-auto cj-custom-progress",
                attrs: { max: _vm.queryCountUserReview },
              },
              [
                _c("b-progress-bar", {
                  attrs: { value: cbVwUserReviewRating.count },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "ml-2 text-right font-roboto cj-color-black-primary font-weight-400 font-size-12",
                staticStyle: { "min-width": "20px" },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(cbVwUserReviewRating.count) + "\n    "
                ),
              ]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c(
          "p",
          {
            staticClass:
              "mb-2 mr-2 font-roboto cj-color-black-primary font-weight-500 font-size-12",
            domProps: { textContent: _vm._s(_vm.$t("cbGlobal.sortBy")) },
          },
          [_vm._v("Sort By")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mb-2 d-flex wrap-border-grey-tertiary rounded-pill px-2 py-1 cursor-pointer",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex mr-1" },
              [
                _c("icon", {
                  staticClass: "cj-fill-black-primary",
                  attrs: {
                    name: "ic_sort_outline",
                    width: "18px",
                    height: "18px",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("cPdReviewSortBy"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._l(_vm.cbVwUserReviews, function (cbVwUserReview, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "d-flex mb-3 pb-4",
                staticStyle: { "border-bottom": "1px solid var(--color21)" },
              },
              [
                _c("c-user-photo-level", {
                  staticClass: "mr-2 cursor-pointer",
                  attrs: {
                    userPhoto: _vm.$options.filters.LAZY(
                      `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${cbVwUserReview.reviewerPhotoId}`,
                      40,
                      "ic-lazy-avatar.png"
                    ),
                    width: "2.5rem",
                    userLevel: cbVwUserReview.reviewerLevel,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-100" },
                  [
                    _c("div", { staticClass: "mb-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-items-center mb-1",
                          staticStyle: { "min-height": "40px" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "m-0 flex-grow-1 font-roboto font-weight-500 font-size-14 cj-color-black-primary",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(cbVwUserReview.reviewerName) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml-2" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "ml-auto mr-1" },
                                  [
                                    _c("b-icon-star-fill", {
                                      staticClass:
                                        "cj-color-status-warning font-size-14 my-auto",
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-roboto cj-color-black-primary font-weight-700 font-size-14",
                                  },
                                  [_vm._v(_vm._s(cbVwUserReview.rating))]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-right font-roboto font-weight-400 font-size-8 cj-color-black-primary",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("formatDateTimeDefault")(
                                        cbVwUserReview.createdDate
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "py-1 font-roboto font-weight-400 font-size-12 cj-color-black-primary",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(cbVwUserReview.comment) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("c-pd-review-detail-reply", {
                      attrs: {
                        cbVwUserReview: cbVwUserReview,
                        seletedReviewId: _vm.seletedReviewId,
                      },
                      on: { handleShowReview: _vm.handleShowReview },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._t("cPdReviewLoader"),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }