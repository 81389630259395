import { ICbSvcCatMetaDataAgg } from '@/shared/model/cb-svc-cat-meta-data-agg.model';
import { ICbPawMetaValueAgg } from '@/shared/model/cb-paw-meta-value-agg.model';

export interface ICbMetaData {
  id?: number;
  name?: string | null;
  titleHeader?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  seq?: number | null;
  isActive?: boolean;
  cbSvcCatMetaDataAggs?: ICbSvcCatMetaDataAgg[] | null;
  cbPawMetaValueAggs?: ICbPawMetaValueAgg[] | null;
}

export class CbMetaData implements ICbMetaData {
  constructor(
    public id?: number,
    public name?: string | null,
    public titleHeader?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public seq?: number | null,
    public isActive?: boolean,
    public cbSvcCatMetaDataAggs?: ICbSvcCatMetaDataAgg[] | null,
    public cbPawMetaValueAggs?: ICbPawMetaValueAgg[] | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
