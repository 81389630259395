import axios from 'axios';

import { ICbLog } from '@/shared/model/cb-log.model';

const baseApiUrl = 'services/cbcorems/api/cb-logs';

export default class CbLogService {
  public create(entity: ICbLog): Promise<ICbLog> {
    return new Promise<ICbLog>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
