var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "border-radius-card wrap-border-grey-tertiary p-2 mt-3",
      style: _vm.isMobile ? "width: 100%" : "width: 100%",
    },
    _vm._l(_vm.languages, function (value, key) {
      return _c(
        "div",
        {
          key: `lang-${key}`,
          staticClass: "cursor-pointer d-flex justify-content-between p-2",
          class: _vm.isActiveLanguage(key)
            ? "cj-bg-red-secondary"
            : "cj-bg-white-primary",
          attrs: { "data-cy": "language" },
          on: {
            click: function ($event) {
              return _vm.changeLanguage(key)
            },
          },
        },
        [
          _c(
            "p",
            {
              staticClass:
                "font-roboto cj-color-black-primary font-weight-400 my-auto",
              class: _vm.isMobile ? "font-size-16" : "font-size-20",
            },
            [_vm._v("\n      " + _vm._s(value.name) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.isActiveLanguage(key)
            ? _c("icon", {
                staticClass: "cj-fill-red-primary my-auto",
                attrs: { name: "ic_check", width: "30px", height: "30px" },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }