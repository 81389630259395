var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-navbar-dropdown-menu py-4",
      class: !_vm.isMobile ? "actived" : "",
      attrs: { "data-cy": "navbar-dropdown-menu" },
    },
    [
      _c("div", { staticClass: "px-4" }, [
        _c(
          "div",
          {
            staticClass:
              "border-radius-card cj-bg-red-primary p-3 mb-3 text-nowrap",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex w-100" },
              [
                _c("c-user-photo-online", {
                  staticClass: "mr-3 rounded-circle",
                  attrs: {
                    width: 2.625,
                    url: _vm.$options.filters.LAZY(
                      `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                        _vm.currentUser ? _vm.currentUser.photoId : ""
                      }`,
                      42,
                      "ic-lazy-avatar.png"
                    ),
                    tabindex: "0",
                    isOnline: null,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "d-block my-auto" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-weight-500 cj-color-white-primary font-size-12",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.userRole === _vm.cbLoginType.BUYER
                              ? "Buyer"
                              : "Freelancer"
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-weight-500 cj-color-white-primary font-size-14 text-wrap text-ellipsis oneline",
                      staticStyle: { width: "92px" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("sliceCharacter")(
                              _vm.currentUser && _vm.currentUser.displayName
                                ? _vm.currentUser.displayName
                                : "Guest",
                              16
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isMobile ? _c("c-user-balance") : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        [
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-dashboard-button",
              "data-cy": "button-to-dashboard",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.dashboard")),
            },
            on: {
              click: function ($event) {
                return _vm.toPage("dashboard")
              },
            },
          }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-profile-button",
              "data-cy": "button-to-profile",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.profile")),
            },
            on: {
              click: function ($event) {
                return _vm.toPage("profile")
              },
            },
          }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-manage-button",
              "data-cy": "button-to-manage-order",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.manageOrder")),
            },
            on: {
              click: function ($event) {
                return _vm.toPage("manageOrder")
              },
            },
          }),
          _vm._v(" "),
          _vm.userRole === _vm.cbLoginType.BUYER
            ? [
                _c("li", {
                  staticClass: "px-4",
                  attrs: {
                    "data-gtm": "go-to-manage-request-button",
                    "data-cy": "button-to-manage-auction",
                  },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbNavbar.manageRequest")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPage("manageRequest")
                    },
                  },
                }),
                _vm._v(" "),
                _c("li", {
                  staticClass: "px-4",
                  attrs: {
                    "data-gtm": "go-to-auction-button",
                    "data-cy": "button-to-auction",
                  },
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.postRequest")),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPage("postRequest")
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "px-4",
                    attrs: { "data-gtm": "go-to-paw-wishlist-button" },
                    on: {
                      click: function ($event) {
                        return _vm.toPage("pawWishlist")
                      },
                    },
                  },
                  [_vm._v("PAW Wishlist")]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.userRole === _vm.cbLoginType.PARTNER
            ? [
                _c("li", {
                  staticClass: "px-4",
                  attrs: {
                    "data-gtm": "go-to-manage-paw-button",
                    "data-cy": "button-to-manage-paw",
                  },
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.managePaw")),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPage("managePaw")
                    },
                  },
                }),
                _vm._v(" "),
                _c("li", {
                  staticClass: "px-4",
                  attrs: {
                    "data-gtm": "go-to-create-button",
                    "data-cy": "button-to-create-paw",
                  },
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.createPaw")),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPage("createPaw")
                    },
                  },
                }),
                _vm._v(" "),
                _c("li", {
                  staticClass: "px-4",
                  attrs: { "data-gtm": "go-to-find-project-button" },
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.findProject")),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPage("findProject")
                    },
                  },
                }),
                _vm._v(" "),
                _c("li", {
                  staticClass: "px-4",
                  attrs: { "data-gtm": "go-to-project-wishlist-button" },
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbNavbar.projectWishlist")
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toPage("projectWishlist")
                    },
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("hr", { staticClass: "my-2" }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-balance-button",
              "data-cy": "button-to-balance",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.accountBalance")),
            },
            on: {
              click: function ($event) {
                return _vm.toPage("balance")
              },
            },
          }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-billing-button",
              "data-cy": "button-to-billing-history",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.billingHistory")),
            },
            on: {
              click: function ($event) {
                return _vm.toPage("billing")
              },
            },
          }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-language-button",
              "data-cy": "button-to-language",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.language")),
            },
            on: { click: _vm.openModalLanguage },
          }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-help-center-button",
              "data-cy": "button-to-help-center-page",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.helpCenter")),
            },
            on: {
              click: function ($event) {
                return _vm.toPage("helpCenter")
              },
            },
          }),
          _vm._v(" "),
          _c("hr", { staticClass: "my-2" }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "go-to-setting-button",
              "data-cy": "button-to-setting",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.setting")),
            },
            on: {
              click: function ($event) {
                return _vm.toPage("setting")
              },
            },
          }),
          _vm._v(" "),
          _c("li", {
            staticClass: "px-4",
            attrs: {
              "data-gtm": "logout-button",
              "data-cy": "button-to-logout",
            },
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbNavbar.logout")),
            },
            on: { click: _vm.logout },
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }