export enum PayCommonStatus {
  COMPLETED = 'COMPLETED',
  DISPUTED = 'DISPUTED',
  CANCELLED = 'CANCELLED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REMAINING_AUCTION_GURANTEE = 'REMAINING_AUCTION_GURANTEE',
  CANCELLED_AUCTION = 'CANCELLED_AUCTION',
  CANCELLED_AUCTION_PENALTY = 'CANCELLED_AUCTION_PENALTY',
  ORDER_PAYMENT = 'ORDER_PAYMENT',
  WAITING = 'WAITING',
  REFUNDED = 'REFUNDED',
}
