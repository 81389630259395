var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "qrcode-stream-wrapper" }, [
    _c("video", {
      ref: "video",
      staticClass: "qrcode-stream-camera flipLeftToRight",
      class: { "qrcode-stream-camera--hidden": !_vm.shouldScan },
      attrs: { autoplay: "", muted: "", playsinline: "" },
      domProps: { muted: true },
    }),
    _vm._v(" "),
    _c("canvas", { ref: "pauseFrame", staticClass: "qrcode-stream-camera" }),
    _vm._v(" "),
    _c("canvas", {
      ref: "trackingLayer",
      staticClass: "qrcode-stream-overlay",
    }),
    _vm._v(" "),
    _c("div", { staticClass: "qrcode-stream-overlay" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }