import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';

@Component({
  components: { Icon },
})
export default class roomMessageDate extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ type: Object, required: true })
  private message;
  @Prop({ type: Array, required: true })
  private messages;
  @Prop({ type: Number, required: true })
  private index;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public get showDate() {
    return this.index > 0 && this.message.date !== this.messages[this.index - 1].date;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
