import { ICbVwUser } from './../../../../../shared/model/cb-vw-user.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { ICbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { CbPawOrderExtraType } from '@/shared/model/enumerations/cb-paw-order-extra-type.model';
import { ICbPawOrderExtra } from '@/shared/model/cb-paw-order-extra.model';
import { CbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import dayjs from 'dayjs';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import { IPayPayload2023 } from '@/shared/model/pay-payload-2023.model';
import { App } from '@/shared/model/enumerations/app.model';
import { PayMethodType } from '@/shared/model/enumerations/pay-method-type.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';

@Component({
  mixins: [Vue2Filters.mixin],
  components: {
    CButton,
    Icon,
    CTimeCountdown,
    CTextareaPreview,
  },
})
export default class CItemChatMessageRevisionOffer extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrderRequirement: CbVwPawOrderRequirement;
  @Prop({ default: null }) public cbTextRestrict: CbTextRestrict;
  public CbCommonStatus = CbCommonStatus;
  public isExpandRequirement: boolean = false;
  public isExpandExtra: boolean = false;
  public CbPawOrderExtraType = CbPawOrderExtraType;
  public cbPawOrderExtras: ICbPawOrderExtra[] = [];

  public offerExpiredDate: Date = new Date();
  public displayCountDown: string = '';
  public coundownInterval: any;

  public CbLoginType = CbLoginType;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.cbVwPawOrderRequirementListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public toModalPayment() {
    if (this.cbVwPawOrderRequirement?.amount > 0) {
      const payload: IPayPayload2023 = {
        title: 'Payment',
        subTitle: `Revision | ${this.cbVwPawOrderRequirement.pawTitle}`,
        orderId: this.cbVwPawOrderRequirement.id,
        payMethodType: PayMethodType.ORDER_PAW_DETAIL,
        app: App.CB_APP,
      };

      (<any>this.$root).$emit('opc::changeValue', 'cbVwPawOrder', { id: this.cbVwPawOrderRequirement.cbPawOrderId }); // ini buat pegangan kalo payment berhasil dia refresh
      (<any>this.$root).$emit('opc::changeFunction', 'checkPaymentVa', payload);
    } else {
      const partial: ICbPawOrderRequirement = {
        id: this.cbVwPawOrderRequirement.id,
        requirementType: <CbRequirementType>this.cbVwPawOrderRequirement.requirementType,
        status: CbCommonStatus.PAID,
        cbPawOrder: {
          id: this.cbVwPawOrderRequirement.cbPawOrderId,
        },
      };

      this.$root.$emit('opc::changeFunction', 'cbPawOrderRequirementPartialUpdate', partial);
    }
  }

  public toggleOfferExpired() {
    let partial: ICbPawOrderRequirement = {
      id: this.cbVwPawOrderRequirement.id,
      status: CbCommonStatus.CANCELLED,
    };
    (<any>this.$root).$emit('updatePartialPawOrderRequirement', partial);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }

  public get getStatusCountdown() {
    let pass: boolean = false;
    if (
      this.cbVwPawOrderRequirement &&
      dayjs(this.cbVwPawOrderRequirement.offerExpiredDate).toDate().getTime() > dayjs().toDate().getTime()
    ) {
      pass = true;
    }
    return pass;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('cbVwPawOrderRequirement', { deep: true })
  public cbVwPawOrderRequirementListener() {
    if (this.cbVwPawOrderRequirement?.id) {
      if (dayjs(this.cbVwPawOrderRequirement.offerExpiredDate).toDate().getTime() <= dayjs().toDate().getTime()) {
        this.toggleOfferExpired();
      }

      if (this.cbVwPawOrderRequirement.extraDetails) {
        // URGENT::45801::URGENT::30000.00||URGENT::45802::URGENT::30000.00||URGENT::45803::URGENT::30000.00
        // 0 type
        // 1 id
        // 2 value
        // 3 amount

        let splitExtra = this.cbVwPawOrderRequirement.extraDetails.split('||');

        if (splitExtra.length > 0) {
          for (const item of splitExtra) {
            let splitField = item.split('::');
            if (splitField.length > 0) {
              let findindex = this.cbPawOrderExtras.findIndex(e => e.value == splitField[2]);

              if (findindex < 0) {
                this.cbPawOrderExtras.push({
                  pawOrderExtraType: <CbPawOrderExtraType>splitField[0],
                  id: +splitField[1],
                  value: splitField[2],
                  amount: +splitField[3],
                });
              } else {
                this.cbPawOrderExtras[findindex] = {
                  pawOrderExtraType: <CbPawOrderExtraType>splitField[0],
                  id: +splitField[1],
                  value: splitField[2],
                  amount: +splitField[3],
                };
              }
            }
          }
        }
      }
    }
  }
  // ================= END LISTENERS ===================

  public openAllCollapse() {
    this.isExpandRequirement = true;
    this.isExpandExtra = true;
  }
}
