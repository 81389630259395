var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-room-star-messages",
      attrs: {
        id: "room-star-chat-message",
        "data-cy": "c-room-star-messages",
      },
    },
    [
      _c(
        "div",
        { staticClass: "header-container" },
        [
          _c(
            "div",
            {
              staticClass: "cursor-pointer p-3",
              attrs: { "data-cy": "c-rstar-header-back" },
              on: {
                click: function ($event) {
                  return _vm.callBack()
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "cb-icon-size-arrow-left cj-color-black-primary",
                attrs: { icon: "fa-solid fa-arrow-left" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-grow-1" }, [
            _c(
              "p",
              {
                staticClass: "m-0 font-roboto font-size-18 font-weight-700",
                domProps: {
                  textContent: _vm._s(_vm.$t("chat.starredMessage")),
                },
              },
              [_vm._v("Starred Messages")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "vac-room-options p-3 cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.menuOpened = !_vm.menuOpened
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "font-size-24 cj-color-red-primary",
                attrs: { icon: "fa-solid fa-ellipsis-vertical" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "vac-slide-left" } }, [
            _vm.menuOpened
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.closeMenu,
                        expression: "closeMenu",
                      },
                    ],
                    staticClass: "vac-menu-options",
                  },
                  [
                    _c("div", { staticClass: "vac-menu-list" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleActionStarDelete("STAR_DELETE")
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vac-menu-item",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("chat.deleteAllStars")
                                ),
                              },
                            },
                            [_vm._v("Delete All Stars")]
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "star-msg-wrapper" }, [
        !_vm.isFetching && _vm.messages.length === 0
          ? _c("div", { staticClass: "d-flex justify-content-center p-3" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-grey-primary font-size-12 font-weight-500",
                  domProps: {
                    textContent: _vm._s(_vm.$t("chat.noStarredMessageFound")),
                  },
                },
                [_vm._v("\n        No Star Messages Found\n      ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.messages.length > 0
          ? _c(
              "div",
              { staticClass: "p-3" },
              [
                _vm._l(_vm.messages, function (m, i) {
                  return _c(
                    "div",
                    {
                      key: m.indexId || m._id,
                      attrs: { id: "roomMessage" + m.id },
                      on: {
                        click: function ($event) {
                          return _vm.starMsgResultSelected(m)
                        },
                      },
                    },
                    [
                      m.stars && !m.deleted
                        ? _c(
                            "div",
                            { staticClass: "pb-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center info-title mb-2",
                                },
                                _vm._l(_vm.room.users, function (user) {
                                  return _c(
                                    "span",
                                    {
                                      key: user.id,
                                      attrs: {
                                        "data-cy": "info-title-username",
                                      },
                                    },
                                    [
                                      user.id === m.senderId
                                        ? [_vm._v(_vm._s(user.username))]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      user.id === !m.senderId
                                        ? [_vm._v(_vm._s(user.username))]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c("room-message", {
                                ref: "roomMessage" + m.id,
                                refInFor: true,
                                attrs: {
                                  id: "roomMessage" + m.id,
                                  "current-user-id": _vm.currentUserId,
                                  message: m,
                                  index: i,
                                  messages: _vm.messages,
                                  "username-options": _vm.usernameOptions,
                                  "message-actions": _vm.messageActions,
                                  "link-options": _vm.linkOptions,
                                  "text-formatting": _vm.textFormatting,
                                  "message-selection-enabled":
                                    _vm.messageSelectionEnabled,
                                  "show-new-messages-divider":
                                    _vm.showNewMessagesDivider,
                                  "show-reaction-emojis":
                                    _vm.showReactionEmojis,
                                  "text-messages": _vm.textMessages,
                                  "selected-messages": _vm.selectedMessages,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.totalItems > _vm.itemsPerPage
                  ? _c(
                      "infinite-loading",
                      {
                        ref: "infiniteLoading",
                        attrs: {
                          slot: "append",
                          identifier: _vm.infiniteId,
                          distance: 20,
                        },
                        on: { infinite: _vm.loadMore },
                        slot: "append",
                      },
                      [
                        _c("div", {
                          attrs: { slot: "no-more" },
                          slot: "no-more",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "spinner" }, slot: "spinner" },
                          [
                            _c("c-loader", {
                              staticClass: "d-flex m-auto",
                              attrs: { show: true, infinite: true },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }