export enum CbPkgCoreType {
  DESCRIPTION = 'DESCRIPTION',

  DELIVERY_TIME = 'DELIVERY_TIME',

  REVISION = 'REVISION',

  PRICE = 'PRICE',

  NONE = 'NONE',
  CUSTOM = 'CUSTOM',
}
