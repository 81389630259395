import axios from 'axios';

import { ICbVwUserData } from '@/shared/model/cb-vw-user-data.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-data';

export default class PCbVwUserDataService {
  public findByLogin(): Promise<ICbVwUserData> {
    return new Promise<ICbVwUserData>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
