export enum CbPayMethodType {
  CC = 'CC',

  DISBURSEMENT = 'DISBURSEMENT',

  EWALLET = 'EWALLET',

  QRCODE = 'QRCODE',

  VA = 'VA',

  CB_BALLANCE = 'CB_BALLANCE',

  CB_EXCHANGE_ITEMS = 'CB_EXCHANGE_ITEMS',

  BALANCE_REFUND = 'BALANCE_REFUND',
}
