import { Module } from 'vuex';

export const routerStore: Module<any, any> = {
  state: {
    fromPath: null,
    toPath: null
  },
  getters: {
    fromPath: state => state.fromPath,
    toPath: state => state.toPath,
  },
  mutations: {
    setFromPath(state, path) {
      state.fromPath = path;
    },
    setToPath(state, path) {
      state.toPath = path;
    }
  },
  actions: {
    updateFromPath({ commit }, path) {
      commit('setFromPath', path);
    },
    updateToPath({ commit }, path) {
      commit('setToPath', path);
    }
  },
};
