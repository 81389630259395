import dayjs from 'dayjs';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { Inject, Component, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CButton from '../button/c-button.vue';
import CContentBank from '../c-content-bank/c-content-bank.vue';
import CTimeCountdown from '../c-time-countdown/c-time-countdown.vue';
import CLoader from '../c-loader/c-loader.vue';

import { PayDetailVA } from '@/shared/model/pay-detail-va.model';
import PPayService from '@/services/pay.service';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { ICbVwPromoUserAgg } from '@/shared/model/cb-vw-promo-user-agg.model';
import { PayMethodType } from '@/shared/model/enumerations/pay-method-type.model';

@Component({
  components: {
    CButton,
    Icon,
    CContentBank,
    CTimeCountdown,
    CLoader,
  },
})
export default class CCompletePaymentVa extends Mixins(StatusTextEnId) {
  // ================= START SERVICES =================
  @Inject('pPayService') private pPayService: () => PPayService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: new PayDetailVA() }) payDetailVA: PayDetailVA;

  public coundownInterval: any;
  public displayTimeCountdown = '00:00:00';
  public canCopy = false;
  public isShowForBuyer = false;
  public expiredCountdown = null;
  public isFetchingTransaction = false;
  public isIncreaseBalance = false;

  public cbCommonStatus = CbCommonStatus;
  public currentStatus = CbCommonStatus.WAITING_FOR_PAYMENT;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  mounted() {
    this.canCopy = !!navigator.clipboard;
  }
  created() {
    this.payDetailVAListener();
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public async copy(s) {
    await navigator.clipboard.writeText(s);
  }
  @Emit('cancel')
  public cancel() {
    return;
  }

  public checkTransaction() {
    this.isFetchingTransaction = true;
    this.pPayService()
      .findPayVwWaitingForPaymentByLabelPaymentId(this.payDetailVA.externalId)
      .then(res => {
        if (res && res.labelExternalId != null) {
          this.currentStatus = res.transactionStatus;
        }

        if (res && res.payMethod == PayMethodType.TOP_UP && res.amountInCrease > 0 && !this.isIncreaseBalance) {
          // jika increas update topup di halaman terkait
          this.$root.$emit('accountBalance::getVwBalanceCore', payVwBalanceCore => {
            payVwBalanceCore.balance += res.amountInCrease;

            this.$root.$emit('accountBalance::changeValue', 'payVwBalanceCore', payVwBalanceCore);
          });

          this.$root.$emit('cUserBalance::getVwBalanceCore', payVwBalanceCore => {
            payVwBalanceCore.balance += res.amountInCrease;

            this.$root.$emit('cUserBalance::changeValue', 'payVwBalanceCore', payVwBalanceCore);
          });

          this.$emit('getVariable', 'orderDetail', (orderDetail: ICbVwPromoUserAgg) => {
            orderDetail.balanceAmount += res.amountInCrease;
            this.$emit('changeValue', 'orderDetail', orderDetail);
          });

          this.isIncreaseBalance = true;
        }
      })
      .finally(() => {
        this.isFetchingTransaction = false;
      });
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('payDetailVA')
  public payDetailVAListener() {
    if (this.payDetailVA) {
      if (dayjs(this.payDetailVA.expirationDate).toDate().getTime() < dayjs().toDate().getTime()) {
        this.expiredCountdown = CbCommonStatus.EXPIRED;
      }
    }
  }

  // ================= END LISTENERS ===================
}
