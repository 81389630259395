var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass: "c-item-chat-message-milestone p-2 cj-bg-white-primary",
          attrs: { "data-cy": "item-chat-message-milestone" },
        },
        [
          _c("div", { staticClass: "text-center mb-2" }, [
            _c(
              "p",
              {
                staticClass: "c-icm-font-card-title mb-1",
                attrs: { "data-cy": "message-text-milestone" },
              },
              [
                _vm._v(
                  "\n      Milestone " +
                    _vm._s(
                      _vm.cbVwPawOrderRequirement.progressProject
                        ? _vm.cbVwPawOrderRequirement.progressProject
                        : 0
                    ) +
                    "%\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                ref: "cbVwPawOrderRequirement.labelOrderId",
                staticClass:
                  "c-icm-font-card-subtitle mb-1 text-ellipsis oneline",
                attrs: { "data-cy": "message-heading-milestone" },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                    " | " +
                    _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                    "\n      "
                ),
                _c(
                  "b-tooltip",
                  {
                    staticClass: "custom-tooltip",
                    attrs: {
                      target: () =>
                        _vm.$refs["cbVwPawOrderRequirement.labelOrderId"],
                      variant: "primary",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-catamaran font-size-12 font-weight-500",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                            " | " +
                            _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 d-flex cj-border-color-red-primary",
            },
            [
              _c("img", {
                staticClass: "my-auto",
                staticStyle: { width: "4rem" },
                attrs: {
                  src: `${_vm.$env.CDN_CB}/content/cb-img/ic-file-plain-text.png`,
                  alt: "file",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "bVwPawOrderRequirement.title",
                  staticClass: "my-auto text-left",
                  staticStyle: { "word-break": "break-all" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "c-icm-font-title text-ellipsis oneline",
                      attrs: { "data-cy": "message-title-milestone" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.title) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "c-icm-font-content text-ellipsis oneline",
                      attrs: { "data-cy": "message-date-milestone" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("formatDateTimeDefault")(
                              _vm.cbVwPawOrderRequirement.createdDate
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "custom-tooltip",
                      attrs: {
                        target: () => _vm.$refs["bVwPawOrderRequirement.title"],
                        variant: "primary",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.cbVwPawOrderRequirement.title) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("formatDateTimeDefault")(
                                  _vm.cbVwPawOrderRequirement.createdDate
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass: "c-icm-font-btn",
              attrs: {
                "type-style": "secondary",
                fullWidth: "",
                "data-cy": "message-btn-view-milestone",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleOpenPreviewMilestone.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cMilestoneFile.view")
                  ),
                },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }