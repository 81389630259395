import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MapUtils extends Vue {
  getCurrentPosition(options?: any): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      const geolocation = navigator.geolocation;

      if (geolocation)
        geolocation.getCurrentPosition(
          (position: GeolocationPosition) => {
            resolve(<any>(<unknown>position));
          },
          reject,
          options
        );
    });
  }
}
