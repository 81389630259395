import { CbPawOrderExtra } from '@/shared/model/cb-paw-order-extra.model';
import { CbPromoMethod } from '@/shared/model/enumerations/cb-promo-method.model';
import { CbPromoValueType } from '@/shared/model/enumerations/cb-promo-value-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbPromoUsedStatus } from '@/shared/model/enumerations/cb-promo-used-status.model';
import { PayBalanceFreeze } from './pay-balance-freeze.model';
import { CbPromoPayMethod } from './enumerations/cb-promo-pay-method.model';
export interface ICbVwPromoUserAgg {
  id?: number;
  promoId?: number | null;
  title?: string | null;
  code?: string | null;
  promoMethod?: CbPromoMethod | null;
  promoValueType?: CbPromoValueType | null;
  promoValue?: number | null;
  currency?: CbCurrency | null;
  minAmount?: number | null;
  maxDiscountAmount?: number | null;
  maxUsedCount?: number | null;
  priodeStartDate?: Date | null;
  priodeEndDate?: Date | null;
  cbPromoSpecifics?: string | null;
  attId?: number | null;
  descriptionId?: number | null;
  status?: CbPromoUsedStatus | null;
  promoValueLabel?: string | null;
  minAmountLabel?: string | null;
  maxDiscountAmountLabel?: string | null;
  seq?: number | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  payPromoMethod?: CbPromoPayMethod | null;
  isValidWithThisOrder?: boolean | null; //transient
  promoAmount?: number | null; //transient
  insufficientAmount?: number | null; //transient
  orderAmount?: number | null; //transient
  rawAmount?: number | null; //transient
  isProjectGuarantee?: boolean | null; //transient
  payBalanceFreeze?: PayBalanceFreeze | null; //transient
  refundAmountPaid?: number | null; //transient
  totalAmountMustPaid?: number | null; //transient
  pgAmountPaid?: number | null; //transient
  pgAmountRemaining?: number | null; //transient
  isUserHasUsed?: boolean | null; //transient
  isUserHasClaimed?: boolean | null; //transient
  balanceAmountPaid?: number | null; //transient
  balanceAmount?: number | null; //transient
  refundAmount?: number | null; //transient
  cbPawOrderExtras?: CbPawOrderExtra[] | null; //transient
  feeAdmin?: number | null; //transient
  totalAmountAfterFeeAdmin?: number | null; //transient
  incomeAmount?: number | null; //transient
  limitAmount?: number | null; //transient
  isExceedUsage?: boolean | null; //transient
  isValidWithPayType?: boolean | null; //transient
  isValidMinimumTransaction?: boolean | null; //transient
  isValid?: boolean | null; //transient
  isSpecificPromoValid?: boolean | null; //transient
  attEnId?: number | null;
  descriptionEnId?: number | null;
  // promoStatus?:
}

export class CbVwPromoUserAgg implements ICbVwPromoUserAgg {
  constructor(
    public id?: number,
    public promoId?: number | null,
    public title?: string | null,
    public code?: string | null,
    public promoMethod?: CbPromoMethod | null,
    public promoValueType?: CbPromoValueType | null,
    public promoValue?: number | null,
    public currency?: CbCurrency | null,
    public minAmount?: number | null,
    public maxDiscountAmount?: number | null,
    public maxUsedCount?: number | null,
    public priodeStartDate?: Date | null,
    public priodeEndDate?: Date | null,
    public cbPromoSpecifics?: string | null,
    public attId?: number | null,
    public descriptionId?: number | null,
    public status?: CbPromoUsedStatus | null,
    public promoValueLabel?: string | null,
    public minAmountLabel?: string | null,
    public maxDiscountAmountLabel?: string | null,
    public seq?: number | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public payPromoMethod?: CbPromoPayMethod | null,
    public isValidWithThisOrder?: boolean | null, //transient
    public promoAmount?: number | null, //transient
    public rawAmount?: number | null, //transient
    public insufficientAmount?: number | null, //transient
    public orderAmount?: number | null, //transient
    public isProjectGuarantee?: boolean | null, //transient
    public payBalanceFreeze?: PayBalanceFreeze | null, //transient
    public refundAmountPaid?: number | null, //transient
    public totalAmountMustPaid?: number | null, //transient
    public pgAmountPaid?: number | null, //transient
    public pgAmountRemaining?: number | null, //transient
    public isUserHasClaimed?: boolean | null, //Transient
    public isUserHasUsed?: boolean | null, //transient
    public balanceAmountPaid?: number | null, //transient
    public balanceAmount?: number | null, //transient
    public refundAmount?: number | null, //transient
    public cbPawOrderExtras?: CbPawOrderExtra[] | null, //transient

    public feeAdmin?: number | null, //transient
    public totalAmountAfterFeeAdmin?: number | null, //transient
    public incomeAmount?: number | null, //transient
    public limitAmount?: number | null, //transient,
    public isExceedUsage?: boolean | null, //transient
    public isValidWithPayType?: boolean | null, //transient
    public isValidMinimumTransaction?: boolean | null, //transient
    public isValid?: boolean | null, //transient
    public isSpecificPromoValid?: boolean | null, //transient
    public attEnId?: number | null,
    public descriptionEnId?: number | null
  ) {
    // this.isActive = this.isActive ?? false;
    // this.isUserHasUsed = this.isUserHasUsed ?? true;
    // this.isUserHasClaimed = this.isUserHasClaimed ?? false;
  }
}
