var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-user-balance-topup" }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-items-center cursor-pointer",
        attrs: {
          "data-cy": "btn-topup-menu",
          "data-gtm": "btn-topup-menu-button",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("handleTopup")
          },
        },
      },
      [
        _c("font-awesome-icon", {
          staticClass: "font-size-top-up cj-color-red-primary",
          attrs: { icon: "circle-plus" },
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "ml-1 font-roboto font-weight-500 font-size-top-up cj-color-red-primary",
          },
          [_vm._v("Top Up")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }