<template>
  <div class="vac-room-container">
    <slot name="room-list-item" v-bind="{ room }">
      <slot name="room-list-avatar" v-bind="{ room }">
        <div
          v-if="room.avatar"
          class="vac-avatar position-relative"
          data-cy="vac-avatar"
          :style="{ 'background-image': `url('${room.avatar}')` }"
        >
          <div
            class="vac-status"
            :class="{ 'status-online': $options.filters.chatIsUserOnline(room.members, currentUserId, userIdsOnline) }"
          ></div>
        </div>
      </slot>
      <div class="vac-name-container vac-text-ellipsis">
        <div class="vac-title-container">
          <div
            class="vac-room-name vac-text-ellipsis cj-color-black-primary cj-color-black-primary font-roboto font-weight-700 font-size-16"
            data-cy="room-content-user-name"
          >
            {{ room.roomName }}
          </div>

          <div v-if="room.isPinned">
            <c-icons name="cic_pin" width="18px" height="18px" />
          </div>
          <div v-if="room.lastMessage" data-cy="chat-date" class="vac-text-date cj-color-grey-secondary font-roboto">
            {{ room.lastMessage.timestamp }}
          </div>
        </div>
        <div
          class="vac-text-last font-catamaran font-weight-300"
          data-cy="vac-text-snapshot"
          :class="{
            'vac-message-new': room.lastMessage && room.lastMessage.new && !typingUsers,
          }"
        >
          <span v-if="isMessageCheckmarkVisible">
            <slot name="checkmark-icon" v-bind="{ message: room.lastMessage }">
              <svg-icon
                :name="room.lastMessage.distributed ? 'double-checkmark' : 'checkmark'"
                :param="room.lastMessage.seen ? 'seen' : ''"
                class="vac-icon-check"
              />
            </slot>
          </span>

          <div v-if="room.lastMessage && !room.lastMessage.deleted && isAudio" class="vac-text-ellipsis">
            <slot name="microphone-icon">
              <svg-icon name="microphone" class="vac-icon-microphone" />
            </slot>
            {{ formattedDuration }}
          </div>
          <div class="vac-text-ellipsis" v-else-if="isLastIcon">
            <div class="d-flex align-items-center" v-if="lastFileType == 'IMAGE'">
              <icon
                name="ic_cht_image"
                width="0.81rem"
                class="icon-chat-cht_image d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary"
              />
              <span class="font-size-12 vac-text-ellipsis">Image</span>
            </div>
            <div class="d-flex align-items-center" v-else-if="lastFileType == 'VIDEO'">
              <icon
                name="ic_cht_video"
                width="0.81rem"
                class="icon-chat-cht_video d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary"
              />
              <span class="font-size-12 vac-text-ellipsis">Video</span>
            </div>
            <div class="d-flex align-items-center" v-else-if="lastFileType == 'DOCUMENT'">
              <icon
                name="ic_cht_document"
                width="0.81rem"
                class="icon-chat-cht_document d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary"
              />
              <span class="font-size-12 vac-text-ellipsis">Document</span>
            </div>
            <div class="d-flex align-items-center" v-else-if="lastFileType == 'STICKER'">
              <icon
                name="ic_cht_sticker"
                width="0.81rem"
                class="icon-chat-cht_sticker d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary"
              />
              <span class="font-size-12 vac-text-ellipsis">Sticker</span>
            </div>
            <div class="d-flex align-items-center" v-else-if="lastFileType == 'MAP'">
              <icon
                name="ic_cht_location"
                width="0.81rem"
                class="icon-chat-cht_location d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary"
              />
              <span class="font-size-12 vac-text-ellipsis">Location</span>
            </div>
            <div class="d-flex align-items-center" v-else-if="lastFileType == 'PAW'">
              <icon
                name="ic_cht_order_update"
                class="d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary"
                width="12px"
                height="12px"
              />
              <span class="font-size-12 vac-text-ellipsis" data-cy="vac-snapshot-order-updated">Order Update</span>
            </div>
          </div>
          <format-message
            v-else-if="room.lastMessage"
            :content="getLastMessage"
            :deleted="!!room.lastMessage.deleted && !typingUsers"
            :users="room.users"
            :linkify="false"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :single-line="true"
          >
            <template #deleted-icon="data">
              <slot name="deleted-icon" v-bind="data" />
            </template>
          </format-message>
          <div v-if="!room.lastMessage && typingUsers" class="vac-text-ellipsis">
            {{ typingUsers }}
          </div>
          <div class="vac-room-options-container">
            <div @click="actionFile" v-if="room.isHasOrderActive" class="d-flex align-items-center justify-content-center">
              <div
                ref="order-active"
                class="vac-badge-counter vac-room-badge size-count d-flex align-items-center justify-content-center"
                style="height: 20px; width: 20px"
              >
                <icon name="ic_clipboard" class="cj-fill-white-primary position-relative my-auto" width="14px" height="14px" />
              </div>
            </div>
            <b-tooltip class="rounded" :target="() => $refs['order-active']" variant="primary" v-if="room.isHasOrderActive">
              <p class="font-catamaran font-size-12 font-weight-500">This message has active order</p>
            </b-tooltip>
            <div
              v-if="room.unreadCount > 0"
              class="vac-badge-counter vac-room-badge yellow size-count"
              data-cy="vac-badge-counter"
              :class="room.unreadCount > 99 ? 'actived-max-count' : ''"
            >
              {{ room.unreadCount | maxCount(room.unreadCount) }}
            </div>
            <slot name="room-list-options" v-bind="{ room }">
              <div
                v-show="false"
                v-if="roomActions.length"
                class="vac-svg-button vac-list-room-options"
                @click.stop="roomMenuOpened = room.roomId"
              >
                <slot name="room-list-options-icon">
                  <svg-icon name="dropdown" param="room" />
                </slot>
              </div>
              <transition v-if="roomActions.length" name="vac-slide-left">
                <div v-if="roomMenuOpened === room.roomId" v-click-outside="closeRoomMenu" class="vac-menu-options">
                  <div class="vac-menu-list">
                    <div v-for="action in roomActions" :key="action.name">
                      <div class="vac-menu-item" @click.stop="roomActionHandler(action)">
                        {{ action.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </slot>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import FormatMessage from '../../../components/FormatMessage/FormatMessage';

import typingText from '../../../utils/typing-text';
const { isAudioFile } = require('../../../utils/media-file');
import CIcons from '../../../components/c-icons/c-icons.vue';
import Icon from '@/shared/icons/icon.vue';

export default {
  name: 'RoomsContent',
  components: {
    SvgIcon,
    FormatMessage,
    CIcons,
    Icon,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    room: { type: Object, required: true },
    textFormatting: { type: Object, required: true },
    linkOptions: { type: Object, required: true },
    textMessages: { type: Object, required: true },
    roomActions: { type: Array, required: true },
    userIdsOnline: { type: Array, required: true },
  },

  emits: ['room-action-handler'],

  data() {
    return {
      roomMenuOpened: null,
      lastFileType: '',
    };
  },

  computed: {
    getLastMessage() {
      const isTyping = this.typingUsers;
      if (isTyping) return isTyping;

      const content = this.room.lastMessage.deleted ? this.textMessages.MESSAGE_DELETED : this.room.lastMessage.content;

      if (this.room.users.length <= 2) {
        return content;
      }

      const user = this.room.users.find(user => user._id === this.room.lastMessage.senderId);

      if (this.room.lastMessage.username) {
        return `${this.room.lastMessage.username} - ${content}`;
      } else if (!user || user._id === this.currentUserId) {
        return content;
      }

      return `${user.username} - ${content}`;
    },
    userStatus() {
      if (!this.room.users || this.room.users.length !== 2) return;

      const user = this.room.users.find(u => u._id !== this.currentUserId);
      if (user?.status) return user.status.state;

      return null;
    },
    typingUsers() {
      return typingText(this.room, this.currentUserId, this.textMessages);
    },
    isMessageCheckmarkVisible() {
      return (
        !this.typingUsers &&
        this.room.lastMessage &&
        !this.room.lastMessage.deleted &&
        this.room.lastMessage.senderId === this.currentUserId &&
        (this.room.lastMessage.saved || this.room.lastMessage.distributed || this.room.lastMessage.seen)
      );
    },
    formattedDuration() {
      const file = this.room.lastMessage?.files?.[0];

      if (file) {
        if (!file.duration) {
          return `${file.name}.${file.extension}`;
        }

        let s = Math.floor(file.duration);
        return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s;
      }

      return '';
    },
    isAudio() {
      return this.room.lastMessage.files ? isAudioFile(this.room.lastMessage.files[0]) : false;
    },
    isLastIcon() {
      const lastMsgFile = this.room.lastMessage && this.room.lastMessage.iconMsg;

      if (lastMsgFile) {
        if (lastMsgFile.includes('image')) {
          return (this.lastFileType = 'IMAGE');
        } else if (lastMsgFile.includes('video')) {
          return (this.lastFileType = 'VIDEO');
        } else if (lastMsgFile.includes('application')) {
          return (this.lastFileType = 'DOCUMENT');
        } else if (lastMsgFile == 'sticker') {
          return (this.lastFileType = 'STICKER');
        } else if (lastMsgFile == 'map') {
          return (this.lastFileType = 'MAP');
        } else if (lastMsgFile == 'paw') {
          return (this.lastFileType = 'PAW');
        }
      }
    },
  },

  methods: {
    roomActionHandler(action) {
      this.closeRoomMenu();
      this.$emit('room-action-handler', { action, roomId: this.room.roomId });
    },
    closeRoomMenu() {
      this.roomMenuOpened = null;
    },
    actionFile() {},
  },
};
</script>
