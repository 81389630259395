import axios from 'axios';

import { ICbUserInfo } from '@/shared/model/cb-user-info.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-infos';

export default class PCbUserInfoService {
  public findUsernameExist(username: string, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/is-username-exist/${username}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public updateRegisterFreelancer(entity: ICbUserInfo, callBack: Function, callbackReject: Function): Promise<ICbUserInfo> {
    let blob = null;
    if (entity.photo && entity.photo.att) {
      entity.photo.att = '';
      entity.isUpdatePhotoProfile = true;
      blob = entity.photo.blob;
      delete entity.photo.blob;
    }
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/register-freelancer/current`, entity)
        .then(res => {
          const result: ICbUserInfo = res.data;
          if (result.uploadLinkPhotoProfile && entity.photo && blob) {
            resolve(this.upload(result, result.uploadLinkPhotoProfile, entity.photo.attContentType, blob, callBack, callbackReject));
          } else {
            resolve(res.data);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdateByLogin(entity: ICbUserInfo): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveIsUsername(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/is-set-username')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdateWithAttByLogin(entity: ICbUserInfo, callBack: Function, callbackReject: Function): Promise<ICbUserInfo> {
    let blob = null;
    if (entity.banner && entity.banner.att && entity.banner.blob) {
      entity.banner.att = '';
      blob = entity.banner.blob;
      entity.isUpdatePhotoBanner = true;
      delete entity.banner.blob;
    } else if (entity.photo && entity.photo.att && entity.photo.blob) {
      entity.photo.att = '';
      entity.isUpdatePhotoProfile = true;
      blob = entity.photo.blob;
      delete entity.photo.blob;
    }

    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/by-login`, entity)
        .then(res => {
          const result: ICbUserInfo = res.data;

          if (result.uploadLinkBanner && entity.banner) {
            resolve(this.upload(result, result.uploadLinkBanner, entity.banner.attContentType, blob, callBack, callbackReject));
          } else if (result.uploadLinkPhotoProfile && entity.photo) {
            resolve(this.upload(result, result.uploadLinkPhotoProfile, entity.photo.attContentType, blob, callBack, callbackReject));
          } else {
            resolve(result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findByLogin(): Promise<ICbUserInfo> {
    return new Promise<ICbUserInfo>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public upload(
    result: ICbUserInfo,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        console.log('upload progress: ' + progress);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(signUrlRes => {
          resolve(result);
        })
        .catch(signUrlErr => {
          reject(callbackReject());
        });
    });
  }
}
