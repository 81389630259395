var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-autocomplete-new cj-field-autocomplete",
      class: { "position-relative": !_vm.isNoRelative, disabled: _vm.disabled },
      attrs: {
        "data-cy": _vm.dataCyCustom
          ? `wrap-${_vm.dataCyCustom}`
          : "wrap-autocomplete-new",
      },
    },
    [
      _c(
        "form",
        {
          staticClass: "d-flex w-100 my-auto input-style-focus",
          class: _vm.inputStyle ? _vm.inputStyle : "px-3",
          attrs: { autocomplete: "off" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.localValue,
                expression: "localValue",
              },
            ],
            ref: "inputAutocomplete",
            staticClass: "cj-field-autocomplete-input my-auto px-0",
            attrs: {
              placeholder: _vm.placeholder
                ? _vm.placeholder
                : _vm.$t("cbGlobal.typeHere"),
              readonly: _vm.disabled || _vm.disableTyping,
              type: "text",
              "data-gtm": _vm.dataGTMCustom
                ? `${_vm.dataGTMCustom}-input`
                : "autocomplete-input",
              "data-cy": _vm.dataCyCustom
                ? `${_vm.dataCyCustom}-input`
                : "autocomplete-input",
              autocomplete: "new-password",
            },
            domProps: { value: _vm.localValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.localValue = $event.target.value
                },
                _vm.handleSearch,
              ],
              blur: function ($event) {
                if (!_vm.isShowListItem) {
                  _vm.handleSearchBlur()
                  _vm.clickOutside()
                }
              },
              focus: function ($event) {
                return _vm.handleToggleDropdown()
              },
              click: function ($event) {
                return _vm.handleToggleDropdown()
              },
              keydown: _vm.handleKey,
            },
          }),
          _vm._v(" "),
          _vm.localValue && !_vm.disabled
            ? _c(
                "span",
                {
                  staticClass: "my-auto cj-field-autocomplete-position-icon",
                  attrs: {
                    id: "clear-autocomplete",
                    "data-cy": _vm.dataCyCustom
                      ? `clear-${_vm.dataCyCustom}`
                      : "clear-autocomplete",
                  },
                  on: { click: _vm.handleClear },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "cj-field-autocomplete-icon-x-circle",
                    attrs: {
                      "data-gtm": _vm.dataGTMCustom
                        ? `${_vm.dataGTMCustom}-clear-input-button`
                        : "autocomplete-clear-input-button",
                    },
                  }),
                ],
                1
              )
            : _c(
                "span",
                {
                  staticClass:
                    "my-auto d-flex cj-field-autocomplete-position-icon",
                  on: {
                    click: function ($event) {
                      return _vm.openDropdown()
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass:
                      "my-auto cj-field-autocomplete-icon-down ic_arrow_dropdown",
                    attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                  }),
                ],
                1
              ),
        ]
      ),
      _vm._v(" "),
      (_vm.isShowListItem && _vm.itemsSearch.length > 0) ||
      (_vm.toggleDropdown && !_vm.disabled && _vm.itemsSearch.length > 0)
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "cj-field-autocomplete-list mt-2",
                class: {
                  "position-relative": _vm.isShowDropdown,
                  "position-absolute": !_vm.isShowDropdown,
                },
                on: {
                  mousedown: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              _vm._l(_vm.itemsSearch, function (itemSearch, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "cj-field-autocomplete-item",
                    class: [
                      {
                        "cj-field-autocomplete-item-scroll-actived":
                          _vm.selectedIndex === index,
                        "mt-2": index > 0,
                      },
                    ],
                    attrs: {
                      "data-cy": _vm.dataCyCustom
                        ? `${_vm.dataCyCustom}-output`
                        : "autocomplete-output",
                      "data-gtm": `autocomplete-${itemSearch.itemText}-output-clickable`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSelect(itemSearch)
                      },
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-ellipsis-oneline cj-color-black-primary",
                        attrs: {
                          "data-cy": _vm.dataCyCustom
                            ? `${_vm.dataCyCustom}-output-text`
                            : "autocomplete-output-text",
                          title: _vm.itemText
                            ? itemSearch[_vm.itemText]
                            : itemSearch,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.itemText
                                ? itemSearch[_vm.itemText]
                                : itemSearch
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }