export enum CbPromoTagType {
  SERVICE_ALL = 'SERVICE_ALL',

  SERVICE_SPECIFIC = 'SERVICE_SPECIFIC',

  SERVICE_INSIDE = 'SERVICE_INSIDE',

  SERVICE_FIELD = 'SERVICE_FIELD',

  CATEGORY_ALL = 'CATEGORY_ALL',

  CATEGORY_SPECIFIC = 'CATEGORY_SPECIFIC',

  CATEGORY_INSIDE = 'CATEGORY_INSIDE',

  CATEGORY_FIELD = 'CATEGORY_FIELD',
}
