import { ICbLocProvince } from '@/shared/model/cb-loc-province.model';

export interface ICbLocation {
  id?: number;
  country?: string | null;
  state?: string | null;
  province?: string | null;
  city?: string | null;
  subCity?: string | null;
  zipCode?: string | null;
  address?: string | null;
  addressPinPoint?: string | null;
  latGoogle?: string | null;
  longGoogle?: string | null;
  placeId?: string | null;
  placeName?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  cbLocProvince?: ICbLocProvince | null;
  snapshot?: string | null;
}

export class CbLocation implements ICbLocation {
  constructor(
    public id?: number,
    public country?: string | null,
    public state?: string | null,
    public province?: string | null,
    public city?: string | null,
    public subCity?: string | null,
    public zipCode?: string | null,
    public address?: string | null,
    public addressPinPoint?: string | null,
    public latGoogle?: string | null,
    public longGoogle?: string | null,
    public placeId?: string | null,
    public placeName?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public cbLocProvince?: ICbLocProvince | null,
    public snapshot?: string | null
  ) {}
}
