import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = 'services/cbcorems/api/cb-vw-paw-populars';

export default class PCbVwPawPopularService {
  public retrievePaginationQuery(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiUrl +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
