import { CbUserTimezone, ICbUserTimezone } from '@/shared/model/cb-user-timezone.model';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import CItemTimeRegion from '@/components/c-item-time-region/c-item-time-region.vue';
import Icon from '@/shared/icons/icon.vue';
import { DATE_REGION_LONG_FORMAT, DATE_REGION_SHORT_FORMAT, TIME_24_FORMAT } from '@/shared/date/filters';

@Component({
  components: { CItemTimeRegion, Icon },
})
export default class CItemTimeRegionContainer extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public parentCbUserTimeZone: CbUserTimezone;
  @Prop({ default: null }) public index: number;

  public cbUserTimeZone: ICbUserTimezone = new CbUserTimezone();
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.cbUserTimezonesListener();
    setInterval(this.cbUserTimezonesListener, 1000);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('parentCbUserTimeZone')
  public cbUserTimezonesListener() {
    if (this.parentCbUserTimeZone) {
      this.cbUserTimeZone = Object.assign({}, this.cbUserTimeZone, this.parentCbUserTimeZone);
      const currentDate = dayjs().tz(this.cbUserTimeZone.timeZoneCode);
      this.cbUserTimeZone.timeFormat = currentDate.format(TIME_24_FORMAT);
      this.cbUserTimeZone.dateFormat = currentDate.format(DATE_REGION_SHORT_FORMAT);
      let dateTime = dayjs(`${this.cbUserTimeZone.dateFormat} ${this.cbUserTimeZone.timeFormat}`, DATE_REGION_LONG_FORMAT);
      dateTime = dateTime.tz(this.cbUserTimeZone.timeZoneCode);
      const formattedDateTime = dateTime.toDate();
      this.cbUserTimeZone.userZoneDateTime = formattedDateTime;
    }
  }
  // ================= END LISTENERS ==================
}
