var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-personalize-y-Interests" }, [
    _c(
      "div",
      {
        staticClass: "cb-position-sticky-top",
        class: _vm.isMobile ? "border-grey-tertiary-bottom-1" : "pb-1",
      },
      [
        _c(
          "p",
          {
            staticClass:
              "text-lg-center text-left font-roboto cj-color-black-primary",
            class: _vm.isMobile
              ? "font-size-18 font-weight-500"
              : "font-size-24 font-weight-700",
            attrs: { "data-cy": "personalize-interest" },
          },
          [_vm._v("\n      Personalize Your Interests\n    ")]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass:
          "m-0 text-lg-center text-left font-weight-400 font-catamaran cj-color-fav-top",
        class: _vm.isMobile
          ? "font-size-14 px-0 pb-0 pt-3"
          : "font-size-18 p-0",
      },
      [_vm._v("\n    Pick 5 (or more!) of your favorite topics\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex flex-wrap py-2 mx-min-1",
        attrs: { "data-cy": "personalize-wrapper" },
      },
      _vm._l(_vm.getCbVwSvcCatSorts, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "col-4 col-sm-3 p-1 p-sm-2",
            attrs: {
              "data-gtm": `item-personalize-interest-${item.name}-clickable`,
            },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.seletedCbVwSvcCats,
                  expression: "seletedCbVwSvcCats",
                },
              ],
              staticClass: "d-none item-personalize-interest-wrapper",
              attrs: {
                type: "checkbox",
                "data-cy": "personalize-item",
                id: `item-personalize-interest${index + 1}`,
              },
              domProps: {
                value: item,
                checked: Array.isArray(_vm.seletedCbVwSvcCats)
                  ? _vm._i(_vm.seletedCbVwSvcCats, item) > -1
                  : _vm.seletedCbVwSvcCats,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.seletedCbVwSvcCats,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = item,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.seletedCbVwSvcCats = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.seletedCbVwSvcCats = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.seletedCbVwSvcCats = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "h-100 w-100",
                attrs: {
                  for: `item-personalize-interest${index + 1}`,
                  "data-cy": "personalize-item-title",
                },
              },
              [
                _c("c-item-svc-cat", {
                  staticClass: "item-personalize-interest",
                  attrs: {
                    cbSvcCat: item,
                    "data-cy": "list-item-personalize-interest",
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.isMobile
          ? "cb-position-sticky-bottom border-grey-tertiary-top-1"
          : "cb-max-w20 ml-auto",
      },
      [
        _c(
          "c-button",
          {
            staticClass: "font-roboto font-weight-500",
            class: _vm.isMobile ? "font-size-18" : "font-size-20",
            attrs: {
              dataCyCustom: "continue-interest",
              fullWidth: "",
              disabled:
                _vm.isSaving ||
                (_vm.seletedCbVwSvcCats && _vm.seletedCbVwSvcCats.length < 5),
            },
            nativeOn: {
              click: function ($event) {
                return _vm.save()
              },
            },
          },
          [_vm._v("\n      CONTINUE\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }