import Icon from '@/shared/icons/icon.vue';
import { ProgressUpload } from '@/shared/model/progress-upload';
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({ components: { Icon } })
export default class CProgressUpload extends Vue {
  // ================= START SERVICES =================
  @Prop({ type: Array, default: [] })
  public progressUploads: ProgressUpload[];

  public isShowProgressUpload = false;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public closeProgressUploadMini() {
    this.$emit('closeProgressUploadMini');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ===================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
