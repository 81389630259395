import { Inject, Component, Vue, Emit, Prop } from 'vue-property-decorator';

import PCbVwUserPartnerLevelService from '@/services/cb-vw-user-partner-level.service';
import AlertService from '@/shared/alert/alert.service';

import CUserPhotoLevel from '@/components/c-user-photo-level/c-user-photo-level.vue';
import CButton from '@/components/button/c-button.vue';
import CTextareaNew from '../c-textarea-new/c-textarea-new.vue';

import { CbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { CbUserReview, ICbUserReview } from '@/shared/model/cb-user-review.model';
import { CbVwUserPartnerLevel, ICbVwUserPartnerLevel } from '@/shared/model/cb-vw-user-partner-level.model';
import { ReviewType } from '@/shared/model/enumerations/review-type.model';
import { ReviewStatus } from '@/shared/model/enumerations/review-status.model';
import { SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL } from '@/shared/constant/constants-session-storage';

@Component({
  components: {
    CUserPhotoLevel,
    CButton,
    CTextareaNew,
  },
})
export default class CReview extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbVwUserPartnerLevelService') private pCbVwUserPartnerLevelService: () => PCbVwUserPartnerLevelService;
  @Inject('alertService') private alertService: () => AlertService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwPawOrder: CbVwPawOrder;

  public message = '';
  public hasError = false;
  public cbUserReview: ICbUserReview = new CbUserReview();
  public isReviewFreelancer = false;
  public isReviewBuyer = true;

  public valueItemHoneyStick = 0;
  public valueItemHoneyJar = 0;
  public valueItemHoneyComb = 0;
  public valueItemSalmonFish = 0;
  public starts = 0;

  public cbVwUserPartnerLevel: ICbVwUserPartnerLevel = new CbVwUserPartnerLevel();
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    if (this.cbVwPawOrder?.partnerId) {
      this.findCbVwUserPartnerLevel();
    }
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }

  @Emit('save')
  public handleSubmit() {
    if (this.cbVwPawOrder.orderType == CbOrderType.PAW) {
      this.cbUserReview.reviewType = ReviewType.PAW;
    } else if (this.cbVwPawOrder.orderType == CbOrderType.AUCTION_PROJECT) {
      this.cbUserReview.reviewType = ReviewType.AUCTION;
    }

    this.cbUserReview.status = ReviewStatus.ACTIVE;
    this.cbUserReview.partnerId = this.cbVwPawOrder.partnerId;
    this.cbUserReview.externalId = this.cbVwPawOrder.pawIdHistory.toString();
    this.cbUserReview.externalOrderId = this.cbVwPawOrder.id.toString();

    return this.cbUserReview;
  }

  public findCbVwUserPartnerLevel() {
    const tmpPartnerLevel: ICbVwUserPartnerLevel = this.sessionCbVwUserPartnerLevel().find(e => e.userId == this.cbVwPawOrder.partnerId);
    if (tmpPartnerLevel) {
      this.cbVwUserPartnerLevel = Object.assign({}, tmpPartnerLevel);
      return;
    }

    const params: any = {
      'userId.equals': this.cbVwPawOrder.partnerId,
    };
    this.pCbVwUserPartnerLevelService()
      .retrieve(params)
      .then(res => {
        if (res && res.data && res.data.length == 1) {
          this.cbVwUserPartnerLevel = Object.assign({}, res.data[0]);

          if (this.cbVwUserPartnerLevel) {
            const tmpCbVwUserPartnerLevels: ICbVwUserPartnerLevel[] = [...this.sessionCbVwUserPartnerLevel()];
            const foundLevel = tmpCbVwUserPartnerLevels.findIndex(e => e.userId == this.cbVwPawOrder.partnerId);
            if (foundLevel < 0) {
              tmpCbVwUserPartnerLevels.push(this.cbVwUserPartnerLevel);
            } else {
              Vue.set(tmpCbVwUserPartnerLevels, foundLevel, this.cbVwUserPartnerLevel);
            }
            sessionStorage.setItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL, JSON.stringify(tmpCbVwUserPartnerLevels));
          }
        }
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      });
  }

  public sessionCbVwUserPartnerLevel(): ICbVwUserPartnerLevel[] {
    const stringCbVwUserPartnerLevels: string = sessionStorage.getItem(SESSION_STORAGE_CB_VW_USER_PARTNER_LEVEL);
    const tmpCbVwUserPartnerLevels: ICbVwUserPartnerLevel[] = JSON.parse(stringCbVwUserPartnerLevels);
    if (tmpCbVwUserPartnerLevels && tmpCbVwUserPartnerLevels.length > 0) {
      return tmpCbVwUserPartnerLevels;
    }
    return [];
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================

  // ================= END LISTENERS ===================
}
