var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    {
      staticClass: "c-rmedia-files custom-control custom-checkbox",
      attrs: { "data-cy": "c-rmedia-files", for: "checkboxfiles" + _vm.idx },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedMessageIdsAvoidMutated,
            expression: "selectedMessageIdsAvoidMutated",
          },
        ],
        staticClass: "custom-control-input",
        attrs: {
          type: "radio",
          name: "radioTitle",
          id: "checkboxfiles" + _vm.idx,
        },
        domProps: {
          value: _vm.chtVwMedia.chtMessageId,
          checked: _vm._q(
            _vm.selectedMessageIdsAvoidMutated,
            _vm.chtVwMedia.chtMessageId
          ),
        },
        on: {
          change: [
            function ($event) {
              _vm.selectedMessageIdsAvoidMutated = _vm.chtVwMedia.chtMessageId
            },
            function ($event) {
              return _vm.$emit("onSelectedMedia", $event)
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "custom-control-label w-100 py-2" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            { staticClass: "mr-3" },
            [_c("c-icon-file", { attrs: { file: _vm.file } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "flex-grow-1 vac-overflow-hidden" }, [
            _c(
              "p",
              {
                staticClass: "m-0 file-title vac-text-ellipsis",
                attrs: { "data-cy": "file-title" },
              },
              [_vm._v(_vm._s(_vm.chtVwMedia.title))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "d-flex m-0 file-sub" }, [
              _c("span", { staticClass: "mr-1 c-rmediafile-circle" }, [
                _vm._v(_vm._s(_vm.chtVwMedia.extensionType)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "mr-1 c-rmediafile-circle" }, [
                _vm._v(_vm._s(_vm.getFileSize)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-3 my-auto" }, [
            _c(
              "p",
              {
                staticClass: "m-0 file-date",
                attrs: { "data-cy": "file-date" },
              },
              [_vm._v(_vm._s(_vm.getGroupDate))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }