import axios from 'axios';

const baseApiUrl = 'services/btichartreport/api/rpt-meta-tables';

export default class PRptMetaTableService {
  public download(invoiceType: string, invoice: string, lang: any): Promise<any> {
    if (invoice) {
      invoice = invoice + '::' + lang;
    }
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/download-pdf/CB_APP/${invoiceType}/${invoice}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {},
        })
        .then(res => {
          this.processDownload(res);
        })
        .catch(err => {});
    });
  }

  public downloadByUrl(url): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${url}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {},
        })
        .then(res => {
          this.processDownload(res);
        })
        .catch(err => {});
    });
  }

  private processDownload(res) {
    const headerLine = res.headers['content-disposition'];
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    const blob = new Blob([res.data], { type: res.headers['content-type'] });

    // Creating a unique URL to prevent caching issues
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = filename;
    link.target = '_blank';

    // Append current timestamp to the download filename to ensure uniqueness
    link.download = `${filename}_${new Date().getTime()}`;

    // Trigger the download
    link.click();

    // Cleanup: revoke the blob URL after a delay
    setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100);
}
}
