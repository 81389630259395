import axios from 'axios';
import { ICbVwUserSummaryPartnerCount } from '@/shared/model/cb-vw-summary-partner-count.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-user-summary-partner-count';

export default class PCbVwUserSummaryPartnerCountService {
  public find(id): Promise<ICbVwUserSummaryPartnerCount> {
    return new Promise<ICbVwUserSummaryPartnerCount>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(): Promise<ICbVwUserSummaryPartnerCount> {
    return new Promise<ICbVwUserSummaryPartnerCount>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
