import { ICbLocation } from '@/shared/model/cb-location.model';
import { ICbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { IUser } from '@/shared/model/user.model';
import { ICbPawOrder } from '@/shared/model/cb-paw-order.model';

import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import { CbPaymentStatus } from '@/shared/model/enumerations/cb-payment-status.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbReminderValueEnum } from '@/shared/model/enumerations/cb-reminder-value-enum.model';
import { IWfHistory } from './wf-history.model';
import { ICbPawOrderFile } from './cb-paw-order-file.model';
export interface ICbPawOrderRequirement {
  id?: number;
  title?: string | null;
  labelPaymentId?: string | null;
  requirementType?: CbRequirementType | null;
  amount?: number | null;
  baseAmount?: number | null;
  currency?: string | null;
  paymentStatus?: CbPaymentStatus | null;
  status?: CbCommonStatus | null;
  statusDate?: Date | null;
  deliveryTime?: number | null;
  deliveryTimeType?: string | null;
  dayCD?: number | null;
  hourCD?: number | null;
  minuteCD?: number | null;
  progressProject?: number | null;
  offerExpired?: number | null;
  note?: string | null;
  callfrom?: string | null;
  cbPromoUserAggId?: number | null;
  cbPromoCode?: string | null;
  pawIdHistory?: number | null;
  buyerIdFrmServer?: string | null;
  partnerIdFrmServer?: string | null;
  dealDate?: Date | null;
  offerExpiredDate?: Date | null;
  paidDate?: Date | null;
  acceptedDate?: Date | null;
  acceptedBy?: string | null;
  requirementId?: number | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  deliveryGroupByDate?: Date | null;
  bookStartDate?: Date | null;
  bookEndDate?: Date | null;
  reminderValue?: CbReminderValueEnum | null;
  fromWorkLocation?: ICbLocation | null;
  toWorkLocation?: ICbLocation | null;
  timeZone?: string | null;
  cbTextRestrict?: ICbTextRestrict | null;
  buyer?: IUser | null;
  partner?: IUser | null;
  cbPawOrder?: ICbPawOrder | null;
  isBuyerRead?: boolean | null;
  isPartnerRead?: boolean | null;
  cbPawOrderFiles?: ICbPawOrderFile[] | null;

  // WF
  wfHistory?: IWfHistory | null;
  workflowName?: string | null;
  isWfRunNeeded?: boolean | null;

  // Transient
  mainPhotoPawId?: number | null;
  budget?: number | null;
  typeInterestPaw?: 'EDIT' | 'ACCEPT' | null;
}

export class CbPawOrderRequirement implements ICbPawOrderRequirement {
  constructor(
    public id?: number,
    public title?: string | null,
    public labelPaymentId?: string | null,
    public requirementType?: CbRequirementType | null,
    public amount?: number | null,
    public baseAmount?: number | null,
    public currency?: string | null,
    public paymentStatus?: CbPaymentStatus | null,
    public status?: CbCommonStatus | null,
    public statusDate?: Date | null,
    public deliveryTime?: number | null,
    public deliveryTimeType?: string | null,
    public dayCD?: number | null,
    public hourCD?: number | null,
    public minuteCD?: number | null,
    public progressProject?: number | null,
    public offerExpired?: number | null,
    public note?: string | null,
    public callfrom?: string | null,
    public cbPromoUserAggId?: number | null,
    public cbPromoCode?: string | null,
    public pawIdHistory?: number | null,
    public buyerIdFrmServer?: string | null,
    public partnerIdFrmServer?: string | null,
    public dealDate?: Date | null,
    public offerExpiredDate?: Date | null,
    public paidDate?: Date | null,
    public acceptedDate?: Date | null,
    public acceptedBy?: string | null,
    public requirementId?: number | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public deliveryGroupByDate?: Date | null,
    public bookStartDate?: Date | null,
    public bookEndDate?: Date | null,
    public reminderValue?: CbReminderValueEnum | null,
    public fromWorkLocation?: ICbLocation | null,
    public toWorkLocation?: ICbLocation | null,
    public cbTextRestrict?: ICbTextRestrict | null,
    public buyer?: IUser | null,
    public partner?: IUser | null,
    public cbPawOrder?: ICbPawOrder | null,
    public isBuyerRead?: boolean | null,
    public isPartnerRead?: boolean | null,
    public wfHistory?: IWfHistory | null,
    public workflowName?: string | null,
    public timeZone?: string | null,
    public isWfRunNeeded?: boolean | null,
    public cbPawOrderFiles?: ICbPawOrderFile[] | null,

    // Transient
    public mainPhotoPawId?: number | null,
    public budget?: number | null,
    public typeInterestPaw?: 'EDIT' | 'ACCEPT' | null
  ) {}
}
