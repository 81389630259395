var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-icon-file", attrs: { "data-cy": "vac-file-icon" } },
    [
      _vm.isAudio
        ? _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_audio" },
          })
        : _vm.isImage
        ? _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_picture" },
          })
        : _vm.isVideo
        ? _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_video" },
          })
        : _vm.isDoc
        ? _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_docx" },
          })
        : _vm.isXls
        ? _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_excel" },
          })
        : _vm.isPpt
        ? _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_ppt" },
          })
        : _vm.isPdf
        ? _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_pdf" },
          })
        : _c("icon", {
            style: `width:${_vm.width};height:${_vm.height};`,
            attrs: { name: "ic_file_other" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }