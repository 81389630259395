export interface ICbVwUserReview {
  id?: number;
  pawTitle?: string | null;
  tags?: string | null;
  rating?: number;
  comment?: string;
  ownerId?: string | null;
  status?: string | null;
  reviewerId?: string | null;
  reviewerName?: string | null;
  reviewerSignature?: string | null;
  reviewerTrxCount?: number | null;
  reviewerPhotoId?: number | null;
  reviewerLevel?: string | null;
  catName?: string | null;
  svcName?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  externalId?: string | null;
  externalOrderId?: string | null;
  reviewType?: string | null;
  replyRefId?: string | null;
}

export class CbVwUserReview implements ICbVwUserReview {
  constructor(
    public id?: number,
    public pawTitle?: string | null,
    public tags?: string | null,
    public rating?: number,
    public comment?: string,
    public ownerId?: string | null,
    public status?: string | null,
    public reviewerId?: string | null,
    public reviewerName?: string | null,
    public reviewerSignature?: string | null,
    public reviewerTrxCount?: number | null,
    public reviewerPhotoId?: number | null,
    public reviewerLevel?: string | null,
    public catName?: string | null,
    public svcName?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public externalId?: string | null,
    public externalOrderId?: string | null,
    public reviewType?: string | null,
    public replyRefId?: string | null
  ) {}
}
