import { ICbAuctionProjectAtt } from '@/shared/model/cb-auction-project-att.model';

import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';

export interface ICbVwAuctionProject {
  id?: number;
  auctionId?: number;
  svcType?: CbSvcType | null;
  svc?: string | null;
  svcCat?: string | null;
  svcId?: number | null;
  svcCatId?: number | null;
  title?: string | null;
  revisionCount?: number | null;
  projectDueDate?: Date | null;
  auctionDueDate?: Date | null;
  priceType?: CbPriceType | null;
  currency?: CbCurrency | null;
  price?: number | null;
  isProjectGuarantee?: boolean | null;
  isPoliceAgreement?: boolean | null;
  status?: string | null;
  descriptionId?: number | null;
  attIds?: string | null;
  lastModifiedDate?: Date | null;
  attNames?: string | null;
  projectLocation?: string | null;
  authorCity?: string | null;
  authorProvince?: string | null;
  authorCountry?: string | null;
  province?: string | null;
  provinceLat?: string | null;
  provinceLong?: string | null;
  provincePlaceId?: string | null;
  authorName?: string | null;
  authorId?: string | null;
  createdDate?: Date | null;
  createdDateLabel?: string | null;
  authorSignature?: string | null;
  proposalCount?: number | null;
  attIdWithTypes?: string | null;
  authorPhotoId?: number | null;
  hourlyValue?: number | null;
  impression?: number | null;
  impressionLabel?: string | null;
  isActive?: boolean | null;
  locationSearch?: string | null;
  statusSeq?: number | null;
  isPaidGuarantee?: boolean | null;
  auctionSearch?: string | null;
  cbAuctionProjectAtts?: ICbAuctionProjectAtt[] | null;
}

export class CbVwAuctionProject implements ICbVwAuctionProject {
  constructor(
    public id?: number,
    public auctionId?: number,
    public svcType?: CbSvcType | null,
    public svc?: string | null,
    public svcCat?: string | null,
    public svcId?: number | null,
    public svcCatId?: number | null,
    public title?: string | null,
    public revisionCount?: number | null,
    public projectDueDate?: Date | null,
    public auctionDueDate?: Date | null,
    public priceType?: CbPriceType | null,
    public currency?: CbCurrency | null,
    public price?: number | null,
    public isProjectGuarantee?: boolean | null,
    public isPoliceAgreement?: boolean | null,
    public status?: string | null,
    public descriptionId?: number | null,
    public attIds?: string | null,
    public attNames?: string | null,
    public projectLocation?: string | null,
    public authorCity?: string | null,
    public authorProvince?: string | null,
    public authorCountry?: string | null,
    public province?: string | null,
    public provinceLat?: string | null,
    public provinceLong?: string | null,
    public provincePlaceId?: string | null,
    public authorName?: string | null,
    public authorId?: string | null,
    public createdDate?: Date | null,
    public createdDateLabel?: string | null,
    public authorSignature?: string | null,
    public proposalCount?: number | null,
    public attIdWithTypes?: string | null,
    public authorPhotoId?: number | null,
    public hourlyValue?: number | null,
    public impression?: number | null,
    public impressionLabel?: string | null,
    public isActive?: boolean | null,
    public locationSearch?: string | null,
    public statusSeq?: number | null,
    public isPaidGuarantee?: boolean | null,
    public auctionSearch?: string | null,
    public lastModifiedDate?: Date | null,

    public cbAuctionProjectAtts?: ICbAuctionProjectAtt[] | null
  ) {
    // this.isProjectGuarantee = this.isProjectGuarantee ?? false;
    // this.isPoliceAgreement = this.isPoliceAgreement ?? false;
    // this.isActive = this.isActive ?? false;
  }
}
