import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class CInputRevision extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public value: any;
  @Prop({ default: false }) public isValid;
  @Prop({ default: null }) public dataCyCustom;
  @Prop({ default: null }) public dataGTMCustom;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.valueListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleSelectRevision(data) {
    this.$emit('input', data);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('value')
  public valueListener() {
    if (!this.value) {
      this.handleSelectRevision(1);
    }
  }
  // ================= END LISTENERS ===================
}
