import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

import CRegister from './components/auth/register/c-register.vue';
import { NavbarType } from './components/navbar/c-navbar.component';
import { TabSettingType } from './module/general/setting/settings.component';

import PCbUserPinService from './services/cb-user-pin.service';
import PCbVwUserService from './services/cb-vw-user.service';
import PCbVwUserPartnerLevelService from './services/cb-vw-user-partner-level.service';
import AlertService from './shared/alert/alert.service';
import AccountService from './account/account.service';
import GlobalService from './services/global.service';

import { CbUserInfo, ICbUserInfo } from './shared/model/cb-user-info.model';
import { CbVerificationOtp, ICbVerificationOtp } from './shared/model/cb-verification-otp.model';
import { CbVerificationMethodType } from './shared/model/enumerations/cb-verification-method-type.model';
import { CbVwUser, ICbVwUser } from './shared/model/cb-vw-user.model';
import { ICbOid } from './shared/model/cb-oids.model';
import { SetPinType } from './shared/model/enumerations/set-pin-type.model';

@Component({
  components: {
    CRegister,
  },
})
export default class AppRegisterPin extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalServiceRegisterPin: () => GlobalService;
  @Inject('pCbUserPinService') private pCbUserPinService: () => PCbUserPinService;
  @Inject('alertService') private alertServiceRegisterPin: () => AlertService;
  @Inject('accountService') private accountServiceRegisterPin: () => AccountService;
  @Inject('pCbVwUserService') private pCbVwUserService: () => PCbVwUserService;
  @Inject('pCbVwUserPartnerLevelService') private pCbVwUserPartnerLevelService: () => PCbVwUserPartnerLevelService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public cbUserInfo: ICbUserInfo = new CbUserInfo();
  public cbVerificationOtp: ICbVerificationOtp = new CbVerificationOtp();
  public cbVerificationOtpPhone: ICbVerificationOtp = new CbVerificationOtp();
  public methodeVerification: CbVerificationMethodType = null;
  public visibleVerificationEmail = false;
  public visibleVerificationPhone = false;
  public visibleVerificationEmailButtonCancel = false;
  public visibleVerificationPhoneButtonCancel = false;
  public cbSetPin: any = '';
  public visibleVerificationPin = false;
  public visibleSuccessPin = false;
  public wrongOtpCode = false;
  public dataOtp = '';
  public visibleSetNewPin = false;
  public CbVerificationMethodType = CbVerificationMethodType;

  // STORE
  public vwUser: ICbVwUser = new CbVwUser();
  public passwordCurrentNotMatch = false;
  public pinOldNotMatched = false;
  public newPin = '';
  public oldPin = '';
  public cbVwUser: ICbVwUser = new CbVwUser();

  // PIN
  public setPinType: SetPinType;
  public isShowForgotPin = false;
  public visibleInfoPinEmailBeforeOtp = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    (<any>this.$root).$off('selectVerification');
    (<any>this.$root).$on('selectVerification', methodeVerification => {
      this.methodeVerification = methodeVerification;
    });
    (<any>this.$root).$off('handleOpenRegister');
    (<any>this.$root).$on('handleOpenRegister', () => {
      this.closeAllDialogRegisterPin();
      this.globalServiceRegisterPin().openGlobalDialog(this.$root, 'modal-c-register');
    });
    (<any>this.$root).$off('openModalSetPin');
    <any>this.$root.$on('openModalSetPin', value => {
      this.cbVerificationOtp = value;
      this.setPinType = this.cbVerificationOtp.setPinType;
      if (this.cbVerificationOtp.setPinType && this.cbVerificationOtp.setPinType == SetPinType.SET_PIN) {
        this.visibleInfoPinEmailBeforeOtp = true;
      } else {
        this.visibleVerificationPin = true;
      }
    });
    (<any>this.$root).$off('submitInputOldPin');
    (<any>this.$root).$on('submitInputOldPin', (res: boolean) => {
      this.isShowForgotPin = res;
      this.setPinType = SetPinType.CHANGE_PIN;
      this.closeAllDialogRegisterPin();
      this.globalServiceRegisterPin().openGlobalDialog(this.$root, 'modal-c-input-pin-old');
    });
    (<any>this.$root).$off('changePhoneVerification');
    (<any>this.$root).$on('changePhoneVerification', (cbVerificationOtp: ICbVerificationOtp) => {
      this.$store.commit('isPhoneTrouble', false);
      this.cbVerificationOtpPhone = cbVerificationOtp;
      this.closeAllDialogRegisterPin();
      this.globalServiceRegisterPin().openGlobalDialog(this.$root, 'modal-edit-phone-number');
    });
    (<any>this.$root).$off('submitResetPin');
    (<any>this.$root).$on('submitResetPin', () => {
      this.submitSetPin();
    });

    (<any>this.$root).$off('submitRegister');
    (<any>this.$root).$on('submitRegister', (res: ICbOid, cbUserInfo?: ICbUserInfo) => {
      this.cbUserInfo = Object.assign({}, this.cbUserInfo, cbUserInfo);

      this.closeAllDialogRegisterPin();
      if (res?.detail) {
        this.cbVerificationOtpPhone = null;
        this.cbVerificationOtp = null;
        this.cbVerificationOtp = Object.assign({}, this.cbVerificationOtp, res.detail);

        if (this.cbVerificationOtp?.phoneActivationKey?.length > 0) {
          this.cbVerificationOtpPhone = Object.assign({}, this.cbVerificationOtpPhone, res.detail);
          this.methodeVerification = CbVerificationMethodType.PHONE;
          this.visibleVerificationPhone = true;

          // visibleVerificationPhone from dashboard > c-information
          if (res.typeCbOid === 'DASHBOARD') {
            this.visibleVerificationPhoneButtonCancel = true;
          }
        }

        if (this.cbVerificationOtp?.emailActivationKey?.length > 0) {
          this.methodeVerification = CbVerificationMethodType.EMAIL;
          this.visibleVerificationEmail = true;

          // visibleVerificationPhone from dashboard > c-information
          if (res.typeCbOid === 'DASHBOARD') {
            this.visibleVerificationEmailButtonCancel = true;
          }
        }
      } else {
        this.cbVerificationOtp = this.cbSetPin;
      }
    });

    (<any>this.$root).$off('handleSuccessRegister');
    (<any>this.$root).$on('handleSuccessRegister', methodeVerification => {
      this.methodeVerification = methodeVerification;
      this.closeAllDialogRegisterPin();
      if (
        this.methodeVerification &&
        this.methodeVerification == CbVerificationMethodType.EMAIL &&
        this.cbVerificationOtpPhone &&
        this.cbVerificationOtpPhone.phoneActivationKey
      ) {
        this.methodeVerification = CbVerificationMethodType.PHONE;
        this.visibleVerificationPhone = true;
      } else {
        (<any>this.$root).$bvToast.toast(this.$t('cbgwApp.cbHome.cbHomeBeforeLogin.register.successLogin.success').toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        });
        this.submitSuccessOtp();

        // this.globalServiceRegisterPin().openGlobalDialog(this.$root, 'modal-c-success-otp');
      }
    });

    (<any>this.$root).$off('apppin::changeValue');
    (<any>this.$root).$on('apppin::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });

    (<any>this.$root).$off('apppin::changeFunction');
    (<any>this.$root).$on('apppin::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public submitSetPin() {
    this.pCbUserPinService()
      .postResetPinInit()
      .then(res => {
        this.closeAllDialogRegisterPin();
        this.cbSetPin = res.detail;
        this.cbSetPin['setPinType'] = SetPinType.RESET_PIN;
        this.cbVerificationOtp = this.cbSetPin;
        this.globalServiceRegisterPin().openGlobalDialog(this.$root, 'modal-c-reset-pin-init');
      })
      .catch(err => {
        this.alertServiceRegisterPin().showHttpError(this, err.response);
      });
  }

  public changeVerificationKey(e) {
    this.cbVerificationOtp = e;
  }

  public submitSuccessOtp() {
    const routeName = this.$route.name;
    const routeParam = this.$route.params.otpType;
    if (this.$route.name == 'PRegisterFreelancerOne') {
      this.submitSuccessOtpRegisterOne();
    } else if (this.$route.name == 'PDashboard') {
      this.accountServiceRegisterPin().refreshAccount();
    } else {
      //Kondisi by wildan
      if (this.isMobile) {
        if (!this.isAuthenticated) {
          this.$router.replace({ name: routeName, params: { otpType: routeParam }, query: { otpActive: 'true' } });
          setTimeout(() => {
            this.$router.push({ name: 'PLoginM' });
          }, 2000);
        } else if (this.isAuthenticated && routeName == 'POtpVerification') {
          setTimeout(() => {
            window.location.replace('/');
          }, 2000);
        }
      } else {
        this.submitSuccessOtpOther(routeName, routeParam);
      }
    }
  }
  public submitSuccessOtpRegisterOne() {
    const data = { username: this.cbUserInfo.email, password: this.cbUserInfo.password, rememberMe: false };
    this.globalServiceRegisterPin()
      .login(data, this)
      .then(() => {
        this.refreshAccountToStepTwo();
      })
      .catch(err => {
        this.alertServiceRegisterPin().showHttpError(this, err.response);
      });
  }

  public submitSuccessOtpOther(routeName: string, routeParam: any) {
    if (!this.isAuthenticated) {
      this.$router.replace({ name: routeName, params: { otpType: routeParam }, query: { otpActive: 'true' } });
      this.globalServiceRegisterPin().openGlobalDialog(this.$root, 'modal-c-login');
    } else if (this.isAuthenticated && routeName == 'POtpVerification') {
      setTimeout(() => {
        window.location.replace('/');
      }, 2000);
    }
  }

  public refreshAccountToStepTwo() {
    this.accountServiceRegisterPin()
      .retrieveAccount()
      .then(res => {
        this.retrieveCbVwUserCurrent();
      })
      .catch(err => {
        this.alertServiceRegisterPin().showHttpError(this, err.response);
      });
  }
  public retrieveCbVwUserCurrent() {
    this.pCbVwUserService()
      .retrieveByLogin()
      .then(res => {
        this.$store.commit('loginType', NavbarType.PARTNER);
        this.$router.push({ name: 'PRegisterFreelancerTwo' });
      })
      .catch(err => {
        this.alertServiceRegisterPin().showHttpError(this, err.response);
      });
  }

  public submitPin(e) {
    if (this.setPinType == SetPinType.CHANGE_PIN) {
      this.submitPinChangePin(e);
    }
    if (!this.vwUser.pinActivated && !this.vwUser.isPinExist && this.setPinType == SetPinType.SET_PIN) {
      this.submitPinSetPin(e);
    }
    if (this.vwUser.isPinExist && this.dataOtp && this.setPinType == SetPinType.RESET_PIN) {
      this.submitPinResetPin(e);
    }
  }

  public submitPinChangePin(e: any) {
    if (e == this.newPin) {
      this.pCbUserPinService()
        .postChangePin({ currentPin: this.oldPin, newPin: this.newPin })
        .then(() => {
          this.closeAllDialogRegisterPin();
          this.visibleSuccessPin = true;
          this.vwUser.isPinExist = true;
          this.vwUser.pinActivated = true;
          if (this.$route.name == 'POtpVerification') {
            this.$router.push({ name: 'PSettings', query: { tab: 'PIN' } });
          }
          this.processAssignUserLvl(this.vwUser);
        })
        .catch(() => {
          this.passwordCurrentNotMatch = true;
          this.vwUser.isPinExist = true;
          this.vwUser.pinActivated = true;
          this.processAssignUserLvl(this.vwUser);
        });
    } else {
      this.wrongOtpCode = true;
    }
  }

  public submitPinSetPin(e: any) {
    if (e == this.newPin) {
      const pinActivationKey = this.cbVerificationOtp;
      this.pCbUserPinService()
        .postSetPinFinish({ pinActivationKey: pinActivationKey.pinActivationKey, pinActivationCode: this.dataOtp, pin: this.newPin })
        .then(() => {
          this.closeAllDialogRegisterPin();
          this.visibleSuccessPin = true;
          this.vwUser.isPinExist = true;
          this.vwUser.pinActivated = true;
          if (this.$route.name == 'POtpVerification') {
            this.$router.push({ name: 'PSettings', query: { tab: 'PIN' } });
          }
          this.processAssignUserLvl(this.vwUser);
        })
        .catch(err => {
          this.alertServiceRegisterPin().showHttpError(this, err.response);
        });
    } else {
      this.wrongOtpCode = true;
    }
  }

  public submitPinResetPin(e: any) {
    if (e == this.newPin) {
      const pinActivationKey = this.cbVerificationOtp;
      this.pCbUserPinService()
        .postResetPinFinish({ pinActivationKey: pinActivationKey.pinActivationKey, pinActivationCode: this.dataOtp, pin: this.newPin })
        .then(res => {
          this.closeAllDialogRegisterPin();
          this.visibleSuccessPin = true;

          this.vwUser.isPinExist = true;
          this.vwUser.pinActivated = true;
          if (this.$route.name == 'POtpVerification') {
            this.$router.push({ name: 'PSettings', query: { tab: 'PIN' } });
          }
          this.processAssignUserLvl(this.vwUser);
        })
        .catch(err => {
          this.alertServiceRegisterPin().showHttpError(this, err.response);
          this.vwUser.isPinExist = true;
          this.vwUser.pinActivated = true;
          this.processAssignUserLvl(this.vwUser);
        });
    } else {
      this.wrongOtpCode = true;
    }
  }

  public processAssignUserLvl(cbVwUser: ICbVwUser) {
    const params: any = {
      'userId.equals': cbVwUser.id,
    };

    this.pCbVwUserPartnerLevelService()
      .retrieve(params)
      .then(res => {
        if (res && res.data[0] && res.data[0].userLvl) {
          cbVwUser.userLvl = res.data[0].userLvl.toString();
        }
      })
      .finally(() => {
        this.cbVwUser = Object.assign({}, cbVwUser);
        this.vwUser = Object.assign({}, cbVwUser);
        this.$store.commit('currentUser', cbVwUser);
      });
  }
  public valueOtp(dataOtp) {
    this.dataOtp = dataOtp;
  }

  public openModalNewPin() {
    this.closeAllDialogRegisterPin();
    this.visibleSetNewPin = true;
  }

  public submitOldPin(e) {
    this.oldPin = e;
    this.pCbUserPinService()
      .postCurrentPin({ currentPin: this.oldPin })
      .then(res => {
        this.closeAllDialogRegisterPin();
        this.visibleSetNewPin = true;
      })
      .catch(err => {
        this.pinOldNotMatched = true;
      });
  }

  public retrieveCbVwUserByLogin() {
    this.pCbVwUserService()
      .retrieveByLogin()
      .then(res => {
        Object.assign(this.vwUser, res);
        this.processAssignUserLvl(this.vwUser);
      })
      .catch(err => {
        this.alertServiceRegisterPin().showHttpError(this, err.response);
      });
  }

  public handleSetPinType(setPin: SetPinType) {
    this.closeAllDialogRegisterPin();
    this.setPinType = setPin;
    this.visibleSetNewPin = true;
  }

  public saveNewPin(e) {
    this.closeAllDialogRegisterPin();
    this.newPin = e;
    this.retrieveCbVwUserByLogin();
    this.globalServiceRegisterPin().openGlobalDialog(this.$root, 'modal-c-enter-pin');
  }

  public closeAllDialogRegisterPin() {
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-register');
    this.visibleVerificationPhone = false;
    this.visibleVerificationEmail = false;
    this.visibleVerificationPin = false;
    this.visibleSuccessPin = false;
    this.visibleSetNewPin = false;
    this.wrongOtpCode = false;
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-enter-pin');
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-verification-pin');
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-edit-pin');
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-input-pin-old');
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-reset-pin');
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-reset-pin-init');
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-notification-email');
    this.globalServiceRegisterPin().closeGlobalDialog(this.$root, 'modal-c-verification-email-new');
  }

  public toSetPinPage() {
    if (this.$route.name == 'PSettings') {
      this.$root.$emit('changeTab', TabSettingType.PIN);
    } else {
      if (!this.isMobile) {
        this.$router.push({
          name: 'PSettings',
          query: { tab: TabSettingType.PIN },
        });
      } else {
        this.$router.push({
          name: 'PPinM',
        });
      }
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isAuthenticated(): any {
    return this.$store.getters.authenticated;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
