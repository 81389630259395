var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "media-preview" }, [
    _c("div", { staticClass: "p-btn d-flex p-3 w-100" }, [
      _c(
        "div",
        {
          staticClass: "vac-svg-button mr-auto",
          attrs: { "data-cy": "vac-button-close" },
          on: {
            click: function ($event) {
              return _vm.closeModal()
            },
          },
        },
        [
          _c("b-icon-x", {
            staticClass: "cj-fill-white-primary font-size-36 position-relative",
            staticStyle: { top: "-7px", left: "-7px" },
            attrs: { name: "close-outline" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "vac-svg-button vac-button-download ml-3",
          attrs: { "data-cy": "vac-button-download" },
          on: {
            click: function ($event) {
              return _vm.download()
            },
          },
        },
        [
          _c("icon", {
            staticClass: "cj-fill-white-primary",
            attrs: { name: "ic_download", width: "24px", height: "24px" },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "media-preview-container",
        class:
          _vm.message.files && _vm.message.files.length == 1
            ? "active-solo-video-photo"
            : "",
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPlaying == true,
                expression: "isPlaying == true",
              },
            ],
            staticClass: "play-video-container",
          },
          [
            _c("video", {
              ref: "myVideo",
              staticClass: "video-wrapper",
              attrs: {
                "data-cy": "media-preview-video-playing",
                src: _vm.selectedPlayVideoUrl,
              },
              on: {
                click: _vm.setPausePlay,
                mousedown: _vm.setPausePlay,
                touchstart: _vm.setPausePlay,
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "slideshow-container" },
          [
            _c(
              "vueper-slides",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPlaying == false,
                    expression: "isPlaying == false",
                  },
                ],
                ref: "vueperSlidesContentImageVideo",
                staticClass: "main-content-wrapper no-shadow",
                attrs: {
                  autoplay: false,
                  bullets: false,
                  "dragging-distance": 70,
                },
                on: {
                  slide: function ($event) {
                    return _vm.$refs.vueperSlidesThumbitemImageVideoDetails.goToSlide(
                      $event.currentSlide.index,
                      { emit: false }
                    )
                  },
                },
              },
              _vm._l(_vm.message.files, function (file, i) {
                return _c("vueper-slide", {
                  key: i,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _vm.isImage(file)
                              ? _c("div", {
                                  staticClass: "img-size object-contain",
                                  style: `background-image: url(${file.url})`,
                                  attrs: { "data-cy": "media-preview-image" },
                                })
                              : _vm.isVideo(file)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "img-size object-contain icn-play",
                                    style: `background-image: url(${file.urlThumbnail})`,
                                    attrs: { "data-cy": "media-preview-video" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePlayVideo(file.url)
                                      },
                                    },
                                  },
                                  [
                                    _c("c-icons", {
                                      staticClass:
                                        "position-play cj-fill-white-primary",
                                      attrs: {
                                        name: "cic_play_circle_outlined",
                                        width: "100px",
                                        height: "100px",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "vueper-slides",
              {
                ref: "vueperSlidesThumbitemImageVideoDetails",
                staticClass: "sub-content-wrapper no-shadow pt-4",
                class: _vm.getWidhtSlide ? "min-slide" : "",
                attrs: {
                  "dragging-distance": 70,
                  "visible-slides": 9,
                  bullets: false,
                  arrows: false,
                  gap: 1.5,
                  breakpoints: {
                    992: { visibleSlides: 7 },
                    760: { visibleSlides: 4 },
                    572: { visibleSlides: 3 },
                  },
                },
                on: {
                  slide: function ($event) {
                    return _vm.$refs.vueperSlidesContentImageVideo.goToSlide(
                      $event.currentSlide.index,
                      { emit: false }
                    )
                  },
                },
              },
              _vm._l(_vm.message.files, function (file, i) {
                return _c("vueper-slide", {
                  key: i,
                  nativeOn: {
                    click: function ($event) {
                      _vm.$refs.vueperSlidesContentImageVideo.goToSlide(i),
                        _vm.$refs.myVideo.pause(),
                        (_vm.isPlaying = false)
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _vm.isImage(file)
                              ? _c("div", {
                                  staticClass: "img-size object-cover",
                                  style: `background-image: url(${file.url})`,
                                  attrs: {
                                    "data-cy": "media-preview-thumbnail-image",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isVideo(file)
                              ? _c("div", {
                                  staticClass: "img-size object-cover",
                                  style: `background-image: url(${file.urlThumbnail})`,
                                  attrs: {
                                    "data-cy": "media-preview-thumbnail-video",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }