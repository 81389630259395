import { NtNotifApp } from '@/shared/model/enumerations/nt-notif-app.model';
import { NtNotifType } from '@/shared/model/enumerations/nt-notif-type.model';
import { NtNotifModule } from '@/shared/model/enumerations/nt-notif-module.model';
import { NtNotifCodeUnique } from '@/shared/model/enumerations/nt-notif-code-unique.model';
import { NtNotifImageLabel } from '@/shared/model/enumerations/nt-notif-image-label.model';
import { NtNotifIconLabel } from '@/shared/model/enumerations/nt-notif-icon-label.model';
export interface INtUserNotif {
  id?: number;
  app?: NtNotifApp | null;
  notifType?: NtNotifType | null;
  notifModule?: NtNotifModule | null;
  codeUnique?: NtNotifCodeUnique | null;
  subjectOrTitle?: string | null;
  subjectOrTitleId?: string | null;
  content?: string | null;
  contentId?: string | null;
  ownerId?: string | null;
  sendToLink?: string | null;
  sendToLinkIos?: string | null;
  sendToLinkAndroid?: string | null;
  senderUserId?: string | null;
  imageLabel?: NtNotifImageLabel | null;
  iconLabel?: NtNotifIconLabel | null;
  isNotifApp?: boolean | null;
  isRead?: boolean | null;
  isActive?: boolean | null;
  createdDate?: Date | null;
  createdBy?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  externalId?: string | null;
  externalEntity?: string | null;
}

export class NtUserNotif implements INtUserNotif {
  constructor(
    public id?: number,
    public app?: NtNotifApp | null,
    public notifType?: NtNotifType | null,
    public notifModule?: NtNotifModule | null,
    public codeUnique?: NtNotifCodeUnique | null,
    public subjectOrTitle?: string | null,
    public subjectOrTitleId?: string | null,
    public content?: string | null,
    public contentId?: string | null,
    public ownerId?: string | null,
    public sendToLink?: string | null,
    public sendToLinkIos?: string | null,
    public sendToLinkAndroid?: string | null,
    public senderUserId?: string | null,
    public imageLabel?: NtNotifImageLabel | null,
    public iconLabel?: NtNotifIconLabel | null,
    public isNotifApp?: boolean | null,
    public isRead?: boolean | null,
    public isActive?: boolean | null,
    public createdDate?: Date | null,
    public createdBy?: string | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public externalId?: string | null,
    public externalEntity?: string | null
  ) {
    // this.isNotifApp = this.isNotifApp ?? false;
    // this.isRead = this.isRead ?? false;
    // this.isActive = this.isActive ?? false;
  }
}
