import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import CInputCurrency from '@/components/c-input-currency/c-input-currency.vue';

import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';
import { MaxNumberType } from '@/shared/model/enumerations/maxNumberType.model';

const validations: any = {
  additionalPrice: {},
};
@Component({
  validations,
  components: {
    CInputCurrency,
  },
})
export default class COrderPdAddlPrice extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================

  // ================= START VARIABLES ================
  @Prop({ default: false }) public isShowInput: boolean;
  @Prop({ default: null }) public value: number;
  @Prop({ default: null }) public price: number;
  @Prop({ default: null }) public currency: string;

  public deviceSizeType = DeviceSizeType;
  public MaxNumberType = MaxNumberType;

  public additionalPrice = null;

  // ================= END VARIABLES ==================

  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========

  // ================= START FUNCTION =================
  public touchValidation() {
    this.$v.additionalPrice.$touch();
  }
  // ================= END FUNCTION ===================

  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  // ================= END COMPUTE ====================

  // ================= START LISTENERS ================
  @Watch('value', { deep: true })
  public additionalPriceListener() {
    if (this.value !== this.additionalPrice) {
      this.additionalPrice = this.value;
    }
  }
  // ================= END LISTENERS ==================
}
