import dayjs from 'dayjs';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import CButton from '@/components/button/c-button.vue';

import { CbLocation, ICbLocation } from '@/shared/model/cb-location.model';
import { ICbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';
import { HANDLE_VIEW_MAP_TRACK } from '@/module/chat-container/order-paw-controller.component';

@Component({
  components: {
    CButton,
  },
})
export default class CItemChatMessageOnmyway extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrderRequirement: ICbVwPawOrderRequirement;
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;

  public cbLocation: ICbLocation = new CbLocation();
  public cbLoginType = CbLoginType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.cbVwPawOrderRequirementListener();
  }

  public handleOpenLocation() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_VIEW_MAP_TRACK,
      typeModal: TypeComponent.CHAT_MODAL,
      cbVwPawOrder: this.cbVwPawOrder,
      cbVwPawOrderRequirement: this.cbVwPawOrderRequirement,
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getBookDate() {
    if (this.cbVwPawOrderRequirement.bookStartDate && this.cbVwPawOrderRequirement.bookEndDate) {
      const startTime: string = dayjs(this.cbVwPawOrderRequirement.bookStartDate).format('HH:ss');
      const endTime: string = dayjs(this.cbVwPawOrderRequirement.bookEndDate).format('HH:ss');
      return `${dayjs(this.cbVwPawOrderRequirement.bookStartDate).format('DD MM YYYY')} | ${startTime}-${endTime}`;
    } else return null;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }

  public get loginType() {
    return this.$store.getters.loginType;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('cbVwPawOrderRequirement')
  public cbVwPawOrderRequirementListener() {
    if (this.cbVwPawOrderRequirement?.id) {
      if (this.cbVwPawOrderRequirement?.toWorkLocations?.length > 0) {
        const splitLocation = this.cbVwPawOrderRequirement.toWorkLocations.split('::');

        if (splitLocation.length > 0) {
          // 57037::ChIJ44AmghyNaS4R5dQ_bSN2kZI::Jl. Raya Pekayon, RT.003/RW.001, Jaka Setia, Kec. Bekasi Sel., Kota Bks, Jawa Barat 17148, Indonesia
          // 0 id location
          // 1 place id
          // 2 address
          this.cbLocation = {
            ...new CbLocation(),
            ...{
              id: +splitLocation[0],
              placeId: splitLocation[1],
              address: splitLocation[2],
            },
          };
        }
      }
    }
  }
  // ================= END LISTENERS ===================
}
