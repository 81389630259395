import GlobalService from '@/services/global.service';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';

@Component({})
export default class CNavbarSwitchLogintype extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: true })
  public isMarginRight: boolean;

  @Prop({ default: false })
  public isHome: boolean;

  public cbLoginType = CbLoginType;
  public loginType: CbLoginType = null;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.$watch('$route', function (routeTo, routeFromo) {
      if (routeTo.name === 'PIntroductionFreelancer') {
        this.loginType = this.cbLoginType.PARTNER;
        return;
      }

      if (routeTo.fullPath.includes('/freelancer')) {
        if (
          this.isAuthenticated &&
          !this.currentUser?.emailActivated &&
          (this.getUser?.email == this.getUser?.id || this.getUser?.email == null || !this.getUser?.email?.includes('@'))
        ) {
          this.globalService().openGlobalDialog(this.$root, 'modal-c-verification-email-new');
          this.$router.push({ name: 'PHome' });
          return;
        }

        if (this.$store.getters.hasRoleCbPartner) {
          this.loginType = CbLoginType.PARTNER;
        } else {
          this.loginType = CbLoginType.BUYER;
        }

        this.$store.commit('loginType', this.loginType);
      } else if (routeTo.fullPath.includes('/buyer')) {
        this.loginType = CbLoginType.BUYER;
        this.$store.commit('loginType', this.loginType);
      } else {
        this.loginType = this.$store.getters.loginType;
      }
    });
    this.loginType = this.$store.getters.loginType;
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public changeLoginType() {
    let loginType = null;
    if (this.loginType == this.cbLoginType.PARTNER) {
      loginType = this.cbLoginType.BUYER;
    }
    if (this.loginType == this.cbLoginType.BUYER) {
      loginType = this.cbLoginType.PARTNER;
    }
    if (this.currentUser && !this.$store.getters.hasRoleCbPartner && !this.$store.getters.hasRoleCbBuyer) {
      if (this.loginType == this.cbLoginType.BUYER) {
        this.loginType = this.cbLoginType.PARTNER;
        this.$router.push({ name: 'PIntroductionFreelancer' });
      } else {
        this.loginType = this.cbLoginType.BUYER;
        this.$router.push({ name: 'PBuyerHome' });
      }

      if (
        this.isAuthenticated &&
        !this.currentUser?.emailActivated &&
        (this.getUser?.email == this.getUser?.id || this.getUser?.email == null || !this.getUser?.email?.includes('@'))
      ) {
        this.globalService().openGlobalDialog(this.$root, 'modal-c-verification-email-new');
        this.$router.push({ name: 'PHome' });
        return;
      }
    } else {
      this.$emit('input', loginType);
      this.loginType = loginType;
      this.$store.commit('loginType', loginType);

      if (!this.$route.fullPath.toLocaleLowerCase().includes('chat')) {
        if (this.loginType === CbLoginType.BUYER) {
          this.$router.replace({ name: 'PBuyerHome' });
        }
        if (this.loginType === CbLoginType.PARTNER) {
          this.$router.replace({ name: 'PFreelancerHome' });
        }
      } else {
        window.location.reload();
      }
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getUser() {
    return this.$store.getters.account;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }

  public get isAuthenticated(): any {
    return this.$store.getters.authenticated;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
