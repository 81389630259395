var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "room-uploads", attrs: { "data-cy": "vac-room-uploads" } },
    [
      _c("div", { staticClass: "d-flex p-container" }, [
        _c(
          "button",
          {
            staticClass:
              "btn-wrapper text-center m-1 d-flex flex-column align-items-center",
            attrs: { "data-cy": "vac-btn-document-icon" },
            on: { click: _vm.handleShowDocument },
          },
          [
            _c("img", {
              staticClass: "h-100 w-100 image-size",
              attrs: {
                src: `${_vm.$env.CDN_CB}/content/cb-img/ic-document.png`,
                alt: "file",
              },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "m-auto font-btn",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbChat.roomUpload.document")
                  ),
                },
              },
              [_vm._v("Document")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn-wrapper text-center m-1 d-flex flex-column align-items-center",
            attrs: { "data-cy": "vac-btn-videoimage-icon" },
            on: { click: _vm.handleShowVideoImage },
          },
          [
            _c("img", {
              staticClass: "h-100 w-100 image-size",
              attrs: {
                src: `${_vm.$env.CDN_CB}/content/cb-img/ic-image.png`,
                alt: "file",
              },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "m-auto font-btn",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbChat.roomUpload.imgVideo")
                  ),
                },
              },
              [_vm._v("Image & Video")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn-wrapper text-center m-1 d-flex flex-column align-items-center",
            attrs: { "data-cy": "vac-btn-location-icon" },
            on: { click: _vm.handleShowLocation },
          },
          [
            _c("img", {
              staticClass: "h-100 w-100 image-size",
              attrs: {
                src: `${_vm.$env.CDN_CB}/content/cb-img/ic-location.png`,
                alt: "file",
              },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "m-auto font-btn",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbChat.roomUpload.location")
                  ),
                },
              },
              [_vm._v("Location")]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn-wrapper text-center m-1 d-flex flex-column align-items-center",
            attrs: { "data-cy": "vac-btn-camera-icon" },
            on: { click: _vm.handleShowCamera },
          },
          [
            _c("img", {
              staticClass: "h-100 w-100 image-size",
              attrs: {
                src: `${_vm.$env.CDN_CB}/content/cb-img/ic-camera.png`,
                alt: "file",
              },
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "m-auto font-btn",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbChat.roomUpload.camera")
                  ),
                },
              },
              [_vm._v("Camera")]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }