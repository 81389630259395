import axios from 'axios';

const baseApiUrl = 'services/cbuserms/api/cb-vw-medals';

export default class PCbVWMedalService {
  public retrieveByLogin(cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`, { cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
