import { Component, Prop } from 'vue-property-decorator';
import { DEFAULT_LAT, DEFAULT_LNG } from '@/constants';
import { CbLocation, ICbLocation } from '@/shared/model/cb-location.model';
import Icon from '@/shared/icons/icon.vue';
import MapUtils from '@/shared/utils/map-utils.service';
import { mixins } from 'vue-class-component';
import { Flutter } from '@/app-flutter';
import JhiDataUtils from '@/shared/data/data-utils.service';

@Component({
  components: {
    Icon,
  },
})
export default class CLocationMap extends mixins(MapUtils, JhiDataUtils) {
  @Prop() public mapSize;
  @Prop({ default: new CbLocation() }) public placeModel: CbLocation;

  public newPlaceModel: ICbLocation = new CbLocation();

  public map = null;
  public infowindow = null;
  public infowindowContent = null;
  public marker = null;
  public geocoder = null;
  public markers = [];

  public mounted(): void {
    this.initMap();
  }

  public initMap() {
    this.geocoder = new (<any>window).google.maps.Geocoder();
    const bangalore = {
      lat: this.placeModel?.latGoogle ? Number(this.placeModel.latGoogle) : DEFAULT_LAT,
      lng: this.placeModel?.longGoogle ? Number(this.placeModel.longGoogle) : DEFAULT_LNG,
    };

    this.map = new (<any>window).google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: bangalore,
      zoom: 13,
      streetViewControl: false,
      fullscreenControl: false,
    });

    const getPosition = () => {
      this.getCurrentPosition({})
        .then(pos => {
          const curLoc = new (<any>window).google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
          try {
            new (<any>window).google.maps.Marker({
              position: curLoc,
              map: this.map,
              icon: `${process.env.CDN_CB}/content/ic_map_position_circle.png`,
            });
            if (Flutter.isRunOn()) {
              Flutter.call('permissionLocationStatus', {
                permissionLocationStatus: 'PERMISSION_GRANTED',
              });
            }
          } catch (e) {}
        })
        .catch(err => {
          if (Flutter.isRunOn()) {
            if (err.code === err.PERMISSION_DENIED) {
              console.log('Geolocation permission denied (Flutter):', err.message);
              Flutter.call('permissionLocationStatus', {
                permissionLocationStatus: 'PERMISSION_DENIED',
              });
            }
          }
          console.log('ERR', err.message);
        });
    };

    //CURRENT LOCATION
    if (Flutter.isRunOn()) {
      this.permissionLocationFlutter(getPosition);
    } else {
      getPosition();
    }

    this.marker = new (<any>window).google.maps.Marker({ position: bangalore, map: this.map, draggable: true, zIndex: 101 });

    this.marker.addListener('dragend', () => {
      const curLoc = new (<any>window).google.maps.LatLng(this.marker.getPosition().lat(), this.marker.getPosition().lng());

      this.geocoder.geocode({ location: curLoc }, async (results, status) => {
        if (status !== 'OK' || !results[0]) {
          throw new Error(status);
        }
        await this.olahPlace(results[0]);

        const input = document.getElementById('pac-input') as HTMLInputElement;
        input.value = this.newPlaceModel.addressPinPoint;
      });
    });

    const inputWrapper = document.getElementById('pac-input-wraper') as HTMLElement;
    const input = inputWrapper.querySelector('#pac-input') as HTMLInputElement;
    const inputClear = inputWrapper.querySelector('#pac-input-clear') as HTMLButtonElement;
    const autocomplete = new (<any>window).google.maps.places.Autocomplete(input);
    autocomplete.bindTo('bounds', this.map);
    if (this.placeModel?.addressPinPoint) {
      input.value = this.placeModel.addressPinPoint;
    }

    input.addEventListener('input', () => {
      if (input.value.trim() === '') {
        inputClear.style.display = 'none';
      } else {
        inputClear.style.display = 'flex';
      }
    });
    inputClear.addEventListener('click', () => {
      if (input) {
        input.value = '';
        input.focus();
      }
      input.dispatchEvent(new Event('input'));
    });

    if (input.value.trim() === '') {
      inputClear.style.display = 'none';
    } else {
      inputClear.style.display = 'flex';
    }

    // Specify just the place data fields that you need.
    autocomplete.setFields(['place_id', 'geometry', 'name', 'address_components', 'formatted_address']);

    this.map.controls[(<any>window).google.maps.ControlPosition.TOP_CENTER].push(inputWrapper);

    this.infowindow = new (<any>window).google.maps.InfoWindow();
    this.infowindowContent = document.getElementById('infowindow-content');
    this.infowindow.setContent(this.infowindowContent);

    autocomplete.addListener('place_changed', async () => {
      this.infowindow.close();

      const place = autocomplete.getPlace();

      console.log('autocomplete', autocomplete, 'place', place);

      await this.olahPlace(place);
    });

    //  Create the DIV to hold the control.
    const centerControlDiv = document.getElementById('cur-loc');

    const controlButton = centerControlDiv.children.namedItem('loc-button') as HTMLElement;

    // Setup the click event listeners: simply set the map to Chicago.
    const getCurrPosition = () => {
      this.getCurrentPosition({})
        .then(pos => {
          const curLoc = new (<any>window).google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
          this.marker.setPosition(null);
          this.marker.setPosition(curLoc);

          this.geocoder.geocode({ location: curLoc }, async (results, status) => {
            if (status !== 'OK' || !results[0]) {
              throw new Error(status);
            }
            if (Flutter.isRunOn()) {
              Flutter.call('permissionLocationStatus', {
                permissionLocationStatus: 'PERMISSION_GRANTED',
              });
            }
            await this.olahPlace(results[0]);
            input.value = this.newPlaceModel.addressPinPoint;
          });
        })
        .catch(err => {
          if (Flutter.isRunOn()) {
            if (err.code === err.PERMISSION_DENIED) {
              console.log('Geolocation permission denied (Flutter):', err.message);
              Flutter.call('permissionLocationStatus', {
                permissionLocationStatus: 'PERMISSION_DENIED',
              });
            }
          }
          console.log('ERR', err.message);
        });
    };

    controlButton.addEventListener('click', () => {
      if (Flutter.isRunOn()) {
        this.permissionLocationFlutter(getCurrPosition);
      } else {
        getCurrPosition();
      }
    });

    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
  }

  async getDetailByPlaceId(service, placeIdP): Promise<any> {
    return new Promise<any>(resolve => {
      service.getDetails(
        {
          placeId: placeIdP,
        },
        function (place, status) {
          if (status === (<any>window).google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          }
        }
      );
    });
  }

  public async olahPlace(place) {
    if (!place.geometry || !place.geometry.location) {
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    this.marker.setPosition(place.geometry.location);

    this.marker.setVisible(true);

    (this.infowindowContent.children.namedItem('place-name') as HTMLElement).textContent = place.name as string;

    (this.infowindowContent.children.namedItem('place-address') as HTMLElement).textContent = place.formatted_address as string;
    this.infowindow.open(this.map, this.marker);

    const ac = place.address_components;
    const componentForm = {
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
    };
    let cityCountry = '';
    for (const address of place.address_components) {
      const addressType = address.types[0];
      if (componentForm[addressType]) {
        const val = address[componentForm[addressType]];
        cityCountry = cityCountry + val + (addressType == 'locality' || addressType == 'administrative_area_level_1' ? ',' : '') + ' ';
      }
    }

    if (!this.newPlaceModel) {
      this.newPlaceModel = new CbLocation();
    }
    this.newPlaceModel.address = place.formatted_address;
    this.newPlaceModel.addressPinPoint = place.formatted_address;

    this.newPlaceModel.latGoogle = place.geometry.location.lat() + '';
    this.newPlaceModel.longGoogle = place.geometry.location.lng() + '';

    this.processLocation(ac, place);
  }

  private processLocation(ac: any, place: any) {
    //adehuh
    let country, province, city, subCity, zipCode;

    for (let c = 0; c < ac.length; c++) {
      if (ac[c]['short_name'].length == 2) {
        // country
        country = ac[c]['long_name'];
        if (ac.length > 1) province = this.getAddressComponentByIndex(ac, c, 1);
        if (ac.length > 2) city = this.getAddressComponentByIndex(ac, c, 2);
        if (ac.length > 3) subCity = this.getAddressComponentByIndex(ac, c, 3);

        if (ac[c + 1] != null) zipCode = this.getAddressComponentZipcodeByIndex(ac, c);
      }
    }

    this.newPlaceModel.country = country;
    this.newPlaceModel.province = province;
    this.newPlaceModel.city = city;
    this.newPlaceModel.subCity = subCity;
    this.newPlaceModel.zipCode = zipCode;
    this.newPlaceModel.placeId = place.place_id;
    this.$emit('callback', this.newPlaceModel);
  }

  private getAddressComponentByIndex(ac, counter, idx): string {
    return ac[counter - idx] && ac[counter - idx]['long_name'] ? ac[counter - idx]['long_name'] : '';
  }

  private getAddressComponentZipcodeByIndex(ac, counter): string {
    return ac[counter + 1] && ac[counter + 1]['long_name'] ? ac[counter + 1]['long_name'] : '';
  }
}
