import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Vue2Filters from 'vue2-filters';

import CButton from '@/components/button/c-button.vue';
import CTimeCountdown from '@/components/c-time-countdown/c-time-countdown.vue';
import Icon from '@/shared/icons/icon.vue';
import CDetailOrderStatus from '@/components/c-order/c-order-detail/components/c-detail-order-status/c-detail-order-status.vue';

import JhiDataUtils from '@/shared/data/data-utils.service';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { ICbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { ICbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

import { HANDLE_MDL_PROJECT_DELIVERY_PREVIEW } from '@/module/chat-container/order-paw-controller.component';

@Component({
  components: {
    Icon,
    CButton,
    CTimeCountdown,
    CDetailOrderStatus,
  },
  mixins: [Vue2Filters.mixin],
})
export default class CItemChatMessageProjectDelivery extends mixins(JhiDataUtils, StatusTextEnId) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrderRequirement: ICbVwPawOrderRequirement;
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;

  public CbCommonStatus = CbCommonStatus;
  public TypeComponent = TypeComponent;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleOpenPreviewProjectDelivery() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_MDL_PROJECT_DELIVERY_PREVIEW,
      cbVwPawOrder: this.cbVwPawOrder,
      actionId: this.cbVwPawOrderRequirement.id,
      typeModal: TypeComponent.CHAT_MODAL,
    });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get cbTextRestricts(): ICbTextRestrict[] {
    return this.$store.getters.cbTextRestricts;
  }
  // ================= END COMPUTE ==================
}
