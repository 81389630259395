import { ICbPawOrderFile } from './cb-paw-order-file.model';
import { ICbVwPawOrderRequirement } from './cb-vw-paw-order-requirement.model';
import { ICbLocation } from './cb-location.model';
import { ICbVwUser } from './cb-vw-user.model';
import { ICbPawOrderInclude } from './cb-paw-order-include.model';
import { ICbPawOrderExtra } from './cb-paw-order-extra.model';
import { ICbPawOrderMilestone } from './cb-paw-order-milestone.model';
import { ICbTextRestrict } from './cb-text-restrict.model';
import { ICbItemChatWidgetOffer } from './cb-item-chat-widget-offer.model';
import { ICbVwUserSummary } from './cb-vw-user-summary.model';
import { ICbVWMedal } from './cb-vw-medal.model';
import { ICbPawOrderTrack } from './cb-paw-order-track.model';
import { IPayVwBillingHistoryPayment } from './pay-vw-billing-history-payment.model';

import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';

export interface ICbVwPawOrder {
  id?: number;
  orderType?: CbOrderType | null;
  orderStatus?: CbCommonStatus | null;
  orderSearch?: string | null;
  bookStartDate?: Date | null;
  bookEndDate?: Date | null;
  cbPawPkgDescriptionId?: number | null;
  createdBy?: string | null;
  currency?: CbCurrency | null;
  countTransaction?: number | null;
  createdDate?: Date | null;
  deliveryTime?: number | null;
  deliveryTimeType?: string | null;
  dealDate?: Date | null;
  pawTitle?: string | null;
  pawPkgDescription?: string | null;
  pawPkgType?: string | null;
  pawPkgTitle?: string | null;
  pawIdHistory?: number | null;
  progressProject?: number | null;
  projectValueAmount?: number | null;
  revision?: number | null;
  svcType?: CbSvcType | null;
  svcName?: string | null;
  svcCategoryName?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  labelOrderId?: string | null;
  milestoneDetails?: string | null;
  extraDetails?: string | null;
  includeValues?: string | null;
  toWorkLocations?: string | null;
  workflowName?: string | null;
  keyName?: string | null;
  buyerId?: string | null;
  partnerId?: string | null;
  buyerPhotoId?: number | null;
  buyerDisplayName?: string | null;
  buyerRating?: number | null;
  buyerLvl?: string | null;
  buyerCity?: string | null;
  buyerSignature?: string | null;
  partnerPhotoId?: number | null;
  partnerDisplayName?: string | null;
  partnerRating?: number | null;
  partnerLvl?: string | null;
  partnerCity?: string | null;
  partnerSignature?: string | null;
  aaltDate?: Date | null;
  expiredDate?: Date | null;
  freezeDate?: Date | null;
  xExpiredDate?: Date | null;
  xDealDate?: Date | null;
  xIntervalCountDownSecond?: number | null;
  cbLocationRequirement?: ICbLocation | null;
  cbLocationClockIn?: ICbLocation | null;
  cbLocationClockOut?: ICbLocation | null;
  isHasMilestone?: boolean | null;

  // Transient
  cbPawOrderFiles?: ICbPawOrderFile[] | null;
  cbVwPawOrderRequirements?: ICbVwPawOrderRequirement[] | null;
  cbVwPawOrderRequirement?: ICbVwPawOrderRequirement | null;
  toWorkLocation?: ICbLocation | null;
  cbVwuserPartner?: ICbVwUser | null;
  cbVwuserBuyer?: ICbVwUser | null;
  cbVwUser?: ICbVwUser | null;
  cbVwUserSummary?: ICbVwUserSummary | null;
  cbVwMedals?: ICbVWMedal[] | null;
  cbVwPawOrderIncludes?: ICbPawOrderInclude[] | null;
  cbVwPawOrderExtras?: ICbPawOrderExtra[] | null;
  cbVwPawOrderMilestones?: ICbPawOrderMilestone[] | null;
  cbVwPawOrderProjectDeliverys?: ICbVwPawOrderRequirement[] | null;
  cbVwPawOrderProjectDeliveryPreviews?: ICbVwPawOrderRequirement[] | null;
  cbVwPawOrderProjectDeliverySelected?: ICbVwPawOrderRequirement | null;
  cbVwPawOrderMilestoneRequirements?: ICbVwPawOrderRequirement[] | null;
  cbVwPawOrderMOMs?: ICbVwPawOrderRequirement[] | null;
  cbVwPawOrderAppoinments?: ICbVwPawOrderRequirement[] | null;
  cbVwPawOrderFileClockIns?: ICbPawOrderFile[] | null;
  cbVwPawOrderFileClockOuts?: ICbPawOrderFile[] | null;
  cbVwBillingHistoryPayments?: IPayVwBillingHistoryPayment[] | null;
  cbVwPawOrderTracks?: ICbPawOrderTrack[] | null;
  cbVwTextRestrict?: ICbTextRestrict | null;
  itemChatWidgetOffers?: ICbItemChatWidgetOffer[] | null;
  wfHistoriesKeyName?: string[] | null;
  pawTitleAgg?: string | null;
  reviewed?: boolean | null;
  deliveryTimeTotalOverdue?: string | null;
  timeZone?: string | null;
  isAlreadyOrderReview?: boolean | null;
  isAlreadyOrderDisputed?: boolean | null;
}

export class CbVwPawOrder implements ICbVwPawOrder {
  constructor(
    public id?: number,
    public orderType?: CbOrderType | null,
    public orderStatus?: CbCommonStatus | null,
    public orderSearch?: string | null,
    public bookStartDate?: Date | null,
    public bookEndDate?: Date | null,
    public cbPawPkgDescriptionId?: number | null,
    public createdBy?: string | null,
    public currency?: CbCurrency | null,
    public countTransaction?: number | null,
    public createdDate?: Date | null,
    public deliveryTime?: number | null,
    public deliveryTimeType?: string | null,
    public dealDate?: Date | null,
    public pawTitle?: string | null,
    public pawPkgDescription?: string | null,
    public pawPkgType?: string | null,
    public pawPkgTitle?: string | null,
    public pawIdHistory?: number | null,
    public progressProject?: number | null,
    public projectValueAmount?: number | null,
    public revision?: number | null,
    public svcType?: CbSvcType | null,
    public svcName?: string | null,
    public svcCategoryName?: string | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public labelOrderId?: string | null,
    public milestoneDetails?: string | null,
    public extraDetails?: string | null,
    public includeValues?: string | null,
    public toWorkLocations?: string | null,
    public workflowName?: string | null,
    public keyName?: string | null,
    public buyerId?: string | null,
    public partnerId?: string | null,
    public buyerPhotoId?: number | null,
    public buyerDisplayName?: string | null,
    public buyerRating?: number | null,
    public buyerLvl?: string | null,
    public buyerCity?: string | null,
    public buyerSignature?: string | null,
    public partnerPhotoId?: number | null,
    public partnerDisplayName?: string | null,
    public partnerRating?: number | null,
    public partnerLvl?: string | null,
    public partnerCity?: string | null,
    public partnerSignature?: string | null,
    public aaltDate?: Date | null,
    public expiredDate?: Date | null,
    public freezeDate?: Date | null,
    public xDealDate?: Date | null,
    public xExpiredDate?: Date | null,
    public xIntervalCountDownSecond?: number | null,
    public cbLocationRequirement?: ICbLocation | null,
    public cbLocationClockIn?: ICbLocation | null,
    public cbLocationClockOut?: ICbLocation | null,
    public isHasMilestone?: boolean | null,

    //Transient
    public reviewed?: boolean | null,
    public toWorkLocation?: ICbLocation | null,
    public cbVwPawOrderRequirements?: ICbVwPawOrderRequirement[] | null,
    public cbVwPawOrderRequirement?: ICbVwPawOrderRequirement | null,
    public cbVwUser?: ICbVwUser | null,
    public cbVwUserSummary?: ICbVwUserSummary | null,
    public cbVwMedals?: ICbVWMedal[] | null,
    public cbVwPawOrderIncludes?: ICbPawOrderInclude[] | null,
    public cbVwPawOrderExtras?: ICbPawOrderExtra[] | null,
    public cbVwPawOrderMilestones?: ICbPawOrderMilestone[] | null,
    public cbVwPawOrderProjectDeliverys?: ICbVwPawOrderRequirement[] | null,
    public cbVwPawOrderProjectDeliveryPreviews?: ICbVwPawOrderRequirement[] | null,
    public cbVwPawOrderProjectDeliverySelected?: ICbVwPawOrderRequirement | null,
    public cbVwPawOrderMilestoneRequirements?: ICbVwPawOrderRequirement[] | null,
    public cbVwPawOrderMOMs?: ICbVwPawOrderRequirement[] | null,
    public cbVwPawOrderAppoinments?: ICbVwPawOrderRequirement[] | null,
    public cbVwPawOrderFileClockIns?: ICbPawOrderFile[] | null,
    public cbVwPawOrderFileClockOuts?: ICbPawOrderFile[] | null,
    public cbVwBillingHistoryPayments?: IPayVwBillingHistoryPayment[] | null,
    public cbVwPawOrderTracks?: ICbPawOrderTrack[] | null,
    public cbVwTextRestrict?: ICbTextRestrict | null,
    public cbPawOrderFiles?: ICbPawOrderFile[] | null,
    public itemChatWidgetOffers?: ICbItemChatWidgetOffer[] | null,
    public deliveryTimeTotalOverdue?: string | null,
    public timeZone?: string | null,
    public isAlreadyOrderReview?: boolean | null,
    public isAlreadyOrderDisputed?: boolean | null,
    public wfHistoriesKeyName?: string[] | null
  ) {
    this.isAlreadyOrderDisputed = false;
  }
}
