import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { CbBankAccount, ICbBankAccount } from '@/shared/model/cb-bank-account.model';
import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CBaCreditUpdate from '../c-ba-credit-update/c-ba-credit-update.vue';

@Component({
  components: {
    Icon,
    CButton,
    CBaCreditUpdate,
  },
})
export default class CBaCreditCard extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null })
  public cbCreditCards: CbBankAccount[];
  public newLocalCreditCard: ICbBankAccount = new CbBankAccount();
  public visibleUpdateId: number = null;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public openEditBankAccount(cbCreditCard: ICbBankAccount) {
    this.newLocalCreditCard = Object.assign({}, cbCreditCard);
    this.newLocalCreditCard.expiryDateLabel = dayjs(this.newLocalCreditCard.expiredDate).format('MM/YY');
    let numberArr: string[] = [];
    let tmpAccountNumber: string = this.newLocalCreditCard.accountNumber.replace(/\s/g, '');
    numberArr = tmpAccountNumber.match(/.{1,4}/g);
    this.newLocalCreditCard.accountNumber = numberArr.join(' ');
    this.visibleUpdateId = cbCreditCard.id;
  }

  public submitCC(cbBankAccount: ICbBankAccount) {
    this.$emit('changeValue', 'newCbCreditCard', cbBankAccount);
    this.$emit('changeFunction', 'handleSubmitCC');
    this.visibleUpdateId = null;
    this.newLocalCreditCard = new CbBankAccount();
  }

  public handleCancel() {
    this.visibleUpdateId = null;
    this.newLocalCreditCard = new CbBankAccount();
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getCodeBank() {
    return (accountNumber: string) => {
      if (accountNumber) {
        if (accountNumber[0] == '4') {
          return 'visa';
        } else if (accountNumber[0] == '5') {
          return 'mastercard';
        }
      }
      return 'visa';
    };
  }
  public get getStatusExpired() {
    return (cbCreditCard: ICbBankAccount) => {
      let now = dayjs().toDate();
      if (cbCreditCard && cbCreditCard.expiredDate && dayjs(cbCreditCard.expiredDate).toDate() < now) {
        return true;
      } else {
        return false;
      }
    };
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================

  // ================= END LISTENERS ==================
}
