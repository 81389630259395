import { IUser } from './user.model';
import { ICbAttachmentNoAuth } from '@/shared/model/cb-attachment-no-auth.model';
import { ICbLocation } from '@/shared/model/cb-location.model';
import { ICbUserSvcType } from '@/shared/model/cb-user-svc-type.model';
import { ICbUserSkill } from '@/shared/model/cb-user-skill.model';
import { ICbUserEducation } from '@/shared/model/cb-user-education.model';
import { ICbUserCrt } from '@/shared/model/cb-user-crt.model';
import { ICbUserLanguage } from '@/shared/model/cb-user-language.model';
import { ICbUserSocialMedia } from '@/shared/model/cb-user-social-media.model';
import { ICbUserEmergencyContact } from '@/shared/model/cb-user-emergency-contact.model';
import { ICbUserStory } from '@/shared/model/cb-user-story.model';
import { ICbUserTime } from '@/shared/model/cb-user-time.model';
import { ICbUserBookmarkAgg } from '@/shared/model/cb-user-bookmark-agg.model';
import { ICbUserData } from '@/shared/model/cb-user-data.model';
import { ICbUserFcm } from '@/shared/model/cb-user-fcm.model';
import { ICbUserFans } from '@/shared/model/cb-user-fans.model';
import { ICbUserActivity } from '@/shared/model/cb-user-activity.model';
import { ICbUserAvatarAgg } from '@/shared/model/cb-user-avatar-agg.model';
import { ICbUserBannerAgg } from '@/shared/model/cb-user-banner-agg.model';
import { ICbPromoUserAgg } from '@/shared/model/cb-promo-user-agg.model';

import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbGender } from '@/shared/model/enumerations/cb-gender.model';
import { CbNationality } from '@/shared/model/enumerations/cb-nationality.model';
import { CbIdentityType } from '@/shared/model/enumerations/cb-identity-type.model';
import { CbUserStatusType } from './enumerations/cb-user-status-type.model';
import { CbProfileUpdateType } from './enumerations/cb-profile-update-type.model';
export interface ICbUserInfo {
  id?: string;
  loginType?: CbLoginType | null;
  defaultSvcType?: CbSvcType | null;
  isHidePhoto?: boolean | null;
  username?: string | null;
  signature?: string | null;
  birthDate?: Date | null;
  gender?: CbGender | null;
  nationality?: CbNationality | null;
  identityType?: CbIdentityType | null;
  identityNo?: string | null;
  timeZone?: string | null;
  timeZoneUTC?: string | null;
  timeZoneDetail?: string | null;
  langKey?: string | null;
  emailVerificationOpt?: boolean | null;
  phoneVerificationOpt?: boolean | null;
  phoneCode?: string | null;
  phone?: string | null;
  phoneActivated?: boolean | null;
  phoneActivationKey?: string | null;
  emailActivated?: boolean | null;
  emailActivationKey?: string | null;
  enabled?: boolean | null;
  passwordResetKey?: string | null;
  passwordResetDate?: Date | null;
  dataKey?: string | null;
  dataDate?: Date | null;
  dataAgreement?: boolean | null;
  operationHourStart?: Date | null;
  operationHourEnd?: Date | null;
  aboutMe?: string | null;
  website?: string | null;
  description?: string | null;
  isShowEducationProfile?: boolean | null;
  isHibernate?: boolean | null;
  isStatementDataReal?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  photo?: ICbAttachmentNoAuth | null;
  banner?: ICbAttachmentNoAuth | null;
  cbLocation?: ICbLocation | null;
  cbUserSvcTypes?: ICbUserSvcType[] | null;
  cbUserSkills?: ICbUserSkill[] | null;
  cbUserEducations?: ICbUserEducation[] | null;
  cbUserCrts?: ICbUserCrt[] | null;
  cbUserLanguages?: ICbUserLanguage[] | null;
  cbUserSocialMedias?: ICbUserSocialMedia[] | null;
  cbUserEmergencyContacts?: ICbUserEmergencyContact[] | null;
  cbUserStories?: ICbUserStory[] | null;
  cbUserTimes?: ICbUserTime[] | null;
  cbUserBookmarkAggs?: ICbUserBookmarkAgg[] | null;
  cbUserData?: ICbUserData[] | null;
  cbUserFcms?: ICbUserFcm[] | null;
  cbUserFans?: ICbUserFans[] | null;
  cbUserActivities?: ICbUserActivity[] | null;
  cbUserAvatarAggs?: ICbUserAvatarAgg[] | null;
  cbUserBannerAggs?: ICbUserBannerAgg[] | null;
  cbPromoUserAggs?: ICbPromoUserAgg[] | null;
  user?: IUser | null;
  email?: string | null; // transient
  password?: string | null; // transient
  isSkillPersisted?: boolean | null; // transient
  isShowOnlineStatus?: boolean | null;
  languagePreference?: string | null;
  inActiveDate?: Date | null;
  inActiveReason?: string | null;
  inActiveReasonMore?: string | null;
  uploadLinkPhotoProfile?: string | null; // transient
  uploadLinkBanner?: string | null; // transient
  isUpdatePhotoProfile?: boolean | null; // transient
  isUpdatePhotoBanner?: boolean | null; // transient
  firstName?: string | null;
  lastName?: string | null;
  userStatus?: CbUserStatusType | null;
  externalPin?: string | null;
  cbUserDataId?: number | null;
  updateType?: CbProfileUpdateType | null;
}

export class CbUserInfo implements ICbUserInfo {
  constructor(
    public id?: string,
    public loginType?: CbLoginType | null,
    public defaultSvcType?: CbSvcType | null,
    public isHidePhoto?: boolean | null,
    public username?: string | null,
    public signature?: string | null,
    public birthDate?: Date | null,
    public gender?: CbGender | null,
    public nationality?: CbNationality | null,
    public identityType?: CbIdentityType | null,
    public identityNo?: string | null,
    public timeZone?: string | null,
    public timeZoneUTC?: string | null,
    public timeZoneDetail?: string | null,
    public langKey?: string | null,
    public emailVerificationOpt?: boolean | null,
    public phoneVerificationOpt?: boolean | null,
    public phoneCode?: string | null,
    public phone?: string | null,
    public phoneActivated?: boolean | null,
    public phoneActivationKey?: string | null,
    public emailActivated?: boolean | null,
    public emailActivationKey?: string | null,
    public enabled?: boolean | null,
    public passwordResetKey?: string | null,
    public passwordResetDate?: Date | null,
    public dataKey?: string | null,
    public dataDate?: Date | null,
    public dataAgreement?: boolean | null,
    public operationHourStart?: Date | null,
    public operationHourEnd?: Date | null,
    public aboutMe?: string | null,
    public website?: string | null,
    public description?: string | null,
    public isShowEducationProfile?: boolean | null,
    public isHibernate?: boolean | null,
    public isStatementDataReal?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public photo?: ICbAttachmentNoAuth | null,
    public banner?: ICbAttachmentNoAuth | null,
    public cbLocation?: ICbLocation | null,
    public cbUserSvcTypes?: ICbUserSvcType[] | null,
    public cbUserSkills?: ICbUserSkill[] | null,
    public cbUserEducations?: ICbUserEducation[] | null,
    public cbUserCrts?: ICbUserCrt[] | null,
    public cbUserLanguages?: ICbUserLanguage[] | null,
    public cbUserSocialMedias?: ICbUserSocialMedia[] | null,
    public cbUserEmergencyContacts?: ICbUserEmergencyContact[] | null,
    public cbUserStories?: ICbUserStory[] | null,
    public cbUserTimes?: ICbUserTime[] | null,
    public cbUserBookmarkAggs?: ICbUserBookmarkAgg[] | null,
    public cbUserData?: ICbUserData[] | null,
    public cbUserFcms?: ICbUserFcm[] | null,
    public cbUserFans?: ICbUserFans[] | null,
    public cbUserActivities?: ICbUserActivity[] | null,
    public cbUserAvatarAggs?: ICbUserAvatarAgg[] | null,
    public cbUserBannerAggs?: ICbUserBannerAgg[] | null,
    public cbPromoUserAggs?: ICbPromoUserAgg[] | null,
    public user?: IUser | null,
    public email?: string | null, // transient
    public password?: string | null, // transient
    public isSkillPersisted?: boolean | null, // transien
    public isShowOnlineStatus?: boolean | null,
    public languagePreference?: string | null,
    public inActiveDate?: Date | null,
    public inActiveReason?: string | null,
    public inActiveReasonMore?: string | null,
    public uploadLinkPhotoProfile?: string | null, // transient
    public uploadLinkBanner?: string | null, // transient
    public isUpdatePhotoProfile?: boolean | null, // transient
    public isUpdatePhotoBanner?: boolean | null, // transient
    public firstName?: string | null,
    public lastName?: string | null,
    public userStatus?: CbUserStatusType | null,
    public externalPin?: string | null,
    public cbUserDataId?: number | null,
    public updateType?: CbProfileUpdateType | null
  ) {
    this.inActiveReason = this.inActiveReason ?? null;
    // this.isHidePhoto = this.isHidePhoto ?? false;
    // this.emailVerificationOpt = this.emailVerificationOpt ?? false;
    // this.phoneVerificationOpt = this.phoneVerificationOpt ?? false;
    // this.phoneActivated = this.phoneActivated ?? false;
    // this.emailActivated = this.emailActivated ?? false;
    // this.enabled = this.enabled ?? false;
    // this.dataAgreement = this.dataAgreement ?? false;
    // this.isShowEducationProfile = this.isShowEducationProfile ?? false;
    // this.isHibernate = this.isHibernate ?? false;
    // this.isStatementDataReal = this.isStatementDataReal ?? false;
    // this.isActive = this.isActive ?? false;
    // this.photo = this.photo ?? new CbAttachmentNoAuth();
    // this.banner = this.banner ?? new CbAttachmentNoAuth();
    // this.cbLocation = this.cbLocation ?? new CbLocation();
    // this.cbUserSvcTypes = this.cbUserSvcTypes ?? [];
    // this.cbUserSkills = this.cbUserSkills ?? [];
    // this.cbUserEducations = this.cbUserEducations ?? [];
    // this.cbUserCrts = this.cbUserCrts ?? [];
    // this.cbUserLanguages = this.cbUserLanguages ?? [];
    // this.cbUserSocialMedias = this.cbUserSocialMedias ?? [];
    // this.cbUserEmergencyContacts = this.cbUserEmergencyContacts ?? [];
    // this.cbUserStories = this.cbUserStories ?? [];
    // this.cbUserTimes = this.cbUserTimes ?? [];
    // this.cbUserBookmarkAggs = this.cbUserBookmarkAggs ?? [];
    // this.cbUserData = this.cbUserData ?? [];
    // this.cbUserFcms = this.cbUserFcms ?? [];
    // this.cbUserFans = this.cbUserFans ?? [];
    // this.cbUserActivities = this.cbUserActivities ?? [];
    // this.cbUserAvatarAggs = this.cbUserAvatarAggs ?? [];
    // this.cbUserBannerAggs = this.cbUserBannerAggs ?? [];
    // this.cbPromoUserAggs = this.cbPromoUserAggs ?? [];
    // this.user = this.user ?? new User();
    // this.isUpdatePhotoBanner = this.isUpdatePhotoBanner ?? false;
    // this.isShowEducationProfile = this.isShowEducationProfile ?? false;
  }
}
