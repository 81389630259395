var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-footer",
      attrs: { "data-cy": "footer", id: "footer-loader" },
    },
    [
      _c("hr", { staticClass: "mt-0 mb-5 footer-bs-d-none" }),
      _vm._v(" "),
      _c("div", { staticClass: "c-container footer-bs-py-0" }, [
        _c("div", { staticClass: "c-footer-container" }, [
          _c("div", {}, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center mb-4 mr-3 cursor-pointer",
                on: { click: _vm.isCollapseInside },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "m-0 font-roboto font-weight-700 font-size-24 cj-color-black-primary w-100",
                  },
                  [_vm._v("Inside Worker")]
                ),
                _vm._v(" "),
                _c("icon", {
                  staticClass:
                    "ic_arrow_dropdown_up_down cj-fill-black-primary my-auto vac-d-none-web icon-rotasi",
                  class: _vm.isShowInside ? "actived" : "",
                  attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wrapper-collapse",
                class: _vm.isShowInside ? "show" : "",
              },
              _vm._l(
                _vm.getSvcByType(_vm.cbSvcType.INSIDE),
                function (item, index) {
                  return _c(
                    "p",
                    {
                      key: index,
                      staticClass:
                        "m-0 font-roboto font-weight-300 cj-color-black-primary font-size-14 cursor-pointer mb-3",
                      attrs: { "data-gtm": `footer-${item.name}-button` },
                      on: {
                        click: function ($event) {
                          return _vm.handleToService(item.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center mb-4 mr-3 cursor-pointer",
                attrs: { "data-gtm": "collapse-footer-field-button" },
                on: { click: _vm.isCollapseField },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "m-0 font-roboto font-weight-700 font-size-24 cj-color-black-primary w-100",
                  },
                  [_vm._v("Field Worker")]
                ),
                _vm._v(" "),
                _c("icon", {
                  staticClass:
                    "ic_arrow_dropdown_up_down cj-fill-black-primary my-auto vac-d-none-web icon-rotasi",
                  class: _vm.isShowField ? "actived" : "",
                  attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wrapper-collapse",
                class: _vm.isShowField ? "show" : "",
              },
              _vm._l(
                _vm.getSvcByType(_vm.cbSvcType.FIELD),
                function (item, index) {
                  return _c(
                    "p",
                    {
                      key: index,
                      staticClass:
                        "m-0 font-roboto cj-color-black-primary font-weight-300 font-size-14 mb-3 cursor-pointer",
                      attrs: { "data-gtm": `footer-${item.name}-button` },
                      on: {
                        click: function ($event) {
                          return _vm.handleToService(item.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " + _vm._s(item.name) + "\n          "
                      ),
                    ]
                  )
                }
              ),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center mb-4 mr-3 cursor-pointer",
                attrs: { "data-gtm": "collapse-footer-support-button" },
                on: { click: _vm.isCollapseSupport },
              },
              [
                _c("p", {
                  staticClass:
                    "m-0 font-roboto font-weight-700 font-size-24 cj-color-black-primary w-100",
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbgwApp.cbSvc.support.title")),
                  },
                }),
                _vm._v(" "),
                _c("icon", {
                  staticClass:
                    "ic_arrow_dropdown_up_down cj-fill-black-primary my-auto vac-d-none-web icon-rotasi",
                  class: _vm.isShowSupport ? "actived" : "",
                  attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wrapper-collapse",
                class: _vm.isShowSupport ? "show" : "",
              },
              _vm._l(_vm.dummyFooters, function (item, index) {
                return _c(
                  "p",
                  {
                    key: index,
                    staticClass:
                      "m-0 font-roboto font-weight-300 font-size-14 mb-3 cj-color-black-primary cursor-pointer",
                    attrs: {
                      "data-gtm": `footer-support-${item.support}-button`,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleToPages(item.support)
                      },
                    },
                  },
                  [
                    item.title == "Support"
                      ? [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t(item.name)) +
                              "\n            "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center mb-4 mr-3 cursor-pointer",
                on: { click: _vm.isCollapseFilterAbout },
              },
              [
                _c("p", {
                  staticClass:
                    "m-0 font-roboto font-weight-700 font-size-24 cj-color-black-primary w-100",
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbgwApp.cbSvc.about.title")),
                  },
                }),
                _vm._v(" "),
                _c("icon", {
                  staticClass:
                    "ic_arrow_dropdown_up_down cj-fill-black-primary my-auto vac-d-none-web icon-rotasi",
                  class: _vm.isShowFilterAbout ? "actived" : "",
                  attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "wrapper-collapse",
                class: _vm.isShowFilterAbout ? "show" : "",
              },
              _vm._l(_vm.dummyFooters, function (item, index) {
                return _c(
                  "p",
                  {
                    key: index,
                    staticClass:
                      "m-0 font-roboto font-weight-300 cj-color-black-primary font-size-14 mb-3 cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.handleToPages(item.about)
                      },
                    },
                  },
                  [
                    item.title == "About"
                      ? [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t(item.name)) +
                              "\n            "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "c-container footer-bs-py-0" }, [
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "pt-3 c-footer-bank-mobile" }, [
            _c("div", { staticClass: "mb-2" }, [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-700 font-size-24 cj-color-black-primary",
                  domProps: {
                    textContent: _vm._s(_vm.$t("cbGlobal.supportedPayment")),
                  },
                },
                [_vm._v("Supported Payment")]
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("c-footer-copyright", {
        staticClass: "mt-3",
        attrs: { isShowTabFooter: _vm.isShowTabFooter },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "c-footer-d-flex-mobile" }, [
      _c("img", {
        staticClass: "ml-3 c-footer-size-img",
        attrs: {
          src: require("@/../content/images/bank/mastercard.svg"),
          alt: "Bank Image",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "ml-3 c-footer-size-img",
        attrs: {
          src: require("@/../content/images/bank/visa.svg"),
          alt: "Bank Image",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "ml-3 c-footer-size-img",
        attrs: {
          src: require("@/../content/images/bank/bca.svg"),
          alt: "Bank Image",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "ml-3 c-footer-size-img",
        attrs: {
          src: require("@/../content/images/bank/bri.svg"),
          alt: "Bank Image",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "ml-3 c-footer-size-img",
        attrs: {
          src: require("@/../content/images/bank/bsi.svg"),
          alt: "Bank Image",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "ml-3 c-footer-size-img",
        attrs: {
          src: require("@/../content/images/bank/bni.svg"),
          alt: "Bank Image",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "ml-3 c-footer-size-img",
        attrs: {
          src: require("@/../content/images/bank/mandiri.svg"),
          alt: "Bank Image",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }