var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-navbar-search-container cj-bg-white-primary rounded-pill",
    },
    [
      _c(
        "div",
        {
          staticClass: "wrap-search",
          attrs: { "data-cy": "wrap-advance-search" },
        },
        [
          _c("icon", {
            staticClass: "cj-stroke-grey-tertiary mr-2 ic_search",
            attrs: { name: "ic_search", width: "18px", height: "18px" },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            ref: "searchInput",
            staticClass: "rounded-pill cj-field-border-default cj-field-input",
            staticStyle: { "max-height": "2.1rem" },
            attrs: {
              "data-gtm": "advance-search",
              "data-cy": "advance-search",
              name: "advance-search",
              type: "text",
              placeholder: _vm.$t("cbgwApp.cbComponent.cInput.search"),
            },
            domProps: { value: _vm.search },
            on: {
              keypress: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                _vm.toAdvanceSearch(_vm.search)
                _vm.closeModal()
              },
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.search = $event.target.value
                },
                function ($event) {
                  return _vm.inputSeachAutocomplete($event.target.value)
                },
              ],
              blur: function ($event) {
                _vm.toggle = false
              },
              focus: function ($event) {
                _vm.toggle = true
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.toggle
        ? _c(
            "div",
            {
              staticClass:
                "w-100 mt-2 results w-100 cj-bg-white-primary wrap-border-grey-tertiary border-radius-card px-4 py-3 height-scroll",
              staticStyle: {
                position: "absolute",
                top: "10",
                "z-index": "2 !important",
                "max-width": "calc(100% - 7px)",
              },
              on: {
                mousedown: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.searchInputed.length > 1
                ? _c(
                    "div",
                    [
                      _vm.isFetching
                        ? _c(
                            "div",
                            [
                              _c("c-loader", {
                                staticClass: "d-flex m-auto",
                                attrs: { show: _vm.isFetching, infinite: true },
                              }),
                            ],
                            1
                          )
                        : _vm.cbVwSearchAutocompletes.length === 0 &&
                          !_vm.isFetching
                        ? _c(
                            "div",
                            {
                              attrs: { "data-cy": "advance-search-not-found" },
                            },
                            [_vm._v("not found")]
                          )
                        : _vm._l(
                            _vm.cbVwSearchAutocompletes,
                            function (found, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "result w-100",
                                  attrs: { "data-cy": "advance-search-item" },
                                },
                                [
                                  _c("div", { staticClass: "w-100" }, [
                                    _c("div", { staticClass: "d-flex w-100" }, [
                                      found.searchType == "SVC_CAT" &&
                                      found.showGroup
                                        ? _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex py-2" },
                                                [
                                                  found.searchType == "SVC_CAT"
                                                    ? _c("icon", {
                                                        staticClass:
                                                          "cj-fill-black-primary my-auto mr-2",
                                                        attrs: {
                                                          name: "ic_category",
                                                          width: "24px",
                                                          height: "24px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("p", {
                                                    staticClass:
                                                      "font-roboto cj-color-black-primary font-size-16 font-weight-700 my-auto",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "cbgwApp.cbHome.cbHomeBuyer.category"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "d-flex w-100" }, [
                                      found.searchType == "SVC" &&
                                      found.showGroup
                                        ? _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex py-2" },
                                                [
                                                  _c("icon", {
                                                    staticClass:
                                                      "cj-fill-black-primary my-auto mr-2",
                                                    attrs: {
                                                      name: "ic_cube",
                                                      width: "24px",
                                                      height: "24px",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("span", {
                                                    staticClass:
                                                      "font-roboto cj-color-black-primary font-size-16 font-weight-700 my-auto",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "cbgwApp.cbHome.cbHomeBuyer.service"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "d-flex w-100" }, [
                                      found.searchType == "AUCTION_PROJECT" &&
                                      found.showGroup
                                        ? _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex py-2" },
                                                [
                                                  _c("icon", {
                                                    staticClass:
                                                      "cj-fill-black-primary cj-stroke-grey-tertiary my-auto mr-2",
                                                    attrs: {
                                                      name: "ic_project",
                                                      width: "24px",
                                                      height: "24px",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("p", {
                                                    staticClass:
                                                      "font-roboto cj-color-black-primary font-size-16 font-weight-700 my-auto",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "cbgwApp.cbHome.cbHomeBuyer.auctionProject"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "d-flex w-100" }, [
                                      found.searchType == "USER_PARTNER" &&
                                      found.showGroup
                                        ? _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex py-2" },
                                                [
                                                  _c("icon", {
                                                    staticClass:
                                                      "my-auto mr-2 cj-fill-black-primary my-auto",
                                                    attrs: {
                                                      name: "ic_profile",
                                                      width: "24px",
                                                      height: "24px",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("p", {
                                                    staticClass:
                                                      "font-roboto cj-color-black-primary font-size-16 font-weight-700 my-auto",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.$t(
                                                          "cbgwApp.cbHome.cbHomeBuyer.user"
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "d-flex w-100" }, [
                                      found.searchType == "PAW" &&
                                      found.showGroup
                                        ? _c(
                                            "span",
                                            { staticClass: "d-block" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex py-2" },
                                                [
                                                  _c("icon", {
                                                    staticClass:
                                                      "cj-fill-black-primary my-auto mr-2",
                                                    attrs: {
                                                      name: "ic_paw_outline",
                                                      width: "24px",
                                                      height: "24px",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "font-roboto cj-color-black-primary font-size-16 font-weight-700 my-auto",
                                                    },
                                                    [_vm._v("PAW")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "cursor-pointer font-weight-400 cj-bg-red-secondary-hover py-1 text-capitalize",
                                      attrs: { "data-cy": "search-result" },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.SECURITY_VHTML(
                                            _vm.$options.filters.boldText(
                                              found.searchLabel,
                                              _vm.searchInputed
                                            )
                                          )
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.selectResult(found)
                                          _vm.closeModal()
                                        },
                                        mousedown: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            }
                          ),
                    ],
                    2
                  )
                : _c(
                    "div",
                    [
                      [
                        _vm.recentSearch && _vm.recentSearch.length > 0
                          ? _c(
                              "div",
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "search-text font-weight-700 mb-2",
                                  },
                                  [_vm._v("Recent Search")]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.reverseRecentSearch,
                                  function (recent, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "d-flex justify-content-between mb-2",
                                        on: {
                                          click: function ($event) {
                                            _vm.toAdvanceSearch(recent)
                                            _vm.closeModal()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex my-auto mr-2 recent-text-ellipses cursor-pointer",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "mr-2 my-auto" },
                                              [
                                                _c("icon", {
                                                  staticClass:
                                                    "cj-fill-grey-tertiary",
                                                  attrs: {
                                                    name: "ic_time_outlined",
                                                    width: "1.406rem",
                                                    height: "1.406rem",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-roboto cj-color-black-primary font-weight-400 font-size-14 my-auto recent-text-ellipses",
                                              },
                                              [_vm._v(_vm._s(recent))]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "my-auto",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteRecent(recent)
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon-x-circle-fill", {
                                              staticClass:
                                                "font-size-18 cj-color-grey-secondary",
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "result w-100" }, [
                          _c(
                            "div",
                            {
                              staticClass: "popular-search",
                              attrs: { "data-cy": "popular-search" },
                            },
                            [
                              _c("p", {
                                staticClass:
                                  "font-roboto cj-color-black-primary font-size-14 font-weight-700",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("cbgwApp.cbSvc.popularSearch")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("c-popular-search-list", {
                                on: {
                                  "item-clicked": function ($event) {
                                    return _vm.closeModal()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }