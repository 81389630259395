var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-pd-description paw-detail-svc-description border-radius-card wrap-border-grey-tertiary",
    },
    [
      _c(
        "div",
        { staticClass: "px-3 pt-3 pb-2" },
        [
          _c("p", {
            staticClass: "font-roboto cj-color-black-primary mb-2",
            class: _vm.isMobile
              ? "font-weight-500 font-size-14"
              : "font-weight-700 font-size-16",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbPaw.detail.serviceDescription")
              ),
            },
          }),
          _vm._v(" "),
          _c("c-textarea-preview", {
            staticClass:
              "font-catamaran font-weight-400 cj-color-black-primary font-size-14 text-break-all",
            attrs: {
              dataCyCustom: "paw-description",
              text: _vm.cbTextNoauth.content,
              collapseText: true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.getFilterMetadatas && _vm.getFilterMetadatas.length > 0
        ? _c("hr", { staticClass: "mx-3" })
        : _vm._e(),
      _vm._v(" "),
      _vm.getFilterMetadatas && _vm.getFilterMetadatas.length > 0
        ? _c("div", { staticClass: "paw-detail-metadata px-2" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap" },
              _vm._l(_vm.getFilterMetadatas, function (metadata, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-12 col-md-4 px-2 mb-2" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "paw-detail-metadata-item",
                        attrs: { "data-cy": "paw-detail-meta" },
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto mb-2 font-weight-700 cj-color-black-primary",
                            class: _vm.isMobile
                              ? "font-size-14"
                              : "font-size-16",
                            attrs: { "data-cy": "paw-detail-metadata" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("capitalEachSpace")(metadata.name)
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(
                          _vm.getFilterMetavalue(metadata.id),
                          function (value, indexMetavalue) {
                            return _c(
                              "div",
                              { key: indexMetavalue, staticClass: "d-flex" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex my-auto mr-1" },
                                  [
                                    _c("icon", {
                                      staticClass:
                                        "cj-fill-red-primary my-auto",
                                      attrs: {
                                        name: "ic_check",
                                        width: "1.5rem",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-roboto cj-color-black-primary font-weight-400 font-size-14 my-auto",
                                    attrs: {
                                      "data-cy": "paw-detail-metavalue",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("capitalEachSpace")(value)
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("hr", { staticClass: "mx-3 mt-2" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "paw-detail-language px-3 pb-3" },
        [
          _c(
            "p",
            {
              staticClass:
                "mb-2 font-roboto font-weight-700 cj-color-black-primary",
              class: _vm.isMobile ? "font-size-14" : "font-size-16",
              attrs: { "data-cy": "paw-detail-language" },
              domProps: { textContent: _vm._s(_vm.$t("cbGlobal.language")) },
            },
            [_vm._v("\n      LANGUAGE\n    ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.cbVwPaw.cbUserLanguages, function (pawLanguage, index2) {
            return _c("div", { key: index2, staticClass: "d-flex mb-2" }, [
              _c("div", { staticClass: "mr-2 mt-1" }, [
                _c("img", {
                  staticClass: "cb-flag-size",
                  attrs: {
                    src: _vm.$options.filters.LAZY(
                      `${_vm.$env.CDN_CB}/content/flag/ic_${pawLanguage.name}.png`,
                      18,
                      "ic-lazy-flag.png"
                    ),
                    onerror:
                      _vm.$options.filters.LAZY_ERROR("ic-lazy-flag.png"),
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "ml-1 font-roboto cj-color-black-primary font-weight-400 font-size-14 my-auto",
                  attrs: { "data-cy": "paw-detail-language" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.getLanguageTextEnId(
                          pawLanguage.name,
                          pawLanguage.level
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ])
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }