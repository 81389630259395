var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-ap-pitch-popup" },
    [
      _vm.isMobile && _vm.visiblePopupPriceType
        ? _c(
            "c-popup-bottom",
            {
              attrs: {
                "data-cy": "auction-popup-price-type",
                id: "popup-auction-pitch-price-type",
              },
              model: {
                value: _vm.visiblePopupPriceType,
                callback: function ($$v) {
                  _vm.visiblePopupPriceType = $$v
                },
                expression: "visiblePopupPriceType",
              },
            },
            [
              _c("div", { staticClass: "p-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between mb-3 py-2 border-grey-tertiary-bottom-1",
                  },
                  [
                    _c("p", {
                      staticClass:
                        "font-roboto cj-color-black-primary font-size-16 font-weight-600",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cAuctionProject.priceType"
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "font-roboto font-size-16 cj-btn-text-primary font-weight-600",
                      class: {
                        disabled: !_vm.popupValuePriceType,
                      },
                      attrs: {
                        disabled: !_vm.popupValuePriceType,
                        "data-cy": "btn-popup-update-price-type",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbAuctionProject.update")
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.localCbAuctionProjectPreOffer.priceType =
                            _vm.popupValuePriceType
                          _vm.$emit(
                            "changeValue",
                            "cbAuctionProjectPreOffer",
                            _vm.localCbAuctionProjectPreOffer
                          )
                          _vm.visiblePopupPriceType = false
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "container-popup" },
                  [
                    _c("c-autocomplete-new", {
                      staticClass:
                        "font-roboto font-size-14 cj-color-black-primary font-weight-400 cj-field-not-border",
                      attrs: {
                        items: _vm.priceTypeList,
                        "item-text": "value",
                        "item-value": "key",
                        placeholder: _vm.$t(
                          "cbgwApp.cbAuctionProject.selectHere"
                        ),
                        isShowDropdown: true,
                        isShowListItem: true,
                        inputStyle: "cj-field-border-default rounded px-3",
                        disableTyping: true,
                      },
                      model: {
                        value: _vm.popupValuePriceType,
                        callback: function ($$v) {
                          _vm.popupValuePriceType = $$v
                        },
                        expression: "popupValuePriceType",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile && _vm.visiblePopupDeliveryTimeType
        ? _c(
            "c-popup-bottom",
            {
              attrs: {
                "data-cy": "auction-popup-delivery-time-type",
                id: "popup-auction-pitch-delivery-time-type",
              },
              model: {
                value: _vm.visiblePopupDeliveryTimeType,
                callback: function ($$v) {
                  _vm.visiblePopupDeliveryTimeType = $$v
                },
                expression: "visiblePopupDeliveryTimeType",
              },
            },
            [
              _c("div", { staticClass: "p-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between mb-3 py-2 border-grey-tertiary-bottom-1",
                  },
                  [
                    _c("p", {
                      staticClass:
                        "font-roboto cj-color-black-primary font-size-16 font-weight-600",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cAuctionProject.deliveryTimeType"
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("p", {
                      staticClass:
                        "font-roboto font-size-16 cj-btn-text-primary font-weight-600",
                      class: {
                        disabled: !_vm.popupValueDeliveryTimeType,
                      },
                      attrs: {
                        disabled: !_vm.popupValueDeliveryTimeType,
                        "data-cy": "btn-popup-update-delivery-time",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbAuctionProject.update")
                        ),
                      },
                      on: {
                        click: function ($event) {
                          _vm.localCbAuctionProjectPreOffer.deliveryTimeType =
                            _vm.popupValueDeliveryTimeType
                          _vm.localCbAuctionProjectPreOffer.deliveryTime = null
                          _vm.$emit(
                            "changeValue",
                            "cbAuctionProjectPreOffer",
                            _vm.localCbAuctionProjectPreOffer
                          )
                          _vm.visiblePopupDeliveryTimeType = false
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "container-popup" },
                  [
                    _c("c-autocomplete-new", {
                      staticClass:
                        "font-roboto font-size-14 cj-color-black-primary font-weight-400 cj-field-not-border",
                      attrs: {
                        items: _vm.deliveryTimeTypes,
                        "item-text": "value",
                        "item-value": "key",
                        placeholder: _vm.$t(
                          "cbgwApp.cbAuctionProject.selectHere"
                        ),
                        isShowDropdown: true,
                        isShowListItem: true,
                        inputStyle: "cj-field-border-default rounded px-3",
                        disableTyping: true,
                      },
                      model: {
                        value: _vm.popupValueDeliveryTimeType,
                        callback: function ($$v) {
                          _vm.popupValueDeliveryTimeType = $$v
                        },
                        expression: "popupValueDeliveryTimeType",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }