import { ICbTextNoAuth } from './cb-text-no-auth.model';
import { CbBillboardTypeStatus } from './enumerations/cb-billboard-type-status.model';
import { CbBillboardType } from './enumerations/cb-billboard-type.model';
import { CbCurrency } from './enumerations/cb-currency.model';
import { CbPromoMethod } from './enumerations/cb-promo-method.model';
import { CbPromoPayMethod } from './enumerations/cb-promo-pay-method.model';
import { CbPromoValueType } from './enumerations/cb-promo-value-type.model';

export interface ICbVwBillboard {
  id?: number;
  billboardType?: CbBillboardType;
  seq?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  status?: CbBillboardTypeStatus;
  externalId?: number | null;
  externalEntity?: string | null;
  url?: string | null;
  isActive?: boolean | null;
  titleID?: string | null;
  titleEN?: string | null;
  btnTitleID?: string | null;
  btnTitleEN?: string | null;
  isCallToActionButton?: boolean | null;
  descriptionLangIdId?: number | null;
  descriptionLangEnId?: number | null;
  bannerLangIdId?: number | null;
  bannerLangEnId?: number | null;
  promoId?: number | null;
  titlePromo?: number | null;
  code?: string | null;
  promoValueType?: CbPromoValueType | null;
  promoMethod?: CbPromoMethod | null;
  promoValue?: number | null;
  currency?: CbCurrency | null;
  priodeStartDate?: Date | null;
  priodeEndDate?: Date | null;
  payPromoMethod?: CbPromoPayMethod | null;
  downloadLinkBannerId?: string | null;
  downloadLinkBannerEn?: string | null;
  descriptionId?: ICbTextNoAuth | null;
  descriptionEn?: ICbTextNoAuth | null;
  dtlIdId?: number | null;
  dtlIdEn?: number | null;
}

export class CbVwBillboard implements ICbVwBillboard {
  constructor(
    public id?: number,
    public billboardType?: CbBillboardType,
    public seq?: number | null,
    public startDate?: Date | null,
    public endDate?: Date | null,
    public status?: CbBillboardTypeStatus,
    public externalId?: number | null,
    public externalEntity?: string | null,
    public url?: string | null,
    public isActive?: boolean | null,
    public titleID?: string | null,
    public titleEN?: string | null,
    public btnTitleID?: string | null,
    public btnTitleEN?: string | null,
    public isCallToActionButton?: boolean | null,
    public descriptionLangIdId?: number | null,
    public descriptionLangEnId?: number | null,
    public bannerLangIdId?: number | null,
    public bannerLangEnId?: number | null,
    public promoId?: number | null,
    public titlePromo?: number | null,
    public code?: string | null,
    public promoValueType?: CbPromoValueType | null,
    public promoMethod?: CbPromoMethod | null,
    public promoValue?: number | null,
    public currency?: CbCurrency | null,
    public priodeStartDate?: Date | null,
    public priodeEndDate?: Date | null,
    public payPromoMethod?: CbPromoPayMethod | null,
    public downloadLinkBannerId?: string | null,
    public downloadLinkBannerEn?: string | null,
    public descriptionId?: ICbTextNoAuth | null,
    public descriptionEn?: ICbTextNoAuth | null,
    public dtlIdId?: number | null,
    public dtlIdEn?: number | null
  ) {}
}
