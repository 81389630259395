var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-fp-input-balance-tab d-flex flex-wrap" }, [
    _c(
      "div",
      { staticClass: "col-6 col-lg-3 px-2 pb-2" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-sm btn-tab-wrapper btn-block",
            attrs: {
              "data-gtm": "topu-option-50k",
              "data-cy": "btn-balance-tab-option",
            },
            on: {
              click: function ($event) {
                return _vm.selectAmount(50000)
              },
            },
          },
          [_vm._v("IDR 50.000")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-6 col-lg-3 px-2 pb-2" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-sm btn-tab-wrapper btn-block",
            attrs: {
              "data-gtm": "topu-option-100k",
              "data-cy": "btn-balance-tab-option",
            },
            on: {
              click: function ($event) {
                return _vm.selectAmount(100000)
              },
            },
          },
          [_vm._v("IDR 100.000")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-6 col-lg-3 px-2 pb-2 pt-2 pt-lg-0" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-sm btn-tab-wrapper btn-block",
            attrs: {
              "data-gtm": "topu-option-300k",
              "data-cy": "btn-balance-tab-option",
            },
            on: {
              click: function ($event) {
                return _vm.selectAmount(300000)
              },
            },
          },
          [_vm._v("IDR 300.000")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-6 col-lg-3 px-2 pb-2 pt-2 pt-lg-0" },
      [
        _c(
          "b-button",
          {
            staticClass: "btn-sm btn-tab-wrapper btn-block",
            attrs: {
              "data-gtm": "topu-option-500k",
              "data-cy": "btn-balance-tab-option",
            },
            on: {
              click: function ($event) {
                return _vm.selectAmount(500000)
              },
            },
          },
          [_vm._v("IDR 500.000")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }