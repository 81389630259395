import axios from 'axios';

import { ICbVwAuctionProjectPreOffer } from '@/shared/model/cb-vw-auction-project-pre-offer.model';

const baseApiUrl = 'services/cbcorems/api/cb-vw-auction-project-pre-offers';

export default class PCbVwAuctionProjectPreOfferService {
  public find(id: number, cancelToken?: any): Promise<ICbVwAuctionProjectPreOffer> {
    return new Promise<ICbVwAuctionProjectPreOffer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveBySenderId(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-sender-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
