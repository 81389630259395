export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif'];
// type mov ketika di upload  berubah menjadi quicktime
export const VIDEO_TYPES = ['m4v', 'mov', 'mp4', 'quicktime'];
export const AUDIO_TYPES = ['mp3', 'wav', 'mpeg', 'mid', 'midi', 'ogg'];
export const PDF_TYPES = ['pdf'];
export const DOC_TYPES = ['docx', 'doc', 'pages', 'application/msword'];
export const XLS_TYPES = ['xls', 'xlsx', 'numbers', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'vnd.ms-excel'];
export const PPT_TYPES = [
  'ppr',
  'pptx',
  'key',
  'ppt',
  'vnd.ms-powerpoint',
  'vnd.openxmlformats-officedocument.presentationml.presentation',
];
export const MAX_SIZE_10MB = 10485760;
export const MAX_SIZE_500MB = 524288000;
export const MAX_SIZE_100MB = 104857600;
