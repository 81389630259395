var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-time-region-m" },
    [
      _c(
        "div",
        { staticClass: "gear-wrapper", on: { click: _vm.handleShowModal } },
        [
          _c("b-icon-gear", {
            staticClass: "cj-color-red-primary font-size-18",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-center time-regio-wrapper" },
        _vm._l(_vm.cbUserTimezones, function (cbUserTimeZone, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "d-flex align-items-center px-2 overflow-hidden",
              class: {
                "custom-wrap-solo": _vm.cbUserTimezones.length <= 1,
                "custom-wrap-multi": _vm.cbUserTimezones.length >= 2,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "mr-2 custom-m-time mb-1",
                  class: cbUserTimeZone.isActive == true ? "" : "d-none",
                },
                [
                  index == 0
                    ? [
                        _c("c-item-time-region", {
                          attrs: {
                            time: cbUserTimeZone.userZoneDateTime,
                            width: "7rem",
                          },
                        }),
                      ]
                    : [
                        _c("c-item-time-region", {
                          attrs: {
                            time: cbUserTimeZone.userZoneDateTime,
                            width: "7rem",
                          },
                        }),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mb-2 custom-m-time custom-width",
                  class: cbUserTimeZone.isActive == true ? "d-flex" : "d-none",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pr-1 d-flex flex-column",
                      style: `${
                        cbUserTimeZone.isActive == true
                          ? "border-right: 1px solid var(--cj-color-grey-tertiary)"
                          : ""
                      }`,
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-auto mb-auto font-roboto cj-color-black-primary font-size-12 font-weight-500",
                          staticStyle: { "margin-top": "3px !important" },
                        },
                        [
                          index == 0
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.timeFormat) +
                                    "\n            "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.timeFormat) +
                                    "\n              "
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "ml-auto mt-auto font-roboto cj-color-black-primary font-size-9 font-weight-500 text-nowrap",
                        },
                        [
                          index == 0
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.dateFormat) +
                                    "\n            "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.dateFormat) +
                                    "\n              "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "ml-1 flex-column",
                      class:
                        cbUserTimeZone.isActive == true ? " d-flex" : "d-none",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-auto font-roboto font-weight-700 font-size-14 cj-color-black-primary text-ellipsis oneline",
                        },
                        [
                          index == 0
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.timeZoneCode) +
                                    "\n            "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.timeZoneCode) +
                                    "\n              "
                                ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mt-auto font-roboto font-weight-500 font-size-9 cj-color-black-primary text-ellipsis oneline",
                        },
                        [
                          index == 0
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.timeZoneName) +
                                    "\n            "
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(cbUserTimeZone.timeZoneName) +
                                    "\n              "
                                ),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "hide-footer": "",
          "hide-header": "",
          size: "lg",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "modal-class": "vac-custom-modal-b-modal",
          id: "modal-visible-time-region-update",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-time-region-m-update", {
                  attrs: {
                    leftArrow: true,
                    cbUserTimezones: _vm.cbUserTimezones,
                  },
                  on: { closeModal: cancel },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }