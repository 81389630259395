var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-paw-detail",
      class: _vm.isMobile
        ? ""
        : _vm.paramId
        ? "navbar-distance c-container"
        : "",
      attrs: { "data-cy": "paw-detail-modal" },
    },
    [
      _vm.isFetchingCbVwPaw || _vm.isFetchingCbVwPawPkgAgg
        ? _c(
            "div",
            {
              staticClass:
                "d-flex w-100 justify-content-center align-items-center p-3 cj-bg-white-primary w-100 size-loading",
            },
            [
              _c("c-loader", {
                staticClass: "d-flex m-auto",
                attrs: {
                  show: _vm.isFetchingCbVwPaw || _vm.isFetchingCbVwPawPkgAgg,
                  infinite: true,
                },
              }),
            ],
            1
          )
        : _c("div", [
            _c(
              "div",
              { staticClass: "paw-detail-header text-center" },
              [
                !_vm.isMobile
                  ? _c(
                      "div",
                      { staticClass: "paw-detail-icon-wrapper" },
                      [
                        _c("icon", {
                          style: `fill:${_vm.getCbVwSvcsColor(
                            _vm.cbVwPaw.svcServiceId
                          )}`,
                          attrs: { name: "ic_paw_fill", width: "4.625rem" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.isMobile ? true : !_vm.paramId)
                  ? _c(
                      "button",
                      {
                        staticClass: "paw-detail-icon-close-wrapper",
                        attrs: {
                          "data-cy": "close-paw-detail",
                          type: "button",
                        },
                        on: { click: _vm.onBack },
                      },
                      [
                        _vm.isMobile
                          ? _c("font-awesome-icon", {
                              staticClass:
                                "cb-icon-size-arrow-left cj-color-black-primary",
                              attrs: { icon: "fa-solid fa-arrow-left" },
                            })
                          : _c("b-icon-x-circle-fill", {
                              staticClass:
                                "font-size-18 cj-color-grey-secondary",
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMobile
                  ? _c("c-pd-title", {
                      staticClass: "cb-max-w30 mx-auto pt-4 pb-3",
                      attrs: { cbVwPaw: _vm.cbVwPaw },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.cbVwPaw.status != _vm.cbPawStatus.ACTIVE
                  ? _c("c-pd-status", {
                      staticClass: "pb-4",
                      class: _vm.isMobile ? "paw-detail-status-position" : "",
                      attrs: { cbVwPaw: _vm.cbVwPaw },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "paw-detail-container" },
              [
                _c("c-pd-slideshow-main", {
                  attrs: {
                    cbVwPawDetailAttPortfolioMains:
                      _vm.cbVwPawDetailAttPortfolioMains,
                    cbVwPaw: _vm.cbVwPaw,
                  },
                  on: {
                    openModalReport: _vm.openModalReport,
                    saveBookmark: _vm.saveBookmark,
                    openModalCShare: _vm.openModalCShare,
                  },
                }),
                _vm._v(" "),
                _c("c-pd-profile-user", {
                  attrs: {
                    cbVwPaw: _vm.cbVwPaw,
                    cbVwUser: _vm.cbVwUser,
                    cbVwUserSummary: _vm.cbVwUserSummary,
                    cbVwAvgResponseTime: _vm.cbVwAvgResponseTime,
                    cbVwMedals: _vm.cbVwMedals,
                  },
                  on: { toProfile: _vm.toProfile },
                }),
                _vm._v(" "),
                _vm.isMobile
                  ? _c("c-pd-title", {
                      staticClass: "px-4 py-2 mb-2",
                      attrs: {
                        cbVwPaw: _vm.cbVwPaw,
                        getCbVwSvcsColor: _vm.getCbVwSvcsColor,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "px-4 py-2 pt-lg-4 mb-2" },
                  [
                    _c("c-pd-description", {
                      attrs: {
                        cbVwPaw: _vm.cbVwPaw,
                        cbTextNoauth: _vm.cbTextNoauth,
                        getFilterMetadatas: _vm.getFilterMetadatas,
                        getFilterMetavalue: _vm.getFilterMetavalue,
                        getLanguageTextEnId: _vm.getLanguageTextEnId,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "paw-detail-package py-2 mb-2",
                    attrs: {
                      "data-cy": "paw-detail-package",
                      id: "paw-detail-package-id",
                    },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "px-4 mb-2 font-roboto cj-color-black-primary mb-2",
                        class: _vm.isMobile
                          ? "font-weight-500 font-size-14"
                          : "font-weight-700 font-size-20",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cbGlobal.package")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "swiper",
                      {
                        staticClass: "px-4 c-pd-package-slideshow-wrapper",
                        class: !_vm.isPremiumPkg ? "actived" : "",
                        attrs: {
                          "slides-per-view": "auto",
                          "space-between": 16,
                          loop: false,
                          threshold: 10,
                        },
                      },
                      [
                        _c(
                          "swiper-slide",
                          {
                            nativeOn: {
                              click: function ($event) {
                                _vm.tabPkg = _vm.CbPkgType.REGULAR
                              },
                            },
                          },
                          [
                            _c("c-pd-package-item", {
                              staticClass: "card-package-regular",
                              class:
                                _vm.tabPkg === _vm.CbPkgType.REGULAR
                                  ? "actived"
                                  : "",
                              attrs: {
                                "data-cy": "card-package-regular",
                                title: _vm._f("capitalFirstLetter")(
                                  _vm.CbPkgType.REGULAR
                                ),
                                pkgType: _vm.CbPkgType.REGULAR,
                                tabPkg: _vm.tabPkg,
                                cbVwPaw: _vm.cbVwPaw,
                                showMoreNonePkg: _vm.showMoreNonePkg,
                                cbPawPkgAggs: _vm.cbPawPkgAggs,
                                seletedPackage:
                                  _vm.tabPkg === _vm.CbPkgType.REGULAR,
                                isloader: _vm.isloader,
                              },
                              on: {
                                handleShowDetailPackage:
                                  _vm.handleShowDetailPackage,
                                handleChat: _vm.handleChat,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isPremiumPkg
                          ? _c(
                              "swiper-slide",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.tabPkg = _vm.CbPkgType.PREMIUM
                                  },
                                },
                              },
                              [
                                _c("c-pd-package-item", {
                                  staticClass: "card-package-premium",
                                  class:
                                    _vm.tabPkg === _vm.CbPkgType.PREMIUM
                                      ? "actived"
                                      : "",
                                  attrs: {
                                    "data-cy": "card-package-premium",
                                    title: _vm._f("capitalFirstLetter")(
                                      _vm.CbPkgType.PREMIUM
                                    ),
                                    pkgType: _vm.CbPkgType.PREMIUM,
                                    tabPkg: _vm.tabPkg,
                                    cbVwPaw: _vm.cbVwPaw,
                                    showMoreNonePkg: _vm.showMoreNonePkg,
                                    cbPawPkgAggs: _vm.cbPawPkgAggs,
                                    seletedPackage:
                                      _vm.tabPkg === _vm.CbPkgType.PREMIUM,
                                    isloader: _vm.isloader,
                                  },
                                  on: {
                                    handleShowDetailPackage:
                                      _vm.handleShowDetailPackage,
                                    handleChat: _vm.handleChat,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "py-2 mb-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "px-4 d-flex align-items-center" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary",
                            class: _vm.isMobile
                              ? "font-size-14 font-weight-500"
                              : "font-weight-700 font-size-20",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("cbGlobal.portfolio")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 cj-btn-text-primary font-roboto font-weight-500 font-underline",
                            class: _vm.isMobile
                              ? "font-size-12"
                              : "font-size-16",
                            on: {
                              click: function ($event) {
                                _vm.isSeeAllPortfolio = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("cbGlobal.seeAll")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("c-pd-portfolio-slideshow", {
                      attrs: {
                        cbVwPawDetailAttPortfolios:
                          _vm.cbVwPawDetailAttPortfolios,
                        countPortfolio: _vm.countPortfolio,
                      },
                      on: {
                        handleShowPortfolio: _vm.handleShowPortfolioDetail,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.cbVwPaw.svcType == _vm.CbSvcType.FIELD
                  ? _c("div", { staticClass: "px-4 py-2 mb-2" }, [
                      _c("p", {
                        staticClass: "mb-2 font-roboto cj-color-black-primary",
                        class: _vm.isMobile
                          ? "font-size-14 font-weight-500"
                          : "font-weight-700 font-size-20",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbPaw.detail.schedule")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-radius-card wrap-border-grey-tertiary p-2 p-sm-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "cb-custom-full-calendar" },
                            [
                              _c("full-calendar", {
                                ref: "fullCalendarRef",
                                attrs: {
                                  options: _vm.calendarOptions,
                                  "data-cy": "detail-paw-book-schedule",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "pt-2 pt-sm-3" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cb-custom-time-booked wrap-border-grey-tertiary p-2 p-sm-3",
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "m-0 font-roboto font-weight-500 font-size-12 cj-color-black-primary",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("cbGlobal.bookedSchedule")
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Booked Schedule\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "time-booked-wrapper" },
                                  [
                                    _vm.cbVwUserScheduleFilter &&
                                    _vm.cbVwUserScheduleFilter.length == 0
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "my-1 font-roboto font-weight-500 font-size-12 cj-color-black-primary",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.noOrderDate) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.cbVwUserScheduleFilter,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "d-flex mb-1",
                                            attrs: {
                                              "data-cy":
                                                "detail-paw-book-schedule-list",
                                            },
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "cb-list-time-booked m-0 mr-3 font-roboto font-weight-500 font-size-12 cj-color-black-primary",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getScheduleDateDisplay(
                                                        item
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.cbVwPaw.authorId ===
                                            _vm.currentUser.id
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 font-roboto font-weight-500 font-size-12 color-status",
                                                    class:
                                                      item.status == "COMPLETED"
                                                        ? "status-completed"
                                                        : "",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          item.status == "OFFER"
                                                            ? _vm.$t(
                                                                "cbGlobal.status.waitingForPayment"
                                                              )
                                                            : item.status
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "px-lg-3 pb-0 pt-3 pb-lg-2 pt-lg-2 mb-2",
                    class: _vm.isMobile ? "cj-bg-white-secondary" : "",
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-wrap mx-min-2" }, [
                      _vm.cbVwPawDetailAttDocs &&
                      _vm.cbVwPawDetailAttDocs.length > 0
                        ? _c(
                            "div",
                            { staticClass: "col-12 col-lg-6 px-2 mb-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "c-paw-detail-card px-4 py-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary",
                                          class: _vm.isMobile
                                            ? "font-size-14 font-weight-500"
                                            : "font-weight-700 font-size-16",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Reference\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.cbVwPawDetailAttDocs &&
                                      _vm.cbVwPawDetailAttDocs.length > 2
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-2 cj-btn-text-primary font-roboto font-weight-500 font-underline",
                                              class: _vm.isMobile
                                                ? "font-size-12"
                                                : "font-size-14",
                                              on: {
                                                click: function ($event) {
                                                  _vm.isSeeAllReference = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("cbGlobal.seeAll")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.cbVwPawDetailAttDocs.slice(0, 2),
                                    function (item, index) {
                                      return _c("c-pd-reference-item", {
                                        key: index,
                                        attrs: {
                                          item: item,
                                          isShowDownload: true,
                                        },
                                        on: { downloadDoc: _vm.downloadDoc },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cbVwPawCrts?.length > 0
                        ? _c(
                            "div",
                            { staticClass: "col-12 col-lg-6 px-2 mb-3" },
                            [
                              _c(
                                "div",
                                { staticClass: "c-paw-detail-card px-4 py-3" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mb-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary",
                                          class: _vm.isMobile
                                            ? "font-size-14 font-weight-500"
                                            : "font-weight-700 font-size-16",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "cbGlobal.licenseCertification"
                                              )
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  License and/or Certification\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.cbVwPawCrts &&
                                      _vm.cbVwPawCrts.length > 2
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-2 cj-btn-text-primary font-roboto font-weight-500 font-underline",
                                              class: _vm.isMobile
                                                ? "font-size-12"
                                                : "font-size-14",
                                              on: {
                                                click: function ($event) {
                                                  _vm.isSeeAllLicense = true
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("cbGlobal.seeAll")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.cbVwPawCrts.slice(0, 2),
                                    function (item, index) {
                                      return _c("c-pd-license-item", {
                                        key: index,
                                        attrs: {
                                          item: item,
                                          currentLanguage: _vm.currentLanguage,
                                          cbVwPaw: _vm.cbVwPaw,
                                          currentUser: _vm.currentUser,
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cbVwPawDetailAttTags &&
                      _vm.cbVwPawDetailAttTags.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-lg-6 px-2 mb-3 box-order",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "c-paw-detail-card px-4 py-3" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary",
                                      class: _vm.isMobile
                                        ? "font-size-14 font-weight-500"
                                        : "font-weight-700 font-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Tags\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-wrap cb-gap-2",
                                    },
                                    _vm._l(
                                      _vm.cbVwPawDetailAttTags,
                                      function (item, index) {
                                        return _c("c-pd-tags-or-tools-item", {
                                          key: index,
                                          attrs: { value: item.title },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cbVwPawDetailAttTools &&
                      _vm.cbVwPawDetailAttTools.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-lg-6 px-2 mb-3 box-order",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "c-paw-detail-card px-4 py-3" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "mb-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary",
                                      class: _vm.isMobile
                                        ? "font-size-14 font-weight-500"
                                        : "font-weight-700 font-size-16",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Tools\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-wrap cb-gap-2",
                                    },
                                    _vm._l(
                                      _vm.cbVwPawDetailAttTools,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          { key: index },
                                          [
                                            _c("c-pd-tags-or-tools-item", {
                                              attrs: {
                                                isShowImg: true,
                                                img: item.cbAttachmentNoAuthId,
                                                value: item.title,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "py-2 mb-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "px-4 d-flex align-items-center mb-2" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary",
                            class: _vm.isMobile
                              ? "font-size-18 font-weight-500"
                              : "font-weight-700 font-size-20",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("cbGlobal.review")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 cj-btn-text-primary font-roboto font-weight-500 font-underline",
                            class: _vm.isMobile
                              ? "font-size-12"
                              : "font-size-14",
                            attrs: { "data-cy": "detail-paw-see-all" },
                            on: { click: _vm.handleSeeAllReview },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("cbGlobal.seeAll")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "px-4 d-flex align-items-center mb-2" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "mb-2 flex-grow-1 mr-2 font-roboto font-weight-700 cj-color-grey-secondary",
                            class: _vm.isMobile
                              ? "font-size-14"
                              : "font-size-16",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("cbGlobal.overallRating")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("b-icon-star-fill", {
                              staticClass:
                                "cj-color-status-warning mr-1 my-auto font-size-18",
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "my-auto font-roboto font-weight-500 font-size-18 cj-color-black-primary",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.averageRatingReview
                                        ? _vm.averageRatingReview
                                        : 0
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "ml-2 my-auto font-roboto font-weight-400 font-size-12 cj-color-grey-secondary",
                                attrs: { "data-cy": "detail-paw-rating" },
                              },
                              [
                                _vm._v(
                                  "\n              (" +
                                    _vm._s(_vm.queryCountUserReview) +
                                    ")\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("c-pd-review-slideshow", {
                      attrs: {
                        cbVwUserReviews: _vm.cbVwUserReviews.slice(0, 2),
                      },
                      on: {
                        toProfile: _vm.toProfile,
                        handleSeeAllReview: _vm.handleSeeAllReview,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isMobile &&
            _vm.tabPkg &&
            _vm.cbVwPaw.authorId !== _vm.currentUser.id
              ? _c(
                  "div",
                  { staticClass: "position-interested-wrapper" },
                  [
                    _c(
                      "c-button",
                      {
                        staticClass:
                          "font-roboto text-center font-weight-500 font-size-14",
                        attrs: {
                          fullWidth: "",
                          disabled: _vm.isloader,
                          "data-cy": "im-intrested",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleScrollPackage.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm.isloader
                          ? _c("font-awesome-icon", {
                              attrs: { icon: "sync", spin: _vm.isloader },
                            })
                          : [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("cbGlobal.imInterested")) +
                                  "\n        "
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
      _vm._v(" "),
      _c(
        "c-popup-bottom",
        {
          attrs: {
            id: `popup-bottom-detail-package-${
              _vm.tabPkg === _vm.CbPkgType.REGULAR ? "regular" : "premium"
            }`,
          },
          model: {
            value: _vm.isShowDetailPackage,
            callback: function ($$v) {
              _vm.isShowDetailPackage = $$v
            },
            expression: "isShowDetailPackage",
          },
        },
        [
          _vm.tabPkg === _vm.CbPkgType.REGULAR
            ? _c("c-pd-package-item", {
                staticClass: "card-package-regular p-0",
                attrs: {
                  title: _vm._f("capitalFirstLetter")(_vm.CbPkgType.REGULAR),
                  pkgType: _vm.CbPkgType.REGULAR,
                  tabPkg: _vm.tabPkg,
                  cbVwPaw: _vm.cbVwPaw,
                  showMoreNonePkg: _vm.showMoreNonePkg,
                  cbPawPkgAggs: _vm.cbPawPkgAggs,
                  seletedPackage: _vm.tabPkg === _vm.CbPkgType.REGULAR,
                  seletedPopUp: true,
                  isloader: _vm.isloader,
                },
                on: {
                  closeDetailPackage: _vm.closeDetailPackage,
                  handleChat: _vm.handleChat,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.tabPkg === _vm.CbPkgType.PREMIUM
            ? _c("c-pd-package-item", {
                staticClass: "card-package-premium p-0",
                attrs: {
                  title: _vm._f("capitalFirstLetter")(_vm.CbPkgType.PREMIUM),
                  pkgType: _vm.CbPkgType.PREMIUM,
                  tabPkg: _vm.tabPkg,
                  cbVwPaw: _vm.cbVwPaw,
                  showMoreNonePkg: _vm.showMoreNonePkg,
                  cbPawPkgAggs: _vm.cbPawPkgAggs,
                  seletedPackage: _vm.tabPkg === _vm.CbPkgType.PREMIUM,
                  seletedPopUp: true,
                  isloader: _vm.isloader,
                },
                on: {
                  closeDetailPackage: _vm.closeDetailPackage,
                  handleChat: _vm.handleChat,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        _vm.isMobile ? "c-popup-bottom" : "b-modal",
        {
          tag: "component",
          attrs: {
            id: "component-see-all-portfolio",
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.isSeeAllPortfolio,
            callback: function ($$v) {
              _vm.isSeeAllPortfolio = $$v
            },
            expression: "isSeeAllPortfolio",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center c-paw-detail-sticky" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "py-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary font-weight-700",
                  class: _vm.isMobile ? "font-size-18" : " font-size-24",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("cbGlobal.portfolio")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cursor-pointer py-2 p-3 m-n3",
                  on: {
                    click: function ($event) {
                      _vm.isSeeAllPortfolio = false
                    },
                  },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-paw-detail-scroll" },
            [
              _c("c-pd-portfolio-list", {
                attrs: {
                  cbVwPawDetailAttPortfolios: _vm.cbVwPawDetailAttPortfolios,
                  countPortfolio: _vm.countPortfolio,
                },
                on: { handleShowPortfolio: _vm.handleShowPortfolioDetail },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        _vm.isMobile ? "c-popup-bottom" : "b-modal",
        {
          tag: "component",
          attrs: {
            id: "component-show-portfolio",
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.isShowPortfolio,
            callback: function ($$v) {
              _vm.isShowPortfolio = $$v
            },
            expression: "isShowPortfolio",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center c-paw-detail-sticky" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "py-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary font-weight-700",
                  class: _vm.isMobile ? "font-size-16" : " font-size-20",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.cbVwPawDetailAttPortfolioDetail.title) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cursor-pointer py-2 p-3 m-n3",
                  attrs: { "data-cy": "portfolio-preview-btn-close" },
                  on: {
                    click: function ($event) {
                      _vm.isShowPortfolio = false
                    },
                  },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "c-paw-detail-scroll py-4" }, [
            _c(
              "div",
              {
                staticClass: "mx-auto",
                class: _vm.isMobile ? "cb-max-w30" : "",
              },
              _vm._l(
                _vm.cbVwPawDetailAttPortfolioDetails,
                function (item, index) {
                  return _c("c-pd-portfolio-preview", {
                    key: index,
                    attrs: { item: item },
                    on: { noLoginPortfolioDetail: _vm.noLoginPortfolioDetail },
                  })
                }
              ),
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        _vm.isMobile ? "c-popup-bottom" : "b-modal",
        {
          tag: "component",
          attrs: {
            id: "component-see-all-license",
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.isSeeAllLicense,
            callback: function ($$v) {
              _vm.isSeeAllLicense = $$v
            },
            expression: "isSeeAllLicense",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center c-paw-detail-sticky" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "py-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary font-weight-700",
                  class: _vm.isMobile ? "font-size-18" : " font-size-24",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbGlobal.licenseCertification")
                    ),
                  },
                },
                [_vm._v("\n        License and/or Certification\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cursor-pointer py-2 p-3 m-n3",
                  on: {
                    click: function ($event) {
                      _vm.isSeeAllLicense = false
                    },
                  },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-paw-detail-scroll" },
            _vm._l(_vm.cbVwPawCrts, function (item, index) {
              return _c("c-pd-license-item", {
                key: index,
                attrs: {
                  item: item,
                  currentLanguage: _vm.currentLanguage,
                  cbVwPaw: _vm.cbVwPaw,
                  currentUser: _vm.currentUser,
                },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        _vm.isMobile ? "c-popup-bottom" : "b-modal",
        {
          tag: "component",
          attrs: {
            id: "component-see-all-reference",
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.isSeeAllReference,
            callback: function ($$v) {
              _vm.isSeeAllReference = $$v
            },
            expression: "isSeeAllReference",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center c-paw-detail-sticky" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "py-2 flex-grow-1 mr-2 font-roboto cj-color-black-primary font-weight-700",
                  class: _vm.isMobile ? "font-size-18" : " font-size-24",
                },
                [_vm._v("\n        Reference\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cursor-pointer py-2 p-3 m-n3",
                  on: {
                    click: function ($event) {
                      _vm.isSeeAllReference = false
                    },
                  },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-paw-detail-scroll" },
            _vm._l(_vm.cbVwPawDetailAttDocs, function (item, index) {
              return _c("c-pd-reference-item", {
                key: index,
                attrs: { item: item, isShowDownload: true },
                on: { downloadDoc: _vm.downloadDoc },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        _vm.isMobile ? "c-popup-bottom" : "b-modal",
        {
          tag: "component",
          attrs: {
            id: "component-see-all-review",
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.isSeeAllReview,
            callback: function ($$v) {
              _vm.isSeeAllReview = $$v
            },
            expression: "isSeeAllReview",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center c-paw-detail-sticky text-center",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "py-2 pr-2 flex-grow-1 mx-auto font-roboto cj-color-black-primary",
                  class: _vm.isMobile
                    ? "font-weight-500 font-size-18"
                    : "font-weight-600 font-size-20",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.queryCountUserReview) +
                      " " +
                      _vm._s(_vm.$t("cbGlobal.review")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cursor-pointer py-2 p-3 m-n3",
                  on: {
                    click: function ($event) {
                      _vm.isSeeAllReview = false
                    },
                  },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "c-paw-detail-scroll" },
            [
              _c("c-pd-review-detail", {
                attrs: {
                  cbVwUserReviews: _vm.cbVwUserReviews,
                  cbVwUserReviewRatings: _vm.cbVwUserReviewRatings,
                  queryCountUserReview: _vm.queryCountUserReview,
                  averageRatingReview: _vm.averageRatingReview,
                  sortReview: _vm.sortReview,
                },
                on: { clear: _vm.clear },
                scopedSlots: _vm._u([
                  {
                    key: "cPdReviewSortBy",
                    fn: function () {
                      return [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sortReview,
                                expression: "sortReview",
                              },
                            ],
                            staticClass:
                              "m-0 p-0 font-roboto cj-color-black-primary font-weight-400 font-size-14 select-icon-hide",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.sortReview = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.clear,
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "id,desc" } }, [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("cbgwApp.cbPaw.detail.latest")
                                  ),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "id,asc" } }, [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("cbgwApp.cbPaw.detail.oldest")
                                  ),
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "cPdReviewLoader",
                    fn: function () {
                      return [
                        _vm.totalItemsUserReview > _vm.itemsPerPageUserReview
                          ? _c(
                              "infinite-loading",
                              {
                                ref: "infiniteLoading",
                                attrs: {
                                  slot: "append",
                                  identifier: _vm.infiniteId,
                                  distance: 5,
                                },
                                on: { infinite: _vm.loadMoreUserReview },
                                slot: "append",
                              },
                              [
                                _c("div", {
                                  attrs: { slot: "no-more" },
                                  slot: "no-more",
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "spinner" },
                                    slot: "spinner",
                                  },
                                  [
                                    _c("c-loader", {
                                      staticClass: "d-flex m-auto",
                                      attrs: { show: true, infinite: true },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          size: "lg",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "modal-class": "vac-custom-modal-b-modal",
          id: "modal-c-report",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("c-profile-report", {
                  attrs: { username: _vm.cbVwUser.username },
                  on: {
                    submitReportUser: _vm.handleSubmitReportUser,
                    handleCloseModalReport: _vm.closeModalReport,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-c-paw-set-work-location",
          size: "lg",
          "hide-footer": "",
          "hide-header": "",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "modal-class": "vac-custom-modal-b-modal",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-paw-set-work-location", {
                  attrs: { parentCbLocation: _vm.cbPawPreOrder.cbLocation },
                  on: { cancel: cancel, submit: _vm.handleOpenBookingDate },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          size: "lg",
          "content-class": "vac-custom-modal-content-b-modal",
          "dialog-class": "vac-custom-modal-size-b-modal",
          "modal-class": "vac-custom-modal-b-modal",
          id: "modal-c-paw-booking-date",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-paw-booking-date", {
                  attrs: {
                    parentCbVwUserSchedules: _vm.cbVwUserSchedules,
                    userId: _vm.cbVwUser && _vm.cbVwUser.id,
                    withoutModalStepFormOrders: true,
                    isLoadingButtonPawBookingDate: _vm.isloader,
                  },
                  on: {
                    cancel: cancel,
                    back: function ($event) {
                      _vm
                        .globalService()
                        .closeGlobalDialog(
                          _vm.$root,
                          "modal-c-paw-booking-date"
                        )
                      _vm
                        .globalService()
                        .openGlobalDialog(
                          _vm.$root,
                          "modal-c-paw-set-work-location"
                        )
                    },
                    submit: _vm.handleSubmitFromSetBookingDate,
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "content-class": "vac-custom-modal-content-b-modal",
            "dialog-class": "vac-custom-modal-size-b-modal",
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-p-login-m-pop-up",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [_c("p-login-m", { attrs: { closePopUp: _vm.closePopUp } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }