import { ICbUserInfo } from '@/shared/model/cb-user-info.model';

import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';
export interface ICbUserBookmarkAgg {
  id?: number;
  dataId?: number | null;
  bookmarkType?: CbBookmarkType | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbUserInfo?: ICbUserInfo | null;
}

export class CbUserBookmarkAgg implements ICbUserBookmarkAgg {
  constructor(
    public id?: number,
    public dataId?: number | null,
    public bookmarkType?: CbBookmarkType | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbUserInfo?: ICbUserInfo | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
