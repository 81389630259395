export enum CmTktChannel {
  EMAIL = 'EMAIL',

  FREELANCER_APPLICATION_FORM = 'FREELANCER_APPLICATION_FORM',

  PAW_APPLICATION_FORM = 'PAW_APPLICATION_FORM',

  WEB_FORM = 'WEB_FORM',

  LIVE_CHAT = 'LIVE_CHAT',

  BANK_ACCOUNT_APPLICATION_FORM = 'BANK_ACCOUNT_APPLICATION_FORM',
}
