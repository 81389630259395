import { render, staticRenderFns } from "./c-app-modals.vue?vue&type=template&id=79ddd8f4&"
import script from "./c-app-modals.component.ts?vue&type=script&lang=js&"
export * from "./c-app-modals.component.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports