var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-billboard-detail",
      class: _vm.isMobile ? "cb-position-h" : "",
    },
    [
      _c(
        "div",
        {
          class: _vm.isMobile
            ? "d-flex cb-position-sticky-top"
            : "icon-close-billboard",
        },
        [
          _vm.isMobile
            ? _c(
                "p",
                {
                  staticClass:
                    "flex-grow-1 font-roboto cj-color-black-primary font-size-18 font-weight-500",
                },
                [_vm._v("Klikjob Billboard")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center cursor-pointer p-3 m-n3",
              on: { click: _vm.close },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "c-header-billboard-detail-wrapper cb-img-wrapper cb-ratio-2x1",
        },
        [
          _c("img", {
            staticClass: "cb-img-cover",
            attrs: {
              src: _vm.$options.filters.LAZY(
                `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                  _vm.currentLanguage == "id"
                    ? _vm.cbVwBillboard.bannerLangIdId
                      ? _vm.cbVwBillboard.bannerLangIdId
                      : _vm.cbVwBillboard.bannerLangEnId
                    : _vm.cbVwBillboard.bannerLangEnId
                    ? _vm.cbVwBillboard.bannerLangEnId
                    : _vm.cbVwBillboard.bannerLangIdId
                }`,
                800,
                "ic-lazy-paw.png"
              ),
              onerror: _vm.$options.filters.LAZY_ERROR("ic-lazy-paw.png"),
              alt: "img",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-weight-500 py-3",
          class: _vm.isMobile ? "font-size-16 " : "font-size-20 ",
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.currentLanguage == "id"
                  ? _vm.cbVwBillboard.titleID
                    ? _vm.cbVwBillboard.titleID
                    : _vm.cbVwBillboard.titleEN
                  : _vm.cbVwBillboard.titleEN
                  ? _vm.cbVwBillboard.titleEN
                  : _vm.cbVwBillboard.titleID
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.cbVwBillboard.billboardType == _vm.billboardType.EXISTING
        ? _c("div", { staticClass: "billboard-detail-code-wrapper p-3 mb-3" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-6 px-2 d-flex flex-column align-items-center justify-content-center border-grey-tertiary-right-1",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center cb-icon-position-wrapper cb-larger-icon icon-promo-period font-roboto cj-color-black-primary font-weight-300",
                      class: _vm.isMobile ? "font-size-10 " : "font-size-12 ",
                      staticStyle: { "line-height": "24px" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbGlobal.promo.promoPeriod")
                        ),
                      },
                    },
                    [_vm._v("\n          Promo Period\n        ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-6 px-2 d-flex flex-column align-items-center justify-content-center",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center cb-icon-position-wrapper cb-larger-icon icon-minimum-transaction font-roboto cj-color-black-primary font-weight-300",
                      class: _vm.isMobile ? "font-size-10 " : "font-size-12 ",
                      staticStyle: { "line-height": "24px" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbGlobal.promo.minimumTransaction")
                        ),
                      },
                    },
                    [_vm._v("\n          Minimum Transaction\n        ")]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex mb-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-6 px-2 d-flex flex-column align-items-center justify-content-center border-grey-tertiary-right-1",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center font-roboto cj-color-black-primary font-weight-500",
                      class: _vm.isMobile ? "font-size-12 " : "font-size-16",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("cbGlobal.until")) +
                          " " +
                          _vm._s(
                            _vm._f("formatDateTimeDefault")(
                              _vm.cbVwBillboard.priodeEndDate
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-6 px-2 d-flex flex-column align-items-center justify-content-center",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-center font-roboto cj-color-black-primary font-weight-500",
                      class: _vm.isMobile ? "font-size-12 " : "font-size-16",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.cbVwBillboard.currency) +
                          " " +
                          _vm._s(
                            _vm._f("formatCurrency")(
                              _vm.cbVwBillboard.minAmount,
                              _vm.cbVwBillboard.currency
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-items-center px-3 mt-1 wrap-border-grey-tertiary",
                staticStyle: { "border-radius": "20px" },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "py-1 flex-grow-1 font-roboto cj-color-red-primary font-weight-700 pr-2 border-grey-tertiary-right-1",
                    class: _vm.isMobile ? "font-size-12 " : "font-size-16",
                    staticStyle: { "letter-spacing": "1.2px" },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.cbVwBillboard.code) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.canCopy
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "d-flex cb-toollip-copy cursor-pointer pl-2 py-1 pr-0 cb-tooltip-copy-container",
                        on: {
                          click: function ($event) {
                            return _vm.copy(_vm.cbVwBillboard.code)
                          },
                        },
                      },
                      [
                        _c("icon", {
                          staticClass: "cj-stroke-red-primary",
                          attrs: { name: "ic_copy", width: "1.4rem" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "pb-3" }, [
        _vm.cbVwBillboard.billboardType == _vm.billboardType.EXISTING
          ? _c(
              "p",
              {
                staticClass:
                  "mb-2 font-roboto cj-color-black-primary font-weight-600 font-size-14",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbGlobal.promo.minimumTransaction")
                  ),
                },
              },
              [_vm._v("\n      Term and Condition\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$options.filters.SECURITY_VHTML(_vm.cbTextNoauth.content)
            ),
          },
        }),
      ]),
      _vm._v(" "),
      _vm.cbVwBillboard.isCallToActionButton ||
      _vm.cbVwBillboard.billboardType == _vm.billboardType.EXISTING
        ? _c(
            "div",
            {
              class: _vm.isMobile
                ? "cb-position-sticky-bottom border-grey-tertiary-top-1 mt-auto"
                : "",
            },
            [
              _c(
                "c-button",
                {
                  staticClass: "font-roboto font-weight-500",
                  class: _vm.isMobile ? "font-size-18" : "font-size-20",
                  attrs: { fullWidth: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleBillboard(_vm.cbVwBillboard)
                    },
                  },
                },
                [
                  _vm.cbVwBillboard.billboardType == _vm.billboardType.EXISTING
                    ? [
                        !_vm.cbVwBillboard.cbVwPromo.isUserHasClaimed
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("cbGlobal.promo.claimCoupon")) +
                                  " "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.$t("cbGlobal.use")) + " "
                              ),
                            ]),
                      ]
                    : [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.currentLanguage == "id"
                                ? _vm.cbVwBillboard.btnTitleEN
                                  ? _vm.cbVwBillboard.btnTitleEN
                                  : _vm.cbVwBillboard.btnTitleID
                                : _vm.cbVwBillboard.btnTitleID
                                ? _vm.cbVwBillboard.btnTitleID
                                : _vm.cbVwBillboard.btnTitleEN
                            ) +
                            "\n      "
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }