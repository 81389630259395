import axios from 'axios';

import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';

const baseApiUrl = 'services/cbcorems/api/cb-vw-paw-orders';

export default class PCbVwPawOrderService {
  public find(id: number, cancelToken?: any): Promise<ICbVwPawOrder> {
    return new Promise<ICbVwPawOrder>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-login', { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveOnProgress(user1: string, user2: string, params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/on-progress/' + user1 + '/' + user2, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievActiveByBuyerLogin(cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/active/by-buyer-login', { cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievCancelByBuyerLogin(cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/canceled/by-buyer-login', { cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  ///cb-vw-paw-orders/count/{loginType}/by-login
  public countCbVwPawOrderActive(loginType: CbLoginType): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/count/${loginType}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveOnProgressByLogin(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/on-progress/by-login', { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveCountOnProgressByLogin(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/count-project-on-progress/partner/by-login', { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveSubProjectValueOnProgressByLogin(cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/sum-project-value-on-progress/by-login', { cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveTotalDeliveryTimeBeforeOverdue(orderId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/total-delivery-time-before-overdue/' + orderId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
