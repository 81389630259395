import axios from 'axios';

import { ICbUserCookie } from '@/shared/model/cb-user-cookie.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-cookies';

export default class PCbUserCookieService {
  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveCountByLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/count/by-login`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbUserCookie): Promise<ICbUserCookie> {
    return new Promise<ICbUserCookie>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByLogin(entity: ICbUserCookie): Promise<ICbUserCookie> {
    return new Promise<ICbUserCookie>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
