import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CProgressBar extends Vue {
  @Prop({ type: Number, default: 0 }) public progress: number;
  @Prop({ type: Number, default: 35 }) public radius: number;

  public stroke = 4;
  public normalizedRadius = 0;
  public circumference = this.normalizedRadius * 2 * Math.PI;

  public mounted() {
    this.normalizedRadius = this.radius - this.stroke * 2;
  }

  public get strokeDashoffset() {
    return this.circumference - (this.progress / 100) * this.circumference;
  }
}
