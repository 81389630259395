var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    ref: "textRef",
    staticClass: "cj-field-input",
    attrs: {
      type: "text",
      "data-cy": _vm.dataCyCustom
        ? `input-number-${_vm.dataCyCustom}`
        : "input-number",
      "data-gtm": _vm.dataGTMCustom
        ? `input-number-${_vm.dataGTMCustom}`
        : "input-number",
      min: _vm.min,
      max: _vm.max,
      disabled: _vm.disabled,
      inputmode: "numeric",
    },
    domProps: { value: _vm.value },
    on: { input: _vm.handleInput, paste: _vm.handlePaste },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }