var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-pd-package-item cursor-pointer",
      class: _vm.seletedPopUp ? "p-0 pd-pop-up-wrapper" : "py-3",
      attrs: { "data-cy": "popup-im-interested" },
    },
    [
      _c(
        "div",
        {
          staticClass: "c-pd-package-item-header",
          class: _vm.seletedPopUp
            ? "c-paw-detail-sticky py-2"
            : "mx-3 pb-3 mb-3",
        },
        [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c(
              "p",
              {
                staticClass:
                  "mb-2 flex-grow-1 mr-2 c-pd-package-item-title font-size-18",
                class: {
                  "font-size-14 font-weight-500":
                    _vm.isMobile && !_vm.seletedPopUp,
                  "font-weight-700 font-size-16": !_vm.isMobile,
                  "font-size-18 font-weight-700": _vm.seletedPopUp,
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            ),
            _vm._v(" "),
            _vm.seletedPopUp
              ? _c(
                  "div",
                  {
                    staticClass: "cursor-pointer mb-2",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("closeDetailPackage")
                      },
                    },
                  },
                  [
                    _c("b-icon-x-circle-fill", {
                      staticClass: "font-size-18 cj-color-grey-secondary",
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.cbPawPkgAggs(_vm.pkgType, _vm.CbPkgCoreType.PRICE) &&
          _vm.cbPawPkgAggs(_vm.pkgType, _vm.CbPkgCoreType.PRICE).length > 0
            ? _c("div", { staticClass: "c-pd-package-item-price" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "c-pd-package-item-price-type font-roboto cj-color-black-primary font-weight-500",
                    class: _vm.isMobile ? "font-size-8" : "font-size-10",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.cbPawPkgAggs(_vm.pkgType, _vm.CbPkgCoreType.PRICE)
                            .length > 0
                            ? _vm.cbPawPkgAggs(
                                _vm.pkgType,
                                _vm.CbPkgCoreType.PRICE
                              )[0].valueIdfier
                            : "-"
                        ) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto cj-color-black-primary font-weight-700",
                    class: _vm.isMobile ? "font-size-20" : "font-size-32",
                    attrs: { "data-cy": "detail-paw-package-price" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.cbPawPkgAggs(
                              _vm.pkgType,
                              _vm.CbPkgCoreType.PRICE
                            ).length > 0
                              ? _vm.cbPawPkgAggs(
                                  _vm.pkgType,
                                  _vm.CbPkgCoreType.PRICE
                                )[0].value
                              : "-",
                            _vm.cbPawPkgAggs(
                              _vm.pkgType,
                              _vm.CbPkgCoreType.PRICE
                            )[0].valueIdfier
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column pd-package-content-wrapper h-100" },
        [
          _c(
            "div",
            {
              staticClass: "mx-3 mb-3",
              class: _vm.seletedPopUp
                ? "pt-3"
                : "c-pd-package-item-description",
            },
            [
              _c("c-textarea-preview", {
                attrs: {
                  "data-cy-custom": "detail-paw-description-pkg",
                  text:
                    _vm.cbPawPkgAggs(_vm.pkgType, _vm.CbPkgCoreType.DESCRIPTION)
                      .length > 0
                      ? _vm.cbPawPkgAggs(
                          _vm.pkgType,
                          _vm.CbPkgCoreType.DESCRIPTION
                        )[0].value
                      : "-",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mx-auto" }, [
            _c(
              "div",
              {
                staticClass:
                  "mx-2 mb-3 d-flex flex-wrap c-pd-package-item-dr p-2",
              },
              [
                _c("div", { staticClass: "px-2 py-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center c-pd-package-item-dr-item",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex my-auto mr-1" },
                        [
                          _c("icon", {
                            staticClass:
                              "cj-fill-white-primary dr-icon-wrapper",
                            attrs: {
                              name: "ic_time_outlined",
                              width: "22px",
                              height: "22px",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "dr-title-wrapper font-roboto cj-color-white-primary font-weight-700",
                          class: _vm.isMobile ? "font-size-12" : "font-size-12",
                          attrs: { "data-cy": "detail-paw-package-delivery" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.getDeliveryTimeType(
                                  _vm.cbPawPkgAggs(
                                    _vm.pkgType,
                                    _vm.CbPkgCoreType.DELIVERY_TIME
                                  )[0]?.valueIdfier,
                                  _vm.cbPawPkgAggs(
                                    _vm.pkgType,
                                    _vm.CbPkgCoreType.DELIVERY_TIME
                                  )[0]?.value,
                                  true
                                )
                              ) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.cbVwPaw.svcType == _vm.CbSvcType.INSIDE
                  ? _c("div", { staticClass: "px-2 py-1" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center c-pd-package-item-dr-item",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex my-auto mr-1" },
                            [
                              _c("icon", {
                                staticClass:
                                  "cj-fill-white-primary dr-icon-wrapper",
                                attrs: {
                                  name: "ic_revision",
                                  width: "22px",
                                  height: "22px",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "dr-title-wrapper font-roboto cj-color-white-primary font-weight-700",
                              class: _vm.isMobile
                                ? "font-size-12"
                                : "font-size-12",
                              attrs: {
                                "data-cy": "detail-paw-package-revision",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.cbPawPkgAggs(
                                      _vm.pkgType,
                                      _vm.CbPkgCoreType.REVISION
                                    ).length > 0
                                      ? _vm.cbPawPkgAggs(
                                          _vm.pkgType,
                                          _vm.CbPkgCoreType.REVISION
                                        )[0].value
                                      : "-"
                                  ) +
                                  "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.getRevisionTextEnId(
                                        _vm.cbPawPkgAggs(
                                          _vm.pkgType,
                                          _vm.CbPkgCoreType.REVISION
                                        )[0].value
                                      )
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-pd-package-item-include",
              attrs: { "data-cy": "detail-paw-packages" },
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "mx-3 mb-2 font-roboto cj-color-black-primary font-weight-700",
                  class: _vm.isMobile ? "font-size-16" : "font-size-16",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("cbGlobal.packageIncluded")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-wrap flex-row",
                  class: {
                    "c-pd-package-scroll-include": !_vm.seletedPopUp,
                  },
                },
                [
                  _vm._l(
                    _vm.cbPawPkgAggs(
                      _vm.pkgType,
                      _vm.CbPkgCoreType.NONE,
                      _vm.showMoreNonePkg
                    ),
                    function (item, index) {
                      return [
                        (
                          item.coreType == _vm.CbPkgCoreType.NONE
                            ? item.pkgName && item.value
                            : item.pkgNameCustom && item.value
                        )
                          ? _c(
                              "div",
                              {
                                key: index,
                                staticClass: "col-12 col-md-6 px-2 mb-2",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex c-pd-package-item-include-item",
                                    attrs: {
                                      "data-cy": "pd-package-item-include",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex my-auto mr-1" },
                                      [
                                        _c("icon", {
                                          staticClass:
                                            "cj-fill-red-primary my-auto",
                                          attrs: {
                                            name: "ic_check",
                                            width: "1.5rem",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-roboto my-auto cj-color-black-primary font-weight-700 font-size-12 text-ellipsis oneline",
                                        attrs: {
                                          "data-cy":
                                            "paw-detail-regular-package",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("capitalFirstLetter")(
                                                item.coreType ==
                                                  _vm.CbPkgCoreType.NONE
                                                  ? item.pkgName
                                                  : item.pkgNameCustom
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mx-3 pt-3 mt-auto",
              staticStyle: { "min-height": "47px" },
            },
            [
              _vm.isMobile && !_vm.seletedPopUp
                ? _c(
                    "c-button",
                    {
                      staticClass:
                        "font-roboto text-center font-weight-600 font-size-12",
                      staticStyle: { "border-radius": "5px !important" },
                      attrs: { "data-cy": "btn-view-detail", fullWidth: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("handleShowDetailPackage")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("cbGlobal.viewDetails")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.cbVwPaw.authorId !== _vm.currentUser.id
                ? [
                    (!_vm.isMobile && _vm.seletedPackage) || _vm.seletedPopUp
                      ? _c(
                          "c-button",
                          {
                            staticClass:
                              "font-roboto text-center font-weight-500 font-size-14",
                            attrs: {
                              "data-cy": "im-interested",
                              dataGTMCustom: "send-interest",
                              disabled: _vm.isloader,
                              fullWidth: "",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.$emit("handleChat")
                              },
                            },
                          },
                          [
                            _vm.isloader
                              ? _c("font-awesome-icon", {
                                  attrs: { icon: "sync", spin: _vm.isloader },
                                })
                              : [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("cbGlobal.imInterested")) +
                                      "\n          "
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }