import { TypeComponent } from '@/shared/model/enumerations/type-component.model';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class CItemProgressMilestone extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: '' }) type: TypeComponent;
  @Prop() lastItem: boolean;
  @Prop() firstItem: boolean;
  @Prop({ default: null }) vlCustomClass: string;
  @Prop({ default: null }) progress: number;

  public TypeComponent = TypeComponent;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
