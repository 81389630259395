var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-order-pd-date" }, [
    _c(
      "p",
      {
        staticClass:
          "font-roboto cj-color-black-primary font-weight-400 font-size-10",
        attrs: {
          "data-cy": _vm.dataCyCustom ? `date-${_vm.dataCyCustom}` : `pd-date`,
        },
      },
      [
        _vm._v(
          "\n    Created " +
            _vm._s(
              _vm.$options.filters.dynamicFormatDate(_vm.date, "DD MMM YYYY")
            ) +
            "\n  "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }