import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router from 'vue-router';

const Home = () => import('@/core/home/home.vue');
const PHome = () => import('@/module/general/home/home.vue');

const Tes = () => import('@/core/home/tes.vue');
const AppAdmin = () => import('@/app-admin.vue');
import admin from '@/router/admin';
import pages from '@/router/pages';
const PAppGeneral = () => import('@/module/general/app-general.vue');

Vue.use(Router);

window['popStateDetected'] = false;
window.addEventListener('popstate', () => {
  window['popStateDetected'] = true;
});

const routerPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  window['popStateDetected'] = false;
  return routerPush.call(this, location).catch(error => error);
};

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: PAppGeneral,
      children: [
        {
          path: '',
          name: 'PHome',
          component: PHome,
        },
      ],
    },
    {
      path: '/admin',
      component: AppAdmin,
      children: [
        {
          path: '',
          name: 'PAdmin',
          component: Home,
        },
      ],
    },
    {
      path: '/tes',
      name: 'Tes',
      component: Tes,
    },
    // {
    //   path: '/forbidden',
    //   name: 'Forbidden',
    //   component: Error,
    //   meta: { error403: true }
    // },
    // {
    //   name: 'NotFound',
    //   component: Error,
    //   meta: { error404: true }
    // },
    ...admin,
      // entities,//adehuh close in prod to reduce image
    // ...components, //must comment in prod to avoid duplicate
     ...pages
    //...gabung
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
