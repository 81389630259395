var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-upload-file-icon",
      class: { "is-size-contain-box-wrapper": _vm.isHiddenRatio },
      attrs: { "data-cy": "upload-file-icon" },
    },
    [
      _c(
        "div",
        {
          class: {
            "is-size-contain-box-wrapper": _vm.isHiddenRatio,
            "loading-preview ": _vm.isWithLoading && _vm.fileProgress >= 0,
          },
        },
        [
          _vm.isWithLoading && _vm.fileProgress >= 0
            ? _c("c-progress-bar", {
                attrs: { progress: _vm.fileProgress, radius: _vm.radius },
              })
            : [
                (_vm.isImage || _vm.isPdf) && (_vm.fileUrl || _vm.fileThumbnail)
                  ? _c("img", {
                      staticClass: "img-preview",
                      class: {
                        "is-size-contain-box": _vm.isHiddenRatio,
                        [_vm.classCustomImgPreview]: _vm.classCustomImgPreview,
                      },
                      attrs: {
                        src: _vm.$options.filters.PROCESS_IMAGE_PREVIEW(
                          _vm.fileThumbnail ? _vm.fileThumbnail : _vm.fileUrl
                        ),
                        onerror:
                          _vm.$options.filters.PROCESS_IMAGE_PREVIEW_ERROR(),
                        alt: "preview upload file",
                        "data-cy": _vm.dataCyCustom
                          ? _vm.dataCyCustom
                          : "thumbnail",
                      },
                    })
                  : _vm.isVideo && _vm.fileUrl
                  ? [
                      _vm.isVideoThumbnailImg
                        ? [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isPlaying,
                                    expression: "!isPlaying",
                                  },
                                ],
                              },
                              [
                                _vm.fileSize <= _vm.MAX_SIZE_10MB
                                  ? _c("img", {
                                      staticClass: "img-preview",
                                      class: {
                                        "is-size-contain-box":
                                          _vm.isHiddenRatio,
                                      },
                                      attrs: {
                                        src: _vm.$options.filters.PROCESS_IMAGE_PREVIEW(
                                          _vm.fileThumbnail
                                        ),
                                        onerror:
                                          _vm.$options.filters.PROCESS_IMAGE_PREVIEW_ERROR(),
                                        alt: "preview upload file bideo",
                                      },
                                    })
                                  : _c("icon", {
                                      staticClass: "position-svg-wrapper",
                                      attrs: { name: "ic_file_video" },
                                    }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "video",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isPlaying,
                                    expression: "isPlaying",
                                  },
                                ],
                                ref: "myVideoFileMain",
                                staticStyle: { "object-fit": "cover" },
                                attrs: {
                                  controls: _vm.isShowVideo ? true : false,
                                  loop: "",
                                },
                                on: { click: _vm.handlePauseVideo },
                              },
                              [_c("source", { attrs: { src: _vm.fileUrl } })]
                            ),
                            _vm._v(" "),
                            (!_vm.isPlaying || _vm.isPause) && _vm.isVideo
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-play-video cursor-pointer",
                                    on: { click: _vm.handlePlayVideo },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "icon-wrapper" },
                                      [
                                        _c("icon", {
                                          staticClass: "cj-fill-grey-secondary",
                                          attrs: {
                                            name: _vm.isPause
                                              ? "ic_pause"
                                              : "ic_play",
                                            width: "100%",
                                            height: "100%",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : [
                            _c(
                              "video",
                              {
                                attrs: {
                                  controls: _vm.isShowVideo ? true : false,
                                },
                              },
                              [_c("source", { attrs: { src: _vm.fileUrl } })]
                            ),
                          ],
                    ]
                  : [
                      _vm.isAudio
                        ? _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_audio" },
                          })
                        : _vm.isImage
                        ? _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_picture" },
                          })
                        : _vm.isVideo
                        ? _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_video" },
                          })
                        : _vm.isXls
                        ? _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_excel" },
                          })
                        : _vm.isPpt
                        ? _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_ppt" },
                          })
                        : _vm.isDoc
                        ? _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_docx" },
                          })
                        : _vm.isPdf
                        ? _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_pdf" },
                          })
                        : _c("icon", {
                            staticClass: "position-svg-wrapper",
                            attrs: { name: "ic_file_other" },
                          }),
                    ],
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.isShowWatermarkSign
        ? _c("img", {
            staticClass: "watermark-sign",
            attrs: {
              "data-cy": _vm.dataCyCustom ? _vm.dataCyCustom : "watermark-sign",
              src: require("@/../content/images/watermark-sign.svg"),
              alt: "watermark-sign",
              width: "20rem",
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }