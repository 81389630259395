import { IMAGE_TYPES, VIDEO_TYPES, AUDIO_TYPES, PDF_TYPES, DOC_TYPES, XLS_TYPES, PPT_TYPES } from './constants';

function checkMediaType(types, fileType) {
  if (!fileType) return;
  return types.some(t => fileType.toLowerCase().includes(t));
}

export function isImageFile(fileType) {
  return checkMediaType(IMAGE_TYPES, fileType);
}

export function isVideoFile(fileType) {
  return checkMediaType(VIDEO_TYPES, fileType);
}

export function isAudioFile(fileType) {
  return checkMediaType(AUDIO_TYPES, fileType);
}

export function isPdfFile(fileType) {
  return checkMediaType(PDF_TYPES, fileType);
}
export function isDocFile(fileType) {
  return checkMediaType(DOC_TYPES, fileType);
}
export function isXlsFile(fileType) {
  return checkMediaType(XLS_TYPES, fileType);
}
export function isPptFile(fileType) {
  return checkMediaType(PPT_TYPES, fileType);
}
