var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-navbar-switch-logintype wrap-border-grey-tertiary rounded-pill d-flex justify-content-between py-1",
      class: _vm.isHome ? "px-1" : "px-1",
      attrs: { "data-cy": "button-switch-type" },
      on: { click: _vm.changeLoginType },
    },
    [
      _c(
        "button",
        {
          ref: "btnFreelancer",
          staticClass:
            "font-roboto font-weight-500 font-size-12 rounded-pill btn-switch",
          class: {
            active: _vm.loginType && _vm.loginType === _vm.cbLoginType.PARTNER,
            "mr-1 px-3": _vm.isMarginRight,
            "px-2": !_vm.isMarginRight,
          },
          attrs: {
            "data-cy": "button-switch-freelancer",
            "data-gtm": "button-switch-freelancer-button",
            type: "button",
          },
        },
        [_vm._v("\n    Freelancer\n  ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          ref: "btnBuyer",
          staticClass:
            "font-roboto font-weight-500 font-size-12 rounded-pill btn-switch",
          class: {
            active: _vm.loginType && _vm.loginType === _vm.cbLoginType.BUYER,
            "mr-1 px-3": _vm.isMarginRight,
            "px-2": !_vm.isMarginRight,
          },
          attrs: {
            "data-cy": "button-switch-buyer",
            "data-gtm": "button-switch-buyer-button",
            type: "button",
          },
        },
        [_vm._v("\n    Buyer\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }