import { Inject, Component, Mixins, Watch } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CButton from '@/components/button/c-button.vue';
import GlobalService from '@/services/global.service';
import { IUser, User } from '@/shared/model/user.model';
import { minLength, required } from 'vuelidate/lib/validators';
import { Flutter } from '@/app-flutter';
import { IKeycloakToken } from '@/shared/model/keycloak-token.model';
import JhiDataUtils from '@/shared/data/data-utils.service';
import FormUtils from '@/shared/utils/form-utils.service';
import { ERROR_LOGIN_BLOCKED, ERROR_LOGIN_PASSWORD } from '@/constants';
import AccountService from '@/account/account.service';

const validations: any = {
  user: {
    login: {
      required,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
};
@Component({
  validations,
  components: {
    CButton,
    Icon,
  },
})
export default class CLogin extends Mixins(JhiDataUtils, FormUtils) {
  // ================= SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('accountService') private accountService: () => AccountService;
  // ================= SERVICES =================
  // ================= VARIABLES =================
  public passwordInputType = 'password';
  public user: IUser = new User();
  public isLoginErrorPassword = false;
  public isLoginErrorBlocked = false;
  public authenticationError: boolean | null = null;
  public isLoginInvalid: boolean | null = null;
  public rememberMe = false;
  public isRequesting = false;
  public readonly MAX_LENGTH = 255;
  private isLoginEntried = false;
  // ================= VARIABLES =================
  // ================= DEFAULT FUNCTION =================
  // ================= DEFAULT FUNCTION =================
  // ================= FUNCTION =================
  public openDialogRegister(): void {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
    this.globalService().openGlobalDialog(this.$root, 'modal-c-register');
  }

  public mounted(): void {
    this.getEnterLoginRef().addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        if (this.user.password.length >= 4 && this.user.login) {
          this.onLogin();
        }
      }
    });
    this.getEnterLoginPasswordRef().addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        if (this.user.password.length >= 4 && this.user.login) {
          this.onLogin();
        }
      }
    });
    Flutter.onOAuth2Login({
      handlerLabel: 'onOAuth2',
      callback: (data: IKeycloakToken) => {
        this.globalService().keepToken(data, true);
        this.globalService().checkAccountAndLoginTypeData(this);
        window.location.reload();
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
      },
    });
  }

  public handleIntroductionRegister(): void {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
    this.globalService().openGlobalDialog(this.$root, 'modal-c-login-introduction');
  }

  public changePassword(): void {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
    this.globalService().openGlobalDialog(this.$root, 'modal-c-reset-password');
  }

  public openPhoneVerification(): void {
    this.$store.commit('isPhoneTrouble', true);
    this.$root.$emit('app::changeValue', 'isNotOpenGlobalDialogCallback', true);

    this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
    this.globalService().openGlobalDialog(this.$root, 'modal-edit-phone-number');
  }

  public togglePassword(): void {
    this.passwordInputType = this.passwordInputType === 'text' ? 'password' : 'text';
  }

  public onLogin(): void {
    if (this.isRequesting) return;
    this.isLoginErrorPassword = false;
    this.isLoginErrorBlocked = false;
    this.authenticationError = false;
    this.$store.commit('isPhoneTrouble', false);
    this.$root.$emit('registOne::isValidChangePage', true);
    this.isRequesting = true;
    const login = this.user.login.trim();
    const password = this.user.password.trim();
    const data = { username: login, password: password, rememberMe: false };
    this.$root.$emit('navbar::changeValue', 'isInputActived', false);
    this.globalService()
      .login(data, this)
      .then(async _ => {
        await this.accountService().retrieveAccount();
        this.isRequesting = false;
        this.authenticationError = false;
        this.globalService().closeGlobalDialog(this.$root, 'modal-c-login');
        const RNext = this.$store.getters.nextRouteName;
        const RPrevious = this.$store.getters.previousRouteName;
        this.$root.$emit('navbar::changeValue', 'isInputActived', true);
        if (RNext == 'PSetPassword' || RNext == 'PIntroductionFreelancer' || (RPrevious == 'PSetPassword' && this.isMobile)) {
          this.$router.replace('/');
        } else {
          if (this.isMobile) {
            if (RPrevious == 'CNavbarMenu' || !RPrevious) this.$router.replace('/');
            else this.$router.go(-1);
          } else {
            window.location.reload();
          }
        }
      })
      .catch(err => {
        this.isRequesting = false;
        this.authenticationError = true;
        this.$root.$emit('navbar::changeValue', 'isInputActived', true);

        if (err.response.data.detail == ERROR_LOGIN_PASSWORD) {
          this.isLoginErrorPassword = true;
        }

        if (err.response.data.detail == ERROR_LOGIN_BLOCKED) {
          this.isLoginErrorBlocked = true;
        }
      });
  }

  public loginGoogle(): void {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginGoogle();
  }
  public loginFacebook(): void {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginFacebook();
  }
  public loginLinkedin(): void {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginLinkedin();
  }
  public loginApple(): void {
    this.$root.$emit('registOne::isValidChangePage', true);
    this.globalService().loginApple();
  }

  // ================= FUNCTION =================
  // ================= COMPUTE =================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public getEnterLoginRef(): HTMLInputElement {
    return this.$refs.enterlogin as HTMLInputElement;
  }

  public getEnterLoginPasswordRef(): HTMLInputElement {
    return this.$refs.enterloginPassword as HTMLInputElement;
  }

  private validateLength(newVal: string, oldVal: string, onNotValidated: () => void): boolean {
    const length = newVal?.length ?? 0;
    if (length > this.MAX_LENGTH) {
      onNotValidated();
      return false;
    }
    return this.validatePasteMaxLengthUtils(this.MAX_LENGTH, length);
  }

  public isLoginValidCopy(model: any): string {
    const result = this.isLoginValid(model, this.isLoginEntried);
    this.isLoginInvalid = result.isLoginInvalid;
    this.isLoginEntried = result.isLoginEntried;
    if (this.isLoginInvalid) {
      this.authenticationError = false;
      this.isLoginErrorPassword = false;
    }

    return result.valid;
  }
  // ================= COMPUTE =================
  @Watch('user.login', { immediate: true })
  public onLoginChange(newVal: string, oldVal: string): void {
    if (newVal === oldVal) return;
    this.validateLength(newVal, oldVal, () => {
      this.user.login = oldVal;
    });
    // if newValue contain space remove it
    if (newVal && newVal.indexOf(' ') > -1) {
      this.user.login = newVal.replace(/\s/g, '');
    }
  }

  @Watch('user.password', { immediate: true })
  public onPasswordChange(newVal: string, oldVal: string): void {
    this.validateLength(newVal, oldVal, () => {
      this.user.password = oldVal;
    });
  }
}
