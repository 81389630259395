import { Component, Prop, Mixins } from 'vue-property-decorator';
import { CbVwPromoUserAgg, ICbVwPromoUserAgg } from '@/shared/model/cb-vw-promo-user-agg.model';
import { PayPayload2023, IPayPayload2023 } from '@/shared/model/pay-payload-2023.model';
import CVoucherItem from '@/components/c-voucher-item/c-voucher-item.vue';
import Icon from '@/shared/icons/icon.vue';
import FormUtils from '@/shared/utils/form-utils.service';
import vClickOutside from 'v-click-outside';
import { maxLength } from 'vuelidate/lib/validators';

const validations: any = {
  payload: {
    promoCode: {
      maxLength: maxLength(15),
    },
  },
};

@Component({
  validations,
  components: {
    CVoucherItem,
    Icon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    'payload.promoCode': {
      immediate: true,
      handler(newVal, oldVal) {
        const max = 15;
        const total = newVal?.length ? newVal.length : 0;

        if (total > max || (newVal == this.account.email && newVal == this.account.login)) {
          this.payload.promoCode = oldVal;
          this.handleSelectPromo(oldVal);
        }

        if (newVal != this.account.email && newVal != this.account.login) this.validatePasteMaxLengthUtils(max, total);
      },
    },
  },
})
export default class CInputPaymentPromo extends Mixins(FormUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public payload: PayPayload2023;
  @Prop({ default: null }) public cbVwPromoUserAggs: CbVwPromoUserAgg[];
  @Prop({ default: false }) public isFetchingCbVwPromoUserAggs: boolean;

  @Prop({ default: 20 }) public itemsPerPage;
  @Prop({ default: null }) public queryCount: number;
  @Prop({ default: 1 }) public page;
  @Prop({ default: 1 }) public previousPage;
  @Prop({ default: 'id' }) public propOrder;
  @Prop({ default: false }) public reverse;
  @Prop({ default: 0 }) public totalItems;
  @Prop({ default: +new Date() }) public infiniteId;
  @Prop({ default: null }) public links;
  public togglePromo: boolean = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleSelectPromo(value) {
    let tmpPayload: IPayPayload2023 = Object.assign({}, this.payload);

    if (value) {
      const cbVwPromoUserAgg: ICbVwPromoUserAgg = this.cbVwPromoUserAggs.find(v => v.code == value);
      tmpPayload.promoCode = value;
      tmpPayload.promoPayMethod = cbVwPromoUserAgg?.payPromoMethod ? cbVwPromoUserAgg.payPromoMethod : null;
    } else {
      tmpPayload.promoCode = null;
      tmpPayload.promoPayMethod = null;
    }

    this.$emit('changeValue', 'localPayload', tmpPayload);
  }

  public handlClickPromo(cbVwPromoUserAgg: ICbVwPromoUserAgg) {
    if (
      cbVwPromoUserAgg.isValidWithThisOrder &&
      !cbVwPromoUserAgg.isExceedUsage &&
      cbVwPromoUserAgg.isValidMinimumTransaction &&
      cbVwPromoUserAgg.isValid &&
      cbVwPromoUserAgg.isValidWithPayType &&
      cbVwPromoUserAgg.isSpecificPromoValid
    ) {
      let tmpPayload: IPayPayload2023 = Object.assign({}, this.payload);
      tmpPayload.promoCode = cbVwPromoUserAgg.code;
      tmpPayload.promoPayMethod = cbVwPromoUserAgg.payPromoMethod;

      if (
        tmpPayload.promoPayMethod != 'ALL' &&
        tmpPayload.payTypes.length > 0 &&
        tmpPayload.promoPayMethod.toString() != tmpPayload.payTypes[0].toString()
      ) {
        tmpPayload.payTypes = [];
      }

      this.$emit('changeValue', 'localPayload', tmpPayload);
      this.$emit('changeFunction', 'checkOrderDetail');
      this.togglePromo = false;
    }
  }

  public toPromo(param) {
    this.$router.push(param);
  }

 

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get inputSearchPromo() {
    if (this.payload.promoCode) {
      let tmpCbVwPromoUserAggs: ICbVwPromoUserAgg[] = [...this.cbVwPromoUserAggs];

      return tmpCbVwPromoUserAggs.filter(e => e.code.toUpperCase().includes(this.payload.promoCode.toUpperCase()));
    } else {
      return this.cbVwPromoUserAggs;
    }
  }

  public get isPromoDisabled() {
    return (cbVwPromoUserAgg: ICbVwPromoUserAgg) => {
      if (
        cbVwPromoUserAgg.isValidWithThisOrder &&
        !cbVwPromoUserAgg.isExceedUsage &&
        cbVwPromoUserAgg.isValidMinimumTransaction &&
        cbVwPromoUserAgg.isValid &&
        cbVwPromoUserAgg.isValidWithPayType &&
        cbVwPromoUserAgg.isSpecificPromoValid
      ) {
        return false;
      }
      return true;
    };
  }
  public openDropdown() {
    this.togglePromo = true;
  }
  public closeDropdown() {
    this.togglePromo = false;
  }
  public get isMobile() {
    return this.$store.getters.isMobile;
  }

  public get account(): any {
    return this.$store.getters.account;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================

  // ================= END LISTENERS ==================
}
