var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.visibleCountdown
    ? _c(
        "div",
        {
          staticClass: "c-time-countdown d-flex",
          attrs: { "data-cy": "count-down" },
        },
        [
          _vm._t("default", null, {
            days: _vm.days,
            hours: _vm.hours,
            minutes: _vm.minutes,
            seconds: _vm.seconds,
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }