import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';

import { ICbVwUser } from '@/shared/model/cb-vw-user.model';

@Component({})
export default class CTimeCountdown extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) timeDate: Date;
  @Prop({ default: false }) public timeFreezeDate: Date;
  @Prop({ default: null }) public timeMilis: number;
  @Prop({ default: null }) public stopCountdown: number;
  @Prop({ default: false }) public isFreezeCountdown: boolean;
  @Prop({ default: false }) public isVisibleCountdownZero: boolean;

  public countdownInterval: any;
  public days = '00';
  public hours = '00';
  public minutes = '00';
  public seconds = '00';
  public countdown = 0;
  public visibleCountdown = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    if (this.isFreezeCountdown) {
      if (this.timeDate) {
        // ? check untuk yg pake stop countdown
        this.countdown = this.reduceCountdown(this.timeDate);
        this.conditionStartCountdown();
      } else if (this.timeMilis) {
        this.countdown = this.timeMilis;
        this.conditionStartCountdown();
      }
    } else {
      this.toggleCountdown();
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public setTimeCountDown() {
    if ((this.stopCountdown || this.stopCountdown === 0) && this.countdown <= this.stopCountdown) {
      if (this.stopCountdown === 0 && this.isVisibleCountdownZero) {
        this.clearCountdownInterval(true);
      } else {
        this.clearCountdownInterval();
        this.$emit('action');
      }
      return;
    }

    if (this.timeMilis) {
      this.countdown = this.countdown - 1000;
      this.processCountdown();
    }

    if (this.timeDate) {
      this.countdown = this.reduceCountdown(this.timeDate);
      this.processCountdown();
    }
  }

  public processCountdown() {
    const tmpCountdown = this.stopCountdown || this.stopCountdown === 0 ? this.countdown : Math.abs(this.countdown);

    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;

    this.days = String(Math.floor(tmpCountdown / _day));
    this.hours = String(Math.floor((tmpCountdown % _day) / _hour));
    this.minutes = String(Math.floor((tmpCountdown % _hour) / _minute));
    this.seconds = String(Math.floor((tmpCountdown % _minute) / _second));

    if (this.days.length < 2) this.days = `0${this.days}`;
    if (this.hours.length < 2) this.hours = `0${this.hours}`;
    if (this.minutes.length < 2) this.minutes = `0${this.minutes}`;
    if (this.seconds.length < 2) this.seconds = `0${this.seconds}`;
  }

  public clearCountdownInterval(visible = false) {
    clearInterval(this.countdownInterval); // ? to make stop interval
    if (visible) {
      this.countdown = 0;
      this.visibleCountdown = true;
      this.days = '00';
      this.hours = '00';
      this.minutes = '00';
      this.seconds = '00';
    } else {
      this.countdown = 0;
      this.visibleCountdown = false;
    }
  }

  public conditionStartCountdown() {
    if ((this.stopCountdown || this.stopCountdown === 0) && this.countdown <= this.stopCountdown) {
      if (this.stopCountdown === 0 && this.isVisibleCountdownZero) {
        this.clearCountdownInterval(true);
      } else {
        this.$emit('action');
        this.clearCountdownInterval();
      }
    } else if (this.isFreezeCountdown) {
      this.visibleCountdown = true;
      this.countdownInterval = this.setTimeCountDown();
    } else {
      this.visibleCountdown = true;
      this.countdownInterval = setInterval(this.setTimeCountDown, 1000);
    }
  }

  public reduceCountdown(time: any) {
    if (this.stopCountdown || this.stopCountdown === 0) {
      return +dayjs(time).toDate() - +dayjs().toDate();
    } else if (this.isFreezeCountdown) {
      return Math.abs(+dayjs(time).toDate() - +dayjs(this.timeFreezeDate).toDate());
    } else {
      return Math.abs(+dayjs(time).toDate() - +dayjs().toDate());
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  public get getTime() {
    return [this.timeMilis, this.timeDate];
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('getTime')
  public toggleCountdown() {
    if (this.isFreezeCountdown) {
      return;
    }

    this.clearCountdownInterval(); // ? to make stop interval
    if (this.timeDate) {
      // ? check untuk yg pake stop countdown
      this.countdown = this.reduceCountdown(this.timeDate);
      this.conditionStartCountdown();
    } else if (this.timeMilis) {
      this.countdown = this.timeMilis;
      this.conditionStartCountdown();
    } else {
      this.clearCountdownInterval(); // ? to make stop interval
    }
  }
  // ================= END LISTENERS ==================
}
