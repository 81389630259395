import { TabSettingType } from '@/module/general/setting/settings.component';
import { Inject, Component, Vue, Watch } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import GlobalService from '@/services/global.service';

import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from 'firebase/auth';
import PCbAccountService from '@/services/cb-account.service';
import { CbLinkedAccount, ICbLinkedAccount } from '@/shared/model/cb-oids.model';
import { APPLE, FACEBOOK, GOOGLE, LINKEDIN } from '@/constants';
import { ICbLinkedinRequest } from '@/shared/model/cb-linkedin-request.model';
import { Flutter } from '@/app-flutter';
import { SESSION_STORAGE_LINKEDIN_INSTATE } from '@/shared/constant/constants-session-storage';

@Component({
  components: {
    Icon,
    CConfirmation,
  },
})
export default class CLinkedAccount extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbAccountService') private pCbAccountService: () => PCbAccountService;

  public linkedAccounts: ICbLinkedAccount[] = [];
  public linkedAccountGoogle: ICbLinkedAccount = new CbLinkedAccount();
  public linkedAccountFacebook: ICbLinkedAccount = new CbLinkedAccount();
  public linkedAccountLinkedin: ICbLinkedAccount = new CbLinkedAccount();
  public linkedAccountApple: ICbLinkedAccount = new CbLinkedAccount();
  public isFetchingLinkedAccount = false;
  public isSavingLinkedAccount = false;
  public isDeleteLinkedAccount = false;
  public unlinkProvider: string = null;

  public created() {
    this.retireveLinkedAccounts();
    (<any>this.$root).$emit('showNavMobile', false);
    (<any>this.$root).$emit('showFloatingStore', false);
  }

  public mounted() {
    Flutter.listenLinkAccount('onLinkAccount', (cbLinkedAccount: ICbLinkedAccount) => {
      console.log('cbLinkedAccount', JSON.stringify(cbLinkedAccount));
      this.createLinkedAccount(cbLinkedAccount);
    });

    Flutter.listenLinkAccount('onLinkLinkedIn', (cbLinkedinRequest: ICbLinkedinRequest) => {
      console.log('linkedInEvent', JSON.stringify(cbLinkedinRequest));
      this.createLinkedAccountLinkedin(cbLinkedinRequest);
    });
  }

  public loginGoogle() {
    Flutter.call('googleLink', {
      onFlutterNotRun: () => {
        this.loginFirebase('GOOGLE');
      },
    });
  }

  public loginFacebook() {
    Flutter.call('facebookLink', {
      onFlutterNotRun: () => {
        this.loginFirebase('FACEBOOK');
      },
    });
  }

  public loginApple() {
    Flutter.call('appleLink', {
      onFlutterNotRun: () => {
        this.loginFirebase('APPLE');
      },
    });
  }

  private loginFirebase(param) {
    let provider = null;
    if (param == 'GOOGLE') {
      provider = new GoogleAuthProvider();
      provider.addScope('email');
      provider.addScope('profile');
    } else if (param == 'FACEBOOK') {
      provider = new FacebookAuthProvider();
      provider.addScope('email');
      provider.addScope('public_profile');
    } else if (param == 'APPLE') {
      provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.addScope('openid');
    }

    try {
      provider.setCustomParameters({
        prompt: 'select_account',
      });
    } catch (error) {
      console.log(error);
    }

    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then(result => {
        GoogleAuthProvider.credentialFromResult(result);

        // The signed-in user info.
        const user = result.user;
        const emailFb = (<any>result)._tokenResponse.email;
        const userId4LinkingAccount = user && user.providerData && user.providerData.length > 0 ? user.providerData[0].uid : null;

        console.log('userId4LinkingAccount ' + userId4LinkingAccount);
        console.log('user', result);

        // Apple credential

        if (param == 'GOOGLE' && userId4LinkingAccount) {
          const cbLinkedAccount: ICbLinkedAccount = {
            identityProvider: GOOGLE,
            userId: userId4LinkingAccount,
            userName: emailFb,
          };
          this.createLinkedAccount(cbLinkedAccount);
        } else if (param == 'FACEBOOK' && userId4LinkingAccount) {
          const cbLinkedAccount: ICbLinkedAccount = {
            identityProvider: FACEBOOK,
            userId: userId4LinkingAccount,
            userName: emailFb ? emailFb : userId4LinkingAccount,
          };
          this.createLinkedAccount(cbLinkedAccount);
        } else if (param == 'APPLE' && userId4LinkingAccount) {
          const cbLinkedAccount: ICbLinkedAccount = {
            identityProvider: APPLE,
            userId: userId4LinkingAccount,
            userName: emailFb,
          };
          this.createLinkedAccount(cbLinkedAccount);
        }

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch(error => {
        // ...
      });
  }

  public showModal(unlinkProvider: string) {
    if (this.linkedAccounts && this.linkedAccounts.length == 1) {
      const message = this.$t('cbgwApp.cbSetting.canNotUnlink', { provider: unlinkProvider });
      (<any>this.$root).$bvToast.toast(message.toString(), {
        toaster: 'b-toaster-top-center',
        title: unlinkProvider,
        variant: 'danger',
        solid: true,
        autoHideDelay: 5000,
      });
      return;
    }

    this.unlinkProvider = unlinkProvider;
    this.globalService().openGlobalDialog(this.$root, 'modal-c-confirmation-unlinked-account');
  }
  public closeModal() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-confirmation-unlinked-account');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================

  public client_id = process.env.LINKEDIN_CLIENT_ID;
  public redirect_uri = window.location.origin; // pass redirect_uri here
  public scope = process.env.LINKEDIN_SCOPE; // permissions required by end-user
  public win;
  public inState;
  public checkConnect;

  public loginLinkedin(e) {
    if (Flutter.isRunOn() === true) {
      Flutter.call('linkedInLink');
      return;
    }

    if (!sessionStorage.getItem(SESSION_STORAGE_LINKEDIN_INSTATE)) {
      this.inState = Math.floor(Math.random() * 90000) + 10000;
      sessionStorage.setItem(SESSION_STORAGE_LINKEDIN_INSTATE, this.inState);
    } else {
      this.inState = sessionStorage.getItem(SESSION_STORAGE_LINKEDIN_INSTATE);
    }

    const url =
      process.env.LINKEDIN_URL + this.client_id + '&redirect_uri=' + this.redirect_uri + '&scope=' + this.scope + '&state=' + this.inState;

    this.win = window.open(encodeURI(url), 'LinkedIn Login', 'width=800, height=600, left=300, top=100');
    const that = this;
    const checkConnect = setInterval(function () {
      console.log(that.win);
      if (that.win && !that.win.window) {
        clearInterval(checkConnect);
        console.log('close popup');
        return;
      }

      if (!that.win) {
        console.log('1>>>>>>>>' + that.win);
        return;
      }

      if (that.win && that.win.document && that.win.document.URL == 'about:blank') {
        console.log('1url blank', that.win.document);
        return;
      }

      console.log('2>>>>>>>>' + that.win);

      that.getLinkedCode();
      clearInterval(checkConnect);
      console.log('3>>>>>>>>' + that.win);
    }, 100);
  }
  public getLinkedCode() {
    console.log(this.win);
    console.log(this.win.document.URL);

    console.log('getLinkedCode ::: ' + this.win.document.URL);
    const cur_url = new URL(this.win.document.URL);
    const urlParams = new URLSearchParams(cur_url.search);

    if (urlParams.has('state') && sessionStorage.getItem(SESSION_STORAGE_LINKEDIN_INSTATE) == urlParams.get('state')) {
      console.log('state');

      if (urlParams.has('code')) {
        const code = urlParams.get('code');
        console.log('code: ' + code, cur_url.origin);

        const cbLinkedRequest: ICbLinkedinRequest = {
          redirectUri: cur_url.origin,
          code: code,
        };

        this.createLinkedAccountLinkedin(cbLinkedRequest); //comment sementara
      } else if (urlParams.has('error') && urlParams.has('error_description')) {
      }
    }
    this.win.close();
  }

  public retireveLinkedAccounts() {
    this.isFetchingLinkedAccount = true;

    this.pCbAccountService()
      .getLinkedAccounts()
      .then(res => {
        if (res.id == 1) {
          this.linkedAccounts = res.detail;

          if (this.linkedAccounts && this.linkedAccounts.length > 0) {
            this.linkedAccountGoogle = this.linkedAccounts.find(v => v.identityProvider == GOOGLE);
            this.linkedAccountFacebook = this.linkedAccounts.find(v => v.identityProvider == FACEBOOK);
            this.linkedAccountLinkedin = this.linkedAccounts.find(v => v.identityProvider == LINKEDIN);
            this.linkedAccountApple = this.linkedAccounts.find(v => v.identityProvider == APPLE);
          } else {
            this.linkedAccountGoogle = new CbLinkedAccount();
            this.linkedAccountFacebook = new CbLinkedAccount();
            this.linkedAccountLinkedin = new CbLinkedAccount();
          }
        }
      })
      .catch(err => {
        console.log('ERROR=>', err.response);
      })
      .finally(() => (this.isFetchingLinkedAccount = false));
  }

  public createLinkedAccount(cbLinkedAccount: ICbLinkedAccount) {
    this.isSavingLinkedAccount = true;

    this.pCbAccountService()
      .addLinkedAccount(cbLinkedAccount)
      .then(res => {
        if (res.id == 1) {
          this.retireveLinkedAccounts();
        } else {
          const message = this.$t(`cbgwApp.cbSetting.idpExist`);
          (<any>this.$root).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'idPExist',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000,
          });
        }
        this.isSavingLinkedAccount = false;
      })
      .catch(err => {
        this.isSavingLinkedAccount = false;
        const message = this.$t(`cbgwApp.cbSetting.idpExist`);
        (<any>this.$root).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'idPExist',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        });
      });
  }

  public deleteLinkedAccount(identityProvider: string) {
    this.isDeleteLinkedAccount = true;

    if (this.linkedAccounts && this.linkedAccounts.length == 1) {
      return;
    }
    this.pCbAccountService()
      .removeLinkedAccount(identityProvider)
      .then(res => {
        this.retireveLinkedAccounts();
        this.isDeleteLinkedAccount = false;
        this.closeModal();
      })
      .catch(err => {
        console.log('ERROR=>', err.response);
        this.isDeleteLinkedAccount = false;
      });
  }

  public createLinkedAccountLinkedin(cbLinkedRequest: ICbLinkedinRequest) {
    this.isSavingLinkedAccount = true;

    this.pCbAccountService()
      .addLinkedAccountLinkedin(cbLinkedRequest)
      .then(res => {
        if (res.id == 1) {
          this.retireveLinkedAccounts();
        } else {
          const message = this.$t(`cbgwApp.cbSetting.idpExist`);
          (<any>this.$root).$bvToast.toast(message.toString(), {
            toaster: 'b-toaster-top-center',
            title: 'idPExist',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000,
          });
        }
        this.isSavingLinkedAccount = false;
      })
      .catch(err => {
        this.isSavingLinkedAccount = false;
        const message = this.$t(`cbgwApp.cbSetting.idpExist`);
        (<any>this.$root).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'idPExist',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        });
        return;
      });
  }
  @Watch('isMobile')
  public isMobileListener() {
    if (!this.isMobile) {
      this.$router.push({ name: 'PSettings', query: { tab: TabSettingType.LINKED_ACCOUNT } });
    }
  }
}
