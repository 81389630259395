import { Inject, Component, Vue } from 'vue-property-decorator';
import CItemTimeRegion from '@/components/c-item-time-region/c-item-time-region.vue';
import Icon from '@/shared/icons/icon.vue';
import { ICbUserTimezone } from '@/shared/model/cb-user-timezone.model';
import dayjs from 'dayjs';
import CAutocompleteNew from '../c-autocomplete-new/c-autocomplete-new.vue';
import CTimeRegionMUpdate from './c-time-region-m-update/c-time-region-m-update.vue';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import GlobalService from '@/services/global.service';
import { DATE_REGION_LONG_FORMAT, DATE_REGION_SHORT_FORMAT, TIME_24_FORMAT } from '@/shared/date/filters';
@Component({
  components: {
    CItemTimeRegion,
    Icon,
    CAutocompleteNew,
    CTimeRegionMUpdate,
    CConfirmation,
  },
})
export default class CTimeRegionM extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================

  public visibleTimeRegionUpdate: boolean = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  public mounted() {
    if (this.cbUserTimezones && this.cbUserTimezones.length > 0) {
      for (const cbUserTimeZone of this.cbUserTimezones) {
        setInterval(() => {
          const currentDate = dayjs().tz(cbUserTimeZone.timeZoneCode);
          cbUserTimeZone.timeFormat = currentDate.format(TIME_24_FORMAT);
          cbUserTimeZone.dateFormat = currentDate.format(DATE_REGION_SHORT_FORMAT);
          let dateTime = dayjs(`${cbUserTimeZone.dateFormat} ${cbUserTimeZone.timeFormat}`, DATE_REGION_LONG_FORMAT);
          dateTime = dateTime.tz(cbUserTimeZone.timeZoneCode);
          const formattedDateTime = dateTime.toDate();
          cbUserTimeZone.userZoneDateTime = formattedDateTime;
        }, 1000);
      }
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleShowModal() {
    // untuk beforerouterleave
    this.globalService().openGlobalDialog(this.$root, 'modal-visible-time-region-update');
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get cbUserTimezones(): ICbUserTimezone[] {
    return this.$store.getters.cbUserTimeZones;
  }
  // ================= END COMPUTE ====================
}
