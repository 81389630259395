import { Component, Prop, Mixins } from 'vue-property-decorator';

import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';

import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import { ICbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { CbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';
import { HANDLE_MDL_APPOINTMENT_PREVIEW, HANDLE_MDL_APPOINTMENT_UPDATE } from '@/module/chat-container/order-paw-controller.component';

@Component({
  components: {
    CButton,
    CTextareaPreview,
    Icon,
  },
})
export default class CItemChatMessageAppointment extends Mixins(StatusTextEnId) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public cbVwPawOrder: ICbVwPawOrder;
  @Prop({ default: null }) public cbVwPawOrderRequirement: CbVwPawOrderRequirement;
  @Prop({ default: null }) public cbTextRestrict: CbTextRestrict;

  public CbCommonStatus = CbCommonStatus;
  public TypeComponent = TypeComponent;

  public isExpandAppointDesc = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleOpenPreviewAppointment() {
    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_MDL_APPOINTMENT_PREVIEW,
      cbVwPawOrder: this.cbVwPawOrder,
      cbVwPawOrderRequirement: this.cbVwPawOrderRequirement,
      typeModal: TypeComponent.CHAT_MODAL,
    });
  }

  public handleBuyerAction() {
    const partial: ICbPawOrderRequirement = {
      id: this.cbVwPawOrderRequirement.id,
      requirementType: <CbRequirementType>this.cbVwPawOrderRequirement.requirementType,
      cbPawOrder: {
        id: this.cbVwPawOrderRequirement.cbPawOrderId,
      },
      status: CbCommonStatus.ACCEPTED,
    };

    (<any>this.$root).$emit('opc::actionHandler', {
      action: HANDLE_MDL_APPOINTMENT_UPDATE,
      cbVwPawOrder: this.cbVwPawOrder,
      reqParam: partial,
      typeModal: TypeComponent.CHAT_MODAL,
    });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getWorkLocation() {
    let location = '';
    if (this.cbVwPawOrderRequirement.id && this.cbVwPawOrderRequirement.toWorkLocations) {
      const splitRecord = this.cbVwPawOrderRequirement.toWorkLocations.split('::');

      location = splitRecord[2];
    }
    return location;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
