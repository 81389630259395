var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      staticClass: "c-input-payment-promo",
    },
    [
      _c(
        "div",
        { staticClass: "wrap-border-grey-tertiary rounded d-flex" },
        [
          _c("icon", {
            staticClass: "cj-fill-red-primary ml-1 my-auto",
            attrs: { name: "ic_coupon", width: "27px", height: "27px" },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.$v.payload.promoCode.$model,
                expression: "$v.payload.promoCode.$model",
              },
            ],
            staticClass:
              "cj-field-not-border custom-coupon-placeholder cj-field-input",
            staticStyle: { "padding-left": "10px !important" },
            attrs: {
              "data-cy": "autocomplete-input-coupon",
              type: "text",
              placeholder: _vm.$t(
                "cbgwApp.cbComponent.cInputPaymentPromo.selectCoupon"
              ),
            },
            domProps: { value: _vm.$v.payload.promoCode.$model },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.$v.payload.promoCode,
                    "$model",
                    $event.target.value
                  )
                },
                function ($event) {
                  return _vm.handleSelectPromo(_vm.$v.payload.promoCode.$model)
                },
              ],
              click: _vm.openDropdown,
            },
          }),
          _vm._v(" "),
          _vm.payload.promoCode && _vm.payload.promoCode.length > 0
            ? _c(
                "button",
                {
                  staticClass: "ml-2 my-auto",
                  attrs: { "data-cy": "delete-coupon" },
                  on: {
                    click: function ($event) {
                      _vm.handleSelectPromo(null)
                      _vm.$emit("changeFunction", "checkOrderDetail")
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "cj-fill-red-primary my-auto",
                    attrs: {
                      name: "ic_outline_delete",
                      width: "18px",
                      height: "18px",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "wrap-autocomplete",
          class: _vm.isMobile ? "position-static" : "position-relative",
        },
        [
          _vm.togglePromo
            ? _c(
                "div",
                {
                  staticClass:
                    "dropdown-container-autocomplete cj-bg-white-primary rounded cj-field-border-default p-2 select-voucher-m",
                  style: _vm.isMobile
                    ? "width: calc(100% - 2rem);left: 50%;transform: translateX(-50%);"
                    : "",
                  attrs: { "data-cy": "wrap-coupon" },
                },
                [
                  _vm._l(_vm.inputSearchPromo, function (cbVwPromo, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "mb-2" },
                      [
                        _c("c-voucher-item", {
                          attrs: {
                            isFromPayForm: true,
                            cbVwPromo: cbVwPromo,
                            isShowUseButton: false,
                            isShowMessage: true,
                          },
                          on: {
                            handlClickPromo: function ($event) {
                              return _vm.handlClickPromo(cbVwPromo)
                            },
                            promoDetail: function ($event) {
                              _vm.$emit(
                                "changeFunction",
                                "promoDetail",
                                cbVwPromo
                              )
                              _vm.togglePromo = true
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-size-12 font-weight-700 p-1 text-center mx-auto font-roboto cj-btn-text-primary",
                      attrs: {
                        "data-cy": "claim-more-btn",
                        "data-gtm": "claim-more-clikable",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toPromo("/general/p-promo")
                        },
                      },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cInputPaymentPromo.claimMoreCoupon"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("loaderWeb"),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }