import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { CbInputCurrencyType } from '@/shared/model/enumerations/cb-input-currency-type.model';
import JhiDataUtils from '@/shared/data/data-utils.service';

@Component({})
export default class CInputCurrency extends Mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop() public value: number;
  @Prop() public currency: any;
  @Prop({ default: '' }) public customCLass: string;
  @Prop({ default: null }) max: number;
  @Prop({ default: null }) min: number;
  @Prop({ default: null }) dataCyCustom: string;
  @Prop({ default: null }) dataGTMCustom: string;
  @Prop({ default: null }) messageToast: string;

  public numberValue: number = null;
  public stringValue = '';
  public locale: string = CbInputCurrencyType.IDR;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.currencyListener();
    this.valueListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public inputCurrency(e) {
    let value = e.target.value;
    value = value.replace(/\D+/g, '');
    const tmpInput: any = this.parseLocaleNumber(value, this.locale);
    this.stringValue = new Intl.NumberFormat(this.locale).format(tmpInput);
    e.currentTarget.value = this.stringValue;
    this.numberValue = this.parseLocaleNumber(this.stringValue, this.locale);
    if (this.stringValue == 'NaN') {
      this.stringValue = null;
      e.currentTarget.value = null;
    }

    if (this.numberValue) {
      if (this.max && this.numberValue > this.max) {
        const message = {
          toString: () => {
            return this.$t(`cbgwApp.cbComponent.validation.${this.messageToast ? this.messageToast : 'maxSize'}`);
          },
        };

        (<any>this.$root).$bvToast.toast(message.toString(), {
          toaster: 'b-toaster-top-center',
          title: 'Warning',
          variant: 'warning',
          solid: true,
          autoHideDelay: 5000,
        });
        this.$emit('input', this.max);
      } else if (this.numberValue < 0) {
        this.$emit('input', 0);
      } else {
        this.$emit('input', this.numberValue);
      }
    } else {
      this.$emit('input', 0);
    }
    this.valueListener();
  }

  public parseLocaleNumber(stringNumber, locale): number {
    const thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, '');
    return parseFloat(
      stringNumber.replace(new RegExp('\\' + thousandSeparator, 'g'), '').replace(new RegExp('\\' + decimalSeparator), '.')
    );
  }
  public handlePaste(e: any) {
    const message = {
      toString: () => {
        return this.$t('cbgwApp.cbComponent.validation.cannotPaste');
      },
    };
    (<any>this.$root).$bvToast.toast(message.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Warning',
      variant: 'warning',
      solid: true,
      autoHideDelay: 5000,
    });
    e.preventDefault();
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  @Watch('currency')
  public currencyListener() {
    if (this.currency) {
      for (const currency in CbInputCurrencyType) {
        if (this.currency.toUpperCase() == currency) {
          this.locale = CbInputCurrencyType[currency];
        }
      }
    }
  }

  @Watch('value')
  public valueListener() {
    if (this.value) {
      const tmpInput: any = this.parseLocaleNumber(this.value.toString(), this.locale);
      this.stringValue = new Intl.NumberFormat(this.locale).format(tmpInput);
      if (this.stringValue == 'NaN') {
        this.stringValue = null;
      }
    } else {
      this.stringValue = null;
      this.numberValue = null;
    }
  }

  // ================= END LISTENERS ==================
}
