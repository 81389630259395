var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "vac-media-preview",
      attrs: { tabindex: "0" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.closeModal.apply(null, arguments)
        },
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
      },
    },
    [
      _c("transition", { attrs: { name: "vac-bounce-preview", appear: "" } }, [
        _vm.isImage
          ? _c("div", { staticClass: "vac-media-preview-container" }, [
              _c("div", {
                staticClass: "vac-image-preview",
                style: {
                  "background-image": `url('${_vm.file.url}')`,
                },
              }),
            ])
          : _vm.isVideo
          ? _c("div", { staticClass: "vac-media-preview-container" }, [
              _c(
                "video",
                {
                  attrs: {
                    width: "100%",
                    height: "100%",
                    controls: "",
                    autoplay: "",
                  },
                },
                [_c("source", { attrs: { src: _vm.file.url } })]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vac-svg-button" },
        [
          _vm._t("preview-close-icon", function () {
            return [
              _c("svg-icon", {
                attrs: { name: "close-outline", param: "preview" },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }