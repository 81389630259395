var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "vac-fade-spinner", appear: "" } }, [
    _c(
      "div",
      {
        ref: "progress",
        staticClass: "c-progress-bar vac-progress-wrapper",
        style: `--radiusProgress:${_vm.radius}`,
      },
      [
        _c(
          "svg",
          { attrs: { height: _vm.radius * 2, width: _vm.radius * 2 } },
          [
            _c("circle", {
              style: {
                strokeDashoffset: _vm.strokeDashoffset,
                strokeLinecap: "round",
              },
              attrs: {
                stroke: "rgba(255, 255, 255, 0.7)",
                "stroke-dasharray": _vm.circumference + " " + _vm.circumference,
                "stroke-width": _vm.stroke,
                fill: "transparent",
                r: _vm.normalizedRadius,
                cx: _vm.radius,
                cy: _vm.radius,
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "vac-progress-content",
            style: {
              height: _vm.radius * 2 - 19 + "px",
              width: _vm.radius * 2 - 19 + "px",
            },
          },
          [
            _c("div", { staticClass: "vac-progress-text" }, [
              _vm._v(_vm._s(_vm.progress)),
              _c("span", { staticClass: "vac-progress-pourcent" }, [
                _vm._v("%"),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }