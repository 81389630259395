import axios from 'axios';

const baseApiUrl = 'services/cbuserms/api/cb-vw-billing-histories';

export default class PCbVwBillingHistoryService {
  public retrieveByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public downloadBillingHistoryPdf(method: string, id: number): Promise<any> {
    return axios
      .get(`${baseApiUrl}/download-pdf/${method}/${id}`, {
        responseType: 'arraybuffer',
        onDownloadProgress: progressEvent => {},
      })
      .then(res => {
        let headerLine = res.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(err => {});
  }

  public retrieveCanceledInvoice(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/canceled-invoice/by-buyer-login`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
