import { App } from '@/shared/model/enumerations/app.model';
import axios from 'axios';

const baseApiUrl = 'services/crmcorems/api/cm-vw-sop-inputed-agg-req-confirms';


export default class PCmVwSopInputedAggReqConfirmService {

  public retrieveByAppAndExternalId(app: App, tktExternalId: StringConstructor): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${app}/${tktExternalId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

}