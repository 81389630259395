export interface ICbVwUserSummary {
  id?: string;
  orderCompleted?: number | null;
  overduePercentage?: number | null;
  orderCompletedPercentage?: number | null;
  freelancerWorkWith?: number | null;
  lastProjectDelivery?: string | null;
  lastProjectDeliveryDate?: Date | null;
  lastClockOut?: string | null;
  userRating?: number | null;
  profileVisit?: number | null;
  profileInSearch?: number | null;
  resultOnTime?: number | null;
  totalOrderBuyer?: number | null;
  totalFreelancerBuyer?: number | null;
}

export class CbVwUserSummary implements ICbVwUserSummary {
  constructor(
    public id?: string,
    public orderCompleted?: number | null,
    public overduePercentage?: number | null,
    public orderCompletedPercentage?: number | null,
    public freelancerWorkWith?: number | null,
    public lastProjectDelivery?: string | null,
    public lastProjectDeliveryDate?: Date | null,
    public lastClockOut?: string | null,
    public userRating?: number | null,
    public profileVisit?: number | null,
    public profileInSearch?: number | null,
    public resultOnTime?: number | null,
    public totalOrderBuyer?: number | null,
    public totalFreelancerBuyer?: number | null
  ) {}
}
