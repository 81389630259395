import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { ICbAttachmentRestrict } from '@/shared/model/cb-attachment-restrict.model';
import { ICbTextRestrict, CbTextRestrict } from '@/shared/model/cb-text-restrict.model';
import { CbVwPawOrderRequirement, ICbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';
import { CbVwPawOrder, ICbVwPawOrder } from '@/shared/model/cb-vw-paw-order.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbRequirementType } from '@/shared/model/enumerations/cb-requirement-type.model';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';
import { ModalStepFormOrderType } from '@/shared/model/enumerations/modal-step-form-order-type.model';

@Component({
  components: {
    Icon,
    CButton,
    CTextareaPreview,
  },
})
export default class CItemChatMessageRevision extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public isYourSelf: boolean;
  @Prop({ default: null }) public externalId: number;
  @Prop({ default: null }) public cbVwPawOrder: CbVwPawOrder;
  @Prop({ default: null }) public cbVwPawOrderRequirement: CbVwPawOrderRequirement;
  @Prop({ default: null }) public cbTextRestrict: CbTextRestrict;
  public CbCommonStatus = CbCommonStatus;
  public CbRequirementType = CbRequirementType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public openAskRevision() {
    if (this.cbVwPawOrderRequirement.requirementType == CbRequirementType.REVISION_EXTRA) {
      // this.$root.$emit('opc::changeValue', 'cbTextRestrict', new CbTextRestrict());
      // this.$root.$emit('opc::changeValue', 'cbVwPawOrderRequirementRevision', new CbVwPawOrderRequirement());
      // this.$root.$emit('opc::changeValue', 'cbVwPawOrderRequirement', new CbVwPawOrderRequirement());
      // this.$root.$emit('opc::changeValue', 'cbVwPawOrder', new CbVwPawOrder());

      // this.$root.$emit('opc::changeValue', 'cbVwPawOrderRequirementRevision', this.cbVwPawOrderRequirement);
      // const cbTextRestrict = this.cbTextRestricts.find(e => e.id == this.cbVwPawOrderRequirement.descriptionId);
      // if (cbTextRestrict) {
      //   this.$root.$emit('opc::changeValue', 'cbTextRestrict', cbTextRestrict);
      // }

      // find reqDelivery
      // let cbVwPawOrderRequirements: ICbVwPawOrderRequirement[] = [];
      // this.$root.$emit('opc::getVariable', 'cbVwPawOrderRequirements', value => {
      //   cbVwPawOrderRequirements = value;
      // });

      // if (cbVwPawOrderRequirements?.length > 0) {
      //   const cbVwPawOrderDelivery = cbVwPawOrderRequirements.find(e => e.id == this.cbVwPawOrderRequirement.refRequirementId);
      //   this.$root.$emit('opc::changeValue', 'cbVwPawOrderRequirement', cbVwPawOrderDelivery);
      // }

      // find cbVwPawOrder
      let cbVwPawOrders: ICbVwPawOrder[] = [];
      this.$root.$emit('opc::getVariable', 'cbVwPawOrders', value => (cbVwPawOrders = value));

      const cbVwPawOrder = cbVwPawOrders.find(e => e.id == this.cbVwPawOrderRequirement.cbPawOrderId);
      if (cbVwPawOrder?.id) this.$root.$emit('opc::changeValue', 'cbVwPawOrder', cbVwPawOrder);

      const tmpModalStepFormOrders: ModalStepFormOrderType[] = [];

      tmpModalStepFormOrders.push(ModalStepFormOrderType.isShowProjectDelivery);
      this.$root.$emit('opc::changeValue', 'modalStepFormOrders', tmpModalStepFormOrders);

      (<any>this.$root).$emit('mdl::changeValue', 'isShowProjectDelivery', true);
    } else {
      const tmpModalStepFormOrders: ModalStepFormOrderType[] = [];

      tmpModalStepFormOrders.push(ModalStepFormOrderType.isShowAskRevision);
      this.$root.$emit('opc::changeValue', 'modalStepFormOrders', tmpModalStepFormOrders);

      this.$root.$emit('opc::changeValue', 'cbVwPawOrderRequirement', this.cbVwPawOrderRequirement);
      (<any>this.$root).$emit('mdl::changeValue', 'isShowAskRevision', true);
    }
  }
  public downloadFileDelivery(cbAttachmentRestrict: ICbAttachmentRestrict) {
    if (cbAttachmentRestrict.localUrl) {
      window.open(cbAttachmentRestrict.localUrl, '_blank');
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get token() {
    return localStorage.getItem('cb-jhi-authenticationToken');
  }
  public get cbTextRestricts(): ICbTextRestrict[] {
    return this.$store.getters.cbTextRestricts;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
