import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';

import Icon from '@/shared/icons/icon.vue';
import CTextareaPreview from '@/components/c-textarea-preview/c-textarea-preview.vue';

import GlobalService from '@/services/global.service';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { CbVwAuctionProjectPreOffer } from '@/shared/model/cb-vw-auction-project-pre-offer.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';

@Component({
  components: {
    Icon,
    CTextareaPreview,
  },
  mixins: [Vue2Filters.mixin, StatusTextEnId],
})
export default class COfferDetail extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================

  @Prop() cbVwAuctionProjectPreOffer: CbVwAuctionProjectPreOffer;

  public CbCommonStatus = CbCommonStatus;
  public CbSvcType = CbSvcType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public deleteOffer() {
    this.$emit('openDeleteOffer', this.cbVwAuctionProjectPreOffer.id);
  }
  public closeAllDialog() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-item-card-offer');
  }
  public handleCloseOfferDetail() {
    this.$emit('handleCloseOfferDetail');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
