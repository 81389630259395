var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-set-new-password" },
    [
      _c("div", { staticClass: "title-m" }, [
        _c("div", { staticClass: "d-flex pr-3 cj-bg-white-primary" }, [
          _c(
            "div",
            {
              staticClass: "cursor-pointer my-auto d-flex p-3",
              attrs: { "data-cy": "go-back" },
              on: { click: _vm.onBack },
            },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "cb-icon-size-arrow-left cj-color-black-primary my-auto",
                attrs: { icon: "fa-solid fa-arrow-left" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", {
            staticClass:
              "cj-color-black-primary font-roboto font-size-18 font-weight-500 mr-3 my-auto",
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.password.title")),
            },
          }),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "m-0 p-0" }),
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass:
          "font-size-24 title-w cj-color-black-primary font-roboto font-weight-700",
        domProps: {
          textContent: _vm._s(
            _vm.$t("cbgwApp.cbSetting.password.setNewPassword")
          ),
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { name: "form", role: "form", id: "password-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.changePassword()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "row mt-4 no-gutters px-3 px-sm-3 px-md-3 px-lg-0 px-xl-0",
            },
            [
              _c(
                "div",
                { staticClass: "col-lg-4 col-md-4 col-sm-12 col-xs-12" },
                [
                  _c("p", {
                    staticClass:
                      "font-title font-roboto cj-color-black-primary font-weight-500",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbSetting.password.newPassword")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-lg-8 col-md-8 col-sm-12 col-xs-12 m-0 p-0",
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _vm.passwordInputType === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.resetPassword.newPassword.$model,
                              expression: "$v.resetPassword.newPassword.$model",
                            },
                          ],
                          class: {
                            invalid: _vm.invalidPassword,
                            valid:
                              _vm.$v.resetPassword.newPassword.$anyDirty &&
                              !_vm.invalidPassword,
                          },
                          attrs: {
                            id: "newPassword",
                            name: "newPassword",
                            placeholder: _vm.$t(
                              "cbgwApp.cbComponent.cbSetPassword.typePassword"
                            ),
                            required: "",
                            "data-cy": "new-password-input",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.$v.resetPassword.newPassword.$model
                            )
                              ? _vm._i(
                                  _vm.$v.resetPassword.newPassword.$model,
                                  null
                                ) > -1
                              : _vm.$v.resetPassword.newPassword.$model,
                          },
                          on: {
                            keyup: function ($event) {
                              return _vm.onInputRequired($event.target.value)
                            },
                            change: function ($event) {
                              var $$a = _vm.$v.resetPassword.newPassword.$model,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.$v.resetPassword.newPassword,
                                      "$model",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.$v.resetPassword.newPassword,
                                      "$model",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.$v.resetPassword.newPassword,
                                  "$model",
                                  $$c
                                )
                              }
                            },
                          },
                        })
                      : _vm.passwordInputType === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.resetPassword.newPassword.$model,
                              expression: "$v.resetPassword.newPassword.$model",
                            },
                          ],
                          class: {
                            invalid: _vm.invalidPassword,
                            valid:
                              _vm.$v.resetPassword.newPassword.$anyDirty &&
                              !_vm.invalidPassword,
                          },
                          attrs: {
                            id: "newPassword",
                            name: "newPassword",
                            placeholder: _vm.$t(
                              "cbgwApp.cbComponent.cbSetPassword.typePassword"
                            ),
                            required: "",
                            "data-cy": "new-password-input",
                            type: "radio",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.$v.resetPassword.newPassword.$model,
                              null
                            ),
                          },
                          on: {
                            keyup: function ($event) {
                              return _vm.onInputRequired($event.target.value)
                            },
                            change: function ($event) {
                              return _vm.$set(
                                _vm.$v.resetPassword.newPassword,
                                "$model",
                                null
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.resetPassword.newPassword.$model,
                              expression: "$v.resetPassword.newPassword.$model",
                            },
                          ],
                          class: {
                            invalid: _vm.invalidPassword,
                            valid:
                              _vm.$v.resetPassword.newPassword.$anyDirty &&
                              !_vm.invalidPassword,
                          },
                          attrs: {
                            id: "newPassword",
                            name: "newPassword",
                            placeholder: _vm.$t(
                              "cbgwApp.cbComponent.cbSetPassword.typePassword"
                            ),
                            required: "",
                            "data-cy": "new-password-input",
                            type: _vm.passwordInputType,
                          },
                          domProps: {
                            value: _vm.$v.resetPassword.newPassword.$model,
                          },
                          on: {
                            keyup: function ($event) {
                              return _vm.onInputRequired($event.target.value)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.resetPassword.newPassword,
                                "$model",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-password",
                        attrs: { type: "button" },
                        on: { click: _vm.togglePassword },
                      },
                      [
                        _c("icon", {
                          class: `cj-stroke-grey-secondary ${
                            _vm.passwordInputType === "text"
                              ? "d-block"
                              : "d-none"
                          }`,
                          attrs: { name: "ic_eye_open", width: "1rem" },
                        }),
                        _vm._v(" "),
                        _c("icon", {
                          class: `cj-stroke-grey-secondary ${
                            _vm.passwordInputType === "password"
                              ? "d-block"
                              : "d-none"
                          }`,
                          attrs: { name: "ic_eye_close", width: "1rem" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row mt-4 no-gutters px-3 px-sm-3 px-md-3 px-lg-0 px-xl-0",
            },
            [
              _c(
                "div",
                { staticClass: "col-lg-4 col-md-4 col-sm-12 col-xs-12" },
                [
                  _c("p", {
                    staticClass:
                      "font-title font-roboto cj-color-black-primary font-weight-500",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbSetting.password.confirmNewPassword")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "col-lg-8 col-md-8 col-sm-12 col-xs-12 m-0 p-0 mb-2",
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _vm.passwordInputType === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.$v.resetPassword.confirmPassword.$model,
                              expression:
                                "$v.resetPassword.confirmPassword.$model",
                            },
                          ],
                          class: {
                            valid:
                              _vm.$v.resetPassword.confirmPassword.$anyDirty &&
                              !_vm.$v.resetPassword.confirmPassword.$invalid,
                            invalid:
                              _vm.$v.resetPassword.confirmPassword.$anyDirty &&
                              _vm.$v.resetPassword.confirmPassword.$invalid,
                          },
                          attrs: {
                            id: "confirmPassword",
                            name: "confirmPassword",
                            placeholder: _vm.$t(
                              "cbgwApp.cbComponent.cbSetPassword.typeConfirmPassword"
                            ),
                            required: "",
                            "data-cy": "confirm-new-password-input",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.$v.resetPassword.confirmPassword.$model
                            )
                              ? _vm._i(
                                  _vm.$v.resetPassword.confirmPassword.$model,
                                  null
                                ) > -1
                              : _vm.$v.resetPassword.confirmPassword.$model,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.$v.resetPassword.confirmPassword.$model,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.$v.resetPassword.confirmPassword,
                                      "$model",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.$v.resetPassword.confirmPassword,
                                      "$model",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.$v.resetPassword.confirmPassword,
                                  "$model",
                                  $$c
                                )
                              }
                            },
                          },
                        })
                      : _vm.passwordInputType === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.$v.resetPassword.confirmPassword.$model,
                              expression:
                                "$v.resetPassword.confirmPassword.$model",
                            },
                          ],
                          class: {
                            valid:
                              _vm.$v.resetPassword.confirmPassword.$anyDirty &&
                              !_vm.$v.resetPassword.confirmPassword.$invalid,
                            invalid:
                              _vm.$v.resetPassword.confirmPassword.$anyDirty &&
                              _vm.$v.resetPassword.confirmPassword.$invalid,
                          },
                          attrs: {
                            id: "confirmPassword",
                            name: "confirmPassword",
                            placeholder: _vm.$t(
                              "cbgwApp.cbComponent.cbSetPassword.typeConfirmPassword"
                            ),
                            required: "",
                            "data-cy": "confirm-new-password-input",
                            type: "radio",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.$v.resetPassword.confirmPassword.$model,
                              null
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.$v.resetPassword.confirmPassword,
                                "$model",
                                null
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.$v.resetPassword.confirmPassword.$model,
                              expression:
                                "$v.resetPassword.confirmPassword.$model",
                            },
                          ],
                          class: {
                            valid:
                              _vm.$v.resetPassword.confirmPassword.$anyDirty &&
                              !_vm.$v.resetPassword.confirmPassword.$invalid,
                            invalid:
                              _vm.$v.resetPassword.confirmPassword.$anyDirty &&
                              _vm.$v.resetPassword.confirmPassword.$invalid,
                          },
                          attrs: {
                            id: "confirmPassword",
                            name: "confirmPassword",
                            placeholder: _vm.$t(
                              "cbgwApp.cbComponent.cbSetPassword.typeConfirmPassword"
                            ),
                            required: "",
                            "data-cy": "confirm-new-password-input",
                            type: _vm.passwordInputType,
                          },
                          domProps: {
                            value: _vm.$v.resetPassword.confirmPassword.$model,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.resetPassword.confirmPassword,
                                "$model",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-password",
                        attrs: { type: "button" },
                        on: { click: _vm.togglePassword },
                      },
                      [
                        _c("icon", {
                          class: `cj-stroke-grey-secondary ${
                            _vm.passwordInputType === "text"
                              ? "d-block"
                              : "d-none"
                          }`,
                          attrs: { name: "ic_eye_open", width: "1rem" },
                        }),
                        _vm._v(" "),
                        _c("icon", {
                          class: `cj-stroke-grey-secondary ${
                            _vm.passwordInputType === "password"
                              ? "d-block"
                              : "d-none"
                          }`,
                          attrs: { name: "ic_eye_close", width: "1rem" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row no-gutters px-3 px-sm-3 px-md-3 px-lg-0 px-xl-0",
            },
            [
              _c("div", {
                staticClass: "col-lg-4 col-md-4 col-sm-12 col-xs-12",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-lg-8 col-md-8 col-sm-12 col-xs-12" },
                [
                  _vm.resetPassword.confirmPassword &&
                  _vm.resetPassword.confirmPassword.length > 0 &&
                  _vm.resetPassword.confirmPassword !=
                    _vm.resetPassword.newPassword
                    ? _c("div", { staticClass: "d-flex mb-2 mt-3" }, [
                        _c("small", {
                          staticClass: "font-validation cj-color-status-error",
                          attrs: {
                            "data-cy": "new-password-confirm-error-message",
                          },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationConfirmPassword"
                              )
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", {
                      staticClass: "font-validation",
                      style: "color:" + _vm.styleValidPassOne,
                      attrs: {
                        id: "span-one",
                        "data-cy": "new-password-validation-1",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationOne"
                          )
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", {
                      staticClass: "font-validation",
                      style: "color:" + _vm.styleValidPassTwo,
                      attrs: {
                        id: "span-two",
                        "data-cy": "new-password-validation-2",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationTwo"
                          )
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", {
                      staticClass: "font-validation",
                      style: "color:" + _vm.styleValidPassThree,
                      attrs: {
                        id: "span-three",
                        "data-cy": "new-password-validation-3",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationThree"
                          )
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("span", {
                      staticClass: "font-validation",
                      style: "color:" + _vm.styleValidPassFour,
                      attrs: {
                        id: "span-four",
                        "data-cy": "new-password-validation-4",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.register.validationFour"
                          )
                        ),
                      },
                    }),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex my-4" },
            [
              _c("c-button", {
                staticClass: "font-roboto px-5 ml-auto title-w font-title",
                attrs: {
                  type: "submit",
                  disabled:
                    _vm.$v.resetPassword.$invalid || _vm.isInitChangePassword,
                  "data-cy": "submit-new-password",
                  "data-gtm": "submit-change-password-button",
                },
                domProps: {
                  textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.update")),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "title-m position-absolute w-100",
              staticStyle: { bottom: "0" },
            },
            [
              _c("hr", { staticClass: "m-0 p-0" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex mt-2 px-3" },
                [
                  _c("c-button", {
                    staticClass: "font-roboto font-title",
                    attrs: {
                      dataCyCustom: "update-password-button",
                      type: "submit",
                      disabled:
                        _vm.$v.resetPassword.$invalid ||
                        _vm.isInitChangePassword,
                      fullWidth: "",
                    },
                    domProps: {
                      textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.update")),
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            id: "modal-c-success-change-password-m",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                title: "Success",
                hideClose: true,
                subTitle: "Password successfully updated",
                iconCustom: "ic_check_circle",
              },
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            centered: "",
            size: "md",
            id: "modal-c-fail-change-password-m",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [
          [
            _c("c-confirmation", {
              staticClass: "w-100",
              attrs: {
                hideClose: true,
                subTitle: _vm.$t("cbgwApp.cbComponent.cModalChange.info"),
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("b-icon-x-circle", {
                        staticClass:
                          "cj-color-status-error font-size-64 mb-2 mb-lg-3",
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            "modal-class": "vac-custom-modal-b-modal",
            id: "modal-c-change-password-init",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
          },
        },
        [
          [
            _c(
              "div",
              [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("icon", {
                        staticClass: "m-auto cj-fill-red-primary",
                        attrs: {
                          name: "ic_message",
                          width: "54px",
                          height: "54px",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-center font-catamaran cj-color-black-primary font-size-24 font-weight-700",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cbResetPassword.checkEmail")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran cj-color-black-primary font-size-18 font-weight-300 text-center mt-2",
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cbSetPassword.changePassword"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-600",
                          attrs: { "data-cy": "c-set-new-password-email" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.cbVerificationOtp &&
                                _vm.cbVerificationOtp.email
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "c-button",
                  {
                    staticClass: "font-roboto font-size-20 mt-3",
                    attrs: {
                      dataCyCustom: "c-set-new-password-button-continue",
                      fullWidth: "",
                    },
                    nativeOn: {
                      click: function ($event) {
                        _vm.closeDialog()
                        _vm.visibleVerificationPassword = true
                      },
                    },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbSetting.continue")
                        ),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-c-verification-password",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("div", { staticClass: "position-relative" }, [
                  _c("p", {
                    staticClass:
                      "font-roboto cj-color-black-primary font-weight-700 text-center",
                    class: _vm.isMobile ? "font-size-20" : "font-size-24",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.emailVerification"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "position-absolute",
                      staticStyle: { top: "0", right: "0" },
                      attrs: { type: "button" },
                      on: { click: cancel },
                    },
                    [
                      _c("b-icon-x-circle-fill", {
                        staticClass: "font-size-18 cj-color-grey-secondary",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr", {
                    staticStyle: {
                      "margin-left": "-1rem",
                      "margin-right": "-1rem",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("c-verification-password", {
                  attrs: {
                    visibleCountdown: true,
                    visibleHeader: true,
                    visibleFooter: true,
                    value: _vm.cbVerificationOtp,
                    isFetching: _vm.isFetching,
                    invalidOtp: _vm.invalidOtp,
                  },
                  on: { changeFunction: _vm.changeFunction },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleVerificationPassword,
          callback: function ($$v) {
            _vm.visibleVerificationPassword = $$v
          },
          expression: "visibleVerificationPassword",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-success-update-password",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t("cbgwApp.cbComponent.cbSetPassword.success"),
                    subTitle: _vm.$t(
                      "cbgwApp.cbComponent.cbSetPassword.passwordSuccessUpdated"
                    ),
                    iconCustom: "ic_check_circle",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleSuccessPassword,
          callback: function ($$v) {
            _vm.visibleSuccessPassword = $$v
          },
          expression: "visibleSuccessPassword",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }