import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

import GlobalService from '@/services/global.service';

import Icon from '@/shared/icons/icon.vue';

@Component({
  components: { Icon },
})
export default class CMediaPreview extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public fileMediaPreview: string;

  public screenHeight = this.isMobile ? window.innerHeight + 'px' : window.innerHeight - 96 + 'px';
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    window.addEventListener('resize', ev => {
      this.screenHeightMediaPreview();
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public screenHeightMediaPreview() {
    if (this.isMobile) {
      this.screenHeight = window.innerHeight + 'px';
    } else {
      this.screenHeight = window.innerHeight - 96 + 'px';
    }
  }

  public closeModal() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-media-preview');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
}
