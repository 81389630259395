import axios from 'axios';

import { ICbVwAvgResponseTime } from '@/shared/model/cb-vw-avg-response-time.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-avg-response-times';

export default class PCbVwAvgResponseTimeService {
  public find(id: string): Promise<ICbVwAvgResponseTime> {
    if(!id)return;
    
    return new Promise<ICbVwAvgResponseTime>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveBylogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
