import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
    CButton,
  },
})
export default class CFpPaymentOptions extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
