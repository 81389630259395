import axios from 'axios';

import { ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';

const baseApiUrl = 'services/cbuserms/api/cb-text-no-auths';

export default class PCbTextNoAuthService {
  public find(id: number, cancelToken?: any): Promise<ICbTextNoAuth> {
    return new Promise<ICbTextNoAuth>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
