var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-paw-set-work-location",
      attrs: { "data-cy": "modal-set-work-location" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center bar-wrapper pb-3",
          class: {
            "justify-content-lg-between":
              !_vm.isMobile || _vm.modalStepFormOrders?.length === 0,
            "justify-content-start m-n3":
              (_vm.isMobile && _vm.modalStepFormOrders?.length > 0) ||
              (_vm.isMobile && !_vm.modalStepFormOrders),
          },
        },
        [
          _c(
            "div",
            {
              class: {
                "vac-navbar-button-hide":
                  !_vm.isMobile || _vm.modalStepFormOrders?.length === 0,
              },
            },
            [
              _vm.isMobile || _vm.modalStepFormOrders?.length > 1
                ? _c(
                    "button",
                    {
                      staticClass:
                        "cursor-pointer my-auto d-flex align-items-center justify-content-center p-3",
                      attrs: {
                        type: "button",
                        "data-cy": "location-btn-modal-back",
                      },
                      on: {
                        click: function ($event) {
                          _vm.modalStepFormOrders?.length > 1
                            ? _vm.back()
                            : _vm.cancel()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass:
                          "cb-icon-size-arrow-left cj-color-black-primary",
                        attrs: { icon: "fa-solid fa-arrow-left" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "p",
              {
                staticClass:
                  "font-roboto cj-color-black-primary font-weight-700 font-size-24",
              },
              [
                _vm.type === _vm.TypeComponent.APPOINTMENT
                  ? _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cbSetWorkLocation.titleAppointment"
                          )
                        ),
                      },
                    })
                  : _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbComponent.cbSetWorkLocation.title")
                        ),
                      },
                    }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            (_vm.modalStepFormOrders?.length > 0 && !_vm.isMobile) ||
            (!_vm.modalStepFormOrders && !_vm.isMobile)
              ? _c(
                  "button",
                  {
                    staticClass: "cursor-pointer d-flex",
                    attrs: {
                      type: "button",
                      "data-cy": "location-btn-modal-close",
                    },
                    on: { click: _vm.cancel },
                  },
                  [
                    _c("b-icon-x-circle-fill", {
                      staticClass: "font-size-24 cj-color-grey-secondary",
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "room-location-container",
          style: `--heightMap:${_vm.screenHeight};`,
        },
        [
          _c("c-location-map", {
            staticClass: "mb-3 location-wrapper-m",
            attrs: { placeModel: _vm.parentCbLocation, mapSize: "large" },
            on: { callback: _vm.locationMapCallback },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-items-center p-2 info-wrapper" },
            [
              _c("div", { staticClass: "mr-3" }, [
                _c(
                  "div",
                  { staticClass: "circle-icon-wrapper" },
                  [
                    _c("icon", {
                      staticClass: "cj-stroke-red-primary",
                      attrs: {
                        name: "ic_location",
                        width: "18px",
                        height: "18px",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1 text-description" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "m-0 font-roboto cj-color-black-primary font-weight-500 cj-color-black-primary font-size-16",
                    attrs: { "data-cy": "address-pin-point" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cbLocation.addressPinPoint) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "m-0 font-catamaran cj-color-black-primary font-weight-300 font-size-12",
                    attrs: { "data-cy": "address" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cbLocation.address) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-end pt-3 btn-custom-center-m" },
        [
          _c(
            "c-button",
            {
              staticClass: "btn-continue",
              attrs: {
                "data-cy": "btn-modal-location-continue",
                fullWidth: "",
                disabled: _vm.$v.cbLocation.$invalid,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbComponent.cbSetWorkLocation.continue")
                  ),
                },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }