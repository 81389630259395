import { Module } from 'vuex';
export interface WindowStateStorable {
  isMobile: boolean;
  isDeviceSize: string;
}

export const defaultWindowState: WindowStateStorable = {
  isMobile: false,
  isDeviceSize: '',

};

export const windowStore: Module<WindowStateStorable, any> = {
  state: { ...defaultWindowState },
  getters: {
    isMobile: state => state.isMobile,
    isDeviceSize: state => state.isDeviceSize,
  },
  mutations: {
    isMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    isDeviceSize(state, isDeviceSize) {
      state.isDeviceSize = isDeviceSize;
    },
  },
};
