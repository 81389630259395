import CIcons from '@/module/chat-container/chat/components/c-icons/c-icons.vue';
import CTakePicture from '@/module/chat-container/chat/components/c-take-picture/c-take-picture.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    CTakePicture,
    CIcons,
  },
})
export default class roomCameraChat extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: 'Send to Chat' }) btnTextConfirm: string;
  public switchCameraRecord = false;
  public showSwitchCameraRecord = true;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public closeShowSwitchCameraRecord() {
    this.showSwitchCameraRecord = false;
  }

  public isShowSwitchCameraRecord() {
    this.showSwitchCameraRecord = true;
  }
  public callBack() {
    this.$emit('callBack', null);
  }
  public submitPicture(att, attContentType, extension, localUrl, blob, attSize) {
    this.$emit('submitPicture', { att, attContentType, extension, localUrl, blob, attSize });
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
