import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';

@Component({
  components: {
    CButton,
  },
})
export default class CSetPinSetting extends Vue {
  @Prop({ default: false }) public pinActivated: boolean;
  @Prop({ default: false }) public isPinExist: boolean;
  @Prop({ default: false }) public isInit: boolean;

  @Emit('modalSetPin')
  public modalSetPin() {
    return;
  }

  @Emit('modalChangePin')
  public modalChangePin() {
    return;
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  
  public handleForgotPin(){
    (<any>this.$root).$emit('submitResetPin');
  }
}
