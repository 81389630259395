import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Icon extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public name: string;
  @Prop({ default: '1rem' }) public width: string;
  @Prop({ default: '100%' }) public height: string;
  @Prop({ default: '#B3B3B3 ' }) public customColor: string;
  @Prop({ default: '#B3B3B3 ' }) public stopColorFirst: string;
  @Prop({ default: '#B3B3B3 ' }) public stopColorLast: string;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
