import axios from 'axios';

import { IChtMessageDelete } from '@/shared/model/cht-message-delete.model';

const baseApiUrl = 'services/btichat/api/cht-message-deletes';

export default class ChtMessageDeleteService {
  public find(id: number): Promise<IChtMessageDelete> {
    return new Promise<IChtMessageDelete>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IChtMessageDelete): Promise<IChtMessageDelete> {
    return new Promise<IChtMessageDelete>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IChtMessageDelete): Promise<IChtMessageDelete> {
    return new Promise<IChtMessageDelete>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IChtMessageDelete): Promise<IChtMessageDelete> {
    return new Promise<IChtMessageDelete>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteByIds(paginationQuery: any): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/by-ids?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}`, {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
