var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-notification" }, [
    _c(
      "div",
      {
        staticClass:
          "justify-content-between d-block d-lg-none cj-bg-white-primary p-0 c-navbar-m border-grey-tertiary-bottom-1 pr-3 d-flex",
      },
      [
        _c("div", { staticClass: "d-flex" }, [
          _c(
            "div",
            {
              staticClass: "cursor-pointer my-auto d-flex p-3",
              attrs: { "data-cy": "go-back" },
              on: { click: _vm.onBack },
            },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "cb-icon-size-arrow-left cj-color-black-primary my-auto",
                attrs: { icon: "fa-solid fa-arrow-left" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", {
            staticClass:
              "cj-color-black-primary font-roboto font-size-16 font-weight-500 my-auto",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cNotification.notification")
              ),
            },
          }),
        ]),
        _vm._v(" "),
        _vm.tab == _vm.enumTab.NOTIFICATION
          ? _c("p", {
              staticClass:
                "font-catamaran cj-color-red-primary font-weight-400 font-size-14 my-auto",
              attrs: { "data-cy": "btn-wrap-notification" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.cNotification.readAll")
                ),
              },
              on: { click: _vm.updateReadAll },
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex w-100 justify-content-between header-tab" },
      [
        _c(
          "div",
          {
            class: `btn-tab d-flex justify-content-center w-100  px-1 px-sm-3 pb-3 pt-3 pt-lg-2 ${
              _vm.tab === _vm.enumTab.MESSAGE ? "active" : ""
            }`,
            attrs: { "data-cy": "notification-btn-tab-message" },
            on: {
              click: function ($event) {
                _vm.tab = _vm.enumTab.MESSAGE
              },
            },
          },
          [
            _c("p", {
              staticClass: "font-roboto font-size-16 font-weight-500 mr-2",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.cNotification.message")
                ),
              },
            }),
            _vm._v(" "),
            _vm.countChat > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "notification-counter cj-color-white-primary font-roboto font-weight-700 font-size-10 my-auto",
                    class: _vm.countChat > 99 ? "actived-max-count" : "",
                    attrs: { "data-cy": "count-notification-message" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("maxCount")(_vm.countChat, _vm.countChat)
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: `btn-tab w-100 d-flex justify-content-center  px-1 px-sm-3 pb-3 pt-3 pt-lg-2 ${
              _vm.tab === _vm.enumTab.NOTIFICATION ? "active" : ""
            }`,
            attrs: {
              "data-cy": "notification-btn-tab-notif",
              "data-gtm": "notification-tab-notif-button",
            },
            on: {
              click: function ($event) {
                _vm.tab = _vm.enumTab.NOTIFICATION
              },
            },
          },
          [
            _c("p", {
              staticClass: "font-roboto font-size-16 font-weight-500 mr-2",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.cNotification.notification")
                ),
              },
            }),
            _vm._v(" "),
            _vm.countNtNotif > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "notification-counter cj-color-white-primary font-roboto font-weight-700 font-size-10 my-auto",
                    class: _vm.countNtNotif > 99 ? "actived-max-count" : "",
                    attrs: { "data-cy": "count-notification-m" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("maxCount")(_vm.countNtNotif, _vm.countNtNotif)
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === _vm.enumTab.MESSAGE,
            expression: "tab === enumTab.MESSAGE",
          },
        ],
        staticClass: "border-radius-card wrap-border-responsive",
      },
      [
        _c("c-dashboard-chat-list", {
          staticClass: "contact-list-wrapper border-radius-card",
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tab === _vm.enumTab.NOTIFICATION,
            expression: "tab === enumTab.NOTIFICATION",
          },
        ],
      },
      [
        _c(
          "div",
          {
            staticClass:
              "border-radius-card wrap-border-responsive custom-notification",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-responsive-notification border-radius-card position-relative",
              },
              [
                _vm.isFetching && _vm.loadingNotif
                  ? _c("c-loader", {
                      staticClass: "d-flex m-auto",
                      attrs: {
                        show: _vm.loadingNotif && _vm.isFetching,
                        infinite: false,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                (!_vm.ntVwUserNotifs && !_vm.isFetching) ||
                (!_vm.isFetching && _vm.ntVwUserNotifs.length === 0)
                  ? _c("div", { staticClass: "text-center p-3 d-flex h-100" }, [
                      _c("p", {
                        staticClass:
                          "font-size-12 cj-color-black-primary font-weight-300 font-roboto m-auto",
                        attrs: { "data-cy": "no-notification" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbGlobal.noNotification")
                          ),
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.ntVwUserNotifs, function (ntUserNotif, index) {
                  return _c("c-item-notification", {
                    key: index,
                    attrs: {
                      ntUserNotif: ntUserNotif,
                      cbVwUsers: _vm.cbVwUsers,
                      tab: _vm.enumTab.NOTIFICATION,
                    },
                    on: { actionHandler: _vm.actionHandler },
                  })
                }),
                _vm._v(" "),
                _vm.totalItemsNotif > _vm.itemsPerPageNotif
                  ? _c(
                      "infinite-loading",
                      {
                        ref: "infiniteLoadingNotif",
                        attrs: {
                          slot: "append",
                          identifier: _vm.infiniteIdNotif,
                          distance: 10,
                        },
                        on: { infinite: _vm.loadMoreNotif },
                        slot: "append",
                      },
                      [
                        _c("div", {
                          attrs: { slot: "no-more" },
                          slot: "no-more",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "spinner" }, slot: "spinner" },
                          [
                            _c("c-loader", {
                              staticClass: "d-flex m-auto",
                              attrs: { show: true, infinite: true },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-none d-lg-block" }, [
          _c("p", {
            staticClass:
              "font-catamaran c-container cj-btn-text-primary font-weight-400 font-size-14 text-right mt-3",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cNotification.readAll")
              ),
            },
            on: { click: _vm.updateReadAll },
          }),
          _vm._v(" "),
          _c("hr", { staticClass: "p-0 m-0 d-block d-lg-none" }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }