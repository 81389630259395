import { BankAccountType } from './enumerations/bank-account-type.model';
import { CbVerificationStatus } from './enumerations/cb-verification-status.model';

export interface ICbVwBankAccount {
  id?: number | null;
  bankCode?: string | null;
  accountNumber?: string | null;
  accountHolderName?: string | null;
  bankAccountType?: BankAccountType | null;
  userId?: string | null;
  expiredDate?: Date | null;
  status?: CbVerificationStatus | null;
  lastIdcard?: string | null;
  lastIdcardSelfie?: string | null;
  historyIdcards?: string | null;
  historyIdcardSelfies?: string | null;
}

export class CbVwBankAccount implements ICbVwBankAccount {
  constructor(
    public id?: number | null,
    public bankCode?: string | null,
    public accountNumber?: string | null,
    public accountHolderName?: string | null,
    public bankAccountType?: BankAccountType | null,
    public userId?: string | null,
    public expiredDate?: Date | null,
    public status?: CbVerificationStatus | null,
    public lastIdcard?: string | null,
    public lastIdcardSelfie?: string | null,
    public historyIdcards?: string | null,
    public historyIdcardSelfies?: string | null
  ) {}
}
