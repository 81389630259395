import axios from 'axios';

import { ICbUserCrt } from '@/shared/model/cb-user-crt.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-crts';

export default class PCbUserCrtService {
  public retrieveByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-login', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByLogin(entity: ICbUserCrt, callBack: Function, callbackReject: Function): Promise<ICbUserCrt> {
    let tmpBlob = null;
    if (entity.cbAttachmentNoAuth) {
      entity.cbAttachmentNoAuth.att = '';
      tmpBlob = entity.cbAttachmentNoAuth.blob;
      delete entity.cbAttachmentNoAuth.blob;
    }
    return new Promise<ICbUserCrt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/by-login`, entity)
        .then(res => {
          const result: ICbUserCrt = res.data;
          if (result.uploadLink) {
            resolve(this.upload(result, result.uploadLink, entity.cbAttachmentNoAuth.attContentType, tmpBlob, callBack, callbackReject));
          } else {
            resolve(result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdateByLogin(entity: ICbUserCrt, callBack: Function, callbackReject: Function): Promise<ICbUserCrt> {
    let tmpBlob = null;
    if (entity.cbAttachmentNoAuth.att) {
      entity.cbAttachmentNoAuth.att = '';
    }
    tmpBlob = entity.cbAttachmentNoAuth.blob;
    delete entity.cbAttachmentNoAuth.blob;
    return new Promise<ICbUserCrt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/by-login/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);

          const result: ICbUserCrt = res.data;
          if (result.uploadLink) {
            resolve(this.upload(result, result.uploadLink, entity.cbAttachmentNoAuth.attContentType, tmpBlob, callBack, callbackReject));
          } else {
            resolve(result);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  private upload(
    result: ICbUserCrt,
    uploadLink: string,
    type: string,
    fileBlob: Blob,
    callBack: Function,
    callbackReject: Function
  ): Promise<any> {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': type,
      },
      onUploadProgress: uploadEvent => {
        const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
        callBack(progress);
      },
    };

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${uploadLink}`, fileBlob, config)
        .then(res => {
          resolve(result);
        })
        .catch(signUrlErr => {
          reject(callbackReject);
        });
    });
  }
}
