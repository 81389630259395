import Vue from 'vue';
import Component from 'vue-class-component';
import debounce from 'lodash/debounce'; // Assuming lodash is installed

import { ICbNavbar } from './shared/model/cb-navbar.model';
import { Route } from 'vue-router';
@Component({
  components: {},
})
export default class AppRouteManagemet extends Vue {
  public navbarData: ICbNavbar = {};

  public isShowTabFooter = true;
  public isShowFooter = false;
  public isShowFooterCopyRight = false;
  public isShow100Height = false;
  public isShowNavMobile = true;
  public isShowFooterBottomSheet = false;
  public isShowFloatingLiveChat = false;
  public isShowFloatingStore = false;
  public isDoubleCallBack = false;

  // // // // =================  VARIABLES =================
  // // // // ================= START DEFAULT FUNCTION =================
  public created() {
    (<any>this.$root).$off('hideNavbarHideFooter');
    (<any>this.$root).$on('hideNavbarHideFooter', () => {
      this.hideTabFooter();
      this.hideNavbar();
    });

    (<any>this.$root).$off('hideNavbarShowFooter');
    (<any>this.$root).$on('hideNavbarShowFooter', label => {
      this.hideNavbar();
      this.showTabFooter(label);
    });

    (<any>this.$root).$off('showNavbar');
    (<any>this.$root).$on('showNavbar', () => {
      this.showNavbarDefault();
    });

    (<any>this.$root).$off('hideFooter');
    (<any>this.$root).$on('hideFooter', () => {
      this.hideTabFooter();
    });

    (<any>this.$root).$off('showFooter');
    (<any>this.$root).$on('showFooter', label => {
      this.showTabFooter(label);
    });

    (<any>this.$root).$off('showPhWarning');
    (<any>this.$root).$on('showPhWarning', () => {
      this.$root.$emit('profile::changeValue', 'editMode', true);
    });

    (<any>this.$root).$off('watchRoute');
    (<any>this.$root).$on(
      'watchRoute',
      debounce(async (routeFrom: Route, routeTo: Route) => {
        this.$store.commit('previousRouteFullPath', routeFrom.fullPath);
        this.$store.commit('previousRouteName', routeFrom.name);
        this.$store.commit('nextRouteName', routeTo.name);

        if (routeFrom.name !== 'PAskCommitee' && routeFrom.name !== 'PRequestedTicket') {
          this.$store.commit('previousRouteChat', routeFrom.name);
        } else if (routeTo.name === 'PPawCreateOne' || routeTo.name === 'PPawCreateOneEdit') {
          this.$store.commit('routeModulePaw', routeTo);
        }

        this.hideFloatingStore();
        this.hideFloatingLiveChat();
        this.isShowFooterBottomSheet = false;
        this.isShowFooter = false;
        this.isDoubleCallBack = false;
        this.isShowFooterCopyRight = false;
        this.isShow100Height = false;
        const routeToName = routeTo.name;
        const routeQuery = routeTo.query;

        this.navbarData = {};
        if (routeToName == 'PHome') {
          this.isShow100Height = true;
          //Bug ketika sudah login manggil fotter
          if (!this.$store.getters.authenticated) {
            this.isShowFooter = true;
          }
          this.showTabFooter('isFillHome');
          this.showNavbarDefault();
          this.showFloatingStore();
          this.showFloatingLiveChat();
        } else if (routeToName == 'PGeneral' || routeToName == 'PFreelancerHome') {
          this.isShow100Height = true;
          this.isShowFooter = true;
          this.showTabFooter('isFillHome');
          this.showNavbarDefault();
          this.showFloatingStore();
          this.showFloatingLiveChat();
        } else if (routeToName == 'PBuyerHome') {
          this.isShowFooterBottomSheet = true;
          this.showTabFooter('isFillHome');
          this.showNavbarDefault();
          this.showFloatingStore();
          this.showFloatingLiveChat();
        } else if (routeToName == 'PSearchMenuM') {
          this.showTabFooter('isFillSearch');
          this.hideNavbar();
        } else if (routeToName == 'PAppChat') {
          this.showTabFooter('isFillChat');
          this.hideNavbar();
          this.hideFloatingStore();
          this.hideFloatingLiveChat();
        } else if (routeToName == 'PManageOrder') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          if (routeQuery.tab == 'ON') {
            this.showTabFooter('isFillOrder');
          } else {
            this.hideTabFooter();
            this.hideNavbar();
          }
        } else if (routeToName == 'PPawManage') {
          this.isShowFooter = true;
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'PProfile') {
          this.showTabFooter('isFillProfile');
          this.showNavbarDefault();
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
        } else if (routeToName == 'PProfilePublic') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: 'Profile' });
        } else if (routeToName == 'PSvc') {
          this.isShowFooter = true;
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'CNavbarMenu') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PDashboard') {
          this.isShowFooter = true;
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: 'Dashboard' });
        } else if (routeToName == 'PDashboardFreelancer') {
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: 'Dashboard' });
        } else if (routeToName == 'PPawWishlist') {
          this.isShowFooter = true;
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PSettings') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.setting') });
        } else if (routeToName == 'PAccountBalance') {
          this.isShowFooter = true;
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.accountBalance') });
        } else if (routeToName == 'PFindProject') {
          this.isShowFooter = true;
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.findProject') });
        } else if (routeToName == 'CLevelM') {
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: 'Level' });
        } else if (routeToName == 'PRegisterFreelancerOne' || routeToName == 'PRegisterFreelancerTwo') {
          this.isShowFooterCopyRight = true;
          this.hideNavbar();
          this.hideTabFooter();
          this.hideFloatingStore();
        } else if (routeToName == 'PLoginM') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PRegisterM') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PSvcCategory') {
          this.isShowFooter = true;
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PIntroductionM') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PNotificationM') {
          this.hideTabFooter();
          this.hideNavbar();
          this.hideFloatingLiveChat();
          this.hideFloatingStore();
        } else if (routeToName == 'PHelpCenter') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'CFollowingFans') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PIntroductionFreelancer') {
          this.isShow100Height = true;
          this.isShowFooter = true;
          this.hideTabFooter();
          this.hideNavbar();
          this.showFloatingLiveChat();
        } else if (routeToName == 'PPawCreateOne') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PPawCreateOneEdit') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PPawCreateTwo') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PPawCreateThree') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PBuyerManageRequest') {
          this.isShowFooter = true;
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PBuyerPostRequest' || routeToName == 'PBuyerPostRequestEdit') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.createProjectAuction') });
          this.hideTabFooter();
        } else if (routeToName == 'PBuyerPostRequestEditGuarantee') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.createProjectAuction') });
          this.hideTabFooter();
        } else if (routeToName == 'PBuyerPawBasedRequest') {
          this.showNavbarDefault();
          this.hideTabFooter();
        } else if (routeToName == 'PAskCommitee') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.showNavbarWithTitle({
            title: this.$t('cbgwApp.cbComponent.appRoute.askCommitee'),
            typeGoBack: 'IS_SHOW_CHAT_INFO_PANEL',
            page: 'PAskCommitee',
          });
          this.hideTabFooter();
        } else if (routeToName == 'PSettingAccountMBank') {
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.bankAccount') });
          this.hideTabFooter();
        } else if (routeToName == 'PDetailOrder') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'POtpVerification') {
          this.showNavbarDefault();
        } else if (routeToName == 'CChestStoreM') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'CCreateLivechat') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PPromo') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PPromoDetailM') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PBillingHistoryM') {
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.billingHistory') });
        } else if (routeToName == 'PSettingAccountM') {
          this.hideTabFooter();
          this.hideNavbar();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbSetting.account') });
        } else if (routeToName == 'PSettingAccountDeactiveM') {
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.accountDeactivation') });
        } else if (routeToName == 'PAdvanceSearch') {
          this.isShow100Height = true;
          this.isShowFooter = true;
          this.hideTabFooter();
          this.showFloatingStore();
          this.showFloatingLiveChat();
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'PCbStore') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'CExchangeItemM') {
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: 'Exchanges Time' });
        } else if (routeToName == 'CFrame') {
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: 'Frame' });
        } else if (routeToName == 'CSellItem') {
          this.hideTabFooter();
          this.showNavbarWithTitle({ title: 'Sell Item' });
        } else if (routeToName == 'CPaymentM') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PAboutUs') {
          this.isShowFooter = true;
          this.showNavbarDefault();
          this.hideTabFooter();
        } else if (routeToName == 'PLegalDocument') {
          this.isShowFooter = true;
          this.hideTabFooter();
        } else if (routeToName == 'PCompletePaymentM') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PPleaseWait') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'CSurvey') {
          this.hideTabFooter();
          this.hideNavbar();
        } else if (routeToName == 'PRequestedTicket') {
          this.isShowFooter = true;
          this.showNavbarWithTitle({
            title: this.$t('cbgwApp.cbComponent.appRoute.myTicket'),
            typeGoBack: 'IS_SHOW_CHAT_INFO_PANEL',
            page: 'PRequestedTicket',
          });
          this.hideTabFooter();
        } else if (routeToName == 'CDetailTicket') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CReview') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CItemAuctionProjectOfferHoverBuyer') {
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.freelancerOffer') });
          this.hideTabFooter();
        } else if (routeToName == 'CAddMomM') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CAddMilestoneM') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'PProjectDetailM') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CSelectPaw') {
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.selectPaw') });
          this.hideTabFooter();
        } else if (routeToName == 'CSelectPackage') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CCustomPackage') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'COfferSummary') {
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.offerSummary') });
          this.hideTabFooter();
        } else if (routeToName == 'PAddMomM') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CAddAppointmentM') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'PSettingNotificationM') {
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.notification') });
          this.hideTabFooter();
        } else if (routeToName == 'CSetNewPassword') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CWaitingPaymentM') {
          this.showNavbarWithTitle({ title: this.$t('cbgwApp.cbComponent.appRoute.waitingForPayment') });
          this.hideTabFooter();
        } else if (routeToName == 'PRequestRefund') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CProfileReport') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'CSubscription') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'PAppChat') {
          this.hideNavbar();
          this.hideTabFooter();
          this.hideFloatingLiveChat();
          this.hideFloatingStore();
        } else if (routeToName == 'PAppChatByRoomId') {
          this.hideNavbar();
          this.hideTabFooter();
          this.hideFloatingLiveChat();
          this.hideFloatingStore();
        } else if (routeToName == 'PsHome') {
          this.hideNavbar();
          this.hideTabFooter();
          this.showNavbarDefault();
        } else if (routeToName == 'PsRegister') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'PsDashboard') {
          this.hideNavbar();
          this.hideTabFooter();
        } else if (routeToName == 'PsSectionService') {
          this.hideNavbar();
          this.hideTabFooter();
          this.showNavbarDefault();
        } else if (routeToName == 'PsSectionHome') {
          this.hideNavbar();
          this.hideTabFooter();
          this.showNavbarDefault();
        } else if (routeToName == 'Forbidden') {
          this.isShowFooter = true;
          this.showNavbarDefault();
          this.showFloatingStore();
          this.showFloatingLiveChat();
        } else if (routeToName == 'PAuctionProjectDetail') {
          // (<any>this.$root).$emit('meta:::DataUrl', routePath);
          this.hideTabFooter();
        } else if (routeToName == 'POrderM') {
          this.hideTabFooter();
        } else if (routeToName == 'PCancelledInvoiceM') {
          this.hideTabFooter();
        } else if (routeToName == 'PTopupM') {
          this.hideTabFooter();
        } else if (routeToName == 'PWithdrawM') {
          this.hideTabFooter();
        } else if (routeToName == 'PPawDetailView') {
          // (<any>this.$root).$emit('meta:::DataUrl', routePath);
          this.hideTabFooter();
        } else if (routeToName == 'PSettingAccountMLinked') {
          this.hideTabFooter();
          this.hideNavbar();
          this.showNavbarWithTitle({ title: 'Linked Account' });
        } else if (routeToName == 'PBillingHistoryDetailM' || routeToName == 'PBillingHisories') {
          this.hideTabFooter();
          this.hideNavbar();
          this.isShowFooter = true;
        } else if (routeToName == 'PPinM') {
          this.hideTabFooter();
          this.hideNavbar();
          this.showNavbarWithTitle({ title: 'PIN' });
        } else if (routeToName == 'LiveChat') {
          this.hideTabFooter();
        } else if (routeToName == 'PBuyerRequestOffer') {
          this.hideTabFooter();
          this.isShowFooter = true;
        } else if (routeToName == 'PSettingAccountMPassword') {
          this.hideTabFooter();
        } else if (routeToName == 'PDetailOrderLocation') {
          this.isShowWebHideMobFooter();
          this.isShowWebHideMobFooterListener();
          this.hideTabFooter();
        } else if (routeToName == 'NotFound') {
          this.showNavbarDefault();
          this.hideTabFooter();
          this.showFloatingStore();
        }
      }, 20)
      // { deep: true }
    );
  }

  private showNavbarDefault() {
    this.navbarData = {};
    this.navbarData.isShow = true;
    this.navbarData.isDefaultMenu = true;
  }

  private showNavbarWithTitle({ title, page, typeGoBack = null }: { title: any; page?: string; typeGoBack?: 'IS_SHOW_CHAT_INFO_PANEL' }) {
    this.navbarData.isShow = true;
    this.navbarData.isDefaultMenu = false;
    this.navbarData.isArrow = true;
    this.navbarData.title = title;
    this.navbarData.typeGoBack = typeGoBack;
    this.navbarData.page = page || '';
  }

  private hideNavbar() {
    this.navbarData.isShow = false;
    this.navbarData.isDefaultMenu = false;
  }

  private hideTabFooter() {
    this.isShowTabFooter = false;
  }

  private showTabFooter(tabName) {
    // (<any>this.$root).$emit('menuEnableColor', tabName); //fix error tab tab fast and color not suitable, and be change to trigerColorBasedOnBackOrUrl at c-tab-footer
    this.isShowTabFooter = true;
  }

  public showFloatingLiveChat() {
    this.isShowFloatingLiveChat = true;
  }
  public hideFloatingLiveChat() {
    this.isShowFloatingLiveChat = false;
  }
  public showFloatingStore() {
    this.isShowFloatingStore = true;
  }
  public hideFloatingStore() {
    this.isShowFloatingStore = false;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  public isShowWebHideMobFooter() {
    if (this.isMobile) {
      this.isShowFooter = false;
    } else {
      this.isShowFooter = true;
    }
  }

  public isShowWebHideMobFooterListener() {
    this.$store.watch(
      () => this.isMobile,
      () => {
        this.isShowWebHideMobFooter();
      }
    );
  }
}
