import 'vue-slider-component/theme/default.css';
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import VueSlider from 'vue-slider-component';

@Component({
  components: {
    VueSlider,
    Icon,
  },
})
export default class CFilterRating extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: false }) public bgFilterRating: boolean;
  @Prop({ default: null }) value: number[];

  public rating = [0, 5];
  public min = 0;
  public max = 5;

  public starOne = false;
  public starTwo = false;
  public starThree = false;
  public starFour = false;
  public starFive = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  mounted() {
    this.ratingListener();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  @Emit('submit')
  public submit() {
    this.$emit('input', this.rating);
    return this.rating;
  }

  public selectStar(star: number) {
    this.rating = [star, star];
  }
  public clear() {
    this.rating = [0, 5];
    this.submit();
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get getActiveStar() {
    return (star: number) => {
      if (this.rating && this.rating.length > 0) {
        if (star >= this.rating[0] && star <= this.rating[1]) {
          return true;
        } else return false;
      } else return false;
    };
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('value')
  public ratingListener() {
    if (this.value && Array.isArray(this.value)) {
      this.rating = this.value;
    }
  }
  // ================= END LISTENERS ===================
}
