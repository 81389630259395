import { ICbLocation } from '@/shared/model/cb-location.model';
import { ICbPawOrderExtra } from '@/shared/model/cb-paw-order-extra.model';
import { ICbPawOrderInclude } from '@/shared/model/cb-paw-order-include.model';
import { ICbPawOrderRequirement } from '@/shared/model/cb-paw-order-requirement.model';
import { ICbPawOrderMilestone } from '@/shared/model/cb-paw-order-milestone.model';
import { IUser } from '@/shared/model/user.model';
import { ICbPawOrderFile } from '@/shared/model/cb-paw-order-file.model';
import { ICbPawOrderTrack } from '@/shared/model/cb-paw-order-track.model';

import { CbOrderType } from '@/shared/model/enumerations/cb-order-type.model';
import { CbCurrency } from '@/shared/model/enumerations/cb-currency.model';
import { CbCommonStatus } from '@/shared/model/enumerations/cb-common-status.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { ICbTextRestrict } from './cb-text-restrict.model';
import { CbPriceType } from './enumerations/cb-price-type.model';
import { IWfHistory } from './wf-history.model';

export interface ICbPawOrder {
  id?: number;
  orderType?: CbOrderType | null;
  externalId?: number | null;
  pitch?: string | null;
  labelOrderId?: string | null;
  pawTitle?: string | null;
  pawPkgType?: string | null;
  pawPkgTitle?: string | null;
  pawPkgDescription?: string | null;
  currency?: CbCurrency | null;
  projectValueAmount?: number | null;
  discountAmount?: number | null;
  revision?: number | null;
  orderStatus?: CbCommonStatus | null;
  deliveryTime?: number | null;
  deliveryTimeType?: string | null;
  deliveryTimeReal?: number | null;
  progressProject?: number | null;
  bookStartDate?: Date | null;
  bookEndDate?: Date | null;
  pawIdHistory?: number | null;
  buyerIdFrmServer?: string | null;
  partnerIdFrmServer?: string | null;
  dealDate?: Date | null;
  x_deal_date_at_req?: Date | null;
  expiredDate?: Date | null;
  svcType?: CbSvcType | null;
  svcName?: string | null;
  svcCategoryName?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  buyerLocation?: ICbLocation | null;
  cbPawOrderExtras?: ICbPawOrderExtra[] | null;
  cbPawOrderIncludes?: ICbPawOrderInclude[] | null;
  cbPawOrderRequirements?: ICbPawOrderRequirement[] | null;
  cbPawOrderMilestones?: ICbPawOrderMilestone[] | null;
  buyer?: IUser | null;
  partner?: IUser | null;
  cbPawOrderFiles?: ICbPawOrderFile[] | null;
  cbPawOrderTracks?: ICbPawOrderTrack[] | null;
  revisionTotal?: number | null;
  revisionUsed?: number | null;
  cbPawPkgDescription?: ICbTextRestrict | null;
  pitchId?: number | null;
  workflowName?: string | null;
  timeZone?: string | null;

  // transient
  isProjectGuarantee?: boolean | null;
  priceType?: CbPriceType | null;
  wfHistory?: IWfHistory | null;
  budget?: number | null;
}

export class CbPawOrder implements ICbPawOrder {
  constructor(
    public id?: number,
    public orderType?: CbOrderType | null,
    public externalId?: number | null,
    public pitch?: string | null,
    public labelOrderId?: string | null,
    public pawTitle?: string | null,
    public pawPkgType?: string | null,
    public pawPkgTitle?: string | null,
    public pawPkgDescription?: string | null,
    public currency?: CbCurrency | null,
    public projectValueAmount?: number | null,
    public discountAmount?: number | null,
    public revision?: number | null,
    public orderStatus?: CbCommonStatus | null,
    public deliveryTime?: number | null,
    public deliveryTimeType?: string | null,
    public deliveryTimeReal?: number | null,
    public progressProject?: number | null,
    public bookStartDate?: Date | null,
    public bookEndDate?: Date | null,
    public pawIdHistory?: number | null,
    public buyerIdFrmServer?: string | null,
    public partnerIdFrmServer?: string | null,
    public dealDate?: Date | null,
    public x_deal_date_at_req?: Date | null,
    public expiredDate?: Date | null,
    public svcType?: CbSvcType | null,
    public svcName?: string | null,
    public svcCategoryName?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public buyerLocation?: ICbLocation | null,
    public cbPawOrderExtras?: ICbPawOrderExtra[] | null,
    public cbPawOrderIncludes?: ICbPawOrderInclude[] | null,
    public cbPawOrderRequirements?: ICbPawOrderRequirement[] | null,
    public cbPawOrderMilestones?: ICbPawOrderMilestone[] | null,
    public buyer?: IUser | null,
    public partner?: IUser | null,
    public cbPawOrderFiles?: ICbPawOrderFile[] | null,
    public cbPawOrderTracks?: ICbPawOrderTrack[] | null,
    public pitchId?: number | null,
    public timeZone?: string | null,

    public revisionTotal?: number | null,
    public revisionUsed?: number | null,
    public cbPawPkgDescription?: ICbTextRestrict | null,
    public workflowName?: string | null,

    // transient
    public isProjectGuarantee?: boolean | null,
    public priceType?: CbPriceType | null,
    public wfHistory?: IWfHistory | null,
    public budget?: number | null
  ) {
    // this.isActive = this.isActive ?? false;
    this.cbPawOrderIncludes = this.cbPawOrderIncludes ?? [];
    this.cbPawOrderExtras = this.cbPawOrderExtras ?? [];
    this.cbPawOrderRequirements = this.cbPawOrderRequirements ?? [];
  }
}
