var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vac-emoji-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "vac-svg-button",
          class: { "vac-emoji-reaction": _vm.emojiReaction },
          on: { click: _vm.openEmoji },
        },
        [
          _vm._t("emoji-picker-icon", function () {
            return [
              _c("b-icon-emoji-wink", {
                staticClass: "font-size-22 cj-color-red-primary",
                attrs: { param: _vm.emojiReaction ? "reaction" : "" },
              }),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.emojiOpened
        ? [
            _c("transition", { attrs: { name: "vac-slide-up", appear: "" } }, [
              _c(
                "div",
                {
                  staticClass: "vac-emoji-picker",
                  class: { "vac-picker-reaction": _vm.emojiReaction },
                  style: {
                    height: `${_vm.emojiPickerHeight}px`,
                    top: _vm.positionTop
                      ? _vm.emojiPickerHeight
                      : `${_vm.emojiPickerTop}px`,
                    right: _vm.emojiPickerRight,
                    display:
                      _vm.emojiPickerTop || !_vm.emojiReaction
                        ? "initial"
                        : "none",
                  },
                },
                [
                  _vm.emojiOpened
                    ? _c("emoji-picker", { ref: "emojiPicker" })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }