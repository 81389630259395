import axios from 'axios';
import { Inject, Prop } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import vClickOutside from 'v-click-outside';
import Vue2Filters from 'vue2-filters';
import { Debounce } from '@/shared/decorator/debounce';

import Icon from '@/shared/icons/icon.vue';
import CImgBannerMain from '../c-img-banner-main/c-img-banner-main.vue';

import JhiDataUtils from '@/shared/data/data-utils.service';
import GlobalService from '@/services/global.service';
import ContentService from '@/services/content.service';
import CbVwSearchAutocompleteService from '@/services/cb-vw-search-autocomplete.service';

import { ICbVwSearchAutocomplete } from '@/shared/model/cb-vw-search-autocomplete.model';
import { CbSearchType } from '@/shared/model/enumerations/cb-search-type.model';
import { AdvanceSearchFilter, IAdvanceSearchFilter } from '@/shared/model/advance-search-model';
import { ICbLog } from '@/shared/model/cb-log.model';
import { CbLogType } from '@/shared/model/enumerations/cb-log-type.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';

@Component({
  mixins: [Vue2Filters.mixin],

  components: {
    Icon,
    CImgBannerMain,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    search: {
      handler(newVal, oldVal) {
        if (this.account && newVal == this.account.email && newVal == this.account.login) {
          this.search = oldVal;
        }
      },
    },
  },
})
export default class CGSearch extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('pCbVwSearchAutocompleteService') private cbVwSearchAutocompleteService: () => CbVwSearchAutocompleteService;
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('contentService') public contentService: () => ContentService;
  // ================= END SERVICES ===================

  // ================= START VARIABLES ================
  public cbVwSearchAutocompletes: ICbVwSearchAutocomplete[] = [];

  @Prop() public textBorderColorSearchBar;
  @Prop() public bgBtnColorSearchBar;
  @Prop() public iconColorSearchBar;

  public cancelToken: any;
  public isFetching = false;
  public onCheckX = false;
  public toggle = false;
  public searchInputed = '';
  public search = '';
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.contentService().initRetrieveCbVwSvcCatPopular(4);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public toAdvanceSearch(recentSearch?: string) {
    window.dataLayer.push({ event: 'on_user_use_advance_search', search_value: recentSearch }); // TODO apply SOLID principle

    const advanceSearchFilter: IAdvanceSearchFilter = new AdvanceSearchFilter();
    let newObject: any = {};

    if (this.$route.query) {
      newObject = { ...newObject, ...this.$route.query };
    }

    if (advanceSearchFilter.name) {
      for (const key in advanceSearchFilter) {
        if (advanceSearchFilter[key]) {
          newObject[key] = advanceSearchFilter[key];
        }
      }
      const findIndex = this.recentSearch.findIndex(e => e.toUpperCase() == advanceSearchFilter.name.toUpperCase());

      if (findIndex < 0) {
        const tmpRecentSearch = [...this.recentSearch];
        tmpRecentSearch.push(advanceSearchFilter.name);
        if (tmpRecentSearch.length > 3) {
          tmpRecentSearch.shift();
        }
        this.$store.commit('recentSearch', tmpRecentSearch);
      }

      if (this.$route.query) {
        newObject = { ...newObject, ...this.$route.query };
      }
    } else {
      delete newObject.name;
    }

    if (recentSearch) {
      newObject['name'] = recentSearch;

      console.log('recent search', recentSearch);
    } else if (this.search) {
      newObject['name'] = this.search;
    }

    if (newObject['name'] && this.authenticated) {
      const cbVwSearchAutocomplete = {
        searchLabel: newObject['name'],
        searchType: null,
      };

      this.selectResult(cbVwSearchAutocomplete);
    }
    console.log('newObject', newObject['name']);

    this.$router.push({ name: 'PAdvanceSearch', params: { key: `${+new Date()}` }, query: newObject });
  }

  public toSvcCategory(id) {
    this.$router.push({ name: 'PSvcCategory', params: { svcCatId: id } });
  }

  public selectResult(cbVwSearchAutocomplete: ICbVwSearchAutocomplete) {
    const newLabel = cbVwSearchAutocomplete.searchLabel;
    let tmpRecentSearch = [...this.recentSearch];

    const index = tmpRecentSearch.findIndex(e => e.toUpperCase() === newLabel.toUpperCase());
    if (index !== -1) {
      tmpRecentSearch.splice(index, 1);
    }

    tmpRecentSearch.push(newLabel);

    if (tmpRecentSearch.length > 4) {
      tmpRecentSearch.shift();
    }

    if (this.authenticated) {
      this.$store.commit('recentSearch', tmpRecentSearch);
    }
    if (cbVwSearchAutocomplete.searchType == CbSearchType.SVC) this.toSvc(cbVwSearchAutocomplete.externalId);
    if (cbVwSearchAutocomplete.searchType == CbSearchType.SVC_CAT) this.toSvcCategory(cbVwSearchAutocomplete.externalId);
    if (cbVwSearchAutocomplete.searchType == CbSearchType.USER_PARTNER) this.toUserById(cbVwSearchAutocomplete.externalId);
    if (cbVwSearchAutocomplete.searchType == CbSearchType.PAW) this.toPawDetailById(cbVwSearchAutocomplete.externalId);
    if (cbVwSearchAutocomplete.searchType == CbSearchType.AUCTION_PROJECT) this.openAuctionProject(cbVwSearchAutocomplete.externalId);
  }

  public openAuctionProject(id: string) {
    if (!this.authenticated && this.isMobile) {
      this.$router.push({ name: 'PLoginM' });
    } else if (!this.authenticated && !this.isMobile) {
      this.$router.push({ name: 'PHome' });
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    } else {
      this.$router.push({ name: 'PAuctionProjectDetail', params: { id } });
    }
  }

  public toSvc(svcId) {
    this.$router.push({ name: 'PSvc', params: { id: svcId } });
  }

  public toPawDetailById(pawDetailId) {
    const params = {
      id: pawDetailId,
    };
    this.$router.push({ name: 'PPawDetailView', params: params });
  }

  public toUserById(id) {
    const params = {
      id: id,
    };
    if (!this.authenticated && this.isMobile) {
      this.$router.push({ name: 'PLoginM' });
    } else if (!this.authenticated && !this.isMobile) {
      this.$router.push({ name: 'PHome' });
      this.globalService().openGlobalDialog(this.$root, 'modal-c-login');
    } else {
      this.$router.push({ name: 'PProfilePublic', params: params });
    }
  }

  public inputSeachAutocomplete(value?: string) {
    if (this.account && value == this.account.email && value == this.account.login) {
      return;
    }
    this.toggle = true;
    this.search = value;
    this.searchInputed = value;
    this.clearDebounced();
  }

  @Debounce(400)
  public clearDebounced() {
    this.cbVwSearchAutocompletes = [];
    this.searchAutocomplete();
  }

  public searchAutocomplete() {
    if (this.cancelToken) {
      this.cancelToken.cancel('Request Search Autocomplete cancelled');
    }
    this.cancelToken = axios.CancelToken.source();

    if (this.search && this.search.length > 0) {
      this.onCheckX = true;
    } else {
      this.onCheckX = false;
    }

    if (
      !this.search ||
      (this.search && this.search.length < 2) ||
      (this.account && this.search == this.account.email && this.search == this.account.login)
    ) {
      return;
    }

    let searchType = this.authenticated ? CbSearchType.USER_BUYER : [CbSearchType.USER_BUYER, CbSearchType.AUCTION_PROJECT];

    this.isFetching = true;
    const params: any = {
      'searchKey.contains': this.search,
      'searchType.notIn': searchType,
    };
    if (this.loginType == CbLoginType.BUYER) {
      params['searchType.notIn'] = [CbSearchType.USER_BUYER, CbSearchType.AUCTION_PROJECT];
    }

    this.cbVwSearchAutocompleteService()
      .retrieve(params, this.cancelToken ? this.cancelToken.token : null)
      .then(res => {
        const allItems = res.data;
        const maxItemsPerType = 4;
        const filteredItems = [];

        const typeCounts = new Map();

        allItems.forEach(item => {
          const type = item.searchType;
          const currentCount = typeCounts.get(type) || 0;

          if (currentCount < maxItemsPerType) {
            filteredItems.push(item);
            typeCounts.set(type, currentCount + 1);
          }
        });

        let searchTypeTmp = null;
        this.cbVwSearchAutocompletes = filteredItems;
        this.cbVwSearchAutocompletes.forEach(x => {
          if (x.searchType != searchTypeTmp) {
            searchTypeTmp = x.searchType;
            x.showGroup = true;
          } else {
            x.showGroup = false;
          }
        });

        // using data analyst
        // const users: ICbVwSearchAutocomplete[] = this.cbVwSearchAutocompletes.filter(e => e.searchType == CbSearchType.USER);
        // for (const item of users) {
        //   const cbLog: ICbLog = {
        //     cbLogType: CbLogType.PROFILE_SEARCH,
        //     valueHit: item.externalId,
        //   };
        //   (<any>this.$root).$emit('createLog', cbLog);
        // }
      })
      .finally(() => {
        this.isFetching = false;
      });
  }

  public toAdvanceSearchM() {
    if (this.isMobile) {
      this.$router.push({
        name: 'PSearchMenuM',
        query: {
          focus: 'search-focus',
        },
      });
    }
  }

  public deleteRecent(recent: string) {
    const lowerCaseRecent = recent.toLowerCase();

    const index = this.recentSearch.findIndex(item => item.toLowerCase() === lowerCaseRecent);

    if (index !== -1) {
      this.recentSearch.splice(index, 1);
      this.$store.commit('recentSearch', this.recentSearch);
    }
  }

  public freezeSlider() {
    this.toggle = true;
    this.$emit('freezeSlider');
  }

  public unfreezeSlider() {
    this.toggle = false;
    this.$emit('unfreezeSlider');
    this.search = '';
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get reverseRecentSearch() {
    return this.recentSearch.slice().reverse();
  }

  public get recentSearch() {
    return this.$store.getters.recentSearch;
  }

  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
  public get loginType(): CbLoginType {
    return this.$store.getters.loginType;
  }

  public get cbVwSvcCatPopulars() {
    return this.$store.getters.cbVwSvcCatPopulars.slice(0, 4);
  }

  public get account(): any {
    return this.$store.getters.account;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
