import { Component, Emit, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { required } from 'vuelidate/lib/validators';

import Icon from '@/shared/icons/icon.vue';
import CButton from '@/components/button/c-button.vue';
import CTextareaNew from '@/components/c-textarea-new/c-textarea-new.vue';
import CConfirmation from '@/components/c-confirmation/c-confirmation.vue';

import JhiDataUtils from '@/shared/data/data-utils.service';

import { CbUserStatusType } from '@/shared/model/enumerations/cb-user-status-type.model';
import { CbUserInfo, ICbUserInfo } from '@/shared/model/cb-user-info.model';

const validations: any = {
  localCbUserInfo: {
    inActiveReason: {
      required,
    },
    inActiveReasonMore: {},
  },
};
@Component({
  validations,
  components: {
    CButton,
    Icon,
    CTextareaNew,
    CConfirmation,
  },
})
export default class CAccoutDeactivateUpdate extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: new CbUserInfo() }) public cbUserInfo: CbUserInfo;
  public localCbUserInfo: ICbUserInfo = new CbUserInfo();
  public CbUserStatusType = CbUserStatusType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    this.localCbUserInfo = { ...this.cbUserInfo };
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }

  @Emit('submit')
  public submit() {
    return this.localCbUserInfo;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
