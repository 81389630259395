import { Module } from 'vuex';
import { ICbHelpCenter } from '@/shared/model/cb-help-center.model';
import { CbVwHelpCenterFeedback, ICbVwHelpCenterFeedback } from '@/shared/model/cb-vw-help-center-feedback.model';
import { ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import { ICbEducation } from '@/shared/model/cb-education.model';
import { ICbUserBookmarkAgg } from '@/shared/model/cb-user-bookmark-agg.model';

export interface ContentTextStore {
  HELP_CENTER_ID: ICbHelpCenter[];
  HELP_CENTER_EN: ICbHelpCenter[];
  TERM_OF_SERVICE_ID: ICbHelpCenter[];
  TERM_OF_SERVICE_EN: ICbHelpCenter[];
  PRIVACY_POLICY_EN: ICbHelpCenter[];
  PRIVACY_POLICY_ID: ICbHelpCenter[];
  TERM_OF_PAYMENT_EN: ICbHelpCenter[];
  TERM_OF_PAYMENT_ID: ICbHelpCenter[];
  INTELLECTUAL_PROPERTY_CLAIM_EN: ICbHelpCenter[];
  INTELLECTUAL_PROPERTY_CLAIM_ID: ICbHelpCenter[];
  COOKIE_POLICY_EN: ICbHelpCenter[];
  COOKIE_POLICY_ID: ICbHelpCenter[];
  COMMUNITY_GUIDELINES_EN: ICbHelpCenter[];
  COMMUNITY_GUIDELINES_ID: ICbHelpCenter[];
  cbVwHelpCenterFeedback: ICbVwHelpCenterFeedback;
  cbTextNoAuths: ICbTextNoAuth[];
  cbEducations: ICbEducation[];
  USER_BOOKMARK_PAW: ICbUserBookmarkAgg[];
  USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH: ICbUserBookmarkAgg[];
  USER_BOOKMARK_AUCTION_PROJECT: ICbUserBookmarkAgg[];
}

export const defaultState: ContentTextStore = {
  HELP_CENTER_EN: [],
  HELP_CENTER_ID: [],
  TERM_OF_SERVICE_ID: [],
  TERM_OF_SERVICE_EN: [],
  PRIVACY_POLICY_EN: [],
  PRIVACY_POLICY_ID: [],
  TERM_OF_PAYMENT_EN: [],
  TERM_OF_PAYMENT_ID: [],
  INTELLECTUAL_PROPERTY_CLAIM_EN: [],
  INTELLECTUAL_PROPERTY_CLAIM_ID: [],
  COOKIE_POLICY_EN: [],
  COOKIE_POLICY_ID: [],
  COMMUNITY_GUIDELINES_EN: [],
  COMMUNITY_GUIDELINES_ID: [],
  cbVwHelpCenterFeedback: new CbVwHelpCenterFeedback(),
  cbTextNoAuths: [],
  cbEducations: [],
  USER_BOOKMARK_PAW: [],
  USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH: [],
  USER_BOOKMARK_AUCTION_PROJECT: [],
};

export const contentTextStore: Module<ContentTextStore, any> = {
  state: { ...defaultState },
  getters: {
    HELP_CENTER_ID: state => state.HELP_CENTER_ID,
    HELP_CENTER_EN: state => state.HELP_CENTER_EN,
    TERM_OF_SERVICE_EN: state => state.TERM_OF_SERVICE_EN,
    TERM_OF_SERVICE_ID: state => state.TERM_OF_SERVICE_ID,
    PRIVACY_POLICY_EN: state => state.PRIVACY_POLICY_EN,
    PRIVACY_POLICY_ID: state => state.PRIVACY_POLICY_ID,
    TERM_OF_PAYMENT_EN: state => state.TERM_OF_PAYMENT_EN,
    TERM_OF_PAYMENT_ID: state => state.TERM_OF_PAYMENT_ID,
    INTELLECTUAL_PROPERTY_CLAIM_EN: state => state.INTELLECTUAL_PROPERTY_CLAIM_EN,
    INTELLECTUAL_PROPERTY_CLAIM_ID: state => state.INTELLECTUAL_PROPERTY_CLAIM_ID,
    COOKIE_POLICY_EN: state => state.COOKIE_POLICY_EN,
    COOKIE_POLICY_ID: state => state.COOKIE_POLICY_ID,
    COMMUNITY_GUIDELINES_EN: state => state.COMMUNITY_GUIDELINES_EN,
    COMMUNITY_GUIDELINES_ID: state => state.COMMUNITY_GUIDELINES_ID,
    cbVwHelpCenterFeedback: state => state.cbVwHelpCenterFeedback,
    cbTextNoAuths: state => state.cbTextNoAuths,
    cbEducations: state => state.cbEducations,
    GET_USER_BOOKMARK_PAW: state => state.USER_BOOKMARK_PAW,
    GET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH: state => state.USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH,
    GET_USER_BOOKMARK_AUCTION_PROJECT: state => state.USER_BOOKMARK_AUCTION_PROJECT,
  },
  mutations: {
    HELP_CENTER_ID(state, param) {
      state.HELP_CENTER_ID = param;
    },
    HELP_CENTER_EN(state, param) {
      state.HELP_CENTER_EN = param;
    },
    TERM_OF_SERVICE_EN(state, param) {
      state.TERM_OF_SERVICE_EN = param;
    },
    TERM_OF_SERVICE_ID(state, param) {
      state.TERM_OF_SERVICE_ID = param;
    },
    PRIVACY_POLICY_EN(state, param) {
      state.PRIVACY_POLICY_EN = param;
    },
    PRIVACY_POLICY_ID(state, param) {
      state.PRIVACY_POLICY_ID = param;
    },
    TERM_OF_PAYMENT_EN(state, param) {
      state.TERM_OF_PAYMENT_EN = param;
    },
    TERM_OF_PAYMENT_ID(state, param) {
      state.TERM_OF_PAYMENT_ID = param;
    },
    INTELLECTUAL_PROPERTY_CLAIM_EN(state, param) {
      state.INTELLECTUAL_PROPERTY_CLAIM_EN = param;
    },
    INTELLECTUAL_PROPERTY_CLAIM_ID(state, param) {
      state.INTELLECTUAL_PROPERTY_CLAIM_ID = param;
    },
    COOKIE_POLICY_EN(state, param) {
      state.COOKIE_POLICY_EN = param;
    },
    COOKIE_POLICY_ID(state, param) {
      state.COOKIE_POLICY_ID = param;
    },
    COMMUNITY_GUIDELINES_EN(state, param) {
      state.COMMUNITY_GUIDELINES_EN = param;
    },
    COMMUNITY_GUIDELINES_ID(state, param) {
      state.COMMUNITY_GUIDELINES_ID = param;
    },
    cbVwHelpCenterFeedback(state, param) {
      state.cbVwHelpCenterFeedback = param;
    },
    cbTextNoAuths(state, param) {
      state.cbTextNoAuths = param;
    },
    cbEducations(state, param) {
      state.cbEducations = param;
    },
    SET_USER_BOOKMARK_PAW(state, param) {
      state.USER_BOOKMARK_PAW = param;
    },
    SET_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH(state, param) {
      state.USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH = param;
    },
    SET_USER_BOOKMARK_AUCTION_PROJECT(state, param) {
      state.USER_BOOKMARK_AUCTION_PROJECT = param;
    },
    CLEAR_USER_BOOKMARK_PAW(state) {
      state.USER_BOOKMARK_PAW = [];
    },
    CLEAR_USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH(state) {
      state.USER_BOOKMARK_LIKE_CB_ATTACHMENT_NO_AUTH = [];
    },
    CLEAR_USER_BOOKMARK_AUCTION_PROJECT(state) {
      state.USER_BOOKMARK_AUCTION_PROJECT = [];
    },
  },
};
