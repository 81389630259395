import { render, staticRenderFns } from "./c-room-star-messages.vue?vue&type=template&id=c3e83ace&"
import script from "./c-room-star-messages.component.ts?vue&type=script&lang=ts&"
export * from "./c-room-star-messages.component.ts?vue&type=script&lang=ts&"
import style0 from "./c-room-star-messages.vue?vue&type=style&index=0&id=c3e83ace&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports