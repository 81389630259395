import { Component, Vue } from 'vue-property-decorator';

import { CbCommonStatus } from '../model/enumerations/cb-common-status.model';
import { ICbVwAuctionProject } from '../model/cb-vw-auction-project.model';
import { ICbVwPaw } from '../model/cb-vw-paw.model';
import { CbPawStatus } from '../model/enumerations/cb-paw-status.model';
import { CbGender } from '../model/enumerations/cb-gender.model';
import { PayCommonStatus } from '../model/enumerations/pay-common-status.model';

@Component
export default class StatusTextEnId extends Vue {
  public get getOrderStatusTextByStatusOrder() {
    return (orderStatus: CbCommonStatus) => {
      let value;

      switch (orderStatus) {
        case CbCommonStatus.WAITING_PAYMENT:
          value = this.$t('cbGlobal.status.waitingPayment');
          break;
        case CbCommonStatus.COMPLETED:
          value = this.$t('cbGlobal.status.completed');
          break;
        case CbCommonStatus.CANCELLED:
          value = this.$t('cbGlobal.status.cancelled');
          break;
        case CbCommonStatus.ON_PROGRESS:
          value = this.$t('cbGlobal.status.onProgress');
          break;
        case CbCommonStatus.FEEDBACK_NEEDED:
          value = this.$t('cbGlobal.orderStatusInside.feedbackNeeded');
          break;
        case CbCommonStatus.REVISION_REQUESTED:
          value = this.$t('cbGlobal.orderStatusInside.revisionRequested');
          break;
        case CbCommonStatus.REVISION_NEEDED:
          value = this.$t('cbGlobal.orderStatusInside.revisionNeeded');
          break;
        case CbCommonStatus.DISPUTED:
          value = this.$t('cbGlobal.orderStatusInside.requestDispute');
          break;
        case CbCommonStatus.OVERDUE:
          value = this.$t('cbGlobal.orderStatusInside.overdue');
          break;
        case CbCommonStatus.BOOKED:
          value = this.$t('cbGlobal.orderStatusField.booked');
          break;
        case CbCommonStatus.ON_THE_WAY:
          value = this.$t('cbGlobal.orderStatusField.onTheWay');
          break;
        case CbCommonStatus.LATE:
        case CbCommonStatus.LATE_ON_TRACK:
          value = this.$t('cbGlobal.orderStatusField.late');
          break;
        case CbCommonStatus.SERVICE_FINISHED:
          value = this.$t('cbGlobal.orderStatusField.serviceFinished');
          break;
        case CbCommonStatus.DECLINED:
          value = this.$t('cbGlobal.status.declined');
          break;
        case CbCommonStatus.REQUESTED:
          value = this.$t('cbGlobal.status.requested');
          break;
        case CbCommonStatus.PAID:
        case CbCommonStatus.ACCEPTED:
          value = this.$t('cbGlobal.status.accepted');
          break;
        case CbCommonStatus.FAILED:
          value = this.$t('cbGlobal.status.failed');
          break;
        case CbCommonStatus.PENDING:
          value = this.$t('cbGlobal.status.pending');
          break;
      }

      return value;
    };
  }

  public get getOrderStatusTextByStatusPayment() {
    return (paymentStatus: PayCommonStatus) => {
      let value;

      switch (paymentStatus) {
        case PayCommonStatus.SUCCESS:
          value = this.$t('cbGlobal.status.success');
          break;
        case PayCommonStatus.COMPLETED:
          value = this.$t('cbGlobal.status.completed');
          break;
        case PayCommonStatus.CANCELLED:
          value = this.$t('cbGlobal.status.cancelled');
          break;
        case PayCommonStatus.DISPUTED:
          value = this.$t('cbGlobal.orderStatusInside.disputed');
          break;
        case PayCommonStatus.WAITING:
          value = this.$t('cbGlobal.status.waitingPayment');
          break;
        case PayCommonStatus.REFUNDED:
          value = this.$t('cbGlobal.status.refunded');
          break;
        case PayCommonStatus.FAILED:
          value = this.$t('cbGlobal.status.fail');
          break;
        case PayCommonStatus.PENDING:
          value = this.$t('cbGlobal.status.processing');
          break;
      }

      return value;
    };
  }

  public get getOrderStatusColorByStatus() {
    return (status: CbCommonStatus, type: 'text' | 'bg' = 'bg') => {
      let value = type === 'text' ? 'cj-status-text-muted' : 'cj-status cj-status-bg-muted cj-status-text-default';

      if (
        status === CbCommonStatus.WAITING_PAYMENT ||
        status === CbCommonStatus.WAITING_FOR_PAYMENT ||
        status === CbCommonStatus.REQUESTED ||
        status === CbCommonStatus.FEEDBACK_NEEDED
      ) {
        value = type === 'text' ? 'cj-status-text-warning' : 'cj-status cj-status-bg-warning cj-status-text-default';
      } else if (status === CbCommonStatus.BOOKED) {
        value = type === 'text' ? 'cj-status-text-dark' : 'cj-status cj-status-bg-dark cj-status-text-default';
      } else if (
        status === CbCommonStatus.LATE ||
        status === CbCommonStatus.LATE_ON_TRACK ||
        status === CbCommonStatus.OVERDUE ||
        status === CbCommonStatus.DECLINED ||
        status === CbCommonStatus.REVISION_NEEDED ||
        status === CbCommonStatus.EXPIRED
      ) {
        value = type === 'text' ? 'cj-status-text-error' : 'cj-status cj-status-bg-error cj-status-text-default';
      } else if (status === CbCommonStatus.REVISION_REQUESTED) {
        value = type === 'text' ? 'cj-status-text-purple' : 'cj-status cj-status-bg-purple cj-status-text-default';
      } else if (status === CbCommonStatus.ON_THE_WAY) {
        value = type === 'text' ? 'cj-status-text-green' : 'cj-status cj-status-bg-green cj-status-text-default';
      } else if (status === CbCommonStatus.ON_PROGRESS || status === CbCommonStatus.PAID) {
        value = type === 'text' ? 'cj-status-text-success' : 'cj-status cj-status-bg-success cj-status-text-default';
      } else if (status === CbCommonStatus.SERVICE_FINISHED) {
        value = type === 'text' ? 'cj-status-text-deep-blue' : 'cj-status cj-status-bg-deep-blue cj-status-text-default';
      } else if (status === CbCommonStatus.DISPUTED) {
        value = type === 'text' ? 'cj-status-text-muted' : 'cj-status cj-status-bg-muted cj-status-text-default';
      } else if (status === CbCommonStatus.COMPLETED || status === CbCommonStatus.ACCEPTED) {
        value = type === 'text' ? 'cj-status-text-blue' : 'cj-status cj-status-bg-blue cj-status-text-default';
      } else if (status === CbCommonStatus.CANCELLED) {
        value = type === 'text' ? 'cj-status-text-cancelled' : 'cj-status cj-status-bg-cancelled cj-status-text-default';
      }

      return value;
    };
  }

  public get getOrderStatusColorByStatusPayment() {
    return (paymentStatus: PayCommonStatus, type: 'text' | 'bg' = 'bg') => {
      let value;

      switch (paymentStatus) {
        case PayCommonStatus.SUCCESS:
        case PayCommonStatus.COMPLETED:
          value = type === 'text' ? 'cj-status-text-blue' : 'cj-status cj-status-bg-blue cj-status-text-default';
          break;
        case PayCommonStatus.FAILED:
        case PayCommonStatus.CANCELLED:
          value = type === 'text' ? 'cj-status-text-error' : 'cj-status cj-status-bg-error cj-status-text-default';
          break;
        case PayCommonStatus.PENDING:
        case PayCommonStatus.DISPUTED:
        case PayCommonStatus.REFUNDED:
          value = type === 'text' ? 'cj-status-text-muted' : 'cj-status cj-status-bg-muted cj-status-text-default';
          break;
        case PayCommonStatus.WAITING:
          value = type === 'text' ? 'cj-status-text-warning' : 'cj-status cj-status-bg-warning cj-status-text-default';
          break;
      }

      return value;
    };
  }

  public get getOrderBackgroundColorByStatus() {
    return (status: CbCommonStatus) => {
      let value = 'cj-bg-red-primary';

      switch (status) {
        case CbCommonStatus.ON_PROGRESS:
          value = 'cj-status-bg-success';
          break;
        case CbCommonStatus.CANCELLED:
          value = 'cj-status-bg-cancelled';
          break;
        case CbCommonStatus.OVERDUE:
          value = 'cj-bg-status-error';
          break;
        case CbCommonStatus.FEEDBACK_NEEDED:
          value = 'cj-status-bg-warning';
          break;
        case CbCommonStatus.ASK_FOR_REVISION:
        case CbCommonStatus.REVISION_REQUESTED:
          value = 'cj-status-bg-purple';
          break;
        case CbCommonStatus.COMPLETED:
          value = 'cj-status-bg-blue';
          break;
        case CbCommonStatus.DISPUTED:
          value = 'cj-status-bg-muted';
          break;
      }

      return value;
    };
  }

  public get getTimeBackgroundByStatus() {
    return ({
      status,
      type = 'bg',
      isVisibleCountdownZero,
      isTimeOut,
    }: {
      status: CbCommonStatus;
      type?: 'text' | 'bg';
      isVisibleCountdownZero?: boolean;
      isTimeOut?: boolean;
    }) => {
      const object: {
        timeColor: string;
        timeIconColor: string;
      } = {
        timeColor: 'cj-status-countdown cj-status-bg-warning',
        timeIconColor: 'cj-fill-grey-secondary',
      };

      switch (status) {
        case CbCommonStatus.ON_PROGRESS:
          if (isVisibleCountdownZero && isTimeOut) {
            object.timeColor = type === 'text' ? 'cj-status-text-muted' : 'cj-status-countdown cj-status-bg-muted';
            object.timeIconColor = 'cj-fill-grey-secondary';
          } else {
            object.timeColor = type === 'text' ? 'cj-status-text-success' : 'cj-status-countdown cj-status-bg-success';
          }
          break;
        case CbCommonStatus.WAITING_PAYMENT:
        case CbCommonStatus.WAITING_FOR_PAYMENT:
        case CbCommonStatus.REQUESTED:
        case CbCommonStatus.FEEDBACK_NEEDED:
          object.timeColor = type === 'text' ? 'cj-status-text-warning' : 'cj-status-countdown cj-status-bg-warning';
          break;
        case CbCommonStatus.SERVICE_FINISHED:
          object.timeColor = type === 'text' ? 'cj-status-text-deep-blue' : 'cj-status-countdown cj-status-bg-deep-blue';
          break;
        case CbCommonStatus.REVISION_REQUESTED:
          object.timeColor = type === 'text' ? 'cj-status-text-purple' : 'cj-status-countdown cj-status-bg-purple';
          object.timeIconColor = 'cj-fill-purple-primary';
          break;
        case CbCommonStatus.DISPUTED:
          object.timeColor = type === 'text' ? 'cj-status-text-muted' : 'cj-status-countdown cj-status-bg-muted';
          object.timeIconColor = 'cj-fill-grey-secondary';
          break;
        case CbCommonStatus.OVERDUE:
        case CbCommonStatus.LATE:
        case CbCommonStatus.LATE_ON_TRACK:
        case CbCommonStatus.EXPIRED:
          object.timeColor = type === 'text' ? 'cj-status-text-error' : 'cj-status-countdown cj-status-bg-error';
          break;
      }

      return object;
    };
  }

  public get getRevisionTextEnId() {
    return (revision: number) => {
      if (revision > 1) {
        return `cbgwApp.cbDetailOrder.${this.currentLanguage === 'en' ? 'revisions' : 'revision'}`;
      } else {
        return 'cbgwApp.cbDetailOrder.revision';
      }
    };
  }

  public get getDeliveryTimeTextEnId() {
    return (deliveryTimeTotalMinute: number) => {
      if (deliveryTimeTotalMinute <= 0) {
        return '0 Minute'; // Handle non-positive input
      }

      const days = Math.floor(deliveryTimeTotalMinute / (24 * 60));
      const remainingMinutes = deliveryTimeTotalMinute % (24 * 60);

      const hours = Math.floor(remainingMinutes / 60);
      const minutes = remainingMinutes % 60;

      function setTypeGraterThan(condition, type) {
        return condition ? `${type}s` : type;
      }

      const typeDay = this.currentLanguage === 'en' ? setTypeGraterThan(days > 1, 'Day') : 'Hari';
      const typeHour = this.currentLanguage === 'en' ? setTypeGraterThan(hours > 1, 'Hour') : 'Jam';
      const typeMinute = this.currentLanguage === 'en' ? setTypeGraterThan(minutes > 1, 'Minute') : 'Menit';

      // Format the output string
      let outputString = '';

      if (days > 0) {
        outputString += `${days} ${typeDay}`;
      }

      if (hours > 0) {
        outputString += ` ${hours} ${typeHour}`;
      }

      if (minutes > 0) {
        outputString += ` ${minutes} ${typeMinute}`;
      }

      return outputString;
    };
  }

  public get getDeliveryTimeType() {
    return (deliveryTimeType: string, deliveryTime: any, showTextDelivery?: boolean) => {
      const textDelivery = this.$t('cbGlobal.delivery');
      let valueType = '';
      if (deliveryTimeType === 'DAY') {
        if (this.currentLanguage === 'en') {
          if (deliveryTime === 1) {
            valueType = 'Day';
          } else {
            valueType = 'Days';
          }
        } else {
          valueType = 'Hari';
        }
      } else if (deliveryTimeType === 'HOUR') {
        if (this.currentLanguage === 'en') {
          if (deliveryTime === 1) {
            valueType = 'Hour';
          } else {
            valueType = 'Hours';
          }
        } else {
          valueType = 'Jam';
        }
      } else if (deliveryTimeType === 'MINUTE') {
        if (this.currentLanguage === 'en') {
          if (deliveryTime === 1) {
            valueType = 'Minute';
          } else {
            valueType = 'Minutes';
          }
        } else {
          valueType = 'Menit';
        }
      }
      if (showTextDelivery) {
        return deliveryTime ? deliveryTime + ' ' + valueType + ' ' + textDelivery : '-' + ' ' + textDelivery;
      } else {
        return deliveryTime ? deliveryTime + ' ' + valueType : '-';
      }
    };
  }

  // auction
  public get getAuctionColorByStatus() {
    return (cbVwAuctionProject: ICbVwAuctionProject, bgColor?: boolean) => {
      let value = bgColor ? 'cj-status-bg-muted cj-status-text-default' : 'cj-status-text-muted';

      if (cbVwAuctionProject.status == CbCommonStatus.ACTIVE)
        value = bgColor ? 'cj-status-bg-success cj-status-text-default' : 'cj-status-text-success';
      else if (cbVwAuctionProject.status == CbCommonStatus.FINISHED)
        value = bgColor ? 'cj-status-bg-blue cj-status-text-default' : 'cj-status-text-blue';
      else if (cbVwAuctionProject.status == CbCommonStatus.EXPIRED)
        value = bgColor ? 'cj-status-bg-error cj-status-text-default' : 'cj-status-text-error';
      else if (cbVwAuctionProject.status == CbCommonStatus.CANCELLED)
        value = bgColor ? 'cj-status-bg-cancelled cj-status-text-default' : 'cj-status-text-cancelled';
      else if (cbVwAuctionProject.status == CbCommonStatus.DRAFT)
        value = bgColor ? 'cj-status-bg-muted cj-status-text-default' : 'cj-status-text-muted';

      return value;
    };
  }

  public get getAuctionTextByStatus() {
    return (cbVwAuctionProject: ICbVwAuctionProject) => {
      let value;

      if (cbVwAuctionProject.status == CbCommonStatus.ACTIVE) {
        value = this.$t('cbGlobal.auctionStatus.active');
      } else if (cbVwAuctionProject.status == CbCommonStatus.EXPIRED) {
        value = this.$t('cbGlobal.auctionStatus.expired');
      } else if (cbVwAuctionProject.status == CbCommonStatus.DRAFT) {
        value = this.$t('cbGlobal.auctionStatus.draft');
      } else if (cbVwAuctionProject.status == CbCommonStatus.CANCELLED) {
        value = this.$t('cbGlobal.auctionStatus.cancelled');
      } else if (cbVwAuctionProject.status == CbCommonStatus.FINISHED) {
        value = this.$t('cbGlobal.auctionStatus.finished');
      }

      return value;
    };
  }

  // Paw
  public get getPawColorByStatus() {
    return (cbVwPaw: ICbVwPaw, bgColor?: boolean) => {
      let value = '';

      if (cbVwPaw.isHide) value = bgColor ? 'cj-status-bg-grey cj-status-text-default' : 'cj-status-text-grey';
      else if (cbVwPaw.status == CbPawStatus.ACTIVE || cbVwPaw.status == CbPawStatus.HIBERNATE)
        value = bgColor ? 'cj-status-bg-success cj-status-text-default' : 'cj-status-text-success';
      else if (cbVwPaw.status == CbPawStatus.DRAFT) value = bgColor ? 'cj-status-bg-muted cj-status-text-default' : 'cj-status-text-muted';
      else if (cbVwPaw.status == CbPawStatus.UNDER_CONFIRMATION)
        value = bgColor ? 'cj-status-bg-warning cj-status-text-default' : 'cj-status-text-warning';
      else if (cbVwPaw.status == CbPawStatus.UNDER_REVIEW)
        value = bgColor ? 'cj-status-bg-purple cj-status-text-default' : 'cj-status-text-purple';

      return value;
    };
  }

  public get getPawTextByStatus() {
    return (cbVwPaw: ICbVwPaw) => {
      let value;

      if (cbVwPaw.isHide) {
        value = this.$t('cbGlobal.pawStatus.hide');
      } else if (cbVwPaw.status === CbPawStatus.UNDER_REVIEW) {
        value = this.$t('cbGlobal.pawStatus.underReview');
      } else if (cbVwPaw.status === CbPawStatus.UNDER_CONFIRMATION) {
        value = this.$t('cbGlobal.pawStatus.revisionNeeded');
      } else if (cbVwPaw.status === CbPawStatus.DRAFT) {
        value = this.$t('cbGlobal.pawStatus.draft');
      } else if (cbVwPaw.status === CbPawStatus.ACTIVE || cbVwPaw.status == CbPawStatus.HIBERNATE) {
        value = this.$t('cbGlobal.pawStatus.active');
      }

      return value;
    };
  }

  public get getPackageTextEnId() {
    return (pawPkgType: string) => {
      let value: any;

      switch (pawPkgType) {
        case 'REGULAR':
          value = this.$t('cbgwApp.cbDetailOrder.regularPackage');
          break;
        case 'PREMIUM':
          value = this.$t('cbgwApp.cbDetailOrder.premiumPackage');
          break;
        case 'CUSTOM':
          value = this.$t('cbgwApp.cbDetailOrder.customPackage');
          break;

        default:
          value = this.$t('cbgwApp.cbDetailOrder.regularPackage');
          break;
      }

      return value;
    };
  }

  // my tiket
  public get getLastStatusGeneralColorByStatus() {
    return (lastStausGeneral: string, bgColor?: boolean) => {
      if (!lastStausGeneral) {
        // Handle the case where lastStausGeneral is null or undefined
        return '';
      }
      const status = lastStausGeneral.split('::')[1];
      console.log(status, 'lastStausGeneral');
      let value = '';
      if (status == CbCommonStatus.OPEN) value = bgColor ? 'cj-status-bg-success cj-status-text-default' : 'cj-status-text-success';
      else if (status == CbCommonStatus.WAITING_YOUR_FEEDBACK)
        value = bgColor ? 'cj-status-bg-warning cj-status-text-default' : 'cj-status-text-warning';
      else if (status == CbCommonStatus.CLOSED) value = bgColor ? 'cj-status-bg-blue cj-status-text-default' : 'cj-status-text-blue';
      return value;
    };
  }

  public get getLastStatusGeneralTextByStatus() {
    return (lastStausGeneral: string) => {
      if (!lastStausGeneral) {
        // Handle the case where lastStausGeneral is null or undefined
        return '';
      }
      const status = lastStausGeneral.split('::')[1];
      let value;
      if (status == 'OPEN') {
        value = this.$t('cbGlobal.ticketStatus.open');
      } else if (status == 'WAITING_YOUR_FEEDBACK') {
        value = this.$t('cbGlobal.ticketStatus.replied');
      } else if (status == 'CLOSED') {
        value = this.$t('cbGlobal.ticketStatus.closed');
      }
      return value;
    };
  }

  public get getGenderTextEnId() {
    return (gender: CbGender) => {
      let value;

      if (gender) {
        value = this.$t('cbgwApp.cbCreateAccount.genderList.' + gender.toString());
      } else {
        value = '-';
      }

      return value;
    };
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
}
