import axios from 'axios';

import { ICbHelpCenterView } from '@/shared/model/cb-help-center-view.model';

const baseApiUrl = 'services/cbuserms/api/cb-help-center-views';

export default class PCbHelpCenterViewService {
  public create(entity: ICbHelpCenterView): Promise<ICbHelpCenterView> {
    return new Promise<ICbHelpCenterView>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
