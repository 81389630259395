var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass: "c-item-chat-mesage-clockin p-2 cj-bg-white-primary",
          attrs: { "data-cy": "item-chat-mesage-clockin" },
        },
        [
          _c(
            "div",
            { ref: "cbVwPawOrderRequirement", staticClass: "text-center mb-2" },
            [
              _c(
                "p",
                {
                  staticClass: "c-icm-font-card-title text-ellipsis oneline",
                  attrs: { "data-cy": "message-title-clockin" },
                },
                [_vm._v("Freelancer Arrived at Location")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "c-icm-font-card-subtitle text-ellipsis-oneline",
                  attrs: { "data-cy": "message-title-and-cbor-clockin" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                      " | " +
                      _vm._s(
                        _vm._f("capitalEachSpace")(
                          _vm.cbVwPawOrderRequirement.pawTitle
                        )
                      ) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["cbVwPawOrderRequirement"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [_vm._v("Freelancer Arrived at Location")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                      attrs: { "data-cy": "message-title-and-cbor-clockin" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                          " | " +
                          _vm._s(
                            _vm._f("capitalEachSpace")(
                              _vm.cbVwPawOrderRequirement.pawTitle
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
            },
            [
              _vm.cbVwPawOrderRequirement.cbPawOrderFiles &&
              _vm.cbVwPawOrderRequirement.cbPawOrderFiles.length > 0
                ? _c(
                    "div",
                    { staticClass: "img-container" },
                    _vm._l(
                      _vm.cbVwPawOrderRequirement.cbPawOrderFiles,
                      function (cbPawOrderFile, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "img-wrapper position-relative limit-img cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.handleCMediaPreview(
                                    cbPawOrderFile.cbAttachmentRestrict
                                      ?.localUrl
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  "data-cy": "message-image-clockin",
                                  src: _vm.$options.filters.LAZY(
                                    cbPawOrderFile.cbAttachmentRestrict.localUrl
                                  ),
                                  onerror: _vm.$options.filters.LAZY_ERROR(),
                                  alt: `clock in preview ${cbPawOrderFile.fileName}`,
                                },
                              }),
                              _vm._v(" "),
                              index == 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "count-wrapper",
                                      class:
                                        _vm.cbVwPawOrderRequirement
                                          .cbPawOrderFiles.length <= 2
                                          ? "d-none"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "m-0 font-roboto font-weight-700 font-size-12",
                                        },
                                        [
                                          _vm._v(
                                            "+" +
                                              _vm._s(
                                                _vm.cbVwPawOrderRequirement
                                                  .cbPawOrderFiles.length - 2
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ])
                      }
                    ),
                    0
                  )
                : _c("div", [
                    _c("p", { staticClass: "c-icm-font-content" }, [
                      _vm._v("No data."),
                    ]),
                  ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }