import { Component, Prop, Vue } from 'vue-property-decorator';

import CPopupBottom from '@/components/c-popup-bottom/c-popup-bottom.vue';
import CAutocompleteNew from '@/components/c-autocomplete-new/c-autocomplete-new.vue';

import { ICbLov } from '@/shared/model/cb-lov.model';
import { CbAuctionProjectPreOffer, ICbAuctionProjectPreOffer } from '@/shared/model/cb-auction-project-pre-offer.model';
import { CbDeliveryTimeType } from '@/shared/model/enumerations/cb-delivery-time-type.model';
import { CbPriceType } from '@/shared/model/enumerations/cb-price-type.model';

@Component({
  components: {
    CPopupBottom,
    CAutocompleteNew,
  },
})
export default class CApPitchPopup extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) cbAuctionProjectPreOffer: CbAuctionProjectPreOffer;

  public localCbAuctionProjectPreOffer: ICbAuctionProjectPreOffer = new CbAuctionProjectPreOffer();
  public visiblePopupDeliveryTimeType = false;
  public visiblePopupPriceType = false;
  public popupValuePriceType: CbPriceType = null;
  public popupValueDeliveryTimeType: CbDeliveryTimeType = null;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created(): void {
    this.$root.$off('formApPitchPopup::changeValue');
    this.$root.$on('formApPitchPopup::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
      this.localCbAuctionProjectPreOffer = Object.assign({}, this.cbAuctionProjectPreOffer);
    });
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get deliveryTimeTypes(): ICbLov[] {
    return [
      {
        key: CbDeliveryTimeType.DAY,
        value: this.$t('cbgwApp.cbComponent.deliveryTimeType.day').toString(),
      },
      {
        key: CbDeliveryTimeType.HOUR,
        value: this.$t('cbgwApp.cbComponent.deliveryTimeType.hour').toString(),
      },
    ];
  }

  public get priceTypeList() {
    const priceTypeList: ICbLov[] = [];

    for (const key in CbPriceType) {
      priceTypeList.push({
        key: key,
        value: this.$options.filters.formatLanguage(CbPriceType[key], this.currentLanguage),
      });
    }
    return priceTypeList;
  }
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
