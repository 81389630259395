import CButton from '@/components/button/c-button.vue';
import { parseTimestamp } from '@/module/chat-container/utils/dates';
import ChtVwMediaService from '@/services/cht-vw-media.service';
import AlertService from '@/shared/alert/alert.service';
import JhiDataUtils from '@/shared/data/data-utils.service';
import Icon from '@/shared/icons/icon.vue';
import { IChtVwMedia } from '@/shared/model/cht-vw-media.model';
import { IChtVwMessage } from '@/shared/model/cht-vw-message.model';
import { ChtMediaType } from '@/shared/model/enumerations/cht-media-type.model';
import { mixins } from 'vue-class-component';
import { Inject, Component, Prop } from 'vue-property-decorator';
import CIcons from '../c-icons/c-icons.vue';
import SvgIcon from '../SvgIcon/SvgIcon.vue';
import CRmediaFiles from './components/c-rmedia-files/c-rmedia-files.vue';
import CRmediaGroupdate from './components/c-rmedia-groupdate/c-rmedia-groupdate.vue';
import CRmediaLinks from './components/c-rmedia-links/c-rmedia-links.vue';
import CRmediaPictures from './components/c-rmedia-pictures/c-rmedia-pictures.vue';
import CRmediaPreview from './components/c-rmedia-preview/c-rmedia-preview.vue';
import vClickOutside from 'v-click-outside';
import GlobalService from '@/services/global.service';
import CLoader from '@/components/c-loader/c-loader.vue';
import axios from 'axios';

@Component({
  components: {
    Icon,
    SvgIcon,
    CRmediaPictures,
    CRmediaLinks,
    CRmediaFiles,
    CButton,
    CIcons,
    CRmediaGroupdate,
    CRmediaPreview,
    CLoader
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class CRoomMedia extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('ChtVwMediaService') private ChtVwMediaService: () => ChtVwMediaService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('globalService') private globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================

  public isShowMediaOption = false;
  public isSeletedTabMenu = 'PICTURES';
  public values = [];
  public chtVwMedias: IChtVwMedia[] = [];
  public seletedChtMediaType: ChtMediaType = ChtMediaType.MD_VIDEOIMAGES;
  public tabChtMediaType = ChtMediaType;

  public itemsPerPage = 20;
  public queryCount: number = null;
  public page = 1;
  public previousPage = 1;
  public propOrder = 'createdDate';
  public reverse = true;
  public totalItems = 0;
  public infiniteId = +new Date();
  public links = null;

  public isFetching = false;

  public selectedMessageIds = [];
  public idxPreview = null;

  public cancelToken: any;

  @Prop()
  public messages;
  @Prop({ type: Array, required: true })
  rooms;
  @Prop({ type: [String, Number], required: true })
  roomId;

  @Prop({ type: String, default: '' })
  chtToken;

  // MediaPreview
  public showMediaPreview = false;
  public seletedMediaPreview = '';

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted(): void {
    this.retrieveAllChtVwMedia(this.seletedChtMediaType);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleMediaPreview(item, index) {
    this.idxPreview = index;
    this.globalService().openGlobalDialog(this.$root, 'modal-crmedia-preview');
    setTimeout(() => {
      const found = this.chtVwMedias.find(value => value.mediaId == item.mediaId);
      if (found) {
        (<any>(<any>this.$refs.previewMediaMainRef).$refs.vueperSlidesContentImageVideo).goToSlide(index);
      }
    }, 1);
  }

  public callBack() {
    if (this.isShowMediaOption) {
      this.isShowMediaOption = false;
    } else {
      this.$emit('closeRoomMedia', null);
    }
  }

  public handleSeletedTab(chtMediaType: ChtMediaType) {
    this.isShowMediaOption = false;
    this.seletedChtMediaType = chtMediaType;
    this.page = 1;
    this.infiniteId += 1;
    this.chtVwMedias = [];
    this.retrieveAllChtVwMedia(chtMediaType);
  }

  public onSelectedMedia(event) {
    const findIndex = this.selectedMessageIds.findIndex(o => o === event.target._value);

    if (event.target.checked) {
      this.isShowMediaOption = true;
      this.selectedMessageIds = [];
      this.selectedMessageIds.push(event.target._value);
    }
    {
      if (findIndex >= 0) {
        this.selectedMessageIds.splice(findIndex, 1);
      }
    }

    if (this.selectedMessageIds.length == 0) {
      this.isShowMediaOption = false;
    }
  }
  public retrieveAllChtVwMedia(chtMediaType: ChtMediaType): void {
    this.isFetching = true;
  
    if (this.cancelToken) {
      this.cancelToken.cancel('Request Order Cancelled');
    }
    this.cancelToken = axios.CancelToken.source();
    const roomId = this.room.roomId;
    const paginationQuery = {
      page: this.page - 1,
      size: this.itemsPerPage,
      moreFilter: 'chtMediaType.equals=' + chtMediaType + '&' + 'chtConversationId.equals=' + roomId + '&',
      sort: this.sort(),
    };
    this.ChtVwMediaService()
      .retrieve(paginationQuery, this.cancelToken ? this.cancelToken.token : null)
      .then(
        res => {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const formattedMessage = this.formatMessage(null, res.data[i]);
              this.chtVwMedias.push(formattedMessage);
            }

            if (res.headers && res.headers['link']) {
              this.links = this.parseLinks(res.headers['link']);
            }
          }
          this.totalItems = Number(res.headers['x-total-count']);
          this.queryCount = this.totalItems;
          this.isFetching = false;
          if (<any>this.$refs.infiniteLoading) {
            (<any>this.$refs.infiniteLoading).stateChanger.loaded();
            if (this.links !== null && this.page > this.links['last']) {
              (<any>this.$refs.infiniteLoading).stateChanger.complete();
            }
          }
        },
        err => {
          this.isFetching = false;
          this.alertService().showHttpError(this, err.response);
        }
      );
  }

  public formatMessage(room, chtVwMedia: IChtVwMessage) {
    const formattedMessage = {
      ...chtVwMedia,
      ...{
        groupTime: parseTimestamp(new Date(chtVwMedia.createdDate), 'HH:mm'),
        groupDate: parseTimestamp(new Date(chtVwMedia.createdDate), 'DD/MM/YY'),
        groupMonth: parseTimestamp(new Date(chtVwMedia.createdDate), 'Month'),
        groupYear: parseTimestamp(new Date(chtVwMedia.createdDate), 'Year'),
      },
    };

    return formattedMessage;
  }
  public clear(): void {
    this.page = 1;
    this.links = null;
    this.infiniteId += 1;
    this.chtVwMedias = [];
    this.retrieveAllChtVwMedia(this.seletedChtMediaType);
  }

  public reset(): void {
    this.page = 1;
    this.infiniteId += 1;
    this.chtVwMedias = [];
    this.retrieveAllChtVwMedia(this.seletedChtMediaType);
  }

  public handleSyncList(): void {
    this.clear();
  }

  public loadMore($state): void {
    if (!this.isFetching) {
      this.page++;
      this.transition();
    }
  }

  public transition(): void {
    this.retrieveAllChtVwMedia(this.seletedChtMediaType);
  }

  public loadPage(page: number): void {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.transition();
    }
  }

  public closeDialog(): void {
    (<any>this.$refs.removeEntity).hide();
  }

  public sort(): Array<any> {
    const result = [this.propOrder + ',' + (this.reverse ? 'desc' : 'asc')];
    if (this.propOrder !== 'createdDate') {
      result.push('createdDate');
    }
    return result;
  }

  public openFile(file) {
    window.open(file, '_blank');
  }

  public downloadFile() {
    for (const value of this.chtVwMedias) {
      if (value.chtMessageId == this.selectedMessageIds[0]) {
        this.openFile(value.url);
      }
    }
  }

  public downloadImageVideo(valueId) {
    this.openFile(valueId.url);
  }
  public deleteImageVideo(valueId) {
    this.selectedMessageIds[0] = valueId.chtMessageId;
    (<any>this.$refs.dltChtMgsModal).show();
  }

  public delChtMgsCallBack() {
    (<any>this.$refs.dltChtMgsModal).hide();
  }
  public showDeleteChtMgs() {
    (<any>this.$refs.dltChtMgsModal).show();
  }

  public confirmDeleteSelectedMediaIds(action) {
    const msg = { id: this.selectedMessageIds[0] };
    if (msg) {
      setTimeout(() => {
        this.$emit('message-action-handler', { action, message: msg });
      }, 300);
      setTimeout(() => {
        this.page = 1;
        this.links = null;
        this.infiniteId += 1;
        this.chtVwMedias = [];
        if (this.isShowMediaOption) {
          this.isShowMediaOption = false;
          this.retrieveAllChtVwMedia(ChtMediaType.MD_FILES);
        } else {
          this.retrieveAllChtVwMedia(ChtMediaType.MD_VIDEOIMAGES);
          setTimeout(() => {
            (<any>(<any>this.$refs.previewMediaMainRef).$refs.vueperSlidesContentImageVideo).goToSlide(this.idxPreview);
            if (this.chtVwMedias.length == 0) {
              this.globalService().closeGlobalDialog(this.$root, 'modal-crmedia-preview');
            }
          }, 1000);
        }
        (<any>this.$refs.dltChtMgsModal).hide();
      }, 500);
    }
  }
  // ================= END FUNCTION ===================
  public closeCRmediaPreview() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-crmedia-preview');
  }

  public showCRmediaPreview() {
    this.globalService().openGlobalDialog(this.$root, 'modal-crmedia-preview');
  }
  public get room() {
    const room = this.rooms.find(room => room.roomId == this.roomId) || {};

    return room;
  }

  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
