const zeroPad = (num, pad) => {
  return String(num).padStart(pad, '0');
};

const isSameDay = (d1, d2) => {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
};

export const parseTimestamp = (timestamp, format = '') => {
  if (!timestamp) return;

  const date = timestamp.seconds ? new Date(timestamp.seconds * 1000) : timestamp;

  if (format === 'HH:mm') {
    return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
  } else if (format === 'DD MMMM YYYY') {
    const options = { month: 'long', year: 'numeric', day: 'numeric' };
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`;
  } else if (format === 'DD/MM/YY') {
    const options = { month: 'numeric', year: 'numeric', day: 'numeric' };
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`;
  } else if (format === 'DD MMMM, HH:mm') {
    const options = { month: 'long', day: 'numeric' };
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}, ${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
  } else if (format === 'Month') {
    const options = { month: 'long' };
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`;
  } else if (format === 'Year') {
    const options = { year: 'numeric' };
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`;
  } else if (format === 'dd/MM/YY') {
    const options = { month: 'short', year: '2-digit', day: 'numeric' };
    return `${new Intl.DateTimeFormat('en-GB', options).format(date)}`;
  }

  return date;
};

export const formatTimestamp = (date, timestamp, yesterday) => {
  const today = new Date();
  const diffTime = Math.abs(today - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  if (diffDays == 1) {
    return parseTimestamp(timestamp, 'HH:mm');
  } else if (diffDays == 2) {
    return yesterday;
  } else {
    return parseTimestamp(timestamp, 'dd/MM/YY');
  }
};
