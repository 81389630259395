export enum NtNotifType {
  GENERAL = 'GENERAL',

  NO_SETTING = 'NO_SETTING',

  SYSTEM_MESSAGES = 'SYSTEM_MESSAGES',

  REQUEST_OFFER = 'REQUEST_OFFER',

  NEW_REQUEST_OFFER = 'NEW_REQUEST_OFFER',

  ORDER_UPDATES = 'ORDER_UPDATES',

  MAIL = 'MAIL',

  ASSIGNMENT = 'ASSIGNMENT',
}

export enum NtNotifTypeWithSetting {
  SYSTEM_MESSAGES = 'SYSTEM_MESSAGES',

  REQUEST_OFFER = 'REQUEST_OFFER',

  NEW_REQUEST_OFFER = 'NEW_REQUEST_OFFER',

  ORDER_UPDATES = 'ORDER_UPDATES',
}
