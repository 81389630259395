var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-login" }, [
    _c("div", { staticClass: "login-dekstop" }, [
      _c(
        "p",
        {
          staticClass:
            "font-catamaran font-weight-light cj-color-grey-secondary font-size-12 text-center my-3",
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.newToCollarbear")
              ),
            },
          }),
          _vm._v(" "),
          _c("a", {
            staticClass: "cj-color-red-primary font-weight-light",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.linkRegister")
              ),
            },
            on: { click: _vm.openDialogRegister },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-12 col-lg-6 mb-3",
            on: { click: _vm.loginGoogle },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn-social cursor-pointer d-flex justify-content-center p-3",
                attrs: { "data-gtm": "login-with-goole-button" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex mr-3" },
                  [
                    _c("icon", {
                      attrs: { name: "ic_google", width: "1.625rem" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-500 font-size-12 text-nowrap",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.google")
                    ),
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-12 col-lg-6 mb-3",
            on: { click: _vm.loginFacebook },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn-social cursor-pointer d-flex justify-content-center p-3",
                attrs: { "data-gtm": "login-with-facebook-button" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex mr-3" },
                  [
                    _c("icon", {
                      attrs: { name: "ic_facebook", width: "1.625rem" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-500 font-size-12 text-nowrap",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.facebook")
                    ),
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-12 col-lg-6 mb-3",
            on: { click: _vm.loginLinkedin },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn-social cursor-pointer d-flex justify-content-center p-3",
                attrs: { "data-gtm": "login-with-linkedin-button" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex mr-3" },
                  [
                    _c("icon", {
                      attrs: { name: "ic_linkedin", width: "1.625rem" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-500 font-size-12 text-nowrap",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.linkedin")
                    ),
                  },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-12 col-lg-6 mb-3",
            on: { click: _vm.loginApple },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "btn-social cursor-pointer d-flex justify-content-center p-3",
                attrs: { "data-gtm": "login-with-apple-button" },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex mr-3" },
                  [
                    _c("icon", {
                      attrs: { name: "ic_apple", width: "1.625rem" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "font-roboto my-auto font-weight-500 font-size-12 text-nowrap",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.apple")
                    ),
                  },
                }),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "d-flex w-100" }, [
            _c("p", { staticClass: "hr-text my-4" }, [
              _c("span", {
                staticClass:
                  "font-catamaran font-weight-light cj-color-grey-secondary font-size-12 cj-bg-white-primary px-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.or")
                  ),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("div", { staticClass: "mb-4 w-100" }, [
                _c("p", {
                  staticClass: "font-login",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.login.emailPhone"
                      )
                    ),
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$v.user.login.$model,
                      expression: "$v.user.login.$model",
                    },
                  ],
                  ref: "enterlogin",
                  staticClass:
                    "p-0 py-2 cj-field-input custom-input-autofill font-login cj-field-border-bottom-default",
                  class: _vm.isLoginValidCopy(_vm.$v.user.login),
                  attrs: {
                    "data-cy": "input-email",
                    name: "input-email",
                    type: "text",
                  },
                  domProps: { value: _vm.$v.user.login.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.$v.user.login, "$model", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4 w-100" }, [
                _c("p", {
                  staticClass: "font-login",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbHome.cbHomeBeforeLogin.login.password")
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex cj-field-border-bottom-default",
                    class: `${
                      _vm.$v.user.password.$anyDirty
                        ? _vm.$v.user.password.$invalid
                          ? "invalid"
                          : "valid"
                        : ""
                    }`,
                  },
                  [
                    _vm.passwordInputType === "checkbox"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.user.password.$model,
                              expression: "$v.user.password.$model",
                            },
                          ],
                          ref: "enterloginPassword",
                          staticClass:
                            "p-0 py-2 custom-input-autofill font-login cj-field-not-border",
                          attrs: {
                            "data-cy": "input-password",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.$v.user.password.$model)
                              ? _vm._i(_vm.$v.user.password.$model, null) > -1
                              : _vm.$v.user.password.$model,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.$v.user.password.$model,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.$v.user.password,
                                      "$model",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.$v.user.password,
                                      "$model",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.$v.user.password, "$model", $$c)
                              }
                            },
                          },
                        })
                      : _vm.passwordInputType === "radio"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.user.password.$model,
                              expression: "$v.user.password.$model",
                            },
                          ],
                          ref: "enterloginPassword",
                          staticClass:
                            "p-0 py-2 custom-input-autofill font-login cj-field-not-border",
                          attrs: { "data-cy": "input-password", type: "radio" },
                          domProps: {
                            checked: _vm._q(_vm.$v.user.password.$model, null),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.$v.user.password,
                                "$model",
                                null
                              )
                            },
                          },
                        })
                      : _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.user.password.$model,
                              expression: "$v.user.password.$model",
                            },
                          ],
                          ref: "enterloginPassword",
                          staticClass:
                            "p-0 py-2 custom-input-autofill font-login cj-field-not-border",
                          attrs: {
                            "data-cy": "input-password",
                            type: _vm.passwordInputType,
                          },
                          domProps: { value: _vm.$v.user.password.$model },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.user.password,
                                "$model",
                                $event.target.value
                              )
                            },
                          },
                        }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-password",
                        attrs: { "data-cy": "hide-password", type: "button" },
                        on: { click: _vm.togglePassword },
                      },
                      [
                        _vm.passwordInputType === "text"
                          ? _c("icon", {
                              staticClass: "cj-stroke-grey-secondary",
                              attrs: {
                                name: "ic_eye_open",
                                "data-cy": "button-show-password",
                                width: "1rem",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.passwordInputType === "password"
                          ? _c("icon", {
                              staticClass: "cj-stroke-grey-secondary",
                              attrs: {
                                name: "ic_eye_close",
                                "data-cy": "button-hide-password",
                                width: "1rem",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.authenticationError &&
              _vm.isLoginErrorPassword &&
              !_vm.isLoginInvalid
                ? _c("div", [
                    _c(
                      "small",
                      {
                        staticClass:
                          "form-text font-roboto font-size-12 font-weight-300",
                      },
                      [
                        _c("span", {
                          staticClass: "text-danger cj-color-status-error",
                          attrs: { "data-cy": "login-system-alert-incorrect" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(`cbgwApp.cbComponent.login.incorrect`)
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("u", {
                          staticClass:
                            "cj-color-black-primary font-weight-600 hand",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbComponent.login.forgotPassword")
                            ),
                          },
                          on: { click: _vm.changePassword },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "text-danger cj-color-status-error",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(`cbgwApp.cbComponent.login.liaison`)
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("u", {
                          staticClass:
                            "cj-color-black-primary font-weight-600 hand",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "cbgwApp.cbComponent.login.phoneVerification"
                              )
                            ),
                          },
                          on: { click: _vm.openPhoneVerification },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticationError && _vm.isLoginErrorBlocked
                ? _c("div", [
                    _c(
                      "small",
                      {
                        staticClass:
                          "form-text font-roboto font-size-12 font-weight-300",
                      },
                      [
                        _c("span", {
                          staticClass: "text-danger cj-color-status-error",
                          attrs: {
                            "data-cy": "login-system-alert-account-block",
                          },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(`cbgwApp.cbComponent.login.accountBlock`)
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "u",
                          {
                            staticClass:
                              "cj-color-black-primary font-weight-600 hand",
                          },
                          [_vm._v("support@klikjob.com")]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoginInvalid
                ? _c("div", [
                    _c(
                      "small",
                      {
                        staticClass:
                          "form-text font-roboto font-size-12 font-weight-300",
                      },
                      [
                        _c("span", {
                          staticClass: "text-danger cj-color-status-error",
                          attrs: { "data-cy": "login-system-alert-invalid" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(`cbgwApp.cbComponent.login.invalid`)
                            ),
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mt-1" },
                [
                  _c("div", {
                    staticClass: "input-checkbox cj-color-red-primary d-flex",
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "font-catamaran cursor-pointer font-weight-light cj-color-grey-secondary font-size-12",
                    attrs: { "data-cy": "forgot-password" },
                    on: { click: _vm.changePassword },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "input-checkbox cj-color-red-primary d-flex",
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-weight-light cj-color-grey-secondary font-size-12 cursor-pointer global-underline",
                      on: { click: _vm.changePassword },
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.login.forgotPassword"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "c-button",
                {
                  staticClass: "text-center my-3",
                  attrs: {
                    "data-cy": "button-login-menu",
                    dataGTMCustom: "login",
                    fullWidth: "",
                    boxShadow: "",
                    disabled:
                      _vm.$v.user.$invalid ||
                      _vm.isRequesting ||
                      _vm.isLoginInvalid,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onLogin.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.isRequesting
                    ? _c("font-awesome-icon", {
                        staticClass: "py-1",
                        attrs: { icon: "sync", spin: _vm.isRequesting },
                      })
                    : _c("span", {
                        staticClass:
                          "font-roboto font-size-20 py-3 font-weight-500",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbHome.cbHomeBeforeLogin.login.btnLogin"
                            )
                          ),
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }