export enum SocketTopic {
  USER_ONLINE = 'CB_USER_ONLINE',
  CONVERSATION_ID = 'CB_CONVERSATION_ID',
  CONVERSATION_NEW = 'CB_CONVERSATION_NEW',
  EMAIL = 'CB_EMAIL',
  LA_CONVERSATION_ID = 'LA_CONVERSATION_ID',
  LA_CONVERSATION_NEW = 'LA_CONVERSATION_NEW',
  CB_PAW_ORDER_PAID = 'CB_PAW_ORDER_PAID',
  CB_ORDER_TRACK_LOCATION = 'CB_ORDER_TRACK_LOCATION',
  CB_ORDER_TRACK_SHOW_HIDE_MAP = 'CB_ORDER_TRACK_SHOW_HIDE_MAP',
  CB_SUSPEND_AND_BLOCKED = 'CB_SUSPEND_AND_BLOCKED',
  CB_TKT_REPLIED = 'CB_TKT_REPLIED',
}
