var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-user-balance cj-bg-white-primary p-2",
      class: _vm.isMobile ? "actived-mobile mx-3 py-3" : "mt-3",
      staticStyle: { "border-radius": "8px" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex", class: _vm.isMobile ? "" : "flex-wrap" },
        [
          _c(
            "div",
            {
              staticClass: "col-5 col-lg-12 px-1 px-lg-0 mb-0 mb-lg-2",
              class: _vm.isMobile ? "user-balance-border-right" : "",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto cj-color-grey-primary font-weight-300 font-size-title mb-1",
                    },
                    [_vm._v(_vm._s(_vm.$t("cbGlobal.cbBalannce")))]
                  ),
                  _vm._v(" "),
                  !_vm.isMobile
                    ? _c("c-user-balance-topup", {
                        staticClass: "ml-2 mb-1",
                        attrs: { "data-gtm": "handle-top-up-button" },
                        on: { handleTopup: _vm.handleTopup },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mr-lg-auto mr-1 font-roboto font-weight-500 font-size-content cj-color-black-primary",
                  },
                  [_vm._v("IDR")]
                ),
                _vm._v(" "),
                !_vm.isFetchingPayVwBalanceCore
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto font-weight-500 font-size-content cj-color-black-primary d-flex w-100 justify-content-end",
                        class:
                          !_vm.getShowEyesBalance && !_vm.isMobile
                            ? "circle-actived"
                            : "",
                        attrs: { "data-cy": "balance" },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.payVwBalanceCore.balance,
                                "IDR"
                              )
                            )
                          ),
                        ]),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "d-flex w-100 justify-content-end" },
                      [
                        _c("h2", {
                          staticClass: "card-title skeleton my-auto",
                          staticStyle: {
                            width: "70%",
                            height: "12px",
                            "min-height": "12px",
                            "max-height": "12px",
                          },
                        }),
                      ]
                    ),
                _vm._v(" "),
                !_vm.isMobile
                  ? _c("div", { staticClass: "ml-1" }, [
                      _vm.getShowEyesBalance
                        ? _c(
                            "div",
                            {
                              attrs: {
                                "data-gtm": "handle-show-balance-button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleShowEyes("BALANCE", false)
                                },
                              },
                            },
                            [
                              _c("icon", {
                                staticClass: "cj-fill-black-primary",
                                attrs: {
                                  name: "ic_outline_eyes",
                                  width: "20px",
                                  height: "20px",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              attrs: {
                                "data-gtm": "handle-show-balance-button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleShowEyes("BALANCE", true)
                                },
                              },
                            },
                            [
                              _c("icon", {
                                staticClass: "cj-fill-black-primary",
                                attrs: {
                                  name: "ic_outline_eyes_closed",
                                  width: "20px",
                                  height: "20px",
                                },
                              }),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-5 col-lg-12 px-1 px-lg-0",
              class: _vm.isMobile ? "user-balance-border-right" : "",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto cj-color-grey-primary font-weight-300 font-size-title mb-1",
                },
                [
                  _vm.loginType == _vm.cbLoginType.PARTNER
                    ? _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbGlobal.balance.thisMonthRevenue")
                            ),
                          },
                        },
                        [_vm._v("This Month Revenue")]
                      )
                    : _c(
                        "span",
                        {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbGlobal.balance.thisMonthSpending")
                            ),
                          },
                        },
                        [_vm._v("This Month Spending")]
                      ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mr-lg-auto mr-1 font-roboto font-weight-500 font-size-content cj-color-black-primary",
                  },
                  [_vm._v("IDR")]
                ),
                _vm._v(" "),
                !_vm.isFetchingPayVwTransactionSummaries
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto font-weight-500 font-size-content cj-color-black-primary d-flex w-100 justify-content-end",
                      },
                      [
                        _vm.loginType == _vm.cbLoginType.PARTNER
                          ? _c(
                              "span",
                              {
                                staticClass: "d-flex w-100 justify-content-end",
                                class:
                                  !_vm.getShowEyesMonthRevenue && !_vm.isMobile
                                    ? "circle-actived"
                                    : "",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.payVwTransactionSummaries
                                        .countThisMonthRevenue,
                                      "IDR"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "d-flex w-100 justify-content-end",
                                class:
                                  !_vm.getShowEyesMonthSpending && !_vm.isMobile
                                    ? "circle-actived"
                                    : "",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.payVwTransactionSummaries
                                        .countThisMonthSpending,
                                      "IDR"
                                    )
                                  )
                                ),
                              ]
                            ),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "d-flex w-100 justify-content-end" },
                      [
                        _c("h2", {
                          staticClass: "card-title skeleton my-auto",
                          staticStyle: {
                            width: "70%",
                            height: "12px",
                            "min-height": "12px",
                            "max-height": "12px",
                          },
                        }),
                      ]
                    ),
                _vm._v(" "),
                !_vm.isMobile
                  ? _c(
                      "div",
                      { staticClass: "ml-1" },
                      [
                        _vm.loginType == _vm.cbLoginType.PARTNER
                          ? [
                              _vm.getShowEyesMonthRevenue
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-gtm":
                                          "handle-show-revenue-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowEyes(
                                            "REVENUE",
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        staticClass: "cj-fill-black-primary",
                                        attrs: {
                                          name: "ic_outline_eyes",
                                          width: "20px",
                                          height: "20px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-gtm":
                                          "handle-show-revenue-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowEyes(
                                            "REVENUE",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        staticClass: "cj-fill-black-primary",
                                        attrs: {
                                          name: "ic_outline_eyes_closed",
                                          width: "20px",
                                          height: "20px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          : [
                              _vm.getShowEyesMonthSpending
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-gtm":
                                          "handle-show-spending-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowEyes(
                                            "SPENDING",
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        staticClass: "cj-fill-black-primary",
                                        attrs: {
                                          name: "ic_outline_eyes",
                                          width: "20px",
                                          height: "20px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-gtm":
                                          "handle-show-spending-button",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleShowEyes(
                                            "SPENDING",
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("icon", {
                                        staticClass: "cj-fill-black-primary",
                                        attrs: {
                                          name: "ic_outline_eyes_closed",
                                          width: "20px",
                                          height: "20px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.isMobile
            ? _c(
                "div",
                { staticClass: "col-2 px-1 col-2 px-1 my-auto" },
                [
                  _c("c-user-balance-topup", {
                    on: { handleTopup: _vm.handleTopup },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }