import GlobalService from '@/services/global.service';
import { Inject, Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import Icon from '@/shared/icons/icon.vue';
@Component({
  components: {
    CButton,
    CConfirmation,
    Icon,
  },
})
export default class CInputPin extends Vue {
  // ================= SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;

  // ================= END SERVICES =================
  // ================= VARIABLES =================
  @Prop({ default: false }) public wrongOtpCode: boolean;
  @Prop({ default: false }) public isShowForgotPin: boolean;
  @Prop({ default: false }) public passwordCurrentNotMatch: boolean;
  @Prop({ default: false }) public pinNotMatched: boolean;
  @Prop({ default: false }) public pinOldNotMatched: boolean;
  @Prop({ default: false }) public isSavingCbPayInBalance: boolean;
  public pin: string = null;
  public enableBtn: boolean = true;
  // ================= END VARIABLES =================
  // ================= DEFAULT FUNCTION =================
  // ================= FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }
  @Emit('save')
  public save() {
    return this.pin;
  }

  @Emit('submitResetPin')
  public submitResetPin() {
    return;
  }

  public inputPin(e) {
    if (this.pin.length < 6) {
      this.enableBtn = true;
    }
    if (this.pin.length >= 6) {
      (<any>this.$refs.inputPin).blur();
      this.enableBtn = false;
    }
  }

  public openModalResetPin() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-reset-pin');
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-edit-pin');
  }

  public mounted() {
    setTimeout(() => {
      let inputs = document.querySelectorAll<HTMLInputElement>('input.input-pin-wrapper');
      let values = Array(6);
      let clipData;
      inputs[0].focus();

      let that = this;

      function focusFirstInputIfAllEmpty() {
        const allEmpty = values.every(value => !value);
        if (allEmpty) {
          inputs[0].focus();
        }
      }

      inputs.forEach((tag: any, index) => {
        tag.addEventListener('click', () => {
          focusFirstInputIfAllEmpty();
        });
        tag.addEventListener('keyup', event => {
          if (event.key === 'Backspace' && hasNoValue(index)) {
            if (index > 0) inputs[index - 1].focus();
          }

          //else if any input move focus to next or out

          let tmpValue = (event.target as HTMLInputElement).value;
          tmpValue = tmpValue.replace(/[^\d.-]+/g, '');
          if (tmpValue) {
            let length = tmpValue.length;
            inputs[index].setSelectionRange(length, length);
            index < inputs.length - 1 ? inputs[index + 1].focus() : tag.blur();
          }

          event.currentTarget.value = displayAsDot(tmpValue);
          values[index] = tmpValue;

          that.pin = values?.join('');
        });

        tag.addEventListener('input', () => {
          if (tag.value > 10) {
            tag.value = tag.value % 10;
          }
        });

        tag.addEventListener('paste', event => {
          event.preventDefault();
          clipData = event.clipboardData.getData('text/plain').split('');
          filldata(event, index);
        });
      });

      function filldata(event, index) {
        for (let i = index; i < inputs.length; i++) {
          let tmpValue = clipData.shift();

          if (tmpValue) {
            tmpValue = tmpValue.replace(/[^\d.-]+/g, '');

            if (event.code === 'Backspace' && hasNoValue(i)) {
              if (i > 0) inputs[i - 1].focus();
            }

            //else if any input move focus to next or out
            if (tmpValue) {
              i < inputs.length - 1 ? inputs[i + 1].focus() : inputs[i].blur();
            }
            inputs[i].value = displayAsDot(tmpValue);

            values[i] = inputs[i].value;
          }
        }
        that.pin = values?.join('')?.replace(/[^0-9]+/g, '');
      }

      function displayAsDot(value) {
        // Tampilkan simbol titik untuk setiap karakter
        return value.replace(/\d/g, '.');
      }

      function hasNoValue(index) {
        if (values[index] || values[index] === 0) return false;

        return true;
      }
    }, 10);
  }

  public validateInputNumber() {}
  // ================= END FUNCTION =================
  // ================= COMPUTE =================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get disabledSubmit() {
    return this.pin?.length < 6 || this.pin == null;
  }
  // ================= END COMPUTE =================
  // ================= START LISTENERS ================

  // ================= END LISTENERS ==================
}
