var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "p-setting-m", attrs: { "data-cy": "p-setting-m" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between py-3 px-3",
          attrs: {
            "data-gtm": "to-account-setting-button",
            "data-cy": "p-setting-m-account",
          },
          on: {
            click: function ($event) {
              return _vm.toAccount("PSettingAccountM")
            },
          },
        },
        [
          _c("p", {
            staticClass:
              "font-roboto cj-color-black-primary font-size-14 font-weight-500 my-auto",
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbSetting.account")),
            },
          }),
          _vm._v(" "),
          _c("icon", {
            staticClass: "cj-fill-black-primary ic_arrow_dropdown my-auto",
            attrs: { name: "ic_down", width: "1rem", height: "1rem" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "p-0 m-0" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between py-3 px-3",
          attrs: {
            "data-gtm": "to-account-linked-setting-button",
            "data-cy": "p-setting-m-account-linked",
          },
          on: {
            click: function ($event) {
              return _vm.toAccount("PSettingAccountMLinked")
            },
          },
        },
        [
          _c(
            "p",
            {
              staticClass:
                "font-roboto cj-color-black-primary font-size-14 font-weight-500 my-auto",
            },
            [_vm._v("Linked Account")]
          ),
          _vm._v(" "),
          _c("icon", {
            staticClass: "cj-fill-black-primary ic_arrow_dropdown my-auto",
            attrs: { name: "ic_down", width: "1rem", height: "1rem" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "p-0 m-0" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between py-3 px-3",
          attrs: {
            "data-gtm": "to-setting-password-button",
            "data-cy": "p-setting-m-account-password",
          },
          on: {
            click: function ($event) {
              return _vm.toAccount("PSettingAccountMPassword")
            },
          },
        },
        [
          _c(
            "p",
            {
              staticClass:
                "font-roboto cj-color-black-primary font-size-14 font-weight-500 my-auto",
            },
            [_vm._v("Password")]
          ),
          _vm._v(" "),
          _c("icon", {
            staticClass: "cj-fill-black-primary ic_arrow_dropdown my-auto",
            attrs: { name: "ic_down", width: "1rem", height: "1rem" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "p-0 m-0" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between py-3 px-3",
          attrs: {
            "data-gtm": "to-setting-bank-button",
            "data-cy": "p-setting-m-account-bank",
          },
          on: {
            click: function ($event) {
              return _vm.toAccount("PSettingAccountMBank")
            },
          },
        },
        [
          _c("p", {
            staticClass:
              "font-roboto cj-color-black-primary font-size-14 font-weight-500 my-auto",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbSetting.bankAccount.title")
              ),
            },
          }),
          _vm._v(" "),
          _c("icon", {
            staticClass: "cj-fill-black-primary ic_arrow_dropdown my-auto",
            attrs: { name: "ic_down", width: "1rem", height: "1rem" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "p-0 m-0" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between py-3 px-3",
          attrs: {
            "data-gtm": "to-setting-pin-button",
            "data-cy": "p-setting-m-account-pin",
          },
          on: {
            click: function ($event) {
              return _vm.toAccount("PPinM")
            },
          },
        },
        [
          _c(
            "p",
            {
              staticClass:
                "font-roboto cj-color-black-primary font-size-14 font-weight-500 my-auto",
            },
            [_vm._v("PIN")]
          ),
          _vm._v(" "),
          _c("icon", {
            staticClass: "cj-fill-black-primary ic_arrow_dropdown my-auto",
            attrs: { name: "ic_down", width: "1rem", height: "1rem" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "p-0 m-0" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }