var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vac-message-files-container" },
    [
      _vm.imageVideoFiles
        ? _c(
            "div",
            {
              staticClass: "var-message-video-image-container",
              class: {
                "multi-imgvideo": _vm.imageVideoFiles.length >= 2,
                "solo-imgvideo": _vm.imageVideoFiles.length <= 1,
              },
            },
            _vm._l(_vm.imageVideoFiles, function (file, idx) {
              return _c(
                "div",
                {
                  key: idx + "iv",
                  staticClass:
                    "limit-image-video position-relative video-image-wrapper",
                  class: {
                    "multi-image-video": _vm.imageVideoFiles.length >= 2,
                    "multi-mr-2": idx == 0,
                    "solo-image-video": _vm.imageVideoFiles.length <= 1,
                  },
                  attrs: { "data-cy": "video-image-wrapper" },
                },
                [
                  _c("message-file", {
                    attrs: {
                      file: file,
                      "current-user-id": _vm.currentUserId,
                      message: _vm.message,
                      index: idx,
                      "message-selection-enabled": _vm.messageSelectionEnabled,
                      isYourSelf: _vm.isYourSelf,
                    },
                    on: {
                      "open-file": function ($event) {
                        return _vm.$emit("open-file", $event)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.$scopedSlots, function (i, name) {
                          return {
                            key: name,
                            fn: function (data) {
                              return [_vm._t(name, null, null, data)]
                            },
                          }
                        }),
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  idx == 1
                    ? _c(
                        "div",
                        {
                          staticClass: "position-count",
                          class:
                            _vm.imageVideoFiles.length <= 2 ? "d-none" : "",
                        },
                        [
                          _c(
                            "b-avatar",
                            {
                              class:
                                _vm.message.senderId === _vm.currentUserId
                                  ? "bg-isyourself"
                                  : "bg-otheruser",
                              attrs: { size: "25px" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "m-0",
                                  staticStyle: {
                                    "font-size": "14px",
                                    "font-weight": "700",
                                  },
                                },
                                [
                                  _vm._v(
                                    "+" + _vm._s(_vm.imageVideoFiles.length - 2)
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.otherFiles, function (file, idx) {
        return _c(
          "div",
          {
            key: idx + "a",
            staticClass: "var-message-file-container pb-2",
            attrs: { "data-cy": "var-message-file-container" },
          },
          [
            file.progress >= 0
              ? _c("progress-bar", {
                  style: { top: "31px" },
                  attrs: { progress: file.progress },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "vac-file-container",
                class: { "vac-file-container-progress": file.progress >= 0 },
                on: {
                  click: function ($event) {
                    return _vm.openFile($event, file, "download")
                  },
                },
              },
              [
                file.type == "application/pdf" && file.urlThumbnail
                  ? _c("div", { staticClass: "h-100 w-100 pb-2" }, [
                      _c("img", {
                        staticClass: "preview-file-pdf",
                        attrs: { src: file.urlThumbnail, alt: "" },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex w-100" }, [
                  _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "mr-2" },
                      [_c("c-icon-file", { attrs: { file: file } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      staticStyle: { overflow: "hidden" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vac-text-ellipsis",
                          staticStyle: {
                            color: "var(--cj-color-black-primary)",
                          },
                          attrs: { "data-cy": "vac-file-name" },
                        },
                        [_vm._v(_vm._s(file.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center",
                        },
                        [
                          file.extension
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "vac-text-ellipsis vac-text-extension",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(file.extension) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "vac-svg-button ml-1",
                              attrs: { "data-cy": "vac-btn-download" },
                            },
                            [
                              _vm._t("document-icon", function () {
                                return [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      height: "14px",
                                      width: "14px",
                                    },
                                    attrs: { name: "document" },
                                  }),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("format-message", {
        attrs: {
          content: _vm.message.content,
          "sticker-url": _vm.message.stickerUrl,
          users: _vm.roomUsers,
          "text-formatting": _vm.textFormatting,
          "link-options": _vm.linkOptions,
        },
        on: {
          "open-user-tag": function ($event) {
            return _vm.$emit("open-user-tag", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (i, name) {
              return {
                key: name,
                fn: function (data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }