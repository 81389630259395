import { Vue, Component } from 'vue-property-decorator';
import roomFilesChat from '../room-files/room-files.vue';

@Component({
  components: {
    roomFilesChat,
  },
})
export default class RoomUploadsChat extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleShowDocument() {
    this.$emit('launchFilePicker', null);
  }
  public handleShowVideoImage() {
    this.$emit('launchVideoImage', null);
  }
  public handleShowLocation() {
    this.$emit('handleLocation', null);
  }
  public handleShowCamera() {
    this.$emit('handleCamera', null);
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
