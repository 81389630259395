export interface IFnListener {
  key: string;
  fn: (event: Event) => void;
}

export class FnListener {
  static KEY_XENDIT_CREDIT_IFRAME_CALLBACK = 'message'; //key xendit cc iframe must set message
  static KEY_RESIZE = 'resize'; //even resize: c-banner, app

  static fnArr: IFnListener[] = [];
}
