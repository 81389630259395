export enum PayType {
  ALL = 'ALL',
  VA = 'VA',
  CC = 'CC',
  DISBURSEMENT = 'DISBURSEMENT',
  QRCODE = 'QRCODE',
  EWALLET = 'EWALLET',
  BALANCE = 'BALANCE',
  BALANCE_REFUND = 'BALANCE_REFUND',
  BALANCE_AUCTION_GUARANTEE = 'BALANCE_AUCTION_GUARANTEE',
  DISBURSEMENT_INCOME = 'DISBURSEMENT_INCOME',
  DISBURSEMENT_REFUND = 'DISBURSEMENT_REFUND',
}
