import axios from 'axios';

import { ICbVwPawOrderRequirement } from '@/shared/model/cb-vw-paw-order-requirement.model';

const baseApiUrl = 'services/cbcorems/api/cb-vw-paw-order-requirements';

export default class PCbVwPawOrderRequirementService {
  public find(id: number, cancelToken?: any): Promise<ICbVwPawOrderRequirement> {
    return new Promise<ICbVwPawOrderRequirement>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, { cancelToken })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
