var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-complete-payment-va",
      attrs: { "data-cy": "modal-complete-payment-va" },
    },
    [
      _c("div", { staticClass: "d-flex bar-wrapper height-navbar-m" }, [
        _c(
          "div",
          {
            staticClass: "cursor-pointer position-title",
            attrs: { "data-cy": "cancel-complete-payment" },
            on: { click: _vm.cancel },
          },
          [
            _c("b-icon-x-circle-fill", {
              staticClass:
                "font-size-24 cj-color-grey-secondary vac-d-show-web",
            }),
            _vm._v(" "),
            _c("font-awesome-icon", {
              staticClass:
                "cb-icon-size-arrow-left cj-color-black-primary vac-d-none-web p-3",
              attrs: { icon: "fa-solid fa-arrow-left" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "costum-margin" }, [
          _c("p", {
            staticClass:
              "text-center font-roboto p-0 m-0 font-size-24 cj-color-black-primary font-weight-700 text-ellipsis oneline",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbCompletePayment.completeYourPayment")
              ),
            },
          }),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "text-center font-roboto p-0 m-0 font-size-18 cj-color-black-primary font-weight-400 text-break-all",
              attrs: { "data-cy": "complete-payment-created-date" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("dynamicFormatDate")(
                      _vm.payDetailVA.expirationDate,
                      "dddd, DD MMMM YYYY HH:mm"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "complete-payment-container" }, [
        _c("div", { staticClass: "time-coundown-wrapper mt-0 mt-lg-2" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center cb-gap-2 mt-lg-2 mt-0 mx-auto align-items-center",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-700 text-center font-size-16 justify-content-center cb-badge-status",
                  class: {
                    [_vm.getOrderStatusColorByStatus(_vm.currentStatus, "bg")]:
                      _vm.getOrderStatusColorByStatus(_vm.currentStatus, "bg"),
                  },
                },
                [
                  _c("span", {
                    attrs: { "data-cy": "current-status-pay" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          `cbgwApp.payVwWaitingForPayment.${_vm.currentStatus}`
                        )
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.currentStatus !== _vm.cbCommonStatus.COMPLETED
                ? _c("c-time-countdown", {
                    attrs: {
                      "data-cy": "time-countdown",
                      timeDate: _vm.payDetailVA.expirationDate,
                      stopCountdown: 0,
                    },
                    on: {
                      action: function ($event) {
                        _vm.expiredCountdown = _vm.cbCommonStatus.EXPIRED
                        _vm.currentStatus = _vm.cbCommonStatus.EXPIRED
                        _vm.payDetailVA.expirationDate = null
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ hours, minutes, seconds }) {
                            return [
                              hours && hours != "00"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rounded font-weight-700 font-roboto px-1 d-flex align-items-center mr-1",
                                      class: {
                                        [_vm.getTimeBackgroundByStatus({
                                          status: _vm.currentStatus,
                                        }).timeColor]:
                                          _vm.getTimeBackgroundByStatus({
                                            status: _vm.currentStatus,
                                          }).timeColor,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-size-14" },
                                        [_vm._v(_vm._s(hours))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "font-size-10" },
                                        [_vm._v("H")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded font-weight-700 font-roboto px-1 d-flex align-items-center mr-1",
                                  class: {
                                    [_vm.getTimeBackgroundByStatus({
                                      status: _vm.currentStatus,
                                    }).timeColor]:
                                      _vm.getTimeBackgroundByStatus({
                                        status: _vm.currentStatus,
                                      }).timeColor,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-size-14",
                                      attrs: {
                                        "data-cy": "time-countdown-minutes",
                                      },
                                    },
                                    [_vm._v(_vm._s(minutes))]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "font-size-10" }, [
                                    _vm._v("M"),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rounded font-weight-700 font-roboto px-1 d-flex align-items-center",
                                  class: {
                                    [_vm.getTimeBackgroundByStatus({
                                      status: _vm.currentStatus,
                                    }).timeColor]:
                                      _vm.getTimeBackgroundByStatus({
                                        status: _vm.currentStatus,
                                      }).timeColor,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "font-size-14",
                                      attrs: {
                                        "data-cy": "time-countdown-seconds",
                                      },
                                    },
                                    [_vm._v(_vm._s(seconds))]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "font-size-10" }, [
                                    _vm._v("S"),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2035599080
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pt-3" }, [
          _c(
            "div",
            {
              staticClass: "wrap-border-grey-tertiary border-radius-card mb-3",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between py-2 px-3" },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-size-18 font-roboto cj-color-black-primary font-weight-500 my-auto",
                      attrs: { "data-cy": "bank-name" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.payDetailVA.bankCode) +
                          " "
                      ),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbCompletePayment.va")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "my-auto",
                    staticStyle: { width: "4rem" },
                    attrs: {
                      "data-cy": "complete-payment-bank-option",
                      src: `@/../content/images/bank/${_vm.payDetailVA.bankCode.toLowerCase()}.svg`,
                      alt: "card",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "p-0 m-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "py-3 px-3" }, [
                _c("p", {
                  staticClass:
                    "p-0 m-0 cj-color-black-primary font-size-14 font-roboto font-weight-300",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbCompletePayment.vaNumber")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "my-auto p-0 mr-2 cj-color-black-primary font-size-18 font-roboto font-weight-500",
                      attrs: { "data-cy": "va-number", id: "appId" },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.payDetailVA.accountNumber) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.canCopy
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "my-auto d-flex cb-tooltip-copy-container",
                          attrs: { "data-cy": "va-number-copy" },
                          on: {
                            click: function ($event) {
                              return _vm.copy(_vm.payDetailVA.accountNumber)
                            },
                          },
                        },
                        [
                          _c("icon", {
                            staticClass: "cj-stroke-red-primary",
                            attrs: { name: "ic_copy", width: "1.2rem" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "p-0 m-0" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex py-3 px-3 justify-content-between" },
                [
                  _c("div", { staticClass: "my-auto" }, [
                    _c("p", {
                      staticClass:
                        "p-0 m-0 cj-color-black-primary font-size-14 font-roboto font-weight-300",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbCompletePayment.vaName")
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "p-0 m-0 cj-color-black-primary font-size-18 font-roboto font-weight-500",
                        attrs: { "data-cy": "va-name" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.currentUser.displayName) +
                            " - " +
                            _vm._s(_vm.payDetailVA.externalId) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "p-0 m-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "my-auto py-3 px-3" }, [
                _c("p", {
                  staticClass:
                    "cj-color-black-primary p-0 m-0 font-size-14 font-roboto font-weight-300",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbCompletePayment.totalPayment")
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    attrs: { "data-cy": "pay-detail-va" },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "cj-color-black-primary font-size-18 font-roboto font-weight-500 my-auto mr-2",
                        attrs: { "data-cy": "total-price" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.payDetailVA.currency) +
                            " " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.payDetailVA.expectedAmount,
                                _vm.payDetailVA.currency
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.canCopy
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "my-auto d-flex cb-tooltip-copy-container",
                            attrs: { "data-cy": "total-payment-copy" },
                            on: {
                              click: function ($event) {
                                return _vm.copy(_vm.payDetailVA.expectedAmount)
                              },
                            },
                          },
                          [
                            _c("icon", {
                              staticClass: "cj-stroke-red-primary",
                              attrs: { name: "ic_copy", width: "1.2rem" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "font-roboto cj-color-black-primary font-size-16 font-weight-400 text-right mt-1 mb-4",
              attrs: { "data-cy": "transaction-fee" },
            },
            [
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.accountBalance.transactionFee")
                  ),
                },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.payDetailVA.currency) +
                  "\n        " +
                  _vm._s(
                    _vm._f("formatCurrency")(
                      _vm.payDetailVA.feesPaidAmount,
                      _vm.payDetailVA.currency
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "wrap-border-grey-tertiary border-radius-card p-3" },
            [
              _c("p", {
                staticClass:
                  "font-size-18 font-roboto cj-color-black-primary font-weight-500 mb-3",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbCompletePayment.paymentMethod")
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("c-content-bank", {
                    attrs: {
                      payDetailVA: _vm.payDetailVA,
                      "data-cy": "payment-method-step-information",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex w-100 mt-3" },
            [
              _c(
                "c-button",
                {
                  staticClass:
                    "font-roboto font-size-16 font-weight-400 d-flex position-relative w-100",
                  attrs: {
                    fullWidth: "",
                    typeStyle: "secondary",
                    dataGTMCustom: "check-transaction",
                    "data-cy": "btn-check-transaction",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.checkTransaction.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", {
                    staticClass: "m-auto",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbCompletePayment.checkPaymentStatus")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.isFetchingTransaction
                    ? _c(
                        "div",
                        {
                          staticClass: "position-absolute",
                          staticStyle: {
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                          },
                          attrs: { "data-cy": "loading-transaction" },
                        },
                        [
                          _c("c-loader", {
                            staticClass: "d-flex m-auto",
                            attrs: {
                              show: _vm.isFetchingTransaction,
                              infinite: true,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }