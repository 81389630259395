import { ChtIsVideoImageType } from '@/shared/model/enumerations/cht-is-video-image-type.model';
import {Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CRmediaPictures extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop()
  public chtVwMedia;
  @Prop()
  public index;

  public seletedVideoImageType: ChtIsVideoImageType = ChtIsVideoImageType.IS_VIDEO;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public handleMediaPreview(item) {
    this.$emit('handleMediaPreview', item);
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  // ================= END COMPUTE ====================
}
