var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-attachment-preview position-relative d-flex flex-column",
    },
    [
      _c(
        "button",
        {
          staticClass: "position-absolute",
          staticStyle: { top: "0rem", right: "0rem", "z-index": "99" },
          attrs: { type: "button" },
          on: { click: _vm.cancel },
        },
        [
          _c("b-icon-x-circle-fill", {
            staticClass: "font-size-18 cj-color-grey-secondary",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vueper-slides",
        {
          staticClass:
            "no-shadow vueperslides my-auto custom-attachment-preview-slideshow",
          attrs: {
            infinite: _vm.vueperConfig.infinite,
            draggingDistance: _vm.vueperConfig.draggingDistance,
            slideRatio: _vm.vueperConfig.slideRatio,
          },
        },
        _vm._l(_vm.attachments, function (attachment, index) {
          return _c("vueper-slide", {
            key: index,
            staticClass: "cursor-pointer",
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      attachment.downloadLink
                        ? _c("div", { staticClass: "img-wrapper" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.$options.filters.LAZY(
                                  attachment.downloadLink,
                                  800,
                                  "ic-lazy-paw.png"
                                ),
                                onerror:
                                  _vm.$options.filters.LAZY_ERROR(
                                    "ic-lazy-paw.png"
                                  ),
                                alt: "image",
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }