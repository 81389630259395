import { Component, Vue, Prop } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class CPhWarning extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public isMobile: boolean;

  // ================= END VARIABLES ================

  // ================= START FUNCTION ================
  public closePhWarning() {
    this.$store.commit('isSetUsername', false);
  }

  public goToEditProfile() {
    (<any>this.$root).$emit('showPhWarning');

    this.$router.push({
      name: 'PProfile',
      query: { isSetUsername: 'true' },
    });
  }

  // ================= END FUNCTION ===================

  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
