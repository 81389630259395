var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-offer-detail",
      attrs: { "data-cy": "modal-offer-detail" },
    },
    [
      !_vm.isMobile
        ? [
            _c("div", { staticClass: "d-flex" }, [
              _vm.cbVwAuctionProjectPreOffer.status ==
              _vm.CbCommonStatus.WAITING
                ? _c(
                    "div",
                    {
                      staticClass: "cursor-pointer",
                      on: { click: _vm.deleteOffer },
                    },
                    [
                      _c("b-icon-trash", {
                        staticClass: "cj-color-red-primary font-size-16 mr-1",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mx-auto" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "font-size-24 text-center font-weight-500 cj-color-black-primary font-roboto text-break-all mx-5",
                    attrs: { "data-cy": "offer-title" },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cbVwAuctionProjectPreOffer.title) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-size-20 text-center font-weight-300 cj-color-black-primary font-roboto",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.cbVwAuctionProjectPreOffer.svcName) +
                        " "
                    ),
                    _c("span", [_vm._v("|")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.cbVwAuctionProjectPreOffer.catName) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "position-absolute",
                  staticStyle: { right: "0" },
                  attrs: { type: "button", "data-cy": "close-dialog" },
                  on: { click: _vm.closeAllDialog },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "card-content border-radius-card wrap-border-grey-tertiary p-3 my-3 text-break-all",
              },
              [
                _c("c-textarea-preview", {
                  staticClass:
                    "font-size-16 font-weight-300 font-catamaran cj-color-black-primary",
                  attrs: { text: _vm.cbVwAuctionProjectPreOffer.pitch },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex wrap-border-grey-tertiary border-radius-card p-5 my-3",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex m-auto" },
                  [
                    _c("icon", {
                      staticClass: "cj-fill-red-primary mr-2",
                      attrs: { name: "ic_time_outlined", width: "1.75rem" },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-size-18 cj-color-black-primary font-roboto font-weight-700",
                        attrs: { "data-cy": "offer-delivery-time" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.getDeliveryTimeType(
                                _vm.cbVwAuctionProjectPreOffer.deliveryTimeType,
                                _vm.cbVwAuctionProjectPreOffer.deliveryTime
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.cbVwAuctionProjectPreOffer.svcType === _vm.CbSvcType.INSIDE
                  ? _c(
                      "div",
                      { staticClass: "d-flex m-auto" },
                      [
                        _c("icon", {
                          staticClass: "cj-fill-red-primary mr-2",
                          attrs: { name: "ic_revision", width: "1.75rem" },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-size-18 cj-color-black-primary font-roboto font-weight-700",
                            attrs: { "data-cy": "offer-revision" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.cbVwAuctionProjectPreOffer.revisionCount
                                ) +
                                " " +
                                _vm._s(
                                  _vm.$t(
                                    _vm.getRevisionTextEnId(
                                      _vm.cbVwAuctionProjectPreOffer
                                        .revisionCount
                                    )
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "wrap-border-grey-tertiary p-4" }, [
              _vm.cbVwAuctionProjectPreOffer.priceType === "FIXED_PRICE"
                ? _c("p", {
                    staticClass:
                      "text-center font-size-14 cj-color-black-primary font-roboto font-weight-300",
                    attrs: { "data-cy": "offer-price-fixed" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cAuctionProject.fixedPrice")
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.cbVwAuctionProjectPreOffer.priceType === "PER_HOUR"
                ? _c("p", {
                    staticClass:
                      "text-center font-size-14 cj-color-black-primary font-roboto font-weight-300",
                    attrs: { "data-cy": "offer-price-hourly" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cAuctionProject.hourly")
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-center font-size-24 cj-color-black-primary font-roboto font-weight-700",
                  attrs: { "data-cy": "offer-price" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.cbVwAuctionProjectPreOffer.currency) +
                      "\n        " +
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.cbVwAuctionProjectPreOffer.budget,
                          _vm.cbVwAuctionProjectPreOffer.currency
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass:
                  "font-size-16 font-weight-300 font-roboto cj-color-black-primary text-right mt-3",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("formatCreateDatelabel")(
                        _vm.cbVwAuctionProjectPreOffer.createdDate
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isMobile
        ? _c("div", [
            _c("div", { staticClass: "d-flex mb-3" }, [
              _c("p", {
                staticClass:
                  "font-roboto flex-grow-1 font-weight-700 cj-color-red-primary font-size-14",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbComponent.auctionProjectRequest.yourOffer"
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseOfferDetail()
                    },
                  },
                },
                [
                  _c("b-icon-x-circle-fill", {
                    staticClass: "font-size-18 cj-color-grey-secondary",
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "card-content border-radius-card wrap-border-grey-tertiary p-3",
                staticStyle: { height: "12.5rem" },
              },
              [
                _c("c-textarea-preview", {
                  staticClass: "text-break-all",
                  attrs: { text: _vm.cbVwAuctionProjectPreOffer.pitch },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }