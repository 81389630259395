import axios from 'axios';

import { ICbVwPartnerBalance } from '@/shared/model/cb-vw-partner-balance.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-partner-balances';

export default class PCbVwPartnerBalanceService {
  public retrieveByLogin(): Promise<ICbVwPartnerBalance> {
    return new Promise<ICbVwPartnerBalance>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
