import { Component, Vue } from 'vue-property-decorator';
import * as watermark from 'watermarkjs';
import { PDFDocument } from 'pdf-lib';

import * as pdfjsLib from 'pdfjs-dist'; //error at safari 15, and cromium 77
// const pdfjsWorker='//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js'
// import pdfjsWorker from '@/../lib/pdfjs-2.3.200-dist/build/pdf.worker'; //error at safari 15, and cromium 77

const pdfjsWorker = process.env.CDN_CB + '/lib/pdfjs-2.3.200-dist/build/pdf.worker.js';

/**
 * An utility service for data.
 */
@Component
export default class ThumbnailWatermarkUtils extends Vue {
  public async generateVideoThumbnail(file: File, maxSize: number): Promise<string> {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas');
      const video = document.createElement('video');

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);
      video.onloadeddata = () => {
        const ctx = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        //resize
        if (canvas.width > canvas.height) {
          if (canvas.width > maxSize) {
            canvas.height *= maxSize / canvas.width;
            canvas.width = maxSize;
          }
        } else if (canvas.height > maxSize) {
          canvas.width *= maxSize / canvas.height;
          canvas.height = maxSize;
        }
        //end resize

        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        video.pause();

        setTimeout(() => {
          return resolve(canvas.toDataURL('image/png'));
        }, 100);
      };
    });
  }

  public async generatePdfThumbnail(file: File, maxSize?): Promise<string> {
    // //alert(URL.createObjectURL(file))
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const worker = new pdfjsLib.PDFWorker();
    const fileUrl = URL.createObjectURL(file);
    const pdf = await pdfjsLib.getDocument({ url: fileUrl, worker: worker }).promise.then(pdf => pdf);
    const page = await pdf.getPage(1);
    return new Promise(resolve => {
      return resolve(this.makeThumbPdf(page));
    });
  }

  public makeThumbPdf(page) {
    // draw page to fit into 96x96 canvas
    const baseWidth = 200;

    let viewport = page.getViewport({ scale: 1.0 });
    viewport = page.getViewport({ scale: baseWidth / viewport.width });

    const canvas = document.createElement('canvas');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    return page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport }).promise.then(function () {
      return canvas.toDataURL('image/png');
    });
  }

  public async generatePdfToImage(file: File, maxSize?) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const worker = new pdfjsLib.PDFWorker();
    const fileUrl = URL.createObjectURL(file);
    const pdf = await pdfjsLib.getDocument({ url: fileUrl, worker: worker }).promise.then(pdf => pdf);
    // MORE 1
    const images: any = [];
    for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
      await images.push(await this.processPdfToImage(await pdf.getPage(pageNumber)));
    }
    return new Promise<any>(resolve => {
      return resolve(images);
    });
  }

  // TODO buat fungsi generatePdfToImage yang return blob url string

  public processPdfToImage(page) {
    // draw page to fit into 96x96 canvas
    const baseWidth = 5000;

    let viewport = page.getViewport({ scale: 5.0 });
    viewport = page.getViewport({ scale: baseWidth / viewport.width });
    const canvas = document.createElement('canvas');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    return page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport }).promise.then(function () {
      return canvas.toDataURL('image/png');
    });
  }

  public async watermarkPdf(file: File, watermarkValue: string) {
    const watermarkUrl = process.env.CDN_CB + '/' + watermarkValue; //ganti pake lov
    const watermarkBytes = await fetch(watermarkUrl).then(res => res.arrayBuffer());
    const blobToArrybuffer = await new Response(file).arrayBuffer();
    const pdfDoc = await PDFDocument.load(blobToArrybuffer, { ignoreEncryption: true });
    const watermarkImage = await pdfDoc.embedPng(watermarkBytes);

    pdfDoc.getPages().forEach(page => {
      const { width, height } = page.getSize();

      page.drawImage(watermarkImage, {
        x: 0,
        y: 0,
        width: width,
        height: height,
        opacity: 0.2,
      });
    });
    const newPdfBytesArray = await pdfDoc.save();
    const blobWithWatermark = new Blob([newPdfBytesArray]);

    return blobWithWatermark;
    // file.blob=blobWithWatermark;
  }

  public async watermarkImage(fileBlob: Blob, watermarkValue: string, { width = 2048, height = 2048 }: { width: number; height: number }) {
    const watermarkUrl = process.env.CDN_CB + '/' + watermarkValue;

    let watermarkAdjustedUrl;
    let resizeBlob;

    await this.resizeImage(fileBlob, width, height)
      .then(({ blob, width, height }) => {
        resizeBlob = blob;
        watermarkAdjustedUrl = `${watermarkUrl}?width=${width}&height=${height}&mode=crop`;
      })
      .catch(error => {
        console.error('Error resizing image:', error);
      });

    // Continue with your watermarking process using watermarkAdjustedUrl
    const options = {
      init(img) {
        img.crossOrigin = 'anonymous';
      },
    };
    const mergeWithWatermarkResult = await watermark([resizeBlob, watermarkAdjustedUrl], options)
      .blob(watermark.image.center(1))
      .then(mergeWithWatermark => {
        return mergeWithWatermark;
      });
    return mergeWithWatermarkResult;
    // file.blob=mergeWithWatermarkResult;
  }

  public resizeImage(fileBlob, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculate the new dimensions while preserving aspect ratio
        let newWidth = img.width;
        let newHeight = img.height;
        if (img.width > maxWidth) {
          newWidth = maxWidth;
          newHeight = Math.round((img.height * maxWidth) / img.width);
        }
        if (newHeight > maxHeight) {
          newWidth = Math.round((newWidth * maxHeight) / newHeight);
          newHeight = maxHeight;
        }

        // Set the canvas dimensions
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Draw the image onto the canvas with the new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Get the resized image data from the canvas as a blob
        canvas.toBlob(blob => {
          resolve({ blob, width: newWidth, height: newHeight });
        }, fileBlob.type);
      };
      img.onerror = function () {
        reject(new Error('Failed to load the image.'));
      };
      img.src = URL.createObjectURL(fileBlob);
    });
  }
}
