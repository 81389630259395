// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';
import GlobalService from './services/global.service';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SocketService from './admin/socket/socket.service';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import '../content/scss/animation.scss';

// VUEPER ======================================================
import { VueperSlide, VueperSlides } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
// VUEPER ======================================================
// FULLCALENDAR =================================================
import '@fullcalendar/core/vdom'; // solves problem with Vite
// FULLCALENDAR =================================================
// VUE CALENDAR ===============================================
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
// VUE CALENDAR ===============================================
import TranslationService from '@/locale/translation.service';
import TimezoneService from '@/google-api/timezone.service';

import GatewayService from '@/admin/gateway/gateway.service';
import PUserService from './services/user.service';
/* tslint:disable */
// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here
/* tslint:enable */
import { VEmojiPicker } from 'v-emoji-picker';
import PCbUserInfoService from './services/cb-user-info.service';
import PCbOidService from './services/cb-oid.service';
import PCbLovService from './services/cb-lov.service';
import PCbUserDataService from './services/cb-user-data.service';
import PCbVwUserService from './services/cb-vw-user.service';
import PCbVwSvcService from './services/cb-vw-svc.service';
import PCbVwSvcCatService from './services/cb-vw-svc-cat.service';

import PCbTagService from './services/cb-tag.service';
import PCbToolService from './services/cb-tool.service';
import PCbUserCrtService from './services/cb-user-crt.service';
import PCbPawService from './services/cb-paw.service';
import SocketLiveAgentService from './admin/socket/socket-live-agent.service';
import PCbSvcCatPkgAggService from './services/cb-svc-cat-pkg-agg.service';
import PCbPawPortfolioService from './services/cb-paw-portfolio.service';
import PCbPawPortfolioAttService from './services/cb-paw-portfolio-att.service';
import PCbPawPortfolioDocService from './services/cb-paw-portfolio-doc.service';
import PCbVwPawService from './services/cb-vw-paw.service';
import PCbPawOrderService from './services/cb-paw-order.service';
import PPayService from './services/pay.service';
import PCbPawOrderExtraService from './services/cb-paw-order-extra.service';
import PCbPawOrderRequirementService from './services/cb-paw-order-requirement.service';
import PCbVwSvcPopularService from './services/cb-vw-svc-popular.service';
import CmTktService from './services/cm-tkt.service';
import PCmTktService from './services/cm-tkt.service';
import PCmVwTktService from './services/cm-vw-tkt.service';
import PCbAuctionProjectService from './services/cb-auction-project.service';
import PCbAuctionProjectAttService from './services/cb-auction-project-att.service';
import PCbVwAuctionProjectService from './services/cb-vw-auction-project.service';
import PCbLogService from './services/cb-log.service';
import PCbPawOrderFileService from './services/cb-paw-order-file.service';
import PCbTextNoAuthService from './services/cb-text-no-auth.service';
import PCbVwPawMetaValueService from './services/cb-vw-paw-meta-value.service';
import PCbVwPawCrtService from './services/cb-vw-paw-crt.service';
import PCbVwPawDetailAttService from './services/cb-vw-paw-detail-att.service';
import PCbAttachmentNoAuthService from './services/cb-attachment-no-auth.service';
import PCbVwPawOrderService from './services/cb-vw-paw-order.service';
import PCbUserLanguageService from './services/cb-user-language.service';
import PCbQuoteService from './services/cb-quote.service';
import PCbVwSearchAutocompleteService from './services/cb-vw-search-autocomplete.service';
import PCbVwUserReviewService from './services/cb-vw-user-review.service';
import PCbAuctionProjectPreOfferService from './services/cb-auction-project-pre-offer.service';
import PCbUserSkillService from './services/cb-user-skill.service';
import PCbUserEducationService from './services/cb-user-education.service';
import PCbVwAuctionProjectPreOfferService from './services/cb-vw-auction-project-pre-offer.service';
import PCbVwMedalService from './services/cb-vw-medal.service';
import PCbPawOrderTrackService from './services/cb-paw-order-track.service';
import PCbPawPreOrderService from './services/cb-paw-pre-order.service';
import VueSocialSharing from 'vue-social-sharing';
import PCbUserReviewService from './services/cb-user-review.service';
import PCbVwPawOrderRequirementService from './services/cb-vw-paw-order-requirement.service';
import PCbTextRestrictService from './services/cb-text-restrict.service';
import PCbVwPartnerBalanceService from './services/cb-vw-partner-balance.service';
import PCbVwBuyerBalanceService from './services/cb-vw-buyer-balance.service';
import PCbTopupService from './services/cb-topup.service';
import PCbWithdrawService from './services/cb-withdraw.service';
import PCbUserOffDutyService from './services/cb-user-off-duty.service';
import PCbVwUserScheduleService from './services/cb-vw-user-schedule.service';
import PCbVwLocationService from './services/cb-vw-location.service';
import PCbBankAccountService from './services/cb-bank-account.service';
import PCbPawOrderMilestoneService from './services/cb-paw-order-milestone.service';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import PCbVwUserDataService from './services/cb-vw-user-data.service';
import PCbEducationService from './services/cb-education.service';
import PCbVwUserFollowService from './services/cb-vw-user-follow.service';
import PCbUserFollowService from './services/cb-user-follow.service';
import PCbUserBookmarkAggService from './services/cb-user-bookmark-agg.service';
import PCbVwStItemUseService from './services/cb-vw-st-item-use.service';
import PCbVwBillingHistoryService from './services/cb-vw-billing-history.service';
import PCbVwSpOrderService from './services/cb-vw-sp-order.service';
import PCbVwUserCrtService from './services/cb-vw-user-crt.service';
import PCbPromoUserAggService from './services/cb-promo-user-agg.service';
import PCbVwPromoUserAggService from './services/cb-vw-promo-user-agg.service';
import PChtService from './services/cht.service';
import ChtFileService from './services/cht-file.service';
import ChtStarService from './services/cht-star.service';
import ChtReactionService from './services/cht-reaction.service';
import ChtMessageDeleteService from './services/cht-message-delete.service';
import ChtMessageSeenService from './services/cht-message-seen.service';
import PCbUserPinService from './services/cb-user-pin.service';
import PCbVwOrderStatisticService from './services/cb-vw-order-statistic.service';
import PCmVwContactReasonDataService from './services/cm-vw-contact-reason-data.service';
import PCmTktAttService from './services/cm-tkt-att.service';
import PCmTktTextService from './services/cm-tkt-text.service';
import PCmVwTktStatusGeneralService from './services/cm-vw-tkt-status-general.service';
import PCmAttachmentRestrictService from './services/cm-attachment-restrict.service';
import PCbVwHelpCenterService from './services/cb-vw-help-center.service';
import PCbVwSvcCatMetadataAggService from './services/cb-vw-svc-cat-metadata-agg.service';
import PCbHelpCenterFeedbackService from './services/cb-help-center-feedback.service';
import PCbHelpCenterViewService from './services/cb-help-center-view.service';
import PCbVwHelpCenterFeedbackService from './services/cb-vw-help-center-feedback.service';
import PCbAnnouncementService from './services/cb-announcement.service';
import PCbVwStItemPkgService from './services/cb-vw-st-item-pkg.service';
import PCbVwPawOrderRequirementActiveService from './services/cb-vw-paw-order-requirement-active.service';
import PCbUserCookieService from './services/cb-user-cookie.service';
import PNtUserNotifService from './services/nt-user-notif.service';
import PNtVwUserNotifService from './services/nt-vw-user-notif.service';
import PNtLovService from './services/nt-lov.service';
import PNtUserNotifSettingService from './services/nt-user-notif-setting.serivce';
import PCbVwUserCoreService from './services/cb-vw-user-core.service';
import PRptKpiMetaTableService from './services/rpt-kpi-meta-table.service';
import PCmSopInputedAggService from './services/cm-sop-inputed-agg.services';
import PCmVwSopService from './services/cm-vw-sop.service';
import PCbAccountService from './services/cb-account.service';
import PCbVwBankAccountService from './services/cb-vw-bank-account.service';
import PCbUserEmergencyContactService from './services/cb-user-emergency-contact.service';
import PCbUserSocialMediaService from './services/cb-user-social-media.service';
import PCbVwUserActivationService from './services/cb-vw-user-actiivation.service';
import PCbVwUserStatusService from './services/cb-vw-user-status.service';
import ContentService from './services/content.service';
import PCmVwSopInputedAggReqConfirmService from './services/cm-vw-sop-inputed-agg-req-confirm.service';

import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

import { Swiper, SwiperCore, SwiperSlide } from 'swiper-vue2';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import CPopupBottom from '@/components/c-popup-bottom/c-popup-bottom.vue';
import CFormAuctionProjectPitch from '@/components/c-form-auction-project-pitch/c-form-auction-project-pitch.vue';
import PCbVwPromoService from './services/cb-vw-promo.service';
import PCmVwSopInputedAggService from './services/cm-vw-sop-inputed-agg.service';
import PCmAttachmentAuthService from './services/cm-attachment-auth.service';
import PCbUserTimezoneService from './services/cb-user-timezone.service';
import PCbUserAccessoryService from './services/cb-user-accessory.service';
import PCmTextAuthService from './services/cm-text-auth.service';
import PCbSetPhoneService from './services/cb-set-phone.service';
import ChtVwMediaService from './services/cht-vw-media.service';
import PCbVwUserPartnerRatingSvcService from './services/cb-vw-user-partner-rating-svc.service';
import PCbVwLogPawRelatedService from './services/cb-vw-log-paw-related.service';
import PCbVwSvcCatPopularService from './services/cb-vw-svc-cat-popular.service';
import PCbVwAvgResponseTimeService from './services/cb-vw-avg-response-time.service';

// FIREBASE Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import PCbVwUserBalanceSummaryService from './services/cb-vw-user-balance-summary.service';
import PCbVwBillingHistoryTopupService from './services/cb-vw-billing-history-topup.service';
import PCbVwBillingHistoryIncomeService from './services/cb-vw-billing-history-income.service';
import PCbVwBillingHistoryPaymentService from './services/cb-vw-billing-history-payment.service';
import PCbVwBillingHistoryRefundService from './services/cb-vw-billing-history-refund.service';
import PCbVwBillingHistoryWithdrawService from './services/cb-vw-billing-history-withdraw.service';
import PRptMetaTableService from './services/rpt-meta-table.service';
import PCbVwUserSummaryService from './services/cb-vw-user-summary.service';
import PCbVwUserPartnerLevelService from './services/cb-vw-user-partner-level.service';
import { SESSION_STORAGE_BEFORE_REQUESTED_URL, SESSION_STORAGE_REQUESTED_URL } from './shared/constant/constants-session-storage';
import PCbVwAuctionProjectRecommendationService from './services/cb-vw-auction-project-recommendation.service';
import PCbVwAuctionProjectWishlistService from './services/cb-vw-auction-project-wishlist.service';
import PCbVwPawPkgAggService from './services/cb-vw-paw-pkg-agg.service';
import PCbVwPawPkgAggTypeService from './services/cb-vw-paw-pkg-agg-type.service';
import PCbVwPawNameService from './services/cb-vw-paw-name.service';
import PWfVwWorkflowService from './services/wf-vw-workflow.service';
import PCbVwBillboardService from './services/cb-vw-billboard.service';
import PCbVwUserConnectionService from './services/cb-vw-user-connection.service';
import PCbVwPawPopularService from './services/cb-vw-paw-popular.service';
import PCbBannerService from './services/cb-banner.service';
import PCbPersonalizeInterestsService from './services/cb-personalize-interests.service';
import PCbAttachmentRestricts from './services/cb-attachment-restricts.service';
import PCbVwUserSummaryPartnerCountService from '@/services/cb-vw-user-summary-partner-count.service';

// Import DatePicker Style
import 'vue2-datepicker/index.css';

gsap.registerPlugin(ScrollTrigger);

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  },
});

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'vue_error',
    err: err,
    info: info,
    errorClass: vm.$el.classList,
  });
};

config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(VueSocialSharing);
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

Vue.component('vueper-slides', VueperSlides);
Vue.component('vueper-slide', VueperSlide);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('v-emoji-picker', VEmojiPicker);
Vue.component('v-date-picker', DatePicker);
Vue.component('swiper', Swiper);
Vue.component('swiperSlide', SwiperSlide);

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
///change to env
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
const appFirebase = initializeApp(firebaseConfig);
getAnalytics(appFirebase);
// END FIREBASE

Vue.component('c-popup-buttom', CPopupBottom);
Vue.component('c-form-auction-project-pitch', CFormAuctionProjectPitch);

const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);
const cookie = (<any>Vue).cookie;
const translationService = new TranslationService(store, i18n);
const currentLanguage =
  localStorage.getItem('currentLanguage') && localStorage.getItem('currentLanguage') != 'in'
    ? localStorage.getItem('currentLanguage')
    : 'id';
translationService.refreshTranslation(currentLanguage);

const loginService = new LoginService();
const pCbVwUserService = new PCbVwUserService();
const pCbOidService = new PCbOidService();
const pCbVwUserPartnerLevelService = new PCbVwUserPartnerLevelService();
const accountService = new AccountService(
  store,
  translationService,
  cookie,
  router,
  pCbVwUserService,
  pCbOidService,
  pCbVwUserPartnerLevelService
);
const socketService = new SocketService(router, store, accountService);
const globalService = new GlobalService(store, accountService, router);
globalService.socketService = socketService;
socketService.globalService = globalService;
const socketLiveAgentService = new SocketLiveAgentService(router, store, globalService);

const contentService = new ContentService(
  store,
  new PCbTextNoAuthService(),
  new PCbVwHelpCenterService(),
  new PCbLovService(),
  new PCbVwLocationService(),
  new PCbEducationService(),
  new PCbVwSvcService(),
  new PCbVwSvcCatService(),
  new PCbVwSvcCatPopularService(),
  new PCbVwSvcPopularService(),
  new PCbUserBookmarkAggService()
);
// move here to avoid authorize

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;

    if (status === 401) {
      //open login modal when click chat on detail paw
      if (url.endsWith('api/cb-paw-pre-orders')) {
        sessionStorage.setItem(SESSION_STORAGE_REQUESTED_URL, router.currentRoute.path); // to handle if not authorize when chat to user
        globalService.openGlobalDialog(vueObj, 'modal-c-login');
      }
    }

    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    return Promise.reject(error);
  },
  globalService
);

//must used to ignore catch
//sessionStorage.removeItem(SESSION_STORAGE_REQUESTED_URL);

let isFirstLoginForceRetrieveAccountToCheckSessionAccount = true;

router.beforeResolve(async (to, from, next) => {
  console.log('route:: {}', to.name);

  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    if (store.getters.authenticated && isFirstLoginForceRetrieveAccountToCheckSessionAccount) {
      // await globalService.checkRefreshTokeFirstOpenPage();
      await accountService.refreshAccount();
      isFirstLoginForceRetrieveAccountToCheckSessionAccount = false;
    }
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities, isFirstLoginForceRetrieveAccountToCheckSessionAccount).then(value => {
      // isFirstLoginForceRetrieveAccountToCheckSessionAccount = false;
      if (!value) {
        sessionStorage.setItem(SESSION_STORAGE_BEFORE_REQUESTED_URL, from.fullPath);
        sessionStorage.setItem(SESSION_STORAGE_REQUESTED_URL, to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else if (to.fullPath.includes('/?code=')) {
    //bug fixing linked account, call account and logout, and remove storage
    next();
  } else {
    console.log('route:: {}  > {}', to.name, to.path);
    accountService
      .isLogin(isFirstLoginForceRetrieveAccountToCheckSessionAccount)
      .then(value => {
        isFirstLoginForceRetrieveAccountToCheckSessionAccount = false;
        if (value) {
          if (
            store.getters.hasRoleCbPartner &&
            store.getters.loginType == CbLoginType.PARTNER &&
            (to.path === '/general' || to.path === '/' || to.path === '/general/p-login-m' || to.path === '/general/p-register-m') &&
            !sessionStorage.getItem(SESSION_STORAGE_REQUESTED_URL)
          ) {
            console.log('route::  free');
            next({ path: '/freelancer', replace: true });
          } else if (
            store.getters.hasRoleCbUser &&
            store.getters.loginType == CbLoginType.BUYER &&
            (to.path === '/general' || to.path === '/' || to.path === '/general/p-login-m' || to.path === '/general/p-register-m') &&
            !sessionStorage.getItem(SESSION_STORAGE_REQUESTED_URL)
          ) {
            next({ path: '/buyer', replace: true });
          } else {
            next();
          }
        } else {
          next();
        }
      })
      .catch(() => {
        next();
      });
  }
});

router.afterEach((to, from) => {
  // Your code here
  console.log('route:: {} > completed', to.name);

  vueObj.$emit('watchRoute', from, to);
  globalService.setIsPathNeedSignal(from, to);
  globalService.currentNavigationController.abort();
  globalService.currentNavigationController = new AbortController();
});

Vue.prototype.$env = process.env;

/* tslint:disable */
const vueObj = new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,

    gatewayService: () => new GatewayService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    socketService: () => socketService,
    socketLiveAgentService: () => socketLiveAgentService,
    cmTktService: () => new CmTktService(),
    pCmVwTktService: () => new PCmVwTktService(),

    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),

    globalService: () => globalService,
    pUserService: () => new PUserService(),
    pCbUserInfoService: () => new PCbUserInfoService(),
    pCbOidService: () => new PCbOidService(),
    pCbLovService: () => new PCbLovService(),
    pCbUserDataService: () => new PCbUserDataService(),
    pCbVwUserService: () => pCbVwUserService,
    pChtService: () => new PChtService(),
    chtFileService: () => new ChtFileService(),
    chtStarService: () => new ChtStarService(),
    chtReactionService: () => new ChtReactionService(),
    chtMessageDeleteService: () => new ChtMessageDeleteService(),
    chtMessageSeenService: () => new ChtMessageSeenService(),
    ChtVwMediaService: () => new ChtVwMediaService(),
    pCbVwSvcService: () => new PCbVwSvcService(),
    pCbLogService: () => new PCbLogService(),
    pCbVwSvcCatService: () => new PCbVwSvcCatService(),
    pCbTagService: () => new PCbTagService(),
    pCbToolService: () => new PCbToolService(),
    pCbUserCrtService: () => new PCbUserCrtService(),
    pCbPawService: () => new PCbPawService(),
    pCbSvcCatPkgAggService: () => new PCbSvcCatPkgAggService(),
    pCbPawPortfolioService: () => new PCbPawPortfolioService(),
    pCbPawPortfolioAttService: () => new PCbPawPortfolioAttService(),
    pCbPawPortfolioDocService: () => new PCbPawPortfolioDocService(),
    pCbVwPawService: () => new PCbVwPawService(),
    pCbPawOrderService: () => new PCbPawOrderService(),
    pPayService: () => new PPayService(),
    pCbPawOrderExtraService: () => new PCbPawOrderExtraService(),
    pCbPawOrderRequirementService: () => new PCbPawOrderRequirementService(),
    pCbAuctionProjectService: () => new PCbAuctionProjectService(),
    pCbAuctionProjectAttService: () => new PCbAuctionProjectAttService(),
    pCbVwAuctionProjectService: () => new PCbVwAuctionProjectService(),
    pCbPawOrderFileService: () => new PCbPawOrderFileService(),
    pCbVwSvcPopularService: () => new PCbVwSvcPopularService(),
    pCbTextNoAuthService: () => new PCbTextNoAuthService(),
    pCbVwPawMetaValueService: () => new PCbVwPawMetaValueService(),
    pCbVwPawCrtService: () => new PCbVwPawCrtService(),
    pCbVwPawDetailAttService: () => new PCbVwPawDetailAttService(),
    pCbAttachmentNoAuthService: () => new PCbAttachmentNoAuthService(),
    pCbVwPawOrderService: () => new PCbVwPawOrderService(),
    pCbUserLanguageService: () => new PCbUserLanguageService(),
    pCbQuoteService: () => new PCbQuoteService(),
    pCbVwSearchAutocompleteService: () => new PCbVwSearchAutocompleteService(),
    pCbVwUserReviewService: () => new PCbVwUserReviewService(),
    pCbAuctionProjectPreOfferService: () => new PCbAuctionProjectPreOfferService(),
    pCbUserSkillService: () => new PCbUserSkillService(),
    pCbUserEducationService: () => new PCbUserEducationService(),
    pCbVwAuctionProjectPreOfferService: () => new PCbVwAuctionProjectPreOfferService(),
    pCbVwMedalService: () => new PCbVwMedalService(),
    pCbPawOrderTrackService: () => new PCbPawOrderTrackService(),
    pCbPawPreOrderService: () => new PCbPawPreOrderService(),
    pCbUserReviewService: () => new PCbUserReviewService(),
    pCbVwPawOrderRequirementService: () => new PCbVwPawOrderRequirementService(),
    pCbTextRestrictService: () => new PCbTextRestrictService(),
    pCbVwPartnerBalanceService: () => new PCbVwPartnerBalanceService(),
    pCbVwBuyerBalanceService: () => new PCbVwBuyerBalanceService(),
    pCbTopupService: () => new PCbTopupService(),
    pCbWithdrawService: () => new PCbWithdrawService(),
    pCbUserOffDutyService: () => new PCbUserOffDutyService(),
    pCbVwUserScheduleService: () => new PCbVwUserScheduleService(),
    pCbVwLocationService: () => new PCbVwLocationService(),
    pCbBankAccountService: () => new PCbBankAccountService(),
    pCbPawOrderMilestoneService: () => new PCbPawOrderMilestoneService(),
    pCbVwUserDataService: () => new PCbVwUserDataService(),
    pCbEducationService: () => new PCbEducationService(),
    pCbVwUserFollowService: () => new PCbVwUserFollowService(),
    pCbUserFollowService: () => new PCbUserFollowService(),
    pCbUserBookmarkAggService: () => new PCbUserBookmarkAggService(),
    pCbVwStItemUseService: () => new PCbVwStItemUseService(),
    pCbVwBillingHistoryService: () => new PCbVwBillingHistoryService(),
    pCbVwBillingHistoryTopupService: () => new PCbVwBillingHistoryTopupService(),
    pCbVwPromoService: () => new PCbVwPromoService(),
    pCbVwSpOrderService: () => new PCbVwSpOrderService(),
    pCbVwUserCrtService: () => new PCbVwUserCrtService(),
    pCbPromoUserAggService: () => new PCbPromoUserAggService(),
    pCbVwPromoUserAggService: () => new PCbVwPromoUserAggService(),
    pCbUserPinService: () => new PCbUserPinService(),
    pCmTktService: () => new PCmTktService(),
    pCmVwSopInputedAggService: () => new PCmVwSopInputedAggService(),
    pCmAttachmentAuthService: () => new PCmAttachmentAuthService(),
    pCbAttachmentRestricts: () => new PCbAttachmentRestricts(),
    pCbUserTimezoneService: () => new PCbUserTimezoneService(),
    pCbUserAccessoryService: () => new PCbUserAccessoryService(),
    pCmTextAuthService: () => new PCmTextAuthService(),
    pCbVwOrderStatisticService: () => new PCbVwOrderStatisticService(),
    pCmVwContactReasonDataService: () => new PCmVwContactReasonDataService(),
    pCmTktAttService: () => new PCmTktAttService(),
    pCbSetPhoneService: () => new PCbSetPhoneService(),
    pCmTktTextService: () => new PCmTktTextService(),
    pCmVwTktStatusGeneralService: () => new PCmVwTktStatusGeneralService(),
    pCmAttachmentRestrictService: () => new PCmAttachmentRestrictService(),
    pCbVwHelpCenterService: () => new PCbVwHelpCenterService(),
    pCbVwSvcCatMetadataAggService: () => new PCbVwSvcCatMetadataAggService(),
    pCbHelpCenterFeedbackService: () => new PCbHelpCenterFeedbackService(),
    pCbHelpCenterViewService: () => new PCbHelpCenterViewService(),
    pCbVwHelpCenterFeedbackService: () => new PCbVwHelpCenterFeedbackService(),
    pCbAnnouncementService: () => new PCbAnnouncementService(),
    pCbVwStItemPkgService: () => new PCbVwStItemPkgService(),
    pCbVwPawOrderRequirementActiveService: () => new PCbVwPawOrderRequirementActiveService(),
    pCbUserCookieService: () => new PCbUserCookieService(),
    pNtUserNotifService: () => new PNtUserNotifService(),
    pNtVwUserNotifService: () => new PNtVwUserNotifService(),
    pNtLovService: () => new PNtLovService(),
    pNtUserNotifSettingService: () => new PNtUserNotifSettingService(),
    pCbVwUserPartnerRatingSvcService: () => new PCbVwUserPartnerRatingSvcService(),
    pCbVwUserCoreService: () => new PCbVwUserCoreService(),
    pCbVwLogPawRelatedService: () => new PCbVwLogPawRelatedService(),
    pCbVwSvcCatPopularService: () => new PCbVwSvcCatPopularService(),
    pRptKpiMetaTableService: () => new PRptKpiMetaTableService(),
    pCmSopInputedAggService: () => new PCmSopInputedAggService(),
    pCmVwSopService: () => new PCmVwSopService(),
    pCbAccountService: () => new PCbAccountService(),
    pCbVwBankAccountService: () => new PCbVwBankAccountService(),
    pCbUserEmergencyContactService: () => new PCbUserEmergencyContactService(),
    pCbUserSocialMediaService: () => new PCbUserSocialMediaService(),
    pCbVwUserBalanceSummaryService: () => new PCbVwUserBalanceSummaryService(),
    pCbVwBillingHistoryIncomeService: () => new PCbVwBillingHistoryIncomeService(),
    pCbVwBillingHistoryPaymentService: () => new PCbVwBillingHistoryPaymentService(),
    pCbVwBillingHistoryRefundService: () => new PCbVwBillingHistoryRefundService(),
    pCbVwBillingHistoryWithdrawService: () => new PCbVwBillingHistoryWithdrawService(),
    pRptMetaTableService: () => new PRptMetaTableService(),
    pCbVwUserActivationService: () => new PCbVwUserActivationService(),
    pCbVwUserStatusService: () => new PCbVwUserStatusService(),
    pCbVwUserSummaryService: () => new PCbVwUserSummaryService(),
    pCbVwAvgResponseTimeService: () => new PCbVwAvgResponseTimeService(),
    pCbVwUserPartnerLevelService: () => new PCbVwUserPartnerLevelService(),
    contentService: () => contentService,
    pCbVwAuctionProjectRecommendationService: () => new PCbVwAuctionProjectRecommendationService(),
    pCbVwAuctionProjectWishlistService: () => new PCbVwAuctionProjectWishlistService(),
    pCbVwPawPkgAggService: () => new PCbVwPawPkgAggService(),
    pCbVwPawPkgAggTypeService: () => new PCbVwPawPkgAggTypeService(),
    pCbVwPawNameService: () => new PCbVwPawNameService(),
    pWfVwWorkflowService: () => new PWfVwWorkflowService(),
    timezoneService: () => new TimezoneService(),
    pCbVwBillboardService: () => new PCbVwBillboardService(),
    pCbVwUserConnectionService: () => new PCbVwUserConnectionService(),
    pCbVwPawPopularService: () => new PCbVwPawPopularService(),
    pCbBannerService: () => new PCbBannerService(),
    pCmVwSopInputedAggReqConfirmService: () => new PCmVwSopInputedAggReqConfirmService(),
    pCbPersonalizeInterestsService: () => new PCbPersonalizeInterestsService(),
    pCbVwUserSummaryServiceBuyerExt: () => new PCbVwUserSummaryPartnerCountService(),
  },
  i18n,
  store,
});
