import axios from 'axios';

import { ICbPawPortfolio } from '@/shared/model/cb-paw-portfolio.model';

const baseApiUrl = 'services/cbcorems/api/cb-paw-portfolios';

export default class PCbPawPortfolioService {
  public retrieve(params: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteByCriteria(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbPawPortfolio): Promise<ICbPawPortfolio> {
    return new Promise<ICbPawPortfolio>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICbPawPortfolio): Promise<ICbPawPortfolio> {
    return new Promise<ICbPawPortfolio>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
