export enum CbHelpCenterType {
  HELP_CENTER = 'HELP_CENTER',

  TERM_OF_SERVICE = 'TERM_OF_SERVICE',

  PRIVACY_POLICY = 'PRIVACY_POLICY',

  TERM_OF_PAYMENT = 'TERM_OF_PAYMENT',

  INTELLECTUAL_PROPERTY_CLAIM = 'INTELLECTUAL_PROPERTY_CLAIM',

  COOKIE_POLICY = 'COOKIE_POLICY',

  COMMUNITY_GUIDELINES = 'COMMUNITY_GUIDELINES',
}
