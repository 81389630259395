import { Component, Vue, Prop } from 'vue-property-decorator';

export interface ICBreadcrumb {
  text?: any;
  href?: ICRouter;
  active?: boolean;
  isVariable?: boolean;
}

export interface ICRouter {
  name?: string;
  path?: string;
  params?: any;
}

export class CBreadCrumb implements ICBreadcrumb {
  constructor(public text?: any, public href?: ICRouter, public active?: boolean,  isVariable?: boolean) {}
}
export class CRouter implements ICRouter {
  constructor(public name?: string, public path?: string, public params?: any) {}
}

@Component({})
export default class CBreadcrumb extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Prop() paths: CBreadCrumb[];
  public toNavigate(path) {
    if (path.href) {
      this.$router.push({ name: path.href.name, params: path.href.params, path: path.href.path });
    } else if (path.isVariable) {
      this.$emit('callBack');
    }
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
