export interface IPayDetailVA {
  id?: string | null;
  externalId?: string | null;
  accountNumber?: string | null;
  bankCode?: string | null;
  name?: string | null;
  expirationDate?: Date | null;
  status?: string | null;
  expectedAmount?: number | null;
  currency?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  feesPaidAmount?: number | null;
}

export class PayDetailVA implements IPayDetailVA {
  constructor(
    public id?: string | null,
    public externalId?: string | null,
    public accountNumber?: string | null,
    public bankCode?: string | null,
    public name?: string | null,
    public expirationDate?: Date | null,
    public status?: string | null,
    public expectedAmount?: number | null,
    public currency?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public feesPaidAmount?: number | null
  ) {}
}
