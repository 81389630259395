import { Component, Prop, Vue } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CUploadFileIcon from '@/components/c-upload-file/c-upload-file-icon/c-upload-file-icon.vue';

import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';
import { ICbAttachmentRestrict } from '@/shared/model/cb-attachment-restrict.model';

import JhiDataUtils from '@/shared/data/data-utils.service';

@Component({
  components: {
    Icon,
    CUploadFileIcon,
  },
  mixins: [JhiDataUtils],
})
export default class COrderPdItem extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================

  // ================= START VARIABLES ================
  @Prop({ default: null }) public fileName: string;
  @Prop({ default: null }) public dataCyCustom: string;
  @Prop({ default: null }) public fileAtt: ICbAttachmentRestrict;

  public deviceSizeType = DeviceSizeType;
  // ================= END VARIABLES ==================

  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========

  // ================= START FUNCTION =================
  public handleDownloadFileDelivery(cbAttachmentRestrict: ICbAttachmentRestrict) {
    if (cbAttachmentRestrict.localUrl) {
      window.open(cbAttachmentRestrict.localUrl, '_blank');
    }
  }
  // ================= END FUNCTION ===================

  // ================= START COMPUTE ==================
  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  // ================= END COMPUTE ====================

  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
