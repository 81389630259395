export interface IPayVwTransactionSummaries {
  id?: string;
  avgSellingPrice?: number | null;
  avgSpendingTransaction?: number | null;
  countOrderCompleted?: number | null;
  countPurchase?: number | null;
  countRevenue?: number | null;
  countSelling?: number | null;
  countSpent?: number | null;
  countThisMonthPurchase?: number | null;
  countThisMonthRevenue?: number | null;
  countThisMonthSales?: number | null;
  countThisMonthSpending?: number | null;
  externalUserId?: string | null;
}

export class PayVwTransactionSummaries implements IPayVwTransactionSummaries {
  constructor(
    public id?: string,
    public avgSellingPrice?: number | null,
    public avgSpendingTransaction?: number | null,
    public countOrderCompleted?: number | null,
    public countPurchase?: number | null,
    public countRevenue?: number | null,
    public countSelling?: number | null,
    public countSpent?: number | null,
    public countThisMonthPurchase?: number | null,
    public countThisMonthRevenue?: number | null,
    public countThisMonthSales?: number | null,
    public countThisMonthSpending?: number | null,
    public externalUserId?: string | null
  ) {}
}
