export const orderActivityId = {
  sendOffer: 'Freelancer mengirim penawaran',
  offerExpired: 'Penawaran telah berakhir',
  offerPaidAndOrderStarted: 'Penawaran dibayar dan pengerjaan pemesanan dimulai',
  newNoteRequested: 'Note baru diajukan',
  newNoteDeclined: 'Pengajuan note ditolak',
  newNoteAccepted: 'Pengajuan note disetujui',
  newNotePaid: 'Pengajuan note dibayar',
  newAppointmentRequested: 'Janji temu baru diajukan',
  appointmentRequestDeclined: 'Pengajuan janji temu ditolak',
  appointmentRequestAccepted: 'Pengajuan janji temu diterima',
  freelancerUpdatedMilestone: 'Freelancer mengupdate 50% milestone',
  freelancerSentProjectDelivery: 'Freelancer mengirim project delivery',
  buyerRequestedForRevision: 'Buyer mengajukan revisi',
  buyerRequestedForExtraRevision: 'Buyer mengajukan revisi tambahan',
  freelancerDeclinedExtraRevisionRequest: 'Freelancer menolak ajuan revisi tambahan',
  freelancerRequestPaymentForExtraRevision: 'Freelancer meminta pembayaran untuk revisi tambahan',
  buyerDeclinedPaymentRequestForExtraRevision: 'Buyer menolak pembayaran untuk revisi tambahan',
  buyerPaidForExtraRevision: 'Buyer telah membayar untuk revisi tambahan',
  buyerAcceptedOfferedExtraRevision: 'Buyer menyetujui extra revisi yang ditawarkan',
  buyerDeclinedOfferedExtraRevision: 'Buyer menolak extra revisi yang ditawarkan',
  buyerAcceptedProjectDeliveryOrderCompleted: 'Buyer menerima project delivery. Order selesai',
  projectDeliveryReachedAutoAcceptanceLeadTimeOrderCompleted:
    'Project delivery sudah mencapai batas waktu auto acceptance lead time. Order selesai',
  orderDisputedYourOrderIsUnderOurReview: 'Order disputed, order Anda sedang dalam proses peninjauan',
  disputeRequestAcceptedAndOrderCancelled: 'Pengajuan dispute disetujui dan order ini dibatalkan',
  disputeRequestDeclinedAndOrderContinued: 'Pengajuan dispute ditolak dan order dilanjutkan kembali',
  orderReachedProjectDeliveryTimeLimit: 'Order mencapai batas waktu pengiriman proyek',
  buyerCancelOverdueOrder: 'Buyer membatalkan pesanan',
  buyerExtendProjectDeliveryTime: 'Buyer menambahkan waktu pengiriman proyek',
  offerPaidAndOrderBooked: 'Penawaran dibayar dan pemesanan telah diterbitkan',
  freelancerSentAdditionalServiceRequest: 'Freelancer mengajukan layanan tambahan',
  buyerDeclinedAdditionalServiceRequest: 'Buyer menolak pengajuan layanan tambahan',
  buyerAcceptedAdditionalServiceRequest: 'Buyer menyetujui pengajuan layanan tambahan',
  buyerPaidAdditionalServiceRequest: 'Buyer membayar pengajuan layanan tambahan',
  freelancerIsLate: 'Freelancer terlambat',
  buyerCancelLateOrder: 'Buyer membatalkan pesanan',
  freelancerIsOnTheWayToWorkLocation: 'Freelancer sedang dalam perjalanan ke lokasi kerja',
  freelancerArrivedAtWorkLocationAndServiceStarted: 'Freelancer sampai di lokasi kerja dan layanan dimulai',
  serviceCompleted: 'Layanan selesai',
  orderCompleted: 'Order selesai',
};

export const orderActivityEn = {
  sendOffer: 'Freelancer sent offer',
  offerExpired: 'Offer expired',
  offerPaidAndOrderStarted: 'Offer paid and order started',
  newNoteRequested: 'New note requested',
  newNoteDeclined: 'Note request declined',
  newNoteAccepted: 'Note request accepted',
  newNotePaid: 'Note request paid',
  newAppointmentRequested: 'New appointment requested',
  appointmentRequestDeclined: 'Appointment request declined',
  freelancerUpdatedMilestone: 'Freelancer updated 50% milestone',
  freelancerSentProjectDelivery: 'Freelancer sent project delivery',
  buyerRequestedForRevision: 'Buyer requested for revision',
  buyerRequestedForExtraRevision: 'Buyer requested for extra revision',
  freelancerDeclinedExtraRevisionRequest: 'Freelancer declined extra revision request',
  freelancerRequestPaymentForExtraRevision: 'Freelancer request payment for extra revision',
  buyerDeclinedPaymentRequestForExtraRevision: 'Buyer declined payment request for extra revision',
  buyerPaidForExtraRevision: 'Buyer paid for extra revision',
  buyerAcceptedOfferedExtraRevision: 'Buyer accepted offered extra revision',
  buyerDeclinedOfferedExtraRevision: 'Buyer declined offered extra revision',
  buyerAcceptedProjectDeliveryOrderCompleted: 'Buyer accepted project delivery. Order completed',
  projectDeliveryReachedAutoAcceptanceLeadTimeOrderCompleted: 'Project delivery reached auto acceptance lead time. Order completed',
  orderDisputedYourOrderIsUnderOurReview: 'Order disputed, your order is under our review',
  disputeRequestAcceptedAndOrderCancelled: 'Dispute request accepted and order cancelled',
  disputeRequestDeclinedAndOrderContinued: 'Dispute request declined and order continued',
  orderReachedProjectDeliveryTimeLimit: 'Order reached project delivery time limit',
  buyerCancelOverdueOrder: 'Buyer cancelled the order',
  buyerExtendProjectDeliveryTime: 'Buyer extend project delivery time',
  offerPaidAndOrderBooked: 'Offer paid and order booked',
  freelancerSentAdditionalServiceRequest: 'Freelancer sent additional service request',
  buyerDeclinedAdditionalServiceRequest: 'Buyer declined additional service request',
  buyerAcceptedAdditionalServiceRequest: 'Buyer accepted additional service request',
  buyerPaidAdditionalServiceRequest: 'Buyer paid additional service request',
  freelancerIsLate: 'Freelancer is late',
  buyerCancelLateOrder: 'Buyer cancelled the order',
  freelancerIsOnTheWayToWorkLocation: 'Freelancer is on the way to work location',
  freelancerArrivedAtWorkLocationAndServiceStarted: 'Freelancer arrived at work location and service started',
  serviceCompleted: 'Service completed',
  orderCompleted: 'Order completed',
  appointmentRequestAccepted: 'Appointment request accepted',
};
