var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-fp-payment-options" }, [
    _c(
      "div",
      { staticClass: "payment-option-item d-flex p-3" },
      [
        _c("span", {
          staticClass:
            "flex-grow-1 font-weight-500 font-size-20 font-roboto cj-color-black-primary",
          domProps: {
            textContent: _vm._s(
              _vm.$t("cbgwApp.cbComponent.cFromPayment.paymentOption")
            ),
          },
        }),
        _vm._v(" "),
        _c("icon", {
          staticClass: "my-auto cj-fill-black-primary",
          attrs: { name: "ic_down", width: "1rem", height: "1rem" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }