var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-profile-report" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex d-lg-none cj-bg-white-primary border-grey-tertiary-bottom-1 c-navbar-m pr-3",
      },
      [
        _c(
          "div",
          {
            staticClass: "cursor-pointer my-auto d-flex p-3",
            on: { click: _vm.handleCloseModalReport },
          },
          [
            _c("font-awesome-icon", {
              staticClass:
                "cb-icon-size-arrow-left cj-color-black-primary my-auto",
              attrs: { icon: "fa-solid fa-arrow-left" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "cj-color-black-primary font-roboto font-size-18 font-weight-500 my-auto text-ellipsis",
          },
          [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbProfile.profileReport.report")
                  ),
                },
              },
              [_vm._v("Report")]
            ),
            _vm._v(" " + _vm._s(_vm.username) + "\n    "),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-none d-lg-flex w-100" }, [
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-size-24 font-weight-700 ml-auto my-auto text-ellipsis",
        },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbProfile.profileReport.report")
                ),
              },
            },
            [_vm._v("Report")]
          ),
          _vm._v(" " + _vm._s(_vm.username) + "\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ml-auto my-auto cursor-pointer",
          on: { click: _vm.handleCloseModalReport },
        },
        [
          _c("b-icon-x-circle-fill", {
            staticClass: "font-size-20 cj-color-grey-tertiary",
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex mt-3" }, [
      _c(
        "div",
        {
          staticClass: "ml-auto px-3 py-1 cj-bg-red-secondary",
          staticStyle: { "border-radius": "28px" },
        },
        [
          _c(
            "p",
            {
              staticClass:
                "ml-auto font-roboto font-weight-400 font-size-12 cj-color-black-primary",
            },
            [
              _c("span", { staticClass: "cj-color-grey-secondary mr-1" }, [
                _vm._v("*"),
              ]),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbHome.cbHomeBeforeLogin.register.required"
                      )
                    ),
                  },
                },
                [_vm._v("Required")]
              ),
            ]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {},
      [
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-size-18 font-weight-700 mb-2",
          },
          [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbProfile.profileReport.reason")
                  ),
                },
              },
              [_vm._v("Reason")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "cj-color-grey-secondary" }, [
              _vm._v("*"),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.reasonTypes, function (reasonType, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "custom-control custom-checkbox cb-custom-checkbox mb-2",
            },
            [
              _c("input", {
                staticClass: "custom-control-input",
                attrs: {
                  "data-cy": "check1",
                  type: "checkbox",
                  id: "customCheck" + index,
                },
                domProps: { value: reasonType },
                on: { change: _vm.handleCheckBoxReason },
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "custom-control-label",
                attrs: { for: "customCheck" + index },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(`cbgwApp.cbProfile.profileReport.${reasonType}`)
                  ),
                },
              }),
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-size-16 font-weight-700 mt-4 mb-2",
          },
          [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbProfile.profileReport.describeYourProblem"
                    )
                  ),
                },
              },
              [_vm._v("Describe your problem ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "cj-color-grey-secondary" }, [
              _vm._v("*"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("c-textarea-new", {
          staticClass: "cj-field-border-default",
          class: {
            invalid:
              _vm.$v.description.$anyDirty && _vm.$v.description.$invalid,
            valid: _vm.$v.description.$anyDirty && !_vm.$v.description.$invalid,
          },
          attrs: { maxlength: 1000 },
          model: {
            value: _vm.$v.description.$model,
            callback: function ($$v) {
              _vm.$set(_vm.$v.description, "$model", $$v)
            },
            expression: "$v.description.$model",
          },
        }),
        _vm._v(" "),
        _vm.$v.description.$anyDirty && _vm.$v.description.$invalid
          ? _c(
              "small",
              { staticClass: "font-validation cj-color-status-error" },
              [
                _c(
                  "span",
                  {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbCreatePaw.stepOne.descriptionValidation"
                        )
                      ),
                    },
                  },
                  [_vm._v(" Your description is required to be at least")]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "font-validation mt-1",
            class: _vm.isMobile ? "font-size-12" : "font-size-14",
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbProfile.profileReport.descDefaultReport")
                ),
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-size-16 font-weight-700 mt-3 mb-2",
          },
          [
            _c(
              "span",
              {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbProfile.profileReport.attachment")
                  ),
                },
              },
              [_vm._v("Attachment")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "cj-color-grey-tertiary",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbProfile.profileReport.optional")
                  ),
                },
              },
              [_vm._v("(optional)")]
            ),
          ]
        ),
        _vm._v(" "),
        _c("c-upload-file", {
          attrs: {
            files: _vm.files,
            accept: _vm.fileAccept,
            fileType: "attContentType",
            fileUrl: "localUrl",
            fileThumbnail: "thumbnail",
            textUploadCondition: _vm.$t(
              "cbgwApp.cbProfile.profileReport.maxMb"
            ),
          },
          on: {
            onFileChange: _vm.handleUploadFile,
            removeFile: _vm.handleClear,
          },
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "font-validation mt-1",
            class: _vm.isMobile ? "font-size-12" : "font-size-14",
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbProfile.profileReport.maxFileDefault")
                ),
              },
            }),
          ]
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-none d-lg-flex w-100 mt-4" },
      [
        _c(
          "c-button",
          {
            staticClass:
              "font-size-20 px-5 ml-auto font-roboto font-weight-400",
            attrs: {
              disabled:
                _vm.reasons.length == 0 ||
                _vm.$v.description.$invalid ||
                _vm.isSaving,
            },
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbProfile.profileReport.sendReport")
              ),
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [_vm._v("Send Report")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-block d-lg-none" }, [
      _c(
        "div",
        {
          staticClass:
            "w-100 p-3 border-grey-tertiary-top-1 cj-bg-white-primary position-fixed",
          staticStyle: { bottom: "0", left: "0" },
        },
        [
          _c(
            "c-button",
            {
              staticClass: "font-size-18 font-roboto font-weight-400",
              attrs: {
                disabled:
                  _vm.reasons.length == 0 ||
                  _vm.$v.description.$invalid ||
                  _vm.isSaving,
                fullWidth: "",
              },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbProfile.profileReport.sendReport")
                ),
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleSubmit.apply(null, arguments)
                },
              },
            },
            [_vm._v("Send Report")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }