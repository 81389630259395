var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    { staticClass: "c-breadcrumb" },
    _vm._l(_vm.paths, function (path, index) {
      return _c("li", { key: index }, [
        _c(
          "span",
          {
            class: path.active === true ? "active" : "in-active",
            attrs: { "data-cy": `breadcrumb-${path.text}` },
            on: {
              click: function ($event) {
                return _vm.toNavigate(path)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(path.text))]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }