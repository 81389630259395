var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "room-bar" }, [
    _c(
      "button",
      {
        staticClass:
          "btn-w-100 py-1 px-4 mr-lg-3 wrap-border-grey-tertiary rounded-pill",
        attrs: {
          type: "button",
          "data-cy": "btn-search",
          "data-gtm": "search-button",
        },
        on: {
          click: function ($event) {
            return _vm.handleShowSearch()
          },
        },
      },
      [
        _c("icon", {
          staticClass: "cj-stroke-red-primary",
          attrs: { name: "ic_search", width: "21px", height: "21px" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "mb-0 mx-3 mx-lg-0 title" }, [_vm._v("CHAT")]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "py-1 btn-w-100 px-4 wrap-border-grey-tertiary rounded-pill",
        attrs: {
          type: "button",
          "data-cy": "btn-setting",
          "data-gtm": "setting-button",
        },
        on: {
          click: function ($event) {
            return _vm.handleShowSettingUser()
          },
        },
      },
      [_c("b-icon-gear", { staticClass: "cj-color-red-primary font-size-18" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }