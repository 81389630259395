var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-ap-pitch-preview" },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-lg-center justify-content-lg-star position-relative mb-2",
          style: _vm.isMobile ? "width:100vw" : "",
        },
        [
          !_vm.isMobile
            ? _c("div", { staticClass: "wrap-icon-left d-flex" }, [
                !this.$options.filters.findBookmarkByIdIsActive(
                  _vm.cbUserBookmarkAggs,
                  _vm.vwAuctionProject.id,
                  _vm.CbBookmarkType.AUCTION_PROJECT
                )
                  ? _c(
                      "div",
                      {
                        ref: "info-bookmark",
                        staticClass:
                          "my-auto cj-stroke-grey-secondary mr-2 cursor-pointer d-flex",
                        attrs: { "data-cy": "project-detail-bookmark" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "saveBookmark",
                              _vm.vwAuctionProject.id
                            )
                          },
                        },
                      },
                      [
                        _c("icon", {
                          staticClass: "cj-stroke-grey-secondary m-auto",
                          attrs: {
                            name: "ic_bookmark",
                            width: "1.5rem",
                            height: "1.5rem",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                this.$options.filters.findBookmarkByIdIsActive(
                  _vm.cbUserBookmarkAggs,
                  _vm.vwAuctionProject.id,
                  _vm.CbBookmarkType.AUCTION_PROJECT
                )
                  ? _c(
                      "div",
                      {
                        ref: "info-bookmark-fill",
                        staticClass: "my-auto mr-2 cursor-pointer d-flex",
                        attrs: { "data-cy": "project-detail-bookmark" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit(
                              "saveBookmark",
                              _vm.vwAuctionProject.id
                            )
                          },
                        },
                      },
                      [
                        _c("icon", {
                          staticClass:
                            "cj-fill-red-primary cj-stroke-red-primary m-auto",
                          attrs: {
                            name: "ic_bookmark",
                            width: "1.5rem",
                            height: "1.5rem",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "info-share-project",
                    staticClass: "d-flex cursor-pointer",
                    attrs: { "data-cy": "project-detail-share" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit(
                          "openModalCShare",
                          _vm.vwAuctionProject.id
                        )
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "cj-stroke-grey-secondary m-auto",
                      attrs: {
                        width: "1.75rem",
                        height: "1.75rem",
                        name: "ic_shared",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-lg-center text-left w-100 card-bg-m" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-700 cj-color-black-primary font-size-24 text-break-all",
                  attrs: { "data-cy": "project-detail-title" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.vwAuctionProject.title) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "font-roboto font-weight-300 cj-color-black-primary font-size-12",
                  attrs: { "data-cy": "project-detail-service" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.vwAuctionProject.svc) +
                      " | " +
                      _vm._s(_vm.vwAuctionProject.svcCat) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isMobile
                ? _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-weight-300 font-size-12 cj-color-black-primary",
                      attrs: { "data-cy": "project-detail-proposal" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.vwAuctionProject.proposalCount) +
                          " " +
                          _vm._s(
                            _vm.$t(
                              "cbGlobal.freelanceMainPage.proposalReceived"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isMobile
                ? _c("div", { staticClass: "text-right" }, [
                    _vm.vwAuctionProject.priceType === "FIXED_PRICE"
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "m-0 font-weight-300 font-size-14 cj-color-black-primary",
                            attrs: { "data-cy": "project-detail-fixed-price" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("cbGlobal.freelanceMainPage.fixedPrice")
                              ),
                            },
                          },
                          [_vm._v("\n          Fixed price\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.vwAuctionProject.priceType === "PER_HOUR"
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "m-0 font-weight-300 font-size-14 cj-color-black-primary",
                            attrs: { "data-cy": "project-detail-hourly" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cAuctionProject.hourly"
                                )
                              ),
                            },
                          },
                          [_vm._v("\n          Hourly\n        ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex justify-content-end" }, [
                      _vm.isMobile && _vm.vwAuctionProject.isProjectGuarantee
                        ? _c("span", { ref: "info-project-guarantee" }, [
                            _c("img", {
                              staticClass: "my-auto mr-2",
                              attrs: {
                                "data-cy": "project-detail-guarantee",
                                src: require("@/../content/images/shield-guarantee.svg"),
                                alt: "shield guarantee",
                                width: "18rem",
                                height: "18rem",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto font-weight-700 font-size-18 cj-color-black-primary",
                          attrs: { "data-cy": "project-detail-price" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatPriceWithPriceType")(
                                  _vm.vwAuctionProject.price,
                                  _vm.vwAuctionProject.priceType,
                                  _vm.vwAuctionProject.hourlyValue,
                                  _vm.vwAuctionProject.currency
                                )
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.vwAuctionProject.status ==
                        _vm.CbCommonStatus.EXPIRED,
                      expression:
                        "vwAuctionProject.status == CbCommonStatus.EXPIRED",
                    },
                  ],
                  staticClass:
                    "font-roboto font-weight-500 cj-color-status-error font-size-16",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cAuctionProject.expired")
                    ),
                  },
                },
                [_vm._v("\n        Expired\n      ")]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.closeModalId & !_vm.isMobile
            ? _c(
                "div",
                {
                  staticClass: "position-absolute d-flex",
                  staticStyle: { top: "0", right: "0" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "ml-auto mb-auto",
                      attrs: {
                        "data-cy": "project-detail-cancel",
                        type: "button",
                      },
                      on: { click: _vm.cancel },
                    },
                    [
                      _c("b-icon-x-circle-fill", {
                        staticClass: "font-size-18 cj-color-grey-secondary",
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c("div", { staticClass: "col-lg-6 col-12 mb-3" }, [
          _c(
            "div",
            {
              staticClass:
                "wrap-border-grey-tertiary rounded p-3 d-flex h-100 card-bg-m-2",
            },
            [
              _c(
                "b-avatar",
                {
                  staticClass: "my-auto mr-3 img-user my-auto",
                  attrs: { size: "4.5rem" },
                },
                [
                  [
                    _c(
                      "span",
                      {
                        staticClass: "b-avatar-img cursor-pointer",
                        attrs: { "data-cy": "project-detail-avatar" },
                        on: {
                          click: function ($event) {
                            return _vm.toProfile(_vm.vwAuctionProject.authorId)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.$options.filters.LAZY(
                              `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                                _vm.vwAuctionProject
                                  ? _vm.vwAuctionProject.authorPhotoId
                                  : ""
                              }`,
                              72,
                              "ic-lazy-avatar.png"
                            ),
                            onerror:
                              _vm.$options.filters.LAZY_ERROR(
                                "ic-lazy-avatar.png"
                              ),
                            alt:
                              _vm.vwAuctionProject.authorPhotoId ?? "default",
                          },
                        }),
                      ]
                    ),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-block my-auto" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto font-size-18 font-weight-700 cj-color-black-primary text-break-all",
                    attrs: { "data-cy": "project-detail-author-name" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.vwAuctionProject.authorName) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto font-size-14 font-weight-500 font-italic cj-color-black-primary text-ellipsis oneline",
                    attrs: { "data-cy": "project-detail-author-signature" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.vwAuctionProject.authorSignature
                            ? _vm.vwAuctionProject.authorSignature
                            : "-"
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex my-auto mr-2" },
                    [
                      _c("icon", {
                        staticClass: "cj-stroke-grey-secondary my-auto",
                        attrs: {
                          name: "ic_location",
                          width: "10px",
                          height: "10px",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-size-12 font-weight-500 font-italic cj-color-grey-secondary my-auto text-ellipsis",
                      attrs: {
                        "data-cy": "project-detail-author-city-and-province",
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.getLanguangeLocation(
                              _vm.vwAuctionProject.authorCity
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-6 col-12 mb-3 pl-lg-0" }, [
          _c(
            "div",
            {
              staticClass:
                "wrap-border-grey-tertiary rounded p-3 d-block card-bg-m-2",
            },
            [
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex mr-2 my-auto mb-2" },
                  [
                    _c("icon", {
                      staticClass: "cj-fill-red-primary",
                      attrs: {
                        name: "ic_file_plus",
                        width: "26px",
                        height: "26px",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto font-weight-400 cj-color-black-primary font-size-14 my-auto",
                    attrs: { "data-cy": "project-detail-created-date" },
                  },
                  [
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbComponent.cAuctionProject.posted")
                          ),
                        },
                      },
                      [_vm._v("Posted")]
                    ),
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("formatCreateDatelabel")(
                            _vm.vwAuctionProject.createdDate
                          )
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex mb-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex mr-2 my-auto mb-2" },
                  [
                    _c("icon", {
                      staticClass: "cj-fill-red-primary",
                      attrs: {
                        name: "ic_calendar",
                        width: "24px",
                        height: "24px",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "font-roboto font-weight-400 cj-color-black-primary font-size-14 my-auto",
                    attrs: { "data-cy": "project-detail-due-date" },
                  },
                  [
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cAuctionProject.dueDate"
                            )
                          ),
                        },
                      },
                      [_vm._v("Due Date")]
                    ),
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm._f("dynamicFormatDate")(
                            _vm.vwAuctionProject.auctionDueDate,
                            "DD MMM YYYY"
                          )
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.vwAuctionProject &&
              _vm.vwAuctionProject.svcType == _vm.CbSvcType.INSIDE
                ? _c("div", { staticClass: "d-flex mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex mr-2 my-auto mb-2" },
                      [
                        _c("icon", {
                          staticClass: "cj-fill-red-primary",
                          attrs: { name: "ic_revision", width: "1.51rem" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto font-weight-400 cj-color-black-primary font-size-14 my-auto",
                        attrs: { "data-cy": "project-detail-revision-count" },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.vwAuctionProject.revisionCount) +
                            " " +
                            _vm._s(
                              _vm.$t(
                                _vm.getRevisionTextEnId(
                                  _vm.vwAuctionProject.revisionCount
                                )
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex mb-2 mb-lg-0 align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex mr-2 my-auto mb-2" },
                    [
                      _c("icon", {
                        staticClass: "cj-stroke-red-primary",
                        attrs: {
                          name: "ic_location",
                          width: "24px",
                          height: "24px",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-weight-400 cj-color-black-primary font-size-14",
                      attrs: { "data-cy": "project-detail-project-location" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.getLanguangeLocation(
                              _vm.vwAuctionProject.projectLocation
                            )
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.cbTextNoAuth.content
        ? _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                {
                  staticClass:
                    "wrap-border-grey-tertiary rounded p-3 card-bg-m-2",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-weight-700 font-size-16 cj-color-black-primary mb-2",
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cAuctionProject.projectDescription"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("c-textarea-preview", {
                    staticClass:
                      "font-catamaran font-weight-300 font-size-16 cj-color-black-primary text-break",
                    attrs: { text: _vm.cbTextNoAuth.content },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.vwAuctionProject.cbAuctionProjectAtts &&
      _vm.vwAuctionProject.cbAuctionProjectAtts.length > 0
        ? _c("div", { staticClass: "row mb-3" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                {
                  staticClass:
                    "wrap-border-grey-tertiary rounded pr-3 pt-3 pl-3 pb-1 wrap-document card-bg-m-2",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-weight-700 font-size-16 cj-color-black-primary mb-2",
                    },
                    [
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "cbgwApp.cbComponent.cAuctionProject.attachment"
                            )
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.vwAuctionProject.cbAuctionProjectAtts,
                    function (cbAuctionProjectAtt, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "item-card-file text-center mr-3",
                          on: {
                            click: function ($event) {
                              return _vm.downloadFile(
                                cbAuctionProjectAtt.cbAttachmentNoAuth &&
                                  cbAuctionProjectAtt.cbAttachmentNoAuth
                                    .localUrl
                              )
                            },
                          },
                        },
                        [
                          _c("c-upload-file-icon", {
                            staticClass: "img-size mb-2",
                            attrs: {
                              file: cbAuctionProjectAtt.cbAttachmentNoAuth
                                .attContentType,
                              fileUrl:
                                cbAuctionProjectAtt.cbAttachmentNoAuth.localUrl,
                              fileThumbnail:
                                cbAuctionProjectAtt.cbAttachmentNoAuth
                                  .thumbnailLocalUrl,
                              isShowVideo: false,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto font-size-12 font-weight-500 font-italic cj-color-black-primary font-underline",
                              staticStyle: {
                                "white-space": "nowrap",
                                width: "8rem",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                              },
                              attrs: { "data-cy": "project-detail-image-name" },
                            },
                            [
                              _vm._v(
                                "\n             \n            " +
                                  _vm._s(
                                    cbAuctionProjectAtt.cbAttachmentNoAuth &&
                                      cbAuctionProjectAtt.cbAttachmentNoAuth
                                        .name
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }
                  ),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobile
        ? _c("div", { staticClass: "d-flex flex-wrap mx-min-2 mb-3" }, [
            _c("div", { staticClass: "col px-2" }, [
              _c(
                "div",
                {
                  staticClass:
                    "wrap-border-grey-tertiary rounded d-flex justify-content-between p-3",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-roboto font-weight-400 font-size-16 cj-color-black-primary my-auto",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cAuctionProject.projectBudget"
                          )
                        ),
                      },
                    },
                    [_vm._v("\n          Project budget\n        ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex my-auto" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.vwAuctionProject.isProjectGuarantee,
                            expression: "vwAuctionProject.isProjectGuarantee",
                          },
                        ],
                        ref: "info-project-guarantee",
                        attrs: { "data-cy": "project-detail-guarantee" },
                      },
                      [
                        _c("img", {
                          staticClass: "my-auto mr-3",
                          attrs: {
                            "data-cy": "project-icon",
                            src: require("@/../content/images/shield-guarantee.svg"),
                            alt: "shield guarantee",
                            width: "20rem",
                            height: "100%",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "font-roboto font-size-18 cj-color-black-primary my-auto",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-500",
                            attrs: { "data-cy": "project-detail-price" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatPriceWithPriceType")(
                                    _vm.vwAuctionProject.price,
                                    _vm.vwAuctionProject.priceType,
                                    _vm.vwAuctionProject.hourlyValue,
                                    _vm.vwAuctionProject.currency
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.vwAuctionProject.priceType === "FIXED_PRICE"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-300 font-size-14 cj-color-grey-secondary",
                                attrs: {
                                  "data-cy": "project-detail-fixed-price",
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "cbGlobal.freelanceMainPage.fixedPrice"
                                    )
                                  ),
                                },
                              },
                              [_vm._v("Fixed price")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.vwAuctionProject.priceType === "PER_HOUR"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-300 font-size-14 cj-color-grey-secondary",
                                attrs: { "data-cy": "project-detail-hourly" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "cbgwApp.cbComponent.cAuctionProject.hourly"
                                    )
                                  ),
                                },
                              },
                              [_vm._v("Hourly")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          staticClass: "rounded",
          attrs: {
            target: () => _vm.$refs["info-project-guarantee"],
            variant: "dark",
          },
        },
        [
          _c(
            "p",
            {
              staticClass: "font-catamaran font-size-12 font-weight-500",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.toolTip.projectGuarantee")
                ),
              },
            },
            [
              _vm._v(
                "\n      The buyer has paid a deposit to guarantee the need for this project.\n    "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          staticClass: "rounded",
          attrs: { target: () => _vm.$refs["info-bookmark"], variant: "dark" },
        },
        [
          _c(
            "p",
            {
              staticClass: "font-catamaran font-size-12 font-weight-500",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.toolTip.addWishlist")
                ),
              },
            },
            [_vm._v("Add to Wishlist")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          staticClass: "rounded",
          attrs: {
            target: () => _vm.$refs["info-bookmark-fill"],
            variant: "dark",
          },
        },
        [
          _c(
            "p",
            {
              staticClass: "font-catamaran font-size-12 font-weight-500",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.toolTip.removeWishlist")
                ),
              },
            },
            [_vm._v("\n      Remove from wishlist\n    ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-tooltip",
        {
          staticClass: "rounded",
          attrs: {
            target: () => _vm.$refs["info-share-project"],
            variant: "dark",
          },
        },
        [
          _c(
            "p",
            {
              staticClass: "font-catamaran font-size-12 font-weight-500",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbComponent.toolTip.shareProject")
                ),
              },
            },
            [_vm._v("Share Project")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }