import axios from 'axios';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICmVwTkt } from '@/shared/model/cm-vw-tkt.model';

const baseApiUrl = 'services/crmcorems/api/cm-vw-tkts';

export default class PCmVwTktService {
  public find(id: number): Promise<ICmVwTkt> {
    return new Promise<ICmVwTkt>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findTktLiveChat(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/live-chat/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(paginationQuery?: any,  cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/by-login?${buildPaginationQueryOpts(paginationQuery)}`, {cancelToken})
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICmVwTkt): Promise<ICmVwTkt> {
    return new Promise<ICmVwTkt>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICmVwTkt): Promise<ICmVwTkt> {
    return new Promise<ICmVwTkt>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: ICmVwTkt): Promise<ICmVwTkt> {
    return new Promise<ICmVwTkt>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public download(downloadLink: string, type: string, callBack: (progress: number)=> void): Promise<any> {
    // const config = {
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': type,
    //   },
    //   onUploadProgress: uploadEvent => {
    //     const progress = Math.round((uploadEvent.loaded / uploadEvent.total) * 100);
    //     console.log('upload progress: ' + progress);
    //     callBack(progress);
    //   },
    // };

    return new Promise<string>((resolve, reject) => {
      axios
        .get(`${downloadLink}`, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {},
        })
        .then(signUrlRes => {
          let base64 = null;
          const blob = new Blob([signUrlRes.data], { type: signUrlRes.headers['content-type'] });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            base64 = reader.result;
            resolve(base64);
          };
        })
        .catch(signUrlErr => {
          reject(false);
        });
    });
  }

  public countCmVwTktByUserId(userId: string): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/count/by-user-id/' + userId)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
