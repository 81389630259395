import vClickOutside from 'v-click-outside';
import { Inject, Component, Vue, Emit } from 'vue-property-decorator';

import Icon from '@/shared/icons/icon.vue';
import CFilterBudget from '../c-filter-budget/c-filter-budget.vue';
import CFilterRating from '../c-filter-rating/c-filter-rating.vue';

import PCbVwSvcService from '@/services/cb-vw-svc.service';
import PCbVwSvcCatService from '@/services/cb-vw-svc-cat.service';

import { ICbVwSvc } from '@/shared/model/cb-vw-svc.model';
import { ICbVwSvcCat } from '@/shared/model/cb-vw-svc-cat.model';
import { IAdvanceSearchFilter, AdvanceSearchFilter } from '@/shared/model/advance-search-model';
import { ICbLog } from '@/shared/model/cb-log.model';
import { CbLogType } from '@/shared/model/enumerations/cb-log-type.model';

export enum EnumQuickSearchType {
  SVC = 'SVC',
  SVC_CAT = 'SVC_CAT',
  RATING = 'RATING',
  BUDGET = 'BUDGET',
}

@Component({
  components: {
    Icon,
    CFilterBudget,
    CFilterRating,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class CQuickSearch extends Vue {
  // ================= START SERVICES =================
  @Inject('pCbVwSvcService') private pCbVwSvcService: () => PCbVwSvcService;
  @Inject('pCbVwSvcCatService') private pCbVwSvcCatService: () => PCbVwSvcCatService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public quickSearch: IAdvanceSearchFilter = new AdvanceSearchFilter();
  public advanceSearchFilter: IAdvanceSearchFilter = new AdvanceSearchFilter();
  public cbVwSvcs: ICbVwSvc[] = [];
  public searchCbVwSvcs: ICbVwSvc[] = [];
  public enumQuickSearchType = EnumQuickSearchType;
  public cbVwSvcCats: ICbVwSvcCat[] = [];
  public searchCbVwSvcCats: ICbVwSvcCat[] = [];
  public filterBudget: any = {};

  public valueCbVwSvc: string = null;
  public valueCbVwSvcCat: string = null;

  public toggleService = false;
  public toggleCategory = false;
  public toggleRating = false;
  public toggleBudget = false;
  public isFetchingCbVwSvc = false;
  public isFetchingCbVwSvcCat = false;
  public isShowBudget = false;
  public isTabActive: EnumQuickSearchType = null;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.initStar();
    this.retrieveCbVwSvcs();
    this.retrieveCbVwSvcCats();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('cancel')
  public cancel() {
    return;
  }

  public initStar() {
    if (this.$route.query['rating']) {
      this.advanceSearchFilter.rating = [];
      for (const rating of this.$route.query['rating']) {
        this.advanceSearchFilter.rating.push(+rating);
      }
    } else {
      this.advanceSearchFilter.rating = [0, 5];
    }
  }

  public retrieveCbVwSvcs() {
    this.isFetchingCbVwSvc = true;
    this.pCbVwSvcService()
      .retrieve()
      .then(res => {
        this.isFetchingCbVwSvc = false;
        this.cbVwSvcs = res.data;
        this.searchCbVwSvcs = this.cbVwSvcs;
      })
      .catch(err => {
        this.isFetchingCbVwSvc = false;
        console.log('error retrieve quick search cb svc => ', err);
      });
  }

  public retrieveCbVwSvcCats() {
    this.isFetchingCbVwSvcCat = true;
    const params: any = {
      size: 9999,
    };

    this.pCbVwSvcCatService()
      .retrieve(params)
      .then(res => {
        this.isFetchingCbVwSvcCat = false;
        this.cbVwSvcCats = res.data;
        this.searchCbVwSvcCats = this.cbVwSvcCats;
      })
      .catch(err => {
        this.isFetchingCbVwSvcCat = false;
        console.log('error retrieve quick search cb svc => ', err);
      });
  }

  public onQuickSearch() {
    const newObject: any = {};

    if (
      this.advanceSearchFilter.rating &&
      this.advanceSearchFilter.rating.length == 2 &&
      Number(this.advanceSearchFilter.rating[0]) == 0 &&
      Number(this.advanceSearchFilter.rating[1]) == 0
    ) {
      this.advanceSearchFilter.rating = null;
    }

    for (const key in this.advanceSearchFilter) {
      if (this.advanceSearchFilter[key]) {
        newObject[key] = this.advanceSearchFilter[key];
      }
    }

    if (this.advanceSearchFilter.svcId) {
      const cbLog: ICbLog = {
        cbLogType: CbLogType.SVC_HIT,
        valueHitId: this.advanceSearchFilter.svcId,
      };
      this.$root.$emit('createLog', cbLog);
    }

    if (this.advanceSearchFilter.svcCatId) {
      const cbLog: ICbLog = {
        cbLogType: CbLogType.SVC_CAT_HIT,
        valueHitId: this.advanceSearchFilter.svcCatId,
      };
      this.$root.$emit('createLog', cbLog);
    }

    this.$router.push({
      name: 'PAdvanceSearch',
      params: { key: `${+new Date()}` },
      query: newObject,
    });
    this.cancel();
  }

  public selectResultService(searchCbVwSvc: ICbVwSvc) {
    this.valueCbVwSvc = searchCbVwSvc.name;
    this.advanceSearchFilter.svcId = searchCbVwSvc.id;
    this.searchCbVwSvcCats = this.cbVwSvcCats.filter(e => e.svcId === searchCbVwSvc.id);
    this.valueCbVwSvcCat = null;
    this.advanceSearchFilter.svcCatId = null;
  }

  public selectResultCategory(searchCbVwSvcCat: ICbVwSvcCat) {
    this.valueCbVwSvcCat = searchCbVwSvcCat.name;
    this.advanceSearchFilter.svcCatId = searchCbVwSvcCat.id;
  }

  public resultBudget() {
    this.advanceSearchFilter.greaterStartPrice = this.filterBudget?.greaterStartPrice;
    this.advanceSearchFilter.lessStartPrice = this.filterBudget?.lessStartPrice;
    this.isShowBudget = false;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get getValueRating() {
    if (this.advanceSearchFilter.rating && this.advanceSearchFilter.rating.length > 0) {
      if (this.advanceSearchFilter.rating[0] == this.advanceSearchFilter.rating[1]) {
        return this.advanceSearchFilter.rating[0];
      } else return `${this.advanceSearchFilter.rating[0]} - ${this.advanceSearchFilter.rating[1]}`;
    } else {
      return 0;
    }
  }
  public get getValueBudget() {
    if (
      ((this.advanceSearchFilter.greaterStartPrice == 0 || !this.advanceSearchFilter.greaterStartPrice) &&
        this.advanceSearchFilter.lessStartPrice == 0) ||
      !this.advanceSearchFilter.lessStartPrice
    ) {
      return 0;
    } else {
      return `${
        this.advanceSearchFilter.lessStartPrice && this.advanceSearchFilter.lessStartPrice > 0 ? this.advanceSearchFilter.lessStartPrice : 0
      } - ${
        this.advanceSearchFilter.greaterStartPrice && this.advanceSearchFilter.greaterStartPrice > 0
          ? this.advanceSearchFilter.lessStartPrice
          : 0
      }`;
    }
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
