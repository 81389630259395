import dayjs from 'dayjs';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';

import CButton from '../button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';

import { CbUserTimezone, ICbUserTimezone } from '@/shared/model/cb-user-timezone.model';
import { CbVwUser, ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { CbNavbar } from '@/shared/model/cb-navbar.model';
import { TIME_24_FORMAT } from '@/shared/date/filters';
import CNavbarSwitchLogintype from '../navbar/component/c-navbar-switch-logintype/c-navbar-switch-logintype.vue';

export enum NavbarType {
  GENERAL = 'GENERAL',
  BUYER = 'BUYER',
  PARTNER = 'PARTNER',
  CMS = 'CMS',
}

@Component({
  components: {
    CButton,
    Icon,
    CNavbarSwitchLogintype,
  },
  mixins: [Vue2Filters.mixin],
})
export default class CNavbarMobile extends Vue {
  // ================= SERVICES =================
  // =================END SERVICES =================
  // ================= VARIABLES =================
  @Prop({ default: null }) public navbar: CbNavbar;
  @Prop({ default: false }) public isDoubleCallBack;

  public newSearchValue: any[] = [];
  public searchValue: any[] = [
    { name: 'Graphic & Design' },
    { name: 'Programming & Tech' },
    { name: 'Bismillah' },
    { name: 'Graphic & Design' },
    { name: 'Graphic & Design' },
    { name: 'Graphic & Design' },
  ];
  public currentUser: ICbVwUser = new CbVwUser();
  public cbUserTimezoneUTC: ICbUserTimezone = new CbUserTimezone();
  public quickSearch: any = {
    service: null,
    category: null,
    rating: null,
    price: null,
  };

  public type: NavbarType = NavbarType.GENERAL;
  public navbarType = NavbarType;

  public methodeVerification = 'Email';
  public verificationKey = '';
  public search = '';

  public toggle = false;
  public showNavbarMobile = false;

  public interval: any = null;
  // =================END VARIABLES =================
  // ================= DEFAULT FUNCTION =================
  public mounted() {
    this.initTimeZoneUTC();
  }

  // =================END DEFAULT FUNCTION =================
  // ================= FUNCTION =================
  public goBack() {
    if (this.isDoubleCallBack) {
      this.$router.go(-2);
    } else if (this.navbar.typeGoBack === 'IS_SHOW_CHAT_INFO_PANEL') {
      if (this.previousRouteChat === 'PAppChatByRoomId' && this.typeBackChat !== 'IS_NOT_SHOW_CHAT_INFO_PANEL') {
        this.$router.replace({
          name: 'PAppChatByRoomId',
          params: { roomId: this.roomIdToStore },
          query: { type: 'isShowChatInfoPanelTrue' },
        });
      } else if (
        this.previousRouteChat === 'PNotificationM' &&
        ((this.nextRouteName === 'PRequestedTicket' && this.previousRouteName === 'PAskCommitee') ||
          this.nextRouteName === 'PRequestedTicket')
      ) {
        this.$router.replace({ name: 'PNotificationM' });

        // clear typeBackChat
        this.$store.commit('typeBackChat', '');
      } else {
        // clear typeBackChat
        this.$store.commit('typeBackChat', '');
        this.$router.go(-1);
      }
    } else {
      this.$router.go(-1);
    }
  }

  public toNavbarMenu() {
    (<any>this.$root).$emit('showFloatingLiveChat', false);
    this.$router.push({ name: 'CNavbarMenu' });
  }
  public toPageNotification() {
    this.$router.push({ name: 'PNotificationM' });
  }

  public initTimeZoneUTC() {
    this.setTimeUtcStore();
    setInterval(this.setTimeUtcStore, 1000);
  }

  public setTimeUtcStore() {
    const utcTime = dayjs().utc().format(TIME_24_FORMAT);
    this.cbUserTimezoneUTC.timeFormat = utcTime;
  }
  // =================END FUNCTION =================
  // ================= COMPUTE =================
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get countNotifTotal(): number {
    return this.$store.getters.countNotif + this.$store.getters.countMsg;
  }

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get previousRouteChat() {
    return this.$store.getters.previousRouteChat;
  }

  public get previousRouteName() {
    return this.$store.getters.previousRouteName;
  }

  public get nextRouteName() {
    return this.$store.getters.nextRouteName;
  }

  public get roomIdToStore() {
    // from chat-store
    return this.$store.getters.roomIdToStore;
  }
  public get typeBackChat() {
    // from chat-store
    return this.$store.getters.typeBackChat;
  }
  // =================END COMPUTE =================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
