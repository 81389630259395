import { render, staticRenderFns } from "./items-chat-message.vue?vue&type=template&id=145c38b6&scoped=true&"
import script from "./items-chat-message.component.ts?vue&type=script&lang=ts&"
export * from "./items-chat-message.component.ts?vue&type=script&lang=ts&"
import style0 from "./items-chat-message.vue?vue&type=style&index=0&id=145c38b6&prod&lang=scss&"
import style1 from "./items-chat-message.vue?vue&type=style&index=1&id=145c38b6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "145c38b6",
  null
  
)

export default component.exports