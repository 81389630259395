var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "room-files-chat" },
    [
      _c("transition", { attrs: { name: "vac-slide-up" } }, [
        _vm.files.length
          ? _c(
              "div",
              {
                staticClass: "var-files-container-chat",
                style: { bottom: `${_vm.footerHeight}px` },
              },
              [
                _c("div", { staticClass: "vac-room-files-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "vac-files-box",
                      attrs: { "data-cy": "vac-files-box" },
                    },
                    _vm._l(_vm.files, function (file, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "py-2 pl-2 bg-file firstchild",
                          attrs: { "data-cy": "vac-files-box-item" },
                        },
                        [
                          _c("room-file-chat", {
                            attrs: { file: file, index: i },
                            on: {
                              "remove-file": function ($event) {
                                return _vm.handleRemove($event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "vac-icon-close p-2 bg-file" }, [
                    _c(
                      "div",
                      {
                        staticClass: "vac-svg-button",
                        attrs: { "data-cy": "vac-svg-btn-reset" },
                        on: {
                          click: function ($event) {
                            return _vm.handleReset()
                          },
                        },
                      },
                      [
                        _vm._t("reply-close-icon", function () {
                          return [
                            _c("svg-icon", {
                              attrs: { name: "close-outline" },
                            }),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }