import { CbPaymentStatus } from '@/shared/model/enumerations/cb-payment-status.model';
export interface ICbWithdraw {
  id?: number;
  externalId?: string | null;
  withdrawAmount?: number | null;
  note?: string | null;
  status?: CbPaymentStatus | null;
  statusWathdrawDate?: Date | null;
  ip?: string | null;
  bankCode?: string | null;
  accountHolderName?: string | null;
  accountNumber?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
}

export class CbWithdraw implements ICbWithdraw {
  constructor(
    public id?: number,
    public externalId?: string | null,
    public withdrawAmount?: number | null,
    public note?: string | null,
    public status?: CbPaymentStatus | null,
    public statusWathdrawDate?: Date | null,
    public ip?: string | null,
    public bankCode?: string | null,
    public accountHolderName?: string | null,
    public accountNumber?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null
  ) {}
}
