var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-content-bank",
      attrs: { "data-cy": "payment-method-information" },
    },
    [
      _vm.payDetailVA.bankCode == _vm.cbListBank.BCA
        ? _c("div", [
            _vm.currentLanguage == "en"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bca",
                              arg: "atm-bca",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bca",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Enter your ATM card and PIN "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Choose Other Transaction Menu "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Select Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Select Go to BCA Virtual Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Input Virtual Account Number, which is "
                              ),
                              _c(
                                "span",
                                { staticClass: "cj-color-red-primary" },
                                [_vm._v(_vm._s(_vm.payDetailVA.accountNumber))]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v("\n            6. Choose Right "),
                              _c("br"),
                              _vm._v("\n            7. Select Yes "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Take your proof of payment "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bca",
                              arg: "ib-bca",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bca",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login Internet Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Select Fund Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Select Transfer to BCA Virtual Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Input Virtual Account Number, which is "
                              ),
                              _c(
                                "span",
                                { staticClass: "cj-color-red-primary" },
                                [_vm._v(_vm._s(_vm.payDetailVA.accountNumber))]
                              ),
                              _vm._v(" as No.\n            Virtual Account "),
                              _c("br"),
                              _vm._v("\n            5. Click Continue "),
                              _c("br"),
                              _vm._v(
                                "\n            6. KeyBCA Apply 1 Response Input "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Click Send "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Proof of payment is displayed "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bca",
                              arg: "m-bca",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bca",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Login Mobile Banking "),
                              _c("br"),
                              _vm._v("\n            2. Select m-Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Choose BCA Virtual Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Input Virtual Account Number, which is "
                              ),
                              _c(
                                "span",
                                { staticClass: "cj-color-red-primary" },
                                [_vm._v(_vm._s(_vm.payDetailVA.accountNumber))]
                              ),
                              _vm._v(" as No.\n            Virtual Account "),
                              _c("br"),
                              _vm._v("\n            5. Click Send "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Virtual Account Information will be displayed "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Click OK "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Enter Mobile Banking PIN "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Proof of payment is displayed "
                              ),
                              _c("br"),
                              _vm._v("\n            10. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentLanguage == "id"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bca",
                              arg: "atm-bca",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bca",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masukkan kartu ATM dan PIN Anda "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Pilih Menu Transaksi Lainnya "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Pilih Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Pilih Masuk ke BCA Virtual Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Masukan Nomor Virtual Account yaitu "
                              ),
                              _c(
                                "span",
                                { staticClass: "cj-color-red-primary" },
                                [_vm._v(_vm._s(_vm.payDetailVA.accountNumber))]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v("\n            6. Pilih Kanan "),
                              _c("br"),
                              _vm._v("\n            7. Pilih Ya "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Ambil bukti pembayaran Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bca",
                              arg: "ib-bca",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bca",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masuk Internet Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih Transfer Dana "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Pilih Transfer ke BCA Virtual Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Masukkan Nomor Virtual Account, "
                              ),
                              _c(
                                "span",
                                { staticClass: "cj-color-red-primary" },
                                [_vm._v(_vm._s(_vm.payDetailVA.accountNumber))]
                              ),
                              _vm._v(
                                " sebagai No\n            Virtual Account\n            "
                              ),
                              _c("br"),
                              _vm._v("\n            5. Klik Lanjutkan "),
                              _c("br"),
                              _vm._v(
                                "\n            6. KeyBCA Terapkan 1 Masukan Respon "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Klik Kirim "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Bukti pembayaran ditampilkan "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bca",
                              arg: "m-bca",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bca",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Masuk Mobile Banking "),
                              _c("br"),
                              _vm._v("\n            2. Pilih m-Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Pilih BCA Virtual Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Masukkan Nomor Virtual Account yaitu "
                              ),
                              _c(
                                "span",
                                { staticClass: "cj-color-red-primary" },
                                [_vm._v(_vm._s(_vm.payDetailVA.accountNumber))]
                              ),
                              _vm._v(
                                " sebagai No\n            Virtual Account "
                              ),
                              _c("br"),
                              _vm._v("\n            5. Klik Kirim "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Informasi Akun Virtual akan ditampilkan "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Klik OK "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Masukkan PIN Mobile Banking "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Bukti pembayaran ditampilkan "
                              ),
                              _c("br"),
                              _vm._v("\n            10. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.payDetailVA.bankCode == _vm.cbListBank.MANDIRI
        ? _c("div", [
            _vm.currentLanguage == "en"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-mandiri",
                              arg: "atm-mandiri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-mandiri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Enter your ATM card and PIN "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Select Menu Pay/Buy "),
                              _c("br"),
                              _vm._v("\n            3. Choose More "),
                              _c("br"),
                              _vm._v("\n            4. Choose Multi Payment "),
                              _c("br"),
                              _vm._v(
                                "\n            5. Input 70014 as Institution Code "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Input Virtual Account Number, eg. 7001 4501 0538 1294 "
                              ),
                              _c("br"),
                              _vm._v("\n            7.Choose Correct "),
                              _c("br"),
                              _vm._v("\n            8. Select Yes "),
                              _c("br"),
                              _vm._v("\n            9. Select Yes "),
                              _c("br"),
                              _vm._v(
                                "\n            10. Take your proof of payment "
                              ),
                              _c("br"),
                              _vm._v("\n            11. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-mandiri",
                              arg: "ib-mandiri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-mandiri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Enter your ATM card and PIN "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Select Menu Pay/Buy "),
                              _c("br"),
                              _vm._v("\n            3. Choose More "),
                              _c("br"),
                              _vm._v("\n            4. Choose Multi Payment "),
                              _c("br"),
                              _vm._v(
                                "\n            5. Input 70014 as Institution Code "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Input Virtual Account Number, eg. 7001 4501 0538 1294 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Choose Correct "),
                              _c("br"),
                              _vm._v("\n            8. Select Yes "),
                              _c("br"),
                              _vm._v("\n            9. Select Yes "),
                              _c("br"),
                              _vm._v(
                                "\n            10. Take your proof of payment "
                              ),
                              _c("br"),
                              _vm._v("\n            11. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-mandiri",
                              arg: "m-mandiri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Livin By Mandiri ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-mandiri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login New Livin by Mandiri "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Choose Pay Menu "),
                              _c("br"),
                              _vm._v("\n            3. Choose E-Commerce "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Look for Transferpay as a service provider "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Input Virtual Account Number, eg. 7001 4501 0538 1393 as pay code "
                              ),
                              _c("br"),
                              _vm._v("\n            6. Select Next "),
                              _c("br"),
                              _vm._v(
                                "\n            7. The screen will show the pay code and payment details. Check if it's appropriate "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. nput MPIN New Livin, Click OK "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentLanguage == "id"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-mandiri",
                              arg: "atm-mandiri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-mandiri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masukkan kartu ATM dan PIN Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih Menu Bayar/Beli "),
                              _c("br"),
                              _vm._v("\n            3. Pilih Lebih Banyak "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Pilih Multi Pembayaran "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Masukkan 70014 sebagai Kode Institusi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Masukkan Nomor Virtual Account, mis. 7001 4501 0538 1294 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Pilih yang Benar "),
                              _c("br"),
                              _vm._v("\n            8. Pilih Ya "),
                              _c("br"),
                              _vm._v("\n            9. Pilih Ya "),
                              _c("br"),
                              _vm._v(
                                "\n            10. Ambil bukti pembayaran Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            11. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-mandiri",
                              arg: "ib-mandiri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-mandiri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masukkan kartu ATM dan PIN Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih Menu Bayar/Beli "),
                              _c("br"),
                              _vm._v("\n            3. Pilih Lebih Banyak "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Pilih Multi Pembayaran "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Masukkan 70014 sebagai Kode Institusi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Masukkan Nomor Virtual Account, mis. 7001 4501 0538 1294 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Pilih Benar "),
                              _c("br"),
                              _vm._v("\n            8. Pilih Ya "),
                              _c("br"),
                              _vm._v("\n            9. Pilih Ya "),
                              _c("br"),
                              _vm._v(
                                "\n            10. Ambil bukti pembayaran Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            11. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-mandiri",
                              arg: "m-mandiri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Livin By Mandiri ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-mandiri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login New Livin by Mandiri "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Select the Pay menu "),
                              _c("br"),
                              _vm._v("\n            3. Choose E-Commerce "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Look for Transferpay as a service provider "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Enter the Virtual Account Number, e.g. 7001 4501 0538 1393 as payment code "
                              ),
                              _c("br"),
                              _vm._v("\n            6. Select Next "),
                              _c("br"),
                              _vm._v(
                                "\n            7. The screen will display the payment code and payment details. Check if it fits "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Enter New Livin's MPIN, Click OK "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.payDetailVA.bankCode == _vm.cbListBank.BNI
        ? _c("div", [
            _vm.currentLanguage == "en"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bni",
                              arg: "atm-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Insert Your Card "),
                              _c("br"),
                              _vm._v("\n            2. Select a language "),
                              _c("br"),
                              _vm._v("\n            3. Enter your ATM PIN "),
                              _c("br"),
                              _vm._v("\n            4. Select More Menu "),
                              _c("br"),
                              _vm._v("\n            5. Select Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Select the type of account you will use (Example: From a Savings Account) "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            7. Choose Virtual Account Billing "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Enter your Virtual Account Number 8319 0010 0518 3299 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. The bill to be paid will appear on the confirmation screen "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            10. Confirm, if it is appropriate, continue the transaction "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            11. Your transaction has been completed\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bni",
                              arg: "ib-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Type the address https://ibank.bni.co.id then click Enter "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Enter User ID and Password "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            3. Select the Transfer menu "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Choose Virtual Account Billing "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Then enter your Virtual Account number 8319 0010 0518 3299 that you want to pay. "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Then select the debit account to be used. "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Then press Next "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Then the bill to be paid will appear on the confirmation screen "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Enter the Token Authentication Code "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            10. Your payment has been successful\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bni",
                              arg: "m-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Access BNI Mobile Banking from your cellphone then enter your user ID and password "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Select the Transfer menu "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            3. Select the Virtual Account Billing menu then select Debit Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Enter your Virtual Account number 8319 0010 0518 3299 on the New Input menu "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. The bill to be paid will appear on the confirmation screen "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Confirm the transaction and enter the Transaction Password "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            7. Your Payment Has Been Successful\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:sms-bni",
                              arg: "sms-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via SMS Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "sms-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login to BNI SMS Banking Application "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Select Transfer Menu "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Choose BNI Account Trf "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Enter Virtual Account Number 8319 0010 0518 3299 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Enter the Billing Amount. For example, 10000 "
                              ),
                              _c("br"),
                              _vm._v("\n            6. Select Process "),
                              _c("br"),
                              _vm._v(
                                "\n            7. In the Pop Up Message, select Agree "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            You Will Get A Confirmation Message "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Enter the 2 digits of your PIN by following the instructions on the message "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Payment Proof Shown "),
                              _c("br"),
                              _vm._v("\n            10. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentLanguage == "id"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bni",
                              arg: "atm-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Masukkan Kartu Anda "),
                              _c("br"),
                              _vm._v("\n            2. Pilih bahasa "),
                              _c("br"),
                              _vm._v("\n            3. Masukkan PIN ATM Anda "),
                              _c("br"),
                              _vm._v("\n            4. Pilih Menu Lainnya "),
                              _c("br"),
                              _vm._v("\n            5. Pilih Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Pilih jenis rekening yang akan Anda gunakan (Contoh: Dari Rekening Tabungan) "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            7. Pilih Penagihan Akun Virtual "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Masukkan Nomor Virtual Account Anda 8319 0010 0518 3299 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Tagihan yang harus dibayar akan muncul di layar konfirmasi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            10. Konfirmasi, jika sudah sesuai, lanjutkan transaksi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            11. Transaksi Anda telah selesai\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bni",
                              arg: "ib-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Ketik alamat https://ibank.bni.co.id lalu klik Enter "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Masukkan ID Pengguna dan Kata Sandi "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Pilih menu Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Pilih Penagihan Akun Virtual "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Kemudian masukkan nomor Virtual Account Anda 8319 0010 0518 3299 yang ingin Anda bayarkan. "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Kemudian pilih rekening debet yang akan digunakan. "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            7. Kemudian tekan Berikutnya "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Kemudian akan muncul tagihan yang harus dibayar pada layar konfirmasi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Masukkan Kode Otentikasi Token "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            10. Pembayaran Anda telah berhasil\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bni",
                              arg: "m-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Akses BNI Mobile Banking dari ponsel Anda kemudian masukkan ID pengguna dan kata sandi Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih menu Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Pilih menu Virtual Account Billing lalu pilih Debit Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Masukkan nomor Virtual Account Anda 8319 0010 0518 3299 pada menu New Input "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Tagihan yang harus dibayar akan muncul di layar konfirmasi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Konfirmasikan transaksi dan masukkan Kata Sandi Transaksi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            7. Pembayaran Anda Telah Berhasil\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:sms-bni",
                              arg: "sms-bni",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui SMS Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "sms-bni",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login ke Aplikasi BNI SMS Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih Menu Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Pilih Trf Rekening BNI "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Masukkan Nomor Virtual Account 8319 0010 0518 3299 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Masukkan Jumlah Penagihan. Misalnya, 10.000 "
                              ),
                              _c("br"),
                              _vm._v("\n            6. Pilih Proses "),
                              _c("br"),
                              _vm._v(
                                "\n            7. Pada Pop Up Message, pilih Agree "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            Anda Akan Mendapatkan Pesan Konfirmasi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Masukkan 2 digit PIN Anda dengan mengikuti petunjuk pada pesan "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Bukti Pembayaran Ditampilkan "
                              ),
                              _c("br"),
                              _vm._v("\n            10. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.payDetailVA.bankCode == _vm.cbListBank.BRI
        ? _c("div", [
            _vm.currentLanguage == "en"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bri",
                              arg: "atm-bri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Enter your ATM card and PIN "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Choose Other Transaction Menu "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Select Payment Menu "),
                              _c("br"),
                              _vm._v("\n            4. Choose Other Menu "),
                              _c("br"),
                              _vm._v("\n            5. Select Menu BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Enter the Virtual Account Number, eg. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Select Yes "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Take your proof of payment "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bri",
                              arg: "ib-bri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login Internet Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Select Payment "),
                              _c("br"),
                              _vm._v("\n            3. Choose BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Enter the Virtual Account Number, eg. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v("\n            5. Click Send "),
                              _c("br"),
                              _vm._v("\n            6. Enter Password "),
                              _c("br"),
                              _vm._v("\n            7. Enter mToken "),
                              _c("br"),
                              _vm._v("\n            8. Click Send "),
                              _c("br"),
                              _vm._v(
                                "\n            9. Proof of payment will be displayed "
                              ),
                              _c("br"),
                              _vm._v("\n            10. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bri",
                              arg: "m-bri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Login BRI Mobile "),
                              _c("br"),
                              _vm._v(
                                "\n            2. Choose BRI Mobile Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Select Payment Menu "),
                              _c("br"),
                              _vm._v("\n            4. Select Menu BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            5. Enter the Virtual Account Number, eg. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Enter the nominal eg. 10000 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Click Send "),
                              _c("br"),
                              _vm._v("\n            8. Enter Mobile PIN "),
                              _c("br"),
                              _vm._v("\n            9. Click Send "),
                              _c("br"),
                              _vm._v(
                                "\n            10. Proof of payment will be sent via sms "
                              ),
                              _c("br"),
                              _vm._v("\n            11. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentLanguage == "id"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bri",
                              arg: "atm-bri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masukkan kartu ATM dan PIN Anda "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Pilih Menu Transaksi Lainnya "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Pilih Menu Pembayaran "),
                              _c("br"),
                              _vm._v("\n            4. Pilih Menu Lainnya "),
                              _c("br"),
                              _vm._v("\n            5. Pilih Menu BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Masukkan Nomor Virtual Account, mis. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Pilih Ya "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Ambil bukti pembayaran Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bri",
                              arg: "ib-bri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masuk Internet Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih Pembayaran "),
                              _c("br"),
                              _vm._v("\n            3. Pilih BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Masukkan Nomor Virtual Account, mis. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v("\n            5. Klik Kirim "),
                              _c("br"),
                              _vm._v("\n            6. Masukkan Kata Sandi "),
                              _c("br"),
                              _vm._v("\n            7. Masukkan mToken "),
                              _c("br"),
                              _vm._v("\n            8. Klik Kirim "),
                              _c("br"),
                              _vm._v(
                                "\n            9. Bukti pembayaran akan ditampilkan "
                              ),
                              _c("br"),
                              _vm._v("\n            10. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bri",
                              arg: "m-bri",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Login BRI Mobile "),
                              _c("br"),
                              _vm._v(
                                "\n            2. Pilih BRI Mobile Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Pilih Menu Pembayaran "),
                              _c("br"),
                              _vm._v("\n            4. Pilih Menu BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            5. Masukkan Nomor Virtual Account, mis. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Masukkan nominal mis. 10000 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Klik Kirim "),
                              _c("br"),
                              _vm._v("\n            8. Masukkan PIN Ponsel "),
                              _c("br"),
                              _vm._v("\n            9. Klik Kirim "),
                              _c("br"),
                              _vm._v(
                                "\n            10. Bukti pembayaran akan dikirimkan melalui sms "
                              ),
                              _c("br"),
                              _vm._v("\n            11. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.payDetailVA.bankCode == _vm.cbListBank.BSI
        ? _c("div", [
            _vm.currentLanguage == "en"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bsi",
                              arg: "atm-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Insert Your Card "),
                              _c("br"),
                              _vm._v("\n            2. Select a language "),
                              _c("br"),
                              _vm._v("\n            3. Enter your ATM PIN "),
                              _c("br"),
                              _vm._v("\n            4. Select More Menu "),
                              _c("br"),
                              _vm._v("\n            5. Select Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Select the type of account you will use (Example: From a Savings Account) "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            7. Choose Virtual Account Billing "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Enter your Virtual Account Number 8319 0010 0518 3299 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. The bill to be paid will appear on the confirmation screen "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            10. Confirm, if it is appropriate, continue the transaction "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            11. Your transaction has been completed\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bsi",
                              arg: "ib-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bri",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Type the address "),
                              _c(
                                "a",
                                { attrs: { href: "https://ibank.bsi.co.id" } },
                                [_vm._v("https://ibank.bsi.co.id")]
                              ),
                              _vm._v(" then click Enter "),
                              _c("br"),
                              _vm._v(
                                "\n            2. Enter User ID and Password "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            3. Select the Transfer menu "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Choose Virtual Account Billing "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Then enter your Virtual Account number 8319 0010 0518 3299 that you want to pay. "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Then select the debit account to be used. "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Then press Next "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Then the bill to be paid will appear on the confirmation screen"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Enter the Token Authentication Code "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            10. Your payment has been successful\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bsi",
                              arg: "m-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bsi",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Access BSI Mobile Banking from your cellphone then enter your user ID and password "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Select the Transfer menu "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            3. Select the Virtual Account Billing menu then select Debit Account "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Enter your Virtual Account number 8319 0010 0518 3299 on the New Input menu "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. The bill to be paid will appear on the confirmation screen "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Confirm the transaction and enter the Transaction Password "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            7. Your Payment Has Been Successful\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:sms-bsi",
                              arg: "sms-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer via SMS Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "sms-bsi",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login to BSI SMS Banking Application "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Select Transfer Menu "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Choose BSI Account Transfer "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Enter Virtual Account Number 8319 0010 0518 3299 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Enter the Billing Amount. For example, 10000 "
                              ),
                              _c("br"),
                              _vm._v("\n            6. Select Process "),
                              _c("br"),
                              _vm._v(
                                "\n            7. In the Pop Up Message, select Agree "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            You Will Get A Confirmation Message "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Enter the 2 digits of your PIN by following the instructions on the message "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Payment Proof Shown "),
                              _c("br"),
                              _vm._v("\n            10. Done\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentLanguage == "id"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:atm-bsi",
                              arg: "atm-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui ATM ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "atm-bsi",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masukkan kartu ATM dan PIN Anda "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            2. Pilih Menu Transaksi Lainnya "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Pilih Menu Pembayaran "),
                              _c("br"),
                              _vm._v("\n            4. Pilih Menu Lainnya "),
                              _c("br"),
                              _vm._v("\n            5. Pilih Menu BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            6. Masukkan Nomor Virtual Account, mis. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Pilih Ya "),
                              _c("br"),
                              _vm._v(
                                "\n            8. Ambil bukti pembayaran Anda "
                              ),
                              _c("br"),
                              _vm._v("\n            9. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:ib-bsi",
                              arg: "ib-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Internet Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "ib-bsi",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Masuk Internet Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih Pembayaran "),
                              _c("br"),
                              _vm._v("\n            3. Pilih BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            4. Masukkan Nomor Virtual Account, mis. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v("\n            5. Klik Kirim "),
                              _c("br"),
                              _vm._v("\n            6. Masukkan Kata Sandi "),
                              _c("br"),
                              _vm._v("\n            7. Masukkan mToken "),
                              _c("br"),
                              _vm._v("\n            8. Klik Kirim "),
                              _c("br"),
                              _vm._v(
                                "\n            9. Bukti pembayaran akan ditampilkan "
                              ),
                              _c("br"),
                              _vm._v("\n            10. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:m-bsi",
                              arg: "m-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui Mobile Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "m-bsi",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v("\n            1. Login BRI Mobile "),
                              _c("br"),
                              _vm._v(
                                "\n            2. Pilih BRI Mobile Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            3. Pilih Menu Pembayaran "),
                              _c("br"),
                              _vm._v("\n            4. Pilih Menu BRIVA "),
                              _c("br"),
                              _vm._v(
                                "\n            5. Masukkan Nomor Virtual Account, mis. 8878 8000 0892 6810 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            6. Masukkan nominal mis. 10000 "
                              ),
                              _c("br"),
                              _vm._v("\n            7. Klik Kirim "),
                              _c("br"),
                              _vm._v("\n            8. Masukkan PIN Ponsel "),
                              _c("br"),
                              _vm._v("\n            9. Klik Kirim "),
                              _c("br"),
                              _vm._v(
                                "\n            10. Bukti pembayaran akan dikirimkan melalui sms "
                              ),
                              _c("br"),
                              _vm._v("\n            11. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pb-3" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:sms-bsi",
                              arg: "sms-bsi",
                            },
                          ],
                          staticClass:
                            "payment-option-item justify-content-between d-flex",
                          attrs: {
                            "data-gtm": "toggle-method-information-clickable",
                            "data-cy": "toggle-method-information",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-400 font-size-16 font-roboto cj-color-black-primary",
                            },
                            [_vm._v(" Transfer Melalui SMS Banking ")]
                          ),
                          _vm._v(" "),
                          _c("icon", {
                            staticClass:
                              "ic_arrow_dropdown_up_down my-auto cj-fill-black-primary",
                            attrs: {
                              name: "ic_down",
                              width: "1rem",
                              height: "1rem",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "sms-bsi",
                            accordion: "bank-transfer",
                            role: "tabpanel",
                            "data-cy": "payment-method-information",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-roboto cj-color-black-primary font-weight-300 font-size-14",
                            },
                            [
                              _vm._v(
                                "\n            1. Login ke Aplikasi BSI SMS Banking "
                              ),
                              _c("br"),
                              _vm._v("\n            2. Pilih Menu Transfer "),
                              _c("br"),
                              _vm._v(
                                "\n            3. Pilih Transfer Rekening BSI "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            4. Masukkan Nomor Virtual Account 8319 0010 0518 3299 "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            5. Masukkan Jumlah Penagihan. Misalnya, 10.000 "
                              ),
                              _c("br"),
                              _vm._v("\n            6. Pilih Proses "),
                              _c("br"),
                              _vm._v(
                                "\n            7. Pada Pop Up Message, pilih Agree "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            8. Anda Akan Mendapatkan Pesan Konfirmasi "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Masukkan PIN Anda dengan mengikuti petunjuk pada pesan "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            9. Bukti Pembayaran Ditampilkan "
                              ),
                              _c("br"),
                              _vm._v("\n            10. Selesai\n          "),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }