import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { CbVwPromo } from '@/shared/model/cb-vw-promo.model';
import Vue2Filters from 'vue2-filters';
import { CbPromoUsedStatus } from '@/shared/model/enumerations/cb-promo-used-status.model';
import { CbPromoTagType } from '@/shared/model/enumerations/cb-promo-tag-type.model';
import { AdvanceSearchFilterKey } from '@/shared/model/advance-search-model';
import { ICbVwPromoUserAgg } from '@/shared/model/cb-vw-promo-user-agg.model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';

@Component({
  mixins: [Vue2Filters.mixin],
  components: {
    CButton,
    Icon,
  },
})
export default class CCardPromo extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public cbVwPromo: CbVwPromo;
  public appId: '';
  public canCopy: boolean = false;
  public cbPromoUsedStatus = CbPromoUsedStatus;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    this.canCopy = !!navigator.clipboard;
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public async copy(s) {
    await navigator.clipboard.writeText(s);
    alert('Copied!');
  }

  @Emit('toCardPromoDetail')
  public toCardPromoDetail() {
    return this.cbVwPromo;
  }

  @Emit('handleSaveCardPromo')
  public handleSaveCardPromo() {
    return this.cbVwPromo;
  }

  public toAdvancedSearch(promo: ICbVwPromoUserAgg) {
    let queryObj = {};
    if (promo.cbPromoSpecifics != null) {
      queryObj = Object.assign({}, this.assignQueryObject(promo, queryObj));
    }

    console.log('OJECK  ', queryObj);

    this.$router.push({
      name: 'PAdvanceSearch',
      params: { key: new Date().getTime().toString() },
      query: queryObj,
    });
  }

  public assignQueryObject(promo: ICbVwPromoUserAgg, queryObj: any): any {
    for (const spec of promo.cbPromoSpecifics.split('||')) {
      const specDtl = spec.split('::');

      if (specDtl[0] == CbPromoTagType.SERVICE_FIELD) {
        queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.FIELD;
      } else if (specDtl[0] == CbPromoTagType.SERVICE_INSIDE) {
        queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.INSIDE;
      } else if (specDtl[0] == CbPromoTagType.SERVICE_SPECIFIC) {
        if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] == null) {
          queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] = [];
          queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
        } else {
          queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
        }
      } else if (specDtl[0] == CbPromoTagType.CATEGORY_SPECIFIC) {
        if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] == null) {
          queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] = [];
          queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
        } else {
          queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
        }
      }
      queryObj['promoCode'] = promo.code;
    }
    return queryObj;
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
