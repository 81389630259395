var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vac-room-container" },
    [
      _vm._t(
        "room-list-item",
        function () {
          return [
            _vm._t(
              "room-list-avatar",
              function () {
                return [
                  _vm.room.avatar
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-avatar position-relative",
                          style: {
                            "background-image": `url('${_vm.room.avatar}')`,
                          },
                          attrs: { "data-cy": "vac-avatar" },
                        },
                        [
                          _c("div", {
                            staticClass: "vac-status",
                            class: {
                              "status-online":
                                _vm.$options.filters.chatIsUserOnline(
                                  _vm.room.members,
                                  _vm.currentUserId,
                                  _vm.userIdsOnline
                                ),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              null,
              { room: _vm.room }
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vac-name-container vac-text-ellipsis" }, [
              _c("div", { staticClass: "vac-title-container" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vac-room-name vac-text-ellipsis cj-color-black-primary cj-color-black-primary font-roboto font-weight-700 font-size-16",
                    attrs: { "data-cy": "room-content-user-name" },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.room.roomName) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.room.isPinned
                  ? _c(
                      "div",
                      [
                        _c("c-icons", {
                          attrs: {
                            name: "cic_pin",
                            width: "18px",
                            height: "18px",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.room.lastMessage
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "vac-text-date cj-color-grey-secondary font-roboto",
                        attrs: { "data-cy": "chat-date" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.room.lastMessage.timestamp) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "vac-text-last font-catamaran font-weight-300",
                  class: {
                    "vac-message-new":
                      _vm.room.lastMessage &&
                      _vm.room.lastMessage.new &&
                      !_vm.typingUsers,
                  },
                  attrs: { "data-cy": "vac-text-snapshot" },
                },
                [
                  _vm.isMessageCheckmarkVisible
                    ? _c(
                        "span",
                        [
                          _vm._t(
                            "checkmark-icon",
                            function () {
                              return [
                                _c("svg-icon", {
                                  staticClass: "vac-icon-check",
                                  attrs: {
                                    name: _vm.room.lastMessage.distributed
                                      ? "double-checkmark"
                                      : "checkmark",
                                    param: _vm.room.lastMessage.seen
                                      ? "seen"
                                      : "",
                                  },
                                }),
                              ]
                            },
                            null,
                            { message: _vm.room.lastMessage }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.room.lastMessage &&
                  !_vm.room.lastMessage.deleted &&
                  _vm.isAudio
                    ? _c(
                        "div",
                        { staticClass: "vac-text-ellipsis" },
                        [
                          _vm._t("microphone-icon", function () {
                            return [
                              _c("svg-icon", {
                                staticClass: "vac-icon-microphone",
                                attrs: { name: "microphone" },
                              }),
                            ]
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formattedDuration) +
                              "\n        "
                          ),
                        ],
                        2
                      )
                    : _vm.isLastIcon
                    ? _c("div", { staticClass: "vac-text-ellipsis" }, [
                        _vm.lastFileType == "IMAGE"
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("icon", {
                                  staticClass:
                                    "icon-chat-cht_image d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary",
                                  attrs: {
                                    name: "ic_cht_image",
                                    width: "0.81rem",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-12 vac-text-ellipsis",
                                  },
                                  [_vm._v("Image")]
                                ),
                              ],
                              1
                            )
                          : _vm.lastFileType == "VIDEO"
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("icon", {
                                  staticClass:
                                    "icon-chat-cht_video d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary",
                                  attrs: {
                                    name: "ic_cht_video",
                                    width: "0.81rem",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-12 vac-text-ellipsis",
                                  },
                                  [_vm._v("Video")]
                                ),
                              ],
                              1
                            )
                          : _vm.lastFileType == "DOCUMENT"
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("icon", {
                                  staticClass:
                                    "icon-chat-cht_document d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary",
                                  attrs: {
                                    name: "ic_cht_document",
                                    width: "0.81rem",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-12 vac-text-ellipsis",
                                  },
                                  [_vm._v("Document")]
                                ),
                              ],
                              1
                            )
                          : _vm.lastFileType == "STICKER"
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("icon", {
                                  staticClass:
                                    "icon-chat-cht_sticker d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary",
                                  attrs: {
                                    name: "ic_cht_sticker",
                                    width: "0.81rem",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-12 vac-text-ellipsis",
                                  },
                                  [_vm._v("Sticker")]
                                ),
                              ],
                              1
                            )
                          : _vm.lastFileType == "MAP"
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("icon", {
                                  staticClass:
                                    "icon-chat-cht_location d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary",
                                  attrs: {
                                    name: "ic_cht_location",
                                    width: "0.81rem",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-12 vac-text-ellipsis",
                                  },
                                  [_vm._v("Location")]
                                ),
                              ],
                              1
                            )
                          : _vm.lastFileType == "PAW"
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("icon", {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center mr-1 cj-fill-red-primary",
                                  attrs: {
                                    name: "ic_cht_order_update",
                                    width: "12px",
                                    height: "12px",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-size-12 vac-text-ellipsis",
                                    attrs: {
                                      "data-cy": "vac-snapshot-order-updated",
                                    },
                                  },
                                  [_vm._v("Order Update")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm.room.lastMessage
                    ? _c("format-message", {
                        attrs: {
                          content: _vm.getLastMessage,
                          deleted:
                            !!_vm.room.lastMessage.deleted && !_vm.typingUsers,
                          users: _vm.room.users,
                          linkify: false,
                          "text-formatting": _vm.textFormatting,
                          "link-options": _vm.linkOptions,
                          "single-line": true,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "deleted-icon",
                              fn: function (data) {
                                return [
                                  _vm._t("deleted-icon", null, null, data),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.room.lastMessage && _vm.typingUsers
                    ? _c("div", { staticClass: "vac-text-ellipsis" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.typingUsers) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vac-room-options-container" },
                    [
                      _vm.room.isHasOrderActive
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-items-center justify-content-center",
                              on: { click: _vm.actionFile },
                            },
                            [
                              _c(
                                "div",
                                {
                                  ref: "order-active",
                                  staticClass:
                                    "vac-badge-counter vac-room-badge size-count d-flex align-items-center justify-content-center",
                                  staticStyle: {
                                    height: "20px",
                                    width: "20px",
                                  },
                                },
                                [
                                  _c("icon", {
                                    staticClass:
                                      "cj-fill-white-primary position-relative my-auto",
                                    attrs: {
                                      name: "ic_clipboard",
                                      width: "14px",
                                      height: "14px",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.room.isHasOrderActive
                        ? _c(
                            "b-tooltip",
                            {
                              staticClass: "rounded",
                              attrs: {
                                target: () => _vm.$refs["order-active"],
                                variant: "primary",
                              },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "font-catamaran font-size-12 font-weight-500",
                                },
                                [_vm._v("This message has active order")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.room.unreadCount > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vac-badge-counter vac-room-badge yellow size-count",
                              class:
                                _vm.room.unreadCount > 99
                                  ? "actived-max-count"
                                  : "",
                              attrs: { "data-cy": "vac-badge-counter" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("maxCount")(
                                      _vm.room.unreadCount,
                                      _vm.room.unreadCount
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t(
                        "room-list-options",
                        function () {
                          return [
                            _vm.roomActions.length
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: false,
                                        expression: "false",
                                      },
                                    ],
                                    staticClass:
                                      "vac-svg-button vac-list-room-options",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        _vm.roomMenuOpened = _vm.room.roomId
                                      },
                                    },
                                  },
                                  [
                                    _vm._t(
                                      "room-list-options-icon",
                                      function () {
                                        return [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "dropdown",
                                              param: "room",
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.roomActions.length
                              ? _c(
                                  "transition",
                                  { attrs: { name: "vac-slide-left" } },
                                  [
                                    _vm.roomMenuOpened === _vm.room.roomId
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "click-outside",
                                                rawName: "v-click-outside",
                                                value: _vm.closeRoomMenu,
                                                expression: "closeRoomMenu",
                                              },
                                            ],
                                            staticClass: "vac-menu-options",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "vac-menu-list" },
                                              _vm._l(
                                                _vm.roomActions,
                                                function (action) {
                                                  return _c(
                                                    "div",
                                                    { key: action.name },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vac-menu-item",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.roomActionHandler(
                                                                action
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                action.title
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                        null,
                        { room: _vm.room }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        null,
        { room: _vm.room }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }