import Icon from '@/shared/icons/icon.vue';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: { Icon },
})
export default class MessageActionItem extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  @Prop({ default: null })
  public currentUserId;
  @Prop({ default: null })
  public message;
  @Prop({ default: null })
  public messageActions;

  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public showAction(active) {
    this.$emit('message-action-handler', active);
  }

  public handleMsgDelete() {
    this.$emit('handleShowMsgDelete', null);
  }
  public handleShowReactions() {
    this.$emit('handleShowReactions', null);
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get filteredMessageActions() {
    return this.message.senderId === this.currentUserId ? this.messageActions : this.messageActions.filter(message => !message.onlyMe);
  }

  public get messageAction() {
    const seletedAction = 'replyMessage';
    return this.filteredMessageActions.find(e => e.name == seletedAction);
  }

  public get isShowMenuReply(): boolean {
    const validContentTypes = [
      ChtContentType.CB_STICKER,
      ChtContentType.DOC,
      ChtContentType.EXCEL,
      ChtContentType.FILE,
      ChtContentType.IMAGE,
      ChtContentType.PDF,
      ChtContentType.STICKER,
      ChtContentType.TEXT,
      ChtContentType.VIDEO,
      ChtContentType.ZIP,
    ];

    // Directly returns a boolean value
    return !this.message.chtContentType || validContentTypes.includes(this.message.chtContentType);
  }

  // // ================= END COMPUTE ====================
}
