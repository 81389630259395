import axios from 'axios';

import { ICbVwSvcCat } from '@/shared/model/cb-vw-svc-cat.model';

const baseApiUrl = 'services/cbcorems/api/cb-vw-svc-cats';

export default class PCbVwSvcCatService {
  public find(id: number): Promise<ICbVwSvcCat> {
    return new Promise<ICbVwSvcCat>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
