import axios from 'axios';

import { IWfVwWorkflow } from '@/shared/model/wf-vw-workflow.model';
import { IWfHistory } from '@/shared/model/wf-history.model';

const baseApiUrl = process.env.WORKFLOW_URL
  ? process.env.WORKFLOW_URL + 'api/wf-vw-workflows'
  : '/services/bti-workflow/api/wf-vw-workflows';
const historyBaseApiUrl = process.env.WORKFLOW_URL
  ? process.env.WORKFLOW_URL + 'api/wf-histories'
  : '/services/bti-workflow/api/wf-histories';

export default class WfVwWorkflowService {
  // /wf-histories/by-cb-order?workflowName.equals=OR_FIELD_WORKER&externalId.equals=44
  public retrieveHistoryByExternalId(workflowName: string, externalId: number): Promise<IWfHistory[]> {
    return new Promise<IWfHistory[]>((resolve, reject) => {
      axios
        .get(`${historyBaseApiUrl}/by-cb-order/${workflowName}/${externalId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  //wf-vw-workflows/actions-roles/OR_FIELD_WORKER/start
  public actionRoleStart(workflowName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/actions-roles/${workflowName}/start`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  //http://localhost:8089/api/wf-vw-workflows/actions-roles/{{workflowName}}/{{app}}/{{externalId}}
  // WF
  public actionRoleCurrent(workflowName: string, externalId: number, subExternalId?: number): Promise<any> {
    let subExternalIdPath = '';
    if (subExternalId) {
      subExternalIdPath = '/' + subExternalId;
    }
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/actions-roles/${workflowName}/CB_APP/${externalId}${subExternalIdPath}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public find(id: number): Promise<IWfVwWorkflow> {
    return new Promise<IWfVwWorkflow>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IWfVwWorkflow): Promise<IWfVwWorkflow> {
    return new Promise<IWfVwWorkflow>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IWfVwWorkflow): Promise<IWfVwWorkflow> {
    return new Promise<IWfVwWorkflow>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IWfVwWorkflow): Promise<IWfVwWorkflow> {
    return new Promise<IWfVwWorkflow>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
