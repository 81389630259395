var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showGroupDate
    ? _c("div", { staticClass: "c-rmedia-groupdate" }, [
        _vm._v("\n  " + _vm._s(_vm.getMonthBetweenYear) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }