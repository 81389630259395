var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-medal-progress" },
    [
      _c(
        "div",
        {
          ref: "info-medal",
          on: {
            mouseenter: function ($event) {
              _vm.isShowInfo = true
            },
            mouseleave: function ($event) {
              _vm.isShowInfo = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "logo-back",
              attrs: { "data-cy": "profile-achievement-back" },
            },
            [
              _c("img", {
                staticClass: "avatar-default m-auto rounded-circle",
                attrs: {
                  "data-cy": "profile-achievement-image",
                  src: _vm.logoInActiveUrl + `?${_vm.t}`,
                  onerror: _vm.$options.filters.LAZY_ERROR(),
                  alt: `${_vm.medalName}-${_vm.percent}`,
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "pawLight",
              staticClass: "logo-front",
              attrs: { "data-cy": "profile-achievement-front" },
            },
            [
              _c("img", {
                staticClass: "avatar-default m-auto rounded-circle",
                attrs: {
                  src: _vm.logoActiveUrl + `?${_vm.t}`,
                  onerror: _vm.$options.filters.LAZY_ERROR(),
                  alt: _vm.medalName,
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.requirement
        ? _c(
            "b-tooltip",
            {
              staticClass: "rounded",
              attrs: {
                target: () => _vm.$refs["info-medal"],
                variant: "primary",
              },
            },
            [
              _c(
                "p",
                { staticClass: "font-catamaran font-size-12 font-weight-500" },
                [_vm._v("\n      " + _vm._s(_vm.requirement) + "\n    ")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }