export enum CbPawOrderExtraType {
  URGENT = 'URGENT',

  NDA = 'NDA',

  GUARANTEE = 'GUARANTEE',

  EXTRA_REVISION = 'EXTRA_REVISION',

  OTHER = 'OTHER',
}
