export enum TypeComponent {
  CHAT = 'CHAT',
  CHAT_MODAL = 'CHAT_MODAL',
  CHAT_BUBBLE = 'CHAT_BUBBLE',
  MODAL_DELIVERY = 'MODAL_DELIVERY',
  MANAGE_ORDER = 'MANAGE_ORDER',
  DETAIL_ORDER_MODAL = 'DETAIL_ORDER_MODAL',
  DETAIL_ORDER_LOCATION = 'DETAIL_ORDER_LOCATION',
  PAW_DETAIL_MODAL = 'PAW_DETAIL_MODAL',
  ONLY_COUNTDOWN = 'ONLY_COUNTDOWN',

  DETAIL_ORDER = 'DETAIL_ORDER',
  CHAT_WIDGET = 'CHAT_WIDGET',
  ACTIVE_ORDER = 'ACTIVE_ORDER',

  EXTRA = 'EXTRA',
  OFFER = 'OFFER',
  INFO = 'INFO',
  FILE = 'FILE',
  APPOINTMENT = 'APPOINTMENT',
}
