import { CbBankAccount, ICbBankAccount } from '@/shared/model/cb-bank-account.model';
import { Inject, Component, Vue, Emit, Watch } from 'vue-property-decorator';
import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { CbUserData, ICbUserData } from '@/shared/model/cb-user-data.model';
import { CbUserDataType } from '@/shared/model/enumerations/cb-user-data-type.model';
import CBaBankCard from './components/c-ba-bank-card/c-ba-bank-card.vue';
import CBaCreditCard from './components/c-ba-credit-card/c-ba-credit-card.vue';
import CBaBankUpdate from './components/c-ba-bank-update/c-ba-bank-update.vue';
import CBaCreditUpdate from './components/c-ba-credit-update/c-ba-credit-update.vue';
import CConfirmation from '../c-confirmation/c-confirmation.vue';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import PCbBankAccountService from '@/services/cb-bank-account.service';
import PCbVwBankAccountService from '@/services/cb-vw-bank-account.service';
import GlobalService from '@/services/global.service';
import { BankAccountType } from '@/shared/model/enumerations/bank-account-type.model';
import { ICbVwBankAccount, CbVwBankAccount } from '@/shared/model/cb-vw-bank-account.model';
import { CmVwSopInputedAgg, ICmVwSopInputedAgg } from '@/shared/model/cm-vw-sop-inputed-agg.model';
import dayjs from 'dayjs';
import { TabSettingType } from '@/module/general/setting/settings.component';
import CInputPin from '../c-input-pin/c-input-pin.vue';
import ContentService from '@/services/content.service';
import { CbLovType } from '@/shared/model/enumerations/cb-lov-type.model';

export enum CbBankAccountProcess {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  NO_PROCESS = 'NO_PROCESS',
}

const validations: any = {
  newCbBankAccount: {
    bankCode: { required },
    accountNumber: { required, maxLength: maxLength(16) },
    accountHolderName: { required },
    expiryDateLabel: { minLength: minLength(5), maxLength: maxLength(5) },
  },
  newCbCreditCard: {
    accountNumber: { required },
    accountHolderName: { required },
    expiryDateLabel: { required },
  },
};

@Component({
  validations,
  components: {
    CButton,
    Icon,
    CBaBankCard,
    CBaCreditCard,
    CBaBankUpdate,
    CBaCreditUpdate,
    CConfirmation,
    CInputPin,
  },
})
export default class CBankAccount extends Vue {
  // ================= START SERVICES =================

  @Inject('pCbBankAccountService') public pCbBankAccountService: () => PCbBankAccountService;
  @Inject('globalService') public globalService: () => GlobalService;
  @Inject('pCbVwBankAccountService') private pCbVwBankAccountService: () => PCbVwBankAccountService;
  @Inject('contentService') private contentService: () => ContentService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public payOption: string = '';
  public isEditBank: boolean = false;
  public tempModelAmountWithdraw = '';
  public tempModelAccountNumber = '';
  public cbBankAccount: ICbBankAccount = new CbBankAccount();
  public isFetchingCbBankAccount: boolean = true;
  public isFetchingCbCC: boolean = false;
  public newCbBankAccount: ICbBankAccount = new CbBankAccount();
  public isFetchingCbLovBanks: boolean = false;
  public isSavingCbLovBanks: boolean = false;
  public valueBankCode: string = '';
  public cbUserDataIdCard: ICbUserData = new CbUserData();
  public cbUserDataSelfieWithIdCard: ICbUserData = new CbUserData();
  public isSavingCbUserData: boolean = false;
  public isSaving: boolean = false;
  public visiblePin: boolean = false;
  public pinNotMatched: boolean = false;
  public isSavingBankAccount: boolean = false;
  public isUpdateUserInfo: boolean = false;
  public cbVwBankAccount: ICbVwBankAccount = new CbVwBankAccount();
  public isFetchingCmVwSopinputedAgg: boolean = false;
  public cmVwSopInputedAgg: ICmVwSopInputedAgg = new CmVwSopInputedAgg();
  public newCbCreditCard: ICbBankAccount = new CbBankAccount();
  public saveBankProcess: BankAccountType;
  public isShowCreditUpdate = false;
  public cbCreditCards: ICbBankAccount[] = [];
  public isDeleteCbBankAccount: boolean = false;
  public removeCbBankAccount: ICbBankAccount = new CbBankAccount();
  public cbBankAccountProcess = CbBankAccountProcess.NO_PROCESS;
  public isSavingBank: boolean = false;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  public created() {
    this.retrieveCbVwBankAccount();
    this.contentService().initRetrieveCbLovs(CbLovType.BANK_CODE);
    this.retireveCbCreditCardByLogin();

    (<any>this.$root).$off('bankAccountParent::changeValue');
    (<any>this.$root).$on('bankAccountParent::changeValue', (fieldName: string, value: any) => {
      this[fieldName] = value;
    });

    this.$root.$off('bankAccountParent::changeFunction');
    this.$root.$on('bankAccountParent::changeFunction', (functionName: string, param1?: any, param2?: any, param3?: any) => {
      this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
    });
  }

  public get cbLovBanks() {
    return this.$store.getters[CbLovType.BANK_CODE];
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  @Emit('save')
  public save() {
    return;
  }
  public onBack() {
    this.$router.go(-1);
  }
  public cancel() {
    this.reset();
    this.$emit('cancel');
  }

  public changeFunction(functionName: string, param1?: any, param2?: any, param3?: any) {
    this[functionName](param1 ? param1 : null, param2 ? param2 : null, param3 ? param3 : null);
  }
  public changeValue(fieldName: string, value: any) {
    this[fieldName] = value;
  }
  public reset() {
    this.tempModelAmountWithdraw = '';
    this.tempModelAccountNumber = '';
    this.isFetchingCbBankAccount = false;
    this.newCbBankAccount = new CbBankAccount();
    this.$v.$reset();
    this.isFetchingCbLovBanks = false;
    this.isSavingCbLovBanks = false;
  }

  public openForm() {
    this.newCbBankAccount = Object.assign({}, this.cbBankAccount);
    this.cbBankAccountProcess = CbBankAccountProcess.CREATE;
    this.contentService().initRetrieveCbLovs(CbLovType.BANK_CODE);
  }

  public openEditBank() {
    this.isEditBank = !this.isEditBank;
    Object.assign(this.newCbBankAccount, this.cbBankAccount);
  }
  public formatExpiredDate(e) {
    let code = e.keyCode;
    let allowedKeys = [8];

    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }
    this.newCbBankAccount.expiryDateLabel = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([\d]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );
  }

  public formatNumberOnly(e) {
    e.currentTarget.value = e.target.value.replace(/\D+/g, '');
  }

  public handleDelete(cbUSerDataType: CbUserDataType) {
    if (cbUSerDataType == CbUserDataType.ID_CARD) {
      this.cbUserDataIdCard = new CbUserData();
    }
    if (cbUSerDataType == CbUserDataType.ID_CARD_SELFIE) {
      this.cbUserDataSelfieWithIdCard = new CbUserData();
    }
  }

  public handleShowCreditUpdate() {
    this.isShowCreditUpdate = true;
  }
  public handleHideCreditUpdate() {
    this.isShowCreditUpdate = false;
  }

  public handleHideBankUpdate() {
    this.cbBankAccountProcess = CbBankAccountProcess.NO_PROCESS;
    this.newCbBankAccount = new CbBankAccount();
  }

  public selectBankCode(e) {
    this.valueBankCode = e.target.value;
    this.newCbBankAccount.bankCode = e.target.value;
  }

  public retrieveCbVwBankAccount() {
    this.isFetchingCbBankAccount = true;

    this.pCbVwBankAccountService()
      .retrieveByLogin()
      .then(res => {
        if (res.data && res.data.length > 0) {
          Object.assign(this.cbVwBankAccount, res.data[0]);

          if (this.cbVwBankAccount.id) {
            this.cbBankAccount = {
              id: this.cbVwBankAccount.id,
              bankCode: this.cbVwBankAccount.bankCode,
              accountNumber: this.cbVwBankAccount.accountNumber,
              accountHolderName: this.cbVwBankAccount.accountHolderName,
              bankAccountType: this.cbVwBankAccount.bankAccountType,
            };
          }
        } else {
          this.cbBankAccount = new CbBankAccount();
        }
      })
      .finally(() => (this.isFetchingCbBankAccount = false));
  }

  public inputCardNumber(e: any) {
    let newString = e.target.value.replace(/\s/g, '');
    if (!Number(newString)) {
      this.newCbBankAccount.accountNumber = newString.replace(/[^,\d]/g, '');
    }
  }

  public async saveBankAccount() {
    this.isSavingCbLovBanks = true;
    let tmpCbBankAccount: ICbBankAccount = {
      ...this.newCbBankAccount,
      ...{
        accountNumber: this.newCbBankAccount.accountNumber.replace(/[^,\d]/g, ''),
      },
      bankAccountType: BankAccountType.DEBIT,
      bankCode: this.valueBankCode == null || this.valueBankCode.length == 0 ? null : this.valueBankCode,
    };
    if (tmpCbBankAccount.id) {
      await this.pCbBankAccountService()
        .partialUpdate(tmpCbBankAccount)
        .then(() => {
          this.reset();
          this.retrieveCbVwBankAccount();
          this.pinNotMatched = false;
          this.visiblePin = false;
          this.cbBankAccountProcess = CbBankAccountProcess.NO_PROCESS;
          this.toaastSuccess();
        })
        .catch(error => {
          this.pinNotMatched = true;
        })
        .finally(() => {
          this.isSavingCbLovBanks = false;
        });
    } else {
      await this.pCbBankAccountService()
        .create(tmpCbBankAccount)
        .then(() => {
          this.reset();
          this.retrieveCbVwBankAccount();
          this.visiblePin = false;
          this.pinNotMatched = false;
          this.cbBankAccountProcess = CbBankAccountProcess.NO_PROCESS;
          this.toaastSuccess();
        })
        .catch(error => {
          this.pinNotMatched = true;
        })
        .finally(() => {
          this.isSavingCbLovBanks = false;
        });
    }
  }

  public async saveBankAccountCC() {
    this.isSavingCbLovBanks = true;

    let tmpCbBankAccount: ICbBankAccount = {
      ...this.newCbCreditCard,
      ...{
        accountNumber: this.newCbCreditCard.accountNumber.replace(/[^,\d]/g, ''),
      },
      bankAccountType: BankAccountType.CREDIT,
    };

    let expiredDate: Date = dayjs().toDate();

    expiredDate = dayjs(expiredDate)
      .set('month', +Number(tmpCbBankAccount.expiryDateLabel.split('/')[0]) - 1)
      .toDate();
    expiredDate = dayjs(expiredDate)
      .set('year', Number(new Date().getFullYear().toString().slice(0, 2) + tmpCbBankAccount.expiryDateLabel.split('/')[1]))
      .toDate();
    tmpCbBankAccount.expiredDate = expiredDate;
    if (tmpCbBankAccount.id) {
      await this.pCbBankAccountService()
        .partialUpdate(tmpCbBankAccount)
        .then(() => {
          // this.reset();
          this.visiblePin = false;
          this.pinNotMatched = false;
          this.isShowCreditUpdate = false;
          // this.newCbCreditCard = new CbBankAccount();
          this.retireveCbCreditCardByLogin();

          (<any>this.$root).$emit('ccUpdate::changeFunction', 'handleCancel');

          this.toaastSuccess();
        })
        .catch(error => {
          this.pinNotMatched = true;
        })
        .finally(() => {
          this.isSavingCbLovBanks = false;
        });
    } else {
      await this.pCbBankAccountService()
        .create(tmpCbBankAccount)
        .then(() => {
          // this.reset();
          this.visiblePin = false;
          this.pinNotMatched = false;
          this.isShowCreditUpdate = false;
          // this.newCbCreditCard = new CbBankAccount();
          this.retireveCbCreditCardByLogin();

          (<any>this.$root).$emit('ccUpdate::changeFunction', 'handleCancel');

          this.toaastSuccess();
        })
        .catch(error => {
          this.pinNotMatched = true;
        })
        .finally(() => {
          this.isSavingCbLovBanks = false;
        });
    }
  }

  public toSetting() {
    this.$router.push({
      name: 'PSettings',
      query: {
        tab: TabSettingType.BANK_ACCOUNT,
      },
    });
  }

  public async saveBankAccountSettingPage(pin: string) {
    this.isSavingBankAccount = true;
    if (this.saveBankProcess == BankAccountType.CREDIT) {
      this.newCbCreditCard.pin = pin;
      await this.saveBankAccountCC();
    } else {
      this.newCbBankAccount.pin = pin;
      await this.saveBankAccount();
    }
    this.isSavingBankAccount = false;
  }

  public submitNewBankCC(cbBankAccount: ICbBankAccount) {
    this.newCbCreditCard = Object.assign({}, cbBankAccount);

    if (this.newCbCreditCard?.id) {
      this.isShowCreditUpdate = false;
    }
    this.handleSubmitCC();
  }

  public handleSubmitBankAccount() {
    if (!this.currentUser.isPinExist) {
      this.globalService().openGlobalDialog(this.$root, 'modal-info-set-pin');
      return;
    }

    this.saveBankProcess = BankAccountType.DEBIT;
    this.visiblePin = true;
  }

  public handleSubmitCC() {
    if (!this.currentUser.isPinExist) {
      this.globalService().openGlobalDialog(this.$root, 'modal-info-set-pin');
      return;
    }

    this.saveBankProcess = BankAccountType.CREDIT;
    this.visiblePin = true;
  }

  public retireveCbCreditCardByLogin() {
    this.isFetchingCbCC = true;
    let params: any = {
      'bankAccountType.equals': BankAccountType.CREDIT,
    };

    this.pCbBankAccountService()
      .retrieveByLogin(params)
      .then(res => {
        if (res.data && res.data.length > 0) {
          this.cbCreditCards = res.data;
        } else {
          this.cbCreditCards = [];
        }
      })
      .catch(err => {
        console.log('ERROR', err.response);
      })
      .finally(() => (this.isFetchingCbCC = true));
  }

  public initValueCC(cbCreditCard: ICbBankAccount) {
    Object.assign(this.newCbCreditCard, cbCreditCard);
    this.newCbCreditCard.expiryDateLabel = dayjs(this.newCbCreditCard.expiredDate).format('MM/YY');
    let accountNumber = '';
    for (let i = 0; i < this.newCbCreditCard.accountNumber.length; i++) {
      if (i % 4 == 0) {
        accountNumber += ' ' + this.newCbCreditCard.accountNumber[i];
      } else {
        accountNumber += this.newCbCreditCard.accountNumber[i];
      }
    }

    this.newCbCreditCard.accountNumber = accountNumber;
    this.isShowCreditUpdate = true;
  }

  public deleteCbBankAccount() {
    this.isDeleteCbBankAccount = true;

    this.pCbBankAccountService()
      .delete(this.removeCbBankAccount.id)
      .then(res => {
        if (this.removeCbBankAccount.bankAccountType == BankAccountType.CREDIT) {
          this.isShowCreditUpdate = false;
          this.retireveCbCreditCardByLogin();
        } else {
          this.retrieveCbVwBankAccount();
        }

        this.removeCbBankAccount = new CbBankAccount();
        this.isDeleteCbBankAccount = false;
        this.globalService().closeGlobalDialog(this.$root, 'modal-notif-bank-delet-item');
        this.toaastDeleteSuccess();
      })
      .catch(err => console.log('ERROR', err.response))
      .finally(() => {
        this.removeCbBankAccount = new CbBankAccount();
        this.isDeleteCbBankAccount = false;
      });
  }

  public openModalDelteBankAccount(cbBankAccount: ICbBankAccount) {
    Object.assign(this.removeCbBankAccount, cbBankAccount);
    this.globalService().openGlobalDialog(this.$root, 'modal-notif-bank-delet-item');
  }

  public handleCloseInputPin() {
    this.visiblePin = false;
  }

  public hendleCancelInputPin() {
    this.newCbCreditCard = new CbBankAccount();
    this.newCbBankAccount = new CbBankAccount();
  }

  public toaastSuccess() {
    const message = this.$t('cbgwApp.cbProfile.submitSuccess');
    this.$bvToast.toast(message.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Success',
      variant: 'success',
      solid: true,
      autoHideDelay: 5000,
    });
  }

  public toaastDeleteSuccess() {
    const message = this.$t('cbGlobal.successfullyDeleted');
    this.$bvToast.toast(message.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Info',
      variant: 'danger',
      solid: true,
      autoHideDelay: 5000,
    });
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get getBankNameByKey(): string {
    let value: string = '';
    if (this.cbBankAccount && this.cbBankAccount.id && this.cbBankAccount.bankCode && this.cbLovBanks.length > 0) {
      let found = this.cbLovBanks.find(e => e.key === this.cbBankAccount.bankCode);
      if (found) {
        value = found.value;
      }
    }
    return value;
  }

  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  @Watch('isMobile')
  public isMobileListener() {
    if (!this.isMobile) {
      this.$router.push({ name: 'PSettings', query: { tab: TabSettingType.BANK_ACCOUNT } });
    }
  }
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
