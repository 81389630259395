var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-footer-copyright pt-3" }, [
    _c(
      "div",
      {
        staticClass:
          "c-container d-flex flex-column flex-lg-row justify-content-between align-items-center pt-3",
      },
      [
        _c("div", { staticClass: "mb-3" }, [
          _c("img", {
            staticClass: "c-footer-size-logo",
            attrs: {
              src: `${_vm.$env.CDN_CB}/content/cb-img/ic-logo-black.png`,
              alt: "Image Klikjob",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-3" }, [
          _c(
            "p",
            {
              staticClass:
                "font-size-14 font-roboto font-weight-light mx-auto cj-color-black-primary",
              domProps: { textContent: _vm._s(_vm.$t("cbGlobal.copyright")) },
            },
            [
              _vm._v(
                "\n        2024 Klikjob by PT. BIONIC TEKNOLOGI INDONESIA\n      "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex mb-3" }, [
          _c(
            "a",
            { attrs: { href: "" } },
            [
              _c("icon", {
                staticClass: "mr-2",
                attrs: { name: "ic_twitter", width: "1.5rem" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: "https://www.linkedin.com/company/pt-bionic-teknologi-indonesia",
              },
            },
            [
              _c("icon", {
                staticClass: "mr-2",
                attrs: { name: "ic_linkedin", width: "1.5rem" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: "" } },
            [
              _c("icon", {
                staticClass: "mr-2",
                attrs: { name: "ic_facebook", width: "1.5rem" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "cursor-pointer",
              attrs: {
                href: "https://instagram.com/bti_world?igshid=YmMyMTA2M2Y=",
              },
            },
            [_c("icon", { attrs: { name: "ic_instagram", width: "1.75rem" } })],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { class: _vm.isShowTabFooter ? "height-wrapper" : "" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }