var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "vac-fade-spinner", appear: "" } }, [
    _vm.show
      ? _c(
          "div",
          {
            staticClass: "c-loader vac-loader-wrapper",
            class: {
              "vac-container-center": !_vm.infinite,
              "vac-container-top": _vm.infinite,
            },
          },
          [
            _vm._t(
              "spinner-icon",
              function () {
                return [_c("div", { attrs: { id: "vac-circle" } })]
              },
              null,
              { show: _vm.show, infinite: _vm.infinite }
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }