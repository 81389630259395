import { Inject, Component, Vue } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import GlobalService from '@/services/global.service';
import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import CUserPhotoOnline from '@/components/c-user-photo-online/c-user-photo-online.vue';
import cUserBalance from '@/components/c-user-balance/c-user-balance.vue';
import CPhWarning from '@/components/c-alert copy/c-ph-warning.vue';
@Component({
  components: {
    Icon,
    CUserPhotoOnline,
    cUserBalance,
    CPhWarning,
  },
})
export default class CNavbarDropdownMenu extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') private globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public user = 'BUYER';
  public userRole: CbLoginType = null;
  public cbLoginType = CbLoginType;
  public t;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    this.$store.watch(
      () => this.$store.getters.loginType,
      () => {
        this.userRole = this.$store.getters.loginType;
      }
    );
    this.userRole = this.$store.getters.loginType;
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public logout() {
    this.$emit('select');
    this.globalService().logout();
  }
  public toPage(conditionName: string) {
    let name = '';
    const params: any = {};
    const query: any = {};
    if (this.userRole == this.cbLoginType.BUYER) {
      if (conditionName == 'manageRequest') {
        name = 'PBuyerManageRequest';
      } else if (conditionName == 'postRequest') {
        name = 'PBuyerPostRequest';
      } else if (conditionName == 'pawWishlist') {
        name = 'PPawWishlist';
      }
    } else if (this.userRole == this.cbLoginType.PARTNER) {
      if (conditionName == 'managePaw') {
        name = 'PPawManage';
      } else if (conditionName == 'createPaw') {
        name = 'PPawCreateOne';
        params['type'] = 'NEW';
      } else if (conditionName == 'findProject') {
        name = 'PFindProject';
        params['projectTab'] = 'recommendation';
      } else if (conditionName == 'projectWishlist') {
        name = 'PFindProject';
        params['projectTab'] = 'wishlist';
      }
    }
    if (conditionName == 'balance') {
      name = 'PAccountBalance';
    } else if (conditionName == 'dashboard') {
      name = 'PDashboard';
    } else if (conditionName == 'billing') {
      name = 'PBillingHisories';
    } else if (conditionName == 'setting') {
      name = 'PSettings';
      query['tab'] = 'ACCOUNT';
    } else if (conditionName == 'helpCenter') {
      name = 'PHelpCenter';
      params['info'] = 'info';
    } else if (conditionName == 'manageOrder') {
      name = 'PManageOrder';
    } else if (conditionName == 'profile') {
      name = 'PProfile';
    }
    this.$router.push({ name, params, query });
    this.$emit('select');
  }

  public openModalLanguage() {
    this.globalService().openGlobalDialog(this.$root, 'modal-c-change-language');
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser(): ICbVwUser {
    return this.$store.getters.currentUser;
  }
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
}
