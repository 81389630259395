
import SvgIcon from '../../../../components/SvgIcon/SvgIcon.vue';
import FormatMessage from '../../../../components/FormatMessage/FormatMessage.vue';

import AudioPlayer from '../AudioPlayer/AudioPlayer.vue';

const { isAudioFile, isImageFile, isVideoFile } = require('../../../../utils/media-file');
import { Inject, Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({
  components: {
    AudioPlayer,
    SvgIcon,
    FormatMessage,
  },
  directives: {
    // clickOutside: vClickOutside.directive,
  },
  watch: {},
})
export default class MessageReply extends Vue {
  @Prop({ type: Object, required: true })
  private message;
  @Prop({ type: Object, required: true })
  private textFormatting;
  @Prop({ type: Object, required: true })
  private linkOptions;
  @Prop({ type: Array, required: true })
  private roomUsers;

  // computed: {
  public get replyUsername() {
    const { authorId } = this.message.replyMessage;
    const replyUser = this.roomUsers.find(user => user._id === authorId);
    return replyUser ? replyUser.username : '';
  }
  public get firstFile() {
    return this.message.replyMessage?.files?.length ? this.message.replyMessage.files[0] : {};
  }
  public get isAudio() {
    return isAudioFile(this.firstFile);
  }
  public get isImage() {
    return isImageFile(this.firstFile);
  }
  public get isVideo() {
    return isVideoFile(this.firstFile);
  }
  public get isOtherFile() {
    return this.message.replyMessage.files?.length && !this.isAudio && !this.isVideo && !this.isImage;
  }
  // },
}
