var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "c-item-time-region" }, [
    _c("div", { on: { click: _vm.deleteTimeRegion } }, [
      _vm.isThirdClock
        ? _c(
            "div",
            { staticClass: "ic-trash cursor-pointer ml-2 cj-bg-white-primary" },
            [
              _c("icon", {
                staticClass: "cj-fill-grey-secondary",
                attrs: {
                  name: "ic_outline_delete",
                  width: "14px",
                  height: "14px",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "clock", style: `width:${_vm.width}` }, [
      _c("div", {
        ref: "clockHour",
        staticClass: "hand hour-hand",
        class: _vm.isDaylight ? "cj-bg-black-primary" : "cj-bg-white-primary",
      }),
      _vm._v(" "),
      _c("div", {
        ref: "clockMinute",
        staticClass: "hand min-hand",
        class: _vm.isDaylight ? "cj-bg-black-primary" : "cj-bg-white-primary",
      }),
      _vm._v(" "),
      _c("div", {
        ref: "clockSecond",
        staticClass: "hand second-hand cj-bg-red-primary",
      }),
      _vm._v(" "),
      _c("div", { staticClass: "op-hour" }, [
        _c("div", { staticClass: "op-hour__image-container" }, [
          _c("div", { staticClass: "position-relative" }, [
            _vm.isDaylight
              ? _c(
                  "svg",
                  {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      viewBox: "0 0 2100 2100",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter0_ii_667_11614)" } },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "1050",
                            cy: "1050",
                            r: "1050",
                            fill: "#9FC6EB",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("circle", {
                      attrs: {
                        cx: "1050",
                        cy: "1050",
                        r: "1047.5",
                        stroke: "#3F3F3F",
                        "stroke-opacity": "0.1",
                        "stroke-width": "5",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter1_d_667_11614)" } },
                      [
                        _c(
                          "g",
                          { attrs: { filter: "url(#filter2_i_667_11614)" } },
                          [
                            _c("circle", {
                              attrs: {
                                cx: "1052.5",
                                cy: "1047.5",
                                r: "327.5",
                                fill: "#F8D755",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "1015",
                            y: "445",
                            width: "75",
                            height: "215",
                            rx: "37.5",
                            fill: "#F8D755",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "1015",
                            y: "1435",
                            width: "75",
                            height: "215",
                            rx: "37.5",
                            fill: "#F8D755",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "1655",
                            y: "1010",
                            width: "75",
                            height: "215",
                            rx: "37.5",
                            transform: "rotate(90 1655 1010)",
                            fill: "#F8D755",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "665",
                            y: "1010",
                            width: "75",
                            height: "215",
                            rx: "37.5",
                            transform: "rotate(90 665 1010)",
                            fill: "#F8D755",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "1423.75",
                            y: "623.207",
                            width: "75",
                            height: "175.036",
                            rx: "37.5",
                            transform: "rotate(45 1423.75 623.207)",
                            fill: "#F8D755",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "751.973",
                            y: "1294.99",
                            width: "75",
                            height: "175.044",
                            rx: "37.5",
                            transform: "rotate(45 751.973 1294.99)",
                            fill: "#F8D755",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "1476.82",
                            y: "1418.78",
                            width: "75",
                            height: "175.071",
                            rx: "37.5",
                            transform: "rotate(135 1476.82 1418.78)",
                            fill: "#F8D755",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            x: "805.02",
                            y: "746.982",
                            width: "75",
                            height: "175.057",
                            rx: "37.5",
                            transform: "rotate(135 805.02 746.982)",
                            fill: "#F8D755",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M1050 2100C1629.9 2100 2100 1629.9 2100 1050C2100 1048.7 2100 1047.39 2099.99 1046.09C2051.62 1016.84 1994.9 1000 1934.24 1000C1757.09 1000 1613.48 1143.61 1613.48 1320.76C1613.48 1414.01 1653.27 1497.97 1716.79 1556.58C1681.68 1590.03 1656.27 1633.59 1645.24 1682.59C1619.46 1672.64 1591.44 1667.19 1562.14 1667.19C1461.59 1667.19 1376.04 1731.45 1344.33 1821.15L1344.02 1821.15C1326.79 1821.15 1310.13 1823.58 1294.35 1828.11C1255.21 1769.75 1188.63 1731.34 1113.08 1731.34C1024.46 1731.34 948.18 1784.2 914.047 1860.1C892.599 1849.65 868.604 1843.61 843.245 1843.18C795.681 1725.58 680.411 1642.62 545.764 1642.62C440.154 1642.62 346.464 1693.66 288.016 1772.42C479.361 1974.17 749.987 2100 1050 2100Z",
                        fill: "url(#paint0_linear_667_11614)",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "defs",
                      [
                        _c(
                          "filter",
                          {
                            attrs: {
                              id: "filter0_ii_667_11614",
                              x: "-30",
                              y: "-5",
                              width: "2155",
                              height: "2150",
                              filterUnits: "userSpaceOnUse",
                              "color-interpolation-filters": "sRGB",
                            },
                          },
                          [
                            _c("feFlood", {
                              attrs: {
                                "flood-opacity": "0",
                                result: "BackgroundImageFix",
                              },
                            }),
                            _vm._v(" "),
                            _c("feBlend", {
                              attrs: {
                                mode: "normal",
                                in: "SourceGraphic",
                                in2: "BackgroundImageFix",
                                result: "shape",
                              },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                in: "SourceAlpha",
                                type: "matrix",
                                values:
                                  "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                                result: "hardAlpha",
                              },
                            }),
                            _vm._v(" "),
                            _c("feMorphology", {
                              attrs: {
                                radius: "5",
                                operator: "dilate",
                                in: "SourceAlpha",
                                result: "effect1_innerShadow_667_11614",
                              },
                            }),
                            _vm._v(" "),
                            _c("feOffset", { attrs: { dx: "25", dy: "45" } }),
                            _vm._v(" "),
                            _c("feGaussianBlur", {
                              attrs: { stdDeviation: "67.5" },
                            }),
                            _vm._v(" "),
                            _c("feComposite", {
                              attrs: {
                                in2: "hardAlpha",
                                operator: "arithmetic",
                                k2: "-1",
                                k3: "1",
                              },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                type: "matrix",
                                values:
                                  "0 0 0 0 0.435294 0 0 0 0 0.627451 0 0 0 0 0.815686 0 0 0 1 0",
                              },
                            }),
                            _vm._v(" "),
                            _c("feBlend", {
                              attrs: {
                                mode: "normal",
                                in2: "shape",
                                result: "effect1_innerShadow_667_11614",
                              },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                in: "SourceAlpha",
                                type: "matrix",
                                values:
                                  "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                                result: "hardAlpha",
                              },
                            }),
                            _vm._v(" "),
                            _c("feMorphology", {
                              attrs: {
                                radius: "10",
                                operator: "erode",
                                in: "SourceAlpha",
                                result: "effect2_innerShadow_667_11614",
                              },
                            }),
                            _vm._v(" "),
                            _c("feOffset", { attrs: { dx: "-30", dy: "-5" } }),
                            _vm._v(" "),
                            _c("feGaussianBlur", {
                              attrs: { stdDeviation: "25" },
                            }),
                            _vm._v(" "),
                            _c("feComposite", {
                              attrs: {
                                in2: "hardAlpha",
                                operator: "arithmetic",
                                k2: "-1",
                                k3: "1",
                              },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                type: "matrix",
                                values:
                                  "0 0 0 0 0.678785 0 0 0 0 0.854324 0 0 0 0 0.941667 0 0 0 1 0",
                              },
                            }),
                            _vm._v(" "),
                            _c("feBlend", {
                              attrs: {
                                mode: "normal",
                                in2: "effect1_innerShadow_667_11614",
                                result: "effect2_innerShadow_667_11614",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "filter",
                          {
                            attrs: {
                              id: "filter1_d_667_11614",
                              x: "400",
                              y: "415",
                              width: "1305",
                              height: "1305",
                              filterUnits: "userSpaceOnUse",
                              "color-interpolation-filters": "sRGB",
                            },
                          },
                          [
                            _c("feFlood", {
                              attrs: {
                                "flood-opacity": "0",
                                result: "BackgroundImageFix",
                              },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                in: "SourceAlpha",
                                type: "matrix",
                                values:
                                  "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                                result: "hardAlpha",
                              },
                            }),
                            _vm._v(" "),
                            _c("feOffset", { attrs: { dy: "20" } }),
                            _vm._v(" "),
                            _c("feGaussianBlur", {
                              attrs: { stdDeviation: "25" },
                            }),
                            _vm._v(" "),
                            _c("feComposite", {
                              attrs: { in2: "hardAlpha", operator: "out" },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                type: "matrix",
                                values:
                                  "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0",
                              },
                            }),
                            _vm._v(" "),
                            _c("feBlend", {
                              attrs: {
                                mode: "normal",
                                in2: "BackgroundImageFix",
                                result: "effect1_dropShadow_667_11614",
                              },
                            }),
                            _vm._v(" "),
                            _c("feBlend", {
                              attrs: {
                                mode: "normal",
                                in: "SourceGraphic",
                                in2: "effect1_dropShadow_667_11614",
                                result: "shape",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "filter",
                          {
                            attrs: {
                              id: "filter2_i_667_11614",
                              x: "715",
                              y: "700",
                              width: "665",
                              height: "675",
                              filterUnits: "userSpaceOnUse",
                              "color-interpolation-filters": "sRGB",
                            },
                          },
                          [
                            _c("feFlood", {
                              attrs: {
                                "flood-opacity": "0",
                                result: "BackgroundImageFix",
                              },
                            }),
                            _vm._v(" "),
                            _c("feBlend", {
                              attrs: {
                                mode: "normal",
                                in: "SourceGraphic",
                                in2: "BackgroundImageFix",
                                result: "shape",
                              },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                in: "SourceAlpha",
                                type: "matrix",
                                values:
                                  "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                                result: "hardAlpha",
                              },
                            }),
                            _vm._v(" "),
                            _c("feOffset", { attrs: { dx: "-10", dy: "-20" } }),
                            _vm._v(" "),
                            _c("feGaussianBlur", {
                              attrs: { stdDeviation: "15" },
                            }),
                            _vm._v(" "),
                            _c("feComposite", {
                              attrs: {
                                in2: "hardAlpha",
                                operator: "arithmetic",
                                k2: "-1",
                                k3: "1",
                              },
                            }),
                            _vm._v(" "),
                            _c("feColorMatrix", {
                              attrs: {
                                type: "matrix",
                                values:
                                  "0 0 0 0 0.904167 0 0 0 0 0.754674 0 0 0 0 0.165764 0 0 0 1 0",
                              },
                            }),
                            _vm._v(" "),
                            _c("feBlend", {
                              attrs: {
                                mode: "normal",
                                in2: "shape",
                                result: "effect1_innerShadow_667_11614",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "linearGradient",
                          {
                            attrs: {
                              id: "paint0_linear_667_11614",
                              x1: "1480",
                              y1: "1622.5",
                              x2: "2432.5",
                              y2: "2475",
                              gradientUnits: "userSpaceOnUse",
                            },
                          },
                          [
                            _c("stop", { attrs: { "stop-color": "#F5FDFF" } }),
                            _vm._v(" "),
                            _c("stop", {
                              attrs: {
                                offset: "1",
                                "stop-color": "#F5FDFF",
                                "stop-opacity": "0",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _c(
                  "svg",
                  {
                    attrs: {
                      width: "100%",
                      height: "100%",
                      viewBox: "0 0 2100 2100",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter0_ii_667_11657)" } },
                      [
                        _c("circle", {
                          attrs: {
                            cx: "1050",
                            cy: "1050",
                            r: "1050",
                            fill: "#222734",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("circle", {
                      attrs: {
                        cx: "1050",
                        cy: "1050",
                        r: "1047.5",
                        stroke: "#3F3F3F",
                        "stroke-opacity": "0.1",
                        "stroke-width": "5",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter1_ii_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1052.5 1375C1233.37 1375 1380 1228.37 1380 1047.5C1380 1040.01 1379.75 1032.58 1379.25 1025.22C1355.18 1133.8 1258.32 1215 1142.5 1215C1008.57 1215 900 1106.43 900 972.5C900 841.503 1003.87 734.766 1133.74 730.155C1107.76 723.525 1080.54 720 1052.5 720C871.627 720 725 866.627 725 1047.5C725 1228.37 871.627 1375 1052.5 1375Z",
                            fill: "#C5C9D1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter2_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M775.545 530.002C775.529 557.927 752.91 580.565 724.993 580.615C724.883 582.588 724.096 584.381 722.859 585.764L770.234 633.138C771.759 632.085 773.579 631.428 775.545 631.316L775.545 631.259C775.545 603.289 798.22 580.615 826.189 580.615L826.282 580.615L799.743 614.762L811.758 602.747L826.282 580.615L826.389 580.615L826.389 580.614L826.282 580.615L826.189 580.615C798.23 580.615 775.562 557.957 775.545 530.002Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter3_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1608.09 1100.51C1607.8 1121.31 1590.87 1138.09 1570.01 1138.13L1570.01 1138.13C1590.87 1138.18 1607.8 1154.95 1608.09 1175.75C1608.38 1154.93 1625.35 1138.13 1646.24 1138.13C1646.3 1138.13 1646.35 1138.13 1646.4 1138.14L1646.4 1138.13C1646.35 1138.13 1646.3 1138.13 1646.24 1138.13C1625.35 1138.13 1608.37 1121.34 1608.09 1100.51Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter4_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1233.09 1665.51C1232.8 1686.31 1215.87 1703.09 1195.01 1703.13L1195.01 1703.13C1215.87 1703.18 1232.8 1719.95 1233.09 1740.75C1233.38 1719.93 1250.35 1703.13 1271.24 1703.13L1271.28 1703.13L1271.4 1703.14L1271.4 1703.13L1271.33 1703.13L1271.24 1703.13C1250.35 1703.13 1233.37 1686.34 1233.09 1665.51Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter5_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M428.088 1175.51C427.801 1196.31 410.871 1213.09 390.012 1213.13L390.012 1213.13C410.87 1213.18 427.8 1229.95 428.088 1250.75C428.376 1229.93 445.348 1213.13 466.242 1213.13C466.271 1213.13 466.3 1213.13 466.33 1213.13C466.354 1213.13 466.378 1213.14 466.402 1213.14L466.401 1213.13C466.348 1213.13 466.295 1213.13 466.242 1213.13C445.347 1213.13 428.374 1196.34 428.088 1175.51Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter6_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1448.09 570.51C1447.8 591.311 1430.87 608.089 1410.01 608.131L1410.01 608.135C1430.87 608.177 1447.8 624.954 1448.09 645.754C1448.38 624.928 1465.35 608.135 1486.24 608.135C1486.27 608.135 1486.3 608.135 1486.33 608.135C1486.35 608.135 1486.38 608.135 1486.4 608.135L1486.4 608.132C1486.35 608.132 1486.3 608.132 1486.24 608.132C1465.35 608.132 1448.37 591.337 1448.09 570.51Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter7_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M639.57 539.792L639.442 539.792C622.983 539.792 609.639 526.455 609.629 509.998L609.628 509.998C609.617 526.436 596.304 539.762 579.871 539.793L579.871 539.794C596.31 539.825 609.628 553.161 609.628 569.608L609.628 569.638L609.629 569.638L609.629 569.608C609.629 553.142 622.977 539.794 639.443 539.794L639.57 539.794L639.57 539.792Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter8_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1389.57 479.792L1389.44 479.792C1372.98 479.792 1359.64 466.455 1359.63 449.998L1359.63 449.998C1359.62 466.436 1346.3 479.762 1329.87 479.793L1329.87 479.794C1346.31 479.825 1359.63 493.161 1359.63 509.608L1359.63 509.638L1359.63 509.638L1359.63 509.608C1359.63 493.142 1372.98 479.794 1389.44 479.794L1389.57 479.794L1389.57 479.792Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter9_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M657.754 405.145C657.981 414.934 679.309 422.847 705.613 422.875C679.309 422.903 657.981 430.817 657.755 440.606C657.529 430.828 636.252 422.922 609.99 422.875C636.251 422.828 657.528 414.922 657.754 405.145Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter10_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1052.85 1570C1052.84 1579.86 1044.86 1587.85 1035 1587.88C1044.86 1587.9 1052.85 1595.9 1052.85 1605.77L1052.85 1605.79L1052.85 1605.79L1052.85 1605.77C1052.85 1595.91 1060.81 1587.92 1070.65 1587.88C1060.82 1587.83 1052.86 1579.85 1052.85 1570L1052.85 1570Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter11_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1357.85 1590C1357.84 1599.86 1349.86 1607.85 1340 1607.88C1349.86 1607.9 1357.85 1615.9 1357.85 1625.77L1357.85 1625.79L1357.85 1625.79L1357.85 1625.77C1357.85 1615.91 1365.81 1607.92 1375.65 1607.88C1365.82 1607.83 1357.86 1599.85 1357.85 1590L1357.85 1590Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter12_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M357.847 1320C357.84 1329.86 349.858 1337.85 340.004 1337.88C349.863 1337.9 357.847 1345.9 357.847 1355.77L357.847 1355.79L357.852 1355.79L357.852 1355.77C357.852 1345.91 365.814 1337.92 375.653 1337.88C365.818 1337.83 357.859 1329.85 357.852 1320L357.847 1320Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter13_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M499.993 1355C499.986 1366.01 491.114 1374.95 480.129 1375.06C491.119 1375.17 499.993 1384.12 499.993 1395.13L499.993 1395.16L500.001 1395.16L500.001 1395.13C500.001 1384.12 508.875 1375.17 519.865 1375.06C508.879 1374.95 500.008 1366.01 500.001 1355L499.993 1355Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter14_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1722.85 1255C1722.84 1264.86 1714.86 1272.85 1705 1272.88C1714.86 1272.9 1722.85 1280.9 1722.85 1290.77L1722.85 1290.79L1722.85 1290.79L1722.85 1290.77C1722.85 1280.91 1730.81 1272.92 1740.65 1272.88C1730.82 1272.83 1722.86 1264.85 1722.85 1255L1722.85 1255Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { filter: "url(#filter15_f_667_11657)" } },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            "clip-rule": "evenodd",
                            d: "M1587.85 530.002C1587.84 539.861 1579.86 547.853 1570 547.877C1579.86 547.901 1587.85 555.901 1587.85 565.766L1587.85 565.788L1587.85 565.788L1587.85 565.765C1587.85 555.915 1595.81 547.924 1605.65 547.877C1595.82 547.83 1587.86 539.846 1587.85 530.002L1587.85 530.002Z",
                            fill: "#E1E1E1",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("defs", [
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter0_ii_667_11657",
                            x: "-30",
                            y: "-5",
                            width: "2155",
                            height: "2150",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              in: "SourceAlpha",
                              type: "matrix",
                              values:
                                "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                              result: "hardAlpha",
                            },
                          }),
                          _vm._v(" "),
                          _c("feMorphology", {
                            attrs: {
                              radius: "5",
                              operator: "dilate",
                              in: "SourceAlpha",
                              result: "effect1_innerShadow_667_11657",
                            },
                          }),
                          _vm._v(" "),
                          _c("feOffset", { attrs: { dx: "25", dy: "45" } }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: { stdDeviation: "57.5" },
                          }),
                          _vm._v(" "),
                          _c("feComposite", {
                            attrs: {
                              in2: "hardAlpha",
                              operator: "arithmetic",
                              k2: "-1",
                              k3: "1",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              type: "matrix",
                              values:
                                "0 0 0 0 0.542014 0 0 0 0 0.546605 0 0 0 0 0.583333 0 0 0 1 0",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in2: "shape",
                              result: "effect1_innerShadow_667_11657",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              in: "SourceAlpha",
                              type: "matrix",
                              values:
                                "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                              result: "hardAlpha",
                            },
                          }),
                          _vm._v(" "),
                          _c("feMorphology", {
                            attrs: {
                              radius: "10",
                              operator: "erode",
                              in: "SourceAlpha",
                              result: "effect2_innerShadow_667_11657",
                            },
                          }),
                          _vm._v(" "),
                          _c("feOffset", { attrs: { dx: "-30", dy: "-5" } }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: { stdDeviation: "25" },
                          }),
                          _vm._v(" "),
                          _c("feComposite", {
                            attrs: {
                              in2: "hardAlpha",
                              operator: "arithmetic",
                              k2: "-1",
                              k3: "1",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              type: "matrix",
                              values:
                                "0 0 0 0 0.105882 0 0 0 0 0.113725 0 0 0 0 0.160784 0 0 0 1 0",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in2: "effect1_innerShadow_667_11657",
                              result: "effect2_innerShadow_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter1_ii_667_11657",
                            x: "715",
                            y: "710",
                            width: "675",
                            height: "675",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              in: "SourceAlpha",
                              type: "matrix",
                              values:
                                "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                              result: "hardAlpha",
                            },
                          }),
                          _vm._v(" "),
                          _c("feOffset", { attrs: { dx: "10", dy: "10" } }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: { stdDeviation: "10" },
                          }),
                          _vm._v(" "),
                          _c("feComposite", {
                            attrs: {
                              in2: "hardAlpha",
                              operator: "arithmetic",
                              k2: "-1",
                              k3: "1",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              type: "matrix",
                              values:
                                "0 0 0 0 0.878837 0 0 0 0 0.901616 0 0 0 0 0.945833 0 0 0 1 0",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in2: "shape",
                              result: "effect1_innerShadow_667_11657",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              in: "SourceAlpha",
                              type: "matrix",
                              values:
                                "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
                              result: "hardAlpha",
                            },
                          }),
                          _vm._v(" "),
                          _c("feOffset", { attrs: { dx: "-10", dy: "-10" } }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: { stdDeviation: "10" },
                          }),
                          _vm._v(" "),
                          _c("feComposite", {
                            attrs: {
                              in2: "hardAlpha",
                              operator: "arithmetic",
                              k2: "-1",
                              k3: "1",
                            },
                          }),
                          _vm._v(" "),
                          _c("feColorMatrix", {
                            attrs: {
                              type: "matrix",
                              values:
                                "0 0 0 0 0.316979 0 0 0 0 0.347225 0 0 0 0 0.425 0 0 0 0.25 0",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in2: "effect1_innerShadow_667_11657",
                              result: "effect2_innerShadow_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter2_f_667_11657",
                            x: "717.859",
                            y: "525.002",
                            width: "113.531",
                            height: "113.137",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "2.5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter3_f_667_11657",
                            x: "1565.01",
                            y: "1095.51",
                            width: "86.3906",
                            height: "85.2441",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "2.5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter4_f_667_11657",
                            x: "1185.01",
                            y: "1655.51",
                            width: "96.3906",
                            height: "95.2441",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter5_f_667_11657",
                            x: "380.012",
                            y: "1165.51",
                            width: "96.3906",
                            height: "95.2441",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter6_f_667_11657",
                            x: "1400.01",
                            y: "560.51",
                            width: "96.3906",
                            height: "95.2441",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter7_f_667_11657",
                            x: "569.871",
                            y: "499.998",
                            width: "79.6992",
                            height: "79.6406",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter8_f_667_11657",
                            x: "1324.87",
                            y: "444.998",
                            width: "69.6992",
                            height: "69.6406",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "2.5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter9_f_667_11657",
                            x: "599.992",
                            y: "395.145",
                            width: "115.621",
                            height: "55.4609",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter10_f_667_11657",
                            x: "1030",
                            y: "1565",
                            width: "45.6484",
                            height: "45.7861",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "2.5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter11_f_667_11657",
                            x: "1335",
                            y: "1585",
                            width: "45.6484",
                            height: "45.7861",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "2.5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter12_f_667_11657",
                            x: "330.004",
                            y: "1310",
                            width: "55.6484",
                            height: "55.7852",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter13_f_667_11657",
                            x: "475.129",
                            y: "1350",
                            width: "49.7344",
                            height: "50.1582",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "2.5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter14_f_667_11657",
                            x: "1695",
                            y: "1245",
                            width: "55.6484",
                            height: "55.7852",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "filter",
                        {
                          attrs: {
                            id: "filter15_f_667_11657",
                            x: "1565",
                            y: "525.002",
                            width: "45.6484",
                            height: "45.7852",
                            filterUnits: "userSpaceOnUse",
                            "color-interpolation-filters": "sRGB",
                          },
                        },
                        [
                          _c("feFlood", {
                            attrs: {
                              "flood-opacity": "0",
                              result: "BackgroundImageFix",
                            },
                          }),
                          _vm._v(" "),
                          _c("feBlend", {
                            attrs: {
                              mode: "normal",
                              in: "SourceGraphic",
                              in2: "BackgroundImageFix",
                              result: "shape",
                            },
                          }),
                          _vm._v(" "),
                          _c("feGaussianBlur", {
                            attrs: {
                              stdDeviation: "2.5",
                              result: "effect1_foregroundBlur_667_11657",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
            _vm._v(" "),
            _c("div", { staticClass: "time-wrapper" }, [
              _vm.isShowOpHour
                ? _c("canvas", {
                    ref: "canvas",
                    staticClass: "op-hour__line",
                    attrs: { width: "100%", height: "100%" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    id: "main",
                    width: "100%",
                    height: "100%",
                    viewBox: "0 0 2100 2100",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      id: "clock_12",
                      d: "M1012.95 148.636V265H998.864V163.409H998.182L969.773 182.273V167.955L998.864 148.636H1012.95ZM1046.42 265V254.773L1084.83 212.727C1089.34 207.803 1093.05 203.523 1095.97 199.886C1098.88 196.212 1101.04 192.765 1102.44 189.545C1103.88 186.288 1104.6 182.879 1104.6 179.318C1104.6 175.227 1103.62 171.686 1101.65 168.693C1099.72 165.701 1097.06 163.39 1093.69 161.761C1090.32 160.133 1086.53 159.318 1082.33 159.318C1077.86 159.318 1073.96 160.246 1070.62 162.102C1067.33 163.92 1064.77 166.477 1062.95 169.773C1061.17 173.068 1060.28 176.932 1060.28 181.364H1046.88C1046.88 174.545 1048.45 168.561 1051.59 163.409C1054.73 158.258 1059.02 154.242 1064.43 151.364C1069.89 148.485 1076 147.045 1082.78 147.045C1089.6 147.045 1095.64 148.485 1100.91 151.364C1106.17 154.242 1110.3 158.125 1113.3 163.011C1116.29 167.898 1117.78 173.333 1117.78 179.318C1117.78 183.598 1117.01 187.784 1115.45 191.875C1113.94 195.928 1111.29 200.455 1107.5 205.455C1103.75 210.417 1098.54 216.477 1091.88 223.636L1065.74 251.591V252.5H1119.83V265H1046.42Z",
                      fill: _vm.isDaylight ? "black" : "white",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_1",
                      d: "M1445.66 354.678L1508.16 246.425",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_2",
                      d: "M1740.31 645.668L1848.57 583.168",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_3",
                      d: "M1921.49 1111.59C1913.99 1111.59 1907.3 1110.3 1901.43 1107.73C1895.6 1105.15 1890.96 1101.57 1887.51 1096.99C1884.1 1092.37 1882.25 1087.01 1881.94 1080.91H1896.26C1896.56 1084.66 1897.85 1087.9 1900.12 1090.62C1902.4 1093.31 1905.37 1095.4 1909.05 1096.88C1912.72 1098.35 1916.79 1099.09 1921.26 1099.09C1926.26 1099.09 1930.69 1098.22 1934.56 1096.48C1938.42 1094.73 1941.45 1092.31 1943.65 1089.2C1945.84 1086.1 1946.94 1082.5 1946.94 1078.41C1946.94 1074.13 1945.88 1070.36 1943.76 1067.1C1941.64 1063.81 1938.53 1061.23 1934.44 1059.38C1930.35 1057.52 1925.35 1056.59 1919.44 1056.59H1910.12V1044.09H1919.44C1924.06 1044.09 1928.12 1043.26 1931.6 1041.59C1935.13 1039.92 1937.87 1037.58 1939.84 1034.55C1941.85 1031.52 1942.85 1027.95 1942.85 1023.86C1942.85 1019.92 1941.98 1016.5 1940.24 1013.58C1938.5 1010.66 1936.03 1008.39 1932.85 1006.76C1929.71 1005.13 1926 1004.32 1921.72 1004.32C1917.7 1004.32 1913.91 1005.06 1910.35 1006.53C1906.83 1007.97 1903.95 1010.08 1901.72 1012.84C1899.48 1015.57 1898.27 1018.86 1898.08 1022.73H1884.44C1884.67 1016.63 1886.51 1011.29 1889.95 1006.7C1893.4 1002.08 1897.91 998.485 1903.48 995.909C1909.08 993.333 1915.24 992.045 1921.94 992.045C1929.14 992.045 1935.31 993.504 1940.47 996.42C1945.62 999.299 1949.58 1003.11 1952.34 1007.84C1955.11 1012.58 1956.49 1017.69 1956.49 1023.18C1956.49 1029.73 1954.77 1035.32 1951.32 1039.94C1947.91 1044.56 1943.27 1047.77 1937.4 1049.55V1050.45C1944.75 1051.67 1950.48 1054.79 1954.61 1059.83C1958.74 1064.83 1960.81 1071.02 1960.81 1078.41C1960.81 1084.73 1959.08 1090.42 1955.64 1095.45C1952.23 1100.45 1947.57 1104.39 1941.66 1107.27C1935.75 1110.15 1929.03 1111.59 1921.49 1111.59Z",
                      fill: _vm.isDaylight ? "black" : "white",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_4",
                      d: "M1853.57 1508.17L1745.32 1445.67",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_5",
                      d: "M1516.84 1848.57L1454.34 1740.32",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_6",
                      d: "M1050.76 1971.59C1045.99 1971.52 1041.21 1970.61 1036.44 1968.86C1031.67 1967.12 1027.31 1964.19 1023.37 1960.06C1019.43 1955.89 1016.27 1950.27 1013.88 1943.18C1011.5 1936.06 1010.31 1927.12 1010.31 1916.36C1010.31 1906.06 1011.27 1896.93 1013.2 1888.98C1015.13 1880.98 1017.94 1874.26 1021.61 1868.81C1025.29 1863.31 1029.72 1859.15 1034.91 1856.31C1040.13 1853.47 1046.03 1852.05 1052.58 1852.05C1059.09 1852.05 1064.89 1853.35 1069.96 1855.97C1075.08 1858.54 1079.24 1862.14 1082.46 1866.76C1085.68 1871.38 1087.77 1876.7 1088.71 1882.73H1074.85C1073.56 1877.5 1071.06 1873.16 1067.35 1869.72C1063.64 1866.27 1058.71 1864.55 1052.58 1864.55C1043.56 1864.55 1036.46 1868.47 1031.27 1876.31C1026.12 1884.15 1023.53 1895.15 1023.49 1909.32H1024.4C1026.52 1906.1 1029.04 1903.35 1031.95 1901.08C1034.91 1898.77 1038.17 1896.99 1041.73 1895.74C1045.29 1894.49 1049.06 1893.86 1053.03 1893.86C1059.7 1893.86 1065.8 1895.53 1071.33 1898.86C1076.86 1902.16 1081.29 1906.72 1084.62 1912.56C1087.96 1918.35 1089.62 1925 1089.62 1932.5C1089.62 1939.7 1088.01 1946.29 1084.79 1952.27C1081.57 1958.22 1077.05 1962.95 1071.21 1966.48C1065.42 1969.96 1058.6 1971.67 1050.76 1971.59ZM1050.76 1959.09C1055.53 1959.09 1059.81 1957.9 1063.6 1955.51C1067.43 1953.12 1070.44 1949.92 1072.63 1945.91C1074.87 1941.89 1075.99 1937.42 1075.99 1932.5C1075.99 1927.69 1074.91 1923.31 1072.75 1919.38C1070.63 1915.4 1067.69 1912.23 1063.94 1909.89C1060.23 1907.54 1055.99 1906.36 1051.21 1906.36C1047.62 1906.36 1044.26 1907.08 1041.16 1908.52C1038.05 1909.92 1035.32 1911.86 1032.98 1914.32C1030.67 1916.78 1028.85 1919.6 1027.52 1922.78C1026.2 1925.93 1025.53 1929.24 1025.53 1932.73C1025.53 1937.35 1026.61 1941.67 1028.77 1945.68C1030.97 1949.7 1033.96 1952.94 1037.75 1955.4C1041.57 1957.86 1045.91 1959.09 1050.76 1959.09Z",
                      fill: _vm.isDaylight ? "black" : "white",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_7",
                      d: "M583.164 1848.57L645.664 1740.32",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_8",
                      d: "M246.426 1508.17L354.679 1445.67",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_9",
                      d: "M199.247 992.045C204.02 992.083 208.793 992.992 213.565 994.773C218.338 996.553 222.694 999.508 226.634 1003.64C230.573 1007.73 233.736 1013.31 236.122 1020.4C238.509 1027.48 239.702 1036.36 239.702 1047.05C239.702 1057.39 238.717 1066.57 236.747 1074.6C234.815 1082.59 232.012 1089.34 228.338 1094.83C224.702 1100.32 220.27 1104.49 215.043 1107.33C209.853 1110.17 203.982 1111.59 197.429 1111.59C190.914 1111.59 185.099 1110.3 179.986 1107.73C174.91 1105.11 170.743 1101.5 167.486 1096.88C164.266 1092.22 162.202 1086.82 161.293 1080.68H175.156C176.406 1086.02 178.887 1090.44 182.599 1093.92C186.349 1097.37 191.293 1099.09 197.429 1099.09C206.406 1099.09 213.49 1095.17 218.679 1087.33C223.906 1079.49 226.52 1068.41 226.52 1054.09H225.611C223.49 1057.27 220.971 1060.02 218.054 1062.33C215.137 1064.64 211.899 1066.42 208.338 1067.67C204.777 1068.92 200.99 1069.55 196.974 1069.55C190.308 1069.55 184.19 1067.9 178.622 1064.6C173.092 1061.27 168.66 1056.7 165.327 1050.91C162.031 1045.08 160.384 1038.41 160.384 1030.91C160.384 1023.79 161.974 1017.27 165.156 1011.36C168.376 1005.42 172.884 1000.68 178.679 997.159C184.512 993.636 191.368 991.932 199.247 992.045ZM199.247 1004.55C194.474 1004.55 190.175 1005.74 186.349 1008.12C182.562 1010.47 179.55 1013.66 177.315 1017.67C175.118 1021.65 174.02 1026.06 174.02 1030.91C174.02 1035.76 175.08 1040.17 177.202 1044.15C179.361 1048.09 182.296 1051.23 186.009 1053.58C189.759 1055.89 194.02 1057.05 198.793 1057.05C202.391 1057.05 205.743 1056.34 208.849 1054.94C211.955 1053.5 214.664 1051.55 216.974 1049.09C219.323 1046.59 221.16 1043.77 222.486 1040.62C223.812 1037.44 224.474 1034.13 224.474 1030.68C224.474 1026.14 223.376 1021.87 221.179 1017.9C219.02 1013.92 216.027 1010.7 212.202 1008.24C208.414 1005.78 204.096 1004.55 199.247 1004.55Z",
                      fill: _vm.isDaylight ? "black" : "white",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_10",
                      d: "M359.688 645.668L251.434 583.168",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "clock_11",
                      d: "M654.336 354.678L591.836 246.425",
                      stroke: _vm.isDaylight ? "#6B6B6B" : "#D5D5D5",
                      "stroke-width": "10",
                      "stroke-linecap": "round",
                    },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }