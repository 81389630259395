var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-verification-phone-number" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-center w-100 position-relative",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex mb-2", staticStyle: { width: "25rem" } },
            [
              _c("p", {
                staticClass:
                  "font-roboto font-weight-600 cj-color-black-primary m-auto",
                class: _vm.isMobile ? "font-size-18" : "font-size-24",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbComponent.cbVerificationMethod.phoneVerification"
                    )
                  ),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "position-absolute",
              staticStyle: { right: "0" },
              attrs: { type: "button" },
              on: { click: _vm.cancel },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.errorSubmitOtp
        ? _c("p", {
            staticClass:
              "font-roboto cj-color-status-error font-size-14 font-weight-500 border-radius-card wrap-border-status-error px-3 py-2 mt-3",
            attrs: {
              "data-cy": "c-verification-phone-number-error-submit-otp",
            },
            domProps: {
              textContent: _vm._s(
                _vm.$t(
                  "cbgwApp.cbComponent.cbVerificationMethod.incorrectDataEntered"
                )
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-roboto font-italic font-weight-500 cj-color-black-primary text-center mt-3",
          class: _vm.isMobile ? "font-size-16" : "font-size-18",
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cbVerificationMethod.otpDescPhone")
              ),
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n    " + _vm._s(_vm.valueNewPhoneNumber) + "\n  "),
        ]
      ),
      _vm._v(" "),
      _c("c-input-otp", {
        attrs: { value: _vm.value, visibleLoading: false, isUpperCase: true },
        on: {
          input: function ($event) {
            return _vm.inputOtp($event)
          },
        },
      }),
      _vm._v(" "),
      _vm.visibleFooter
        ? _c("div", { staticClass: "d-flex justify-content-between mb-2" }, [
            !_vm.isPhoneTrouble
              ? _c(
                  "p",
                  {
                    staticClass:
                      "font-catamaran font-weight-400 cj-btn-text-primary my-auto",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18",
                    attrs: { "data-cy": "change-phone-number" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.changePhoneNumber"
                        )
                      ),
                    },
                    on: { click: _vm.changePhoneNumber },
                  },
                  [_vm._v("\n      Change phone number\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex",
                class: {
                  "cj-btn-text-primary": !_vm.showCountdown,
                  "cj-color-black-primary": _vm.showCountdown,
                  "ml-auto": _vm.isPhoneTrouble,
                },
              },
              [
                _c(
                  "p",
                  {
                    staticClass: "font-catamaran font-weight-400 my-auto",
                    class: _vm.isMobile ? "font-size-14" : "font-size-18",
                    attrs: {
                      disabled: _vm.visibleLoading,
                      "data-cy":
                        "resend-button" + (_vm.showCountdown ? "-off" : "-on"),
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbVerificationMethod.sendAgain"
                        )
                      ),
                    },
                    on: {
                      click: () => {
                        if (!_vm.showCountdown && !_vm.visibleLoading) {
                          _vm.resend()
                        }
                      },
                    },
                  },
                  [_vm._v("\n        Send Again\n      ")]
                ),
                _vm._v(" "),
                _vm.showCountdown
                  ? _c("c-time-countdown", {
                      attrs: {
                        timeMilis:
                          60 *
                          (_vm.value.timeCountDown != null
                            ? _vm.value.timeCountDown
                            : _vm.timeCoundown),
                        stopCountdown: 0,
                      },
                      on: {
                        action: function ($event) {
                          _vm.showCountdown = false
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ minutes, seconds }) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-catamaran font-weight-400 my-auto ml-1",
                                    class: _vm.isMobile
                                      ? "font-size-14"
                                      : "font-size-18",
                                  },
                                  [
                                    _vm._v(
                                      "\n          (" +
                                        _vm._s(minutes) +
                                        ":" +
                                        _vm._s(seconds) +
                                        ")\n        "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        579652137
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }