import Icon from '@/shared/icons/icon.vue';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({ components: { Icon } })
export default class CAttachmentPreview extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) attachments: any[];
  public vueperConfig: any = {
    fixedHeight: true,
    infinite: true,
    draggingDistance: 70,
  };
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  @Emit('cancel')
  public cancel() {
    return;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
