var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.message.deleted
    ? _c(
        "transition-group",
        { attrs: { name: "vac-slide-left", tag: "span" } },
        _vm._l(_vm.message.reactions, function (reaction, key) {
          return _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: reaction.length,
                  expression: "reaction.length",
                },
              ],
              key: key + 0,
              staticClass: "vac-button-reaction",
              class: {
                "vac-reaction-me": reaction.indexOf(_vm.currentUserId) !== -1,
              },
              style: {
                float:
                  _vm.message.senderId === _vm.currentUserId ? "right" : "left",
              },
              on: {
                click: function ($event) {
                  return _vm.sendMessageReaction({ unicode: key }, reaction)
                },
              },
            },
            [
              _vm._v("\n    " + _vm._s(key)),
              _c("span", [_vm._v(_vm._s(reaction.length))]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }