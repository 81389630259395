import {
  BForm,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BProgress,
  BProgressBar,
  BPagination,
  BButton,
  BNavbar,
  BNavbarNav,
  BNavbarBrand,
  BNavbarToggle,
  BNavItem,
  BNavItemDropdown,
  BCollapse,
  BBadge,
  BDropdown,
  BDropdownItem,
  BLink,
  BAlert,
  BFormRating,
  BTooltip,
  BFormTimepicker,
  BModal,
  VBModal,
  BPopover,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  VBToggle,
  BCalendar,
  BAvatar,
  BIconGlobe2,
  BIconChevronDown,
  BIconChevronRight,
  BIconCheck,
  BIconBox,
  BIconSearch,
  BIconXCircleFill,
  BIconCaretRightFill,
  BIconCalendar4,
  BIconShare,
  BIconArrowClockwise,
  BIconExclamationCircle,
  BIconTrash,
  BIconCamera,
  BIconCameraFill,
  BIconStar,
  BIconStarFill,
  BIconStarHalf,
  BIconGear,
  BIconReply,
  BIconReplyFill,
  BIconFolder2Open,
  BIconEmojiWink,
  BIconCheckAll,
  BIconPaperclip,
  BIconBag,
  BIconBagFill,
  BIconDot,
  BIconImage,
  BIconQuestionCircle,
  BIconEye,
  BIconEyeSlash,
  BIconXCircle,
  BIconInfoCircle,
  BIconX,
  BIconPencilFill,
  BIconCircle,
  BIconCloud,
  BIconLockFill,
  BIconHandThumbsUp,
  BIconHandThumbsDown,
  BIconPlayCircle,
  BIconPlusCircle,
  BInputGroupAppend,
  BNavText,
  BToast,
  BFormTextarea,
  BIconClock,
} from 'bootstrap-vue';

export function initBootstrapVue(vue) {
  vue.component('b-badge', BBadge);
  vue.component('b-dropdown', BDropdown);
  vue.component('b-dropdown-item', BDropdownItem);
  vue.component('b-link', BLink);
  vue.component('b-alert', BAlert);
  vue.component('b-modal', BModal);
  vue.component('b-tooltip', BTooltip);
  vue.component('b-popover', BPopover);
  vue.component('b-button', BButton);
  vue.component('b-navbar', BNavbar);
  vue.component('b-navbar-nav', BNavbarNav);
  vue.component('b-navbar-brand', BNavbarBrand);
  vue.component('b-navbar-toggle', BNavbarToggle);
  vue.component('b-pagination', BPagination);
  vue.component('b-progress', BProgress);
  vue.component('b-progress-bar', BProgressBar);
  vue.component('b-form', BForm);
  vue.component('b-form-input', BFormInput);
  vue.component('b-form-group', BFormGroup);
  vue.component('b-form-checkbox', BFormCheckbox);
  vue.component('b-collapse', BCollapse);
  vue.component('b-nav-item', BNavItem);
  vue.component('b-form-rating', BFormRating);
  vue.component('b-nav-item-dropdown', BNavItemDropdown);
  vue.component('b-modal', BModal);
  vue.component('b-form-timepicker', BFormTimepicker);
  vue.directive('b-modal', VBModal);
  vue.component('b-form-datepicker', BFormDatepicker);
  vue.component('b-input-group', BInputGroup);
  vue.component('b-input-group-prepend', BInputGroupPrepend);
  vue.component('b-table', BTable);
  vue.component('b-avatar', BAvatar);
  vue.component('b-calendar', BCalendar);
  vue.directive('b-toggle', VBToggle);
  vue.component('b-icon-globe2', BIconGlobe2);
  vue.component('b-icon-chevron-down', BIconChevronDown);
  vue.component('b-icon-chevron-right', BIconChevronRight);
  vue.component('b-icon-check', BIconCheck);
  vue.component('b-icon-box', BIconBox);
  vue.component('b-icon-search', BIconSearch);
  vue.component('b-icon-x-circle-fill', BIconXCircleFill);
  vue.component('b-icon-caret-right-fill', BIconCaretRightFill);
  vue.component('b-icon-calendar4', BIconCalendar4);
  vue.component('b-icon-share', BIconShare);
  vue.component('b-icon-arrow-clockwise', BIconArrowClockwise);
  vue.component('b-icon-exclamation-circle', BIconExclamationCircle);
  vue.component('b-icon-camera', BIconCamera);
  vue.component('b-icon-camera-fill', BIconCameraFill);
  vue.component('b-icon-star', BIconStar);
  vue.component('b-icon-star-fill', BIconStarFill);
  vue.component('b-icon-star-half', BIconStarHalf);
  vue.component('b-icon-gear', BIconGear);
  vue.component('b-icon-reply', BIconReply);
  vue.component('b-icon-reply-fill', BIconReplyFill);
  vue.component('b-icon-folder2-open', BIconFolder2Open);
  vue.component('b-icon-emoji-wink', BIconEmojiWink);
  vue.component('b-icon-check-all', BIconCheckAll);
  vue.component('b-icon-paperclip', BIconPaperclip);
  vue.component('b-icon-bag', BIconBag);
  vue.component('b-icon-bag-fill', BIconBagFill);
  vue.component('b-icon-dot', BIconDot);
  vue.component('b-icon-question-circle', BIconQuestionCircle);
  vue.component('b-icon-camera', BIconCamera);
  vue.component('b-icon-image', BIconImage);
  vue.component('b-icon-eye', BIconEye);
  vue.component('b-icon-eye-slash', BIconEyeSlash);
  vue.component('b-icon-info-circle', BIconInfoCircle);
  vue.component('b-icon-x-circle', BIconXCircle);
  vue.component('b-icon-x', BIconX);
  vue.component('b-icon-trash', BIconTrash);
  vue.component('b-icon-pencil-fill', BIconPencilFill);
  vue.component('b-icon-circle', BIconCircle);
  vue.component('b-icon-cloud', BIconCloud);
  vue.component('b-icon-lock-fill', BIconLockFill);
  vue.component('b-hand-thumbs-up', BIconHandThumbsUp);
  vue.component('b-hand-thumbs-down', BIconHandThumbsDown);
  vue.component('b-icon-play-circle', BIconPlayCircle);
  vue.component('b-icon-plus-circle', BIconPlusCircle);
  vue.component('b-input-group-append', BInputGroupAppend);
  vue.component('b-nav-text', BNavText);
  vue.component('b-toast', BToast);
  vue.component('b-form-textarea', BFormTextarea);
  vue.component('b-icon-clock', BIconClock);
}
