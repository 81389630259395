import { Component, Vue } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class PSettingM extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public valueAccount: string = '';
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    (<any>this.$root).$emit('showNavMobile', false);
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public onBack() {
    this.$router.go(-1);
  }
  public toAccount(val) {
    this.valueAccount = val;
    this.$router.push({ name: this.valueAccount });
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
