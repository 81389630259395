import { ICbVwUser } from '@/shared/model/cb-vw-user.model';
import { IChtVwConversation } from '@/shared/model/cht-vw-conversation.model';
import { Inject, Component, Vue, Prop } from 'vue-property-decorator';
import CItemNotification from './component/c-item-notification/c-item-notification.vue';
import Vue2Filters from 'vue2-filters';
import Icon from '@/shared/icons/icon.vue';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { mixins } from 'vue-class-component';
import { Subscription } from 'rxjs';
import PNtVwUserNotifService from '@/services/nt-vw-user-notif.service';
import PNtUserNotifService from '@/services/nt-user-notif.service';
import { INtVwUserNotif, NtVwUserNotif } from '@/shared/model/nt-vw-user-notif.model';
import CDashboardChatList from '@/module/general/dashboard/components/c-dashboard-chat-list/c-dashboard-chat-list.vue';
import CLoader from '../c-loader/c-loader.vue';
import PRptMetaTableService from '@/services/rpt-meta-table.service';
import PCbVwUserCoreService from '@/services/cb-vw-user-core.service';
import GlobalService from '@/services/global.service';

enum EnumTab {
  MESSAGE = 'MESSAGE',
  NOTIFICATION = 'NOTIFICATION',
}

@Component({
  mixins: [Vue2Filters.mixin],
  components: {
    CItemNotification,
    Icon,
    CDashboardChatList,
    CLoader,
  },
})
export default class CNotification extends mixins(JhiDataUtils) {
  // ================= START SERVICES =================
  @Inject('pNtUserNotifService') private pNtUserNotifService: () => PNtUserNotifService;
  @Inject('pNtVwUserNotifService') private pNtVwUserNotifService: () => PNtVwUserNotifService;
  @Inject('pRptMetaTableService') private pRptMetaTableService: () => PRptMetaTableService;
  @Inject('globalService') private globalService: () => GlobalService;
  @Inject('pCbVwUserCoreService') private pCbVwUserCoreService: () => PCbVwUserCoreService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // @Prop({default:EnumTab.MESSAGE})
  public tab: EnumTab = EnumTab.MESSAGE;

  public isNotif = false;
  public enumTab = EnumTab;
  public chtVwConversations: IChtVwConversation[] = [];
  public isFetchingChtVwConversations = false;
  public isFetchingCbVwUser = false;
  public cbVwUsers: ICbVwUser[] = [];
  public ntVwUserNotifs: INtVwUserNotif[] = [];
  public isFetching = false;

  private removeId: number = null;
  public itemsPerPageNotif = 10;
  public queryCount: number = null;
  public pageNotif = 1;
  public previousPageNotif = 1;
  public propOrderNotif = 'createdDate';
  public reverseNotif = true;
  public totalItemsNotif = 0;
  public infiniteIdNotif = +new Date();
  public linksNotif = null;
  public countNtNotif = 0;
  public loadingNotif = true;

  public countTotal = 0;

  private subscriptionConversationId?: Subscription;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========

  created() {
    this.$store.watch(
      () => this.$store.getters.authenticated,
      () => {
        if (this.$store.getters.authenticated === true) {
          this.initFetch();
        }
      }
    );

    (<any>this.$root).$off('updateRead');
    (<any>this.$root).$on('updateRead', (ntUserNotif: NtVwUserNotif) => {
      this.updateRead(ntUserNotif);
    });

    (<any>this.$root).$off('onNotifRefreshList');
    (<any>this.$root).$on('onNotifRefreshList', () => {
      this.retrieveAllCbVwUserNotifsClear();
    });

    (<any>this.$root).$off('onNotifCountRefreshList');
    (<any>this.$root).$on('onNotifCountRefreshList', () => {
      this.retrieveCountNtNotif();
    });

    this.retrieveCountNtNotif();
  }

  mounted() {
    this.initFetch();
  }

  private initFetch() {
    this.retrieveAllCbVwUserNotifsClear();
  }

  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public onBack() {
    if (this.previousRouteName == 'PRequestedTicket') {
      this.$router.go(-2);
      return;
    }

    this.$router.go(-1);
  }

  public updateRead(notif: INtVwUserNotif) {
    this.pNtUserNotifService()
      .updateRead(notif)
      .then(
        res => {
          const foundIndex = this.ntVwUserNotifs.findIndex(e => e.id === res.id);
          Vue.set(this.ntVwUserNotifs, foundIndex, res);

          let tmpCountNotif = this.countNotif;
          tmpCountNotif--;
          this.$store.commit('countNotif', tmpCountNotif);
          this.retrieveCountNtNotif();
        },
        err => {
          this.isFetching = false;
        }
      );
  }

  public updateReadAll() {
    this.pNtUserNotifService()
      .updateReadAll()
      .then(
        res => {
          this.retrieveAllCbVwUserNotifsClear();
          this.retrieveCountNtNotif();
        },
        err => {
          this.isFetching = false;
        }
      );
  }

  public retrieveCountNtNotif() {
    this.pNtVwUserNotifService()
      .retrieveCountByLogin()
      .then(res => {
        this.countNtNotif = res.data;
        this.$store.commit('countNotif', this.countNtNotif);
      })
      .catch(err => {
        this.isFetchingCbVwUser = false;
      });
  }

  public retrieveAllCbVwUserNotifsClear(): void {
    this.pageNotif = 1;
    this.linksNotif = {};
    this.infiniteIdNotif += 1;
    this.ntVwUserNotifs = [];
    this.retrieveAllCbVwUserNotifs();
  }

  public resetNotif(): void {
    this.pageNotif = 1;
    this.infiniteIdNotif += 1;
    this.ntVwUserNotifs = [];
    this.retrieveAllCbVwUserNotifs();
  }

  public retrieveAllCbVwUserNotifs(): void {
    this.isFetching = true;
    const paginationQuery = {
      page: this.pageNotif - 1,
      size: this.itemsPerPageNotif,
      sort: this.sortNotif(),
    };
    this.pNtVwUserNotifService()
      .retrieveByLogin(paginationQuery)
      .then(
        res => {
          const userIds: string[] = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              this.ntVwUserNotifs.push(res.data[i]);
              if (res.data[i].iconLabel == 'PARTNER_PROFILE_PICTURE') {
                userIds.push(res.data[i].senderId);
              }
            }
            if (userIds && userIds.length > 0) {
              this.retrieveCbVwUser(userIds);
            }
            if (res.headers && res.headers['link']) {
              this.linksNotif = this.parseLinks(res.headers['link']);
            }
          }
          this.totalItemsNotif = Number(res.headers['x-total-count']);
          this.queryCount = this.totalItemsNotif;
          this.loadingNotif = false;
          this.isFetching = false;
        },
        err => {
          this.loadingNotif = false;
          this.isFetching = false;
        }
      )
      .finally(() => {
        if (<any>this.$refs.infiniteLoadingNotif) {
          (<any>this.$refs.infiniteLoadingNotif).stateChanger.loaded();
          if (this.linksNotif !== null && this.pageNotif > this.linksNotif['last']) {
            (<any>this.$refs.infiniteLoadingNotif).stateChanger.complete();
          }
        }
      });
  }

  public retrieveCbVwUser(userIds) {
    const tmpIds: string[] = userIds.filter(this.globalService().onlyUniqueIds);

    const params: any = {
      'id.in': tmpIds,
    };

    this.pCbVwUserCoreService()
      .retrieve(params)
      .then(res => {
        res.data.forEach(user => {
          const foundIndex = this.cbVwUsers.findIndex(ex => ex.id === user.id);
          if (foundIndex > 0) {
            this.cbVwUsers[foundIndex] = user;
          } else {
            this.cbVwUsers.push(user);
          }
        });
        this.$store.commit('vwUsers', this.cbVwUsers);
      })
      .catch(err => {
        console.log('ERROR FIND USER ', err);
      });
  }

  public loadMoreNotif($state): void {
    if (!this.isFetching) {
      this.pageNotif++;
      this.transitionNotif();
    }
  }

  public sortNotif(): Array<any> {
    const result = [this.propOrderNotif + ',' + (this.reverseNotif ? 'desc' : 'asc')];
    if (this.propOrderNotif !== 'id') {
      result.push('id');
    }
    return result;
  }

  public loadPage(page: number): void {
    if (page !== this.previousPageNotif) {
      this.previousPageNotif = page;
      this.transitionNotif();
    }
  }

  public transitionNotif(): void {
    this.retrieveAllCbVwUserNotifs();
  }

  public changeOrder(propOrder): void {
    this.propOrderNotif = propOrder;
    this.reverseNotif = !this.reverseNotif;
    this.resetNotif();
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  public get getCurrentUser(): any {
    return this.$store.getters.currentUser;
  }
  public get countNotif(): number {
    return this.$store.getters.countNotif;
  }
  public get countChat(): number {
    return this.$store.getters.countMsg;
  }
  public get countNotifTotal(): number {
    return this.$store.getters.countNotif + this.$store.getters.countMsg;
  }

  public get previousRouteName() {
    return this.$store.getters.previousRouteName;
  }

  actionHandler({ action, url }) {
    switch (action) {
      case 'download':
        return this.download(url);
      case 'route':
        this.$emit('isShowMail', false);
        return this.$router.replace({ path: url });
    }
  }

  public download(url: string) {
    this.isFetching = true;
    this.pRptMetaTableService()
      .downloadByUrl(url)

      .catch(() => {
        console.log('FAILED to Download PDF');
      })
      .finally(() => {
        this.isFetching = false;
      });
  }

  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  // ================= END COMPUTE ====================
}
