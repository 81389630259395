var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "c-card-cookies p-3 border-radius-card wrap-border-grey-tertiary navbar-distance cj-bg-white-primary",
    },
    [
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-size-20-m font-weight-700 text-center mb-2",
        },
        [_vm._v("We use Cookies 🍪")]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "font-roboto cj-color-black-primary font-size-14-m font-weight-400 text-center",
        },
        [
          _vm._v(
            "\n    Klikjob uses cookie to improve your browsing experience. Learn more about Klikjob\n    "
          ),
          _c(
            "span",
            {
              staticClass: "cj-btn-text-primary",
              on: { click: _vm.openModalCookiePolicy },
            },
            [_vm._v("Cookie Policy")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between mt-3" },
        [
          _c(
            "c-button",
            {
              staticClass:
                "font-roboto font-size-16-m w-left-cs text-ellipsis oneline",
              attrs: { typeStyle: "secondary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.openCookiesSetting.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v("Cookies Setting")])]
          ),
          _vm._v(" "),
          _c(
            "c-button",
            {
              staticClass:
                "font-roboto font-size-16-m w-right-cs text-ellipsis oneline",
              nativeOn: {
                click: function ($event) {
                  return _vm.accAllCookies.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v(" Accept All Coookies")])]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }