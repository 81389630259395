var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-confirmation position-relative",
      attrs: {
        "data-cy": _vm.dataCyCustom ? _vm.dataCyCustom : "modal-comfirmation",
        "data-gtm": _vm.dataGTMCustom
          ? `${_vm.dataGTMCustom}-confirmation-modal`
          : "confirmation-modal",
        id: _vm.dataCyCustom ? _vm.dataCyCustom : "modal-comfirmation",
      },
    },
    [
      !_vm.hideClose
        ? _c(
            "button",
            {
              staticClass: "position-absolute p-3 m-n3",
              staticStyle: { right: "0", top: "0" },
              attrs: { type: "button", "data-cy": "close-button" },
              on: { click: _vm.close },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm.iconCustom
            ? _c("icon", {
                staticClass: "font-size-18 cj-fill-red-primary mb-2 mb-lg-3",
                attrs: {
                  name: _vm.iconCustom,
                  width: _vm.iconWidth
                    ? _vm.iconWidth
                    : _vm.isMobile
                    ? "66px"
                    : "77px",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("icon"),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "font-roboto font-weight-700 confirmation-title cj-color-black-primary pb-3",
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "font-catamaran font-weight-400 confirmation-subtitle cj-color-black-primary mb-4",
              attrs: { "data-cy": "confirmation-subtitle" },
            },
            [_vm._v("\n      " + _vm._s(_vm.subTitle) + "\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row my-2 mx-min-2" }, [
            _vm.cancelTitle
              ? _c(
                  "div",
                  {
                    staticClass: "px-2",
                    class: _vm.submitTitle ? "col-6" : "col-12",
                  },
                  [
                    _c(
                      "c-button",
                      {
                        staticClass: "btn-action font-roboto font-weight-500",
                        attrs: {
                          fullWidth: "",
                          disabled: _vm.isLoading,
                          type: "button",
                          "data-cy": _vm.dataCyCustom
                            ? `button-secondary-${_vm.dataCyCustom}`
                            : "button-secondary-submit",
                          "data-gtm": _vm.dataGTMCustom
                            ? `secondary-${_vm.dataGTMCustom}-confirmation-button`
                            : "secondary-confirmation-button",
                          typeStyle: _vm.typeStyleBtnCancel,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.cancel.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.cancelTitle) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.submitTitle
              ? _c(
                  "div",
                  {
                    staticClass: "px-2",
                    class: _vm.cancelTitle ? "col-6" : "col-12",
                  },
                  [
                    _c(
                      "c-button",
                      {
                        staticClass: "btn-action font-roboto font-weight-500",
                        attrs: {
                          "data-cy": _vm.dataCyCustom
                            ? `button-submit-${_vm.dataCyCustom}`
                            : "button-submit-confirmation",
                          "data-gtm": _vm.dataGTMCustom
                            ? `submit-${_vm.dataGTMCustom}-confirmation-button`
                            : "submit-confirmation-button",
                          fullWidth: "",
                          disabled: _vm.isLoading,
                          type: "button",
                          typeStyle: _vm.typeStyleBtnSubmit,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.isLoading
                          ? _c("font-awesome-icon", {
                              attrs: { icon: "sync", spin: _vm.isLoading },
                            })
                          : _c(
                              "span",
                              {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.SECURITY_VHTML(
                                      _vm.submitTitle
                                    )
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.submitTitle) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }