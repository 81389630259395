import axios from 'axios';

const baseApiUrl = 'services/cbuserms/api/cb-vw-billing-history-topups';

export default class PCbVwBillingHistoryTopupService {
  public retrieveByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
