var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-navbar-container vac-navbar-fixed",
      class: _vm.getAuthernticated ? "cj-bg-white-primary" : "navbar-scroll",
      style: {
        top: _vm.delayedInputState ? "0px" : "1px",
        "margin-top": _vm.delayedInputState ? "0px" : "-1px",
      },
      attrs: { id: "intersection-navbar" },
    },
    [
      _c(
        "b-navbar",
        {
          staticClass: "c-navbar py-2 c-container",
          class: _vm.getAuthernticated ? "cj-bg-white-primary" : "",
          attrs: { fixed: "top", toggleable: "lg" },
        },
        [
          _c(
            "b-navbar-brand",
            {
              staticClass: "mr-3 cursor-pointer",
              attrs: { "data-cy": "homepage" },
              on: { click: _vm.redirectHome },
            },
            [
              _c("div", [
                _c("img", {
                  staticClass: "logo-black-scroll",
                  staticStyle: { width: "8rem" },
                  attrs: {
                    "data-gtm": "navbar-logo-button",
                    "data-cy": "navbar-logo",
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-logo-black.png`,
                    alt: "Image Klikjob",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "logo-white-scroll",
                  staticStyle: { width: "8rem" },
                  attrs: {
                    "data-gtm": "navbar-logo-button",
                    "data-cy": "navbar-logo",
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-logo-white.png`,
                    alt: "Image Klikjob",
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "mx-auto navbar-w-100 cj-color-black-primary" },
            [
              _c(
                "div",
                {
                  staticClass: "c-navbar-search mr-0 nav-w-100 pr-2 my-auto",
                  class: _vm.getAuthernticated ? "" : "input-scroll",
                },
                [_c("c-navbar-search", { staticClass: "w-100" })],
                1
              ),
              _vm._v(" "),
              _vm.loginType === _vm.navbarType.BUYER
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mr-0 d-flex c-navbar-quick-search-container",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "my-auto",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.isShowQuickSearch = true
                            },
                          },
                        },
                        [
                          _c("icon", {
                            attrs: {
                              name: "ic_fillter_search",
                              width: "36px",
                              height: "36px",
                              stopColorFirst:
                                "var(--cj-color-linier-gradient-primary)",
                              stopColorLast:
                                "var(--cj-color-linier-gradient-secondary)",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto c-navbar-nav-web" },
            [
              _vm.loginType === _vm.navbarType.GENERAL
                ? [
                    _c(
                      "b-nav-item",
                      { staticClass: "mr-2 toggle-dropdown-container" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("div", {
                              staticClass: "toggle-input",
                              attrs: {
                                "data-gtm": "language-change-button",
                                "data-cy": "language-change-button",
                                type: "checkbox",
                              },
                              on: { click: _vm.showLanguage },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-roboto font-weight-500 font-size-16 my-auto mr-1",
                                class: _vm.getAuthernticated
                                  ? "cj-color-black-primary"
                                  : "color-scroll",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.currentLanguage.toUpperCase()) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("b-icon-globe2", {
                              staticClass: "font-size-16 my-auto mx-1",
                              class: _vm.getAuthernticated
                                ? "cj-color-black-primary"
                                : "color-scroll",
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("router-link", {
                      staticClass:
                        "font-roboto font-weight-500 font-size-16 text-nowrap text-underline",
                      class: _vm.getAuthernticated
                        ? "cj-color-black-primary"
                        : "color-scroll",
                      attrs: {
                        "data-gtm": "join-as-freelancer-button",
                        "data-cy": "join-as-freelancer",
                        to: { name: "PIntroductionFreelancer" },
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbHome.cbHomeBeforeLogin.joinAsFreelancer.title"
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("a", {
                      staticClass:
                        "font-roboto text-nowrap font-weight-500 font-size-16 mx-3",
                      class: _vm.getAuthernticated
                        ? "cj-color-black-primary"
                        : "color-scroll",
                      attrs: {
                        "data-gtm": "login-button",
                        id: "button-login",
                        "data-cy": "button-login",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbComponent.cbNavbar.login")
                        ),
                      },
                      on: { click: _vm.handleOpenLogin },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "my-auto" },
                      [
                        _c(
                          "c-button",
                          {
                            staticClass:
                              "font-roboto font-weight-500 font-size-16",
                            class: _vm.getAuthernticated ? "" : "btn-scroll",
                            attrs: {
                              boxShadow: "",
                              "data-gtm": "register-button",
                              "data-cy": "register",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.handleOpenRegister.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "cbgwApp.cbComponent.cbNavbar.register"
                                  )
                                ),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? [
                    _c(
                      "b-nav-item",
                      { staticClass: "mr-2 toggle-dropdown-container" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto font-weight-700 font-size-20 cj-btn-text-secondary my-auto text-nowrap",
                            on: {
                              click: function ($event) {
                                _vm.isShowUTC = true
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.cbUserTimezoneUTC.timeFormat) +
                                "\n            "
                            ),
                            _c("span", { staticClass: "font-weight-400" }, [
                              _vm._v(" UTC"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isShowUTC
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.closeIsShowUTC,
                                    expression: "closeIsShowUTC",
                                  },
                                ],
                                staticClass:
                                  "toggle-dropdown-content border-radius-card wrap-border-grey-tertiary",
                              },
                              [
                                _c("c-time-region-m-update", {
                                  attrs: { leftArrow: false },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("b-nav-item", { staticClass: "mr-2 d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "my-auto" },
                        [_c("c-navbar-switch-logintype")],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "toggle-dropdown-container",
                        staticStyle: { "z-index": "1000" },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "my-auto position-relative card-message",
                            attrs: {
                              type: "button",
                              "data-cy": "notif-message",
                              "data-gtm": "notif-message-button",
                            },
                            on: {
                              click: function ($event) {
                                _vm.isShowMail = true
                                _vm.applyDelayedStyle(true)
                              },
                            },
                          },
                          [
                            _vm.countNotifTotal > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cj-bg-status-success count-notif-total rounded-circle",
                                    class:
                                      _vm.countNotifTotal > 99
                                        ? "actived-max-count"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "cj-color-black-primary font-roboto font-weight-500 m-auto font-size-9",
                                        attrs: {
                                          "data-cy": "total-notification",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("maxCount")(
                                              _vm.countNotifTotal,
                                              _vm.countNotifTotal
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("icon", {
                              staticClass: "cj-fill-black-primary",
                              attrs: {
                                name: "ic_mail_fill",
                                width: "36px",
                                height: "36px",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.isShowMail
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: () => {
                                      _vm.isShowMail = false
                                      _vm.applyDelayedStyle(false)
                                    },
                                    expression:
                                      "\n              () => {\n                isShowMail = false;\n                applyDelayedStyle(false);\n              }\n            ",
                                  },
                                ],
                                staticClass:
                                  "p-3 border-radius-card wrap-border-grey-tertiary toggle-dropdown-content",
                                attrs: {
                                  "data-cy": "notif-message-content",
                                  "data-gtm": "notif-message-tab-button",
                                },
                              },
                              [
                                _c("c-notification", {
                                  staticStyle: { width: "28.375rem" },
                                  on: {
                                    isShowMail: function ($event) {
                                      _vm.isShowMail = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-nav-item",
                      {
                        staticClass: "mr-0 toggle-dropdown-container",
                        attrs: { "data-cy": "photo-user" },
                      },
                      [
                        _c("c-user-photo-online", {
                          staticClass: "cursor-pointer",
                          attrs: {
                            width: 3.75,
                            url: _vm.$options.filters.LAZY(
                              `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                                _vm.currentUser
                                  ? _vm.currentUser.photoId
                                  : "ic-lazy-avatar.png"
                              }`,
                              55,
                              "ic-lazy-avatar.png"
                            ),
                            isOnline: true,
                          },
                          nativeOn: {
                            click: function ($event) {
                              _vm.isShowDropdownMenu = true
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.isSetUsername && _vm.authenticated
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "toggle-dropdown-content border-radius-card shadow-none",
                                staticStyle: {
                                  background: "transparent !important",
                                },
                                attrs: { "data-cy": "ph-warning" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [_c("c-ph-warning")],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isShowDropdownMenu
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.handleCloseDropdownMenu,
                                    expression: "handleCloseDropdownMenu",
                                  },
                                ],
                                staticClass:
                                  "toggle-dropdown-content border-radius-card wrap-border-grey-tertiary",
                                attrs: { "data-cy": "photo-user" },
                              },
                              [
                                _c("c-navbar-dropdown-menu", {
                                  on: {
                                    select: function ($event) {
                                      _vm.isShowDropdownMenu = false
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "cb-fade-image" } }, [
        _vm.isShowQuickSearch
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: () => {
                      _vm.isShowQuickSearch = false
                    },
                    expression:
                      "\n        () => {\n          isShowQuickSearch = false;\n        }\n      ",
                  },
                ],
                staticClass: "dropdown-quick-search",
              },
              [
                _c("c-quick-search", {
                  on: {
                    cancel: function ($event) {
                      _vm.isShowQuickSearch = false
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }