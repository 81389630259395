import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { CbUserStatusType } from './enumerations/cb-user-status-type.model';
import { CbGender } from './enumerations/cb-gender.model';

export interface ICbVwUser {
  id?: string;
  userLvl?: string | null;
  userMedals?: string | null;
  userRating?: number | null;
  followerCount?: number | null;
  followingCount?: number | null;
  lastSeenLogin?: string | null;
  lastSeenLoginDate?: Date | null;
  averageReplyTime?: string | null;
  lastProjectDelivery?: string | null;
  lastProjectDeliveryDate?: Date | null;
  completedProfilePercentage?: number | null;
  isSvcInside?: boolean | null;
  isSvcField?: boolean | null;
  orderTotal?: number | null;
  orderCompleted?: number | null;
  orderNotShowing?: number | null;
  totalAmountEarned?: number | null;
  displayName?: string | null;
  username?: string | null;
  photoId?: number | null;
  bannerId?: number | null;
  userSkills?: string | null;
  userLanguages?: string | null;
  userEducations?: string | null;
  userCerts?: string | null;
  offDutyStartDate?: Date | null;
  offDutyEndDate?: Date | null;
  offDutyReason?: string | null;
  offDutyActive?: boolean | null;
  offDutyId?: number | null;
  locationCity?: string | null;
  memberSinceDate?: Date | null;
  projectCompleted?: number | null;
  orderCompletion?: number | null;
  freelancerWorkedWith?: number | null;
  profileVisit?: number | null;
  profileInSearch?: number | null;
  spOrderUser?: string | null;
  loginType?: CbLoginType | null;
  defaultSvcType?: CbSvcType | null;
  pinActivated?: boolean | null;
  operationHourStart?: Date | null;
  operationHourEnd?: Date | null;
  operationHourLabel?: string | null;
  operationHourLabelDiff?: string | null;
  signature?: string | null;
  aboutMe?: string | null;
  timeZone?: string | null;
  timeZoneDetail?: string | null;
  langKey?: string | null;
  isShowOnlineStatus?: boolean | null;
  isPinExist?: boolean | null;
  phoneActivated?: boolean | null;
  phoneActivationKey?: string | null;
  emailActivated?: boolean | null;
  emailActivationKey?: string | null;
  languagePreference?: string | null;
  userStatus?: CbUserStatusType | null;
  gender?: CbGender | null;

  // transient
  localUrlPhoto?: string | null;
  localUrlBanner?: string | null;
  locationCityId?: string | null;
  locationCityEn?: string | null;
  languageUser?: string | null;
}

export class CbVwUser implements ICbVwUser {
  constructor(
    public id?: string,
    public userLvl?: string | null,
    public userMedals?: string | null,
    public userRating?: number | null,
    public followerCount?: number | null,
    public followingCount?: number | null,
    public lastSeenLogin?: string | null,
    public lastSeenLoginDate?: Date | null,
    public averageReplyTime?: string | null,
    public lastProjectDelivery?: string | null,
    public completedProfilePercentage?: number | null,
    public isSvcInside?: boolean | null,
    public isSvcField?: boolean | null,
    public orderTotal?: number | null,
    public orderCompleted?: number | null,
    public orderNotShowing?: number | null,
    public totalAmountEarned?: number | null,
    public displayName?: string | null,
    public username?: string | null,
    public photoId?: number | null,
    public bannerId?: number | null,
    public userSkills?: string | null,
    public userLanguages?: string | null,
    public userEducations?: string | null,
    public userCerts?: string | null,
    public offDutyStartDate?: Date | null,
    public offDutyEndDate?: Date | null,
    public offDutyReason?: string | null,
    public offDutyActive?: boolean | null,
    public offDutyId?: number | null,
    public locationCity?: string | null,
    public memberSinceDate?: Date | null,
    public projectCompleted?: number | null,
    public orderCompletion?: number | null,
    public freelancerWorkedWith?: number | null,
    public profileVisit?: number | null,
    public profileInSearch?: number | null,
    public spOrderUser?: string | null,
    public loginType?: CbLoginType | null,
    public defaultSvcType?: CbSvcType | null,
    public pinActivated?: boolean | null,
    public operationHourStart?: Date | null,
    public operationHourEnd?: Date | null,
    public operationHourLabel?: string | null,
    public operationHourLabelDiff?: string | null,
    public signature?: string | null,
    public aboutMe?: string | null,
    public timeZone?: string | null,
    public timeZoneDetail?: string | null,
    public langKey?: string | null,
    public isShowOnlineStatus?: boolean | null,
    public isPinExist?: boolean | null,
    public languagePreference?: string | null,
    public phoneActivated?: boolean | null,
    public phoneActivationKey?: string | null,
    public emailActivated?: boolean | null,
    public emailActivationKey?: string | null,
    public userStatus?: CbUserStatusType | null,
    public gender?: CbGender | null,

    // transient
    public localUrlPhoto?: string | null,
    public localUrlBanner?: string | null,
    public locationCityId?: string | null,
    public locationCityEn?: string | null,
    public languageUser?: string | null
  ) {
    // this.isSvcInside = this.isSvcInside ?? false;
    // this.isSvcField = this.isSvcField ?? false;
    // this.offDutyActive = this.offDutyActive ?? false;
    // this.isPinExist = this.isPinExist ?? false;
  }
}
