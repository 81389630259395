import axios from 'axios';

import { ICbVwAuctionProject } from '@/shared/model/cb-vw-auction-project.model';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

const baseApiUrl = 'services/cbcorems/api/cb-vw-auction-projects';

///cb-vw-auction-projects/active/count/by-login

export default class PCbVwAuctionProjectService {
  public find(id: number): Promise<ICbVwAuctionProject> {
    return new Promise<ICbVwAuctionProject>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl, {
          params,
          cancelToken,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrievePaginationQuery(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(
          baseApiUrl +
            `?${paginationQuery && paginationQuery.moreFilter ? paginationQuery.moreFilter : ''}${buildPaginationQueryOpts(
              paginationQuery
            )}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveRecommendation(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/recommendation', {
          params,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public retrieveWishlist(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/wishlist', {
          params,
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/by-login`, { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveCountByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/count/by-login`, { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public countCbVwAuctionProjectActive(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/active/count/by-login`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
