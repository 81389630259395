// Errors
export const PROBLEM_BASE_URL = 'https://www.klikjob.com/problem';
export const EMAIL_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/email-already-used`;
export const LOGIN_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/login-already-used`;

// GOOGLE MAP
export const DEFAULT_LAT = -6.2087634;
export const DEFAULT_LNG = 106.845599;
export const GOOGLE_TRANSLATE_FIELD_PERMITTED = ['content', 'pawTitle'];
export const BANNER_ID_DEFAULT = '410560';

// DATE
export const TIMEZONE_JAKARTA = 'Asia/Jakarta';

import { App } from '@/shared/model/enumerations/app.model';
export const CHT_APP_SELECTED = App.CB_APP;

//identity provider
export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const LINKEDIN = 'linkedin';
export const APPLE = 'apple';

//chat
export const CONST_MESSAGE_CARD = 'message-card-';
export const CONST_MESSAGE_CARD_FORMAT_MESSAGE = 'message-card-format-message-';
export const REFRESH_TOKEN_API = 'services/cbuserms/api/account/refresh-token';

//WF
export const WF_NAME_OR_FIELD_WORKER = 'OR_FIELD_WORKER';

//login error
export const ERROR_LOGIN_PASSWORD = 'Incorrect password';
export const ERROR_LOGIN_BLOCKED = 'Incorrect status user';

// payment
export const BALANCE_LIMIT = 20000000;
export const TOPUP_LIMIT = 40000000;
export const VA_REMAINING_MINIMUM = 10000;
export const CC_REMAINING_MINIMUM = 5000;
export const CC_REMAINING_MAXIMAL = 200000000;
export const TOAST_WARNING = 'warning';
export const TOAST_SUCCESS = 'success';

// Ticket
