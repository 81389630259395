var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-account-deactive px-3 px-lg-0" },
    [
      _c(
        "div",
        {
          staticClass:
            "diactive-wrapper d-flex flex-wrap flex-lg-nowrap border-grey-tertiary-top-1 mb-0 mb-lg-4 pt-4",
        },
        [
          _c("div", { staticClass: "flex-grow-1" }, [
            _c("p", {
              staticClass:
                "mb-3 font-roboto cj-color-black-primary font-weight-500",
              class: _vm.isMobile ? "font-size-14" : "font-size-18",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.title")
                ),
              },
            }),
            _vm._v(" "),
            _c("ul", { staticClass: "ul-p-22 mb-0" }, [
              _c("li", {
                staticClass:
                  "cj-color-black-primary font-catamaran font-weight-400",
                class: _vm.isMobile ? "font-size-14" : "font-size-16",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.subtitle")
                  ),
                },
              }),
              _vm._v(" "),
              _c("li", {
                staticClass:
                  "cj-color-black-primary font-catamaran font-weight-400",
                class: _vm.isMobile ? "font-size-14" : "font-size-16",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.reactivate")
                  ),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "mt-3 mb-4 ml-auto ml-lg-3 col-12 col-sm-5 col-lg-4 px-0",
            },
            [
              _c(
                "c-button",
                {
                  staticClass: "py-1",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18",
                  attrs: {
                    dataGTMCustom: "deactivate-account",
                    "data-cy": "btn-deactivate-account",
                    disabled:
                      _vm.isFetchingBalance ||
                      _vm.isFetchingCountActiveAuction ||
                      _vm.isFetchingCountActiveOrder,
                    typeStyle: "secondary",
                    fullWidth: "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleStartDeactiveAccount.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.cDeactiveAccount.btnDeactiveAccount"
                        )
                      ),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "diactive-wrapper d-flex flex-wrap flex-lg-nowrap border-grey-tertiary-top-1 pt-4",
        },
        [
          _c("div", { staticClass: "flex-grow-1" }, [
            _c("p", {
              staticClass:
                "mb-3 font-roboto cj-color-black-primary font-weight-500",
              class: _vm.isMobile ? "font-size-14" : "font-size-18",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.deleteAccount")
                ),
              },
            }),
            _vm._v(" "),
            _c("ul", { staticClass: "ul-p-22 mb-4" }, [
              _c("li", {
                staticClass:
                  "cj-color-black-primary font-catamaran font-weight-400",
                class: _vm.isMobile ? "font-size-12" : "font-size-16",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.descLoseBalance")
                  ),
                },
              }),
              _vm._v(" "),
              _c("li", {
                staticClass:
                  "cj-color-black-primary font-catamaran font-weight-400",
                class: _vm.isMobile ? "font-size-12" : "font-size-16",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.descRetreiaveAllData"
                    )
                  ),
                },
              }),
              _vm._v(" "),
              _c("li", {
                staticClass:
                  "cj-color-black-primary font-catamaran font-weight-400",
                class: _vm.isMobile ? "font-size-12" : "font-size-16",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.descRegisterCb")
                  ),
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mb-4 ml-auto ml-lg-3 col-12 col-sm-5 col-lg-4 px-0",
              class: !_vm.isMobile && "mt-2",
            },
            [
              _c(
                "c-button",
                {
                  staticClass: "py-1",
                  class: _vm.isMobile ? "font-size-14" : "font-size-18",
                  attrs: {
                    dataGTMCustom: "delete-account",
                    "data-cy": "btn-delete-account",
                    disabled:
                      _vm.isFetchingBalance ||
                      _vm.isFetchingCountActiveAuction ||
                      _vm.isFetchingCountActiveOrder,
                    typeStyle: "secondary",
                    fullWidth: "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleStartDeleteAccount.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbSetting.cDeactiveAccount.btnDeleteAccount"
                        )
                      ),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "cad-modal-finish-order",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    data: "",
                    title: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.finishOrder"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.descFinishOrder",
                      {
                        param1:
                          _vm.countPawOrderBuyer + _vm.countPawOrderPartner,
                        param2: _vm.countAuctionActive,
                      }
                    ),
                    dataCyCustom: "deactive-account",
                    iconCustom: "ic_exclamation",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "cad-modal-confirmation-deactivate-account",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    "data-cy": "deactive-account",
                    title: _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.title"),
                    subTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.descDeactivateAccount"
                    ),
                    submitTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.btnContinue"
                    ),
                    cancelTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.gotoBalance"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: _vm.toBalanceAccount,
                    save: _vm.continueDeactiveOrDeleteAccount,
                    close: _vm.closeAllDialog,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          id: "modal-deactive-delete",
          "data-cy": "modal-deactive-delete",
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-accout-deactivate-update", {
                  attrs: {
                    cbUserInfo: _vm.tmpCbUserInfo,
                    "data-cy": "modal-deactive-delete",
                  },
                  on: { submit: _vm.handleDeactiveSubmit, cancel: cancel },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.visibleModalDeactive,
          callback: function ($$v) {
            _vm.visibleModalDeactive = $$v
          },
          expression: "visibleModalDeactive",
        },
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "cad-modal-finish-order-delete",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ cancel }) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.finishOrder"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.descDeleteOrder",
                      {
                        param1:
                          _vm.countPawOrderBuyer + _vm.countPawOrderPartner,
                        param2: _vm.countAuctionActive,
                      }
                    ),
                    dataCyCustom: "delete-account",
                    iconCustom: "ic_exclamation",
                  },
                  on: { cancel: cancel, close: cancel },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "cad-modal-confirmation-deactivate-account-delete",
          "data-cy": "cad-modal-confirmation-deactivate-account-delete",
          "hide-footer": "",
          "hide-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    "data-cy": "form-delete-account",
                    title: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.deleteAccount"
                    ),
                    subTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.descModalDeleteAccount",
                      {
                        param1: _vm.$options.filters.formatCurrency(
                          _vm.currentBalance.balance,
                          _vm.CbCurrency.IDR
                        ),
                        param2: _vm.$options.filters.formatCurrency(
                          _vm.currentBalance.balanceRefund,
                          _vm.CbCurrency.IDR
                        ),
                        param3: _vm.$options.filters.formatCurrency(
                          _vm.currentBalance.balanceIncome,
                          _vm.CbCurrency.IDR
                        ),
                      }
                    ),
                    submitTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.btnContinue"
                    ),
                    cancelTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.gotoBalance"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: _vm.toBalanceAccount,
                    save: _vm.continueDeactiveOrDeleteAccount,
                    close: _vm.closeAllDialog,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("b-modal", {
        attrs: {
          "modal-class": "vac-custom-modal-b-modal",
          centered: "",
          size: "md",
          id: "cad-modal-we-gonna-miss-you-delete",
          "hide-footer": "",
          "hide-header": "",
          "no-close-on-backdrop": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({}) {
              return [
                _c("c-confirmation", {
                  staticClass: "w-100",
                  attrs: {
                    title: _vm.$t("cbgwApp.cbSetting.cDeactiveAccount.soSad"),
                    subTitle: _vm.$t(
                      "cbgwApp.cbSetting.cDeactiveAccount.sayingGoodbye"
                    ),
                    iconCustom: "ic_exclamation",
                  },
                  on: {
                    cancel: function ($event) {
                      return _vm.logout()
                    },
                    close: function ($event) {
                      return _vm.logout()
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            id: "modal-enter-pin",
            "modal-class": "vac-custom-modal-b-modal",
            size: "md",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center w-100 position-relative",
                    },
                    [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass:
                              "font-roboto text-center font-weight-700 cj-color-black-primary font-size-24",
                          },
                          [_vm._v("PIN")]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          staticClass:
                            "p-0 m-0 font-roboto cj-color-black-primary text-italic font-weight-500 mt-2 text-center font-size-18",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbPayment.enterPin")
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "position-absolute p-3 m-n3",
                          staticStyle: { right: "0" },
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.visiblePin = false
                            },
                          },
                        },
                        [
                          _c("b-icon-x-circle-fill", {
                            staticClass: "font-size-18 cj-color-grey-secondary",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.visiblePin,
            callback: function ($$v) {
              _vm.visiblePin = $$v
            },
            expression: "visiblePin",
          },
        },
        [
          _vm._v(" "),
          _c("c-input-pin", {
            attrs: {
              isSavingCbPayInBalance:
                _vm.isSavingDeleteAccount || _vm.isSavingDeactiveAccount,
              pinNotMatched: _vm.pinNotMatched,
            },
            on: {
              cancel: function ($event) {
                _vm.visiblePin = false
              },
              save: _vm.handleSubmitDeActiveAndDeleteAccount,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }