import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { gsap, Cubic } from 'gsap/all';

@Component({})
export default class CMedalProgress extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) logoActiveUrl: string;
  @Prop({ default: null }) logoInActiveUrl: string;
  @Prop({ default: null }) medalName: string;
  @Prop({ default: 0 }) percent: number;
  @Prop({ default: null }) requirement: string;
  public t: number = +new Date();

  public center = '50% 50%';
  public topStart = '50% 0';
  public topRight = '50% 0';
  public bottomRight = '50% 0';
  public bottomleft = '50% 0';
  public topLeft = '50% 0';
  public topEnd = '50% 0';

  public isShowInfo = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public mounted() {
    this.changePercentage();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('percent', { deep: true })
  public changePercentage() {
    if (this.percent <= 12.5) {
      this.topRight = `${50 + ((this.percent / 12.5) * 100) / 2}% 0`;
      this.bottomRight = this.topRight;
      this.bottomleft = this.topRight;
      this.topLeft = this.topRight;
      this.topEnd = this.topRight;
    } else if (this.percent <= 25) {
      this.topRight = `100% 0%`;
      this.bottomRight = `100% ${(this.percent / 50) * 100}%`;
      this.bottomleft = this.bottomRight;
      this.topLeft = this.bottomRight;
      this.topEnd = this.bottomRight;
    } else if (this.percent <= 37.5) {
      this.topRight = `100% 0%`;
      this.bottomRight = `100% ${(this.percent / 37.5) * 100}%`;
      this.bottomleft = this.bottomRight;
      this.topLeft = this.bottomRight;
      this.topEnd = this.bottomRight;
    } else if (this.percent <= 50) {
      this.topRight = `100% 0%`;
      this.bottomRight = `100% 100%`;
      this.bottomleft = `${100 - (this.percent / 50) * 100 + 50}% 100% `;
      this.topLeft = this.bottomleft;
      this.topEnd = this.bottomleft;
    } else if (this.percent <= 62.5) {
      this.topRight = `100% 0%`;
      this.bottomRight = `100% 100%`;
      this.bottomleft = `${100 - (this.percent / 62.5) * 100}% 100% `;
      this.topLeft = this.bottomleft;
      this.topEnd = this.bottomleft;
    } else if (this.percent <= 75) {
      this.topRight = `100% 0%`;
      this.bottomRight = `100% 100%`;
      this.bottomleft = `0% 100% `;
      this.topLeft = `0% ${100 - (this.percent / 75) * 100 + 50}%`;
      this.topEnd = this.topLeft;
    } else if (this.percent <= 87.5) {
      this.topRight = `100% 0%`;
      this.bottomRight = `100% 100%`;
      this.bottomleft = `0% 100% `;
      this.topLeft = `0% ${100 - (this.percent / 87.5) * 100}%`;
      this.topEnd = this.topLeft;
    } else if (this.percent <= 100) {
      this.topRight = `100% 0%`;
      this.bottomRight = `100% 100%`;
      this.bottomleft = `0% 100% `;
      this.topLeft = `0% 0%`;
      this.topEnd = `${((this.percent / 100) * 100) / 2}% 0%`;
    } else {
      this.center = '50% 50%';
      this.topStart = '50% 0';
      this.topRight = '50% 0';
      this.bottomRight = '50% 0';
      this.bottomleft = '50% 0';
      this.topLeft = '50% 0';
      this.topEnd = '50% 0';
    }

    gsap.to(
      <any>this.$refs.pawLight,

      {
        clipPath: `polygon(${this.center}, ${this.topStart}, ${this.topRight}, ${this.bottomRight}, ${this.bottomleft}, ${this.topLeft}, ${this.topEnd})`,
        duration: 0.5,
        ease: Cubic.easeOut,
      }
    );
  }
  // ================= END LISTENERS ===================
}
