import { IChtAttachmentRestrict } from '@/shared/model/cht-attachment-restrict.model';
import { IChtAggMessageLabel } from '@/shared/model/cht-agg-message-label.model';
import { IChtConversation } from '@/shared/model/cht-conversation.model';

import { ChtMessageStatus } from '@/shared/model/enumerations/cht-message-status.model';
import { App } from '@/shared/model/enumerations/app.model';
import { ChtContentType } from '@/shared/model/enumerations/cht-content-type.model';
import { IChtLocation } from './cht-location.model';
import { IChtFile } from './cht-file.model';
export interface IChtMessage {
  id?: number;
  authorId?: string | null;
  content?: string | null;
  imgUrl?: string | null;
  status?: ChtMessageStatus | null;
  messageRefId?: number | null;
  isOrder?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  isLiveChat?: boolean | null;
  app?: App | null;
  chtContentType?: ChtContentType | null;
  externalId?: string | null;
  chtAttachmentRestrict?: IChtAttachmentRestrict | null;
  chtAggMessageLabels?: IChtAggMessageLabel[] | null;
  chtConversation?: IChtConversation | null;
  chtLocation?: IChtLocation | null;
  authorPhotoId?: number | null; //transient for notif
  authorDisplayName?: string | null; //transient for notif
  cbVwPawOrder?: any | null;
  cbVwAuctionProjectPreOffer?: any | null;
  cbPawPreOrder?: any | null;
  cbVwPaw?: any | null;
  cbTextRestrict?: any | null;
  messageTranslated?: IChtMessage | null;
  files?: IChtFile[] | null; //transient
  currentLangKey?: string | null; //transient
}

export class ChtMessage implements IChtMessage {
  constructor(
    public id?: number,
    public authorId?: string | null,
    public content?: string | null,
    public imgUrl?: string | null,
    public status?: ChtMessageStatus | null,
    public messageRefId?: number | null,
    public isOrder?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public isLiveChat?: boolean | null,
    public app?: App | null,
    public chtContentType?: ChtContentType | null,
    public externalId?: string | null,
    public chtAttachmentRestrict?: IChtAttachmentRestrict | null,
    public chtAggMessageLabels?: IChtAggMessageLabel[] | null,
    public chtConversation?: IChtConversation | null,
    public chtLocation?: IChtLocation | null,
    public authorPhotoId?: number | null, //transient for notif
    public authorDisplayName?: string | null, //transient for notif
    public cbVwPawOrder?: any | null,
    public cbVwAuctionProjectPreOffer?: any | null,
    public cbPawPreOrder?: any | null,
    public cbVwPaw?: any | null,
    public cbTextRestrict?: any | null,
    public messageTranslated?: IChtMessage | null,
    public files?: IChtFile[] | null, //transient
    public currentLangKey?: string | null //transient
  ) {
    // this.isOrder = this.isOrder ?? false;
    // this.isActive = this.isActive ?? false;
    // this.isLiveChat = this.isLiveChat ?? false;
  }
}
