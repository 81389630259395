import { NtUserNotifSetting } from '@/shared/model/nt-user-notif-setting.model';
import axios from 'axios';

const baseApiUrl = 'services/btinotif/api/nt-user-notif-settings';

export default class PNtUserNotifSettingService {
  public retirveByLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/CB_APP/by-login`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createdByLogin(entity: NtUserNotifSetting): Promise<NtUserNotifSetting> {
    return new Promise<NtUserNotifSetting>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/CB_APP/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: NtUserNotifSetting): Promise<NtUserNotifSetting> {
    return new Promise<NtUserNotifSetting>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
