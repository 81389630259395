import CButton from '@/components/button/c-button.vue';
import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CButton,
  },
})
export default class COrderPdBtn extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public textBtn1: string;
  @Prop({ default: null }) public textBtnAdditional1: string;
  @Prop({ default: null }) public textBtn2: string;
  @Prop({ default: null }) public textConditionBtn1: boolean;
  @Prop({ default: null }) public isLoadingBtn1: boolean;
  @Prop({ default: null }) public isLoadingBtn2: boolean;
  @Prop({ default: null }) public disabledBtn1: boolean;
  @Prop({ default: null }) public disabledBtn2: boolean;
  @Prop({ default: null }) public dataCyCustom1: string;
  @Prop({ default: null }) public dataCyCustom2: string;

  public deviceSizeType = DeviceSizeType;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
