var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-input-pin", attrs: { "data-cy": "c-input-pin" } },
    [
      _vm.wrongOtpCode
        ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-roboto px-3 py-1 d-flex wrap-border-status-error rounded-pill m-auto cj-color-status-error font-size-14 font-weight-300",
                attrs: { "data-cy": "pin-not-match" },
              },
              [
                _c("b-icon-x-circle", {
                  staticClass: "font-size-16 cj-color-status-error my-auto",
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ml-2 my-auto",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cPin.passwordNotMatch")
                    ),
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.passwordCurrentNotMatch
        ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-roboto px-3 py-1 d-flex wrap-border-status-error rounded-pill m-auto cj-color-status-error font-size-14 font-weight-300",
                attrs: { "data-cy": "pin-not-match" },
              },
              [
                _c("b-icon-x-circle", {
                  staticClass: "font-size-16 cj-color-status-error my-auto",
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ml-2 my-auto",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cPin.passwordCurrentNotMatch")
                    ),
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.pinNotMatched
        ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-roboto px-3 py-1 d-flex wrap-border-status-error rounded-pill m-auto cj-color-status-error font-size-14 font-weight-300",
                attrs: { "data-cy": "pin-not-match" },
              },
              [
                _c("b-icon-x-circle", {
                  staticClass: "font-size-16 cj-color-status-error my-auto",
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ml-2 my-auto",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cPin.pinNotMatch")
                    ),
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.pinOldNotMatched
        ? _c("div", { staticClass: "d-flex w-100 mt-1" }, [
            _c(
              "div",
              {
                staticClass:
                  "font-roboto px-3 py-1 d-flex wrap-border-status-error rounded-pill m-auto cj-color-status-error font-size-14 font-weight-300",
                attrs: { "data-cy": "pin-not-match" },
              },
              [
                _c("b-icon-x-circle", {
                  staticClass: "font-size-16 cj-color-status-error my-auto",
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "ml-2 my-auto",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cPin.pinOldNotMatched")
                    ),
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "c-button",
        {
          staticClass: "font-roboto font-size-20 text-center mt-3",
          attrs: {
            disabled: _vm.disabledSubmit || _vm.isSavingCbPayInBalance,
            "data-cy": "btn-continue-pin",
            fullWidth: "",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.save.apply(null, arguments)
            },
          },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("cbgwApp.cbComponent.cPin.continue")),
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass: "d-flex justify-content-center my-4 my-lg-5",
        attrs: { id: "form" },
      },
      [
        _c(
          "div",
          {
            staticClass: "custom-input-pin",
            attrs: { "data-cy": "custom-input-pin" },
          },
          [
            _c("input", {
              staticClass: "input-pin-wrapper cj-field-border-bottom-default",
              attrs: {
                "data-cy": "pin-first",
                id: "pin-first",
                name: "pin-first",
                type: "password",
                inputmode: "numeric",
                autocomplete: "off",
                pattern: "[0-9]*",
                maxlength: "1",
                step: "1",
                "aria-label": "first digit",
              },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "input-pin-wrapper cj-field-border-bottom-default",
              attrs: {
                "data-cy": "pin-second",
                id: "pin-second",
                name: "pin-second",
                type: "password",
                inputmode: "numeric",
                autocomplete: "off",
                pattern: "[0-9]*",
                maxlength: "1",
                step: "1",
                "aria-label": "second digit",
              },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "input-pin-wrapper cj-field-border-bottom-default",
              attrs: {
                "data-cy": "pin-third",
                id: "pin-third",
                name: "pin-third",
                type: "password",
                inputmode: "numeric",
                autocomplete: "off",
                pattern: "[0-9]*",
                maxlength: "1",
                step: "1",
                "aria-label": "third digit",
              },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "input-pin-wrapper cj-field-border-bottom-default",
              attrs: {
                "data-cy": "pin-fourth",
                id: "pin-fourth",
                name: "pin-fourth",
                type: "password",
                inputmode: "numeric",
                autocomplete: "off",
                pattern: "[0-9]*",
                maxlength: "1",
                step: "1",
                "aria-label": "fourth digit",
              },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "input-pin-wrapper cj-field-border-bottom-default",
              attrs: {
                "data-cy": "pin-five",
                id: "pin-five",
                name: "pin-five",
                type: "password",
                inputmode: "numeric",
                autocomplete: "off",
                pattern: "[0-9]*",
                maxlength: "1",
                step: "1",
                "aria-label": "five digit",
              },
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "input-pin-wrapper cj-field-border-bottom-default",
              attrs: {
                "data-cy": "pin-six",
                id: "pin-six",
                name: "pin-six",
                type: "password",
                inputmode: "numeric",
                autocomplete: "off",
                pattern: "[0-9]*",
                maxlength: "1",
                step: "1",
                "aria-label": "six digit",
              },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }