import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Icon from '@/shared/icons/icon.vue';
import { CbVerificationOtp } from '@/shared/model/cb-verification-otp.model';
import CButton from '../button/c-button.vue';

@Component({
  components: {
    Icon,
    CButton,
  },
})
export default class CNotificationEmail extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public value: CbVerificationOtp;
  @Prop({ default: false }) public isButtonCountinue: boolean;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  @Emit('submit')
  public submit() {
    return;
  }

  @Emit('cancel')
  public cancel() {
    return;
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
