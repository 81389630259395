import { App } from './enumerations/app.model';

export interface IPayVwBalanceCore {
  app?: App | null;
  externalUserId?: string | null;
  balance?: number | null;
  balanceRefund?: number | null;
  balanceFreeze?: number | null;
  balanceIncome?: number | null;
}

export class PayVwBalanceCore implements IPayVwBalanceCore {
  constructor(
    public app?: App | null,
    public externalUserId?: string | null,
    public balance?: number | null,
    public balanceRefund?: number | null,
    public balanceFreeze?: number | null,
    public balanceIncome?: number | null
  ) {}
}
