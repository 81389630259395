export enum CbPromoPayMethod {
  ALL = 'ALL',
  VA = 'VA',
  CC = 'CC',
  BALANCE = 'BALANCE',
  BALANCE_AUCTION_GUARANTEE = 'BALANCE_AUCTION_GUARANTEE',
  DISBURSEMENT = 'DISBURSEMENT',
  QRCODE = 'QRCODE',
  EWALLET = 'EWALLET',
  BALANCE_REFUND = 'BALANCE_REFUND',
}
