import axios from 'axios';

import { ICbUserLanguage } from '@/shared/model/cb-user-language.model';

const baseApiUrl = 'services/cbuserms/api/cb-user-languages';

export default class PCbUserLanguageService {
  public deleteByLogin(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/by-login/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public createByLogin(entity: ICbUserLanguage): Promise<ICbUserLanguage> {
    return new Promise<ICbUserLanguage>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/by-login`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateByLogin(entity: ICbUserLanguage): Promise<ICbUserLanguage> {
    return new Promise<ICbUserLanguage>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/by-login/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
