var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      staticClass: "c-navbar p-0 cj-bg-white-primary",
      staticStyle: { "z-index": "1040" },
      attrs: { fixed: "bottom" },
    },
    [
      _c(
        "div",
        {
          staticClass: "c-tab-footer d-flex justify-content-between p-2 w-100",
          attrs: { "data-cy": "footer" },
        },
        [
          _c("div", { staticClass: "wrap-btn my-auto" }, [
            _c(
              "button",
              {
                staticClass: "custom-p",
                attrs: { "data-cy": "button-to-home" },
                on: {
                  click: function ($event) {
                    return _vm.navigateTo("PHome")
                  },
                },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFillHome,
                      expression: "!isFillHome",
                    },
                  ],
                  staticStyle: { width: "1.688rem", height: "1.688rem" },
                  attrs: {
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-home-default.png`,
                    alt: "img",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFillHome,
                      expression: "isFillHome",
                    },
                  ],
                  staticStyle: { width: "1.688rem", height: "1.688rem" },
                  attrs: {
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-home-active.png`,
                    alt: "img",
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "m-0 btn-active font-roboto cj-color-black-primary font-size-12 font-weight-500 font-italic",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cTabFooter.home")
                    ),
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "wrap-btn my-auto",
              attrs: { "data-cy": "button-to-search" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "custom-p",
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo("PSearchMenuM")
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFillSearch,
                        expression: "!isFillSearch",
                      },
                    ],
                    staticStyle: { width: "1.688rem", height: "1.688rem" },
                    attrs: {
                      src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-search-default.png`,
                      alt: "search",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFillSearch,
                        expression: "isFillSearch",
                      },
                    ],
                    staticStyle: { width: "1.688rem", height: "1.688rem" },
                    attrs: {
                      src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-search-active.png`,
                      alt: "search",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "m-0 font-roboto cj-color-black-primary font-size-12 font-weight-500 font-italic",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cTabFooter.search")
                      ),
                    },
                  }),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "wrap-btn my-auto" }, [
            _c(
              "button",
              {
                staticClass: "custom-p",
                attrs: { "data-cy": "button-to-chat" },
                on: {
                  click: function ($event) {
                    return _vm.navigateTo("PAppChat")
                  },
                },
              },
              [
                _vm.countChat > 0
                  ? _c("div", {
                      staticClass:
                        "notification-counter cj-color-white-primary font-roboto font-weight-700 font-size-10 my-auto",
                      class: _vm.countChat > 99 ? "actived-max-count" : "",
                      attrs: { "data-cy": "count-notification-message" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFillChat,
                      expression: "!isFillChat",
                    },
                  ],
                  staticStyle: { width: "1.688rem", height: "1.688rem" },
                  attrs: {
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-chat-default.png`,
                    alt: "chat",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFillChat,
                      expression: "isFillChat",
                    },
                  ],
                  staticStyle: { width: "1.688rem", height: "1.688rem" },
                  attrs: {
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-chat-active.png`,
                    alt: "chat",
                  },
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "m-0 font-roboto cj-color-black-primary font-size-12 font-weight-500 font-italic",
                  },
                  [_vm._v("Chat")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wrap-btn my-auto" }, [
            _c(
              "button",
              {
                staticClass: "custom-p",
                attrs: { "data-cy": "button-to-manage-order" },
                on: {
                  click: function ($event) {
                    return _vm.navigateTo("PManageOrder")
                  },
                },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFillOrder,
                      expression: "!isFillOrder",
                    },
                  ],
                  staticStyle: { width: "1.688rem", height: "1.688rem" },
                  attrs: {
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-auction-default.png`,
                    alt: "order",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFillOrder,
                      expression: "isFillOrder",
                    },
                  ],
                  staticStyle: { width: "1.688rem", height: "1.688rem" },
                  attrs: {
                    src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-auction-active.png`,
                    alt: "order",
                  },
                }),
                _vm._v(" "),
                _c("p", {
                  staticClass:
                    "m-0 font-roboto cj-color-black-primary font-size-12 font-weight-500 font-italic",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbComponent.cTabFooter.order")
                    ),
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "wrap-btn my-auto",
              attrs: { "data-cy": "button-to-profile" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "custom-p",
                  staticStyle: { position: "relative" },
                  on: {
                    click: function ($event) {
                      return _vm.navigateTo("PProfile")
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFillProfile && !_vm.authenticated,
                        expression: "!isFillProfile && !authenticated",
                      },
                    ],
                    staticStyle: { width: "1.688rem", height: "1.688rem" },
                    attrs: {
                      src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-profile-default.png`,
                      alt: "profile",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFillProfile && !_vm.authenticated,
                        expression: "isFillProfile && !authenticated",
                      },
                    ],
                    staticStyle: { width: "1.688rem", height: "1.688rem" },
                    attrs: {
                      src: `${_vm.$env.CDN_CB}/content/cb-img/ic-footer-tab-profile-active,png`,
                      alt: "profile",
                    },
                  }),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("c-user-photo-online", {
                            staticClass: "m-auto",
                            staticStyle: { "flex-shrink": "0" },
                            attrs: {
                              width: 1.688,
                              url: _vm.$options.filters.LAZY(
                                `${_vm.$env.CDN_CB}/CbAttachmentNoAuth/${
                                  _vm.getUser ? _vm.getUser.photoId : ""
                                }`,
                                27,
                                "ic-lazy-avatar.png"
                              ),
                              tabindex: "0",
                              isOnline: null,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "m-0 font-roboto cj-color-black-primary font-size-12 font-weight-500 font-italic",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbComponent.cTabFooter.profile")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.isSetUsername && _vm.authenticated
                    ? _c(
                        "div",
                        {
                          staticClass: "position-absolute-ph",
                          attrs: { "data-cy": "ph-warning" },
                          on: {
                            click: [
                              function ($event) {
                                $event.stopPropagation()
                              },
                              function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                              },
                            ],
                          },
                        },
                        [
                          _c("c-ph-warning", {
                            attrs: { isMobile: true },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }