var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "vac-slide-up" } }, [
    _vm.filteredUsersTag.length
      ? _c(
          "div",
          {
            staticClass: "vac-tags-container",
            style: { bottom: `${_vm.footerHeight}px` },
          },
          _vm._l(_vm.filteredUsersTag, function (user, index) {
            return _c(
              "div",
              {
                key: user._id,
                staticClass: "vac-tags-box",
                class: { "vac-tags-box-active": index === _vm.activeItem },
                on: {
                  mouseover: function ($event) {
                    _vm.activeItem = index
                  },
                  click: function ($event) {
                    return _vm.$emit("select-user-tag", user)
                  },
                },
              },
              [
                _c("div", { staticClass: "vac-tags-info" }, [
                  user.avatar
                    ? _c("div", {
                        staticClass: "vac-avatar vac-tags-avatar",
                        style: { "background-image": `url('${user.avatar}')` },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "vac-tags-username" }, [
                    _vm._v(
                      "\n          " + _vm._s(user.username) + "\n        "
                    ),
                  ]),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }