import { Inject, Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import CCardPromo from '../c-card-promo/c-card-promo.component';
import AlertService from '@/shared/alert/alert.service';
import Vue2Filters from 'vue2-filters';
import { CbVwPromo, ICbVwPromo } from '@/shared/model/cb-vw-promo.model';
import { ICbTextNoAuth } from '@/shared/model/cb-text-no-auth.model';
import PCbTextNoAuthService from '@/services/cb-text-no-auth.service';
import { ICbPromoUserAgg, CbPromoUserAgg } from '@/shared/model/cb-promo-user-agg.model';
import { CbPromoUsedStatus } from '@/shared/model/enumerations/cb-promo-used-status.model';
import { CbPromoTagType } from '@/shared/model/enumerations/cb-promo-tag-type.model';
import { CbPromoPayMethod } from '@/shared/model/enumerations/cb-promo-pay-method.model';
import { AdvanceSearchFilterKey } from '@/shared/model/advance-search-model';
import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import GlobalService from '@/services/global.service';
@Component({
  mixins: [Vue2Filters.mixin],
  components: {
    CButton,
    Icon,
    CCardPromo,
  },
})
export default class CCardPromoDetail extends Vue {
  // ================= START SERVICES =================
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('pCbTextNoAuthService') private pCbTextNoAuthService: () => PCbTextNoAuthService;
  @Inject('globalService') private globalService: () => GlobalService;
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  public appId: '';
  public canCopy = false;
  @Prop({ default: null }) public cbVwPromo: CbVwPromo;
  public cbTextNoAuth: ICbTextNoAuth = {};
  public cbPromoUserAgg: ICbPromoUserAgg = new CbPromoUserAgg();
  public cbPromoUsedStatus = CbPromoUsedStatus;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    this.canCopy = !!navigator.clipboard;
    if (this.currentLanguage === 'id') {
      this.findDescription(
        this.cbVwPromo.descriptionId ? this.cbVwPromo.descriptionId : this.cbVwPromo.descriptionEnId
      );
    } else {
      this.findDescription(
        this.cbVwPromo.descriptionEnId ? this.cbVwPromo.descriptionEnId : this.cbVwPromo.descriptionId
      );
    }
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public async copy(s) {
    await navigator.clipboard.writeText(s);
  }
  public findDescription(descriptionLang) {
    this.pCbTextNoAuthService()
      .find(descriptionLang)
      .then(res => {
        this.cbTextNoAuth = res;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  public toAdvancedSearch(promo: ICbVwPromo) {
    let queryObj = {};
    if (promo.cbPromoSpecifics != null) {
      for (const spec of promo.cbPromoSpecifics.split('||')) {
        const specDtl = spec.split('::');
        queryObj = Object.assign({}, this.assignQueryObj(queryObj, specDtl));
      }
    }

    this.$router.push({
      name: 'PAdvanceSearch',
      params: { key: new Date().getTime().toString() },
      query: queryObj,
    });
  }
  public assignQueryObj(queryObj: any, specDtl: string[]): any {
    if (specDtl[0] == CbPromoTagType.SERVICE_FIELD) {
      queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.FIELD;
    } else if (specDtl[0] == CbPromoTagType.SERVICE_INSIDE) {
      queryObj[AdvanceSearchFilterKey.SVCTYPE] = CbSvcType.INSIDE;
    } else if (specDtl[0] == CbPromoTagType.SERVICE_SPECIFIC) {
      if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] == null) {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`] = [];
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
      } else {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCID}`].push(specDtl[1]);
      }
    } else if (specDtl[0] == CbPromoTagType.CATEGORY_SPECIFIC) {
      if (queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] == null) {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`] = [];
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
      } else {
        queryObj[`${AdvanceSearchFilterKey.PROMOSVCCATID}`].push(specDtl[1]);
      }
    }
    queryObj['promoCode'] = this.cbVwPromo.code;
    return queryObj;
  }

  @Emit('saveCardPromoDetail')
  public saveCardPromoDetail() {
    return;
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get getPromoTag() {
    let promoTags: string[] = [];

    if (this.cbVwPromo?.payPromoMethod) {
      if (this.cbVwPromo.payPromoMethod == CbPromoPayMethod.ALL) promoTags.push(CbPromoPayMethod.ALL);
      else if (this.cbVwPromo.payPromoMethod == CbPromoPayMethod.BALANCE) promoTags.push(CbPromoPayMethod.BALANCE);
      else if (this.cbVwPromo.payPromoMethod == CbPromoPayMethod.CC) promoTags.push(CbPromoPayMethod.CC);
      else if (this.cbVwPromo.payPromoMethod == CbPromoPayMethod.VA) promoTags.push(CbPromoPayMethod.VA);
    }

    if (this.cbVwPromo?.cbPromoSpecifics?.length > 0) {
      promoTags = [...this.pushPromoTags(promoTags)];
    }

    return promoTags;
  }

  public pushPromoTags(promoTags: string[]): string[] {
    let promoTagSplit = this.cbVwPromo.cbPromoSpecifics.split('||');
    for (let item of promoTagSplit) {
      let fields = item.split('::');
      if (fields[0] == CbPromoTagType.SERVICE_ALL) {
        promoTags.push(CbPromoTagType.SERVICE_ALL);
      } else if (fields[0] == CbPromoTagType.SERVICE_FIELD) {
        promoTags.push(CbPromoTagType.SERVICE_FIELD);
      } else if (fields[0] == CbPromoTagType.SERVICE_INSIDE) {
        promoTags.push(CbPromoTagType.SERVICE_INSIDE);
      } else if (fields[0] == CbPromoTagType.SERVICE_SPECIFIC) {
        promoTags.push(fields[2].toUpperCase());
      } else if (fields[0] == CbPromoTagType.CATEGORY_SPECIFIC) {
        promoTags.push(fields[2].toUpperCase());
      }
    }
    return promoTags;
  }

  public close() {
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-card-promo-detail');
  }

  // ================= END COMPUTE ====================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
