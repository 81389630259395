var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass: "c-item-chat-mesage-appointment p-2 cj-bg-white-primary",
          attrs: { "data-cy": "item-chat-message-appointment" },
        },
        [
          _c(
            "div",
            {
              ref: "cbVwPawOrderRequirement.title",
              staticClass: "text-center mb-2",
            },
            [
              _c(
                "p",
                {
                  staticClass: "c-icm-font-card-title text-ellipsis-oneline",
                  attrs: { "data-cy": "message-title-appointment" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cbVwPawOrderRequirement.title) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "c-icm-font-card-subtitle mb-1 text-ellipsis-oneline",
                  attrs: { "data-cy": "message-labelOrId-and-paw-appointment" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                      " |\n      " +
                      _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                      "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.cbVwPawOrderRequirement.status
                ? _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-card-status mb-1 text-ellipsis oneline",
                      class: {
                        [_vm.getOrderStatusColorByStatus(
                          _vm.cbVwPawOrderRequirement.status,
                          "text"
                        )]: _vm.getOrderStatusColorByStatus(
                          _vm.cbVwPawOrderRequirement.status,
                          "text"
                        ),
                      },
                      attrs: { "data-cy": "message-status" },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.getOrderStatusTextByStatusOrder(
                              _vm.cbVwPawOrderRequirement.status
                            )
                          ) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["cbVwPawOrderRequirement.title"],
                    variant: "primary",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.title) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-catamaran font-size-12 font-weight-500",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.labelOrderId) +
                          " |\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.cbVwPawOrderRequirement.status
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto font-weight-500 font-size-12 mb-1 text-ellipsis oneline",
                          class: {
                            [_vm.getOrderStatusColorByStatus(
                              _vm.cbVwPawOrderRequirement.status,
                              "text"
                            )]: _vm.getOrderStatusColorByStatus(
                              _vm.cbVwPawOrderRequirement.status,
                              "text"
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.getOrderStatusTextByStatusOrder(
                                  _vm.cbVwPawOrderRequirement.status
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "getWorkLocation",
              staticClass:
                "rounded border-width-1 p-2 text-left mb-2 d-flex flex-column cj-border-color-red-primary",
            },
            [
              _c("p", {
                staticClass: "c-icm-font-title",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbAppointmentForm.location")
                  ),
                },
              }),
              _vm._v(" "),
              _vm.cbVwPawOrderRequirement.bookStartDate
                ? _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-content text-ellipsis threeline mt-1",
                      attrs: { "data-cy": "message-work-location-appointment" },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.getWorkLocation) + "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () => _vm.$refs["getWorkLocation"],
                    variant: "primary",
                  },
                },
                [
                  _c("p", {
                    staticClass: "font-catamaran font-size-12 font-weight-700",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbAppointmentForm.location")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.cbVwPawOrderRequirement.bookStartDate
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-400",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.getWorkLocation) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "cbVwPawOrderRequirement.bookStartDate",
              staticClass:
                "rounded border-width-1 p-2 text-left mb-2 cj-border-color-red-primary",
            },
            [
              _c("p", {
                staticClass: "c-icm-font-title",
                domProps: {
                  textContent: _vm._s(_vm.$t("cbgwApp.cbAppointmentForm.date")),
                },
              }),
              _vm._v(" "),
              _vm.cbVwPawOrderRequirement.bookStartDate
                ? _c(
                    "p",
                    {
                      staticClass:
                        "c-icm-font-content mt-1 text-ellipsis oneline",
                      attrs: {
                        "data-cy": "message-bookstart-date-appointment",
                      },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$options.filters.convertDateToAccordanceTimeZone(
                              this.cbVwPawOrderRequirement?.bookStartDate,
                              this.cbVwPawOrderRequirement?.timeZone
                            )
                          ) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  staticClass: "custom-tooltip",
                  attrs: {
                    target: () =>
                      _vm.$refs["cbVwPawOrderRequirement.bookStartDate"],
                    variant: "primary",
                  },
                },
                [
                  _c("p", {
                    staticClass: "font-catamaran font-size-12 font-weight-700",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("cbgwApp.cbAppointmentForm.date")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.cbVwPawOrderRequirement.bookStartDate
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$options.filters.convertDateToAccordanceTimeZone(
                                  this.cbVwPawOrderRequirement?.bookStartDate,
                                  this.cbVwPawOrderRequirement?.timeZone
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "cbTextRestrict.content",
              staticClass:
                "rounded border-width-1 p-2 text-left mb-2 cj-border-color-red-primary",
            },
            [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c("p", {
                  staticClass: "c-icm-font-title my-auto text-ellipsis-oneline",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("cbgwApp.cbAppointmentForm.description")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "d-block" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                      attrs: {
                        type: "button",
                        "data-cy": "message-btn-drop-desc",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isExpandAppointDesc = !_vm.isExpandAppointDesc
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass:
                          "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                        class: _vm.isExpandAppointDesc ? "actived" : "",
                        attrs: {
                          name: "ic_down",
                          width: "1rem",
                          height: "1rem",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  model: {
                    value: _vm.isExpandAppointDesc,
                    callback: function ($$v) {
                      _vm.isExpandAppointDesc = $$v
                    },
                    expression: "isExpandAppointDesc",
                  },
                },
                [
                  _vm.cbTextRestrict
                    ? _c("c-textarea-preview", {
                        staticClass: "c-icm-font-content",
                        attrs: {
                          "data-cy-custom": "message-collapse-desc-appointment",
                          text: _vm.cbTextRestrict.content,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-wrap" }, [
            _c(
              "div",
              { staticClass: "w-100 mb-2 mx-1" },
              [
                _c(
                  "c-button",
                  {
                    staticClass: "mt-1 c-icm-font-btn",
                    attrs: {
                      fullWidth: "",
                      "type-style": "secondary",
                      "data-cy": "message-btn-view-appointment",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.handleOpenPreviewAppointment.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("cbgwApp.cbAppointmentForm.view")
                        ),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.cbVwPawOrderRequirement.buyerId == _vm.currentUser.id &&
            _vm.cbVwPawOrderRequirement.status === _vm.CbCommonStatus.REQUESTED
              ? _c(
                  "div",
                  { staticClass: "w-100 mb-2 mx-1" },
                  [
                    _c(
                      "c-button",
                      {
                        staticClass: "mt-1 c-icm-font-btn",
                        attrs: {
                          fullWidth: "",
                          "data-cy": "message-btn-accept-appointment",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleBuyerAction.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("cbgwApp.cbAppointmentForm.accept")
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }