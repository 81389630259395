import axios from 'axios';

const baseApiUrl = 'services/cbcorems/api/cb-vw-log-paw-relateds';

export default class PCbVwLogPawRelatedService {
  public retrieveByLogin(params?: any, cancelToken?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/by-login', { params, cancelToken })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
