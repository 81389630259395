var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwAuctionProjectPreOffer
    ? _c(
        "div",
        {
          staticClass:
            "c-item-chat-message-auction-project p-2 cj-bg-white-primary",
          attrs: { "data-cy": "c-item-chat-message-auction-project" },
        },
        [
          _c("icon", {
            staticClass: "ic_medal_interested",
            attrs: {
              name: "ic_medal_interested",
              width: "4rem",
              height: "4rem",
            },
          }),
          _vm._v(" "),
          _vm.cbVwAuctionProjectPreOffer &&
          !_vm.cbVwAuctionProjectPreOffer.isActive
            ? _c(
                "div",
                {
                  ref: "underReview",
                  staticClass:
                    "d-flex align-items-center rounded border-width-1 p-1 cj-border-color-red-primary",
                },
                [
                  _c("icon", {
                    staticClass: "cj-fill-red-primary mr-2",
                    attrs: {
                      name: "ic_exclamation",
                      width: "25px",
                      height: "25px",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "c-icm-font-forbidden text-ellipsis" },
                    [
                      _vm._v(
                        "Unfortunately this Pitch is removed by freelancer."
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "custom-tooltip",
                      attrs: {
                        target: () => _vm.$refs["underReview"],
                        variant: "primary",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("icon", {
                            staticClass: "cj-fill-red-primary mr-2",
                            attrs: {
                              name: "ic_exclamation",
                              width: "25px",
                              height: "25px",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-catamaran font-size-10 cj-color-white-primary",
                            },
                            [
                              _vm._v(
                                "Unfortunately this Pitch is removed by freelancer."
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "text-center mb-2" },
                    [
                      _c(
                        "p",
                        {
                          ref: "cbVwAuctionProjectPreOffer.title",
                          staticClass:
                            "max-title-wrapper c-icm-font-card-title text-ellipsis oneline mx-auto",
                          attrs: { "data-cy": "auction-project-title" },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.cbVwAuctionProjectPreOffer.title) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "c-icm-font-card-subtitle mb-1 text-ellipsis oneline",
                          attrs: { "data-cy": "auction-project-svc" },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.cbVwAuctionProjectPreOffer.svcName) +
                              " | " +
                              _vm._s(_vm.cbVwAuctionProjectPreOffer.catName) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tooltip",
                        {
                          staticClass: "custom-tooltip",
                          attrs: {
                            target: () =>
                              _vm.$refs["cbVwAuctionProjectPreOffer.title"],
                            variant: "primary",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "max-title-wrapper c-icm-font-card-title text-break-all mx-auto",
                              attrs: { "data-cy": "auction-project-title" },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.cbVwAuctionProjectPreOffer.title) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "c-icm-font-card-subtitle mb-1 text-break-all",
                              attrs: { "data-cy": "auction-project-svc" },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.cbVwAuctionProjectPreOffer.svcName
                                  ) +
                                  " | " +
                                  _vm._s(
                                    _vm.cbVwAuctionProjectPreOffer.catName
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "cbVwAuctionProjectPreOffer.detail",
                      staticClass:
                        "rounded border-width-1 p-2 mb-2 text-center cj-border-color-red-primary",
                    },
                    [
                      _c("p", {
                        staticClass: "c-icm-font-price-title",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("cbgwApp.cbDetailOrder.totalPrice")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "c-icm-font-price-content text-ellipsis oneline",
                          attrs: { "data-cy": "auction-project-currency" },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.cbVwAuctionProjectPreOffer.currency) +
                              "\n        " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.cbVwAuctionProjectPreOffer.budget,
                                  _vm.cbVwAuctionProjectPreOffer.currency
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-wrap justify-content-center mx-min-2 mt-1",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex mb-1 px-2 align-items-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex my-auto mr-1" },
                                [
                                  _c("icon", {
                                    staticClass: "cj-fill-red-primary my-auto",
                                    attrs: {
                                      name: "ic_time_outlined",
                                      width: "18px",
                                      height: "18px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "c-icm-font-delivery-content",
                                  attrs: {
                                    "data-cy": "auction-project-delivery-time",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.getDeliveryTimeType(
                                          _vm.cbVwAuctionProjectPreOffer
                                            .deliveryTimeType,
                                          _vm.cbVwAuctionProjectPreOffer
                                            .deliveryTime
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.cbVwAuctionProjectPreOffer.revisionCount &&
                          _vm.cbVwAuctionProjectPreOffer.svcType ===
                            _vm.CbSvcType.INSIDE
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex mb-1 px-2 align-items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex my-auto mr-1" },
                                    [
                                      _c("icon", {
                                        staticClass:
                                          "cj-fill-red-primary my-auto",
                                        attrs: {
                                          name: "ic_revision",
                                          width: "18px",
                                          height: "18px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-icm-font-delivery-content",
                                      attrs: {
                                        "data-cy": "auction-project-revision",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.cbVwAuctionProjectPreOffer
                                              .revisionCount
                                          ) +
                                          "\n\n            "
                                      ),
                                      _c("span", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              _vm.getRevisionTextEnId(
                                                _vm.cbVwAuctionProjectPreOffer
                                                  .revisionCount
                                              )
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tooltip",
                        {
                          staticClass: "custom-tooltip",
                          attrs: {
                            target: () =>
                              _vm.$refs["cbVwAuctionProjectPreOffer.detail"],
                            variant: "primary",
                          },
                        },
                        [
                          _c("p", {
                            staticClass:
                              "font-catamaran font-size-12 font-weight-500",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("cbgwApp.cbDetailOrder.totalPrice")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-catamaran font-size-12 font-weight-500",
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.cbVwAuctionProjectPreOffer.currency
                                  ) +
                                  "\n          " +
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.cbVwAuctionProjectPreOffer.budget,
                                      _vm.cbVwAuctionProjectPreOffer.currency
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-wrap justify-content-center mx-min-2 mt-1",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex mb-1 px-2 align-items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex my-auto mr-1" },
                                    [
                                      _c("icon", {
                                        staticClass:
                                          "cj-fill-red-primary my-auto",
                                        attrs: {
                                          name: "ic_time_outlined",
                                          width: "18px",
                                          height: "18px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-icm-font-delivery-content",
                                      attrs: {
                                        "data-cy":
                                          "auction-project-delivery-time",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getDeliveryTimeType(
                                              _vm.cbVwAuctionProjectPreOffer
                                                .deliveryTimeType,
                                              _vm.cbVwAuctionProjectPreOffer
                                                .deliveryTime
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.cbVwAuctionProjectPreOffer.revisionCount &&
                              _vm.cbVwAuctionProjectPreOffer.svcType ===
                                _vm.CbSvcType.INSIDE
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex mb-1 px-2 align-items-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex my-auto mr-1" },
                                        [
                                          _c("icon", {
                                            staticClass:
                                              "cj-fill-red-primary my-auto",
                                            attrs: {
                                              name: "ic_revision",
                                              width: "18px",
                                              height: "18px",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "c-icm-font-delivery-content",
                                          attrs: {
                                            "data-cy":
                                              "auction-project-revision",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.cbVwAuctionProjectPreOffer
                                                  .revisionCount
                                              ) +
                                              "\n\n              "
                                          ),
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(
                                                  _vm.getRevisionTextEnId(
                                                    _vm
                                                      .cbVwAuctionProjectPreOffer
                                                      .revisionCount
                                                  )
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
                      staticStyle: { "word-break": "break-all" },
                      attrs: {
                        "data-cy": "auction-project-description-section",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _c("p", {
                            staticClass: "c-icm-font-title my-auto",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("cbGlobal.description")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-block" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                                attrs: {
                                  type: "button",
                                  "data-cy":
                                    "auction-project-btn-expand-description",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.visibleDescription =
                                      !_vm.visibleDescription
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  staticClass:
                                    "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                                  class: _vm.visibleDescription
                                    ? "actived"
                                    : "",
                                  attrs: {
                                    name: "ic_down",
                                    width: "1rem",
                                    height: "1rem",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          model: {
                            value: _vm.visibleDescription,
                            callback: function ($$v) {
                              _vm.visibleDescription = $$v
                            },
                            expression: "visibleDescription",
                          },
                        },
                        [
                          !_vm.cbVwAuctionProjectPreOffer ||
                          !_vm.cbVwAuctionProjectPreOffer.description
                            ? _c("div", [
                                _c("p", { staticClass: "c-icm-font-content" }, [
                                  _vm._v("No Data"),
                                ]),
                              ])
                            : [
                                _c("c-textarea-preview", {
                                  staticClass: "c-icm-font-content text-break",
                                  attrs: {
                                    "data-cy-custom":
                                      "auction-project-collapse-description",
                                    text: _vm.cbVwAuctionProjectPreOffer
                                      .description,
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
                      staticStyle: { "word-break": "break-all" },
                      attrs: { "data-cy": "auction-project-pitch-section" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _c("p", {
                            staticClass: "c-icm-font-title my-auto",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("cbGlobal.freelancerPitch")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-block" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                                attrs: {
                                  type: "button",
                                  "data-cy": "auction-project-btn-expand-pitch",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.visiblePitch = !_vm.visiblePitch
                                  },
                                },
                              },
                              [
                                _c("icon", {
                                  staticClass:
                                    "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                                  class: _vm.visiblePitch ? "actived" : "",
                                  attrs: {
                                    name: "ic_down",
                                    width: "1rem",
                                    height: "1rem",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          model: {
                            value: _vm.visiblePitch,
                            callback: function ($$v) {
                              _vm.visiblePitch = $$v
                            },
                            expression: "visiblePitch",
                          },
                        },
                        [
                          !_vm.cbVwAuctionProjectPreOffer ||
                          !_vm.cbVwAuctionProjectPreOffer.pitch
                            ? _c("div", [
                                _c("p", { staticClass: "c-icm-font-content" }, [
                                  _vm._v("No Data"),
                                ]),
                              ])
                            : [
                                _c("c-textarea-preview", {
                                  staticClass: "c-icm-font-content",
                                  attrs: {
                                    "data-cy-custom":
                                      "auction-project-collapse-pitch",
                                    text: _vm.cbVwAuctionProjectPreOffer.pitch,
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.cbVwAuctionProjectPreOffer.bookStartDate
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
                          staticStyle: { "word-break": "break-all" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("p", {
                                staticClass: "c-icm-font-title my-auto",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("cbgwApp.cbDetailOrder.bookDate")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-block" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                                    attrs: {
                                      "data-cy": "auction-project-show-date",
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.visibleDate = !_vm.visibleDate
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      staticClass:
                                        "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                                      class: _vm.visibleDate ? "actived" : "",
                                      attrs: {
                                        name: "ic_down",
                                        width: "1rem",
                                        height: "1rem",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            {
                              model: {
                                value: _vm.visibleDate,
                                callback: function ($$v) {
                                  _vm.visibleDate = $$v
                                },
                                expression: "visibleDate",
                              },
                            },
                            [
                              _vm.$options.filters.convertDateToAccordanceTimeZone(
                                _vm.cbVwAuctionProjectPreOffer?.bookStartDate,
                                _vm.cbVwAuctionProjectPreOffer?.timeZone
                              )
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "c-icm-font-content text-left",
                                      attrs: {
                                        "data-cy":
                                          "auction-project-collapse-booking-date",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.$options.filters.convertDateToAccordanceTimeZone(
                                              _vm.cbVwAuctionProjectPreOffer
                                                ?.bookStartDate,
                                              _vm.cbVwAuctionProjectPreOffer
                                                ?.timeZone
                                            )
                                          ) +
                                          "\n        "
                                      ),
                                    ]
                                  )
                                : _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "c-icm-font-content" },
                                      [_vm._v("No Data")]
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cbVwAuctionProjectPreOffer.toWorkLocation
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "rounded border-width-1 p-2 text-center mb-2 cj-border-color-red-primary",
                          staticStyle: { "word-break": "break-all" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("p", {
                                staticClass: "c-icm-font-title my-auto",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("cbgwApp.cbDetailOrder.workLocation")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-block" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "px-2 rounded border-width-1 cj-border-color-grey-secondary",
                                    attrs: {
                                      "data-cy":
                                        "auction-project-show-location",
                                      type: "button",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.visibleLocation =
                                          !_vm.visibleLocation
                                      },
                                    },
                                  },
                                  [
                                    _c("icon", {
                                      staticClass:
                                        "my-auto cj-fill-grey-primary ic_arrow_dropdown cb-ic_arrow_dropdown",
                                      class: _vm.visibleLocation
                                        ? "actived"
                                        : "",
                                      attrs: {
                                        name: "ic_down",
                                        width: "1rem",
                                        height: "1rem",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            {
                              model: {
                                value: _vm.visibleLocation,
                                callback: function ($$v) {
                                  _vm.visibleLocation = $$v
                                },
                                expression: "visibleLocation",
                              },
                            },
                            [
                              _vm.toWorkLocation && _vm.toWorkLocation.address
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-1 c-icm-font-content text-ellipsis oneline",
                                        attrs: {
                                          "data-cy":
                                            "auction-project-collapse-location",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.toWorkLocation.address) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      staticClass:
                                        "c-icm-font-text-btn cj-btn-text-primary",
                                      attrs: {
                                        "data-cy": "message-btn-open-map",
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("cbGlobal.viewInMap")
                                        ),
                                      },
                                      on: { click: _vm.handleOpenLocation },
                                    }),
                                  ])
                                : _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "c-icm-font-content" },
                                      [_vm._v("No Data")]
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cbVwAuctionProjectPreOffer.senderId ===
                    _vm.currentUser.id &&
                  _vm.cbVwAuctionProjectPreOffer.auctionStatus ===
                    _vm.CbCommonStatus.ACTIVE &&
                  (_vm.cbVwAuctionProjectPreOffer.status ===
                    _vm.CbCommonStatus.PRE_CHOOSEN ||
                    _vm.cbVwAuctionProjectPreOffer.status ===
                      _vm.CbCommonStatus.WAITING)
                    ? _c(
                        "c-button",
                        {
                          staticClass: "mt-2 c-icm-font-btn",
                          attrs: {
                            fullWidth: "",
                            "data-cy": "auction-project-btn-make-offer",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openMakeOffer.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n      Make an Offer\n    ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }