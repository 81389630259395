import PCbAnnouncementService from '@/services/cb-announcement.service';
import { ICbAnnouncement } from '@/shared/model/cb-announcement.model';
import { AnnouncementType } from '@/shared/model/enumerations/announcement-type.model';
import { CbLoginType } from '@/shared/model/enumerations/cb-login-type.model';
import { Inject, Component, Vue, Emit } from 'vue-property-decorator';
import CButton from '../button/c-button.vue';
import { NavbarType } from '../navbar/c-navbar.component';
import PCbPersonalizeInterestsService from '@/services/cb-personalize-interests.service';
import GlobalService from '@/services/global.service';

@Component({
  components: {
    CButton,
  },
})
export default class CLoginIntroduction extends Vue {
  // ================= SERVICES =================
  @Inject('pCbAnnouncementService') private pCbAnnouncementService: () => PCbAnnouncementService;
  @Inject('pCbPersonalizeInterestsService') private pCbPersonalizeInterestsService: () => PCbPersonalizeInterestsService;
  @Inject('globalService') private globalService: () => GlobalService;
  // ================= END SERVICES =================
  // ================= VARIABLES =================
  public isSelectType: boolean = true;
  public userRoleFlag: string = 'BUYER';
  public selectLoginType: CbLoginType = null;
  public CbLoginType = CbLoginType;
  public isCollapse: boolean = false;

  public isSaving: boolean = false;
  // ================= END VARIABLES =================
  // ================= DEFAULT FUNCTION =================
  // ================= END DEFAULT FUNCTION =================
  // ================= FUNCTION =================
  public onChangeType(type) {
    if (type === 'BUYER') {
      this.isSelectType = true;
    }
    if (type === 'FREELANCER') {
      this.isSelectType = false;
    }
  }

  public changeClass(role: string): string {
    if (this.userRoleFlag === role) {
      return 'selected';
    } else {
      return 'not-selected';
    }
  }
  public handleLogin() {
    this.$store.commit('loginType', NavbarType.BUYER);
    this.createCbAnnouncementByLogin();
  }

  @Emit('cancel')
  public cancel() {
    return;
  }

  public createCbAnnouncementByLogin() {
    this.isSaving = true;
    const cbAnnouncement: ICbAnnouncement = {
      announcementType: AnnouncementType.INTRODUCTION,
    };
    this.pCbAnnouncementService()
      .createByLogin(cbAnnouncement)
      .then(res => {
        this.isSaving = false;
        this.cancel();
        if (this.selectLoginType === CbLoginType.PARTNER) {
          if (this.$store.getters.hasRoleCbPartner == false) {
            this.$router.push({ name: 'PRegisterFreelancerOne', params: { svcType: 'INSIDE' } });
          }
        } else if (this.selectLoginType === CbLoginType.BUYER) {
          this.retrieveCountCbPersonalizeInterests();
        }
      })
      .catch(err => {
        this.isSaving = false;
      });
  }
  public retrieveCountCbPersonalizeInterests() {
    this.pCbPersonalizeInterestsService()
      .retrieveCountByLogin()
      .then(res => {
        if (res.data == 0) {
          this.globalService().openGlobalDialog(this.$root, 'modal-personalize-y-interests');
        }
      });
  }
  // ================= END FUNCTION =================
  // ================= COMPUTE =================
  // ================= END COMPUTE =================
}
