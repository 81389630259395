import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import CMedalProgress from '@/components/c-medal-progress/c-medal-progress.vue';
import CUserPhotoLevel from '@/components/c-user-photo-level/c-user-photo-level.vue';

import JhiDataUtils from '@/shared/data/data-utils.service';
import locationUltis from '@/shared/data/location-ultis.service';
import UtilFormatter from '@/shared/utils/util-formatter.component';
import StatusTextEnId from '@/shared/data/status-text-en-Id.service';

import { CbSvcType } from '@/shared/model/enumerations/cb-svc-type.model';
import { DeviceSizeType } from '@/shared/model/enumerations/device-size-type.model';

@Component({
  components: { CUserPhotoLevel, CMedalProgress },
  mixins: [UtilFormatter, locationUltis],
})
export default class CPdProfileUser extends mixins(JhiDataUtils, StatusTextEnId) {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop() public cbVwPaw;
  @Prop() public cbVwMedals;
  @Prop() public cbVwUser;
  @Prop() public cbVwUserSummary;
  @Prop() public cbVwAvgResponseTime;

  public isShowProfileDetail = false;

  public deviceSizeType = DeviceSizeType;
  public CbSvcType = CbSvcType;
  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get isMobile() {
    return this.$store.getters.isMobile;
  }
  public get isDeviceSize() {
    return this.$store.getters.isDeviceSize;
  }
  public get userIdsOnline() {
    return this.$store.getters.userIdsOnline;
  }
  public get currentLanguage() {
    return this.$store.getters.currentLanguage;
  }

  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
