import { ICbLocation } from '@/shared/model/cb-location.model';

import { CbLogType } from '@/shared/model/enumerations/cb-log-type.model';
export interface ICbLog {
  id?: number;
  cbLogType?: CbLogType | null;
  valueHitId?: number | null;
  valueHit?: string | null;
  ip?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  cbLocation?: ICbLocation | null;
}

export class CbLog implements ICbLog {
  constructor(
    public id?: number,
    public cbLogType?: CbLogType | null,
    public valueHitId?: number | null,
    public valueHit?: string | null,
    public ip?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public cbLocation?: ICbLocation | null
  ) {}
}
