import { IChtMessage } from './cht-message.model';

export interface IChtLocation {
  id?: number;
  country?: string | null;
  state?: string | null;
  province?: string | null;
  city?: string | null;
  subCity?: string | null;
  zipCode?: string | null;
  address?: string | null;
  addressPinPoint?: string | null;
  latGoogle?: string | null;
  longGoogle?: string | null;
  placeId?: string | null;
  placeName?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  chtMessage?: IChtMessage | null;
  snapshot?: string | null; //transient
}

export class ChtLocation implements IChtLocation {
  constructor(
    public id?: number,
    public country?: string | null,
    public state?: string | null,
    public province?: string | null,
    public city?: string | null,
    public subCity?: string | null,
    public zipCode?: string | null,
    public address?: string | null,
    public addressPinPoint?: string | null,
    public latGoogle?: string | null,
    public longGoogle?: string | null,
    public placeId?: string | null,
    public placeName?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public chtMessage?: IChtMessage | null,
    public snapshot?: string | null //transient
  ) {}
}
