import { render, staticRenderFns } from "./c-pd-portfolio-list.vue?vue&type=template&id=7452a383&scoped=true&"
import script from "./c-pd-portfolio-list.component.ts?vue&type=script&lang=js&"
export * from "./c-pd-portfolio-list.component.ts?vue&type=script&lang=js&"
import style0 from "./c-pd-portfolio-list.scss?vue&type=style&index=0&id=7452a383&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7452a383",
  null
  
)

export default component.exports