import axios from 'axios';

import { ICbVwPromo } from '@/shared/model/cb-vw-promo.model';

const baseApiUrl = 'services/cbuserms/api/cb-vw-promos';

export default class PCbVwPromoService {
  public find(id: number): Promise<ICbVwPromo> {
    return new Promise<ICbVwPromo>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cb', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveByLogin(params?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/cb/by-login', { params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
