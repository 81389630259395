import axios from 'axios';

import { ICbPawOrder } from '@/shared/model/cb-paw-order.model';
import { IWfHistory } from '@/shared/model/wf-history.model';

const baseApiUrl = 'services/cbcorems/api/cb-paw-orders';

export default class PCbPawOrderService {
  public getAaltByAmount(amount?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/aalt/${amount}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICbPawOrder, wfHistory: IWfHistory): Promise<ICbPawOrder> {
    // WF
    if (!wfHistory) {
      console.log('wf needed');
      return;
    }
    entity.wfHistory = wfHistory;

    return new Promise<ICbPawOrder>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
