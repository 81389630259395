import { ICbAttachmentRestrict } from '@/shared/model/cb-attachment-restrict.model';
import { ICbUserInfo } from '@/shared/model/cb-user-info.model';
import { ICbUserDataVerification } from '@/shared/model/cb-user-data-verification.model';

import { CbUserDataType } from '@/shared/model/enumerations/cb-user-data-type.model';
import { CbVerificationStatus } from './enumerations/cb-verification-status.model';
export interface ICbUserData {
  id?: number;
  userDataType?: CbUserDataType;
  name?: string | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbAttachmentRestrict?: ICbAttachmentRestrict | null;
  cbUserInfo?: ICbUserInfo | null;
  cbUserDataVerifications?: ICbUserDataVerification[] | null;
  dataKey?: string | null; // transient
  groupDate?: Date | null;
  externalId?: string | null;
  entityLabel?: string | null;
  uploadLink?: string | null;
  value?: string | null;
  localUrl?: string | null;
  isPoliceAgreement?: boolean | null;
  verification?: CbVerificationStatus | null;
  noteCrm?: string | null;
}

export class CbUserData implements ICbUserData {
  constructor(
    public id?: number,
    public userDataType?: CbUserDataType,
    public name?: string | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbAttachmentRestrict?: ICbAttachmentRestrict | null,
    public cbUserInfo?: ICbUserInfo | null,
    public cbUserDataVerifications?: ICbUserDataVerification[] | null,
    public dataKey?: string | null, // transient
    public groupDate?: Date | null,
    public externalId?: string | null,
    public entityLabel?: string | null,
    public uploadLink?: string | null,
    public value?: string | null,
    public localUrl?: string | null,
    public isPoliceAgreement?: boolean | null,
    public verification?: CbVerificationStatus | null,
    public noteCrm?: string | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
