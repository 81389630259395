import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';

import { orderActivityEn, orderActivityId } from '../constant/order-activity';
import { ICbVwPawOrderRequirement } from '../model/cb-vw-paw-order-requirement.model';

@Component
export default class UtilsFunction extends Vue {
  public get getContentTracks() {
    return (workflowName: string, keyName: string) => {
      const description = {
        en: '',
        id: '',
      };

      if (workflowName === 'OR_INSIDE_WORKER') {
        switch (keyName) {
          case 'SEND_OFFER':
            description.en = orderActivityEn.sendOffer;
            description.id = orderActivityId.sendOffer;
            break;
          case 'CALLBACK_PAYMENT_PAID_ORDER':
            description.en = orderActivityEn.offerPaidAndOrderStarted;
            description.id = orderActivityId.offerPaidAndOrderStarted;
            break;
          case 'CHANGE_STATUS_FROM_OFFER':
            description.en = orderActivityEn.offerExpired;
            description.id = orderActivityId.offerExpired;
            break;
          case 'CREATE_NOTE_FREE':
          case 'CREATE_NOTE_CHARGE':
            description.en = orderActivityEn.newNoteRequested;
            description.id = orderActivityId.newNoteRequested;
            break;
          case 'DECLINE_NOTE_FREE':
          case 'DECLINE_NOTE_CHARGE':
            description.en = orderActivityEn.newNoteDeclined;
            description.id = orderActivityId.newNoteDeclined;
            break;
          case 'ACCEPT_NOTE_FREE':
            description.en = orderActivityEn.newNoteAccepted;
            description.id = orderActivityId.newNoteAccepted;
            break;
          case 'CALLBACK_PAYMENT_PAID_NOTE':
            description.en = orderActivityEn.newNotePaid;
            description.id = orderActivityId.newNotePaid;
            break;
          case 'CREATE_APPOINTMENT':
            description.en = orderActivityEn.newAppointmentRequested;
            description.id = orderActivityId.newAppointmentRequested;
            break;
          case 'ACCEPT_APPOINTMENT':
            description.en = orderActivityEn.appointmentRequestAccepted;
            description.id = orderActivityId.appointmentRequestAccepted;
            break;
          case 'DECLINE_APPOINTMENT':
          case 'DECLINE_APPOINTMENT_CRON':
            description.en = orderActivityEn.appointmentRequestDeclined;
            description.id = orderActivityId.appointmentRequestDeclined;
            break;
          case 'UPLOAD_MILESTONE':
            description.en = orderActivityEn.freelancerUpdatedMilestone;
            description.id = orderActivityId.freelancerUpdatedMilestone;
            break;
          case 'UPLOAD_DELIVERY':
            description.en = orderActivityEn.freelancerSentProjectDelivery;
            description.id = orderActivityId.freelancerSentProjectDelivery;
            break;
          case 'REQUEST_FOR_REVISION_FREE':
            description.en = orderActivityEn.buyerRequestedForRevision;
            description.id = orderActivityId.buyerRequestedForRevision;
            break;
          case 'REQUEST_FOR_EXTRA_REVISION':
            description.en = orderActivityEn.buyerRequestedForExtraRevision;
            description.id = orderActivityId.buyerRequestedForExtraRevision;
            break;
          case 'CANCEL_REVISION_EXTRA':
            description.en = orderActivityEn.freelancerDeclinedExtraRevisionRequest;
            description.id = orderActivityId.freelancerDeclinedExtraRevisionRequest;
            break;
          case 'SEND_OFFER_REVISION_EXTRA':
            description.en = orderActivityEn.freelancerRequestPaymentForExtraRevision;
            description.id = orderActivityId.freelancerRequestPaymentForExtraRevision;
            break;
          case 'CANCEL_REVISION_EXTRA_CRON':
          case 'EXTRA_REVISION_DECLINED':
            description.en = orderActivityEn.buyerDeclinedPaymentRequestForExtraRevision;
            description.id = orderActivityId.buyerDeclinedPaymentRequestForExtraRevision;
            break;
          case 'CALLBACK_PAYMENT_PAID_REVISION_EXTRA':
            description.en = orderActivityEn.buyerPaidForExtraRevision;
            description.id = orderActivityId.buyerPaidForExtraRevision;
            break;
          case 'ACCEPTED_REVISION_EXTRA_FREE':
            description.en = orderActivityEn.buyerAcceptedOfferedExtraRevision;
            description.id = orderActivityId.buyerAcceptedOfferedExtraRevision;
            break;
          case 'EXTRA_REVISION_FREE_DECLINED':
          case 'CANCEL_REVISION_EXTRA_FREE_CRON':
            description.en = orderActivityEn.buyerDeclinedOfferedExtraRevision;
            description.id = orderActivityId.buyerDeclinedOfferedExtraRevision;
            break;
          case 'ACCEPT_DELIVERY':
            description.en = orderActivityEn.buyerAcceptedProjectDeliveryOrderCompleted;
            description.id = orderActivityId.buyerAcceptedProjectDeliveryOrderCompleted;
            break;
          case 'COMPLETED_BY_AALT_CRON':
            description.en = orderActivityEn.projectDeliveryReachedAutoAcceptanceLeadTimeOrderCompleted;
            description.id = orderActivityId.projectDeliveryReachedAutoAcceptanceLeadTimeOrderCompleted;
            break;
          case 'REQUEST_DISPUTE':
            description.en = orderActivityEn.orderDisputedYourOrderIsUnderOurReview;
            description.id = orderActivityId.orderDisputedYourOrderIsUnderOurReview;
            break;
          case 'BUYER_WIN':
            description.en = orderActivityEn.disputeRequestAcceptedAndOrderCancelled;
            description.id = orderActivityId.disputeRequestAcceptedAndOrderCancelled;
            break;
          case 'PARTNER_WIN':
            description.en = orderActivityEn.disputeRequestDeclinedAndOrderContinued;
            description.id = orderActivityId.disputeRequestDeclinedAndOrderContinued;
            break;
          case 'CHANGE_STATUS_FROM_ON_PROGRESS':
            description.en = orderActivityEn.orderReachedProjectDeliveryTimeLimit;
            description.id = orderActivityId.orderReachedProjectDeliveryTimeLimit;
            break;
          case 'CHANGE_STATUS_FROM_OVERDUE':
            description.en = orderActivityEn.buyerCancelOverdueOrder;
            description.id = orderActivityId.buyerCancelOverdueOrder;
            break;
          case 'CREATE_EXTEND_TIME':
            description.en = orderActivityEn.buyerExtendProjectDeliveryTime;
            description.id = orderActivityId.buyerExtendProjectDeliveryTime;
            break;
        }
      } else if (workflowName === 'OR_FIELD_WORKER') {
        switch (keyName) {
          case 'SEND_OFFER':
            description.en = orderActivityEn.sendOffer;
            description.id = orderActivityId.sendOffer;
            break;
          case 'CHANGE_STATUS_FROM_OFFER':
            description.en = orderActivityEn.offerExpired;
            description.id = orderActivityId.offerExpired;
            break;
          case 'CALLBACK_PAYMENT_PAID_ORDER':
            description.en = orderActivityEn.offerPaidAndOrderBooked;
            description.id = orderActivityId.offerPaidAndOrderBooked;
            break;
          case 'ADDITIONAL_CHARGE':
          case 'ADDITIONAL_FREE':
            description.en = orderActivityEn.freelancerSentAdditionalServiceRequest;
            description.id = orderActivityId.freelancerSentAdditionalServiceRequest;
            break;
          case 'DECLINE_ADDITIONAL':
          case 'DECLINE_ADDITIONAL_2':
          case 'DECLINE_ADDITIONAL_CRON':
            description.en = orderActivityEn.buyerDeclinedAdditionalServiceRequest;
            description.id = orderActivityId.buyerDeclinedAdditionalServiceRequest;
            break;
          case 'ACCEPT_ADDITIONAL':
            description.en = orderActivityEn.buyerAcceptedAdditionalServiceRequest;
            description.id = orderActivityId.buyerAcceptedAdditionalServiceRequest;
            break;
          case 'CALLBACK_PAYMENT_PAID_BOOKED':
            description.en = orderActivityEn.buyerPaidAdditionalServiceRequest;
            description.id = orderActivityId.buyerPaidAdditionalServiceRequest;
            break;
          case 'CHANGE_STATUS_FROM_BOOKED':
          case 'CHANGE_STATUS_FROM_ON_MY_WAY':
            description.en = orderActivityEn.freelancerIsLate;
            description.id = orderActivityId.freelancerIsLate;
            break;
          case 'CHANGE_STATUS_FROM_LATE_ON_TRACK':
          case 'CHANGE_STATUS_FROM_LATE':
            description.en = orderActivityEn.buyerCancelLateOrder;
            description.id = orderActivityId.buyerCancelLateOrder;
            break;
          case 'CREATE_ON_MY_WAY':
            description.en = orderActivityEn.freelancerIsOnTheWayToWorkLocation;
            description.id = orderActivityId.freelancerIsOnTheWayToWorkLocation;
            break;
          case 'CREATE_CLOCK_IN':
            description.en = orderActivityEn.freelancerArrivedAtWorkLocationAndServiceStarted;
            description.id = orderActivityId.freelancerArrivedAtWorkLocationAndServiceStarted;
            break;
          case 'CREATE_CLOCK_OUT':
            description.en = orderActivityEn.serviceCompleted;
            description.id = orderActivityId.serviceCompleted;
            break;
          case 'CREATE_COMPLETED_CRON':
          case 'CREATE_COMPLETED':
            description.en = orderActivityEn.orderCompleted;
            description.id = orderActivityId.orderCompleted;
            break;
        }
      }

      return description;
    };
  }

  public get isWithin24Hours() {
    return (bookStartDateString: Date) => {
      const bookStartDate = dayjs.utc(bookStartDateString);
      const currentTime = dayjs.utc();
      const timeDifference = bookStartDate.diff(currentTime);

      // Check if difference is within 24 hours (24 hours in milliseconds)
      return Math.abs(timeDifference) <= 24 * 60 * 60 * 1000;
    };
  }

  public get isAfterDateNow() {
    return (endDate: Date) => {
      const now = dayjs();
      const end = dayjs(endDate);

      return end.isAfter(now); // Check if endDate is after the current date and time
    };
  }

  public get isPastBookingEndDate() {
    return (bookEndDateString: Date) => {
      const bookEndDate = dayjs(bookEndDateString);
      const currentTime = dayjs.utc();

      return bookEndDate.isBefore(currentTime);
    };
  }

  public isObjectNotEmpty(obj) {
    return Object.keys(obj).length > 0;
  }

  public findUniqueArrayInCbPawOrderRequirements(arrays: ICbVwPawOrderRequirement[]): ICbVwPawOrderRequirement[] {
    const uniqueArray = [];
    const uniqueIds = new Set();

    arrays.forEach(obj => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        uniqueArray.push(obj);
      }
    });

    return uniqueArray;
  }
}
