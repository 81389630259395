import Icon from '@/shared/icons/icon.vue';
import { isVideoFile } from '@/shared/media-file/media-file';
import { CbBookmarkType } from '@/shared/model/enumerations/cb-bookmark-type.model';
import { CbPawStatus } from '@/shared/model/enumerations/cb-paw-status.model';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  components: { Icon },
})
export default class CPdSlideshowMain extends Vue {
  // ================= START SERVICES ===================
  // ================= END SERVICES =====================
  // ================= START VARIABLES ==================
  @Prop({ default: [] })
  public cbVwPawDetailAttPortfolioMains;
  @Prop({ default: [] })
  public cbVwPaw;
  public isPlaying = false;
  public isPause = false;
  public isMuted = true;
  public isShowVideo = false;
  public cbBookmarkType = CbBookmarkType;
  public cbPawStatus = CbPawStatus;

  // ================= END VARIABLES ====================
  // ================= START DEFAULT FUNCTION ===========
  // ================= END DEFAULT FUNCTION ============
  // ================= START FUNCTION ==================
  public handlePlayVideo() {
    this.isPlaying = true;
    this.isPause = false;
    setTimeout(() => {
      (<any>this.$refs.myVideoPortfolioMain)[0].play();
    }, 50);
  }
  public handlePauseVideo() {
    this.isPause = true;
    setTimeout(() => {
      (<any>this.$refs.myVideoPortfolioMain)[0].pause();
    }, 50);
  }

  public handleMuteVideo() {
    this.isMuted = !this.isMuted;
  }
  // ================= END FUNCTION ====================
  // ================= START COMPUTE ===================
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  public get isVideo() {
    return attContentType => {
      this.isShowVideo = isVideoFile(attContentType);
      return this.isShowVideo;
    };
  }
  public get cbUserBookmarkAggs() {
    return this.$store.getters.GET_USER_BOOKMARK_PAW;
  }
  // ================= END COMPUTE =====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
