import CButton from '@/components/button/c-button.vue';
import { CbHelpCenterFeedback, ICbHelpCenterFeedback } from '@/shared/model/cb-help-center-feedback.model';
import { ChtMessage } from '@/shared/model/cht-message.model';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

const validatiion = {
  cbHelpCenterFeedback: {
    userId: {},
    feedBack: {},
    externalId: {},
    isActive: {},
    createdDate: {},
    createdBy: {},
    lastModifiedBy: {},
    lastModifiedDate: {},
    feedBackLvl: { required },
    externalType: {},
    description: {},
  },
};
@Component({
  validations: validatiion,
  components: {
    CButton,
  },
})
export default class FeedbackChatMessage extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public chtMessage: ChtMessage;
  @Prop({ default: null }) public currentUserId: string;
  public cbHelpCenterFeedback: ICbHelpCenterFeedback = new CbHelpCenterFeedback();
  public isSaving = false;

  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  public created() {
    (<any>this.$root).$off('liveChat::feedback::getVariable');
    (<any>this.$root).$on('liveChat::feedback::getVariable', (fieldName: string, value: any) => {
      this[fieldName] = value;
      console.log('>>>>>>', this[fieldName]);
    });
  }
  mounted() {
    this.listenChatExternalId();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public submit() {
    this.cbHelpCenterFeedback.authorIdAgent = this.chtMessage.authorId;
    this.cbHelpCenterFeedback.createdByTktLiveChat = this.currentUserId;
    this.$root.$emit('fc::changeFunction', 'save', this.cbHelpCenterFeedback);
    // this.isSaving = true;
  }

  @Watch('chtMessage.externalId')
  public listenChatExternalId() {
    if (this.chtMessage && this.chtMessage.externalId) {
      this.$root.$emit('fc::changeFunction', 'findCbHelpCenterFeedback', this.chtMessage.externalId);
    }
  }

  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
}
