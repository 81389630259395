var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-paw-booking-date",
      attrs: { "data-cy": "modal-paw-booking-date" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center bar-wrapper",
          class: {
            "justify-content-lg-between":
              !_vm.isMobile || _vm.modalStepFormOrders?.length === 0,
            "justify-content-start":
              (_vm.isMobile && _vm.modalStepFormOrders?.length > 0) ||
              (_vm.isMobile && !_vm.modalStepFormOrders),
          },
        },
        [
          _c(
            "div",
            {
              class: {
                "vac-navbar-button-hide":
                  (!_vm.isMobile || !_vm.modalStepFormOrders) &&
                  !_vm.withoutModalStepFormOrders,
              },
            },
            [
              _vm.isMobile ||
              _vm.modalStepFormOrders?.length > 1 ||
              _vm.withoutModalStepFormOrders
                ? _c(
                    "button",
                    {
                      staticClass: "cursor-pointer d-flex p-3",
                      attrs: {
                        type: "button",
                        "data-cy": "booking-btn-modal-back",
                      },
                      on: {
                        click: function ($event) {
                          _vm.modalStepFormOrders?.length > 1 ||
                          _vm.withoutModalStepFormOrders
                            ? _vm.back()
                            : _vm.cancel()
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass:
                          "cb-icon-size-arrow-left cj-color-black-primary",
                        attrs: { icon: "fa-solid fa-arrow-left" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "font-roboto cj-color-black-primary font-weight-700 font-size-24",
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.textBasedModel.headerTitle) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            (_vm.modalStepFormOrders?.length > 0 && !_vm.isMobile) ||
            (!_vm.modalStepFormOrders && !_vm.isMobile)
              ? _c(
                  "button",
                  {
                    staticClass: "cursor-pointer d-flex",
                    attrs: {
                      type: "button",
                      "data-cy": "booking-btn-modal-cancel",
                    },
                    on: { click: _vm.cancel },
                  },
                  [
                    _c("b-icon-x-circle-fill", {
                      staticClass: "font-size-24 cj-color-grey-secondary",
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pt-3 paw-booking-date-container" }, [
        _c("p", { staticClass: "mb-2" }, [
          _c("span", {
            staticClass:
              "mb-2 font-roboto font-weight-600 cj-color-black-primary font-size-16",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cbPawBookingDate.time")
              ),
            },
          }),
          _c("span", { staticClass: "cj-color-grey-secondary" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex mx-min-2 flex-wrap" }, [
          _c(
            "div",
            { staticClass: "col-lg-6 col-12 px-2 mb-2 mb-lg-1" },
            [
              _c("c-autocomplete-new", {
                staticClass:
                  "rounded cj-bg-white-primary h-100 min-height-custom",
                class: {
                  invalid:
                    _vm.$v.timeZone.$anyDirty && _vm.$v.timeZone.$invalid,
                  valid: _vm.$v.timeZone.$anyDirty && !_vm.$v.timeZone.$invalid,
                },
                attrs: {
                  "data-cy-custom": "booking-select-timezone",
                  items: _vm.TIME_ZONE,
                  "item-text": "value",
                  "item-value": "value",
                  placeholder: _vm.$t(
                    "cbgwApp.cbComponent.cbPawBookingDate.selectTimeZone"
                  ),
                  inputStyle: "my-auto h-100 px-3",
                },
                model: {
                  value: _vm.timeZone,
                  callback: function ($$v) {
                    _vm.timeZone = $$v
                  },
                  expression: "timeZone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-6 col-12 px-2 mb-1 mb-lg-0" },
            [
              _c("c-timepicker", {
                staticClass:
                  "rounded cj-field-input cj-field-border-default rounded w-100 font-roboto font-weight-400 font-size-18 cj-color-black-primary",
                class: `${
                  _vm.$v.startHour.$anyDirty
                    ? _vm.$v.startHour.$invalid || !_vm.validateDueTime
                      ? "invalid"
                      : "valid"
                    : ""
                }`,
                attrs: {
                  "data-cy-custom": "booking-date",
                  isFormat24: true,
                  disabledTime: _vm.disabledStartHour,
                  placeholder: "00:00",
                  type: "text",
                },
                on: { time: _vm.handleTime },
                model: {
                  value: _vm.startHour,
                  callback: function ($$v) {
                    _vm.startHour = $$v
                  },
                  expression: "startHour",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "font-roboto cj-color-black-primary font-size-12 font-weight-400 mb-3",
          },
          [
            _vm.bookingType === _vm.TypeComponent.APPOINTMENT
              ? _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbComponent.cbPawBookingDate.infoTimezoneInside"
                      )
                    ),
                  },
                })
              : _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t(
                        "cbgwApp.cbComponent.cbPawBookingDate.infoTimezoneField"
                      )
                    ),
                  },
                }),
          ]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "mb-2" }, [
          _c("span", {
            staticClass:
              "mb-2 font-roboto font-weight-600 cj-color-black-primary font-size-16",
            domProps: {
              textContent: _vm._s(
                _vm.$t("cbgwApp.cbComponent.cbPawBookingDate.date")
              ),
            },
          }),
          _c("span", { staticClass: "cj-color-grey-secondary" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-12 col-sm-12 col-md-12 d-flex flex-column mb-3",
            },
            [
              _c(
                "div",
                { staticClass: "wrap-border-grey-tertiary rounded p-3 h-100" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "cb-custom-full-calendar rounded mb-2 mb-lg-3",
                      attrs: { "data-cy": "booking-calender-wrap" },
                    },
                    [
                      _c("full-calendar", {
                        ref: "fullCalendarRef",
                        attrs: { options: _vm.calendarOptions },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "font-roboto cj-color-black-primary font-size-12 font-weight-400",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbPawBookingDate.pressFullCalendarSystem"
                        )
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _vm.bookingType !== _vm.TypeComponent.APPOINTMENT
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "wrap-border-grey-tertiary rounded p-3 mt-2 d-flex flex-column bg-green",
                        },
                        [
                          _c("p", {
                            staticClass:
                              "font-roboto cj-color-black-primary font-size-14 font-weight-700",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cbPawBookingDate.bookedSchedule"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _vm.bookedList?.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "schedule-overflow mt-2 d-flex gap-1 flex-column",
                                },
                                _vm._l(_vm.bookedList, function (event, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      attrs: {
                                        "data-cy": "booking-date-schedule-list",
                                      },
                                    },
                                    [
                                      _vm.getValidationBookedList(
                                        event.start,
                                        event.end
                                      )
                                        ? _c(
                                            "div",
                                            { staticClass: "d-flex gap-1" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "cb-list-time-booked font-roboto cj-color-black-primary font-size-10 font-weight-500 font-italic",
                                                  attrs: {
                                                    "data-cy": "booking-time",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f("time24")(
                                                          event.start
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        event.end
                                                          ? " - " +
                                                              _vm.$options.filters.time24(
                                                                event.end
                                                              )
                                                          : ""
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "d-flex gap-1" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "cb-list-time-booked font-roboto cj-color-black-primary font-size-10 font-weight-500 font-italic",
                                                  attrs: {
                                                    "data-cy": "booking-time",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f("formatMillis")(
                                                          event.start
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        event.end
                                                          ? " - " +
                                                              _vm.$options.filters.formatMillis(
                                                                event.end
                                                              )
                                                          : ""
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-weight-700 font-size-14 mt-2 cj-color-grey-tertiary",
                                    attrs: { "data-cy": "time-available" },
                                  },
                                  [_vm._v("Available")]
                                ),
                              ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.timeZone &&
                  _vm.bookingType !== _vm.TypeComponent.APPOINTMENT
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto font-size-12 cj-color-black-primary mt-2",
                        },
                        [
                          _c("span", {
                            staticClass: "font-weight-400",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "cbgwApp.cbComponent.cbPawBookingDate.bookedScheduleTime"
                                )
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "font-weight-700",
                              attrs: { "data-cy": "booking-time-showed-in" },
                            },
                            [_vm._v(_vm._s(_vm.timeZone))]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-wrap" }, [
          _c(
            "div",
            { staticClass: "col-12 wrap-border-grey-tertiary rounded p-3" },
            [
              _c(
                "p",
                {
                  staticClass: "font-roboto font-weight-400 font-size-16",
                  attrs: { "data-cy": "booking-date-schedule" },
                },
                [
                  _c("span", [
                    !_vm.startDate
                      ? _c(
                          "span",
                          {
                            staticClass: "cj-color-grey-tertiary",
                            attrs: {
                              "data-cy": "booking-start-date-placeholder",
                            },
                          },
                          [_vm._v("DD MMM YYYY")]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "cj-color-black-primary",
                            attrs: { "data-cy": "booking-start-date" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDateTimeDefault")(_vm.startDate)
                              )
                            ),
                          ]
                        ),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.startDate && !_vm.startHour
                      ? _c(
                          "span",
                          {
                            staticClass: "cj-color-grey-tertiary",
                            attrs: {
                              "data-cy": "booking-start-hour-placeholder",
                            },
                          },
                          [_vm._v("00:00 ")]
                        )
                      : !_vm.startDate && _vm.startHour
                      ? _c(
                          "span",
                          {
                            staticClass: "cj-color-black-primary",
                            attrs: { "data-cy": "booking-start-hour" },
                          },
                          [_vm._v(" " + _vm._s(_vm.startHour))]
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    !_vm.timeZone
                      ? _c(
                          "span",
                          {
                            staticClass: "cj-color-grey-tertiary",
                            attrs: {
                              "data-cy": "booking-timezone-placeholder",
                            },
                          },
                          [_vm._v("(UTC)")]
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "cj-color-black-primary",
                            attrs: { "data-cy": "booking-timezone" },
                          },
                          [_vm._v(_vm._s(_vm.timeZone))]
                        ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.timeZone && _vm.disabledStartHour
                ? _c("p", {
                    staticClass:
                      "font-roboto font-size-12 cj-color-black-primary mt-2 cj-color-status-error",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "cbgwApp.cbComponent.cbPawBookingDate.invalidSelectedTime"
                        )
                      ),
                    },
                  })
                : _vm._e(),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex justify-content-end btn-custom-center-m pt-3",
          },
          [
            _c(
              "c-button",
              {
                staticClass: "btn-continue",
                attrs: {
                  "data-cy": "booking-btn-modal-continue",
                  disabled:
                    !_vm.getValidDate || _vm.isLoadingButtonPawBookingDate,
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.getValidDate &&
                      !_vm.isLoadingButtonPawBookingDate &&
                      _vm.submit()
                  },
                },
              },
              [
                _vm.isLoadingButtonPawBookingDate
                  ? _c("font-awesome-icon", {
                      attrs: {
                        icon: "sync",
                        spin: _vm.isLoadingButtonPawBookingDate,
                      },
                    })
                  : _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "cbgwApp.cbComponent.cbPawBookingDate.continue"
                          )
                        ),
                      },
                    }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }