import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

import CLocationMap from '@/components/location-map/c-location-map.vue';
import Icon from '@/shared/icons/icon.vue';
import CButton from '@/components/button/c-button.vue';

import { CbLocation, ICbLocation } from '@/shared/model/cb-location.model';
import { ModalStepFormOrderType } from '@/shared/model/enumerations/modal-step-form-order-type.model';
import { TypeComponent } from '@/shared/model/enumerations/type-component.model';

const validations: any = {
  cbLocation: {
    address: { required },
    latGoogle: { required },
    longGoogle: { required },
    addressPinPoint: { required },
  },
};
@Component({
  validations,

  components: {
    Icon,
    CLocationMap,
    CButton,
  },
})
export default class CPawSetWorkLocation extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: null }) public parentCbLocation: CbLocation;
  @Prop({ default: null }) public modalStepFormOrders: ModalStepFormOrderType[];
  @Prop({ default: null }) public type: TypeComponent;

  public cbLocation: ICbLocation = new CbLocation();
  public TypeComponent = TypeComponent;
  public screenHeight = window.innerHeight - 245 + 'px';
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {
    window.addEventListener('resize', ev => {
      this.screenHeightMap();
    });
  }
  public mounted() {
    this.watchParentCbLocation();
  }
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public locationMapCallback(location: ICbLocation) {
    this.cbLocation = Object.assign({}, location);
  }

  public screenHeightMap() {
    this.screenHeight = window.innerHeight - 245 + 'px';
  }

  @Emit('cancel')
  public cancel() {
    return;
  }

  @Emit('submit')
  public submit() {
    if (this.$v.cbLocation.$invalid) return;

    return this.cbLocation;
  }

  @Emit('back')
  public back() {
    return;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  @Watch('parentCbLocation')
  public watchParentCbLocation() {
    if (this.parentCbLocation) {
      Object.assign(this.cbLocation, this.parentCbLocation);
    }
  }
  // ================= END LISTENERS ===================
}
