import { ICmAttachmentAuth } from './cm-attachment-auth.model';
import { ICmTextAuth } from './cm-text-auth.model';
import { BankAccountType } from './enumerations/bank-account-type.model';
import { CbVerificationStatus } from './enumerations/cb-verification-status.model';

export interface ICbBankAccount {
  id?: number;
  bankCode?: string | null;
  accountNumber?: string | null;
  accountHolderName?: string | null;
  bankAccountType?: BankAccountType | null;
  expiredDate?: Date | null;
  expiryDateLabel?: string | null;
  cvc?: number | null;
  isSave?: boolean | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  pin?: string | null;
  status?: CbVerificationStatus | null; //Transient
  lastIdcard?: string | null; //Transient
  lastIdcardSelfie?: string | null; //Transient
  historyIdcards?: string | null; //Transient
  historyIdcardSelfies?: string | null; //Transient
  cmAttachmentAuths?: ICmAttachmentAuth[] | null; //Transient
  reasonAdmin?: string | null; //Transient
  cmTextAuth?: ICmTextAuth | null; //Transient
  isShowIdCardForm?: boolean | null; //Transient
  isShowSelfieIdCardForm?: boolean | null; //Transient
}

export class CbBankAccount implements ICbBankAccount {
  constructor(
    public id?: number,
    public bankCode?: string | null,
    public accountNumber?: string | null,
    public accountHolderName?: string | null,
    public bankAccountType?: BankAccountType | null,
    public expiredDate?: Date | null,
    public expiryDateLabel?: string | null,
    public cvc?: number | null,
    public isSave?: boolean | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public pin?: string | null,
    public status?: CbVerificationStatus | null, //Transient
    public lastIdcard?: string | null, //Transient
    public lastIdcardSelfie?: string | null, //Transient
    public historyIdcards?: string | null, //Transient
    public historyIdcardSelfies?: string | null, //Transient
    public cmAttachmentAuths?: ICmAttachmentAuth[] | null, //Transient
    public reasonAdmin?: string | null, //Transient
    public cmTextAuth?: ICmTextAuth | null, //Transient
    public isShowIdCardForm?: boolean | null, //Transient
    public isShowSelfieIdCardForm?: boolean | null //Transient
  ) {
    // this.isShowIdCardForm = this.isShowIdCardForm ?? false;
    // this.isShowSelfieIdCardForm = this.isShowSelfieIdCardForm ?? false;
  }
}
