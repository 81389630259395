var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-footer-bottom-sheet" },
    [
      _c("div", { staticClass: "footer-title-wrapper" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center py-2 c-container" },
          [
            _c(
              "p",
              {
                staticClass:
                  "m-0 font-roboto font-weight-400 cj-color-black-primary font-size-14 mr-2",
              },
              [
                _vm._v(
                  "\n        © 2024 Klikjob by PT. BIONIC TEKNOLOGI INDONESIA\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "m-0 circle-text font-roboto font-weight-400 cj-color-black-primary font-size-14 mr-2",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSvc.about.privacyPolicy")
                  ),
                },
                on: { click: _vm.toPrivacyPolicy },
              },
              [_vm._v("Privacy Policy")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "m-0 circle-text mr-auto font-roboto font-weight-400 cj-color-black-primary font-size-14",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("cbgwApp.cbSvc.about.termService")
                  ),
                },
                on: { click: _vm.toTermService },
              },
              [_vm._v("Terms of Service")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "d-flex align-items-center",
                on: {
                  click: function ($event) {
                    _vm.isShowFooter = true
                  },
                },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "m-0 font-roboto font-weight-500 cj-color-black-primary font-size-14",
                    domProps: {
                      textContent: _vm._s(_vm.$t("cbGlobal.supportResources")),
                    },
                  },
                  [_vm._v("\n          Support & Resources\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex ml-2" },
                  [
                    _c("icon", {
                      staticClass: "cj-fill-black-primary",
                      staticStyle: { transform: "rotate(180deg)" },
                      attrs: { name: "ic_down", width: "1rem", height: "1rem" },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "c-popup-bottom",
        {
          model: {
            value: _vm.isShowFooter,
            callback: function ($$v) {
              _vm.isShowFooter = $$v
            },
            expression: "isShowFooter",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-right cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.isShowFooter = false
                },
              },
            },
            [
              _c("b-icon-x-circle-fill", {
                staticClass: "font-size-18 cj-color-grey-secondary",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("c-footer", { attrs: { isShowTabFooter: _vm.isShowTabFooter } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }