var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-media-preview",
      style: `--heightMediaPreview:${_vm.screenHeight};`,
    },
    [
      _c(
        "div",
        {
          staticClass: "btn-wrapper cursor-pointer",
          attrs: { "data-cy": "vac-button-close" },
          on: {
            click: function ($event) {
              return _vm.closeModal()
            },
          },
        },
        [
          _c("b-icon-x", {
            staticClass: "cj-fill-white-primary font-size-36",
            attrs: { name: "close-outline" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", {
          attrs: { src: _vm.fileMediaPreview, alt: "media preview" },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }