var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cbVwPawOrderRequirement
    ? _c(
        "div",
        {
          staticClass: "c-item-chat-message-revision p-2 cj-bg-white-primary",
          attrs: { "data-cy": "item-revision" },
        },
        [
          _c(
            "div",
            [
              _vm.cbVwPawOrderRequirement.requirementType ==
              _vm.CbRequirementType.REVISION_EXTRA
                ? _c("icon", {
                    staticClass: "ic_medal_extra_revision",
                    attrs: {
                      name: "ic_medal_extra_revision",
                      width: "4rem",
                      height: "4rem",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mb-2 text-center",
                  staticStyle: { height: "62px" },
                },
                [
                  _c(
                    "p",
                    {
                      ref: "cbVwPawOrderRequirement.pawTitle",
                      staticClass:
                        "c-icm-font-card-title text-ellipsis oneline",
                      attrs: { "data-cy": "item-revision-title" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                          "\n        "
                      ),
                      _c(
                        "b-tooltip",
                        {
                          staticClass: "custom-tooltip",
                          attrs: {
                            target: () =>
                              _vm.$refs["cbVwPawOrderRequirement.pawTitle"],
                            variant: "primary",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-catamaran font-size-12 font-weight-500",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.cbVwPawOrderRequirement.pawTitle) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      ref: "cbVwPawOrderRequirement.svcName",
                      staticClass:
                        "c-icm-font-card-subtitle text-ellipsis oneline",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cbVwPawOrderRequirement.svcName) +
                          " | " +
                          _vm._s(_vm.cbVwPawOrderRequirement.svcCategoryName) +
                          "\n        "
                      ),
                      _c(
                        "b-tooltip",
                        {
                          staticClass: "custom-tooltip",
                          attrs: {
                            target: () =>
                              _vm.$refs["cbVwPawOrderRequirement.svcName"],
                            variant: "primary",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "font-catamaran font-size-12 font-weight-500",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.cbVwPawOrderRequirement.svcName) +
                                  " |\n            " +
                                  _vm._s(
                                    _vm.cbVwPawOrderRequirement.svcCategoryName
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.cbVwPawOrderRequirement.status ==
                  _vm.CbCommonStatus.CANCELLED
                    ? _c(
                        "p",
                        {
                          staticClass:
                            "c-icm-font-card-status cj-color-status-error text-ellipsis oneline",
                        },
                        [_vm._v("\n        Rejected\n      ")]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "cbTextRestrict.content",
                  staticClass:
                    "rounded border-width-1 px-2 py-1 text-center mb-2 cj-border-color-red-primary",
                  staticStyle: { height: "52px" },
                },
                [
                  _c("p", { staticClass: "c-icm-font-title mb-1" }, [
                    _vm._v("Description"),
                  ]),
                  _vm._v(" "),
                  _vm.cbTextRestrict && _vm.cbTextRestrict.content
                    ? _c("c-textarea-preview", {
                        staticClass: "c-icm-font-content text-ellipsis-oneline",
                        attrs: {
                          "data-cy": "item-revision-desc",
                          text: _vm.cbTextRestrict.content,
                        },
                      })
                    : _c(
                        "p",
                        {
                          staticClass:
                            "font-roboto cj-color-black-primary font-weight-400 font-size-12",
                        },
                        [_vm._v("No data.")]
                      ),
                  _vm._v(" "),
                  _c(
                    "b-tooltip",
                    {
                      staticClass: "custom-tooltip",
                      attrs: {
                        target: () => _vm.$refs["cbTextRestrict.content"],
                        variant: "primary",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "font-catamaran font-size-12 font-weight-500",
                        },
                        [_vm._v("Description")]
                      ),
                      _vm._v(" "),
                      _vm.cbTextRestrict && _vm.cbTextRestrict.content
                        ? _c("c-textarea-preview", {
                            staticClass:
                              "font-catamaran font-size-12 font-weight-500",
                            staticStyle: {
                              color: "var(--cj-color-white-primary) !important",
                            },
                            attrs: { text: _vm.cbTextRestrict.content },
                          })
                        : _c(
                            "p",
                            {
                              staticClass:
                                "font-catamaran font-size-12 font-weight-500",
                            },
                            [_vm._v("No data.")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.cbVwPawOrderRequirement.cbPawOrderFiles &&
              _vm.cbVwPawOrderRequirement.cbPawOrderFiles.length > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "rounded border-width-1 px-2 py-1 text-center mb-2 cj-border-color-red-primary",
                    },
                    [
                      _c("p", { staticClass: "c-icm-font-title mb-1" }, [
                        _vm._v("Attachment"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "img-container" },
                        _vm._l(
                          _vm.cbVwPawOrderRequirement.cbPawOrderFiles.slice(
                            0,
                            2
                          ),
                          function (cbPawOrderFile, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "d-flex position-relative",
                                attrs: { "data-cy": "item-revision-file" },
                              },
                              [
                                cbPawOrderFile.cbAttachmentRestrict &&
                                cbPawOrderFile.cbAttachmentRestrict.localUrl
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "my-auto mr-2 position-relative",
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "m-auto",
                                          staticStyle: { width: "4rem" },
                                          attrs: {
                                            src: `${_vm.$env.CDN_CB}/content/cb-img/ic-file-plain-text.png`,
                                            alt: "file",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "c-icm-font-text-btn cj-btn-text-primary",
                                            on: {
                                              click: function ($event) {
                                                return _vm.downloadFileDelivery(
                                                  cbPawOrderFile.cbAttachmentRestrict
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              Download\n            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        index == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "count-wrapper",
                                                class:
                                                  _vm.cbVwPawOrderRequirement
                                                    .cbPawOrderFiles.length <= 2
                                                    ? "d-none"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openAskRevision()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 font-roboto font-weight-700 font-size-12",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "+" +
                                                        _vm._s(
                                                          _vm
                                                            .cbVwPawOrderRequirement
                                                            .cbPawOrderFiles
                                                            .length - 2
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.cbVwPawOrderRequirement.id
                    ? _c(
                        "c-button",
                        {
                          staticClass: "c-icm-font-btn",
                          attrs: { fullWidth: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openAskRevision.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("VIEW")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }