import { render, staticRenderFns } from "./c-loader.vue?vue&type=template&id=63d08568&scoped=true&"
import script from "./c-loader.component.ts?vue&type=script&lang=js&"
export * from "./c-loader.component.ts?vue&type=script&lang=js&"
import style0 from "./c-loader.scss?vue&type=style&index=0&id=63d08568&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d08568",
  null
  
)

export default component.exports