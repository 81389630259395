export const regexFileType_IMAGE_ALL: RegExp = /image.*/;
export const regexFileType_VIDEO_ALL: RegExp = /video.*/;
export const regexFileType_PNG: RegExp = /.png/;
export const regexFileType_JPG: RegExp = /.jpg/;
export const regexFileType_JPEG: RegExp = /.jpeg/;
export const regexFileType_PDF: RegExp = /.pdf/;
export const regexFileType_SVG: RegExp = /.svg/;
export const regexFileType_DOC: RegExp = /.doc/;
export const regexFileType_DOCX: RegExp = /.docx/;
export const regexFileType_XLS: RegExp = /.xls/;
export const regexFileType_XLSX: RegExp = /.xlsx/;
export const regexFileType_MP3: RegExp = /.mp3/;
export const regexFileType_MP4: RegExp = /.mp4/;
export const regexFileType_WAV: RegExp = /.wav/;
export const regexFileType_WEBM: RegExp = /.webm/;
export const regexFileType_MKV: RegExp = /.mkv/;
export const regexFileType_AV: RegExp = /.av/;
export const regexFileType_MID: RegExp = /.mid/;
export const regexFileType_MIDI: RegExp = /.midi/;
export const regexFileType_7Z: RegExp = /.7z/;
export const regexFileType_RAR: RegExp = /.rar/;
export const regexFileType_ZIP: RegExp = /.zip/;
export const regexFileType_GIF: RegExp = /.gif/;
export const regexFileType_3GP: RegExp = /.3gp/;
export const regexFileType_AVI: RegExp = /.avi/;
export const regexFileType_FLV: RegExp = /.flv/;
export const regexFileType_M4V: RegExp = /.m4v/;
export const regexFileType_MOV: RegExp = /.mov/;
export const regexFileType_MPG: RegExp = /.mpg/;
export const regexFileType_MPEG: RegExp = /.mpeg/;
export const regexFileType_WMV: RegExp = /.wmv/;
export const regexFileType_PPTX: RegExp = /.pptx/;
export const regexFileType_PPT: RegExp = /.ppt/;
export const regexFileType_KEY: RegExp = /.key/;
export const regexFileType_NUMBERS: RegExp = /.numbers/;
export const regexFileType_PAGES: RegExp = /.pages/;

