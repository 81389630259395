import axios from 'axios';

import { ICbPawOrderMilestone } from '@/shared/model/cb-paw-order-milestone.model';

const baseApiUrl = 'services/cbcorems/api/cb-paw-order-milestones';

export default class PCbPawOrderMilestoneService {
  public partialUpdate(entity: ICbPawOrderMilestone): Promise<ICbPawOrderMilestone> {
    return new Promise<ICbPawOrderMilestone>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
