import { ICbPromo } from '@/shared/model/cb-promo.model';
import { ICbUserInfo } from '@/shared/model/cb-user-info.model';

import { CbPromoUsedStatus } from '@/shared/model/enumerations/cb-promo-used-status.model';
export interface ICbPromoUserAgg {
  id?: number;
  status?: CbPromoUsedStatus | null;
  createdBy?: string | null;
  createdDate?: Date | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: Date | null;
  isActive?: boolean | null;
  cbPromo?: ICbPromo | null;
  cbUserInfo?: ICbUserInfo | null;
}

export class CbPromoUserAgg implements ICbPromoUserAgg {
  constructor(
    public id?: number,
    public status?: CbPromoUsedStatus | null,
    public createdBy?: string | null,
    public createdDate?: Date | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: Date | null,
    public isActive?: boolean | null,
    public cbPromo?: ICbPromo | null,
    public cbUserInfo?: ICbUserInfo | null
  ) {
    // this.isActive = this.isActive ?? false;
  }
}
