var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "c-pd-license-item d-flex align-items-center" },
    [
      _c("div", { staticClass: "card-item-wrapper mb-2 mr-2" }, [
        _c("div", { staticClass: "cb-img-wrapper cb-ratio-1x1" }, [
          _c("img", {
            staticClass: "cb-img-contain",
            attrs: {
              src: `${_vm.$env.CDN_CB}/content/cb-img/${
                _vm.item.isExpired && _vm.currentUser.id == _vm.cbVwPaw.authorId
                  ? "ic-cetification-" +
                    "expired" +
                    (_vm.currentLanguage == "en" ? "_EN" : "_ID") +
                    ".png"
                  : "ic-cetification-default.png"
              }`,
              alt: "img",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow-1 mb-2" }, [
        _c(
          "p",
          {
            staticClass:
              "mb-0 font-roboto font-weight-700 cj-color-black-primary",
            class: _vm.isMobile ? "font-size-16" : "font-size-14",
            attrs: { "data-cy": "detail-paw-license-name" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.item && _vm.item.name ? _vm.item.name : "-") +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "mb-0 font-roboto font-weight-400 font-size-12 cj-color-black-primary",
            attrs: { "data-cy": "detail-paw-license-publisher" },
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.item && _vm.item.publisher) + "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "mb-0 font-roboto font-weight-400 font-size-8 cj-color-black-primary",
            attrs: { "data-cy": "detail-paw-license-date" },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("cbgwApp.cbComponent.profileInfoUser.published")
                ) +
                " " +
                _vm._s(
                  _vm._f("dynamicFormatDate")(_vm.item.publishDate, "MMM YYYY")
                ) +
                "\n      "
            ),
            _vm._v(" "),
            _vm.item.expiredDate
              ? [
                  _vm._v(
                    " - " +
                      _vm._s(
                        _vm._f("dynamicFormatDate")(
                          _vm.item.expiredDate,
                          "MMM YYYY"
                        )
                      ) +
                      " "
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }