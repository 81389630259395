import CButton from '@/components/button/c-button.vue';
import Icon from '@/shared/icons/icon.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
    Icon,
    CButton,
  },
})
export default class CBaBankCard extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop()
  cbBankAccount;
  @Prop()
  getBankNameByKey;
  @Prop()
  newCbBankAccount;
  @Prop()
  public isFetchingCbBankAccount: boolean;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  public openForm() {
    this.$emit('openForm');
  }

  public openModalUnderReview() {
    this.$emit('changeFunction', 'openModalBankUunderReview');
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  // ================= END COMPUTE ====================
  // ================= START LISTENERS ================
  // ================= END LISTENERS ==================
}
