import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class CUserPhotoOnline extends Vue {
  // ================= START SERVICES =================
  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  @Prop({ default: 3.75 }) public width: number;
  @Prop({ default: '' }) public url: string;
  @Prop({ default: false }) public isOnline: boolean;
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================
  public get currentUser() {
    return this.$store.getters.currentUser;
  }
  // ================= END COMPUTE ====================
  // ================= START LISTENERS =================
  // ================= END LISTENERS ===================
}
