import { Vue, Component, Prop, Inject } from 'vue-property-decorator';

import GlobalService from '@/services/global.service';

import Icon from '@/shared/icons/icon.vue';
import TranslationService from '@/locale/translation.service';

@Component({
  components: { Icon },
})
export default class CLanguages extends Vue {
  // ================= START SERVICES =================
  @Inject('globalService') public globalService: () => GlobalService;
  @Inject('translationService') private translationService: () => TranslationService;

  // ================= END SERVICES ===================
  // ================= START VARIABLES ================
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  // ================= END VARIABLES ==================
  // ================= START DEFAULT FUNCTION =========
  created() {}
  // ================= END DEFAULT FUNCTION ===========
  // ================= START FUNCTION =================

  public changeLanguage(newLanguage: any): void {
    this.translationService().refreshTranslation(newLanguage);
    this.globalService().closeGlobalDialog(this.$root, 'modal-c-change-language');
  }

  public isActiveLanguage(key: any): boolean {
    return key === this.$store.getters.currentLanguage;
  }
  // ================= END FUNCTION ===================
  // ================= START COMPUTE ==================

  public get isMobile(): boolean {
    return this.$store.getters.isMobile;
  }

  public get languages(): any {
    const language: any = {};
    for (const key in this.$store.getters.languages) {
      if (key != 'in') {
        language[key] = this.$store.getters.languages[key];
      }
    }
    return language;
  }
  // ================= END COMPUTE ====================
}
