var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "c-rmedia-links p-3 custom-links",
      attrs: { "data-cy": "c-rmedia-links" },
    },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "a",
          {
            staticClass: "link-item flex-grow-1 vac-overflow-hidden",
            attrs: {
              href: "https://" + _vm.chtVwMedia.linkSrc,
              target: "_blank",
            },
          },
          [_vm._v(_vm._s(_vm.chtVwMedia.linkSrc))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-3" }, [
          _c(
            "p",
            {
              staticClass: "m-0 links-date",
              attrs: { "data-cy": "links-date" },
            },
            [_vm._v(_vm._s(_vm.getGroupDate))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }